export const emptyArray = [];

/**
 * Ensures that the input value is an array.
 * If the input value is already an array, it is returned as is.
 * If the input value is null or undefined, an empty array is returned.
 *
 * @template T - The type of the array elements.
 * @param {T} arr - The input value to ensure as an array.
 * @returns {T[]} - The input value as an array.
 */
export const ensureArray = <T = unknown[] | null | undefined>(arr?: T) =>
  arr ?? (emptyArray as unknown as NonNullable<T>);
