import { useBooksOwnersQuery } from 'api';
import { useMeta } from 'hooks/useMeta';
import { usePaginateAllQuery } from 'hooks/usePaginateAllQuery';
import { useTeams } from 'hooks/useTeams';
import { compact, Dictionary, find, sum, uniq, uniqBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { ensureArray } from 'system';

export const usePropertyOwners = ({
  unitById,
  propertyById,
}: {
  unitById: Dictionary<{ id?: string; propertyId?: string; name: string; occupancy?: string }>;
  propertyById: Dictionary<{ name: string; enabled?: boolean }>;
}) => {
  const { isOwnerVisible, isPropertyVisible, isOccupancyVisible } = useTeams();

  const { items, ...ownersMeta } = usePaginateAllQuery(useBooksOwnersQuery, {
    getNextToken: (d) => d?.account?.books?.listOwners?.nextToken,
    getItems: (d) => d?.account?.books?.listOwners?.items,
  });
  const { loading, working } = useMeta(ownersMeta);

  const booksOwners = useMemo(
    () =>
      items.filter(isOwnerVisible).map((owner) => {
        const currentPropertyIds = compact(
          uniq([
            ...ensureArray(owner.propertyIds),
            ...ensureArray(owner.unitHeaders).map(({ propertyId }) => propertyId),
          ])
        );

        const pastLeasedPropertyIds = compact(
          ensureArray(owner.history).map(
            ({ assetType, assetId, leasedUnits, commercialLeasedUnits }) =>
              assetType === 'unit'
                ? unitById?.[assetId]?.propertyId
                : sum([leasedUnits, commercialLeasedUnits]) < 1
                  ? undefined
                  : assetId
          )
        );

        const historicalAssets = uniqBy(owner.history, 'assetId').filter(
          ({ assetId }) =>
            !owner.propertyIds?.includes(assetId) && !owner.unitIds?.includes(assetId)
        );

        const historicalProperties = historicalAssets
          .filter(
            ({ assetId, assetType }) =>
              assetType === 'property' && propertyById[assetId] && isPropertyVisible(assetId)
          )
          .map(({ assetId }) => ({
            id: `${owner.id}#${assetId}`,
            ownerId: owner.id,
            ownerName: owner.name,
            propertyId: assetId,
            propertyName: propertyById[assetId].name,
            enabled: Boolean(owner.enabled),
            occupancy: undefined,
            propertyEnabled: propertyById[assetId]?.enabled,
          }));

        const historicalPropertyIds = new Set(
          historicalProperties.map(({ propertyId }) => propertyId)
        );

        const historicalUnits = historicalAssets
          .filter(
            ({ assetId, assetType }) =>
              assetType === 'unit' &&
              unitById[assetId] &&
              isOccupancyVisible(unitById[assetId]) &&
              !historicalPropertyIds.has(unitById[assetId].propertyId ?? '') &&
              propertyById[unitById[assetId].propertyId ?? '']
          )
          .map(({ assetId }) => {
            const unit = unitById[assetId];
            return {
              id: `${owner.id}#${unit.propertyId}`,
              ownerId: owner.id,
              ownerName: owner.name,
              propertyId: String(unit.propertyId),
              propertyName: propertyById[unit.propertyId ?? 'invalid']?.name,
              enabled: Boolean(owner.enabled),
              occupancy: unit.occupancy ?? 'rental',
              propertyEnabled: Boolean(unit.propertyId && propertyById[unit.propertyId]?.enabled),
            };
          });

        const propertyOptions = [
          ...ensureArray(owner?.propertyHeaders)
            .filter(({ id }) => isPropertyVisible(id))
            .map((property) => ({
              id: `${owner.id}#${property.id}`,
              ownerId: owner.id,
              ownerName: owner.name,
              propertyId: property.id,
              propertyName: property.name,
              enabled: owner.enabled,
              occupancy: undefined,
              propertyEnabled: propertyById[property.id]?.enabled,
            })),
          ...ensureArray(owner?.unitHeaders)
            .filter(isOccupancyVisible)
            .map((unit) => ({
              id: `${owner.id}#${unit.propertyId}`,
              ownerId: owner.id,
              ownerName: owner.name,
              propertyId: unit.propertyId,
              propertyName: unit.propertyName,
              enabled: owner.enabled,
              occupancy: unit.occupancy ?? 'rental',
              propertyEnabled: propertyById[unit.propertyId]?.enabled,
            })),
          ...historicalProperties,
          ...historicalUnits,
        ];

        return {
          id: owner.id,
          name: owner.name,
          address: owner.address,
          currentPropertyIds,
          allPropertyIds: uniq(currentPropertyIds.concat(pastLeasedPropertyIds)),
          propertyOptions,
          unitHeaders: owner.unitHeaders,
          unitIds: owner.unitIds,
          propertyHeaders: owner.propertyHeaders,
          propertyIds: owner.propertyIds,
          isUnitOwner: Boolean(owner?.unitIds?.length ?? false),
          isPropertyOwner: Boolean(owner?.propertyIds?.length ?? false),
          enabled: owner.enabled,
        };
      }),
    [isOccupancyVisible, isOwnerVisible, isPropertyVisible, items, propertyById, unitById]
  );

  const getPropertyOwnerId = useCallback(
    (propertyId: string) => items.find((owner) => owner.propertyIds?.includes(propertyId))?.id,
    [items]
  );

  const unitsOwnedInProperty = useCallback(
    (propertyId: string, ownerId: string) =>
      ensureArray(
        find(items, {
          id: ownerId,
        })?.unitHeaders?.filter((unitHeader) => unitHeader.propertyId === propertyId)
      ),
    [items]
  );

  return { loading, booksOwners, getPropertyOwnerId, unitsOwnedInProperty, working };
};
