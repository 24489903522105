import { PropsWithChildren, useState } from 'react';
import Modal, { ModalProps } from './Modal';

type MaybeBool = boolean | undefined | void;

type ConfirmModalProps = PropsWithChildren<
  {
    onConfirm: () => MaybeBool | Promise<MaybeBool>;
    onConfirmComplete?: VoidFunction;
    confirmLabel?: string;
    onCancel?: () => MaybeBool | Promise<MaybeBool>;
    cancelLabel?: string;
    loading?: boolean;
    closeOnConfirm?: boolean;
    disabled?: boolean;
  } & Omit<ModalProps, 'children'>
>;

export const ConfirmModal = ({
  onClose,
  onCancel,
  onConfirm,
  onConfirmComplete,
  open = false,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  title = 'Confirmation',
  loading,
  children,
  closeOnConfirm = true,
  disabled,
  ...modalProps
}: ConfirmModalProps) => {
  const [confirming, setConfirming] = useState(false);

  return open ? (
    <Modal
      {...{ onClose, open, title, children, ...modalProps }}
      actions={{
        cancel: {
          label: cancelLabel,
          onClick: async () => {
            await onCancel?.();
            await onClose();
          },
          disabled: confirming || disabled,
        },
        confirm: {
          label: confirmLabel,
          onClick: async () => {
            setConfirming(true);
            let result;
            try {
              result = await onConfirm();
            } catch (_) {
              result = false;
            }
            setConfirming(false);
            if (result !== false) {
              await (closeOnConfirm && onClose());
              onConfirmComplete?.();
            }
          },
          loading: confirming || loading,
          disabled,
        },
      }}
    />
  ) : (
    <></>
  );
};
