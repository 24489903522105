import { eventFactory } from '@propra-system/shape-handler';
import { maybe } from '@propra-system/shapes-util/src/maybe';
import { z } from 'zod';

export const { managerPayeeAutopayCancelledEvent } = eventFactory(
  'manager-payee-autopay-cancelled-event',
  z.object({
    id: z.string(),
    accountId: z.string(),
    payee: z.string(),
    autopayStatus: z.string(),
    profileId: maybe(z.string()),
    ownerId: maybe(z.string()),
    tenantId: maybe(z.string()),
  })
);
