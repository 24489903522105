import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  ButtonBase as ButtonBase_,
  ButtonBaseProps,
  styled,
} from '@mui/material';
import { ReactNode } from 'react';

const ButtonBase = styled(ButtonBase_)<{ selected?: boolean }>(({ theme, selected }) => ({
  width: '100%',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-start',
  border: 'none',
  borderRadius: theme.shape.borderRadius,
  ...(selected && {
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.primaryBackground.main,
  }),
}));

export function AccordionRadioButton({
  selected,
  label,
  children,
  sx,
  ...buttonProps
}: { selected?: boolean; label?: ReactNode } & ButtonBaseProps) {
  return (
    <Accordion
      expanded={children ? selected : false}
      sx={{ [`&.${accordionClasses.expanded}`]: { my: 0 } }}
    >
      <ButtonBase selected={selected} sx={{ textAlign: 'left', ...sx }} {...buttonProps}>
        {selected ? (
          <RadioButtonChecked color="primary" />
        ) : (
          <RadioButtonUnchecked color="disabled" />
        )}

        {label}
      </ButtonBase>

      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
