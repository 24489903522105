import { eventFactory } from '@propra-system/shape-handler';
import { maybe } from '@propra-system/shapes-util/src/maybe';
import { z } from 'zod';

export const { managerResidencyResidentPaymentDetailsUpdatedEvent } = eventFactory(
  'manager-residency-resident-payment-details-updated-event',
  z.object({
    residentId: z.string(),
    residencyId: z.string(),
    accountId: z.string(),
    paymentDetails: z.object({
      preferredPaymentMethod: maybe(z.string()),
    }),
  })
);
