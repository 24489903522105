import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The AWSDate scalar type represents a valid extended ISO 8601 Date string. In other words, this scalar type accepts date strings of the form YYYY-MM-DD. This scalar type can also accept time zone offsets. For example, 1970-01-01Z, 1970-01-01-07:00 and 1970-01-01+05:30 are all valid dates. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: { input: string; output: string };
  /** The AWSDateTime scalar type represents a valid extended ISO 8601 DateTime string. In other words, this scalar type accepts datetime strings of the form YYYY-MM-DDThh:mm:ss.sssZ. The field after the seconds field is a nanoseconds field. It can accept between 1 and 9 digits. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used). The time zone offset is compulsory for this scalar. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: { input: string; output: string };
  /** The AWSEmail scalar type represents an Email address string that complies with RFC 822. For example, username@example.com is a valid Email address. */
  AWSEmail: { input: string; output: string };
  /** The AWSIPAddress scalar type represents a valid IPv4 or IPv6 address string. */
  AWSIPAddress: { input: string; output: string };
  /**
   * The AWSJSON scalar type represents a JSON string that complies with RFC 8259.
   *
   * Maps like {\"upvotes\": 10}, lists like [1,2,3], and scalar values like \"AWSJSON example string\", 1, and true are accepted as valid JSON. They will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings. Invalid JSON strings like {a: 1}, {'a': 1} and Unquoted string will throw GraphQL validation errors.
   */
  AWSJSON: { input: string; output: string };
  /** The AWSPhone scalar type represents a valid Phone Number. Phone numbers are serialized and deserialized as Strings. Phone numbers provided may be whitespace delimited or hyphenated. The number can specify a country code at the beginning but this is not required. */
  AWSPhone: { input: string; output: string };
  /**
   * The AWSTime scalar type represents a valid extended ISO 8601 Time string. In other words, this scalar type accepts time strings of the form hh:mm:ss.sss. The field after the seconds field is a nanoseconds field. It can accept between 1 and 9 digits. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used). This scalar type can also accept time zone offsets.
   *
   * For example, 12:30Z, 12:30:24-07:00 and 12:30:24.500+05:30 are all valid time strings.
   *
   * The time zone offset must either be Z (representing the UTC time zone) or be in the format hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard.
   */
  AWSTime: { input: string; output: string };
  /** The AWSTimestamp scalar type represents the number of seconds that have elapsed since 1970-01-01T00:00Z. Timestamps are serialized and deserialized as numbers. Negative values are also accepted and these represent the number of seconds till 1970-01-01T00:00Z. */
  AWSTimestamp: { input: string; output: string };
  /** The AWSURL scalar type represents a valid URL string. The URL may use any scheme and may also be a local URL (Ex: <http://localhost/>). URLs without schemes are considered invalid. URLs which contain double slashes are also considered invalid. */
  AWSURL: { input: string; output: string };
};

export type Account = {
  __typename?: 'Account';
  activatedEnrollmentIds?: Maybe<Scalars['AWSJSON']['output']>;
  address?: Maybe<Address>;
  /** @deprecated Use `listAnnouncements` to ensure all records are returned`. */
  announcements?: Maybe<Array<Maybe<Announcement>>>;
  autopayAccount?: Maybe<Scalars['String']['output']>;
  autopayClientId?: Maybe<Scalars['ID']['output']>;
  autopayInstitution?: Maybe<Scalars['String']['output']>;
  autopayStatus?: Maybe<Scalars['String']['output']>;
  autopayStatusUpdatedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  autopayTransit?: Maybe<Scalars['String']['output']>;
  billingEmail?: Maybe<Scalars['String']['output']>;
  books?: Maybe<Books>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  campaignApplicationsWithAction?: Maybe<Scalars['Int']['output']>;
  charges?: Maybe<Array<Maybe<Charge>>>;
  contactName?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  fees?: Maybe<Array<Maybe<Fee>>>;
  fingerprints?: Maybe<Fingerprints>;
  id: Scalars['ID']['output'];
  kyc?: Maybe<Kyc>;
  kycHistory?: Maybe<Array<Maybe<Kyc>>>;
  listActiveApprovals?: Maybe<ApprovalConnection>;
  listActiveRequests?: Maybe<RequestConnection>;
  listAllFinalizedRequests?: Maybe<RequestConnection>;
  listAnnouncements?: Maybe<AnnouncementConnection>;
  listApprovals?: Maybe<ApprovalConnection>;
  listAutopayEnrollments?: Maybe<AutopayEnrollmentConnection>;
  listAutopayProperties?: Maybe<PropertyConnection>;
  listAutopaySummaries?: Maybe<AutopaySummaryConnection>;
  listFloorplans?: Maybe<FloorplanConnection>;
  listLeaseDocumentTemplates?: Maybe<LeaseDocumentTemplateConnection>;
  listOwners?: Maybe<OwnerConnection>;
  listProperties?: Maybe<PropertyConnection>;
  listPropertyFacts?: Maybe<PropertyFactConnection>;
  listRecentFinalizedRequests?: Maybe<RequestConnection>;
  listRequestRecurrences?: Maybe<RequestRecurrenceConnection>;
  listRequests?: Maybe<RequestConnection>;
  listResidencies?: Maybe<ResidencyConnection>;
  listResidents?: Maybe<TenantConnection>;
  listSuppliers?: Maybe<SuppliersConnection>;
  listTenants?: Maybe<TenantConnection>;
  listUnits?: Maybe<UnitConnection>;
  logoKey?: Maybe<Scalars['String']['output']>;
  managers?: Maybe<Array<Maybe<Manager>>>;
  name: Scalars['String']['output'];
  operators?: Maybe<Array<Maybe<Operator>>>;
  owners?: Maybe<Array<Maybe<Owner>>>;
  pageOwners?: Maybe<OwnersPage>;
  pageProperties?: Maybe<PropertiesPage>;
  pageRequests?: Maybe<RequestsPage>;
  pageResidencies?: Maybe<ResidenciesPage>;
  pageSuppliers?: Maybe<SuppliersPage>;
  pageUnits?: Maybe<UnitsPage>;
  phone?: Maybe<Scalars['String']['output']>;
  /** @deprecated Not paginated– use `listProprties` to ensure all records are returned`. */
  properties?: Maybe<Array<Maybe<Property>>>;
  propertyFacts?: Maybe<Array<Maybe<PropertyFact>>>;
  /** @deprecated Use `listRequests` to ensure all records are returned`. */
  requests?: Maybe<Array<Maybe<Request>>>;
  requestsWithAction?: Maybe<Scalars['Int']['output']>;
  settings?: Maybe<Settings>;
  stats?: Maybe<AccountStats>;
  /** @deprecated Use `listSuppliers query`. */
  suppliers?: Maybe<Array<Maybe<Supplier>>>;
  teams?: Maybe<Array<Team>>;
  /** @deprecated Use `template query`. */
  templates?: Maybe<Array<Maybe<Template>>>;
  test?: Maybe<Scalars['Boolean']['output']>;
  testUnits?: Maybe<Scalars['Int']['output']>;
  tier: Scalars['Int']['output'];
  totalUnits?: Maybe<Scalars['Int']['output']>;
};

export type AccountListActiveApprovalsArgs = {
  filter?: InputMaybe<ApprovalFilterInput>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListActiveRequestsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListAllFinalizedRequestsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListAnnouncementsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListApprovalsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListAutopayEnrollmentsArgs = {
  filter?: InputMaybe<AutopayEnrollmentFilterInput>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListAutopayPropertiesArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListAutopaySummariesArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListFloorplansArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListLeaseDocumentTemplatesArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListOwnersArgs = {
  filter?: InputMaybe<OwnersFilter>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListPropertiesArgs = {
  filter?: InputMaybe<PropertiesFilter>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListPropertyFactsArgs = {
  filter?: InputMaybe<PropertyFactFilter>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListRecentFinalizedRequestsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListRequestRecurrencesArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListRequestsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListResidenciesArgs = {
  filter?: InputMaybe<ResidencyConnectionFilter>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListResidentsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListSuppliersArgs = {
  filter?: InputMaybe<SuppliersFilter>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListTenantsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListUnitsArgs = {
  filter?: InputMaybe<UnitFilter>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountOperatorsArgs = {
  filter?: InputMaybe<OperatorsFilterInput>;
};

export type AccountPageOwnersArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<OwnersFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OwnersSortInput>>;
};

export type AccountPagePropertiesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<PropertiesFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<PropertiesSortInput>;
};

export type AccountPageRequestsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<RequestsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<RequestsSortInput>>;
};

export type AccountPageResidenciesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<ResidenciesFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ResidenciesSortInput>>;
};

export type AccountPageSuppliersArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<SuppliersFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SuppliersSortInput>>;
};

export type AccountPageUnitsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<UnitsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UnitsSortInput>>;
};

export type AccountPropertiesArgs = {
  filter?: InputMaybe<PropertiesFilter>;
};

export type AccountPropertyFactsArgs = {
  filter?: InputMaybe<PropertyFactFilter>;
};

export type AccountTemplatesArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
};

export type AccountDetail = {
  __typename?: 'AccountDetail';
  accountDetails?: Maybe<AccountDetail>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  accountNumber4Digit?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  institutionNumber?: Maybe<Scalars['String']['output']>;
  transitNumber?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AccountEvent = {
  __typename?: 'AccountEvent';
  accountId: Scalars['ID']['output'];
  detail?: Maybe<Scalars['AWSJSON']['output']>;
  detailType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  source: EventSource;
  time: Scalars['String']['output'];
};

export type AccountEventInput = {
  event: Scalars['AWSJSON']['input'];
};

export type AccountFact = {
  __typename?: 'AccountFact';
  accountEnabled?: Maybe<Scalars['Boolean']['output']>;
  accountId?: Maybe<Scalars['ID']['output']>;
  accountName?: Maybe<Scalars['String']['output']>;
  commercialExpiring15Days?: Maybe<Scalars['Int']['output']>;
  commercialLeasedUnits?: Maybe<Scalars['Int']['output']>;
  commercialLeasesExpired?: Maybe<Scalars['Int']['output']>;
  commercialUnits?: Maybe<Scalars['Int']['output']>;
  commonAreas?: Maybe<Scalars['Int']['output']>;
  dateKey: Scalars['AWSDate']['output'];
  expired?: Maybe<Scalars['Int']['output']>;
  expiring15Days?: Maybe<Scalars['Int']['output']>;
  leasedUnits?: Maybe<Scalars['Int']['output']>;
  ownedTestUnits?: Maybe<Scalars['Int']['output']>;
  ownedUnits?: Maybe<Scalars['Int']['output']>;
  ownerUnits?: Maybe<Scalars['Int']['output']>;
  testAccount?: Maybe<Scalars['Boolean']['output']>;
  testUnits?: Maybe<Scalars['Int']['output']>;
  totalCommercialRent?: Maybe<Scalars['Float']['output']>;
  totalCommercialSqft?: Maybe<Scalars['Float']['output']>;
  totalRent?: Maybe<Scalars['Float']['output']>;
  totalResidentialRent?: Maybe<Scalars['Float']['output']>;
  totalResidentialSqft?: Maybe<Scalars['Float']['output']>;
  totalResidentialUnits?: Maybe<Scalars['Int']['output']>;
  totalSqft?: Maybe<Scalars['Float']['output']>;
  totalUnits?: Maybe<Scalars['Int']['output']>;
};

export type AccountFactConnection = {
  __typename?: 'AccountFactConnection';
  items?: Maybe<Array<Maybe<AccountFact>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type AccountPayload = {
  __typename?: 'AccountPayload';
  account?: Maybe<Account>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AccountStat = {
  __typename?: 'AccountStat';
  accountId: Scalars['ID']['output'];
  testUnits: Scalars['Int']['output'];
  totalUnits: Scalars['Int']['output'];
};

export type AccountStats = {
  __typename?: 'AccountStats';
  books_gl?: Maybe<Scalars['Int']['output']>;
  operator?: Maybe<Scalars['Int']['output']>;
  owner?: Maybe<Scalars['Int']['output']>;
  property?: Maybe<Scalars['Int']['output']>;
  residency?: Maybe<Scalars['Int']['output']>;
  tenant?: Maybe<Scalars['Int']['output']>;
  unit?: Maybe<Scalars['Int']['output']>;
};

export enum AccountType {
  Asset = 'asset',
  Equity = 'equity',
  Expense = 'expense',
  Liability = 'liability',
  Revenue = 'revenue',
}

export type AccrualsInput = {
  endDate: Scalars['AWSDate']['input'];
  principal: Scalars['Float']['input'];
  simple?: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['AWSDate']['input'];
};

export type ActivateAutopayInput = {
  agreeToTerms?: InputMaybe<Scalars['Boolean']['input']>;
  collectionTypes?: InputMaybe<Array<CollectionType>>;
  feeExpense: Scalars['String']['input'];
  linkedAccount: LinkedAccountInput;
  nsfFee?: InputMaybe<Scalars['Float']['input']>;
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ActivateBooksInput = {
  glAccounts: Array<ActivationGlAccountInput>;
};

export type ActivateBooksPayload = {
  __typename?: 'ActivateBooksPayload';
  books?: Maybe<Books>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ActivationGlAccountInput = {
  accountType: AccountType;
  category?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type AddAnnouncementReplyInput = {
  announcementId: Scalars['ID']['input'];
  managerReply?: InputMaybe<Scalars['Boolean']['input']>;
  replyId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type AddAutopayBankInput = {
  accountNumber: Scalars['String']['input'];
  institutionNumber: Scalars['String']['input'];
  name: Scalars['String']['input'];
  transitNumber: Scalars['String']['input'];
};

export type AddClearableInput = {
  autopayCollectionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due: Scalars['AWSDate']['input'];
  lines: Array<JournalEntryLineInput>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  payee: PayeeType;
  payeeId: Scalars['ID']['input'];
  posted: Scalars['AWSDate']['input'];
  presetId?: InputMaybe<Scalars['String']['input']>;
  recurrence?: InputMaybe<RecurrenceInput>;
  residencyId?: InputMaybe<Scalars['ID']['input']>;
  supplierAccount?: InputMaybe<Scalars['String']['input']>;
};

export type AddClearingEntryTaskInput = {
  batchId?: InputMaybe<Scalars['ID']['input']>;
  taskInput: ClearingEntryTaskInput;
};

export type AddJournalEntryInput = {
  lines: Array<JournalEntryLineInput>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  posted: Scalars['AWSDate']['input'];
  recurrence?: InputMaybe<RecurrenceInput>;
};

export type AddJournalsToReconciliationInput = {
  journals: Array<JournalKeyInput>;
  reconciliationId: Scalars['ID']['input'];
};

export type AddLinkedBankAccountsInput = {
  linkedAccount: LinkedAccountInput;
};

export type AddOwnerPropertyInput = {
  ownerId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type AddOwnerUnitInput = {
  ownerId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type AddPropertyAssignedKeyringsInput = {
  assignedKeyrings: Array<AssignedKeyringsInput>;
  id: Scalars['ID']['input'];
};

export type AddPropertyAssignedParkingsInput = {
  assignedParkings: Array<AssignedParkingsInput>;
  id: Scalars['ID']['input'];
};

export type AddPropertyAssignedStoragesInput = {
  assignedStorages: Array<AssignedStoragesInput>;
  id: Scalars['ID']['input'];
};

export type AddPropertyKeyringInput = {
  id: Scalars['ID']['input'];
  keyring: CreateKeyringInput;
};

export type AddPropertyParkingInput = {
  id: Scalars['ID']['input'];
  parking: CreateParkingInput;
};

export type AddPropertyStorageInput = {
  id: Scalars['ID']['input'];
  storage: CreateStorageInput;
};

export type AddPushTokenInput = {
  platform: PushPlatform;
  token: Scalars['String']['input'];
};

export type AddReconciliationConfigInput = {
  bankId?: InputMaybe<Scalars['ID']['input']>;
  glId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  propertyOwnerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type AddReconciliationInput = {
  id: Scalars['ID']['input'];
  posted: Scalars['AWSDate']['input'];
  reconciliationId: Scalars['ID']['input'];
};

export type AddResidencyEventInput = {
  category: ResidencyEventCategory;
  description?: InputMaybe<Scalars['String']['input']>;
  eventDateZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  noteText?: InputMaybe<Scalars['String']['input']>;
  noticeZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  residencyId: Scalars['ID']['input'];
  resolvedZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type AddResidencySecurityDepositStatusInput = {
  residencyId: Scalars['ID']['input'];
  status: SecurityDepositStatus;
};

export type AddScanApprovalTaskInput = {
  batchId?: InputMaybe<Scalars['ID']['input']>;
  taskInput: ScanApprovalTaskInput;
};

export type AddScanMatchingTaskInput = {
  batchId?: InputMaybe<Scalars['ID']['input']>;
  taskInput: ScanMatchingTaskInput;
};

export type AddSecurityDepositStatusInput = {
  leaseId: Scalars['ID']['input'];
  status: SecurityDepositStatus;
};

export type AdditionalProvisions = {
  __typename?: 'AdditionalProvisions';
  parking?: Maybe<Scalars['Boolean']['output']>;
  pets?: Maybe<Scalars['Int']['output']>;
  smoking?: Maybe<Scalars['Boolean']['output']>;
  storage?: Maybe<Scalars['Boolean']['output']>;
};

export type AdditionalProvisionsInput = {
  parking?: InputMaybe<Scalars['Boolean']['input']>;
  pets?: InputMaybe<Scalars['Int']['input']>;
  smoking?: InputMaybe<Scalars['Boolean']['input']>;
  storage?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AdditionalTenant = {
  __typename?: 'AdditionalTenant';
  email?: Maybe<Scalars['AWSEmail']['output']>;
  id: Scalars['ID']['output'];
  leaseholder?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payeeFrac?: Maybe<Fraction>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  postal?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  suite?: Maybe<Scalars['String']['output']>;
};

export type AddressHistory = {
  __typename?: 'AddressHistory';
  address: Address;
  email?: Maybe<Scalars['AWSEmail']['output']>;
  endDate?: Maybe<Scalars['AWSDate']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  rentalType?: Maybe<RentalType>;
  startDate: Scalars['AWSDate']['output'];
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  postal?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  suite?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type Agreement = {
  __typename?: 'Agreement';
  agreementType: AgreementType;
  cost?: Maybe<Scalars['Int']['output']>;
  entityId?: Maybe<Scalars['ID']['output']>;
  noteText?: Maybe<Scalars['String']['output']>;
};

export type AgreementInput = {
  agreementType: AgreementType;
  cost?: InputMaybe<Scalars['Int']['input']>;
  entityId?: InputMaybe<Scalars['ID']['input']>;
  noteText?: InputMaybe<Scalars['String']['input']>;
};

export type AgreementLease = {
  __typename?: 'AgreementLease';
  autoRenewSetup?: Maybe<AutoRenewSetup>;
  end?: Maybe<Scalars['String']['output']>;
  furnishings?: Maybe<Array<Scalars['String']['output']>>;
  gst?: Maybe<Scalars['Float']['output']>;
  incentives?: Maybe<Array<Maybe<LeaseIncentive>>>;
  moveInDate?: Maybe<Scalars['AWSDate']['output']>;
  moveOutDate?: Maybe<Scalars['AWSDate']['output']>;
  occupancy?: Maybe<UnitOccupancy>;
  preferredPaymentMethod?: Maybe<PaymentMethod>;
  propertyId?: Maybe<Scalars['ID']['output']>;
  rateSchedule?: Maybe<Array<Maybe<RateSchedule>>>;
  refundables?: Maybe<Array<Refundable>>;
  rent: Scalars['Float']['output'];
  rentRollFees?: Maybe<Array<Maybe<AgreementLeaseFees>>>;
  rrule?: Maybe<Scalars['String']['output']>;
  /** @deprecated use refundables instead */
  securityDeposit?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['String']['output']>;
  tenants?: Maybe<Array<Maybe<LeaseTenant>>>;
};

export type AgreementLeaseFees = {
  __typename?: 'AgreementLeaseFees';
  amount?: Maybe<Scalars['Float']['output']>;
  feeId: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum AgreementType {
  Absent = 'absent',
  Agreed = 'agreed',
  Disagreed = 'disagreed',
  Refused = 'refused',
  Skipped = 'skipped',
}

export type AgreementUrl = {
  __typename?: 'AgreementUrl';
  agreementUrl?: Maybe<LeaseAgreementUrl>;
  applicationId: Scalars['ID']['output'];
  profileId: Scalars['ID']['output'];
};

export type AmenitiesSetting = {
  __typename?: 'AmenitiesSetting';
  amenities?: Maybe<Array<Maybe<Amenity>>>;
};

export type AmenitiesSettingInput = {
  amenities?: InputMaybe<Array<InputMaybe<AmenityInput>>>;
  categories?: InputMaybe<Array<InputMaybe<AmenityCategoryInput>>>;
};

export type Amenity = {
  __typename?: 'Amenity';
  category?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AmenityCategory = {
  __typename?: 'AmenityCategory';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AmenityCategoryInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AmenityInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Announcement = {
  __typename?: 'Announcement';
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  author: Scalars['String']['output'];
  email?: Maybe<AnnouncementEmail>;
  fingerprints?: Maybe<Fingerprints>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  publishZ?: Maybe<Scalars['AWSDateTime']['output']>;
  replies?: Maybe<Array<Maybe<Reply>>>;
  scope: AnnouncementScope;
  status: Scalars['String']['output'];
  totalRecipients?: Maybe<Scalars['Int']['output']>;
  voice?: Maybe<AnnouncementVoice>;
};

export type AnnouncementConnection = {
  __typename?: 'AnnouncementConnection';
  items?: Maybe<Array<Maybe<Announcement>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type AnnouncementEmail = {
  __typename?: 'AnnouncementEmail';
  body?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type AnnouncementEmailInput = {
  body: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type AnnouncementPayload = {
  __typename?: 'AnnouncementPayload';
  announcement?: Maybe<Announcement>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AnnouncementScope = {
  __typename?: 'AnnouncementScope';
  omitIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  propertyIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  unitIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type AnnouncementScopeInput = {
  omitIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  unitIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type AnnouncementVoice = {
  __typename?: 'AnnouncementVoice';
  text?: Maybe<Scalars['String']['output']>;
};

export type AnnouncementVoiceInput = {
  text: Scalars['String']['input'];
};

export type Application = {
  __typename?: 'Application';
  accountId: Scalars['ID']['output'];
  additionalInformation?: Maybe<Scalars['String']['output']>;
  addressHistory?: Maybe<Array<AddressHistory>>;
  applicationId?: Maybe<Scalars['ID']['output']>;
  approvedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  campaignId: Scalars['ID']['output'];
  coApplications?: Maybe<Array<Maybe<CoApplication>>>;
  declinedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  dob?: Maybe<Scalars['AWSDate']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  emails?: Maybe<Array<Maybe<Scalars['AWSEmail']['output']>>>;
  emergencyContacts?: Maybe<Array<Maybe<EmergencyContact>>>;
  flag?: Maybe<ApplicationFlag>;
  household?: Maybe<Household>;
  id: Scalars['ID']['output'];
  leaseAgreement?: Maybe<LeaseAgreement>;
  leaseId?: Maybe<Scalars['ID']['output']>;
  listedUnit?: Maybe<ListedUnit>;
  listedUnitId: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  moveInDate?: Maybe<Scalars['AWSDate']['output']>;
  name: Scalars['String']['output'];
  needsAgreement?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  otherIncome?: Maybe<Array<Income>>;
  phone?: Maybe<Scalars['String']['output']>;
  profileId: Scalars['ID']['output'];
  residencyId?: Maybe<Scalars['ID']['output']>;
  screeningAgreement?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<ApplicationStatus>;
  submittedZ: Scalars['AWSDateTime']['output'];
  workHistory?: Maybe<Array<WorkHistory>>;
};

export enum ApplicationFlag {
  Dissatisfied = 'dissatisfied',
  Neutral = 'neutral',
  None = 'none',
  Phone = 'phone',
  Satisfied = 'satisfied',
  Search = 'search',
}

export type ApplicationPayload = {
  __typename?: 'ApplicationPayload';
  application?: Maybe<Application>;
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum ApplicationStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Submitted = 'SUBMITTED',
}

export type AppointmentChange = {
  __typename?: 'AppointmentChange';
  appointmentPreferences?: Maybe<Array<Maybe<Interval>>>;
  fingerprints?: Maybe<Fingerprints>;
  id: Scalars['ID']['output'];
  isRead?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Approval = ApprovalRecord & {
  __typename?: 'Approval';
  accountId: Scalars['String']['output'];
  approvedSub?: Maybe<Scalars['ID']['output']>;
  approvedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  approverRoles?: Maybe<Array<Maybe<ApproverRole>>>;
  charge: Charge;
  declinedSub?: Maybe<Scalars['ID']['output']>;
  declinedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['String']['output'];
  request: ChargeApprovalRequest;
  requestedName?: Maybe<Scalars['String']['output']>;
  requestedSub: Scalars['ID']['output'];
  requestedZ: Scalars['AWSDateTime']['output'];
  status: ApprovalStatus;
};

export type ApprovalConnection = {
  __typename?: 'ApprovalConnection';
  items?: Maybe<Array<Maybe<ApprovalRecord>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ApprovalFilterInput = {
  type?: InputMaybe<ApprovalType>;
};

export type ApprovalPayload = {
  __typename?: 'ApprovalPayload';
  approval?: Maybe<Approval>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ApprovalRecord = {
  accountId: Scalars['String']['output'];
  approvedSub?: Maybe<Scalars['ID']['output']>;
  approvedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  approverRoles?: Maybe<Array<Maybe<ApproverRole>>>;
  declinedSub?: Maybe<Scalars['ID']['output']>;
  declinedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['String']['output'];
  requestedName?: Maybe<Scalars['String']['output']>;
  requestedSub: Scalars['ID']['output'];
  requestedZ: Scalars['AWSDateTime']['output'];
  status: ApprovalStatus;
};

export enum ApprovalStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
}

export enum ApprovalType {
  Charge = 'CHARGE',
  Maintenance = 'MAINTENANCE',
  Payments = 'PAYMENTS',
  Scan = 'SCAN',
}

export enum ApproverRole {
  BooksAdmin = 'BOOKS_ADMIN',
  BooksAdvanced = 'BOOKS_ADVANCED',
  BooksUser = 'BOOKS_USER',
  Owner = 'OWNER',
}

export type Asset = {
  __typename?: 'Asset';
  accountId: Scalars['ID']['output'];
  assetType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  role?: Maybe<Scalars['String']['output']>;
  shared: Scalars['Boolean']['output'];
};

export type AssetFee = {
  __typename?: 'AssetFee';
  assetId: Scalars['ID']['output'];
  feeAmount?: Maybe<Scalars['Float']['output']>;
  feeRate?: Maybe<Scalars['Float']['output']>;
  flatFee?: Maybe<Scalars['Float']['output']>;
  nrtFeeAmount?: Maybe<Scalars['Float']['output']>;
  nrtFeeRate?: Maybe<Scalars['Float']['output']>;
  type: AssetType;
};

export type AssetShare = {
  __typename?: 'AssetShare';
  assets?: Maybe<Array<Asset>>;
  id: Scalars['ID']['output'];
  profile?: Maybe<Profile>;
  profileId: Scalars['ID']['output'];
};

export enum AssetType {
  Property = 'property',
  Unit = 'unit',
}

export type AssignKeyringInput = {
  keyringName: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type AssignKeyringPayload = {
  __typename?: 'AssignKeyringPayload';
  property?: Maybe<Property>;
  success?: Maybe<Scalars['Boolean']['output']>;
  unit?: Maybe<Unit>;
};

export type AssignParkingInput = {
  parkingName: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type AssignParkingPayload = {
  __typename?: 'AssignParkingPayload';
  property?: Maybe<Property>;
  success?: Maybe<Scalars['Boolean']['output']>;
  unit?: Maybe<Unit>;
};

export type AssignStorageInput = {
  propertyId: Scalars['ID']['input'];
  storageName: Scalars['String']['input'];
  unitId: Scalars['ID']['input'];
};

export type AssignStoragePayload = {
  __typename?: 'AssignStoragePayload';
  property?: Maybe<Property>;
  success?: Maybe<Scalars['Boolean']['output']>;
  unit?: Maybe<Unit>;
};

export type AssignedKeyringsInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['ID']['input'];
};

export type AssignedParkingsInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['ID']['input'];
};

export type AssignedStoragesInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['ID']['input'];
};

export type Attachment = {
  __typename?: 'Attachment';
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type AttachmentInput = {
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export enum AttemptReason {
  Absent = 'absent',
  Other = 'other',
  Refused = 'refused',
  Unreachable = 'unreachable',
}

export type AutoRenewSetup = {
  __typename?: 'AutoRenewSetup';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  termMonths?: Maybe<Scalars['Int']['output']>;
};

export type AutoRenewSetupInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  termMonths?: InputMaybe<Scalars['Int']['input']>;
};

export type Autopay = {
  __typename?: 'Autopay';
  accountId: Scalars['ID']['output'];
  activated?: Maybe<Scalars['Boolean']['output']>;
  activatedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  activationStatus?: Maybe<Scalars['String']['output']>;
  activationUrl?: Maybe<Scalars['String']['output']>;
  activationUrlExpireZ?: Maybe<Scalars['AWSDateTime']['output']>;
  agreeToTermsSub?: Maybe<Scalars['String']['output']>;
  agreeToTermsZ?: Maybe<Scalars['String']['output']>;
  banks?: Maybe<Array<Bank>>;
  collectionTypes?: Maybe<Array<CollectionType>>;
  deactivatedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  enableCollection?: Maybe<Scalars['Boolean']['output']>;
  enablePayments?: Maybe<Scalars['Boolean']['output']>;
  feeBankAccountId?: Maybe<Scalars['String']['output']>;
  feeExpense: Scalars['String']['output'];
  feeInvoiceChargeMethod?: Maybe<FeeInvoiceChargeMethod>;
  invitePayees?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use `banks` instead */
  linkedAccounts?: Maybe<Array<LinkedAccount>>;
  nsfFee?: Maybe<Scalars['Float']['output']>;
  pageOwnerAutopayPayees?: Maybe<AutopayPayeePage>;
  pageTenantAutopayPayees?: Maybe<AutopayPayeePage>;
  propraSupplier?: Maybe<Scalars['String']['output']>;
  undepositedFunds?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  vopay?: Maybe<Vopay>;
};

export type AutopayPageOwnerAutopayPayeesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<AutopayPayeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AutopayPayeeSortInput>>;
};

export type AutopayPageTenantAutopayPayeesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<AutopayPayeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AutopayPayeeSortInput>>;
};

export type AutopayEnrollment = {
  __typename?: 'AutopayEnrollment';
  archivedZ?: Maybe<Scalars['String']['output']>;
  collectionType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invitedZ?: Maybe<Scalars['String']['output']>;
  leaseId?: Maybe<Scalars['ID']['output']>;
  payeeId: Scalars['ID']['output'];
  payeeType: PayeeType;
  profileId?: Maybe<Scalars['ID']['output']>;
  propertyId: Scalars['ID']['output'];
  residencyId?: Maybe<Scalars['ID']['output']>;
  status: AutopayEnrollmentStatus;
  statusHistory?: Maybe<Array<AutopayEnrollmentStatusHistory>>;
  unitId: Scalars['ID']['output'];
};

export type AutopayEnrollmentConnection = {
  __typename?: 'AutopayEnrollmentConnection';
  items?: Maybe<Array<Maybe<AutopayEnrollment>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type AutopayEnrollmentFilterInput = {
  anyStatus?: InputMaybe<Array<InputMaybe<AutopayEnrollmentStatus>>>;
  type?: InputMaybe<AutopayEnrollmentType>;
};

export type AutopayEnrollmentPayload = {
  __typename?: 'AutopayEnrollmentPayload';
  enrollment?: Maybe<AutopayEnrollment>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum AutopayEnrollmentStatus {
  Activated = 'ACTIVATED',
  Archived = 'ARCHIVED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Invited = 'INVITED',
  Nsf = 'NSF',
  Suspended = 'SUSPENDED',
}

export type AutopayEnrollmentStatusHistory = {
  __typename?: 'AutopayEnrollmentStatusHistory';
  createdZ: Scalars['AWSDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  status: AutopayEnrollmentStatus;
};

export enum AutopayEnrollmentType {
  Fixed = 'FIXED',
  Variable = 'VARIABLE',
}

export type AutopayOnboardingUrlPayload = {
  __typename?: 'AutopayOnboardingUrlPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type AutopayPayee = {
  __typename?: 'AutopayPayee';
  autopayCollectionsEnabled?: Maybe<Scalars['Boolean']['output']>;
  autopayCollectionsEnabledZ?: Maybe<Scalars['AWSDateTime']['output']>;
  autopayStatus?: Maybe<Scalars['String']['output']>;
  autopayStatusZ?: Maybe<Scalars['AWSDateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  payeeType: PayeeType;
  propertyUnits?: Maybe<Array<AutopayPayeePropertyUnit>>;
};

export type AutopayPayeeEdge = RelayEdge & {
  __typename?: 'AutopayPayeeEdge';
  cursor: Scalars['ID']['output'];
  node: AutopayPayee;
};

export type AutopayPayeeFilter = {
  numberFilters?: InputMaybe<Array<IntFilterFieldInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
  stringFilters?: InputMaybe<Array<StringFilterFieldInput>>;
};

export type AutopayPayeePage = RelayPage & {
  __typename?: 'AutopayPayeePage';
  edges: Array<AutopayPayeeEdge>;
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AutopayPayeePropertyUnit = {
  __typename?: 'AutopayPayeePropertyUnit';
  buildingId?: Maybe<Scalars['ID']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  propertyId: Scalars['ID']['output'];
  propertyName?: Maybe<Scalars['String']['output']>;
  residencyId?: Maybe<Scalars['ID']['output']>;
  unitId: Scalars['ID']['output'];
  unitName?: Maybe<Scalars['String']['output']>;
};

export enum AutopayPayeeSortField {
  AutopayStatus = 'autopayStatus',
  AutopayStatusZ = 'autopayStatusZ',
  Name = 'name',
}

export type AutopayPayeeSortInput = {
  direction: SortDirection;
  field: AutopayPayeeSortField;
};

export type AutopayPropertiesInput = {
  propertyIds: Array<Scalars['ID']['input']>;
};

export type AutopayStatusHistory = {
  __typename?: 'AutopayStatusHistory';
  status: Scalars['String']['output'];
  z: Scalars['AWSDateTime']['output'];
};

export type AutopaySummaryConnection = {
  __typename?: 'AutopaySummaryConnection';
  items?: Maybe<Array<Maybe<SettlementSummary>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type BalanceInput = {
  from?: InputMaybe<Scalars['AWSDate']['input']>;
  ownerId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  to?: InputMaybe<Scalars['AWSDate']['input']>;
};

export enum BalanceType {
  Credit = 'credit',
  Debit = 'debit',
}

export type Bank = {
  __typename?: 'Bank';
  accountNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  institutionNumber: Scalars['String']['output'];
  name: Scalars['String']['output'];
  transitNumber: Scalars['String']['output'];
};

export type Batch = {
  __typename?: 'Batch';
  clearables?: Maybe<Array<Maybe<Clearable>>>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  itemCount?: Maybe<Scalars['Int']['output']>;
  journalEntries?: Maybe<Array<Maybe<JournalEntry>>>;
  listPrototypes?: Maybe<JePrototypeConnection>;
  name?: Maybe<Scalars['String']['output']>;
  posted?: Maybe<Scalars['String']['output']>;
  presetId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type BatchListPrototypesArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type BatchPrototypePayload = {
  __typename?: 'BatchPrototypePayload';
  error?: Maybe<Scalars['String']['output']>;
  prototype?: Maybe<JePrototype>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type BatchTask = {
  __typename?: 'BatchTask';
  id: Scalars['ID']['output'];
  taskProgress: BatchTaskProgress;
};

export type BatchTaskPayload = {
  __typename?: 'BatchTaskPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  task?: Maybe<BatchTask>;
};

export type BatchTaskProgress = {
  __typename?: 'BatchTaskProgress';
  done?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  processed: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BillingAccount = {
  __typename?: 'BillingAccount';
  address?: Maybe<Address>;
  domainId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BillingRate = {
  __typename?: 'BillingRate';
  category?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  glId?: Maybe<Scalars['ID']['output']>;
  labourRate?: Maybe<Scalars['Float']['output']>;
  materialsMarkup?: Maybe<Scalars['Float']['output']>;
  minBillableMinutes?: Maybe<Scalars['Int']['output']>;
  requireApproval?: Maybe<Scalars['Boolean']['output']>;
  trackRevenue?: Maybe<Scalars['Boolean']['output']>;
};

export type BillingRateInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  glId?: InputMaybe<Scalars['String']['input']>;
  labourRate: Scalars['Float']['input'];
  materialsMarkup: Scalars['Float']['input'];
  minBillableMinutes?: InputMaybe<Scalars['Int']['input']>;
  requireApproval?: InputMaybe<Scalars['Boolean']['input']>;
  trackRevenue?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Books = {
  __typename?: 'Books';
  accountId: Scalars['ID']['output'];
  activatedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  autopay?: Maybe<Autopay>;
  batchTask?: Maybe<BatchTask>;
  batches?: Maybe<Array<Maybe<Batch>>>;
  billableRevenueFeeIds?: Maybe<Array<Scalars['ID']['output']>>;
  billingRates?: Maybe<BillingRate>;
  bundledReports?: Maybe<Array<BundledReport>>;
  chargeMapping?: Maybe<Array<Maybe<ChargeMapping>>>;
  chequePlacement?: Maybe<ChequePlacement>;
  cheques?: Maybe<Array<Maybe<JournalEntry>>>;
  clearablesById: ClearablesPayload;
  collections?: Maybe<Collections>;
  countClearables?: Maybe<ClearableCount>;
  customBillingRates?: Maybe<Array<BillingRate>>;
  editableGlAccounts: Array<Scalars['ID']['output']>;
  feeMapping?: Maybe<Array<Maybe<FeeMapping>>>;
  getBudgets: Array<Budget>;
  glAccountMapping?: Maybe<Array<Maybe<GlMapping>>>;
  glAccounts: Array<GlAccount>;
  gstFiling?: Maybe<FilingFrequency>;
  gstNumber?: Maybe<Scalars['String']['output']>;
  includeDisabledProperties?: Maybe<Scalars['Boolean']['output']>;
  incomePayoutOptions?: Maybe<IncomePayoutOptions>;
  journalEntriesForJE?: Maybe<Array<Maybe<JournalEntry>>>;
  journalEntriesForPayeeRef?: Maybe<Array<JournalEntry>>;
  lastPrintedGlId?: Maybe<Scalars['ID']['output']>;
  listCheques?: Maybe<JournalEntryConnection>;
  listClearables?: Maybe<ClearableConnection>;
  listJournalEntriesForReconciliation: JournalForReconciliationPayload;
  listOwners?: Maybe<OwnerConnection>;
  listReconciliationConfigs?: Maybe<ReconciliationConfigConnection>;
  listReconciliations?: Maybe<ReconciliationConnection>;
  listSubAccounts?: Maybe<SubAccountConnection>;
  managementFeeBasis?: Maybe<ManagementFeeBasis>;
  /** @deprecated Use `printedCheques` instead */
  nextCheque?: Maybe<Scalars['Int']['output']>;
  nextInvoice?: Maybe<Scalars['Int']['output']>;
  nextJournal?: Maybe<Scalars['Int']['output']>;
  pageClearables?: Maybe<ClearablesPage>;
  pageDraftedJournalEntries: DraftedJournalEntriesPage;
  pageJournalEntriesForReconciliation: JournalEntriesForRecPage;
  payeeClearables?: Maybe<ClearableConnection>;
  presets?: Maybe<Array<Maybe<Preset>>>;
  previewCheques: ChequePayload;
  printedCheques?: Maybe<Array<BooksPrintedCheque>>;
  /** @deprecated Use `printedCheques` instead */
  printedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  receiverGeneralId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use `listReconciliationConfigs` instead */
  reconciliationConfigs?: Maybe<Array<Maybe<ReconciliationConfig>>>;
  /** @deprecated Use `listReconciliations` instead */
  reconciliations?: Maybe<Array<Maybe<Reconciliation>>>;
  recurrences?: Maybe<Array<Maybe<Recurrence>>>;
  renderReport: BooksReportPayload;
  report: BooksReportPayload;
  reportBundles?: Maybe<Array<ReportBundle>>;
  searchRelatedUnits?: Maybe<SearchUnitPayload>;
  trackManagementFeeRevenue?: Maybe<Scalars['Boolean']['output']>;
  unitClearables?: Maybe<ClearableConnection>;
  yearEndMonth?: Maybe<Scalars['Int']['output']>;
};

export type BooksBatchTaskArgs = {
  id: Scalars['ID']['input'];
};

export type BooksChequesArgs = {
  glId: Scalars['ID']['input'];
  printedZ?: InputMaybe<Scalars['String']['input']>;
};

export type BooksClearablesByIdArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type BooksCollectionsArgs = {
  propertyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BooksCountClearablesArgs = {
  filter?: InputMaybe<ClearablesFilterInput>;
};

export type BooksGetBudgetsArgs = {
  input: GetBudgetsInput;
};

export type BooksJournalEntriesForJeArgs = {
  jeId: Scalars['ID']['input'];
};

export type BooksJournalEntriesForPayeeRefArgs = {
  filter: PayeeRefFilter;
};

export type BooksListChequesArgs = {
  glId: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
  printedZ: Scalars['String']['input'];
};

export type BooksListClearablesArgs = {
  filter?: InputMaybe<ClearablesFilterInput>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type BooksListJournalEntriesForReconciliationArgs = {
  filter: JournalRecFilter;
};

export type BooksListOwnersArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type BooksListReconciliationConfigsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type BooksListReconciliationsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type BooksListSubAccountsArgs = {
  filter?: InputMaybe<SubAccountFilterInput>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type BooksPageClearablesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<ClearablesFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ClearablesSortInput>>;
};

export type BooksPageDraftedJournalEntriesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter: DraftedJournalFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type BooksPageJournalEntriesForReconciliationArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter: ReconciliationJournalFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<JournalEntriesSortInput>>;
};

export type BooksPayeeClearablesArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
  payeeId: Scalars['ID']['input'];
};

export type BooksPreviewChequesArgs = {
  glId: Scalars['ID']['input'];
};

export type BooksReconciliationsArgs = {
  filter?: InputMaybe<ReconciliationFilter>;
};

export type BooksRenderReportArgs = {
  input: BooksReportInput;
};

export type BooksReportArgs = {
  input: BooksReportInput;
};

export type BooksSearchRelatedUnitsArgs = {
  filter?: InputMaybe<SearchUnitsFilterInput>;
};

export type BooksUnitClearablesArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['ID']['input'];
};

export type BooksActivatedPayload = {
  __typename?: 'BooksActivatedPayload';
  anyActivated?: Maybe<Scalars['Boolean']['output']>;
};

export type BooksBatchPayload = {
  __typename?: 'BooksBatchPayload';
  batch?: Maybe<Batch>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type BooksFieldInput = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum BooksFieldType {
  AutopaySwitch = 'autopaySwitch',
  BankAccount = 'bankAccount',
  Check = 'check',
  Date = 'date',
  DateRange = 'dateRange',
  DueOffset = 'dueOffset',
  GlAccount = 'glAccount',
  Hidden = 'hidden',
  ManualProperties = 'manualProperties',
  Money = 'money',
  Owner = 'owner',
  OwnerProperty = 'ownerProperty',
  Payee = 'payee',
  PaymentMethod = 'paymentMethod',
  Period = 'period',
  Properties = 'properties',
  Property = 'property',
  ReconciliationGroups = 'reconciliationGroups',
  Section = 'section',
  SelectOne = 'selectOne',
  SubAccountHolder = 'subAccountHolder',
  Text = 'text',
  Timeframe = 'timeframe',
  Unit = 'unit',
  UnitPayeeType = 'unitPayeeType',
}

export type BooksPayload = {
  __typename?: 'BooksPayload';
  books?: Maybe<Books>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type BooksPrintedCheque = {
  __typename?: 'BooksPrintedCheque';
  chequePlacement?: Maybe<ChequePlacement>;
  glId: Scalars['ID']['output'];
  nextCheque: Scalars['Int']['output'];
  printedZ: Scalars['AWSDateTime']['output'];
};

export type BooksReport = {
  __typename?: 'BooksReport';
  grandTotals?: Maybe<Array<Maybe<GrandTotal>>>;
  id: Scalars['ID']['output'];
  options?: Maybe<BooksReportOptions>;
  ownerId?: Maybe<Scalars['String']['output']>;
  propertyId?: Maybe<Scalars['String']['output']>;
  range?: Maybe<Scalars['String']['output']>;
  subtitle1?: Maybe<Scalars['String']['output']>;
  subtitle2?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type BooksReportExtraColumn = {
  __typename?: 'BooksReportExtraColumn';
  columnHeader?: Maybe<Scalars['String']['output']>;
  columnType?: Maybe<ExtraColumnType>;
  flex?: Maybe<Scalars['String']['output']>;
  keepNonZero?: Maybe<Scalars['Boolean']['output']>;
  maxWidth?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type BooksReportFooter = {
  __typename?: 'BooksReportFooter';
  label?: Maybe<Scalars['String']['output']>;
};

export type BooksReportHeader = {
  __typename?: 'BooksReportHeader';
  label?: Maybe<Scalars['String']['output']>;
};

export type BooksReportInput = {
  parameters?: InputMaybe<Scalars['AWSJSON']['input']>;
  presetId: Scalars['ID']['input'];
};

export type BooksReportOptions = {
  __typename?: 'BooksReportOptions';
  hideAmountColumn?: Maybe<Scalars['Boolean']['output']>;
  showZeroAmounts?: Maybe<Scalars['Boolean']['output']>;
};

export type BooksReportPayload = {
  __typename?: 'BooksReportPayload';
  docDefinition?: Maybe<Scalars['AWSJSON']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  report?: Maybe<BooksReport>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type BooksReportRendered = {
  __typename?: 'BooksReportRendered';
  action?: Maybe<RenderedAction>;
  amount?: Maybe<Scalars['Float']['output']>;
  balanceType?: Maybe<BalanceType>;
  columnHeader?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['AWSDate']['output']>;
  extraColumns?: Maybe<Array<Maybe<BooksReportExtraColumn>>>;
  footer?: Maybe<BooksReportFooter>;
  header?: Maybe<BooksReportHeader>;
  label?: Maybe<Scalars['String']['output']>;
  lines?: Maybe<Array<Maybe<BooksReportRendered>>>;
  maxWidth?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export enum BooksReportVersion {
  V2 = 'v2',
}

export type Budget = {
  __typename?: 'Budget';
  amount: Scalars['Float']['output'];
  glId: Scalars['ID']['output'];
  ownerId: Scalars['ID']['output'];
  period: Scalars['String']['output'];
  propertyId: Scalars['ID']['output'];
};

export type BudgetsPayload = {
  __typename?: 'BudgetsPayload';
  budgets?: Maybe<Array<Maybe<Budget>>>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Building = {
  __typename?: 'Building';
  accountId?: Maybe<Scalars['ID']['output']>;
  address?: Maybe<Address>;
  amenitiesSetting?: Maybe<AmenitiesSetting>;
  documents?: Maybe<Array<Maybe<Document>>>;
  features?: Maybe<Array<Maybe<Feature>>>;
  fingerprints?: Maybe<Fingerprints>;
  floorplanIds?: Maybe<Array<Scalars['ID']['output']>>;
  floorplans?: Maybe<Array<Floorplan>>;
  id: Scalars['ID']['output'];
  imageKey?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  insurancePolicy?: Maybe<InsurancePolicy>;
  insurancePolicyHistory?: Maybe<Array<Maybe<InsurancePolicy>>>;
  key?: Maybe<Scalars['String']['output']>;
  legalDescription?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Array<Maybe<Note>>>;
  ownerId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  specs?: Maybe<Array<Maybe<PropertySpecs>>>;
  timezone: Scalars['String']['output'];
  types?: Maybe<Array<Maybe<PropertyType>>>;
};

export type BuildingFilter = {
  buildingId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type BuildingInsuranceInput = {
  buildingId: Scalars['ID']['input'];
  insurancePolicy: InsurancePolicyInput;
  propertyId: Scalars['ID']['input'];
};

export type BulkUnitInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  bathrooms: Scalars['Float']['input'];
  bedrooms: Scalars['Float']['input'];
  condoFee?: InputMaybe<Scalars['Float']['input']>;
  condoFeeGst?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  occupancy?: InputMaybe<UnitOccupancy>;
  paymentDetails?: InputMaybe<PaymentDetailsInput>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  sqft: Scalars['Float']['input'];
  unitFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type BundledReport = {
  __typename?: 'BundledReport';
  bundleId?: Maybe<Scalars['ID']['output']>;
  completedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  flag?: Maybe<BundledReportFlag>;
  id: Scalars['ID']['output'];
  inputBindings: Scalars['AWSJSON']['output'];
  isShared?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  managerId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  startedZ: Scalars['AWSDateTime']['output'];
  status: Scalars['String']['output'];
  ttl?: Maybe<Scalars['Int']['output']>;
  xlsx?: Maybe<Scalars['Boolean']['output']>;
};

export enum BundledReportFlag {
  Done = 'done',
  Error = 'error',
  None = 'none',
  Warning = 'warning',
}

export type BundledReportPayload = {
  __typename?: 'BundledReportPayload';
  bundledReport?: Maybe<BundledReport>;
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Campaign = {
  __typename?: 'Campaign';
  accountId: Scalars['ID']['output'];
  applications?: Maybe<Array<Application>>;
  fingerprints?: Maybe<Fingerprints>;
  history?: Maybe<Array<StatusHistoryEvent>>;
  id: Scalars['ID']['output'];
  link?: Maybe<Scalars['String']['output']>;
  listedUnits?: Maybe<Array<ListedUnit>>;
  name?: Maybe<Scalars['String']['output']>;
  property?: Maybe<Property>;
  propertyId: Scalars['ID']['output'];
  status: CampaignStatus;
  systemCreated?: Maybe<Scalars['Boolean']['output']>;
};

export type CampaignApplicationDocRule = {
  __typename?: 'CampaignApplicationDocRule';
  docTypes: Array<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
};

export type CampaignApplicationDocRuleInput = {
  docTypes: Array<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
};

export type CampaignApplicationRule = {
  __typename?: 'CampaignApplicationRule';
  requiredDocs?: Maybe<Array<CampaignApplicationDocRule>>;
};

export type CampaignApplicationRuleInput = {
  requiredDocs?: InputMaybe<Array<CampaignApplicationDocRuleInput>>;
};

export type CampaignConnection = {
  __typename?: 'CampaignConnection';
  items?: Maybe<Array<Maybe<Campaign>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type CampaignPayload = {
  __typename?: 'CampaignPayload';
  campaign?: Maybe<Campaign>;
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum CampaignStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Inactive = 'INACTIVE',
}

export type Charge = {
  __typename?: 'Charge';
  default?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  limit?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
};

export type ChargeApprovalRequest = {
  __typename?: 'ChargeApprovalRequest';
  amount: Scalars['Float']['output'];
  applyGst?: Maybe<Scalars['Boolean']['output']>;
  applyPst?: Maybe<Scalars['Boolean']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  chargeId: Scalars['ID']['output'];
  date: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  notifyPayee?: Maybe<Scalars['Boolean']['output']>;
  ownerId: Scalars['ID']['output'];
  ownerName?: Maybe<Scalars['String']['output']>;
  payee: PayeeType;
  payeeId: Scalars['ID']['output'];
  payeeName?: Maybe<Scalars['String']['output']>;
  propertyId: Scalars['ID']['output'];
  propertyKey?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  unitId?: Maybe<Scalars['ID']['output']>;
  unitName?: Maybe<Scalars['String']['output']>;
};

export type ChargeMapping = {
  __typename?: 'ChargeMapping';
  chargeId: Scalars['ID']['output'];
  glId: Scalars['ID']['output'];
};

export type ChargeMappingInput = {
  chargeId: Scalars['ID']['input'];
  glId: Scalars['ID']['input'];
};

export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  completedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  lastUpdatedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['Int']['output']>;
};

export type ChecklistItemInput = {
  completedZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type ChequePayload = {
  __typename?: 'ChequePayload';
  books?: Maybe<Books>;
  error?: Maybe<Scalars['String']['output']>;
  glId?: Maybe<Scalars['ID']['output']>;
  journalEntries?: Maybe<Array<Maybe<JournalEntry>>>;
  success: Scalars['Boolean']['output'];
};

export enum ChequePlacement {
  Bottom = 'bottom',
  Middle = 'middle',
  Top = 'top',
}

export type Claim = {
  __typename?: 'Claim';
  amount: Scalars['Float']['output'];
  date: Scalars['AWSDate']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fingerprints?: Maybe<Fingerprints>;
  id: Scalars['ID']['output'];
  status?: Maybe<ClaimStatus>;
  type?: Maybe<ClaimType>;
};

export type ClaimInput = {
  amount: Scalars['Float']['input'];
  date: Scalars['AWSDate']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ClaimStatus>;
  type?: InputMaybe<ClaimType>;
};

export type ClaimPayload = {
  __typename?: 'ClaimPayload';
  error?: Maybe<Scalars['String']['output']>;
  property?: Maybe<Property>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum ClaimStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export enum ClaimType {
  AllPerils = 'ALL_PERILS',
  BuilderDeffect = 'BUILDER_DEFFECT',
  Earthquake = 'EARTHQUAKE',
  FireDamage = 'FIRE_DAMAGE',
  Flood = 'FLOOD',
  Robbery = 'ROBBERY',
  WaterDamage = 'WATER_DAMAGE',
}

export type Clearable = {
  __typename?: 'Clearable';
  amount: Scalars['Float']['output'];
  approverName?: Maybe<Scalars['String']['output']>;
  autopayCollectionsEnabled?: Maybe<Scalars['Boolean']['output']>;
  autopaySinglePosted?: Maybe<Scalars['AWSDate']['output']>;
  autopaySingleProfileId?: Maybe<Scalars['ID']['output']>;
  balanceType: BalanceType;
  batchId?: Maybe<Scalars['String']['output']>;
  buildingKey?: Maybe<Scalars['String']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  chargeback?: Maybe<Scalars['Boolean']['output']>;
  cleared?: Maybe<Scalars['AWSDate']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  due: Scalars['AWSDate']['output'];
  enrollment?: Maybe<AutopayEnrollment>;
  enrollmentId?: Maybe<Scalars['ID']['output']>;
  fixedEnrollmentId?: Maybe<Scalars['ID']['output']>;
  glAccount: GlAccount;
  glId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  journalEntries?: Maybe<Array<JournalEntry>>;
  notes?: Maybe<Array<Maybe<Note>>>;
  outstanding: Scalars['Float']['output'];
  ownerId: Scalars['ID']['output'];
  ownerName?: Maybe<Scalars['String']['output']>;
  payee: PayeeType;
  payeeAutopayClientId?: Maybe<Scalars['ID']['output']>;
  payeeAutopayCollectionsEnabled?: Maybe<Scalars['Boolean']['output']>;
  payeeAutopayStatus?: Maybe<Scalars['String']['output']>;
  payeeId: Scalars['ID']['output'];
  payeeName?: Maybe<Scalars['String']['output']>;
  paymentsStatus?: Maybe<PaymentStatus>;
  posted: Scalars['AWSDate']['output'];
  propertyId: Scalars['ID']['output'];
  propertyKey?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  ref?: Maybe<Scalars['String']['output']>;
  refund?: Maybe<Scalars['Boolean']['output']>;
  sourceJournalEntry: JournalEntry;
  sourceJournalId: Scalars['String']['output'];
  supplierAccount?: Maybe<Scalars['String']['output']>;
  unitId?: Maybe<Scalars['ID']['output']>;
  unitName?: Maybe<Scalars['String']['output']>;
};

export type ClearableConnection = {
  __typename?: 'ClearableConnection';
  items?: Maybe<Array<Maybe<Clearable>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ClearableCount = {
  __typename?: 'ClearableCount';
  amountTotal: Scalars['Float']['output'];
  outstandingTotal: Scalars['Float']['output'];
  rowCount: Scalars['Int']['output'];
};

export type ClearableEdge = RelayEdge & {
  __typename?: 'ClearableEdge';
  cursor: Scalars['ID']['output'];
  node: Clearable;
};

export type ClearablePayload = {
  __typename?: 'ClearablePayload';
  books?: Maybe<Books>;
  clearable?: Maybe<Clearable>;
  error?: Maybe<Scalars['String']['output']>;
  recurrence?: Maybe<Recurrence>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ClearablesFilterInput = {
  autopayStatus?: InputMaybe<Scalars['String']['input']>;
  balanceType?: InputMaybe<BalanceType>;
  clearableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  cleared?: InputMaybe<Scalars['Boolean']['input']>;
  due?: InputMaybe<Scalars['AWSDate']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  ownerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  payeeId?: InputMaybe<Scalars['ID']['input']>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  range?: InputMaybe<PostedRangeInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ClearablesPage = RelayPage & {
  __typename?: 'ClearablesPage';
  creditAmount?: Maybe<Scalars['Float']['output']>;
  creditOutstanding?: Maybe<Scalars['Float']['output']>;
  debitAmount?: Maybe<Scalars['Float']['output']>;
  debitOutstanding?: Maybe<Scalars['Float']['output']>;
  edges: Array<ClearableEdge>;
  pageInfo: RelayPageInfo;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  totalCount: Scalars['Int']['output'];
  totalOutstanding?: Maybe<Scalars['Float']['output']>;
};

export type ClearablesPayload = {
  __typename?: 'ClearablesPayload';
  clearables?: Maybe<Array<Clearable>>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum ClearablesSortField {
  Amount = 'amount',
  BuildingKey = 'buildingKey',
  BuildingName = 'buildingName',
  Cleared = 'cleared',
  Description = 'description',
  Due = 'due',
  Outstanding = 'outstanding',
  OwnerName = 'ownerName',
  PayeeName = 'payeeName',
  Posted = 'posted',
  PropertyKey = 'propertyKey',
  PropertyName = 'propertyName',
  Ref = 'ref',
  UnitName = 'unitName',
}

export type ClearablesSortInput = {
  direction: SortDirection;
  field: ClearablesSortField;
};

export type ClearingEntryTaskInput = {
  label: Scalars['String']['input'];
  newClearableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  newSinceZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedClearableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedSinceZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CoApplication = {
  __typename?: 'CoApplication';
  accountId: Scalars['ID']['output'];
  additionalInformation?: Maybe<Scalars['String']['output']>;
  addressHistory?: Maybe<Array<AddressHistory>>;
  applicationId?: Maybe<Scalars['ID']['output']>;
  campaignId: Scalars['ID']['output'];
  dob?: Maybe<Scalars['AWSDate']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  emails?: Maybe<Array<Maybe<Scalars['AWSEmail']['output']>>>;
  emergencyContacts?: Maybe<Array<Maybe<EmergencyContact>>>;
  household?: Maybe<Household>;
  id: Scalars['ID']['output'];
  leaseAgreement?: Maybe<CoApplicationLeaseAgreement>;
  listedUnitId: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  otherIncome?: Maybe<Array<Income>>;
  phone?: Maybe<Scalars['String']['output']>;
  profileId: Scalars['ID']['output'];
  screeningAgreement?: Maybe<Scalars['Boolean']['output']>;
  submittedZ: Scalars['AWSDateTime']['output'];
  workHistory?: Maybe<Array<WorkHistory>>;
};

export type CoApplicationLeaseAgreement = {
  __typename?: 'CoApplicationLeaseAgreement';
  id: Scalars['ID']['output'];
  status?: Maybe<LeaseAgreementStatus>;
};

export enum CollectionType {
  CommercialRent = 'commercialRent',
  CondoFee = 'condoFee',
  Rent = 'rent',
}

export type Collections = {
  __typename?: 'Collections';
  current: Scalars['Float']['output'];
  delinquent: Scalars['Float']['output'];
  due: Scalars['Float']['output'];
  late: Scalars['Float']['output'];
  overdue: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type CombinedBalanceInput = {
  balanceDate?: InputMaybe<Scalars['AWSDate']['input']>;
  ownerIds: Array<Scalars['ID']['input']>;
  propertyIds: Array<Scalars['ID']['input']>;
};

export type CombinedSubAccount = {
  __typename?: 'CombinedSubAccount';
  balance: Scalars['Float']['output'];
  journalEntries?: Maybe<JournalEntryConnection>;
};

export type CondoUnitFee = {
  __typename?: 'CondoUnitFee';
  amount?: Maybe<Scalars['Float']['output']>;
  feeId: Scalars['ID']['output'];
};

export type CondoUnitFeesInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  feeId: Scalars['ID']['input'];
};

export type Contact = {
  __typename?: 'Contact';
  accountId: Scalars['ID']['output'];
  address?: Maybe<Address>;
  emails?: Maybe<Array<Maybe<Scalars['AWSEmail']['output']>>>;
  entityId: Scalars['ID']['output'];
  fingerprints?: Maybe<Fingerprints>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phones?: Maybe<Array<Maybe<Scalars['AWSPhone']['output']>>>;
  type?: Maybe<ContactType>;
};

export type ContactPayload = {
  __typename?: 'ContactPayload';
  contact?: Maybe<Contact>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum ContactRelationship {
  Child = 'Child',
  Friend = 'Friend',
  Other = 'Other',
  Parent = 'Parent',
  Relative = 'Relative',
  Spouse = 'Spouse',
}

export enum ContactType {
  Administrator = 'ADMINISTRATOR',
  JointOwner = 'JOINT_OWNER',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
}

export type ContractorBill = {
  __typename?: 'ContractorBill';
  date: Scalars['AWSDate']['output'];
  due?: Maybe<Scalars['AWSDate']['output']>;
  gstAmount?: Maybe<Scalars['Float']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  ref?: Maybe<Scalars['String']['output']>;
  totalAmount: Scalars['Float']['output'];
};

export enum CorrespondenceMethod {
  Electronic = 'electronic',
  Mail = 'mail',
}

export type CostLine = {
  __typename?: 'CostLine';
  amount: Scalars['Float']['output'];
  date?: Maybe<Scalars['AWSDate']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  markup?: Maybe<Scalars['Float']['output']>;
  receiptImages?: Maybe<Array<Maybe<Image>>>;
  receiptKey?: Maybe<Scalars['String']['output']>;
  total: Scalars['Float']['output'];
  visitNumber?: Maybe<Scalars['Int']['output']>;
};

export enum Country {
  Ca = 'CA',
}

export type CreateAccountInput = {
  address?: InputMaybe<AddressInput>;
  billingEmail?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone: Scalars['AWSPhone']['input'];
  tier: Scalars['Int']['input'];
};

export type CreateAnnouncementInput = {
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  author: Scalars['String']['input'];
  email?: InputMaybe<AnnouncementEmailInput>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  scope?: InputMaybe<AnnouncementScopeInput>;
  voice?: InputMaybe<AnnouncementVoiceInput>;
};

export type CreateApplicationNoteInput = {
  applicationId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type CreateApplicationNotePayload = {
  __typename?: 'CreateApplicationNotePayload';
  application?: Maybe<Application>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateAutopayEmbedUrlPayload = {
  __typename?: 'CreateAutopayEmbedUrlPayload';
  error?: Maybe<Scalars['String']['output']>;
  iframeKey?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type CreateBuildingFloorplanInput = {
  buildingId: Scalars['ID']['input'];
  floorplan: FloorplanInput;
  propertyId: Scalars['ID']['input'];
  unitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CreateBuildingInput = {
  address?: InputMaybe<AddressInput>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  legalDescription?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['ID']['input'];
  skipUnitSearch?: InputMaybe<Scalars['Boolean']['input']>;
  timezone: Scalars['String']['input'];
  types?: InputMaybe<Array<InputMaybe<PropertyType>>>;
};

export type CreateBuildingInsuranceClaimInput = {
  buildingId: Scalars['ID']['input'];
  claim: ClaimInput;
  insurancePolicyId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type CreateBuildingInsuranceHistoryInput = {
  buildingId: Scalars['ID']['input'];
  insurancePolicy: InsurancePolicyInput;
  propertyId: Scalars['ID']['input'];
};

export type CreateBuildingNoteInput = {
  buildingId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBuildingPayload = {
  __typename?: 'CreateBuildingPayload';
  building?: Maybe<Building>;
  error?: Maybe<Scalars['String']['output']>;
  property?: Maybe<Property>;
  success?: Maybe<Scalars['Boolean']['output']>;
  unitNames?: Maybe<UnitNames>;
};

export type CreateCampaignInput = {
  listedUnits?: InputMaybe<Array<ListedUnitInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['ID']['input'];
  status: CampaignStatus;
};

export type CreateCampaignLinkInput = {
  id: Scalars['ID']['input'];
};

export type CreateCampaignPayload = {
  __typename?: 'CreateCampaignPayload';
  campaign?: Maybe<Campaign>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateChargeApprovalInput = {
  amount: Scalars['Float']['input'];
  applyGst?: InputMaybe<Scalars['Boolean']['input']>;
  applyPst?: InputMaybe<Scalars['Boolean']['input']>;
  chargeId: Scalars['ID']['input'];
  date: Scalars['AWSDate']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  notifyPayee?: InputMaybe<Scalars['Boolean']['input']>;
  ownerId: Scalars['ID']['input'];
  payee: PayeeType;
  payeeId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  unitId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateChargeInput = {
  default: Scalars['Float']['input'];
  glId?: InputMaybe<Scalars['ID']['input']>;
  limit: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type CreateContactInput = {
  address?: InputMaybe<AddressInput>;
  emails?: InputMaybe<Array<InputMaybe<Scalars['AWSEmail']['input']>>>;
  entityId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<InputMaybe<Scalars['AWSPhone']['input']>>>;
  type?: InputMaybe<ContactType>;
};

export type CreateDraftJeBatchInput = {
  parameters?: InputMaybe<Scalars['AWSJSON']['input']>;
  posted: Scalars['AWSDate']['input'];
  presetId: Scalars['ID']['input'];
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CreateDraftJeBatchPayload = {
  __typename?: 'CreateDraftJEBatchPayload';
  batch?: Maybe<JeDraftBatch>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateFeeInput = {
  name: Scalars['String']['input'];
};

export type CreateFloorplanPayload = {
  __typename?: 'CreateFloorplanPayload';
  error?: Maybe<Scalars['String']['output']>;
  floorplan?: Maybe<Floorplan>;
  property?: Maybe<Property>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateGlAccountInput = {
  accountType: AccountType;
  category?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateGlAccountPayload = {
  __typename?: 'CreateGLAccountPayload';
  error?: Maybe<Scalars['String']['output']>;
  glAccount?: Maybe<GlAccount>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateImageNoteInput = {
  imageKey: Scalars['String']['input'];
  requestId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type CreateInterestRateInput = {
  province: Scalars['String']['input'];
  rate: Scalars['Float']['input'];
  startDate: Scalars['AWSDate']['input'];
};

export type CreateJournalEntryNoteInput = {
  journalEntryId: Scalars['ID']['input'];
  posted: Scalars['String']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateKeyringInput = {
  name: Scalars['String']['input'];
};

export type CreateLeaseAgreementInput = {
  additionalClause?: InputMaybe<Array<Scalars['String']['input']>>;
  additionalProvisions?: InputMaybe<AdditionalProvisionsInput>;
  applicationId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
  houseRules?: InputMaybe<Array<Scalars['String']['input']>>;
  lease: LeaseInput;
  unitId: Scalars['ID']['input'];
  utilities?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateLeaseDocumentTemplateInput = {
  leaseType: LeaseDocumentLeaseType;
  name: Scalars['String']['input'];
  propertyIds: Array<Scalars['ID']['input']>;
  schemas?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type CreateLeaseInput = {
  additionalProvisions?: InputMaybe<AdditionalProvisionsInput>;
  applicationId?: InputMaybe<Scalars['String']['input']>;
  autoRenewSetup?: InputMaybe<AutoRenewSetupInput>;
  campaignId?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  gst?: InputMaybe<Scalars['Float']['input']>;
  incentives?: InputMaybe<Array<InputMaybe<LeaseIncentiveInput>>>;
  occupancy: UnitOccupancy;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  previousLeaseEnd?: InputMaybe<Scalars['String']['input']>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  rent: Scalars['Float']['input'];
  rentRollFees?: InputMaybe<Array<InputMaybe<RentRollFeesInput>>>;
  securityDeposit?: InputMaybe<Scalars['Float']['input']>;
  start: Scalars['String']['input'];
  tenants: Array<LeaseTenantInput>;
  unitOwnerId?: InputMaybe<Scalars['ID']['input']>;
  utilities?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateLeaseInspectionInput = {
  inspection: LeaseInspectionInput;
  residencyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type CreateLeaseNoteInput = {
  leaseId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLeaseTenantInput = {
  leaseId: Scalars['ID']['input'];
  tenant: LeaseTenantInput;
};

export type CreateMaintenanceApprovalInput = {
  amount: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  ownerId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
  unitId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateManagerInput = {
  email: Scalars['AWSEmail']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['AWSPhone']['input']>;
};

export type CreateOperatorInput = {
  address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  expenseGlId?: InputMaybe<Scalars['String']['input']>;
  expiryCOI?: InputMaybe<Scalars['String']['input']>;
  hasCOI?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  operatorType?: InputMaybe<OperatorType>;
  phone?: InputMaybe<Scalars['String']['input']>;
  skills?: InputMaybe<Array<OperatorSkill>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOwnerInput = {
  address?: InputMaybe<AddressInput>;
  autopayAccount?: InputMaybe<Scalars['String']['input']>;
  autopayInstitution?: InputMaybe<Scalars['String']['input']>;
  autopayTransit?: InputMaybe<Scalars['String']['input']>;
  booksSinceZ?: InputMaybe<Scalars['String']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  correspondenceMethod?: InputMaybe<CorrespondenceMethod>;
  dob?: InputMaybe<Scalars['AWSDate']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  emergencyContacts?: InputMaybe<Array<InputMaybe<EmergencyContactInput>>>;
  inviteToAutopay?: InputMaybe<Scalars['Boolean']['input']>;
  mailingAddress?: InputMaybe<AddressInput>;
  name: Scalars['String']['input'];
  padAccount?: InputMaybe<Scalars['String']['input']>;
  padAmount?: InputMaybe<Scalars['Float']['input']>;
  padInstitution?: InputMaybe<Scalars['String']['input']>;
  padTransit?: InputMaybe<Scalars['String']['input']>;
  pets?: InputMaybe<Array<PetInput>>;
  phones?: InputMaybe<Array<InputMaybe<Scalars['AWSPhone']['input']>>>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  vehicles?: InputMaybe<Array<VehicleInput>>;
};

export type CreateOwnerNoteInput = {
  ownerId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateParkingInput = {
  name: Scalars['String']['input'];
};

export type CreatePaymentsApprovalInput = {
  batchId: Scalars['ID']['input'];
};

export type CreatePaymentsBatchInput = {
  id: Scalars['ID']['input'];
  itemCount: Scalars['Int']['input'];
  paymentMethod?: InputMaybe<PaymentMethod>;
  posted: Scalars['AWSDate']['input'];
  total: Scalars['Float']['input'];
};

export type CreatePropertyFloorplanInput = {
  floorplan: FloorplanInput;
  propertyId: Scalars['ID']['input'];
  unitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CreatePropertyInput = {
  address: AddressInput;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  legalDescription?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  onsiteManager?: InputMaybe<OnsiteManagerInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  types?: InputMaybe<Array<InputMaybe<PropertyType>>>;
};

export type CreatePropertyInsuranceClaimInput = {
  claim: ClaimInput;
  insurancePolicyId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type CreatePropertyInsuranceHistoryInput = {
  insurancePolicy: InsurancePolicyInput;
  propertyId: Scalars['ID']['input'];
};

export type CreatePropertyNoteInput = {
  propertyId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReportBundleInput = {
  name: Scalars['String']['input'];
  presetIds: Array<Scalars['ID']['input']>;
};

export type CreateRequestAccountInput = {
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRequestCostInput = {
  amount: Scalars['Float']['input'];
  date: Scalars['AWSDate']['input'];
  description: Scalars['String']['input'];
  markup: Scalars['Boolean']['input'];
  requestId: Scalars['ID']['input'];
};

export type CreateRequestCostPayload = {
  __typename?: 'CreateRequestCostPayload';
  error?: Maybe<Scalars['String']['output']>;
  newCostId?: Maybe<Scalars['String']['output']>;
  request?: Maybe<Request>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateRequestInput = {
  additionalTenants?: InputMaybe<Array<InputMaybe<UpdateTenantInput>>>;
  category: Scalars['String']['input'];
  firstOccurred?: InputMaybe<Scalars['String']['input']>;
  minutes: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  nonBillable?: InputMaybe<Scalars['Boolean']['input']>;
  occupant?: InputMaybe<UpdateTenantInput>;
  originalRequest: Scalars['String']['input'];
  summary: Scalars['String']['input'];
  templateId: Scalars['String']['input'];
  tenant?: InputMaybe<UpdateTenantInput>;
  unit: CreateRequestUnitInput;
};

export type CreateRequestNoteInput = {
  requestId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRequestNotePayload = {
  __typename?: 'CreateRequestNotePayload';
  request?: Maybe<Request>;
};

export type CreateRequestPayload = {
  __typename?: 'CreateRequestPayload';
  error?: Maybe<Scalars['String']['output']>;
  request?: Maybe<Request>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateRequestPropertyInput = {
  account?: InputMaybe<CreateRequestAccountInput>;
  address?: InputMaybe<AddressInput>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRequestRecurrenceInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  checklist?: InputMaybe<Array<InputMaybe<ChecklistItemInput>>>;
  details?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nonBillable?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  originalRequest?: InputMaybe<Scalars['String']['input']>;
  printNotes?: InputMaybe<Scalars['Boolean']['input']>;
  propertyId: Scalars['ID']['input'];
  rrule: Scalars['String']['input'];
  subcategory?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['ID']['input'];
};

export type CreateRequestUnitInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  occupancy?: InputMaybe<UnitOccupancy>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  property: CreateRequestPropertyInput;
};

export type CreateResidencyInput = {
  additionalClause?: InputMaybe<Array<Scalars['String']['input']>>;
  additionalProvisions?: InputMaybe<AdditionalProvisionsInput>;
  applicationId?: InputMaybe<Scalars['String']['input']>;
  campaignId?: InputMaybe<Scalars['String']['input']>;
  collectionDay?: InputMaybe<Scalars['Int']['input']>;
  effects?: InputMaybe<Array<ResidencyEffectInput>>;
  endZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  furnishings?: InputMaybe<Array<Scalars['String']['input']>>;
  gst?: InputMaybe<Scalars['Float']['input']>;
  leaseInput?: InputMaybe<CreateResidencyLeaseInput>;
  moveInDate?: InputMaybe<Scalars['AWSDate']['input']>;
  moveOutDate?: InputMaybe<Scalars['AWSDate']['input']>;
  occupancy: UnitOccupancy;
  previousLeaseEnd?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['ID']['input'];
  refundablesInput?: InputMaybe<Array<RefundableInput>>;
  renewZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  residents?: InputMaybe<Array<ResidentAssociationInput>>;
  rrule?: InputMaybe<Scalars['String']['input']>;
  securityDeposit?: InputMaybe<Scalars['Float']['input']>;
  startZ: Scalars['AWSDateTime']['input'];
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unitId: Scalars['ID']['input'];
  unitOwnerId?: InputMaybe<Scalars['ID']['input']>;
  utilities?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateResidencyLeaseInput = {
  additionalProvisions?: InputMaybe<AdditionalProvisionsInput>;
  applicationId?: InputMaybe<Scalars['String']['input']>;
  autoRenewSetup?: InputMaybe<AutoRenewSetupInput>;
  campaignId?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  gst?: InputMaybe<Scalars['Float']['input']>;
  incentives?: InputMaybe<Array<LeaseIncentiveInput>>;
  occupancy: UnitOccupancy;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  previousLeaseEnd?: InputMaybe<Scalars['String']['input']>;
  rent?: InputMaybe<Scalars['Float']['input']>;
  rentRollFees?: InputMaybe<Array<RentRollFeesInput>>;
  securityDeposit?: InputMaybe<Scalars['Float']['input']>;
  start: Scalars['String']['input'];
  tenants: Array<LeaseTenantInput>;
  unitOwnerId?: InputMaybe<Scalars['ID']['input']>;
  utilities?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateResidencyNoteInput = {
  residencyId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRoutineInspectionInput = {
  inspection: InspectionInput;
  residencyId?: InputMaybe<Scalars['ID']['input']>;
  unitId: Scalars['ID']['input'];
};

export type CreateStorageInput = {
  name: Scalars['String']['input'];
};

export type CreateTeamInput = {
  managerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
  occupancies: Array<Scalars['String']['input']>;
  propertyIds: Array<Scalars['ID']['input']>;
  propertyUnitIds?: InputMaybe<Array<PropertyUnitInput>>;
};

export type CreateTemplateInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fields?: InputMaybe<Array<InputMaybe<FieldInput>>>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
  response?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTenantNoteInput = {
  tenantId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUnitNoteInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['ID']['input'];
};

export type CreateUnitOnPropertyInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  bathrooms: Scalars['Float']['input'];
  bedrooms: Scalars['Float']['input'];
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  condoFee?: InputMaybe<Scalars['Float']['input']>;
  condoFeeGst?: InputMaybe<Scalars['Float']['input']>;
  condoUnitFees?: InputMaybe<Array<InputMaybe<CondoUnitFeesInput>>>;
  legalAddress?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  occupancy?: InputMaybe<UnitOccupancy>;
  paymentDetails?: InputMaybe<PaymentDetailsInput>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  propertyId: Scalars['ID']['input'];
  sqft: Scalars['Float']['input'];
  unitFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateUnitsInput = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  propertyId: Scalars['ID']['input'];
  units: Array<BulkUnitInput>;
};

export type CreateVisitInput = {
  appointmentZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
  operatorId?: InputMaybe<Scalars['ID']['input']>;
  operatorName?: InputMaybe<Scalars['String']['input']>;
  requestId: Scalars['ID']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  tenantProposedTimes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVisitPayload = {
  __typename?: 'CreateVisitPayload';
  request?: Maybe<Request>;
};

export enum CustomerType {
  CondominiumCorporation = 'Condominium_Corporation',
  Landlord = 'Landlord',
  PropertyManagement = 'Property_Management',
  Realtor = 'Realtor',
}

export type DeleteApplicationNoteInput = {
  applicationId: Scalars['ID']['input'];
  noteId: Scalars['ID']['input'];
};

export type DeleteApplicationNotePayload = {
  __typename?: 'DeleteApplicationNotePayload';
  application?: Maybe<Application>;
};

export type DeleteBuildingInput = {
  buildingId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type DeleteBuildingInsuranceClaimInput = {
  buildingId: Scalars['ID']['input'];
  claimId: Scalars['ID']['input'];
  insurancePolicyId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type DeleteBuildingInsuranceHistoryInput = {
  buildingId: Scalars['ID']['input'];
  insurancePolicyId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type DeleteBuildingNoteInput = {
  buildingId: Scalars['ID']['input'];
  noteId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type DeleteBuildingPayload = {
  __typename?: 'DeleteBuildingPayload';
  error?: Maybe<Scalars['String']['output']>;
  property?: Maybe<Property>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteClaimPayload = {
  __typename?: 'DeleteClaimPayload';
  error?: Maybe<Scalars['String']['output']>;
  property?: Maybe<Property>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteClearablePayload = {
  __typename?: 'DeleteClearablePayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteFutureLeaseInput = {
  leaseId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type DeleteImageNoteInput = {
  imageKey: Scalars['String']['input'];
  noteId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
};

export type DeleteLeaseInspectionInput = {
  inspectionId: Scalars['ID']['input'];
  residencyId: Scalars['ID']['input'];
};

export type DeleteLeaseNoteInput = {
  leaseId: Scalars['ID']['input'];
  noteId: Scalars['ID']['input'];
};

export type DeleteLeaseTenantInput = {
  leaseId: Scalars['ID']['input'];
  tenantId: Scalars['ID']['input'];
};

export type DeleteOwnerInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOwnerNoteInput = {
  noteId: Scalars['ID']['input'];
  ownerId: Scalars['ID']['input'];
};

export type DeletePropertyImageInput = {
  imageKey: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
};

export type DeletePropertyInput = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export type DeletePropertyInsuranceClaimInput = {
  claimId: Scalars['ID']['input'];
  insurancePolicyId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type DeletePropertyInsuranceHistoryInput = {
  insurancePolicyId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type DeletePropertyNoteInput = {
  noteId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type DeleteReconciliationConfigInput = {
  id: Scalars['ID']['input'];
};

export type DeleteRequestCostInput = {
  costId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
};

export type DeleteRequestImageInput = {
  imageKey: Scalars['String']['input'];
  requestId: Scalars['ID']['input'];
};

export type DeleteRequestNoteInput = {
  noteId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
};

export type DeleteRequestNotePayload = {
  __typename?: 'DeleteRequestNotePayload';
  request?: Maybe<Request>;
};

export type DeleteResidencyEventInput = {
  eventId: Scalars['ID']['input'];
  residencyId: Scalars['ID']['input'];
};

export type DeleteResidencyInput = {
  id: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type DeleteResidencyNoteInput = {
  noteId: Scalars['ID']['input'];
  residencyId: Scalars['ID']['input'];
};

export type DeleteRoutineInspectionInput = {
  inspectionId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type DeleteTenantNoteInput = {
  noteId: Scalars['ID']['input'];
  tenantId: Scalars['ID']['input'];
};

export type DeleteUnitInput = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteUnitNoteInput = {
  noteId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type DeleteVisitBillInput = {
  requestId: Scalars['ID']['input'];
  visitId: Scalars['ID']['input'];
};

export type DepositTemplate = {
  __typename?: 'DepositTemplate';
  default?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  items: Array<DepositTemplateItem>;
  province: Scalars['String']['output'];
};

export type DepositTemplateItem = {
  __typename?: 'DepositTemplateItem';
  accrualStartExpr?: Maybe<Scalars['String']['output']>;
  defaultAmount?: Maybe<Scalars['Float']['output']>;
  defaultAmountExpr?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  present?: Maybe<Scalars['Boolean']['output']>;
  simple?: Maybe<Scalars['Boolean']['output']>;
};

export type Document = {
  __typename?: 'Document';
  createdZ: Scalars['String']['output'];
  expiryZ?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  typename?: Maybe<Scalars['String']['output']>;
};

export type DocumentLinkPayload = {
  __typename?: 'DocumentLinkPayload';
  error?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
};

export type DraftReconciliationInput = {
  configuration: Scalars['ID']['input'];
  statementBalance?: InputMaybe<Scalars['Float']['input']>;
  statementDate: Scalars['AWSDate']['input'];
};

export type DraftReconciliationPayload = {
  __typename?: 'DraftReconciliationPayload';
  error?: Maybe<Scalars['String']['output']>;
  reconciliation?: Maybe<Reconciliation>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DraftedJournalEntriesPage = {
  __typename?: 'DraftedJournalEntriesPage';
  clearedCount: Scalars['Int']['output'];
  clearedDepositsCount: Scalars['Int']['output'];
  clearedWithdrawalsCount: Scalars['Int']['output'];
  edges: Array<DraftedJournalEntryEdge>;
  pageInfo: RelayPageInfo;
  totalCleared: Scalars['Float']['output'];
  totalCount: Scalars['Int']['output'];
  totalDepositsCleared: Scalars['Float']['output'];
  totalDepositsUncleared: Scalars['Float']['output'];
  totalUncleared: Scalars['Float']['output'];
  totalWithdrawalsCleared: Scalars['Float']['output'];
  totalWithdrawalsUncleared: Scalars['Float']['output'];
  unclearedCount: Scalars['Int']['output'];
  unclearedDepositsCount: Scalars['Int']['output'];
  unclearedWithdrawalsCount: Scalars['Int']['output'];
};

export type DraftedJournalEntry = {
  __typename?: 'DraftedJournalEntry';
  amount: Scalars['Float']['output'];
  checked?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  posted: Scalars['AWSDate']['output'];
};

export type DraftedJournalEntryEdge = RelayEdge & {
  __typename?: 'DraftedJournalEntryEdge';
  cursor: Scalars['ID']['output'];
  node: DraftedJournalEntry;
};

export type DraftedJournalEntryInput = {
  accountId: Scalars['ID']['input'];
  amount?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  posted: Scalars['AWSDate']['input'];
};

export type DraftedJournalFilter = {
  checked?: InputMaybe<Scalars['Boolean']['input']>;
  draftReconciliationId: Scalars['ID']['input'];
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  queryByIds?: InputMaybe<Scalars['Boolean']['input']>;
  transactionType?: InputMaybe<JournalTransactionType>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  relationship?: Maybe<ContactRelationship>;
};

export type EmergencyContactInput = {
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  relationship?: InputMaybe<ContactRelationship>;
};

export type EnablePropertyInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export enum EventSource {
  System = 'system',
}

export type Exception = {
  __typename?: 'Exception';
  comment?: Maybe<Scalars['String']['output']>;
  interval: Interval;
};

export type ExceptionInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  interval: IntervalInput;
};

export enum ExtraColumnType {
  Amount = 'amount',
}

export type Feature = {
  __typename?: 'Feature';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type FeatureInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Fee = {
  __typename?: 'Fee';
  deletedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum FeeInvoiceChargeMethod {
  Pad = 'pad',
  UponSettlement = 'uponSettlement',
}

export type FeeMapping = {
  __typename?: 'FeeMapping';
  feeId: Scalars['ID']['output'];
  glId: Scalars['ID']['output'];
};

export type FeeMappingInput = {
  feeId: Scalars['ID']['input'];
  glId: Scalars['ID']['input'];
};

export type FeesInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  feeId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Field = {
  __typename?: 'Field';
  id?: Maybe<Scalars['ID']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FieldInput = {
  imageKey?: InputMaybe<Scalars['String']['input']>;
  info?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export enum FilingFrequency {
  Monthly = 'monthly',
  None = 'none',
  Quarterly = 'quarterly',
  Self = 'self',
  Yearly = 'yearly',
}

export enum FinalStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
}

export type FinalizeRequestInput = {
  cancelledReason?: InputMaybe<Scalars['String']['input']>;
  requestId: Scalars['ID']['input'];
  status: FinalStatus;
};

export type FinalizeRequestPayload = {
  __typename?: 'FinalizeRequestPayload';
  error?: Maybe<Scalars['String']['output']>;
  request?: Maybe<Request>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Fingerprints = {
  __typename?: 'Fingerprints';
  createdSub?: Maybe<Scalars['ID']['output']>;
  createdZ?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedSub?: Maybe<Scalars['ID']['output']>;
  updatedZ?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type Floorplan = {
  __typename?: 'Floorplan';
  additionalFeatures?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  areas?: Maybe<Array<FloorplanArea>>;
  bathrooms?: Maybe<Scalars['Float']['output']>;
  bedrooms?: Maybe<Scalars['Float']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  exteriorSqFt?: Maybe<Scalars['Float']['output']>;
  generatedSummary?: Maybe<GeneratedFloorplanSummary>;
  id: Scalars['ID']['output'];
  interiorSqFt?: Maybe<Scalars['Float']['output']>;
  links?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  marketRate?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  photos?: Maybe<Array<Image>>;
  publicPhotos?: Maybe<Array<Image>>;
  totalUnits?: Maybe<Scalars['Int']['output']>;
  units?: Maybe<Array<Maybe<Unit>>>;
};

export type FloorplanArea = {
  __typename?: 'FloorplanArea';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Array<Maybe<FloorplanAreaFeature>>>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type FloorplanAreaFeature = {
  __typename?: 'FloorplanAreaFeature';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type FloorplanAreaFeatureInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type FloorplanAreaInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<FloorplanAreaFeatureInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label: Scalars['String']['input'];
};

export type FloorplanConnection = {
  __typename?: 'FloorplanConnection';
  items?: Maybe<Array<Maybe<Floorplan>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type FloorplanInput = {
  additionalFeatures?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areas?: InputMaybe<Array<FloorplanAreaInput>>;
  bathrooms?: InputMaybe<Scalars['Float']['input']>;
  bedrooms?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  exteriorSqFt?: InputMaybe<Scalars['Float']['input']>;
  interiorSqFt?: InputMaybe<Scalars['Float']['input']>;
  links?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  marketRate?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  photos?: InputMaybe<Array<InputMaybe<ImageInput>>>;
};

export type FloorplanPayload = {
  __typename?: 'FloorplanPayload';
  error?: Maybe<Scalars['String']['output']>;
  floorplan?: Maybe<Floorplan>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Fraction = {
  __typename?: 'Fraction';
  denominator: Scalars['Int']['output'];
  numerator: Scalars['Int']['output'];
};

export type FractionInput = {
  denominator: Scalars['Int']['input'];
  numerator: Scalars['Int']['input'];
};

export type FurnishingsTemplate = {
  __typename?: 'FurnishingsTemplate';
  id: Scalars['ID']['output'];
  items: Array<TemplateItem>;
  name: Scalars['String']['output'];
};

export type GlAccount = {
  __typename?: 'GLAccount';
  accountType: AccountType;
  balanceType: BalanceType;
  category?: Maybe<Scalars['String']['output']>;
  combinedBalance?: Maybe<Scalars['Float']['output']>;
  combinedSubAccount?: Maybe<CombinedSubAccount>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  key?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pageJournalEntries?: Maybe<JournalEntriesPage>;
};

export type GlAccountCombinedBalanceArgs = {
  input: CombinedBalanceInput;
};

export type GlAccountCombinedSubAccountArgs = {
  input: SubAccountInput;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type GlAccountPageJournalEntriesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<JournalEntriesFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type GlChequePlacementInput = {
  chequePlacement: ChequePlacement;
  glId: Scalars['ID']['input'];
};

export type GlMapping = {
  __typename?: 'GLMapping';
  default: Scalars['String']['output'];
  description: Scalars['String']['output'];
  filter?: Maybe<Scalars['String']['output']>;
  glId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parentId: Scalars['ID']['output'];
};

export type GlMappingInput = {
  glId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type GeneratedFloorplanFeature = {
  __typename?: 'GeneratedFloorplanFeature';
  feature: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type GeneratedFloorplanSummary = {
  __typename?: 'GeneratedFloorplanSummary';
  features?: Maybe<Array<GeneratedFloorplanFeature>>;
  summary?: Maybe<Scalars['String']['output']>;
};

export type GetBudgetsInput = {
  endPeriod: Scalars['String']['input'];
  ownerId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  startPeriod: Scalars['String']['input'];
};

export type GetProvinceTaxDetailsInput = {
  country?: InputMaybe<Country>;
  province?: InputMaybe<Scalars['String']['input']>;
  taxExempted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetRegionTaxDetailsInput = {
  category?: InputMaybe<TaxCategory>;
  country?: InputMaybe<Scalars['String']['input']>;
  postal?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  taxExempted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GrandTotal = {
  __typename?: 'GrandTotal';
  amount?: Maybe<Scalars['Float']['output']>;
  balanceType?: Maybe<BalanceType>;
  extraColumns?: Maybe<Array<Maybe<BooksReportExtraColumn>>>;
  footer?: Maybe<BooksReportFooter>;
  header?: Maybe<BooksReportHeader>;
  order?: Maybe<Scalars['Int']['output']>;
  sections: Array<BooksReportRendered>;
};

export enum GstInfo {
  Line103 = 'line103',
  Line104 = 'line104',
  Line106 = 'line106',
  Line107 = 'line107',
  Line110 = 'line110',
  Line111 = 'line111',
  Line205 = 'line205',
  Line405 = 'line405',
}

export type HouseRulesTemplateInput = {
  fields?: InputMaybe<Array<InputMaybe<FieldInput>>>;
};

export type Household = {
  __typename?: 'Household';
  additionalTenants?: Maybe<Array<AdditionalTenant>>;
  payeeFrac?: Maybe<Fraction>;
  pets?: Maybe<Array<Pet>>;
  vehicles?: Maybe<Array<Vehicle>>;
};

export type IamAccountFactFilter = {
  dateKey?: InputMaybe<Scalars['AWSDate']['input']>;
  dateKeys?: InputMaybe<Array<InputMaybe<Scalars['AWSDate']['input']>>>;
};

export type IamMutation = {
  __typename?: 'IamMutation';
  addAccountKycNote?: Maybe<AccountPayload>;
  addManagerRole: ManagerPayload;
  addPropertyAssignedKeyrings: PropertyPayload;
  addPropertyAssignedParkings: PropertyPayload;
  addPropertyAssignedStorages: PropertyPayload;
  createAccount?: Maybe<AccountPayload>;
  createAutopayEmbedUrl?: Maybe<CreateAutopayEmbedUrlPayload>;
  createInterestRate?: Maybe<InterestRatePayload>;
  createManager?: Maybe<ManagerPayload>;
  createResidency: ResidencyPayload;
  deleteAccountKycNote?: Maybe<AccountPayload>;
  deleteInterestRate?: Maybe<SuccessPayload>;
  deleteManager?: Maybe<SuccessPayload>;
  deleteProperty?: Maybe<SuccessPayload>;
  deleteReconciliationIndex?: Maybe<SuccessPayload>;
  deleteUnit?: Maybe<SuccessPayload>;
  enableAccount?: Maybe<AccountPayload>;
  enableManager?: Maybe<ManagerPayload>;
  enableProperty?: Maybe<PropertyPayload>;
  reactivateAutopay?: Maybe<SuccessPayload>;
  removeManagerRole: ManagerPayload;
  suspendAutopay?: Maybe<SuccessPayload>;
  updateAccountBillingInfo?: Maybe<AccountPayload>;
  updateAccountKyc?: Maybe<AccountPayload>;
  updateInterestRate?: Maybe<InterestRatePayload>;
  updateManager?: Maybe<ManagerPayload>;
  updateUnit?: Maybe<UnitPayload>;
  updateUnitTest?: Maybe<UnitPayload>;
  welcomeTenant: TenantPayload;
};

export type IamMutationAddAccountKycNoteArgs = {
  accountId: Scalars['ID']['input'];
  input: NoteInput;
};

export type IamMutationAddManagerRoleArgs = {
  accountId: Scalars['ID']['input'];
  input: ManagerRoleInput;
};

export type IamMutationAddPropertyAssignedKeyringsArgs = {
  accountId: Scalars['ID']['input'];
  input: AddPropertyAssignedKeyringsInput;
};

export type IamMutationAddPropertyAssignedParkingsArgs = {
  accountId: Scalars['ID']['input'];
  input: AddPropertyAssignedParkingsInput;
};

export type IamMutationAddPropertyAssignedStoragesArgs = {
  accountId: Scalars['ID']['input'];
  input: AddPropertyAssignedStoragesInput;
};

export type IamMutationCreateAccountArgs = {
  input: CreateAccountInput;
};

export type IamMutationCreateAutopayEmbedUrlArgs = {
  accountId: Scalars['ID']['input'];
  payeeId: Scalars['ID']['input'];
};

export type IamMutationCreateInterestRateArgs = {
  input: CreateInterestRateInput;
};

export type IamMutationCreateManagerArgs = {
  accountId: Scalars['ID']['input'];
  input: CreateManagerInput;
};

export type IamMutationCreateResidencyArgs = {
  accountId: Scalars['ID']['input'];
  input: CreateResidencyInput;
};

export type IamMutationDeleteAccountKycNoteArgs = {
  accountId: Scalars['ID']['input'];
  noteId: Scalars['ID']['input'];
};

export type IamMutationDeleteInterestRateArgs = {
  id: Scalars['ID']['input'];
};

export type IamMutationDeleteManagerArgs = {
  accountId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type IamMutationDeletePropertyArgs = {
  accountId: Scalars['ID']['input'];
  input: DeletePropertyInput;
};

export type IamMutationDeleteReconciliationIndexArgs = {
  accountId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type IamMutationDeleteUnitArgs = {
  accountId: Scalars['ID']['input'];
  input: DeleteUnitInput;
};

export type IamMutationEnableAccountArgs = {
  accountId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
};

export type IamMutationEnableManagerArgs = {
  accountId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type IamMutationEnablePropertyArgs = {
  accountId: Scalars['ID']['input'];
  input: EnablePropertyInput;
};

export type IamMutationReactivateAutopayArgs = {
  accountId: Scalars['ID']['input'];
  payee: Scalars['String']['input'];
  payeeId: Scalars['ID']['input'];
};

export type IamMutationRemoveManagerRoleArgs = {
  accountId: Scalars['ID']['input'];
  input: ManagerRoleInput;
};

export type IamMutationSuspendAutopayArgs = {
  accountId: Scalars['ID']['input'];
  payee: Scalars['String']['input'];
  payeeId: Scalars['ID']['input'];
};

export type IamMutationUpdateAccountBillingInfoArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAccountBillingInfoInput;
};

export type IamMutationUpdateAccountKycArgs = {
  accountId: Scalars['ID']['input'];
  input: UpdateKycInput;
};

export type IamMutationUpdateInterestRateArgs = {
  input: UpdateInterestRateInput;
};

export type IamMutationUpdateManagerArgs = {
  accountId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: UpdateManagerInput;
};

export type IamMutationUpdateUnitArgs = {
  accountId: Scalars['ID']['input'];
  input?: InputMaybe<UpdateUnitInput>;
};

export type IamMutationUpdateUnitTestArgs = {
  accountId: Scalars['ID']['input'];
  input: UpdateUnitTestInput;
};

export type IamMutationWelcomeTenantArgs = {
  accountId: Scalars['ID']['input'];
  input: WelcomeTenantInput;
};

export type IamPropertyFactFilter = {
  dateKey?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type IamQuery = {
  __typename?: 'IamQuery';
  account?: Maybe<Account>;
  accountStats: Array<AccountStat>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  autopayAccount?: Maybe<Account>;
  autopayResident?: Maybe<ResidentRecord>;
  bank?: Maybe<Bank>;
  billingAccount?: Maybe<BillingAccount>;
  campaign?: Maybe<Campaign>;
  campaigns?: Maybe<CampaignConnection>;
  clearable?: Maybe<Clearable>;
  entity?: Maybe<Scalars['AWSJSON']['output']>;
  entry?: Maybe<Scalars['AWSJSON']['output']>;
  interestRates: InterestRateConnection;
  listAccountFacts?: Maybe<AccountFactConnection>;
  listPaymentBatchPrototypes?: Maybe<PaymentsPrototypeConnection>;
  listProperties?: Maybe<PropertyConnection>;
  listPropertyFacts?: Maybe<PropertyFactConnection>;
  propertyFacts?: Maybe<Array<Maybe<PropertyFact>>>;
  unit?: Maybe<Unit>;
  unitJournalEntries?: Maybe<Array<Maybe<JournalEntry>>>;
};

export type IamQueryAccountArgs = {
  accountId: Scalars['ID']['input'];
};

export type IamQueryAutopayAccountArgs = {
  vopayAccountId: Scalars['ID']['input'];
};

export type IamQueryAutopayResidentArgs = {
  accountId: Scalars['ID']['input'];
  autopayClientId: Scalars['ID']['input'];
};

export type IamQueryBankArgs = {
  accountId: Scalars['ID']['input'];
  bankId?: InputMaybe<Scalars['ID']['input']>;
};

export type IamQueryBillingAccountArgs = {
  accountId: Scalars['ID']['input'];
};

export type IamQueryCampaignArgs = {
  id: Scalars['ID']['input'];
};

export type IamQueryCampaignsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type IamQueryClearableArgs = {
  accountId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type IamQueryEntityArgs = {
  id: Scalars['ID']['input'];
};

export type IamQueryEntryArgs = {
  id: Scalars['ID']['input'];
};

export type IamQueryListAccountFactsArgs = {
  filter?: InputMaybe<IamAccountFactFilter>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type IamQueryListPaymentBatchPrototypesArgs = {
  accountId: Scalars['ID']['input'];
  batchId: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type IamQueryListPropertiesArgs = {
  accountId: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type IamQueryListPropertyFactsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type IamQueryPropertyFactsArgs = {
  filter?: InputMaybe<IamPropertyFactFilter>;
};

export type IamQueryUnitArgs = {
  accountId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type IamQueryUnitJournalEntriesArgs = {
  accountId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type IamTenant = {
  __typename?: 'IamTenant';
  account?: Maybe<Account>;
  booksActivation?: Maybe<BooksActivatedPayload>;
  campaign?: Maybe<Campaign>;
  documentLink?: Maybe<DocumentLinkPayload>;
  getInspectionUrl?: Maybe<InspectionUrlPayload>;
  getProvinceTaxDetails?: Maybe<ProvinceTaxDetailsPayload>;
  getRegionTaxDetails?: Maybe<RegionTaxDetailsPayload>;
  manager?: Maybe<Manager>;
  owner?: Maybe<Owner>;
  payeeClearable?: Maybe<IamTenantClearable>;
  payeeClearables?: Maybe<IamTenantClearablesPage>;
  property?: Maybe<Property>;
  propertyExpenses?: Maybe<Array<Maybe<IamTenantJournalEntry>>>;
  request?: Maybe<Request>;
  searchOwnerRequests?: Maybe<RequestsPage>;
  searchResidents?: Maybe<ResidentPage>;
  tenant?: Maybe<Tenant>;
  unit?: Maybe<Unit>;
};

export type IamTenantAccountArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type IamTenantBooksActivationArgs = {
  accountIds: Array<Scalars['ID']['input']>;
};

export type IamTenantCampaignArgs = {
  id: Scalars['ID']['input'];
};

export type IamTenantDocumentLinkArgs = {
  accountId: Scalars['ID']['input'];
  documentKey: Scalars['String']['input'];
};

export type IamTenantGetInspectionUrlArgs = {
  accountId: Scalars['ID']['input'];
  input: InspectionUrlInput;
};

export type IamTenantGetProvinceTaxDetailsArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  input: GetProvinceTaxDetailsInput;
};

export type IamTenantGetRegionTaxDetailsArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  input: GetRegionTaxDetailsInput;
};

export type IamTenantManagerArgs = {
  id: Scalars['ID']['input'];
};

export type IamTenantOwnerArgs = {
  id: Scalars['ID']['input'];
};

export type IamTenantPayeeClearableArgs = {
  id: Scalars['ID']['input'];
  payeeAccountId: PayeeClearablesFilterItemInput;
};

export type IamTenantPayeeClearablesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<SearchClearablesInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type IamTenantPropertyArgs = {
  id: Scalars['ID']['input'];
};

export type IamTenantPropertyExpensesArgs = {
  filter: PropertyExpensesFilterItemInput;
};

export type IamTenantRequestArgs = {
  id: Scalars['ID']['input'];
};

export type IamTenantSearchOwnerRequestsArgs = {
  ownerId: Scalars['ID']['input'];
};

export type IamTenantSearchResidentsArgs = {
  filter: SearchResidentsFilterInput;
};

export type IamTenantTenantArgs = {
  accountId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type IamTenantUnitArgs = {
  id: Scalars['ID']['input'];
};

export type IamTenantClearable = {
  __typename?: 'IamTenantClearable';
  accountAddress?: Maybe<Address>;
  accountId: Scalars['ID']['output'];
  accountName?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Float']['output'];
  autopaySinglePosted?: Maybe<Scalars['AWSDate']['output']>;
  autopaySingleProfileId?: Maybe<Scalars['ID']['output']>;
  balanceType: BalanceType;
  cleared?: Maybe<Scalars['AWSDate']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due: Scalars['AWSDate']['output'];
  enrollmentId?: Maybe<Scalars['ID']['output']>;
  fixedEnrollmentId?: Maybe<Scalars['ID']['output']>;
  glId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  outstanding: Scalars['Float']['output'];
  ownerId: Scalars['ID']['output'];
  payee: PayeeType;
  payeeAutopayClientId?: Maybe<Scalars['ID']['output']>;
  payeeAutopayStatus?: Maybe<Scalars['String']['output']>;
  payeeId: Scalars['ID']['output'];
  payeeMailingAddress?: Maybe<Address>;
  payeeName?: Maybe<Scalars['String']['output']>;
  payments?: Maybe<Array<IamTenantClearablePaymentEffect>>;
  posted: Scalars['AWSDate']['output'];
  propertyAddress?: Maybe<Address>;
  propertyId: Scalars['ID']['output'];
  propertyName?: Maybe<Scalars['String']['output']>;
  ref?: Maybe<Scalars['String']['output']>;
  refund?: Maybe<Scalars['Boolean']['output']>;
  sourceJournalId: Scalars['String']['output'];
  unitId?: Maybe<Scalars['ID']['output']>;
  unitName?: Maybe<Scalars['String']['output']>;
};

export type IamTenantClearableEdge = RelayEdge & {
  __typename?: 'IamTenantClearableEdge';
  cursor: Scalars['ID']['output'];
  node: IamTenantClearable;
};

export type IamTenantClearablePaymentEffect = {
  __typename?: 'IamTenantClearablePaymentEffect';
  effect: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  paymentMethod?: Maybe<PaymentMethod>;
  posted: Scalars['AWSDate']['output'];
};

export type IamTenantClearablesPage = RelayPage & {
  __typename?: 'IamTenantClearablesPage';
  edges: Array<IamTenantClearableEdge>;
  pageInfo: RelayPageInfo;
  totalByAccount?: Maybe<Array<Maybe<TotalByAccount>>>;
  totalByProperty?: Maybe<Array<Maybe<TotalByProperty>>>;
  totalByUnit?: Maybe<Array<Maybe<TotalByUnit>>>;
  totalCount: Scalars['Int']['output'];
};

export type IamTenantInsurancePolicy = {
  __typename?: 'IamTenantInsurancePolicy';
  effective: Scalars['AWSDate']['output'];
  expiry: Scalars['AWSDate']['output'];
  id: Scalars['ID']['output'];
  liability?: Maybe<Scalars['Float']['output']>;
  policyDocumentKey?: Maybe<Scalars['String']['output']>;
  policyNumber: Scalars['String']['output'];
  propertyId: Scalars['ID']['output'];
  providerName: Scalars['String']['output'];
  residencyId: Scalars['ID']['output'];
  unitId: Scalars['ID']['output'];
};

export type IamTenantJournalEntry = {
  __typename?: 'IamTenantJournalEntry';
  amount: Scalars['Float']['output'];
  clearableId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  glAccountType?: Maybe<Scalars['String']['output']>;
  glBalanceType?: Maybe<Scalars['String']['output']>;
  glId: Scalars['ID']['output'];
  glName?: Maybe<Scalars['String']['output']>;
  gstAmount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  outstanding?: Maybe<Scalars['Float']['output']>;
  ownerId: Scalars['ID']['output'];
  paidDate?: Maybe<Scalars['AWSDate']['output']>;
  posted: Scalars['AWSDate']['output'];
  propertyId: Scalars['ID']['output'];
  ref?: Maybe<Scalars['String']['output']>;
  requestId?: Maybe<Scalars['ID']['output']>;
  unitId?: Maybe<Scalars['ID']['output']>;
};

export type Image = {
  __typename?: 'Image';
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ImageInput = {
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type IncentiveEffect = ResidencyEffectRecord & {
  __typename?: 'IncentiveEffect';
  description?: Maybe<Scalars['String']['output']>;
  effect: Scalars['Float']['output'];
  feeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  incentiveId: Scalars['ID']['output'];
  startZ: Scalars['AWSDateTime']['output'];
};

export type Income = {
  __typename?: 'Income';
  amount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  source: IncomeSource;
};

export type IncomePayoutOptions = {
  __typename?: 'IncomePayoutOptions';
  excludePayables?: Maybe<Scalars['Boolean']['output']>;
  excludePrepayments?: Maybe<Scalars['Boolean']['output']>;
  includeOutstandingPayables?: Maybe<Scalars['Boolean']['output']>;
};

export type IncomePayoutOptionsInput = {
  excludePayables?: InputMaybe<Scalars['Boolean']['input']>;
  excludePrepayments?: InputMaybe<Scalars['Boolean']['input']>;
  includeOutstandingPayables?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum IncomeSource {
  Alimony = 'alimony',
  GovernmentAssistancePrograms = 'government_assistance_programs',
  Investments = 'investments',
  Pension = 'pension',
}

export type InitiateBooksBatchInput = {
  parameters?: InputMaybe<Scalars['AWSJSON']['input']>;
  posted: Scalars['String']['input'];
  presetId: Scalars['ID']['input'];
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type Inspection = {
  __typename?: 'Inspection';
  accountId: Scalars['ID']['output'];
  category?: Maybe<InspectionCategory>;
  comments?: Maybe<Scalars['String']['output']>;
  completedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  generatedSummary?: Maybe<InspectionSummary>;
  id: Scalars['ID']['output'];
  includeTenantInfo?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Maybe<InspectionItem>>>;
  lastSyncedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  propertyId?: Maybe<Scalars['ID']['output']>;
  reportKeys?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  signatures?: Maybe<Array<Maybe<Signature>>>;
  status?: Maybe<InspectionStatus>;
  templateId: Scalars['ID']['output'];
  unitId?: Maybe<Scalars['ID']['output']>;
};

export type InspectionAttempt = {
  __typename?: 'InspectionAttempt';
  attemptedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  noteText?: Maybe<Scalars['String']['output']>;
  reason: AttemptReason;
};

export type InspectionAttemptInput = {
  attemptedZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  noteText?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
};

export enum InspectionCategory {
  MoveIn = 'MOVE_IN',
  MoveOut = 'MOVE_OUT',
  Routine = 'ROUTINE',
}

export enum InspectionEntityType {
  Lease = 'lease',
}

export type InspectionField = {
  __typename?: 'InspectionField';
  category?: Maybe<InspectionFieldCategory>;
  description?: Maybe<Scalars['String']['output']>;
  hasChanged?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Image>>>;
  inspectedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  needsRequest?: Maybe<Scalars['Boolean']['output']>;
  noteText?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum InspectionFieldCategory {
  Alarm = 'alarm',
  Appliance = 'appliance',
  Ceiling = 'ceiling',
  Dishwasher = 'dishwasher',
  Door = 'door',
  Fridge = 'fridge',
  Key = 'key',
  Lights = 'lights',
  Microwave = 'microwave',
  Other = 'other',
  Range = 'range',
  Wall = 'wall',
}

export type InspectionFieldImageInput = {
  key?: InputMaybe<Scalars['String']['input']>;
};

export type InspectionFieldInput = {
  category?: InputMaybe<InspectionFieldCategory>;
  description?: InputMaybe<Scalars['String']['input']>;
  hasChanged?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  images?: InputMaybe<Array<InputMaybe<InspectionFieldImageInput>>>;
  inspectedZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  needsRequest?: InputMaybe<Scalars['Boolean']['input']>;
  noteText?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type InspectionFilter = {
  accountId: Scalars['ID']['input'];
  inspectionId: Scalars['ID']['input'];
  residencyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type InspectionInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  completedZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  includeTenantInfo?: InputMaybe<Scalars['Boolean']['input']>;
  items?: InputMaybe<Array<InputMaybe<InspectionItemInput>>>;
  reportKeys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  signatures?: InputMaybe<Array<InputMaybe<SignatureInput>>>;
  status?: InputMaybe<InspectionStatus>;
  templateId: Scalars['ID']['input'];
};

export type InspectionItem = {
  __typename?: 'InspectionItem';
  category: InspectionItemCategory;
  description?: Maybe<Scalars['String']['output']>;
  fields: Array<InspectionField>;
  hasChanged?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  referenceId?: Maybe<Scalars['ID']['output']>;
  wasInspected?: Maybe<Scalars['Boolean']['output']>;
};

export enum InspectionItemCategory {
  Basement = 'basement',
  Bathroom = 'bathroom',
  Bedroom = 'bedroom',
  Den = 'den',
  DiningRoom = 'dining_room',
  Entry = 'entry',
  Exterior = 'exterior',
  FireSafety = 'fire_safety',
  GarageParking = 'garage_parking',
  HalfBathroom = 'half_bathroom',
  Hallway = 'hallway',
  KeysAndControls = 'keys_and_controls',
  Kitchen = 'kitchen',
  LivingRoom = 'living_room',
  Livingroom = 'livingroom',
  Other = 'other',
  StairwellAndHall = 'stairwell_and_hall',
  Storage = 'storage',
  Utility = 'utility',
}

export type InspectionItemInput = {
  category: InspectionItemCategory;
  description?: InputMaybe<Scalars['String']['input']>;
  fields: Array<InspectionFieldInput>;
  hasChanged?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  referenceId?: InputMaybe<Scalars['ID']['input']>;
  wasInspected?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum InspectionStatus {
  Attempted = 'ATTEMPTED',
  Completed = 'COMPLETED',
  Started = 'STARTED',
}

export type InspectionSummary = {
  __typename?: 'InspectionSummary';
  score?: Maybe<Scalars['Float']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
};

export type InspectionTemplate = {
  __typename?: 'InspectionTemplate';
  category: InspectionCategory;
  default?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  fingerprints?: Maybe<Fingerprints>;
  id: Scalars['ID']['output'];
  items: Array<InspectionItem>;
};

export type InspectionTemplateInput = {
  category: InspectionCategory;
  items?: InputMaybe<Array<InputMaybe<InspectionItemInput>>>;
  templateId: Scalars['ID']['input'];
};

export type InspectionUrlInput = {
  inspectionId: Scalars['ID']['input'];
};

export type InspectionUrlObject = {
  __typename?: 'InspectionUrlObject';
  fileUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type InspectionUrlPayload = {
  __typename?: 'InspectionUrlPayload';
  error?: Maybe<Scalars['String']['output']>;
  inspectionUrlObject?: Maybe<InspectionUrlObject>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type InsurancePolicy = {
  __typename?: 'InsurancePolicy';
  claims?: Maybe<Array<Maybe<Claim>>>;
  coverage?: Maybe<Scalars['Float']['output']>;
  effective: Scalars['AWSDate']['output'];
  expiry: Scalars['AWSDate']['output'];
  fingerprints?: Maybe<Fingerprints>;
  id: Scalars['ID']['output'];
  liability?: Maybe<Scalars['Float']['output']>;
  policyHolder: Scalars['String']['output'];
  policyNumber: Scalars['String']['output'];
  premium?: Maybe<Scalars['Float']['output']>;
  supplier?: Maybe<Supplier>;
  supplierId?: Maybe<Scalars['ID']['output']>;
};

export type InsurancePolicyInput = {
  coverage?: InputMaybe<Scalars['Float']['input']>;
  effective: Scalars['AWSDate']['input'];
  expiry: Scalars['AWSDate']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  liability?: InputMaybe<Scalars['Float']['input']>;
  policyHolder: Scalars['String']['input'];
  policyNumber: Scalars['String']['input'];
  premium?: InputMaybe<Scalars['Float']['input']>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type InsurancePolicyPayload = {
  __typename?: 'InsurancePolicyPayload';
  error?: Maybe<Scalars['String']['output']>;
  property?: Maybe<Property>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type IntFilterFieldInput = {
  anyOf?: InputMaybe<Array<Scalars['Int']['input']>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  field: Scalars['String']['input'];
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  notAnyOf?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type InterestRate = {
  __typename?: 'InterestRate';
  id: Scalars['ID']['output'];
  province: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
  startDate: Scalars['AWSDate']['output'];
};

export type InterestRateConnection = {
  __typename?: 'InterestRateConnection';
  items?: Maybe<Array<InterestRate>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type InterestRatePayload = {
  __typename?: 'InterestRatePayload';
  error?: Maybe<Scalars['String']['output']>;
  interestRate?: Maybe<InterestRate>;
  success: Scalars['Boolean']['output'];
};

export type Interval = {
  __typename?: 'Interval';
  end: Scalars['AWSDateTime']['output'];
  start: Scalars['AWSDateTime']['output'];
};

export type IntervalInput = {
  end: Scalars['AWSDateTime']['input'];
  start: Scalars['AWSDateTime']['input'];
};

export type InviteOwnerAutopayInput = {
  id: Scalars['ID']['input'];
};

export type InvitePayeeToEnrollInput = {
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  propertyId: Scalars['ID']['input'];
  residencyId?: InputMaybe<Scalars['ID']['input']>;
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  unitId: Scalars['ID']['input'];
};

export type InviteTenantAutopayInput = {
  id: Scalars['ID']['input'];
};

export type JeDraftBatch = {
  __typename?: 'JEDraftBatch';
  id: Scalars['ID']['output'];
  listPrototypes?: Maybe<JePrototypeConnection>;
  name: Scalars['String']['output'];
  posted?: Maybe<Scalars['String']['output']>;
  presetId?: Maybe<Scalars['ID']['output']>;
  prototypes?: Maybe<Array<Maybe<JePrototype>>>;
};

export type JeDraftBatchListPrototypesArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type JePrototype = {
  __typename?: 'JEPrototype';
  fields?: Maybe<Array<Maybe<JePrototypeField>>>;
  id: Scalars['ID']['output'];
  omit?: Maybe<Scalars['Boolean']['output']>;
};

export type JePrototypeConnection = {
  __typename?: 'JEPrototypeConnection';
  items?: Maybe<Array<Maybe<JePrototype>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type JePrototypeField = {
  __typename?: 'JEPrototypeField';
  editable?: Maybe<Scalars['Boolean']['output']>;
  formula?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  secondary?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<BooksFieldType>;
  value?: Maybe<Scalars['String']['output']>;
};

export type JePrototypeInput = {
  fields?: InputMaybe<Array<InputMaybe<BooksFieldInput>>>;
  id: Scalars['ID']['input'];
  omit?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JePrototypeKey = {
  __typename?: 'JEPrototypeKey';
  batchId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
};

export type JePrototypeKeysInput = {
  ids: Array<Scalars['ID']['input']>;
  omit: Scalars['Boolean']['input'];
};

export type JePrototypeKeysPayload = {
  __typename?: 'JEPrototypeKeysPayload';
  error?: Maybe<Scalars['String']['output']>;
  prototypeKeys?: Maybe<Array<Maybe<JePrototypeKey>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type JournalEntriesFilterInput = {
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  ownerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  range?: InputMaybe<PostedRangeInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type JournalEntriesForRecPage = RelayPage & {
  __typename?: 'JournalEntriesForRecPage';
  clearedCount: Scalars['Int']['output'];
  clearedDepositsCount: Scalars['Int']['output'];
  clearedWithdrawalsCount: Scalars['Int']['output'];
  edges: Array<JournalEntryEdge>;
  pageInfo: RelayPageInfo;
  totalCleared: Scalars['Float']['output'];
  totalCount: Scalars['Int']['output'];
  totalDeposits: Scalars['Float']['output'];
  totalDepositsCleared: Scalars['Float']['output'];
  totalDepositsUncleared: Scalars['Float']['output'];
  totalUncleared: Scalars['Float']['output'];
  totalWithdrawals: Scalars['Float']['output'];
  totalWithdrawalsCleared: Scalars['Float']['output'];
  totalWithdrawalsUncleared: Scalars['Float']['output'];
  unclearedCount: Scalars['Int']['output'];
  unclearedDepositsCount: Scalars['Int']['output'];
  unclearedWithdrawalsCount: Scalars['Int']['output'];
};

export type JournalEntriesPage = RelayPage & {
  __typename?: 'JournalEntriesPage';
  edges: Array<JournalEntryEdge>;
  endingBalance: Scalars['Float']['output'];
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type JournalEntriesSortInput = {
  direction: SortDirection;
  field: JournalSortField;
};

export type JournalEntry = {
  __typename?: 'JournalEntry';
  amount: Scalars['Float']['output'];
  approvalId?: Maybe<Scalars['String']['output']>;
  approverName?: Maybe<Scalars['String']['output']>;
  batchId?: Maybe<Scalars['String']['output']>;
  buildingKey?: Maybe<Scalars['String']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  clearable?: Maybe<Clearable>;
  clearableId?: Maybe<Scalars['ID']['output']>;
  clearableLinked?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  draftReconciliationId?: Maybe<Scalars['ID']['output']>;
  glAccount: GlAccount;
  glId: Scalars['ID']['output'];
  gstAmount?: Maybe<Scalars['Float']['output']>;
  gstInfo?: Maybe<GstInfo>;
  id: Scalars['ID']['output'];
  jeId: Scalars['ID']['output'];
  journalEntries?: Maybe<Array<JournalEntry>>;
  journalIds?: Maybe<Array<Scalars['ID']['output']>>;
  lines: Array<JournalEntryLine>;
  notes?: Maybe<Array<Maybe<Note>>>;
  ownerId: Scalars['ID']['output'];
  ownerName?: Maybe<Scalars['String']['output']>;
  payee?: Maybe<PayeeType>;
  payeeId?: Maybe<Scalars['ID']['output']>;
  payeeName?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  posted: Scalars['AWSDate']['output'];
  printedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  property: Property;
  propertyId: Scalars['ID']['output'];
  propertyKey?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  reconciliation?: Maybe<Reconciliation>;
  reconciliationId?: Maybe<Scalars['ID']['output']>;
  ref?: Maybe<Scalars['String']['output']>;
  relatedClearables: Array<Clearable>;
  requestId?: Maybe<Scalars['ID']['output']>;
  transactionReferenceId?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Unit>;
  unitId?: Maybe<Scalars['ID']['output']>;
  unitName?: Maybe<Scalars['String']['output']>;
  voidedZ?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type JournalEntryJournalEntriesArgs = {
  filter?: InputMaybe<LinkedEntryFilter>;
};

export type JournalEntryConnection = {
  __typename?: 'JournalEntryConnection';
  items?: Maybe<Array<Maybe<JournalEntry>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type JournalEntryEdge = RelayEdge & {
  __typename?: 'JournalEntryEdge';
  cursor: Scalars['ID']['output'];
  node: JournalEntry;
};

export type JournalEntryLine = {
  __typename?: 'JournalEntryLine';
  amount: Scalars['Float']['output'];
  clearableId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  glId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  ownerId: Scalars['ID']['output'];
  payee?: Maybe<PayeeType>;
  payeeId?: Maybe<Scalars['ID']['output']>;
  propertyId: Scalars['ID']['output'];
  ref?: Maybe<Scalars['String']['output']>;
  requestId?: Maybe<Scalars['ID']['output']>;
  unitId?: Maybe<Scalars['ID']['output']>;
};

export type JournalEntryLineInput = {
  amount: Scalars['Float']['input'];
  batchId?: InputMaybe<Scalars['ID']['input']>;
  chargeback?: InputMaybe<Scalars['Boolean']['input']>;
  clearableId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  glId: Scalars['ID']['input'];
  gstInfo?: InputMaybe<GstInfo>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ownerId: Scalars['ID']['input'];
  payee?: InputMaybe<PayeeType>;
  payeeId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  printedZ?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['ID']['input'];
  reconciliationId?: InputMaybe<Scalars['ID']['input']>;
  ref?: InputMaybe<Scalars['String']['input']>;
  unitId?: InputMaybe<Scalars['ID']['input']>;
};

export type JournalEntryPayload = {
  __typename?: 'JournalEntryPayload';
  books?: Maybe<Books>;
  error?: Maybe<Scalars['String']['output']>;
  journalEntries?: Maybe<Array<JournalEntry>>;
  journalIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  recurrence?: Maybe<Recurrence>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type JournalForReconciliationPayload = {
  __typename?: 'JournalForReconciliationPayload';
  error?: Maybe<Scalars['String']['output']>;
  journalEntries?: Maybe<Array<Scalars['AWSJSON']['output']>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type JournalKey = {
  __typename?: 'JournalKey';
  id: Scalars['ID']['output'];
  posted: Scalars['AWSDate']['output'];
};

export type JournalKeyInput = {
  id: Scalars['ID']['input'];
  posted: Scalars['AWSDate']['input'];
};

export type JournalKeysPayload = {
  __typename?: 'JournalKeysPayload';
  error?: Maybe<Scalars['String']['output']>;
  journals?: Maybe<Array<Maybe<JournalKey>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type JournalRecFilter = {
  glId: Scalars['ID']['input'];
  propertyOwnerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  reconciliationId: Scalars['ID']['input'];
  showCleared?: InputMaybe<Scalars['Boolean']['input']>;
  showUncleared?: InputMaybe<Scalars['Boolean']['input']>;
  statementDate?: InputMaybe<Scalars['AWSDate']['input']>;
};

export enum JournalSortField {
  Amount = 'amount',
  BatchId = 'batchId',
  BuildingKey = 'buildingKey',
  BuildingName = 'buildingName',
  Description = 'description',
  OwnerName = 'ownerName',
  PayeeName = 'payeeName',
  PaymentMethodRef = 'paymentMethodRef',
  Posted = 'posted',
  PropertyKey = 'propertyKey',
  PropertyName = 'propertyName',
  PropertyUnitName = 'propertyUnitName',
  Ref = 'ref',
  UnitName = 'unitName',
}

export enum JournalTransactionType {
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
}

export type Keyring = {
  __typename?: 'Keyring';
  name: Scalars['String']['output'];
  unitId?: Maybe<Scalars['String']['output']>;
};

export type Kyc = {
  __typename?: 'Kyc';
  bankInfoMatched?: Maybe<Scalars['Boolean']['output']>;
  businessNumberVerified?: Maybe<Scalars['Boolean']['output']>;
  completed?: Maybe<Scalars['AWSDate']['output']>;
  customerType?: Maybe<CustomerType>;
  noOfEmployees?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  riskProfile?: Maybe<RiskProfile>;
  signupMethod?: Maybe<KycSignupMethod>;
  trainingForStaff?: Maybe<Scalars['Boolean']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export enum KycSignupMethod {
  Operations = 'Operations',
  Self = 'Self',
}

export type Lease = {
  __typename?: 'Lease';
  additionalProvisions?: Maybe<AdditionalProvisions>;
  applicationId?: Maybe<Scalars['String']['output']>;
  autoRenewSetup?: Maybe<AutoRenewSetup>;
  campaignId?: Maybe<Scalars['String']['output']>;
  chainedLease?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  end?: Maybe<Scalars['String']['output']>;
  fingerprints?: Maybe<Fingerprints>;
  fullTenants?: Maybe<Array<Maybe<Tenant>>>;
  gst?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  incentives?: Maybe<Array<Maybe<LeaseIncentive>>>;
  insurancePolicy?: Maybe<LeaseInsurancePolicy>;
  leaseAgreement?: Maybe<LeaseAgreement>;
  moveInDate?: Maybe<Scalars['AWSDate']['output']>;
  moveInInspection?: Maybe<LeaseInspection>;
  moveOutDate?: Maybe<Scalars['AWSDate']['output']>;
  moveOutInspection?: Maybe<LeaseInspection>;
  moveOutReason?: Maybe<MoveOutReason>;
  notes?: Maybe<Array<Maybe<Note>>>;
  occupancy?: Maybe<UnitOccupancy>;
  originalLeaseId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use preferredPaymentMethod on Tenant instead. */
  preferredPaymentMethod?: Maybe<PaymentMethod>;
  property?: Maybe<Property>;
  propertyId?: Maybe<Scalars['ID']['output']>;
  rateSchedule?: Maybe<Array<Maybe<RateSchedule>>>;
  renewedLeaseId?: Maybe<Scalars['String']['output']>;
  rent: Scalars['Float']['output'];
  rentRollFees?: Maybe<Array<Maybe<RentRollFee>>>;
  securityDeposit?: Maybe<Scalars['Float']['output']>;
  securityDepositHistory?: Maybe<Array<SecurityDepositHistory>>;
  start?: Maybe<Scalars['String']['output']>;
  status?: Maybe<LeaseStatus>;
  tenants?: Maybe<Array<Maybe<LeaseTenant>>>;
  type?: Maybe<LeaseType>;
  unit?: Maybe<Unit>;
  unitId?: Maybe<Scalars['ID']['output']>;
  utilities?: Maybe<Array<Scalars['String']['output']>>;
};

export type LeaseAgreement = {
  __typename?: 'LeaseAgreement';
  additionalClause?: Maybe<Array<Scalars['String']['output']>>;
  additionalProvisions?: Maybe<AdditionalProvisions>;
  agreementFileKey?: Maybe<Scalars['String']['output']>;
  history?: Maybe<Array<StatusHistoryEvent>>;
  houseRules?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  lease?: Maybe<AgreementLease>;
  signatures?: Maybe<Array<Signature>>;
  status?: Maybe<LeaseAgreementStatus>;
  unitId?: Maybe<Scalars['ID']['output']>;
  updatedZ?: Maybe<Scalars['String']['output']>;
  utilities?: Maybe<Array<Scalars['String']['output']>>;
};

export type LeaseAgreementInput = {
  additionalClause?: InputMaybe<Array<Scalars['String']['input']>>;
  additionalProvisions?: InputMaybe<AdditionalProvisionsInput>;
  generatedZ: Scalars['String']['input'];
  houseRules?: InputMaybe<Array<Scalars['String']['input']>>;
  lease: LeaseInput;
  signatures?: InputMaybe<Array<InputMaybe<SignatureInput>>>;
  utilities?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum LeaseAgreementStatus {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Offered = 'OFFERED',
}

export type LeaseAgreementUrl = {
  __typename?: 'LeaseAgreementUrl';
  accountFileName: Scalars['String']['output'];
  accountFileUrl: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  profileFileName: Scalars['String']['output'];
  profileFileUrl: Scalars['String']['output'];
};

export type LeaseAgreementUrlInput = {
  agreement: LeaseAgreementInput;
  applicationId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type LeaseAgreementUrlPayload = {
  __typename?: 'LeaseAgreementUrlPayload';
  agreementUrls?: Maybe<Array<AgreementUrl>>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type LeaseConnection = {
  __typename?: 'LeaseConnection';
  items?: Maybe<Array<Maybe<Lease>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type LeaseConnectionFilter = {
  activeOnDate?: InputMaybe<Scalars['AWSDate']['input']>;
  expiringInEnd?: InputMaybe<Scalars['AWSDate']['input']>;
  expiringInStart?: InputMaybe<Scalars['AWSDate']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  ownerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export enum LeaseDocumentLeaseType {
  Commercial = 'commercial',
  Rental = 'rental',
}

export type LeaseDocumentTemplate = {
  __typename?: 'LeaseDocumentTemplate';
  baseDocument?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  leaseType: LeaseDocumentLeaseType;
  name: Scalars['String']['output'];
  propertyIds?: Maybe<Array<Scalars['ID']['output']>>;
  schemas?: Maybe<Scalars['AWSJSON']['output']>;
};

export type LeaseDocumentTemplateConnection = {
  __typename?: 'LeaseDocumentTemplateConnection';
  items?: Maybe<Array<Maybe<LeaseDocumentTemplate>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type LeaseDocumentTemplatePayload = {
  __typename?: 'LeaseDocumentTemplatePayload';
  error?: Maybe<Scalars['String']['output']>;
  leaseDocumentTemplate?: Maybe<LeaseDocumentTemplate>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type LeaseFilterItemInput = {
  accountId: Scalars['String']['input'];
  leaseId: Scalars['String']['input'];
};

export type LeaseForm = {
  __typename?: 'LeaseForm';
  bindings?: Maybe<Scalars['AWSJSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inputFields?: Maybe<Array<LeaseFormInputField>>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  province: Scalars['String']['output'];
};

export enum LeaseFormFieldType {
  BankAccount = 'bankAccount',
  Check = 'check',
  Date = 'date',
  DateRange = 'dateRange',
  Datetime = 'datetime',
  DueOffset = 'dueOffset',
  GlAccount = 'glAccount',
  Hidden = 'hidden',
  Longtext = 'longtext',
  Money = 'money',
  Options = 'options',
  Owner = 'owner',
  OwnerProperty = 'ownerProperty',
  Payee = 'payee',
  PaymentMethod = 'paymentMethod',
  Properties = 'properties',
  Property = 'property',
  Section = 'section',
  SubAccountHolder = 'subAccountHolder',
  Text = 'text',
  Time = 'time',
  Unit = 'unit',
  UnitPayeeType = 'unitPayeeType',
}

export type LeaseFormInput = {
  id: Scalars['ID']['input'];
  province: Scalars['String']['input'];
};

export type LeaseFormInputField = {
  __typename?: 'LeaseFormInputField';
  defaultValue?: Maybe<Scalars['String']['output']>;
  helpText?: Maybe<Scalars['String']['output']>;
  hiddenExpression?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  options?: Maybe<Array<LeaseFormInputFieldOption>>;
  optionsExpression?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  subLabel?: Maybe<Scalars['String']['output']>;
  type: LeaseFormFieldType;
};

export type LeaseFormInputFieldOption = {
  __typename?: 'LeaseFormInputFieldOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type LeaseFormsFilter = {
  province?: InputMaybe<Scalars['String']['input']>;
};

export type LeaseIncentive = {
  __typename?: 'LeaseIncentive';
  description?: Maybe<Scalars['String']['output']>;
  discountAmount: Scalars['Float']['output'];
  end: Scalars['AWSDate']['output'];
  id: Scalars['ID']['output'];
  start: Scalars['AWSDate']['output'];
  type: Scalars['String']['output'];
};

export type LeaseIncentiveInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  discountAmount: Scalars['Float']['input'];
  end: Scalars['AWSDate']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  start: Scalars['AWSDate']['input'];
  type: Scalars['String']['input'];
};

export type LeaseInput = {
  autoRenewSetup?: InputMaybe<AutoRenewSetupInput>;
  end?: InputMaybe<Scalars['String']['input']>;
  furnishings?: InputMaybe<Array<Scalars['String']['input']>>;
  gst?: InputMaybe<Scalars['Float']['input']>;
  incentives?: InputMaybe<Array<InputMaybe<LeaseIncentiveInput>>>;
  moveInDate?: InputMaybe<Scalars['AWSDate']['input']>;
  moveOutDate?: InputMaybe<Scalars['AWSDate']['input']>;
  occupancy?: InputMaybe<UnitOccupancy>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  refundables?: InputMaybe<Array<RefundableInput>>;
  rent: Scalars['Float']['input'];
  rentRollFees?: InputMaybe<Array<InputMaybe<FeesInput>>>;
  rrule?: InputMaybe<Scalars['String']['input']>;
  securityDeposit?: InputMaybe<Scalars['Float']['input']>;
  start: Scalars['String']['input'];
  tenants: Array<LeaseTenantInput>;
  unitOwnerId?: InputMaybe<Scalars['ID']['input']>;
};

export type LeaseInspection = {
  __typename?: 'LeaseInspection';
  accountId: Scalars['ID']['output'];
  attempts?: Maybe<Array<Maybe<InspectionAttempt>>>;
  category?: Maybe<InspectionCategory>;
  completedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  costAgreement?: Maybe<Agreement>;
  generatedSummary?: Maybe<InspectionSummary>;
  id: Scalars['ID']['output'];
  /** @deprecated Use `inspectionAgreements` instead. */
  inspectionAgreement?: Maybe<Agreement>;
  inspectionAgreements?: Maybe<Array<Maybe<Agreement>>>;
  items?: Maybe<Array<Maybe<InspectionItem>>>;
  lastSyncedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  propertyId?: Maybe<Scalars['ID']['output']>;
  reportKeys?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  requests?: Maybe<Array<Maybe<LeaseInspectionRequest>>>;
  residencyId?: Maybe<Scalars['ID']['output']>;
  signatures?: Maybe<Array<Maybe<Signature>>>;
  status?: Maybe<InspectionStatus>;
  templateId: Scalars['ID']['output'];
  tenantMailingAddress?: Maybe<Address>;
  unitId?: Maybe<Scalars['ID']['output']>;
};

export type LeaseInspectionCategoryInput = {
  category?: InputMaybe<InspectionCategory>;
};

export type LeaseInspectionInput = {
  attempts?: InputMaybe<Array<InputMaybe<InspectionAttemptInput>>>;
  category?: InputMaybe<InspectionCategory>;
  completedZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  costAgreement?: InputMaybe<AgreementInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inspectionAgreement?: InputMaybe<AgreementInput>;
  inspectionAgreements?: InputMaybe<Array<InputMaybe<AgreementInput>>>;
  items?: InputMaybe<Array<InputMaybe<InspectionItemInput>>>;
  reportKeys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  signatures?: InputMaybe<Array<InputMaybe<SignatureInput>>>;
  status?: InputMaybe<InspectionStatus>;
  templateId: Scalars['ID']['input'];
  tenantMailingAddress?: InputMaybe<AddressInput>;
};

export type LeaseInspectionPayload = {
  __typename?: 'LeaseInspectionPayload';
  error?: Maybe<Scalars['String']['output']>;
  residency?: Maybe<Residency>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type LeaseInspectionRequest = {
  __typename?: 'LeaseInspectionRequest';
  fieldId: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  requestId: Scalars['ID']['output'];
};

export type LeaseInspectionUrlInput = {
  leaseId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type LeaseInspectionUrlPayload = {
  __typename?: 'LeaseInspectionUrlPayload';
  error?: Maybe<Scalars['String']['output']>;
  inspectionUrlObject?: Maybe<InspectionUrlObject>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type LeaseInsurancePolicy = {
  __typename?: 'LeaseInsurancePolicy';
  expirationDate?: Maybe<Scalars['AWSDate']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  liability?: Maybe<Scalars['Float']['output']>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
};

export type LeaseInsurancePolicyInput = {
  expirationDate?: InputMaybe<Scalars['AWSDate']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  liability?: InputMaybe<Scalars['Float']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  providerName?: InputMaybe<Scalars['String']['input']>;
};

export type LeaseOfferInput = {
  applicationId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type LeasePayload = {
  __typename?: 'LeasePayload';
  error?: Maybe<Scalars['String']['output']>;
  lease?: Maybe<Lease>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum LeaseStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Inactive = 'INACTIVE',
  Terminated = 'TERMINATED',
}

export type LeaseTenant = {
  __typename?: 'LeaseTenant';
  correspondenceMethod?: Maybe<CorrespondenceMethod>;
  dob?: Maybe<Scalars['AWSDate']['output']>;
  emails?: Maybe<Array<Maybe<Scalars['AWSEmail']['output']>>>;
  emergencyContacts?: Maybe<Array<Maybe<EmergencyContact>>>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isOwner?: Maybe<Scalars['Boolean']['output']>;
  isPayee?: Maybe<Scalars['Boolean']['output']>;
  leaseHolder?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  padAccount?: Maybe<Scalars['String']['output']>;
  padAmount?: Maybe<Scalars['Float']['output']>;
  padInstitution?: Maybe<Scalars['String']['output']>;
  padTransit?: Maybe<Scalars['String']['output']>;
  payeeFrac?: Maybe<Fraction>;
  pets?: Maybe<Array<Pet>>;
  phone?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<Scalars['AWSPhone']['output']>>>;
  preferredPaymentMethod?: Maybe<PaymentMethod>;
  sendWelcomeEmail?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  vehicles?: Maybe<Array<Vehicle>>;
};

export type LeaseTenantInput = {
  correspondenceMethod?: InputMaybe<CorrespondenceMethod>;
  dob?: InputMaybe<Scalars['AWSDate']['input']>;
  emails?: InputMaybe<Array<InputMaybe<Scalars['AWSEmail']['input']>>>;
  emergencyContacts?: InputMaybe<Array<InputMaybe<EmergencyContactInput>>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isPayee?: InputMaybe<Scalars['Boolean']['input']>;
  isRentalAssist?: InputMaybe<Scalars['Boolean']['input']>;
  leaseHolder?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  padAccount?: InputMaybe<Scalars['String']['input']>;
  padAmount?: InputMaybe<Scalars['Float']['input']>;
  padInstitution?: InputMaybe<Scalars['String']['input']>;
  padTransit?: InputMaybe<Scalars['String']['input']>;
  payeeFrac?: InputMaybe<FractionInput>;
  paymentPct?: InputMaybe<Scalars['Float']['input']>;
  pets?: InputMaybe<Array<PetInput>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<InputMaybe<Scalars['AWSPhone']['input']>>>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  rentalAssistance?: InputMaybe<RentalAssistanceInput>;
  sendWelcomeEmail?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  vehicles?: InputMaybe<Array<VehicleInput>>;
};

export enum LeaseType {
  DoubleNet = 'double_net',
  GrossRent = 'gross_rent',
  ModifiedGrossRent = 'modified_gross_rent',
  Net = 'net',
  PercentageRent = 'percentage_rent',
  TripleNet = 'triple_net',
}

export type LeaseUnitPayload = {
  __typename?: 'LeaseUnitPayload';
  error?: Maybe<Scalars['String']['output']>;
  renewedLeaseId?: Maybe<Scalars['ID']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  unit?: Maybe<Unit>;
};

export type LinkSupplierInput = {
  autopayAccount?: InputMaybe<Scalars['String']['input']>;
  autopayInstitution?: InputMaybe<Scalars['String']['input']>;
  autopayTransit?: InputMaybe<Scalars['String']['input']>;
  billingEmail?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  coi?: InputMaybe<Scalars['Boolean']['input']>;
  coiExpiry?: InputMaybe<Scalars['AWSDate']['input']>;
  supplierAccounts?: InputMaybe<Array<SupplierAccountInput>>;
};

export type LinkedAccount = {
  __typename?: 'LinkedAccount';
  accountDetails?: Maybe<AccountDetail>;
  flinksBankAccountId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  institutionName: Scalars['String']['output'];
  loginId: Scalars['String']['output'];
  requestId?: Maybe<Scalars['String']['output']>;
};

export type LinkedAccountInput = {
  bankAccountIds: Array<Scalars['String']['input']>;
  institutionName: Scalars['String']['input'];
  loginId: Scalars['String']['input'];
  requestId?: InputMaybe<Scalars['String']['input']>;
};

export type LinkedEntryFilter = {
  excludeSelf?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ListedUnit = {
  __typename?: 'ListedUnit';
  bathrooms?: Maybe<Scalars['Float']['output']>;
  bedrooms?: Maybe<Scalars['Float']['output']>;
  exteriorSqFt?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  rent?: Maybe<Scalars['Float']['output']>;
  sqft?: Maybe<Scalars['Float']['output']>;
};

export type ListedUnitInput = {
  bathrooms?: InputMaybe<Scalars['Float']['input']>;
  bedrooms?: InputMaybe<Scalars['Float']['input']>;
  exteriorSqFt?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  rent?: InputMaybe<Scalars['Float']['input']>;
  sqft?: InputMaybe<Scalars['Float']['input']>;
};

export type MaintenanceApproval = ApprovalRecord & {
  __typename?: 'MaintenanceApproval';
  accountId: Scalars['String']['output'];
  approvedSub?: Maybe<Scalars['ID']['output']>;
  approvedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  approverRoles?: Maybe<Array<Maybe<ApproverRole>>>;
  declinedSub?: Maybe<Scalars['ID']['output']>;
  declinedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['String']['output'];
  request: MaintenanceApprovalRequest;
  requestedName?: Maybe<Scalars['String']['output']>;
  requestedSub: Scalars['ID']['output'];
  requestedZ: Scalars['AWSDateTime']['output'];
  status: ApprovalStatus;
};

export type MaintenanceApprovalPayload = {
  __typename?: 'MaintenanceApprovalPayload';
  error?: Maybe<Scalars['String']['output']>;
  request?: Maybe<Request>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MaintenanceApprovalRequest = {
  __typename?: 'MaintenanceApprovalRequest';
  amount: Scalars['Float']['output'];
  description?: Maybe<Scalars['String']['output']>;
  ownerId: Scalars['ID']['output'];
  propertyId: Scalars['ID']['output'];
  requestId: Scalars['ID']['output'];
  unitId?: Maybe<Scalars['ID']['output']>;
};

export enum ManagementFeeBasis {
  Charged = 'charged',
  Collected = 'collected',
}

export type Manager = {
  __typename?: 'Manager';
  account: Account;
  accountId?: Maybe<Scalars['ID']['output']>;
  email: Scalars['AWSEmail']['output'];
  enabled: Scalars['Boolean']['output'];
  fingerprints?: Maybe<Fingerprints>;
  id: Scalars['ID']['output'];
  locale?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['AWSPhone']['output']>;
  pushTokens?: Maybe<Array<PushToken>>;
  /** @deprecated Use `roles` instead. */
  role: ManagerRole;
  roles: Array<ManagerRole>;
  signature?: Maybe<Signature>;
  subscriptions?: Maybe<Array<MessageSubscription>>;
  unreadPushNotifications?: Maybe<Scalars['Int']['output']>;
};

export type ManagerContactInformation = {
  __typename?: 'ManagerContactInformation';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type ManagerContactInformationInput = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type ManagerPayload = {
  __typename?: 'ManagerPayload';
  error?: Maybe<Scalars['String']['output']>;
  manager?: Maybe<Manager>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum ManagerRole {
  Admin = 'ADMIN',
  BooksAdmin = 'BOOKS_ADMIN',
  BooksAdvanced = 'BOOKS_ADVANCED',
  BooksReporter = 'BOOKS_REPORTER',
  BooksUser = 'BOOKS_USER',
  Inspector = 'INSPECTOR',
  User = 'USER',
}

export type ManagerRoleInput = {
  id: Scalars['ID']['input'];
  role: ManagerRole;
};

export type MarkAppointmentChangesReadInput = {
  appointmentChangeIds: Array<Scalars['ID']['input']>;
  requestId: Scalars['ID']['input'];
};

export type MarkAppointmentChangesReadPayload = {
  __typename?: 'MarkAppointmentChangesReadPayload';
  error?: Maybe<Scalars['String']['output']>;
  request?: Maybe<Request>;
  success: Scalars['Boolean']['output'];
};

export type MessageSubscription = {
  __typename?: 'MessageSubscription';
  endpoints: Array<SubscriptionEndpoint>;
  id: Scalars['String']['output'];
};

export type MessageSubscriptionInput = {
  endpoints: Array<SubscriptionEndpointInput>;
  id: Scalars['String']['input'];
};

export enum MoveOutReason {
  CircumstanceChange = 'CIRCUMSTANCE_CHANGE',
  EarlyMoveOut = 'EARLY_MOVE_OUT',
  Evicted = 'EVICTED',
  Expired = 'EXPIRED',
  None = 'NONE',
  Sublet = 'SUBLET',
  Terminated = 'TERMINATED',
  UnitNoLongerSuitable = 'UNIT_NO_LONGER_SUITABLE',
}

export type MoveUnitsToBuildingInput = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  unitIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  abortBooksBatchTask: BatchTaskPayload;
  /** @deprecated Use `cancelBooksBatch` instead */
  abortDraftBatch?: Maybe<SuccessPayload>;
  accountEvent?: Maybe<AccountEvent>;
  activateAutopay: BooksPayload;
  activateBooks: ActivateBooksPayload;
  activatePropertiesAutopay: BooksPayload;
  addAnnouncementReply?: Maybe<AnnouncementPayload>;
  addAutopayBank: BooksPayload;
  addBuildingInsurance: InsurancePolicyPayload;
  addBuildingInsuranceClaim?: Maybe<ClaimPayload>;
  addBuildingInsuranceHistory?: Maybe<InsurancePolicyPayload>;
  addBuildingInsuranceHistoryClaim?: Maybe<ClaimPayload>;
  addClearable: ClearablePayload;
  addClearingEntryTask: BatchTaskPayload;
  addJournalEntry: JournalEntryPayload;
  /** @deprecated use `addAutopayBank` instead */
  addLinkedBankAccounts: BooksPayload;
  addManagerRole: ManagerPayload;
  addOwnerProperty?: Maybe<OwnerPayload>;
  addOwnerUnit?: Maybe<OwnerPayload>;
  addPaymentsBatchPrototypes: SuccessPayload;
  addPropertyAutopayBankAccount?: Maybe<Property>;
  addPropertyImages: PropertyPayload;
  addPropertyInsurance: InsurancePolicyPayload;
  addPropertyInsuranceClaim?: Maybe<ClaimPayload>;
  addPropertyInsuranceHistory?: Maybe<InsurancePolicyPayload>;
  addPropertyInsuranceHistoryClaim?: Maybe<ClaimPayload>;
  addPropertyKeyring?: Maybe<PropertyPayload>;
  addPropertyParking?: Maybe<PropertyPayload>;
  addPropertyStorage?: Maybe<PropertyPayload>;
  addPushToken?: Maybe<ManagerPayload>;
  addReconciliationConfig?: Maybe<ReconciliationConfigPayload>;
  addRequestImages: RequestPayload;
  addResidencyEvent: ResidencyPayload;
  addResidencySecurityDepositStatus: ResidencyPayload;
  addScanApprovalTask: BatchTaskPayload;
  addScanMatchingTask: BatchTaskPayload;
  approveChargeApproval?: Maybe<SuccessPayload>;
  approvePaymentsApproval?: Maybe<SuccessPayload>;
  approveScanApproval?: Maybe<SuccessPayload>;
  assignKeyring?: Maybe<AssignKeyringPayload>;
  assignParking?: Maybe<AssignParkingPayload>;
  assignRequestVisit?: Maybe<UpdateVisitPayload>;
  assignStorage?: Maybe<AssignStoragePayload>;
  cancelBooksBatch?: Maybe<BooksBatchPayload>;
  cancelPublishAnnouncement?: Maybe<AnnouncementPayload>;
  commitBooksBatch?: Maybe<BooksBatchPayload>;
  completeReconciliation?: Maybe<ReconciliationPayload>;
  createAccountAutopayOnboardingUrl?: Maybe<AutopayOnboardingUrlPayload>;
  createAnnouncement?: Maybe<AnnouncementPayload>;
  createApplicationNote?: Maybe<CreateApplicationNotePayload>;
  createBuilding?: Maybe<CreateBuildingPayload>;
  createBuildingFloorplan?: Maybe<CreateFloorplanPayload>;
  createBuildingNote?: Maybe<UpdateBuildingPayload>;
  createCampaign: CreateCampaignPayload;
  createCampaignLink: CampaignPayload;
  createCharge?: Maybe<AccountPayload>;
  createChargeApproval?: Maybe<ApprovalPayload>;
  createContact: ContactPayload;
  /** @deprecated Use `initiateBooksBatch` instead */
  createDraftBatch: CreateDraftJeBatchPayload;
  createFee?: Maybe<AccountPayload>;
  createGLAccount: CreateGlAccountPayload;
  createHouseRulesTemplate?: Maybe<Template>;
  createImageNote?: Maybe<Request>;
  createInspectionUrl: InspectionUrlPayload;
  createJournalEntryNote?: Maybe<JournalEntry>;
  createLeaseAgreementUrl: LeaseAgreementUrlPayload;
  createLeaseDocumentTemplate?: Maybe<LeaseDocumentTemplatePayload>;
  createLeaseInspection?: Maybe<LeaseInspectionPayload>;
  createMaintenanceApproval?: Maybe<MaintenanceApprovalPayload>;
  createManager?: Maybe<Manager>;
  createOperator?: Maybe<Operator>;
  createOwner?: Maybe<OwnerPayload>;
  createOwnerNote?: Maybe<OwnerPayload>;
  createPaymentsApproval?: Maybe<PaymentsApprovalPayload>;
  createPaymentsBatch: PaymentsBatchPayload;
  createProperty?: Maybe<PropertyPayload>;
  createPropertyFloorplan?: Maybe<CreateFloorplanPayload>;
  createPropertyNote?: Maybe<PropertyPayload>;
  createReportBundle: ReportBundlePayload;
  createRequest?: Maybe<CreateRequestPayload>;
  createRequestCost?: Maybe<CreateRequestCostPayload>;
  createRequestNote?: Maybe<CreateRequestNotePayload>;
  createRequestRecurrence?: Maybe<RequestRecurrencePayload>;
  createRequestVisit?: Maybe<CreateVisitPayload>;
  createResidency: ResidencyPayload;
  createResidencyNote: ResidencyPayload;
  createRoutineInspection?: Maybe<RoutineInspectionPayload>;
  createSupplier: SupplierPayload;
  createTeam?: Maybe<TeamPayload>;
  createTemplate?: Maybe<Template>;
  createTenantNote?: Maybe<TenantPayload>;
  createUnitNote?: Maybe<UnitPayload>;
  createUnitOnProperty?: Maybe<UnitPayload>;
  createUnits?: Maybe<SuccessPayload>;
  deactivateAutopay: BooksPayload;
  deactivatePropertiesAutopay: BooksPayload;
  declineChargeApproval?: Maybe<SuccessPayload>;
  declinePaymentsApproval?: Maybe<SuccessPayload>;
  declineScanApproval?: Maybe<SuccessPayload>;
  deleteAnnouncement?: Maybe<SuccessPayload>;
  deleteApplicationNote?: Maybe<DeleteApplicationNotePayload>;
  deleteAutopayBank: BooksPayload;
  deleteBuilding?: Maybe<DeleteBuildingPayload>;
  deleteBuildingInsuranceClaim?: Maybe<DeleteClaimPayload>;
  deleteBuildingInsuranceHistory?: Maybe<InsurancePolicyPayload>;
  deleteBuildingInsuranceHistoryClaim?: Maybe<DeleteClaimPayload>;
  deleteBuildingNote?: Maybe<UpdateBuildingPayload>;
  deleteBundledReport: SuccessPayload;
  deleteCharge?: Maybe<AccountPayload>;
  deleteClearable: DeleteClearablePayload;
  deleteContact: ContactPayload;
  deleteFee?: Maybe<AccountPayload>;
  deleteHouseRulesTemplate?: Maybe<Template>;
  deleteImageNote?: Maybe<Request>;
  deleteJournalEntry?: Maybe<SuccessPayload>;
  deleteJournalEntryNote?: Maybe<JournalEntry>;
  deleteLeaseDocumentTemplate?: Maybe<LeaseDocumentTemplatePayload>;
  deleteLeaseInspection?: Maybe<LeaseInspectionPayload>;
  deleteLinkedBankAccount: BooksPayload;
  deleteManager?: Maybe<Manager>;
  deleteOperator?: Maybe<Operator>;
  deleteOwner?: Maybe<OwnerPayload>;
  deleteOwnerNote?: Maybe<OwnerPayload>;
  deleteProperty: SuccessPayload;
  deletePropertyAutopayBankAccount?: Maybe<Property>;
  deletePropertyImage: PropertyPayload;
  deletePropertyInsuranceClaim?: Maybe<DeleteClaimPayload>;
  deletePropertyInsuranceHistory?: Maybe<InsurancePolicyPayload>;
  deletePropertyInsuranceHistoryClaim?: Maybe<DeleteClaimPayload>;
  deletePropertyNote?: Maybe<PropertyPayload>;
  deletePushTokens?: Maybe<ManagerPayload>;
  deleteReconciliation?: Maybe<RemoveReconciliationPayload>;
  deleteReconciliationConfig?: Maybe<ReconciliationConfigPayload>;
  deleteRecurrence?: Maybe<SuccessPayload>;
  deleteReportBundle: SuccessPayload;
  deleteRequestCost?: Maybe<RequestPayload>;
  deleteRequestImage: RequestPayload;
  deleteRequestNote?: Maybe<DeleteRequestNotePayload>;
  deleteRequestRecurrence?: Maybe<SuccessPayload>;
  deleteResidency: UnitPayload;
  deleteResidencyEvent: ResidencyPayload;
  deleteResidencyNote: ResidencyPayload;
  deleteRoutineInspection?: Maybe<RoutineInspectionPayload>;
  deleteSupplier: SupplierPayload;
  deleteTeam?: Maybe<SuccessPayload>;
  deleteTemplate?: Maybe<Template>;
  deleteTenantNote?: Maybe<TenantPayload>;
  deleteUnitNote?: Maybe<UnitPayload>;
  deleteVisitBill?: Maybe<UpdateVisitPayload>;
  draftReconciliation?: Maybe<DraftReconciliationPayload>;
  enableManager?: Maybe<Manager>;
  finalizeRequest?: Maybe<FinalizeRequestPayload>;
  finalizeRequestVisit?: Maybe<UpdateVisitPayload>;
  iamMutation?: Maybe<IamMutation>;
  initiateBooksBatch: BooksBatchPayload;
  inviteOwnerAutopay?: Maybe<OwnerPayload>;
  invitePayeeToEnroll: AutopayEnrollmentPayload;
  inviteTenantAutopay?: Maybe<TenantPayload>;
  linkSupplier: SupplierPayload;
  markRequestAppointmentChangesRead?: Maybe<MarkAppointmentChangesReadPayload>;
  moveUnitsToBuilding?: Maybe<SuccessPayload>;
  notifyNsfPayee?: Maybe<SuccessPayload>;
  /** @deprecated Use `commitBooksBatch` instead */
  postDraftBatch: PostDraftJeBatchPayload;
  printJournalEntries?: Maybe<ChequePayload>;
  publishAnnouncement?: Maybe<AnnouncementPayload>;
  putBudgets: BudgetsPayload;
  refreshRequestImages?: Maybe<RequestPayload>;
  refundClearable: ClearablePayload;
  removeManagerRole: ManagerPayload;
  removeManagerSignature?: Maybe<Manager>;
  removeOwnerProperty?: Maybe<OwnerPayload>;
  removeOwnerUnit?: Maybe<OwnerPayload>;
  removePropertyKeyring?: Maybe<PropertyPayload>;
  removePropertyParking?: Maybe<PropertyPayload>;
  removePropertyStorage?: Maybe<PropertyPayload>;
  removeResidencyInsurance: ResidencyPayload;
  removeResidencyResident: ResidencyPayload;
  removeUnitFloorplan?: Maybe<UnitPayload>;
  renameBuildingDocument: UpdateBuildingPayload;
  renameOwnerDocument?: Maybe<OwnerPayload>;
  renamePropertyDocument: PropertyPayload;
  renameRequestDocument: RequestPayload;
  renameResidencyDocument: ResidencyPayload;
  renameTenantDocument: TenantPayload;
  renameUnitDocument: UnitPayload;
  resendAutopayInviteToOwner: OwnerPayload;
  resendAutopayInviteToTenant: TenantPayload;
  runReportSet: ReportSetPayload;
  saveLeaseAgreement?: Maybe<ApplicationPayload>;
  scheduleRequestVisit?: Maybe<UpdateVisitPayload>;
  sendLeaseOffer?: Maybe<ApplicationPayload>;
  setBundledReportSharedFlag: BundledReportPayload;
  setClearableAutopayCollectionsEnabled: ClearablePayload;
  setOwnerAutopayCollectionsEnabled: OwnerPayload;
  setPropertyAutopayCollectionsEnabled: PropertyPayload;
  setTenantAutopayCollectionsEnabled: TenantPayload;
  shareCampaign: SuccessPayload;
  signLeaseAgreement?: Maybe<ApplicationPayload>;
  startAccountAutopay?: Maybe<AccountPayload>;
  startReconciliation?: Maybe<StartReconciliationPayload>;
  terminateResidency: ResidencyPayload;
  testVoiceAnnouncement?: Maybe<SuccessPayload>;
  toggleBatchPrototypes: JePrototypeKeysPayload;
  transferJournalEntries: TransferJournalEntriesPayload;
  unassignKeyring?: Maybe<AssignKeyringPayload>;
  unassignParking?: Maybe<AssignParkingPayload>;
  unassignStorage?: Maybe<AssignStoragePayload>;
  updateAccount?: Maybe<Account>;
  updateAccountAutopay?: Maybe<AccountPayload>;
  updateAnnouncement?: Maybe<AnnouncementPayload>;
  updateApplication?: Maybe<ApplicationPayload>;
  updateAutopayCollectionTypes: BooksPayload;
  updateAutopayEnrollmentToDeclined: AutopayEnrollmentPayload;
  updateAutopayEnrollmentToInvited: AutopayEnrollmentPayload;
  updateAutopayFeeInvoice: BooksPayload;
  updateAutopayNSFFees: BooksPayload;
  updateBatchPrototype: BatchPrototypePayload;
  updateBuilding?: Maybe<UpdateBuildingPayload>;
  updateBuildingAmenities: UpdateBuildingPayload;
  updateBuildingFeatures: UpdateBuildingPayload;
  updateBuildingInsurance: InsurancePolicyPayload;
  updateBuildingInsuranceClaim?: Maybe<ClaimPayload>;
  updateBuildingInsuranceHistory?: Maybe<InsurancePolicyPayload>;
  updateBuildingInsuranceHistoryClaim?: Maybe<ClaimPayload>;
  updateBuildingSpecs: UpdateBuildingPayload;
  updateBundledReportFlag: BundledReportPayload;
  updateCampaign: CampaignPayload;
  updateCampaignStatus?: Maybe<CampaignPayload>;
  updateCharge?: Maybe<AccountPayload>;
  updateClearable: ClearablePayload;
  updateClearingEntryTask: BatchTaskPayload;
  updateContact: ContactPayload;
  updateFee?: Maybe<AccountPayload>;
  updateFinancialSettings: BooksPayload;
  updateFloorplan?: Maybe<FloorplanPayload>;
  updateGLAccount: UpdateGlAccountPayload;
  updateGLChequePlacement: BooksPayload;
  updateHouseRulesTemplate?: Maybe<Template>;
  updateJournalDrafts: SuccessPayload;
  updateJournalEntry: JournalEntryPayload;
  updateLeaseDocumentTemplate?: Maybe<LeaseDocumentTemplatePayload>;
  updateLeaseInspection?: Maybe<LeaseInspectionPayload>;
  updateManager?: Maybe<Manager>;
  updateManagerLocale?: Maybe<Manager>;
  updateManagerSubscriptions: ManagerPayload;
  updateNextCheque: BooksPayload;
  updateOperator?: Maybe<Operator>;
  updateOperatorAutoAP?: Maybe<OperatorPayload>;
  updateOwner?: Maybe<OwnerPayload>;
  updateOwnerAutopay?: Maybe<OwnerPayload>;
  updateOwnerFinancials?: Maybe<OwnerPayload>;
  updateOwnerManagementFee?: Maybe<OwnerPayload>;
  updatePaymentsBatch: PaymentsBatchPayload;
  updatePresetGLMapping: PresetPayload;
  updatePresetRecurrence: PresetPayload;
  updateProperty?: Maybe<Property>;
  updatePropertyAmenities: PropertyPayload;
  updatePropertyContractors: PropertyPayload;
  updatePropertyFeatures: PropertyPayload;
  updatePropertyInsurance: InsurancePolicyPayload;
  updatePropertyInsuranceClaim?: Maybe<ClaimPayload>;
  updatePropertyInsuranceHistory?: Maybe<InsurancePolicyPayload>;
  updatePropertyInsuranceHistoryClaim?: Maybe<ClaimPayload>;
  updatePropertySpecs: PropertyPayload;
  updatePropertySuppliers: PropertyPayload;
  updateReconciliation?: Maybe<ReconciliationPayload>;
  updateReconciliationConfig?: Maybe<ReconciliationConfigPayload>;
  updateReconciliationJournals: SuccessPayload;
  updateReportBundle: ReportBundlePayload;
  updateRequest?: Maybe<Request>;
  updateRequestChecklistItem?: Maybe<RequestPayload>;
  updateRequestCost?: Maybe<RequestPayload>;
  updateRequestRecurrence?: Maybe<RequestRecurrencePayload>;
  updateRequestVisit?: Maybe<UpdateVisitPayload>;
  updateResidency: ResidencyPayload;
  updateResidencyEffects: ResidencyPayload;
  updateResidencyEvent: ResidencyPayload;
  updateResidencyInsurance: ResidencyPayload;
  updateResidencyRefundableDeposits: ResidencyPayload;
  updateResidencyResidentFinancials: ResidencyPayload;
  updateRoutineInspection?: Maybe<RoutineInspectionPayload>;
  updateScanApprovalRequest?: Maybe<ScanApprovalPayload>;
  updateSupplier: SupplierPayload;
  updateSupplierAutoAP: SupplierPayload;
  updateTeam?: Maybe<TeamPayload>;
  updateTemplate?: Maybe<Template>;
  updateTenantAutopay?: Maybe<TenantPayload>;
  updateTenantFinancials?: Maybe<TenantPayload>;
  updateTenantRecord?: Maybe<TenantPayload>;
  updateUnit?: Maybe<UnitPayload>;
  updateUnitFloorplan?: Maybe<UnitPayload>;
  updateUnreadPushNotifications?: Maybe<ManagerPayload>;
  updateVisitBillMarkup?: Maybe<UpdateVisitPayload>;
  upsertLeaseInspection?: Maybe<LeaseInspectionPayload>;
  upsertRoutineInspection?: Maybe<UpsertRoutineInspectionPayload>;
  voidJournalEntry?: Maybe<SuccessPayload>;
  welcomeTenant?: Maybe<TenantPayload>;
};

export type MutationAbortBooksBatchTaskArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAbortDraftBatchArgs = {
  id: Scalars['ID']['input'];
};

export type MutationAccountEventArgs = {
  input: AccountEventInput;
};

export type MutationActivateAutopayArgs = {
  input: ActivateAutopayInput;
};

export type MutationActivateBooksArgs = {
  input: ActivateBooksInput;
};

export type MutationActivatePropertiesAutopayArgs = {
  input: AutopayPropertiesInput;
};

export type MutationAddAnnouncementReplyArgs = {
  input: AddAnnouncementReplyInput;
};

export type MutationAddAutopayBankArgs = {
  input: AddAutopayBankInput;
};

export type MutationAddBuildingInsuranceArgs = {
  input: BuildingInsuranceInput;
};

export type MutationAddBuildingInsuranceClaimArgs = {
  input?: InputMaybe<CreateBuildingInsuranceClaimInput>;
};

export type MutationAddBuildingInsuranceHistoryArgs = {
  input: CreateBuildingInsuranceHistoryInput;
};

export type MutationAddBuildingInsuranceHistoryClaimArgs = {
  input?: InputMaybe<CreateBuildingInsuranceClaimInput>;
};

export type MutationAddClearableArgs = {
  input: AddClearableInput;
};

export type MutationAddClearingEntryTaskArgs = {
  input: AddClearingEntryTaskInput;
};

export type MutationAddJournalEntryArgs = {
  input: AddJournalEntryInput;
};

export type MutationAddLinkedBankAccountsArgs = {
  input: AddLinkedBankAccountsInput;
};

export type MutationAddManagerRoleArgs = {
  input: ManagerRoleInput;
};

export type MutationAddOwnerPropertyArgs = {
  input: AddOwnerPropertyInput;
};

export type MutationAddOwnerUnitArgs = {
  input: AddOwnerUnitInput;
};

export type MutationAddPaymentsBatchPrototypesArgs = {
  inputs: Array<PaymentPrototypeInput>;
};

export type MutationAddPropertyAutopayBankAccountArgs = {
  input: PropertyBankAccountInput;
};

export type MutationAddPropertyImagesArgs = {
  input: PropertyImagesInput;
};

export type MutationAddPropertyInsuranceArgs = {
  input: PropertyInsuranceInput;
};

export type MutationAddPropertyInsuranceClaimArgs = {
  input?: InputMaybe<CreatePropertyInsuranceClaimInput>;
};

export type MutationAddPropertyInsuranceHistoryArgs = {
  input: CreatePropertyInsuranceHistoryInput;
};

export type MutationAddPropertyInsuranceHistoryClaimArgs = {
  input?: InputMaybe<CreatePropertyInsuranceClaimInput>;
};

export type MutationAddPropertyKeyringArgs = {
  input: AddPropertyKeyringInput;
};

export type MutationAddPropertyParkingArgs = {
  input: AddPropertyParkingInput;
};

export type MutationAddPropertyStorageArgs = {
  input: AddPropertyStorageInput;
};

export type MutationAddPushTokenArgs = {
  input: AddPushTokenInput;
};

export type MutationAddReconciliationConfigArgs = {
  input: AddReconciliationConfigInput;
};

export type MutationAddRequestImagesArgs = {
  input: RequestImagesInput;
};

export type MutationAddResidencyEventArgs = {
  input: AddResidencyEventInput;
};

export type MutationAddResidencySecurityDepositStatusArgs = {
  input: AddResidencySecurityDepositStatusInput;
};

export type MutationAddScanApprovalTaskArgs = {
  input: AddScanApprovalTaskInput;
};

export type MutationAddScanMatchingTaskArgs = {
  input: AddScanMatchingTaskInput;
};

export type MutationApproveChargeApprovalArgs = {
  id: Scalars['ID']['input'];
};

export type MutationApprovePaymentsApprovalArgs = {
  id: Scalars['ID']['input'];
};

export type MutationApproveScanApprovalArgs = {
  id: Scalars['ID']['input'];
};

export type MutationAssignKeyringArgs = {
  input: AssignKeyringInput;
};

export type MutationAssignParkingArgs = {
  input: AssignParkingInput;
};

export type MutationAssignRequestVisitArgs = {
  input: UpdateVisitInput;
};

export type MutationAssignStorageArgs = {
  input: AssignStorageInput;
};

export type MutationCancelBooksBatchArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCancelPublishAnnouncementArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCommitBooksBatchArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCompleteReconciliationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCreateAnnouncementArgs = {
  input: CreateAnnouncementInput;
};

export type MutationCreateApplicationNoteArgs = {
  input: CreateApplicationNoteInput;
};

export type MutationCreateBuildingArgs = {
  input: CreateBuildingInput;
};

export type MutationCreateBuildingFloorplanArgs = {
  input: CreateBuildingFloorplanInput;
};

export type MutationCreateBuildingNoteArgs = {
  input: CreateBuildingNoteInput;
};

export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};

export type MutationCreateCampaignLinkArgs = {
  input: CreateCampaignLinkInput;
};

export type MutationCreateChargeArgs = {
  input: CreateChargeInput;
};

export type MutationCreateChargeApprovalArgs = {
  input: CreateChargeApprovalInput;
};

export type MutationCreateContactArgs = {
  input: CreateContactInput;
};

export type MutationCreateDraftBatchArgs = {
  input: CreateDraftJeBatchInput;
};

export type MutationCreateFeeArgs = {
  input: CreateFeeInput;
};

export type MutationCreateGlAccountArgs = {
  input: CreateGlAccountInput;
};

export type MutationCreateHouseRulesTemplateArgs = {
  input: HouseRulesTemplateInput;
};

export type MutationCreateImageNoteArgs = {
  input: CreateImageNoteInput;
};

export type MutationCreateInspectionUrlArgs = {
  input: InspectionUrlInput;
};

export type MutationCreateJournalEntryNoteArgs = {
  input: CreateJournalEntryNoteInput;
};

export type MutationCreateLeaseAgreementUrlArgs = {
  input: LeaseAgreementUrlInput;
};

export type MutationCreateLeaseDocumentTemplateArgs = {
  input: CreateLeaseDocumentTemplateInput;
};

export type MutationCreateLeaseInspectionArgs = {
  input: CreateLeaseInspectionInput;
};

export type MutationCreateMaintenanceApprovalArgs = {
  input: CreateMaintenanceApprovalInput;
};

export type MutationCreateManagerArgs = {
  input: CreateManagerInput;
};

export type MutationCreateOperatorArgs = {
  input: CreateOperatorInput;
};

export type MutationCreateOwnerArgs = {
  input: CreateOwnerInput;
};

export type MutationCreateOwnerNoteArgs = {
  input: CreateOwnerNoteInput;
};

export type MutationCreatePaymentsApprovalArgs = {
  input: CreatePaymentsApprovalInput;
};

export type MutationCreatePaymentsBatchArgs = {
  input: CreatePaymentsBatchInput;
};

export type MutationCreatePropertyArgs = {
  input: CreatePropertyInput;
};

export type MutationCreatePropertyFloorplanArgs = {
  input: CreatePropertyFloorplanInput;
};

export type MutationCreatePropertyNoteArgs = {
  input: CreatePropertyNoteInput;
};

export type MutationCreateReportBundleArgs = {
  input: CreateReportBundleInput;
};

export type MutationCreateRequestArgs = {
  input: CreateRequestInput;
};

export type MutationCreateRequestCostArgs = {
  input: CreateRequestCostInput;
};

export type MutationCreateRequestNoteArgs = {
  input: CreateRequestNoteInput;
};

export type MutationCreateRequestRecurrenceArgs = {
  input: CreateRequestRecurrenceInput;
};

export type MutationCreateRequestVisitArgs = {
  input: CreateVisitInput;
};

export type MutationCreateResidencyArgs = {
  input: CreateResidencyInput;
};

export type MutationCreateResidencyNoteArgs = {
  input: CreateResidencyNoteInput;
};

export type MutationCreateRoutineInspectionArgs = {
  input: CreateRoutineInspectionInput;
};

export type MutationCreateSupplierArgs = {
  input: SupplierInput;
};

export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};

export type MutationCreateTemplateArgs = {
  id: Scalars['ID']['input'];
  input: CreateTemplateInput;
};

export type MutationCreateTenantNoteArgs = {
  input: CreateTenantNoteInput;
};

export type MutationCreateUnitNoteArgs = {
  input: CreateUnitNoteInput;
};

export type MutationCreateUnitOnPropertyArgs = {
  input?: InputMaybe<CreateUnitOnPropertyInput>;
};

export type MutationCreateUnitsArgs = {
  input?: InputMaybe<CreateUnitsInput>;
};

export type MutationDeactivatePropertiesAutopayArgs = {
  input: AutopayPropertiesInput;
};

export type MutationDeclineChargeApprovalArgs = {
  declinedComment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationDeclinePaymentsApprovalArgs = {
  declinedComment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationDeclineScanApprovalArgs = {
  declinedComment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationDeleteAnnouncementArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteApplicationNoteArgs = {
  input: DeleteApplicationNoteInput;
};

export type MutationDeleteAutopayBankArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteBuildingArgs = {
  input: DeleteBuildingInput;
};

export type MutationDeleteBuildingInsuranceClaimArgs = {
  input?: InputMaybe<DeleteBuildingInsuranceClaimInput>;
};

export type MutationDeleteBuildingInsuranceHistoryArgs = {
  input?: InputMaybe<DeleteBuildingInsuranceHistoryInput>;
};

export type MutationDeleteBuildingInsuranceHistoryClaimArgs = {
  input?: InputMaybe<DeleteBuildingInsuranceClaimInput>;
};

export type MutationDeleteBuildingNoteArgs = {
  input: DeleteBuildingNoteInput;
};

export type MutationDeleteBundledReportArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteChargeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteClearableArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteContactArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFeeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteHouseRulesTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteImageNoteArgs = {
  input: DeleteImageNoteInput;
};

export type MutationDeleteJournalEntryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteJournalEntryNoteArgs = {
  input: UpdateJournalEntryNoteInput;
};

export type MutationDeleteLeaseDocumentTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteLeaseInspectionArgs = {
  input: DeleteLeaseInspectionInput;
};

export type MutationDeleteLinkedBankAccountArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteManagerArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteOperatorArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteOwnerArgs = {
  input: DeleteOwnerInput;
};

export type MutationDeleteOwnerNoteArgs = {
  input: DeleteOwnerNoteInput;
};

export type MutationDeletePropertyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePropertyAutopayBankAccountArgs = {
  input: PropertyBankAccountInput;
};

export type MutationDeletePropertyImageArgs = {
  input: DeletePropertyImageInput;
};

export type MutationDeletePropertyInsuranceClaimArgs = {
  input?: InputMaybe<DeletePropertyInsuranceClaimInput>;
};

export type MutationDeletePropertyInsuranceHistoryArgs = {
  input?: InputMaybe<DeletePropertyInsuranceHistoryInput>;
};

export type MutationDeletePropertyInsuranceHistoryClaimArgs = {
  input?: InputMaybe<DeletePropertyInsuranceClaimInput>;
};

export type MutationDeletePropertyNoteArgs = {
  input: DeletePropertyNoteInput;
};

export type MutationDeleteReconciliationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteReconciliationConfigArgs = {
  input: DeleteReconciliationConfigInput;
};

export type MutationDeleteRecurrenceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteReportBundleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteRequestCostArgs = {
  input: DeleteRequestCostInput;
};

export type MutationDeleteRequestImageArgs = {
  input: DeleteRequestImageInput;
};

export type MutationDeleteRequestNoteArgs = {
  input: DeleteRequestNoteInput;
};

export type MutationDeleteRequestRecurrenceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteResidencyArgs = {
  input: DeleteResidencyInput;
};

export type MutationDeleteResidencyEventArgs = {
  input: DeleteResidencyEventInput;
};

export type MutationDeleteResidencyNoteArgs = {
  input: DeleteResidencyNoteInput;
};

export type MutationDeleteRoutineInspectionArgs = {
  input: DeleteRoutineInspectionInput;
};

export type MutationDeleteSupplierArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTeamArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTenantNoteArgs = {
  input: DeleteTenantNoteInput;
};

export type MutationDeleteUnitNoteArgs = {
  input: DeleteUnitNoteInput;
};

export type MutationDeleteVisitBillArgs = {
  input: DeleteVisitBillInput;
};

export type MutationDraftReconciliationArgs = {
  input: DraftReconciliationInput;
};

export type MutationEnableManagerArgs = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type MutationFinalizeRequestArgs = {
  input: FinalizeRequestInput;
};

export type MutationFinalizeRequestVisitArgs = {
  input: UpdateVisitInput;
};

export type MutationInitiateBooksBatchArgs = {
  input: InitiateBooksBatchInput;
};

export type MutationInviteOwnerAutopayArgs = {
  input: InviteOwnerAutopayInput;
};

export type MutationInvitePayeeToEnrollArgs = {
  input: InvitePayeeToEnrollInput;
};

export type MutationInviteTenantAutopayArgs = {
  input: InviteTenantAutopayInput;
};

export type MutationLinkSupplierArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<LinkSupplierInput>;
};

export type MutationMarkRequestAppointmentChangesReadArgs = {
  input: MarkAppointmentChangesReadInput;
};

export type MutationMoveUnitsToBuildingArgs = {
  input?: InputMaybe<MoveUnitsToBuildingInput>;
};

export type MutationNotifyNsfPayeeArgs = {
  input: NotifyNsfPayeeInput;
};

export type MutationPostDraftBatchArgs = {
  input: PostDraftJeBatchInput;
};

export type MutationPrintJournalEntriesArgs = {
  input: PrintJournalEntriesInput;
};

export type MutationPublishAnnouncementArgs = {
  id: Scalars['ID']['input'];
  publishZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type MutationPutBudgetsArgs = {
  input: PutBudgetsInput;
};

export type MutationRefreshRequestImagesArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRefundClearableArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveManagerRoleArgs = {
  input: ManagerRoleInput;
};

export type MutationRemoveManagerSignatureArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveOwnerPropertyArgs = {
  input: RemoveOwnerPropertyInput;
};

export type MutationRemoveOwnerUnitArgs = {
  input: RemoveOwnerUnitInput;
};

export type MutationRemovePropertyKeyringArgs = {
  input: RemovePropertyKeyInput;
};

export type MutationRemovePropertyParkingArgs = {
  input: RemovePropertyParkingInput;
};

export type MutationRemovePropertyStorageArgs = {
  input: RemovePropertyStorageInput;
};

export type MutationRemoveResidencyInsuranceArgs = {
  id: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type MutationRemoveResidencyResidentArgs = {
  input: RemoveResidencyResidentInput;
};

export type MutationRemoveUnitFloorplanArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRenameBuildingDocumentArgs = {
  input: RenameBuildingDocumentInput;
};

export type MutationRenameOwnerDocumentArgs = {
  input: RenameOwnerDocumentInput;
};

export type MutationRenamePropertyDocumentArgs = {
  input: RenamePropertyDocumentInput;
};

export type MutationRenameRequestDocumentArgs = {
  input: RenameRequestDocumentInput;
};

export type MutationRenameResidencyDocumentArgs = {
  input: RenameResidencyDocumentInput;
};

export type MutationRenameTenantDocumentArgs = {
  input: RenameTenantDocumentInput;
};

export type MutationRenameUnitDocumentArgs = {
  input: RenameUnitDocumentInput;
};

export type MutationResendAutopayInviteToOwnerArgs = {
  input: ResendAutopayInviteInput;
};

export type MutationResendAutopayInviteToTenantArgs = {
  input: ResendAutopayInviteInput;
};

export type MutationRunReportSetArgs = {
  input: RunReportSetInput;
};

export type MutationSaveLeaseAgreementArgs = {
  input: CreateLeaseAgreementInput;
};

export type MutationScheduleRequestVisitArgs = {
  input: UpdateVisitInput;
};

export type MutationSendLeaseOfferArgs = {
  input: LeaseOfferInput;
};

export type MutationSetBundledReportSharedFlagArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSetClearableAutopayCollectionsEnabledArgs = {
  input: SetClearableAutopayCollectionsEnabledInput;
};

export type MutationSetOwnerAutopayCollectionsEnabledArgs = {
  input: SetAutopayCollectionsEnabledInput;
};

export type MutationSetPropertyAutopayCollectionsEnabledArgs = {
  input: SetPropertyAutopayCollectionsEnabledInput;
};

export type MutationSetTenantAutopayCollectionsEnabledArgs = {
  input: SetAutopayCollectionsEnabledInput;
};

export type MutationShareCampaignArgs = {
  input: ShareCampaignInput;
};

export type MutationSignLeaseAgreementArgs = {
  input: SignLeaseAgreementInput;
};

export type MutationStartAccountAutopayArgs = {
  input: StartAccountAutopayInput;
};

export type MutationStartReconciliationArgs = {
  input: StartReconciliationInput;
};

export type MutationTerminateResidencyArgs = {
  input: TerminateResidencyInput;
};

export type MutationTestVoiceAnnouncementArgs = {
  input: TestVoiceAnnouncementInput;
};

export type MutationToggleBatchPrototypesArgs = {
  input: JePrototypeKeysInput;
};

export type MutationTransferJournalEntriesArgs = {
  input: TransferJournalEntriesInput;
};

export type MutationUnassignKeyringArgs = {
  input: AssignKeyringInput;
};

export type MutationUnassignParkingArgs = {
  input: AssignParkingInput;
};

export type MutationUnassignStorageArgs = {
  input: AssignStorageInput;
};

export type MutationUpdateAccountArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAccountInput;
};

export type MutationUpdateAccountAutopayArgs = {
  input: UpdateAccountAutopayInput;
};

export type MutationUpdateAnnouncementArgs = {
  input: UpdateAnnouncementInput;
};

export type MutationUpdateApplicationArgs = {
  input: UpdateApplicationInput;
};

export type MutationUpdateAutopayCollectionTypesArgs = {
  input: UpdateAutopayCollectionTypesInput;
};

export type MutationUpdateAutopayEnrollmentToDeclinedArgs = {
  input: UpdateAutopayEnrollmentInput;
};

export type MutationUpdateAutopayEnrollmentToInvitedArgs = {
  input: UpdateAutopayEnrollmentInput;
};

export type MutationUpdateAutopayFeeInvoiceArgs = {
  input: UpdateAutopayFeeInvoiceInput;
};

export type MutationUpdateAutopayNsfFeesArgs = {
  input: UpdateAutopayNsfFeeInput;
};

export type MutationUpdateBatchPrototypeArgs = {
  input: JePrototypeInput;
};

export type MutationUpdateBuildingArgs = {
  input: UpdateBuildingInput;
};

export type MutationUpdateBuildingAmenitiesArgs = {
  input: UpdateBuildingAmenitiesInput;
};

export type MutationUpdateBuildingFeaturesArgs = {
  input: UpdateBuildingFeaturesInput;
};

export type MutationUpdateBuildingInsuranceArgs = {
  input: BuildingInsuranceInput;
};

export type MutationUpdateBuildingInsuranceClaimArgs = {
  input?: InputMaybe<UpdateBuildingInsuranceClaimInput>;
};

export type MutationUpdateBuildingInsuranceHistoryArgs = {
  input: UpdateBuildingInsuranceHistoryInput;
};

export type MutationUpdateBuildingInsuranceHistoryClaimArgs = {
  input?: InputMaybe<UpdateBuildingInsuranceClaimInput>;
};

export type MutationUpdateBuildingSpecsArgs = {
  input: UpdateBuildingSpecsInput;
};

export type MutationUpdateBundledReportFlagArgs = {
  input: UpdateBundledReportFlagInput;
};

export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};

export type MutationUpdateCampaignStatusArgs = {
  input: UpdateCampaignStatusInput;
};

export type MutationUpdateChargeArgs = {
  input: UpdateChargeInput;
};

export type MutationUpdateClearableArgs = {
  input: UpdateClearableInput;
};

export type MutationUpdateClearingEntryTaskArgs = {
  id: Scalars['ID']['input'];
  input: UpdateClearingEntryTaskInput;
};

export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};

export type MutationUpdateFeeArgs = {
  input: UpdateFeeInput;
};

export type MutationUpdateFinancialSettingsArgs = {
  input: UpdateFinancialSettingsInput;
};

export type MutationUpdateFloorplanArgs = {
  id: Scalars['ID']['input'];
  input: FloorplanInput;
};

export type MutationUpdateGlAccountArgs = {
  input: UpdateGlAccountInput;
};

export type MutationUpdateGlChequePlacementArgs = {
  input: GlChequePlacementInput;
};

export type MutationUpdateHouseRulesTemplateArgs = {
  id: Scalars['ID']['input'];
  input: HouseRulesTemplateInput;
};

export type MutationUpdateJournalDraftsArgs = {
  input: UpdateJournalDraftsInput;
};

export type MutationUpdateJournalEntryArgs = {
  input: UpdateJournalEntryInput;
};

export type MutationUpdateLeaseDocumentTemplateArgs = {
  input: UpdateLeaseDocumentTemplateInput;
};

export type MutationUpdateLeaseInspectionArgs = {
  input: UpdateLeaseInspectionInput;
};

export type MutationUpdateManagerArgs = {
  id: Scalars['ID']['input'];
  input: UpdateManagerInput;
};

export type MutationUpdateManagerLocaleArgs = {
  id: Scalars['ID']['input'];
  input: UpdateManagerLocaleInput;
};

export type MutationUpdateManagerSubscriptionsArgs = {
  input: SubscriptionsInput;
};

export type MutationUpdateNextChequeArgs = {
  input: NextChequeInput;
};

export type MutationUpdateOperatorArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOperatorInput;
};

export type MutationUpdateOperatorAutoApArgs = {
  input: UpdateOperatorAutoApInput;
};

export type MutationUpdateOwnerArgs = {
  input: UpdateOwnerInput;
};

export type MutationUpdateOwnerAutopayArgs = {
  input: UpdateOwnerAutopayInput;
};

export type MutationUpdateOwnerFinancialsArgs = {
  input: UpdateOwnerFinancialsInput;
};

export type MutationUpdateOwnerManagementFeeArgs = {
  input: UpdateOwnerManagementFeeInput;
};

export type MutationUpdatePaymentsBatchArgs = {
  input: UpdatePaymentsBatchInput;
};

export type MutationUpdatePresetGlMappingArgs = {
  input: UpdatePresetGlMappingInput;
};

export type MutationUpdatePresetRecurrenceArgs = {
  input: UpdatePresetRecurrenceInput;
};

export type MutationUpdatePropertyArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePropertyInput;
};

export type MutationUpdatePropertyAmenitiesArgs = {
  input: UpdatePropertyAmenitiesInput;
};

export type MutationUpdatePropertyContractorsArgs = {
  input: UpdatePropertyContractorsInput;
};

export type MutationUpdatePropertyFeaturesArgs = {
  input: UpdatePropertyFeaturesInput;
};

export type MutationUpdatePropertyInsuranceArgs = {
  input: PropertyInsuranceInput;
};

export type MutationUpdatePropertyInsuranceClaimArgs = {
  input?: InputMaybe<UpdatePropertyInsuranceClaimInput>;
};

export type MutationUpdatePropertyInsuranceHistoryArgs = {
  input: UpdatePropertyInsuranceHistoryInput;
};

export type MutationUpdatePropertyInsuranceHistoryClaimArgs = {
  input?: InputMaybe<UpdatePropertyInsuranceClaimInput>;
};

export type MutationUpdatePropertySpecsArgs = {
  input: UpdatePropertySpecsInput;
};

export type MutationUpdatePropertySuppliersArgs = {
  input: UpdatePropertySuppliersInput;
};

export type MutationUpdateReconciliationArgs = {
  input: UpdateReconciliationInput;
};

export type MutationUpdateReconciliationConfigArgs = {
  input: UpdateReconciliationConfigInput;
};

export type MutationUpdateReconciliationJournalsArgs = {
  input: UpdateReconciliationJournalsInput;
};

export type MutationUpdateReportBundleArgs = {
  input: UpdateReportBundleInput;
};

export type MutationUpdateRequestArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRequestInput;
};

export type MutationUpdateRequestChecklistItemArgs = {
  input: UpdateRequestChecklistItemInput;
};

export type MutationUpdateRequestCostArgs = {
  input: UpdateRequestCostInput;
};

export type MutationUpdateRequestRecurrenceArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRequestRecurrenceInput;
};

export type MutationUpdateRequestVisitArgs = {
  input: UpdateVisitInput;
};

export type MutationUpdateResidencyArgs = {
  input: UpdateResidencyInput;
};

export type MutationUpdateResidencyEffectsArgs = {
  input: UpdateResidencyEffectsInput;
  propertyId: Scalars['ID']['input'];
  residencyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type MutationUpdateResidencyEventArgs = {
  input: UpdateResidencyEventInput;
};

export type MutationUpdateResidencyInsuranceArgs = {
  id: Scalars['ID']['input'];
  input: ResidencyInsuranceInput;
  propertyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type MutationUpdateResidencyRefundableDepositsArgs = {
  input: UpdateResidencyRefundableDepositsInput;
};

export type MutationUpdateResidencyResidentFinancialsArgs = {
  input: UpdateResidencyResidentFinancialsInput;
};

export type MutationUpdateRoutineInspectionArgs = {
  input: UpdateRoutineInspectionInput;
};

export type MutationUpdateScanApprovalRequestArgs = {
  id: Scalars['ID']['input'];
  input: UpdateScanApprovalRequestInput;
};

export type MutationUpdateSupplierArgs = {
  input: SupplierInput;
};

export type MutationUpdateSupplierAutoApArgs = {
  input: UpdateSupplierAutoApInput;
};

export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};

export type MutationUpdateTemplateArgs = {
  id: Scalars['ID']['input'];
  input: UpdateTemplateInput;
};

export type MutationUpdateTenantAutopayArgs = {
  input: UpdateTenantAutopayInput;
};

export type MutationUpdateTenantFinancialsArgs = {
  input: UpdateTenantFinancialsInput;
};

export type MutationUpdateTenantRecordArgs = {
  input: UpdateTenantInput;
};

export type MutationUpdateUnitArgs = {
  input?: InputMaybe<UpdateUnitInput>;
};

export type MutationUpdateUnitFloorplanArgs = {
  input: UpdateUnitFloorplanInput;
};

export type MutationUpdateUnreadPushNotificationsArgs = {
  input: UpdateUnreadPushNotificationsInput;
};

export type MutationUpdateVisitBillMarkupArgs = {
  input: UpdateVisitBillMarkupInput;
};

export type MutationUpsertLeaseInspectionArgs = {
  input: UpsertLeaseInspectionInput;
};

export type MutationUpsertRoutineInspectionArgs = {
  input: UpsertRoutineInspectionInput;
};

export type MutationVoidJournalEntryArgs = {
  input: VoidJournalEntryInput;
};

export type MutationWelcomeTenantArgs = {
  input: WelcomeTenantInput;
};

export type NextChequeInput = {
  nextCheque: Scalars['Int']['input'];
};

export type Note = {
  __typename?: 'Note';
  createdName?: Maybe<Scalars['String']['output']>;
  createdSub?: Maybe<Scalars['String']['output']>;
  createdZ?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  source?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type NoteInput = {
  createdName?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type NotifyNsfPayeeInput = {
  amount: Scalars['Float']['input'];
  date: Scalars['String']['input'];
  description: Scalars['String']['input'];
  fee?: InputMaybe<Scalars['Float']['input']>;
  payeeId: Scalars['ID']['input'];
  payeeType: PayeeType;
};

export type OnsiteManager = {
  __typename?: 'OnsiteManager';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['AWSEmail']['output']>;
  entityType: OnsiteManagerType;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type OnsiteManagerInput = {
  entityType: OnsiteManagerType;
  id: Scalars['ID']['input'];
};

export enum OnsiteManagerType {
  Manager = 'manager',
  Operator = 'operator',
}

export type OpenSearch = {
  __typename?: 'OpenSearch';
  task?: Maybe<OpenSearchTaskResult>;
};

export type OpenSearchTaskArgs = {
  id: Scalars['ID']['input'];
};

export type OpenSearchTask = {
  __typename?: 'OpenSearchTask';
  cancellable?: Maybe<Scalars['Boolean']['output']>;
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  status: OpenSearchTaskStatus;
};

export type OpenSearchTaskResponse = {
  __typename?: 'OpenSearchTaskResponse';
  created: Scalars['Int']['output'];
  deleted: Scalars['Int']['output'];
  took: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  updated: Scalars['Int']['output'];
};

export type OpenSearchTaskResult = {
  __typename?: 'OpenSearchTaskResult';
  completed?: Maybe<Scalars['Boolean']['output']>;
  response?: Maybe<OpenSearchTaskResponse>;
  task: OpenSearchTask;
};

export type OpenSearchTaskStatus = {
  __typename?: 'OpenSearchTaskStatus';
  created: Scalars['Int']['output'];
  deleted: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  updated: Scalars['Int']['output'];
};

export type Operator = {
  __typename?: 'Operator';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  address?: Maybe<Address>;
  autopayAccount?: Maybe<Scalars['String']['output']>;
  autopayClientId?: Maybe<Scalars['ID']['output']>;
  autopayInstitution?: Maybe<Scalars['String']['output']>;
  autopayStatus?: Maybe<Scalars['String']['output']>;
  autopayStatusUpdatedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  autopayTransit?: Maybe<Scalars['String']['output']>;
  availability?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['AWSEmail']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  exceptions?: Maybe<Array<Maybe<Exception>>>;
  expenseGlId?: Maybe<Scalars['String']['output']>;
  expiryCOI?: Maybe<Scalars['String']['output']>;
  fingerprints?: Maybe<Fingerprints>;
  gcalBusy?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  hasCOI?: Maybe<Scalars['Boolean']['output']>;
  hasNonFinalizedVisits?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  operatorType?: Maybe<OperatorType>;
  phone?: Maybe<Scalars['String']['output']>;
  requests?: Maybe<Array<Maybe<Request>>>;
  requestsCompleted?: Maybe<Array<Maybe<Request>>>;
  skills?: Maybe<Array<OperatorSkill>>;
  timezone?: Maybe<Scalars['String']['output']>;
  totalAssignedVisits?: Maybe<Scalars['Int']['output']>;
  totalCancelledVisits?: Maybe<Scalars['Int']['output']>;
  totalCompletedVisits?: Maybe<Scalars['Int']['output']>;
  totalStartedVisits?: Maybe<Scalars['Int']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type OperatorPayload = {
  __typename?: 'OperatorPayload';
  error?: Maybe<Scalars['String']['output']>;
  operator?: Maybe<Operator>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum OperatorSkill {
  Appliance = 'APPLIANCE',
  Cleaning = 'CLEANING',
  Door = 'DOOR',
  Electrical = 'ELECTRICAL',
  Exterior = 'EXTERIOR',
  General = 'GENERAL',
  Hvac = 'HVAC',
  Keys = 'KEYS',
  Plumbing = 'PLUMBING',
  Safety = 'SAFETY',
  Security = 'SECURITY',
  Window = 'WINDOW',
}

export enum OperatorType {
  AdHoc = 'adHoc',
  Staff = 'staff',
}

export type OperatorWindow = {
  __typename?: 'OperatorWindow';
  operator?: Maybe<Operator>;
  window?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type OperatorsFilterInput = {
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
};

export type Owner = ResidentRecord & {
  __typename?: 'Owner';
  accountId: Scalars['ID']['output'];
  address?: Maybe<Address>;
  assetFees?: Maybe<Array<Maybe<AssetFee>>>;
  autopayAccount?: Maybe<Scalars['String']['output']>;
  autopayClientId?: Maybe<Scalars['ID']['output']>;
  autopayCollectionsEnabled?: Maybe<Scalars['Boolean']['output']>;
  autopayCollectionsEnabledZ?: Maybe<Scalars['AWSDateTime']['output']>;
  autopayInstitution?: Maybe<Scalars['String']['output']>;
  autopayInvitedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  autopayStatus?: Maybe<Scalars['String']['output']>;
  autopayStatusUpdatedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  autopayTransit?: Maybe<Scalars['String']['output']>;
  booksSinceZ?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  correspondenceMethod?: Maybe<CorrespondenceMethod>;
  dob?: Maybe<Scalars['AWSDate']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  email?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Array<Scalars['String']['output']>>;
  emergencyContacts?: Maybe<Array<Maybe<EmergencyContact>>>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  enrollments?: Maybe<Array<Maybe<AutopayEnrollment>>>;
  feeAmount?: Maybe<Scalars['Float']['output']>;
  feeRate?: Maybe<Scalars['Float']['output']>;
  flatFee?: Maybe<Scalars['Float']['output']>;
  gstFiling?: Maybe<FilingFrequency>;
  gstNumber?: Maybe<Scalars['String']['output']>;
  history?: Maybe<Array<Maybe<OwnerHistoryEntry>>>;
  id: Scalars['ID']['output'];
  listProperties?: Maybe<PropertyConnection>;
  listUnits?: Maybe<UnitConnection>;
  mailingAddress?: Maybe<Address>;
  name: Scalars['String']['output'];
  notes?: Maybe<Array<Maybe<Note>>>;
  nrtFeeAmount?: Maybe<Scalars['Float']['output']>;
  nrtFeeRate?: Maybe<Scalars['Float']['output']>;
  padAccount?: Maybe<Scalars['String']['output']>;
  padAmount?: Maybe<Scalars['Float']['output']>;
  padInstitution?: Maybe<Scalars['String']['output']>;
  padTransit?: Maybe<Scalars['String']['output']>;
  pets?: Maybe<Array<Pet>>;
  /** @deprecated Use `phones` instead. */
  phone?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<Scalars['AWSPhone']['output']>>>;
  preferredPaymentMethod?: Maybe<PaymentMethod>;
  profile?: Maybe<Profile>;
  profileId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated No pagination, use `listProprties` instead. */
  properties?: Maybe<Array<Maybe<Property>>>;
  /** @deprecated Use length of `propertyIds` instead. */
  propertyCount?: Maybe<Scalars['Int']['output']>;
  propertyHeaders?: Maybe<Array<Maybe<PropertyHeader>>>;
  propertyIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  propertyOptions?: Maybe<Array<PropertyOption>>;
  trackCondoFees?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use length of `unitIds` instead. */
  unitCount?: Maybe<Scalars['Int']['output']>;
  unitHeaders?: Maybe<Array<Maybe<UnitHeader>>>;
  unitIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** @deprecated No pagination, use `listUnits` instead. */
  units?: Maybe<Array<Maybe<Unit>>>;
  vehicles?: Maybe<Array<Vehicle>>;
  yearEndMonth?: Maybe<Scalars['Int']['output']>;
};

export type OwnerEnrollmentsArgs = {
  filter?: InputMaybe<AutopayEnrollmentFilterInput>;
};

export type OwnerListPropertiesArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type OwnerListUnitsArgs = {
  filter?: InputMaybe<UnitFilter>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type OwnerConnection = {
  __typename?: 'OwnerConnection';
  items?: Maybe<Array<Maybe<Owner>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type OwnerEdge = RelayEdge & {
  __typename?: 'OwnerEdge';
  cursor: Scalars['ID']['output'];
  node: Owner;
};

export type OwnerHistoryEntry = {
  __typename?: 'OwnerHistoryEntry';
  assetId: Scalars['ID']['output'];
  assetType: AssetType;
  commercialLeasedUnits?: Maybe<Scalars['Int']['output']>;
  createdZ: Scalars['AWSDateTime']['output'];
  lease?: Maybe<Scalars['ID']['output']>;
  leasedUnits?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
};

export type OwnerPayload = {
  __typename?: 'OwnerPayload';
  error?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Owner>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type OwnerSearchFilter = {
  email: Scalars['String']['input'];
};

export type OwnersFilter = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OwnersFilterInput = {
  ownerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OwnersPage = RelayPage & {
  __typename?: 'OwnersPage';
  edges: Array<OwnerEdge>;
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum OwnersSortField {
  Id = 'id',
  Name = 'name',
}

export type OwnersSortInput = {
  direction: SortDirection;
  field: OwnersSortField;
};

export type ParkingStall = {
  __typename?: 'ParkingStall';
  name: Scalars['String']['output'];
  unitId?: Maybe<Scalars['String']['output']>;
};

export type PayeeClearablesFilterItemInput = {
  accountId: Scalars['String']['input'];
  payeeId: Scalars['String']['input'];
};

export type PayeeClearingAmount = {
  __typename?: 'PayeeClearingAmount';
  amount: Scalars['Float']['output'];
  buildingKey?: Maybe<Scalars['String']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  clearable: Clearable;
  clearableId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  due?: Maybe<Scalars['AWSDate']['output']>;
  glId: Scalars['ID']['output'];
  ownerId: Scalars['ID']['output'];
  ownerName?: Maybe<Scalars['String']['output']>;
  posted?: Maybe<Scalars['AWSDate']['output']>;
  propertyId: Scalars['ID']['output'];
  propertyKey?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  ref?: Maybe<Scalars['String']['output']>;
  unitId?: Maybe<Scalars['ID']['output']>;
  unitName?: Maybe<Scalars['String']['output']>;
};

export type PayeeClearingAmountInput = {
  amount: Scalars['Float']['input'];
  clearableId: Scalars['ID']['input'];
  glId: Scalars['ID']['input'];
  ownerId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  unitId?: InputMaybe<Scalars['ID']['input']>;
};

export type PayeeRefFilter = {
  payeeId: Scalars['ID']['input'];
  ref: Scalars['String']['input'];
};

export enum PayeeType {
  Account = 'account',
  Operator = 'operator',
  Owner = 'owner',
  Supplier = 'supplier',
  Tenant = 'tenant',
}

export type PaymentDetails = {
  __typename?: 'PaymentDetails';
  padAccount?: Maybe<Scalars['String']['output']>;
  padAmount?: Maybe<Scalars['Float']['output']>;
  padInstitution?: Maybe<Scalars['String']['output']>;
  padTransit?: Maybe<Scalars['String']['output']>;
  preferredPaymentMethod?: Maybe<PaymentMethod>;
};

export type PaymentDetailsInput = {
  padAccount?: InputMaybe<Scalars['String']['input']>;
  padAmount?: InputMaybe<Scalars['Float']['input']>;
  padInstitution?: InputMaybe<Scalars['String']['input']>;
  padTransit?: InputMaybe<Scalars['String']['input']>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
};

export enum PaymentMethod {
  Eft = 'EFT',
  Autopay = 'autopay',
  Cash = 'cash',
  Cheque = 'cheque',
  CreditCard = 'creditCard',
  Debit = 'debit',
  ETransfer = 'eTransfer',
  None = 'none',
  Pad = 'pad',
}

export type PaymentPayee = {
  __typename?: 'PaymentPayee';
  amount: Scalars['Float']['output'];
  description?: Maybe<Scalars['String']['output']>;
  glId: Scalars['ID']['output'];
  payee: PayeeType;
  payeeClearingAmounts: Array<PayeeClearingAmount>;
  payeeId: Scalars['ID']['output'];
  payeeName?: Maybe<Scalars['String']['output']>;
  ref?: Maybe<Scalars['String']['output']>;
};

export type PaymentPayeeInput = {
  amount: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  glId: Scalars['ID']['input'];
  payee: PayeeType;
  payeeClearingAmounts: Array<PayeeClearingAmountInput>;
  payeeId: Scalars['ID']['input'];
  payeeName?: InputMaybe<Scalars['String']['input']>;
  ref?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentPrototype = {
  __typename?: 'PaymentPrototype';
  amount: Scalars['Float']['output'];
  bankId: Scalars['ID']['output'];
  bankName?: Maybe<Scalars['String']['output']>;
  batchId: Scalars['ID']['output'];
  billingName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  payees: Array<PaymentPayee>;
  paymentMethod?: Maybe<PaymentMethod>;
  posted: Scalars['AWSDate']['output'];
};

export type PaymentPrototypeInput = {
  amount: Scalars['Float']['input'];
  bankId: Scalars['ID']['input'];
  batchId: Scalars['ID']['input'];
  billingName?: InputMaybe<Scalars['String']['input']>;
  index: Scalars['Int']['input'];
  payees: Array<PaymentPayeeInput>;
  posted: Scalars['AWSDate']['input'];
};

export enum PaymentStatus {
  Pending = 'PENDING',
}

export type PaymentsApproval = ApprovalRecord & {
  __typename?: 'PaymentsApproval';
  accountId: Scalars['String']['output'];
  approvedSub?: Maybe<Scalars['ID']['output']>;
  approvedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  approverRoles?: Maybe<Array<Maybe<ApproverRole>>>;
  declinedSub?: Maybe<Scalars['ID']['output']>;
  declinedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['String']['output'];
  request: PaymentsApprovalRequest;
  requestedName?: Maybe<Scalars['String']['output']>;
  requestedSub: Scalars['ID']['output'];
  requestedZ: Scalars['AWSDateTime']['output'];
  status: ApprovalStatus;
};

export type PaymentsApprovalPayload = {
  __typename?: 'PaymentsApprovalPayload';
  approval?: Maybe<PaymentsApproval>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PaymentsApprovalRequest = {
  __typename?: 'PaymentsApprovalRequest';
  batchId: Scalars['ID']['output'];
  itemCount: Scalars['Int']['output'];
  listPayments?: Maybe<PaymentsPrototypeConnection>;
  paymentMethod?: Maybe<PaymentMethod>;
  posted: Scalars['AWSDate']['output'];
  total: Scalars['Float']['output'];
};

export type PaymentsApprovalRequestListPaymentsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentsBatch = {
  __typename?: 'PaymentsBatch';
  approvalId?: Maybe<Scalars['ID']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  itemCount: Scalars['Int']['output'];
  listPrototypes?: Maybe<PaymentsPrototypeConnection>;
  paymentMethod?: Maybe<PaymentMethod>;
  posted: Scalars['AWSDate']['output'];
  presetId: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
  total: Scalars['Float']['output'];
};

export type PaymentsBatchPayload = {
  __typename?: 'PaymentsBatchPayload';
  error?: Maybe<Scalars['String']['output']>;
  paymentsBatch?: Maybe<PaymentsBatch>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PaymentsPrototypeConnection = {
  __typename?: 'PaymentsPrototypeConnection';
  items?: Maybe<Array<PaymentPrototype>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type Pet = {
  __typename?: 'Pet';
  count: Scalars['Int']['output'];
  details?: Maybe<Array<PetDetails>>;
  type: Scalars['String']['output'];
};

export type PetDetails = {
  __typename?: 'PetDetails';
  breed?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  isService?: Maybe<Scalars['Boolean']['output']>;
  license?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['String']['output']>;
};

export type PetDetailsInput = {
  breed?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  isService?: InputMaybe<Scalars['Boolean']['input']>;
  license?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['String']['input']>;
};

export type PetInput = {
  count: Scalars['Int']['input'];
  details?: InputMaybe<Array<PetDetailsInput>>;
  type: Scalars['String']['input'];
};

export type PostDraftJeBatchInput = {
  id: Scalars['ID']['input'];
  overrides?: InputMaybe<Array<InputMaybe<JePrototypeInput>>>;
};

export type PostDraftJeBatchPayload = {
  __typename?: 'PostDraftJEBatchPayload';
  batch?: Maybe<Batch>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PostedRangeInput = {
  from?: InputMaybe<Scalars['AWSDate']['input']>;
  to?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type Preset = {
  __typename?: 'Preset';
  category?: Maybe<PresetCategory>;
  description: Scalars['String']['output'];
  glMapping?: Maybe<Array<Maybe<GlMapping>>>;
  group?: Maybe<PresetGroup>;
  icon?: Maybe<PresetIcon>;
  id: Scalars['ID']['output'];
  inputFields?: Maybe<Array<Maybe<PresetInputField>>>;
  lastPosted?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  recurrence?: Maybe<PresetRecurrence>;
  roles?: Maybe<Array<ManagerRole>>;
  type: PresetType;
  version?: Maybe<BooksReportVersion>;
  visibleExpression?: Maybe<Scalars['String']['output']>;
};

export enum PresetCategory {
  Manager = 'manager',
  Owner = 'owner',
}

export type PresetGroup = {
  __typename?: 'PresetGroup';
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['Int']['output']>;
};

export type PresetIcon = {
  __typename?: 'PresetIcon';
  color?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PresetInputField = {
  __typename?: 'PresetInputField';
  defaultExpression?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  options?: Maybe<Array<PresetOption>>;
  required?: Maybe<Scalars['Boolean']['output']>;
  type: BooksFieldType;
};

export type PresetOption = {
  __typename?: 'PresetOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PresetPayload = {
  __typename?: 'PresetPayload';
  error?: Maybe<Scalars['String']['output']>;
  preset?: Maybe<Preset>;
  success: Scalars['Boolean']['output'];
};

export type PresetRecurrence = {
  __typename?: 'PresetRecurrence';
  enabled: Scalars['Boolean']['output'];
  locked?: Maybe<Scalars['Boolean']['output']>;
  parameters: Scalars['AWSJSON']['output'];
  postedOffset: Scalars['Int']['output'];
  rrule: Scalars['String']['output'];
};

export enum PresetType {
  Batch = 'batch',
  Report = 'report',
}

export type PrintJournalEntriesInput = {
  glId: Scalars['ID']['input'];
  startingCheque: Scalars['Int']['input'];
};

export type ProRatedEffect = {
  __typename?: 'ProRatedEffect';
  description?: Maybe<Scalars['String']['output']>;
  effect: Scalars['Float']['output'];
  feeId?: Maybe<Scalars['ID']['output']>;
  glId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  incentiveId?: Maybe<Scalars['ID']['output']>;
  startZ?: Maybe<Scalars['String']['output']>;
};

export type Profile = {
  __typename?: 'Profile';
  addressHistory?: Maybe<Array<AddressHistory>>;
  assetsShares?: Maybe<Array<AssetShare>>;
  dob?: Maybe<Scalars['AWSDate']['output']>;
  email: Scalars['AWSEmail']['output'];
  emails?: Maybe<Array<Maybe<Scalars['AWSEmail']['output']>>>;
  household?: Maybe<Household>;
  id: Scalars['ID']['output'];
  logins?: Maybe<Array<ProfileLogin>>;
  name: Scalars['String']['output'];
  otherIncome?: Maybe<Array<Income>>;
  phone?: Maybe<Scalars['String']['output']>;
  workHistory?: Maybe<Array<WorkHistory>>;
};

export type ProfileLoginsArgs = {
  status?: InputMaybe<ProfileLoginStatus>;
};

export type ProfileLogin = {
  __typename?: 'ProfileLogin';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: ProfileLoginStatus;
};

export enum ProfileLoginStatus {
  Confirmed = 'CONFIRMED',
  ExternalProvider = 'EXTERNAL_PROVIDER',
  Unconfirmed = 'UNCONFIRMED',
  Unknown = 'UNKNOWN',
}

export type PropertiesFilter = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PropertiesFilterInput = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type PropertiesPage = RelayPage & {
  __typename?: 'PropertiesPage';
  edges: Array<PropertyEdge>;
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum PropertiesSortField {
  Enabled = 'enabled',
  Name = 'name',
  Occupancy = 'occupancy',
  TotalUnits = 'totalUnits',
}

export type PropertiesSortFieldInput = {
  direction: SortDirection;
  field: PropertiesSortField;
};

export type PropertiesSortInput = {
  fields?: InputMaybe<Array<PropertiesSortFieldInput>>;
  location?: InputMaybe<PropertiesSortLocationInput>;
};

export type PropertiesSortLocationInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type Property = {
  __typename?: 'Property';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  address: Address;
  amenitiesSetting?: Maybe<AmenitiesSetting>;
  autopayActivatedZ?: Maybe<Scalars['String']['output']>;
  autopayBankAccounts?: Maybe<Array<Maybe<PropertyBankAccount>>>;
  autopayCollectionsEnabled?: Maybe<Scalars['Boolean']['output']>;
  autopayCollectionsEnabledZ?: Maybe<Scalars['AWSDateTime']['output']>;
  autopayDeactivatedZ?: Maybe<Scalars['String']['output']>;
  autopayStatus?: Maybe<PropertyAutopayStatus>;
  autopayStatusHistory?: Maybe<Array<Maybe<StatusHistoryEvent>>>;
  /** @deprecated Use `window`. */
  availabilityWindows?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  buildings?: Maybe<Array<Maybe<Building>>>;
  campaigns?: Maybe<CampaignConnection>;
  commercialLeaseDocumentTemplateId?: Maybe<Scalars['ID']['output']>;
  commercialLeasedUnits?: Maybe<Scalars['Int']['output']>;
  commercialUnits?: Maybe<Scalars['Int']['output']>;
  commonAreas?: Maybe<Scalars['Int']['output']>;
  condoUnits?: Maybe<Scalars['Int']['output']>;
  contractorIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  contractors?: Maybe<Array<Maybe<Operator>>>;
  creatingUnits?: Maybe<Scalars['Int']['output']>;
  currentResidencies?: Maybe<Array<Residency>>;
  currentResidents?: Maybe<Array<ResidentRecord>>;
  documents?: Maybe<Array<Maybe<Document>>>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  features?: Maybe<Array<Maybe<Feature>>>;
  fingerprints?: Maybe<Fingerprints>;
  floorplanIds?: Maybe<Array<Scalars['ID']['output']>>;
  floorplans?: Maybe<Array<Floorplan>>;
  houseRules?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  imageKey?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Maybe<Image>>>;
  insurancePolicy?: Maybe<InsurancePolicy>;
  insurancePolicyHistory?: Maybe<Array<Maybe<InsurancePolicy>>>;
  journalCount?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  keyring?: Maybe<Array<Maybe<Keyring>>>;
  leasedUnits?: Maybe<Scalars['Int']['output']>;
  legalDescription?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Array<Maybe<Note>>>;
  onsiteManager?: Maybe<OnsiteManager>;
  operatorWindows?: Maybe<Array<Maybe<OperatorWindow>>>;
  owner?: Maybe<Owner>;
  ownerId?: Maybe<Scalars['String']['output']>;
  ownerUnits?: Maybe<Scalars['Int']['output']>;
  pageCurrentResidencies?: Maybe<ResidenciesPage>;
  pageUnits?: Maybe<UnitsPage>;
  parking?: Maybe<Array<Maybe<ParkingStall>>>;
  phone?: Maybe<Scalars['String']['output']>;
  rentalLeaseDocumentTemplateId?: Maybe<Scalars['ID']['output']>;
  rentalUnits?: Maybe<Scalars['Int']['output']>;
  reserveAmount?: Maybe<Scalars['Float']['output']>;
  specs?: Maybe<Array<Maybe<PropertySpecs>>>;
  storage?: Maybe<Array<Maybe<StorageLocker>>>;
  supplierIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  suppliers?: Maybe<Array<Maybe<Supplier>>>;
  timezone: Scalars['String']['output'];
  totalUnitFactor?: Maybe<Scalars['Float']['output']>;
  totalUnits?: Maybe<Scalars['Int']['output']>;
  types?: Maybe<Array<Maybe<PropertyType>>>;
  /** @deprecated Use `pageUnits` to avoid missing records. */
  units?: Maybe<Array<Maybe<Unit>>>;
  window?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type PropertyAvailabilityWindowsArgs = {
  duration?: InputMaybe<Scalars['Int']['input']>;
};

export type PropertyCampaignsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type PropertyOperatorWindowsArgs = {
  duration?: InputMaybe<Scalars['Int']['input']>;
};

export type PropertyPageCurrentResidenciesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type PropertyPageUnitsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export enum PropertyAutopayStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
}

export type PropertyBankAccount = {
  __typename?: 'PropertyBankAccount';
  bankAccountId: Scalars['ID']['output'];
  collectionType: CollectionType;
  id: Scalars['ID']['output'];
  propertyId: Scalars['ID']['output'];
};

export type PropertyBankAccountInput = {
  bankAccountId: Scalars['ID']['input'];
  collectionType: CollectionType;
  propertyId: Scalars['ID']['input'];
};

export type PropertyConnection = {
  __typename?: 'PropertyConnection';
  items?: Maybe<Array<Maybe<Property>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type PropertyEdge = RelayEdge & {
  __typename?: 'PropertyEdge';
  cursor: Scalars['ID']['output'];
  node: Property;
};

export type PropertyExpensesFilterItemInput = {
  accountId: Scalars['ID']['input'];
  endDate: Scalars['AWSDate']['input'];
  ownerId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  startDate: Scalars['AWSDate']['input'];
};

export type PropertyFact = {
  __typename?: 'PropertyFact';
  accountEnabled?: Maybe<Scalars['Boolean']['output']>;
  accountId?: Maybe<Scalars['ID']['output']>;
  accountName?: Maybe<Scalars['String']['output']>;
  commercialExpiring15Days?: Maybe<Scalars['Int']['output']>;
  commercialExpiringLater?: Maybe<Scalars['Int']['output']>;
  commercialExpiringSoon?: Maybe<Scalars['Int']['output']>;
  commercialLeasedUnits?: Maybe<Scalars['Int']['output']>;
  commercialLeasesExpired?: Maybe<Scalars['Int']['output']>;
  commercialUnits?: Maybe<Scalars['Int']['output']>;
  commonAreas?: Maybe<Scalars['Int']['output']>;
  dateKey: Scalars['AWSDate']['output'];
  expired?: Maybe<Scalars['Int']['output']>;
  expiring1Month?: Maybe<Scalars['Int']['output']>;
  expiring15Days?: Maybe<Scalars['Int']['output']>;
  expiringLater?: Maybe<Scalars['Int']['output']>;
  expiringSoon?: Maybe<Scalars['Int']['output']>;
  leasedUnits?: Maybe<Scalars['Int']['output']>;
  ownedTestUnits?: Maybe<Scalars['Int']['output']>;
  ownedUnits?: Maybe<Scalars['Int']['output']>;
  ownerUnits?: Maybe<Scalars['Int']['output']>;
  propertyEnabled?: Maybe<Scalars['Boolean']['output']>;
  propertyId: Scalars['ID']['output'];
  testAccount?: Maybe<Scalars['Boolean']['output']>;
  testUnits?: Maybe<Scalars['Int']['output']>;
  totalCommercialRent?: Maybe<Scalars['Float']['output']>;
  totalCommercialSqft?: Maybe<Scalars['Float']['output']>;
  totalRent?: Maybe<Scalars['Float']['output']>;
  totalResidentialRent?: Maybe<Scalars['Float']['output']>;
  totalResidentialSqft?: Maybe<Scalars['Float']['output']>;
  totalResidentialUnits?: Maybe<Scalars['Int']['output']>;
  totalSqft?: Maybe<Scalars['Float']['output']>;
  totalUnits?: Maybe<Scalars['Int']['output']>;
};

export type PropertyFactConnection = {
  __typename?: 'PropertyFactConnection';
  items?: Maybe<Array<Maybe<PropertyFact>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type PropertyFactFilter = {
  endDate?: InputMaybe<Scalars['AWSDate']['input']>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startDate?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type PropertyHeader = {
  __typename?: 'PropertyHeader';
  address?: Maybe<Address>;
  id: Scalars['ID']['output'];
  imageKey?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PropertyImagesInput = {
  images?: InputMaybe<Array<ImageInput>>;
  propertyId: Scalars['ID']['input'];
};

export type PropertyInsuranceInput = {
  insurancePolicy: InsurancePolicyInput;
  propertyId: Scalars['ID']['input'];
};

export type PropertyOption = {
  __typename?: 'PropertyOption';
  entityName: Scalars['String']['output'];
  occupancy?: Maybe<Scalars['String']['output']>;
  ownerId: Scalars['String']['output'];
  ownerName: Scalars['String']['output'];
  propertyAddress?: Maybe<Address>;
  propertyId: Scalars['String']['output'];
  unitId?: Maybe<Scalars['String']['output']>;
};

export type PropertyPayload = {
  __typename?: 'PropertyPayload';
  error?: Maybe<Scalars['String']['output']>;
  property?: Maybe<Property>;
  success?: Maybe<Scalars['Boolean']['output']>;
  unitNames?: Maybe<UnitNames>;
};

export enum PropertySpecTypes {
  Basement = 'basement',
  BuildingType = 'buildingType',
  CommercialUnits = 'commercialUnits',
  CommonAreas = 'commonAreas',
  Commons = 'commons',
  Construction = 'construction',
  ConstructionFireResistive = 'constructionFireResistive',
  ConstructionFrame = 'constructionFrame',
  ConstructionOther = 'constructionOther',
  DistanceBetweenBuildings = 'distanceBetweenBuildings',
  Electrical = 'electrical',
  Elevators = 'elevators',
  EmergencyLighting = 'emergencyLighting',
  ExteriorWalls = 'exteriorWalls',
  FireAlarms = 'fireAlarms',
  FireDepartmentServices = 'fireDepartmentServices',
  FireDetectorsType = 'fireDetectorsType',
  FireExtinguishers = 'fireExtinguishers',
  Floors = 'floors',
  FuseType = 'fuseType',
  HasParking = 'hasParking',
  Heating = 'heating',
  LastElectricalService = 'lastElectricalService',
  LastFireAlarmsService = 'lastFireAlarmsService',
  LastHeatingService = 'lastHeatingService',
  LastPlumbingService = 'lastPlumbingService',
  LastRoofService = 'lastRoofService',
  LegalDescription = 'legalDescription',
  ManualPullStations = 'manualPullStations',
  NumberOfBuildings = 'numberOfBuildings',
  NumberOfFireExtinguishers = 'numberOfFireExtinguishers',
  OwnerUnits = 'ownerUnits',
  Parking = 'parking',
  ParkingSpaces = 'parkingSpaces',
  Plumbing = 'plumbing',
  PollutionExposure = 'pollutionExposure',
  PrimaryFuelType = 'primaryFuelType',
  PublicHydrants = 'publicHydrants',
  RentalUnits = 'rentalUnits',
  ResidenceType = 'residenceType',
  RoofCovering = 'roofCovering',
  ServiceElevators = 'serviceElevators',
  Sprinklers = 'sprinklers',
  SprinklersValue = 'sprinklersValue',
  TotalArea = 'totalArea',
  Units = 'units',
  UnitsWithAc = 'unitsWithAC',
  WiringType = 'wiringType',
  YearBuilt = 'yearBuilt',
}

export type PropertySpecs = {
  __typename?: 'PropertySpecs';
  key: PropertySpecTypes;
  value: Scalars['String']['output'];
};

export type PropertySpecsInput = {
  key: PropertySpecTypes;
  value: Scalars['String']['input'];
};

export enum PropertyType {
  Commercial = 'commercial',
  Condominium = 'condominium',
  Rental = 'rental',
}

export type PropertyUnit = {
  __typename?: 'PropertyUnit';
  propertyId: Scalars['ID']['output'];
  unitId: Scalars['ID']['output'];
};

export type PropertyUnitInput = {
  propertyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type ProvinceTaxDetailsPayload = {
  __typename?: 'ProvinceTaxDetailsPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  taxDetails?: Maybe<Array<TaxDetail>>;
};

export enum PushPlatform {
  Adm = 'ADM',
  Apns = 'APNS',
  ApnsSandbox = 'APNS_SANDBOX',
  Gcm = 'GCM',
}

export type PushToken = {
  __typename?: 'PushToken';
  id: Scalars['ID']['output'];
  platform?: Maybe<PushPlatform>;
  token?: Maybe<Scalars['String']['output']>;
};

export type PutBudgetsInput = {
  budgets: Array<SingleBudgetInput>;
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  announcement?: Maybe<Announcement>;
  approval?: Maybe<ApprovalRecord>;
  batch?: Maybe<Batch>;
  building?: Maybe<Building>;
  campaign?: Maybe<Campaign>;
  campaigns?: Maybe<CampaignConnection>;
  clearable?: Maybe<Clearable>;
  contact?: Maybe<Contact>;
  customTemplate?: Maybe<Template>;
  defaultFloorplanTemplates?: Maybe<Array<Maybe<Floorplan>>>;
  defaultTemplate?: Maybe<Template>;
  depositTemplate?: Maybe<DepositTemplate>;
  depositTemplates?: Maybe<Array<Maybe<DepositTemplate>>>;
  draftBatch?: Maybe<JeDraftBatch>;
  floorplan?: Maybe<Floorplan>;
  floorplanAreaDefaultFeatures?: Maybe<Array<Maybe<FloorplanAreaFeature>>>;
  floorplanByIds?: Maybe<Array<Maybe<Floorplan>>>;
  floorplanDefaultAdditionalFeatures?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  furnishingsTemplate?: Maybe<FurnishingsTemplate>;
  getProvinceTaxDetails?: Maybe<ProvinceTaxDetailsPayload>;
  getRegionTaxDetails?: Maybe<RegionTaxDetailsPayload>;
  glAccount?: Maybe<GlAccount>;
  houseRulesTemplate?: Maybe<Template>;
  iamQuery?: Maybe<IamQuery>;
  iamTenant?: Maybe<IamTenant>;
  inspection?: Maybe<Inspection>;
  inspectionByIds?: Maybe<Array<Maybe<Inspection>>>;
  inspectionTemplates?: Maybe<Array<Maybe<InspectionTemplate>>>;
  je?: Maybe<JournalEntry>;
  journalEntry?: Maybe<JournalEntry>;
  leaseDocumentTemplate?: Maybe<LeaseDocumentTemplate>;
  leaseForm?: Maybe<LeaseForm>;
  leaseForms?: Maybe<Array<LeaseForm>>;
  leaseInspection?: Maybe<LeaseInspection>;
  manager?: Maybe<Manager>;
  managers?: Maybe<Array<Maybe<Manager>>>;
  opensearch?: Maybe<OpenSearch>;
  operator?: Maybe<Operator>;
  operatorByEmail?: Maybe<Operator>;
  owner?: Maybe<Owner>;
  ownerByIds?: Maybe<Array<Maybe<Owner>>>;
  /** @deprecated Not paginated- use `Account.listProperties` to ensure all records are returned`. */
  properties?: Maybe<Array<Maybe<Property>>>;
  property?: Maybe<Property>;
  reconciliation?: Maybe<Reconciliation>;
  recurrence?: Maybe<Recurrence>;
  request?: Maybe<Request>;
  requestRecurrence?: Maybe<RequestRecurrence>;
  residency?: Maybe<Residency>;
  residencyProRatedEffects?: Maybe<ResidencyProRatedEffectsPayload>;
  searchOwnersByEmail?: Maybe<Array<Maybe<Owner>>>;
  supplier?: Maybe<Supplier>;
  suppliers?: Maybe<Array<Maybe<Supplier>>>;
  team?: Maybe<Team>;
  templates?: Maybe<Array<Maybe<Template>>>;
  tenant?: Maybe<Tenant>;
  tenantByIds?: Maybe<Array<Maybe<Tenant>>>;
  tenantByProfileId?: Maybe<Tenant>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  tenantsByEmail?: Maybe<Array<Maybe<Tenant>>>;
  unit?: Maybe<Unit>;
  units?: Maybe<Array<Maybe<Unit>>>;
  unitsWithResidencies?: Maybe<Array<Maybe<Unit>>>;
  utilitiesTemplate?: Maybe<UtilitiesTemplate>;
};

export type QueryAccountArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAnnouncementArgs = {
  id: Scalars['ID']['input'];
};

export type QueryApprovalArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBatchArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBuildingArgs = {
  filter: BuildingFilter;
};

export type QueryCampaignArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCampaignsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type QueryClearableArgs = {
  id: Scalars['ID']['input'];
};

export type QueryContactArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCustomTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDefaultTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDepositTemplateArgs = {
  province: Scalars['String']['input'];
};

export type QueryDraftBatchArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFloorplanArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFloorplanByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryGetProvinceTaxDetailsArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  input: GetProvinceTaxDetailsInput;
};

export type QueryGetRegionTaxDetailsArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  input: GetRegionTaxDetailsInput;
};

export type QueryGlAccountArgs = {
  id: Scalars['ID']['input'];
};

export type QueryInspectionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryInspectionByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryJeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryJournalEntryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLeaseDocumentTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLeaseFormArgs = {
  input: LeaseFormInput;
};

export type QueryLeaseFormsArgs = {
  filter?: InputMaybe<LeaseFormsFilter>;
};

export type QueryLeaseInspectionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryManagerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOperatorArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOperatorByEmailArgs = {
  email: Scalars['AWSEmail']['input'];
};

export type QueryOwnerArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOwnerByIdsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryPropertiesArgs = {
  filter?: InputMaybe<PropertiesFilter>;
};

export type QueryPropertyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryReconciliationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRecurrenceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRequestArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRequestRecurrenceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryResidencyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryResidencyProRatedEffectsArgs = {
  input: ResidencyProRatedEffectsInput;
};

export type QuerySearchOwnersByEmailArgs = {
  filter: OwnerSearchFilter;
};

export type QuerySupplierArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTeamArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTenantArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTenantByIdsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryTenantByProfileIdArgs = {
  profileId: Scalars['ID']['input'];
};

export type QueryTenantsByEmailArgs = {
  email: Scalars['String']['input'];
};

export type QueryUnitArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUnitsArgs = {
  filter: UnitFilter;
};

export type QueryUnitsWithResidenciesArgs = {
  filter: UnitsWithResidenciesFilter;
};

export type QueryUtilitiesTemplateArgs = {
  propertyId?: InputMaybe<Scalars['ID']['input']>;
};

export type RateSchedule = {
  __typename?: 'RateSchedule';
  rent: Scalars['Float']['output'];
  start: Scalars['AWSDate']['output'];
};

export type RateScheduleInput = {
  rent: Scalars['Float']['input'];
  start: Scalars['AWSDate']['input'];
};

export type Reconciliation = {
  __typename?: 'Reconciliation';
  booksBatchId?: Maybe<Scalars['ID']['output']>;
  completedZ?: Maybe<Scalars['String']['output']>;
  configuration: Scalars['String']['output'];
  current?: Maybe<Scalars['Boolean']['output']>;
  documentError?: Maybe<Scalars['String']['output']>;
  documentGeneratedZ?: Maybe<Scalars['String']['output']>;
  documentKey?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  glAccount?: Maybe<GlAccount>;
  id: Scalars['ID']['output'];
  lastReconciliationId?: Maybe<Scalars['ID']['output']>;
  lastStatementBalance?: Maybe<Scalars['Float']['output']>;
  ledgerDocumentError?: Maybe<Scalars['String']['output']>;
  ledgerDocumentGeneratedZ?: Maybe<Scalars['String']['output']>;
  ledgerDocumentKey?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  reindexToken?: Maybe<Scalars['String']['output']>;
  scope: ReconciliationScope;
  statementBalance?: Maybe<Scalars['Float']['output']>;
  statementDate: Scalars['AWSDate']['output'];
  status: ReconciliationStatus;
  taskProgress?: Maybe<ReconciliationTaskProgress>;
};

export type ReconciliationConfig = {
  __typename?: 'ReconciliationConfig';
  bankId?: Maybe<Scalars['ID']['output']>;
  configuration: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  scope: ReconciliationScope;
};

export type ReconciliationConfigConnection = {
  __typename?: 'ReconciliationConfigConnection';
  items?: Maybe<Array<ReconciliationConfig>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ReconciliationConfigPayload = {
  __typename?: 'ReconciliationConfigPayload';
  error?: Maybe<Scalars['String']['output']>;
  reconciliationConfig?: Maybe<ReconciliationConfig>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ReconciliationConnection = {
  __typename?: 'ReconciliationConnection';
  items?: Maybe<Array<Reconciliation>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ReconciliationFilter = {
  configurationId?: InputMaybe<Scalars['ID']['input']>;
};

export type ReconciliationJournalEntryInput = {
  amount: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
  posted: Scalars['AWSDate']['input'];
  reconciliationId?: InputMaybe<Scalars['ID']['input']>;
};

export type ReconciliationJournalFilter = {
  draftReconciliationId?: InputMaybe<Scalars['ID']['input']>;
  glId: Scalars['ID']['input'];
  numberFilters?: InputMaybe<Array<IntFilterFieldInput>>;
  propertyOwnerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  reconciliationId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  showCleared?: InputMaybe<Scalars['Boolean']['input']>;
  statementDate?: InputMaybe<Scalars['AWSDate']['input']>;
  stringFilters?: InputMaybe<Array<StringFilterFieldInput>>;
  transactionType?: InputMaybe<JournalTransactionType>;
};

export type ReconciliationPayload = {
  __typename?: 'ReconciliationPayload';
  error?: Maybe<Scalars['String']['output']>;
  reconciliation?: Maybe<Reconciliation>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ReconciliationScope = {
  __typename?: 'ReconciliationScope';
  glId: Scalars['ID']['output'];
  propertyOwnerIds?: Maybe<Array<Scalars['String']['output']>>;
};

export enum ReconciliationStatus {
  Committed = 'COMMITTED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Failed = 'FAILED',
  Prepared = 'PREPARED',
  Reconciling = 'RECONCILING',
  Started = 'STARTED',
}

export type ReconciliationTaskProgress = {
  __typename?: 'ReconciliationTaskProgress';
  clearedAmount?: Maybe<Scalars['Float']['output']>;
  done?: Maybe<Scalars['Boolean']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
};

export type Recurrence = {
  __typename?: 'Recurrence';
  dueOffset?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  lastPosted?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  output: RecurrenceOutput;
  rrule: Scalars['String']['output'];
};

export type RecurrenceClearableConfig = {
  __typename?: 'RecurrenceClearableConfig';
  payee: Scalars['String']['output'];
  payeeId: Scalars['String']['output'];
  postedForDue?: Maybe<Scalars['Boolean']['output']>;
};

export type RecurrenceInput = {
  dueOffset?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  rrule: Scalars['String']['input'];
};

export type RecurrenceOutput = {
  __typename?: 'RecurrenceOutput';
  clearable?: Maybe<RecurrenceClearableConfig>;
  lines: Array<JournalEntryLine>;
};

export type Refundable = {
  __typename?: 'Refundable';
  accrualStartExpr?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  collectedZ?: Maybe<Scalars['String']['output']>;
  extraCollection?: Maybe<Scalars['Float']['output']>;
  heldByOwner?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  interest?: Maybe<Scalars['Float']['output']>;
  ledger?: Maybe<Array<RefundableLedger>>;
  ledgerHash?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  retained?: Maybe<Scalars['Float']['output']>;
  returned?: Maybe<Scalars['Float']['output']>;
  settledZ?: Maybe<Scalars['String']['output']>;
  simple?: Maybe<Scalars['Boolean']['output']>;
  templateId: Scalars['ID']['output'];
};

export type RefundableInput = {
  accrualStartExpr?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  collectedZ?: InputMaybe<Scalars['String']['input']>;
  heldByOwner?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ledgerInput?: InputMaybe<Array<RefundableLedgerInput>>;
  name: Scalars['String']['input'];
  settledZ?: InputMaybe<Scalars['String']['input']>;
  simple?: InputMaybe<Scalars['Boolean']['input']>;
  templateId: Scalars['ID']['input'];
};

export type RefundableLedger = {
  __typename?: 'RefundableLedger';
  amount: Scalars['Float']['output'];
  category: RefundableLedgerCategory;
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export enum RefundableLedgerCategory {
  Collection = 'COLLECTION',
  Credit = 'CREDIT',
  Damages = 'DAMAGES',
  Disbursement = 'DISBURSEMENT',
  Interest = 'INTEREST',
  Settlement = 'SETTLEMENT',
}

export type RefundableLedgerInput = {
  amount: Scalars['Float']['input'];
  category: RefundableLedgerCategory;
  date: Scalars['AWSDate']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RegionTax = {
  __typename?: 'RegionTax';
  accountId: Scalars['ID']['output'];
  categories?: Maybe<Array<TaxCategory>>;
  country: Scalars['String']['output'];
  defaultTaxes: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  region: Scalars['String']['output'];
  taxes?: Maybe<Array<Tax>>;
};

export type RegionTaxDetailsPayload = {
  __typename?: 'RegionTaxDetailsPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  taxDetails?: Maybe<Array<TaxDetail>>;
};

export type RelayEdge = {
  cursor: Scalars['ID']['output'];
};

export type RelayPage = {
  edges: Array<RelayEdge>;
  pageInfo: RelayPageInfo;
};

export type RelayPageInfo = {
  __typename?: 'RelayPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type RemoveJournalsFromReconciliationInput = {
  journals: Array<JournalKeyInput>;
};

export type RemoveOwnerPropertyInput = {
  ownerId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type RemoveOwnerUnitInput = {
  ownerId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type RemovePropertyKeyInput = {
  id: Scalars['ID']['input'];
  keyringName: Scalars['String']['input'];
};

export type RemovePropertyParkingInput = {
  id: Scalars['ID']['input'];
  parkingName: Scalars['String']['input'];
};

export type RemovePropertyStorageInput = {
  id: Scalars['ID']['input'];
  storageName: Scalars['String']['input'];
};

export type RemoveReconciliationInput = {
  id: Scalars['ID']['input'];
  posted: Scalars['AWSDate']['input'];
};

export type RemoveReconciliationPayload = {
  __typename?: 'RemoveReconciliationPayload';
  error?: Maybe<Scalars['String']['output']>;
  lastReconciliation?: Maybe<Reconciliation>;
  reconciliation?: Maybe<Reconciliation>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveResidencyResidentInput = {
  id: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  residentId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type RenameBuildingDocumentInput = {
  buildingId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
};

export type RenameLeaseDocumentInput = {
  key: Scalars['String']['input'];
  leaseId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type RenameOwnerDocumentInput = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  ownerId: Scalars['ID']['input'];
};

export type RenamePropertyDocumentInput = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
};

export type RenameRequestDocumentInput = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  requestId: Scalars['ID']['input'];
};

export type RenameResidencyDocumentInput = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  residencyId: Scalars['ID']['input'];
};

export type RenameTenantDocumentInput = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tenantId: Scalars['ID']['input'];
};

export type RenameUnitDocumentInput = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  unitId: Scalars['ID']['input'];
};

export type RenderedAction = {
  __typename?: 'RenderedAction';
  glId?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['String']['output']>;
  propertyId?: Maybe<Scalars['String']['output']>;
};

export type RentRollFee = {
  __typename?: 'RentRollFee';
  amount?: Maybe<Scalars['Float']['output']>;
  feeId: Scalars['ID']['output'];
};

export type RentRollFeesInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  feeId: Scalars['ID']['input'];
};

export type RentalAssistance = {
  __typename?: 'RentalAssistance';
  amount?: Maybe<Scalars['Float']['output']>;
  qualified?: Maybe<Scalars['Boolean']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type RentalAssistanceInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  qualified?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export enum RentalType {
  Family = 'family',
  Own = 'own',
  Rental = 'rental',
}

export type Reply = {
  __typename?: 'Reply';
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  createdName: Scalars['String']['output'];
  createdSub: Scalars['ID']['output'];
  createdZ: Scalars['AWSDateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  managerReply?: Maybe<Scalars['Boolean']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
  replyId?: Maybe<Scalars['ID']['output']>;
  text: Scalars['String']['output'];
};

export type ReportBundle = {
  __typename?: 'ReportBundle';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  presetIds: Array<Scalars['ID']['output']>;
};

export type ReportBundlePayload = {
  __typename?: 'ReportBundlePayload';
  error?: Maybe<Scalars['String']['output']>;
  reportBundle?: Maybe<ReportBundle>;
  success: Scalars['Boolean']['output'];
};

export type ReportSet = {
  __typename?: 'ReportSet';
  setId: Scalars['ID']['output'];
};

export type ReportSetPayload = {
  __typename?: 'ReportSetPayload';
  error?: Maybe<Scalars['String']['output']>;
  reportSet?: Maybe<ReportSet>;
  success: Scalars['Boolean']['output'];
};

export type Request = UnitHistory & {
  __typename?: 'Request';
  accountId: Scalars['ID']['output'];
  additionalTenants?: Maybe<Array<Maybe<RequestTenant>>>;
  analysis?: Maybe<Scalars['AWSJSON']['output']>;
  appointmentChanges?: Maybe<Array<Maybe<AppointmentChange>>>;
  appointmentPreferences?: Maybe<Array<Maybe<Interval>>>;
  appointmentZ?: Maybe<Scalars['AWSDateTime']['output']>;
  approval?: Maybe<MaintenanceApproval>;
  autoSummary?: Maybe<Scalars['String']['output']>;
  buildingAddress?: Maybe<Address>;
  buildingId?: Maybe<Scalars['ID']['output']>;
  buildingKey?: Maybe<Scalars['String']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  cancelledReason?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  checklist?: Maybe<Array<Maybe<ChecklistItem>>>;
  consents?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  costLines?: Maybe<Array<CostLine>>;
  costs?: Maybe<Array<RequestCost>>;
  delay?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  entryConsentDetails?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Maybe<Field>>>;
  finalizedName?: Maybe<Scalars['String']['output']>;
  finalizedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  fingerprints?: Maybe<Fingerprints>;
  firstOccurred?: Maybe<Scalars['String']['output']>;
  history?: Maybe<Array<StatusHistoryEvent>>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Image>>>;
  instructions?: Maybe<Scalars['String']['output']>;
  journalId?: Maybe<Scalars['String']['output']>;
  minutes?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nonBillable?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  occupant?: Maybe<RequestTenant>;
  openedZ: Scalars['AWSDateTime']['output'];
  operator?: Maybe<Operator>;
  operatorName?: Maybe<Scalars['String']['output']>;
  originalRequest?: Maybe<Scalars['String']['output']>;
  ownerApprovalId?: Maybe<Scalars['String']['output']>;
  ownerApprovalStatus?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  printNotes?: Maybe<Scalars['Boolean']['output']>;
  propertyAddress?: Maybe<Address>;
  propertyId?: Maybe<Scalars['ID']['output']>;
  propertyKey?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  resolution?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  sourceInspection?: Maybe<SourceInspection>;
  status?: Maybe<Scalars['String']['output']>;
  subcategory?: Maybe<Scalars['String']['output']>;
  /** @deprecated use `openedZ` instead */
  submittedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  taxExempted?: Maybe<Scalars['Boolean']['output']>;
  tenant?: Maybe<RequestTenant>;
  tenantId?: Maybe<Scalars['ID']['output']>;
  tenantName?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Unit>;
  unitId?: Maybe<Scalars['ID']['output']>;
  unitName?: Maybe<Scalars['String']['output']>;
  unitOccupancy?: Maybe<UnitOccupancy>;
  visits?: Maybe<Array<Maybe<Visit>>>;
};

export type RequestConnection = {
  __typename?: 'RequestConnection';
  items?: Maybe<Array<Maybe<Request>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type RequestCost = {
  __typename?: 'RequestCost';
  amount: Scalars['Float']['output'];
  date: Scalars['AWSDate']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  markup: Scalars['Boolean']['output'];
};

export type RequestEdge = RelayEdge & {
  __typename?: 'RequestEdge';
  cursor: Scalars['ID']['output'];
  node: Request;
};

export type RequestEvent = UnitHistory & {
  __typename?: 'RequestEvent';
  fingerprints?: Maybe<Fingerprints>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type RequestImagesInput = {
  images?: InputMaybe<Array<ImageInput>>;
  requestId: Scalars['ID']['input'];
};

export type RequestPayload = {
  __typename?: 'RequestPayload';
  error?: Maybe<Scalars['String']['output']>;
  request?: Maybe<Request>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RequestRecurrence = {
  __typename?: 'RequestRecurrence';
  accountId: Scalars['ID']['output'];
  category?: Maybe<Scalars['String']['output']>;
  checklist?: Maybe<Array<Maybe<ChecklistItem>>>;
  details?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  latestRequest?: Maybe<Request>;
  minutes?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextRecurrenceZ?: Maybe<Scalars['AWSDateTime']['output']>;
  nonBillable?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  originalRequest?: Maybe<Scalars['String']['output']>;
  printNotes?: Maybe<Scalars['Boolean']['output']>;
  property?: Maybe<Property>;
  rrule: Scalars['String']['output'];
  subcategory?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Unit>;
};

export type RequestRecurrenceConnection = {
  __typename?: 'RequestRecurrenceConnection';
  items?: Maybe<Array<Maybe<RequestRecurrence>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type RequestRecurrencePayload = {
  __typename?: 'RequestRecurrencePayload';
  error?: Maybe<Scalars['String']['output']>;
  requestRecurrence?: Maybe<RequestRecurrence>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RequestTenant = {
  __typename?: 'RequestTenant';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type RequestsFilterInput = {
  minScore?: InputMaybe<Scalars['Float']['input']>;
  numberFilters?: InputMaybe<Array<IntFilterFieldInput>>;
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
  stringFilters?: InputMaybe<Array<StringFilterFieldInput>>;
};

export type RequestsPage = RelayPage & {
  __typename?: 'RequestsPage';
  edges: Array<RequestEdge>;
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum RequestsSortField {
  Category = 'category',
  FinalizedZ = 'finalizedZ',
  Name = 'name',
  OpenedZ = 'openedZ',
  Status = 'status',
  Summary = 'summary',
  TenantName = 'tenantName',
  UnitName = 'unitName',
}

export type RequestsSortInput = {
  direction: SortDirection;
  field: RequestsSortField;
};

export type ResendAutopayInviteInput = {
  enrollmentId: Scalars['ID']['input'];
  payeeId: Scalars['ID']['input'];
};

export type ResidenciesFilterInput = {
  autoRenewOrMonthToMonth?: InputMaybe<Scalars['Boolean']['input']>;
  includeFuture?: InputMaybe<Scalars['Boolean']['input']>;
  includePast?: InputMaybe<Scalars['Boolean']['input']>;
  leaseTerm?: InputMaybe<Scalars['String']['input']>;
  occupancy?: InputMaybe<UnitOccupancy>;
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  referenceZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  rentIncreaseEligible?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  stringFilters?: InputMaybe<Array<StringFilterFieldInput>>;
};

export type ResidenciesPage = RelayPage & {
  __typename?: 'ResidenciesPage';
  edges: Array<ResidencyEdge>;
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum ResidenciesSortField {
  BuildingKey = 'buildingKey',
  BuildingName = 'buildingName',
  Id = 'id',
  LatestRentEffectZ = 'latestRentEffectZ',
  OwnerName = 'ownerName',
  PropertyKey = 'propertyKey',
  PropertyName = 'propertyName',
  PropertyUnitName = 'propertyUnitName',
  StartZ = 'startZ',
  UnitName = 'unitName',
}

export type ResidenciesSortInput = {
  direction: SortDirection;
  field: ResidenciesSortField;
};

export type Residency = {
  __typename?: 'Residency';
  accountId: Scalars['ID']['output'];
  accruals?: Maybe<Array<RefundableLedger>>;
  additionalProvisions?: Maybe<AdditionalProvisions>;
  applicationId?: Maybe<Scalars['String']['output']>;
  buildingKey?: Maybe<Scalars['String']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  campaignId?: Maybe<Scalars['String']['output']>;
  collectionDay?: Maybe<Scalars['Int']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  effects?: Maybe<Array<ResidencyEffectRecord>>;
  endZ?: Maybe<Scalars['String']['output']>;
  eventHistory?: Maybe<Array<ResidencyEvent>>;
  furnishings?: Maybe<Array<Scalars['String']['output']>>;
  gst?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  insurancePolicies?: Maybe<Array<Maybe<LeaseInsurancePolicy>>>;
  /** @deprecated Use insurancePolicies instead. */
  insurancePolicy?: Maybe<LeaseInsurancePolicy>;
  latestRentEffectZ?: Maybe<Scalars['String']['output']>;
  leaseAgreement?: Maybe<LeaseAgreement>;
  moveInDate?: Maybe<Scalars['AWSDate']['output']>;
  moveInInspection?: Maybe<LeaseInspection>;
  moveInInspectionId?: Maybe<Scalars['ID']['output']>;
  moveOutDate?: Maybe<Scalars['AWSDate']['output']>;
  moveOutInspection?: Maybe<LeaseInspection>;
  moveOutInspectionId?: Maybe<Scalars['ID']['output']>;
  moveOutReason?: Maybe<MoveOutReason>;
  notes?: Maybe<Array<Maybe<Note>>>;
  occupancy?: Maybe<UnitOccupancy>;
  outstanding?: Maybe<ResidencyOutstanding>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  property?: Maybe<Property>;
  propertyAddress?: Maybe<Address>;
  propertyId: Scalars['ID']['output'];
  propertyKey?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  proratedClearableIds?: Maybe<Array<Scalars['ID']['output']>>;
  refundables?: Maybe<Array<Refundable>>;
  renewZ?: Maybe<Scalars['String']['output']>;
  residentDocuments?: Maybe<Array<ResidentDocument>>;
  residentInsurancePolicies?: Maybe<Array<Maybe<IamTenantInsurancePolicy>>>;
  residents: Array<ResidentAssociation>;
  rrule?: Maybe<Scalars['String']['output']>;
  securityDeposit?: Maybe<Scalars['Float']['output']>;
  securityDepositHistory?: Maybe<Array<SecurityDepositHistory>>;
  startZ: Scalars['String']['output'];
  terms?: Maybe<Array<Scalars['String']['output']>>;
  type?: Maybe<LeaseType>;
  unit?: Maybe<Unit>;
  unitId: Scalars['ID']['output'];
  unitName?: Maybe<Scalars['String']['output']>;
  unitOccupancy?: Maybe<UnitOccupancy>;
  unitOwnerId?: Maybe<Scalars['ID']['output']>;
  utilities?: Maybe<Array<Scalars['String']['output']>>;
};

export type ResidencyAccrualsArgs = {
  input: AccrualsInput;
};

export type ResidencyResidentsArgs = {
  filter?: InputMaybe<ResidencyResidentFilterInput>;
};

export type ResidencyConnection = {
  __typename?: 'ResidencyConnection';
  items?: Maybe<Array<Maybe<Residency>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ResidencyConnectionFilter = {
  activeOnDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  expiringInEnd?: InputMaybe<Scalars['AWSDate']['input']>;
  expiringInStart?: InputMaybe<Scalars['AWSDate']['input']>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ResidencyEdge = RelayEdge & {
  __typename?: 'ResidencyEdge';
  cursor: Scalars['ID']['output'];
  node: Residency;
};

export type ResidencyEffect = ResidencyEffectRecord & {
  __typename?: 'ResidencyEffect';
  effect: Scalars['Float']['output'];
  feeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  startZ: Scalars['AWSDateTime']['output'];
};

export type ResidencyEffectInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  effect: Scalars['Float']['input'];
  feeId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  incentiveId?: InputMaybe<Scalars['ID']['input']>;
  startZ: Scalars['AWSDateTime']['input'];
};

export type ResidencyEffectRecord = {
  effect: Scalars['Float']['output'];
  feeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  startZ: Scalars['AWSDateTime']['output'];
};

export type ResidencyEvent = {
  __typename?: 'ResidencyEvent';
  category: ResidencyEventCategory;
  deletedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  eventDateZ: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  noteText?: Maybe<Scalars['String']['output']>;
  noticeZ?: Maybe<Scalars['AWSDateTime']['output']>;
  resolvedZ?: Maybe<Scalars['AWSDateTime']['output']>;
};

export enum ResidencyEventCategory {
  BylawViolation = 'BYLAW_VIOLATION',
  CriminalActivity = 'CRIMINAL_ACTIVITY',
  DomesticViolence = 'DOMESTIC_VIOLENCE',
  FalseApplicationInfo = 'FALSE_APPLICATION_INFO',
  HealthSafetyStandards = 'HEALTH_SAFETY_STANDARDS',
  Inspection = 'INSPECTION',
  LeaseTermsViolation = 'LEASE_TERMS_VIOLATION',
  NeighbourlyMisconduct = 'NEIGHBOURLY_MISCONDUCT',
  NonPaymentDeposits = 'NON_PAYMENT_DEPOSITS',
  NonPaymentFees = 'NON_PAYMENT_FEES',
  NonPaymentRent = 'NON_PAYMENT_RENT',
  NonPaymentSpecialLevy = 'NON_PAYMENT_SPECIAL_LEVY',
  Parking = 'PARKING',
  PropertyDamage = 'PROPERTY_DAMAGE',
  RateChange = 'RATE_CHANGE',
  Renewal = 'RENEWAL',
  ResidentChange = 'RESIDENT_CHANGE',
  TermChange = 'TERM_CHANGE',
  UnauthorizedPet = 'UNAUTHORIZED_PET',
  UnitModificationApproval = 'UNIT_MODIFICATION_APPROVAL',
  UnitModificationRefusal = 'UNIT_MODIFICATION_REFUSAL',
}

export type ResidencyInput = {
  additionalProvisions?: InputMaybe<AdditionalProvisionsInput>;
  applicationId?: InputMaybe<Scalars['String']['input']>;
  autoRenewSetup?: InputMaybe<AutoRenewSetupInput>;
  campaignId?: InputMaybe<Scalars['String']['input']>;
  endZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  furnishings?: InputMaybe<Array<Scalars['String']['input']>>;
  gst?: InputMaybe<Scalars['Float']['input']>;
  occupancy: UnitOccupancy;
  previousLeaseEnd?: InputMaybe<Scalars['String']['input']>;
  securityDeposit?: InputMaybe<Scalars['Float']['input']>;
  startZ: Scalars['AWSDateTime']['input'];
  unitOwnerId?: InputMaybe<Scalars['ID']['input']>;
  utilities?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ResidencyInsuranceInput = {
  insurancePolicies?: InputMaybe<Array<LeaseInsurancePolicyInput>>;
};

export enum ResidencyNoteType {
  Deposits = 'DEPOSITS',
}

export type ResidencyOutstanding = {
  __typename?: 'ResidencyOutstanding';
  clearables?: Maybe<Array<Maybe<Clearable>>>;
  totalOutstanding?: Maybe<Scalars['Float']['output']>;
};

export type ResidencyPayload = {
  __typename?: 'ResidencyPayload';
  error?: Maybe<Scalars['String']['output']>;
  residency?: Maybe<Residency>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ResidencyProRatedEffect = {
  __typename?: 'ResidencyProRatedEffect';
  amount?: Maybe<Scalars['Float']['output']>;
  autopayActivated?: Maybe<Scalars['Boolean']['output']>;
  effects?: Maybe<Array<ProRatedEffect>>;
  enrollmentId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  residentId: Scalars['ID']['output'];
};

export type ResidencyProRatedEffectsInput = {
  collectionType: CollectionType;
  propertyId: Scalars['ID']['input'];
  residencyId: Scalars['ID']['input'];
};

export type ResidencyProRatedEffectsPayload = {
  __typename?: 'ResidencyProRatedEffectsPayload';
  residents?: Maybe<Array<ResidencyProRatedEffect>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ResidencyResidentFilterInput = {
  residentId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ResidencyResidentTitle {
  AdditionalResident = 'ADDITIONAL_RESIDENT',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  PropertyManager = 'PROPERTY_MANAGER',
  Sublet = 'SUBLET',
  Tenant = 'TENANT',
}

export type Resident = {
  __typename?: 'Resident';
  accountId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  profileId?: Maybe<Scalars['ID']['output']>;
};

export type ResidentAssociation = {
  __typename?: 'ResidentAssociation';
  id: Scalars['ID']['output'];
  leaseHolder?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use `paymentDetails` instead */
  padAccount?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `paymentDetails` instead */
  padAmount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use `paymentDetails` instead */
  padInstitution?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `paymentDetails` instead */
  padTransit?: Maybe<Scalars['String']['output']>;
  payeeFrac?: Maybe<Fraction>;
  paymentDetails?: Maybe<PaymentDetails>;
  /** @deprecated Use `paymentDetails` instead */
  preferredPaymentMethod?: Maybe<PaymentMethod>;
  residencyId: Scalars['ID']['output'];
  resident: ResidentRecord;
  residentId: Scalars['ID']['output'];
  residentName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<ResidencyResidentTitle>;
};

export type ResidentAssociationInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  inviteToAutopay?: InputMaybe<Scalars['Boolean']['input']>;
  leaseHolder?: InputMaybe<Scalars['Boolean']['input']>;
  payeeFrac?: InputMaybe<FractionInput>;
  paymentDetails?: InputMaybe<PaymentDetailsInput>;
  residentId?: InputMaybe<Scalars['ID']['input']>;
  residentInput?: InputMaybe<ResidentInput>;
  title?: InputMaybe<ResidencyResidentTitle>;
};

export type ResidentDocument = {
  __typename?: 'ResidentDocument';
  document?: Maybe<Document>;
  entityId?: Maybe<Scalars['ID']['output']>;
  entityType?: Maybe<Scalars['String']['output']>;
  residentId: Scalars['ID']['output'];
  sentZ?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type ResidentEdge = RelayEdge & {
  __typename?: 'ResidentEdge';
  cursor: Scalars['ID']['output'];
  node: Resident;
};

export type ResidentInput = {
  autopayAccount?: InputMaybe<Scalars['String']['input']>;
  autopayInstitution?: InputMaybe<Scalars['String']['input']>;
  autopayInvitedZ?: InputMaybe<Scalars['String']['input']>;
  autopayStatus?: InputMaybe<Scalars['String']['input']>;
  autopayTransit?: InputMaybe<Scalars['String']['input']>;
  correspondenceMethod?: InputMaybe<CorrespondenceMethod>;
  dob?: InputMaybe<Scalars['AWSDate']['input']>;
  emails?: InputMaybe<Array<InputMaybe<Scalars['AWSEmail']['input']>>>;
  emergencyContacts?: InputMaybe<Array<InputMaybe<EmergencyContactInput>>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isRentalAssist?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  pets?: InputMaybe<Array<PetInput>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<InputMaybe<Scalars['AWSPhone']['input']>>>;
  rentalAssistance?: InputMaybe<RentalAssistanceInput>;
  type?: InputMaybe<Scalars['String']['input']>;
  vehicles?: InputMaybe<Array<VehicleInput>>;
  welcomedZ?: InputMaybe<Scalars['String']['input']>;
};

export type ResidentPage = RelayPage & {
  __typename?: 'ResidentPage';
  edges: Array<ResidentEdge>;
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ResidentRecord = {
  accountId: Scalars['ID']['output'];
  autopayClientId?: Maybe<Scalars['ID']['output']>;
  correspondenceMethod?: Maybe<CorrespondenceMethod>;
  documents?: Maybe<Array<Maybe<Document>>>;
  emails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  emergencyContacts?: Maybe<Array<Maybe<EmergencyContact>>>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  enrollments?: Maybe<Array<Maybe<AutopayEnrollment>>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Array<Maybe<Note>>>;
  phones?: Maybe<Array<Maybe<Scalars['AWSPhone']['output']>>>;
  profileId?: Maybe<Scalars['ID']['output']>;
  propertyOptions?: Maybe<Array<PropertyOption>>;
  vehicles?: Maybe<Array<Vehicle>>;
};

export type ResidentRecordEnrollmentsArgs = {
  filter?: InputMaybe<AutopayEnrollmentFilterInput>;
};

export enum ResidentRequestScheduling {
  None = 'NONE',
  Preferences = 'PREFERENCES',
}

export enum RiskProfile {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
}

export type RoutineInspectionPayload = {
  __typename?: 'RoutineInspectionPayload';
  error?: Maybe<Scalars['String']['output']>;
  inspection?: Maybe<Inspection>;
  success?: Maybe<Scalars['Boolean']['output']>;
  unit?: Maybe<Unit>;
};

export type RunReportSetInput = {
  bundleId: Scalars['ID']['input'];
  inputBindings: Scalars['AWSJSON']['input'];
  name: Scalars['String']['input'];
  ownerProperty: Array<Scalars['ID']['input']>;
  presetIds: Array<Scalars['ID']['input']>;
};

export enum SalaryFrequency {
  AnnualSalary = 'annual_salary',
  HourlyRate = 'hourly_rate',
  MonthlySalary = 'monthly_salary',
}

export type ScanApproval = ApprovalRecord & {
  __typename?: 'ScanApproval';
  accountId: Scalars['String']['output'];
  approvedSub?: Maybe<Scalars['ID']['output']>;
  approvedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  approverRoles?: Maybe<Array<Maybe<ApproverRole>>>;
  declinedSub?: Maybe<Scalars['ID']['output']>;
  declinedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['String']['output'];
  request: ScanApprovalRequest;
  requestedName?: Maybe<Scalars['String']['output']>;
  requestedSub: Scalars['ID']['output'];
  requestedZ: Scalars['AWSDateTime']['output'];
  status: ApprovalStatus;
};

export type ScanApprovalLine = {
  __typename?: 'ScanApprovalLine';
  amount: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  glId: Scalars['ID']['output'];
  unitId?: Maybe<Scalars['ID']['output']>;
};

export type ScanApprovalLineInput = {
  amount: Scalars['Float']['input'];
  description: Scalars['String']['input'];
  glId: Scalars['ID']['input'];
  unitId?: InputMaybe<Scalars['ID']['input']>;
};

export type ScanApprovalPayload = {
  __typename?: 'ScanApprovalPayload';
  approval?: Maybe<ScanApproval>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ScanApprovalRequest = {
  __typename?: 'ScanApprovalRequest';
  amount: Scalars['Float']['output'];
  description?: Maybe<Scalars['String']['output']>;
  due?: Maybe<Scalars['AWSDate']['output']>;
  expenseGlId?: Maybe<Scalars['ID']['output']>;
  gstOrHstAllocation?: Maybe<Scalars['Float']['output']>;
  includesGstOrHst?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
  lines?: Maybe<Array<ScanApprovalLine>>;
  ownerId: Scalars['ID']['output'];
  payee: Scalars['String']['output'];
  payeeId: Scalars['ID']['output'];
  payeeName: Scalars['String']['output'];
  posted: Scalars['AWSDate']['output'];
  propertyId: Scalars['ID']['output'];
  ref?: Maybe<Scalars['String']['output']>;
  scanType?: Maybe<ScanType>;
  supplierAccount?: Maybe<Scalars['ID']['output']>;
};

export type ScanApprovalTaskInput = {
  label: Scalars['String']['input'];
  recordIds: Array<Scalars['ID']['input']>;
};

export type ScanMatchingTaskInput = {
  label: Scalars['String']['input'];
  recordIds: Array<Scalars['ID']['input']>;
};

export enum ScanType {
  Expense = 'expense',
  Payable = 'payable',
}

export type SearchClearablesInput = {
  payeeAccountIds: Array<PayeeClearablesFilterItemInput>;
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  showPaid: Scalars['Boolean']['input'];
  unitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SearchResidentsFilterInput = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type SearchUnit = {
  __typename?: 'SearchUnit';
  buildingKey?: Maybe<Scalars['String']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  propertyKey?: Maybe<Scalars['String']['output']>;
  propertyName: Scalars['String']['output'];
  unitId: Scalars['ID']['output'];
  unitName: Scalars['String']['output'];
};

export type SearchUnitPayload = {
  __typename?: 'SearchUnitPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  units?: Maybe<Array<Maybe<SearchUnit>>>;
};

export type SearchUnitsFilterInput = {
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  payee?: InputMaybe<PayeeType>;
  payeeId?: InputMaybe<Scalars['ID']['input']>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  unitId?: InputMaybe<Scalars['ID']['input']>;
};

export type SecurityDepositAllocation = {
  __typename?: 'SecurityDepositAllocation';
  amount: Scalars['Float']['output'];
  status: SecurityDepositStatus;
};

export type SecurityDepositHistory = {
  __typename?: 'SecurityDepositHistory';
  allocations: Array<SecurityDepositAllocation>;
  createdZ: Scalars['AWSDateTime']['output'];
};

export enum SecurityDepositStatus {
  Collected = 'collected',
  Outstanding = 'outstanding',
  Retained = 'retained',
  Returned = 'returned',
}

export type SetAutopayCollectionsEnabledInput = {
  autopayCollectionsEnabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type SetClearableAutopayCollectionsEnabledInput = {
  autopayCollectionsEnabled: Scalars['Boolean']['input'];
  clearableId: Scalars['ID']['input'];
};

export type SetPropertyAutopayCollectionsEnabledInput = {
  autopayCollectionsEnabled: Scalars['Boolean']['input'];
  propertyId: Scalars['ID']['input'];
};

export type Settings = {
  __typename?: 'Settings';
  campaignApplicationRules?: Maybe<CampaignApplicationRule>;
  later_days_threshold?: Maybe<Scalars['Int']['output']>;
  resident_request_scheduling?: Maybe<ResidentRequestScheduling>;
  soon_days_threshold?: Maybe<Scalars['Int']['output']>;
};

export type SettingsInput = {
  campaignApplicationRules?: InputMaybe<CampaignApplicationRuleInput>;
  later_days_threshold?: InputMaybe<Scalars['Int']['input']>;
  resident_request_scheduling?: InputMaybe<ResidentRequestScheduling>;
  soon_days_threshold?: InputMaybe<Scalars['Int']['input']>;
};

export type SettlementSummary = {
  __typename?: 'SettlementSummary';
  accountId: Scalars['ID']['output'];
  accountName?: Maybe<Scalars['String']['output']>;
  autopayId: Scalars['ID']['output'];
  bankId?: Maybe<Scalars['ID']['output']>;
  billingName?: Maybe<Scalars['String']['output']>;
  collectionPosted: Scalars['AWSDate']['output'];
  feesTotal?: Maybe<Scalars['Float']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  gstAmount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  items?: Maybe<Scalars['Int']['output']>;
  journalsTotal?: Maybe<Scalars['Float']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  paymentAmount?: Maybe<Scalars['Float']['output']>;
  paymentCount?: Maybe<Scalars['Int']['output']>;
  returnedItems?: Maybe<Scalars['Int']['output']>;
  returnedTotal?: Maybe<Scalars['Float']['output']>;
  settledZ?: Maybe<Scalars['AWSDateTime']['output']>;
  status: Scalars['String']['output'];
  total?: Maybe<Scalars['Float']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type ShareCampaignInput = {
  emails: Array<Scalars['AWSEmail']['input']>;
  id: Scalars['ID']['input'];
};

export type SignLeaseAgreementInput = {
  applicationId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
  signature: SignatureInput;
};

export type Signature = {
  __typename?: 'Signature';
  agentRelationship?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isAgent?: Maybe<Scalars['Boolean']['output']>;
  signature: Scalars['String']['output'];
  signerEntity: SignerEntity;
  signerId?: Maybe<Scalars['ID']['output']>;
  signerName: Scalars['String']['output'];
};

export type SignatureInput = {
  agentRelationship?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAgent?: InputMaybe<Scalars['Boolean']['input']>;
  signature: Scalars['String']['input'];
  signerEntity: SignerEntity;
  signerId?: InputMaybe<Scalars['ID']['input']>;
  signerName: Scalars['String']['input'];
};

export enum SignerEntity {
  Manager = 'manager',
  Tenant = 'tenant',
}

export type SingleBudgetInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  glId: Scalars['ID']['input'];
  ownerId: Scalars['ID']['input'];
  period: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type SourceInspection = {
  __typename?: 'SourceInspection';
  fieldId: Scalars['ID']['output'];
  inspectionId: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  leaseId: Scalars['ID']['output'];
  unitId: Scalars['ID']['output'];
};

export type StartAccountAutopayInput = {
  enableCollection: Scalars['Boolean']['input'];
  enablePayments: Scalars['Boolean']['input'];
  feeExpense: Scalars['String']['input'];
  invitePayees?: InputMaybe<Scalars['Boolean']['input']>;
  nsfFee?: InputMaybe<Scalars['Float']['input']>;
};

export type StartReconciliationInput = {
  configuration: Scalars['ID']['input'];
  statementBalance?: InputMaybe<Scalars['Float']['input']>;
  statementDate: Scalars['AWSDate']['input'];
};

export type StartReconciliationPayload = {
  __typename?: 'StartReconciliationPayload';
  error?: Maybe<Scalars['String']['output']>;
  journalEntries?: Maybe<Array<Maybe<JournalEntry>>>;
  reconciliation?: Maybe<Reconciliation>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type StatusHistoryEvent = {
  __typename?: 'StatusHistoryEvent';
  createdZ?: Maybe<Scalars['AWSDateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type StorageLocker = {
  __typename?: 'StorageLocker';
  name: Scalars['String']['output'];
  unitId?: Maybe<Scalars['String']['output']>;
};

export type StringFilterFieldInput = {
  anyOf?: InputMaybe<Array<Scalars['String']['input']>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  field: Scalars['String']['input'];
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notAnyOf?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SubAccount = {
  __typename?: 'SubAccount';
  glId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  owner?: Maybe<Owner>;
  ownerId: Scalars['ID']['output'];
  property?: Maybe<Property>;
  propertyId: Scalars['ID']['output'];
};

export type SubAccountConnection = {
  __typename?: 'SubAccountConnection';
  items?: Maybe<Array<Maybe<SubAccount>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type SubAccountFilterInput = {
  glId?: InputMaybe<Scalars['ID']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  ownerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type SubAccountInput = {
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  ownerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  range: PostedRangeInput;
};

export type Subscription = {
  __typename?: 'Subscription';
  onAccountEvent?: Maybe<AccountEvent>;
};

export type SubscriptionOnAccountEventArgs = {
  accountId: Scalars['ID']['input'];
};

export type SubscriptionEndpoint = {
  __typename?: 'SubscriptionEndpoint';
  address: Scalars['String']['output'];
  id: SubscriptionEndpointType;
};

export type SubscriptionEndpointInput = {
  address: Scalars['String']['input'];
  id: SubscriptionEndpointType;
};

export enum SubscriptionEndpointType {
  Email = 'EMAIL',
  Sms = 'SMS',
}

export type SubscriptionsInput = {
  subscriptions: Array<MessageSubscriptionInput>;
};

export type SuccessPayload = {
  __typename?: 'SuccessPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  accountId: Scalars['ID']['output'];
  address?: Maybe<Address>;
  autopayAccount?: Maybe<Scalars['String']['output']>;
  autopayClientId?: Maybe<Scalars['ID']['output']>;
  autopayInstitution?: Maybe<Scalars['String']['output']>;
  autopayStatus?: Maybe<Scalars['String']['output']>;
  autopayStatusUpdatedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  autopayTransit?: Maybe<Scalars['String']['output']>;
  billingEmail?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  coi?: Maybe<Scalars['Boolean']['output']>;
  coiExpiry?: Maybe<Scalars['AWSDate']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  deletedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  expenseGlId?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  fingerprints?: Maybe<Fingerprints>;
  id: Scalars['ID']['output'];
  /** @deprecated Use `address` */
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  supplierAccounts?: Maybe<Array<SupplierAccount>>;
  type?: Maybe<Scalars['String']['output']>;
  verificationRequestedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  verifiedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type SupplierAccount = {
  __typename?: 'SupplierAccount';
  accountNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type SupplierAccountInput = {
  accountNumber: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SupplierEdge = RelayEdge & {
  __typename?: 'SupplierEdge';
  cursor: Scalars['ID']['output'];
  node: Supplier;
};

export type SupplierInput = {
  address?: InputMaybe<AddressInput>;
  autopayAccount?: InputMaybe<Scalars['String']['input']>;
  autopayInstitution?: InputMaybe<Scalars['String']['input']>;
  autopayTransit?: InputMaybe<Scalars['String']['input']>;
  billingEmail?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  coi?: InputMaybe<Scalars['Boolean']['input']>;
  coiExpiry?: InputMaybe<Scalars['AWSDate']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  expenseGlId?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  supplierAccounts?: InputMaybe<Array<SupplierAccountInput>>;
  type?: InputMaybe<Scalars['String']['input']>;
  verificationRequestedZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type SupplierPayload = {
  __typename?: 'SupplierPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  supplier?: Maybe<Supplier>;
};

export type SuppliersConnection = {
  __typename?: 'SuppliersConnection';
  items?: Maybe<Array<Maybe<Supplier>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type SuppliersFilter = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SuppliersFilterInput = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  verifiedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SuppliersPage = RelayPage & {
  __typename?: 'SuppliersPage';
  edges: Array<SupplierEdge>;
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum SuppliersSortField {
  Id = 'id',
  Name = 'name',
  Type = 'type',
}

export type SuppliersSortInput = {
  direction: SortDirection;
  field: SuppliersSortField;
};

export type Tax = {
  __typename?: 'Tax';
  gst?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  postals?: Maybe<Array<Scalars['String']['output']>>;
  rate: Scalars['Float']['output'];
};

export enum TaxCategory {
  MgmtFee = 'MGMT_FEE',
  Request = 'REQUEST',
}

export type TaxDetail = {
  __typename?: 'TaxDetail';
  gst?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID']['output'];
  managerIds?: Maybe<Array<Scalars['ID']['output']>>;
  name: Scalars['String']['output'];
  occupancies: Array<Scalars['String']['output']>;
  propertyIds: Array<Scalars['ID']['output']>;
  propertyUnitIds?: Maybe<Array<PropertyUnit>>;
};

export type TeamPayload = {
  __typename?: 'TeamPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  team?: Maybe<Team>;
};

export type Template = {
  __typename?: 'Template';
  category?: Maybe<Scalars['String']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  fields?: Maybe<Array<Maybe<Field>>>;
  fingerprints?: Maybe<Fingerprints>;
  id: Scalars['ID']['output'];
  minutes?: Maybe<Scalars['Int']['output']>;
  response?: Maybe<Scalars['String']['output']>;
};

export type TemplateItem = {
  __typename?: 'TemplateItem';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Tenant = ResidentRecord & {
  __typename?: 'Tenant';
  accountId: Scalars['ID']['output'];
  allResidencies?: Maybe<Array<Maybe<Residency>>>;
  autopayAccount?: Maybe<Scalars['String']['output']>;
  autopayClientId?: Maybe<Scalars['ID']['output']>;
  autopayCollectionsEnabled?: Maybe<Scalars['Boolean']['output']>;
  autopayCollectionsEnabledZ?: Maybe<Scalars['AWSDateTime']['output']>;
  autopayInstitution?: Maybe<Scalars['String']['output']>;
  autopayInvitedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  autopayStatus?: Maybe<Scalars['String']['output']>;
  autopayStatusUpdatedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  autopayTransit?: Maybe<Scalars['String']['output']>;
  correspondenceMethod?: Maybe<CorrespondenceMethod>;
  currentResidencies?: Maybe<Array<Maybe<Residency>>>;
  dob?: Maybe<Scalars['AWSDate']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  emails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  emergencyContacts?: Maybe<Array<Maybe<EmergencyContact>>>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  enrollments?: Maybe<Array<Maybe<AutopayEnrollment>>>;
  fingerprints?: Maybe<Fingerprints>;
  futureResidencies?: Maybe<Array<Maybe<Residency>>>;
  id: Scalars['ID']['output'];
  isOwner?: Maybe<Scalars['Boolean']['output']>;
  isRentalAssist?: Maybe<Scalars['Boolean']['output']>;
  leaseFutureIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  leaseHistoryIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  leaseHolder?: Maybe<Scalars['Boolean']['output']>;
  leaseId?: Maybe<Scalars['String']['output']>;
  mailingAddress?: Maybe<Address>;
  name: Scalars['String']['output'];
  notes?: Maybe<Array<Maybe<Note>>>;
  /** @deprecated These are now maintained in each resident's residency association */
  padAccount?: Maybe<Scalars['String']['output']>;
  /** @deprecated These are now maintained in each resident's residency association */
  padAmount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated These are now maintained in each resident's residency association */
  padInstitution?: Maybe<Scalars['String']['output']>;
  /** @deprecated These are now maintained in each resident's residency association */
  padTransit?: Maybe<Scalars['String']['output']>;
  pets?: Maybe<Array<Pet>>;
  phone?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<Scalars['AWSPhone']['output']>>>;
  /** @deprecated These are now maintained in each resident's residency association */
  preferredPaymentMethod?: Maybe<PaymentMethod>;
  profile?: Maybe<Profile>;
  profileId?: Maybe<Scalars['ID']['output']>;
  propertyId?: Maybe<Scalars['String']['output']>;
  propertyOptions?: Maybe<Array<PropertyOption>>;
  rentalAssistance?: Maybe<RentalAssistance>;
  sendWelcomeEmail?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unitId?: Maybe<Scalars['String']['output']>;
  vehicles?: Maybe<Array<Vehicle>>;
};

export type TenantEnrollmentsArgs = {
  filter?: InputMaybe<AutopayEnrollmentFilterInput>;
};

export type TenantConnection = {
  __typename?: 'TenantConnection';
  items?: Maybe<Array<Maybe<Tenant>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type TenantInput = {
  correspondenceMethod?: InputMaybe<CorrespondenceMethod>;
  emails?: InputMaybe<Array<InputMaybe<Scalars['AWSEmail']['input']>>>;
  emergencyContacts?: InputMaybe<Array<InputMaybe<EmergencyContactInput>>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isRentalAssist?: InputMaybe<Scalars['Boolean']['input']>;
  leaseHolder?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  padAccount?: InputMaybe<Scalars['String']['input']>;
  padAmount?: InputMaybe<Scalars['Float']['input']>;
  padInstitution?: InputMaybe<Scalars['String']['input']>;
  padTransit?: InputMaybe<Scalars['String']['input']>;
  pets?: InputMaybe<Array<PetInput>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<InputMaybe<Scalars['AWSPhone']['input']>>>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  rentalAssistance?: InputMaybe<RentalAssistanceInput>;
  sendWelcomeEmail?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  vehicles?: InputMaybe<Array<VehicleInput>>;
};

export type TenantLease = {
  __typename?: 'TenantLease';
  isPayee?: Maybe<Scalars['Boolean']['output']>;
  leaseHolder?: Maybe<Scalars['Boolean']['output']>;
  leaseId: Scalars['ID']['output'];
  propertyId: Scalars['ID']['output'];
  status: LeaseStatus;
  unitId: Scalars['ID']['output'];
};

export type TenantPayload = {
  __typename?: 'TenantPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  tenant?: Maybe<Tenant>;
};

export type TerminateLeaseInput = {
  end?: InputMaybe<Scalars['AWSDate']['input']>;
  leaseId: Scalars['ID']['input'];
  moveOutReason?: InputMaybe<MoveOutReason>;
};

export type TerminateResidencyInput = {
  endZ: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  moveOutDate?: InputMaybe<Scalars['AWSDate']['input']>;
  moveOutReason?: InputMaybe<MoveOutReason>;
  propertyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type TestVoiceAnnouncementInput = {
  phone: Scalars['AWSPhone']['input'];
  voice: AnnouncementVoiceInput;
};

export type TotalByAccount = {
  __typename?: 'TotalByAccount';
  accountId: Scalars['ID']['output'];
  totalOutstanding: Scalars['Float']['output'];
};

export type TotalByProperty = {
  __typename?: 'TotalByProperty';
  propertyId: Scalars['ID']['output'];
  totalOutstanding: Scalars['Float']['output'];
};

export type TotalByUnit = {
  __typename?: 'TotalByUnit';
  totalOutstanding: Scalars['Float']['output'];
  unitId: Scalars['ID']['output'];
};

export type TransferJournalEntriesInput = {
  destination?: InputMaybe<AddJournalEntryInput>;
  selfTransfer: Scalars['Boolean']['input'];
  source: AddJournalEntryInput;
};

export type TransferJournalEntriesPayload = {
  __typename?: 'TransferJournalEntriesPayload';
  destinationJournalIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  error?: Maybe<Scalars['String']['output']>;
  sourceJournalIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Unit = {
  __typename?: 'Unit';
  additionalInformation?: Maybe<Scalars['String']['output']>;
  allResidencies?: Maybe<Array<Residency>>;
  bathrooms: Scalars['Float']['output'];
  bedrooms: Scalars['Float']['output'];
  building?: Maybe<Building>;
  buildingId?: Maybe<Scalars['ID']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  condition?: Maybe<UnitCondition>;
  condoFee?: Maybe<Scalars['Float']['output']>;
  condoFeeGst?: Maybe<Scalars['Float']['output']>;
  condoUnitFees?: Maybe<Array<Maybe<CondoUnitFee>>>;
  currentResidencies?: Maybe<Array<Residency>>;
  documents?: Maybe<Array<Maybe<Document>>>;
  fingerprints?: Maybe<Fingerprints>;
  floorplan?: Maybe<Floorplan>;
  floorplanId?: Maybe<Scalars['ID']['output']>;
  futureResidencies?: Maybe<Array<Residency>>;
  history?: Maybe<Array<Maybe<UnitHistory>>>;
  id: Scalars['ID']['output'];
  keyring?: Maybe<Array<Scalars['String']['output']>>;
  legalAddress?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Array<Maybe<Note>>>;
  occupancy?: Maybe<UnitOccupancy>;
  owner?: Maybe<Owner>;
  ownerId?: Maybe<Scalars['String']['output']>;
  parking?: Maybe<Array<Scalars['String']['output']>>;
  paymentDetails?: Maybe<PaymentDetails>;
  /** @deprecated Use `paymentDetails` instead */
  preferredPaymentMethod?: Maybe<PaymentMethod>;
  property?: Maybe<Property>;
  propertyId?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  requests?: Maybe<Array<Maybe<Request>>>;
  reserveAmount?: Maybe<Scalars['Float']['output']>;
  residency?: Maybe<Residency>;
  routineInspections?: Maybe<Array<Maybe<Inspection>>>;
  sqft: Scalars['Float']['output'];
  storage?: Maybe<Array<Scalars['String']['output']>>;
  test?: Maybe<Scalars['Boolean']['output']>;
  unitFactor?: Maybe<Scalars['Float']['output']>;
};

export type UnitResidencyArgs = {
  id: Scalars['ID']['input'];
};

export type UnitCondition = {
  __typename?: 'UnitCondition';
  category: InspectionCategory;
  id: Scalars['ID']['output'];
  items?: Maybe<Array<InspectionItem>>;
  templateId: Scalars['ID']['output'];
};

export type UnitConnection = {
  __typename?: 'UnitConnection';
  items?: Maybe<Array<Maybe<Unit>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type UnitEdge = RelayEdge & {
  __typename?: 'UnitEdge';
  cursor: Scalars['ID']['output'];
  node: Unit;
};

export type UnitFilter = {
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  unitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UnitHeader = {
  __typename?: 'UnitHeader';
  address?: Maybe<Address>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  occupancy?: Maybe<UnitOccupancy>;
  propertyId: Scalars['ID']['output'];
  propertyImageKey?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
};

export type UnitHistory = {
  fingerprints?: Maybe<Fingerprints>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UnitNames = {
  __typename?: 'UnitNames';
  complete?: Maybe<Scalars['Boolean']['output']>;
  names?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  namesFound?: Maybe<Scalars['Int']['output']>;
  totalUnits?: Maybe<Scalars['Int']['output']>;
};

export enum UnitOccupancy {
  Commercial = 'commercial',
  CommercialOwner = 'commercialOwner',
  Common = 'common',
  Owner = 'owner',
  Rental = 'rental',
}

export type UnitPayload = {
  __typename?: 'UnitPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  unit?: Maybe<Unit>;
};

export type UnitsFilterInput = {
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UnitsPage = RelayPage & {
  __typename?: 'UnitsPage';
  edges: Array<UnitEdge>;
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum UnitsSortField {
  Name = 'name',
  Occupancy = 'occupancy',
  PropertyName = 'propertyName',
  Test = 'test',
}

export type UnitsSortInput = {
  direction: SortDirection;
  field: UnitsSortField;
};

export type UnitsWithResidenciesFilter = {
  availableOnly?: InputMaybe<Scalars['Boolean']['input']>;
  expiringInDaysEnd?: InputMaybe<Scalars['Int']['input']>;
  expiringInDaysStart?: InputMaybe<Scalars['Int']['input']>;
  m2mOnly?: InputMaybe<Scalars['Boolean']['input']>;
  occupancy?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateAccountAutopayInput = {
  autopayAccount?: InputMaybe<Scalars['String']['input']>;
  autopayClientId?: InputMaybe<Scalars['ID']['input']>;
  autopayInstitution?: InputMaybe<Scalars['String']['input']>;
  autopayTransit?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateAccountBillingInfoInput = {
  billingEmail?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  tier?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateAccountInput = {
  address?: InputMaybe<AddressInput>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<SettingsInput>;
};

export type UpdateAnnouncementInput = {
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  email?: InputMaybe<AnnouncementEmailInput>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  publishZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  scope?: InputMaybe<AnnouncementScopeInput>;
  voice?: InputMaybe<AnnouncementVoiceInput>;
};

export type UpdateApplicationInput = {
  applicationId: Scalars['ID']['input'];
  campaignId?: InputMaybe<Scalars['ID']['input']>;
  flag?: InputMaybe<ApplicationFlag>;
  leaseId?: InputMaybe<Scalars['ID']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  needsAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<ApplicationStatus>;
};

export type UpdateAutopayCollectionTypesInput = {
  collectionTypes: Array<CollectionType>;
  enableCollection?: InputMaybe<Scalars['Boolean']['input']>;
  enablePayments?: InputMaybe<Scalars['Boolean']['input']>;
  invitePayees?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateAutopayEnrollmentInput = {
  enrollmentId: Scalars['ID']['input'];
};

export type UpdateAutopayFeeInvoiceInput = {
  feeBankAccountId?: InputMaybe<Scalars['String']['input']>;
  feeExpense: Scalars['String']['input'];
  feeInvoiceChargeMethod: FeeInvoiceChargeMethod;
};

export type UpdateAutopayNsfFeeInput = {
  nsfFee: Scalars['Float']['input'];
};

export type UpdateBuildingAmenitiesInput = {
  amenitiesSetting: AmenitiesSettingInput;
  buildingId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type UpdateBuildingFeaturesInput = {
  buildingId: Scalars['ID']['input'];
  features: Array<FeatureInput>;
  propertyId: Scalars['ID']['input'];
};

export type UpdateBuildingInput = {
  address?: InputMaybe<AddressInput>;
  id: Scalars['ID']['input'];
  imageKey?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  legalDescription?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['ID']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<InputMaybe<PropertyType>>>;
};

export type UpdateBuildingInsuranceClaimInput = {
  buildingId: Scalars['ID']['input'];
  claim: ClaimInput;
  claimId: Scalars['ID']['input'];
  insurancePolicyId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type UpdateBuildingInsuranceHistoryInput = {
  buildingId: Scalars['ID']['input'];
  insurancePolicy: InsurancePolicyInput;
  insurancePolicyId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type UpdateBuildingPayload = {
  __typename?: 'UpdateBuildingPayload';
  building?: Maybe<Building>;
  error?: Maybe<Scalars['String']['output']>;
  property?: Maybe<Property>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateBuildingSpecsInput = {
  buildingId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  specs: Array<InputMaybe<PropertySpecsInput>>;
};

export type UpdateBundledReportFlagInput = {
  flag: BundledReportFlag;
  id: Scalars['ID']['input'];
};

export type UpdateCampaignInput = {
  id: Scalars['ID']['input'];
  listedUnits?: InputMaybe<Array<ListedUnitInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['ID']['input'];
  status: CampaignStatus;
};

export type UpdateCampaignStatusInput = {
  id: Scalars['ID']['input'];
  status?: InputMaybe<CampaignStatus>;
};

export type UpdateChargeInput = {
  default: Scalars['Float']['input'];
  glId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  limit: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type UpdateClearableInput = {
  autopayCollectionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  due?: InputMaybe<Scalars['AWSDate']['input']>;
  enrollmentId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  lines: Array<JournalEntryLineInput>;
  payee?: InputMaybe<PayeeType>;
  payeeId?: InputMaybe<Scalars['ID']['input']>;
  posted?: InputMaybe<Scalars['AWSDate']['input']>;
  ref?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClearingEntryTaskInput = {
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateContactInput = {
  address?: InputMaybe<AddressInput>;
  emails?: InputMaybe<Array<InputMaybe<Scalars['AWSEmail']['input']>>>;
  entityId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<InputMaybe<Scalars['AWSPhone']['input']>>>;
  type?: InputMaybe<ContactType>;
};

export type UpdateFeeInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateFinancialSettingsInput = {
  billableRevenueFeeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  billingRates?: InputMaybe<BillingRateInput>;
  chargeMapping?: InputMaybe<Array<InputMaybe<ChargeMappingInput>>>;
  chequePlacement?: InputMaybe<ChequePlacement>;
  customBillingRates?: InputMaybe<Array<BillingRateInput>>;
  feeMapping?: InputMaybe<Array<InputMaybe<FeeMappingInput>>>;
  glMapping?: InputMaybe<Array<InputMaybe<GlMappingInput>>>;
  gstFiling?: InputMaybe<FilingFrequency>;
  gstNumber?: InputMaybe<Scalars['String']['input']>;
  includeDisabledProperties?: InputMaybe<Scalars['Boolean']['input']>;
  incomePayoutOptions?: InputMaybe<IncomePayoutOptionsInput>;
  managementFeeBasis?: InputMaybe<ManagementFeeBasis>;
  receiverGeneralId?: InputMaybe<Scalars['ID']['input']>;
  trackManagementFeeRevenue?: InputMaybe<Scalars['Boolean']['input']>;
  yearEndMonth?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateFutureLeaseInput = {
  autoRenewSetup?: InputMaybe<AutoRenewSetupInput>;
  end?: InputMaybe<Scalars['AWSDate']['input']>;
  gst?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  incentives?: InputMaybe<Array<InputMaybe<LeaseIncentiveInput>>>;
  insurancePolicy?: InputMaybe<LeaseInsurancePolicyInput>;
  moveInDate?: InputMaybe<Scalars['AWSDate']['input']>;
  moveOutDate?: InputMaybe<Scalars['AWSDate']['input']>;
  moveOutReason?: InputMaybe<MoveOutReason>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  rateSchedule?: InputMaybe<Array<InputMaybe<RateScheduleInput>>>;
  rent: Scalars['Float']['input'];
  securityDeposit?: InputMaybe<Scalars['Float']['input']>;
  start: Scalars['AWSDate']['input'];
  unitId: Scalars['ID']['input'];
  unitOwnerId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateGlAccountInput = {
  accountType?: InputMaybe<AccountType>;
  category?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGlAccountPayload = {
  __typename?: 'UpdateGLAccountPayload';
  error?: Maybe<Scalars['String']['output']>;
  glAccount?: Maybe<GlAccount>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateInterestRateInput = {
  id: Scalars['ID']['input'];
  province: Scalars['String']['input'];
  rate: Scalars['Float']['input'];
  startDate: Scalars['AWSDate']['input'];
};

export type UpdateJournalDraftsInput = {
  checked: Scalars['Boolean']['input'];
  draftReconciliationId: Scalars['ID']['input'];
  draftedJournalEntries?: InputMaybe<Array<DraftedJournalEntryInput>>;
};

export type UpdateJournalEntryInput = {
  clearableId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  lines: Array<JournalEntryLineInput>;
  posted: Scalars['AWSDate']['input'];
};

export type UpdateJournalEntryNoteInput = {
  journalEntryId: Scalars['ID']['input'];
  noteId: Scalars['ID']['input'];
  posted: Scalars['String']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateJournalPayload = {
  __typename?: 'UpdateJournalPayload';
  error?: Maybe<Scalars['String']['output']>;
  journalEntry?: Maybe<JournalEntry>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateKycInput = {
  bankInfoMatched?: InputMaybe<Scalars['Boolean']['input']>;
  businessNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['AWSDate']['input']>;
  customerType?: InputMaybe<CustomerType>;
  noOfEmployees?: InputMaybe<Scalars['Int']['input']>;
  riskProfile?: InputMaybe<RiskProfile>;
  signupMethod?: InputMaybe<KycSignupMethod>;
  trainingForStaff?: InputMaybe<Scalars['Boolean']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLeaseDocumentTemplateInput = {
  id: Scalars['ID']['input'];
  leaseType: LeaseDocumentLeaseType;
  name: Scalars['String']['input'];
  propertyIds: Array<Scalars['ID']['input']>;
  schemas?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type UpdateLeaseInput = {
  additionalProvisions?: InputMaybe<AdditionalProvisionsInput>;
  autoRenewSetup?: InputMaybe<AutoRenewSetupInput>;
  end?: InputMaybe<Scalars['String']['input']>;
  gst?: InputMaybe<Scalars['Float']['input']>;
  incentives?: InputMaybe<Array<InputMaybe<LeaseIncentiveInput>>>;
  insurancePolicy?: InputMaybe<LeaseInsurancePolicyInput>;
  moveInDate?: InputMaybe<Scalars['AWSDate']['input']>;
  moveOutDate?: InputMaybe<Scalars['AWSDate']['input']>;
  moveOutReason?: InputMaybe<MoveOutReason>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  rent: Scalars['Float']['input'];
  rentRollFees?: InputMaybe<Array<InputMaybe<RentRollFeesInput>>>;
  securityDeposit?: InputMaybe<Scalars['Float']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
  tenants?: InputMaybe<Array<LeaseTenantInput>>;
  type?: InputMaybe<LeaseType>;
  utilities?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateLeaseInspectionInput = {
  inspection: LeaseInspectionInput;
  inspectionId: Scalars['ID']['input'];
  residencyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type UpdateLeaseTenantInput = {
  leaseId: Scalars['ID']['input'];
  tenant: LeaseTenantInput;
  tenantId: Scalars['ID']['input'];
};

export type UpdateManagerInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['AWSPhone']['input']>;
  signature?: InputMaybe<SignatureInput>;
};

export type UpdateManagerLocaleInput = {
  locale: Scalars['String']['input'];
};

export type UpdateOperatorAutoApInput = {
  autopayAccount?: InputMaybe<Scalars['String']['input']>;
  autopayInstitution?: InputMaybe<Scalars['String']['input']>;
  autopayTransit?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  expenseGlId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateOperatorInput = {
  address?: InputMaybe<AddressInput>;
  availability?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  exceptions?: InputMaybe<Array<InputMaybe<ExceptionInput>>>;
  expenseGlId?: InputMaybe<Scalars['String']['input']>;
  expiryCOI?: InputMaybe<Scalars['String']['input']>;
  hasCOI?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  operatorType?: InputMaybe<OperatorType>;
  phone?: InputMaybe<Scalars['String']['input']>;
  skills?: InputMaybe<Array<OperatorSkill>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOwnerAutopayInput = {
  autopayAccount?: InputMaybe<Scalars['String']['input']>;
  autopayInstitution?: InputMaybe<Scalars['String']['input']>;
  autopayTransit?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateOwnerFinancialsInput = {
  feeAmount?: InputMaybe<Scalars['Float']['input']>;
  feeRate?: InputMaybe<Scalars['Float']['input']>;
  flatFee?: InputMaybe<Scalars['Float']['input']>;
  gstFiling?: InputMaybe<FilingFrequency>;
  gstNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  nrtFeeAmount?: InputMaybe<Scalars['Float']['input']>;
  nrtFeeRate?: InputMaybe<Scalars['Float']['input']>;
  padAccount?: InputMaybe<Scalars['String']['input']>;
  padAmount?: InputMaybe<Scalars['Float']['input']>;
  padInstitution?: InputMaybe<Scalars['String']['input']>;
  padTransit?: InputMaybe<Scalars['String']['input']>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  trackCondoFees?: InputMaybe<Scalars['Boolean']['input']>;
  yearEndMonth?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateOwnerInput = {
  address?: InputMaybe<AddressInput>;
  booksSinceZ?: InputMaybe<Scalars['String']['input']>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  correspondenceMethod?: InputMaybe<CorrespondenceMethod>;
  dob?: InputMaybe<Scalars['AWSDate']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  emergencyContacts?: InputMaybe<Array<InputMaybe<EmergencyContactInput>>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  mailingAddress?: InputMaybe<AddressInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  pets?: InputMaybe<Array<PetInput>>;
  phones?: InputMaybe<Array<InputMaybe<Scalars['AWSPhone']['input']>>>;
  vehicles?: InputMaybe<Array<VehicleInput>>;
};

export type UpdateOwnerManagementFeeInput = {
  assetId: Scalars['ID']['input'];
  feeAmount?: InputMaybe<Scalars['Float']['input']>;
  feeRate?: InputMaybe<Scalars['Float']['input']>;
  flatFee?: InputMaybe<Scalars['Float']['input']>;
  nrtFeeAmount?: InputMaybe<Scalars['Float']['input']>;
  nrtFeeRate?: InputMaybe<Scalars['Float']['input']>;
  ownerId: Scalars['ID']['input'];
  type: AssetType;
};

export type UpdatePaymentsBatchInput = {
  approvalId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type UpdatePresetGlMappingInput = {
  glMapping?: InputMaybe<Array<InputMaybe<GlMappingInput>>>;
  id: Scalars['ID']['input'];
};

export type UpdatePresetRecurrenceInput = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  parameters?: InputMaybe<Scalars['AWSJSON']['input']>;
  postedOffset: Scalars['Int']['input'];
  rrule: Scalars['String']['input'];
};

export type UpdatePropertyAmenitiesInput = {
  amenitiesSetting: AmenitiesSettingInput;
  propertyId: Scalars['ID']['input'];
};

export type UpdatePropertyContractorsInput = {
  contractorIds: Array<Scalars['ID']['input']>;
  propertyId: Scalars['ID']['input'];
};

export type UpdatePropertyFeaturesInput = {
  features: Array<FeatureInput>;
  propertyId: Scalars['ID']['input'];
};

export type UpdatePropertyInput = {
  address?: InputMaybe<AddressInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  legalDescription?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onsiteManager?: InputMaybe<OnsiteManagerInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reserveAmount?: InputMaybe<Scalars['Float']['input']>;
  supplierIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<InputMaybe<PropertyType>>>;
};

export type UpdatePropertyInsuranceClaimInput = {
  claim: ClaimInput;
  claimId: Scalars['ID']['input'];
  insurancePolicyId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type UpdatePropertyInsuranceHistoryInput = {
  insurancePolicy: InsurancePolicyInput;
  insurancePolicyId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type UpdatePropertySpecsInput = {
  propertyId: Scalars['ID']['input'];
  specs: Array<InputMaybe<PropertySpecsInput>>;
};

export type UpdatePropertySuppliersInput = {
  propertyId: Scalars['ID']['input'];
  supplierIds: Array<Scalars['ID']['input']>;
};

export type UpdateRateScheduleInput = {
  leaseId: Scalars['ID']['input'];
  newRateSchedule?: InputMaybe<Array<InputMaybe<RateScheduleInput>>>;
  unitId: Scalars['ID']['input'];
};

export type UpdateReconciliationConfigInput = {
  bankId?: InputMaybe<Scalars['ID']['input']>;
  glId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  propertyOwnerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UpdateReconciliationInput = {
  documentKey?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  ledgerDocumentKey?: InputMaybe<Scalars['String']['input']>;
  statementBalance?: InputMaybe<Scalars['Float']['input']>;
  statementDate?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type UpdateReconciliationJournalsInput = {
  journals: Array<ReconciliationJournalEntryInput>;
};

export type UpdateReportBundleInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  presetIds: Array<Scalars['ID']['input']>;
};

export type UpdateRequestChecklistInput = {
  checklist?: InputMaybe<Array<InputMaybe<ChecklistItemInput>>>;
  requestId: Scalars['ID']['input'];
};

export type UpdateRequestChecklistItemInput = {
  completedZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  itemId: Scalars['ID']['input'];
  order: Scalars['Int']['input'];
  requestId: Scalars['ID']['input'];
};

export type UpdateRequestCostInput = {
  amount: Scalars['Float']['input'];
  costId: Scalars['ID']['input'];
  date: Scalars['AWSDate']['input'];
  description: Scalars['String']['input'];
  markup: Scalars['Boolean']['input'];
  requestId: Scalars['ID']['input'];
};

export type UpdateRequestInput = {
  appointmentZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  nonBillable?: InputMaybe<Scalars['Boolean']['input']>;
  operatorId?: InputMaybe<Scalars['ID']['input']>;
  operatorName?: InputMaybe<Scalars['String']['input']>;
  originalRequest?: InputMaybe<Scalars['String']['input']>;
  printNotes?: InputMaybe<Scalars['Boolean']['input']>;
  resetDelay?: InputMaybe<Scalars['Boolean']['input']>;
  resolution?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  taxExempted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateRequestRecurrenceInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  checklist?: InputMaybe<Array<InputMaybe<ChecklistItemInput>>>;
  details?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nonBillable?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  originalRequest?: InputMaybe<Scalars['String']['input']>;
  printNotes?: InputMaybe<Scalars['Boolean']['input']>;
  propertyId: Scalars['String']['input'];
  rrule: Scalars['String']['input'];
  subcategory?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateResidencyEffectsInput = {
  feeEffects?: InputMaybe<Array<ResidencyEffectInput>>;
  incentiveEffects?: InputMaybe<Array<ResidencyEffectInput>>;
  rentEffects?: InputMaybe<Array<ResidencyEffectInput>>;
};

export type UpdateResidencyEventInput = {
  clearNoticeZ?: InputMaybe<Scalars['Boolean']['input']>;
  clearResolvedZ?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  noteText?: InputMaybe<Scalars['String']['input']>;
  noticeZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  residencyId: Scalars['ID']['input'];
  resolvedZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type UpdateResidencyInput = {
  additionalClause?: InputMaybe<Array<Scalars['String']['input']>>;
  additionalProvisions?: InputMaybe<AdditionalProvisionsInput>;
  applicationId?: InputMaybe<Scalars['String']['input']>;
  campaignId?: InputMaybe<Scalars['String']['input']>;
  collectionDay?: InputMaybe<Scalars['Int']['input']>;
  effects?: InputMaybe<Array<ResidencyEffectInput>>;
  endZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  furnishings?: InputMaybe<Array<Scalars['String']['input']>>;
  gst?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  leaseInput?: InputMaybe<UpdateResidencyLeaseInput>;
  moveInDate?: InputMaybe<Scalars['AWSDate']['input']>;
  moveOutDate?: InputMaybe<Scalars['AWSDate']['input']>;
  moveOutReason?: InputMaybe<MoveOutReason>;
  previousLeaseEnd?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['ID']['input'];
  proratedClearableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  refundablesInput?: InputMaybe<Array<RefundableInput>>;
  renewZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  residents?: InputMaybe<Array<ResidentAssociationInput>>;
  rrule?: InputMaybe<Scalars['String']['input']>;
  securityDeposit?: InputMaybe<Scalars['Float']['input']>;
  startZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  terms?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<LeaseType>;
  unitId: Scalars['ID']['input'];
  unitOwnerId?: InputMaybe<Scalars['ID']['input']>;
  utilities?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateResidencyLeaseInput = {
  additionalProvisions?: InputMaybe<AdditionalProvisionsInput>;
  autoRenewSetup?: InputMaybe<AutoRenewSetupInput>;
  end?: InputMaybe<Scalars['String']['input']>;
  gst?: InputMaybe<Scalars['Float']['input']>;
  incentives?: InputMaybe<Array<InputMaybe<LeaseIncentiveInput>>>;
  moveInDate?: InputMaybe<Scalars['AWSDate']['input']>;
  moveOutDate?: InputMaybe<Scalars['AWSDate']['input']>;
  moveOutReason?: InputMaybe<MoveOutReason>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  rent?: InputMaybe<Scalars['Float']['input']>;
  rentRollFees?: InputMaybe<Array<InputMaybe<RentRollFeesInput>>>;
  securityDeposit?: InputMaybe<Scalars['Float']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
  tenants?: InputMaybe<Array<LeaseTenantInput>>;
  type?: InputMaybe<LeaseType>;
  utilities?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateResidencyRefundableDepositsInput = {
  noteText?: InputMaybe<Scalars['String']['input']>;
  refundablesInput?: InputMaybe<Array<RefundableInput>>;
  residencyId: Scalars['ID']['input'];
};

export type UpdateResidencyResidentFinancialsInput = {
  padAccount?: InputMaybe<Scalars['String']['input']>;
  padAmount?: InputMaybe<Scalars['Float']['input']>;
  padInstitution?: InputMaybe<Scalars['String']['input']>;
  padTransit?: InputMaybe<Scalars['String']['input']>;
  paymentDetails: PaymentDetailsInput;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  residencyResidentId: Scalars['ID']['input'];
};

export type UpdateRoutineInspectionInput = {
  inspection: InspectionInput;
  inspectionId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type UpdateScanApprovalPayload = {
  __typename?: 'UpdateScanApprovalPayload';
  approval?: Maybe<ScanApproval>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateScanApprovalRequestInput = {
  amount: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  due?: InputMaybe<Scalars['AWSDate']['input']>;
  expenseGlId?: InputMaybe<Scalars['ID']['input']>;
  gstOrHstAllocation?: InputMaybe<Scalars['Float']['input']>;
  includesGstOrHst?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  lines: Array<ScanApprovalLineInput>;
  ownerId: Scalars['ID']['input'];
  payee: Scalars['String']['input'];
  payeeId: Scalars['ID']['input'];
  payeeName: Scalars['String']['input'];
  posted: Scalars['AWSDate']['input'];
  propertyId: Scalars['ID']['input'];
  ref?: InputMaybe<Scalars['String']['input']>;
  scanType?: InputMaybe<ScanType>;
  supplierAccount?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateSupplierAutoApInput = {
  autopayAccount?: InputMaybe<Scalars['String']['input']>;
  autopayInstitution?: InputMaybe<Scalars['String']['input']>;
  autopayTransit?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  expenseGlId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateTeamInput = {
  id: Scalars['ID']['input'];
  managerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
  occupancies: Array<Scalars['String']['input']>;
  propertyIds: Array<Scalars['ID']['input']>;
  propertyUnitIds?: InputMaybe<Array<PropertyUnitInput>>;
};

export type UpdateTemplateInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fields?: InputMaybe<Array<InputMaybe<FieldInput>>>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
  response?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTenantAutopayInput = {
  autopayAccount?: InputMaybe<Scalars['String']['input']>;
  autopayInstitution?: InputMaybe<Scalars['String']['input']>;
  autopayTransit?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateTenantFinancialsInput = {
  id: Scalars['ID']['input'];
  padAccount?: InputMaybe<Scalars['String']['input']>;
  padAmount?: InputMaybe<Scalars['Float']['input']>;
  padInstitution?: InputMaybe<Scalars['String']['input']>;
  padTransit?: InputMaybe<Scalars['String']['input']>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
};

export type UpdateTenantInput = {
  correspondenceMethod?: InputMaybe<CorrespondenceMethod>;
  dob?: InputMaybe<Scalars['AWSDate']['input']>;
  emails?: InputMaybe<Array<InputMaybe<Scalars['AWSEmail']['input']>>>;
  emergencyContacts?: InputMaybe<Array<InputMaybe<EmergencyContactInput>>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  leaseHolder?: InputMaybe<Scalars['Boolean']['input']>;
  mailingAddress?: InputMaybe<AddressInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  padAccount?: InputMaybe<Scalars['String']['input']>;
  padAmount?: InputMaybe<Scalars['Float']['input']>;
  padInstitution?: InputMaybe<Scalars['String']['input']>;
  padTransit?: InputMaybe<Scalars['String']['input']>;
  pets?: InputMaybe<Array<PetInput>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<InputMaybe<Scalars['AWSPhone']['input']>>>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  rentalAssistance?: InputMaybe<RentalAssistanceInput>;
  type?: InputMaybe<Scalars['String']['input']>;
  vehicles?: InputMaybe<Array<VehicleInput>>;
};

export type UpdateUnitFloorplanInput = {
  floorplanId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type UpdateUnitInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  bathrooms?: InputMaybe<Scalars['Float']['input']>;
  bedrooms?: InputMaybe<Scalars['Float']['input']>;
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  condoFee?: InputMaybe<Scalars['Float']['input']>;
  condoFeeGst?: InputMaybe<Scalars['Float']['input']>;
  condoUnitFees?: InputMaybe<Array<InputMaybe<CondoUnitFeesInput>>>;
  id: Scalars['ID']['input'];
  keyring?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  legalAddress?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  occupancy?: InputMaybe<UnitOccupancy>;
  parking?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paymentDetails?: InputMaybe<PaymentDetailsInput>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  reserveAmount?: InputMaybe<Scalars['Float']['input']>;
  sqft?: InputMaybe<Scalars['Float']['input']>;
  storage?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unitFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateUnitTestInput = {
  id: Scalars['ID']['input'];
  test: Scalars['Boolean']['input'];
};

export type UpdateUnreadPushNotificationsInput = {
  unreadPushNotifications: Scalars['Int']['input'];
};

export type UpdateVisitBillMarkupInput = {
  markup?: InputMaybe<Scalars['Float']['input']>;
  requestId: Scalars['ID']['input'];
  visitId: Scalars['ID']['input'];
};

export type UpdateVisitInput = {
  appointmentZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  dueZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id: Scalars['ID']['input'];
  instructions?: InputMaybe<Scalars['String']['input']>;
  labourCost?: InputMaybe<Scalars['Float']['input']>;
  managerContactInformation?: InputMaybe<ManagerContactInformationInput>;
  materialCosts?: InputMaybe<Scalars['Float']['input']>;
  materialCostsNote?: InputMaybe<Scalars['String']['input']>;
  materialMarkup?: InputMaybe<Scalars['Boolean']['input']>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
  operatorId?: InputMaybe<Scalars['ID']['input']>;
  operatorName?: InputMaybe<Scalars['String']['input']>;
  requestId: Scalars['ID']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  tenantProposedTimes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVisitPayload = {
  __typename?: 'UpdateVisitPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  visit?: Maybe<Visit>;
};

export type UpsertLeaseInspectionInput = {
  inspection: LeaseInspectionInput;
  residencyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type UpsertRoutineInspectionInput = {
  inspection: InspectionInput;
  unitId: Scalars['ID']['input'];
};

export type UpsertRoutineInspectionPayload = {
  __typename?: 'UpsertRoutineInspectionPayload';
  error?: Maybe<Scalars['String']['output']>;
  inspection?: Maybe<Inspection>;
  success?: Maybe<Scalars['Boolean']['output']>;
  unit?: Maybe<Unit>;
};

export type UtilitiesTemplate = {
  __typename?: 'UtilitiesTemplate';
  id: Scalars['ID']['output'];
  items: Array<TemplateItem>;
  name: Scalars['String']['output'];
  propertyId?: Maybe<Scalars['ID']['output']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  color?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  plate: Scalars['String']['output'];
};

export type VehicleInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
  plate: Scalars['String']['input'];
};

export type Visit = {
  __typename?: 'Visit';
  appointmentZ?: Maybe<Scalars['AWSDateTime']['output']>;
  bill?: Maybe<ContractorBill>;
  category?: Maybe<Scalars['String']['output']>;
  completedDelta?: Maybe<Scalars['Float']['output']>;
  completedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  delay?: Maybe<Scalars['String']['output']>;
  dueZ?: Maybe<Scalars['AWSDateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  instructions?: Maybe<Scalars['String']['output']>;
  journalId?: Maybe<Scalars['String']['output']>;
  labourCost?: Maybe<Scalars['Float']['output']>;
  managerContactInformation?: Maybe<ManagerContactInformation>;
  materialCosts?: Maybe<Scalars['Float']['output']>;
  materialCostsNote?: Maybe<Scalars['String']['output']>;
  materialMarkup?: Maybe<Scalars['Boolean']['output']>;
  minutes?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  operatorId?: Maybe<Scalars['ID']['output']>;
  operatorName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantProposedTimes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type VoidJeInput = {
  id: Scalars['String']['input'];
};

export type VoidJournalEntryInput = {
  jeId?: InputMaybe<Scalars['String']['input']>;
};

export type Vopay = {
  __typename?: 'Vopay';
  accountId?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  secretCipher?: Maybe<Scalars['String']['output']>;
};

export type WelcomeTenantInput = {
  id: Scalars['ID']['input'];
};

export type WorkHistory = {
  __typename?: 'WorkHistory';
  amount?: Maybe<Scalars['Float']['output']>;
  endDate?: Maybe<Scalars['AWSDate']['output']>;
  frequency?: Maybe<SalaryFrequency>;
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['AWSDate']['output'];
};

export type ListPayeeClearablesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  payeeId: Scalars['ID']['input'];
}>;

export type ListPayeeClearablesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      payeeClearables?: {
        __typename?: 'ClearableConnection';
        nextToken?: string;
        items?: Array<{
          __typename?: 'Clearable';
          id: string;
          due: string;
          posted: string;
          glId: string;
          ownerId: string;
          sourceJournalId: string;
          payeeId: string;
          payeeName?: string;
          unitId?: string;
          propertyId: string;
          payee: PayeeType;
          amount: number;
          outstanding: number;
          cleared?: string;
          ref?: string;
          balanceType: BalanceType;
          batchId?: string;
          refund?: boolean;
          enrollmentId?: string;
          fixedEnrollmentId?: string;
          sourceJournalEntry: {
            __typename?: 'JournalEntry';
            id: string;
            ownerId: string;
            glId: string;
            jeId: string;
            unitId?: string;
            amount: number;
            description?: string;
            posted: string;
            propertyId: string;
            paymentMethod?: PaymentMethod;
            ref?: string;
            gstInfo?: GstInfo;
            printedZ?: string;
            voidedZ?: string;
            requestId?: string;
            payee?: PayeeType;
            payeeId?: string;
            payeeName?: string;
            reconciliationId?: string;
          };
        }>;
      };
    };
  };
};

export type ChargeApprovalFieldsFragment = {
  __typename?: 'Approval';
  id: string;
  accountId: string;
  approverRoles?: Array<ApproverRole>;
  status: ApprovalStatus;
  request: {
    __typename?: 'ChargeApprovalRequest';
    date: string;
    amount: number;
    chargeId: string;
    payeeId: string;
    payee: PayeeType;
    ownerId: string;
    propertyId: string;
    unitId?: string;
    applyGst?: boolean;
    applyPst?: boolean;
    note?: string;
  };
};

export type CreateChargeApprovalMutationVariables = Exact<{
  input: CreateChargeApprovalInput;
}>;

export type CreateChargeApprovalMutation = {
  __typename?: 'Mutation';
  createChargeApproval?: {
    __typename?: 'ApprovalPayload';
    success?: boolean;
    error?: string;
    approval?: {
      __typename?: 'Approval';
      id: string;
      accountId: string;
      approverRoles?: Array<ApproverRole>;
      status: ApprovalStatus;
      request: {
        __typename?: 'ChargeApprovalRequest';
        date: string;
        amount: number;
        chargeId: string;
        payeeId: string;
        payee: PayeeType;
        ownerId: string;
        propertyId: string;
        unitId?: string;
        applyGst?: boolean;
        applyPst?: boolean;
        note?: string;
      };
    };
  };
};

export type GetAccountChargesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountChargesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    name: string;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
    charges?: Array<{
      __typename?: 'Charge';
      id: string;
      default?: number;
      limit?: number;
      name: string;
    }>;
  };
};

export type PayeePropertiesQueryVariables = Exact<{
  payeeId: Scalars['ID']['input'];
}>;

export type PayeePropertiesQuery = {
  __typename?: 'Query';
  tenant?: {
    __typename?: 'Tenant';
    id: string;
    name: string;
    propertyOptions?: Array<{
      __typename?: 'PropertyOption';
      entityName: string;
      ownerName: string;
      occupancy?: string;
      unitId?: string;
      propertyId: string;
      ownerId: string;
      propertyAddress?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    }>;
  };
  owner?: {
    __typename?: 'Owner';
    id: string;
    name: string;
    propertyOptions?: Array<{
      __typename?: 'PropertyOption';
      entityName: string;
      ownerName: string;
      occupancy?: string;
      unitId?: string;
      propertyId: string;
      ownerId: string;
      propertyAddress?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    }>;
  };
};

export type ListUnitClearablesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['ID']['input'];
}>;

export type ListUnitClearablesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      unitClearables?: {
        __typename?: 'ClearableConnection';
        nextToken?: string;
        items?: Array<{
          __typename?: 'Clearable';
          id: string;
          due: string;
          posted: string;
          glId: string;
          ownerId: string;
          sourceJournalId: string;
          payeeId: string;
          payeeName?: string;
          unitId?: string;
          propertyId: string;
          payee: PayeeType;
          amount: number;
          outstanding: number;
          cleared?: string;
          ref?: string;
          balanceType: BalanceType;
          batchId?: string;
          refund?: boolean;
          autopaySinglePosted?: string;
          payeeAutopayCollectionsEnabled?: boolean;
          autopayCollectionsEnabled?: boolean;
          payeeAutopayStatus?: string;
          sourceJournalEntry: {
            __typename?: 'JournalEntry';
            id: string;
            ownerId: string;
            glId: string;
            jeId: string;
            unitId?: string;
            amount: number;
            description?: string;
            posted: string;
            propertyId: string;
            paymentMethod?: PaymentMethod;
            ref?: string;
            gstInfo?: GstInfo;
            printedZ?: string;
            voidedZ?: string;
            requestId?: string;
            payee?: PayeeType;
            payeeId?: string;
            payeeName?: string;
            reconciliationId?: string;
          };
        }>;
      };
    };
  };
};

export type TeamPickerQueryVariables = Exact<{ [key: string]: never }>;

export type TeamPickerQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    teams?: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      propertyIds: Array<string>;
      managerIds?: Array<string>;
      occupancies: Array<string>;
      propertyUnitIds?: Array<{ __typename?: 'PropertyUnit'; propertyId: string; unitId: string }>;
    }>;
  };
};

export type GetAccountSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountSettingsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    settings?: {
      __typename?: 'Settings';
      soon_days_threshold?: number;
      later_days_threshold?: number;
    };
  };
};

export type CreateRequestNoteMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateRequestNoteMutation = {
  __typename?: 'Mutation';
  createRequestNote?: {
    __typename?: 'CreateRequestNotePayload';
    request?: {
      __typename?: 'Request';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type DeleteRequestNoteMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
  noteId: Scalars['ID']['input'];
}>;

export type DeleteRequestNoteMutation = {
  __typename?: 'Mutation';
  deleteRequestNote?: {
    __typename?: 'DeleteRequestNotePayload';
    request?: {
      __typename?: 'Request';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type DeleteImageNoteMutationVariables = Exact<{
  input: DeleteImageNoteInput;
}>;

export type DeleteImageNoteMutation = {
  __typename?: 'Mutation';
  deleteImageNote?: {
    __typename?: 'Request';
    id: string;
    images?: Array<{
      __typename?: 'Image';
      key?: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    }>;
  };
};

export type CreateImageNoteMutationVariables = Exact<{
  input: CreateImageNoteInput;
}>;

export type CreateImageNoteMutation = {
  __typename?: 'Mutation';
  createImageNote?: {
    __typename?: 'Request';
    id: string;
    images?: Array<{
      __typename?: 'Image';
      key?: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    }>;
  };
};

export type CreateUnitNoteMutationVariables = Exact<{
  unitId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateUnitNoteMutation = {
  __typename?: 'Mutation';
  createUnitNote?: {
    __typename?: 'UnitPayload';
    unit?: {
      __typename?: 'Unit';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type DeleteUnitNoteMutationVariables = Exact<{
  unitId: Scalars['ID']['input'];
  noteId: Scalars['ID']['input'];
}>;

export type DeleteUnitNoteMutation = {
  __typename?: 'Mutation';
  deleteUnitNote?: {
    __typename?: 'UnitPayload';
    unit?: {
      __typename?: 'Unit';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type CreateJournalEntryNoteMutationVariables = Exact<{
  input: CreateJournalEntryNoteInput;
}>;

export type CreateJournalEntryNoteMutation = {
  __typename?: 'Mutation';
  createJournalEntryNote?: {
    __typename?: 'JournalEntry';
    id: string;
    ownerId: string;
    glId: string;
    jeId: string;
    unitId?: string;
    amount: number;
    description?: string;
    posted: string;
    propertyId: string;
    paymentMethod?: PaymentMethod;
    ref?: string;
    gstInfo?: GstInfo;
    printedZ?: string;
    voidedZ?: string;
    requestId?: string;
    payee?: PayeeType;
    payeeId?: string;
    payeeName?: string;
    clearableId?: string;
    clearableLinked?: boolean;
    reconciliationId?: string;
    approverName?: string;
    transactionReferenceId?: string;
    clearable?: {
      __typename?: 'Clearable';
      id: string;
      payee: PayeeType;
      payeeId: string;
      payeeName?: string;
      balanceType: BalanceType;
      description?: string;
      sourceJournalId: string;
      sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
    };
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    lines: Array<{
      __typename?: 'JournalEntryLine';
      id?: string;
      glId: string;
      ownerId: string;
      propertyId: string;
      unitId?: string;
      amount: number;
      description?: string;
      clearableId?: string;
    }>;
  };
};

export type DeleteJournalEntryNoteMutationVariables = Exact<{
  input: UpdateJournalEntryNoteInput;
}>;

export type DeleteJournalEntryNoteMutation = {
  __typename?: 'Mutation';
  deleteJournalEntryNote?: {
    __typename?: 'JournalEntry';
    id: string;
    ownerId: string;
    glId: string;
    jeId: string;
    unitId?: string;
    amount: number;
    description?: string;
    posted: string;
    propertyId: string;
    paymentMethod?: PaymentMethod;
    ref?: string;
    gstInfo?: GstInfo;
    printedZ?: string;
    voidedZ?: string;
    requestId?: string;
    payee?: PayeeType;
    payeeId?: string;
    payeeName?: string;
    clearableId?: string;
    clearableLinked?: boolean;
    reconciliationId?: string;
    approverName?: string;
    transactionReferenceId?: string;
    clearable?: {
      __typename?: 'Clearable';
      id: string;
      payee: PayeeType;
      payeeId: string;
      payeeName?: string;
      balanceType: BalanceType;
      description?: string;
      sourceJournalId: string;
      sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
    };
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    lines: Array<{
      __typename?: 'JournalEntryLine';
      id?: string;
      glId: string;
      ownerId: string;
      propertyId: string;
      unitId?: string;
      amount: number;
      description?: string;
      clearableId?: string;
    }>;
  };
};

export type CreateResidencyNoteMutationVariables = Exact<{
  input: CreateResidencyNoteInput;
}>;

export type CreateResidencyNoteMutation = {
  __typename?: 'Mutation';
  createResidencyNote: {
    __typename?: 'ResidencyPayload';
    success?: boolean;
    residency?: {
      __typename?: 'Residency';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type DeleteResidencyNoteMutationVariables = Exact<{
  input: DeleteResidencyNoteInput;
}>;

export type DeleteResidencyNoteMutation = {
  __typename?: 'Mutation';
  deleteResidencyNote: {
    __typename?: 'ResidencyPayload';
    success?: boolean;
    residency?: {
      __typename?: 'Residency';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type CreateApplicationNoteMutationVariables = Exact<{
  input: CreateApplicationNoteInput;
}>;

export type CreateApplicationNoteMutation = {
  __typename?: 'Mutation';
  createApplicationNote?: {
    __typename?: 'CreateApplicationNotePayload';
    application?: {
      __typename?: 'Application';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type DeleteApplicationNoteMutationVariables = Exact<{
  input: DeleteApplicationNoteInput;
}>;

export type DeleteApplicationNoteMutation = {
  __typename?: 'Mutation';
  deleteApplicationNote?: {
    __typename?: 'DeleteApplicationNotePayload';
    application?: {
      __typename?: 'Application';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type ResidencyExpiringThresholdsQueryVariables = Exact<{ [key: string]: never }>;

export type ResidencyExpiringThresholdsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    settings?: {
      __typename?: 'Settings';
      soon_days_threshold?: number;
      later_days_threshold?: number;
    };
  };
};

export type AccountFieldsFragment = {
  __typename?: 'Account';
  id: string;
  phone?: string;
  name: string;
  logoKey?: string;
  tier: number;
  autopayClientId?: string;
  autopayInstitution?: string;
  autopayTransit?: string;
  autopayAccount?: string;
  settings?: {
    __typename?: 'Settings';
    soon_days_threshold?: number;
    later_days_threshold?: number;
    resident_request_scheduling?: ResidentRequestScheduling;
    campaignApplicationRules?: {
      __typename?: 'CampaignApplicationRule';
      requiredDocs?: Array<{
        __typename?: 'CampaignApplicationDocRule';
        quantity: number;
        docTypes: Array<string>;
      }>;
    };
  };
  address?: {
    __typename?: 'Address';
    suite?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    country?: string;
    lat?: number;
    lng?: number;
  };
  fees?: Array<{ __typename?: 'Fee'; id: string; name: string; deletedZ?: string }>;
  books?: {
    __typename?: 'Books';
    accountId: string;
    trackManagementFeeRevenue?: boolean;
    billingRates?: { __typename?: 'BillingRate'; materialsMarkup?: number };
  };
};

export type AddressFieldsFragment = {
  __typename?: 'Address';
  suite?: string;
  street?: string;
  city?: string;
  province?: string;
  postal?: string;
  country?: string;
  lat?: number;
  lng?: number;
};

export type AddressHistoryFieldsFragment = {
  __typename?: 'AddressHistory';
  id: string;
  name: string;
  phone?: string;
  email?: string;
  startDate: string;
  endDate?: string;
  rentalType?: RentalType;
  address: {
    __typename?: 'Address';
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
  };
};

export type AdditionalTenantFieldsFragment = {
  __typename?: 'AdditionalTenant';
  id: string;
  name?: string;
  email?: string;
  phone?: string;
  leaseholder?: boolean;
  payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
};

export type PetFieldsFragment = {
  __typename?: 'Pet';
  type: string;
  count: number;
  details?: Array<{
    __typename?: 'PetDetails';
    type?: string;
    breed?: string;
    weight?: string;
    name?: string;
    color?: string;
    license?: string;
    isService?: boolean;
  }>;
};

export type VehicleFieldsFragment = {
  __typename?: 'Vehicle';
  id: string;
  plate: string;
  make: string;
  model: string;
  color?: string;
};

export type HouseholdFieldsFragment = {
  __typename?: 'Household';
  payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
  additionalTenants?: Array<{
    __typename?: 'AdditionalTenant';
    id: string;
    name?: string;
    email?: string;
    phone?: string;
    leaseholder?: boolean;
    payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
  }>;
  pets?: Array<{
    __typename?: 'Pet';
    type: string;
    count: number;
    details?: Array<{
      __typename?: 'PetDetails';
      type?: string;
      breed?: string;
      weight?: string;
      name?: string;
      color?: string;
      license?: string;
      isService?: boolean;
    }>;
  }>;
  vehicles?: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    make: string;
    model: string;
    color?: string;
  }>;
};

export type WorkHistoryFieldsFragment = {
  __typename?: 'WorkHistory';
  id: string;
  name: string;
  location: string;
  phone?: string;
  startDate: string;
  endDate?: string;
  frequency?: SalaryFrequency;
  amount?: number;
};

export type OtherIncomeFieldsFragment = {
  __typename?: 'Income';
  id: string;
  source: IncomeSource;
  amount: number;
};

export type CoApplicationsFieldsFragment = {
  __typename?: 'CoApplication';
  id: string;
  profileId: string;
  name: string;
  emails?: Array<string>;
  phone?: string;
  dob?: string;
  submittedZ: string;
  listedUnitId: string;
  campaignId: string;
  screeningAgreement?: boolean;
  additionalInformation?: string;
  leaseAgreement?: { __typename?: 'CoApplicationLeaseAgreement'; status?: LeaseAgreementStatus };
  workHistory?: Array<{
    __typename?: 'WorkHistory';
    id: string;
    name: string;
    location: string;
    phone?: string;
    startDate: string;
    endDate?: string;
    frequency?: SalaryFrequency;
    amount?: number;
  }>;
  otherIncome?: Array<{ __typename?: 'Income'; id: string; source: IncomeSource; amount: number }>;
  addressHistory?: Array<{
    __typename?: 'AddressHistory';
    id: string;
    name: string;
    phone?: string;
    email?: string;
    startDate: string;
    endDate?: string;
    rentalType?: RentalType;
    address: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  }>;
  household?: {
    __typename?: 'Household';
    payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
    additionalTenants?: Array<{
      __typename?: 'AdditionalTenant';
      id: string;
      name?: string;
      email?: string;
      phone?: string;
      leaseholder?: boolean;
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
    }>;
    pets?: Array<{
      __typename?: 'Pet';
      type: string;
      count: number;
      details?: Array<{
        __typename?: 'PetDetails';
        type?: string;
        breed?: string;
        weight?: string;
        name?: string;
        color?: string;
        license?: string;
        isService?: boolean;
      }>;
    }>;
    vehicles?: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      make: string;
      model: string;
      color?: string;
    }>;
  };
  documents?: Array<{
    __typename?: 'Document';
    key: string;
    name?: string;
    size?: number;
    createdZ: string;
    typename?: string;
  }>;
  emergencyContacts?: Array<{
    __typename?: 'EmergencyContact';
    name: string;
    phone: string;
    relationship?: ContactRelationship;
  }>;
};

export type ApplicationFieldsFragment = {
  __typename?: 'Application';
  id: string;
  campaignId: string;
  leaseId?: string;
  residencyId?: string;
  profileId: string;
  name: string;
  emails?: Array<string>;
  phone?: string;
  dob?: string;
  flag?: ApplicationFlag;
  needsAgreement?: boolean;
  moveInDate?: string;
  additionalInformation?: string;
  listedUnitId: string;
  submittedZ: string;
  status?: ApplicationStatus;
  approvedZ?: string;
  declinedZ?: string;
  screeningAgreement?: boolean;
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    text?: string;
    source?: string;
    target?: string;
    createdZ?: string;
    createdSub?: string;
    createdName?: string;
  }>;
  workHistory?: Array<{
    __typename?: 'WorkHistory';
    id: string;
    name: string;
    location: string;
    phone?: string;
    startDate: string;
    endDate?: string;
    frequency?: SalaryFrequency;
    amount?: number;
  }>;
  otherIncome?: Array<{ __typename?: 'Income'; id: string; source: IncomeSource; amount: number }>;
  addressHistory?: Array<{
    __typename?: 'AddressHistory';
    id: string;
    name: string;
    phone?: string;
    email?: string;
    startDate: string;
    endDate?: string;
    rentalType?: RentalType;
    address: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  }>;
  household?: {
    __typename?: 'Household';
    payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
    additionalTenants?: Array<{
      __typename?: 'AdditionalTenant';
      id: string;
      name?: string;
      email?: string;
      phone?: string;
      leaseholder?: boolean;
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
    }>;
    pets?: Array<{
      __typename?: 'Pet';
      type: string;
      count: number;
      details?: Array<{
        __typename?: 'PetDetails';
        type?: string;
        breed?: string;
        weight?: string;
        name?: string;
        color?: string;
        license?: string;
        isService?: boolean;
      }>;
    }>;
    vehicles?: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      make: string;
      model: string;
      color?: string;
    }>;
  };
  listedUnit?: {
    __typename?: 'ListedUnit';
    id: string;
    rent?: number;
    bedrooms?: number;
    bathrooms?: number;
    sqft?: number;
    exteriorSqFt?: number;
  };
  leaseAgreement?: {
    __typename?: 'LeaseAgreement';
    id: string;
    unitId?: string;
    utilities?: Array<string>;
    additionalClause?: Array<string>;
    updatedZ?: string;
    status?: LeaseAgreementStatus;
    houseRules?: Array<string>;
    history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
    lease?: {
      __typename?: 'AgreementLease';
      start?: string;
      end?: string;
      rrule?: string;
      rent: number;
      securityDeposit?: number;
      gst?: number;
      preferredPaymentMethod?: PaymentMethod;
      occupancy?: UnitOccupancy;
      propertyId?: string;
      furnishings?: Array<string>;
      autoRenewSetup?: { __typename?: 'AutoRenewSetup'; enabled?: boolean; termMonths?: number };
      tenants?: Array<{
        __typename?: 'LeaseTenant';
        name?: string;
        dob?: string;
        emails?: Array<string>;
        phone?: string;
        phones?: Array<string>;
        leaseHolder?: boolean;
        isPayee?: boolean;
        preferredPaymentMethod?: PaymentMethod;
        padAmount?: number;
        padInstitution?: string;
        padTransit?: string;
        padAccount?: string;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          relationship?: ContactRelationship;
          phone: string;
        }>;
        pets?: Array<{ __typename?: 'Pet'; type: string; count: number }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      }>;
      incentives?: Array<{
        __typename?: 'LeaseIncentive';
        start: string;
        end: string;
        discountAmount: number;
        type: string;
        description?: string;
      }>;
      rateSchedule?: Array<{ __typename?: 'RateSchedule'; start: string; rent: number }>;
      rentRollFees?: Array<{
        __typename?: 'AgreementLeaseFees';
        feeId: string;
        amount?: number;
        name?: string;
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
      }>;
    };
    additionalProvisions?: {
      __typename?: 'AdditionalProvisions';
      pets?: number;
      parking?: boolean;
      storage?: boolean;
      smoking?: boolean;
    };
    signatures?: Array<{
      __typename?: 'Signature';
      id?: string;
      signature: string;
      signerName: string;
      signerEntity: SignerEntity;
      isAgent?: boolean;
      agentRelationship?: string;
    }>;
  };
  documents?: Array<{
    __typename?: 'Document';
    key: string;
    name?: string;
    size?: number;
    createdZ: string;
    typename?: string;
  }>;
  emergencyContacts?: Array<{
    __typename?: 'EmergencyContact';
    name: string;
    phone: string;
    relationship?: ContactRelationship;
  }>;
  coApplications?: Array<{
    __typename?: 'CoApplication';
    id: string;
    profileId: string;
    name: string;
    emails?: Array<string>;
    phone?: string;
    dob?: string;
    submittedZ: string;
    listedUnitId: string;
    campaignId: string;
    screeningAgreement?: boolean;
    additionalInformation?: string;
    leaseAgreement?: { __typename?: 'CoApplicationLeaseAgreement'; status?: LeaseAgreementStatus };
    workHistory?: Array<{
      __typename?: 'WorkHistory';
      id: string;
      name: string;
      location: string;
      phone?: string;
      startDate: string;
      endDate?: string;
      frequency?: SalaryFrequency;
      amount?: number;
    }>;
    otherIncome?: Array<{
      __typename?: 'Income';
      id: string;
      source: IncomeSource;
      amount: number;
    }>;
    addressHistory?: Array<{
      __typename?: 'AddressHistory';
      id: string;
      name: string;
      phone?: string;
      email?: string;
      startDate: string;
      endDate?: string;
      rentalType?: RentalType;
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    }>;
    household?: {
      __typename?: 'Household';
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      additionalTenants?: Array<{
        __typename?: 'AdditionalTenant';
        id: string;
        name?: string;
        email?: string;
        phone?: string;
        leaseholder?: boolean;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      }>;
      pets?: Array<{
        __typename?: 'Pet';
        type: string;
        count: number;
        details?: Array<{
          __typename?: 'PetDetails';
          type?: string;
          breed?: string;
          weight?: string;
          name?: string;
          color?: string;
          license?: string;
          isService?: boolean;
        }>;
      }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
    };
    documents?: Array<{
      __typename?: 'Document';
      key: string;
      name?: string;
      size?: number;
      createdZ: string;
      typename?: string;
    }>;
    emergencyContacts?: Array<{
      __typename?: 'EmergencyContact';
      name: string;
      phone: string;
      relationship?: ContactRelationship;
    }>;
  }>;
};

export type BatchFieldsFragment = {
  __typename?: 'Batch';
  id: string;
  name?: string;
  presetId?: string;
  posted?: string;
  status?: string;
  errorMessage?: string;
};

export type BatchPrototypeFieldsFragment = {
  __typename?: 'JEPrototype';
  id: string;
  omit?: boolean;
  fields?: Array<{
    __typename?: 'JEPrototypeField';
    id: string;
    groupId?: string;
    label: string;
    type?: BooksFieldType;
    order?: number;
    editable?: boolean;
    value?: string;
    formula?: string;
    secondary?: boolean;
  }>;
};

export type BooksFieldsFragment = {
  __typename?: 'Books';
  accountId: string;
  chequePlacement?: ChequePlacement;
  nextCheque?: number;
  nextInvoice?: number;
  nextJournal?: number;
  yearEndMonth?: number;
  receiverGeneralId?: string;
  gstNumber?: string;
  gstFiling?: FilingFrequency;
  printedZ?: string;
  includeDisabledProperties?: boolean;
  trackManagementFeeRevenue?: boolean;
  managementFeeBasis?: ManagementFeeBasis;
  billableRevenueFeeIds?: Array<string>;
  lastPrintedGlId?: string;
  billingRates?: {
    __typename?: 'BillingRate';
    enabled?: boolean;
    labourRate?: number;
    materialsMarkup?: number;
    requireApproval?: boolean;
    minBillableMinutes?: number;
    trackRevenue?: boolean;
  };
  customBillingRates?: Array<{
    __typename?: 'BillingRate';
    category?: string;
    glId?: string;
    enabled?: boolean;
    labourRate?: number;
    materialsMarkup?: number;
    requireApproval?: boolean;
    minBillableMinutes?: number;
    trackRevenue?: boolean;
  }>;
  printedCheques?: Array<{
    __typename?: 'BooksPrintedCheque';
    glId: string;
    printedZ: string;
    nextCheque: number;
    chequePlacement?: ChequePlacement;
  }>;
  incomePayoutOptions?: {
    __typename?: 'IncomePayoutOptions';
    excludePayables?: boolean;
    excludePrepayments?: boolean;
    includeOutstandingPayables?: boolean;
  };
  glAccounts: Array<{
    __typename?: 'GLAccount';
    id: string;
    name: string;
    key?: string;
    category?: string;
    accountType: AccountType;
    balanceType: BalanceType;
  }>;
  glAccountMapping?: Array<{
    __typename?: 'GLMapping';
    id: string;
    parentId: string;
    name: string;
    description: string;
    default: string;
    filter?: string;
    glId?: string;
  }>;
  presets?: Array<{
    __typename?: 'Preset';
    id: string;
    type: PresetType;
    version?: BooksReportVersion;
    category?: PresetCategory;
    name: string;
    visibleExpression?: string;
    description: string;
    lastPosted?: string;
    roles?: Array<ManagerRole>;
    group?: { __typename?: 'PresetGroup'; name: string; order?: number };
    glMapping?: Array<{
      __typename?: 'GLMapping';
      id: string;
      parentId: string;
      name: string;
      description: string;
      default: string;
      filter?: string;
      glId?: string;
    }>;
    inputFields?: Array<{
      __typename?: 'PresetInputField';
      id: string;
      label: string;
      type: BooksFieldType;
      required?: boolean;
      defaultExpression?: string;
      options?: Array<{ __typename?: 'PresetOption'; label: string; value: string }>;
    }>;
    recurrence?: {
      __typename?: 'PresetRecurrence';
      enabled: boolean;
      locked?: boolean;
      rrule: string;
      postedOffset: number;
      parameters: string;
    };
    icon?: { __typename?: 'PresetIcon'; name?: string; color?: string };
  }>;
  feeMapping?: Array<{ __typename?: 'FeeMapping'; feeId: string; glId: string }>;
  chargeMapping?: Array<{ __typename?: 'ChargeMapping'; chargeId: string; glId: string }>;
};

export type BooksReportFieldsFragment = {
  __typename?: 'BooksReport';
  id: string;
  title?: string;
  subtitle1?: string;
  subtitle2?: string;
  propertyId?: string;
  ownerId?: string;
  range?: string;
  options?: {
    __typename?: 'BooksReportOptions';
    showZeroAmounts?: boolean;
    hideAmountColumn?: boolean;
  };
  grandTotals?: Array<{
    __typename?: 'GrandTotal';
    order?: number;
    amount?: number;
    balanceType?: BalanceType;
    header?: { __typename?: 'BooksReportHeader'; label?: string };
    footer?: { __typename?: 'BooksReportFooter'; label?: string };
    extraColumns?: Array<{
      __typename?: 'BooksReportExtraColumn';
      columnHeader?: string;
      value?: string;
      flex?: string;
      columnType?: ExtraColumnType;
      keepNonZero?: boolean;
    }>;
    sections: Array<{
      __typename?: 'BooksReportRendered';
      order?: number;
      date?: string;
      columnHeader?: string;
      amount?: number;
      balanceType?: BalanceType;
      header?: { __typename?: 'BooksReportHeader'; label?: string };
      footer?: { __typename?: 'BooksReportFooter'; label?: string };
      extraColumns?: Array<{
        __typename?: 'BooksReportExtraColumn';
        columnHeader?: string;
        value?: string;
        flex?: string;
        columnType?: ExtraColumnType;
        keepNonZero?: boolean;
      }>;
      lines?: Array<{
        __typename?: 'BooksReportRendered';
        label?: string;
        order?: number;
        date?: string;
        columnHeader?: string;
        amount?: number;
        maxWidth?: string;
        balanceType?: BalanceType;
        extraColumns?: Array<{
          __typename?: 'BooksReportExtraColumn';
          columnHeader?: string;
          value?: string;
          flex?: string;
          columnType?: ExtraColumnType;
          keepNonZero?: boolean;
          maxWidth?: string;
        }>;
        action?: {
          __typename?: 'RenderedAction';
          glId?: string;
          ownerId?: string;
          propertyId?: string;
        };
        header?: { __typename?: 'BooksReportHeader'; label?: string };
        footer?: { __typename?: 'BooksReportFooter'; label?: string };
        lines?: Array<{
          __typename?: 'BooksReportRendered';
          label?: string;
          order?: number;
          date?: string;
          amount?: number;
          maxWidth?: string;
          balanceType?: BalanceType;
          extraColumns?: Array<{
            __typename?: 'BooksReportExtraColumn';
            columnHeader?: string;
            value?: string;
            flex?: string;
            columnType?: ExtraColumnType;
            keepNonZero?: boolean;
            maxWidth?: string;
          }>;
          action?: {
            __typename?: 'RenderedAction';
            glId?: string;
            ownerId?: string;
            propertyId?: string;
          };
          header?: { __typename?: 'BooksReportHeader'; label?: string };
          footer?: { __typename?: 'BooksReportFooter'; label?: string };
        }>;
      }>;
    }>;
  }>;
};

export type BuildingFieldsFragment = {
  __typename?: 'Building';
  id: string;
  name: string;
  key?: string;
  imageKey?: string;
  timezone: string;
  phone?: string;
  ownerId?: string;
  types?: Array<PropertyType>;
  legalDescription?: string;
  address?: {
    __typename?: 'Address';
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    lat?: number;
    lng?: number;
  };
};

export type ListedUnitFieldsFragment = {
  __typename?: 'ListedUnit';
  id: string;
  rent?: number;
  bedrooms?: number;
  bathrooms?: number;
  sqft?: number;
  exteriorSqFt?: number;
};

export type CampaignFieldsFragment = {
  __typename?: 'Campaign';
  id: string;
  accountId: string;
  propertyId: string;
  link?: string;
  name?: string;
  status: CampaignStatus;
  systemCreated?: boolean;
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    key?: string;
    imageKey?: string;
    address: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
    units?: Array<{ __typename?: 'Unit'; id: string; floorplanId?: string }>;
  };
  listedUnits?: Array<{
    __typename?: 'ListedUnit';
    id: string;
    rent?: number;
    bedrooms?: number;
    bathrooms?: number;
    sqft?: number;
    exteriorSqFt?: number;
  }>;
  history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
  applications?: Array<{
    __typename?: 'Application';
    id: string;
    campaignId: string;
    leaseId?: string;
    residencyId?: string;
    profileId: string;
    name: string;
    emails?: Array<string>;
    phone?: string;
    dob?: string;
    flag?: ApplicationFlag;
    needsAgreement?: boolean;
    moveInDate?: string;
    additionalInformation?: string;
    listedUnitId: string;
    submittedZ: string;
    status?: ApplicationStatus;
    approvedZ?: string;
    declinedZ?: string;
    screeningAgreement?: boolean;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      text?: string;
      source?: string;
      target?: string;
      createdZ?: string;
      createdSub?: string;
      createdName?: string;
    }>;
    workHistory?: Array<{
      __typename?: 'WorkHistory';
      id: string;
      name: string;
      location: string;
      phone?: string;
      startDate: string;
      endDate?: string;
      frequency?: SalaryFrequency;
      amount?: number;
    }>;
    otherIncome?: Array<{
      __typename?: 'Income';
      id: string;
      source: IncomeSource;
      amount: number;
    }>;
    addressHistory?: Array<{
      __typename?: 'AddressHistory';
      id: string;
      name: string;
      phone?: string;
      email?: string;
      startDate: string;
      endDate?: string;
      rentalType?: RentalType;
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    }>;
    household?: {
      __typename?: 'Household';
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      additionalTenants?: Array<{
        __typename?: 'AdditionalTenant';
        id: string;
        name?: string;
        email?: string;
        phone?: string;
        leaseholder?: boolean;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      }>;
      pets?: Array<{
        __typename?: 'Pet';
        type: string;
        count: number;
        details?: Array<{
          __typename?: 'PetDetails';
          type?: string;
          breed?: string;
          weight?: string;
          name?: string;
          color?: string;
          license?: string;
          isService?: boolean;
        }>;
      }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
    };
    listedUnit?: {
      __typename?: 'ListedUnit';
      id: string;
      rent?: number;
      bedrooms?: number;
      bathrooms?: number;
      sqft?: number;
      exteriorSqFt?: number;
    };
    leaseAgreement?: {
      __typename?: 'LeaseAgreement';
      id: string;
      unitId?: string;
      utilities?: Array<string>;
      additionalClause?: Array<string>;
      updatedZ?: string;
      status?: LeaseAgreementStatus;
      houseRules?: Array<string>;
      history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
      lease?: {
        __typename?: 'AgreementLease';
        start?: string;
        end?: string;
        rrule?: string;
        rent: number;
        securityDeposit?: number;
        gst?: number;
        preferredPaymentMethod?: PaymentMethod;
        occupancy?: UnitOccupancy;
        propertyId?: string;
        furnishings?: Array<string>;
        autoRenewSetup?: { __typename?: 'AutoRenewSetup'; enabled?: boolean; termMonths?: number };
        tenants?: Array<{
          __typename?: 'LeaseTenant';
          name?: string;
          dob?: string;
          emails?: Array<string>;
          phone?: string;
          phones?: Array<string>;
          leaseHolder?: boolean;
          isPayee?: boolean;
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          emergencyContacts?: Array<{
            __typename?: 'EmergencyContact';
            name: string;
            relationship?: ContactRelationship;
            phone: string;
          }>;
          pets?: Array<{ __typename?: 'Pet'; type: string; count: number }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        }>;
        incentives?: Array<{
          __typename?: 'LeaseIncentive';
          start: string;
          end: string;
          discountAmount: number;
          type: string;
          description?: string;
        }>;
        rateSchedule?: Array<{ __typename?: 'RateSchedule'; start: string; rent: number }>;
        rentRollFees?: Array<{
          __typename?: 'AgreementLeaseFees';
          feeId: string;
          amount?: number;
          name?: string;
        }>;
        refundables?: Array<{
          __typename?: 'Refundable';
          id: string;
          name: string;
          amount?: number;
          templateId: string;
        }>;
      };
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
      signatures?: Array<{
        __typename?: 'Signature';
        id?: string;
        signature: string;
        signerName: string;
        signerEntity: SignerEntity;
        isAgent?: boolean;
        agentRelationship?: string;
      }>;
    };
    documents?: Array<{
      __typename?: 'Document';
      key: string;
      name?: string;
      size?: number;
      createdZ: string;
      typename?: string;
    }>;
    emergencyContacts?: Array<{
      __typename?: 'EmergencyContact';
      name: string;
      phone: string;
      relationship?: ContactRelationship;
    }>;
    coApplications?: Array<{
      __typename?: 'CoApplication';
      id: string;
      profileId: string;
      name: string;
      emails?: Array<string>;
      phone?: string;
      dob?: string;
      submittedZ: string;
      listedUnitId: string;
      campaignId: string;
      screeningAgreement?: boolean;
      additionalInformation?: string;
      leaseAgreement?: {
        __typename?: 'CoApplicationLeaseAgreement';
        status?: LeaseAgreementStatus;
      };
      workHistory?: Array<{
        __typename?: 'WorkHistory';
        id: string;
        name: string;
        location: string;
        phone?: string;
        startDate: string;
        endDate?: string;
        frequency?: SalaryFrequency;
        amount?: number;
      }>;
      otherIncome?: Array<{
        __typename?: 'Income';
        id: string;
        source: IncomeSource;
        amount: number;
      }>;
      addressHistory?: Array<{
        __typename?: 'AddressHistory';
        id: string;
        name: string;
        phone?: string;
        email?: string;
        startDate: string;
        endDate?: string;
        rentalType?: RentalType;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      household?: {
        __typename?: 'Household';
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        additionalTenants?: Array<{
          __typename?: 'AdditionalTenant';
          id: string;
          name?: string;
          email?: string;
          phone?: string;
          leaseholder?: boolean;
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        }>;
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      };
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ: string;
        typename?: string;
      }>;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        phone: string;
        relationship?: ContactRelationship;
      }>;
    }>;
  }>;
};

export type ChequeFieldsFragment = {
  __typename?: 'JournalEntry';
  id: string;
  payee?: PayeeType;
  payeeId?: string;
  payeeName?: string;
  amount: number;
  posted: string;
  printedZ?: string;
  ref?: string;
  description?: string;
  glAccount: {
    __typename?: 'GLAccount';
    id: string;
    name: string;
    key?: string;
    category?: string;
    accountType: AccountType;
    balanceType: BalanceType;
  };
  journalEntries?: Array<{
    __typename?: 'JournalEntry';
    ref?: string;
    description?: string;
    propertyId: string;
    amount: number;
    glAccount: {
      __typename?: 'GLAccount';
      id: string;
      name: string;
      key?: string;
      category?: string;
      accountType: AccountType;
      balanceType: BalanceType;
    };
    clearable?: {
      __typename?: 'Clearable';
      id: string;
      sourceJournalEntry: {
        __typename?: 'JournalEntry';
        id: string;
        description?: string;
        ref?: string;
        posted: string;
        propertyId: string;
        unitId?: string;
      };
    };
  }>;
};

export type ClearableFieldsFragment = {
  __typename?: 'Clearable';
  id: string;
  description?: string;
  due: string;
  posted: string;
  glId: string;
  ownerId: string;
  sourceJournalId: string;
  payeeId: string;
  payeeName?: string;
  unitId?: string;
  propertyId: string;
  payee: PayeeType;
  amount: number;
  outstanding: number;
  cleared?: string;
  ref?: string;
  balanceType: BalanceType;
  batchId?: string;
  refund?: boolean;
  enrollmentId?: string;
  fixedEnrollmentId?: string;
  autopayCollectionsEnabled?: boolean;
  payeeAutopayCollectionsEnabled?: boolean;
  supplierAccount?: string;
  paymentsStatus?: PaymentStatus;
  autopaySinglePosted?: string;
  journalEntries?: Array<{
    __typename?: 'JournalEntry';
    id: string;
    ownerId: string;
    glId: string;
    jeId: string;
    unitId?: string;
    amount: number;
    description?: string;
    posted: string;
    propertyId: string;
    paymentMethod?: PaymentMethod;
    ref?: string;
    gstInfo?: GstInfo;
    printedZ?: string;
    voidedZ?: string;
    requestId?: string;
    payee?: PayeeType;
    payeeId?: string;
    payeeName?: string;
    clearableId?: string;
    clearableLinked?: boolean;
    reconciliationId?: string;
    approverName?: string;
    transactionReferenceId?: string;
    clearable?: {
      __typename?: 'Clearable';
      id: string;
      payee: PayeeType;
      payeeId: string;
      payeeName?: string;
      balanceType: BalanceType;
      description?: string;
      sourceJournalId: string;
      sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
    };
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    lines: Array<{
      __typename?: 'JournalEntryLine';
      id?: string;
      glId: string;
      ownerId: string;
      propertyId: string;
      unitId?: string;
      amount: number;
      description?: string;
      clearableId?: string;
    }>;
  }>;
  glAccount: {
    __typename?: 'GLAccount';
    id: string;
    name: string;
    key?: string;
    category?: string;
    accountType: AccountType;
    balanceType: BalanceType;
  };
  sourceJournalEntry: {
    __typename?: 'JournalEntry';
    id: string;
    ownerId: string;
    glId: string;
    jeId: string;
    unitId?: string;
    amount: number;
    description?: string;
    posted: string;
    propertyId: string;
    paymentMethod?: PaymentMethod;
    ref?: string;
    gstInfo?: GstInfo;
    printedZ?: string;
    voidedZ?: string;
    requestId?: string;
    payee?: PayeeType;
    payeeId?: string;
    payeeName?: string;
    clearableId?: string;
    clearableLinked?: boolean;
    reconciliationId?: string;
    approverName?: string;
    transactionReferenceId?: string;
    clearable?: {
      __typename?: 'Clearable';
      id: string;
      payee: PayeeType;
      payeeId: string;
      payeeName?: string;
      balanceType: BalanceType;
      description?: string;
      sourceJournalId: string;
      sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
    };
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    lines: Array<{
      __typename?: 'JournalEntryLine';
      id?: string;
      glId: string;
      ownerId: string;
      propertyId: string;
      unitId?: string;
      amount: number;
      description?: string;
      clearableId?: string;
    }>;
  };
  documents?: Array<{
    __typename?: 'Document';
    key: string;
    name?: string;
    size?: number;
    createdZ: string;
    typename?: string;
  }>;
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    target?: string;
    source?: string;
    createdName?: string;
    createdSub?: string;
    createdZ?: string;
    text?: string;
  }>;
};

export type ClearableListFieldsFragment = {
  __typename?: 'Clearable';
  id: string;
  due: string;
  posted: string;
  glId: string;
  ownerId: string;
  sourceJournalId: string;
  payeeId: string;
  unitId?: string;
  propertyId: string;
  payee: PayeeType;
  amount: number;
  outstanding: number;
  cleared?: string;
  ref?: string;
  description?: string;
  balanceType: BalanceType;
  batchId?: string;
  refund?: boolean;
  enrollmentId?: string;
  fixedEnrollmentId?: string;
  paymentsStatus?: PaymentStatus;
  autopayCollectionsEnabled?: boolean;
};

export type ContactFieldsFragment = {
  __typename?: 'Contact';
  id: string;
  accountId: string;
  entityId: string;
  type?: ContactType;
  name: string;
  emails?: Array<string>;
  phones?: Array<string>;
  address?: {
    __typename?: 'Address';
    suite?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
  };
};

export type DocumentFieldsFragment = {
  __typename?: 'Document';
  key: string;
  name?: string;
  size?: number;
  createdZ: string;
  typename?: string;
};

export type DraftBatchFieldsFragment = {
  __typename?: 'JEDraftBatch';
  id: string;
  name: string;
  presetId?: string;
  posted?: string;
  prototypes?: Array<{
    __typename?: 'JEPrototype';
    id: string;
    fields?: Array<{
      __typename?: 'JEPrototypeField';
      id: string;
      groupId?: string;
      label: string;
      type?: BooksFieldType;
      order?: number;
      editable?: boolean;
      value?: string;
      formula?: string;
      secondary?: boolean;
    }>;
  }>;
};

export type FeatureFieldsFragment = { __typename?: 'Feature'; id?: string; name?: string };

export type GlAccountFieldsFragment = {
  __typename?: 'GLAccount';
  id: string;
  name: string;
  key?: string;
  category?: string;
  accountType: AccountType;
  balanceType: BalanceType;
};

export type GlMappingFieldsFragment = {
  __typename?: 'GLMapping';
  id: string;
  parentId: string;
  name: string;
  description: string;
  default: string;
  filter?: string;
  glId?: string;
};

export type InspectionFieldsFragment = {
  __typename?: 'LeaseInspection';
  id: string;
  status?: InspectionStatus;
  completedZ?: string;
  templateId: string;
  category?: InspectionCategory;
  inspectionAgreements?: Array<{
    __typename?: 'Agreement';
    noteText?: string;
    agreementType: AgreementType;
  }>;
  costAgreement?: {
    __typename?: 'Agreement';
    cost?: number;
    noteText?: string;
    agreementType: AgreementType;
  };
  signatures?: Array<{
    __typename?: 'Signature';
    id?: string;
    signature: string;
    signerName: string;
    signerEntity: SignerEntity;
    isAgent?: boolean;
    agentRelationship?: string;
  }>;
  items?: Array<{
    __typename?: 'InspectionItem';
    id: string;
    referenceId?: string;
    label: string;
    description?: string;
    category: InspectionItemCategory;
    wasInspected?: boolean;
    fields: Array<{
      __typename?: 'InspectionField';
      id: string;
      name: string;
      label?: string;
      description?: string;
      type: string;
      value?: string;
      noteText?: string;
      needsRequest?: boolean;
      inspectedZ?: string;
      images?: Array<{ __typename?: 'Image'; key?: string }>;
    }>;
  }>;
  generatedSummary?: { __typename?: 'InspectionSummary'; summary?: string; score?: number };
};

export type InspectionItemFieldsFragment = {
  __typename?: 'InspectionItem';
  id: string;
  referenceId?: string;
  label: string;
  description?: string;
  category: InspectionItemCategory;
  wasInspected?: boolean;
  fields: Array<{
    __typename?: 'InspectionField';
    id: string;
    name: string;
    label?: string;
    description?: string;
    type: string;
    value?: string;
    noteText?: string;
    needsRequest?: boolean;
    inspectedZ?: string;
    images?: Array<{ __typename?: 'Image'; key?: string }>;
  }>;
};

export type ClaimFieldsFragment = {
  __typename?: 'Claim';
  id: string;
  date: string;
  amount: number;
  status?: ClaimStatus;
  type?: ClaimType;
  description?: string;
};

export type InsurancePolicyFieldsFragment = {
  __typename?: 'InsurancePolicy';
  id: string;
  policyNumber: string;
  policyHolder: string;
  effective: string;
  expiry: string;
  supplierId?: string;
  coverage?: number;
  premium?: number;
  supplier?: {
    __typename?: 'Supplier';
    id: string;
    accountId: string;
    name: string;
    contactName?: string;
    type?: string;
    phone?: string;
    fax?: string;
    email?: string;
    enabled: boolean;
    location?: string;
    deletedZ?: string;
    verificationRequestedZ?: string;
    verifiedZ?: string;
    website?: string;
    coi?: boolean;
    coiExpiry?: string;
    billingEmail?: string;
    businessNumber?: string;
    autopayInstitution?: string;
    autopayTransit?: string;
    autopayAccount?: string;
    autopayStatus?: string;
    autopayStatusUpdatedZ?: string;
    autopayClientId?: string;
    expenseGlId?: string;
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
    fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
    supplierAccounts?: Array<{ __typename?: 'SupplierAccount'; id: string; accountNumber: string }>;
  };
  claims?: Array<{
    __typename?: 'Claim';
    id: string;
    date: string;
    amount: number;
    status?: ClaimStatus;
    type?: ClaimType;
    description?: string;
  }>;
};

export type JournalEntryFieldsFragment = {
  __typename?: 'JournalEntry';
  id: string;
  ownerId: string;
  glId: string;
  jeId: string;
  unitId?: string;
  amount: number;
  description?: string;
  posted: string;
  propertyId: string;
  paymentMethod?: PaymentMethod;
  ref?: string;
  gstInfo?: GstInfo;
  printedZ?: string;
  voidedZ?: string;
  requestId?: string;
  payee?: PayeeType;
  payeeId?: string;
  payeeName?: string;
  clearableId?: string;
  clearableLinked?: boolean;
  reconciliationId?: string;
  approverName?: string;
  transactionReferenceId?: string;
  clearable?: {
    __typename?: 'Clearable';
    id: string;
    payee: PayeeType;
    payeeId: string;
    payeeName?: string;
    balanceType: BalanceType;
    description?: string;
    sourceJournalId: string;
    sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
  };
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    target?: string;
    source?: string;
    createdName?: string;
    createdSub?: string;
    createdZ?: string;
    text?: string;
  }>;
  lines: Array<{
    __typename?: 'JournalEntryLine';
    id?: string;
    glId: string;
    ownerId: string;
    propertyId: string;
    unitId?: string;
    amount: number;
    description?: string;
    clearableId?: string;
  }>;
};

export type JournalEntryListFieldsFragment = {
  __typename?: 'JournalEntry';
  id: string;
  ownerId: string;
  glId: string;
  jeId: string;
  unitId?: string;
  amount: number;
  description?: string;
  posted: string;
  propertyId: string;
  paymentMethod?: PaymentMethod;
  ref?: string;
  gstInfo?: GstInfo;
  printedZ?: string;
  voidedZ?: string;
  requestId?: string;
  payee?: PayeeType;
  payeeId?: string;
  payeeName?: string;
  clearableId?: string;
  clearableLinked?: boolean;
  reconciliationId?: string;
  approverName?: string;
  lines: Array<{
    __typename?: 'JournalEntryLine';
    id?: string;
    glId: string;
    ownerId: string;
    propertyId: string;
    unitId?: string;
    amount: number;
    description?: string;
    clearableId?: string;
  }>;
};

export type AgreementLeaseFieldsFragment = {
  __typename?: 'AgreementLease';
  start?: string;
  end?: string;
  rrule?: string;
  rent: number;
  securityDeposit?: number;
  gst?: number;
  preferredPaymentMethod?: PaymentMethod;
  occupancy?: UnitOccupancy;
  propertyId?: string;
  furnishings?: Array<string>;
  autoRenewSetup?: { __typename?: 'AutoRenewSetup'; enabled?: boolean; termMonths?: number };
  tenants?: Array<{
    __typename?: 'LeaseTenant';
    name?: string;
    dob?: string;
    emails?: Array<string>;
    phone?: string;
    phones?: Array<string>;
    leaseHolder?: boolean;
    isPayee?: boolean;
    preferredPaymentMethod?: PaymentMethod;
    padAmount?: number;
    padInstitution?: string;
    padTransit?: string;
    padAccount?: string;
    payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
    emergencyContacts?: Array<{
      __typename?: 'EmergencyContact';
      name: string;
      relationship?: ContactRelationship;
      phone: string;
    }>;
    pets?: Array<{ __typename?: 'Pet'; type: string; count: number }>;
    vehicles?: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      make: string;
      model: string;
      color?: string;
    }>;
  }>;
  incentives?: Array<{
    __typename?: 'LeaseIncentive';
    start: string;
    end: string;
    discountAmount: number;
    type: string;
    description?: string;
  }>;
  rateSchedule?: Array<{ __typename?: 'RateSchedule'; start: string; rent: number }>;
  rentRollFees?: Array<{
    __typename?: 'AgreementLeaseFees';
    feeId: string;
    amount?: number;
    name?: string;
  }>;
  refundables?: Array<{
    __typename?: 'Refundable';
    id: string;
    name: string;
    amount?: number;
    templateId: string;
  }>;
};

export type LeaseAgreementFieldsFragment = {
  __typename?: 'LeaseAgreement';
  id: string;
  unitId?: string;
  utilities?: Array<string>;
  additionalClause?: Array<string>;
  updatedZ?: string;
  status?: LeaseAgreementStatus;
  houseRules?: Array<string>;
  history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
  lease?: {
    __typename?: 'AgreementLease';
    start?: string;
    end?: string;
    rrule?: string;
    rent: number;
    securityDeposit?: number;
    gst?: number;
    preferredPaymentMethod?: PaymentMethod;
    occupancy?: UnitOccupancy;
    propertyId?: string;
    furnishings?: Array<string>;
    autoRenewSetup?: { __typename?: 'AutoRenewSetup'; enabled?: boolean; termMonths?: number };
    tenants?: Array<{
      __typename?: 'LeaseTenant';
      name?: string;
      dob?: string;
      emails?: Array<string>;
      phone?: string;
      phones?: Array<string>;
      leaseHolder?: boolean;
      isPayee?: boolean;
      preferredPaymentMethod?: PaymentMethod;
      padAmount?: number;
      padInstitution?: string;
      padTransit?: string;
      padAccount?: string;
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        relationship?: ContactRelationship;
        phone: string;
      }>;
      pets?: Array<{ __typename?: 'Pet'; type: string; count: number }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
    }>;
    incentives?: Array<{
      __typename?: 'LeaseIncentive';
      start: string;
      end: string;
      discountAmount: number;
      type: string;
      description?: string;
    }>;
    rateSchedule?: Array<{ __typename?: 'RateSchedule'; start: string; rent: number }>;
    rentRollFees?: Array<{
      __typename?: 'AgreementLeaseFees';
      feeId: string;
      amount?: number;
      name?: string;
    }>;
    refundables?: Array<{
      __typename?: 'Refundable';
      id: string;
      name: string;
      amount?: number;
      templateId: string;
    }>;
  };
  additionalProvisions?: {
    __typename?: 'AdditionalProvisions';
    pets?: number;
    parking?: boolean;
    storage?: boolean;
    smoking?: boolean;
  };
  signatures?: Array<{
    __typename?: 'Signature';
    id?: string;
    signature: string;
    signerName: string;
    signerEntity: SignerEntity;
    isAgent?: boolean;
    agentRelationship?: string;
  }>;
};

export type ManagerFieldsFragment = {
  __typename?: 'Manager';
  id: string;
  name: string;
  email: string;
  phone?: string;
  roles: Array<ManagerRole>;
  enabled: boolean;
  accountId?: string;
  signature?: {
    __typename?: 'Signature';
    id?: string;
    signerId?: string;
    signature: string;
    signerName: string;
    signerEntity: SignerEntity;
    isAgent?: boolean;
    agentRelationship?: string;
  };
  subscriptions?: Array<{
    __typename?: 'MessageSubscription';
    id: string;
    endpoints: Array<{
      __typename?: 'SubscriptionEndpoint';
      id: SubscriptionEndpointType;
      address: string;
    }>;
  }>;
};

export type OperatorRequestFieldsFragment = {
  __typename?: 'Operator';
  id: string;
  name: string;
  location?: string;
  email?: string;
  phone?: string;
  availability?: Array<string>;
  operatorType?: OperatorType;
};

export type OwnerFieldsFragment = {
  __typename?: 'Owner';
  id: string;
  name: string;
  dob?: string;
  email?: string;
  phones?: Array<string>;
  enabled?: boolean;
  correspondenceMethod?: CorrespondenceMethod;
  profileId?: string;
  propertyIds?: Array<string>;
  unitIds?: Array<string>;
  gstNumber?: string;
  gstFiling?: FilingFrequency;
  yearEndMonth?: number;
  feeRate?: number;
  feeAmount?: number;
  flatFee?: number;
  nrtFeeAmount?: number;
  nrtFeeRate?: number;
  preferredPaymentMethod?: PaymentMethod;
  padAmount?: number;
  padInstitution?: string;
  padTransit?: string;
  padAccount?: string;
  trackCondoFees?: boolean;
  booksSinceZ?: string;
  autopayInstitution?: string;
  autopayAccount?: string;
  autopayTransit?: string;
  autopayStatus?: string;
  autopayInvitedZ?: string;
  autopayStatusUpdatedZ?: string;
  autopayCollectionsEnabled?: boolean;
  address?: {
    __typename?: 'Address';
    suite?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    country?: string;
    lat?: number;
    lng?: number;
  };
  mailingAddress?: {
    __typename?: 'Address';
    suite?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    country?: string;
    lat?: number;
    lng?: number;
  };
  pets?: Array<{
    __typename?: 'Pet';
    type: string;
    count: number;
    details?: Array<{
      __typename?: 'PetDetails';
      type?: string;
      breed?: string;
      weight?: string;
      name?: string;
      color?: string;
      license?: string;
      isService?: boolean;
    }>;
  }>;
  vehicles?: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    make: string;
    model: string;
    color?: string;
  }>;
  assetFees?: Array<{
    __typename?: 'AssetFee';
    assetId: string;
    feeRate?: number;
    feeAmount?: number;
    flatFee?: number;
    nrtFeeAmount?: number;
    nrtFeeRate?: number;
  }>;
  contacts?: Array<{
    __typename?: 'Contact';
    id: string;
    accountId: string;
    entityId: string;
    type?: ContactType;
    name: string;
    emails?: Array<string>;
    phones?: Array<string>;
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  }>;
  properties?: Array<{
    __typename?: 'Property';
    id: string;
    name: string;
    key?: string;
    leasedUnits?: number;
    totalUnits?: number;
    imageKey?: string;
    reserveAmount?: number;
    address: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  }>;
  units?: Array<{
    __typename?: 'Unit';
    id: string;
    name: string;
    ownerId?: string;
    occupancy?: UnitOccupancy;
    floorplanId?: string;
    reserveAmount?: number;
    buildingId?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      autopayCollectionsEnabled?: boolean;
    };
  }>;
  history?: Array<{
    __typename?: 'OwnerHistoryEntry';
    assetType: AssetType;
    status: string;
    assetId: string;
    createdZ: string;
    leasedUnits?: number;
    commercialLeasedUnits?: number;
  }>;
  emergencyContacts?: Array<{
    __typename?: 'EmergencyContact';
    name: string;
    relationship?: ContactRelationship;
    phone: string;
  }>;
};

export type OwnerListFieldsFragment = {
  __typename?: 'Owner';
  id: string;
  name: string;
  email?: string;
  phones?: Array<string>;
  enabled?: boolean;
  booksSinceZ?: string;
  propertyIds?: Array<string>;
  unitIds?: Array<string>;
  preferredPaymentMethod?: PaymentMethod;
  padAmount?: number;
  profileId?: string;
  autopayClientId?: string;
  autopayStatus?: string;
  address?: {
    __typename?: 'Address';
    city?: string;
    postal?: string;
    province?: string;
    suite?: string;
    street?: string;
  };
  propertyHeaders?: Array<{
    __typename?: 'PropertyHeader';
    id: string;
    name?: string;
    key?: string;
  }>;
  unitHeaders?: Array<{
    __typename?: 'UnitHeader';
    id: string;
    propertyId: string;
    propertyName?: string;
    name?: string;
    occupancy?: UnitOccupancy;
  }>;
  history?: Array<{
    __typename?: 'OwnerHistoryEntry';
    assetType: AssetType;
    assetId: string;
    leasedUnits?: number;
    commercialLeasedUnits?: number;
  }>;
  units?: Array<{
    __typename?: 'Unit';
    id: string;
    paymentDetails?: {
      __typename?: 'PaymentDetails';
      preferredPaymentMethod?: PaymentMethod;
      padAmount?: number;
      padInstitution?: string;
      padTransit?: string;
      padAccount?: string;
    };
  }>;
};

export type OwnerWithNotesListFieldsFragment = {
  __typename?: 'Owner';
  correspondenceMethod?: CorrespondenceMethod;
  id: string;
  name: string;
  email?: string;
  phones?: Array<string>;
  enabled?: boolean;
  booksSinceZ?: string;
  propertyIds?: Array<string>;
  unitIds?: Array<string>;
  preferredPaymentMethod?: PaymentMethod;
  padAmount?: number;
  profileId?: string;
  autopayClientId?: string;
  autopayStatus?: string;
  address?: {
    __typename?: 'Address';
    suite?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
  };
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    createdName?: string;
    createdSub?: string;
    createdZ?: string;
    text?: string;
  }>;
  propertyHeaders?: Array<{
    __typename?: 'PropertyHeader';
    id: string;
    name?: string;
    key?: string;
  }>;
  unitHeaders?: Array<{
    __typename?: 'UnitHeader';
    id: string;
    propertyId: string;
    propertyName?: string;
    name?: string;
    occupancy?: UnitOccupancy;
  }>;
  history?: Array<{
    __typename?: 'OwnerHistoryEntry';
    assetType: AssetType;
    assetId: string;
    leasedUnits?: number;
    commercialLeasedUnits?: number;
  }>;
  units?: Array<{
    __typename?: 'Unit';
    id: string;
    paymentDetails?: {
      __typename?: 'PaymentDetails';
      preferredPaymentMethod?: PaymentMethod;
      padAmount?: number;
      padInstitution?: string;
      padTransit?: string;
      padAccount?: string;
    };
  }>;
};

export type PresetFieldsFragment = {
  __typename?: 'Preset';
  id: string;
  type: PresetType;
  version?: BooksReportVersion;
  category?: PresetCategory;
  name: string;
  visibleExpression?: string;
  description: string;
  lastPosted?: string;
  roles?: Array<ManagerRole>;
  group?: { __typename?: 'PresetGroup'; name: string; order?: number };
  glMapping?: Array<{
    __typename?: 'GLMapping';
    id: string;
    parentId: string;
    name: string;
    description: string;
    default: string;
    filter?: string;
    glId?: string;
  }>;
  inputFields?: Array<{
    __typename?: 'PresetInputField';
    id: string;
    label: string;
    type: BooksFieldType;
    required?: boolean;
    defaultExpression?: string;
    options?: Array<{ __typename?: 'PresetOption'; label: string; value: string }>;
  }>;
  recurrence?: {
    __typename?: 'PresetRecurrence';
    enabled: boolean;
    locked?: boolean;
    rrule: string;
    postedOffset: number;
    parameters: string;
  };
  icon?: { __typename?: 'PresetIcon'; name?: string; color?: string };
};

export type AmenityFieldsFragment = {
  __typename?: 'Amenity';
  id?: string;
  name?: string;
  category?: string;
};

export type AmenitiesSettingFieldsFragment = {
  __typename?: 'AmenitiesSetting';
  amenities?: Array<{ __typename?: 'Amenity'; id?: string; name?: string; category?: string }>;
};

export type PropertyEntityFieldsFragment = {
  __typename?: 'Property';
  id: string;
  ownerId?: string;
  enabled?: boolean;
  name: string;
  key?: string;
  leasedUnits?: number;
  totalUnits?: number;
  totalUnitFactor?: number;
  commonAreas?: number;
  ownerUnits?: number;
  commercialLeasedUnits?: number;
  condoUnits?: number;
  imageKey?: string;
  timezone: string;
  commercialUnits?: number;
  rentalUnits?: number;
  phone?: string;
  types?: Array<PropertyType>;
  supplierIds?: Array<string>;
  contractorIds?: Array<string>;
  legalDescription?: string;
  creatingUnits?: number;
  houseRules?: Array<string>;
  autopayCollectionsEnabled?: boolean;
  rentalLeaseDocumentTemplateId?: string;
  commercialLeaseDocumentTemplateId?: string;
  address: {
    __typename?: 'Address';
    suite?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    country?: string;
    lat?: number;
    lng?: number;
  };
  parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
  storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
  keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
  onsiteManager?: { __typename?: 'OnsiteManager'; id: string; entityType: OnsiteManagerType };
  owner?: { __typename?: 'Owner'; id: string; name: string };
};

export type PropertyFieldsFragment = {
  __typename?: 'Property';
  id: string;
  ownerId?: string;
  enabled?: boolean;
  name: string;
  key?: string;
  leasedUnits?: number;
  totalUnits?: number;
  totalUnitFactor?: number;
  commonAreas?: number;
  ownerUnits?: number;
  commercialLeasedUnits?: number;
  condoUnits?: number;
  imageKey?: string;
  timezone: string;
  commercialUnits?: number;
  rentalUnits?: number;
  phone?: string;
  types?: Array<PropertyType>;
  supplierIds?: Array<string>;
  contractorIds?: Array<string>;
  legalDescription?: string;
  creatingUnits?: number;
  houseRules?: Array<string>;
  autopayCollectionsEnabled?: boolean;
  rentalLeaseDocumentTemplateId?: string;
  commercialLeaseDocumentTemplateId?: string;
  buildings?: Array<{
    __typename?: 'Building';
    id: string;
    name: string;
    key?: string;
    imageKey?: string;
    timezone: string;
    phone?: string;
    ownerId?: string;
    types?: Array<PropertyType>;
    legalDescription?: string;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      lat?: number;
      lng?: number;
    };
  }>;
  units?: Array<{
    __typename?: 'Unit';
    parking?: Array<string>;
    storage?: Array<string>;
    keyring?: Array<string>;
    id: string;
    name: string;
    legalAddress?: string;
    sqft: number;
    bedrooms: number;
    bathrooms: number;
    additionalInformation?: string;
    occupancy?: UnitOccupancy;
    unitFactor?: number;
    condoFee?: number;
    condoFeeGst?: number;
    preferredPaymentMethod?: PaymentMethod;
    propertyId?: string;
    ownerId?: string;
    buildingId?: string;
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      email?: string;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
    };
    property?: {
      __typename?: 'Property';
      id: string;
      enabled?: boolean;
      name: string;
      phone?: string;
      imageKey?: string;
      timezone: string;
      ownerId?: string;
      storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
      parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
      keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
    building?: {
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    };
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    history?: Array<
      | {
          __typename?: 'Request';
          summary?: string;
          status?: string;
          delay?: string;
          id: string;
          name?: string;
          tenant?: { __typename?: 'RequestTenant'; name?: string };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
      | {
          __typename?: 'RequestEvent';
          id: string;
          name?: string;
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
    >;
  }>;
  address: {
    __typename?: 'Address';
    suite?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    country?: string;
    lat?: number;
    lng?: number;
  };
  parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
  storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
  keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
  onsiteManager?: { __typename?: 'OnsiteManager'; id: string; entityType: OnsiteManagerType };
  owner?: { __typename?: 'Owner'; id: string; name: string };
};

export type PropertyListFieldsFragment = {
  __typename?: 'Property';
  id: string;
  ownerId?: string;
  enabled?: boolean;
  name: string;
  key?: string;
  leasedUnits?: number;
  totalUnits?: number;
  totalUnitFactor?: number;
  commonAreas?: number;
  ownerUnits?: number;
  commercialLeasedUnits?: number;
  condoUnits?: number;
  imageKey?: string;
  timezone: string;
  commercialUnits?: number;
  rentalUnits?: number;
  phone?: string;
  types?: Array<PropertyType>;
  supplierIds?: Array<string>;
  contractorIds?: Array<string>;
  legalDescription?: string;
  creatingUnits?: number;
  houseRules?: Array<string>;
  autopayCollectionsEnabled?: boolean;
  rentalLeaseDocumentTemplateId?: string;
  commercialLeaseDocumentTemplateId?: string;
  buildings?: Array<{ __typename?: 'Building'; id: string; name: string }>;
  address: {
    __typename?: 'Address';
    suite?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    country?: string;
    lat?: number;
    lng?: number;
  };
  parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
  storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
  keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
  onsiteManager?: { __typename?: 'OnsiteManager'; id: string; entityType: OnsiteManagerType };
  owner?: { __typename?: 'Owner'; id: string; name: string };
};

export type PropertySpecsFieldsFragment = {
  __typename?: 'PropertySpecs';
  key: PropertySpecTypes;
  value: string;
};

export type RecurrenceFieldsFragment = {
  __typename?: 'Recurrence';
  id: string;
  name: string;
  rrule: string;
  lastPosted?: string;
  output: {
    __typename?: 'RecurrenceOutput';
    lines: Array<{
      __typename?: 'JournalEntryLine';
      glId: string;
      propertyId: string;
      unitId?: string;
      ownerId: string;
      description?: string;
      amount: number;
      payee?: PayeeType;
      payeeId?: string;
    }>;
    clearable?: {
      __typename?: 'RecurrenceClearableConfig';
      payee: string;
      payeeId: string;
      postedForDue?: boolean;
    };
  };
};

export type RegionTaxDetailsFieldsFragment = {
  __typename?: 'RegionTax';
  id: string;
  region: string;
  country: string;
  defaultTaxes: Array<string>;
  taxes?: Array<{
    __typename?: 'Tax';
    id: string;
    label: string;
    rate: number;
    gst?: boolean;
    postals?: Array<string>;
  }>;
};

export type RequestFieldsFragment = {
  __typename?: 'Request';
  id: string;
  name?: string;
  summary?: string;
  category?: string;
  ownerApprovalId?: string;
  ownerApprovalStatus?: string;
  status?: string;
  cancelledReason?: string;
  delay?: string;
  originalRequest?: string;
  details?: string;
  appointmentZ?: string;
  submittedZ?: string;
  operatorName?: string;
  minutes?: number;
  instructions?: string;
  firstOccurred?: string;
  consents?: Array<string>;
  entryConsentDetails?: string;
  nonBillable?: boolean;
  printNotes?: boolean;
  autoSummary?: string;
  resolution?: string;
  finalizedName?: string;
  finalizedZ?: string;
  source?: string;
  taxExempted?: boolean;
  journalId?: string;
  approval?: {
    __typename?: 'MaintenanceApproval';
    id: string;
    status: ApprovalStatus;
    requestedZ: string;
    approvedZ?: string;
    declinedZ?: string;
    request: {
      __typename?: 'MaintenanceApprovalRequest';
      ownerId: string;
      amount: number;
      description?: string;
    };
  };
  fields?: Array<{ __typename?: 'Field'; label?: string; value?: string }>;
  visits?: Array<{
    __typename?: 'Visit';
    id?: string;
    status?: string;
    delay?: string;
    dueZ?: string;
    appointmentZ?: string;
    operatorId?: string;
    operatorName?: string;
    minutes?: number;
    tenantProposedTimes?: Array<string>;
    category?: string;
    instructions?: string;
    materialCosts?: number;
    materialCostsNote?: string;
    materialMarkup?: boolean;
    labourCost?: number;
    completedDelta?: number;
    completedZ?: string;
    journalId?: string;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    bill?: {
      __typename?: 'ContractorBill';
      date: string;
      totalAmount: number;
      ref?: string;
      due?: string;
      gstAmount?: number;
      key?: string;
    };
  }>;
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    target?: string;
    source?: string;
    createdName?: string;
    createdSub?: string;
    createdZ?: string;
    text?: string;
  }>;
  images?: Array<{
    __typename?: 'Image';
    key?: string;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
  }>;
  history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
  appointmentPreferences?: Array<{ __typename?: 'Interval'; start: string }>;
  operator?: {
    __typename?: 'Operator';
    id: string;
    name: string;
    location?: string;
    email?: string;
    phone?: string;
    availability?: Array<string>;
    operatorType?: OperatorType;
  };
  tenant?: { __typename?: 'RequestTenant'; id?: string; name?: string; phone?: string };
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    legalAddress?: string;
    sqft: number;
    bedrooms: number;
    bathrooms: number;
    additionalInformation?: string;
    occupancy?: UnitOccupancy;
    parking?: Array<string>;
    storage?: Array<string>;
    keyring?: Array<string>;
    unitFactor?: number;
    condoFee?: number;
    condoFeeGst?: number;
    preferredPaymentMethod?: PaymentMethod;
    propertyId?: string;
    ownerId?: string;
    buildingId?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      enabled?: boolean;
      name: string;
      phone?: string;
      imageKey?: string;
      timezone: string;
      ownerId?: string;
      storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
      parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
      keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
    building?: {
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    };
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      email?: string;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
    };
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    history?: Array<
      | {
          __typename?: 'Request';
          summary?: string;
          status?: string;
          delay?: string;
          id: string;
          name?: string;
          tenant?: { __typename?: 'RequestTenant'; name?: string };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
      | {
          __typename?: 'RequestEvent';
          id: string;
          name?: string;
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
    >;
  };
  costs?: Array<{
    __typename?: 'RequestCost';
    id: string;
    date: string;
    description: string;
    amount: number;
    markup: boolean;
  }>;
  checklist?: Array<{
    __typename?: 'ChecklistItem';
    id: string;
    name: string;
    completedZ?: string;
    order?: number;
  }>;
  fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string; createdSub?: string };
};

export type RequestListFieldsFragment = {
  __typename?: 'Request';
  id: string;
  name?: string;
  summary?: string;
  category?: string;
  ownerApprovalId?: string;
  ownerApprovalStatus?: string;
  status?: string;
  delay?: string;
  submittedZ?: string;
  finalizedZ?: string;
  visits?: Array<{
    __typename?: 'Visit';
    id?: string;
    status?: string;
    delay?: string;
    dueZ?: string;
    appointmentZ?: string;
    operatorId?: string;
    operatorName?: string;
    minutes?: number;
    tenantProposedTimes?: Array<string>;
    category?: string;
    instructions?: string;
    materialCosts?: number;
    materialCostsNote?: string;
    materialMarkup?: boolean;
    labourCost?: number;
    completedDelta?: number;
    completedZ?: string;
    journalId?: string;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    bill?: {
      __typename?: 'ContractorBill';
      date: string;
      totalAmount: number;
      ref?: string;
      due?: string;
      gstAmount?: number;
      key?: string;
    };
  }>;
  occupant?: { __typename?: 'RequestTenant'; name?: string };
  tenant?: { __typename?: 'RequestTenant'; name?: string };
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    occupancy?: UnitOccupancy;
    propertyId?: string;
    ownerId?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
  };
  fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
};

export type ServerRequestListFieldsFragment = {
  __typename?: 'Request';
  id: string;
  name?: string;
  summary?: string;
  category?: string;
  status?: string;
  delay?: string;
  ownerId?: string;
  ownerApprovalId?: string;
  ownerApprovalStatus?: string;
  finalizedZ?: string;
  openedZ: string;
  tenantId?: string;
  tenantName?: string;
  unitId?: string;
  unitName?: string;
  unitOccupancy?: UnitOccupancy;
  buildingId?: string;
  buildingKey?: string;
  buildingName?: string;
  propertyId?: string;
  propertyKey?: string;
  propertyName?: string;
  occupant?: { __typename?: 'RequestTenant'; name?: string };
  visits?: Array<{
    __typename?: 'Visit';
    id?: string;
    status?: string;
    delay?: string;
    dueZ?: string;
    appointmentZ?: string;
    operatorId?: string;
    operatorName?: string;
    minutes?: number;
    tenantProposedTimes?: Array<string>;
    category?: string;
    instructions?: string;
    materialCosts?: number;
    materialCostsNote?: string;
    materialMarkup?: boolean;
    labourCost?: number;
    completedDelta?: number;
    completedZ?: string;
    journalId?: string;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    bill?: {
      __typename?: 'ContractorBill';
      date: string;
      totalAmount: number;
      ref?: string;
      due?: string;
      gstAmount?: number;
      key?: string;
    };
  }>;
  buildingAddress?: {
    __typename?: 'Address';
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
  };
  propertyAddress?: {
    __typename?: 'Address';
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
  };
};

export type ResidencyEntityFieldsFragment = {
  __typename?: 'Residency';
  id: string;
  terms?: Array<string>;
  startZ: string;
  endZ?: string;
  renewZ?: string;
  securityDeposit?: number;
  gst?: number;
  type?: LeaseType;
  occupancy?: UnitOccupancy;
  unitId: string;
  propertyId: string;
  moveInDate?: string;
  moveOutDate?: string;
  moveOutReason?: MoveOutReason;
  applicationId?: string;
  campaignId?: string;
  rrule?: string;
  utilities?: Array<string>;
  furnishings?: Array<string>;
  collectionDay?: number;
  securityDepositHistory?: Array<{
    __typename?: 'SecurityDepositHistory';
    createdZ: string;
    allocations: Array<{
      __typename?: 'SecurityDepositAllocation';
      status: SecurityDepositStatus;
      amount: number;
    }>;
  }>;
  eventHistory?: Array<{
    __typename?: 'ResidencyEvent';
    id: string;
    name: string;
    category: ResidencyEventCategory;
    description?: string;
    eventDateZ: string;
    deletedZ?: string;
    resolvedZ?: string;
    noticeZ?: string;
    noteText?: string;
  }>;
  insurancePolicies?: Array<{
    __typename?: 'LeaseInsurancePolicy';
    id?: string;
    policyNumber?: string;
    providerName?: string;
    expirationDate?: string;
    liability?: number;
  }>;
  residentInsurancePolicies?: Array<{
    __typename?: 'IamTenantInsurancePolicy';
    id: string;
    policyNumber: string;
    providerName: string;
    liability?: number;
    expiry: string;
  }>;
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    target?: string;
    source?: string;
    createdName?: string;
    createdSub?: string;
    createdZ?: string;
    text?: string;
  }>;
  additionalProvisions?: {
    __typename?: 'AdditionalProvisions';
    pets?: number;
    parking?: boolean;
    storage?: boolean;
    smoking?: boolean;
  };
};

export type ResidencyFieldsFragment = {
  __typename?: 'Residency';
  id: string;
  accountId: string;
  proratedClearableIds?: Array<string>;
  moveInInspectionId?: string;
  moveOutInspectionId?: string;
  terms?: Array<string>;
  startZ: string;
  endZ?: string;
  renewZ?: string;
  securityDeposit?: number;
  gst?: number;
  type?: LeaseType;
  occupancy?: UnitOccupancy;
  unitId: string;
  propertyId: string;
  moveInDate?: string;
  moveOutDate?: string;
  moveOutReason?: MoveOutReason;
  applicationId?: string;
  campaignId?: string;
  rrule?: string;
  utilities?: Array<string>;
  furnishings?: Array<string>;
  collectionDay?: number;
  effects?: Array<
    | {
        __typename: 'IncentiveEffect';
        description?: string;
        incentiveId: string;
        id: string;
        feeId: string;
        startZ: string;
        effect: number;
      }
    | { __typename: 'ResidencyEffect'; id: string; feeId: string; startZ: string; effect: number }
  >;
  residents: Array<{
    __typename?: 'ResidentAssociation';
    id: string;
    residentId: string;
    leaseHolder?: boolean;
    title?: ResidencyResidentTitle;
    payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
    paymentDetails?: {
      __typename?: 'PaymentDetails';
      preferredPaymentMethod?: PaymentMethod;
      padAmount?: number;
      padInstitution?: string;
      padTransit?: string;
      padAccount?: string;
    };
    resident:
      | {
          __typename: 'Owner';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
          id: string;
          name: string;
          emails?: Array<string>;
          phones?: Array<string>;
          correspondenceMethod?: CorrespondenceMethod;
          enabled?: boolean;
          emergencyContacts?: Array<{
            __typename?: 'EmergencyContact';
            name: string;
            relationship?: ContactRelationship;
            phone: string;
          }>;
        }
      | {
          __typename: 'Tenant';
          phone?: string;
          dob?: string;
          isRentalAssist?: boolean;
          id: string;
          name: string;
          emails?: Array<string>;
          phones?: Array<string>;
          correspondenceMethod?: CorrespondenceMethod;
          enabled?: boolean;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
          rentalAssistance?: {
            __typename?: 'RentalAssistance';
            qualified?: boolean;
            amount?: number;
            source?: string;
          };
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          mailingAddress?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
          emergencyContacts?: Array<{
            __typename?: 'EmergencyContact';
            name: string;
            relationship?: ContactRelationship;
            phone: string;
          }>;
        };
  }>;
  refundables?: Array<{
    __typename?: 'Refundable';
    id: string;
    name: string;
    amount?: number;
    templateId: string;
    accrualStartExpr?: string;
    collectedZ?: string;
    heldByOwner?: boolean;
    ledger?: Array<{
      __typename?: 'RefundableLedger';
      id: string;
      category: RefundableLedgerCategory;
      amount: number;
      date: string;
    }>;
  }>;
  securityDepositHistory?: Array<{
    __typename?: 'SecurityDepositHistory';
    createdZ: string;
    allocations: Array<{
      __typename?: 'SecurityDepositAllocation';
      status: SecurityDepositStatus;
      amount: number;
    }>;
  }>;
  eventHistory?: Array<{
    __typename?: 'ResidencyEvent';
    id: string;
    name: string;
    category: ResidencyEventCategory;
    description?: string;
    eventDateZ: string;
    deletedZ?: string;
    resolvedZ?: string;
    noticeZ?: string;
    noteText?: string;
  }>;
  insurancePolicies?: Array<{
    __typename?: 'LeaseInsurancePolicy';
    id?: string;
    policyNumber?: string;
    providerName?: string;
    expirationDate?: string;
    liability?: number;
  }>;
  residentInsurancePolicies?: Array<{
    __typename?: 'IamTenantInsurancePolicy';
    id: string;
    policyNumber: string;
    providerName: string;
    liability?: number;
    expiry: string;
  }>;
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    target?: string;
    source?: string;
    createdName?: string;
    createdSub?: string;
    createdZ?: string;
    text?: string;
  }>;
  additionalProvisions?: {
    __typename?: 'AdditionalProvisions';
    pets?: number;
    parking?: boolean;
    storage?: boolean;
    smoking?: boolean;
  };
};

export type RoutineInspectionFieldsFragment = {
  __typename?: 'Inspection';
  id: string;
  status?: InspectionStatus;
  completedZ?: string;
  templateId: string;
  category?: InspectionCategory;
  description?: string;
  items?: Array<{
    __typename?: 'InspectionItem';
    id: string;
    referenceId?: string;
    label: string;
    description?: string;
    category: InspectionItemCategory;
    wasInspected?: boolean;
    fields: Array<{
      __typename?: 'InspectionField';
      id: string;
      name: string;
      label?: string;
      description?: string;
      type: string;
      value?: string;
      noteText?: string;
      needsRequest?: boolean;
      inspectedZ?: string;
      images?: Array<{ __typename?: 'Image'; key?: string }>;
    }>;
  }>;
  signatures?: Array<{
    __typename?: 'Signature';
    id?: string;
    signature: string;
    signerName: string;
    signerEntity: SignerEntity;
    isAgent?: boolean;
    agentRelationship?: string;
  }>;
  generatedSummary?: { __typename?: 'InspectionSummary'; summary?: string; score?: number };
};

export type SignatureFieldsFragment = {
  __typename?: 'Signature';
  id?: string;
  signature: string;
  signerName: string;
  signerEntity: SignerEntity;
  isAgent?: boolean;
  agentRelationship?: string;
};

export type SupplierFieldsFragment = {
  __typename?: 'Supplier';
  id: string;
  accountId: string;
  name: string;
  contactName?: string;
  type?: string;
  phone?: string;
  fax?: string;
  email?: string;
  enabled: boolean;
  location?: string;
  deletedZ?: string;
  verificationRequestedZ?: string;
  verifiedZ?: string;
  website?: string;
  coi?: boolean;
  coiExpiry?: string;
  billingEmail?: string;
  businessNumber?: string;
  autopayInstitution?: string;
  autopayTransit?: string;
  autopayAccount?: string;
  autopayStatus?: string;
  autopayStatusUpdatedZ?: string;
  autopayClientId?: string;
  expenseGlId?: string;
  address?: {
    __typename?: 'Address';
    suite?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
  };
  fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
  supplierAccounts?: Array<{ __typename?: 'SupplierAccount'; id: string; accountNumber: string }>;
};

export type TemplateFieldsFragment = {
  __typename?: 'Template';
  id: string;
  enabled?: boolean;
  default?: boolean;
  category?: string;
  minutes?: number;
  response?: string;
  fields?: Array<{
    __typename?: 'Field';
    label?: string;
    type?: string;
    info?: string;
    imageKey?: string;
  }>;
};

export type TenantFieldsFragment = {
  __typename?: 'Tenant';
  id: string;
  name: string;
  emails?: Array<string>;
  phone?: string;
  phones?: Array<string>;
  leaseHolder?: boolean;
  unitId?: string;
  propertyId?: string;
  type?: string;
  isOwner?: boolean;
  preferredPaymentMethod?: PaymentMethod;
  padAmount?: number;
  padInstitution?: string;
  padTransit?: string;
  padAccount?: string;
  correspondenceMethod?: CorrespondenceMethod;
  profileId?: string;
  enabled?: boolean;
  autopayClientId?: string;
  autopayInstitution?: string;
  autopayTransit?: string;
  autopayAccount?: string;
  autopayStatus?: string;
  autopayInvitedZ?: string;
  autopayCollectionsEnabled?: boolean;
  emergencyContacts?: Array<{
    __typename?: 'EmergencyContact';
    name: string;
    phone: string;
    relationship?: ContactRelationship;
  }>;
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    createdName?: string;
    createdSub?: string;
    createdZ?: string;
    text?: string;
  }>;
  pets?: Array<{
    __typename?: 'Pet';
    type: string;
    count: number;
    details?: Array<{
      __typename?: 'PetDetails';
      type?: string;
      breed?: string;
      weight?: string;
      name?: string;
      color?: string;
      license?: string;
      isService?: boolean;
    }>;
  }>;
  vehicles?: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    make: string;
    model: string;
    color?: string;
  }>;
};

export type UnitEntityFieldsFragment = {
  __typename?: 'Unit';
  id: string;
  name: string;
  legalAddress?: string;
  sqft: number;
  bedrooms: number;
  bathrooms: number;
  additionalInformation?: string;
  occupancy?: UnitOccupancy;
  parking?: Array<string>;
  storage?: Array<string>;
  keyring?: Array<string>;
  unitFactor?: number;
  condoFee?: number;
  condoFeeGst?: number;
  preferredPaymentMethod?: PaymentMethod;
  propertyId?: string;
  ownerId?: string;
  buildingId?: string;
  condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    createdName?: string;
    createdSub?: string;
    createdZ?: string;
    text?: string;
  }>;
};

export type UnitFieldsFragment = {
  __typename?: 'Unit';
  id: string;
  name: string;
  legalAddress?: string;
  sqft: number;
  bedrooms: number;
  bathrooms: number;
  additionalInformation?: string;
  occupancy?: UnitOccupancy;
  parking?: Array<string>;
  storage?: Array<string>;
  keyring?: Array<string>;
  unitFactor?: number;
  condoFee?: number;
  condoFeeGst?: number;
  preferredPaymentMethod?: PaymentMethod;
  propertyId?: string;
  ownerId?: string;
  buildingId?: string;
  property?: {
    __typename?: 'Property';
    id: string;
    enabled?: boolean;
    name: string;
    phone?: string;
    imageKey?: string;
    timezone: string;
    ownerId?: string;
    storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
    parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
    keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      email?: string;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
    };
    address: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  };
  building?: {
    __typename?: 'Building';
    id: string;
    name: string;
    key?: string;
    imageKey?: string;
    timezone: string;
    phone?: string;
    ownerId?: string;
    types?: Array<PropertyType>;
    legalDescription?: string;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      lat?: number;
      lng?: number;
    };
  };
  owner?: {
    __typename?: 'Owner';
    id: string;
    name: string;
    email?: string;
    phones?: Array<string>;
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
  };
  condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    createdName?: string;
    createdSub?: string;
    createdZ?: string;
    text?: string;
  }>;
  history?: Array<
    | {
        __typename?: 'Request';
        summary?: string;
        status?: string;
        delay?: string;
        id: string;
        name?: string;
        tenant?: { __typename?: 'RequestTenant'; name?: string };
        fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      }
    | {
        __typename?: 'RequestEvent';
        id: string;
        name?: string;
        fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      }
  >;
};

export type UnitFieldsWithResidencyFragment = {
  __typename?: 'Unit';
  id: string;
  name: string;
  legalAddress?: string;
  sqft: number;
  bedrooms: number;
  bathrooms: number;
  additionalInformation?: string;
  occupancy?: UnitOccupancy;
  parking?: Array<string>;
  storage?: Array<string>;
  keyring?: Array<string>;
  unitFactor?: number;
  condoFee?: number;
  condoFeeGst?: number;
  preferredPaymentMethod?: PaymentMethod;
  propertyId?: string;
  ownerId?: string;
  buildingId?: string;
  allResidencies?: Array<{
    __typename?: 'Residency';
    id: string;
    accountId: string;
    proratedClearableIds?: Array<string>;
    moveInInspectionId?: string;
    moveOutInspectionId?: string;
    terms?: Array<string>;
    startZ: string;
    endZ?: string;
    renewZ?: string;
    securityDeposit?: number;
    gst?: number;
    type?: LeaseType;
    occupancy?: UnitOccupancy;
    unitId: string;
    propertyId: string;
    moveInDate?: string;
    moveOutDate?: string;
    moveOutReason?: MoveOutReason;
    applicationId?: string;
    campaignId?: string;
    rrule?: string;
    utilities?: Array<string>;
    furnishings?: Array<string>;
    collectionDay?: number;
    effects?: Array<
      | {
          __typename: 'IncentiveEffect';
          description?: string;
          incentiveId: string;
          id: string;
          feeId: string;
          startZ: string;
          effect: number;
        }
      | { __typename: 'ResidencyEffect'; id: string; feeId: string; startZ: string; effect: number }
    >;
    residents: Array<{
      __typename?: 'ResidentAssociation';
      id: string;
      residentId: string;
      leaseHolder?: boolean;
      title?: ResidencyResidentTitle;
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      paymentDetails?: {
        __typename?: 'PaymentDetails';
        preferredPaymentMethod?: PaymentMethod;
        padAmount?: number;
        padInstitution?: string;
        padTransit?: string;
        padAccount?: string;
      };
      resident:
        | {
            __typename: 'Owner';
            preferredPaymentMethod?: PaymentMethod;
            padAmount?: number;
            padInstitution?: string;
            padTransit?: string;
            padAccount?: string;
            id: string;
            name: string;
            emails?: Array<string>;
            phones?: Array<string>;
            correspondenceMethod?: CorrespondenceMethod;
            enabled?: boolean;
            emergencyContacts?: Array<{
              __typename?: 'EmergencyContact';
              name: string;
              relationship?: ContactRelationship;
              phone: string;
            }>;
          }
        | {
            __typename: 'Tenant';
            phone?: string;
            dob?: string;
            isRentalAssist?: boolean;
            id: string;
            name: string;
            emails?: Array<string>;
            phones?: Array<string>;
            correspondenceMethod?: CorrespondenceMethod;
            enabled?: boolean;
            vehicles?: Array<{
              __typename?: 'Vehicle';
              id: string;
              plate: string;
              make: string;
              model: string;
              color?: string;
            }>;
            rentalAssistance?: {
              __typename?: 'RentalAssistance';
              qualified?: boolean;
              amount?: number;
              source?: string;
            };
            pets?: Array<{
              __typename?: 'Pet';
              type: string;
              count: number;
              details?: Array<{
                __typename?: 'PetDetails';
                type?: string;
                breed?: string;
                weight?: string;
                name?: string;
                color?: string;
                license?: string;
                isService?: boolean;
              }>;
            }>;
            mailingAddress?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
              country?: string;
              lat?: number;
              lng?: number;
            };
            emergencyContacts?: Array<{
              __typename?: 'EmergencyContact';
              name: string;
              relationship?: ContactRelationship;
              phone: string;
            }>;
          };
    }>;
    refundables?: Array<{
      __typename?: 'Refundable';
      id: string;
      name: string;
      amount?: number;
      templateId: string;
      accrualStartExpr?: string;
      collectedZ?: string;
      heldByOwner?: boolean;
      ledger?: Array<{
        __typename?: 'RefundableLedger';
        id: string;
        category: RefundableLedgerCategory;
        amount: number;
        date: string;
      }>;
    }>;
    securityDepositHistory?: Array<{
      __typename?: 'SecurityDepositHistory';
      createdZ: string;
      allocations: Array<{
        __typename?: 'SecurityDepositAllocation';
        status: SecurityDepositStatus;
        amount: number;
      }>;
    }>;
    eventHistory?: Array<{
      __typename?: 'ResidencyEvent';
      id: string;
      name: string;
      category: ResidencyEventCategory;
      description?: string;
      eventDateZ: string;
      deletedZ?: string;
      resolvedZ?: string;
      noticeZ?: string;
      noteText?: string;
    }>;
    insurancePolicies?: Array<{
      __typename?: 'LeaseInsurancePolicy';
      id?: string;
      policyNumber?: string;
      providerName?: string;
      expirationDate?: string;
      liability?: number;
    }>;
    residentInsurancePolicies?: Array<{
      __typename?: 'IamTenantInsurancePolicy';
      id: string;
      policyNumber: string;
      providerName: string;
      liability?: number;
      expiry: string;
    }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    additionalProvisions?: {
      __typename?: 'AdditionalProvisions';
      pets?: number;
      parking?: boolean;
      storage?: boolean;
      smoking?: boolean;
    };
  }>;
  property?: {
    __typename?: 'Property';
    id: string;
    enabled?: boolean;
    name: string;
    phone?: string;
    imageKey?: string;
    timezone: string;
    ownerId?: string;
    storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
    parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
    keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      email?: string;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
    };
    address: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  };
  building?: {
    __typename?: 'Building';
    id: string;
    name: string;
    key?: string;
    imageKey?: string;
    timezone: string;
    phone?: string;
    ownerId?: string;
    types?: Array<PropertyType>;
    legalDescription?: string;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      lat?: number;
      lng?: number;
    };
  };
  owner?: {
    __typename?: 'Owner';
    id: string;
    name: string;
    email?: string;
    phones?: Array<string>;
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
  };
  condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    createdName?: string;
    createdSub?: string;
    createdZ?: string;
    text?: string;
  }>;
  history?: Array<
    | {
        __typename?: 'Request';
        summary?: string;
        status?: string;
        delay?: string;
        id: string;
        name?: string;
        tenant?: { __typename?: 'RequestTenant'; name?: string };
        fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      }
    | {
        __typename?: 'RequestEvent';
        id: string;
        name?: string;
        fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      }
  >;
};

export type UnitHistoryFieldsFragment = {
  __typename?: 'Unit';
  history?: Array<
    | {
        __typename?: 'Request';
        summary?: string;
        status?: string;
        delay?: string;
        id: string;
        name?: string;
        tenant?: { __typename?: 'RequestTenant'; name?: string };
        fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      }
    | {
        __typename?: 'RequestEvent';
        id: string;
        name?: string;
        fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      }
  >;
};

export type UnitListFieldsFragment = {
  __typename?: 'Unit';
  id: string;
  name: string;
  ownerId?: string;
  occupancy?: UnitOccupancy;
  floorplanId?: string;
  reserveAmount?: number;
  buildingId?: string;
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    key?: string;
    imageKey?: string;
    autopayCollectionsEnabled?: boolean;
  };
};

export type UnitListFieldsWithResidencyFragment = {
  __typename?: 'Unit';
  id: string;
  name: string;
  legalAddress?: string;
  ownerId?: string;
  occupancy?: UnitOccupancy;
  buildingId?: string;
  property?: { __typename?: 'Property'; id: string; name: string; key?: string; imageKey?: string };
  allResidencies?: Array<{
    __typename?: 'Residency';
    id: string;
    startZ: string;
    endZ?: string;
    residents: Array<{
      __typename?: 'ResidentAssociation';
      id: string;
      leaseHolder?: boolean;
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      resident:
        | { __typename: 'Owner'; email?: string; id: string; name: string; phones?: Array<string> }
        | {
            __typename: 'Tenant';
            emails?: Array<string>;
            id: string;
            name: string;
            phones?: Array<string>;
          };
    }>;
  }>;
};

export type VisitFieldsFragment = {
  __typename?: 'Visit';
  id?: string;
  status?: string;
  delay?: string;
  dueZ?: string;
  appointmentZ?: string;
  operatorId?: string;
  operatorName?: string;
  minutes?: number;
  tenantProposedTimes?: Array<string>;
  category?: string;
  instructions?: string;
  materialCosts?: number;
  materialCostsNote?: string;
  materialMarkup?: boolean;
  labourCost?: number;
  completedDelta?: number;
  completedZ?: string;
  journalId?: string;
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    target?: string;
    source?: string;
    createdName?: string;
    createdSub?: string;
    createdZ?: string;
    text?: string;
  }>;
  bill?: {
    __typename?: 'ContractorBill';
    date: string;
    totalAmount: number;
    ref?: string;
    due?: string;
    gstAmount?: number;
    key?: string;
  };
};

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;

export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact: {
    __typename?: 'ContactPayload';
    success?: boolean;
    error?: string;
    contact?: {
      __typename?: 'Contact';
      id: string;
      accountId: string;
      entityId: string;
      type?: ContactType;
      name: string;
      emails?: Array<string>;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
  };
};

export type UpdateContactMutationVariables = Exact<{
  input: UpdateContactInput;
}>;

export type UpdateContactMutation = {
  __typename?: 'Mutation';
  updateContact: {
    __typename?: 'ContactPayload';
    contact?: {
      __typename?: 'Contact';
      id: string;
      accountId: string;
      entityId: string;
      type?: ContactType;
      name: string;
      emails?: Array<string>;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
  };
};

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteContactMutation = {
  __typename?: 'Mutation';
  deleteContact: { __typename?: 'ContactPayload'; success?: boolean; error?: string };
};

export type GetContactQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetContactQuery = {
  __typename?: 'Query';
  contact?: {
    __typename?: 'Contact';
    id: string;
    accountId: string;
    entityId: string;
    type?: ContactType;
    name: string;
    emails?: Array<string>;
    phones?: Array<string>;
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  manager?: {
    __typename?: 'Manager';
    id: string;
    name: string;
    email: string;
    phone?: string;
    roles: Array<ManagerRole>;
    enabled: boolean;
    accountId?: string;
    account: { __typename?: 'Account'; id: string; name: string };
    signature?: {
      __typename?: 'Signature';
      id?: string;
      signerId?: string;
      signature: string;
      signerName: string;
      signerEntity: SignerEntity;
      isAgent?: boolean;
      agentRelationship?: string;
    };
    subscriptions?: Array<{
      __typename?: 'MessageSubscription';
      id: string;
      endpoints: Array<{
        __typename?: 'SubscriptionEndpoint';
        id: SubscriptionEndpointType;
        address: string;
      }>;
    }>;
  };
};

export type FeeFieldsFragment = { __typename?: 'Fee'; id: string; name: string; deletedZ?: string };

export type GetAccountFeesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountFeesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    fees?: Array<{ __typename?: 'Fee'; id: string; name: string; deletedZ?: string }>;
  };
};

export type DeleteAccountFeeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteAccountFeeMutation = {
  __typename?: 'Mutation';
  deleteFee?: {
    __typename?: 'AccountPayload';
    success?: boolean;
    error?: string;
    account?: {
      __typename?: 'Account';
      id: string;
      fees?: Array<{ __typename?: 'Fee'; id: string; name: string; deletedZ?: string }>;
    };
  };
};

export type UpdateAccountFeeMutationVariables = Exact<{
  input: UpdateFeeInput;
}>;

export type UpdateAccountFeeMutation = {
  __typename?: 'Mutation';
  updateFee?: {
    __typename?: 'AccountPayload';
    success?: boolean;
    error?: string;
    account?: {
      __typename?: 'Account';
      id: string;
      fees?: Array<{ __typename?: 'Fee'; id: string; name: string; deletedZ?: string }>;
    };
  };
};

export type CreateAccountFeeMutationVariables = Exact<{
  input: CreateFeeInput;
}>;

export type CreateAccountFeeMutation = {
  __typename?: 'Mutation';
  createFee?: {
    __typename?: 'AccountPayload';
    success?: boolean;
    error?: string;
    account?: {
      __typename?: 'Account';
      id: string;
      fees?: Array<{ __typename?: 'Fee'; id: string; name: string; deletedZ?: string }>;
    };
  };
};

export type GetProvinceTaxDetailsQueryVariables = Exact<{
  input: GetProvinceTaxDetailsInput;
}>;

export type GetProvinceTaxDetailsQuery = {
  __typename?: 'Query';
  getProvinceTaxDetails?: {
    __typename?: 'ProvinceTaxDetailsPayload';
    success?: boolean;
    error?: string;
    taxDetails?: Array<{ __typename?: 'TaxDetail'; label: string; rate?: number; gst?: boolean }>;
  };
};

export type GetOwnerNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOwnerNameQuery = {
  __typename?: 'Query';
  owner?: { __typename?: 'Owner'; id: string; name: string };
};

export type GetOwnerNamesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetOwnerNamesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listOwners?: {
      __typename?: 'OwnerConnection';
      nextToken?: string;
      items?: Array<{ __typename?: 'Owner'; id: string; name: string }>;
    };
  };
};

export type ListAllPropertiesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PropertiesFilter>;
}>;

export type ListAllPropertiesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listProperties?: {
      __typename?: 'PropertyConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Property';
        id: string;
        ownerId?: string;
        enabled?: boolean;
        name: string;
        key?: string;
        leasedUnits?: number;
        totalUnits?: number;
        totalUnitFactor?: number;
        commonAreas?: number;
        ownerUnits?: number;
        commercialLeasedUnits?: number;
        condoUnits?: number;
        imageKey?: string;
        timezone: string;
        commercialUnits?: number;
        rentalUnits?: number;
        phone?: string;
        types?: Array<PropertyType>;
        supplierIds?: Array<string>;
        contractorIds?: Array<string>;
        legalDescription?: string;
        creatingUnits?: number;
        houseRules?: Array<string>;
        autopayCollectionsEnabled?: boolean;
        rentalLeaseDocumentTemplateId?: string;
        commercialLeaseDocumentTemplateId?: string;
        buildings?: Array<{ __typename?: 'Building'; id: string; name: string }>;
        address: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
        parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
        storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
        keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
        onsiteManager?: { __typename?: 'OnsiteManager'; id: string; entityType: OnsiteManagerType };
        owner?: { __typename?: 'Owner'; id: string; name: string };
      }>;
    };
  };
};

export type GetRegionTaxDetailsQueryVariables = Exact<{
  input: GetRegionTaxDetailsInput;
}>;

export type GetRegionTaxDetailsQuery = {
  __typename?: 'Query';
  getRegionTaxDetails?: {
    __typename?: 'RegionTaxDetailsPayload';
    success?: boolean;
    taxDetails?: Array<{ __typename?: 'TaxDetail'; label: string; rate?: number; gst?: boolean }>;
  };
};

export type GetRequestsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetRequestsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listRequests?: {
      __typename?: 'RequestConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Request';
        id: string;
        name?: string;
        summary?: string;
        category?: string;
        ownerApprovalId?: string;
        ownerApprovalStatus?: string;
        status?: string;
        delay?: string;
        submittedZ?: string;
        finalizedZ?: string;
        visits?: Array<{
          __typename?: 'Visit';
          id?: string;
          status?: string;
          delay?: string;
          dueZ?: string;
          appointmentZ?: string;
          operatorId?: string;
          operatorName?: string;
          minutes?: number;
          tenantProposedTimes?: Array<string>;
          category?: string;
          instructions?: string;
          materialCosts?: number;
          materialCostsNote?: string;
          materialMarkup?: boolean;
          labourCost?: number;
          completedDelta?: number;
          completedZ?: string;
          journalId?: string;
          notes?: Array<{
            __typename?: 'Note';
            id: string;
            target?: string;
            source?: string;
            createdName?: string;
            createdSub?: string;
            createdZ?: string;
            text?: string;
          }>;
          bill?: {
            __typename?: 'ContractorBill';
            date: string;
            totalAmount: number;
            ref?: string;
            due?: string;
            gstAmount?: number;
            key?: string;
          };
        }>;
        occupant?: { __typename?: 'RequestTenant'; name?: string };
        tenant?: { __typename?: 'RequestTenant'; name?: string };
        unit?: {
          __typename?: 'Unit';
          id: string;
          name: string;
          occupancy?: UnitOccupancy;
          propertyId?: string;
          ownerId?: string;
          property?: {
            __typename?: 'Property';
            id: string;
            name: string;
            address: {
              __typename?: 'Address';
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
          };
        };
        fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      }>;
    };
  };
};

export type GetRequestHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetRequestHeaderQuery = {
  __typename?: 'Query';
  request?: { __typename?: 'Request'; id: string; name?: string };
};

export type CreateSupplierMutationVariables = Exact<{
  input: SupplierInput;
}>;

export type CreateSupplierMutation = {
  __typename?: 'Mutation';
  createSupplier: {
    __typename?: 'SupplierPayload';
    success?: boolean;
    error?: string;
    supplier?: {
      __typename?: 'Supplier';
      id: string;
      accountId: string;
      name: string;
      contactName?: string;
      type?: string;
      phone?: string;
      fax?: string;
      email?: string;
      enabled: boolean;
      location?: string;
      deletedZ?: string;
      verificationRequestedZ?: string;
      verifiedZ?: string;
      website?: string;
      coi?: boolean;
      coiExpiry?: string;
      billingEmail?: string;
      businessNumber?: string;
      autopayInstitution?: string;
      autopayTransit?: string;
      autopayAccount?: string;
      autopayStatus?: string;
      autopayStatusUpdatedZ?: string;
      autopayClientId?: string;
      expenseGlId?: string;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      supplierAccounts?: Array<{
        __typename?: 'SupplierAccount';
        id: string;
        accountNumber: string;
      }>;
    };
  };
};

export type LinkSupplierMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input?: InputMaybe<LinkSupplierInput>;
}>;

export type LinkSupplierMutation = {
  __typename?: 'Mutation';
  linkSupplier: {
    __typename?: 'SupplierPayload';
    success?: boolean;
    error?: string;
    supplier?: {
      __typename?: 'Supplier';
      id: string;
      accountId: string;
      name: string;
      contactName?: string;
      type?: string;
      phone?: string;
      fax?: string;
      email?: string;
      enabled: boolean;
      location?: string;
      deletedZ?: string;
      verificationRequestedZ?: string;
      verifiedZ?: string;
      website?: string;
      coi?: boolean;
      coiExpiry?: string;
      billingEmail?: string;
      businessNumber?: string;
      autopayInstitution?: string;
      autopayTransit?: string;
      autopayAccount?: string;
      autopayStatus?: string;
      autopayStatusUpdatedZ?: string;
      autopayClientId?: string;
      expenseGlId?: string;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      supplierAccounts?: Array<{
        __typename?: 'SupplierAccount';
        id: string;
        accountNumber: string;
      }>;
    };
  };
};

export type UpdateSupplierMutationVariables = Exact<{
  input: SupplierInput;
}>;

export type UpdateSupplierMutation = {
  __typename?: 'Mutation';
  updateSupplier: {
    __typename?: 'SupplierPayload';
    success?: boolean;
    error?: string;
    supplier?: {
      __typename?: 'Supplier';
      id: string;
      accountId: string;
      name: string;
      contactName?: string;
      type?: string;
      phone?: string;
      fax?: string;
      email?: string;
      enabled: boolean;
      location?: string;
      deletedZ?: string;
      verificationRequestedZ?: string;
      verifiedZ?: string;
      website?: string;
      coi?: boolean;
      coiExpiry?: string;
      billingEmail?: string;
      businessNumber?: string;
      autopayInstitution?: string;
      autopayTransit?: string;
      autopayAccount?: string;
      autopayStatus?: string;
      autopayStatusUpdatedZ?: string;
      autopayClientId?: string;
      expenseGlId?: string;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      supplierAccounts?: Array<{
        __typename?: 'SupplierAccount';
        id: string;
        accountNumber: string;
      }>;
    };
  };
};

export type GetSuppliersQueryVariables = Exact<{ [key: string]: never }>;

export type GetSuppliersQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    suppliers?: Array<{
      __typename?: 'Supplier';
      id: string;
      accountId: string;
      name: string;
      contactName?: string;
      type?: string;
      phone?: string;
      fax?: string;
      email?: string;
      enabled: boolean;
      location?: string;
      deletedZ?: string;
      verificationRequestedZ?: string;
      verifiedZ?: string;
      website?: string;
      coi?: boolean;
      coiExpiry?: string;
      billingEmail?: string;
      businessNumber?: string;
      autopayInstitution?: string;
      autopayTransit?: string;
      autopayAccount?: string;
      autopayStatus?: string;
      autopayStatusUpdatedZ?: string;
      autopayClientId?: string;
      expenseGlId?: string;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      supplierAccounts?: Array<{
        __typename?: 'SupplierAccount';
        id: string;
        accountNumber: string;
      }>;
    }>;
  };
};

export type SupplierQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SupplierQuery = {
  __typename?: 'Query';
  supplier?: {
    __typename?: 'Supplier';
    id: string;
    accountId: string;
    name: string;
    contactName?: string;
    type?: string;
    phone?: string;
    fax?: string;
    email?: string;
    enabled: boolean;
    location?: string;
    deletedZ?: string;
    verificationRequestedZ?: string;
    verifiedZ?: string;
    website?: string;
    coi?: boolean;
    coiExpiry?: string;
    billingEmail?: string;
    businessNumber?: string;
    autopayInstitution?: string;
    autopayTransit?: string;
    autopayAccount?: string;
    autopayStatus?: string;
    autopayStatusUpdatedZ?: string;
    autopayClientId?: string;
    expenseGlId?: string;
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
    fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
    supplierAccounts?: Array<{ __typename?: 'SupplierAccount'; id: string; accountNumber: string }>;
  };
};

export type ListSuppliersQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SuppliersFilter>;
}>;

export type ListSuppliersQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listSuppliers?: {
      __typename?: 'SuppliersConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Supplier';
        id: string;
        accountId: string;
        name: string;
        contactName?: string;
        type?: string;
        phone?: string;
        fax?: string;
        email?: string;
        enabled: boolean;
        location?: string;
        deletedZ?: string;
        verificationRequestedZ?: string;
        verifiedZ?: string;
        website?: string;
        coi?: boolean;
        coiExpiry?: string;
        billingEmail?: string;
        businessNumber?: string;
        autopayInstitution?: string;
        autopayTransit?: string;
        autopayAccount?: string;
        autopayStatus?: string;
        autopayStatusUpdatedZ?: string;
        autopayClientId?: string;
        expenseGlId?: string;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
        fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        supplierAccounts?: Array<{
          __typename?: 'SupplierAccount';
          id: string;
          accountNumber: string;
        }>;
      }>;
    };
  };
};

export type DeleteSupplierMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteSupplierMutation = {
  __typename?: 'Mutation';
  deleteSupplier: {
    __typename?: 'SupplierPayload';
    success?: boolean;
    error?: string;
    supplier?: { __typename?: 'Supplier'; id: string; deletedZ?: string };
  };
};

export type PageSuppliersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<SuppliersFilterInput>;
  sort?: InputMaybe<Array<SuppliersSortInput> | SuppliersSortInput>;
}>;

export type PageSuppliersQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageSuppliers?: {
      __typename?: 'SuppliersPage';
      totalCount: number;
      pageInfo: { __typename?: 'RelayPageInfo'; endCursor?: string; hasNextPage: boolean };
      edges: Array<{
        __typename?: 'SupplierEdge';
        cursor: string;
        node: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
      }>;
    };
  };
};

export type GetUnitsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UnitFilter>;
}>;

export type GetUnitsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listUnits?: {
      __typename?: 'UnitConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Unit';
        id: string;
        name: string;
        ownerId?: string;
        occupancy?: UnitOccupancy;
        floorplanId?: string;
        reserveAmount?: number;
        buildingId?: string;
        property?: {
          __typename?: 'Property';
          id: string;
          name: string;
          key?: string;
          imageKey?: string;
          autopayCollectionsEnabled?: boolean;
        };
      }>;
    };
  };
};

export type ListAllUnitsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListAllUnitsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listUnits?: {
      __typename?: 'UnitConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Unit';
        id: string;
        name: string;
        ownerId?: string;
        propertyId?: string;
        occupancy?: UnitOccupancy;
        buildingId?: string;
        sqft: number;
        allResidencies?: Array<{
          __typename?: 'Residency';
          id: string;
          startZ: string;
          endZ?: string;
        }>;
      }>;
    };
  };
};

export type ListUnitsWithResidenciesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UnitFilter>;
}>;

export type ListUnitsWithResidenciesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listUnits?: {
      __typename?: 'UnitConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Unit';
        id: string;
        name: string;
        legalAddress?: string;
        sqft: number;
        bedrooms: number;
        bathrooms: number;
        additionalInformation?: string;
        occupancy?: UnitOccupancy;
        parking?: Array<string>;
        storage?: Array<string>;
        keyring?: Array<string>;
        unitFactor?: number;
        condoFee?: number;
        condoFeeGst?: number;
        preferredPaymentMethod?: PaymentMethod;
        propertyId?: string;
        ownerId?: string;
        buildingId?: string;
        owner?: { __typename?: 'Owner'; id: string; name: string };
        allResidencies?: Array<{
          __typename?: 'Residency';
          id: string;
          startZ: string;
          endZ?: string;
          renewZ?: string;
          rrule?: string;
          terms?: Array<string>;
          moveOutReason?: MoveOutReason;
          residents: Array<{
            __typename?: 'ResidentAssociation';
            id: string;
            leaseHolder?: boolean;
            resident:
              | { __typename?: 'Owner'; id: string; name: string }
              | { __typename?: 'Tenant'; id: string; name: string };
          }>;
        }>;
        condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
      }>;
    };
  };
};

export type AccountNameQueryVariables = Exact<{ [key: string]: never }>;

export type AccountNameQuery = {
  __typename?: 'Query';
  account?: { __typename?: 'Account'; id: string; name: string };
};

export type AddAutopayBankMutationVariables = Exact<{
  input: AddAutopayBankInput;
}>;

export type AddAutopayBankMutation = {
  __typename?: 'Mutation';
  addAutopayBank: {
    __typename?: 'BooksPayload';
    success?: boolean;
    error?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        banks?: Array<{
          __typename?: 'Bank';
          id: string;
          name: string;
          transitNumber: string;
          accountNumber: string;
          institutionNumber: string;
        }>;
      };
    };
  };
};

export type GetAutopayBanksQueryVariables = Exact<{ [key: string]: never }>;

export type GetAutopayBanksQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    autopayClientId?: string;
    autopayAccount?: string;
    autopayTransit?: string;
    autopayInstitution?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        banks?: Array<{
          __typename?: 'Bank';
          id: string;
          name: string;
          institutionNumber: string;
          accountNumber: string;
          transitNumber: string;
        }>;
      };
    };
  };
};

export type GetBooksActivationQueryVariables = Exact<{ [key: string]: never }>;

export type GetBooksActivationQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      activatedZ?: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activated?: boolean;
        activationStatus?: string;
        version?: string;
        enablePayments?: boolean;
        enableCollection?: boolean;
      };
    };
  };
};

export type GetBooksBatchTaskQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBooksBatchTaskQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      batchTask?: {
        __typename?: 'BatchTask';
        id: string;
        taskProgress: {
          __typename?: 'BatchTaskProgress';
          label: string;
          total: number;
          processed: number;
          done?: boolean;
        };
      };
    };
  };
};

export type GetOpenSearchTaskQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOpenSearchTaskQuery = {
  __typename?: 'Query';
  opensearch?: {
    __typename?: 'OpenSearch';
    task?: {
      __typename?: 'OpenSearchTaskResult';
      completed?: boolean;
      task: {
        __typename?: 'OpenSearchTask';
        id: string;
        cancellable?: boolean;
        cancelled?: boolean;
        status: {
          __typename?: 'OpenSearchTaskStatus';
          total: number;
          updated: number;
          created: number;
          deleted: number;
        };
      };
      response?: {
        __typename?: 'OpenSearchTaskResponse';
        took: number;
        total: number;
        updated: number;
        created: number;
        deleted: number;
      };
    };
  };
};

export type AllPropertiesQueryVariables = Exact<{ [key: string]: never }>;

export type AllPropertiesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageProperties?: {
      __typename?: 'PropertiesPage';
      edges: Array<{
        __typename?: 'PropertyEdge';
        node: {
          __typename?: 'Property';
          id: string;
          ownerId?: string;
          enabled?: boolean;
          name: string;
          key?: string;
          leasedUnits?: number;
          totalUnits?: number;
          totalUnitFactor?: number;
          commonAreas?: number;
          ownerUnits?: number;
          commercialLeasedUnits?: number;
          condoUnits?: number;
          imageKey?: string;
          timezone: string;
          commercialUnits?: number;
          rentalUnits?: number;
          phone?: string;
          types?: Array<PropertyType>;
          supplierIds?: Array<string>;
          contractorIds?: Array<string>;
          legalDescription?: string;
          creatingUnits?: number;
          houseRules?: Array<string>;
          autopayCollectionsEnabled?: boolean;
          rentalLeaseDocumentTemplateId?: string;
          commercialLeaseDocumentTemplateId?: string;
          buildings?: Array<{ __typename?: 'Building'; id: string; name: string }>;
          address: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
          parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
          storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
          keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
          onsiteManager?: {
            __typename?: 'OnsiteManager';
            id: string;
            entityType: OnsiteManagerType;
          };
          owner?: { __typename?: 'Owner'; id: string; name: string };
        };
      }>;
    };
  };
};

export type PropertyNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PropertyNameQuery = {
  __typename?: 'Query';
  property?: { __typename?: 'Property'; id: string; name: string };
};

export type GetPropertyUnitsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyUnitsQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    key?: string;
    autopayCollectionsEnabled?: boolean;
    address: { __typename?: 'Address'; province?: string };
    units?: Array<{
      __typename?: 'Unit';
      id: string;
      name: string;
      ownerId?: string;
      occupancy?: UnitOccupancy;
      floorplanId?: string;
      reserveAmount?: number;
      buildingId?: string;
      property?: {
        __typename?: 'Property';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        autopayCollectionsEnabled?: boolean;
      };
    }>;
    buildings?: Array<{ __typename?: 'Building'; id: string; name: string; key?: string }>;
  };
};

export type GetPropertyUnitsWithResidencyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyUnitsWithResidencyQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    key?: string;
    address: { __typename?: 'Address'; province?: string };
    units?: Array<{
      __typename?: 'Unit';
      id: string;
      name: string;
      legalAddress?: string;
      ownerId?: string;
      occupancy?: UnitOccupancy;
      buildingId?: string;
      property?: {
        __typename?: 'Property';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
      };
      allResidencies?: Array<{
        __typename?: 'Residency';
        id: string;
        startZ: string;
        endZ?: string;
        residents: Array<{
          __typename?: 'ResidentAssociation';
          id: string;
          leaseHolder?: boolean;
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          resident:
            | {
                __typename: 'Owner';
                email?: string;
                id: string;
                name: string;
                phones?: Array<string>;
              }
            | {
                __typename: 'Tenant';
                emails?: Array<string>;
                id: string;
                name: string;
                phones?: Array<string>;
              };
        }>;
      }>;
    }>;
    buildings?: Array<{ __typename?: 'Building'; id: string; name: string; key?: string }>;
  };
};

export type RefundableDepositTemplateQueryVariables = Exact<{
  province: Scalars['String']['input'];
}>;

export type RefundableDepositTemplateQuery = {
  __typename?: 'Query';
  depositTemplate?: {
    __typename?: 'DepositTemplate';
    id: string;
    enabled?: boolean;
    items: Array<{
      __typename?: 'DepositTemplateItem';
      id: string;
      name: string;
      defaultAmount?: number;
      defaultAmountExpr?: string;
      accrualStartExpr?: string;
      present?: boolean;
    }>;
  };
};

export type TaxesPropertiesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type TaxesPropertiesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    address?: { __typename?: 'Address'; province?: string };
    listProperties?: {
      __typename?: 'PropertyConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Property';
        id: string;
        address: { __typename?: 'Address'; province?: string };
      }>;
    };
  };
};

export type TeamUnitQueryVariables = Exact<{
  unitId: Scalars['ID']['input'];
}>;

export type TeamUnitQuery = {
  __typename?: 'Query';
  unit?: { __typename?: 'Unit'; id: string; propertyId?: string; occupancy?: UnitOccupancy };
};

export type GetActionableCampaignApplicationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetActionableCampaignApplicationsQuery = {
  __typename?: 'Query';
  account?: { __typename?: 'Account'; id: string; campaignApplicationsWithAction?: number };
};

export type GetActionableRequestsForAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GetActionableRequestsForAccountQuery = {
  __typename?: 'Query';
  account?: { __typename?: 'Account'; id: string; requestsWithAction?: number };
};

export type AddLinkedBankAccountsMutationVariables = Exact<{
  input: AddLinkedBankAccountsInput;
}>;

export type AddLinkedBankAccountsMutation = {
  __typename?: 'Mutation';
  addLinkedBankAccounts: {
    __typename?: 'BooksPayload';
    success?: boolean;
    error?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activated?: boolean;
        activatedZ?: string;
        activationStatus?: string;
        version?: string;
        enablePayments?: boolean;
        enableCollection?: boolean;
        deactivatedZ?: string;
        collectionTypes?: Array<CollectionType>;
        feeExpense: string;
        propraSupplier?: string;
        undepositedFunds?: string;
        nsfFee?: number;
        feeBankAccountId?: string;
        feeInvoiceChargeMethod?: FeeInvoiceChargeMethod;
        invitePayees?: boolean;
        linkedAccounts?: Array<{
          __typename?: 'LinkedAccount';
          id: string;
          institutionName: string;
          accountDetails?: {
            __typename?: 'AccountDetail';
            accountNumber4Digit?: string;
            type?: string;
            currency?: string;
          };
        }>;
        banks?: Array<{
          __typename?: 'Bank';
          id: string;
          name: string;
          transitNumber: string;
          accountNumber: string;
          institutionNumber: string;
        }>;
      };
    };
  };
};

export type DeleteLinkedBankAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteLinkedBankAccountMutation = {
  __typename?: 'Mutation';
  deleteLinkedBankAccount: {
    __typename?: 'BooksPayload';
    success?: boolean;
    error?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activated?: boolean;
        activatedZ?: string;
        activationStatus?: string;
        version?: string;
        enablePayments?: boolean;
        enableCollection?: boolean;
        deactivatedZ?: string;
        collectionTypes?: Array<CollectionType>;
        feeExpense: string;
        propraSupplier?: string;
        undepositedFunds?: string;
        nsfFee?: number;
        feeBankAccountId?: string;
        feeInvoiceChargeMethod?: FeeInvoiceChargeMethod;
        invitePayees?: boolean;
        linkedAccounts?: Array<{
          __typename?: 'LinkedAccount';
          id: string;
          institutionName: string;
          accountDetails?: {
            __typename?: 'AccountDetail';
            accountNumber4Digit?: string;
            type?: string;
            currency?: string;
          };
        }>;
        banks?: Array<{
          __typename?: 'Bank';
          id: string;
          name: string;
          transitNumber: string;
          accountNumber: string;
          institutionNumber: string;
        }>;
      };
    };
  };
};

export type UpdateAutopayCollectionTypesMutationVariables = Exact<{
  input: UpdateAutopayCollectionTypesInput;
}>;

export type UpdateAutopayCollectionTypesMutation = {
  __typename?: 'Mutation';
  updateAutopayCollectionTypes: {
    __typename?: 'BooksPayload';
    success?: boolean;
    error?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activated?: boolean;
        activatedZ?: string;
        activationStatus?: string;
        version?: string;
        enablePayments?: boolean;
        enableCollection?: boolean;
        deactivatedZ?: string;
        collectionTypes?: Array<CollectionType>;
        feeExpense: string;
        propraSupplier?: string;
        undepositedFunds?: string;
        nsfFee?: number;
        feeBankAccountId?: string;
        feeInvoiceChargeMethod?: FeeInvoiceChargeMethod;
        invitePayees?: boolean;
        linkedAccounts?: Array<{
          __typename?: 'LinkedAccount';
          id: string;
          institutionName: string;
          accountDetails?: {
            __typename?: 'AccountDetail';
            accountNumber4Digit?: string;
            type?: string;
            currency?: string;
          };
        }>;
        banks?: Array<{
          __typename?: 'Bank';
          id: string;
          name: string;
          transitNumber: string;
          accountNumber: string;
          institutionNumber: string;
        }>;
      };
    };
  };
};

export type UpdateAutopayNsfFeesMutationVariables = Exact<{
  input: UpdateAutopayNsfFeeInput;
}>;

export type UpdateAutopayNsfFeesMutation = {
  __typename?: 'Mutation';
  updateAutopayNSFFees: {
    __typename?: 'BooksPayload';
    success?: boolean;
    error?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activated?: boolean;
        activatedZ?: string;
        activationStatus?: string;
        version?: string;
        enablePayments?: boolean;
        enableCollection?: boolean;
        deactivatedZ?: string;
        collectionTypes?: Array<CollectionType>;
        feeExpense: string;
        propraSupplier?: string;
        undepositedFunds?: string;
        nsfFee?: number;
        feeBankAccountId?: string;
        feeInvoiceChargeMethod?: FeeInvoiceChargeMethod;
        invitePayees?: boolean;
        linkedAccounts?: Array<{
          __typename?: 'LinkedAccount';
          id: string;
          institutionName: string;
          accountDetails?: {
            __typename?: 'AccountDetail';
            accountNumber4Digit?: string;
            type?: string;
            currency?: string;
          };
        }>;
        banks?: Array<{
          __typename?: 'Bank';
          id: string;
          name: string;
          transitNumber: string;
          accountNumber: string;
          institutionNumber: string;
        }>;
      };
    };
  };
};

export type UpdateAutopayFeeInvoiceMutationVariables = Exact<{
  input: UpdateAutopayFeeInvoiceInput;
}>;

export type UpdateAutopayFeeInvoiceMutation = {
  __typename?: 'Mutation';
  updateAutopayFeeInvoice: {
    __typename?: 'BooksPayload';
    success?: boolean;
    error?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activated?: boolean;
        activatedZ?: string;
        activationStatus?: string;
        version?: string;
        enablePayments?: boolean;
        enableCollection?: boolean;
        deactivatedZ?: string;
        collectionTypes?: Array<CollectionType>;
        feeExpense: string;
        propraSupplier?: string;
        undepositedFunds?: string;
        nsfFee?: number;
        feeBankAccountId?: string;
        feeInvoiceChargeMethod?: FeeInvoiceChargeMethod;
        invitePayees?: boolean;
        linkedAccounts?: Array<{
          __typename?: 'LinkedAccount';
          id: string;
          institutionName: string;
          accountDetails?: {
            __typename?: 'AccountDetail';
            accountNumber4Digit?: string;
            type?: string;
            currency?: string;
          };
        }>;
        banks?: Array<{
          __typename?: 'Bank';
          id: string;
          name: string;
          transitNumber: string;
          accountNumber: string;
          institutionNumber: string;
        }>;
      };
    };
  };
};

export type AccountAutopayConfigsQueryVariables = Exact<{ [key: string]: never }>;

export type AccountAutopayConfigsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      glAccountMapping?: Array<{
        __typename?: 'GLMapping';
        id: string;
        parentId: string;
        glId?: string;
      }>;
      reconciliationConfigs?: Array<{
        __typename?: 'ReconciliationConfig';
        id: string;
        name: string;
        bankId?: string;
        scope: {
          __typename?: 'ReconciliationScope';
          glId: string;
          propertyOwnerIds?: Array<string>;
        };
      }>;
    };
  };
};

export type UpdateAccountAutopayMutationVariables = Exact<{
  input: UpdateAccountAutopayInput;
}>;

export type UpdateAccountAutopayMutation = {
  __typename?: 'Mutation';
  updateAccountAutopay?: {
    __typename?: 'AccountPayload';
    success?: boolean;
    error?: string;
    account?: {
      __typename?: 'Account';
      id: string;
      phone?: string;
      name: string;
      logoKey?: string;
      tier: number;
      autopayClientId?: string;
      autopayInstitution?: string;
      autopayTransit?: string;
      autopayAccount?: string;
      settings?: {
        __typename?: 'Settings';
        soon_days_threshold?: number;
        later_days_threshold?: number;
        resident_request_scheduling?: ResidentRequestScheduling;
        campaignApplicationRules?: {
          __typename?: 'CampaignApplicationRule';
          requiredDocs?: Array<{
            __typename?: 'CampaignApplicationDocRule';
            quantity: number;
            docTypes: Array<string>;
          }>;
        };
      };
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      fees?: Array<{ __typename?: 'Fee'; id: string; name: string; deletedZ?: string }>;
      books?: {
        __typename?: 'Books';
        accountId: string;
        trackManagementFeeRevenue?: boolean;
        billingRates?: { __typename?: 'BillingRate'; materialsMarkup?: number };
      };
    };
  };
};

export type LeaseDocumentTemplateListFieldsFragment = {
  __typename?: 'LeaseDocumentTemplate';
  id: string;
  name: string;
  leaseType: LeaseDocumentLeaseType;
  propertyIds?: Array<string>;
};

export type LeaseDocumentTemplateFieldsFragment = {
  __typename?: 'LeaseDocumentTemplate';
  id: string;
  name: string;
  leaseType: LeaseDocumentLeaseType;
  propertyIds?: Array<string>;
  schemas?: string;
};

export type CreateLeaseDocumentTemplateMutationVariables = Exact<{
  input: CreateLeaseDocumentTemplateInput;
}>;

export type CreateLeaseDocumentTemplateMutation = {
  __typename?: 'Mutation';
  createLeaseDocumentTemplate?: {
    __typename?: 'LeaseDocumentTemplatePayload';
    success?: boolean;
    error?: string;
    leaseDocumentTemplate?: {
      __typename?: 'LeaseDocumentTemplate';
      id: string;
      name: string;
      leaseType: LeaseDocumentLeaseType;
      propertyIds?: Array<string>;
      schemas?: string;
    };
  };
};

export type UpdateLeaseDocumentTemplateMutationVariables = Exact<{
  input: UpdateLeaseDocumentTemplateInput;
}>;

export type UpdateLeaseDocumentTemplateMutation = {
  __typename?: 'Mutation';
  updateLeaseDocumentTemplate?: {
    __typename?: 'LeaseDocumentTemplatePayload';
    success?: boolean;
    error?: string;
    leaseDocumentTemplate?: {
      __typename?: 'LeaseDocumentTemplate';
      id: string;
      name: string;
      leaseType: LeaseDocumentLeaseType;
      propertyIds?: Array<string>;
      schemas?: string;
    };
  };
};

export type GetLeaseDocumentTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetLeaseDocumentTemplateQuery = {
  __typename?: 'Query';
  leaseDocumentTemplate?: {
    __typename?: 'LeaseDocumentTemplate';
    id: string;
    name: string;
    leaseType: LeaseDocumentLeaseType;
    propertyIds?: Array<string>;
    schemas?: string;
  };
};

export type ListLeaseDocumentTemplatesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListLeaseDocumentTemplatesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listLeaseDocumentTemplates?: {
      __typename?: 'LeaseDocumentTemplateConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'LeaseDocumentTemplate';
        id: string;
        name: string;
        leaseType: LeaseDocumentLeaseType;
        propertyIds?: Array<string>;
      }>;
    };
  };
};

export type LeaseDocumentTemplatesPropertiesQueryVariables = Exact<{ [key: string]: never }>;

export type LeaseDocumentTemplatesPropertiesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageProperties?: {
      __typename?: 'PropertiesPage';
      edges: Array<{
        __typename?: 'PropertyEdge';
        node: { __typename?: 'Property'; id: string; name: string };
      }>;
    };
  };
};

export type DeleteLeaseDocumentTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteLeaseDocumentTemplateMutation = {
  __typename?: 'Mutation';
  deleteLeaseDocumentTemplate?: {
    __typename?: 'LeaseDocumentTemplatePayload';
    success?: boolean;
    error?: string;
  };
};

export type ManagerSubscriptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ManagerSubscriptionsQuery = {
  __typename?: 'Query';
  manager?: {
    __typename?: 'Manager';
    id: string;
    email: string;
    phone?: string;
    subscriptions?: Array<{
      __typename?: 'MessageSubscription';
      id: string;
      endpoints: Array<{
        __typename?: 'SubscriptionEndpoint';
        id: SubscriptionEndpointType;
        address: string;
      }>;
    }>;
  };
};

export type TeamFieldsFragment = {
  __typename?: 'Team';
  id: string;
  name: string;
  propertyIds: Array<string>;
  managerIds?: Array<string>;
  occupancies: Array<string>;
  propertyUnitIds?: Array<{ __typename?: 'PropertyUnit'; propertyId: string; unitId: string }>;
};

export type GetTeamQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetTeamQuery = {
  __typename?: 'Query';
  team?: {
    __typename?: 'Team';
    id: string;
    name: string;
    propertyIds: Array<string>;
    managerIds?: Array<string>;
    occupancies: Array<string>;
    propertyUnitIds?: Array<{ __typename?: 'PropertyUnit'; propertyId: string; unitId: string }>;
  };
};

export type TeamsWizardQueryVariables = Exact<{ [key: string]: never }>;

export type TeamsWizardQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageProperties?: {
      __typename?: 'PropertiesPage';
      edges: Array<{
        __typename?: 'PropertyEdge';
        node: { __typename?: 'Property'; id: string; key?: string; name: string };
      }>;
    };
    managers?: Array<{
      __typename?: 'Manager';
      id: string;
      name: string;
      email: string;
      roles: Array<ManagerRole>;
    }>;
  };
};

export type TeamsAdminQueryVariables = Exact<{ [key: string]: never }>;

export type TeamsAdminQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageProperties?: {
      __typename?: 'PropertiesPage';
      edges: Array<{
        __typename?: 'PropertyEdge';
        node: { __typename?: 'Property'; id: string; name: string; enabled?: boolean };
      }>;
    };
    managers?: Array<{ __typename?: 'Manager'; id: string; name: string }>;
    teams?: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      propertyIds: Array<string>;
      managerIds?: Array<string>;
      occupancies: Array<string>;
      propertyUnitIds?: Array<{ __typename?: 'PropertyUnit'; propertyId: string; unitId: string }>;
    }>;
  };
};

export type TeamPropertyUnitsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type TeamPropertyUnitsQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    pageUnits?: {
      __typename?: 'UnitsPage';
      edges: Array<{
        __typename?: 'UnitEdge';
        node: {
          __typename?: 'Unit';
          id: string;
          name: string;
          occupancy?: UnitOccupancy;
          buildingId?: string;
          buildingName?: string;
        };
      }>;
    };
  };
};

export type CreateTeamMutationVariables = Exact<{
  input: CreateTeamInput;
}>;

export type CreateTeamMutation = {
  __typename?: 'Mutation';
  createTeam?: {
    __typename?: 'TeamPayload';
    success?: boolean;
    error?: string;
    team?: {
      __typename?: 'Team';
      id: string;
      name: string;
      propertyIds: Array<string>;
      managerIds?: Array<string>;
      occupancies: Array<string>;
      propertyUnitIds?: Array<{ __typename?: 'PropertyUnit'; propertyId: string; unitId: string }>;
    };
  };
};

export type UpdateTeamMutationVariables = Exact<{
  input: UpdateTeamInput;
}>;

export type UpdateTeamMutation = {
  __typename?: 'Mutation';
  updateTeam?: {
    __typename?: 'TeamPayload';
    success?: boolean;
    error?: string;
    team?: {
      __typename?: 'Team';
      id: string;
      name: string;
      propertyIds: Array<string>;
      managerIds?: Array<string>;
      occupancies: Array<string>;
      propertyUnitIds?: Array<{ __typename?: 'PropertyUnit'; propertyId: string; unitId: string }>;
    };
  };
};

export type DeleteTeamMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTeamMutation = {
  __typename?: 'Mutation';
  deleteTeam?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type GetAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    phone?: string;
    name: string;
    logoKey?: string;
    tier: number;
    autopayClientId?: string;
    autopayInstitution?: string;
    autopayTransit?: string;
    autopayAccount?: string;
    settings?: {
      __typename?: 'Settings';
      soon_days_threshold?: number;
      later_days_threshold?: number;
      resident_request_scheduling?: ResidentRequestScheduling;
      campaignApplicationRules?: {
        __typename?: 'CampaignApplicationRule';
        requiredDocs?: Array<{
          __typename?: 'CampaignApplicationDocRule';
          quantity: number;
          docTypes: Array<string>;
        }>;
      };
    };
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    fees?: Array<{ __typename?: 'Fee'; id: string; name: string; deletedZ?: string }>;
    books?: {
      __typename?: 'Books';
      accountId: string;
      trackManagementFeeRevenue?: boolean;
      billingRates?: { __typename?: 'BillingRate'; materialsMarkup?: number };
    };
  };
};

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateAccountInput;
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  updateAccount?: {
    __typename?: 'Account';
    id: string;
    phone?: string;
    name: string;
    logoKey?: string;
    tier: number;
    autopayClientId?: string;
    autopayInstitution?: string;
    autopayTransit?: string;
    autopayAccount?: string;
    settings?: {
      __typename?: 'Settings';
      soon_days_threshold?: number;
      later_days_threshold?: number;
      resident_request_scheduling?: ResidentRequestScheduling;
      campaignApplicationRules?: {
        __typename?: 'CampaignApplicationRule';
        requiredDocs?: Array<{
          __typename?: 'CampaignApplicationDocRule';
          quantity: number;
          docTypes: Array<string>;
        }>;
      };
    };
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    fees?: Array<{ __typename?: 'Fee'; id: string; name: string; deletedZ?: string }>;
    books?: {
      __typename?: 'Books';
      accountId: string;
      trackManagementFeeRevenue?: boolean;
      billingRates?: { __typename?: 'BillingRate'; materialsMarkup?: number };
    };
  };
};

export type GetManagersQueryVariables = Exact<{ [key: string]: never }>;

export type GetManagersQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    managers?: Array<{
      __typename?: 'Manager';
      id: string;
      name: string;
      email: string;
      phone?: string;
      roles: Array<ManagerRole>;
      enabled: boolean;
      accountId?: string;
      signature?: {
        __typename?: 'Signature';
        id?: string;
        signerId?: string;
        signature: string;
        signerName: string;
        signerEntity: SignerEntity;
        isAgent?: boolean;
        agentRelationship?: string;
      };
      subscriptions?: Array<{
        __typename?: 'MessageSubscription';
        id: string;
        endpoints: Array<{
          __typename?: 'SubscriptionEndpoint';
          id: SubscriptionEndpointType;
          address: string;
        }>;
      }>;
    }>;
  };
};

export type CreateManagerMutationVariables = Exact<{
  input: CreateManagerInput;
}>;

export type CreateManagerMutation = {
  __typename?: 'Mutation';
  createManager?: {
    __typename?: 'Manager';
    id: string;
    name: string;
    email: string;
    phone?: string;
    roles: Array<ManagerRole>;
    enabled: boolean;
    accountId?: string;
    signature?: {
      __typename?: 'Signature';
      id?: string;
      signerId?: string;
      signature: string;
      signerName: string;
      signerEntity: SignerEntity;
      isAgent?: boolean;
      agentRelationship?: string;
    };
    subscriptions?: Array<{
      __typename?: 'MessageSubscription';
      id: string;
      endpoints: Array<{
        __typename?: 'SubscriptionEndpoint';
        id: SubscriptionEndpointType;
        address: string;
      }>;
    }>;
  };
};

export type UpdateManagerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateManagerInput;
}>;

export type UpdateManagerMutation = {
  __typename?: 'Mutation';
  updateManager?: {
    __typename?: 'Manager';
    id: string;
    name: string;
    email: string;
    phone?: string;
    roles: Array<ManagerRole>;
    enabled: boolean;
    accountId?: string;
    signature?: {
      __typename?: 'Signature';
      id?: string;
      signerId?: string;
      signature: string;
      signerName: string;
      signerEntity: SignerEntity;
      isAgent?: boolean;
      agentRelationship?: string;
    };
    subscriptions?: Array<{
      __typename?: 'MessageSubscription';
      id: string;
      endpoints: Array<{
        __typename?: 'SubscriptionEndpoint';
        id: SubscriptionEndpointType;
        address: string;
      }>;
    }>;
  };
};

export type ToggleEnableManagerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
}>;

export type ToggleEnableManagerMutation = {
  __typename?: 'Mutation';
  enableManager?: {
    __typename?: 'Manager';
    id: string;
    name: string;
    email: string;
    phone?: string;
    roles: Array<ManagerRole>;
    enabled: boolean;
    accountId?: string;
    signature?: {
      __typename?: 'Signature';
      id?: string;
      signerId?: string;
      signature: string;
      signerName: string;
      signerEntity: SignerEntity;
      isAgent?: boolean;
      agentRelationship?: string;
    };
    subscriptions?: Array<{
      __typename?: 'MessageSubscription';
      id: string;
      endpoints: Array<{
        __typename?: 'SubscriptionEndpoint';
        id: SubscriptionEndpointType;
        address: string;
      }>;
    }>;
  };
};

export type DeleteManagerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteManagerMutation = {
  __typename?: 'Mutation';
  deleteManager?: {
    __typename?: 'Manager';
    id: string;
    name: string;
    email: string;
    phone?: string;
    roles: Array<ManagerRole>;
    enabled: boolean;
    accountId?: string;
    signature?: {
      __typename?: 'Signature';
      id?: string;
      signerId?: string;
      signature: string;
      signerName: string;
      signerEntity: SignerEntity;
      isAgent?: boolean;
      agentRelationship?: string;
    };
    subscriptions?: Array<{
      __typename?: 'MessageSubscription';
      id: string;
      endpoints: Array<{
        __typename?: 'SubscriptionEndpoint';
        id: SubscriptionEndpointType;
        address: string;
      }>;
    }>;
  };
};

export type AddManagerRoleMutationVariables = Exact<{
  input: ManagerRoleInput;
}>;

export type AddManagerRoleMutation = {
  __typename?: 'Mutation';
  addManagerRole: {
    __typename?: 'ManagerPayload';
    success?: boolean;
    error?: string;
    manager?: { __typename?: 'Manager'; id: string; roles: Array<ManagerRole> };
  };
};

export type RemoveManagerRoleMutationVariables = Exact<{
  input: ManagerRoleInput;
}>;

export type RemoveManagerRoleMutation = {
  __typename?: 'Mutation';
  removeManagerRole: {
    __typename?: 'ManagerPayload';
    success?: boolean;
    error?: string;
    manager?: { __typename?: 'Manager'; id: string; roles: Array<ManagerRole> };
  };
};

export type RemoveManagerSignatureMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveManagerSignatureMutation = {
  __typename?: 'Mutation';
  removeManagerSignature?: {
    __typename?: 'Manager';
    id: string;
    signature?: {
      __typename?: 'Signature';
      id?: string;
      signerId?: string;
      signature: string;
      signerName: string;
      signerEntity: SignerEntity;
    };
  };
};

export type UpdateManagerSubscriptionsMutationVariables = Exact<{
  input: SubscriptionsInput;
}>;

export type UpdateManagerSubscriptionsMutation = {
  __typename?: 'Mutation';
  updateManagerSubscriptions: {
    __typename?: 'ManagerPayload';
    success?: boolean;
    error?: string;
    manager?: {
      __typename?: 'Manager';
      id: string;
      subscriptions?: Array<{
        __typename?: 'MessageSubscription';
        id: string;
        endpoints: Array<{
          __typename?: 'SubscriptionEndpoint';
          id: SubscriptionEndpointType;
          address: string;
        }>;
      }>;
    };
  };
};

export type SearchRelatedUnitsQueryVariables = Exact<{
  filter: SearchUnitsFilterInput;
}>;

export type SearchRelatedUnitsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      searchRelatedUnits?: {
        __typename?: 'SearchUnitPayload';
        success?: boolean;
        error?: string;
        units?: Array<{
          __typename?: 'SearchUnit';
          unitId: string;
          unitName: string;
          propertyName: string;
          propertyKey?: string;
          buildingName?: string;
          buildingKey?: string;
        }>;
      };
    };
  };
};

export type UnitNameQueryVariables = Exact<{
  unitId: Scalars['ID']['input'];
}>;

export type UnitNameQuery = {
  __typename?: 'Query';
  unit?: { __typename?: 'Unit'; id: string; name: string };
};

export type GetBooksQueryVariables = Exact<{ [key: string]: never }>;

export type GetBooksQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    charges?: Array<{
      __typename?: 'Charge';
      id: string;
      name: string;
      default?: number;
      limit?: number;
    }>;
    fees?: Array<{ __typename?: 'Fee'; id: string; name: string; deletedZ?: string }>;
    books?: {
      __typename?: 'Books';
      accountId: string;
      chequePlacement?: ChequePlacement;
      nextCheque?: number;
      nextInvoice?: number;
      nextJournal?: number;
      yearEndMonth?: number;
      receiverGeneralId?: string;
      gstNumber?: string;
      gstFiling?: FilingFrequency;
      printedZ?: string;
      includeDisabledProperties?: boolean;
      trackManagementFeeRevenue?: boolean;
      managementFeeBasis?: ManagementFeeBasis;
      billableRevenueFeeIds?: Array<string>;
      lastPrintedGlId?: string;
      billingRates?: {
        __typename?: 'BillingRate';
        enabled?: boolean;
        labourRate?: number;
        materialsMarkup?: number;
        requireApproval?: boolean;
        minBillableMinutes?: number;
        trackRevenue?: boolean;
      };
      customBillingRates?: Array<{
        __typename?: 'BillingRate';
        category?: string;
        glId?: string;
        enabled?: boolean;
        labourRate?: number;
        materialsMarkup?: number;
        requireApproval?: boolean;
        minBillableMinutes?: number;
        trackRevenue?: boolean;
      }>;
      printedCheques?: Array<{
        __typename?: 'BooksPrintedCheque';
        glId: string;
        printedZ: string;
        nextCheque: number;
        chequePlacement?: ChequePlacement;
      }>;
      incomePayoutOptions?: {
        __typename?: 'IncomePayoutOptions';
        excludePayables?: boolean;
        excludePrepayments?: boolean;
        includeOutstandingPayables?: boolean;
      };
      glAccounts: Array<{
        __typename?: 'GLAccount';
        id: string;
        name: string;
        key?: string;
        category?: string;
        accountType: AccountType;
        balanceType: BalanceType;
      }>;
      glAccountMapping?: Array<{
        __typename?: 'GLMapping';
        id: string;
        parentId: string;
        name: string;
        description: string;
        default: string;
        filter?: string;
        glId?: string;
      }>;
      presets?: Array<{
        __typename?: 'Preset';
        id: string;
        type: PresetType;
        version?: BooksReportVersion;
        category?: PresetCategory;
        name: string;
        visibleExpression?: string;
        description: string;
        lastPosted?: string;
        roles?: Array<ManagerRole>;
        group?: { __typename?: 'PresetGroup'; name: string; order?: number };
        glMapping?: Array<{
          __typename?: 'GLMapping';
          id: string;
          parentId: string;
          name: string;
          description: string;
          default: string;
          filter?: string;
          glId?: string;
        }>;
        inputFields?: Array<{
          __typename?: 'PresetInputField';
          id: string;
          label: string;
          type: BooksFieldType;
          required?: boolean;
          defaultExpression?: string;
          options?: Array<{ __typename?: 'PresetOption'; label: string; value: string }>;
        }>;
        recurrence?: {
          __typename?: 'PresetRecurrence';
          enabled: boolean;
          locked?: boolean;
          rrule: string;
          postedOffset: number;
          parameters: string;
        };
        icon?: { __typename?: 'PresetIcon'; name?: string; color?: string };
      }>;
      feeMapping?: Array<{ __typename?: 'FeeMapping'; feeId: string; glId: string }>;
      chargeMapping?: Array<{ __typename?: 'ChargeMapping'; chargeId: string; glId: string }>;
    };
  };
};

export type GetBatchesQueryVariables = Exact<{ [key: string]: never }>;

export type GetBatchesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      batches?: Array<{
        __typename?: 'Batch';
        id: string;
        presetId?: string;
        posted?: string;
        name?: string;
        status?: string;
      }>;
    };
  };
};

export type ListClearablesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ClearablesFilterInput>;
}>;

export type ListClearablesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      listClearables?: {
        __typename?: 'ClearableConnection';
        nextToken?: string;
        items?: Array<{
          __typename?: 'Clearable';
          id: string;
          due: string;
          posted: string;
          glId: string;
          ownerId: string;
          sourceJournalId: string;
          payeeId: string;
          unitId?: string;
          propertyId: string;
          payee: PayeeType;
          amount: number;
          outstanding: number;
          cleared?: string;
          ref?: string;
          description?: string;
          balanceType: BalanceType;
          batchId?: string;
          refund?: boolean;
          enrollmentId?: string;
          fixedEnrollmentId?: string;
          paymentsStatus?: PaymentStatus;
          autopayCollectionsEnabled?: boolean;
        }>;
      };
    };
  };
};

export type CountClearablesQueryVariables = Exact<{
  filter?: InputMaybe<ClearablesFilterInput>;
}>;

export type CountClearablesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      countClearables?: {
        __typename?: 'ClearableCount';
        rowCount: number;
        amountTotal: number;
        outstandingTotal: number;
      };
    };
  };
};

export type GetClearablesByIdQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetClearablesByIdQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      clearablesById: {
        __typename?: 'ClearablesPayload';
        success?: boolean;
        error?: string;
        clearables?: Array<{
          __typename?: 'Clearable';
          id: string;
          description?: string;
          due: string;
          posted: string;
          glId: string;
          ownerId: string;
          sourceJournalId: string;
          payeeId: string;
          payeeName?: string;
          unitId?: string;
          propertyId: string;
          payee: PayeeType;
          amount: number;
          outstanding: number;
          cleared?: string;
          ref?: string;
          balanceType: BalanceType;
          batchId?: string;
          refund?: boolean;
          enrollmentId?: string;
          fixedEnrollmentId?: string;
          autopayCollectionsEnabled?: boolean;
          payeeAutopayCollectionsEnabled?: boolean;
          supplierAccount?: string;
          paymentsStatus?: PaymentStatus;
          autopaySinglePosted?: string;
          journalEntries?: Array<{
            __typename?: 'JournalEntry';
            id: string;
            ownerId: string;
            glId: string;
            jeId: string;
            unitId?: string;
            amount: number;
            description?: string;
            posted: string;
            propertyId: string;
            paymentMethod?: PaymentMethod;
            ref?: string;
            gstInfo?: GstInfo;
            printedZ?: string;
            voidedZ?: string;
            requestId?: string;
            payee?: PayeeType;
            payeeId?: string;
            payeeName?: string;
            clearableId?: string;
            clearableLinked?: boolean;
            reconciliationId?: string;
            approverName?: string;
            transactionReferenceId?: string;
            clearable?: {
              __typename?: 'Clearable';
              id: string;
              payee: PayeeType;
              payeeId: string;
              payeeName?: string;
              balanceType: BalanceType;
              description?: string;
              sourceJournalId: string;
              sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
            };
            notes?: Array<{
              __typename?: 'Note';
              id: string;
              target?: string;
              source?: string;
              createdName?: string;
              createdSub?: string;
              createdZ?: string;
              text?: string;
            }>;
            lines: Array<{
              __typename?: 'JournalEntryLine';
              id?: string;
              glId: string;
              ownerId: string;
              propertyId: string;
              unitId?: string;
              amount: number;
              description?: string;
              clearableId?: string;
            }>;
          }>;
          glAccount: {
            __typename?: 'GLAccount';
            id: string;
            name: string;
            key?: string;
            category?: string;
            accountType: AccountType;
            balanceType: BalanceType;
          };
          sourceJournalEntry: {
            __typename?: 'JournalEntry';
            id: string;
            ownerId: string;
            glId: string;
            jeId: string;
            unitId?: string;
            amount: number;
            description?: string;
            posted: string;
            propertyId: string;
            paymentMethod?: PaymentMethod;
            ref?: string;
            gstInfo?: GstInfo;
            printedZ?: string;
            voidedZ?: string;
            requestId?: string;
            payee?: PayeeType;
            payeeId?: string;
            payeeName?: string;
            clearableId?: string;
            clearableLinked?: boolean;
            reconciliationId?: string;
            approverName?: string;
            transactionReferenceId?: string;
            clearable?: {
              __typename?: 'Clearable';
              id: string;
              payee: PayeeType;
              payeeId: string;
              payeeName?: string;
              balanceType: BalanceType;
              description?: string;
              sourceJournalId: string;
              sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
            };
            notes?: Array<{
              __typename?: 'Note';
              id: string;
              target?: string;
              source?: string;
              createdName?: string;
              createdSub?: string;
              createdZ?: string;
              text?: string;
            }>;
            lines: Array<{
              __typename?: 'JournalEntryLine';
              id?: string;
              glId: string;
              ownerId: string;
              propertyId: string;
              unitId?: string;
              amount: number;
              description?: string;
              clearableId?: string;
            }>;
          };
          documents?: Array<{
            __typename?: 'Document';
            key: string;
            name?: string;
            size?: number;
            createdZ: string;
            typename?: string;
          }>;
          notes?: Array<{
            __typename?: 'Note';
            id: string;
            target?: string;
            source?: string;
            createdName?: string;
            createdSub?: string;
            createdZ?: string;
            text?: string;
          }>;
        }>;
      };
    };
  };
};

export type UpdateClearableMutationVariables = Exact<{
  input: UpdateClearableInput;
}>;

export type UpdateClearableMutation = {
  __typename?: 'Mutation';
  updateClearable: {
    __typename?: 'ClearablePayload';
    success?: boolean;
    error?: string;
    clearable?: {
      __typename?: 'Clearable';
      id: string;
      description?: string;
      due: string;
      posted: string;
      glId: string;
      ownerId: string;
      sourceJournalId: string;
      payeeId: string;
      payeeName?: string;
      unitId?: string;
      propertyId: string;
      payee: PayeeType;
      amount: number;
      outstanding: number;
      cleared?: string;
      ref?: string;
      balanceType: BalanceType;
      batchId?: string;
      refund?: boolean;
      enrollmentId?: string;
      fixedEnrollmentId?: string;
      autopayCollectionsEnabled?: boolean;
      payeeAutopayCollectionsEnabled?: boolean;
      supplierAccount?: string;
      paymentsStatus?: PaymentStatus;
      autopaySinglePosted?: string;
      journalEntries?: Array<{
        __typename?: 'JournalEntry';
        id: string;
        ownerId: string;
        glId: string;
        jeId: string;
        unitId?: string;
        amount: number;
        description?: string;
        posted: string;
        propertyId: string;
        paymentMethod?: PaymentMethod;
        ref?: string;
        gstInfo?: GstInfo;
        printedZ?: string;
        voidedZ?: string;
        requestId?: string;
        payee?: PayeeType;
        payeeId?: string;
        payeeName?: string;
        clearableId?: string;
        clearableLinked?: boolean;
        reconciliationId?: string;
        approverName?: string;
        transactionReferenceId?: string;
        clearable?: {
          __typename?: 'Clearable';
          id: string;
          payee: PayeeType;
          payeeId: string;
          payeeName?: string;
          balanceType: BalanceType;
          description?: string;
          sourceJournalId: string;
          sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
        };
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          target?: string;
          source?: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        lines: Array<{
          __typename?: 'JournalEntryLine';
          id?: string;
          glId: string;
          ownerId: string;
          propertyId: string;
          unitId?: string;
          amount: number;
          description?: string;
          clearableId?: string;
        }>;
      }>;
      glAccount: {
        __typename?: 'GLAccount';
        id: string;
        name: string;
        key?: string;
        category?: string;
        accountType: AccountType;
        balanceType: BalanceType;
      };
      sourceJournalEntry: {
        __typename?: 'JournalEntry';
        id: string;
        ownerId: string;
        glId: string;
        jeId: string;
        unitId?: string;
        amount: number;
        description?: string;
        posted: string;
        propertyId: string;
        paymentMethod?: PaymentMethod;
        ref?: string;
        gstInfo?: GstInfo;
        printedZ?: string;
        voidedZ?: string;
        requestId?: string;
        payee?: PayeeType;
        payeeId?: string;
        payeeName?: string;
        clearableId?: string;
        clearableLinked?: boolean;
        reconciliationId?: string;
        approverName?: string;
        transactionReferenceId?: string;
        clearable?: {
          __typename?: 'Clearable';
          id: string;
          payee: PayeeType;
          payeeId: string;
          payeeName?: string;
          balanceType: BalanceType;
          description?: string;
          sourceJournalId: string;
          sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
        };
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          target?: string;
          source?: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        lines: Array<{
          __typename?: 'JournalEntryLine';
          id?: string;
          glId: string;
          ownerId: string;
          propertyId: string;
          unitId?: string;
          amount: number;
          description?: string;
          clearableId?: string;
        }>;
      };
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ: string;
        typename?: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type GetClearableDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetClearableDetailsQuery = {
  __typename?: 'Query';
  clearable?: {
    __typename?: 'Clearable';
    id: string;
    description?: string;
    due: string;
    posted: string;
    glId: string;
    ownerId: string;
    sourceJournalId: string;
    payeeId: string;
    payeeName?: string;
    unitId?: string;
    propertyId: string;
    payee: PayeeType;
    amount: number;
    outstanding: number;
    cleared?: string;
    ref?: string;
    balanceType: BalanceType;
    batchId?: string;
    refund?: boolean;
    enrollmentId?: string;
    fixedEnrollmentId?: string;
    autopayCollectionsEnabled?: boolean;
    payeeAutopayCollectionsEnabled?: boolean;
    supplierAccount?: string;
    paymentsStatus?: PaymentStatus;
    autopaySinglePosted?: string;
    journalEntries?: Array<{
      __typename?: 'JournalEntry';
      id: string;
      ownerId: string;
      glId: string;
      jeId: string;
      unitId?: string;
      amount: number;
      description?: string;
      posted: string;
      propertyId: string;
      paymentMethod?: PaymentMethod;
      ref?: string;
      gstInfo?: GstInfo;
      printedZ?: string;
      voidedZ?: string;
      requestId?: string;
      payee?: PayeeType;
      payeeId?: string;
      payeeName?: string;
      clearableId?: string;
      clearableLinked?: boolean;
      reconciliationId?: string;
      approverName?: string;
      transactionReferenceId?: string;
      journalEntries?: Array<{
        __typename?: 'JournalEntry';
        id: string;
        ownerId: string;
        glId: string;
        jeId: string;
        unitId?: string;
        amount: number;
        description?: string;
        posted: string;
        propertyId: string;
        paymentMethod?: PaymentMethod;
        ref?: string;
        gstInfo?: GstInfo;
        printedZ?: string;
        voidedZ?: string;
        requestId?: string;
        payee?: PayeeType;
        payeeId?: string;
        payeeName?: string;
        clearableId?: string;
        clearableLinked?: boolean;
        reconciliationId?: string;
        approverName?: string;
        transactionReferenceId?: string;
        clearable?: {
          __typename?: 'Clearable';
          id: string;
          payee: PayeeType;
          payeeId: string;
          payeeName?: string;
          balanceType: BalanceType;
          description?: string;
          sourceJournalId: string;
          sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
        };
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          target?: string;
          source?: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        lines: Array<{
          __typename?: 'JournalEntryLine';
          id?: string;
          glId: string;
          ownerId: string;
          propertyId: string;
          unitId?: string;
          amount: number;
          description?: string;
          clearableId?: string;
        }>;
      }>;
      clearable?: {
        __typename?: 'Clearable';
        id: string;
        payee: PayeeType;
        payeeId: string;
        payeeName?: string;
        balanceType: BalanceType;
        description?: string;
        sourceJournalId: string;
        sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
      };
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      lines: Array<{
        __typename?: 'JournalEntryLine';
        id?: string;
        glId: string;
        ownerId: string;
        propertyId: string;
        unitId?: string;
        amount: number;
        description?: string;
        clearableId?: string;
      }>;
    }>;
    glAccount: {
      __typename?: 'GLAccount';
      id: string;
      name: string;
      key?: string;
      category?: string;
      accountType: AccountType;
      balanceType: BalanceType;
    };
    sourceJournalEntry: {
      __typename?: 'JournalEntry';
      id: string;
      ownerId: string;
      glId: string;
      jeId: string;
      unitId?: string;
      amount: number;
      description?: string;
      posted: string;
      propertyId: string;
      paymentMethod?: PaymentMethod;
      ref?: string;
      gstInfo?: GstInfo;
      printedZ?: string;
      voidedZ?: string;
      requestId?: string;
      payee?: PayeeType;
      payeeId?: string;
      payeeName?: string;
      clearableId?: string;
      clearableLinked?: boolean;
      reconciliationId?: string;
      approverName?: string;
      transactionReferenceId?: string;
      clearable?: {
        __typename?: 'Clearable';
        id: string;
        payee: PayeeType;
        payeeId: string;
        payeeName?: string;
        balanceType: BalanceType;
        description?: string;
        sourceJournalId: string;
        sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
      };
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      lines: Array<{
        __typename?: 'JournalEntryLine';
        id?: string;
        glId: string;
        ownerId: string;
        propertyId: string;
        unitId?: string;
        amount: number;
        description?: string;
        clearableId?: string;
      }>;
    };
    documents?: Array<{
      __typename?: 'Document';
      key: string;
      name?: string;
      size?: number;
      createdZ: string;
      typename?: string;
    }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
  };
};

export type GetRecurrencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRecurrencesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      recurrences?: Array<{
        __typename?: 'Recurrence';
        id: string;
        name: string;
        rrule: string;
        lastPosted?: string;
        output: {
          __typename?: 'RecurrenceOutput';
          lines: Array<{
            __typename?: 'JournalEntryLine';
            glId: string;
            propertyId: string;
            unitId?: string;
            ownerId: string;
            description?: string;
            amount: number;
            payee?: PayeeType;
            payeeId?: string;
          }>;
          clearable?: {
            __typename?: 'RecurrenceClearableConfig';
            payee: string;
            payeeId: string;
            postedForDue?: boolean;
          };
        };
      }>;
    };
  };
};

export type DeleteRecurrenceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteRecurrenceMutation = {
  __typename?: 'Mutation';
  deleteRecurrence?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type ActivateBooksMutationVariables = Exact<{
  input: ActivateBooksInput;
}>;

export type ActivateBooksMutation = {
  __typename?: 'Mutation';
  activateBooks: {
    __typename?: 'ActivateBooksPayload';
    books?: {
      __typename?: 'Books';
      accountId: string;
      activatedZ?: string;
      glAccounts: Array<{
        __typename?: 'GLAccount';
        id: string;
        name: string;
        key?: string;
        category?: string;
        accountType: AccountType;
        balanceType: BalanceType;
      }>;
    };
  };
};

export type UpdateFinancialSettingsMutationVariables = Exact<{
  input: UpdateFinancialSettingsInput;
}>;

export type UpdateFinancialSettingsMutation = {
  __typename?: 'Mutation';
  updateFinancialSettings: {
    __typename?: 'BooksPayload';
    success?: boolean;
    error?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      receiverGeneralId?: string;
      yearEndMonth?: number;
      gstFiling?: FilingFrequency;
      gstNumber?: string;
      chequePlacement?: ChequePlacement;
      trackManagementFeeRevenue?: boolean;
      includeDisabledProperties?: boolean;
      glAccountMapping?: Array<{
        __typename?: 'GLMapping';
        id: string;
        parentId: string;
        name: string;
        description: string;
        default: string;
        filter?: string;
        glId?: string;
      }>;
      billingRates?: {
        __typename?: 'BillingRate';
        enabled?: boolean;
        labourRate?: number;
        materialsMarkup?: number;
        requireApproval?: boolean;
        minBillableMinutes?: number;
        trackRevenue?: boolean;
      };
      customBillingRates?: Array<{
        __typename?: 'BillingRate';
        category?: string;
        glId?: string;
        enabled?: boolean;
        labourRate?: number;
        materialsMarkup?: number;
        requireApproval?: boolean;
        minBillableMinutes?: number;
        trackRevenue?: boolean;
      }>;
      incomePayoutOptions?: {
        __typename?: 'IncomePayoutOptions';
        excludePayables?: boolean;
        excludePrepayments?: boolean;
        includeOutstandingPayables?: boolean;
      };
      feeMapping?: Array<{ __typename?: 'FeeMapping'; feeId: string; glId: string }>;
      chargeMapping?: Array<{ __typename?: 'ChargeMapping'; chargeId: string; glId: string }>;
    };
  };
};

export type CreateGlAccountMutationVariables = Exact<{
  input: CreateGlAccountInput;
}>;

export type CreateGlAccountMutation = {
  __typename?: 'Mutation';
  createGLAccount: {
    __typename?: 'CreateGLAccountPayload';
    glAccount?: {
      __typename?: 'GLAccount';
      id: string;
      name: string;
      key?: string;
      category?: string;
      accountType: AccountType;
      balanceType: BalanceType;
    };
  };
};

export type UpdateGlAccountMutationVariables = Exact<{
  input: UpdateGlAccountInput;
}>;

export type UpdateGlAccountMutation = {
  __typename?: 'Mutation';
  updateGLAccount: {
    __typename?: 'UpdateGLAccountPayload';
    glAccount?: {
      __typename?: 'GLAccount';
      id: string;
      name: string;
      key?: string;
      category?: string;
      accountType: AccountType;
      balanceType: BalanceType;
    };
  };
};

export type AddClearableMutationVariables = Exact<{
  input: AddClearableInput;
}>;

export type AddClearableMutation = {
  __typename?: 'Mutation';
  addClearable: {
    __typename?: 'ClearablePayload';
    success?: boolean;
    error?: string;
    clearable?: {
      __typename?: 'Clearable';
      id: string;
      description?: string;
      due: string;
      posted: string;
      glId: string;
      ownerId: string;
      sourceJournalId: string;
      payeeId: string;
      payeeName?: string;
      unitId?: string;
      propertyId: string;
      payee: PayeeType;
      amount: number;
      outstanding: number;
      cleared?: string;
      ref?: string;
      balanceType: BalanceType;
      batchId?: string;
      refund?: boolean;
      enrollmentId?: string;
      fixedEnrollmentId?: string;
      autopayCollectionsEnabled?: boolean;
      payeeAutopayCollectionsEnabled?: boolean;
      supplierAccount?: string;
      paymentsStatus?: PaymentStatus;
      autopaySinglePosted?: string;
      journalEntries?: Array<{
        __typename?: 'JournalEntry';
        id: string;
        ownerId: string;
        glId: string;
        jeId: string;
        unitId?: string;
        amount: number;
        description?: string;
        posted: string;
        propertyId: string;
        paymentMethod?: PaymentMethod;
        ref?: string;
        gstInfo?: GstInfo;
        printedZ?: string;
        voidedZ?: string;
        requestId?: string;
        payee?: PayeeType;
        payeeId?: string;
        payeeName?: string;
        clearableId?: string;
        clearableLinked?: boolean;
        reconciliationId?: string;
        approverName?: string;
        transactionReferenceId?: string;
        clearable?: {
          __typename?: 'Clearable';
          id: string;
          payee: PayeeType;
          payeeId: string;
          payeeName?: string;
          balanceType: BalanceType;
          description?: string;
          sourceJournalId: string;
          sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
        };
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          target?: string;
          source?: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        lines: Array<{
          __typename?: 'JournalEntryLine';
          id?: string;
          glId: string;
          ownerId: string;
          propertyId: string;
          unitId?: string;
          amount: number;
          description?: string;
          clearableId?: string;
        }>;
      }>;
      glAccount: {
        __typename?: 'GLAccount';
        id: string;
        name: string;
        key?: string;
        category?: string;
        accountType: AccountType;
        balanceType: BalanceType;
      };
      sourceJournalEntry: {
        __typename?: 'JournalEntry';
        id: string;
        ownerId: string;
        glId: string;
        jeId: string;
        unitId?: string;
        amount: number;
        description?: string;
        posted: string;
        propertyId: string;
        paymentMethod?: PaymentMethod;
        ref?: string;
        gstInfo?: GstInfo;
        printedZ?: string;
        voidedZ?: string;
        requestId?: string;
        payee?: PayeeType;
        payeeId?: string;
        payeeName?: string;
        clearableId?: string;
        clearableLinked?: boolean;
        reconciliationId?: string;
        approverName?: string;
        transactionReferenceId?: string;
        clearable?: {
          __typename?: 'Clearable';
          id: string;
          payee: PayeeType;
          payeeId: string;
          payeeName?: string;
          balanceType: BalanceType;
          description?: string;
          sourceJournalId: string;
          sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
        };
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          target?: string;
          source?: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        lines: Array<{
          __typename?: 'JournalEntryLine';
          id?: string;
          glId: string;
          ownerId: string;
          propertyId: string;
          unitId?: string;
          amount: number;
          description?: string;
          clearableId?: string;
        }>;
      };
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ: string;
        typename?: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
    recurrence?: {
      __typename?: 'Recurrence';
      id: string;
      name: string;
      rrule: string;
      lastPosted?: string;
      output: {
        __typename?: 'RecurrenceOutput';
        lines: Array<{
          __typename?: 'JournalEntryLine';
          glId: string;
          propertyId: string;
          unitId?: string;
          ownerId: string;
          description?: string;
          amount: number;
          payee?: PayeeType;
          payeeId?: string;
        }>;
        clearable?: {
          __typename?: 'RecurrenceClearableConfig';
          payee: string;
          payeeId: string;
          postedForDue?: boolean;
        };
      };
    };
    books?: { __typename?: 'Books'; accountId: string; nextInvoice?: number };
  };
};

export type RefundClearableMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RefundClearableMutation = {
  __typename?: 'Mutation';
  refundClearable: {
    __typename?: 'ClearablePayload';
    success?: boolean;
    error?: string;
    clearable?: {
      __typename?: 'Clearable';
      id: string;
      balanceType: BalanceType;
      outstanding: number;
      refund?: boolean;
    };
  };
};

export type AddJournalEntryMutationVariables = Exact<{
  input: AddJournalEntryInput;
}>;

export type AddJournalEntryMutation = {
  __typename?: 'Mutation';
  addJournalEntry: {
    __typename?: 'JournalEntryPayload';
    journalIds?: Array<string>;
    success?: boolean;
    error?: string;
    journalEntries?: Array<{
      __typename?: 'JournalEntry';
      id: string;
      ownerId: string;
      glId: string;
      jeId: string;
      unitId?: string;
      amount: number;
      description?: string;
      posted: string;
      propertyId: string;
      paymentMethod?: PaymentMethod;
      ref?: string;
      gstInfo?: GstInfo;
      printedZ?: string;
      voidedZ?: string;
      requestId?: string;
      payee?: PayeeType;
      payeeId?: string;
      payeeName?: string;
      clearableId?: string;
      clearableLinked?: boolean;
      reconciliationId?: string;
      approverName?: string;
      transactionReferenceId?: string;
      clearable?: {
        __typename?: 'Clearable';
        id: string;
        payee: PayeeType;
        payeeId: string;
        payeeName?: string;
        balanceType: BalanceType;
        description?: string;
        sourceJournalId: string;
        sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
      };
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      lines: Array<{
        __typename?: 'JournalEntryLine';
        id?: string;
        glId: string;
        ownerId: string;
        propertyId: string;
        unitId?: string;
        amount: number;
        description?: string;
        clearableId?: string;
      }>;
    }>;
    recurrence?: {
      __typename?: 'Recurrence';
      id: string;
      name: string;
      rrule: string;
      lastPosted?: string;
      output: {
        __typename?: 'RecurrenceOutput';
        lines: Array<{
          __typename?: 'JournalEntryLine';
          glId: string;
          propertyId: string;
          unitId?: string;
          ownerId: string;
          description?: string;
          amount: number;
          payee?: PayeeType;
          payeeId?: string;
        }>;
        clearable?: {
          __typename?: 'RecurrenceClearableConfig';
          payee: string;
          payeeId: string;
          postedForDue?: boolean;
        };
      };
    };
    books?: { __typename?: 'Books'; accountId: string; nextJournal?: number };
  };
};

export type UpdateReconciliationJournalsMutationVariables = Exact<{
  input: UpdateReconciliationJournalsInput;
}>;

export type UpdateReconciliationJournalsMutation = {
  __typename?: 'Mutation';
  updateReconciliationJournals: {
    __typename?: 'SuccessPayload';
    success?: boolean;
    error?: string;
  };
};

export type UpdateJournalEntryMutationVariables = Exact<{
  input: UpdateJournalEntryInput;
}>;

export type UpdateJournalEntryMutation = {
  __typename?: 'Mutation';
  updateJournalEntry: {
    __typename?: 'JournalEntryPayload';
    journalIds?: Array<string>;
    success?: boolean;
    error?: string;
    journalEntries?: Array<{
      __typename?: 'JournalEntry';
      id: string;
      ownerId: string;
      glId: string;
      jeId: string;
      unitId?: string;
      amount: number;
      description?: string;
      posted: string;
      propertyId: string;
      paymentMethod?: PaymentMethod;
      ref?: string;
      gstInfo?: GstInfo;
      printedZ?: string;
      voidedZ?: string;
      requestId?: string;
      payee?: PayeeType;
      payeeId?: string;
      payeeName?: string;
      clearableId?: string;
      clearableLinked?: boolean;
      reconciliationId?: string;
      approverName?: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      lines: Array<{
        __typename?: 'JournalEntryLine';
        id?: string;
        glId: string;
        ownerId: string;
        propertyId: string;
        unitId?: string;
        amount: number;
        description?: string;
        clearableId?: string;
      }>;
    }>;
  };
};

export type DeleteJournalEntryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteJournalEntryMutation = {
  __typename?: 'Mutation';
  deleteJournalEntry?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type GetJournalEntryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetJournalEntryQuery = {
  __typename?: 'Query';
  journalEntry?: {
    __typename?: 'JournalEntry';
    id: string;
    ownerId: string;
    glId: string;
    jeId: string;
    unitId?: string;
    amount: number;
    description?: string;
    posted: string;
    propertyId: string;
    paymentMethod?: PaymentMethod;
    ref?: string;
    gstInfo?: GstInfo;
    printedZ?: string;
    voidedZ?: string;
    requestId?: string;
    payee?: PayeeType;
    payeeId?: string;
    payeeName?: string;
    clearableId?: string;
    clearableLinked?: boolean;
    reconciliationId?: string;
    approverName?: string;
    transactionReferenceId?: string;
    clearable?: {
      __typename?: 'Clearable';
      id: string;
      payee: PayeeType;
      payeeId: string;
      payeeName?: string;
      balanceType: BalanceType;
      description?: string;
      sourceJournalId: string;
      sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
    };
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    lines: Array<{
      __typename?: 'JournalEntryLine';
      id?: string;
      glId: string;
      ownerId: string;
      propertyId: string;
      unitId?: string;
      amount: number;
      description?: string;
      clearableId?: string;
    }>;
  };
};

export type GetJournalEntryDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetJournalEntryDetailsQuery = {
  __typename?: 'Query';
  journalEntry?: {
    __typename?: 'JournalEntry';
    id: string;
    ownerId: string;
    glId: string;
    jeId: string;
    unitId?: string;
    amount: number;
    description?: string;
    posted: string;
    propertyId: string;
    paymentMethod?: PaymentMethod;
    ref?: string;
    gstInfo?: GstInfo;
    printedZ?: string;
    voidedZ?: string;
    requestId?: string;
    payee?: PayeeType;
    payeeId?: string;
    payeeName?: string;
    clearableId?: string;
    clearableLinked?: boolean;
    reconciliationId?: string;
    approverName?: string;
    transactionReferenceId?: string;
    relatedClearables: Array<{
      __typename?: 'Clearable';
      id: string;
      due: string;
      posted: string;
      glId: string;
      ownerId: string;
      sourceJournalId: string;
      payeeId: string;
      payeeName?: string;
      unitName?: string;
      buildingName?: string;
      propertyName?: string;
      propertyKey?: string;
      unitId?: string;
      propertyId: string;
      payee: PayeeType;
      amount: number;
      outstanding: number;
      cleared?: string;
      ref?: string;
      balanceType: BalanceType;
      batchId?: string;
      refund?: boolean;
      sourceJournalEntry: {
        __typename?: 'JournalEntry';
        id: string;
        ownerId: string;
        glId: string;
        jeId: string;
        unitId?: string;
        amount: number;
        description?: string;
        posted: string;
        propertyId: string;
        paymentMethod?: PaymentMethod;
        ref?: string;
        gstInfo?: GstInfo;
        printedZ?: string;
        voidedZ?: string;
        requestId?: string;
        payee?: PayeeType;
        payeeId?: string;
        payeeName?: string;
        reconciliationId?: string;
      };
    }>;
    journalEntries?: Array<{
      __typename?: 'JournalEntry';
      id: string;
      ownerId: string;
      glId: string;
      jeId: string;
      unitId?: string;
      amount: number;
      description?: string;
      posted: string;
      propertyId: string;
      paymentMethod?: PaymentMethod;
      ref?: string;
      gstInfo?: GstInfo;
      printedZ?: string;
      voidedZ?: string;
      requestId?: string;
      payee?: PayeeType;
      payeeId?: string;
      payeeName?: string;
      clearableId?: string;
      clearableLinked?: boolean;
      reconciliationId?: string;
      approverName?: string;
      transactionReferenceId?: string;
      clearable?: {
        __typename?: 'Clearable';
        id: string;
        payee: PayeeType;
        payeeId: string;
        payeeName?: string;
        balanceType: BalanceType;
        description?: string;
        sourceJournalId: string;
        sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
      };
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      lines: Array<{
        __typename?: 'JournalEntryLine';
        id?: string;
        glId: string;
        ownerId: string;
        propertyId: string;
        unitId?: string;
        amount: number;
        description?: string;
        clearableId?: string;
      }>;
    }>;
    clearable?: {
      __typename?: 'Clearable';
      id: string;
      payee: PayeeType;
      payeeId: string;
      payeeName?: string;
      balanceType: BalanceType;
      description?: string;
      sourceJournalId: string;
      sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
    };
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    lines: Array<{
      __typename?: 'JournalEntryLine';
      id?: string;
      glId: string;
      ownerId: string;
      propertyId: string;
      unitId?: string;
      amount: number;
      description?: string;
      clearableId?: string;
    }>;
  };
};

export type JournalWithJournalsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type JournalWithJournalsQuery = {
  __typename?: 'Query';
  journalEntry?: {
    __typename?: 'JournalEntry';
    id: string;
    ownerId: string;
    glId: string;
    jeId: string;
    unitId?: string;
    amount: number;
    description?: string;
    posted: string;
    propertyId: string;
    paymentMethod?: PaymentMethod;
    ref?: string;
    gstInfo?: GstInfo;
    printedZ?: string;
    voidedZ?: string;
    requestId?: string;
    payee?: PayeeType;
    payeeId?: string;
    payeeName?: string;
    clearableId?: string;
    clearableLinked?: boolean;
    reconciliationId?: string;
    approverName?: string;
    transactionReferenceId?: string;
    journalEntries?: Array<{
      __typename?: 'JournalEntry';
      id: string;
      ownerId: string;
      glId: string;
      jeId: string;
      unitId?: string;
      amount: number;
      description?: string;
      posted: string;
      propertyId: string;
      paymentMethod?: PaymentMethod;
      ref?: string;
      gstInfo?: GstInfo;
      printedZ?: string;
      voidedZ?: string;
      requestId?: string;
      payee?: PayeeType;
      payeeId?: string;
      payeeName?: string;
      clearableId?: string;
      clearableLinked?: boolean;
      reconciliationId?: string;
      approverName?: string;
      transactionReferenceId?: string;
      clearable?: {
        __typename?: 'Clearable';
        id: string;
        payee: PayeeType;
        payeeId: string;
        payeeName?: string;
        balanceType: BalanceType;
        description?: string;
        sourceJournalId: string;
        sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
      };
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      lines: Array<{
        __typename?: 'JournalEntryLine';
        id?: string;
        glId: string;
        ownerId: string;
        propertyId: string;
        unitId?: string;
        amount: number;
        description?: string;
        clearableId?: string;
      }>;
    }>;
    clearable?: {
      __typename?: 'Clearable';
      id: string;
      payee: PayeeType;
      payeeId: string;
      payeeName?: string;
      balanceType: BalanceType;
      description?: string;
      sourceJournalId: string;
      sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
    };
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    lines: Array<{
      __typename?: 'JournalEntryLine';
      id?: string;
      glId: string;
      ownerId: string;
      propertyId: string;
      unitId?: string;
      amount: number;
      description?: string;
      clearableId?: string;
    }>;
  };
};

export type GetJournalEntriesForJeQueryVariables = Exact<{
  jeId: Scalars['ID']['input'];
}>;

export type GetJournalEntriesForJeQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      journalEntriesForJE?: Array<{
        __typename?: 'JournalEntry';
        id: string;
        ownerId: string;
        glId: string;
        jeId: string;
        unitId?: string;
        amount: number;
        description?: string;
        posted: string;
        propertyId: string;
        paymentMethod?: PaymentMethod;
        ref?: string;
        gstInfo?: GstInfo;
        printedZ?: string;
        voidedZ?: string;
        requestId?: string;
        payee?: PayeeType;
        payeeId?: string;
        payeeName?: string;
        clearableId?: string;
        clearableLinked?: boolean;
        reconciliationId?: string;
        approverName?: string;
        transactionReferenceId?: string;
        clearable?: {
          __typename?: 'Clearable';
          id: string;
          payee: PayeeType;
          payeeId: string;
          payeeName?: string;
          balanceType: BalanceType;
          description?: string;
          sourceJournalId: string;
          sourceJournalEntry: { __typename?: 'JournalEntry'; jeId: string };
        };
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          target?: string;
          source?: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        lines: Array<{
          __typename?: 'JournalEntryLine';
          id?: string;
          glId: string;
          ownerId: string;
          propertyId: string;
          unitId?: string;
          amount: number;
          description?: string;
          clearableId?: string;
        }>;
      }>;
    };
  };
};

export type CreateDraftBatchMutationVariables = Exact<{
  input: CreateDraftJeBatchInput;
}>;

export type CreateDraftBatchMutation = {
  __typename?: 'Mutation';
  createDraftBatch: {
    __typename?: 'CreateDraftJEBatchPayload';
    success?: boolean;
    error?: string;
    batch?: {
      __typename?: 'JEDraftBatch';
      id: string;
      name: string;
      presetId?: string;
      posted?: string;
      prototypes?: Array<{
        __typename?: 'JEPrototype';
        id: string;
        fields?: Array<{
          __typename?: 'JEPrototypeField';
          id: string;
          groupId?: string;
          label: string;
          type?: BooksFieldType;
          order?: number;
          editable?: boolean;
          value?: string;
          formula?: string;
          secondary?: boolean;
        }>;
      }>;
    };
  };
};

export type GetBatchQueryVariables = Exact<{
  batchId: Scalars['ID']['input'];
}>;

export type GetBatchQuery = {
  __typename?: 'Query';
  batch?: {
    __typename?: 'Batch';
    id: string;
    name?: string;
    presetId?: string;
    posted?: string;
    status?: string;
    errorMessage?: string;
  };
};

export type GetBatchPrototypesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  batchId: Scalars['ID']['input'];
}>;

export type GetBatchPrototypesQuery = {
  __typename?: 'Query';
  batch?: {
    __typename?: 'Batch';
    id: string;
    name?: string;
    presetId?: string;
    posted?: string;
    status?: string;
    errorMessage?: string;
    listPrototypes?: {
      __typename?: 'JEPrototypeConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'JEPrototype';
        id: string;
        omit?: boolean;
        fields?: Array<{
          __typename?: 'JEPrototypeField';
          id: string;
          groupId?: string;
          label: string;
          type?: BooksFieldType;
          order?: number;
          editable?: boolean;
          value?: string;
          formula?: string;
          secondary?: boolean;
        }>;
      }>;
    };
  };
};

export type UpdateBatchPrototypeMutationVariables = Exact<{
  input: JePrototypeInput;
}>;

export type UpdateBatchPrototypeMutation = {
  __typename?: 'Mutation';
  updateBatchPrototype: {
    __typename?: 'BatchPrototypePayload';
    success?: boolean;
    error?: string;
    prototype?: {
      __typename?: 'JEPrototype';
      id: string;
      omit?: boolean;
      fields?: Array<{
        __typename?: 'JEPrototypeField';
        id: string;
        groupId?: string;
        label: string;
        type?: BooksFieldType;
        order?: number;
        editable?: boolean;
        value?: string;
        formula?: string;
        secondary?: boolean;
      }>;
    };
  };
};

export type ToggleBatchPrototypesMutationVariables = Exact<{
  input: JePrototypeKeysInput;
}>;

export type ToggleBatchPrototypesMutation = {
  __typename?: 'Mutation';
  toggleBatchPrototypes: {
    __typename?: 'JEPrototypeKeysPayload';
    success?: boolean;
    error?: string;
    prototypeKeys?: Array<{ __typename?: 'JEPrototypeKey'; id: string; batchId: string }>;
  };
};

export type InitiateBooksBatchMutationVariables = Exact<{
  input: InitiateBooksBatchInput;
}>;

export type InitiateBooksBatchMutation = {
  __typename?: 'Mutation';
  initiateBooksBatch: {
    __typename?: 'BooksBatchPayload';
    success?: boolean;
    error?: string;
    batch?: {
      __typename?: 'Batch';
      id: string;
      name?: string;
      presetId?: string;
      posted?: string;
      status?: string;
      errorMessage?: string;
    };
  };
};

export type CancelBooksBatchMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CancelBooksBatchMutation = {
  __typename?: 'Mutation';
  cancelBooksBatch?: {
    __typename?: 'BooksBatchPayload';
    success?: boolean;
    error?: string;
    batch?: {
      __typename?: 'Batch';
      id: string;
      name?: string;
      presetId?: string;
      posted?: string;
      status?: string;
      errorMessage?: string;
    };
  };
};

export type CommitBooksBatchMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CommitBooksBatchMutation = {
  __typename?: 'Mutation';
  commitBooksBatch?: {
    __typename?: 'BooksBatchPayload';
    success?: boolean;
    error?: string;
    batch?: {
      __typename?: 'Batch';
      id: string;
      name?: string;
      presetId?: string;
      posted?: string;
      status?: string;
      errorMessage?: string;
    };
  };
};

export type GetBooksReportQueryVariables = Exact<{
  input: BooksReportInput;
}>;

export type GetBooksReportQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      report: {
        __typename?: 'BooksReportPayload';
        success?: boolean;
        error?: string;
        docDefinition?: string;
        report?: {
          __typename?: 'BooksReport';
          id: string;
          title?: string;
          subtitle1?: string;
          subtitle2?: string;
          propertyId?: string;
          ownerId?: string;
          range?: string;
          options?: {
            __typename?: 'BooksReportOptions';
            showZeroAmounts?: boolean;
            hideAmountColumn?: boolean;
          };
          grandTotals?: Array<{
            __typename?: 'GrandTotal';
            order?: number;
            amount?: number;
            balanceType?: BalanceType;
            header?: { __typename?: 'BooksReportHeader'; label?: string };
            footer?: { __typename?: 'BooksReportFooter'; label?: string };
            extraColumns?: Array<{
              __typename?: 'BooksReportExtraColumn';
              columnHeader?: string;
              value?: string;
              flex?: string;
              columnType?: ExtraColumnType;
              keepNonZero?: boolean;
            }>;
            sections: Array<{
              __typename?: 'BooksReportRendered';
              order?: number;
              date?: string;
              columnHeader?: string;
              amount?: number;
              balanceType?: BalanceType;
              header?: { __typename?: 'BooksReportHeader'; label?: string };
              footer?: { __typename?: 'BooksReportFooter'; label?: string };
              extraColumns?: Array<{
                __typename?: 'BooksReportExtraColumn';
                columnHeader?: string;
                value?: string;
                flex?: string;
                columnType?: ExtraColumnType;
                keepNonZero?: boolean;
              }>;
              lines?: Array<{
                __typename?: 'BooksReportRendered';
                label?: string;
                order?: number;
                date?: string;
                columnHeader?: string;
                amount?: number;
                maxWidth?: string;
                balanceType?: BalanceType;
                extraColumns?: Array<{
                  __typename?: 'BooksReportExtraColumn';
                  columnHeader?: string;
                  value?: string;
                  flex?: string;
                  columnType?: ExtraColumnType;
                  keepNonZero?: boolean;
                  maxWidth?: string;
                }>;
                action?: {
                  __typename?: 'RenderedAction';
                  glId?: string;
                  ownerId?: string;
                  propertyId?: string;
                };
                header?: { __typename?: 'BooksReportHeader'; label?: string };
                footer?: { __typename?: 'BooksReportFooter'; label?: string };
                lines?: Array<{
                  __typename?: 'BooksReportRendered';
                  label?: string;
                  order?: number;
                  date?: string;
                  amount?: number;
                  maxWidth?: string;
                  balanceType?: BalanceType;
                  extraColumns?: Array<{
                    __typename?: 'BooksReportExtraColumn';
                    columnHeader?: string;
                    value?: string;
                    flex?: string;
                    columnType?: ExtraColumnType;
                    keepNonZero?: boolean;
                    maxWidth?: string;
                  }>;
                  action?: {
                    __typename?: 'RenderedAction';
                    glId?: string;
                    ownerId?: string;
                    propertyId?: string;
                  };
                  header?: { __typename?: 'BooksReportHeader'; label?: string };
                  footer?: { __typename?: 'BooksReportFooter'; label?: string };
                }>;
              }>;
            }>;
          }>;
        };
      };
    };
  };
};

export type GetBooksReportDefinitionQueryVariables = Exact<{
  input: BooksReportInput;
}>;

export type GetBooksReportDefinitionQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      renderReport: {
        __typename?: 'BooksReportPayload';
        success?: boolean;
        error?: string;
        docDefinition?: string;
      };
    };
  };
};

export type UpdatePresetGlMappingMutationVariables = Exact<{
  input: UpdatePresetGlMappingInput;
}>;

export type UpdatePresetGlMappingMutation = {
  __typename?: 'Mutation';
  updatePresetGLMapping: {
    __typename?: 'PresetPayload';
    success: boolean;
    error?: string;
    preset?: {
      __typename?: 'Preset';
      id: string;
      type: PresetType;
      version?: BooksReportVersion;
      category?: PresetCategory;
      name: string;
      visibleExpression?: string;
      description: string;
      lastPosted?: string;
      roles?: Array<ManagerRole>;
      group?: { __typename?: 'PresetGroup'; name: string; order?: number };
      glMapping?: Array<{
        __typename?: 'GLMapping';
        id: string;
        parentId: string;
        name: string;
        description: string;
        default: string;
        filter?: string;
        glId?: string;
      }>;
      inputFields?: Array<{
        __typename?: 'PresetInputField';
        id: string;
        label: string;
        type: BooksFieldType;
        required?: boolean;
        defaultExpression?: string;
        options?: Array<{ __typename?: 'PresetOption'; label: string; value: string }>;
      }>;
      recurrence?: {
        __typename?: 'PresetRecurrence';
        enabled: boolean;
        locked?: boolean;
        rrule: string;
        postedOffset: number;
        parameters: string;
      };
      icon?: { __typename?: 'PresetIcon'; name?: string; color?: string };
    };
  };
};

export type UpdateGlChequePlacementMutationVariables = Exact<{
  input: GlChequePlacementInput;
}>;

export type UpdateGlChequePlacementMutation = {
  __typename?: 'Mutation';
  updateGLChequePlacement: {
    __typename?: 'BooksPayload';
    success?: boolean;
    error?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      printedCheques?: Array<{
        __typename?: 'BooksPrintedCheque';
        glId: string;
        chequePlacement?: ChequePlacement;
      }>;
    };
  };
};

export type VoidJournalEntryMutationVariables = Exact<{
  input: VoidJournalEntryInput;
}>;

export type VoidJournalEntryMutation = {
  __typename?: 'Mutation';
  voidJournalEntry?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type EditableGlAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type EditableGlAccountsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: { __typename?: 'Books'; accountId: string; editableGlAccounts: Array<string> };
  };
};

export type ClearablePropertyQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type ClearablePropertyQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    autopayCollectionsEnabled?: boolean;
    autopayCollectionsEnabledZ?: string;
  };
};

export type OwnerGstQueryVariables = Exact<{
  ownerId: Scalars['ID']['input'];
}>;

export type OwnerGstQuery = {
  __typename?: 'Query';
  owner?: { __typename?: 'Owner'; id: string; gstNumber?: string };
};

export type SetClearableAutopayCollectionsEnabledMutationVariables = Exact<{
  input: SetClearableAutopayCollectionsEnabledInput;
}>;

export type SetClearableAutopayCollectionsEnabledMutation = {
  __typename?: 'Mutation';
  setClearableAutopayCollectionsEnabled: {
    __typename?: 'ClearablePayload';
    success?: boolean;
    error?: string;
    clearable?: { __typename?: 'Clearable'; id: string; autopayCollectionsEnabled?: boolean };
  };
};

export type GetChargeApprovalsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetChargeApprovalsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listActiveApprovals?: {
      __typename?: 'ApprovalConnection';
      nextToken?: string;
      items?: Array<
        | {
            __typename: 'Approval';
            id: string;
            accountId: string;
            approverRoles?: Array<ApproverRole>;
            status: ApprovalStatus;
            requestedName?: string;
            requestedZ: string;
            charge: { __typename?: 'Charge'; id: string; name: string };
            request: {
              __typename?: 'ChargeApprovalRequest';
              date: string;
              amount: number;
              chargeId: string;
              payeeId: string;
              payee: PayeeType;
              ownerId: string;
              propertyId: string;
              unitId?: string;
              applyGst?: boolean;
              applyPst?: boolean;
              note?: string;
              unitName?: string;
              buildingName?: string;
              propertyKey?: string;
              propertyName?: string;
              payeeName?: string;
              ownerName?: string;
            };
          }
        | {
            __typename: 'MaintenanceApproval';
            id: string;
            accountId: string;
            approverRoles?: Array<ApproverRole>;
            status: ApprovalStatus;
            requestedName?: string;
            requestedZ: string;
          }
        | {
            __typename: 'PaymentsApproval';
            id: string;
            accountId: string;
            approverRoles?: Array<ApproverRole>;
            status: ApprovalStatus;
            requestedName?: string;
            requestedZ: string;
          }
        | {
            __typename: 'ScanApproval';
            id: string;
            accountId: string;
            approverRoles?: Array<ApproverRole>;
            status: ApprovalStatus;
            requestedName?: string;
            requestedZ: string;
          }
      >;
    };
  };
};

export type ApproveChargeApprovalMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ApproveChargeApprovalMutation = {
  __typename?: 'Mutation';
  approveChargeApproval?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type DeclineChargeApprovalMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  declinedComment?: InputMaybe<Scalars['String']['input']>;
}>;

export type DeclineChargeApprovalMutation = {
  __typename?: 'Mutation';
  declineChargeApproval?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type PaymentsApprovalsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type PaymentsApprovalsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listActiveApprovals?: {
      __typename?: 'ApprovalConnection';
      nextToken?: string;
      items?: Array<
        | {
            __typename: 'Approval';
            id: string;
            accountId: string;
            approverRoles?: Array<ApproverRole>;
            status: ApprovalStatus;
            requestedName?: string;
            requestedZ: string;
          }
        | {
            __typename: 'MaintenanceApproval';
            id: string;
            accountId: string;
            approverRoles?: Array<ApproverRole>;
            status: ApprovalStatus;
            requestedName?: string;
            requestedZ: string;
          }
        | {
            __typename: 'PaymentsApproval';
            id: string;
            accountId: string;
            approverRoles?: Array<ApproverRole>;
            status: ApprovalStatus;
            requestedName?: string;
            requestedZ: string;
            request: {
              __typename?: 'PaymentsApprovalRequest';
              total: number;
              batchId: string;
              posted: string;
              paymentMethod?: PaymentMethod;
              itemCount: number;
            };
          }
        | {
            __typename: 'ScanApproval';
            id: string;
            accountId: string;
            approverRoles?: Array<ApproverRole>;
            status: ApprovalStatus;
            requestedName?: string;
            requestedZ: string;
          }
      >;
    };
  };
};

export type ListRequestedPaymentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListRequestedPaymentsQuery = {
  __typename?: 'Query';
  approval?:
    | { __typename: 'Approval'; id: string }
    | { __typename: 'MaintenanceApproval'; id: string }
    | {
        __typename: 'PaymentsApproval';
        id: string;
        request: {
          __typename?: 'PaymentsApprovalRequest';
          listPayments?: {
            __typename?: 'PaymentsPrototypeConnection';
            nextToken?: string;
            items?: Array<{
              __typename?: 'PaymentPrototype';
              id: string;
              batchId: string;
              posted: string;
              amount: number;
              paymentMethod?: PaymentMethod;
              bankId: string;
              bankName?: string;
              payees: Array<{
                __typename?: 'PaymentPayee';
                payeeId: string;
                payee: PayeeType;
                payeeName?: string;
                ref?: string;
                description?: string;
                glId: string;
                payeeClearingAmounts: Array<{
                  __typename?: 'PayeeClearingAmount';
                  clearableId: string;
                  glId: string;
                  amount: number;
                  ownerId: string;
                  propertyId: string;
                  unitId?: string;
                  ref?: string;
                  description?: string;
                  posted?: string;
                  due?: string;
                  propertyKey?: string;
                  propertyName?: string;
                  buildingKey?: string;
                  buildingName?: string;
                  unitName?: string;
                  ownerName?: string;
                }>;
              }>;
            }>;
          };
        };
      }
    | { __typename: 'ScanApproval'; id: string };
};

export type ApprovePaymentsApprovalMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ApprovePaymentsApprovalMutation = {
  __typename?: 'Mutation';
  approvePaymentsApproval?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type DeclinePaymentsApprovalMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  declinedComment?: InputMaybe<Scalars['String']['input']>;
}>;

export type DeclinePaymentsApprovalMutation = {
  __typename?: 'Mutation';
  declinePaymentsApproval?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type ScanApprovalFieldsFragment = {
  __typename?: 'ScanApproval';
  request: {
    __typename?: 'ScanApprovalRequest';
    amount: number;
    description?: string;
    due?: string;
    expenseGlId?: string;
    gstOrHstAllocation?: number;
    includesGstOrHst?: boolean;
    key: string;
    ownerId: string;
    payee: string;
    payeeId: string;
    payeeName: string;
    posted: string;
    propertyId: string;
    ref?: string;
    scanType?: ScanType;
    supplierAccount?: string;
    lines?: Array<{
      __typename?: 'ScanApprovalLine';
      glId: string;
      amount: number;
      description: string;
      unitId?: string;
    }>;
  };
};

export type ScanApprovalsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ScanApprovalsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listActiveApprovals?: {
      __typename?: 'ApprovalConnection';
      nextToken?: string;
      items?: Array<
        | {
            __typename: 'Approval';
            id: string;
            accountId: string;
            approverRoles?: Array<ApproverRole>;
            status: ApprovalStatus;
            requestedName?: string;
            requestedZ: string;
          }
        | {
            __typename: 'MaintenanceApproval';
            id: string;
            accountId: string;
            approverRoles?: Array<ApproverRole>;
            status: ApprovalStatus;
            requestedName?: string;
            requestedZ: string;
          }
        | {
            __typename: 'PaymentsApproval';
            id: string;
            accountId: string;
            approverRoles?: Array<ApproverRole>;
            status: ApprovalStatus;
            requestedName?: string;
            requestedZ: string;
          }
        | {
            __typename: 'ScanApproval';
            id: string;
            accountId: string;
            approverRoles?: Array<ApproverRole>;
            status: ApprovalStatus;
            requestedName?: string;
            requestedZ: string;
            request: {
              __typename?: 'ScanApprovalRequest';
              amount: number;
              description?: string;
              due?: string;
              expenseGlId?: string;
              gstOrHstAllocation?: number;
              includesGstOrHst?: boolean;
              key: string;
              ownerId: string;
              payee: string;
              payeeId: string;
              payeeName: string;
              posted: string;
              propertyId: string;
              ref?: string;
              scanType?: ScanType;
              supplierAccount?: string;
              lines?: Array<{
                __typename?: 'ScanApprovalLine';
                glId: string;
                amount: number;
                description: string;
                unitId?: string;
              }>;
            };
          }
      >;
    };
  };
};

export type GetScanApprovalQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetScanApprovalQuery = {
  __typename?: 'Query';
  approval?:
    | {
        __typename: 'Approval';
        id: string;
        accountId: string;
        approverRoles?: Array<ApproverRole>;
        status: ApprovalStatus;
        requestedName?: string;
        requestedZ: string;
      }
    | {
        __typename: 'MaintenanceApproval';
        id: string;
        accountId: string;
        approverRoles?: Array<ApproverRole>;
        status: ApprovalStatus;
        requestedName?: string;
        requestedZ: string;
      }
    | {
        __typename: 'PaymentsApproval';
        id: string;
        accountId: string;
        approverRoles?: Array<ApproverRole>;
        status: ApprovalStatus;
        requestedName?: string;
        requestedZ: string;
      }
    | {
        __typename: 'ScanApproval';
        id: string;
        accountId: string;
        approverRoles?: Array<ApproverRole>;
        status: ApprovalStatus;
        requestedName?: string;
        requestedZ: string;
        request: {
          __typename?: 'ScanApprovalRequest';
          amount: number;
          description?: string;
          due?: string;
          expenseGlId?: string;
          gstOrHstAllocation?: number;
          includesGstOrHst?: boolean;
          key: string;
          ownerId: string;
          payee: string;
          payeeId: string;
          payeeName: string;
          posted: string;
          propertyId: string;
          ref?: string;
          scanType?: ScanType;
          supplierAccount?: string;
          lines?: Array<{
            __typename?: 'ScanApprovalLine';
            glId: string;
            amount: number;
            description: string;
            unitId?: string;
          }>;
        };
      };
};

export type ApproveScanApprovalMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ApproveScanApprovalMutation = {
  __typename?: 'Mutation';
  approveScanApproval?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type DeclineScanApprovalMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  declinedComment?: InputMaybe<Scalars['String']['input']>;
}>;

export type DeclineScanApprovalMutation = {
  __typename?: 'Mutation';
  declineScanApproval?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type UpdateScanApprovalRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateScanApprovalRequestInput;
}>;

export type UpdateScanApprovalRequestMutation = {
  __typename?: 'Mutation';
  updateScanApprovalRequest?: {
    __typename?: 'ScanApprovalPayload';
    success?: boolean;
    error?: string;
    approval?: {
      __typename?: 'ScanApproval';
      id: string;
      request: {
        __typename?: 'ScanApprovalRequest';
        amount: number;
        description?: string;
        due?: string;
        expenseGlId?: string;
        gstOrHstAllocation?: number;
        includesGstOrHst?: boolean;
        key: string;
        ownerId: string;
        payee: string;
        payeeId: string;
        payeeName: string;
        posted: string;
        propertyId: string;
        ref?: string;
        scanType?: ScanType;
        supplierAccount?: string;
        lines?: Array<{
          __typename?: 'ScanApprovalLine';
          glId: string;
          amount: number;
          description: string;
          unitId?: string;
        }>;
      };
    };
  };
};

export type AddScanApprovalTaskMutationVariables = Exact<{
  input: AddScanApprovalTaskInput;
}>;

export type AddScanApprovalTaskMutation = {
  __typename?: 'Mutation';
  addScanApprovalTask: {
    __typename?: 'BatchTaskPayload';
    success?: boolean;
    error?: string;
    task?: {
      __typename?: 'BatchTask';
      id: string;
      taskProgress: {
        __typename?: 'BatchTaskProgress';
        processed: number;
        total: number;
        label: string;
        done?: boolean;
      };
    };
  };
};

export type AddScanMatchingTaskMutationVariables = Exact<{
  input: AddScanMatchingTaskInput;
}>;

export type AddScanMatchingTaskMutation = {
  __typename?: 'Mutation';
  addScanMatchingTask: {
    __typename?: 'BatchTaskPayload';
    success?: boolean;
    error?: string;
    task?: {
      __typename?: 'BatchTask';
      id: string;
      taskProgress: {
        __typename?: 'BatchTaskProgress';
        processed: number;
        total: number;
        label: string;
        done?: boolean;
      };
    };
  };
};

export type ActivatedEnrollmentIdsQueryVariables = Exact<{ [key: string]: never }>;

export type ActivatedEnrollmentIdsQuery = {
  __typename?: 'Query';
  account?: { __typename?: 'Account'; id: string; activatedEnrollmentIds?: string };
};

export type PagePropertiesQueryVariables = Exact<{ [key: string]: never }>;

export type PagePropertiesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageProperties?: {
      __typename?: 'PropertiesPage';
      edges: Array<{
        __typename?: 'PropertyEdge';
        node: {
          __typename?: 'Property';
          id: string;
          key?: string;
          name: string;
          autopayCollectionsEnabled?: boolean;
          autopayCollectionsEnabledZ?: string;
        };
      }>;
    };
  };
};

export type ServerClearablesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<ClearablesFilterInput>;
  sort?: InputMaybe<Array<ClearablesSortInput> | ClearablesSortInput>;
}>;

export type ServerClearablesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      pageClearables?: {
        __typename?: 'ClearablesPage';
        totalAmount?: number;
        totalCount: number;
        totalOutstanding?: number;
        creditOutstanding?: number;
        debitOutstanding?: number;
        pageInfo: { __typename?: 'RelayPageInfo'; endCursor?: string; hasNextPage: boolean };
        edges: Array<{
          __typename?: 'ClearableEdge';
          cursor: string;
          node: {
            __typename?: 'Clearable';
            payeeName?: string;
            ownerName?: string;
            propertyKey?: string;
            propertyName?: string;
            buildingKey?: string;
            buildingName?: string;
            unitName?: string;
            payeeAutopayClientId?: string;
            payeeAutopayStatus?: string;
            payeeAutopayCollectionsEnabled?: boolean;
            autopaySinglePosted?: string;
            id: string;
            due: string;
            posted: string;
            glId: string;
            ownerId: string;
            sourceJournalId: string;
            payeeId: string;
            unitId?: string;
            propertyId: string;
            payee: PayeeType;
            amount: number;
            outstanding: number;
            cleared?: string;
            ref?: string;
            description?: string;
            balanceType: BalanceType;
            batchId?: string;
            refund?: boolean;
            enrollmentId?: string;
            fixedEnrollmentId?: string;
            paymentsStatus?: PaymentStatus;
            autopayCollectionsEnabled?: boolean;
          };
        }>;
      };
    };
  };
};

export type PayeeOwnersQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type PayeeOwnersQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listOwners?: {
      __typename?: 'OwnerConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        enabled?: boolean;
        propertyIds?: Array<string>;
        unitIds?: Array<string>;
        preferredPaymentMethod?: PaymentMethod;
        padAmount?: number;
        address?: {
          __typename?: 'Address';
          city?: string;
          postal?: string;
          province?: string;
          suite?: string;
          street?: string;
        };
        propertyHeaders?: Array<{
          __typename?: 'PropertyHeader';
          id: string;
          name?: string;
          key?: string;
        }>;
        unitHeaders?: Array<{
          __typename?: 'UnitHeader';
          id: string;
          propertyId: string;
          name?: string;
          occupancy?: UnitOccupancy;
        }>;
        history?: Array<{
          __typename?: 'OwnerHistoryEntry';
          assetType: AssetType;
          assetId: string;
        }>;
      }>;
    };
  };
};

export type JournalPostedDetailFragment = {
  __typename?: 'JournalEntry';
  id: string;
  jeId: string;
  journalIds?: Array<string>;
  glId: string;
  propertyId: string;
  ownerId: string;
  clearableId?: string;
  unitId?: string;
  posted: string;
  amount: number;
  description?: string;
  ref?: string;
  lines: Array<{
    __typename?: 'JournalEntryLine';
    id?: string;
    glId: string;
    ownerId: string;
    propertyId: string;
    unitId?: string;
    amount: number;
    description?: string;
    ref?: string;
    clearableId?: string;
    requestId?: string;
    payee?: PayeeType;
    payeeId?: string;
  }>;
};

export type AccountChargesFieldsFragment = {
  __typename?: 'Account';
  id: string;
  books?: {
    __typename?: 'Books';
    accountId: string;
    chargeMapping?: Array<{ __typename?: 'ChargeMapping'; chargeId: string; glId: string }>;
  };
  charges?: Array<{
    __typename?: 'Charge';
    id: string;
    name: string;
    default?: number;
    limit?: number;
  }>;
};

export type CreateChargeMutationVariables = Exact<{
  input: CreateChargeInput;
}>;

export type CreateChargeMutation = {
  __typename?: 'Mutation';
  createCharge?: {
    __typename?: 'AccountPayload';
    success?: boolean;
    error?: string;
    account?: {
      __typename?: 'Account';
      id: string;
      books?: {
        __typename?: 'Books';
        accountId: string;
        chargeMapping?: Array<{ __typename?: 'ChargeMapping'; chargeId: string; glId: string }>;
      };
      charges?: Array<{
        __typename?: 'Charge';
        id: string;
        name: string;
        default?: number;
        limit?: number;
      }>;
    };
  };
};

export type DeleteChargeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteChargeMutation = {
  __typename?: 'Mutation';
  deleteCharge?: {
    __typename?: 'AccountPayload';
    success?: boolean;
    error?: string;
    account?: {
      __typename?: 'Account';
      id: string;
      books?: {
        __typename?: 'Books';
        accountId: string;
        chargeMapping?: Array<{ __typename?: 'ChargeMapping'; chargeId: string; glId: string }>;
      };
      charges?: Array<{
        __typename?: 'Charge';
        id: string;
        name: string;
        default?: number;
        limit?: number;
      }>;
    };
  };
};

export type AddClearingEntryBatchTaskMutationVariables = Exact<{
  input: AddClearingEntryTaskInput;
}>;

export type AddClearingEntryBatchTaskMutation = {
  __typename?: 'Mutation';
  addClearingEntryTask: {
    __typename?: 'BatchTaskPayload';
    success?: boolean;
    error?: string;
    task?: {
      __typename?: 'BatchTask';
      id: string;
      taskProgress: {
        __typename?: 'BatchTaskProgress';
        processed: number;
        total: number;
        label: string;
        done?: boolean;
      };
    };
  };
};

export type AbortBooksBatchTaskMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
}>;

export type AbortBooksBatchTaskMutation = {
  __typename?: 'Mutation';
  abortBooksBatchTask: {
    __typename?: 'BatchTaskPayload';
    success?: boolean;
    error?: string;
    task?: {
      __typename?: 'BatchTask';
      id: string;
      taskProgress: {
        __typename?: 'BatchTaskProgress';
        processed: number;
        total: number;
        label: string;
        done?: boolean;
      };
    };
  };
};

export type UpdateClearingEntryBatchTaskMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateClearingEntryTaskInput;
}>;

export type UpdateClearingEntryBatchTaskMutation = {
  __typename?: 'Mutation';
  updateClearingEntryTask: {
    __typename?: 'BatchTaskPayload';
    success?: boolean;
    error?: string;
    task?: {
      __typename?: 'BatchTask';
      id: string;
      taskProgress: {
        __typename?: 'BatchTaskProgress';
        processed: number;
        total: number;
        label: string;
        done?: boolean;
      };
    };
  };
};

export type CreatePaymentsBatchMutationVariables = Exact<{
  input: CreatePaymentsBatchInput;
}>;

export type CreatePaymentsBatchMutation = {
  __typename?: 'Mutation';
  createPaymentsBatch: {
    __typename?: 'PaymentsBatchPayload';
    success?: boolean;
    error?: string;
    paymentsBatch?: {
      __typename?: 'PaymentsBatch';
      id: string;
      presetId: string;
      total: number;
      itemCount: number;
      posted: string;
      status?: string;
      paymentMethod?: PaymentMethod;
      errorMessage?: string;
    };
  };
};

export type AddPaymentsBatchPrototypesMutationVariables = Exact<{
  inputs: Array<PaymentPrototypeInput> | PaymentPrototypeInput;
}>;

export type AddPaymentsBatchPrototypesMutation = {
  __typename?: 'Mutation';
  addPaymentsBatchPrototypes: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type CreatePaymentsApprovalMutationVariables = Exact<{
  input: CreatePaymentsApprovalInput;
}>;

export type CreatePaymentsApprovalMutation = {
  __typename?: 'Mutation';
  createPaymentsApproval?: {
    __typename?: 'PaymentsApprovalPayload';
    success?: boolean;
    error?: string;
    approval?: { __typename?: 'PaymentsApproval'; id: string };
  };
};

export type UpdatePaymentsBatchMutationVariables = Exact<{
  input: UpdatePaymentsBatchInput;
}>;

export type UpdatePaymentsBatchMutation = {
  __typename?: 'Mutation';
  updatePaymentsBatch: {
    __typename?: 'PaymentsBatchPayload';
    success?: boolean;
    error?: string;
    paymentsBatch?: {
      __typename?: 'PaymentsBatch';
      id: string;
      status?: string;
      approvalId?: string;
    };
  };
};

export type GetCombinedSubAccountBalanceQueryVariables = Exact<{
  glId: Scalars['ID']['input'];
  ownerIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  propertyIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  balanceDate?: InputMaybe<Scalars['AWSDate']['input']>;
}>;

export type GetCombinedSubAccountBalanceQuery = {
  __typename?: 'Query';
  glAccount?: { __typename?: 'GLAccount'; id: string; combinedBalance?: number };
};

export type CommitReconciliationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CommitReconciliationMutation = {
  __typename?: 'Mutation';
  completeReconciliation?: {
    __typename?: 'ReconciliationPayload';
    success?: boolean;
    error?: string;
    reconciliation?: { __typename?: 'Reconciliation'; id: string; status: ReconciliationStatus };
  };
};

export type DraftReconciliationMutationVariables = Exact<{
  input: DraftReconciliationInput;
}>;

export type DraftReconciliationMutation = {
  __typename?: 'Mutation';
  draftReconciliation?: {
    __typename?: 'DraftReconciliationPayload';
    success?: boolean;
    error?: string;
    reconciliation?: {
      __typename?: 'Reconciliation';
      id: string;
      name?: string;
      current?: boolean;
      configuration: string;
      statementDate: string;
      status: ReconciliationStatus;
      errorMessage?: string;
      statementBalance?: number;
      lastStatementBalance?: number;
      lastReconciliationId?: string;
      booksBatchId?: string;
      reindexToken?: string;
      completedZ?: string;
      documentKey?: string;
      ledgerDocumentKey?: string;
      scope: { __typename?: 'ReconciliationScope'; glId: string; propertyOwnerIds?: Array<string> };
      glAccount?: { __typename?: 'GLAccount'; id: string; name: string };
    };
  };
};

export type OwnerEntityNamesQueryVariables = Exact<{
  ownerIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type OwnerEntityNamesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageOwners?: {
      __typename?: 'OwnersPage';
      edges: Array<{
        __typename?: 'OwnerEdge';
        node: { __typename?: 'Owner'; id: string; name: string };
      }>;
    };
  };
};

export type PropertyEntityNamesQueryVariables = Exact<{
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type PropertyEntityNamesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageProperties?: {
      __typename?: 'PropertiesPage';
      edges: Array<{
        __typename?: 'PropertyEdge';
        node: { __typename?: 'Property'; id: string; name: string; key?: string };
      }>;
    };
  };
};

export type GetReconciliationJournalsQueryVariables = Exact<{
  filter: JournalRecFilter;
}>;

export type GetReconciliationJournalsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      listJournalEntriesForReconciliation: {
        __typename?: 'JournalForReconciliationPayload';
        error?: string;
        success?: boolean;
        journalEntries?: Array<string>;
      };
    };
  };
};

export type NotifyPayeePaymentReturnedMutationVariables = Exact<{
  input: NotifyNsfPayeeInput;
}>;

export type NotifyPayeePaymentReturnedMutation = {
  __typename?: 'Mutation';
  notifyNsfPayee?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type AccountPayeeNameQueryVariables = Exact<{ [key: string]: never }>;

export type AccountPayeeNameQuery = {
  __typename?: 'Query';
  account?: { __typename?: 'Account'; id: string; name: string };
};

export type OperatorPayeeNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OperatorPayeeNameQuery = {
  __typename?: 'Query';
  operator?: { __typename?: 'Operator'; id: string; name: string };
};

export type OwnerPayeeNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OwnerPayeeNameQuery = {
  __typename?: 'Query';
  owner?: { __typename?: 'Owner'; id: string; name: string };
};

export type SupplierPayeeNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SupplierPayeeNameQuery = {
  __typename?: 'Query';
  supplier?: { __typename?: 'Supplier'; id: string; name: string };
};

export type TenantPayeeNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TenantPayeeNameQuery = {
  __typename?: 'Query';
  tenant?: { __typename?: 'Tenant'; id: string; name: string };
};

export type JournalEntriesForPayeeRefQueryVariables = Exact<{
  filter: PayeeRefFilter;
}>;

export type JournalEntriesForPayeeRefQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      journalEntriesForPayeeRef?: Array<{
        __typename?: 'JournalEntry';
        id: string;
        glId: string;
        posted: string;
        amount: number;
      }>;
    };
  };
};

export type UpdatePresetRecurrenceMutationVariables = Exact<{
  input: UpdatePresetRecurrenceInput;
}>;

export type UpdatePresetRecurrenceMutation = {
  __typename?: 'Mutation';
  updatePresetRecurrence: {
    __typename?: 'PresetPayload';
    success: boolean;
    error?: string;
    preset?: {
      __typename?: 'Preset';
      id: string;
      type: PresetType;
      version?: BooksReportVersion;
      category?: PresetCategory;
      name: string;
      visibleExpression?: string;
      description: string;
      lastPosted?: string;
      roles?: Array<ManagerRole>;
      group?: { __typename?: 'PresetGroup'; name: string; order?: number };
      glMapping?: Array<{
        __typename?: 'GLMapping';
        id: string;
        parentId: string;
        name: string;
        description: string;
        default: string;
        filter?: string;
        glId?: string;
      }>;
      inputFields?: Array<{
        __typename?: 'PresetInputField';
        id: string;
        label: string;
        type: BooksFieldType;
        required?: boolean;
        defaultExpression?: string;
        options?: Array<{ __typename?: 'PresetOption'; label: string; value: string }>;
      }>;
      recurrence?: {
        __typename?: 'PresetRecurrence';
        enabled: boolean;
        locked?: boolean;
        rrule: string;
        postedOffset: number;
        parameters: string;
      };
      icon?: { __typename?: 'PresetIcon'; name?: string; color?: string };
    };
  };
};

export type BooksOwnersQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type BooksOwnersQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      listOwners?: {
        __typename?: 'OwnerConnection';
        nextToken?: string;
        items?: Array<{
          __typename?: 'Owner';
          id: string;
          name: string;
          email?: string;
          phones?: Array<string>;
          enabled?: boolean;
          booksSinceZ?: string;
          propertyIds?: Array<string>;
          unitIds?: Array<string>;
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          profileId?: string;
          autopayClientId?: string;
          autopayStatus?: string;
          address?: {
            __typename?: 'Address';
            city?: string;
            postal?: string;
            province?: string;
            suite?: string;
            street?: string;
          };
          propertyHeaders?: Array<{
            __typename?: 'PropertyHeader';
            id: string;
            name?: string;
            key?: string;
          }>;
          unitHeaders?: Array<{
            __typename?: 'UnitHeader';
            id: string;
            propertyId: string;
            propertyName?: string;
            name?: string;
            occupancy?: UnitOccupancy;
          }>;
          history?: Array<{
            __typename?: 'OwnerHistoryEntry';
            assetType: AssetType;
            assetId: string;
            leasedUnits?: number;
            commercialLeasedUnits?: number;
          }>;
          units?: Array<{
            __typename?: 'Unit';
            id: string;
            paymentDetails?: {
              __typename?: 'PaymentDetails';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
            };
          }>;
        }>;
      };
    };
  };
};

export type GetReconciliationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetReconciliationQuery = {
  __typename?: 'Query';
  reconciliation?: {
    __typename?: 'Reconciliation';
    id: string;
    name?: string;
    current?: boolean;
    configuration: string;
    statementDate: string;
    status: ReconciliationStatus;
    errorMessage?: string;
    statementBalance?: number;
    lastStatementBalance?: number;
    lastReconciliationId?: string;
    booksBatchId?: string;
    reindexToken?: string;
    completedZ?: string;
    documentKey?: string;
    ledgerDocumentKey?: string;
    scope: { __typename?: 'ReconciliationScope'; glId: string; propertyOwnerIds?: Array<string> };
    glAccount?: { __typename?: 'GLAccount'; id: string; name: string };
  };
};

export type GetReconciliationDocumentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetReconciliationDocumentQuery = {
  __typename?: 'Query';
  reconciliation?: {
    __typename?: 'Reconciliation';
    id: string;
    documentKey?: string;
    documentError?: string;
    documentGeneratedZ?: string;
    ledgerDocumentKey?: string;
    ledgerDocumentError?: string;
    ledgerDocumentGeneratedZ?: string;
  };
};

export type ServerDraftedJournalsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['ID']['input']>;
  filter: DraftedJournalFilter;
}>;

export type ServerDraftedJournalsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      pageDraftedJournalEntries: {
        __typename?: 'DraftedJournalEntriesPage';
        totalCount: number;
        clearedCount: number;
        totalCleared: number;
        clearedDepositsCount: number;
        totalDepositsCleared: number;
        clearedWithdrawalsCount: number;
        totalWithdrawalsCleared: number;
        totalUncleared: number;
        unclearedDepositsCount: number;
        totalDepositsUncleared: number;
        unclearedWithdrawalsCount: number;
        totalWithdrawalsUncleared: number;
        pageInfo: { __typename?: 'RelayPageInfo'; hasNextPage: boolean; endCursor?: string };
        edges: Array<{
          __typename?: 'DraftedJournalEntryEdge';
          cursor: string;
          node: {
            __typename?: 'DraftedJournalEntry';
            id: string;
            posted: string;
            amount: number;
            checked?: boolean;
          };
        }>;
      };
    };
  };
};

export type ServerSubAccountQueryVariables = Exact<{
  glId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  ownerIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  range?: InputMaybe<PostedRangeInput>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type ServerSubAccountQuery = {
  __typename?: 'Query';
  glAccount?: {
    __typename?: 'GLAccount';
    id: string;
    pageJournalEntries?: {
      __typename?: 'JournalEntriesPage';
      totalCount: number;
      endingBalance: number;
      pageInfo: { __typename?: 'RelayPageInfo'; endCursor?: string; hasNextPage: boolean };
      edges: Array<{
        __typename?: 'JournalEntryEdge';
        cursor: string;
        node: {
          __typename?: 'JournalEntry';
          payeeName?: string;
          ownerName?: string;
          propertyKey?: string;
          propertyName?: string;
          buildingKey?: string;
          buildingName?: string;
          unitName?: string;
          id: string;
          ownerId: string;
          glId: string;
          jeId: string;
          unitId?: string;
          amount: number;
          description?: string;
          posted: string;
          propertyId: string;
          paymentMethod?: PaymentMethod;
          ref?: string;
          gstInfo?: GstInfo;
          printedZ?: string;
          voidedZ?: string;
          requestId?: string;
          payee?: PayeeType;
          payeeId?: string;
          clearableId?: string;
          clearableLinked?: boolean;
          reconciliationId?: string;
          approverName?: string;
          lines: Array<{
            __typename?: 'JournalEntryLine';
            id?: string;
            glId: string;
            ownerId: string;
            propertyId: string;
            unitId?: string;
            amount: number;
            description?: string;
            clearableId?: string;
          }>;
        };
      }>;
    };
  };
};

export type CombinedSubAccountQueryVariables = Exact<{
  glId: Scalars['ID']['input'];
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  ownerIds?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>
  >;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  propertyIds?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>
  >;
  from?: InputMaybe<Scalars['AWSDate']['input']>;
  to?: InputMaybe<Scalars['AWSDate']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type CombinedSubAccountQuery = {
  __typename?: 'Query';
  glAccount?: {
    __typename?: 'GLAccount';
    id: string;
    combinedSubAccount?: {
      __typename?: 'CombinedSubAccount';
      balance: number;
      journalEntries?: {
        __typename?: 'JournalEntryConnection';
        nextToken?: string;
        items?: Array<{
          __typename?: 'JournalEntry';
          id: string;
          ownerId: string;
          glId: string;
          jeId: string;
          unitId?: string;
          amount: number;
          description?: string;
          posted: string;
          propertyId: string;
          paymentMethod?: PaymentMethod;
          ref?: string;
          gstInfo?: GstInfo;
          printedZ?: string;
          voidedZ?: string;
          requestId?: string;
          payee?: PayeeType;
          payeeId?: string;
          payeeName?: string;
          clearableId?: string;
          clearableLinked?: boolean;
          reconciliationId?: string;
          approverName?: string;
          lines: Array<{
            __typename?: 'JournalEntryLine';
            id?: string;
            glId: string;
            ownerId: string;
            propertyId: string;
            unitId?: string;
            amount: number;
            description?: string;
            clearableId?: string;
          }>;
        }>;
      };
    };
  };
};

export type TransferJournalEntriesMutationVariables = Exact<{
  input: TransferJournalEntriesInput;
}>;

export type TransferJournalEntriesMutation = {
  __typename?: 'Mutation';
  transferJournalEntries: {
    __typename?: 'TransferJournalEntriesPayload';
    success?: boolean;
    error?: string;
    sourceJournalIds?: Array<string>;
    destinationJournalIds?: Array<string>;
  };
};

export type BundledReportsQueryVariables = Exact<{ [key: string]: never }>;

export type BundledReportsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      bundledReports?: Array<{
        __typename?: 'BundledReport';
        id: string;
        name: string;
        key?: string;
        startedZ: string;
        status: string;
        inputBindings: string;
        completedZ?: string;
        ttl?: number;
        bundleId?: string;
        flag?: BundledReportFlag;
        isShared?: boolean;
        xlsx?: boolean;
      }>;
      presets?: Array<{
        __typename?: 'Preset';
        id: string;
        type: PresetType;
        category?: PresetCategory;
        name: string;
      }>;
    };
  };
};

export type RunReportSetMutationVariables = Exact<{
  input: RunReportSetInput;
}>;

export type RunReportSetMutation = {
  __typename?: 'Mutation';
  runReportSet: {
    __typename?: 'ReportSetPayload';
    success: boolean;
    error?: string;
    reportSet?: { __typename?: 'ReportSet'; setId: string };
  };
};

export type DeleteBundledReportMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteBundledReportMutation = {
  __typename?: 'Mutation';
  deleteBundledReport: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type SetBundledReportSharedFlagMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SetBundledReportSharedFlagMutation = {
  __typename?: 'Mutation';
  setBundledReportSharedFlag: {
    __typename?: 'BundledReportPayload';
    success: boolean;
    error?: string;
    bundledReport?: { __typename?: 'BundledReport'; id: string; isShared?: boolean };
  };
};

export type UpdateBundledReportFlagMutationVariables = Exact<{
  input: UpdateBundledReportFlagInput;
}>;

export type UpdateBundledReportFlagMutation = {
  __typename?: 'Mutation';
  updateBundledReportFlag: {
    __typename?: 'BundledReportPayload';
    success: boolean;
    error?: string;
    bundledReport?: { __typename?: 'BundledReport'; id: string; flag?: BundledReportFlag };
  };
};

export type ReportBundlesQueryVariables = Exact<{ [key: string]: never }>;

export type ReportBundlesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      reportBundles?: Array<{
        __typename?: 'ReportBundle';
        id: string;
        name: string;
        presetIds: Array<string>;
      }>;
      presets?: Array<{
        __typename?: 'Preset';
        id: string;
        version?: BooksReportVersion;
        type: PresetType;
        category?: PresetCategory;
        name: string;
      }>;
    };
  };
};

export type CreateReportBundleMutationVariables = Exact<{
  input: CreateReportBundleInput;
}>;

export type CreateReportBundleMutation = {
  __typename?: 'Mutation';
  createReportBundle: {
    __typename?: 'ReportBundlePayload';
    success: boolean;
    error?: string;
    reportBundle?: {
      __typename?: 'ReportBundle';
      id: string;
      name: string;
      presetIds: Array<string>;
    };
  };
};

export type UpdateReportBundleMutationVariables = Exact<{
  input: UpdateReportBundleInput;
}>;

export type UpdateReportBundleMutation = {
  __typename?: 'Mutation';
  updateReportBundle: {
    __typename?: 'ReportBundlePayload';
    success: boolean;
    error?: string;
    reportBundle?: {
      __typename?: 'ReportBundle';
      id: string;
      name: string;
      presetIds: Array<string>;
    };
  };
};

export type DeleteReportBundleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteReportBundleMutation = {
  __typename?: 'Mutation';
  deleteReportBundle: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type GetOwnerFiscalQueryVariables = Exact<{
  ownerId: Scalars['ID']['input'];
}>;

export type GetOwnerFiscalQuery = {
  __typename?: 'Query';
  owner?: { __typename?: 'Owner'; id: string; yearEndMonth?: number };
};

export type GetBudgetsQueryVariables = Exact<{
  input: GetBudgetsInput;
}>;

export type GetBudgetsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      getBudgets: Array<{
        __typename?: 'Budget';
        ownerId: string;
        propertyId: string;
        glId: string;
        period: string;
        amount: number;
      }>;
    };
  };
};

export type PutBudgetsMutationVariables = Exact<{
  input: PutBudgetsInput;
}>;

export type PutBudgetsMutation = {
  __typename?: 'Mutation';
  putBudgets: { __typename?: 'BudgetsPayload'; success?: boolean; error?: string };
};

export type PrintJournalEntriesMutationVariables = Exact<{
  input: PrintJournalEntriesInput;
}>;

export type PrintJournalEntriesMutation = {
  __typename?: 'Mutation';
  printJournalEntries?: {
    __typename?: 'ChequePayload';
    success: boolean;
    error?: string;
    glId?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      chequePlacement?: ChequePlacement;
      nextCheque?: number;
      nextInvoice?: number;
      nextJournal?: number;
      yearEndMonth?: number;
      receiverGeneralId?: string;
      gstNumber?: string;
      gstFiling?: FilingFrequency;
      printedZ?: string;
      includeDisabledProperties?: boolean;
      trackManagementFeeRevenue?: boolean;
      managementFeeBasis?: ManagementFeeBasis;
      billableRevenueFeeIds?: Array<string>;
      lastPrintedGlId?: string;
      billingRates?: {
        __typename?: 'BillingRate';
        enabled?: boolean;
        labourRate?: number;
        materialsMarkup?: number;
        requireApproval?: boolean;
        minBillableMinutes?: number;
        trackRevenue?: boolean;
      };
      customBillingRates?: Array<{
        __typename?: 'BillingRate';
        category?: string;
        glId?: string;
        enabled?: boolean;
        labourRate?: number;
        materialsMarkup?: number;
        requireApproval?: boolean;
        minBillableMinutes?: number;
        trackRevenue?: boolean;
      }>;
      printedCheques?: Array<{
        __typename?: 'BooksPrintedCheque';
        glId: string;
        printedZ: string;
        nextCheque: number;
        chequePlacement?: ChequePlacement;
      }>;
      incomePayoutOptions?: {
        __typename?: 'IncomePayoutOptions';
        excludePayables?: boolean;
        excludePrepayments?: boolean;
        includeOutstandingPayables?: boolean;
      };
      glAccounts: Array<{
        __typename?: 'GLAccount';
        id: string;
        name: string;
        key?: string;
        category?: string;
        accountType: AccountType;
        balanceType: BalanceType;
      }>;
      glAccountMapping?: Array<{
        __typename?: 'GLMapping';
        id: string;
        parentId: string;
        name: string;
        description: string;
        default: string;
        filter?: string;
        glId?: string;
      }>;
      presets?: Array<{
        __typename?: 'Preset';
        id: string;
        type: PresetType;
        version?: BooksReportVersion;
        category?: PresetCategory;
        name: string;
        visibleExpression?: string;
        description: string;
        lastPosted?: string;
        roles?: Array<ManagerRole>;
        group?: { __typename?: 'PresetGroup'; name: string; order?: number };
        glMapping?: Array<{
          __typename?: 'GLMapping';
          id: string;
          parentId: string;
          name: string;
          description: string;
          default: string;
          filter?: string;
          glId?: string;
        }>;
        inputFields?: Array<{
          __typename?: 'PresetInputField';
          id: string;
          label: string;
          type: BooksFieldType;
          required?: boolean;
          defaultExpression?: string;
          options?: Array<{ __typename?: 'PresetOption'; label: string; value: string }>;
        }>;
        recurrence?: {
          __typename?: 'PresetRecurrence';
          enabled: boolean;
          locked?: boolean;
          rrule: string;
          postedOffset: number;
          parameters: string;
        };
        icon?: { __typename?: 'PresetIcon'; name?: string; color?: string };
      }>;
      feeMapping?: Array<{ __typename?: 'FeeMapping'; feeId: string; glId: string }>;
      chargeMapping?: Array<{ __typename?: 'ChargeMapping'; chargeId: string; glId: string }>;
    };
    journalEntries?: Array<{
      __typename?: 'JournalEntry';
      id: string;
      payee?: PayeeType;
      payeeId?: string;
      payeeName?: string;
      amount: number;
      posted: string;
      printedZ?: string;
      ref?: string;
      description?: string;
      glAccount: {
        __typename?: 'GLAccount';
        id: string;
        name: string;
        key?: string;
        category?: string;
        accountType: AccountType;
        balanceType: BalanceType;
      };
      journalEntries?: Array<{
        __typename?: 'JournalEntry';
        ref?: string;
        description?: string;
        propertyId: string;
        amount: number;
        glAccount: {
          __typename?: 'GLAccount';
          id: string;
          name: string;
          key?: string;
          category?: string;
          accountType: AccountType;
          balanceType: BalanceType;
        };
        clearable?: {
          __typename?: 'Clearable';
          id: string;
          sourceJournalEntry: {
            __typename?: 'JournalEntry';
            id: string;
            description?: string;
            ref?: string;
            posted: string;
            propertyId: string;
            unitId?: string;
          };
        };
      }>;
    }>;
  };
};

export type ListChequesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  glId: Scalars['ID']['input'];
  printedZ: Scalars['String']['input'];
}>;

export type ListChequesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      printedZ?: string;
      nextCheque?: number;
      listCheques?: {
        __typename?: 'JournalEntryConnection';
        nextToken?: string;
        items?: Array<{
          __typename?: 'JournalEntry';
          id: string;
          payee?: PayeeType;
          payeeId?: string;
          payeeName?: string;
          amount: number;
          posted: string;
          printedZ?: string;
          ref?: string;
          description?: string;
          glAccount: {
            __typename?: 'GLAccount';
            id: string;
            name: string;
            key?: string;
            category?: string;
            accountType: AccountType;
            balanceType: BalanceType;
          };
          journalEntries?: Array<{
            __typename?: 'JournalEntry';
            ref?: string;
            description?: string;
            propertyId: string;
            amount: number;
            glAccount: {
              __typename?: 'GLAccount';
              id: string;
              name: string;
              key?: string;
              category?: string;
              accountType: AccountType;
              balanceType: BalanceType;
            };
            clearable?: {
              __typename?: 'Clearable';
              id: string;
              sourceJournalEntry: {
                __typename?: 'JournalEntry';
                id: string;
                description?: string;
                ref?: string;
                posted: string;
                propertyId: string;
                unitId?: string;
              };
            };
          }>;
        }>;
      };
    };
  };
};

export type PreviewChequesQueryVariables = Exact<{
  glId: Scalars['ID']['input'];
}>;

export type PreviewChequesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      previewCheques: {
        __typename?: 'ChequePayload';
        glId?: string;
        journalEntries?: Array<{
          __typename?: 'JournalEntry';
          id: string;
          payee?: PayeeType;
          payeeId?: string;
          payeeName?: string;
          amount: number;
          posted: string;
          printedZ?: string;
          ref?: string;
          description?: string;
          glAccount: {
            __typename?: 'GLAccount';
            id: string;
            name: string;
            key?: string;
            category?: string;
            accountType: AccountType;
            balanceType: BalanceType;
          };
          journalEntries?: Array<{
            __typename?: 'JournalEntry';
            ref?: string;
            description?: string;
            propertyId: string;
            amount: number;
            glAccount: {
              __typename?: 'GLAccount';
              id: string;
              name: string;
              key?: string;
              category?: string;
              accountType: AccountType;
              balanceType: BalanceType;
            };
            clearable?: {
              __typename?: 'Clearable';
              id: string;
              sourceJournalEntry: {
                __typename?: 'JournalEntry';
                id: string;
                description?: string;
                ref?: string;
                posted: string;
                propertyId: string;
                unitId?: string;
              };
            };
          }>;
        }>;
      };
    };
  };
};

export type TenantActivatedEnrollmentsQueryVariables = Exact<{
  payeeId: Scalars['ID']['input'];
}>;

export type TenantActivatedEnrollmentsQuery = {
  __typename?: 'Query';
  tenant?: {
    __typename?: 'Tenant';
    id: string;
    enrollments?: Array<{
      __typename?: 'AutopayEnrollment';
      id: string;
      unitId: string;
      propertyId: string;
    }>;
  };
};

export type OwnerActivatedEnrollmentsQueryVariables = Exact<{
  payeeId: Scalars['ID']['input'];
}>;

export type OwnerActivatedEnrollmentsQuery = {
  __typename?: 'Query';
  owner?: {
    __typename?: 'Owner';
    id: string;
    enrollments?: Array<{
      __typename?: 'AutopayEnrollment';
      id: string;
      unitId: string;
      propertyId: string;
    }>;
  };
};

export type ServerReconciliationJournalsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  filter: ReconciliationJournalFilter;
  sort?: InputMaybe<Array<JournalEntriesSortInput> | JournalEntriesSortInput>;
}>;

export type ServerReconciliationJournalsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      pageJournalEntriesForReconciliation: {
        __typename?: 'JournalEntriesForRecPage';
        totalCount: number;
        totalDeposits: number;
        totalWithdrawals: number;
        clearedCount: number;
        totalCleared: number;
        unclearedCount: number;
        totalUncleared: number;
        clearedDepositsCount: number;
        totalDepositsCleared: number;
        clearedWithdrawalsCount: number;
        totalWithdrawalsCleared: number;
        unclearedDepositsCount: number;
        totalDepositsUncleared: number;
        unclearedWithdrawalsCount: number;
        totalWithdrawalsUncleared: number;
        pageInfo: { __typename?: 'RelayPageInfo'; endCursor?: string; hasNextPage: boolean };
        edges: Array<{
          __typename?: 'JournalEntryEdge';
          cursor: string;
          node: {
            __typename?: 'JournalEntry';
            id: string;
            posted: string;
            ref?: string;
            amount: number;
            batchId?: string;
            description?: string;
            reconciliationId?: string;
            paymentMethod?: PaymentMethod;
            payeeName?: string;
            ownerName?: string;
            propertyKey?: string;
            propertyName?: string;
            buildingKey?: string;
            buildingName?: string;
            unitId?: string;
            unitName?: string;
          };
        }>;
      };
    };
  };
};

export type ReconciliationConfigScopeStepPathQueryVariables = Exact<{
  filter?: InputMaybe<SubAccountFilterInput>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ReconciliationConfigScopeStepPathQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      listSubAccounts?: {
        __typename?: 'SubAccountConnection';
        nextToken?: string;
        items?: Array<{
          __typename?: 'SubAccount';
          id: string;
          ownerId: string;
          propertyId: string;
        }>;
      };
    };
  };
};

export type ReconciliationScopeFieldsFragment = {
  __typename?: 'ReconciliationScope';
  glId: string;
  propertyOwnerIds?: Array<string>;
};

export type ReconciliationFieldsFragment = {
  __typename?: 'Reconciliation';
  id: string;
  name?: string;
  current?: boolean;
  configuration: string;
  statementDate: string;
  status: ReconciliationStatus;
  errorMessage?: string;
  statementBalance?: number;
  lastStatementBalance?: number;
  lastReconciliationId?: string;
  booksBatchId?: string;
  reindexToken?: string;
  completedZ?: string;
  documentKey?: string;
  ledgerDocumentKey?: string;
  scope: { __typename?: 'ReconciliationScope'; glId: string; propertyOwnerIds?: Array<string> };
  glAccount?: { __typename?: 'GLAccount'; id: string; name: string };
};

export type ReconciliationConfigFieldsFragment = {
  __typename?: 'ReconciliationConfig';
  id: string;
  name: string;
  configuration: string;
  bankId?: string;
  scope: { __typename?: 'ReconciliationScope'; glId: string; propertyOwnerIds?: Array<string> };
};

export type GetReconciliationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetReconciliationsQuery = {
  __typename?: 'Query';
  manager?: {
    __typename?: 'Manager';
    id: string;
    account: {
      __typename?: 'Account';
      id: string;
      books?: {
        __typename?: 'Books';
        accountId: string;
        reconciliations?: Array<{
          __typename?: 'Reconciliation';
          id: string;
          name?: string;
          current?: boolean;
          configuration: string;
          statementDate: string;
          status: ReconciliationStatus;
          errorMessage?: string;
          statementBalance?: number;
          lastStatementBalance?: number;
          lastReconciliationId?: string;
          booksBatchId?: string;
          reindexToken?: string;
          completedZ?: string;
          documentKey?: string;
          ledgerDocumentKey?: string;
          scope: {
            __typename?: 'ReconciliationScope';
            glId: string;
            propertyOwnerIds?: Array<string>;
          };
          glAccount?: { __typename?: 'GLAccount'; id: string; name: string };
        }>;
        reconciliationConfigs?: Array<{
          __typename?: 'ReconciliationConfig';
          id: string;
          name: string;
          configuration: string;
          bankId?: string;
          scope: {
            __typename?: 'ReconciliationScope';
            glId: string;
            propertyOwnerIds?: Array<string>;
          };
        }>;
      };
    };
  };
};

export type ListReconciliationsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListReconciliationsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      listReconciliations?: {
        __typename?: 'ReconciliationConnection';
        nextToken?: string;
        items?: Array<{
          __typename?: 'Reconciliation';
          id: string;
          name?: string;
          current?: boolean;
          configuration: string;
          statementDate: string;
          status: ReconciliationStatus;
          errorMessage?: string;
          statementBalance?: number;
          lastStatementBalance?: number;
          lastReconciliationId?: string;
          booksBatchId?: string;
          reindexToken?: string;
          completedZ?: string;
          documentKey?: string;
          ledgerDocumentKey?: string;
          scope: {
            __typename?: 'ReconciliationScope';
            glId: string;
            propertyOwnerIds?: Array<string>;
          };
          glAccount?: { __typename?: 'GLAccount'; id: string; name: string };
        }>;
      };
    };
  };
};

export type ListReconciliationConfigsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListReconciliationConfigsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      listReconciliationConfigs?: {
        __typename?: 'ReconciliationConfigConnection';
        nextToken?: string;
        items?: Array<{
          __typename?: 'ReconciliationConfig';
          id: string;
          name: string;
          configuration: string;
          bankId?: string;
          scope: {
            __typename?: 'ReconciliationScope';
            glId: string;
            propertyOwnerIds?: Array<string>;
          };
        }>;
      };
    };
  };
};

export type UpdateReconciliationMutationVariables = Exact<{
  input: UpdateReconciliationInput;
}>;

export type UpdateReconciliationMutation = {
  __typename?: 'Mutation';
  updateReconciliation?: {
    __typename?: 'ReconciliationPayload';
    success?: boolean;
    error?: string;
    reconciliation?: {
      __typename?: 'Reconciliation';
      id: string;
      name?: string;
      current?: boolean;
      configuration: string;
      statementDate: string;
      status: ReconciliationStatus;
      errorMessage?: string;
      statementBalance?: number;
      lastStatementBalance?: number;
      lastReconciliationId?: string;
      booksBatchId?: string;
      reindexToken?: string;
      completedZ?: string;
      documentKey?: string;
      ledgerDocumentKey?: string;
      scope: { __typename?: 'ReconciliationScope'; glId: string; propertyOwnerIds?: Array<string> };
      glAccount?: { __typename?: 'GLAccount'; id: string; name: string };
    };
  };
};

export type DeleteReconciliationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteReconciliationMutation = {
  __typename?: 'Mutation';
  deleteReconciliation?: {
    __typename?: 'RemoveReconciliationPayload';
    success?: boolean;
    error?: string;
    lastReconciliation?: { __typename?: 'Reconciliation'; id: string; current?: boolean };
  };
};

export type UpdateJournalDraftsMutationVariables = Exact<{
  input: UpdateJournalDraftsInput;
}>;

export type UpdateJournalDraftsMutation = {
  __typename?: 'Mutation';
  updateJournalDrafts: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type AddReconciliationConfigMutationVariables = Exact<{
  input: AddReconciliationConfigInput;
}>;

export type AddReconciliationConfigMutation = {
  __typename?: 'Mutation';
  addReconciliationConfig?: {
    __typename?: 'ReconciliationConfigPayload';
    success?: boolean;
    error?: string;
    reconciliationConfig?: {
      __typename?: 'ReconciliationConfig';
      id: string;
      name: string;
      configuration: string;
      bankId?: string;
      scope: { __typename?: 'ReconciliationScope'; glId: string; propertyOwnerIds?: Array<string> };
    };
  };
};

export type UpdateReconciliationConfigMutationVariables = Exact<{
  input: UpdateReconciliationConfigInput;
}>;

export type UpdateReconciliationConfigMutation = {
  __typename?: 'Mutation';
  updateReconciliationConfig?: {
    __typename?: 'ReconciliationConfigPayload';
    success?: boolean;
    error?: string;
    reconciliationConfig?: {
      __typename?: 'ReconciliationConfig';
      id: string;
      name: string;
      configuration: string;
      bankId?: string;
      scope: { __typename?: 'ReconciliationScope'; glId: string; propertyOwnerIds?: Array<string> };
    };
  };
};

export type DeleteReconciliationConfigMutationVariables = Exact<{
  input: DeleteReconciliationConfigInput;
}>;

export type DeleteReconciliationConfigMutation = {
  __typename?: 'Mutation';
  deleteReconciliationConfig?: {
    __typename?: 'ReconciliationConfigPayload';
    success?: boolean;
    error?: string;
  };
};

export type PropertiesWithCurrentResidenciesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<ResidenciesFilterInput>;
  sort?: InputMaybe<Array<ResidenciesSortInput> | ResidenciesSortInput>;
}>;

export type PropertiesWithCurrentResidenciesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageResidencies?: {
      __typename?: 'ResidenciesPage';
      totalCount: number;
      pageInfo: { __typename?: 'RelayPageInfo'; endCursor?: string; hasNextPage: boolean };
      edges: Array<{
        __typename?: 'ResidencyEdge';
        cursor: string;
        node: {
          __typename?: 'Residency';
          id: string;
          propertyId: string;
          propertyName?: string;
          unitId: string;
          unitName?: string;
          residents: Array<{
            __typename?: 'ResidentAssociation';
            id: string;
            residentId: string;
            leaseHolder?: boolean;
            resident:
              | {
                  __typename: 'Owner';
                  id: string;
                  enabled?: boolean;
                  name: string;
                  emails?: Array<string>;
                  phones?: Array<string>;
                }
              | {
                  __typename: 'Tenant';
                  phone?: string;
                  enabled?: boolean;
                  id: string;
                  name: string;
                  emails?: Array<string>;
                  phones?: Array<string>;
                };
          }>;
        };
      }>;
    };
  };
};

export type GetOwnersWithContactsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<OwnersFilterInput>;
  sort?: InputMaybe<Array<OwnersSortInput> | OwnersSortInput>;
}>;

export type GetOwnersWithContactsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageOwners?: {
      __typename?: 'OwnersPage';
      totalCount: number;
      pageInfo: { __typename?: 'RelayPageInfo'; endCursor?: string; hasNextPage: boolean };
      edges: Array<{
        __typename?: 'OwnerEdge';
        cursor: string;
        node: {
          __typename?: 'Owner';
          id: string;
          name: string;
          email?: string;
          phones?: Array<string>;
          enabled?: boolean;
          propertyIds?: Array<string>;
          unitIds?: Array<string>;
          unitHeaders?: Array<{
            __typename?: 'UnitHeader';
            id: string;
            propertyId: string;
            propertyName?: string;
            name?: string;
          }>;
          propertyHeaders?: Array<{
            __typename?: 'PropertyHeader';
            id: string;
            name?: string;
            key?: string;
          }>;
          contacts?: Array<{
            __typename?: 'Contact';
            id: string;
            accountId: string;
            entityId: string;
            type?: ContactType;
            name: string;
            emails?: Array<string>;
            phones?: Array<string>;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
          }>;
        };
      }>;
    };
  };
};

export type AnnouncementsFieldsFragment = {
  __typename?: 'Announcement';
  id: string;
  name: string;
  author: string;
  status: string;
  publishZ?: string;
  totalRecipients?: number;
  scope: {
    __typename?: 'AnnouncementScope';
    propertyIds?: Array<string>;
    unitIds?: Array<string>;
    omitIds?: Array<string>;
  };
  email?: { __typename?: 'AnnouncementEmail'; subject?: string; body?: string };
  voice?: { __typename?: 'AnnouncementVoice'; text?: string };
  fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
  attachments?: Array<{ __typename?: 'Attachment'; key: string; name?: string; type: string }>;
  replies?: Array<{
    __typename?: 'Reply';
    id: string;
    createdZ: string;
    createdName: string;
    createdSub: string;
    text: string;
    managerReply?: boolean;
    email?: string;
    replyId?: string;
    attachments?: Array<{ __typename?: 'Attachment'; key: string; type: string }>;
  }>;
};

export type GetAnnouncementsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAnnouncementsQuery = {
  __typename?: 'Query';
  manager?: {
    __typename?: 'Manager';
    id: string;
    account: {
      __typename?: 'Account';
      id: string;
      listAnnouncements?: {
        __typename?: 'AnnouncementConnection';
        nextToken?: string;
        items?: Array<{
          __typename?: 'Announcement';
          id: string;
          name: string;
          author: string;
          status: string;
          publishZ?: string;
          totalRecipients?: number;
          scope: {
            __typename?: 'AnnouncementScope';
            propertyIds?: Array<string>;
            unitIds?: Array<string>;
            omitIds?: Array<string>;
          };
          email?: { __typename?: 'AnnouncementEmail'; subject?: string; body?: string };
          voice?: { __typename?: 'AnnouncementVoice'; text?: string };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          attachments?: Array<{
            __typename?: 'Attachment';
            key: string;
            name?: string;
            type: string;
          }>;
          replies?: Array<{
            __typename?: 'Reply';
            id: string;
            createdZ: string;
            createdName: string;
            createdSub: string;
            text: string;
            managerReply?: boolean;
            email?: string;
            replyId?: string;
            attachments?: Array<{ __typename?: 'Attachment'; key: string; type: string }>;
          }>;
        }>;
      };
    };
  };
};

export type CreateAnnouncementMutationVariables = Exact<{
  input: CreateAnnouncementInput;
}>;

export type CreateAnnouncementMutation = {
  __typename?: 'Mutation';
  createAnnouncement?: {
    __typename?: 'AnnouncementPayload';
    success?: boolean;
    error?: string;
    announcement?: {
      __typename?: 'Announcement';
      id: string;
      name: string;
      author: string;
      status: string;
      publishZ?: string;
      totalRecipients?: number;
      scope: {
        __typename?: 'AnnouncementScope';
        propertyIds?: Array<string>;
        unitIds?: Array<string>;
        omitIds?: Array<string>;
      };
      email?: { __typename?: 'AnnouncementEmail'; subject?: string; body?: string };
      voice?: { __typename?: 'AnnouncementVoice'; text?: string };
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      attachments?: Array<{ __typename?: 'Attachment'; key: string; name?: string; type: string }>;
      replies?: Array<{
        __typename?: 'Reply';
        id: string;
        createdZ: string;
        createdName: string;
        createdSub: string;
        text: string;
        managerReply?: boolean;
        email?: string;
        replyId?: string;
        attachments?: Array<{ __typename?: 'Attachment'; key: string; type: string }>;
      }>;
    };
  };
};

export type UpdateAnnouncementMutationVariables = Exact<{
  input: UpdateAnnouncementInput;
}>;

export type UpdateAnnouncementMutation = {
  __typename?: 'Mutation';
  updateAnnouncement?: {
    __typename?: 'AnnouncementPayload';
    success?: boolean;
    error?: string;
    announcement?: {
      __typename?: 'Announcement';
      id: string;
      name: string;
      author: string;
      status: string;
      publishZ?: string;
      totalRecipients?: number;
      scope: {
        __typename?: 'AnnouncementScope';
        propertyIds?: Array<string>;
        unitIds?: Array<string>;
        omitIds?: Array<string>;
      };
      email?: { __typename?: 'AnnouncementEmail'; subject?: string; body?: string };
      voice?: { __typename?: 'AnnouncementVoice'; text?: string };
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      attachments?: Array<{ __typename?: 'Attachment'; key: string; name?: string; type: string }>;
      replies?: Array<{
        __typename?: 'Reply';
        id: string;
        createdZ: string;
        createdName: string;
        createdSub: string;
        text: string;
        managerReply?: boolean;
        email?: string;
        replyId?: string;
        attachments?: Array<{ __typename?: 'Attachment'; key: string; type: string }>;
      }>;
    };
  };
};

export type DeleteAnnouncementMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteAnnouncementMutation = {
  __typename?: 'Mutation';
  deleteAnnouncement?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type PublishFutureAnnouncementMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  publishZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
}>;

export type PublishFutureAnnouncementMutation = {
  __typename?: 'Mutation';
  publishAnnouncement?: {
    __typename?: 'AnnouncementPayload';
    success?: boolean;
    error?: string;
    announcement?: {
      __typename?: 'Announcement';
      id: string;
      name: string;
      author: string;
      status: string;
      publishZ?: string;
      totalRecipients?: number;
      scope: {
        __typename?: 'AnnouncementScope';
        propertyIds?: Array<string>;
        unitIds?: Array<string>;
        omitIds?: Array<string>;
      };
      email?: { __typename?: 'AnnouncementEmail'; subject?: string; body?: string };
      voice?: { __typename?: 'AnnouncementVoice'; text?: string };
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      attachments?: Array<{ __typename?: 'Attachment'; key: string; name?: string; type: string }>;
      replies?: Array<{
        __typename?: 'Reply';
        id: string;
        createdZ: string;
        createdName: string;
        createdSub: string;
        text: string;
        managerReply?: boolean;
        email?: string;
        replyId?: string;
        attachments?: Array<{ __typename?: 'Attachment'; key: string; type: string }>;
      }>;
    };
  };
};

export type PublishAnnouncementMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PublishAnnouncementMutation = {
  __typename?: 'Mutation';
  publishAnnouncement?: {
    __typename?: 'AnnouncementPayload';
    success?: boolean;
    error?: string;
    announcement?: {
      __typename?: 'Announcement';
      id: string;
      name: string;
      author: string;
      status: string;
      publishZ?: string;
      totalRecipients?: number;
      scope: {
        __typename?: 'AnnouncementScope';
        propertyIds?: Array<string>;
        unitIds?: Array<string>;
        omitIds?: Array<string>;
      };
      email?: { __typename?: 'AnnouncementEmail'; subject?: string; body?: string };
      voice?: { __typename?: 'AnnouncementVoice'; text?: string };
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      attachments?: Array<{ __typename?: 'Attachment'; key: string; name?: string; type: string }>;
      replies?: Array<{
        __typename?: 'Reply';
        id: string;
        createdZ: string;
        createdName: string;
        createdSub: string;
        text: string;
        managerReply?: boolean;
        email?: string;
        replyId?: string;
        attachments?: Array<{ __typename?: 'Attachment'; key: string; type: string }>;
      }>;
    };
  };
};

export type CancelPublishAnnouncementMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CancelPublishAnnouncementMutation = {
  __typename?: 'Mutation';
  cancelPublishAnnouncement?: {
    __typename?: 'AnnouncementPayload';
    success?: boolean;
    error?: string;
    announcement?: {
      __typename?: 'Announcement';
      id: string;
      name: string;
      author: string;
      status: string;
      publishZ?: string;
      totalRecipients?: number;
      scope: {
        __typename?: 'AnnouncementScope';
        propertyIds?: Array<string>;
        unitIds?: Array<string>;
        omitIds?: Array<string>;
      };
      email?: { __typename?: 'AnnouncementEmail'; subject?: string; body?: string };
      voice?: { __typename?: 'AnnouncementVoice'; text?: string };
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      attachments?: Array<{ __typename?: 'Attachment'; key: string; name?: string; type: string }>;
      replies?: Array<{
        __typename?: 'Reply';
        id: string;
        createdZ: string;
        createdName: string;
        createdSub: string;
        text: string;
        managerReply?: boolean;
        email?: string;
        replyId?: string;
        attachments?: Array<{ __typename?: 'Attachment'; key: string; type: string }>;
      }>;
    };
  };
};

export type TestVoiceAnnouncementMutationVariables = Exact<{
  input: TestVoiceAnnouncementInput;
}>;

export type TestVoiceAnnouncementMutation = {
  __typename?: 'Mutation';
  testVoiceAnnouncement?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type AddAnnouncementReplyMutationVariables = Exact<{
  input: AddAnnouncementReplyInput;
}>;

export type AddAnnouncementReplyMutation = {
  __typename?: 'Mutation';
  addAnnouncementReply?: {
    __typename?: 'AnnouncementPayload';
    success?: boolean;
    error?: string;
    announcement?: {
      __typename?: 'Announcement';
      id: string;
      name: string;
      author: string;
      status: string;
      publishZ?: string;
      totalRecipients?: number;
      scope: {
        __typename?: 'AnnouncementScope';
        propertyIds?: Array<string>;
        unitIds?: Array<string>;
        omitIds?: Array<string>;
      };
      email?: { __typename?: 'AnnouncementEmail'; subject?: string; body?: string };
      voice?: { __typename?: 'AnnouncementVoice'; text?: string };
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      attachments?: Array<{ __typename?: 'Attachment'; key: string; name?: string; type: string }>;
      replies?: Array<{
        __typename?: 'Reply';
        id: string;
        createdZ: string;
        createdName: string;
        createdSub: string;
        text: string;
        managerReply?: boolean;
        email?: string;
        replyId?: string;
        attachments?: Array<{ __typename?: 'Attachment'; key: string; type: string }>;
      }>;
    };
  };
};

export type AccountAutopayOnboardingUrlQueryVariables = Exact<{ [key: string]: never }>;

export type AccountAutopayOnboardingUrlQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activationUrl?: string;
        activationStatus?: string;
      };
    };
  };
};

export type StartAccountAutopayMutationVariables = Exact<{
  input: StartAccountAutopayInput;
}>;

export type StartAccountAutopayMutation = {
  __typename?: 'Mutation';
  startAccountAutopay?: {
    __typename?: 'AccountPayload';
    success?: boolean;
    error?: string;
    account?: {
      __typename?: 'Account';
      id: string;
      books?: {
        __typename?: 'Books';
        accountId: string;
        autopay?: {
          __typename?: 'Autopay';
          accountId: string;
          activated?: boolean;
          activationStatus?: string;
          enableCollection?: boolean;
          enablePayments?: boolean;
          invitePayees?: boolean;
          nsfFee?: number;
          feeExpense: string;
        };
      };
    };
  };
};

export type AutopayBankFieldsFragment = {
  __typename?: 'Bank';
  id: string;
  name: string;
  transitNumber: string;
  accountNumber: string;
  institutionNumber: string;
};

export type AutopayFieldsFragment = {
  __typename?: 'Autopay';
  accountId: string;
  activated?: boolean;
  activatedZ?: string;
  activationStatus?: string;
  version?: string;
  enablePayments?: boolean;
  enableCollection?: boolean;
  deactivatedZ?: string;
  collectionTypes?: Array<CollectionType>;
  feeExpense: string;
  propraSupplier?: string;
  undepositedFunds?: string;
  nsfFee?: number;
  feeBankAccountId?: string;
  feeInvoiceChargeMethod?: FeeInvoiceChargeMethod;
  invitePayees?: boolean;
  linkedAccounts?: Array<{
    __typename?: 'LinkedAccount';
    id: string;
    institutionName: string;
    accountDetails?: {
      __typename?: 'AccountDetail';
      accountNumber4Digit?: string;
      type?: string;
      currency?: string;
    };
  }>;
  banks?: Array<{
    __typename?: 'Bank';
    id: string;
    name: string;
    transitNumber: string;
    accountNumber: string;
    institutionNumber: string;
  }>;
};

export type GetServerTenantAutopayPayeesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<AutopayPayeeFilter>;
  sort?: InputMaybe<Array<AutopayPayeeSortInput> | AutopayPayeeSortInput>;
}>;

export type GetServerTenantAutopayPayeesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        pageTenantAutopayPayees?: {
          __typename?: 'AutopayPayeePage';
          totalCount: number;
          pageInfo: { __typename?: 'RelayPageInfo'; hasNextPage: boolean; endCursor?: string };
          edges: Array<{
            __typename?: 'AutopayPayeeEdge';
            node: {
              __typename?: 'AutopayPayee';
              id: string;
              name: string;
              email?: string;
              payeeType: PayeeType;
              autopayStatus?: string;
              autopayStatusZ?: string;
              autopayCollectionsEnabled?: boolean;
              autopayCollectionsEnabledZ?: string;
              propertyUnits?: Array<{
                __typename?: 'AutopayPayeePropertyUnit';
                unitId: string;
                unitName?: string;
                buildingName?: string;
                propertyId: string;
                propertyName?: string;
              }>;
            };
          }>;
        };
      };
    };
  };
};

export type GetServerOwnerAutopayPayeesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<AutopayPayeeFilter>;
  sort?: InputMaybe<Array<AutopayPayeeSortInput> | AutopayPayeeSortInput>;
}>;

export type GetServerOwnerAutopayPayeesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        pageOwnerAutopayPayees?: {
          __typename?: 'AutopayPayeePage';
          totalCount: number;
          pageInfo: { __typename?: 'RelayPageInfo'; hasNextPage: boolean; endCursor?: string };
          edges: Array<{
            __typename?: 'AutopayPayeeEdge';
            node: {
              __typename?: 'AutopayPayee';
              id: string;
              name: string;
              email?: string;
              payeeType: PayeeType;
              autopayStatus?: string;
              autopayStatusZ?: string;
              autopayCollectionsEnabled?: boolean;
              autopayCollectionsEnabledZ?: string;
              propertyUnits?: Array<{
                __typename?: 'AutopayPayeePropertyUnit';
                unitId: string;
                unitName?: string;
                buildingName?: string;
                propertyId: string;
                propertyName?: string;
              }>;
            };
          }>;
        };
      };
    };
  };
};

export type LinkedAccountFieldsFragment = {
  __typename?: 'LinkedAccount';
  id: string;
  institutionName: string;
  accountDetails?: {
    __typename?: 'AccountDetail';
    accountNumber4Digit?: string;
    type?: string;
    currency?: string;
  };
};

export type AutopayPropertiesQueryVariables = Exact<{ [key: string]: never }>;

export type AutopayPropertiesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageProperties?: {
      __typename?: 'PropertiesPage';
      edges: Array<{
        __typename?: 'PropertyEdge';
        node: {
          __typename?: 'Property';
          id: string;
          key?: string;
          name: string;
          autopayCollectionsEnabled?: boolean;
          leasedUnits?: number;
          ownerUnits?: number;
          commercialLeasedUnits?: number;
          autopayActivatedZ?: string;
          autopayCollectionsEnabledZ?: string;
        };
      }>;
    };
  };
};

export type AutopayPropertyConfigQueryVariables = Exact<{ [key: string]: never }>;

export type AutopayPropertyConfigQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    autopayClientId?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      glAccountMapping?: Array<{
        __typename?: 'GLMapping';
        id: string;
        parentId: string;
        glId?: string;
      }>;
      reconciliationConfigs?: Array<{
        __typename?: 'ReconciliationConfig';
        id: string;
        name: string;
        bankId?: string;
        scope: {
          __typename?: 'ReconciliationScope';
          glId: string;
          propertyOwnerIds?: Array<string>;
        };
      }>;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activatedZ?: string;
        banks?: Array<{ __typename?: 'Bank'; id: string; accountNumber: string; name: string }>;
      };
    };
  };
};

export type SetPropertyAutopayCollectionsEnabledMutationVariables = Exact<{
  input: SetPropertyAutopayCollectionsEnabledInput;
}>;

export type SetPropertyAutopayCollectionsEnabledMutation = {
  __typename?: 'Mutation';
  setPropertyAutopayCollectionsEnabled: {
    __typename?: 'PropertyPayload';
    success?: boolean;
    error?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      autopayCollectionsEnabled?: boolean;
      autopayCollectionsEnabledZ?: string;
    };
  };
};

export type ListAutopayStatementsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListAutopayStatementsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listAutopaySummaries?: {
      __typename?: 'AutopaySummaryConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'SettlementSummary';
        id: string;
        accountId: string;
        autopayId: string;
        bankId?: string;
        collectionPosted: string;
        fileNumber?: string;
        paymentAmount?: number;
        paymentCount?: number;
        settledZ?: string;
        status: string;
        key?: string;
        billingName?: string;
        items?: number;
        total?: number;
        returnedItems?: number;
        returnedTotal?: number;
      }>;
    };
  };
};

export type ActivateAutopayMutationVariables = Exact<{
  input: ActivateAutopayInput;
}>;

export type ActivateAutopayMutation = {
  __typename?: 'Mutation';
  activateAutopay: {
    __typename?: 'BooksPayload';
    success?: boolean;
    error?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activated?: boolean;
        activatedZ?: string;
        activationStatus?: string;
        version?: string;
        enablePayments?: boolean;
        enableCollection?: boolean;
        deactivatedZ?: string;
        collectionTypes?: Array<CollectionType>;
        feeExpense: string;
        propraSupplier?: string;
        undepositedFunds?: string;
        nsfFee?: number;
        feeBankAccountId?: string;
        feeInvoiceChargeMethod?: FeeInvoiceChargeMethod;
        invitePayees?: boolean;
        linkedAccounts?: Array<{
          __typename?: 'LinkedAccount';
          id: string;
          institutionName: string;
          accountDetails?: {
            __typename?: 'AccountDetail';
            accountNumber4Digit?: string;
            type?: string;
            currency?: string;
          };
        }>;
        banks?: Array<{
          __typename?: 'Bank';
          id: string;
          name: string;
          transitNumber: string;
          accountNumber: string;
          institutionNumber: string;
        }>;
      };
    };
  };
};

export type DeactivateAutopayMutationVariables = Exact<{ [key: string]: never }>;

export type DeactivateAutopayMutation = {
  __typename?: 'Mutation';
  deactivateAutopay: {
    __typename?: 'BooksPayload';
    success?: boolean;
    error?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activated?: boolean;
        activatedZ?: string;
        activationStatus?: string;
        version?: string;
        enablePayments?: boolean;
        enableCollection?: boolean;
        deactivatedZ?: string;
        collectionTypes?: Array<CollectionType>;
        feeExpense: string;
        propraSupplier?: string;
        undepositedFunds?: string;
        nsfFee?: number;
        feeBankAccountId?: string;
        feeInvoiceChargeMethod?: FeeInvoiceChargeMethod;
        invitePayees?: boolean;
        linkedAccounts?: Array<{
          __typename?: 'LinkedAccount';
          id: string;
          institutionName: string;
          accountDetails?: {
            __typename?: 'AccountDetail';
            accountNumber4Digit?: string;
            type?: string;
            currency?: string;
          };
        }>;
        banks?: Array<{
          __typename?: 'Bank';
          id: string;
          name: string;
          transitNumber: string;
          accountNumber: string;
          institutionNumber: string;
        }>;
      };
    };
  };
};

export type ActivatePropertiesAutopayMutationVariables = Exact<{
  input: AutopayPropertiesInput;
}>;

export type ActivatePropertiesAutopayMutation = {
  __typename?: 'Mutation';
  activatePropertiesAutopay: {
    __typename?: 'BooksPayload';
    success?: boolean;
    error?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activated?: boolean;
        activatedZ?: string;
        activationStatus?: string;
        version?: string;
        enablePayments?: boolean;
        enableCollection?: boolean;
        deactivatedZ?: string;
        collectionTypes?: Array<CollectionType>;
        feeExpense: string;
        propraSupplier?: string;
        undepositedFunds?: string;
        nsfFee?: number;
        feeBankAccountId?: string;
        feeInvoiceChargeMethod?: FeeInvoiceChargeMethod;
        invitePayees?: boolean;
        linkedAccounts?: Array<{
          __typename?: 'LinkedAccount';
          id: string;
          institutionName: string;
          accountDetails?: {
            __typename?: 'AccountDetail';
            accountNumber4Digit?: string;
            type?: string;
            currency?: string;
          };
        }>;
        banks?: Array<{
          __typename?: 'Bank';
          id: string;
          name: string;
          transitNumber: string;
          accountNumber: string;
          institutionNumber: string;
        }>;
      };
    };
  };
};

export type DeactivatePropertiesAutopayMutationVariables = Exact<{
  input: AutopayPropertiesInput;
}>;

export type DeactivatePropertiesAutopayMutation = {
  __typename?: 'Mutation';
  deactivatePropertiesAutopay: {
    __typename?: 'BooksPayload';
    success?: boolean;
    error?: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activated?: boolean;
        activatedZ?: string;
        activationStatus?: string;
        version?: string;
        enablePayments?: boolean;
        enableCollection?: boolean;
        deactivatedZ?: string;
        collectionTypes?: Array<CollectionType>;
        feeExpense: string;
        propraSupplier?: string;
        undepositedFunds?: string;
        nsfFee?: number;
        feeBankAccountId?: string;
        feeInvoiceChargeMethod?: FeeInvoiceChargeMethod;
        invitePayees?: boolean;
        linkedAccounts?: Array<{
          __typename?: 'LinkedAccount';
          id: string;
          institutionName: string;
          accountDetails?: {
            __typename?: 'AccountDetail';
            accountNumber4Digit?: string;
            type?: string;
            currency?: string;
          };
        }>;
        banks?: Array<{
          __typename?: 'Bank';
          id: string;
          name: string;
          transitNumber: string;
          accountNumber: string;
          institutionNumber: string;
        }>;
      };
    };
  };
};

export type AutopayPropertyFieldsFragment = {
  __typename?: 'Property';
  id: string;
  name: string;
  autopayActivatedZ?: string;
  autopayDeactivatedZ?: string;
  autopayStatus?: PropertyAutopayStatus;
  key?: string;
  leasedUnits?: number;
  ownerUnits?: number;
  commercialLeasedUnits?: number;
  autopayStatusHistory?: Array<{
    __typename?: 'StatusHistoryEvent';
    status?: string;
    createdZ?: string;
  }>;
  autopayBankAccounts?: Array<{
    __typename?: 'PropertyBankAccount';
    id: string;
    bankAccountId: string;
    collectionType: CollectionType;
    propertyId: string;
  }>;
};

export type AutopayEnrollmentFieldsFragment = {
  __typename?: 'AutopayEnrollment';
  id: string;
  payeeId: string;
  payeeType: PayeeType;
  invitedZ?: string;
  residencyId?: string;
  propertyId: string;
  unitId: string;
  status: AutopayEnrollmentStatus;
  statusHistory?: Array<{
    __typename?: 'AutopayEnrollmentStatusHistory';
    status: AutopayEnrollmentStatus;
    createdZ: string;
    description?: string;
  }>;
};

export type ListAutopayPropertiesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListAutopayPropertiesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listAutopayProperties?: {
      __typename?: 'PropertyConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Property';
        id: string;
        name: string;
        autopayActivatedZ?: string;
        autopayDeactivatedZ?: string;
        autopayStatus?: PropertyAutopayStatus;
        key?: string;
        leasedUnits?: number;
        ownerUnits?: number;
        commercialLeasedUnits?: number;
        autopayStatusHistory?: Array<{
          __typename?: 'StatusHistoryEvent';
          status?: string;
          createdZ?: string;
        }>;
        autopayBankAccounts?: Array<{
          __typename?: 'PropertyBankAccount';
          id: string;
          bankAccountId: string;
          collectionType: CollectionType;
          propertyId: string;
        }>;
      }>;
    };
  };
};

export type GetAutopayEnrollmentsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AutopayEnrollmentFilterInput>;
}>;

export type GetAutopayEnrollmentsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activated?: boolean;
        activatedZ?: string;
        activationStatus?: string;
        version?: string;
        enablePayments?: boolean;
        enableCollection?: boolean;
        deactivatedZ?: string;
        collectionTypes?: Array<CollectionType>;
        feeExpense: string;
        propraSupplier?: string;
        undepositedFunds?: string;
        nsfFee?: number;
        feeBankAccountId?: string;
        feeInvoiceChargeMethod?: FeeInvoiceChargeMethod;
        invitePayees?: boolean;
        linkedAccounts?: Array<{
          __typename?: 'LinkedAccount';
          id: string;
          institutionName: string;
          accountDetails?: {
            __typename?: 'AccountDetail';
            accountNumber4Digit?: string;
            type?: string;
            currency?: string;
          };
        }>;
        banks?: Array<{
          __typename?: 'Bank';
          id: string;
          name: string;
          transitNumber: string;
          accountNumber: string;
          institutionNumber: string;
        }>;
      };
    };
    listAutopayEnrollments?: {
      __typename?: 'AutopayEnrollmentConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'AutopayEnrollment';
        id: string;
        payeeId: string;
        payeeType: PayeeType;
        invitedZ?: string;
        residencyId?: string;
        propertyId: string;
        unitId: string;
        status: AutopayEnrollmentStatus;
        statusHistory?: Array<{
          __typename?: 'AutopayEnrollmentStatusHistory';
          status: AutopayEnrollmentStatus;
          createdZ: string;
          description?: string;
        }>;
      }>;
    };
  };
};

export type InvitePayeeMutationVariables = Exact<{
  input: InvitePayeeToEnrollInput;
}>;

export type InvitePayeeMutation = {
  __typename?: 'Mutation';
  invitePayeeToEnroll: {
    __typename?: 'AutopayEnrollmentPayload';
    success?: boolean;
    error?: string;
    enrollment?: {
      __typename?: 'AutopayEnrollment';
      id: string;
      payeeId: string;
      payeeType: PayeeType;
      invitedZ?: string;
      residencyId?: string;
      propertyId: string;
      unitId: string;
      status: AutopayEnrollmentStatus;
      statusHistory?: Array<{
        __typename?: 'AutopayEnrollmentStatusHistory';
        status: AutopayEnrollmentStatus;
        createdZ: string;
        description?: string;
      }>;
    };
  };
};

export type ResendInviteToTenantMutationVariables = Exact<{
  input: ResendAutopayInviteInput;
}>;

export type ResendInviteToTenantMutation = {
  __typename?: 'Mutation';
  resendAutopayInviteToTenant: { __typename?: 'TenantPayload'; success?: boolean; error?: string };
};

export type ResendInviteToOwnerMutationVariables = Exact<{
  input: ResendAutopayInviteInput;
}>;

export type ResendInviteToOwnerMutation = {
  __typename?: 'Mutation';
  resendAutopayInviteToOwner: { __typename?: 'OwnerPayload'; success?: boolean; error?: string };
};

export type UpdateEnrollmentToInvitedMutationVariables = Exact<{
  input: UpdateAutopayEnrollmentInput;
}>;

export type UpdateEnrollmentToInvitedMutation = {
  __typename?: 'Mutation';
  updateAutopayEnrollmentToInvited: {
    __typename?: 'AutopayEnrollmentPayload';
    success?: boolean;
    error?: string;
    enrollment?: {
      __typename?: 'AutopayEnrollment';
      id: string;
      payeeId: string;
      payeeType: PayeeType;
      invitedZ?: string;
      residencyId?: string;
      propertyId: string;
      unitId: string;
      status: AutopayEnrollmentStatus;
      statusHistory?: Array<{
        __typename?: 'AutopayEnrollmentStatusHistory';
        status: AutopayEnrollmentStatus;
        createdZ: string;
        description?: string;
      }>;
    };
  };
};

export type UpdateEnrollmentToDeclinedMutationVariables = Exact<{
  input: UpdateAutopayEnrollmentInput;
}>;

export type UpdateEnrollmentToDeclinedMutation = {
  __typename?: 'Mutation';
  updateAutopayEnrollmentToDeclined: {
    __typename?: 'AutopayEnrollmentPayload';
    success?: boolean;
    error?: string;
    enrollment?: {
      __typename?: 'AutopayEnrollment';
      id: string;
      payeeId: string;
      payeeType: PayeeType;
      invitedZ?: string;
      residencyId?: string;
      propertyId: string;
      unitId: string;
      status: AutopayEnrollmentStatus;
      statusHistory?: Array<{
        __typename?: 'AutopayEnrollmentStatusHistory';
        status: AutopayEnrollmentStatus;
        createdZ: string;
        description?: string;
      }>;
    };
  };
};

export type GetAutopayQueryVariables = Exact<{ [key: string]: never }>;

export type GetAutopayQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    name: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      autopay?: {
        __typename?: 'Autopay';
        accountId: string;
        activated?: boolean;
        activatedZ?: string;
        activationStatus?: string;
        version?: string;
        enablePayments?: boolean;
        enableCollection?: boolean;
        deactivatedZ?: string;
        collectionTypes?: Array<CollectionType>;
        feeExpense: string;
        propraSupplier?: string;
        undepositedFunds?: string;
        nsfFee?: number;
        feeBankAccountId?: string;
        feeInvoiceChargeMethod?: FeeInvoiceChargeMethod;
        invitePayees?: boolean;
        linkedAccounts?: Array<{
          __typename?: 'LinkedAccount';
          id: string;
          institutionName: string;
          accountDetails?: {
            __typename?: 'AccountDetail';
            accountNumber4Digit?: string;
            type?: string;
            currency?: string;
          };
        }>;
        banks?: Array<{
          __typename?: 'Bank';
          id: string;
          name: string;
          transitNumber: string;
          accountNumber: string;
          institutionNumber: string;
        }>;
      };
    };
  };
};

export type UpdateApplicationMutationVariables = Exact<{
  input: UpdateApplicationInput;
}>;

export type UpdateApplicationMutation = {
  __typename?: 'Mutation';
  updateApplication?: {
    __typename?: 'ApplicationPayload';
    success: boolean;
    error?: string;
    application?: {
      __typename?: 'Application';
      id: string;
      campaignId: string;
      leaseId?: string;
      residencyId?: string;
      profileId: string;
      name: string;
      emails?: Array<string>;
      phone?: string;
      dob?: string;
      flag?: ApplicationFlag;
      needsAgreement?: boolean;
      moveInDate?: string;
      additionalInformation?: string;
      listedUnitId: string;
      submittedZ: string;
      status?: ApplicationStatus;
      approvedZ?: string;
      declinedZ?: string;
      screeningAgreement?: boolean;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        text?: string;
        source?: string;
        target?: string;
        createdZ?: string;
        createdSub?: string;
        createdName?: string;
      }>;
      workHistory?: Array<{
        __typename?: 'WorkHistory';
        id: string;
        name: string;
        location: string;
        phone?: string;
        startDate: string;
        endDate?: string;
        frequency?: SalaryFrequency;
        amount?: number;
      }>;
      otherIncome?: Array<{
        __typename?: 'Income';
        id: string;
        source: IncomeSource;
        amount: number;
      }>;
      addressHistory?: Array<{
        __typename?: 'AddressHistory';
        id: string;
        name: string;
        phone?: string;
        email?: string;
        startDate: string;
        endDate?: string;
        rentalType?: RentalType;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      household?: {
        __typename?: 'Household';
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        additionalTenants?: Array<{
          __typename?: 'AdditionalTenant';
          id: string;
          name?: string;
          email?: string;
          phone?: string;
          leaseholder?: boolean;
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        }>;
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      };
      listedUnit?: {
        __typename?: 'ListedUnit';
        id: string;
        rent?: number;
        bedrooms?: number;
        bathrooms?: number;
        sqft?: number;
        exteriorSqFt?: number;
      };
      leaseAgreement?: {
        __typename?: 'LeaseAgreement';
        id: string;
        unitId?: string;
        utilities?: Array<string>;
        additionalClause?: Array<string>;
        updatedZ?: string;
        status?: LeaseAgreementStatus;
        houseRules?: Array<string>;
        history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
        lease?: {
          __typename?: 'AgreementLease';
          start?: string;
          end?: string;
          rrule?: string;
          rent: number;
          securityDeposit?: number;
          gst?: number;
          preferredPaymentMethod?: PaymentMethod;
          occupancy?: UnitOccupancy;
          propertyId?: string;
          furnishings?: Array<string>;
          autoRenewSetup?: {
            __typename?: 'AutoRenewSetup';
            enabled?: boolean;
            termMonths?: number;
          };
          tenants?: Array<{
            __typename?: 'LeaseTenant';
            name?: string;
            dob?: string;
            emails?: Array<string>;
            phone?: string;
            phones?: Array<string>;
            leaseHolder?: boolean;
            isPayee?: boolean;
            preferredPaymentMethod?: PaymentMethod;
            padAmount?: number;
            padInstitution?: string;
            padTransit?: string;
            padAccount?: string;
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
            emergencyContacts?: Array<{
              __typename?: 'EmergencyContact';
              name: string;
              relationship?: ContactRelationship;
              phone: string;
            }>;
            pets?: Array<{ __typename?: 'Pet'; type: string; count: number }>;
            vehicles?: Array<{
              __typename?: 'Vehicle';
              id: string;
              plate: string;
              make: string;
              model: string;
              color?: string;
            }>;
          }>;
          incentives?: Array<{
            __typename?: 'LeaseIncentive';
            start: string;
            end: string;
            discountAmount: number;
            type: string;
            description?: string;
          }>;
          rateSchedule?: Array<{ __typename?: 'RateSchedule'; start: string; rent: number }>;
          rentRollFees?: Array<{
            __typename?: 'AgreementLeaseFees';
            feeId: string;
            amount?: number;
            name?: string;
          }>;
          refundables?: Array<{
            __typename?: 'Refundable';
            id: string;
            name: string;
            amount?: number;
            templateId: string;
          }>;
        };
        additionalProvisions?: {
          __typename?: 'AdditionalProvisions';
          pets?: number;
          parking?: boolean;
          storage?: boolean;
          smoking?: boolean;
        };
        signatures?: Array<{
          __typename?: 'Signature';
          id?: string;
          signature: string;
          signerName: string;
          signerEntity: SignerEntity;
          isAgent?: boolean;
          agentRelationship?: string;
        }>;
      };
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ: string;
        typename?: string;
      }>;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        phone: string;
        relationship?: ContactRelationship;
      }>;
      coApplications?: Array<{
        __typename?: 'CoApplication';
        id: string;
        profileId: string;
        name: string;
        emails?: Array<string>;
        phone?: string;
        dob?: string;
        submittedZ: string;
        listedUnitId: string;
        campaignId: string;
        screeningAgreement?: boolean;
        additionalInformation?: string;
        leaseAgreement?: {
          __typename?: 'CoApplicationLeaseAgreement';
          status?: LeaseAgreementStatus;
        };
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate: string;
          endDate?: string;
          frequency?: SalaryFrequency;
          amount?: number;
        }>;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name?: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
        documents?: Array<{
          __typename?: 'Document';
          key: string;
          name?: string;
          size?: number;
          createdZ: string;
          typename?: string;
        }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          phone: string;
          relationship?: ContactRelationship;
        }>;
      }>;
    };
  };
};

export type GetTenantByProfileIdQueryVariables = Exact<{
  profileId: Scalars['ID']['input'];
}>;

export type GetTenantByProfileIdQuery = {
  __typename?: 'Query';
  tenantByProfileId?: { __typename?: 'Tenant'; id: string; isOwner?: boolean };
};

export type SaveLeaseAgreementMutationVariables = Exact<{
  input: CreateLeaseAgreementInput;
}>;

export type SaveLeaseAgreementMutation = {
  __typename?: 'Mutation';
  saveLeaseAgreement?: {
    __typename?: 'ApplicationPayload';
    success: boolean;
    error?: string;
    application?: {
      __typename?: 'Application';
      id: string;
      campaignId: string;
      leaseId?: string;
      residencyId?: string;
      profileId: string;
      name: string;
      emails?: Array<string>;
      phone?: string;
      dob?: string;
      flag?: ApplicationFlag;
      needsAgreement?: boolean;
      moveInDate?: string;
      additionalInformation?: string;
      listedUnitId: string;
      submittedZ: string;
      status?: ApplicationStatus;
      approvedZ?: string;
      declinedZ?: string;
      screeningAgreement?: boolean;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        text?: string;
        source?: string;
        target?: string;
        createdZ?: string;
        createdSub?: string;
        createdName?: string;
      }>;
      workHistory?: Array<{
        __typename?: 'WorkHistory';
        id: string;
        name: string;
        location: string;
        phone?: string;
        startDate: string;
        endDate?: string;
        frequency?: SalaryFrequency;
        amount?: number;
      }>;
      otherIncome?: Array<{
        __typename?: 'Income';
        id: string;
        source: IncomeSource;
        amount: number;
      }>;
      addressHistory?: Array<{
        __typename?: 'AddressHistory';
        id: string;
        name: string;
        phone?: string;
        email?: string;
        startDate: string;
        endDate?: string;
        rentalType?: RentalType;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      household?: {
        __typename?: 'Household';
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        additionalTenants?: Array<{
          __typename?: 'AdditionalTenant';
          id: string;
          name?: string;
          email?: string;
          phone?: string;
          leaseholder?: boolean;
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        }>;
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      };
      listedUnit?: {
        __typename?: 'ListedUnit';
        id: string;
        rent?: number;
        bedrooms?: number;
        bathrooms?: number;
        sqft?: number;
        exteriorSqFt?: number;
      };
      leaseAgreement?: {
        __typename?: 'LeaseAgreement';
        id: string;
        unitId?: string;
        utilities?: Array<string>;
        additionalClause?: Array<string>;
        updatedZ?: string;
        status?: LeaseAgreementStatus;
        houseRules?: Array<string>;
        history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
        lease?: {
          __typename?: 'AgreementLease';
          start?: string;
          end?: string;
          rrule?: string;
          rent: number;
          securityDeposit?: number;
          gst?: number;
          preferredPaymentMethod?: PaymentMethod;
          occupancy?: UnitOccupancy;
          propertyId?: string;
          furnishings?: Array<string>;
          autoRenewSetup?: {
            __typename?: 'AutoRenewSetup';
            enabled?: boolean;
            termMonths?: number;
          };
          tenants?: Array<{
            __typename?: 'LeaseTenant';
            name?: string;
            dob?: string;
            emails?: Array<string>;
            phone?: string;
            phones?: Array<string>;
            leaseHolder?: boolean;
            isPayee?: boolean;
            preferredPaymentMethod?: PaymentMethod;
            padAmount?: number;
            padInstitution?: string;
            padTransit?: string;
            padAccount?: string;
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
            emergencyContacts?: Array<{
              __typename?: 'EmergencyContact';
              name: string;
              relationship?: ContactRelationship;
              phone: string;
            }>;
            pets?: Array<{ __typename?: 'Pet'; type: string; count: number }>;
            vehicles?: Array<{
              __typename?: 'Vehicle';
              id: string;
              plate: string;
              make: string;
              model: string;
              color?: string;
            }>;
          }>;
          incentives?: Array<{
            __typename?: 'LeaseIncentive';
            start: string;
            end: string;
            discountAmount: number;
            type: string;
            description?: string;
          }>;
          rateSchedule?: Array<{ __typename?: 'RateSchedule'; start: string; rent: number }>;
          rentRollFees?: Array<{
            __typename?: 'AgreementLeaseFees';
            feeId: string;
            amount?: number;
            name?: string;
          }>;
          refundables?: Array<{
            __typename?: 'Refundable';
            id: string;
            name: string;
            amount?: number;
            templateId: string;
          }>;
        };
        additionalProvisions?: {
          __typename?: 'AdditionalProvisions';
          pets?: number;
          parking?: boolean;
          storage?: boolean;
          smoking?: boolean;
        };
        signatures?: Array<{
          __typename?: 'Signature';
          id?: string;
          signature: string;
          signerName: string;
          signerEntity: SignerEntity;
          isAgent?: boolean;
          agentRelationship?: string;
        }>;
      };
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ: string;
        typename?: string;
      }>;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        phone: string;
        relationship?: ContactRelationship;
      }>;
      coApplications?: Array<{
        __typename?: 'CoApplication';
        id: string;
        profileId: string;
        name: string;
        emails?: Array<string>;
        phone?: string;
        dob?: string;
        submittedZ: string;
        listedUnitId: string;
        campaignId: string;
        screeningAgreement?: boolean;
        additionalInformation?: string;
        leaseAgreement?: {
          __typename?: 'CoApplicationLeaseAgreement';
          status?: LeaseAgreementStatus;
        };
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate: string;
          endDate?: string;
          frequency?: SalaryFrequency;
          amount?: number;
        }>;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name?: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
        documents?: Array<{
          __typename?: 'Document';
          key: string;
          name?: string;
          size?: number;
          createdZ: string;
          typename?: string;
        }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          phone: string;
          relationship?: ContactRelationship;
        }>;
      }>;
    };
  };
};

export type CreateLeaseAgreementUrlMutationVariables = Exact<{
  input: LeaseAgreementUrlInput;
}>;

export type CreateLeaseAgreementUrlMutation = {
  __typename?: 'Mutation';
  createLeaseAgreementUrl: {
    __typename?: 'LeaseAgreementUrlPayload';
    error?: string;
    success?: boolean;
    agreementUrls?: Array<{
      __typename?: 'AgreementUrl';
      applicationId: string;
      agreementUrl?: {
        __typename?: 'LeaseAgreementUrl';
        accountFileUrl: string;
        accountFileName: string;
        profileFileUrl: string;
        profileFileName: string;
        message?: string;
      };
    }>;
  };
};

export type SignLeaseAgreementMutationVariables = Exact<{
  input: SignLeaseAgreementInput;
}>;

export type SignLeaseAgreementMutation = {
  __typename?: 'Mutation';
  signLeaseAgreement?: {
    __typename?: 'ApplicationPayload';
    success: boolean;
    error?: string;
    application?: {
      __typename?: 'Application';
      id: string;
      leaseAgreement?: {
        __typename?: 'LeaseAgreement';
        signatures?: Array<{
          __typename?: 'Signature';
          id?: string;
          signature: string;
          signerName: string;
          signerEntity: SignerEntity;
          isAgent?: boolean;
          agentRelationship?: string;
        }>;
      };
    };
  };
};

export type SendLeaseOfferMutationVariables = Exact<{
  input: LeaseOfferInput;
}>;

export type SendLeaseOfferMutation = {
  __typename?: 'Mutation';
  sendLeaseOffer?: {
    __typename?: 'ApplicationPayload';
    success: boolean;
    error?: string;
    application?: {
      __typename?: 'Application';
      id: string;
      campaignId: string;
      leaseId?: string;
      residencyId?: string;
      profileId: string;
      name: string;
      emails?: Array<string>;
      phone?: string;
      dob?: string;
      flag?: ApplicationFlag;
      needsAgreement?: boolean;
      moveInDate?: string;
      additionalInformation?: string;
      listedUnitId: string;
      submittedZ: string;
      status?: ApplicationStatus;
      approvedZ?: string;
      declinedZ?: string;
      screeningAgreement?: boolean;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        text?: string;
        source?: string;
        target?: string;
        createdZ?: string;
        createdSub?: string;
        createdName?: string;
      }>;
      workHistory?: Array<{
        __typename?: 'WorkHistory';
        id: string;
        name: string;
        location: string;
        phone?: string;
        startDate: string;
        endDate?: string;
        frequency?: SalaryFrequency;
        amount?: number;
      }>;
      otherIncome?: Array<{
        __typename?: 'Income';
        id: string;
        source: IncomeSource;
        amount: number;
      }>;
      addressHistory?: Array<{
        __typename?: 'AddressHistory';
        id: string;
        name: string;
        phone?: string;
        email?: string;
        startDate: string;
        endDate?: string;
        rentalType?: RentalType;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      household?: {
        __typename?: 'Household';
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        additionalTenants?: Array<{
          __typename?: 'AdditionalTenant';
          id: string;
          name?: string;
          email?: string;
          phone?: string;
          leaseholder?: boolean;
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        }>;
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      };
      listedUnit?: {
        __typename?: 'ListedUnit';
        id: string;
        rent?: number;
        bedrooms?: number;
        bathrooms?: number;
        sqft?: number;
        exteriorSqFt?: number;
      };
      leaseAgreement?: {
        __typename?: 'LeaseAgreement';
        id: string;
        unitId?: string;
        utilities?: Array<string>;
        additionalClause?: Array<string>;
        updatedZ?: string;
        status?: LeaseAgreementStatus;
        houseRules?: Array<string>;
        history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
        lease?: {
          __typename?: 'AgreementLease';
          start?: string;
          end?: string;
          rrule?: string;
          rent: number;
          securityDeposit?: number;
          gst?: number;
          preferredPaymentMethod?: PaymentMethod;
          occupancy?: UnitOccupancy;
          propertyId?: string;
          furnishings?: Array<string>;
          autoRenewSetup?: {
            __typename?: 'AutoRenewSetup';
            enabled?: boolean;
            termMonths?: number;
          };
          tenants?: Array<{
            __typename?: 'LeaseTenant';
            name?: string;
            dob?: string;
            emails?: Array<string>;
            phone?: string;
            phones?: Array<string>;
            leaseHolder?: boolean;
            isPayee?: boolean;
            preferredPaymentMethod?: PaymentMethod;
            padAmount?: number;
            padInstitution?: string;
            padTransit?: string;
            padAccount?: string;
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
            emergencyContacts?: Array<{
              __typename?: 'EmergencyContact';
              name: string;
              relationship?: ContactRelationship;
              phone: string;
            }>;
            pets?: Array<{ __typename?: 'Pet'; type: string; count: number }>;
            vehicles?: Array<{
              __typename?: 'Vehicle';
              id: string;
              plate: string;
              make: string;
              model: string;
              color?: string;
            }>;
          }>;
          incentives?: Array<{
            __typename?: 'LeaseIncentive';
            start: string;
            end: string;
            discountAmount: number;
            type: string;
            description?: string;
          }>;
          rateSchedule?: Array<{ __typename?: 'RateSchedule'; start: string; rent: number }>;
          rentRollFees?: Array<{
            __typename?: 'AgreementLeaseFees';
            feeId: string;
            amount?: number;
            name?: string;
          }>;
          refundables?: Array<{
            __typename?: 'Refundable';
            id: string;
            name: string;
            amount?: number;
            templateId: string;
          }>;
        };
        additionalProvisions?: {
          __typename?: 'AdditionalProvisions';
          pets?: number;
          parking?: boolean;
          storage?: boolean;
          smoking?: boolean;
        };
        signatures?: Array<{
          __typename?: 'Signature';
          id?: string;
          signature: string;
          signerName: string;
          signerEntity: SignerEntity;
          isAgent?: boolean;
          agentRelationship?: string;
        }>;
      };
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ: string;
        typename?: string;
      }>;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        phone: string;
        relationship?: ContactRelationship;
      }>;
      coApplications?: Array<{
        __typename?: 'CoApplication';
        id: string;
        profileId: string;
        name: string;
        emails?: Array<string>;
        phone?: string;
        dob?: string;
        submittedZ: string;
        listedUnitId: string;
        campaignId: string;
        screeningAgreement?: boolean;
        additionalInformation?: string;
        leaseAgreement?: {
          __typename?: 'CoApplicationLeaseAgreement';
          status?: LeaseAgreementStatus;
        };
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate: string;
          endDate?: string;
          frequency?: SalaryFrequency;
          amount?: number;
        }>;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name?: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
        documents?: Array<{
          __typename?: 'Document';
          key: string;
          name?: string;
          size?: number;
          createdZ: string;
          typename?: string;
        }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          phone: string;
          relationship?: ContactRelationship;
        }>;
      }>;
    };
  };
};

export type UnitStepPropertyWithResidencyQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type UnitStepPropertyWithResidencyQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    ownerId?: string;
    enabled?: boolean;
    name: string;
    key?: string;
    leasedUnits?: number;
    totalUnits?: number;
    totalUnitFactor?: number;
    commonAreas?: number;
    ownerUnits?: number;
    commercialLeasedUnits?: number;
    condoUnits?: number;
    imageKey?: string;
    timezone: string;
    commercialUnits?: number;
    rentalUnits?: number;
    phone?: string;
    types?: Array<PropertyType>;
    supplierIds?: Array<string>;
    contractorIds?: Array<string>;
    legalDescription?: string;
    creatingUnits?: number;
    houseRules?: Array<string>;
    autopayCollectionsEnabled?: boolean;
    rentalLeaseDocumentTemplateId?: string;
    commercialLeaseDocumentTemplateId?: string;
    units?: Array<{
      __typename?: 'Unit';
      id: string;
      parking?: Array<string>;
      storage?: Array<string>;
      keyring?: Array<string>;
      name: string;
      legalAddress?: string;
      sqft: number;
      bedrooms: number;
      bathrooms: number;
      additionalInformation?: string;
      occupancy?: UnitOccupancy;
      unitFactor?: number;
      condoFee?: number;
      condoFeeGst?: number;
      preferredPaymentMethod?: PaymentMethod;
      propertyId?: string;
      ownerId?: string;
      buildingId?: string;
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      allResidencies?: Array<{
        __typename?: 'Residency';
        id: string;
        accountId: string;
        proratedClearableIds?: Array<string>;
        moveInInspectionId?: string;
        moveOutInspectionId?: string;
        terms?: Array<string>;
        startZ: string;
        endZ?: string;
        renewZ?: string;
        securityDeposit?: number;
        gst?: number;
        type?: LeaseType;
        occupancy?: UnitOccupancy;
        unitId: string;
        propertyId: string;
        moveInDate?: string;
        moveOutDate?: string;
        moveOutReason?: MoveOutReason;
        applicationId?: string;
        campaignId?: string;
        rrule?: string;
        utilities?: Array<string>;
        furnishings?: Array<string>;
        collectionDay?: number;
        effects?: Array<
          | {
              __typename: 'IncentiveEffect';
              description?: string;
              incentiveId: string;
              id: string;
              feeId: string;
              startZ: string;
              effect: number;
            }
          | {
              __typename: 'ResidencyEffect';
              id: string;
              feeId: string;
              startZ: string;
              effect: number;
            }
        >;
        residents: Array<{
          __typename?: 'ResidentAssociation';
          id: string;
          residentId: string;
          leaseHolder?: boolean;
          title?: ResidencyResidentTitle;
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          paymentDetails?: {
            __typename?: 'PaymentDetails';
            preferredPaymentMethod?: PaymentMethod;
            padAmount?: number;
            padInstitution?: string;
            padTransit?: string;
            padAccount?: string;
          };
          resident:
            | {
                __typename: 'Owner';
                preferredPaymentMethod?: PaymentMethod;
                padAmount?: number;
                padInstitution?: string;
                padTransit?: string;
                padAccount?: string;
                id: string;
                name: string;
                emails?: Array<string>;
                phones?: Array<string>;
                correspondenceMethod?: CorrespondenceMethod;
                enabled?: boolean;
                emergencyContacts?: Array<{
                  __typename?: 'EmergencyContact';
                  name: string;
                  relationship?: ContactRelationship;
                  phone: string;
                }>;
              }
            | {
                __typename: 'Tenant';
                phone?: string;
                dob?: string;
                isRentalAssist?: boolean;
                id: string;
                name: string;
                emails?: Array<string>;
                phones?: Array<string>;
                correspondenceMethod?: CorrespondenceMethod;
                enabled?: boolean;
                vehicles?: Array<{
                  __typename?: 'Vehicle';
                  id: string;
                  plate: string;
                  make: string;
                  model: string;
                  color?: string;
                }>;
                rentalAssistance?: {
                  __typename?: 'RentalAssistance';
                  qualified?: boolean;
                  amount?: number;
                  source?: string;
                };
                pets?: Array<{
                  __typename?: 'Pet';
                  type: string;
                  count: number;
                  details?: Array<{
                    __typename?: 'PetDetails';
                    type?: string;
                    breed?: string;
                    weight?: string;
                    name?: string;
                    color?: string;
                    license?: string;
                    isService?: boolean;
                  }>;
                }>;
                mailingAddress?: {
                  __typename?: 'Address';
                  suite?: string;
                  street?: string;
                  city?: string;
                  province?: string;
                  postal?: string;
                  country?: string;
                  lat?: number;
                  lng?: number;
                };
                emergencyContacts?: Array<{
                  __typename?: 'EmergencyContact';
                  name: string;
                  relationship?: ContactRelationship;
                  phone: string;
                }>;
              };
        }>;
        refundables?: Array<{
          __typename?: 'Refundable';
          id: string;
          name: string;
          amount?: number;
          templateId: string;
          accrualStartExpr?: string;
          collectedZ?: string;
          heldByOwner?: boolean;
          ledger?: Array<{
            __typename?: 'RefundableLedger';
            id: string;
            category: RefundableLedgerCategory;
            amount: number;
            date: string;
          }>;
        }>;
        securityDepositHistory?: Array<{
          __typename?: 'SecurityDepositHistory';
          createdZ: string;
          allocations: Array<{
            __typename?: 'SecurityDepositAllocation';
            status: SecurityDepositStatus;
            amount: number;
          }>;
        }>;
        eventHistory?: Array<{
          __typename?: 'ResidencyEvent';
          id: string;
          name: string;
          category: ResidencyEventCategory;
          description?: string;
          eventDateZ: string;
          deletedZ?: string;
          resolvedZ?: string;
          noticeZ?: string;
          noteText?: string;
        }>;
        insurancePolicies?: Array<{
          __typename?: 'LeaseInsurancePolicy';
          id?: string;
          policyNumber?: string;
          providerName?: string;
          expirationDate?: string;
          liability?: number;
        }>;
        residentInsurancePolicies?: Array<{
          __typename?: 'IamTenantInsurancePolicy';
          id: string;
          policyNumber: string;
          providerName: string;
          liability?: number;
          expiry: string;
        }>;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          target?: string;
          source?: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        additionalProvisions?: {
          __typename?: 'AdditionalProvisions';
          pets?: number;
          parking?: boolean;
          storage?: boolean;
          smoking?: boolean;
        };
      }>;
      property?: {
        __typename?: 'Property';
        id: string;
        enabled?: boolean;
        name: string;
        phone?: string;
        imageKey?: string;
        timezone: string;
        ownerId?: string;
        storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
        parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
        keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
        owner?: {
          __typename?: 'Owner';
          id: string;
          name: string;
          email?: string;
          phones?: Array<string>;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
        };
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      };
      building?: {
        __typename?: 'Building';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        timezone: string;
        phone?: string;
        ownerId?: string;
        types?: Array<PropertyType>;
        legalDescription?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          lat?: number;
          lng?: number;
        };
      };
      condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      history?: Array<
        | {
            __typename?: 'Request';
            summary?: string;
            status?: string;
            delay?: string;
            id: string;
            name?: string;
            tenant?: { __typename?: 'RequestTenant'; name?: string };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
        | {
            __typename?: 'RequestEvent';
            id: string;
            name?: string;
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
      >;
    }>;
    buildings?: Array<{
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    }>;
    address: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
    storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
    keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
    onsiteManager?: { __typename?: 'OnsiteManager'; id: string; entityType: OnsiteManagerType };
    owner?: { __typename?: 'Owner'; id: string; name: string };
  };
};

export type UnitStepAllPropertiesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
}>;

export type UnitStepAllPropertiesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageProperties?: {
      __typename?: 'PropertiesPage';
      totalCount: number;
      pageInfo: { __typename?: 'RelayPageInfo'; endCursor?: string; hasNextPage: boolean };
      edges: Array<{
        __typename?: 'PropertyEdge';
        cursor: string;
        node: { __typename?: 'Property'; id: string; name: string };
      }>;
    };
  };
};

export type GetAccountLeaseExpiryThresholdQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountLeaseExpiryThresholdQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    settings?: { __typename?: 'Settings'; soon_days_threshold?: number };
  };
};

export type ApplicationLeaseUnitWithResidencyQueryVariables = Exact<{
  unitId: Scalars['ID']['input'];
}>;

export type ApplicationLeaseUnitWithResidencyQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    legalAddress?: string;
    sqft: number;
    bedrooms: number;
    bathrooms: number;
    additionalInformation?: string;
    occupancy?: UnitOccupancy;
    parking?: Array<string>;
    storage?: Array<string>;
    keyring?: Array<string>;
    unitFactor?: number;
    condoFee?: number;
    condoFeeGst?: number;
    preferredPaymentMethod?: PaymentMethod;
    propertyId?: string;
    ownerId?: string;
    buildingId?: string;
    allResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          category: RefundableLedgerCategory;
          amount: number;
          date: string;
        }>;
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    }>;
    property?: {
      __typename?: 'Property';
      id: string;
      enabled?: boolean;
      name: string;
      phone?: string;
      imageKey?: string;
      timezone: string;
      ownerId?: string;
      storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
      parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
      keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
    building?: {
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    };
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      email?: string;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
    };
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    history?: Array<
      | {
          __typename?: 'Request';
          summary?: string;
          status?: string;
          delay?: string;
          id: string;
          name?: string;
          tenant?: { __typename?: 'RequestTenant'; name?: string };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
      | {
          __typename?: 'RequestEvent';
          id: string;
          name?: string;
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
    >;
  };
};

export type UpdateApplicationFlagMutationVariables = Exact<{
  input: UpdateApplicationInput;
}>;

export type UpdateApplicationFlagMutation = {
  __typename?: 'Mutation';
  updateApplication?: {
    __typename?: 'ApplicationPayload';
    success: boolean;
    error?: string;
    application?: { __typename?: 'Application'; id: string; flag?: ApplicationFlag };
  };
};

export type GetCampaignQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCampaignQuery = {
  __typename?: 'Query';
  campaign?: {
    __typename?: 'Campaign';
    id: string;
    accountId: string;
    propertyId: string;
    link?: string;
    name?: string;
    status: CampaignStatus;
    systemCreated?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
      units?: Array<{ __typename?: 'Unit'; id: string; floorplanId?: string }>;
    };
    listedUnits?: Array<{
      __typename?: 'ListedUnit';
      id: string;
      rent?: number;
      bedrooms?: number;
      bathrooms?: number;
      sqft?: number;
      exteriorSqFt?: number;
    }>;
    history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
    applications?: Array<{
      __typename?: 'Application';
      id: string;
      campaignId: string;
      leaseId?: string;
      residencyId?: string;
      profileId: string;
      name: string;
      emails?: Array<string>;
      phone?: string;
      dob?: string;
      flag?: ApplicationFlag;
      needsAgreement?: boolean;
      moveInDate?: string;
      additionalInformation?: string;
      listedUnitId: string;
      submittedZ: string;
      status?: ApplicationStatus;
      approvedZ?: string;
      declinedZ?: string;
      screeningAgreement?: boolean;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        text?: string;
        source?: string;
        target?: string;
        createdZ?: string;
        createdSub?: string;
        createdName?: string;
      }>;
      workHistory?: Array<{
        __typename?: 'WorkHistory';
        id: string;
        name: string;
        location: string;
        phone?: string;
        startDate: string;
        endDate?: string;
        frequency?: SalaryFrequency;
        amount?: number;
      }>;
      otherIncome?: Array<{
        __typename?: 'Income';
        id: string;
        source: IncomeSource;
        amount: number;
      }>;
      addressHistory?: Array<{
        __typename?: 'AddressHistory';
        id: string;
        name: string;
        phone?: string;
        email?: string;
        startDate: string;
        endDate?: string;
        rentalType?: RentalType;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      household?: {
        __typename?: 'Household';
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        additionalTenants?: Array<{
          __typename?: 'AdditionalTenant';
          id: string;
          name?: string;
          email?: string;
          phone?: string;
          leaseholder?: boolean;
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        }>;
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      };
      listedUnit?: {
        __typename?: 'ListedUnit';
        id: string;
        rent?: number;
        bedrooms?: number;
        bathrooms?: number;
        sqft?: number;
        exteriorSqFt?: number;
      };
      leaseAgreement?: {
        __typename?: 'LeaseAgreement';
        id: string;
        unitId?: string;
        utilities?: Array<string>;
        additionalClause?: Array<string>;
        updatedZ?: string;
        status?: LeaseAgreementStatus;
        houseRules?: Array<string>;
        history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
        lease?: {
          __typename?: 'AgreementLease';
          start?: string;
          end?: string;
          rrule?: string;
          rent: number;
          securityDeposit?: number;
          gst?: number;
          preferredPaymentMethod?: PaymentMethod;
          occupancy?: UnitOccupancy;
          propertyId?: string;
          furnishings?: Array<string>;
          autoRenewSetup?: {
            __typename?: 'AutoRenewSetup';
            enabled?: boolean;
            termMonths?: number;
          };
          tenants?: Array<{
            __typename?: 'LeaseTenant';
            name?: string;
            dob?: string;
            emails?: Array<string>;
            phone?: string;
            phones?: Array<string>;
            leaseHolder?: boolean;
            isPayee?: boolean;
            preferredPaymentMethod?: PaymentMethod;
            padAmount?: number;
            padInstitution?: string;
            padTransit?: string;
            padAccount?: string;
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
            emergencyContacts?: Array<{
              __typename?: 'EmergencyContact';
              name: string;
              relationship?: ContactRelationship;
              phone: string;
            }>;
            pets?: Array<{ __typename?: 'Pet'; type: string; count: number }>;
            vehicles?: Array<{
              __typename?: 'Vehicle';
              id: string;
              plate: string;
              make: string;
              model: string;
              color?: string;
            }>;
          }>;
          incentives?: Array<{
            __typename?: 'LeaseIncentive';
            start: string;
            end: string;
            discountAmount: number;
            type: string;
            description?: string;
          }>;
          rateSchedule?: Array<{ __typename?: 'RateSchedule'; start: string; rent: number }>;
          rentRollFees?: Array<{
            __typename?: 'AgreementLeaseFees';
            feeId: string;
            amount?: number;
            name?: string;
          }>;
          refundables?: Array<{
            __typename?: 'Refundable';
            id: string;
            name: string;
            amount?: number;
            templateId: string;
          }>;
        };
        additionalProvisions?: {
          __typename?: 'AdditionalProvisions';
          pets?: number;
          parking?: boolean;
          storage?: boolean;
          smoking?: boolean;
        };
        signatures?: Array<{
          __typename?: 'Signature';
          id?: string;
          signature: string;
          signerName: string;
          signerEntity: SignerEntity;
          isAgent?: boolean;
          agentRelationship?: string;
        }>;
      };
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ: string;
        typename?: string;
      }>;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        phone: string;
        relationship?: ContactRelationship;
      }>;
      coApplications?: Array<{
        __typename?: 'CoApplication';
        id: string;
        profileId: string;
        name: string;
        emails?: Array<string>;
        phone?: string;
        dob?: string;
        submittedZ: string;
        listedUnitId: string;
        campaignId: string;
        screeningAgreement?: boolean;
        additionalInformation?: string;
        leaseAgreement?: {
          __typename?: 'CoApplicationLeaseAgreement';
          status?: LeaseAgreementStatus;
        };
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate: string;
          endDate?: string;
          frequency?: SalaryFrequency;
          amount?: number;
        }>;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name?: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
        documents?: Array<{
          __typename?: 'Document';
          key: string;
          name?: string;
          size?: number;
          createdZ: string;
          typename?: string;
        }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          phone: string;
          relationship?: ContactRelationship;
        }>;
      }>;
    }>;
  };
};

export type GetCampaignsListedUnitsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCampaignsListedUnitsQuery = {
  __typename?: 'Query';
  campaigns?: {
    __typename?: 'CampaignConnection';
    nextToken?: string;
    items?: Array<{
      __typename?: 'Campaign';
      id: string;
      accountId: string;
      propertyId: string;
      status: CampaignStatus;
      property?: { __typename?: 'Property'; id: string; name: string; enabled?: boolean };
      listedUnits?: Array<{
        __typename?: 'ListedUnit';
        id: string;
        rent?: number;
        bedrooms?: number;
        bathrooms?: number;
        sqft?: number;
        exteriorSqFt?: number;
      }>;
    }>;
  };
};

export type GetFloorplanByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetFloorplanByIdsQuery = {
  __typename?: 'Query';
  floorplanByIds?: Array<{
    __typename?: 'Floorplan';
    id: string;
    name: string;
    marketRate?: number;
    exteriorSqFt?: number;
    interiorSqFt?: number;
    bedrooms?: number;
    bathrooms?: number;
    units?: Array<{ __typename?: 'Unit'; id: string; name: string }>;
  }>;
};

export type CampaignListFieldsFragment = {
  __typename?: 'Campaign';
  id: string;
  accountId: string;
  propertyId: string;
  link?: string;
  name?: string;
  status: CampaignStatus;
  systemCreated?: boolean;
  property?: {
    __typename?: 'Property';
    id: string;
    enabled?: boolean;
    name: string;
    key?: string;
    imageKey?: string;
    address: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  };
  listedUnits?: Array<{
    __typename?: 'ListedUnit';
    id: string;
    rent?: number;
    bedrooms?: number;
    bathrooms?: number;
    sqft?: number;
    exteriorSqFt?: number;
  }>;
  applications?: Array<{
    __typename?: 'Application';
    id: string;
    campaignId: string;
    leaseId?: string;
    residencyId?: string;
    profileId: string;
    status?: ApplicationStatus;
  }>;
  history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
};

export type GetCampaignsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCampaignsQuery = {
  __typename?: 'Query';
  campaigns?: {
    __typename?: 'CampaignConnection';
    nextToken?: string;
    items?: Array<{
      __typename?: 'Campaign';
      id: string;
      accountId: string;
      propertyId: string;
      link?: string;
      name?: string;
      status: CampaignStatus;
      systemCreated?: boolean;
      property?: {
        __typename?: 'Property';
        id: string;
        enabled?: boolean;
        name: string;
        key?: string;
        imageKey?: string;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      };
      listedUnits?: Array<{
        __typename?: 'ListedUnit';
        id: string;
        rent?: number;
        bedrooms?: number;
        bathrooms?: number;
        sqft?: number;
        exteriorSqFt?: number;
      }>;
      applications?: Array<{
        __typename?: 'Application';
        id: string;
        campaignId: string;
        leaseId?: string;
        residencyId?: string;
        profileId: string;
        status?: ApplicationStatus;
      }>;
      history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
    }>;
  };
};

export type ShareCampaignMutationVariables = Exact<{
  input: ShareCampaignInput;
}>;

export type ShareCampaignMutation = {
  __typename?: 'Mutation';
  shareCampaign: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type CreateCampaignLinkMutationVariables = Exact<{
  input: CreateCampaignLinkInput;
}>;

export type CreateCampaignLinkMutation = {
  __typename?: 'Mutation';
  createCampaignLink: {
    __typename?: 'CampaignPayload';
    success: boolean;
    error?: string;
    campaign?: {
      __typename?: 'Campaign';
      id: string;
      accountId: string;
      propertyId: string;
      link?: string;
      name?: string;
      status: CampaignStatus;
      systemCreated?: boolean;
      property?: {
        __typename?: 'Property';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
        units?: Array<{ __typename?: 'Unit'; id: string; floorplanId?: string }>;
      };
      listedUnits?: Array<{
        __typename?: 'ListedUnit';
        id: string;
        rent?: number;
        bedrooms?: number;
        bathrooms?: number;
        sqft?: number;
        exteriorSqFt?: number;
      }>;
      history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
      applications?: Array<{
        __typename?: 'Application';
        id: string;
        campaignId: string;
        leaseId?: string;
        residencyId?: string;
        profileId: string;
        name: string;
        emails?: Array<string>;
        phone?: string;
        dob?: string;
        flag?: ApplicationFlag;
        needsAgreement?: boolean;
        moveInDate?: string;
        additionalInformation?: string;
        listedUnitId: string;
        submittedZ: string;
        status?: ApplicationStatus;
        approvedZ?: string;
        declinedZ?: string;
        screeningAgreement?: boolean;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          text?: string;
          source?: string;
          target?: string;
          createdZ?: string;
          createdSub?: string;
          createdName?: string;
        }>;
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate: string;
          endDate?: string;
          frequency?: SalaryFrequency;
          amount?: number;
        }>;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name?: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
        listedUnit?: {
          __typename?: 'ListedUnit';
          id: string;
          rent?: number;
          bedrooms?: number;
          bathrooms?: number;
          sqft?: number;
          exteriorSqFt?: number;
        };
        leaseAgreement?: {
          __typename?: 'LeaseAgreement';
          id: string;
          unitId?: string;
          utilities?: Array<string>;
          additionalClause?: Array<string>;
          updatedZ?: string;
          status?: LeaseAgreementStatus;
          houseRules?: Array<string>;
          history?: Array<{
            __typename?: 'StatusHistoryEvent';
            status?: string;
            createdZ?: string;
          }>;
          lease?: {
            __typename?: 'AgreementLease';
            start?: string;
            end?: string;
            rrule?: string;
            rent: number;
            securityDeposit?: number;
            gst?: number;
            preferredPaymentMethod?: PaymentMethod;
            occupancy?: UnitOccupancy;
            propertyId?: string;
            furnishings?: Array<string>;
            autoRenewSetup?: {
              __typename?: 'AutoRenewSetup';
              enabled?: boolean;
              termMonths?: number;
            };
            tenants?: Array<{
              __typename?: 'LeaseTenant';
              name?: string;
              dob?: string;
              emails?: Array<string>;
              phone?: string;
              phones?: Array<string>;
              leaseHolder?: boolean;
              isPayee?: boolean;
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
              pets?: Array<{ __typename?: 'Pet'; type: string; count: number }>;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
            }>;
            incentives?: Array<{
              __typename?: 'LeaseIncentive';
              start: string;
              end: string;
              discountAmount: number;
              type: string;
              description?: string;
            }>;
            rateSchedule?: Array<{ __typename?: 'RateSchedule'; start: string; rent: number }>;
            rentRollFees?: Array<{
              __typename?: 'AgreementLeaseFees';
              feeId: string;
              amount?: number;
              name?: string;
            }>;
            refundables?: Array<{
              __typename?: 'Refundable';
              id: string;
              name: string;
              amount?: number;
              templateId: string;
            }>;
          };
          additionalProvisions?: {
            __typename?: 'AdditionalProvisions';
            pets?: number;
            parking?: boolean;
            storage?: boolean;
            smoking?: boolean;
          };
          signatures?: Array<{
            __typename?: 'Signature';
            id?: string;
            signature: string;
            signerName: string;
            signerEntity: SignerEntity;
            isAgent?: boolean;
            agentRelationship?: string;
          }>;
        };
        documents?: Array<{
          __typename?: 'Document';
          key: string;
          name?: string;
          size?: number;
          createdZ: string;
          typename?: string;
        }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          phone: string;
          relationship?: ContactRelationship;
        }>;
        coApplications?: Array<{
          __typename?: 'CoApplication';
          id: string;
          profileId: string;
          name: string;
          emails?: Array<string>;
          phone?: string;
          dob?: string;
          submittedZ: string;
          listedUnitId: string;
          campaignId: string;
          screeningAgreement?: boolean;
          additionalInformation?: string;
          leaseAgreement?: {
            __typename?: 'CoApplicationLeaseAgreement';
            status?: LeaseAgreementStatus;
          };
          workHistory?: Array<{
            __typename?: 'WorkHistory';
            id: string;
            name: string;
            location: string;
            phone?: string;
            startDate: string;
            endDate?: string;
            frequency?: SalaryFrequency;
            amount?: number;
          }>;
          otherIncome?: Array<{
            __typename?: 'Income';
            id: string;
            source: IncomeSource;
            amount: number;
          }>;
          addressHistory?: Array<{
            __typename?: 'AddressHistory';
            id: string;
            name: string;
            phone?: string;
            email?: string;
            startDate: string;
            endDate?: string;
            rentalType?: RentalType;
            address: {
              __typename?: 'Address';
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
          }>;
          household?: {
            __typename?: 'Household';
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
            additionalTenants?: Array<{
              __typename?: 'AdditionalTenant';
              id: string;
              name?: string;
              email?: string;
              phone?: string;
              leaseholder?: boolean;
              payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
            }>;
            pets?: Array<{
              __typename?: 'Pet';
              type: string;
              count: number;
              details?: Array<{
                __typename?: 'PetDetails';
                type?: string;
                breed?: string;
                weight?: string;
                name?: string;
                color?: string;
                license?: string;
                isService?: boolean;
              }>;
            }>;
            vehicles?: Array<{
              __typename?: 'Vehicle';
              id: string;
              plate: string;
              make: string;
              model: string;
              color?: string;
            }>;
          };
          documents?: Array<{
            __typename?: 'Document';
            key: string;
            name?: string;
            size?: number;
            createdZ: string;
            typename?: string;
          }>;
          emergencyContacts?: Array<{
            __typename?: 'EmergencyContact';
            name: string;
            phone: string;
            relationship?: ContactRelationship;
          }>;
        }>;
      }>;
    };
  };
};

export type UpdateCampaignStatusMutationVariables = Exact<{
  input: UpdateCampaignStatusInput;
}>;

export type UpdateCampaignStatusMutation = {
  __typename?: 'Mutation';
  updateCampaignStatus?: {
    __typename?: 'CampaignPayload';
    success: boolean;
    error?: string;
    campaign?: {
      __typename?: 'Campaign';
      id: string;
      accountId: string;
      propertyId: string;
      link?: string;
      name?: string;
      status: CampaignStatus;
      systemCreated?: boolean;
      property?: {
        __typename?: 'Property';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
        units?: Array<{ __typename?: 'Unit'; id: string; floorplanId?: string }>;
      };
      listedUnits?: Array<{
        __typename?: 'ListedUnit';
        id: string;
        rent?: number;
        bedrooms?: number;
        bathrooms?: number;
        sqft?: number;
        exteriorSqFt?: number;
      }>;
      history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
      applications?: Array<{
        __typename?: 'Application';
        id: string;
        campaignId: string;
        leaseId?: string;
        residencyId?: string;
        profileId: string;
        name: string;
        emails?: Array<string>;
        phone?: string;
        dob?: string;
        flag?: ApplicationFlag;
        needsAgreement?: boolean;
        moveInDate?: string;
        additionalInformation?: string;
        listedUnitId: string;
        submittedZ: string;
        status?: ApplicationStatus;
        approvedZ?: string;
        declinedZ?: string;
        screeningAgreement?: boolean;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          text?: string;
          source?: string;
          target?: string;
          createdZ?: string;
          createdSub?: string;
          createdName?: string;
        }>;
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate: string;
          endDate?: string;
          frequency?: SalaryFrequency;
          amount?: number;
        }>;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name?: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
        listedUnit?: {
          __typename?: 'ListedUnit';
          id: string;
          rent?: number;
          bedrooms?: number;
          bathrooms?: number;
          sqft?: number;
          exteriorSqFt?: number;
        };
        leaseAgreement?: {
          __typename?: 'LeaseAgreement';
          id: string;
          unitId?: string;
          utilities?: Array<string>;
          additionalClause?: Array<string>;
          updatedZ?: string;
          status?: LeaseAgreementStatus;
          houseRules?: Array<string>;
          history?: Array<{
            __typename?: 'StatusHistoryEvent';
            status?: string;
            createdZ?: string;
          }>;
          lease?: {
            __typename?: 'AgreementLease';
            start?: string;
            end?: string;
            rrule?: string;
            rent: number;
            securityDeposit?: number;
            gst?: number;
            preferredPaymentMethod?: PaymentMethod;
            occupancy?: UnitOccupancy;
            propertyId?: string;
            furnishings?: Array<string>;
            autoRenewSetup?: {
              __typename?: 'AutoRenewSetup';
              enabled?: boolean;
              termMonths?: number;
            };
            tenants?: Array<{
              __typename?: 'LeaseTenant';
              name?: string;
              dob?: string;
              emails?: Array<string>;
              phone?: string;
              phones?: Array<string>;
              leaseHolder?: boolean;
              isPayee?: boolean;
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
              pets?: Array<{ __typename?: 'Pet'; type: string; count: number }>;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
            }>;
            incentives?: Array<{
              __typename?: 'LeaseIncentive';
              start: string;
              end: string;
              discountAmount: number;
              type: string;
              description?: string;
            }>;
            rateSchedule?: Array<{ __typename?: 'RateSchedule'; start: string; rent: number }>;
            rentRollFees?: Array<{
              __typename?: 'AgreementLeaseFees';
              feeId: string;
              amount?: number;
              name?: string;
            }>;
            refundables?: Array<{
              __typename?: 'Refundable';
              id: string;
              name: string;
              amount?: number;
              templateId: string;
            }>;
          };
          additionalProvisions?: {
            __typename?: 'AdditionalProvisions';
            pets?: number;
            parking?: boolean;
            storage?: boolean;
            smoking?: boolean;
          };
          signatures?: Array<{
            __typename?: 'Signature';
            id?: string;
            signature: string;
            signerName: string;
            signerEntity: SignerEntity;
            isAgent?: boolean;
            agentRelationship?: string;
          }>;
        };
        documents?: Array<{
          __typename?: 'Document';
          key: string;
          name?: string;
          size?: number;
          createdZ: string;
          typename?: string;
        }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          phone: string;
          relationship?: ContactRelationship;
        }>;
        coApplications?: Array<{
          __typename?: 'CoApplication';
          id: string;
          profileId: string;
          name: string;
          emails?: Array<string>;
          phone?: string;
          dob?: string;
          submittedZ: string;
          listedUnitId: string;
          campaignId: string;
          screeningAgreement?: boolean;
          additionalInformation?: string;
          leaseAgreement?: {
            __typename?: 'CoApplicationLeaseAgreement';
            status?: LeaseAgreementStatus;
          };
          workHistory?: Array<{
            __typename?: 'WorkHistory';
            id: string;
            name: string;
            location: string;
            phone?: string;
            startDate: string;
            endDate?: string;
            frequency?: SalaryFrequency;
            amount?: number;
          }>;
          otherIncome?: Array<{
            __typename?: 'Income';
            id: string;
            source: IncomeSource;
            amount: number;
          }>;
          addressHistory?: Array<{
            __typename?: 'AddressHistory';
            id: string;
            name: string;
            phone?: string;
            email?: string;
            startDate: string;
            endDate?: string;
            rentalType?: RentalType;
            address: {
              __typename?: 'Address';
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
          }>;
          household?: {
            __typename?: 'Household';
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
            additionalTenants?: Array<{
              __typename?: 'AdditionalTenant';
              id: string;
              name?: string;
              email?: string;
              phone?: string;
              leaseholder?: boolean;
              payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
            }>;
            pets?: Array<{
              __typename?: 'Pet';
              type: string;
              count: number;
              details?: Array<{
                __typename?: 'PetDetails';
                type?: string;
                breed?: string;
                weight?: string;
                name?: string;
                color?: string;
                license?: string;
                isService?: boolean;
              }>;
            }>;
            vehicles?: Array<{
              __typename?: 'Vehicle';
              id: string;
              plate: string;
              make: string;
              model: string;
              color?: string;
            }>;
          };
          documents?: Array<{
            __typename?: 'Document';
            key: string;
            name?: string;
            size?: number;
            createdZ: string;
            typename?: string;
          }>;
          emergencyContacts?: Array<{
            __typename?: 'EmergencyContact';
            name: string;
            phone: string;
            relationship?: ContactRelationship;
          }>;
        }>;
      }>;
    };
  };
};

export type CreateCampaignMutationVariables = Exact<{
  input: CreateCampaignInput;
}>;

export type CreateCampaignMutation = {
  __typename?: 'Mutation';
  createCampaign: {
    __typename?: 'CreateCampaignPayload';
    success?: boolean;
    error?: string;
    campaign?: {
      __typename?: 'Campaign';
      id: string;
      accountId: string;
      propertyId: string;
      link?: string;
      name?: string;
      status: CampaignStatus;
      systemCreated?: boolean;
      property?: {
        __typename?: 'Property';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
        units?: Array<{ __typename?: 'Unit'; id: string; floorplanId?: string }>;
      };
      listedUnits?: Array<{
        __typename?: 'ListedUnit';
        id: string;
        rent?: number;
        bedrooms?: number;
        bathrooms?: number;
        sqft?: number;
        exteriorSqFt?: number;
      }>;
      history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
      applications?: Array<{
        __typename?: 'Application';
        id: string;
        campaignId: string;
        leaseId?: string;
        residencyId?: string;
        profileId: string;
        name: string;
        emails?: Array<string>;
        phone?: string;
        dob?: string;
        flag?: ApplicationFlag;
        needsAgreement?: boolean;
        moveInDate?: string;
        additionalInformation?: string;
        listedUnitId: string;
        submittedZ: string;
        status?: ApplicationStatus;
        approvedZ?: string;
        declinedZ?: string;
        screeningAgreement?: boolean;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          text?: string;
          source?: string;
          target?: string;
          createdZ?: string;
          createdSub?: string;
          createdName?: string;
        }>;
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate: string;
          endDate?: string;
          frequency?: SalaryFrequency;
          amount?: number;
        }>;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name?: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
        listedUnit?: {
          __typename?: 'ListedUnit';
          id: string;
          rent?: number;
          bedrooms?: number;
          bathrooms?: number;
          sqft?: number;
          exteriorSqFt?: number;
        };
        leaseAgreement?: {
          __typename?: 'LeaseAgreement';
          id: string;
          unitId?: string;
          utilities?: Array<string>;
          additionalClause?: Array<string>;
          updatedZ?: string;
          status?: LeaseAgreementStatus;
          houseRules?: Array<string>;
          history?: Array<{
            __typename?: 'StatusHistoryEvent';
            status?: string;
            createdZ?: string;
          }>;
          lease?: {
            __typename?: 'AgreementLease';
            start?: string;
            end?: string;
            rrule?: string;
            rent: number;
            securityDeposit?: number;
            gst?: number;
            preferredPaymentMethod?: PaymentMethod;
            occupancy?: UnitOccupancy;
            propertyId?: string;
            furnishings?: Array<string>;
            autoRenewSetup?: {
              __typename?: 'AutoRenewSetup';
              enabled?: boolean;
              termMonths?: number;
            };
            tenants?: Array<{
              __typename?: 'LeaseTenant';
              name?: string;
              dob?: string;
              emails?: Array<string>;
              phone?: string;
              phones?: Array<string>;
              leaseHolder?: boolean;
              isPayee?: boolean;
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
              pets?: Array<{ __typename?: 'Pet'; type: string; count: number }>;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
            }>;
            incentives?: Array<{
              __typename?: 'LeaseIncentive';
              start: string;
              end: string;
              discountAmount: number;
              type: string;
              description?: string;
            }>;
            rateSchedule?: Array<{ __typename?: 'RateSchedule'; start: string; rent: number }>;
            rentRollFees?: Array<{
              __typename?: 'AgreementLeaseFees';
              feeId: string;
              amount?: number;
              name?: string;
            }>;
            refundables?: Array<{
              __typename?: 'Refundable';
              id: string;
              name: string;
              amount?: number;
              templateId: string;
            }>;
          };
          additionalProvisions?: {
            __typename?: 'AdditionalProvisions';
            pets?: number;
            parking?: boolean;
            storage?: boolean;
            smoking?: boolean;
          };
          signatures?: Array<{
            __typename?: 'Signature';
            id?: string;
            signature: string;
            signerName: string;
            signerEntity: SignerEntity;
            isAgent?: boolean;
            agentRelationship?: string;
          }>;
        };
        documents?: Array<{
          __typename?: 'Document';
          key: string;
          name?: string;
          size?: number;
          createdZ: string;
          typename?: string;
        }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          phone: string;
          relationship?: ContactRelationship;
        }>;
        coApplications?: Array<{
          __typename?: 'CoApplication';
          id: string;
          profileId: string;
          name: string;
          emails?: Array<string>;
          phone?: string;
          dob?: string;
          submittedZ: string;
          listedUnitId: string;
          campaignId: string;
          screeningAgreement?: boolean;
          additionalInformation?: string;
          leaseAgreement?: {
            __typename?: 'CoApplicationLeaseAgreement';
            status?: LeaseAgreementStatus;
          };
          workHistory?: Array<{
            __typename?: 'WorkHistory';
            id: string;
            name: string;
            location: string;
            phone?: string;
            startDate: string;
            endDate?: string;
            frequency?: SalaryFrequency;
            amount?: number;
          }>;
          otherIncome?: Array<{
            __typename?: 'Income';
            id: string;
            source: IncomeSource;
            amount: number;
          }>;
          addressHistory?: Array<{
            __typename?: 'AddressHistory';
            id: string;
            name: string;
            phone?: string;
            email?: string;
            startDate: string;
            endDate?: string;
            rentalType?: RentalType;
            address: {
              __typename?: 'Address';
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
          }>;
          household?: {
            __typename?: 'Household';
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
            additionalTenants?: Array<{
              __typename?: 'AdditionalTenant';
              id: string;
              name?: string;
              email?: string;
              phone?: string;
              leaseholder?: boolean;
              payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
            }>;
            pets?: Array<{
              __typename?: 'Pet';
              type: string;
              count: number;
              details?: Array<{
                __typename?: 'PetDetails';
                type?: string;
                breed?: string;
                weight?: string;
                name?: string;
                color?: string;
                license?: string;
                isService?: boolean;
              }>;
            }>;
            vehicles?: Array<{
              __typename?: 'Vehicle';
              id: string;
              plate: string;
              make: string;
              model: string;
              color?: string;
            }>;
          };
          documents?: Array<{
            __typename?: 'Document';
            key: string;
            name?: string;
            size?: number;
            createdZ: string;
            typename?: string;
          }>;
          emergencyContacts?: Array<{
            __typename?: 'EmergencyContact';
            name: string;
            phone: string;
            relationship?: ContactRelationship;
          }>;
        }>;
      }>;
    };
  };
};

export type UpdateCampaignMutationVariables = Exact<{
  input: UpdateCampaignInput;
}>;

export type UpdateCampaignMutation = {
  __typename?: 'Mutation';
  updateCampaign: {
    __typename?: 'CampaignPayload';
    success: boolean;
    error?: string;
    campaign?: {
      __typename?: 'Campaign';
      id: string;
      accountId: string;
      propertyId: string;
      link?: string;
      name?: string;
      status: CampaignStatus;
      systemCreated?: boolean;
      property?: {
        __typename?: 'Property';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
        units?: Array<{ __typename?: 'Unit'; id: string; floorplanId?: string }>;
      };
      listedUnits?: Array<{
        __typename?: 'ListedUnit';
        id: string;
        rent?: number;
        bedrooms?: number;
        bathrooms?: number;
        sqft?: number;
        exteriorSqFt?: number;
      }>;
      history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
      applications?: Array<{
        __typename?: 'Application';
        id: string;
        campaignId: string;
        leaseId?: string;
        residencyId?: string;
        profileId: string;
        name: string;
        emails?: Array<string>;
        phone?: string;
        dob?: string;
        flag?: ApplicationFlag;
        needsAgreement?: boolean;
        moveInDate?: string;
        additionalInformation?: string;
        listedUnitId: string;
        submittedZ: string;
        status?: ApplicationStatus;
        approvedZ?: string;
        declinedZ?: string;
        screeningAgreement?: boolean;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          text?: string;
          source?: string;
          target?: string;
          createdZ?: string;
          createdSub?: string;
          createdName?: string;
        }>;
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate: string;
          endDate?: string;
          frequency?: SalaryFrequency;
          amount?: number;
        }>;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name?: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
        listedUnit?: {
          __typename?: 'ListedUnit';
          id: string;
          rent?: number;
          bedrooms?: number;
          bathrooms?: number;
          sqft?: number;
          exteriorSqFt?: number;
        };
        leaseAgreement?: {
          __typename?: 'LeaseAgreement';
          id: string;
          unitId?: string;
          utilities?: Array<string>;
          additionalClause?: Array<string>;
          updatedZ?: string;
          status?: LeaseAgreementStatus;
          houseRules?: Array<string>;
          history?: Array<{
            __typename?: 'StatusHistoryEvent';
            status?: string;
            createdZ?: string;
          }>;
          lease?: {
            __typename?: 'AgreementLease';
            start?: string;
            end?: string;
            rrule?: string;
            rent: number;
            securityDeposit?: number;
            gst?: number;
            preferredPaymentMethod?: PaymentMethod;
            occupancy?: UnitOccupancy;
            propertyId?: string;
            furnishings?: Array<string>;
            autoRenewSetup?: {
              __typename?: 'AutoRenewSetup';
              enabled?: boolean;
              termMonths?: number;
            };
            tenants?: Array<{
              __typename?: 'LeaseTenant';
              name?: string;
              dob?: string;
              emails?: Array<string>;
              phone?: string;
              phones?: Array<string>;
              leaseHolder?: boolean;
              isPayee?: boolean;
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
              pets?: Array<{ __typename?: 'Pet'; type: string; count: number }>;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
            }>;
            incentives?: Array<{
              __typename?: 'LeaseIncentive';
              start: string;
              end: string;
              discountAmount: number;
              type: string;
              description?: string;
            }>;
            rateSchedule?: Array<{ __typename?: 'RateSchedule'; start: string; rent: number }>;
            rentRollFees?: Array<{
              __typename?: 'AgreementLeaseFees';
              feeId: string;
              amount?: number;
              name?: string;
            }>;
            refundables?: Array<{
              __typename?: 'Refundable';
              id: string;
              name: string;
              amount?: number;
              templateId: string;
            }>;
          };
          additionalProvisions?: {
            __typename?: 'AdditionalProvisions';
            pets?: number;
            parking?: boolean;
            storage?: boolean;
            smoking?: boolean;
          };
          signatures?: Array<{
            __typename?: 'Signature';
            id?: string;
            signature: string;
            signerName: string;
            signerEntity: SignerEntity;
            isAgent?: boolean;
            agentRelationship?: string;
          }>;
        };
        documents?: Array<{
          __typename?: 'Document';
          key: string;
          name?: string;
          size?: number;
          createdZ: string;
          typename?: string;
        }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          phone: string;
          relationship?: ContactRelationship;
        }>;
        coApplications?: Array<{
          __typename?: 'CoApplication';
          id: string;
          profileId: string;
          name: string;
          emails?: Array<string>;
          phone?: string;
          dob?: string;
          submittedZ: string;
          listedUnitId: string;
          campaignId: string;
          screeningAgreement?: boolean;
          additionalInformation?: string;
          leaseAgreement?: {
            __typename?: 'CoApplicationLeaseAgreement';
            status?: LeaseAgreementStatus;
          };
          workHistory?: Array<{
            __typename?: 'WorkHistory';
            id: string;
            name: string;
            location: string;
            phone?: string;
            startDate: string;
            endDate?: string;
            frequency?: SalaryFrequency;
            amount?: number;
          }>;
          otherIncome?: Array<{
            __typename?: 'Income';
            id: string;
            source: IncomeSource;
            amount: number;
          }>;
          addressHistory?: Array<{
            __typename?: 'AddressHistory';
            id: string;
            name: string;
            phone?: string;
            email?: string;
            startDate: string;
            endDate?: string;
            rentalType?: RentalType;
            address: {
              __typename?: 'Address';
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
          }>;
          household?: {
            __typename?: 'Household';
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
            additionalTenants?: Array<{
              __typename?: 'AdditionalTenant';
              id: string;
              name?: string;
              email?: string;
              phone?: string;
              leaseholder?: boolean;
              payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
            }>;
            pets?: Array<{
              __typename?: 'Pet';
              type: string;
              count: number;
              details?: Array<{
                __typename?: 'PetDetails';
                type?: string;
                breed?: string;
                weight?: string;
                name?: string;
                color?: string;
                license?: string;
                isService?: boolean;
              }>;
            }>;
            vehicles?: Array<{
              __typename?: 'Vehicle';
              id: string;
              plate: string;
              make: string;
              model: string;
              color?: string;
            }>;
          };
          documents?: Array<{
            __typename?: 'Document';
            key: string;
            name?: string;
            size?: number;
            createdZ: string;
            typename?: string;
          }>;
          emergencyContacts?: Array<{
            __typename?: 'EmergencyContact';
            name: string;
            phone: string;
            relationship?: ContactRelationship;
          }>;
        }>;
      }>;
    };
  };
};

export type CollectionsQueryVariables = Exact<{
  propertyIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type CollectionsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      collections?: {
        __typename?: 'Collections';
        total: number;
        current: number;
        due: number;
        overdue: number;
        late: number;
        delinquent: number;
      };
    };
  };
};

export type GetPropertyFactsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PropertyFactFilter>;
}>;

export type GetPropertyFactsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listPropertyFacts?: {
      __typename?: 'PropertyFactConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'PropertyFact';
        propertyId: string;
        dateKey: string;
        totalUnits?: number;
        leasedUnits?: number;
        commonAreas?: number;
        ownerUnits?: number;
        expiring15Days?: number;
        expiringSoon?: number;
        expiringLater?: number;
        totalCommercialRent?: number;
        commercialLeasesExpired?: number;
        commercialExpiring15Days?: number;
        totalCommercialSqft?: number;
        commercialUnits?: number;
        commercialLeasedUnits?: number;
        commercialExpiringSoon?: number;
        commercialExpiringLater?: number;
        totalResidentialUnits?: number;
        totalResidentialRent?: number;
        totalResidentialSqft?: number;
      }>;
    };
  };
};

export type ActiveRequestsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ActiveRequestsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listActiveRequests?: {
      __typename?: 'RequestConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Request';
        id: string;
        name?: string;
        summary?: string;
        category?: string;
        ownerApprovalId?: string;
        ownerApprovalStatus?: string;
        status?: string;
        delay?: string;
        submittedZ?: string;
        finalizedZ?: string;
        visits?: Array<{
          __typename?: 'Visit';
          id?: string;
          status?: string;
          delay?: string;
          dueZ?: string;
          appointmentZ?: string;
          operatorId?: string;
          operatorName?: string;
          minutes?: number;
          tenantProposedTimes?: Array<string>;
          category?: string;
          instructions?: string;
          materialCosts?: number;
          materialCostsNote?: string;
          materialMarkup?: boolean;
          labourCost?: number;
          completedDelta?: number;
          completedZ?: string;
          journalId?: string;
          notes?: Array<{
            __typename?: 'Note';
            id: string;
            target?: string;
            source?: string;
            createdName?: string;
            createdSub?: string;
            createdZ?: string;
            text?: string;
          }>;
          bill?: {
            __typename?: 'ContractorBill';
            date: string;
            totalAmount: number;
            ref?: string;
            due?: string;
            gstAmount?: number;
            key?: string;
          };
        }>;
        occupant?: { __typename?: 'RequestTenant'; name?: string };
        tenant?: { __typename?: 'RequestTenant'; name?: string };
        unit?: {
          __typename?: 'Unit';
          id: string;
          name: string;
          occupancy?: UnitOccupancy;
          propertyId?: string;
          ownerId?: string;
          property?: {
            __typename?: 'Property';
            id: string;
            name: string;
            address: {
              __typename?: 'Address';
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
          };
        };
        fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      }>;
    };
  };
};

export type OperatorFieldsFragment = {
  __typename?: 'Operator';
  id: string;
  name: string;
  location?: string;
  website?: string;
  email?: string;
  phone?: string;
  enabled?: boolean;
  operatorType?: OperatorType;
  skills?: Array<OperatorSkill>;
  availability?: Array<string>;
  totalAssignedVisits?: number;
  totalCompletedVisits?: number;
  totalCancelledVisits?: number;
  totalStartedVisits?: number;
  timezone?: string;
  hasNonFinalizedVisits?: boolean;
  hasCOI?: boolean;
  expiryCOI?: string;
  businessNumber?: string;
  autopayInstitution?: string;
  autopayTransit?: string;
  autopayAccount?: string;
  autopayStatus?: string;
  autopayStatusUpdatedZ?: string;
  autopayClientId?: string;
  expenseGlId?: string;
  exceptions?: Array<{
    __typename?: 'Exception';
    comment?: string;
    interval: { __typename?: 'Interval'; start: string; end: string };
  }>;
  address?: {
    __typename?: 'Address';
    country?: string;
    suite?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
  };
};

export type OperatorRequestHistoryFieldsFragment = {
  __typename?: 'Request';
  id: string;
  name?: string;
  summary?: string;
  status?: string;
  delay?: string;
  appointmentZ?: string;
  tenant?: { __typename?: 'RequestTenant'; id?: string; name?: string };
  fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
};

export type UpdateOperatorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateOperatorInput;
}>;

export type UpdateOperatorMutation = {
  __typename?: 'Mutation';
  updateOperator?: {
    __typename?: 'Operator';
    id: string;
    name: string;
    email?: string;
    phone?: string;
    location?: string;
    website?: string;
    operatorType?: OperatorType;
    skills?: Array<OperatorSkill>;
    enabled?: boolean;
    availability?: Array<string>;
    timezone?: string;
    hasCOI?: boolean;
    expiryCOI?: string;
    exceptions?: Array<{
      __typename?: 'Exception';
      comment?: string;
      interval: { __typename?: 'Interval'; start: string; end: string };
    }>;
    address?: {
      __typename?: 'Address';
      country?: string;
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  };
};

export type DeleteOperatorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteOperatorMutation = {
  __typename?: 'Mutation';
  deleteOperator?: { __typename?: 'Operator'; id: string };
};

export type UpdateOperatorAutoApMutationVariables = Exact<{
  input: UpdateOperatorAutoApInput;
}>;

export type UpdateOperatorAutoApMutation = {
  __typename?: 'Mutation';
  updateOperatorAutoAP?: {
    __typename?: 'OperatorPayload';
    success?: boolean;
    error?: string;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      location?: string;
      website?: string;
      email?: string;
      phone?: string;
      enabled?: boolean;
      operatorType?: OperatorType;
      skills?: Array<OperatorSkill>;
      availability?: Array<string>;
      totalAssignedVisits?: number;
      totalCompletedVisits?: number;
      totalCancelledVisits?: number;
      totalStartedVisits?: number;
      timezone?: string;
      hasNonFinalizedVisits?: boolean;
      hasCOI?: boolean;
      expiryCOI?: string;
      businessNumber?: string;
      autopayInstitution?: string;
      autopayTransit?: string;
      autopayAccount?: string;
      autopayStatus?: string;
      autopayStatusUpdatedZ?: string;
      autopayClientId?: string;
      expenseGlId?: string;
      exceptions?: Array<{
        __typename?: 'Exception';
        comment?: string;
        interval: { __typename?: 'Interval'; start: string; end: string };
      }>;
      address?: {
        __typename?: 'Address';
        country?: string;
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
  };
};

export type GetOperatorQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOperatorQuery = {
  __typename?: 'Query';
  operator?: {
    __typename?: 'Operator';
    id: string;
    name: string;
    location?: string;
    website?: string;
    email?: string;
    phone?: string;
    enabled?: boolean;
    operatorType?: OperatorType;
    skills?: Array<OperatorSkill>;
    availability?: Array<string>;
    totalAssignedVisits?: number;
    totalCompletedVisits?: number;
    totalCancelledVisits?: number;
    totalStartedVisits?: number;
    timezone?: string;
    hasNonFinalizedVisits?: boolean;
    hasCOI?: boolean;
    expiryCOI?: string;
    businessNumber?: string;
    autopayInstitution?: string;
    autopayTransit?: string;
    autopayAccount?: string;
    autopayStatus?: string;
    autopayStatusUpdatedZ?: string;
    autopayClientId?: string;
    expenseGlId?: string;
    exceptions?: Array<{
      __typename?: 'Exception';
      comment?: string;
      interval: { __typename?: 'Interval'; start: string; end: string };
    }>;
    address?: {
      __typename?: 'Address';
      country?: string;
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  };
};

export type GetOperatorRequestHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOperatorRequestHistoryQuery = {
  __typename?: 'Query';
  operator?: {
    __typename?: 'Operator';
    id: string;
    requests?: Array<{
      __typename?: 'Request';
      id: string;
      name?: string;
      summary?: string;
      status?: string;
      delay?: string;
      appointmentZ?: string;
      tenant?: { __typename?: 'RequestTenant'; id?: string; name?: string };
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
    }>;
  };
};

export type OperatorListFieldsFragment = {
  __typename?: 'Operator';
  id: string;
  name: string;
  location?: string;
  email?: string;
  phone?: string;
  enabled?: boolean;
  operatorType?: OperatorType;
  availability?: Array<string>;
  skills?: Array<OperatorSkill>;
  hasCOI?: boolean;
  expiryCOI?: string;
  autopayClientId?: string;
  autopayStatus?: string;
};

export type AddOperatorMutationVariables = Exact<{
  input: CreateOperatorInput;
}>;

export type AddOperatorMutation = {
  __typename?: 'Mutation';
  createOperator?: {
    __typename?: 'Operator';
    id: string;
    name: string;
    location?: string;
    email?: string;
    phone?: string;
    enabled?: boolean;
    operatorType?: OperatorType;
    availability?: Array<string>;
    skills?: Array<OperatorSkill>;
    hasCOI?: boolean;
    expiryCOI?: string;
    autopayClientId?: string;
    autopayStatus?: string;
  };
};

export type GetOperatorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOperatorsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    operators?: Array<{
      __typename?: 'Operator';
      id: string;
      name: string;
      location?: string;
      email?: string;
      phone?: string;
      enabled?: boolean;
      operatorType?: OperatorType;
      availability?: Array<string>;
      skills?: Array<OperatorSkill>;
      hasCOI?: boolean;
      expiryCOI?: string;
      autopayClientId?: string;
      autopayStatus?: string;
    }>;
  };
};

export type SetOwnerAutopayCollectionsEnabledMutationVariables = Exact<{
  input: SetAutopayCollectionsEnabledInput;
}>;

export type SetOwnerAutopayCollectionsEnabledMutation = {
  __typename?: 'Mutation';
  setOwnerAutopayCollectionsEnabled: {
    __typename?: 'OwnerPayload';
    success?: boolean;
    error?: string;
    owner?: {
      __typename?: 'Owner';
      id: string;
      autopayCollectionsEnabled?: boolean;
      autopayCollectionsEnabledZ?: string;
    };
  };
};

export type GetPropertyForHeaderQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type GetPropertyForHeaderQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    accountId?: string;
    name: string;
    imageKey?: string;
    ownerId?: string;
    enabled?: boolean;
    address: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  };
};

export type GetOwnerProfileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOwnerProfileQuery = {
  __typename?: 'Query';
  owner?: {
    __typename?: 'Owner';
    id: string;
    profile?: {
      __typename?: 'Profile';
      id: string;
      name: string;
      dob?: string;
      phone?: string;
      email: string;
      logins?: Array<{ __typename?: 'ProfileLogin'; id: string; email: string }>;
      assetsShares?: Array<{
        __typename?: 'AssetShare';
        id: string;
        profile?: { __typename?: 'Profile'; id: string; name: string; emails?: Array<string> };
        assets?: Array<{
          __typename?: 'Asset';
          id: string;
          role?: string;
          assetType: string;
          permissions?: Array<string>;
        }>;
      }>;
    };
  };
};

export type OwnerUnitFinancialsPathQueryVariables = Exact<{
  ownerId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type OwnerUnitFinancialsPathQuery = {
  __typename?: 'Query';
  owner?: {
    __typename?: 'Owner';
    id: string;
    listUnits?: {
      __typename?: 'UnitConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Unit';
        id: string;
        name: string;
        ownerId?: string;
        condoFee?: number;
        condoFeeGst?: number;
        condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        building?: { __typename?: 'Building'; id: string; name: string };
        property?: {
          __typename?: 'Property';
          id: string;
          name: string;
          address: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
        };
      }>;
    };
  };
};

export type UpdateOwnerUnitFinancialsMutationVariables = Exact<{
  unitId: Scalars['ID']['input'];
  input: PaymentDetailsInput;
}>;

export type UpdateOwnerUnitFinancialsMutation = {
  __typename?: 'Mutation';
  updateUnit?: {
    __typename?: 'UnitPayload';
    success?: boolean;
    error?: string;
    unit?: {
      __typename?: 'Unit';
      id: string;
      name: string;
      paymentDetails?: {
        __typename?: 'PaymentDetails';
        preferredPaymentMethod?: PaymentMethod;
        padAmount?: number;
        padInstitution?: string;
        padTransit?: string;
        padAccount?: string;
      };
    };
  };
};

export type GetOwnerDocumentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOwnerDocumentsQuery = {
  __typename?: 'Query';
  owner?: {
    __typename?: 'Owner';
    id: string;
    documents?: Array<{
      __typename?: 'Document';
      name?: string;
      key: string;
      createdZ: string;
      size?: number;
    }>;
  };
};

export type RenameOwnerDocumentMutationVariables = Exact<{
  input: RenameOwnerDocumentInput;
}>;

export type RenameOwnerDocumentMutation = {
  __typename?: 'Mutation';
  renameOwnerDocument?: {
    __typename?: 'OwnerPayload';
    success?: boolean;
    error?: string;
    owner?: {
      __typename?: 'Owner';
      id: string;
      documents?: Array<{ __typename?: 'Document'; key: string; name?: string }>;
    };
  };
};

export type OwnerWithResidenciesDetailQueryVariables = Exact<{
  ownerId: Scalars['ID']['input'];
}>;

export type OwnerWithResidenciesDetailQuery = {
  __typename?: 'Query';
  owner?: {
    __typename?: 'Owner';
    id: string;
    name: string;
    dob?: string;
    email?: string;
    phones?: Array<string>;
    enabled?: boolean;
    correspondenceMethod?: CorrespondenceMethod;
    profileId?: string;
    propertyIds?: Array<string>;
    unitIds?: Array<string>;
    gstNumber?: string;
    gstFiling?: FilingFrequency;
    yearEndMonth?: number;
    feeRate?: number;
    feeAmount?: number;
    flatFee?: number;
    nrtFeeAmount?: number;
    nrtFeeRate?: number;
    preferredPaymentMethod?: PaymentMethod;
    padAmount?: number;
    padInstitution?: string;
    padTransit?: string;
    padAccount?: string;
    trackCondoFees?: boolean;
    booksSinceZ?: string;
    autopayInstitution?: string;
    autopayAccount?: string;
    autopayTransit?: string;
    autopayStatus?: string;
    autopayInvitedZ?: string;
    autopayStatusUpdatedZ?: string;
    autopayCollectionsEnabled?: boolean;
    units?: Array<{
      __typename?: 'Unit';
      id: string;
      name: string;
      ownerId?: string;
      occupancy?: UnitOccupancy;
      floorplanId?: string;
      reserveAmount?: number;
      buildingId?: string;
      currentResidencies?: Array<{
        __typename?: 'Residency';
        id: string;
        startZ: string;
        endZ?: string;
        moveOutReason?: MoveOutReason;
        terms?: Array<string>;
        rrule?: string;
      }>;
      property?: {
        __typename?: 'Property';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        autopayCollectionsEnabled?: boolean;
      };
    }>;
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    mailingAddress?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    pets?: Array<{
      __typename?: 'Pet';
      type: string;
      count: number;
      details?: Array<{
        __typename?: 'PetDetails';
        type?: string;
        breed?: string;
        weight?: string;
        name?: string;
        color?: string;
        license?: string;
        isService?: boolean;
      }>;
    }>;
    vehicles?: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      make: string;
      model: string;
      color?: string;
    }>;
    assetFees?: Array<{
      __typename?: 'AssetFee';
      assetId: string;
      feeRate?: number;
      feeAmount?: number;
      flatFee?: number;
      nrtFeeAmount?: number;
      nrtFeeRate?: number;
    }>;
    contacts?: Array<{
      __typename?: 'Contact';
      id: string;
      accountId: string;
      entityId: string;
      type?: ContactType;
      name: string;
      emails?: Array<string>;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    }>;
    properties?: Array<{
      __typename?: 'Property';
      id: string;
      name: string;
      key?: string;
      leasedUnits?: number;
      totalUnits?: number;
      imageKey?: string;
      reserveAmount?: number;
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    }>;
    history?: Array<{
      __typename?: 'OwnerHistoryEntry';
      assetType: AssetType;
      status: string;
      assetId: string;
      createdZ: string;
      leasedUnits?: number;
      commercialLeasedUnits?: number;
    }>;
    emergencyContacts?: Array<{
      __typename?: 'EmergencyContact';
      name: string;
      relationship?: ContactRelationship;
      phone: string;
    }>;
  };
};

export type GetOwnerEnrollmentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  filter?: InputMaybe<AutopayEnrollmentFilterInput>;
}>;

export type GetOwnerEnrollmentsQuery = {
  __typename?: 'Query';
  owner?: {
    __typename?: 'Owner';
    id: string;
    enrollments?: Array<{
      __typename?: 'AutopayEnrollment';
      id: string;
      residencyId?: string;
      unitId: string;
      payeeType: PayeeType;
      status: AutopayEnrollmentStatus;
    }>;
  };
};

export type SearchOwnersByEmailQueryVariables = Exact<{
  filter: OwnerSearchFilter;
}>;

export type SearchOwnersByEmailQuery = {
  __typename?: 'Query';
  searchOwnersByEmail?: Array<{
    __typename?: 'Owner';
    id: string;
    name: string;
    email?: string;
    phones?: Array<string>;
    enabled?: boolean;
  }>;
};

export type GetOwnerQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOwnerQuery = {
  __typename?: 'Query';
  owner?: {
    __typename?: 'Owner';
    id: string;
    name: string;
    dob?: string;
    email?: string;
    phones?: Array<string>;
    enabled?: boolean;
    correspondenceMethod?: CorrespondenceMethod;
    profileId?: string;
    propertyIds?: Array<string>;
    unitIds?: Array<string>;
    gstNumber?: string;
    gstFiling?: FilingFrequency;
    yearEndMonth?: number;
    feeRate?: number;
    feeAmount?: number;
    flatFee?: number;
    nrtFeeAmount?: number;
    nrtFeeRate?: number;
    preferredPaymentMethod?: PaymentMethod;
    padAmount?: number;
    padInstitution?: string;
    padTransit?: string;
    padAccount?: string;
    trackCondoFees?: boolean;
    booksSinceZ?: string;
    autopayInstitution?: string;
    autopayAccount?: string;
    autopayTransit?: string;
    autopayStatus?: string;
    autopayInvitedZ?: string;
    autopayStatusUpdatedZ?: string;
    autopayCollectionsEnabled?: boolean;
    units?: Array<{
      __typename?: 'Unit';
      reserveAmount?: number;
      condoFee?: number;
      condoFeeGst?: number;
      id: string;
      name: string;
      ownerId?: string;
      occupancy?: UnitOccupancy;
      floorplanId?: string;
      buildingId?: string;
      currentResidencies?: Array<{
        __typename?: 'Residency';
        id: string;
        occupancy?: UnitOccupancy;
      }>;
      property?: {
        __typename?: 'Property';
        id: string;
        name: string;
        ownerId?: string;
        enabled?: boolean;
        key?: string;
        imageKey?: string;
        autopayCollectionsEnabled?: boolean;
        address: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
      paymentDetails?: {
        __typename?: 'PaymentDetails';
        preferredPaymentMethod?: PaymentMethod;
        padAmount?: number;
        padInstitution?: string;
        padTransit?: string;
        padAccount?: string;
      };
    }>;
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    mailingAddress?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    pets?: Array<{
      __typename?: 'Pet';
      type: string;
      count: number;
      details?: Array<{
        __typename?: 'PetDetails';
        type?: string;
        breed?: string;
        weight?: string;
        name?: string;
        color?: string;
        license?: string;
        isService?: boolean;
      }>;
    }>;
    vehicles?: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      make: string;
      model: string;
      color?: string;
    }>;
    assetFees?: Array<{
      __typename?: 'AssetFee';
      assetId: string;
      feeRate?: number;
      feeAmount?: number;
      flatFee?: number;
      nrtFeeAmount?: number;
      nrtFeeRate?: number;
    }>;
    contacts?: Array<{
      __typename?: 'Contact';
      id: string;
      accountId: string;
      entityId: string;
      type?: ContactType;
      name: string;
      emails?: Array<string>;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    }>;
    properties?: Array<{
      __typename?: 'Property';
      id: string;
      name: string;
      key?: string;
      leasedUnits?: number;
      totalUnits?: number;
      imageKey?: string;
      reserveAmount?: number;
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    }>;
    history?: Array<{
      __typename?: 'OwnerHistoryEntry';
      assetType: AssetType;
      status: string;
      assetId: string;
      createdZ: string;
      leasedUnits?: number;
      commercialLeasedUnits?: number;
    }>;
    emergencyContacts?: Array<{
      __typename?: 'EmergencyContact';
      name: string;
      relationship?: ContactRelationship;
      phone: string;
    }>;
  };
};

export type AddOwnerMutationVariables = Exact<{
  input: CreateOwnerInput;
}>;

export type AddOwnerMutation = {
  __typename?: 'Mutation';
  createOwner?: {
    __typename?: 'OwnerPayload';
    success?: boolean;
    error?: string;
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      dob?: string;
      email?: string;
      phones?: Array<string>;
      enabled?: boolean;
      correspondenceMethod?: CorrespondenceMethod;
      profileId?: string;
      propertyIds?: Array<string>;
      unitIds?: Array<string>;
      gstNumber?: string;
      gstFiling?: FilingFrequency;
      yearEndMonth?: number;
      feeRate?: number;
      feeAmount?: number;
      flatFee?: number;
      nrtFeeAmount?: number;
      nrtFeeRate?: number;
      preferredPaymentMethod?: PaymentMethod;
      padAmount?: number;
      padInstitution?: string;
      padTransit?: string;
      padAccount?: string;
      trackCondoFees?: boolean;
      booksSinceZ?: string;
      autopayInstitution?: string;
      autopayAccount?: string;
      autopayTransit?: string;
      autopayStatus?: string;
      autopayInvitedZ?: string;
      autopayStatusUpdatedZ?: string;
      autopayCollectionsEnabled?: boolean;
      propertyHeaders?: Array<{ __typename?: 'PropertyHeader'; id: string }>;
      unitHeaders?: Array<{ __typename?: 'UnitHeader'; id: string }>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      mailingAddress?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      pets?: Array<{
        __typename?: 'Pet';
        type: string;
        count: number;
        details?: Array<{
          __typename?: 'PetDetails';
          type?: string;
          breed?: string;
          weight?: string;
          name?: string;
          color?: string;
          license?: string;
          isService?: boolean;
        }>;
      }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
      assetFees?: Array<{
        __typename?: 'AssetFee';
        assetId: string;
        feeRate?: number;
        feeAmount?: number;
        flatFee?: number;
        nrtFeeAmount?: number;
        nrtFeeRate?: number;
      }>;
      contacts?: Array<{
        __typename?: 'Contact';
        id: string;
        accountId: string;
        entityId: string;
        type?: ContactType;
        name: string;
        emails?: Array<string>;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      properties?: Array<{
        __typename?: 'Property';
        id: string;
        name: string;
        key?: string;
        leasedUnits?: number;
        totalUnits?: number;
        imageKey?: string;
        reserveAmount?: number;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      units?: Array<{
        __typename?: 'Unit';
        id: string;
        name: string;
        ownerId?: string;
        occupancy?: UnitOccupancy;
        floorplanId?: string;
        reserveAmount?: number;
        buildingId?: string;
        property?: {
          __typename?: 'Property';
          id: string;
          name: string;
          key?: string;
          imageKey?: string;
          autopayCollectionsEnabled?: boolean;
        };
      }>;
      history?: Array<{
        __typename?: 'OwnerHistoryEntry';
        assetType: AssetType;
        status: string;
        assetId: string;
        createdZ: string;
        leasedUnits?: number;
        commercialLeasedUnits?: number;
      }>;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        relationship?: ContactRelationship;
        phone: string;
      }>;
    };
  };
};

export type UpdateOwnerMutationVariables = Exact<{
  input: UpdateOwnerInput;
}>;

export type UpdateOwnerMutation = {
  __typename?: 'Mutation';
  updateOwner?: {
    __typename?: 'OwnerPayload';
    success?: boolean;
    error?: string;
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      dob?: string;
      email?: string;
      phones?: Array<string>;
      enabled?: boolean;
      correspondenceMethod?: CorrespondenceMethod;
      profileId?: string;
      propertyIds?: Array<string>;
      unitIds?: Array<string>;
      gstNumber?: string;
      gstFiling?: FilingFrequency;
      yearEndMonth?: number;
      feeRate?: number;
      feeAmount?: number;
      flatFee?: number;
      nrtFeeAmount?: number;
      nrtFeeRate?: number;
      preferredPaymentMethod?: PaymentMethod;
      padAmount?: number;
      padInstitution?: string;
      padTransit?: string;
      padAccount?: string;
      trackCondoFees?: boolean;
      booksSinceZ?: string;
      autopayInstitution?: string;
      autopayAccount?: string;
      autopayTransit?: string;
      autopayStatus?: string;
      autopayInvitedZ?: string;
      autopayStatusUpdatedZ?: string;
      autopayCollectionsEnabled?: boolean;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      mailingAddress?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      pets?: Array<{
        __typename?: 'Pet';
        type: string;
        count: number;
        details?: Array<{
          __typename?: 'PetDetails';
          type?: string;
          breed?: string;
          weight?: string;
          name?: string;
          color?: string;
          license?: string;
          isService?: boolean;
        }>;
      }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
      assetFees?: Array<{
        __typename?: 'AssetFee';
        assetId: string;
        feeRate?: number;
        feeAmount?: number;
        flatFee?: number;
        nrtFeeAmount?: number;
        nrtFeeRate?: number;
      }>;
      contacts?: Array<{
        __typename?: 'Contact';
        id: string;
        accountId: string;
        entityId: string;
        type?: ContactType;
        name: string;
        emails?: Array<string>;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      properties?: Array<{
        __typename?: 'Property';
        id: string;
        name: string;
        key?: string;
        leasedUnits?: number;
        totalUnits?: number;
        imageKey?: string;
        reserveAmount?: number;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      units?: Array<{
        __typename?: 'Unit';
        id: string;
        name: string;
        ownerId?: string;
        occupancy?: UnitOccupancy;
        floorplanId?: string;
        reserveAmount?: number;
        buildingId?: string;
        property?: {
          __typename?: 'Property';
          id: string;
          name: string;
          key?: string;
          imageKey?: string;
          autopayCollectionsEnabled?: boolean;
        };
      }>;
      history?: Array<{
        __typename?: 'OwnerHistoryEntry';
        assetType: AssetType;
        status: string;
        assetId: string;
        createdZ: string;
        leasedUnits?: number;
        commercialLeasedUnits?: number;
      }>;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        relationship?: ContactRelationship;
        phone: string;
      }>;
    };
  };
};

export type UpdateOwnerFinancialsMutationVariables = Exact<{
  input: UpdateOwnerFinancialsInput;
}>;

export type UpdateOwnerFinancialsMutation = {
  __typename?: 'Mutation';
  updateOwnerFinancials?: {
    __typename?: 'OwnerPayload';
    success?: boolean;
    error?: string;
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      dob?: string;
      email?: string;
      phones?: Array<string>;
      enabled?: boolean;
      correspondenceMethod?: CorrespondenceMethod;
      profileId?: string;
      propertyIds?: Array<string>;
      unitIds?: Array<string>;
      gstNumber?: string;
      gstFiling?: FilingFrequency;
      yearEndMonth?: number;
      feeRate?: number;
      feeAmount?: number;
      flatFee?: number;
      nrtFeeAmount?: number;
      nrtFeeRate?: number;
      preferredPaymentMethod?: PaymentMethod;
      padAmount?: number;
      padInstitution?: string;
      padTransit?: string;
      padAccount?: string;
      trackCondoFees?: boolean;
      booksSinceZ?: string;
      autopayInstitution?: string;
      autopayAccount?: string;
      autopayTransit?: string;
      autopayStatus?: string;
      autopayInvitedZ?: string;
      autopayStatusUpdatedZ?: string;
      autopayCollectionsEnabled?: boolean;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      mailingAddress?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      pets?: Array<{
        __typename?: 'Pet';
        type: string;
        count: number;
        details?: Array<{
          __typename?: 'PetDetails';
          type?: string;
          breed?: string;
          weight?: string;
          name?: string;
          color?: string;
          license?: string;
          isService?: boolean;
        }>;
      }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
      assetFees?: Array<{
        __typename?: 'AssetFee';
        assetId: string;
        feeRate?: number;
        feeAmount?: number;
        flatFee?: number;
        nrtFeeAmount?: number;
        nrtFeeRate?: number;
      }>;
      contacts?: Array<{
        __typename?: 'Contact';
        id: string;
        accountId: string;
        entityId: string;
        type?: ContactType;
        name: string;
        emails?: Array<string>;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      properties?: Array<{
        __typename?: 'Property';
        id: string;
        name: string;
        key?: string;
        leasedUnits?: number;
        totalUnits?: number;
        imageKey?: string;
        reserveAmount?: number;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      units?: Array<{
        __typename?: 'Unit';
        id: string;
        name: string;
        ownerId?: string;
        occupancy?: UnitOccupancy;
        floorplanId?: string;
        reserveAmount?: number;
        buildingId?: string;
        property?: {
          __typename?: 'Property';
          id: string;
          name: string;
          key?: string;
          imageKey?: string;
          autopayCollectionsEnabled?: boolean;
        };
      }>;
      history?: Array<{
        __typename?: 'OwnerHistoryEntry';
        assetType: AssetType;
        status: string;
        assetId: string;
        createdZ: string;
        leasedUnits?: number;
        commercialLeasedUnits?: number;
      }>;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        relationship?: ContactRelationship;
        phone: string;
      }>;
    };
  };
};

export type UpdateOwnerAutopayMutationVariables = Exact<{
  input: UpdateOwnerAutopayInput;
}>;

export type UpdateOwnerAutopayMutation = {
  __typename?: 'Mutation';
  updateOwnerAutopay?: {
    __typename?: 'OwnerPayload';
    success?: boolean;
    error?: string;
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      dob?: string;
      email?: string;
      phones?: Array<string>;
      enabled?: boolean;
      correspondenceMethod?: CorrespondenceMethod;
      profileId?: string;
      propertyIds?: Array<string>;
      unitIds?: Array<string>;
      gstNumber?: string;
      gstFiling?: FilingFrequency;
      yearEndMonth?: number;
      feeRate?: number;
      feeAmount?: number;
      flatFee?: number;
      nrtFeeAmount?: number;
      nrtFeeRate?: number;
      preferredPaymentMethod?: PaymentMethod;
      padAmount?: number;
      padInstitution?: string;
      padTransit?: string;
      padAccount?: string;
      trackCondoFees?: boolean;
      booksSinceZ?: string;
      autopayInstitution?: string;
      autopayAccount?: string;
      autopayTransit?: string;
      autopayStatus?: string;
      autopayInvitedZ?: string;
      autopayStatusUpdatedZ?: string;
      autopayCollectionsEnabled?: boolean;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      mailingAddress?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      pets?: Array<{
        __typename?: 'Pet';
        type: string;
        count: number;
        details?: Array<{
          __typename?: 'PetDetails';
          type?: string;
          breed?: string;
          weight?: string;
          name?: string;
          color?: string;
          license?: string;
          isService?: boolean;
        }>;
      }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
      assetFees?: Array<{
        __typename?: 'AssetFee';
        assetId: string;
        feeRate?: number;
        feeAmount?: number;
        flatFee?: number;
        nrtFeeAmount?: number;
        nrtFeeRate?: number;
      }>;
      contacts?: Array<{
        __typename?: 'Contact';
        id: string;
        accountId: string;
        entityId: string;
        type?: ContactType;
        name: string;
        emails?: Array<string>;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      properties?: Array<{
        __typename?: 'Property';
        id: string;
        name: string;
        key?: string;
        leasedUnits?: number;
        totalUnits?: number;
        imageKey?: string;
        reserveAmount?: number;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      units?: Array<{
        __typename?: 'Unit';
        id: string;
        name: string;
        ownerId?: string;
        occupancy?: UnitOccupancy;
        floorplanId?: string;
        reserveAmount?: number;
        buildingId?: string;
        property?: {
          __typename?: 'Property';
          id: string;
          name: string;
          key?: string;
          imageKey?: string;
          autopayCollectionsEnabled?: boolean;
        };
      }>;
      history?: Array<{
        __typename?: 'OwnerHistoryEntry';
        assetType: AssetType;
        status: string;
        assetId: string;
        createdZ: string;
        leasedUnits?: number;
        commercialLeasedUnits?: number;
      }>;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        relationship?: ContactRelationship;
        phone: string;
      }>;
    };
  };
};

export type InviteOwnerAutopayMutationVariables = Exact<{
  input: InviteOwnerAutopayInput;
}>;

export type InviteOwnerAutopayMutation = {
  __typename?: 'Mutation';
  inviteOwnerAutopay?: {
    __typename?: 'OwnerPayload';
    success?: boolean;
    error?: string;
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      dob?: string;
      email?: string;
      phones?: Array<string>;
      enabled?: boolean;
      correspondenceMethod?: CorrespondenceMethod;
      profileId?: string;
      propertyIds?: Array<string>;
      unitIds?: Array<string>;
      gstNumber?: string;
      gstFiling?: FilingFrequency;
      yearEndMonth?: number;
      feeRate?: number;
      feeAmount?: number;
      flatFee?: number;
      nrtFeeAmount?: number;
      nrtFeeRate?: number;
      preferredPaymentMethod?: PaymentMethod;
      padAmount?: number;
      padInstitution?: string;
      padTransit?: string;
      padAccount?: string;
      trackCondoFees?: boolean;
      booksSinceZ?: string;
      autopayInstitution?: string;
      autopayAccount?: string;
      autopayTransit?: string;
      autopayStatus?: string;
      autopayInvitedZ?: string;
      autopayStatusUpdatedZ?: string;
      autopayCollectionsEnabled?: boolean;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      mailingAddress?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      pets?: Array<{
        __typename?: 'Pet';
        type: string;
        count: number;
        details?: Array<{
          __typename?: 'PetDetails';
          type?: string;
          breed?: string;
          weight?: string;
          name?: string;
          color?: string;
          license?: string;
          isService?: boolean;
        }>;
      }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
      assetFees?: Array<{
        __typename?: 'AssetFee';
        assetId: string;
        feeRate?: number;
        feeAmount?: number;
        flatFee?: number;
        nrtFeeAmount?: number;
        nrtFeeRate?: number;
      }>;
      contacts?: Array<{
        __typename?: 'Contact';
        id: string;
        accountId: string;
        entityId: string;
        type?: ContactType;
        name: string;
        emails?: Array<string>;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      properties?: Array<{
        __typename?: 'Property';
        id: string;
        name: string;
        key?: string;
        leasedUnits?: number;
        totalUnits?: number;
        imageKey?: string;
        reserveAmount?: number;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      units?: Array<{
        __typename?: 'Unit';
        id: string;
        name: string;
        ownerId?: string;
        occupancy?: UnitOccupancy;
        floorplanId?: string;
        reserveAmount?: number;
        buildingId?: string;
        property?: {
          __typename?: 'Property';
          id: string;
          name: string;
          key?: string;
          imageKey?: string;
          autopayCollectionsEnabled?: boolean;
        };
      }>;
      history?: Array<{
        __typename?: 'OwnerHistoryEntry';
        assetType: AssetType;
        status: string;
        assetId: string;
        createdZ: string;
        leasedUnits?: number;
        commercialLeasedUnits?: number;
      }>;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        relationship?: ContactRelationship;
        phone: string;
      }>;
    };
  };
};

export type DeleteOwnerMutationVariables = Exact<{
  input: DeleteOwnerInput;
}>;

export type DeleteOwnerMutation = {
  __typename?: 'Mutation';
  deleteOwner?: { __typename?: 'OwnerPayload'; success?: boolean; error?: string };
};

export type RemoveOwnerPropertyMutationVariables = Exact<{
  input: RemoveOwnerPropertyInput;
}>;

export type RemoveOwnerPropertyMutation = {
  __typename?: 'Mutation';
  removeOwnerProperty?: {
    __typename?: 'OwnerPayload';
    success?: boolean;
    error?: string;
    owner?: {
      __typename?: 'Owner';
      id: string;
      propertyIds?: Array<string>;
      properties?: Array<{ __typename?: 'Property'; id: string; ownerId?: string }>;
    };
  };
};

export type RemoveOwnerUnitMutationVariables = Exact<{
  input: RemoveOwnerUnitInput;
}>;

export type RemoveOwnerUnitMutation = {
  __typename?: 'Mutation';
  removeOwnerUnit?: {
    __typename?: 'OwnerPayload';
    success?: boolean;
    error?: string;
    owner?: {
      __typename?: 'Owner';
      id: string;
      unitIds?: Array<string>;
      units?: Array<{ __typename?: 'Unit'; id: string; ownerId?: string }>;
    };
  };
};

export type AddOwnerPropertyMutationVariables = Exact<{
  input: AddOwnerPropertyInput;
}>;

export type AddOwnerPropertyMutation = {
  __typename?: 'Mutation';
  addOwnerProperty?: {
    __typename?: 'OwnerPayload';
    success?: boolean;
    error?: string;
    owner?: {
      __typename?: 'Owner';
      id: string;
      propertyIds?: Array<string>;
      properties?: Array<{
        __typename?: 'Property';
        id: string;
        ownerId?: string;
        reserveAmount?: number;
      }>;
    };
  };
};

export type AddOwnerUnitMutationVariables = Exact<{
  input: AddOwnerUnitInput;
}>;

export type AddOwnerUnitMutation = {
  __typename?: 'Mutation';
  addOwnerUnit?: {
    __typename?: 'OwnerPayload';
    success?: boolean;
    error?: string;
    owner?: {
      __typename?: 'Owner';
      id: string;
      unitIds?: Array<string>;
      units?: Array<{ __typename?: 'Unit'; id: string; ownerId?: string; reserveAmount?: number }>;
    };
  };
};

export type UpdateOwnerUnitMutationVariables = Exact<{
  input: UpdateUnitInput;
}>;

export type UpdateOwnerUnitMutation = {
  __typename?: 'Mutation';
  updateUnit?: {
    __typename?: 'UnitPayload';
    unit?: { __typename?: 'Unit'; id: string; ownerId?: string; reserveAmount?: number };
  };
};

export type UpdateOwnerPropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdatePropertyInput;
}>;

export type UpdateOwnerPropertyMutation = {
  __typename?: 'Mutation';
  updateProperty?: {
    __typename?: 'Property';
    id: string;
    ownerId?: string;
    reserveAmount?: number;
  };
};

export type CreateOwnerNoteMutationVariables = Exact<{
  input: CreateOwnerNoteInput;
}>;

export type CreateOwnerNoteMutation = {
  __typename?: 'Mutation';
  createOwnerNote?: {
    __typename?: 'OwnerPayload';
    owner?: {
      __typename?: 'Owner';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type DeleteOwnerNoteMutationVariables = Exact<{
  input: DeleteOwnerNoteInput;
}>;

export type DeleteOwnerNoteMutation = {
  __typename?: 'Mutation';
  deleteOwnerNote?: {
    __typename?: 'OwnerPayload';
    owner?: {
      __typename?: 'Owner';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type OwnerNotesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OwnerNotesQuery = {
  __typename?: 'Query';
  owner?: {
    __typename?: 'Owner';
    id: string;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
  };
};

export type UpdateOwnerManagementFeeMutationVariables = Exact<{
  input: UpdateOwnerManagementFeeInput;
}>;

export type UpdateOwnerManagementFeeMutation = {
  __typename?: 'Mutation';
  updateOwnerManagementFee?: {
    __typename?: 'OwnerPayload';
    success?: boolean;
    error?: string;
    owner?: {
      __typename?: 'Owner';
      id: string;
      assetFees?: Array<{
        __typename?: 'AssetFee';
        assetId: string;
        feeRate?: number;
        feeAmount?: number;
        flatFee?: number;
        nrtFeeAmount?: number;
        nrtFeeRate?: number;
      }>;
    };
  };
};

export type GetOwnersQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OwnersFilter>;
}>;

export type GetOwnersQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listOwners?: {
      __typename?: 'OwnerConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        enabled?: boolean;
        booksSinceZ?: string;
        propertyIds?: Array<string>;
        unitIds?: Array<string>;
        preferredPaymentMethod?: PaymentMethod;
        padAmount?: number;
        profileId?: string;
        autopayClientId?: string;
        autopayStatus?: string;
        address?: {
          __typename?: 'Address';
          city?: string;
          postal?: string;
          province?: string;
          suite?: string;
          street?: string;
        };
        propertyHeaders?: Array<{
          __typename?: 'PropertyHeader';
          id: string;
          name?: string;
          key?: string;
        }>;
        unitHeaders?: Array<{
          __typename?: 'UnitHeader';
          id: string;
          propertyId: string;
          propertyName?: string;
          name?: string;
          occupancy?: UnitOccupancy;
        }>;
        history?: Array<{
          __typename?: 'OwnerHistoryEntry';
          assetType: AssetType;
          assetId: string;
          leasedUnits?: number;
          commercialLeasedUnits?: number;
        }>;
        units?: Array<{
          __typename?: 'Unit';
          id: string;
          paymentDetails?: {
            __typename?: 'PaymentDetails';
            preferredPaymentMethod?: PaymentMethod;
            padAmount?: number;
            padInstitution?: string;
            padTransit?: string;
            padAccount?: string;
          };
        }>;
      }>;
    };
  };
};

export type CreateUnitsMutationVariables = Exact<{
  input: CreateUnitsInput;
}>;

export type CreateUnitsMutation = {
  __typename?: 'Mutation';
  createUnits?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type ContactListOwnerFieldsFragment = {
  __typename?: 'Owner';
  id: string;
  name: string;
  correspondenceMethod?: CorrespondenceMethod;
  email?: string;
  phones?: Array<string>;
  enabled?: boolean;
  address?: {
    __typename?: 'Address';
    suite?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    country?: string;
    lat?: number;
    lng?: number;
  };
  mailingAddress?: {
    __typename?: 'Address';
    suite?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    country?: string;
    lat?: number;
    lng?: number;
  };
  contacts?: Array<{
    __typename?: 'Contact';
    accountId: string;
    entityId: string;
    id: string;
    name: string;
    emails?: Array<string>;
    phones?: Array<string>;
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
  }>;
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    createdName?: string;
    createdSub?: string;
    createdZ?: string;
    text?: string;
  }>;
};

export type ResidentContactListForPropertyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ResidentContactListForPropertyQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    enabled?: boolean;
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      correspondenceMethod?: CorrespondenceMethod;
      email?: string;
      phones?: Array<string>;
      enabled?: boolean;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      mailingAddress?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      contacts?: Array<{
        __typename?: 'Contact';
        accountId: string;
        entityId: string;
        id: string;
        name: string;
        emails?: Array<string>;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
    address: {
      __typename?: 'Address';
      city?: string;
      postal?: string;
      province?: string;
      street?: string;
    };
    buildings?: Array<{
      __typename?: 'Building';
      id: string;
      name: string;
      address?: {
        __typename?: 'Address';
        city?: string;
        postal?: string;
        province?: string;
        street?: string;
      };
    }>;
    units?: Array<{
      __typename?: 'Unit';
      id: string;
      name: string;
      buildingId?: string;
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        correspondenceMethod?: CorrespondenceMethod;
        email?: string;
        phones?: Array<string>;
        enabled?: boolean;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
        mailingAddress?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
        contacts?: Array<{
          __typename?: 'Contact';
          accountId: string;
          entityId: string;
          id: string;
          name: string;
          emails?: Array<string>;
          phones?: Array<string>;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
        }>;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
      };
      currentResidencies?: Array<{
        __typename?: 'Residency';
        id: string;
        residents: Array<{
          __typename?: 'ResidentAssociation';
          id: string;
          leaseHolder?: boolean;
          title?: ResidencyResidentTitle;
          resident:
            | {
                __typename: 'Owner';
                id: string;
                name: string;
                emails?: Array<string>;
                phones?: Array<string>;
                correspondenceMethod?: CorrespondenceMethod;
                email?: string;
                enabled?: boolean;
                address?: {
                  __typename?: 'Address';
                  suite?: string;
                  street?: string;
                  city?: string;
                  province?: string;
                  postal?: string;
                  country?: string;
                  lat?: number;
                  lng?: number;
                };
                mailingAddress?: {
                  __typename?: 'Address';
                  suite?: string;
                  street?: string;
                  city?: string;
                  province?: string;
                  postal?: string;
                  country?: string;
                  lat?: number;
                  lng?: number;
                };
                contacts?: Array<{
                  __typename?: 'Contact';
                  accountId: string;
                  entityId: string;
                  id: string;
                  name: string;
                  emails?: Array<string>;
                  phones?: Array<string>;
                  address?: {
                    __typename?: 'Address';
                    suite?: string;
                    street?: string;
                    city?: string;
                    province?: string;
                    postal?: string;
                    country?: string;
                    lat?: number;
                    lng?: number;
                  };
                }>;
                notes?: Array<{
                  __typename?: 'Note';
                  id: string;
                  createdName?: string;
                  createdSub?: string;
                  createdZ?: string;
                  text?: string;
                }>;
              }
            | {
                __typename: 'Tenant';
                leaseHolder?: boolean;
                correspondenceMethod?: CorrespondenceMethod;
                id: string;
                name: string;
                emails?: Array<string>;
                phones?: Array<string>;
                mailingAddress?: {
                  __typename?: 'Address';
                  suite?: string;
                  street?: string;
                  city?: string;
                  province?: string;
                  postal?: string;
                };
                notes?: Array<{
                  __typename?: 'Note';
                  id: string;
                  createdName?: string;
                  createdSub?: string;
                  createdZ?: string;
                  text?: string;
                }>;
              };
        }>;
      }>;
    }>;
  };
};

export type GetPropertyForFloorplanQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyForFloorplanQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    enabled?: boolean;
    address: {
      __typename?: 'Address';
      city?: string;
      postal?: string;
      province?: string;
      street?: string;
    };
    units?: Array<{
      __typename?: 'Unit';
      id: string;
      name: string;
      occupancy?: UnitOccupancy;
      buildingId?: string;
      floorplanId?: string;
      bedrooms: number;
      bathrooms: number;
      sqft: number;
    }>;
    buildings?: Array<{ __typename?: 'Building'; id: string; name: string; key?: string }>;
  };
};

export type CreatePropertyFloorplanMutationVariables = Exact<{
  input: CreatePropertyFloorplanInput;
}>;

export type CreatePropertyFloorplanMutation = {
  __typename?: 'Mutation';
  createPropertyFloorplan?: {
    __typename?: 'CreateFloorplanPayload';
    success?: boolean;
    floorplan?: { __typename?: 'Floorplan'; id: string; name: string };
    property?: {
      __typename?: 'Property';
      id: string;
      accountId?: string;
      floorplans?: Array<{
        __typename?: 'Floorplan';
        id: string;
        enabled?: boolean;
        default?: boolean;
        name: string;
        description?: string;
        interiorSqFt?: number;
        exteriorSqFt?: number;
        marketRate?: number;
        bedrooms?: number;
        bathrooms?: number;
        areas?: Array<{
          __typename?: 'FloorplanArea';
          id: string;
          category?: string;
          label: string;
          description?: string;
          features?: Array<{
            __typename?: 'FloorplanAreaFeature';
            id: string;
            description?: string;
            label?: string;
            type?: string;
            name?: string;
          }>;
        }>;
      }>;
      units?: Array<{
        __typename?: 'Unit';
        id: string;
        name: string;
        occupancy?: UnitOccupancy;
        buildingId?: string;
        floorplanId?: string;
        bedrooms: number;
        bathrooms: number;
        sqft: number;
      }>;
    };
  };
};

export type CreateBuildingFloorplanMutationVariables = Exact<{
  input: CreateBuildingFloorplanInput;
}>;

export type CreateBuildingFloorplanMutation = {
  __typename?: 'Mutation';
  createBuildingFloorplan?: {
    __typename?: 'CreateFloorplanPayload';
    success?: boolean;
    floorplan?: { __typename?: 'Floorplan'; id: string; name: string };
    property?: {
      __typename?: 'Property';
      id: string;
      accountId?: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        floorplans?: Array<{
          __typename?: 'Floorplan';
          id: string;
          enabled?: boolean;
          default?: boolean;
          name: string;
          description?: string;
          interiorSqFt?: number;
          exteriorSqFt?: number;
          marketRate?: number;
          bedrooms?: number;
          bathrooms?: number;
          areas?: Array<{
            __typename?: 'FloorplanArea';
            id: string;
            category?: string;
            label: string;
            description?: string;
            features?: Array<{
              __typename?: 'FloorplanAreaFeature';
              id: string;
              description?: string;
              label?: string;
              type?: string;
              name?: string;
            }>;
          }>;
        }>;
      }>;
      units?: Array<{
        __typename?: 'Unit';
        id: string;
        name: string;
        occupancy?: UnitOccupancy;
        buildingId?: string;
        floorplanId?: string;
        bedrooms: number;
        bathrooms: number;
        sqft: number;
      }>;
    };
  };
};

export type FloorplanListFieldsFragment = {
  __typename?: 'Floorplan';
  id: string;
  enabled?: boolean;
  default?: boolean;
  name: string;
  description?: string;
  interiorSqFt?: number;
  exteriorSqFt?: number;
  marketRate?: number;
  bedrooms?: number;
  bathrooms?: number;
  areas?: Array<{
    __typename?: 'FloorplanArea';
    id: string;
    category?: string;
    label: string;
    description?: string;
    features?: Array<{
      __typename?: 'FloorplanAreaFeature';
      id: string;
      description?: string;
      label?: string;
      type?: string;
      name?: string;
    }>;
  }>;
};

export type GetDefaultFloorplansQueryVariables = Exact<{ [key: string]: never }>;

export type GetDefaultFloorplansQuery = {
  __typename?: 'Query';
  defaultFloorplanTemplates?: Array<{
    __typename?: 'Floorplan';
    id: string;
    enabled?: boolean;
    default?: boolean;
    name: string;
    description?: string;
    interiorSqFt?: number;
    exteriorSqFt?: number;
    marketRate?: number;
    bedrooms?: number;
    bathrooms?: number;
    areas?: Array<{
      __typename?: 'FloorplanArea';
      id: string;
      category?: string;
      label: string;
      description?: string;
      features?: Array<{
        __typename?: 'FloorplanAreaFeature';
        id: string;
        description?: string;
        label?: string;
        type?: string;
        name?: string;
      }>;
    }>;
  }>;
};

export type ListFloorplansQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListFloorplansQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listFloorplans?: {
      __typename?: 'FloorplanConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Floorplan';
        id: string;
        enabled?: boolean;
        default?: boolean;
        name: string;
        description?: string;
        interiorSqFt?: number;
        exteriorSqFt?: number;
        marketRate?: number;
        bedrooms?: number;
        bathrooms?: number;
        areas?: Array<{
          __typename?: 'FloorplanArea';
          id: string;
          category?: string;
          label: string;
          description?: string;
          features?: Array<{
            __typename?: 'FloorplanAreaFeature';
            id: string;
            description?: string;
            label?: string;
            type?: string;
            name?: string;
          }>;
        }>;
      }>;
    };
  };
};

export type GetFloorplanQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFloorplanQuery = {
  __typename?: 'Query';
  floorplan?: {
    __typename?: 'Floorplan';
    id: string;
    enabled?: boolean;
    default?: boolean;
    name: string;
    description?: string;
    bedrooms?: number;
    bathrooms?: number;
    interiorSqFt?: number;
    exteriorSqFt?: number;
    marketRate?: number;
    totalUnits?: number;
    additionalFeatures?: Array<string>;
    links?: Array<string>;
    areas?: Array<{
      __typename?: 'FloorplanArea';
      id: string;
      category?: string;
      label: string;
      description?: string;
      features?: Array<{
        __typename?: 'FloorplanAreaFeature';
        id: string;
        description?: string;
        label?: string;
        type?: string;
        name?: string;
      }>;
    }>;
    units?: Array<{ __typename?: 'Unit'; id: string; name: string; occupancy?: UnitOccupancy }>;
  };
};

export type GetDefaultFeaturesQueryVariables = Exact<{ [key: string]: never }>;

export type GetDefaultFeaturesQuery = {
  __typename?: 'Query';
  floorplanAreaDefaultFeatures?: Array<{
    __typename?: 'FloorplanAreaFeature';
    id: string;
    name?: string;
    label?: string;
    description?: string;
  }>;
};

export type GetDefaultAdditionalFeaturesQueryVariables = Exact<{ [key: string]: never }>;

export type GetDefaultAdditionalFeaturesQuery = {
  __typename?: 'Query';
  floorplanDefaultAdditionalFeatures?: Array<string>;
};

export type CondoFeeUnitFieldsFragment = {
  __typename?: 'Unit';
  id: string;
  name: string;
  unitFactor?: number;
  condoFee?: number;
  ownerId?: string;
  condoFeeGst?: number;
  condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
  paymentDetails?: {
    __typename?: 'PaymentDetails';
    preferredPaymentMethod?: PaymentMethod;
    padAmount?: number;
    padInstitution?: string;
    padTransit?: string;
    padAccount?: string;
  };
  building?: { __typename?: 'Building'; id: string; name: string; key?: string };
};

export type GetCondoFeeUnitsForPropertyQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UnitFilter>;
}>;

export type GetCondoFeeUnitsForPropertyQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listUnits?: {
      __typename?: 'UnitConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Unit';
        id: string;
        name: string;
        unitFactor?: number;
        condoFee?: number;
        ownerId?: string;
        condoFeeGst?: number;
        condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        building?: { __typename?: 'Building'; id: string; name: string; key?: string };
      }>;
    };
  };
};

export type GetPropertyForCondoFeesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyForCondoFeesQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    ownerId?: string;
    address: { __typename?: 'Address'; province?: string };
  };
};

export type UpdateFloorplanMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: FloorplanInput;
}>;

export type UpdateFloorplanMutation = {
  __typename?: 'Mutation';
  updateFloorplan?: {
    __typename?: 'FloorplanPayload';
    success?: boolean;
    floorplan?: {
      __typename?: 'Floorplan';
      id: string;
      enabled?: boolean;
      default?: boolean;
      name: string;
      description?: string;
      bedrooms?: number;
      bathrooms?: number;
      interiorSqFt?: number;
      exteriorSqFt?: number;
      marketRate?: number;
      totalUnits?: number;
      additionalFeatures?: Array<string>;
      links?: Array<string>;
      areas?: Array<{
        __typename?: 'FloorplanArea';
        id: string;
        category?: string;
        label: string;
        description?: string;
        features?: Array<{
          __typename?: 'FloorplanAreaFeature';
          id: string;
          description?: string;
          label?: string;
          type?: string;
          name?: string;
        }>;
      }>;
      units?: Array<{ __typename?: 'Unit'; id: string; name: string; occupancy?: UnitOccupancy }>;
    };
  };
};

export type UnitEffectsForPropertyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UnitEffectsForPropertyQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    units?: Array<{
      __typename?: 'Unit';
      id: string;
      name: string;
      occupancy?: UnitOccupancy;
      property?: { __typename?: 'Property'; id: string; timezone: string };
      currentResidencies?: Array<{
        __typename?: 'Residency';
        id: string;
        startZ: string;
        endZ?: string;
        effects?: Array<
          | {
              __typename?: 'IncentiveEffect';
              id: string;
              feeId: string;
              effect: number;
              startZ: string;
            }
          | {
              __typename?: 'ResidencyEffect';
              id: string;
              feeId: string;
              effect: number;
              startZ: string;
            }
        >;
      }>;
    }>;
  };
};

export type AddBuildingMutationVariables = Exact<{
  input: CreateBuildingInput;
}>;

export type AddBuildingMutation = {
  __typename?: 'Mutation';
  createBuilding?: {
    __typename?: 'CreateBuildingPayload';
    success?: boolean;
    error?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        timezone: string;
        phone?: string;
        ownerId?: string;
        types?: Array<PropertyType>;
        legalDescription?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          lat?: number;
          lng?: number;
        };
      }>;
    };
    building?: {
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    };
    unitNames?: {
      __typename?: 'UnitNames';
      namesFound?: number;
      totalUnits?: number;
      complete?: boolean;
      names?: Array<string>;
    };
  };
};

export type UpdateBuildingMutationVariables = Exact<{
  input: UpdateBuildingInput;
}>;

export type UpdateBuildingMutation = {
  __typename?: 'Mutation';
  updateBuilding?: {
    __typename?: 'UpdateBuildingPayload';
    success?: boolean;
    error?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        timezone: string;
        phone?: string;
        ownerId?: string;
        types?: Array<PropertyType>;
        legalDescription?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          lat?: number;
          lng?: number;
        };
      }>;
    };
  };
};

export type CreateBuildingNoteMutationVariables = Exact<{
  input: CreateBuildingNoteInput;
}>;

export type CreateBuildingNoteMutation = {
  __typename?: 'Mutation';
  createBuildingNote?: {
    __typename?: 'UpdateBuildingPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        name: string;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
      }>;
    };
  };
};

export type DeleteBuildingNoteMutationVariables = Exact<{
  input: DeleteBuildingNoteInput;
}>;

export type DeleteBuildingNoteMutation = {
  __typename?: 'Mutation';
  deleteBuildingNote?: {
    __typename?: 'UpdateBuildingPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        name: string;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
      }>;
    };
  };
};

export type BuildingNotesQueryVariables = Exact<{
  input: BuildingFilter;
}>;

export type BuildingNotesQuery = {
  __typename?: 'Query';
  building?: {
    __typename?: 'Building';
    id: string;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
  };
};

export type GetBuildingSpecsQueryVariables = Exact<{
  input: BuildingFilter;
}>;

export type GetBuildingSpecsQuery = {
  __typename?: 'Query';
  building?: {
    __typename?: 'Building';
    id: string;
    specs?: Array<{ __typename?: 'PropertySpecs'; key: PropertySpecTypes; value: string }>;
  };
};

export type UpdateBuildingSpecsMutationVariables = Exact<{
  input: UpdateBuildingSpecsInput;
}>;

export type UpdateBuildingSpecsMutation = {
  __typename?: 'Mutation';
  updateBuildingSpecs: {
    __typename?: 'UpdateBuildingPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        name: string;
        specs?: Array<{ __typename?: 'PropertySpecs'; key: PropertySpecTypes; value: string }>;
      }>;
    };
  };
};

export type BuildingDocumentsQueryVariables = Exact<{
  input: BuildingFilter;
}>;

export type BuildingDocumentsQuery = {
  __typename?: 'Query';
  building?: {
    __typename?: 'Building';
    id: string;
    documents?: Array<{
      __typename?: 'Document';
      key: string;
      name?: string;
      size?: number;
      createdZ: string;
      typename?: string;
    }>;
  };
};

export type RenameBuildingDocumentMutationVariables = Exact<{
  input: RenameBuildingDocumentInput;
}>;

export type RenameBuildingDocumentMutation = {
  __typename?: 'Mutation';
  renameBuildingDocument: {
    __typename?: 'UpdateBuildingPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        documents?: Array<{
          __typename?: 'Document';
          key: string;
          name?: string;
          size?: number;
          createdZ: string;
          typename?: string;
        }>;
      }>;
    };
  };
};

export type DeleteBuildingMutationVariables = Exact<{
  input: DeleteBuildingInput;
}>;

export type DeleteBuildingMutation = {
  __typename?: 'Mutation';
  deleteBuilding?: {
    __typename?: 'DeleteBuildingPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        timezone: string;
        phone?: string;
        ownerId?: string;
        types?: Array<PropertyType>;
        legalDescription?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          lat?: number;
          lng?: number;
        };
      }>;
    };
  };
};

export type GetBuildingAmenitiesQueryVariables = Exact<{
  input: BuildingFilter;
}>;

export type GetBuildingAmenitiesQuery = {
  __typename?: 'Query';
  building?: {
    __typename?: 'Building';
    id: string;
    amenitiesSetting?: {
      __typename?: 'AmenitiesSetting';
      amenities?: Array<{ __typename?: 'Amenity'; id?: string; name?: string; category?: string }>;
    };
  };
};

export type UpdateBuildingAmenitiesMutationVariables = Exact<{
  input: UpdateBuildingAmenitiesInput;
}>;

export type UpdateBuildingAmenitiesMutation = {
  __typename?: 'Mutation';
  updateBuildingAmenities: {
    __typename?: 'UpdateBuildingPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        name: string;
        amenitiesSetting?: {
          __typename?: 'AmenitiesSetting';
          amenities?: Array<{
            __typename?: 'Amenity';
            id?: string;
            name?: string;
            category?: string;
          }>;
        };
      }>;
    };
  };
};

export type GetBuildingFeaturesQueryVariables = Exact<{
  input: BuildingFilter;
}>;

export type GetBuildingFeaturesQuery = {
  __typename?: 'Query';
  building?: {
    __typename?: 'Building';
    id: string;
    features?: Array<{ __typename?: 'Feature'; id?: string; name?: string }>;
  };
};

export type UpdateBuildingFeaturesMutationVariables = Exact<{
  input: UpdateBuildingFeaturesInput;
}>;

export type UpdateBuildingFeaturesMutation = {
  __typename?: 'Mutation';
  updateBuildingFeatures: {
    __typename?: 'UpdateBuildingPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        name: string;
        features?: Array<{ __typename?: 'Feature'; id?: string; name?: string }>;
      }>;
    };
  };
};

export type AddBuildingInsuranceMutationVariables = Exact<{
  input: BuildingInsuranceInput;
}>;

export type AddBuildingInsuranceMutation = {
  __typename?: 'Mutation';
  addBuildingInsurance: {
    __typename?: 'InsurancePolicyPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        insurancePolicy?: {
          __typename?: 'InsurancePolicy';
          id: string;
          policyNumber: string;
          policyHolder: string;
          effective: string;
          expiry: string;
          supplierId?: string;
          coverage?: number;
          premium?: number;
          supplier?: {
            __typename?: 'Supplier';
            id: string;
            accountId: string;
            name: string;
            contactName?: string;
            type?: string;
            phone?: string;
            fax?: string;
            email?: string;
            enabled: boolean;
            location?: string;
            deletedZ?: string;
            verificationRequestedZ?: string;
            verifiedZ?: string;
            website?: string;
            coi?: boolean;
            coiExpiry?: string;
            billingEmail?: string;
            businessNumber?: string;
            autopayInstitution?: string;
            autopayTransit?: string;
            autopayAccount?: string;
            autopayStatus?: string;
            autopayStatusUpdatedZ?: string;
            autopayClientId?: string;
            expenseGlId?: string;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            supplierAccounts?: Array<{
              __typename?: 'SupplierAccount';
              id: string;
              accountNumber: string;
            }>;
          };
          claims?: Array<{
            __typename?: 'Claim';
            id: string;
            date: string;
            amount: number;
            status?: ClaimStatus;
            type?: ClaimType;
            description?: string;
          }>;
        };
      }>;
    };
  };
};

export type UpdateBuildingInsuranceMutationVariables = Exact<{
  input: BuildingInsuranceInput;
}>;

export type UpdateBuildingInsuranceMutation = {
  __typename?: 'Mutation';
  updateBuildingInsurance: {
    __typename?: 'InsurancePolicyPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        insurancePolicy?: {
          __typename?: 'InsurancePolicy';
          id: string;
          policyNumber: string;
          policyHolder: string;
          effective: string;
          expiry: string;
          supplierId?: string;
          coverage?: number;
          premium?: number;
          supplier?: {
            __typename?: 'Supplier';
            id: string;
            accountId: string;
            name: string;
            contactName?: string;
            type?: string;
            phone?: string;
            fax?: string;
            email?: string;
            enabled: boolean;
            location?: string;
            deletedZ?: string;
            verificationRequestedZ?: string;
            verifiedZ?: string;
            website?: string;
            coi?: boolean;
            coiExpiry?: string;
            billingEmail?: string;
            businessNumber?: string;
            autopayInstitution?: string;
            autopayTransit?: string;
            autopayAccount?: string;
            autopayStatus?: string;
            autopayStatusUpdatedZ?: string;
            autopayClientId?: string;
            expenseGlId?: string;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            supplierAccounts?: Array<{
              __typename?: 'SupplierAccount';
              id: string;
              accountNumber: string;
            }>;
          };
          claims?: Array<{
            __typename?: 'Claim';
            id: string;
            date: string;
            amount: number;
            status?: ClaimStatus;
            type?: ClaimType;
            description?: string;
          }>;
        };
      }>;
    };
  };
};

export type AddBuildingInsuranceHistoryMutationVariables = Exact<{
  input: CreateBuildingInsuranceHistoryInput;
}>;

export type AddBuildingInsuranceHistoryMutation = {
  __typename?: 'Mutation';
  addBuildingInsuranceHistory?: {
    __typename?: 'InsurancePolicyPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        insurancePolicyHistory?: Array<{
          __typename?: 'InsurancePolicy';
          id: string;
          policyNumber: string;
          policyHolder: string;
          effective: string;
          expiry: string;
          supplierId?: string;
          coverage?: number;
          premium?: number;
          supplier?: {
            __typename?: 'Supplier';
            id: string;
            accountId: string;
            name: string;
            contactName?: string;
            type?: string;
            phone?: string;
            fax?: string;
            email?: string;
            enabled: boolean;
            location?: string;
            deletedZ?: string;
            verificationRequestedZ?: string;
            verifiedZ?: string;
            website?: string;
            coi?: boolean;
            coiExpiry?: string;
            billingEmail?: string;
            businessNumber?: string;
            autopayInstitution?: string;
            autopayTransit?: string;
            autopayAccount?: string;
            autopayStatus?: string;
            autopayStatusUpdatedZ?: string;
            autopayClientId?: string;
            expenseGlId?: string;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            supplierAccounts?: Array<{
              __typename?: 'SupplierAccount';
              id: string;
              accountNumber: string;
            }>;
          };
          claims?: Array<{
            __typename?: 'Claim';
            id: string;
            date: string;
            amount: number;
            status?: ClaimStatus;
            type?: ClaimType;
            description?: string;
          }>;
        }>;
      }>;
    };
  };
};

export type UpdateBuildingInsuranceHistoryMutationVariables = Exact<{
  input: UpdateBuildingInsuranceHistoryInput;
}>;

export type UpdateBuildingInsuranceHistoryMutation = {
  __typename?: 'Mutation';
  updateBuildingInsuranceHistory?: {
    __typename?: 'InsurancePolicyPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        insurancePolicyHistory?: Array<{
          __typename?: 'InsurancePolicy';
          id: string;
          policyNumber: string;
          policyHolder: string;
          effective: string;
          expiry: string;
          supplierId?: string;
          coverage?: number;
          premium?: number;
          supplier?: {
            __typename?: 'Supplier';
            id: string;
            accountId: string;
            name: string;
            contactName?: string;
            type?: string;
            phone?: string;
            fax?: string;
            email?: string;
            enabled: boolean;
            location?: string;
            deletedZ?: string;
            verificationRequestedZ?: string;
            verifiedZ?: string;
            website?: string;
            coi?: boolean;
            coiExpiry?: string;
            billingEmail?: string;
            businessNumber?: string;
            autopayInstitution?: string;
            autopayTransit?: string;
            autopayAccount?: string;
            autopayStatus?: string;
            autopayStatusUpdatedZ?: string;
            autopayClientId?: string;
            expenseGlId?: string;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            supplierAccounts?: Array<{
              __typename?: 'SupplierAccount';
              id: string;
              accountNumber: string;
            }>;
          };
          claims?: Array<{
            __typename?: 'Claim';
            id: string;
            date: string;
            amount: number;
            status?: ClaimStatus;
            type?: ClaimType;
            description?: string;
          }>;
        }>;
      }>;
    };
  };
};

export type DeleteBuildingInsuranceHistoryMutationVariables = Exact<{
  input: DeleteBuildingInsuranceHistoryInput;
}>;

export type DeleteBuildingInsuranceHistoryMutation = {
  __typename?: 'Mutation';
  deleteBuildingInsuranceHistory?: {
    __typename?: 'InsurancePolicyPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        insurancePolicyHistory?: Array<{
          __typename?: 'InsurancePolicy';
          id: string;
          policyNumber: string;
          policyHolder: string;
          effective: string;
          expiry: string;
          supplierId?: string;
          coverage?: number;
          premium?: number;
          supplier?: {
            __typename?: 'Supplier';
            id: string;
            accountId: string;
            name: string;
            contactName?: string;
            type?: string;
            phone?: string;
            fax?: string;
            email?: string;
            enabled: boolean;
            location?: string;
            deletedZ?: string;
            verificationRequestedZ?: string;
            verifiedZ?: string;
            website?: string;
            coi?: boolean;
            coiExpiry?: string;
            billingEmail?: string;
            businessNumber?: string;
            autopayInstitution?: string;
            autopayTransit?: string;
            autopayAccount?: string;
            autopayStatus?: string;
            autopayStatusUpdatedZ?: string;
            autopayClientId?: string;
            expenseGlId?: string;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            supplierAccounts?: Array<{
              __typename?: 'SupplierAccount';
              id: string;
              accountNumber: string;
            }>;
          };
          claims?: Array<{
            __typename?: 'Claim';
            id: string;
            date: string;
            amount: number;
            status?: ClaimStatus;
            type?: ClaimType;
            description?: string;
          }>;
        }>;
      }>;
    };
  };
};

export type AddBuildingInsuranceClaimMutationVariables = Exact<{
  input: CreateBuildingInsuranceClaimInput;
}>;

export type AddBuildingInsuranceClaimMutation = {
  __typename?: 'Mutation';
  addBuildingInsuranceClaim?: {
    __typename?: 'ClaimPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        insurancePolicy?: {
          __typename?: 'InsurancePolicy';
          id: string;
          policyNumber: string;
          policyHolder: string;
          effective: string;
          expiry: string;
          supplierId?: string;
          coverage?: number;
          premium?: number;
          supplier?: {
            __typename?: 'Supplier';
            id: string;
            accountId: string;
            name: string;
            contactName?: string;
            type?: string;
            phone?: string;
            fax?: string;
            email?: string;
            enabled: boolean;
            location?: string;
            deletedZ?: string;
            verificationRequestedZ?: string;
            verifiedZ?: string;
            website?: string;
            coi?: boolean;
            coiExpiry?: string;
            billingEmail?: string;
            businessNumber?: string;
            autopayInstitution?: string;
            autopayTransit?: string;
            autopayAccount?: string;
            autopayStatus?: string;
            autopayStatusUpdatedZ?: string;
            autopayClientId?: string;
            expenseGlId?: string;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            supplierAccounts?: Array<{
              __typename?: 'SupplierAccount';
              id: string;
              accountNumber: string;
            }>;
          };
          claims?: Array<{
            __typename?: 'Claim';
            id: string;
            date: string;
            amount: number;
            status?: ClaimStatus;
            type?: ClaimType;
            description?: string;
          }>;
        };
      }>;
    };
  };
};

export type UpdateBuildingInsuranceClaimMutationVariables = Exact<{
  input: UpdateBuildingInsuranceClaimInput;
}>;

export type UpdateBuildingInsuranceClaimMutation = {
  __typename?: 'Mutation';
  updateBuildingInsuranceClaim?: {
    __typename?: 'ClaimPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        insurancePolicy?: {
          __typename?: 'InsurancePolicy';
          id: string;
          policyNumber: string;
          policyHolder: string;
          effective: string;
          expiry: string;
          supplierId?: string;
          coverage?: number;
          premium?: number;
          supplier?: {
            __typename?: 'Supplier';
            id: string;
            accountId: string;
            name: string;
            contactName?: string;
            type?: string;
            phone?: string;
            fax?: string;
            email?: string;
            enabled: boolean;
            location?: string;
            deletedZ?: string;
            verificationRequestedZ?: string;
            verifiedZ?: string;
            website?: string;
            coi?: boolean;
            coiExpiry?: string;
            billingEmail?: string;
            businessNumber?: string;
            autopayInstitution?: string;
            autopayTransit?: string;
            autopayAccount?: string;
            autopayStatus?: string;
            autopayStatusUpdatedZ?: string;
            autopayClientId?: string;
            expenseGlId?: string;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            supplierAccounts?: Array<{
              __typename?: 'SupplierAccount';
              id: string;
              accountNumber: string;
            }>;
          };
          claims?: Array<{
            __typename?: 'Claim';
            id: string;
            date: string;
            amount: number;
            status?: ClaimStatus;
            type?: ClaimType;
            description?: string;
          }>;
        };
      }>;
    };
  };
};

export type DeleteBuildingInsuranceClaimMutationVariables = Exact<{
  input: DeleteBuildingInsuranceClaimInput;
}>;

export type DeleteBuildingInsuranceClaimMutation = {
  __typename?: 'Mutation';
  deleteBuildingInsuranceClaim?: {
    __typename?: 'DeleteClaimPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        insurancePolicy?: {
          __typename?: 'InsurancePolicy';
          id: string;
          policyNumber: string;
          policyHolder: string;
          effective: string;
          expiry: string;
          supplierId?: string;
          coverage?: number;
          premium?: number;
          supplier?: {
            __typename?: 'Supplier';
            id: string;
            accountId: string;
            name: string;
            contactName?: string;
            type?: string;
            phone?: string;
            fax?: string;
            email?: string;
            enabled: boolean;
            location?: string;
            deletedZ?: string;
            verificationRequestedZ?: string;
            verifiedZ?: string;
            website?: string;
            coi?: boolean;
            coiExpiry?: string;
            billingEmail?: string;
            businessNumber?: string;
            autopayInstitution?: string;
            autopayTransit?: string;
            autopayAccount?: string;
            autopayStatus?: string;
            autopayStatusUpdatedZ?: string;
            autopayClientId?: string;
            expenseGlId?: string;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            supplierAccounts?: Array<{
              __typename?: 'SupplierAccount';
              id: string;
              accountNumber: string;
            }>;
          };
          claims?: Array<{
            __typename?: 'Claim';
            id: string;
            date: string;
            amount: number;
            status?: ClaimStatus;
            type?: ClaimType;
            description?: string;
          }>;
        };
      }>;
    };
  };
};

export type AddBuildingInsuranceHistoryClaimMutationVariables = Exact<{
  input: CreateBuildingInsuranceClaimInput;
}>;

export type AddBuildingInsuranceHistoryClaimMutation = {
  __typename?: 'Mutation';
  addBuildingInsuranceHistoryClaim?: {
    __typename?: 'ClaimPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        insurancePolicyHistory?: Array<{
          __typename?: 'InsurancePolicy';
          id: string;
          policyNumber: string;
          policyHolder: string;
          effective: string;
          expiry: string;
          supplierId?: string;
          coverage?: number;
          premium?: number;
          supplier?: {
            __typename?: 'Supplier';
            id: string;
            accountId: string;
            name: string;
            contactName?: string;
            type?: string;
            phone?: string;
            fax?: string;
            email?: string;
            enabled: boolean;
            location?: string;
            deletedZ?: string;
            verificationRequestedZ?: string;
            verifiedZ?: string;
            website?: string;
            coi?: boolean;
            coiExpiry?: string;
            billingEmail?: string;
            businessNumber?: string;
            autopayInstitution?: string;
            autopayTransit?: string;
            autopayAccount?: string;
            autopayStatus?: string;
            autopayStatusUpdatedZ?: string;
            autopayClientId?: string;
            expenseGlId?: string;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            supplierAccounts?: Array<{
              __typename?: 'SupplierAccount';
              id: string;
              accountNumber: string;
            }>;
          };
          claims?: Array<{
            __typename?: 'Claim';
            id: string;
            date: string;
            amount: number;
            status?: ClaimStatus;
            type?: ClaimType;
            description?: string;
          }>;
        }>;
      }>;
    };
  };
};

export type UpdateBuildingInsuranceHistoryClaimMutationVariables = Exact<{
  input: UpdateBuildingInsuranceClaimInput;
}>;

export type UpdateBuildingInsuranceHistoryClaimMutation = {
  __typename?: 'Mutation';
  updateBuildingInsuranceHistoryClaim?: {
    __typename?: 'ClaimPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        insurancePolicyHistory?: Array<{
          __typename?: 'InsurancePolicy';
          id: string;
          policyNumber: string;
          policyHolder: string;
          effective: string;
          expiry: string;
          supplierId?: string;
          coverage?: number;
          premium?: number;
          supplier?: {
            __typename?: 'Supplier';
            id: string;
            accountId: string;
            name: string;
            contactName?: string;
            type?: string;
            phone?: string;
            fax?: string;
            email?: string;
            enabled: boolean;
            location?: string;
            deletedZ?: string;
            verificationRequestedZ?: string;
            verifiedZ?: string;
            website?: string;
            coi?: boolean;
            coiExpiry?: string;
            billingEmail?: string;
            businessNumber?: string;
            autopayInstitution?: string;
            autopayTransit?: string;
            autopayAccount?: string;
            autopayStatus?: string;
            autopayStatusUpdatedZ?: string;
            autopayClientId?: string;
            expenseGlId?: string;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            supplierAccounts?: Array<{
              __typename?: 'SupplierAccount';
              id: string;
              accountNumber: string;
            }>;
          };
          claims?: Array<{
            __typename?: 'Claim';
            id: string;
            date: string;
            amount: number;
            status?: ClaimStatus;
            type?: ClaimType;
            description?: string;
          }>;
        }>;
      }>;
    };
  };
};

export type DeleteBuildingInsuranceHistoryClaimMutationVariables = Exact<{
  input: DeleteBuildingInsuranceClaimInput;
}>;

export type DeleteBuildingInsuranceHistoryClaimMutation = {
  __typename?: 'Mutation';
  deleteBuildingInsuranceHistoryClaim?: {
    __typename?: 'DeleteClaimPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        insurancePolicyHistory?: Array<{
          __typename?: 'InsurancePolicy';
          id: string;
          policyNumber: string;
          policyHolder: string;
          effective: string;
          expiry: string;
          supplierId?: string;
          coverage?: number;
          premium?: number;
          supplier?: {
            __typename?: 'Supplier';
            id: string;
            accountId: string;
            name: string;
            contactName?: string;
            type?: string;
            phone?: string;
            fax?: string;
            email?: string;
            enabled: boolean;
            location?: string;
            deletedZ?: string;
            verificationRequestedZ?: string;
            verifiedZ?: string;
            website?: string;
            coi?: boolean;
            coiExpiry?: string;
            billingEmail?: string;
            businessNumber?: string;
            autopayInstitution?: string;
            autopayTransit?: string;
            autopayAccount?: string;
            autopayStatus?: string;
            autopayStatusUpdatedZ?: string;
            autopayClientId?: string;
            expenseGlId?: string;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            supplierAccounts?: Array<{
              __typename?: 'SupplierAccount';
              id: string;
              accountNumber: string;
            }>;
          };
          claims?: Array<{
            __typename?: 'Claim';
            id: string;
            date: string;
            amount: number;
            status?: ClaimStatus;
            type?: ClaimType;
            description?: string;
          }>;
        }>;
      }>;
    };
  };
};

export type GetBuildingInsuranceQueryVariables = Exact<{
  input: BuildingFilter;
}>;

export type GetBuildingInsuranceQuery = {
  __typename?: 'Query';
  building?: {
    __typename?: 'Building';
    id: string;
    insurancePolicy?: {
      __typename?: 'InsurancePolicy';
      id: string;
      policyNumber: string;
      policyHolder: string;
      effective: string;
      expiry: string;
      supplierId?: string;
      coverage?: number;
      premium?: number;
      supplier?: {
        __typename?: 'Supplier';
        id: string;
        accountId: string;
        name: string;
        contactName?: string;
        type?: string;
        phone?: string;
        fax?: string;
        email?: string;
        enabled: boolean;
        location?: string;
        deletedZ?: string;
        verificationRequestedZ?: string;
        verifiedZ?: string;
        website?: string;
        coi?: boolean;
        coiExpiry?: string;
        billingEmail?: string;
        businessNumber?: string;
        autopayInstitution?: string;
        autopayTransit?: string;
        autopayAccount?: string;
        autopayStatus?: string;
        autopayStatusUpdatedZ?: string;
        autopayClientId?: string;
        expenseGlId?: string;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
        fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        supplierAccounts?: Array<{
          __typename?: 'SupplierAccount';
          id: string;
          accountNumber: string;
        }>;
      };
      claims?: Array<{
        __typename?: 'Claim';
        id: string;
        date: string;
        amount: number;
        status?: ClaimStatus;
        type?: ClaimType;
        description?: string;
      }>;
    };
  };
};

export type GetBuildingInsuranceHistoryQueryVariables = Exact<{
  input: BuildingFilter;
}>;

export type GetBuildingInsuranceHistoryQuery = {
  __typename?: 'Query';
  building?: {
    __typename?: 'Building';
    id: string;
    insurancePolicyHistory?: Array<{
      __typename?: 'InsurancePolicy';
      id: string;
      policyNumber: string;
      policyHolder: string;
      effective: string;
      expiry: string;
      supplierId?: string;
      coverage?: number;
      premium?: number;
      supplier?: {
        __typename?: 'Supplier';
        id: string;
        accountId: string;
        name: string;
        contactName?: string;
        type?: string;
        phone?: string;
        fax?: string;
        email?: string;
        enabled: boolean;
        location?: string;
        deletedZ?: string;
        verificationRequestedZ?: string;
        verifiedZ?: string;
        website?: string;
        coi?: boolean;
        coiExpiry?: string;
        billingEmail?: string;
        businessNumber?: string;
        autopayInstitution?: string;
        autopayTransit?: string;
        autopayAccount?: string;
        autopayStatus?: string;
        autopayStatusUpdatedZ?: string;
        autopayClientId?: string;
        expenseGlId?: string;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
        fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        supplierAccounts?: Array<{
          __typename?: 'SupplierAccount';
          id: string;
          accountNumber: string;
        }>;
      };
      claims?: Array<{
        __typename?: 'Claim';
        id: string;
        date: string;
        amount: number;
        status?: ClaimStatus;
        type?: ClaimType;
        description?: string;
      }>;
    }>;
  };
};

export type GetPropertyTimezoneQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyTimezoneQuery = {
  __typename?: 'Query';
  property?: { __typename?: 'Property'; id: string; timezone: string };
};

export type UnitCountsQueryVariables = Exact<{ [key: string]: never }>;

export type UnitCountsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    stats?: { __typename?: 'AccountStats'; unit?: number };
  };
};

export type UpdateResidencyEffectsMutationVariables = Exact<{
  residencyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  input: UpdateResidencyEffectsInput;
}>;

export type UpdateResidencyEffectsMutation = {
  __typename?: 'Mutation';
  updateResidencyEffects: {
    __typename?: 'ResidencyPayload';
    success?: boolean;
    error?: string;
    residency?: {
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          category: RefundableLedgerCategory;
          amount: number;
          date: string;
        }>;
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    };
  };
};

export type UpdateResidencyRateSchedulePathQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
}>;

export type UpdateResidencyRateSchedulePathQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    startZ: string;
    propertyId: string;
    unitId: string;
    effects?: Array<
      | {
          __typename: 'IncentiveEffect';
          description?: string;
          incentiveId: string;
          id: string;
          feeId: string;
          startZ: string;
          effect: number;
        }
      | { __typename: 'ResidencyEffect'; id: string; feeId: string; startZ: string; effect: number }
    >;
  };
};

export type AddPropertyInsuranceMutationVariables = Exact<{
  input: PropertyInsuranceInput;
}>;

export type AddPropertyInsuranceMutation = {
  __typename?: 'Mutation';
  addPropertyInsurance: {
    __typename?: 'InsurancePolicyPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      insurancePolicy?: {
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      };
    };
  };
};

export type UpdatePropertyInsuranceMutationVariables = Exact<{
  input: PropertyInsuranceInput;
}>;

export type UpdatePropertyInsuranceMutation = {
  __typename?: 'Mutation';
  updatePropertyInsurance: {
    __typename?: 'InsurancePolicyPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      insurancePolicy?: {
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      };
    };
  };
};

export type AddPropertyInsuranceHistoryMutationVariables = Exact<{
  input: CreatePropertyInsuranceHistoryInput;
}>;

export type AddPropertyInsuranceHistoryMutation = {
  __typename?: 'Mutation';
  addPropertyInsuranceHistory?: {
    __typename?: 'InsurancePolicyPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      insurancePolicyHistory?: Array<{
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      }>;
    };
  };
};

export type UpdatePropertyInsuranceHistoryMutationVariables = Exact<{
  input: UpdatePropertyInsuranceHistoryInput;
}>;

export type UpdatePropertyInsuranceHistoryMutation = {
  __typename?: 'Mutation';
  updatePropertyInsuranceHistory?: {
    __typename?: 'InsurancePolicyPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      insurancePolicyHistory?: Array<{
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      }>;
    };
  };
};

export type DeletePropertyInsuranceHistoryMutationVariables = Exact<{
  input: DeletePropertyInsuranceHistoryInput;
}>;

export type DeletePropertyInsuranceHistoryMutation = {
  __typename?: 'Mutation';
  deletePropertyInsuranceHistory?: {
    __typename?: 'InsurancePolicyPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      insurancePolicyHistory?: Array<{
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      }>;
    };
  };
};

export type AddPropertyInsuranceClaimMutationVariables = Exact<{
  input: CreatePropertyInsuranceClaimInput;
}>;

export type AddPropertyInsuranceClaimMutation = {
  __typename?: 'Mutation';
  addPropertyInsuranceClaim?: {
    __typename?: 'ClaimPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      insurancePolicy?: {
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      };
      insurancePolicyHistory?: Array<{
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      }>;
    };
  };
};

export type UpdatePropertyInsuranceClaimMutationVariables = Exact<{
  input: UpdatePropertyInsuranceClaimInput;
}>;

export type UpdatePropertyInsuranceClaimMutation = {
  __typename?: 'Mutation';
  updatePropertyInsuranceClaim?: {
    __typename?: 'ClaimPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      insurancePolicy?: {
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      };
      insurancePolicyHistory?: Array<{
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      }>;
    };
  };
};

export type DeletePropertyInsuranceClaimMutationVariables = Exact<{
  input: DeletePropertyInsuranceClaimInput;
}>;

export type DeletePropertyInsuranceClaimMutation = {
  __typename?: 'Mutation';
  deletePropertyInsuranceClaim?: {
    __typename?: 'DeleteClaimPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      insurancePolicy?: {
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      };
      insurancePolicyHistory?: Array<{
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      }>;
    };
  };
};

export type AddPropertyInsuranceHistoryClaimMutationVariables = Exact<{
  input: CreatePropertyInsuranceClaimInput;
}>;

export type AddPropertyInsuranceHistoryClaimMutation = {
  __typename?: 'Mutation';
  addPropertyInsuranceHistoryClaim?: {
    __typename?: 'ClaimPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      insurancePolicy?: {
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      };
      insurancePolicyHistory?: Array<{
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      }>;
    };
  };
};

export type UpdatePropertyInsuranceHistoryClaimMutationVariables = Exact<{
  input: UpdatePropertyInsuranceClaimInput;
}>;

export type UpdatePropertyInsuranceHistoryClaimMutation = {
  __typename?: 'Mutation';
  updatePropertyInsuranceHistoryClaim?: {
    __typename?: 'ClaimPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      insurancePolicy?: {
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      };
      insurancePolicyHistory?: Array<{
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      }>;
    };
  };
};

export type DeletePropertyInsuranceHistoryClaimMutationVariables = Exact<{
  input: DeletePropertyInsuranceClaimInput;
}>;

export type DeletePropertyInsuranceHistoryClaimMutation = {
  __typename?: 'Mutation';
  deletePropertyInsuranceHistoryClaim?: {
    __typename?: 'DeleteClaimPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      insurancePolicy?: {
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      };
      insurancePolicyHistory?: Array<{
        __typename?: 'InsurancePolicy';
        id: string;
        policyNumber: string;
        policyHolder: string;
        effective: string;
        expiry: string;
        supplierId?: string;
        coverage?: number;
        premium?: number;
        supplier?: {
          __typename?: 'Supplier';
          id: string;
          accountId: string;
          name: string;
          contactName?: string;
          type?: string;
          phone?: string;
          fax?: string;
          email?: string;
          enabled: boolean;
          location?: string;
          deletedZ?: string;
          verificationRequestedZ?: string;
          verifiedZ?: string;
          website?: string;
          coi?: boolean;
          coiExpiry?: string;
          billingEmail?: string;
          businessNumber?: string;
          autopayInstitution?: string;
          autopayTransit?: string;
          autopayAccount?: string;
          autopayStatus?: string;
          autopayStatusUpdatedZ?: string;
          autopayClientId?: string;
          expenseGlId?: string;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          supplierAccounts?: Array<{
            __typename?: 'SupplierAccount';
            id: string;
            accountNumber: string;
          }>;
        };
        claims?: Array<{
          __typename?: 'Claim';
          id: string;
          date: string;
          amount: number;
          status?: ClaimStatus;
          type?: ClaimType;
          description?: string;
        }>;
      }>;
    };
  };
};

export type GetPropertyInsuranceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyInsuranceQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    insurancePolicy?: {
      __typename?: 'InsurancePolicy';
      id: string;
      policyNumber: string;
      policyHolder: string;
      effective: string;
      expiry: string;
      supplierId?: string;
      coverage?: number;
      premium?: number;
      supplier?: {
        __typename?: 'Supplier';
        id: string;
        accountId: string;
        name: string;
        contactName?: string;
        type?: string;
        phone?: string;
        fax?: string;
        email?: string;
        enabled: boolean;
        location?: string;
        deletedZ?: string;
        verificationRequestedZ?: string;
        verifiedZ?: string;
        website?: string;
        coi?: boolean;
        coiExpiry?: string;
        billingEmail?: string;
        businessNumber?: string;
        autopayInstitution?: string;
        autopayTransit?: string;
        autopayAccount?: string;
        autopayStatus?: string;
        autopayStatusUpdatedZ?: string;
        autopayClientId?: string;
        expenseGlId?: string;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
        fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        supplierAccounts?: Array<{
          __typename?: 'SupplierAccount';
          id: string;
          accountNumber: string;
        }>;
      };
      claims?: Array<{
        __typename?: 'Claim';
        id: string;
        date: string;
        amount: number;
        status?: ClaimStatus;
        type?: ClaimType;
        description?: string;
      }>;
    };
  };
};

export type GetPropertyInsuranceHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyInsuranceHistoryQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    insurancePolicyHistory?: Array<{
      __typename?: 'InsurancePolicy';
      id: string;
      policyNumber: string;
      policyHolder: string;
      effective: string;
      expiry: string;
      supplierId?: string;
      coverage?: number;
      premium?: number;
      supplier?: {
        __typename?: 'Supplier';
        id: string;
        accountId: string;
        name: string;
        contactName?: string;
        type?: string;
        phone?: string;
        fax?: string;
        email?: string;
        enabled: boolean;
        location?: string;
        deletedZ?: string;
        verificationRequestedZ?: string;
        verifiedZ?: string;
        website?: string;
        coi?: boolean;
        coiExpiry?: string;
        billingEmail?: string;
        businessNumber?: string;
        autopayInstitution?: string;
        autopayTransit?: string;
        autopayAccount?: string;
        autopayStatus?: string;
        autopayStatusUpdatedZ?: string;
        autopayClientId?: string;
        expenseGlId?: string;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
        fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        supplierAccounts?: Array<{
          __typename?: 'SupplierAccount';
          id: string;
          accountNumber: string;
        }>;
      };
      claims?: Array<{
        __typename?: 'Claim';
        id: string;
        date: string;
        amount: number;
        status?: ClaimStatus;
        type?: ClaimType;
        description?: string;
      }>;
    }>;
  };
};

export type MoveUnitsToBuildingMutationVariables = Exact<{
  input: MoveUnitsToBuildingInput;
}>;

export type MoveUnitsToBuildingMutation = {
  __typename?: 'Mutation';
  moveUnitsToBuilding?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type AddPropertyMutationVariables = Exact<{
  input: CreatePropertyInput;
}>;

export type AddPropertyMutation = {
  __typename?: 'Mutation';
  createProperty?: {
    __typename?: 'PropertyPayload';
    success?: boolean;
    error?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      ownerId?: string;
      enabled?: boolean;
      name: string;
      key?: string;
      leasedUnits?: number;
      totalUnits?: number;
      totalUnitFactor?: number;
      commonAreas?: number;
      ownerUnits?: number;
      commercialLeasedUnits?: number;
      condoUnits?: number;
      imageKey?: string;
      timezone: string;
      commercialUnits?: number;
      rentalUnits?: number;
      phone?: string;
      types?: Array<PropertyType>;
      supplierIds?: Array<string>;
      contractorIds?: Array<string>;
      legalDescription?: string;
      creatingUnits?: number;
      houseRules?: Array<string>;
      autopayCollectionsEnabled?: boolean;
      rentalLeaseDocumentTemplateId?: string;
      commercialLeaseDocumentTemplateId?: string;
      buildings?: Array<{
        __typename?: 'Building';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        timezone: string;
        phone?: string;
        ownerId?: string;
        types?: Array<PropertyType>;
        legalDescription?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          lat?: number;
          lng?: number;
        };
      }>;
      units?: Array<{
        __typename?: 'Unit';
        parking?: Array<string>;
        storage?: Array<string>;
        keyring?: Array<string>;
        id: string;
        name: string;
        legalAddress?: string;
        sqft: number;
        bedrooms: number;
        bathrooms: number;
        additionalInformation?: string;
        occupancy?: UnitOccupancy;
        unitFactor?: number;
        condoFee?: number;
        condoFeeGst?: number;
        preferredPaymentMethod?: PaymentMethod;
        propertyId?: string;
        ownerId?: string;
        buildingId?: string;
        owner?: {
          __typename?: 'Owner';
          id: string;
          name: string;
          email?: string;
          phones?: Array<string>;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
        };
        property?: {
          __typename?: 'Property';
          id: string;
          enabled?: boolean;
          name: string;
          phone?: string;
          imageKey?: string;
          timezone: string;
          ownerId?: string;
          storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
          parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
          keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
          owner?: {
            __typename?: 'Owner';
            id: string;
            name: string;
            email?: string;
            phones?: Array<string>;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
              country?: string;
              lat?: number;
              lng?: number;
            };
          };
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        };
        building?: {
          __typename?: 'Building';
          id: string;
          name: string;
          key?: string;
          imageKey?: string;
          timezone: string;
          phone?: string;
          ownerId?: string;
          types?: Array<PropertyType>;
          legalDescription?: string;
          address?: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            lat?: number;
            lng?: number;
          };
        };
        condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        history?: Array<
          | {
              __typename?: 'Request';
              summary?: string;
              status?: string;
              delay?: string;
              id: string;
              name?: string;
              tenant?: { __typename?: 'RequestTenant'; name?: string };
              fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            }
          | {
              __typename?: 'RequestEvent';
              id: string;
              name?: string;
              fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            }
        >;
      }>;
      address: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
      parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
      storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
      keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
      onsiteManager?: { __typename?: 'OnsiteManager'; id: string; entityType: OnsiteManagerType };
      owner?: { __typename?: 'Owner'; id: string; name: string };
    };
    unitNames?: {
      __typename?: 'UnitNames';
      namesFound?: number;
      totalUnits?: number;
      complete?: boolean;
      names?: Array<string>;
    };
  };
};

export type GetPropertyEntityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyEntityQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    ownerId?: string;
    enabled?: boolean;
    name: string;
    key?: string;
    leasedUnits?: number;
    totalUnits?: number;
    totalUnitFactor?: number;
    commonAreas?: number;
    ownerUnits?: number;
    commercialLeasedUnits?: number;
    condoUnits?: number;
    imageKey?: string;
    timezone: string;
    commercialUnits?: number;
    rentalUnits?: number;
    phone?: string;
    types?: Array<PropertyType>;
    supplierIds?: Array<string>;
    contractorIds?: Array<string>;
    legalDescription?: string;
    creatingUnits?: number;
    houseRules?: Array<string>;
    autopayCollectionsEnabled?: boolean;
    rentalLeaseDocumentTemplateId?: string;
    commercialLeaseDocumentTemplateId?: string;
    buildings?: Array<{
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    }>;
    address: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
    storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
    keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
    onsiteManager?: { __typename?: 'OnsiteManager'; id: string; entityType: OnsiteManagerType };
    owner?: { __typename?: 'Owner'; id: string; name: string };
  };
};

export type GetPropertyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    ownerId?: string;
    enabled?: boolean;
    name: string;
    key?: string;
    leasedUnits?: number;
    totalUnits?: number;
    totalUnitFactor?: number;
    commonAreas?: number;
    ownerUnits?: number;
    commercialLeasedUnits?: number;
    condoUnits?: number;
    imageKey?: string;
    timezone: string;
    commercialUnits?: number;
    rentalUnits?: number;
    phone?: string;
    types?: Array<PropertyType>;
    supplierIds?: Array<string>;
    contractorIds?: Array<string>;
    legalDescription?: string;
    creatingUnits?: number;
    houseRules?: Array<string>;
    autopayCollectionsEnabled?: boolean;
    rentalLeaseDocumentTemplateId?: string;
    commercialLeaseDocumentTemplateId?: string;
    buildings?: Array<{
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    }>;
    units?: Array<{
      __typename?: 'Unit';
      parking?: Array<string>;
      storage?: Array<string>;
      keyring?: Array<string>;
      id: string;
      name: string;
      legalAddress?: string;
      sqft: number;
      bedrooms: number;
      bathrooms: number;
      additionalInformation?: string;
      occupancy?: UnitOccupancy;
      unitFactor?: number;
      condoFee?: number;
      condoFeeGst?: number;
      preferredPaymentMethod?: PaymentMethod;
      propertyId?: string;
      ownerId?: string;
      buildingId?: string;
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      property?: {
        __typename?: 'Property';
        id: string;
        enabled?: boolean;
        name: string;
        phone?: string;
        imageKey?: string;
        timezone: string;
        ownerId?: string;
        storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
        parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
        keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
        owner?: {
          __typename?: 'Owner';
          id: string;
          name: string;
          email?: string;
          phones?: Array<string>;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
        };
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      };
      building?: {
        __typename?: 'Building';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        timezone: string;
        phone?: string;
        ownerId?: string;
        types?: Array<PropertyType>;
        legalDescription?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          lat?: number;
          lng?: number;
        };
      };
      condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      history?: Array<
        | {
            __typename?: 'Request';
            summary?: string;
            status?: string;
            delay?: string;
            id: string;
            name?: string;
            tenant?: { __typename?: 'RequestTenant'; name?: string };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
        | {
            __typename?: 'RequestEvent';
            id: string;
            name?: string;
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
      >;
    }>;
    address: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
    storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
    keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
    onsiteManager?: { __typename?: 'OnsiteManager'; id: string; entityType: OnsiteManagerType };
    owner?: { __typename?: 'Owner'; id: string; name: string };
  };
};

export type PropertyWithResidenciesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PropertyWithResidenciesQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    journalCount?: number;
    ownerId?: string;
    enabled?: boolean;
    name: string;
    key?: string;
    leasedUnits?: number;
    totalUnits?: number;
    totalUnitFactor?: number;
    commonAreas?: number;
    ownerUnits?: number;
    commercialLeasedUnits?: number;
    condoUnits?: number;
    imageKey?: string;
    timezone: string;
    commercialUnits?: number;
    rentalUnits?: number;
    phone?: string;
    types?: Array<PropertyType>;
    supplierIds?: Array<string>;
    contractorIds?: Array<string>;
    legalDescription?: string;
    creatingUnits?: number;
    houseRules?: Array<string>;
    autopayCollectionsEnabled?: boolean;
    rentalLeaseDocumentTemplateId?: string;
    commercialLeaseDocumentTemplateId?: string;
    units?: Array<{
      __typename?: 'Unit';
      parking?: Array<string>;
      storage?: Array<string>;
      keyring?: Array<string>;
      id: string;
      name: string;
      legalAddress?: string;
      sqft: number;
      bedrooms: number;
      bathrooms: number;
      additionalInformation?: string;
      occupancy?: UnitOccupancy;
      unitFactor?: number;
      condoFee?: number;
      condoFeeGst?: number;
      preferredPaymentMethod?: PaymentMethod;
      propertyId?: string;
      ownerId?: string;
      buildingId?: string;
      floorplan?: {
        __typename?: 'Floorplan';
        id: string;
        marketRate?: number;
        exteriorSqFt?: number;
        interiorSqFt?: number;
        bedrooms?: number;
        bathrooms?: number;
      };
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      allResidencies?: Array<{
        __typename?: 'Residency';
        id: string;
        startZ: string;
        endZ?: string;
        renewZ?: string;
        rrule?: string;
        terms?: Array<string>;
        moveOutReason?: MoveOutReason;
        residents: Array<{
          __typename?: 'ResidentAssociation';
          id: string;
          leaseHolder?: boolean;
          resident:
            | { __typename?: 'Owner'; id: string; name: string }
            | { __typename?: 'Tenant'; id: string; name: string };
        }>;
      }>;
      condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      property?: {
        __typename?: 'Property';
        id: string;
        enabled?: boolean;
        name: string;
        phone?: string;
        imageKey?: string;
        timezone: string;
        ownerId?: string;
        storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
        parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
        keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
        owner?: {
          __typename?: 'Owner';
          id: string;
          name: string;
          email?: string;
          phones?: Array<string>;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
        };
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      };
      building?: {
        __typename?: 'Building';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        timezone: string;
        phone?: string;
        ownerId?: string;
        types?: Array<PropertyType>;
        legalDescription?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          lat?: number;
          lng?: number;
        };
      };
      history?: Array<
        | {
            __typename?: 'Request';
            summary?: string;
            status?: string;
            delay?: string;
            id: string;
            name?: string;
            tenant?: { __typename?: 'RequestTenant'; name?: string };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
        | {
            __typename?: 'RequestEvent';
            id: string;
            name?: string;
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
      >;
    }>;
    buildings?: Array<{
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    }>;
    address: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
    storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
    keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
    onsiteManager?: { __typename?: 'OnsiteManager'; id: string; entityType: OnsiteManagerType };
    owner?: { __typename?: 'Owner'; id: string; name: string };
  };
};

export type UpdatePropertyAmenitiesMutationVariables = Exact<{
  input: UpdatePropertyAmenitiesInput;
}>;

export type UpdatePropertyAmenitiesMutation = {
  __typename?: 'Mutation';
  updatePropertyAmenities: {
    __typename?: 'PropertyPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      amenitiesSetting?: {
        __typename?: 'AmenitiesSetting';
        amenities?: Array<{
          __typename?: 'Amenity';
          id?: string;
          name?: string;
          category?: string;
        }>;
      };
    };
  };
};

export type CreatePropertyNoteMutationVariables = Exact<{
  input: CreatePropertyNoteInput;
}>;

export type CreatePropertyNoteMutation = {
  __typename?: 'Mutation';
  createPropertyNote?: {
    __typename?: 'PropertyPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type DeletePropertyNoteMutationVariables = Exact<{
  input: DeletePropertyNoteInput;
}>;

export type DeletePropertyNoteMutation = {
  __typename?: 'Mutation';
  deletePropertyNote?: {
    __typename?: 'PropertyPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type UpdatePropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdatePropertyInput;
}>;

export type UpdatePropertyMutation = {
  __typename?: 'Mutation';
  updateProperty?: {
    __typename?: 'Property';
    id: string;
    ownerId?: string;
    enabled?: boolean;
    name: string;
    key?: string;
    leasedUnits?: number;
    totalUnits?: number;
    totalUnitFactor?: number;
    commonAreas?: number;
    ownerUnits?: number;
    commercialLeasedUnits?: number;
    condoUnits?: number;
    imageKey?: string;
    timezone: string;
    commercialUnits?: number;
    rentalUnits?: number;
    phone?: string;
    types?: Array<PropertyType>;
    supplierIds?: Array<string>;
    contractorIds?: Array<string>;
    legalDescription?: string;
    creatingUnits?: number;
    houseRules?: Array<string>;
    autopayCollectionsEnabled?: boolean;
    rentalLeaseDocumentTemplateId?: string;
    commercialLeaseDocumentTemplateId?: string;
    buildings?: Array<{
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    }>;
    units?: Array<{
      __typename?: 'Unit';
      parking?: Array<string>;
      storage?: Array<string>;
      keyring?: Array<string>;
      id: string;
      name: string;
      legalAddress?: string;
      sqft: number;
      bedrooms: number;
      bathrooms: number;
      additionalInformation?: string;
      occupancy?: UnitOccupancy;
      unitFactor?: number;
      condoFee?: number;
      condoFeeGst?: number;
      preferredPaymentMethod?: PaymentMethod;
      propertyId?: string;
      ownerId?: string;
      buildingId?: string;
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      property?: {
        __typename?: 'Property';
        id: string;
        enabled?: boolean;
        name: string;
        phone?: string;
        imageKey?: string;
        timezone: string;
        ownerId?: string;
        storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
        parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
        keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
        owner?: {
          __typename?: 'Owner';
          id: string;
          name: string;
          email?: string;
          phones?: Array<string>;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
        };
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      };
      building?: {
        __typename?: 'Building';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        timezone: string;
        phone?: string;
        ownerId?: string;
        types?: Array<PropertyType>;
        legalDescription?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          lat?: number;
          lng?: number;
        };
      };
      condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      history?: Array<
        | {
            __typename?: 'Request';
            summary?: string;
            status?: string;
            delay?: string;
            id: string;
            name?: string;
            tenant?: { __typename?: 'RequestTenant'; name?: string };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
        | {
            __typename?: 'RequestEvent';
            id: string;
            name?: string;
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
      >;
    }>;
    address: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
    storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
    keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
    onsiteManager?: { __typename?: 'OnsiteManager'; id: string; entityType: OnsiteManagerType };
    owner?: { __typename?: 'Owner'; id: string; name: string };
  };
};

export type PropertyNotesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PropertyNotesQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
  };
};

export type UpdatePropertySpecsMutationVariables = Exact<{
  input: UpdatePropertySpecsInput;
}>;

export type UpdatePropertySpecsMutation = {
  __typename?: 'Mutation';
  updatePropertySpecs: {
    __typename?: 'PropertyPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      specs?: Array<{ __typename?: 'PropertySpecs'; key: PropertySpecTypes; value: string }>;
    };
  };
};

export type GetPropertySpecsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertySpecsQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    specs?: Array<{ __typename?: 'PropertySpecs'; key: PropertySpecTypes; value: string }>;
  };
};

export type PropertyAmenitiesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PropertyAmenitiesQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    amenitiesSetting?: {
      __typename?: 'AmenitiesSetting';
      amenities?: Array<{ __typename?: 'Amenity'; id?: string; name?: string; category?: string }>;
    };
  };
};

export type SuppliersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SuppliersQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    suppliers?: Array<{
      __typename?: 'Supplier';
      id: string;
      accountId: string;
      name: string;
      contactName?: string;
      type?: string;
      phone?: string;
      fax?: string;
      email?: string;
      enabled: boolean;
      location?: string;
      deletedZ?: string;
      verificationRequestedZ?: string;
      verifiedZ?: string;
      website?: string;
      coi?: boolean;
      coiExpiry?: string;
      billingEmail?: string;
      businessNumber?: string;
      autopayInstitution?: string;
      autopayTransit?: string;
      autopayAccount?: string;
      autopayStatus?: string;
      autopayStatusUpdatedZ?: string;
      autopayClientId?: string;
      expenseGlId?: string;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      supplierAccounts?: Array<{
        __typename?: 'SupplierAccount';
        id: string;
        accountNumber: string;
      }>;
    }>;
  };
};

export type GetPropertyFeaturesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyFeaturesQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    features?: Array<{ __typename?: 'Feature'; id?: string; name?: string }>;
  };
};

export type UpdatePropertyFeaturesMutationVariables = Exact<{
  input: UpdatePropertyFeaturesInput;
}>;

export type UpdatePropertyFeaturesMutation = {
  __typename?: 'Mutation';
  updatePropertyFeatures: {
    __typename?: 'PropertyPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      features?: Array<{ __typename?: 'Feature'; id?: string; name?: string }>;
    };
  };
};

export type PropertyDocumentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PropertyDocumentsQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    documents?: Array<{
      __typename?: 'Document';
      key: string;
      name?: string;
      size?: number;
      createdZ: string;
      typename?: string;
    }>;
  };
};

export type RenamePropertyDocumentMutationVariables = Exact<{
  input: RenamePropertyDocumentInput;
}>;

export type RenamePropertyDocumentMutation = {
  __typename?: 'Mutation';
  renamePropertyDocument: {
    __typename?: 'PropertyPayload';
    property?: {
      __typename?: 'Property';
      id: string;
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ: string;
        typename?: string;
      }>;
    };
  };
};

export type UpdatePropertySuppliersMutationVariables = Exact<{
  input: UpdatePropertySuppliersInput;
}>;

export type UpdatePropertySuppliersMutation = {
  __typename?: 'Mutation';
  updatePropertySuppliers: {
    __typename?: 'PropertyPayload';
    property?: { __typename?: 'Property'; id: string; supplierIds?: Array<string> };
  };
};

export type UpdatePropertyContractorsMutationVariables = Exact<{
  input: UpdatePropertyContractorsInput;
}>;

export type UpdatePropertyContractorsMutation = {
  __typename?: 'Mutation';
  updatePropertyContractors: {
    __typename?: 'PropertyPayload';
    property?: { __typename?: 'Property'; id: string; contractorIds?: Array<string> };
  };
};

export type DeletePropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePropertyMutation = {
  __typename?: 'Mutation';
  deleteProperty: { __typename?: 'SuccessPayload'; success?: boolean };
};

export type RemoveParkingStallMutationVariables = Exact<{
  input: RemovePropertyParkingInput;
}>;

export type RemoveParkingStallMutation = {
  __typename?: 'Mutation';
  removePropertyParking?: {
    __typename?: 'PropertyPayload';
    success?: boolean;
    error?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
    };
  };
};

export type RemoveStorageLockerMutationVariables = Exact<{
  input: RemovePropertyStorageInput;
}>;

export type RemoveStorageLockerMutation = {
  __typename?: 'Mutation';
  removePropertyStorage?: {
    __typename?: 'PropertyPayload';
    success?: boolean;
    error?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
    };
  };
};

export type RemoveKeyringMutationVariables = Exact<{
  input: RemovePropertyKeyInput;
}>;

export type RemoveKeyringMutation = {
  __typename?: 'Mutation';
  removePropertyKeyring?: {
    __typename?: 'PropertyPayload';
    success?: boolean;
    error?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
    };
  };
};

export type AddParkingStallMutationVariables = Exact<{
  input: AddPropertyParkingInput;
}>;

export type AddParkingStallMutation = {
  __typename?: 'Mutation';
  addPropertyParking?: {
    __typename?: 'PropertyPayload';
    success?: boolean;
    error?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
    };
  };
};

export type AddStorageLockerMutationVariables = Exact<{
  input: AddPropertyStorageInput;
}>;

export type AddStorageLockerMutation = {
  __typename?: 'Mutation';
  addPropertyStorage?: {
    __typename?: 'PropertyPayload';
    success?: boolean;
    error?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
    };
  };
};

export type AddKeyringMutationVariables = Exact<{
  input: AddPropertyKeyringInput;
}>;

export type AddKeyringMutation = {
  __typename?: 'Mutation';
  addPropertyKeyring?: {
    __typename?: 'PropertyPayload';
    success?: boolean;
    error?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
    };
  };
};

export type FloorplanFieldsFragment = {
  __typename?: 'Floorplan';
  id: string;
  enabled?: boolean;
  default?: boolean;
  name: string;
  description?: string;
  bedrooms?: number;
  bathrooms?: number;
  interiorSqFt?: number;
  exteriorSqFt?: number;
  marketRate?: number;
  totalUnits?: number;
  additionalFeatures?: Array<string>;
  links?: Array<string>;
  areas?: Array<{
    __typename?: 'FloorplanArea';
    id: string;
    category?: string;
    label: string;
    description?: string;
    features?: Array<{
      __typename?: 'FloorplanAreaFeature';
      id: string;
      description?: string;
      label?: string;
      type?: string;
      name?: string;
    }>;
  }>;
  units?: Array<{ __typename?: 'Unit'; id: string; name: string; occupancy?: UnitOccupancy }>;
};

export type GetPropertyFloorplansQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyFloorplansQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    floorplans?: Array<{
      __typename?: 'Floorplan';
      id: string;
      enabled?: boolean;
      default?: boolean;
      name: string;
      description?: string;
      bedrooms?: number;
      bathrooms?: number;
      interiorSqFt?: number;
      exteriorSqFt?: number;
      marketRate?: number;
      totalUnits?: number;
      additionalFeatures?: Array<string>;
      links?: Array<string>;
      areas?: Array<{
        __typename?: 'FloorplanArea';
        id: string;
        category?: string;
        label: string;
        description?: string;
        features?: Array<{
          __typename?: 'FloorplanAreaFeature';
          id: string;
          description?: string;
          label?: string;
          type?: string;
          name?: string;
        }>;
      }>;
      units?: Array<{ __typename?: 'Unit'; id: string; name: string; occupancy?: UnitOccupancy }>;
    }>;
  };
};

export type GetBuildingFloorplansQueryVariables = Exact<{
  filter: BuildingFilter;
}>;

export type GetBuildingFloorplansQuery = {
  __typename?: 'Query';
  building?: {
    __typename?: 'Building';
    id: string;
    floorplans?: Array<{
      __typename?: 'Floorplan';
      id: string;
      enabled?: boolean;
      default?: boolean;
      name: string;
      description?: string;
      bedrooms?: number;
      bathrooms?: number;
      interiorSqFt?: number;
      exteriorSqFt?: number;
      marketRate?: number;
      totalUnits?: number;
      additionalFeatures?: Array<string>;
      links?: Array<string>;
      areas?: Array<{
        __typename?: 'FloorplanArea';
        id: string;
        category?: string;
        label: string;
        description?: string;
        features?: Array<{
          __typename?: 'FloorplanAreaFeature';
          id: string;
          description?: string;
          label?: string;
          type?: string;
          name?: string;
        }>;
      }>;
      units?: Array<{ __typename?: 'Unit'; id: string; name: string; occupancy?: UnitOccupancy }>;
    }>;
  };
};

export type GetPropertyPhotosQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyPhotosQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    images?: Array<{ __typename?: 'Image'; key?: string; name?: string; tags?: Array<string> }>;
  };
};

export type AddPropertyImagesMutationVariables = Exact<{
  input: PropertyImagesInput;
}>;

export type AddPropertyImagesMutation = {
  __typename?: 'Mutation';
  addPropertyImages: {
    __typename?: 'PropertyPayload';
    success?: boolean;
    error?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      images?: Array<{ __typename?: 'Image'; key?: string; name?: string; tags?: Array<string> }>;
    };
  };
};

export type DeletePropertyImageMutationVariables = Exact<{
  input: DeletePropertyImageInput;
}>;

export type DeletePropertyImageMutation = {
  __typename?: 'Mutation';
  deletePropertyImage: {
    __typename?: 'PropertyPayload';
    success?: boolean;
    error?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      images?: Array<{ __typename?: 'Image'; key?: string; name?: string; tags?: Array<string> }>;
    };
  };
};

export type InspectionPhotosUnitDataQueryVariables = Exact<{
  unitId: Scalars['ID']['input'];
}>;

export type InspectionPhotosUnitDataQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    legalAddress?: string;
    sqft: number;
    bedrooms: number;
    bathrooms: number;
    additionalInformation?: string;
    occupancy?: UnitOccupancy;
    parking?: Array<string>;
    storage?: Array<string>;
    keyring?: Array<string>;
    unitFactor?: number;
    condoFee?: number;
    condoFeeGst?: number;
    preferredPaymentMethod?: PaymentMethod;
    propertyId?: string;
    ownerId?: string;
    buildingId?: string;
    routineInspections?: Array<{
      __typename?: 'Inspection';
      id: string;
      status?: InspectionStatus;
      completedZ?: string;
      templateId: string;
      category?: InspectionCategory;
      description?: string;
      items?: Array<{
        __typename?: 'InspectionItem';
        id: string;
        referenceId?: string;
        label: string;
        description?: string;
        category: InspectionItemCategory;
        wasInspected?: boolean;
        fields: Array<{
          __typename?: 'InspectionField';
          id: string;
          name: string;
          label?: string;
          description?: string;
          type: string;
          value?: string;
          noteText?: string;
          needsRequest?: boolean;
          inspectedZ?: string;
          images?: Array<{ __typename?: 'Image'; key?: string }>;
        }>;
      }>;
      signatures?: Array<{
        __typename?: 'Signature';
        id?: string;
        signature: string;
        signerName: string;
        signerEntity: SignerEntity;
        isAgent?: boolean;
        agentRelationship?: string;
      }>;
      generatedSummary?: { __typename?: 'InspectionSummary'; summary?: string; score?: number };
    }>;
    property?: {
      __typename?: 'Property';
      id: string;
      enabled?: boolean;
      name: string;
      phone?: string;
      imageKey?: string;
      timezone: string;
      ownerId?: string;
      storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
      parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
      keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
    building?: {
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    };
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      email?: string;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
    };
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    history?: Array<
      | {
          __typename?: 'Request';
          summary?: string;
          status?: string;
          delay?: string;
          id: string;
          name?: string;
          tenant?: { __typename?: 'RequestTenant'; name?: string };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
      | {
          __typename?: 'RequestEvent';
          id: string;
          name?: string;
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
    >;
  };
};

export type InspectionPhotosResidencyDataQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
}>;

export type InspectionPhotosResidencyDataQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    moveInInspection?: {
      __typename?: 'LeaseInspection';
      id: string;
      status?: InspectionStatus;
      completedZ?: string;
      templateId: string;
      category?: InspectionCategory;
      inspectionAgreements?: Array<{
        __typename?: 'Agreement';
        noteText?: string;
        agreementType: AgreementType;
      }>;
      costAgreement?: {
        __typename?: 'Agreement';
        cost?: number;
        noteText?: string;
        agreementType: AgreementType;
      };
      signatures?: Array<{
        __typename?: 'Signature';
        id?: string;
        signature: string;
        signerName: string;
        signerEntity: SignerEntity;
        isAgent?: boolean;
        agentRelationship?: string;
      }>;
      items?: Array<{
        __typename?: 'InspectionItem';
        id: string;
        referenceId?: string;
        label: string;
        description?: string;
        category: InspectionItemCategory;
        wasInspected?: boolean;
        fields: Array<{
          __typename?: 'InspectionField';
          id: string;
          name: string;
          label?: string;
          description?: string;
          type: string;
          value?: string;
          noteText?: string;
          needsRequest?: boolean;
          inspectedZ?: string;
          images?: Array<{ __typename?: 'Image'; key?: string }>;
        }>;
      }>;
      generatedSummary?: { __typename?: 'InspectionSummary'; summary?: string; score?: number };
    };
    moveOutInspection?: {
      __typename?: 'LeaseInspection';
      id: string;
      status?: InspectionStatus;
      completedZ?: string;
      templateId: string;
      category?: InspectionCategory;
      inspectionAgreements?: Array<{
        __typename?: 'Agreement';
        noteText?: string;
        agreementType: AgreementType;
      }>;
      costAgreement?: {
        __typename?: 'Agreement';
        cost?: number;
        noteText?: string;
        agreementType: AgreementType;
      };
      signatures?: Array<{
        __typename?: 'Signature';
        id?: string;
        signature: string;
        signerName: string;
        signerEntity: SignerEntity;
        isAgent?: boolean;
        agentRelationship?: string;
      }>;
      items?: Array<{
        __typename?: 'InspectionItem';
        id: string;
        referenceId?: string;
        label: string;
        description?: string;
        category: InspectionItemCategory;
        wasInspected?: boolean;
        fields: Array<{
          __typename?: 'InspectionField';
          id: string;
          name: string;
          label?: string;
          description?: string;
          type: string;
          value?: string;
          noteText?: string;
          needsRequest?: boolean;
          inspectedZ?: string;
          images?: Array<{ __typename?: 'Image'; key?: string }>;
        }>;
      }>;
      generatedSummary?: { __typename?: 'InspectionSummary'; summary?: string; score?: number };
    };
  };
};

export type UnassignStorageMutationVariables = Exact<{
  input: AssignStorageInput;
}>;

export type UnassignStorageMutation = {
  __typename?: 'Mutation';
  unassignStorage?: {
    __typename?: 'AssignStoragePayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
    };
    unit?: { __typename?: 'Unit'; id: string; storage?: Array<string> };
  };
};

export type AssignStorageMutationVariables = Exact<{
  input: AssignStorageInput;
}>;

export type AssignStorageMutation = {
  __typename?: 'Mutation';
  assignStorage?: {
    __typename?: 'AssignStoragePayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
    };
    unit?: { __typename?: 'Unit'; id: string; storage?: Array<string> };
  };
};

export type UnassignParkingMutationVariables = Exact<{
  input: AssignParkingInput;
}>;

export type UnassignParkingMutation = {
  __typename?: 'Mutation';
  unassignParking?: {
    __typename?: 'AssignParkingPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
    };
    unit?: { __typename?: 'Unit'; id: string; parking?: Array<string> };
  };
};

export type AssignParkingMutationVariables = Exact<{
  input: AssignParkingInput;
}>;

export type AssignParkingMutation = {
  __typename?: 'Mutation';
  assignParking?: {
    __typename?: 'AssignParkingPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
    };
    unit?: { __typename?: 'Unit'; id: string; parking?: Array<string> };
  };
};

export type UnassignKeyringMutationVariables = Exact<{
  input: AssignKeyringInput;
}>;

export type UnassignKeyringMutation = {
  __typename?: 'Mutation';
  unassignKeyring?: {
    __typename?: 'AssignKeyringPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
    };
    unit?: { __typename?: 'Unit'; id: string; keyring?: Array<string> };
  };
};

export type AssignKeyringMutationVariables = Exact<{
  input: AssignKeyringInput;
}>;

export type AssignKeyringMutation = {
  __typename?: 'Mutation';
  assignKeyring?: {
    __typename?: 'AssignKeyringPayload';
    success?: boolean;
    property?: {
      __typename?: 'Property';
      id: string;
      keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
    };
    unit?: { __typename?: 'Unit'; id: string; keyring?: Array<string> };
  };
};

export type GetUnitFloorplanQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUnitFloorplanQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    floorplan?: { __typename?: 'Floorplan'; id: string; name: string };
  };
};

export type UpdateUnitFloorplanMutationVariables = Exact<{
  input: UpdateUnitFloorplanInput;
}>;

export type UpdateUnitFloorplanMutation = {
  __typename?: 'Mutation';
  updateUnitFloorplan?: {
    __typename?: 'UnitPayload';
    success?: boolean;
    unit?: {
      __typename?: 'Unit';
      id: string;
      bedrooms: number;
      bathrooms: number;
      sqft: number;
      floorplan?: { __typename?: 'Floorplan'; id: string; name: string };
    };
  };
};

export type RemoveUnitFloorplanMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveUnitFloorplanMutation = {
  __typename?: 'Mutation';
  removeUnitFloorplan?: {
    __typename?: 'UnitPayload';
    success?: boolean;
    unit?: {
      __typename?: 'Unit';
      id: string;
      floorplan?: { __typename?: 'Floorplan'; id: string; name: string };
    };
  };
};

export type UpdateResidencyMutationVariables = Exact<{
  input: UpdateResidencyInput;
}>;

export type UpdateResidencyMutation = {
  __typename?: 'Mutation';
  updateResidency: {
    __typename?: 'ResidencyPayload';
    success?: boolean;
    error?: string;
    residency?: {
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          category: RefundableLedgerCategory;
          amount: number;
          date: string;
        }>;
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    };
  };
};

export type CreateRequestRecurrenceMutationVariables = Exact<{
  input: CreateRequestRecurrenceInput;
}>;

export type CreateRequestRecurrenceMutation = {
  __typename?: 'Mutation';
  createRequestRecurrence?: {
    __typename?: 'RequestRecurrencePayload';
    success?: boolean;
    error?: string;
    requestRecurrence?: {
      __typename?: 'RequestRecurrence';
      id: string;
      accountId: string;
      rrule: string;
      nonBillable?: boolean;
      summary?: string;
      minutes?: number;
      instructions?: string;
      details?: string;
      originalRequest?: string;
      subcategory?: string;
      category?: string;
      name?: string;
      notes?: Array<{ __typename?: 'Note'; id: string; text?: string }>;
      checklist?: Array<{ __typename?: 'ChecklistItem'; id: string }>;
      unit?: {
        __typename?: 'Unit';
        id: string;
        name: string;
        occupancy?: UnitOccupancy;
        ownerId?: string;
        property?: {
          __typename?: 'Property';
          id: string;
          address: {
            __typename?: 'Address';
            country?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            suite?: string;
            lat?: number;
            lng?: number;
          };
        };
      };
      latestRequest?: { __typename?: 'Request'; id: string; status?: string };
    };
  };
};

export type CreateResidencyPathQueryVariables = Exact<{
  unitId: Scalars['ID']['input'];
}>;

export type CreateResidencyPathQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    legalAddress?: string;
    sqft: number;
    bedrooms: number;
    bathrooms: number;
    additionalInformation?: string;
    occupancy?: UnitOccupancy;
    parking?: Array<string>;
    storage?: Array<string>;
    keyring?: Array<string>;
    unitFactor?: number;
    condoFee?: number;
    condoFeeGst?: number;
    preferredPaymentMethod?: PaymentMethod;
    propertyId?: string;
    ownerId?: string;
    buildingId?: string;
    allResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          category: RefundableLedgerCategory;
          amount: number;
          date: string;
        }>;
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    }>;
    property?: {
      __typename?: 'Property';
      id: string;
      enabled?: boolean;
      name: string;
      phone?: string;
      imageKey?: string;
      timezone: string;
      ownerId?: string;
      storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
      parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
      keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
    building?: {
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    };
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      email?: string;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
    };
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    history?: Array<
      | {
          __typename?: 'Request';
          summary?: string;
          status?: string;
          delay?: string;
          id: string;
          name?: string;
          tenant?: { __typename?: 'RequestTenant'; name?: string };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
      | {
          __typename?: 'RequestEvent';
          id: string;
          name?: string;
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
    >;
  };
};

export type CreateResidencyMutationVariables = Exact<{
  input: CreateResidencyInput;
}>;

export type CreateResidencyMutation = {
  __typename?: 'Mutation';
  createResidency: {
    __typename?: 'ResidencyPayload';
    success?: boolean;
    error?: string;
    residency?: {
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          category: RefundableLedgerCategory;
          amount: number;
          date: string;
        }>;
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    };
  };
};

export type DeleteResidencyPathQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
}>;

export type DeleteResidencyPathQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    endZ?: string;
    startZ: string;
    unitId: string;
  };
};

export type DeleteResidencyMutationVariables = Exact<{
  input: DeleteResidencyInput;
}>;

export type DeleteResidencyMutation = {
  __typename?: 'Mutation';
  deleteResidency: {
    __typename?: 'UnitPayload';
    success?: boolean;
    error?: string;
    unit?: {
      __typename?: 'Unit';
      id: string;
      allResidencies?: Array<{ __typename?: 'Residency'; id: string }>;
    };
  };
};

export type GetFurnishingsTemplateQueryVariables = Exact<{ [key: string]: never }>;

export type GetFurnishingsTemplateQuery = {
  __typename?: 'Query';
  furnishingsTemplate?: {
    __typename?: 'FurnishingsTemplate';
    id: string;
    name: string;
    items: Array<{ __typename?: 'TemplateItem'; id: string; name: string }>;
  };
};

export type ResidencyWithInspectionsQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
}>;

export type ResidencyWithInspectionsQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    moveInInspection?: {
      __typename?: 'LeaseInspection';
      id: string;
      status?: InspectionStatus;
      completedZ?: string;
      templateId: string;
      category?: InspectionCategory;
      inspectionAgreements?: Array<{
        __typename?: 'Agreement';
        noteText?: string;
        agreementType: AgreementType;
      }>;
      costAgreement?: {
        __typename?: 'Agreement';
        cost?: number;
        noteText?: string;
        agreementType: AgreementType;
      };
      signatures?: Array<{
        __typename?: 'Signature';
        id?: string;
        signature: string;
        signerName: string;
        signerEntity: SignerEntity;
        isAgent?: boolean;
        agentRelationship?: string;
      }>;
      items?: Array<{
        __typename?: 'InspectionItem';
        id: string;
        referenceId?: string;
        label: string;
        description?: string;
        category: InspectionItemCategory;
        wasInspected?: boolean;
        fields: Array<{
          __typename?: 'InspectionField';
          id: string;
          name: string;
          label?: string;
          description?: string;
          type: string;
          value?: string;
          noteText?: string;
          needsRequest?: boolean;
          inspectedZ?: string;
          images?: Array<{ __typename?: 'Image'; key?: string }>;
        }>;
      }>;
      generatedSummary?: { __typename?: 'InspectionSummary'; summary?: string; score?: number };
    };
    moveOutInspection?: {
      __typename?: 'LeaseInspection';
      id: string;
      status?: InspectionStatus;
      completedZ?: string;
      templateId: string;
      category?: InspectionCategory;
      inspectionAgreements?: Array<{
        __typename?: 'Agreement';
        noteText?: string;
        agreementType: AgreementType;
      }>;
      costAgreement?: {
        __typename?: 'Agreement';
        cost?: number;
        noteText?: string;
        agreementType: AgreementType;
      };
      signatures?: Array<{
        __typename?: 'Signature';
        id?: string;
        signature: string;
        signerName: string;
        signerEntity: SignerEntity;
        isAgent?: boolean;
        agentRelationship?: string;
      }>;
      items?: Array<{
        __typename?: 'InspectionItem';
        id: string;
        referenceId?: string;
        label: string;
        description?: string;
        category: InspectionItemCategory;
        wasInspected?: boolean;
        fields: Array<{
          __typename?: 'InspectionField';
          id: string;
          name: string;
          label?: string;
          description?: string;
          type: string;
          value?: string;
          noteText?: string;
          needsRequest?: boolean;
          inspectedZ?: string;
          images?: Array<{ __typename?: 'Image'; key?: string }>;
        }>;
      }>;
      generatedSummary?: { __typename?: 'InspectionSummary'; summary?: string; score?: number };
    };
  };
};

export type RemoveResidencyResidentPathQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
}>;

export type RemoveResidencyResidentPathQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    unitId: string;
    propertyId: string;
    residents: Array<{
      __typename?: 'ResidentAssociation';
      id: string;
      leaseHolder?: boolean;
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      resident:
        | { __typename: 'Owner'; id: string; name: string }
        | { __typename: 'Tenant'; id: string; name: string };
    }>;
  };
};

export type RemoveResidencyResidentMutationVariables = Exact<{
  input: RemoveResidencyResidentInput;
}>;

export type RemoveResidencyResidentMutation = {
  __typename?: 'Mutation';
  removeResidencyResident: {
    __typename?: 'ResidencyPayload';
    error?: string;
    success?: boolean;
    residency?: {
      __typename?: 'Residency';
      id: string;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        leaseHolder?: boolean;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        resident:
          | { __typename: 'Owner'; id: string; name: string }
          | { __typename: 'Tenant'; id: string; name: string };
      }>;
    };
  };
};

export type UpdateResidencyInsuranceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  input: ResidencyInsuranceInput;
}>;

export type UpdateResidencyInsuranceMutation = {
  __typename?: 'Mutation';
  updateResidencyInsurance: {
    __typename?: 'ResidencyPayload';
    success?: boolean;
    error?: string;
    residency?: {
      __typename?: 'Residency';
      id: string;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
    };
  };
};

export type RemoveResidencyInsuranceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
}>;

export type RemoveResidencyInsuranceMutation = {
  __typename?: 'Mutation';
  removeResidencyInsurance: {
    __typename?: 'ResidencyPayload';
    success?: boolean;
    error?: string;
    residency?: {
      __typename?: 'Residency';
      id: string;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
    };
  };
};

export type UpdateResidencyInsurancePolicyPathQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
}>;

export type UpdateResidencyInsurancePolicyPathQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    insurancePolicies?: Array<{
      __typename?: 'LeaseInsurancePolicy';
      id?: string;
      policyNumber?: string;
      providerName?: string;
      expirationDate?: string;
      liability?: number;
    }>;
  };
};

export type ResidencyKeysPathQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
}>;

export type ResidencyKeysPathQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    unitId: string;
    propertyId: string;
    campaignId?: string;
    applicationId?: string;
  };
};

export type ResidencySecurityDepositFieldsFragment = {
  __typename?: 'Residency';
  id: string;
  securityDeposit?: number;
  securityDepositHistory?: Array<{
    __typename?: 'SecurityDepositHistory';
    createdZ: string;
    allocations: Array<{
      __typename?: 'SecurityDepositAllocation';
      status: SecurityDepositStatus;
      amount: number;
    }>;
  }>;
};

export type ResidencySecurityDepositHistoryPathQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
}>;

export type ResidencySecurityDepositHistoryPathQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    securityDeposit?: number;
    securityDepositHistory?: Array<{
      __typename?: 'SecurityDepositHistory';
      createdZ: string;
      allocations: Array<{
        __typename?: 'SecurityDepositAllocation';
        status: SecurityDepositStatus;
        amount: number;
      }>;
    }>;
  };
};

export type AddResidencySecurityDepositStatusMutationVariables = Exact<{
  input: AddResidencySecurityDepositStatusInput;
}>;

export type AddResidencySecurityDepositStatusMutation = {
  __typename?: 'Mutation';
  addResidencySecurityDepositStatus: {
    __typename?: 'ResidencyPayload';
    success?: boolean;
    error?: string;
    residency?: {
      __typename?: 'Residency';
      id: string;
      securityDeposit?: number;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
    };
  };
};

export type TerminateResidencyPathQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
}>;

export type TerminateResidencyPathQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    endZ?: string;
    moveOutReason?: MoveOutReason;
    unitId: string;
    propertyId: string;
  };
};

export type TerminateResidencyMutationVariables = Exact<{
  input: TerminateResidencyInput;
}>;

export type TerminateResidencyMutation = {
  __typename?: 'Mutation';
  terminateResidency: {
    __typename?: 'ResidencyPayload';
    success?: boolean;
    error?: string;
    residency?: {
      __typename?: 'Residency';
      id: string;
      endZ?: string;
      terms?: Array<string>;
      moveOutReason?: MoveOutReason;
      unitId: string;
      propertyId: string;
    };
  };
};

export type GetUtilitiesTemplateQueryVariables = Exact<{
  propertyId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetUtilitiesTemplateQuery = {
  __typename?: 'Query';
  utilitiesTemplate?: {
    __typename?: 'UtilitiesTemplate';
    id: string;
    name: string;
    items: Array<{ __typename?: 'TemplateItem'; id: string; name: string }>;
  };
};

export type CreateInspectionUrlMutationVariables = Exact<{
  input: InspectionUrlInput;
}>;

export type CreateInspectionUrlMutation = {
  __typename?: 'Mutation';
  createInspectionUrl: {
    __typename?: 'InspectionUrlPayload';
    error?: string;
    success?: boolean;
    inspectionUrlObject?: {
      __typename?: 'InspectionUrlObject';
      fileUrl: string;
      filename: string;
      message?: string;
    };
  };
};

export type GetUnitRoutineInspectionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUnitRoutineInspectionsQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    routineInspections?: Array<{
      __typename?: 'Inspection';
      id: string;
      status?: InspectionStatus;
      completedZ?: string;
      templateId: string;
      category?: InspectionCategory;
      description?: string;
      items?: Array<{
        __typename?: 'InspectionItem';
        id: string;
        referenceId?: string;
        label: string;
        description?: string;
        category: InspectionItemCategory;
        wasInspected?: boolean;
        fields: Array<{
          __typename?: 'InspectionField';
          id: string;
          name: string;
          label?: string;
          description?: string;
          type: string;
          value?: string;
          noteText?: string;
          needsRequest?: boolean;
          inspectedZ?: string;
          images?: Array<{ __typename?: 'Image'; key?: string }>;
        }>;
      }>;
      signatures?: Array<{
        __typename?: 'Signature';
        id?: string;
        signature: string;
        signerName: string;
        signerEntity: SignerEntity;
        isAgent?: boolean;
        agentRelationship?: string;
      }>;
      generatedSummary?: { __typename?: 'InspectionSummary'; summary?: string; score?: number };
    }>;
  };
};

export type CreateRequestMutationVariables = Exact<{
  input: CreateRequestInput;
}>;

export type CreateRequestMutation = {
  __typename?: 'Mutation';
  createRequest?: {
    __typename?: 'CreateRequestPayload';
    success?: boolean;
    error?: string;
    request?: {
      __typename?: 'Request';
      id: string;
      name?: string;
      summary?: string;
      category?: string;
      ownerApprovalId?: string;
      ownerApprovalStatus?: string;
      status?: string;
      cancelledReason?: string;
      delay?: string;
      originalRequest?: string;
      details?: string;
      appointmentZ?: string;
      submittedZ?: string;
      operatorName?: string;
      minutes?: number;
      instructions?: string;
      firstOccurred?: string;
      consents?: Array<string>;
      entryConsentDetails?: string;
      nonBillable?: boolean;
      printNotes?: boolean;
      autoSummary?: string;
      resolution?: string;
      finalizedName?: string;
      finalizedZ?: string;
      source?: string;
      taxExempted?: boolean;
      journalId?: string;
      approval?: {
        __typename?: 'MaintenanceApproval';
        id: string;
        status: ApprovalStatus;
        requestedZ: string;
        approvedZ?: string;
        declinedZ?: string;
        request: {
          __typename?: 'MaintenanceApprovalRequest';
          ownerId: string;
          amount: number;
          description?: string;
        };
      };
      fields?: Array<{ __typename?: 'Field'; label?: string; value?: string }>;
      visits?: Array<{
        __typename?: 'Visit';
        id?: string;
        status?: string;
        delay?: string;
        dueZ?: string;
        appointmentZ?: string;
        operatorId?: string;
        operatorName?: string;
        minutes?: number;
        tenantProposedTimes?: Array<string>;
        category?: string;
        instructions?: string;
        materialCosts?: number;
        materialCostsNote?: string;
        materialMarkup?: boolean;
        labourCost?: number;
        completedDelta?: number;
        completedZ?: string;
        journalId?: string;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          target?: string;
          source?: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        bill?: {
          __typename?: 'ContractorBill';
          date: string;
          totalAmount: number;
          ref?: string;
          due?: string;
          gstAmount?: number;
          key?: string;
        };
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      images?: Array<{
        __typename?: 'Image';
        key?: string;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
      }>;
      history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
      appointmentPreferences?: Array<{ __typename?: 'Interval'; start: string }>;
      operator?: {
        __typename?: 'Operator';
        id: string;
        name: string;
        location?: string;
        email?: string;
        phone?: string;
        availability?: Array<string>;
        operatorType?: OperatorType;
      };
      tenant?: { __typename?: 'RequestTenant'; id?: string; name?: string; phone?: string };
      unit?: {
        __typename?: 'Unit';
        id: string;
        name: string;
        legalAddress?: string;
        sqft: number;
        bedrooms: number;
        bathrooms: number;
        additionalInformation?: string;
        occupancy?: UnitOccupancy;
        parking?: Array<string>;
        storage?: Array<string>;
        keyring?: Array<string>;
        unitFactor?: number;
        condoFee?: number;
        condoFeeGst?: number;
        preferredPaymentMethod?: PaymentMethod;
        propertyId?: string;
        ownerId?: string;
        buildingId?: string;
        property?: {
          __typename?: 'Property';
          id: string;
          enabled?: boolean;
          name: string;
          phone?: string;
          imageKey?: string;
          timezone: string;
          ownerId?: string;
          storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
          parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
          keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
          owner?: {
            __typename?: 'Owner';
            id: string;
            name: string;
            email?: string;
            phones?: Array<string>;
            address?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
              country?: string;
              lat?: number;
              lng?: number;
            };
          };
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        };
        building?: {
          __typename?: 'Building';
          id: string;
          name: string;
          key?: string;
          imageKey?: string;
          timezone: string;
          phone?: string;
          ownerId?: string;
          types?: Array<PropertyType>;
          legalDescription?: string;
          address?: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            lat?: number;
            lng?: number;
          };
        };
        owner?: {
          __typename?: 'Owner';
          id: string;
          name: string;
          email?: string;
          phones?: Array<string>;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
        };
        condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        history?: Array<
          | {
              __typename?: 'Request';
              summary?: string;
              status?: string;
              delay?: string;
              id: string;
              name?: string;
              tenant?: { __typename?: 'RequestTenant'; name?: string };
              fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            }
          | {
              __typename?: 'RequestEvent';
              id: string;
              name?: string;
              fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
            }
        >;
      };
      costs?: Array<{
        __typename?: 'RequestCost';
        id: string;
        date: string;
        description: string;
        amount: number;
        markup: boolean;
      }>;
      checklist?: Array<{
        __typename?: 'ChecklistItem';
        id: string;
        name: string;
        completedZ?: string;
        order?: number;
      }>;
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string; createdSub?: string };
    };
  };
};

export type PropertyDataForRequestFragment = {
  __typename?: 'Property';
  id: string;
  name: string;
  ownerId?: string;
  phone?: string;
  timezone: string;
  key?: string;
  address: {
    __typename?: 'Address';
    street?: string;
    city?: string;
    postal?: string;
    province?: string;
    lat?: number;
    lng?: number;
  };
  account?: { __typename?: 'Account'; id: string; phone?: string };
};

export type UnitResidencyDataForRequestFragment = {
  __typename?: 'Unit';
  id: string;
  name: string;
  ownerId?: string;
  occupancy?: UnitOccupancy;
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    ownerId?: string;
    phone?: string;
    timezone: string;
    key?: string;
    address: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      postal?: string;
      province?: string;
      lat?: number;
      lng?: number;
    };
    account?: { __typename?: 'Account'; id: string; phone?: string };
  };
  currentResidencies?: Array<{
    __typename?: 'Residency';
    id: string;
    residents: Array<{
      __typename?: 'ResidentAssociation';
      id: string;
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      resident:
        | { __typename: 'Owner'; email?: string; phones?: Array<string>; id: string; name: string }
        | {
            __typename: 'Tenant';
            emails?: Array<string>;
            phones?: Array<string>;
            id: string;
            name: string;
          };
    }>;
  }>;
};

export type GetUnitWithResidencyForRequestQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUnitWithResidencyForRequestQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    ownerId?: string;
    occupancy?: UnitOccupancy;
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      ownerId?: string;
      phone?: string;
      timezone: string;
      key?: string;
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        postal?: string;
        province?: string;
        lat?: number;
        lng?: number;
      };
      account?: { __typename?: 'Account'; id: string; phone?: string };
    };
    currentResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        resident:
          | {
              __typename: 'Owner';
              email?: string;
              phones?: Array<string>;
              id: string;
              name: string;
            }
          | {
              __typename: 'Tenant';
              emails?: Array<string>;
              phones?: Array<string>;
              id: string;
              name: string;
            };
      }>;
    }>;
  };
};

export type RequestDocumentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RequestDocumentsQuery = {
  __typename?: 'Query';
  request?: {
    __typename?: 'Request';
    id: string;
    documents?: Array<{
      __typename?: 'Document';
      key: string;
      name?: string;
      size?: number;
      createdZ: string;
      typename?: string;
    }>;
  };
};

export type RenameRequestDocumentMutationVariables = Exact<{
  input: RenameRequestDocumentInput;
}>;

export type RenameRequestDocumentMutation = {
  __typename?: 'Mutation';
  renameRequestDocument: {
    __typename?: 'RequestPayload';
    request?: {
      __typename?: 'Request';
      id: string;
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ: string;
        typename?: string;
      }>;
    };
  };
};

export type CreateResidencyWizardPathQueryVariables = Exact<{
  unitId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
}>;

export type CreateResidencyWizardPathQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    legalAddress?: string;
    sqft: number;
    bedrooms: number;
    bathrooms: number;
    additionalInformation?: string;
    occupancy?: UnitOccupancy;
    parking?: Array<string>;
    storage?: Array<string>;
    keyring?: Array<string>;
    unitFactor?: number;
    condoFee?: number;
    condoFeeGst?: number;
    preferredPaymentMethod?: PaymentMethod;
    propertyId?: string;
    ownerId?: string;
    buildingId?: string;
    allResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          category: RefundableLedgerCategory;
          amount: number;
          date: string;
        }>;
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    }>;
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
  };
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    enabled?: boolean;
    ownerId?: string;
    autopayStatus?: PropertyAutopayStatus;
    autopayBankAccounts?: Array<{
      __typename?: 'PropertyBankAccount';
      id: string;
      collectionType: CollectionType;
    }>;
    address: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
  };
};

export type AddResidencyEventMutationVariables = Exact<{
  input: AddResidencyEventInput;
}>;

export type AddResidencyEventMutation = {
  __typename?: 'Mutation';
  addResidencyEvent: {
    __typename?: 'ResidencyPayload';
    success?: boolean;
    error?: string;
    residency?: {
      __typename?: 'Residency';
      id: string;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
    };
  };
};

export type UpdateResidencyEventMutationVariables = Exact<{
  input: UpdateResidencyEventInput;
}>;

export type UpdateResidencyEventMutation = {
  __typename?: 'Mutation';
  updateResidencyEvent: {
    __typename?: 'ResidencyPayload';
    success?: boolean;
    error?: string;
    residency?: {
      __typename?: 'Residency';
      id: string;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
    };
  };
};

export type DeleteResidencyEventMutationVariables = Exact<{
  input: DeleteResidencyEventInput;
}>;

export type DeleteResidencyEventMutation = {
  __typename?: 'Mutation';
  deleteResidencyEvent: {
    __typename?: 'ResidencyPayload';
    success?: boolean;
    error?: string;
    residency?: {
      __typename?: 'Residency';
      id: string;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
    };
  };
};

export type ResidencyPathQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
}>;

export type ResidencyPathQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    legalAddress?: string;
    sqft: number;
    bedrooms: number;
    bathrooms: number;
    additionalInformation?: string;
    occupancy?: UnitOccupancy;
    parking?: Array<string>;
    storage?: Array<string>;
    keyring?: Array<string>;
    unitFactor?: number;
    condoFee?: number;
    condoFeeGst?: number;
    preferredPaymentMethod?: PaymentMethod;
    propertyId?: string;
    ownerId?: string;
    buildingId?: string;
    allResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          category: RefundableLedgerCategory;
          amount: number;
          date: string;
        }>;
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    }>;
    residency?: {
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        settledZ?: string;
        collectedZ?: string;
        templateId: string;
        accrualStartExpr?: string;
        amount?: number;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          date: string;
          amount: number;
          category: RefundableLedgerCategory;
        }>;
      }>;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    };
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
  };
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    enabled?: boolean;
    ownerId?: string;
    address: { __typename?: 'Address'; province?: string };
  };
};

export type CurrentResidenciesPathQueryVariables = Exact<{
  unitId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
}>;

export type CurrentResidenciesPathQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    legalAddress?: string;
    sqft: number;
    bedrooms: number;
    bathrooms: number;
    additionalInformation?: string;
    occupancy?: UnitOccupancy;
    parking?: Array<string>;
    storage?: Array<string>;
    keyring?: Array<string>;
    unitFactor?: number;
    condoFee?: number;
    condoFeeGst?: number;
    preferredPaymentMethod?: PaymentMethod;
    propertyId?: string;
    ownerId?: string;
    buildingId?: string;
    allResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          category: RefundableLedgerCategory;
          amount: number;
          date: string;
        }>;
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    }>;
    currentResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          category: RefundableLedgerCategory;
          amount: number;
          date: string;
        }>;
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    }>;
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
  };
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    enabled?: boolean;
    ownerId?: string;
    address: { __typename?: 'Address'; province?: string };
  };
};

export type ResidencyProRatedEffectsQueryVariables = Exact<{
  input: ResidencyProRatedEffectsInput;
}>;

export type ResidencyProRatedEffectsQuery = {
  __typename?: 'Query';
  residencyProRatedEffects?: {
    __typename?: 'ResidencyProRatedEffectsPayload';
    residents?: Array<{
      __typename?: 'ResidencyProRatedEffect';
      name?: string;
      residentId: string;
      amount?: number;
      effects?: Array<{
        __typename?: 'ProRatedEffect';
        id: string;
        feeId?: string;
        effect: number;
        startZ?: string;
        description?: string;
        glId?: string;
      }>;
    }>;
  };
};

export type RenameResidencyDocumentMutationVariables = Exact<{
  input: RenameResidencyDocumentInput;
}>;

export type RenameResidencyDocumentMutation = {
  __typename?: 'Mutation';
  renameResidencyDocument: {
    __typename?: 'ResidencyPayload';
    residency?: {
      __typename?: 'Residency';
      id: string;
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ: string;
        typename?: string;
      }>;
    };
  };
};

export type ResidencyDocumentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ResidencyDocumentsQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    moveInInspectionId?: string;
    moveOutInspectionId?: string;
    documents?: Array<{
      __typename?: 'Document';
      key: string;
      name?: string;
      size?: number;
      createdZ: string;
      typename?: string;
    }>;
  };
};

export type ResidencyResidentDocumentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ResidencyResidentDocumentsQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    residentDocuments?: Array<{
      __typename?: 'ResidentDocument';
      residentId: string;
      sentZ?: string;
      entityId?: string;
      entityType?: string;
      document?: {
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ: string;
        typename?: string;
      };
    }>;
  };
};

export type ListAllResidentsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListAllResidentsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listResidents?: {
      __typename?: 'TenantConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Tenant';
        id: string;
        name: string;
        phones?: Array<string>;
        emails?: Array<string>;
        leaseHolder?: boolean;
        isOwner?: boolean;
        enabled?: boolean;
        preferredPaymentMethod?: PaymentMethod;
        padAmount?: number;
        padAccount?: string;
        padTransit?: string;
        padInstitution?: string;
        correspondenceMethod?: CorrespondenceMethod;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          relationship?: ContactRelationship;
          phone: string;
        }>;
      }>;
    };
  };
};

export type UpdateResidencyWizardPathQueryVariables = Exact<{
  unitId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  residencyId: Scalars['ID']['input'];
}>;

export type UpdateResidencyWizardPathQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    legalAddress?: string;
    sqft: number;
    bedrooms: number;
    bathrooms: number;
    additionalInformation?: string;
    occupancy?: UnitOccupancy;
    parking?: Array<string>;
    storage?: Array<string>;
    keyring?: Array<string>;
    unitFactor?: number;
    condoFee?: number;
    condoFeeGst?: number;
    preferredPaymentMethod?: PaymentMethod;
    propertyId?: string;
    ownerId?: string;
    buildingId?: string;
    residency?: {
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          category: RefundableLedgerCategory;
          amount: number;
          date: string;
        }>;
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    };
    allResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          category: RefundableLedgerCategory;
          amount: number;
          date: string;
        }>;
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    }>;
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
  };
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    enabled?: boolean;
    ownerId?: string;
    autopayStatus?: PropertyAutopayStatus;
    autopayBankAccounts?: Array<{
      __typename?: 'PropertyBankAccount';
      id: string;
      collectionType: CollectionType;
    }>;
    address: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
  };
};

export type LeaseFormFieldsFragment = {
  __typename?: 'LeaseForm';
  id: string;
  province: string;
  name: string;
  key: string;
  description?: string;
  bindings?: string;
  inputFields?: Array<{
    __typename?: 'LeaseFormInputField';
    id: string;
    label: string;
    subLabel?: string;
    type: LeaseFormFieldType;
    required?: boolean;
    defaultValue?: string;
    hiddenExpression?: string;
    optionsExpression?: string;
    helpText?: string;
    options?: Array<{ __typename?: 'LeaseFormInputFieldOption'; label: string; value: string }>;
  }>;
};

export type ResidencyOutstandingFieldsFragment = {
  __typename?: 'ResidencyOutstanding';
  totalOutstanding?: number;
  clearables?: Array<{
    __typename?: 'Clearable';
    id: string;
    posted: string;
    glId: string;
    payeeId: string;
    amount: number;
    outstanding: number;
    due: string;
  }>;
};

export type LeaseFormsByProvinceQueryVariables = Exact<{
  province?: InputMaybe<Scalars['String']['input']>;
}>;

export type LeaseFormsByProvinceQuery = {
  __typename?: 'Query';
  leaseForms?: Array<{
    __typename?: 'LeaseForm';
    id: string;
    province: string;
    name: string;
    key: string;
    description?: string;
    bindings?: string;
    inputFields?: Array<{
      __typename?: 'LeaseFormInputField';
      id: string;
      label: string;
      subLabel?: string;
      type: LeaseFormFieldType;
      required?: boolean;
      defaultValue?: string;
      hiddenExpression?: string;
      optionsExpression?: string;
      helpText?: string;
      options?: Array<{ __typename?: 'LeaseFormInputFieldOption'; label: string; value: string }>;
    }>;
  }>;
};

export type LeaseFormQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  province: Scalars['String']['input'];
}>;

export type LeaseFormQuery = {
  __typename?: 'Query';
  leaseForm?: {
    __typename?: 'LeaseForm';
    id: string;
    province: string;
    name: string;
    key: string;
    description?: string;
    bindings?: string;
    inputFields?: Array<{
      __typename?: 'LeaseFormInputField';
      id: string;
      label: string;
      subLabel?: string;
      type: LeaseFormFieldType;
      required?: boolean;
      defaultValue?: string;
      hiddenExpression?: string;
      optionsExpression?: string;
      helpText?: string;
      options?: Array<{ __typename?: 'LeaseFormInputFieldOption'; label: string; value: string }>;
    }>;
  };
};

export type LeaseFormResidencyDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  province: Scalars['String']['input'];
  residencyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
}>;

export type LeaseFormResidencyDataQuery = {
  __typename?: 'Query';
  leaseForm?: {
    __typename?: 'LeaseForm';
    id: string;
    province: string;
    name: string;
    key: string;
    description?: string;
    bindings?: string;
    inputFields?: Array<{
      __typename?: 'LeaseFormInputField';
      id: string;
      label: string;
      subLabel?: string;
      type: LeaseFormFieldType;
      required?: boolean;
      defaultValue?: string;
      hiddenExpression?: string;
      optionsExpression?: string;
      helpText?: string;
      options?: Array<{ __typename?: 'LeaseFormInputFieldOption'; label: string; value: string }>;
    }>;
  };
  residency?: {
    __typename?: 'Residency';
    id: string;
    accountId: string;
    proratedClearableIds?: Array<string>;
    moveInInspectionId?: string;
    moveOutInspectionId?: string;
    terms?: Array<string>;
    startZ: string;
    endZ?: string;
    renewZ?: string;
    securityDeposit?: number;
    gst?: number;
    type?: LeaseType;
    occupancy?: UnitOccupancy;
    unitId: string;
    propertyId: string;
    moveInDate?: string;
    moveOutDate?: string;
    moveOutReason?: MoveOutReason;
    applicationId?: string;
    campaignId?: string;
    rrule?: string;
    utilities?: Array<string>;
    furnishings?: Array<string>;
    collectionDay?: number;
    outstanding?: {
      __typename?: 'ResidencyOutstanding';
      totalOutstanding?: number;
      clearables?: Array<{
        __typename?: 'Clearable';
        id: string;
        posted: string;
        glId: string;
        payeeId: string;
        amount: number;
        outstanding: number;
        due: string;
      }>;
    };
    effects?: Array<
      | {
          __typename: 'IncentiveEffect';
          description?: string;
          incentiveId: string;
          id: string;
          feeId: string;
          startZ: string;
          effect: number;
        }
      | { __typename: 'ResidencyEffect'; id: string; feeId: string; startZ: string; effect: number }
    >;
    residents: Array<{
      __typename?: 'ResidentAssociation';
      id: string;
      residentId: string;
      leaseHolder?: boolean;
      title?: ResidencyResidentTitle;
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      paymentDetails?: {
        __typename?: 'PaymentDetails';
        preferredPaymentMethod?: PaymentMethod;
        padAmount?: number;
        padInstitution?: string;
        padTransit?: string;
        padAccount?: string;
      };
      resident:
        | {
            __typename: 'Owner';
            preferredPaymentMethod?: PaymentMethod;
            padAmount?: number;
            padInstitution?: string;
            padTransit?: string;
            padAccount?: string;
            id: string;
            name: string;
            emails?: Array<string>;
            phones?: Array<string>;
            correspondenceMethod?: CorrespondenceMethod;
            enabled?: boolean;
            emergencyContacts?: Array<{
              __typename?: 'EmergencyContact';
              name: string;
              relationship?: ContactRelationship;
              phone: string;
            }>;
          }
        | {
            __typename: 'Tenant';
            phone?: string;
            dob?: string;
            isRentalAssist?: boolean;
            id: string;
            name: string;
            emails?: Array<string>;
            phones?: Array<string>;
            correspondenceMethod?: CorrespondenceMethod;
            enabled?: boolean;
            vehicles?: Array<{
              __typename?: 'Vehicle';
              id: string;
              plate: string;
              make: string;
              model: string;
              color?: string;
            }>;
            rentalAssistance?: {
              __typename?: 'RentalAssistance';
              qualified?: boolean;
              amount?: number;
              source?: string;
            };
            pets?: Array<{
              __typename?: 'Pet';
              type: string;
              count: number;
              details?: Array<{
                __typename?: 'PetDetails';
                type?: string;
                breed?: string;
                weight?: string;
                name?: string;
                color?: string;
                license?: string;
                isService?: boolean;
              }>;
            }>;
            mailingAddress?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
              country?: string;
              lat?: number;
              lng?: number;
            };
            emergencyContacts?: Array<{
              __typename?: 'EmergencyContact';
              name: string;
              relationship?: ContactRelationship;
              phone: string;
            }>;
          };
    }>;
    refundables?: Array<{
      __typename?: 'Refundable';
      id: string;
      name: string;
      amount?: number;
      templateId: string;
      accrualStartExpr?: string;
      collectedZ?: string;
      heldByOwner?: boolean;
      ledger?: Array<{
        __typename?: 'RefundableLedger';
        id: string;
        category: RefundableLedgerCategory;
        amount: number;
        date: string;
      }>;
    }>;
    securityDepositHistory?: Array<{
      __typename?: 'SecurityDepositHistory';
      createdZ: string;
      allocations: Array<{
        __typename?: 'SecurityDepositAllocation';
        status: SecurityDepositStatus;
        amount: number;
      }>;
    }>;
    eventHistory?: Array<{
      __typename?: 'ResidencyEvent';
      id: string;
      name: string;
      category: ResidencyEventCategory;
      description?: string;
      eventDateZ: string;
      deletedZ?: string;
      resolvedZ?: string;
      noticeZ?: string;
      noteText?: string;
    }>;
    insurancePolicies?: Array<{
      __typename?: 'LeaseInsurancePolicy';
      id?: string;
      policyNumber?: string;
      providerName?: string;
      expirationDate?: string;
      liability?: number;
    }>;
    residentInsurancePolicies?: Array<{
      __typename?: 'IamTenantInsurancePolicy';
      id: string;
      policyNumber: string;
      providerName: string;
      liability?: number;
      expiry: string;
    }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    additionalProvisions?: {
      __typename?: 'AdditionalProvisions';
      pets?: number;
      parking?: boolean;
      storage?: boolean;
      smoking?: boolean;
    };
  };
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    legalAddress?: string;
    sqft: number;
    bedrooms: number;
    bathrooms: number;
    additionalInformation?: string;
    occupancy?: UnitOccupancy;
    parking?: Array<string>;
    storage?: Array<string>;
    keyring?: Array<string>;
    unitFactor?: number;
    condoFee?: number;
    condoFeeGst?: number;
    preferredPaymentMethod?: PaymentMethod;
    propertyId?: string;
    ownerId?: string;
    buildingId?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      enabled?: boolean;
      name: string;
      phone?: string;
      imageKey?: string;
      timezone: string;
      ownerId?: string;
      storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
      parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
      keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
    building?: {
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    };
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      email?: string;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
    };
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    history?: Array<
      | {
          __typename?: 'Request';
          summary?: string;
          status?: string;
          delay?: string;
          id: string;
          name?: string;
          tenant?: { __typename?: 'RequestTenant'; name?: string };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
      | {
          __typename?: 'RequestEvent';
          id: string;
          name?: string;
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
    >;
  };
  account?: {
    __typename?: 'Account';
    id: string;
    phone?: string;
    name: string;
    logoKey?: string;
    tier: number;
    autopayClientId?: string;
    autopayInstitution?: string;
    autopayTransit?: string;
    autopayAccount?: string;
    settings?: {
      __typename?: 'Settings';
      soon_days_threshold?: number;
      later_days_threshold?: number;
      resident_request_scheduling?: ResidentRequestScheduling;
      campaignApplicationRules?: {
        __typename?: 'CampaignApplicationRule';
        requiredDocs?: Array<{
          __typename?: 'CampaignApplicationDocRule';
          quantity: number;
          docTypes: Array<string>;
        }>;
      };
    };
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    fees?: Array<{ __typename?: 'Fee'; id: string; name: string; deletedZ?: string }>;
    books?: {
      __typename?: 'Books';
      accountId: string;
      trackManagementFeeRevenue?: boolean;
      billingRates?: { __typename?: 'BillingRate'; materialsMarkup?: number };
    };
  };
  manager?: {
    __typename?: 'Manager';
    id: string;
    name: string;
    email: string;
    phone?: string;
    roles: Array<ManagerRole>;
    enabled: boolean;
    accountId?: string;
    signature?: {
      __typename?: 'Signature';
      id?: string;
      signerId?: string;
      signature: string;
      signerName: string;
      signerEntity: SignerEntity;
      isAgent?: boolean;
      agentRelationship?: string;
    };
    subscriptions?: Array<{
      __typename?: 'MessageSubscription';
      id: string;
      endpoints: Array<{
        __typename?: 'SubscriptionEndpoint';
        id: SubscriptionEndpointType;
        address: string;
      }>;
    }>;
  };
};

export type LeaseFormResidencyPathQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
}>;

export type LeaseFormResidencyPathQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    occupancy?: UnitOccupancy;
    residency?: { __typename?: 'Residency'; id: string; startZ: string; endZ?: string };
  };
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    enabled?: boolean;
    address: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
  };
};

export type LeaseFormCurrentResidenciesPathQueryVariables = Exact<{
  unitId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
}>;

export type LeaseFormCurrentResidenciesPathQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    occupancy?: UnitOccupancy;
    currentResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      startZ: string;
      endZ?: string;
    }>;
  };
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    enabled?: boolean;
    address: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
  };
};

export type IssueDepositWizardPathQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
}>;

export type IssueDepositWizardPathQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    startZ: string;
    endZ?: string;
    occupancy?: UnitOccupancy;
    refundables?: Array<{
      __typename?: 'Refundable';
      id: string;
      name: string;
      amount?: number;
      simple?: boolean;
      templateId: string;
      accrualStartExpr?: string;
      ledger?: Array<{
        __typename?: 'RefundableLedger';
        id: string;
        date: string;
        amount: number;
        category: RefundableLedgerCategory;
      }>;
    }>;
  };
  unit?: { __typename?: 'Unit'; id: string; name: string };
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    timezone: string;
    address: { __typename?: 'Address'; province?: string };
  };
};

export type ResidencyAccrualsQueryQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
  accrualsInput: AccrualsInput;
}>;

export type ResidencyAccrualsQueryQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    accruals?: Array<{
      __typename?: 'RefundableLedger';
      id: string;
      date: string;
      amount: number;
      category: RefundableLedgerCategory;
    }>;
  };
};

export type GetAccountInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountInfoQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    name: string;
    logoKey?: string;
    address?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  };
};

export type GetResidencyQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
}>;

export type GetResidencyQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    startZ: string;
    endZ?: string;
    residents: Array<{
      __typename?: 'ResidentAssociation';
      id: string;
      leaseHolder?: boolean;
      resident:
        | { __typename: 'Owner'; id: string; name: string }
        | {
            __typename: 'Tenant';
            id: string;
            name: string;
            mailingAddress?: {
              __typename?: 'Address';
              suite?: string;
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
          };
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
    }>;
    unit?: { __typename?: 'Unit'; id: string; name: string };
    property?: { __typename?: 'Property'; id: string; name: string };
  };
};

export type ResidencyRefundablesPathQueryVariables = Exact<{
  residencyId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
}>;

export type ResidencyRefundablesPathQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    startZ: string;
    endZ?: string;
    occupancy?: UnitOccupancy;
    refundables?: Array<{
      __typename?: 'Refundable';
      id: string;
      name: string;
      amount?: number;
      templateId: string;
      accrualStartExpr?: string;
      collectedZ?: string;
      settledZ?: string;
      heldByOwner?: boolean;
      ledger?: Array<{
        __typename?: 'RefundableLedger';
        id: string;
        date: string;
        amount: number;
        category: RefundableLedgerCategory;
      }>;
    }>;
    effects?: Array<
      | {
          __typename?: 'IncentiveEffect';
          id: string;
          feeId: string;
          effect: number;
          startZ: string;
        }
      | {
          __typename?: 'ResidencyEffect';
          id: string;
          feeId: string;
          effect: number;
          startZ: string;
        }
    >;
  };
  unit?: { __typename?: 'Unit'; id: string; name: string };
  property?: {
    __typename?: 'Property';
    id: string;
    name: string;
    address: { __typename?: 'Address'; province?: string };
  };
};

export type UpdateResidencyRefundablesMutationVariables = Exact<{
  input: UpdateResidencyRefundableDepositsInput;
}>;

export type UpdateResidencyRefundablesMutation = {
  __typename?: 'Mutation';
  updateResidencyRefundableDeposits: {
    __typename?: 'ResidencyPayload';
    residency?: {
      __typename?: 'Residency';
      id: string;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          date: string;
          amount: number;
          category: RefundableLedgerCategory;
        }>;
      }>;
    };
  };
};

export type GetTenantsByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type GetTenantsByEmailQuery = {
  __typename?: 'Query';
  tenantsByEmail?: Array<{
    __typename?: 'Tenant';
    id: string;
    name: string;
    emails?: Array<string>;
    phone?: string;
    phones?: Array<string>;
    leaseHolder?: boolean;
    unitId?: string;
    propertyId?: string;
    type?: string;
    isOwner?: boolean;
    preferredPaymentMethod?: PaymentMethod;
    padAmount?: number;
    padInstitution?: string;
    padTransit?: string;
    padAccount?: string;
    correspondenceMethod?: CorrespondenceMethod;
    profileId?: string;
    enabled?: boolean;
    autopayClientId?: string;
    autopayInstitution?: string;
    autopayTransit?: string;
    autopayAccount?: string;
    autopayStatus?: string;
    autopayInvitedZ?: string;
    autopayCollectionsEnabled?: boolean;
    emergencyContacts?: Array<{
      __typename?: 'EmergencyContact';
      name: string;
      phone: string;
      relationship?: ContactRelationship;
    }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    pets?: Array<{
      __typename?: 'Pet';
      type: string;
      count: number;
      details?: Array<{
        __typename?: 'PetDetails';
        type?: string;
        breed?: string;
        weight?: string;
        name?: string;
        color?: string;
        license?: string;
        isService?: boolean;
      }>;
    }>;
    vehicles?: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      make: string;
      model: string;
      color?: string;
    }>;
  }>;
};

export type GetTenantsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenantsQuery = {
  __typename?: 'Query';
  tenants?: Array<{
    __typename?: 'Tenant';
    id: string;
    name: string;
    emails?: Array<string>;
    phone?: string;
    phones?: Array<string>;
    leaseHolder?: boolean;
    unitId?: string;
    propertyId?: string;
    type?: string;
    isOwner?: boolean;
    preferredPaymentMethod?: PaymentMethod;
    padAmount?: number;
    padInstitution?: string;
    padTransit?: string;
    padAccount?: string;
    correspondenceMethod?: CorrespondenceMethod;
    profileId?: string;
    enabled?: boolean;
    autopayClientId?: string;
    autopayInstitution?: string;
    autopayTransit?: string;
    autopayAccount?: string;
    autopayStatus?: string;
    autopayInvitedZ?: string;
    autopayCollectionsEnabled?: boolean;
    emergencyContacts?: Array<{
      __typename?: 'EmergencyContact';
      name: string;
      phone: string;
      relationship?: ContactRelationship;
    }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    pets?: Array<{
      __typename?: 'Pet';
      type: string;
      count: number;
      details?: Array<{
        __typename?: 'PetDetails';
        type?: string;
        breed?: string;
        weight?: string;
        name?: string;
        color?: string;
        license?: string;
        isService?: boolean;
      }>;
    }>;
    vehicles?: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      make: string;
      model: string;
      color?: string;
    }>;
  }>;
};

export type GetUnitQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUnitQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    legalAddress?: string;
    sqft: number;
    bedrooms: number;
    bathrooms: number;
    additionalInformation?: string;
    occupancy?: UnitOccupancy;
    parking?: Array<string>;
    storage?: Array<string>;
    keyring?: Array<string>;
    unitFactor?: number;
    condoFee?: number;
    condoFeeGst?: number;
    preferredPaymentMethod?: PaymentMethod;
    propertyId?: string;
    ownerId?: string;
    buildingId?: string;
    allResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          category: RefundableLedgerCategory;
          amount: number;
          date: string;
        }>;
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    }>;
    property?: {
      __typename?: 'Property';
      id: string;
      enabled?: boolean;
      name: string;
      phone?: string;
      imageKey?: string;
      timezone: string;
      ownerId?: string;
      storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
      parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
      keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
    building?: {
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    };
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      email?: string;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
    };
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    history?: Array<
      | {
          __typename?: 'Request';
          summary?: string;
          status?: string;
          delay?: string;
          id: string;
          name?: string;
          tenant?: { __typename?: 'RequestTenant'; name?: string };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
      | {
          __typename?: 'RequestEvent';
          id: string;
          name?: string;
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
    >;
  };
};

export type UnitPathQueryVariables = Exact<{
  unitId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
}>;

export type UnitPathQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    legalAddress?: string;
    sqft: number;
    bedrooms: number;
    bathrooms: number;
    additionalInformation?: string;
    occupancy?: UnitOccupancy;
    parking?: Array<string>;
    storage?: Array<string>;
    keyring?: Array<string>;
    unitFactor?: number;
    condoFee?: number;
    condoFeeGst?: number;
    preferredPaymentMethod?: PaymentMethod;
    propertyId?: string;
    ownerId?: string;
    buildingId?: string;
    allResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      accountId: string;
      proratedClearableIds?: Array<string>;
      moveInInspectionId?: string;
      moveOutInspectionId?: string;
      terms?: Array<string>;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      securityDeposit?: number;
      gst?: number;
      type?: LeaseType;
      occupancy?: UnitOccupancy;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      applicationId?: string;
      campaignId?: string;
      rrule?: string;
      utilities?: Array<string>;
      furnishings?: Array<string>;
      collectionDay?: number;
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        title?: ResidencyResidentTitle;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              dob?: string;
              isRentalAssist?: boolean;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
              correspondenceMethod?: CorrespondenceMethod;
              enabled?: boolean;
              vehicles?: Array<{
                __typename?: 'Vehicle';
                id: string;
                plate: string;
                make: string;
                model: string;
                color?: string;
              }>;
              rentalAssistance?: {
                __typename?: 'RentalAssistance';
                qualified?: boolean;
                amount?: number;
                source?: string;
              };
              pets?: Array<{
                __typename?: 'Pet';
                type: string;
                count: number;
                details?: Array<{
                  __typename?: 'PetDetails';
                  type?: string;
                  breed?: string;
                  weight?: string;
                  name?: string;
                  color?: string;
                  license?: string;
                  isService?: boolean;
                }>;
              }>;
              mailingAddress?: {
                __typename?: 'Address';
                suite?: string;
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
                country?: string;
                lat?: number;
                lng?: number;
              };
              emergencyContacts?: Array<{
                __typename?: 'EmergencyContact';
                name: string;
                relationship?: ContactRelationship;
                phone: string;
              }>;
            };
      }>;
      refundables?: Array<{
        __typename?: 'Refundable';
        id: string;
        name: string;
        amount?: number;
        templateId: string;
        accrualStartExpr?: string;
        collectedZ?: string;
        heldByOwner?: boolean;
        ledger?: Array<{
          __typename?: 'RefundableLedger';
          id: string;
          category: RefundableLedgerCategory;
          amount: number;
          date: string;
        }>;
      }>;
      securityDepositHistory?: Array<{
        __typename?: 'SecurityDepositHistory';
        createdZ: string;
        allocations: Array<{
          __typename?: 'SecurityDepositAllocation';
          status: SecurityDepositStatus;
          amount: number;
        }>;
      }>;
      eventHistory?: Array<{
        __typename?: 'ResidencyEvent';
        id: string;
        name: string;
        category: ResidencyEventCategory;
        description?: string;
        eventDateZ: string;
        deletedZ?: string;
        resolvedZ?: string;
        noticeZ?: string;
        noteText?: string;
      }>;
      insurancePolicies?: Array<{
        __typename?: 'LeaseInsurancePolicy';
        id?: string;
        policyNumber?: string;
        providerName?: string;
        expirationDate?: string;
        liability?: number;
      }>;
      residentInsurancePolicies?: Array<{
        __typename?: 'IamTenantInsurancePolicy';
        id: string;
        policyNumber: string;
        providerName: string;
        liability?: number;
        expiry: string;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    }>;
    property?: {
      __typename?: 'Property';
      id: string;
      enabled?: boolean;
      name: string;
      phone?: string;
      imageKey?: string;
      timezone: string;
      ownerId?: string;
      storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
      parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
      keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
    building?: {
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      imageKey?: string;
      timezone: string;
      phone?: string;
      ownerId?: string;
      types?: Array<PropertyType>;
      legalDescription?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    };
    owner?: {
      __typename?: 'Owner';
      id: string;
      name: string;
      email?: string;
      phones?: Array<string>;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        country?: string;
        lat?: number;
        lng?: number;
      };
    };
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    history?: Array<
      | {
          __typename?: 'Request';
          summary?: string;
          status?: string;
          delay?: string;
          id: string;
          name?: string;
          tenant?: { __typename?: 'RequestTenant'; name?: string };
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
      | {
          __typename?: 'RequestEvent';
          id: string;
          name?: string;
          fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
        }
    >;
  };
  property?: {
    __typename?: 'Property';
    id: string;
    enabled?: boolean;
    name: string;
    phone?: string;
    imageKey?: string;
    timezone: string;
    ownerId?: string;
    storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
    parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
    owner?: { __typename?: 'Owner'; id: string; name: string };
    address: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  };
};

export type UnitDocumentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UnitDocumentsQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'Unit';
    id: string;
    documents?: Array<{
      __typename?: 'Document';
      key: string;
      name?: string;
      size?: number;
      createdZ: string;
      typename?: string;
    }>;
  };
};

export type UpdateUnitMutationVariables = Exact<{
  input: UpdateUnitInput;
}>;

export type UpdateUnitMutation = {
  __typename?: 'Mutation';
  updateUnit?: {
    __typename?: 'UnitPayload';
    unit?: {
      __typename?: 'Unit';
      id: string;
      name: string;
      legalAddress?: string;
      sqft: number;
      bedrooms: number;
      bathrooms: number;
      additionalInformation?: string;
      occupancy?: UnitOccupancy;
      parking?: Array<string>;
      storage?: Array<string>;
      keyring?: Array<string>;
      unitFactor?: number;
      condoFee?: number;
      condoFeeGst?: number;
      preferredPaymentMethod?: PaymentMethod;
      propertyId?: string;
      ownerId?: string;
      buildingId?: string;
      property?: {
        __typename?: 'Property';
        id: string;
        enabled?: boolean;
        name: string;
        phone?: string;
        imageKey?: string;
        timezone: string;
        ownerId?: string;
        storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
        parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
        keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
        owner?: {
          __typename?: 'Owner';
          id: string;
          name: string;
          email?: string;
          phones?: Array<string>;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
        };
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      };
      building?: {
        __typename?: 'Building';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        timezone: string;
        phone?: string;
        ownerId?: string;
        types?: Array<PropertyType>;
        legalDescription?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          lat?: number;
          lng?: number;
        };
      };
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      history?: Array<
        | {
            __typename?: 'Request';
            summary?: string;
            status?: string;
            delay?: string;
            id: string;
            name?: string;
            tenant?: { __typename?: 'RequestTenant'; name?: string };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
        | {
            __typename?: 'RequestEvent';
            id: string;
            name?: string;
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
      >;
    };
  };
};

export type RenameUnitDocumentMutationVariables = Exact<{
  input: RenameUnitDocumentInput;
}>;

export type RenameUnitDocumentMutation = {
  __typename?: 'Mutation';
  renameUnitDocument: {
    __typename?: 'UnitPayload';
    unit?: {
      __typename?: 'Unit';
      id: string;
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ: string;
        typename?: string;
      }>;
    };
  };
};

export type AddUnitMutationVariables = Exact<{
  input: CreateUnitOnPropertyInput;
}>;

export type AddUnitMutation = {
  __typename?: 'Mutation';
  createUnitOnProperty?: {
    __typename?: 'UnitPayload';
    unit?: {
      __typename?: 'Unit';
      id: string;
      name: string;
      legalAddress?: string;
      sqft: number;
      bedrooms: number;
      bathrooms: number;
      additionalInformation?: string;
      occupancy?: UnitOccupancy;
      parking?: Array<string>;
      storage?: Array<string>;
      keyring?: Array<string>;
      unitFactor?: number;
      condoFee?: number;
      condoFeeGst?: number;
      preferredPaymentMethod?: PaymentMethod;
      propertyId?: string;
      ownerId?: string;
      buildingId?: string;
      property?: {
        __typename?: 'Property';
        id: string;
        enabled?: boolean;
        name: string;
        phone?: string;
        imageKey?: string;
        timezone: string;
        ownerId?: string;
        storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
        parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
        keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
        owner?: {
          __typename?: 'Owner';
          id: string;
          name: string;
          email?: string;
          phones?: Array<string>;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
        };
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      };
      building?: {
        __typename?: 'Building';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        timezone: string;
        phone?: string;
        ownerId?: string;
        types?: Array<PropertyType>;
        legalDescription?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          lat?: number;
          lng?: number;
        };
      };
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      history?: Array<
        | {
            __typename?: 'Request';
            summary?: string;
            status?: string;
            delay?: string;
            id: string;
            name?: string;
            tenant?: { __typename?: 'RequestTenant'; name?: string };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
        | {
            __typename?: 'RequestEvent';
            id: string;
            name?: string;
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
      >;
    };
  };
};

export type AddRequestImagesMutationVariables = Exact<{
  input: RequestImagesInput;
}>;

export type AddRequestImagesMutation = {
  __typename?: 'Mutation';
  addRequestImages: {
    __typename?: 'RequestPayload';
    success?: boolean;
    request?: {
      __typename?: 'Request';
      id: string;
      images?: Array<{
        __typename?: 'Image';
        key?: string;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
      }>;
    };
  };
};

export type DeleteRequestImageMutationVariables = Exact<{
  input: DeleteRequestImageInput;
}>;

export type DeleteRequestImageMutation = {
  __typename?: 'Mutation';
  deleteRequestImage: {
    __typename?: 'RequestPayload';
    success?: boolean;
    request?: {
      __typename?: 'Request';
      id: string;
      images?: Array<{
        __typename?: 'Image';
        key?: string;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
      }>;
    };
  };
};

export type UpdateRequestChecklistItemMutationVariables = Exact<{
  input: UpdateRequestChecklistItemInput;
}>;

export type UpdateRequestChecklistItemMutation = {
  __typename?: 'Mutation';
  updateRequestChecklistItem?: {
    __typename?: 'RequestPayload';
    success?: boolean;
    error?: string;
    request?: {
      __typename?: 'Request';
      id: string;
      checklist?: Array<{
        __typename?: 'ChecklistItem';
        id: string;
        name: string;
        completedZ?: string;
        order?: number;
        lastUpdatedZ?: string;
      }>;
    };
  };
};

export type CreateRequestCostMutationVariables = Exact<{
  input: CreateRequestCostInput;
}>;

export type CreateRequestCostMutation = {
  __typename?: 'Mutation';
  createRequestCost?: {
    __typename?: 'CreateRequestCostPayload';
    success?: boolean;
    error?: string;
    newCostId?: string;
    request?: {
      __typename?: 'Request';
      id: string;
      costs?: Array<{ __typename?: 'RequestCost'; id: string; markup: boolean }>;
      costLines?: Array<{
        __typename?: 'CostLine';
        id: string;
        date?: string;
        amount: number;
        markup?: number;
        description?: string;
        receiptKey?: string;
        total: number;
        receiptImages?: Array<{ __typename?: 'Image'; key?: string }>;
      }>;
    };
  };
};

export type UpdateRequestCostMutationVariables = Exact<{
  input: UpdateRequestCostInput;
}>;

export type UpdateRequestCostMutation = {
  __typename?: 'Mutation';
  updateRequestCost?: {
    __typename?: 'RequestPayload';
    success?: boolean;
    error?: string;
    request?: {
      __typename?: 'Request';
      id: string;
      costs?: Array<{ __typename?: 'RequestCost'; id: string; markup: boolean }>;
      costLines?: Array<{
        __typename?: 'CostLine';
        id: string;
        date?: string;
        amount: number;
        markup?: number;
        description?: string;
        receiptKey?: string;
        total: number;
        receiptImages?: Array<{ __typename?: 'Image'; key?: string }>;
      }>;
    };
  };
};

export type DeleteRequestCostMutationVariables = Exact<{
  input: DeleteRequestCostInput;
}>;

export type DeleteRequestCostMutation = {
  __typename?: 'Mutation';
  deleteRequestCost?: {
    __typename?: 'RequestPayload';
    success?: boolean;
    error?: string;
    request?: {
      __typename?: 'Request';
      id: string;
      costs?: Array<{ __typename?: 'RequestCost'; id: string; markup: boolean }>;
      costLines?: Array<{
        __typename?: 'CostLine';
        id: string;
        date?: string;
        amount: number;
        markup?: number;
        description?: string;
        receiptKey?: string;
        total: number;
        receiptImages?: Array<{ __typename?: 'Image'; key?: string }>;
      }>;
    };
  };
};

export type RefreshRequestImagesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RefreshRequestImagesMutation = {
  __typename?: 'Mutation';
  refreshRequestImages?: {
    __typename?: 'RequestPayload';
    success?: boolean;
    error?: string;
    request?: {
      __typename?: 'Request';
      id: string;
      images?: Array<{ __typename?: 'Image'; key?: string }>;
    };
  };
};

export type RequestBillingRatesQueryVariables = Exact<{ [key: string]: never }>;

export type RequestBillingRatesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    books?: {
      __typename?: 'Books';
      accountId: string;
      billingRates?: { __typename?: 'BillingRate'; materialsMarkup?: number };
      customBillingRates?: Array<{
        __typename?: 'BillingRate';
        category?: string;
        materialsMarkup?: number;
      }>;
    };
  };
};

export type DeleteVisitBillMutationVariables = Exact<{
  input: DeleteVisitBillInput;
}>;

export type DeleteVisitBillMutation = {
  __typename?: 'Mutation';
  deleteVisitBill?: {
    __typename?: 'UpdateVisitPayload';
    success?: boolean;
    error?: string;
    visit?: {
      __typename?: 'Visit';
      id?: string;
      bill?: {
        __typename?: 'ContractorBill';
        date: string;
        totalAmount: number;
        ref?: string;
        due?: string;
        gstAmount?: number;
        key?: string;
      };
    };
  };
};

export type CreateMaintenanceApprovalMutationVariables = Exact<{
  input: CreateMaintenanceApprovalInput;
}>;

export type CreateMaintenanceApprovalMutation = {
  __typename?: 'Mutation';
  createMaintenanceApproval?: {
    __typename?: 'MaintenanceApprovalPayload';
    success?: boolean;
    error?: string;
    request?: {
      __typename?: 'Request';
      id: string;
      ownerApprovalId?: string;
      ownerApprovalStatus?: string;
      unit?: {
        __typename?: 'Unit';
        owner?: { __typename?: 'Owner'; id: string; name: string };
        property?: {
          __typename?: 'Property';
          owner?: { __typename?: 'Owner'; id: string; name: string };
        };
      };
      approval?: {
        __typename?: 'MaintenanceApproval';
        id: string;
        status: ApprovalStatus;
        approvedZ?: string;
        approvedSub?: string;
        declinedZ?: string;
        declinedSub?: string;
      };
    };
  };
};

export type GetManagerQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetManagerQuery = {
  __typename?: 'Query';
  manager?: {
    __typename?: 'Manager';
    id: string;
    name: string;
    email: string;
    phone?: string;
    roles: Array<ManagerRole>;
    enabled: boolean;
    accountId?: string;
    signature?: {
      __typename?: 'Signature';
      id?: string;
      signerId?: string;
      signature: string;
      signerName: string;
      signerEntity: SignerEntity;
      isAgent?: boolean;
      agentRelationship?: string;
    };
    subscriptions?: Array<{
      __typename?: 'MessageSubscription';
      id: string;
      endpoints: Array<{
        __typename?: 'SubscriptionEndpoint';
        id: SubscriptionEndpointType;
        address: string;
      }>;
    }>;
  };
};

export type UpdateRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateRequestInput;
}>;

export type UpdateRequestMutation = {
  __typename?: 'Mutation';
  updateRequest?: {
    __typename?: 'Request';
    id: string;
    operatorName?: string;
    appointmentZ?: string;
    status?: string;
    instructions?: string;
    summary?: string;
    originalRequest?: string;
    nonBillable?: boolean;
    printNotes?: boolean;
    resolution?: string;
    taxExempted?: boolean;
  };
};

export type FinalizeRequestMutationVariables = Exact<{
  input: FinalizeRequestInput;
}>;

export type FinalizeRequestMutation = {
  __typename?: 'Mutation';
  finalizeRequest?: {
    __typename?: 'FinalizeRequestPayload';
    success?: boolean;
    error?: string;
    request?: {
      __typename?: 'Request';
      id: string;
      finalizedName?: string;
      status?: string;
      taxExempted?: boolean;
      cancelledReason?: string;
      visits?: Array<{
        __typename?: 'Visit';
        id?: string;
        status?: string;
        labourCost?: number;
        materialCosts?: number;
        materialCostsNote?: string;
        materialMarkup?: boolean;
      }>;
    };
  };
};

export type GetOperatorWindowsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  duration: Scalars['Int']['input'];
}>;

export type GetOperatorWindowsQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'Property';
    id: string;
    operatorWindows?: Array<{
      __typename?: 'OperatorWindow';
      window?: Array<string>;
      operator?: {
        __typename?: 'Operator';
        id: string;
        name: string;
        skills?: Array<OperatorSkill>;
        operatorType?: OperatorType;
      };
    }>;
  };
};

export type ConfirmAppointmentMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
  input: UpdateRequestInput;
}>;

export type ConfirmAppointmentMutation = {
  __typename?: 'Mutation';
  updateRequest?: {
    __typename?: 'Request';
    id: string;
    appointmentZ?: string;
    status?: string;
    delay?: string;
    operator?: { __typename?: 'Operator'; id: string };
  };
};

export type UpdateVisitMutationVariables = Exact<{
  input: UpdateVisitInput;
}>;

export type UpdateVisitMutation = {
  __typename?: 'Mutation';
  updateRequestVisit?: {
    __typename?: 'UpdateVisitPayload';
    error?: string;
    success?: boolean;
    visit?: {
      __typename?: 'Visit';
      id?: string;
      status?: string;
      delay?: string;
      dueZ?: string;
      appointmentZ?: string;
      operatorId?: string;
      operatorName?: string;
      minutes?: number;
      tenantProposedTimes?: Array<string>;
      category?: string;
      instructions?: string;
      materialCosts?: number;
      materialCostsNote?: string;
      materialMarkup?: boolean;
      labourCost?: number;
      completedDelta?: number;
      completedZ?: string;
      journalId?: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      bill?: {
        __typename?: 'ContractorBill';
        date: string;
        totalAmount: number;
        ref?: string;
        due?: string;
        gstAmount?: number;
        key?: string;
      };
    };
  };
};

export type ScheduleVisitMutationVariables = Exact<{
  input: UpdateVisitInput;
}>;

export type ScheduleVisitMutation = {
  __typename?: 'Mutation';
  scheduleRequestVisit?: {
    __typename?: 'UpdateVisitPayload';
    error?: string;
    success?: boolean;
    visit?: {
      __typename?: 'Visit';
      id?: string;
      status?: string;
      delay?: string;
      dueZ?: string;
      appointmentZ?: string;
      operatorId?: string;
      operatorName?: string;
      minutes?: number;
      tenantProposedTimes?: Array<string>;
      category?: string;
      instructions?: string;
      materialCosts?: number;
      materialCostsNote?: string;
      materialMarkup?: boolean;
      labourCost?: number;
      completedDelta?: number;
      completedZ?: string;
      journalId?: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      bill?: {
        __typename?: 'ContractorBill';
        date: string;
        totalAmount: number;
        ref?: string;
        due?: string;
        gstAmount?: number;
        key?: string;
      };
    };
  };
};

export type AssignVisitMutationVariables = Exact<{
  input: UpdateVisitInput;
}>;

export type AssignVisitMutation = {
  __typename?: 'Mutation';
  assignRequestVisit?: {
    __typename?: 'UpdateVisitPayload';
    error?: string;
    success?: boolean;
    visit?: {
      __typename?: 'Visit';
      id?: string;
      status?: string;
      delay?: string;
      dueZ?: string;
      appointmentZ?: string;
      operatorId?: string;
      operatorName?: string;
      minutes?: number;
      tenantProposedTimes?: Array<string>;
      category?: string;
      instructions?: string;
      materialCosts?: number;
      materialCostsNote?: string;
      materialMarkup?: boolean;
      labourCost?: number;
      completedDelta?: number;
      completedZ?: string;
      journalId?: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      bill?: {
        __typename?: 'ContractorBill';
        date: string;
        totalAmount: number;
        ref?: string;
        due?: string;
        gstAmount?: number;
        key?: string;
      };
    };
  };
};

export type FinalizeVisitMutationVariables = Exact<{
  input: UpdateVisitInput;
}>;

export type FinalizeVisitMutation = {
  __typename?: 'Mutation';
  finalizeRequestVisit?: {
    __typename?: 'UpdateVisitPayload';
    error?: string;
    success?: boolean;
    visit?: {
      __typename?: 'Visit';
      id?: string;
      status?: string;
      delay?: string;
      dueZ?: string;
      appointmentZ?: string;
      operatorId?: string;
      operatorName?: string;
      minutes?: number;
      tenantProposedTimes?: Array<string>;
      category?: string;
      instructions?: string;
      materialCosts?: number;
      materialCostsNote?: string;
      materialMarkup?: boolean;
      labourCost?: number;
      completedDelta?: number;
      completedZ?: string;
      journalId?: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      bill?: {
        __typename?: 'ContractorBill';
        date: string;
        totalAmount: number;
        ref?: string;
        due?: string;
        gstAmount?: number;
        key?: string;
      };
    };
  };
};

export type CreateVisitMutationVariables = Exact<{
  input: CreateVisitInput;
}>;

export type CreateVisitMutation = {
  __typename?: 'Mutation';
  createRequestVisit?: {
    __typename?: 'CreateVisitPayload';
    request?: {
      __typename?: 'Request';
      id: string;
      visits?: Array<{
        __typename?: 'Visit';
        id?: string;
        status?: string;
        delay?: string;
        dueZ?: string;
        appointmentZ?: string;
        operatorId?: string;
        operatorName?: string;
        minutes?: number;
        tenantProposedTimes?: Array<string>;
        category?: string;
        instructions?: string;
        materialCosts?: number;
        materialCostsNote?: string;
        materialMarkup?: boolean;
        labourCost?: number;
        completedDelta?: number;
        completedZ?: string;
        journalId?: string;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          target?: string;
          source?: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        bill?: {
          __typename?: 'ContractorBill';
          date: string;
          totalAmount: number;
          ref?: string;
          due?: string;
          gstAmount?: number;
          key?: string;
        };
      }>;
    };
  };
};

export type GetRequestQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetRequestQuery = {
  __typename?: 'Query';
  request?: {
    __typename?: 'Request';
    id: string;
    name?: string;
    summary?: string;
    category?: string;
    ownerApprovalId?: string;
    ownerApprovalStatus?: string;
    status?: string;
    cancelledReason?: string;
    delay?: string;
    originalRequest?: string;
    details?: string;
    appointmentZ?: string;
    submittedZ?: string;
    operatorName?: string;
    minutes?: number;
    instructions?: string;
    firstOccurred?: string;
    consents?: Array<string>;
    entryConsentDetails?: string;
    nonBillable?: boolean;
    printNotes?: boolean;
    autoSummary?: string;
    resolution?: string;
    finalizedName?: string;
    finalizedZ?: string;
    source?: string;
    taxExempted?: boolean;
    journalId?: string;
    approval?: {
      __typename?: 'MaintenanceApproval';
      id: string;
      status: ApprovalStatus;
      requestedZ: string;
      approvedZ?: string;
      declinedZ?: string;
      request: {
        __typename?: 'MaintenanceApprovalRequest';
        ownerId: string;
        amount: number;
        description?: string;
      };
    };
    fields?: Array<{ __typename?: 'Field'; label?: string; value?: string }>;
    visits?: Array<{
      __typename?: 'Visit';
      id?: string;
      status?: string;
      delay?: string;
      dueZ?: string;
      appointmentZ?: string;
      operatorId?: string;
      operatorName?: string;
      minutes?: number;
      tenantProposedTimes?: Array<string>;
      category?: string;
      instructions?: string;
      materialCosts?: number;
      materialCostsNote?: string;
      materialMarkup?: boolean;
      labourCost?: number;
      completedDelta?: number;
      completedZ?: string;
      journalId?: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        target?: string;
        source?: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      bill?: {
        __typename?: 'ContractorBill';
        date: string;
        totalAmount: number;
        ref?: string;
        due?: string;
        gstAmount?: number;
        key?: string;
      };
    }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      target?: string;
      source?: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    images?: Array<{
      __typename?: 'Image';
      key?: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    }>;
    history?: Array<{ __typename?: 'StatusHistoryEvent'; status?: string; createdZ?: string }>;
    appointmentPreferences?: Array<{ __typename?: 'Interval'; start: string }>;
    operator?: {
      __typename?: 'Operator';
      id: string;
      name: string;
      location?: string;
      email?: string;
      phone?: string;
      availability?: Array<string>;
      operatorType?: OperatorType;
    };
    tenant?: { __typename?: 'RequestTenant'; id?: string; name?: string; phone?: string };
    unit?: {
      __typename?: 'Unit';
      id: string;
      name: string;
      legalAddress?: string;
      sqft: number;
      bedrooms: number;
      bathrooms: number;
      additionalInformation?: string;
      occupancy?: UnitOccupancy;
      parking?: Array<string>;
      storage?: Array<string>;
      keyring?: Array<string>;
      unitFactor?: number;
      condoFee?: number;
      condoFeeGst?: number;
      preferredPaymentMethod?: PaymentMethod;
      propertyId?: string;
      ownerId?: string;
      buildingId?: string;
      property?: {
        __typename?: 'Property';
        id: string;
        enabled?: boolean;
        name: string;
        phone?: string;
        imageKey?: string;
        timezone: string;
        ownerId?: string;
        storage?: Array<{ __typename?: 'StorageLocker'; name: string; unitId?: string }>;
        parking?: Array<{ __typename?: 'ParkingStall'; name: string; unitId?: string }>;
        keyring?: Array<{ __typename?: 'Keyring'; name: string; unitId?: string }>;
        owner?: {
          __typename?: 'Owner';
          id: string;
          name: string;
          email?: string;
          phones?: Array<string>;
          address?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
        };
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      };
      building?: {
        __typename?: 'Building';
        id: string;
        name: string;
        key?: string;
        imageKey?: string;
        timezone: string;
        phone?: string;
        ownerId?: string;
        types?: Array<PropertyType>;
        legalDescription?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          lat?: number;
          lng?: number;
        };
      };
      owner?: {
        __typename?: 'Owner';
        id: string;
        name: string;
        email?: string;
        phones?: Array<string>;
        address?: {
          __typename?: 'Address';
          suite?: string;
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          country?: string;
          lat?: number;
          lng?: number;
        };
      };
      condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      history?: Array<
        | {
            __typename?: 'Request';
            summary?: string;
            status?: string;
            delay?: string;
            id: string;
            name?: string;
            tenant?: { __typename?: 'RequestTenant'; name?: string };
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
        | {
            __typename?: 'RequestEvent';
            id: string;
            name?: string;
            fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
          }
      >;
    };
    costs?: Array<{
      __typename?: 'RequestCost';
      id: string;
      date: string;
      description: string;
      amount: number;
      markup: boolean;
    }>;
    checklist?: Array<{
      __typename?: 'ChecklistItem';
      id: string;
      name: string;
      completedZ?: string;
      order?: number;
    }>;
    fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string; createdSub?: string };
  };
};

export type GetRequestCostsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetRequestCostsQuery = {
  __typename?: 'Query';
  request?: {
    __typename?: 'Request';
    id: string;
    costLines?: Array<{
      __typename?: 'CostLine';
      id: string;
      date?: string;
      visitNumber?: number;
      amount: number;
      markup?: number;
      description?: string;
      receiptKey?: string;
      total: number;
      receiptImages?: Array<{ __typename?: 'Image'; key?: string }>;
    }>;
  };
};

export type UpdateRequestRecurrenceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateRequestRecurrenceInput;
}>;

export type UpdateRequestRecurrenceMutation = {
  __typename?: 'Mutation';
  updateRequestRecurrence?: {
    __typename?: 'RequestRecurrencePayload';
    error?: string;
    success?: boolean;
    requestRecurrence?: {
      __typename?: 'RequestRecurrence';
      id: string;
      rrule: string;
      summary?: string;
      name?: string;
      category?: string;
      originalRequest?: string;
      details?: string;
      instructions?: string;
      minutes?: number;
      nextRecurrenceZ?: string;
    };
  };
};

export type DeleteRequestRecurrenceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteRequestRecurrenceMutation = {
  __typename?: 'Mutation';
  deleteRequestRecurrence?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type GetRequestRecurrenceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetRequestRecurrenceQuery = {
  __typename?: 'Query';
  requestRecurrence?: {
    __typename?: 'RequestRecurrence';
    id: string;
    accountId: string;
    rrule: string;
    name?: string;
    category?: string;
    subcategory?: string;
    originalRequest?: string;
    details?: string;
    instructions?: string;
    minutes?: number;
    summary?: string;
    nonBillable?: boolean;
    nextRecurrenceZ?: string;
    unit?: {
      __typename?: 'Unit';
      id: string;
      name: string;
      occupancy?: UnitOccupancy;
      ownerId?: string;
      propertyId?: string;
      property?: {
        __typename?: 'Property';
        id: string;
        name: string;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      };
    };
    latestRequest?: {
      __typename?: 'Request';
      id: string;
      status?: string;
      resolution?: string;
      autoSummary?: string;
      summary?: string;
      submittedZ?: string;
      details?: string;
      originalRequest?: string;
      history?: Array<{ __typename?: 'StatusHistoryEvent'; createdZ?: string; status?: string }>;
    };
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    checklist?: Array<{ __typename?: 'ChecklistItem'; id: string; name: string; order?: number }>;
  };
};

export type RequestRecurrenceFragment = {
  __typename?: 'RequestRecurrence';
  id: string;
  accountId: string;
  rrule: string;
  name?: string;
  category?: string;
  subcategory?: string;
  originalRequest?: string;
  details?: string;
  instructions?: string;
  minutes?: number;
  summary?: string;
  nonBillable?: boolean;
  nextRecurrenceZ?: string;
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    occupancy?: UnitOccupancy;
    ownerId?: string;
    propertyId?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
  };
  latestRequest?: {
    __typename?: 'Request';
    id: string;
    status?: string;
    resolution?: string;
    autoSummary?: string;
    summary?: string;
    submittedZ?: string;
    details?: string;
    originalRequest?: string;
    history?: Array<{ __typename?: 'StatusHistoryEvent'; createdZ?: string; status?: string }>;
  };
  notes?: Array<{
    __typename?: 'Note';
    id: string;
    createdName?: string;
    createdSub?: string;
    createdZ?: string;
    text?: string;
  }>;
  checklist?: Array<{ __typename?: 'ChecklistItem'; id: string; name: string; order?: number }>;
};

export type GetRequestRecurrencesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetRequestRecurrencesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listRequestRecurrences?: {
      __typename?: 'RequestRecurrenceConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'RequestRecurrence';
        id: string;
        accountId: string;
        rrule: string;
        name?: string;
        category?: string;
        subcategory?: string;
        originalRequest?: string;
        details?: string;
        instructions?: string;
        minutes?: number;
        summary?: string;
        nonBillable?: boolean;
        nextRecurrenceZ?: string;
        unit?: {
          __typename?: 'Unit';
          id: string;
          name: string;
          occupancy?: UnitOccupancy;
          ownerId?: string;
          propertyId?: string;
          property?: {
            __typename?: 'Property';
            id: string;
            name: string;
            address: {
              __typename?: 'Address';
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
          };
        };
        latestRequest?: {
          __typename?: 'Request';
          id: string;
          status?: string;
          resolution?: string;
          autoSummary?: string;
          summary?: string;
          submittedZ?: string;
          details?: string;
          originalRequest?: string;
          history?: Array<{
            __typename?: 'StatusHistoryEvent';
            createdZ?: string;
            status?: string;
          }>;
        };
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        checklist?: Array<{
          __typename?: 'ChecklistItem';
          id: string;
          name: string;
          order?: number;
        }>;
      }>;
    };
  };
};

export type ServerRequestsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<RequestsFilterInput>;
  sort?: InputMaybe<Array<RequestsSortInput> | RequestsSortInput>;
}>;

export type ServerRequestsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageRequests?: {
      __typename?: 'RequestsPage';
      totalCount: number;
      pageInfo: { __typename?: 'RelayPageInfo'; endCursor?: string; hasNextPage: boolean };
      edges: Array<{
        __typename?: 'RequestEdge';
        cursor: string;
        node: {
          __typename?: 'Request';
          id: string;
          name?: string;
          summary?: string;
          category?: string;
          status?: string;
          delay?: string;
          ownerId?: string;
          ownerApprovalId?: string;
          ownerApprovalStatus?: string;
          finalizedZ?: string;
          openedZ: string;
          tenantId?: string;
          tenantName?: string;
          unitId?: string;
          unitName?: string;
          unitOccupancy?: UnitOccupancy;
          buildingId?: string;
          buildingKey?: string;
          buildingName?: string;
          propertyId?: string;
          propertyKey?: string;
          propertyName?: string;
          occupant?: { __typename?: 'RequestTenant'; name?: string };
          visits?: Array<{
            __typename?: 'Visit';
            id?: string;
            status?: string;
            delay?: string;
            dueZ?: string;
            appointmentZ?: string;
            operatorId?: string;
            operatorName?: string;
            minutes?: number;
            tenantProposedTimes?: Array<string>;
            category?: string;
            instructions?: string;
            materialCosts?: number;
            materialCostsNote?: string;
            materialMarkup?: boolean;
            labourCost?: number;
            completedDelta?: number;
            completedZ?: string;
            journalId?: string;
            notes?: Array<{
              __typename?: 'Note';
              id: string;
              target?: string;
              source?: string;
              createdName?: string;
              createdSub?: string;
              createdZ?: string;
              text?: string;
            }>;
            bill?: {
              __typename?: 'ContractorBill';
              date: string;
              totalAmount: number;
              ref?: string;
              due?: string;
              gstAmount?: number;
              key?: string;
            };
          }>;
          buildingAddress?: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          propertyAddress?: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        };
      }>;
    };
  };
};

export type GetResidenciesEligibleForRentIncreaseQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<ResidenciesFilterInput>;
  sort?: InputMaybe<Array<ResidenciesSortInput> | ResidenciesSortInput>;
}>;

export type GetResidenciesEligibleForRentIncreaseQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageResidencies?: {
      __typename?: 'ResidenciesPage';
      totalCount: number;
      pageInfo: { __typename?: 'RelayPageInfo'; endCursor?: string; hasNextPage: boolean };
      edges: Array<{
        __typename?: 'ResidencyEdge';
        cursor: string;
        node: {
          __typename?: 'Residency';
          id: string;
          accountId: string;
          unitId: string;
          unitName?: string;
          unitOwnerId?: string;
          ownerId?: string;
          ownerName?: string;
          propertyId: string;
          propertyName?: string;
          startZ: string;
          terms?: Array<string>;
          renewZ?: string;
          endZ?: string;
          latestRentEffectZ?: string;
          unitOccupancy?: UnitOccupancy;
          propertyAddress?: {
            __typename?: 'Address';
            suite?: string;
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            country?: string;
            lat?: number;
            lng?: number;
          };
          residents: Array<{
            __typename?: 'ResidentAssociation';
            leaseHolder?: boolean;
            residentName?: string;
          }>;
          effects?: Array<
            | {
                __typename?: 'IncentiveEffect';
                id: string;
                feeId: string;
                startZ: string;
                effect: number;
              }
            | {
                __typename?: 'ResidencyEffect';
                id: string;
                feeId: string;
                startZ: string;
                effect: number;
              }
          >;
        };
      }>;
    };
  };
};

export type InsuranceResidencyFieldsFragment = {
  __typename?: 'Residency';
  id: string;
  startZ: string;
  endZ?: string;
  renewZ?: string;
  propertyId: string;
  propertyName?: string;
  propertyKey?: string;
  unitId: string;
  unitName?: string;
  unitOccupancy?: UnitOccupancy;
  insurancePolicies?: Array<{
    __typename?: 'LeaseInsurancePolicy';
    id?: string;
    policyNumber?: string;
    providerName?: string;
    expirationDate?: string;
    liability?: number;
  }>;
  residentInsurancePolicies?: Array<{
    __typename?: 'IamTenantInsurancePolicy';
    id: string;
    policyNumber: string;
    providerName: string;
    liability?: number;
    expiry: string;
  }>;
  propertyAddress?: {
    __typename?: 'Address';
    street?: string;
    suite?: string;
    city?: string;
    province?: string;
    postal?: string;
  };
  residents: Array<{
    __typename?: 'ResidentAssociation';
    id: string;
    residentId: string;
    leaseHolder?: boolean;
    resident:
      | {
          __typename: 'Owner';
          id: string;
          enabled?: boolean;
          name: string;
          emails?: Array<string>;
          phones?: Array<string>;
        }
      | {
          __typename: 'Tenant';
          phone?: string;
          enabled?: boolean;
          id: string;
          name: string;
          emails?: Array<string>;
          phones?: Array<string>;
        };
  }>;
};

export type GetInsuranceResidenciesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<ResidenciesFilterInput>;
  sort?: InputMaybe<Array<ResidenciesSortInput> | ResidenciesSortInput>;
}>;

export type GetInsuranceResidenciesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    pageResidencies?: {
      __typename?: 'ResidenciesPage';
      totalCount: number;
      pageInfo: { __typename?: 'RelayPageInfo'; endCursor?: string; hasNextPage: boolean };
      edges: Array<{
        __typename?: 'ResidencyEdge';
        cursor: string;
        node: {
          __typename?: 'Residency';
          id: string;
          startZ: string;
          endZ?: string;
          renewZ?: string;
          propertyId: string;
          propertyName?: string;
          propertyKey?: string;
          unitId: string;
          unitName?: string;
          unitOccupancy?: UnitOccupancy;
          insurancePolicies?: Array<{
            __typename?: 'LeaseInsurancePolicy';
            id?: string;
            policyNumber?: string;
            providerName?: string;
            expirationDate?: string;
            liability?: number;
          }>;
          residentInsurancePolicies?: Array<{
            __typename?: 'IamTenantInsurancePolicy';
            id: string;
            policyNumber: string;
            providerName: string;
            liability?: number;
            expiry: string;
          }>;
          propertyAddress?: {
            __typename?: 'Address';
            street?: string;
            suite?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          residents: Array<{
            __typename?: 'ResidentAssociation';
            id: string;
            residentId: string;
            leaseHolder?: boolean;
            resident:
              | {
                  __typename: 'Owner';
                  id: string;
                  enabled?: boolean;
                  name: string;
                  emails?: Array<string>;
                  phones?: Array<string>;
                }
              | {
                  __typename: 'Tenant';
                  phone?: string;
                  enabled?: boolean;
                  id: string;
                  name: string;
                  emails?: Array<string>;
                  phones?: Array<string>;
                };
          }>;
        };
      }>;
    };
  };
};

export type ListAllResidenciesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ResidencyConnectionFilter>;
}>;

export type ListAllResidenciesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listResidencies?: {
      __typename?: 'ResidencyConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Residency';
        id: string;
        accountId: string;
        proratedClearableIds?: Array<string>;
        moveInInspectionId?: string;
        moveOutInspectionId?: string;
        terms?: Array<string>;
        startZ: string;
        endZ?: string;
        renewZ?: string;
        securityDeposit?: number;
        gst?: number;
        type?: LeaseType;
        occupancy?: UnitOccupancy;
        unitId: string;
        propertyId: string;
        moveInDate?: string;
        moveOutDate?: string;
        moveOutReason?: MoveOutReason;
        applicationId?: string;
        campaignId?: string;
        rrule?: string;
        utilities?: Array<string>;
        furnishings?: Array<string>;
        collectionDay?: number;
        effects?: Array<
          | {
              __typename: 'IncentiveEffect';
              description?: string;
              incentiveId: string;
              id: string;
              feeId: string;
              startZ: string;
              effect: number;
            }
          | {
              __typename: 'ResidencyEffect';
              id: string;
              feeId: string;
              startZ: string;
              effect: number;
            }
        >;
        residents: Array<{
          __typename?: 'ResidentAssociation';
          id: string;
          residentId: string;
          leaseHolder?: boolean;
          title?: ResidencyResidentTitle;
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          paymentDetails?: {
            __typename?: 'PaymentDetails';
            preferredPaymentMethod?: PaymentMethod;
            padAmount?: number;
            padInstitution?: string;
            padTransit?: string;
            padAccount?: string;
          };
          resident:
            | {
                __typename: 'Owner';
                preferredPaymentMethod?: PaymentMethod;
                padAmount?: number;
                padInstitution?: string;
                padTransit?: string;
                padAccount?: string;
                id: string;
                name: string;
                emails?: Array<string>;
                phones?: Array<string>;
                correspondenceMethod?: CorrespondenceMethod;
                enabled?: boolean;
                emergencyContacts?: Array<{
                  __typename?: 'EmergencyContact';
                  name: string;
                  relationship?: ContactRelationship;
                  phone: string;
                }>;
              }
            | {
                __typename: 'Tenant';
                phone?: string;
                dob?: string;
                isRentalAssist?: boolean;
                id: string;
                name: string;
                emails?: Array<string>;
                phones?: Array<string>;
                correspondenceMethod?: CorrespondenceMethod;
                enabled?: boolean;
                vehicles?: Array<{
                  __typename?: 'Vehicle';
                  id: string;
                  plate: string;
                  make: string;
                  model: string;
                  color?: string;
                }>;
                rentalAssistance?: {
                  __typename?: 'RentalAssistance';
                  qualified?: boolean;
                  amount?: number;
                  source?: string;
                };
                pets?: Array<{
                  __typename?: 'Pet';
                  type: string;
                  count: number;
                  details?: Array<{
                    __typename?: 'PetDetails';
                    type?: string;
                    breed?: string;
                    weight?: string;
                    name?: string;
                    color?: string;
                    license?: string;
                    isService?: boolean;
                  }>;
                }>;
                mailingAddress?: {
                  __typename?: 'Address';
                  suite?: string;
                  street?: string;
                  city?: string;
                  province?: string;
                  postal?: string;
                  country?: string;
                  lat?: number;
                  lng?: number;
                };
                emergencyContacts?: Array<{
                  __typename?: 'EmergencyContact';
                  name: string;
                  relationship?: ContactRelationship;
                  phone: string;
                }>;
              };
        }>;
        refundables?: Array<{
          __typename?: 'Refundable';
          id: string;
          name: string;
          amount?: number;
          templateId: string;
          accrualStartExpr?: string;
          collectedZ?: string;
          heldByOwner?: boolean;
          ledger?: Array<{
            __typename?: 'RefundableLedger';
            id: string;
            category: RefundableLedgerCategory;
            amount: number;
            date: string;
          }>;
        }>;
        securityDepositHistory?: Array<{
          __typename?: 'SecurityDepositHistory';
          createdZ: string;
          allocations: Array<{
            __typename?: 'SecurityDepositAllocation';
            status: SecurityDepositStatus;
            amount: number;
          }>;
        }>;
        eventHistory?: Array<{
          __typename?: 'ResidencyEvent';
          id: string;
          name: string;
          category: ResidencyEventCategory;
          description?: string;
          eventDateZ: string;
          deletedZ?: string;
          resolvedZ?: string;
          noticeZ?: string;
          noteText?: string;
        }>;
        insurancePolicies?: Array<{
          __typename?: 'LeaseInsurancePolicy';
          id?: string;
          policyNumber?: string;
          providerName?: string;
          expirationDate?: string;
          liability?: number;
        }>;
        residentInsurancePolicies?: Array<{
          __typename?: 'IamTenantInsurancePolicy';
          id: string;
          policyNumber: string;
          providerName: string;
          liability?: number;
          expiry: string;
        }>;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          target?: string;
          source?: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        additionalProvisions?: {
          __typename?: 'AdditionalProvisions';
          pets?: number;
          parking?: boolean;
          storage?: boolean;
          smoking?: boolean;
        };
      }>;
    };
  };
};

export type InspectionsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type InspectionsByIdsQuery = {
  __typename?: 'Query';
  inspectionByIds?: Array<{
    __typename?: 'Inspection';
    id: string;
    completedZ?: string;
    unitId?: string;
  }>;
};

export type UnitsWithResidenciesQueryVariables = Exact<{
  filter: UnitsWithResidenciesFilter;
}>;

export type UnitsWithResidenciesQuery = {
  __typename?: 'Query';
  unitsWithResidencies?: Array<{
    __typename?: 'Unit';
    id: string;
    name: string;
    ownerId?: string;
    propertyId?: string;
    occupancy?: UnitOccupancy;
    buildingId?: string;
    sqft: number;
    owner?: { __typename?: 'Owner'; id: string; name: string };
    allResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      startZ: string;
      endZ?: string;
      rrule?: string;
      terms?: Array<string>;
      renewZ?: string;
      moveOutInspectionId?: string;
      moveInInspectionId?: string;
      gst?: number;
      occupancy?: UnitOccupancy;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        leaseHolder?: boolean;
        resident: { __typename?: 'Owner'; name: string } | { __typename?: 'Tenant'; name: string };
      }>;
      effects?: Array<
        | {
            __typename?: 'IncentiveEffect';
            id: string;
            feeId: string;
            effect: number;
            startZ: string;
          }
        | {
            __typename?: 'ResidencyEffect';
            id: string;
            feeId: string;
            effect: number;
            startZ: string;
          }
      >;
    }>;
  }>;
};

export type UpdateSupplierAutoApMutationVariables = Exact<{
  input: UpdateSupplierAutoApInput;
}>;

export type UpdateSupplierAutoApMutation = {
  __typename?: 'Mutation';
  updateSupplierAutoAP: {
    __typename?: 'SupplierPayload';
    success?: boolean;
    error?: string;
    supplier?: {
      __typename?: 'Supplier';
      id: string;
      accountId: string;
      name: string;
      contactName?: string;
      type?: string;
      phone?: string;
      fax?: string;
      email?: string;
      enabled: boolean;
      location?: string;
      deletedZ?: string;
      verificationRequestedZ?: string;
      verifiedZ?: string;
      website?: string;
      coi?: boolean;
      coiExpiry?: string;
      billingEmail?: string;
      businessNumber?: string;
      autopayInstitution?: string;
      autopayTransit?: string;
      autopayAccount?: string;
      autopayStatus?: string;
      autopayStatusUpdatedZ?: string;
      autopayClientId?: string;
      expenseGlId?: string;
      address?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
      fingerprints?: { __typename?: 'Fingerprints'; createdZ?: string };
      supplierAccounts?: Array<{
        __typename?: 'SupplierAccount';
        id: string;
        accountNumber: string;
      }>;
    };
  };
};

export type CreateHouseRulesTemplateMutationVariables = Exact<{
  input: HouseRulesTemplateInput;
}>;

export type CreateHouseRulesTemplateMutation = {
  __typename?: 'Mutation';
  createHouseRulesTemplate?: {
    __typename?: 'Template';
    id: string;
    enabled?: boolean;
    default?: boolean;
    category?: string;
    minutes?: number;
    response?: string;
    fields?: Array<{
      __typename?: 'Field';
      label?: string;
      type?: string;
      info?: string;
      imageKey?: string;
    }>;
  };
};

export type UpdateHouseRulesTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: HouseRulesTemplateInput;
}>;

export type UpdateHouseRulesTemplateMutation = {
  __typename?: 'Mutation';
  updateHouseRulesTemplate?: {
    __typename?: 'Template';
    id: string;
    enabled?: boolean;
    default?: boolean;
    category?: string;
    minutes?: number;
    response?: string;
    fields?: Array<{
      __typename?: 'Field';
      label?: string;
      type?: string;
      info?: string;
      imageKey?: string;
    }>;
  };
};

export type DeleteHouseRulesTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteHouseRulesTemplateMutation = {
  __typename?: 'Mutation';
  deleteHouseRulesTemplate?: {
    __typename?: 'Template';
    id: string;
    enabled?: boolean;
    default?: boolean;
    category?: string;
    minutes?: number;
    response?: string;
    fields?: Array<{
      __typename?: 'Field';
      label?: string;
      type?: string;
      info?: string;
      imageKey?: string;
    }>;
  };
};

export type GetHouseRulesTemplateQueryVariables = Exact<{ [key: string]: never }>;

export type GetHouseRulesTemplateQuery = {
  __typename?: 'Query';
  houseRulesTemplate?: {
    __typename?: 'Template';
    id: string;
    enabled?: boolean;
    default?: boolean;
    category?: string;
    minutes?: number;
    response?: string;
    fields?: Array<{
      __typename?: 'Field';
      label?: string;
      type?: string;
      info?: string;
      imageKey?: string;
    }>;
  };
};

export type CreateTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: CreateTemplateInput;
}>;

export type CreateTemplateMutation = {
  __typename?: 'Mutation';
  createTemplate?: {
    __typename?: 'Template';
    id: string;
    enabled?: boolean;
    default?: boolean;
    category?: string;
    minutes?: number;
    response?: string;
    fields?: Array<{
      __typename?: 'Field';
      label?: string;
      type?: string;
      info?: string;
      imageKey?: string;
    }>;
  };
};

export type UpdateTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateTemplateInput;
}>;

export type UpdateTemplateMutation = {
  __typename?: 'Mutation';
  updateTemplate?: {
    __typename?: 'Template';
    id: string;
    enabled?: boolean;
    default?: boolean;
    category?: string;
    minutes?: number;
    response?: string;
    fields?: Array<{
      __typename?: 'Field';
      label?: string;
      type?: string;
      info?: string;
      imageKey?: string;
    }>;
  };
};

export type DeleteTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTemplateMutation = {
  __typename?: 'Mutation';
  deleteTemplate?: {
    __typename?: 'Template';
    id: string;
    enabled?: boolean;
    default?: boolean;
    category?: string;
    minutes?: number;
    response?: string;
    fields?: Array<{
      __typename?: 'Field';
      label?: string;
      type?: string;
      info?: string;
      imageKey?: string;
    }>;
  };
};

export type GetDefaultTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDefaultTemplateQuery = {
  __typename?: 'Query';
  defaultTemplate?: {
    __typename?: 'Template';
    id: string;
    enabled?: boolean;
    default?: boolean;
    category?: string;
    minutes?: number;
    response?: string;
    fields?: Array<{
      __typename?: 'Field';
      label?: string;
      type?: string;
      info?: string;
      imageKey?: string;
    }>;
  };
};

export type GetCustomTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCustomTemplateQuery = {
  __typename?: 'Query';
  customTemplate?: {
    __typename?: 'Template';
    id: string;
    enabled?: boolean;
    default?: boolean;
    category?: string;
    minutes?: number;
    response?: string;
    fields?: Array<{
      __typename?: 'Field';
      label?: string;
      type?: string;
      info?: string;
      imageKey?: string;
    }>;
  };
};

export type GetTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTemplatesQuery = {
  __typename?: 'Query';
  templates?: Array<{
    __typename?: 'Template';
    id: string;
    enabled?: boolean;
    default?: boolean;
    category?: string;
    minutes?: number;
    response?: string;
    fields?: Array<{
      __typename?: 'Field';
      label?: string;
      type?: string;
      info?: string;
      imageKey?: string;
    }>;
  }>;
};

export type TenantResidencyFragment = {
  __typename?: 'Residency';
  id: string;
  startZ: string;
  endZ?: string;
  unitId: string;
  propertyId: string;
  moveInDate?: string;
  moveOutDate?: string;
  moveOutReason?: MoveOutReason;
  gst?: number;
  unit?: {
    __typename?: 'Unit';
    id: string;
    name: string;
    occupancy?: UnitOccupancy;
    ownerId?: string;
    property?: {
      __typename?: 'Property';
      id: string;
      name: string;
      ownerId?: string;
      enabled?: boolean;
      timezone: string;
      autopayCollectionsEnabled?: boolean;
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
    building?: {
      __typename?: 'Building';
      id: string;
      name: string;
      key?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
    };
  };
  effects?: Array<
    | {
        __typename: 'IncentiveEffect';
        description?: string;
        incentiveId: string;
        id: string;
        feeId: string;
        startZ: string;
        effect: number;
      }
    | { __typename: 'ResidencyEffect'; id: string; feeId: string; startZ: string; effect: number }
  >;
  residents: Array<{
    __typename?: 'ResidentAssociation';
    id: string;
    leaseHolder?: boolean;
    residentId: string;
    payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
    paymentDetails?: {
      __typename?: 'PaymentDetails';
      preferredPaymentMethod?: PaymentMethod;
      padAmount?: number;
      padInstitution?: string;
      padTransit?: string;
      padAccount?: string;
    };
    resident:
      | {
          __typename: 'Owner';
          id: string;
          name: string;
          emails?: Array<string>;
          phones?: Array<string>;
        }
      | {
          __typename: 'Tenant';
          phone?: string;
          id: string;
          name: string;
          emails?: Array<string>;
          phones?: Array<string>;
        };
  }>;
};

export type TenantWithResidencyFragment = {
  __typename?: 'Tenant';
  id: string;
  name: string;
  dob?: string;
  emails?: Array<string>;
  phone?: string;
  phones?: Array<string>;
  leaseHolder?: boolean;
  unitId?: string;
  propertyId?: string;
  type?: string;
  isOwner?: boolean;
  profileId?: string;
  isRentalAssist?: boolean;
  correspondenceMethod?: CorrespondenceMethod;
  enabled?: boolean;
  autopayStatus?: string;
  autopayInvitedZ?: string;
  autopayInstitution?: string;
  autopayTransit?: string;
  autopayAccount?: string;
  autopayCollectionsEnabled?: boolean;
  rentalAssistance?: {
    __typename?: 'RentalAssistance';
    qualified?: boolean;
    amount?: number;
    source?: string;
  };
  allResidencies?: Array<{
    __typename?: 'Residency';
    id: string;
    startZ: string;
    endZ?: string;
    unitId: string;
    propertyId: string;
    moveInDate?: string;
    moveOutDate?: string;
    moveOutReason?: MoveOutReason;
    gst?: number;
    unit?: {
      __typename?: 'Unit';
      id: string;
      name: string;
      occupancy?: UnitOccupancy;
      ownerId?: string;
      property?: {
        __typename?: 'Property';
        id: string;
        name: string;
        ownerId?: string;
        enabled?: boolean;
        timezone: string;
        autopayCollectionsEnabled?: boolean;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      };
      building?: {
        __typename?: 'Building';
        id: string;
        name: string;
        key?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
          lat?: number;
          lng?: number;
        };
      };
    };
    effects?: Array<
      | {
          __typename: 'IncentiveEffect';
          description?: string;
          incentiveId: string;
          id: string;
          feeId: string;
          startZ: string;
          effect: number;
        }
      | { __typename: 'ResidencyEffect'; id: string; feeId: string; startZ: string; effect: number }
    >;
    residents: Array<{
      __typename?: 'ResidentAssociation';
      id: string;
      leaseHolder?: boolean;
      residentId: string;
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      paymentDetails?: {
        __typename?: 'PaymentDetails';
        preferredPaymentMethod?: PaymentMethod;
        padAmount?: number;
        padInstitution?: string;
        padTransit?: string;
        padAccount?: string;
      };
      resident:
        | {
            __typename: 'Owner';
            id: string;
            name: string;
            emails?: Array<string>;
            phones?: Array<string>;
          }
        | {
            __typename: 'Tenant';
            phone?: string;
            id: string;
            name: string;
            emails?: Array<string>;
            phones?: Array<string>;
          };
    }>;
  }>;
  emergencyContacts?: Array<{
    __typename?: 'EmergencyContact';
    name: string;
    phone: string;
    relationship?: ContactRelationship;
  }>;
  pets?: Array<{
    __typename?: 'Pet';
    type: string;
    count: number;
    details?: Array<{
      __typename?: 'PetDetails';
      type?: string;
      breed?: string;
      weight?: string;
      name?: string;
      color?: string;
      license?: string;
      isService?: boolean;
    }>;
  }>;
  vehicles?: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    make: string;
    model: string;
    color?: string;
  }>;
  mailingAddress?: {
    __typename?: 'Address';
    suite?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
  };
};

export type SetTenantAutopayCollectionsEnabledMutationVariables = Exact<{
  input: SetAutopayCollectionsEnabledInput;
}>;

export type SetTenantAutopayCollectionsEnabledMutation = {
  __typename?: 'Mutation';
  setTenantAutopayCollectionsEnabled: {
    __typename?: 'TenantPayload';
    success?: boolean;
    error?: string;
    tenant?: {
      __typename?: 'Tenant';
      id: string;
      autopayCollectionsEnabled?: boolean;
      autopayCollectionsEnabledZ?: string;
    };
  };
};

export type TenantResidencyFinancialsPathQueryVariables = Exact<{
  tenantId: Scalars['ID']['input'];
  residencyId: Scalars['ID']['input'];
}>;

export type TenantResidencyFinancialsPathQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    startZ: string;
    endZ?: string;
    gst?: number;
    effects?: Array<
      | { __typename: 'IncentiveEffect'; id: string; feeId: string; startZ: string; effect: number }
      | { __typename: 'ResidencyEffect'; id: string; feeId: string; startZ: string; effect: number }
    >;
    unit?: {
      __typename?: 'Unit';
      id: string;
      name: string;
      building?: { __typename?: 'Building'; id: string; name: string };
    };
    property?: { __typename?: 'Property'; id: string; name: string };
    residents: Array<{
      __typename?: 'ResidentAssociation';
      id: string;
      residentId: string;
      leaseHolder?: boolean;
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      paymentDetails?: {
        __typename?: 'PaymentDetails';
        preferredPaymentMethod?: PaymentMethod;
        padAmount?: number;
        padInstitution?: string;
        padTransit?: string;
        padAccount?: string;
      };
    }>;
  };
};

export type ResidencyResidentFinancialsPathQueryVariables = Exact<{
  residentId: Scalars['ID']['input'];
  residencyId: Scalars['ID']['input'];
}>;

export type ResidencyResidentFinancialsPathQuery = {
  __typename?: 'Query';
  residency?: {
    __typename?: 'Residency';
    id: string;
    startZ: string;
    endZ?: string;
    gst?: number;
    effects?: Array<
      | { __typename: 'IncentiveEffect'; id: string; feeId: string; startZ: string; effect: number }
      | { __typename: 'ResidencyEffect'; id: string; feeId: string; startZ: string; effect: number }
    >;
    residents: Array<{
      __typename?: 'ResidentAssociation';
      id: string;
      residentId: string;
      leaseHolder?: boolean;
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      paymentDetails?: {
        __typename?: 'PaymentDetails';
        preferredPaymentMethod?: PaymentMethod;
        padAmount?: number;
        padInstitution?: string;
        padTransit?: string;
        padAccount?: string;
      };
    }>;
  };
};

export type UpdateResidencyResidentFinancialsMutationVariables = Exact<{
  residentId: Scalars['ID']['input'];
  input: UpdateResidencyResidentFinancialsInput;
}>;

export type UpdateResidencyResidentFinancialsMutation = {
  __typename?: 'Mutation';
  updateResidencyResidentFinancials: {
    __typename?: 'ResidencyPayload';
    residency?: {
      __typename?: 'Residency';
      id: string;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
      }>;
    };
  };
};

export type CreateTenantNoteMutationVariables = Exact<{
  input: CreateTenantNoteInput;
}>;

export type CreateTenantNoteMutation = {
  __typename?: 'Mutation';
  createTenantNote?: {
    __typename?: 'TenantPayload';
    tenant?: {
      __typename?: 'Tenant';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type DeleteTenantNoteMutationVariables = Exact<{
  input: DeleteTenantNoteInput;
}>;

export type DeleteTenantNoteMutation = {
  __typename?: 'Mutation';
  deleteTenantNote?: {
    __typename?: 'TenantPayload';
    tenant?: {
      __typename?: 'Tenant';
      id: string;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
    };
  };
};

export type TenantNotesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TenantNotesQuery = {
  __typename?: 'Query';
  tenant?: {
    __typename?: 'Tenant';
    id: string;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
  };
};

export type UpdateTenantAutopayMutationVariables = Exact<{
  input: UpdateTenantAutopayInput;
}>;

export type UpdateTenantAutopayMutation = {
  __typename?: 'Mutation';
  updateTenantAutopay?: {
    __typename?: 'TenantPayload';
    success?: boolean;
    error?: string;
    tenant?: {
      __typename?: 'Tenant';
      id: string;
      name: string;
      emails?: Array<string>;
      phone?: string;
      phones?: Array<string>;
      leaseHolder?: boolean;
      unitId?: string;
      propertyId?: string;
      type?: string;
      isOwner?: boolean;
      preferredPaymentMethod?: PaymentMethod;
      padAmount?: number;
      padInstitution?: string;
      padTransit?: string;
      padAccount?: string;
      correspondenceMethod?: CorrespondenceMethod;
      profileId?: string;
      enabled?: boolean;
      autopayClientId?: string;
      autopayInstitution?: string;
      autopayTransit?: string;
      autopayAccount?: string;
      autopayStatus?: string;
      autopayInvitedZ?: string;
      autopayCollectionsEnabled?: boolean;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        phone: string;
        relationship?: ContactRelationship;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      pets?: Array<{
        __typename?: 'Pet';
        type: string;
        count: number;
        details?: Array<{
          __typename?: 'PetDetails';
          type?: string;
          breed?: string;
          weight?: string;
          name?: string;
          color?: string;
          license?: string;
          isService?: boolean;
        }>;
      }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
    };
  };
};

export type InviteTenantAutopayMutationVariables = Exact<{
  input: InviteTenantAutopayInput;
}>;

export type InviteTenantAutopayMutation = {
  __typename?: 'Mutation';
  inviteTenantAutopay?: {
    __typename?: 'TenantPayload';
    success?: boolean;
    error?: string;
    tenant?: {
      __typename?: 'Tenant';
      id: string;
      name: string;
      emails?: Array<string>;
      phone?: string;
      phones?: Array<string>;
      leaseHolder?: boolean;
      unitId?: string;
      propertyId?: string;
      type?: string;
      isOwner?: boolean;
      preferredPaymentMethod?: PaymentMethod;
      padAmount?: number;
      padInstitution?: string;
      padTransit?: string;
      padAccount?: string;
      correspondenceMethod?: CorrespondenceMethod;
      profileId?: string;
      enabled?: boolean;
      autopayClientId?: string;
      autopayInstitution?: string;
      autopayTransit?: string;
      autopayAccount?: string;
      autopayStatus?: string;
      autopayInvitedZ?: string;
      autopayCollectionsEnabled?: boolean;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        phone: string;
        relationship?: ContactRelationship;
      }>;
      notes?: Array<{
        __typename?: 'Note';
        id: string;
        createdName?: string;
        createdSub?: string;
        createdZ?: string;
        text?: string;
      }>;
      pets?: Array<{
        __typename?: 'Pet';
        type: string;
        count: number;
        details?: Array<{
          __typename?: 'PetDetails';
          type?: string;
          breed?: string;
          weight?: string;
          name?: string;
          color?: string;
          license?: string;
          isService?: boolean;
        }>;
      }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
    };
  };
};

export type GetTenantWithResidencyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetTenantWithResidencyQuery = {
  __typename?: 'Query';
  tenant?: {
    __typename?: 'Tenant';
    id: string;
    name: string;
    dob?: string;
    emails?: Array<string>;
    phone?: string;
    phones?: Array<string>;
    leaseHolder?: boolean;
    unitId?: string;
    propertyId?: string;
    type?: string;
    isOwner?: boolean;
    profileId?: string;
    isRentalAssist?: boolean;
    correspondenceMethod?: CorrespondenceMethod;
    enabled?: boolean;
    autopayStatus?: string;
    autopayInvitedZ?: string;
    autopayInstitution?: string;
    autopayTransit?: string;
    autopayAccount?: string;
    autopayCollectionsEnabled?: boolean;
    rentalAssistance?: {
      __typename?: 'RentalAssistance';
      qualified?: boolean;
      amount?: number;
      source?: string;
    };
    allResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      startZ: string;
      endZ?: string;
      unitId: string;
      propertyId: string;
      moveInDate?: string;
      moveOutDate?: string;
      moveOutReason?: MoveOutReason;
      gst?: number;
      unit?: {
        __typename?: 'Unit';
        id: string;
        name: string;
        occupancy?: UnitOccupancy;
        ownerId?: string;
        property?: {
          __typename?: 'Property';
          id: string;
          name: string;
          ownerId?: string;
          enabled?: boolean;
          timezone: string;
          autopayCollectionsEnabled?: boolean;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        };
        building?: {
          __typename?: 'Building';
          id: string;
          name: string;
          key?: string;
          address?: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            lat?: number;
            lng?: number;
          };
        };
      };
      effects?: Array<
        | {
            __typename: 'IncentiveEffect';
            description?: string;
            incentiveId: string;
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
        | {
            __typename: 'ResidencyEffect';
            id: string;
            feeId: string;
            startZ: string;
            effect: number;
          }
      >;
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        leaseHolder?: boolean;
        residentId: string;
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
        paymentDetails?: {
          __typename?: 'PaymentDetails';
          preferredPaymentMethod?: PaymentMethod;
          padAmount?: number;
          padInstitution?: string;
          padTransit?: string;
          padAccount?: string;
        };
        resident:
          | {
              __typename: 'Owner';
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
            }
          | {
              __typename: 'Tenant';
              phone?: string;
              id: string;
              name: string;
              emails?: Array<string>;
              phones?: Array<string>;
            };
      }>;
    }>;
    emergencyContacts?: Array<{
      __typename?: 'EmergencyContact';
      name: string;
      phone: string;
      relationship?: ContactRelationship;
    }>;
    pets?: Array<{
      __typename?: 'Pet';
      type: string;
      count: number;
      details?: Array<{
        __typename?: 'PetDetails';
        type?: string;
        breed?: string;
        weight?: string;
        name?: string;
        color?: string;
        license?: string;
        isService?: boolean;
      }>;
    }>;
    vehicles?: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      make: string;
      model: string;
      color?: string;
    }>;
    mailingAddress?: {
      __typename?: 'Address';
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  };
};

export type GetTenantQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetTenantQuery = {
  __typename?: 'Query';
  tenant?: {
    __typename?: 'Tenant';
    id: string;
    name: string;
    emails?: Array<string>;
    phone?: string;
    phones?: Array<string>;
    leaseHolder?: boolean;
    unitId?: string;
    propertyId?: string;
    type?: string;
    isOwner?: boolean;
    preferredPaymentMethod?: PaymentMethod;
    padAmount?: number;
    padInstitution?: string;
    padTransit?: string;
    padAccount?: string;
    correspondenceMethod?: CorrespondenceMethod;
    profileId?: string;
    enabled?: boolean;
    autopayClientId?: string;
    autopayInstitution?: string;
    autopayTransit?: string;
    autopayAccount?: string;
    autopayStatus?: string;
    autopayInvitedZ?: string;
    autopayCollectionsEnabled?: boolean;
    emergencyContacts?: Array<{
      __typename?: 'EmergencyContact';
      name: string;
      phone: string;
      relationship?: ContactRelationship;
    }>;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdName?: string;
      createdSub?: string;
      createdZ?: string;
      text?: string;
    }>;
    pets?: Array<{
      __typename?: 'Pet';
      type: string;
      count: number;
      details?: Array<{
        __typename?: 'PetDetails';
        type?: string;
        breed?: string;
        weight?: string;
        name?: string;
        color?: string;
        license?: string;
        isService?: boolean;
      }>;
    }>;
    vehicles?: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      make: string;
      model: string;
      color?: string;
    }>;
  };
};

export type GetTenantNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetTenantNameQuery = {
  __typename?: 'Query';
  tenant?: { __typename?: 'Tenant'; id: string; name: string };
};

export type GetTenantEnrollmentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  filter?: InputMaybe<AutopayEnrollmentFilterInput>;
}>;

export type GetTenantEnrollmentsQuery = {
  __typename?: 'Query';
  tenant?: {
    __typename?: 'Tenant';
    id: string;
    enrollments?: Array<{
      __typename?: 'AutopayEnrollment';
      id: string;
      payeeType: PayeeType;
      unitId: string;
      residencyId?: string;
      status: AutopayEnrollmentStatus;
    }>;
  };
};

export type GetTenantContactQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetTenantContactQuery = {
  __typename?: 'Query';
  tenant?: {
    __typename?: 'Tenant';
    id: string;
    name: string;
    dob?: string;
    emails?: Array<string>;
    phone?: string;
    phones?: Array<string>;
    profileId?: string;
    isRentalAssist?: boolean;
    correspondenceMethod?: CorrespondenceMethod;
    rentalAssistance?: {
      __typename?: 'RentalAssistance';
      qualified?: boolean;
      amount?: number;
      source?: string;
    };
    emergencyContacts?: Array<{
      __typename?: 'EmergencyContact';
      name: string;
      phone: string;
      relationship?: ContactRelationship;
    }>;
    pets?: Array<{
      __typename?: 'Pet';
      type: string;
      count: number;
      details?: Array<{
        __typename?: 'PetDetails';
        type?: string;
        breed?: string;
        weight?: string;
        name?: string;
        color?: string;
        license?: string;
        isService?: boolean;
      }>;
    }>;
    vehicles?: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      make: string;
      model: string;
      color?: string;
    }>;
    profile?: {
      __typename?: 'Profile';
      id: string;
      name: string;
      email: string;
      emails?: Array<string>;
      phone?: string;
      dob?: string;
      logins?: Array<{ __typename?: 'ProfileLogin'; id: string; email: string }>;
      household?: {
        __typename?: 'Household';
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      };
    };
  };
};

export type ListAllTenantsWithResidencyQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListAllTenantsWithResidencyQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    listTenants?: {
      __typename?: 'TenantConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'Tenant';
        id: string;
        name: string;
        emails?: Array<string>;
        phone?: string;
        phones?: Array<string>;
        leaseHolder?: boolean;
        unitId?: string;
        propertyId?: string;
        type?: string;
        isOwner?: boolean;
        preferredPaymentMethod?: PaymentMethod;
        padAmount?: number;
        padInstitution?: string;
        padTransit?: string;
        padAccount?: string;
        correspondenceMethod?: CorrespondenceMethod;
        profileId?: string;
        enabled?: boolean;
        autopayClientId?: string;
        autopayInstitution?: string;
        autopayTransit?: string;
        autopayAccount?: string;
        autopayStatus?: string;
        autopayInvitedZ?: string;
        autopayCollectionsEnabled?: boolean;
        allResidencies?: Array<{
          __typename?: 'Residency';
          id: string;
          unitId: string;
          propertyId: string;
          startZ: string;
          endZ?: string;
          moveOutReason?: MoveOutReason;
          unit?: {
            __typename?: 'Unit';
            id: string;
            propertyId?: string;
            occupancy?: UnitOccupancy;
          };
          residents: Array<{
            __typename?: 'ResidentAssociation';
            id: string;
            leaseHolder?: boolean;
            residentId: string;
            payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
            paymentDetails?: {
              __typename?: 'PaymentDetails';
              preferredPaymentMethod?: PaymentMethod;
              padAmount?: number;
              padInstitution?: string;
              padTransit?: string;
              padAccount?: string;
            };
          }>;
        }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          phone: string;
          relationship?: ContactRelationship;
        }>;
        notes?: Array<{
          __typename?: 'Note';
          id: string;
          createdName?: string;
          createdSub?: string;
          createdZ?: string;
          text?: string;
        }>;
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      }>;
    };
  };
};

export type TenantDocumentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TenantDocumentsQuery = {
  __typename?: 'Query';
  tenant?: {
    __typename?: 'Tenant';
    id: string;
    documents?: Array<{
      __typename?: 'Document';
      key: string;
      name?: string;
      size?: number;
      createdZ: string;
      typename?: string;
    }>;
  };
};

export type RenameTenantDocumentMutationVariables = Exact<{
  input: RenameTenantDocumentInput;
}>;

export type RenameTenantDocumentMutation = {
  __typename?: 'Mutation';
  renameTenantDocument: {
    __typename?: 'TenantPayload';
    tenant?: {
      __typename?: 'Tenant';
      id: string;
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ: string;
        typename?: string;
      }>;
    };
  };
};

export type UpdateTenantFinancialsMutationVariables = Exact<{
  input: UpdateTenantFinancialsInput;
}>;

export type UpdateTenantFinancialsMutation = {
  __typename?: 'Mutation';
  updateTenantFinancials?: {
    __typename?: 'TenantPayload';
    success?: boolean;
    error?: string;
    tenant?: {
      __typename?: 'Tenant';
      id: string;
      preferredPaymentMethod?: PaymentMethod;
      padAmount?: number;
      padInstitution?: string;
      padTransit?: string;
      padAccount?: string;
    };
  };
};

export type WelcomeTenantMutationVariables = Exact<{
  input: WelcomeTenantInput;
}>;

export type WelcomeTenantMutation = {
  __typename?: 'Mutation';
  welcomeTenant?: { __typename?: 'TenantPayload'; success?: boolean; error?: string };
};

export type UpdateTenantRecordMutationVariables = Exact<{
  input: UpdateTenantInput;
}>;

export type UpdateTenantRecordMutation = {
  __typename?: 'Mutation';
  updateTenantRecord?: {
    __typename?: 'TenantPayload';
    success?: boolean;
    error?: string;
    tenant?: {
      __typename?: 'Tenant';
      id: string;
      name: string;
      dob?: string;
      emails?: Array<string>;
      phone?: string;
      phones?: Array<string>;
      leaseHolder?: boolean;
      unitId?: string;
      propertyId?: string;
      type?: string;
      isOwner?: boolean;
      profileId?: string;
      isRentalAssist?: boolean;
      correspondenceMethod?: CorrespondenceMethod;
      enabled?: boolean;
      autopayStatus?: string;
      autopayInvitedZ?: string;
      autopayInstitution?: string;
      autopayTransit?: string;
      autopayAccount?: string;
      autopayCollectionsEnabled?: boolean;
      rentalAssistance?: {
        __typename?: 'RentalAssistance';
        qualified?: boolean;
        amount?: number;
        source?: string;
      };
      allResidencies?: Array<{
        __typename?: 'Residency';
        id: string;
        startZ: string;
        endZ?: string;
        unitId: string;
        propertyId: string;
        moveInDate?: string;
        moveOutDate?: string;
        moveOutReason?: MoveOutReason;
        gst?: number;
        unit?: {
          __typename?: 'Unit';
          id: string;
          name: string;
          occupancy?: UnitOccupancy;
          ownerId?: string;
          property?: {
            __typename?: 'Property';
            id: string;
            name: string;
            ownerId?: string;
            enabled?: boolean;
            timezone: string;
            autopayCollectionsEnabled?: boolean;
            address: {
              __typename?: 'Address';
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
          };
          building?: {
            __typename?: 'Building';
            id: string;
            name: string;
            key?: string;
            address?: {
              __typename?: 'Address';
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
              lat?: number;
              lng?: number;
            };
          };
        };
        effects?: Array<
          | {
              __typename: 'IncentiveEffect';
              description?: string;
              incentiveId: string;
              id: string;
              feeId: string;
              startZ: string;
              effect: number;
            }
          | {
              __typename: 'ResidencyEffect';
              id: string;
              feeId: string;
              startZ: string;
              effect: number;
            }
        >;
        residents: Array<{
          __typename?: 'ResidentAssociation';
          id: string;
          leaseHolder?: boolean;
          residentId: string;
          payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
          paymentDetails?: {
            __typename?: 'PaymentDetails';
            preferredPaymentMethod?: PaymentMethod;
            padAmount?: number;
            padInstitution?: string;
            padTransit?: string;
            padAccount?: string;
          };
          resident:
            | {
                __typename: 'Owner';
                id: string;
                name: string;
                emails?: Array<string>;
                phones?: Array<string>;
              }
            | {
                __typename: 'Tenant';
                phone?: string;
                id: string;
                name: string;
                emails?: Array<string>;
                phones?: Array<string>;
              };
        }>;
      }>;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        phone: string;
        relationship?: ContactRelationship;
      }>;
      pets?: Array<{
        __typename?: 'Pet';
        type: string;
        count: number;
        details?: Array<{
          __typename?: 'PetDetails';
          type?: string;
          breed?: string;
          weight?: string;
          name?: string;
          color?: string;
          license?: string;
          isService?: boolean;
        }>;
      }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
      mailingAddress?: {
        __typename?: 'Address';
        suite?: string;
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
  };
};

export type AccountEventsSubscriptionVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;

export type AccountEventsSubscription = {
  __typename?: 'Subscription';
  onAccountEvent?: {
    __typename?: 'AccountEvent';
    accountId: string;
    source: EventSource;
    detail?: string;
    detailType: string;
    id: string;
    time: string;
  };
};

export const ChargeApprovalFieldsFragmentDoc = gql`
  fragment ChargeApprovalFields on Approval {
    id
    accountId
    approverRoles
    status
    request {
      date
      amount
      chargeId
      payeeId
      payee
      ownerId
      propertyId
      unitId
      applyGst
      applyPst
      note
    }
  }
`;
export const AddressFieldsFragmentDoc = gql`
  fragment AddressFields on Address {
    suite
    street
    city
    province
    postal
    country
    lat
    lng
  }
`;
export const AccountFieldsFragmentDoc = gql`
  fragment AccountFields on Account {
    id
    phone
    name
    logoKey
    tier
    settings {
      soon_days_threshold
      later_days_threshold
      resident_request_scheduling
      campaignApplicationRules {
        requiredDocs {
          quantity
          docTypes
        }
      }
    }
    address {
      ...AddressFields
    }
    fees {
      id
      name
      deletedZ
    }
    books {
      accountId
      trackManagementFeeRevenue
      billingRates {
        materialsMarkup
      }
    }
    autopayClientId
    autopayInstitution
    autopayTransit
    autopayAccount
  }
  ${AddressFieldsFragmentDoc}
`;
export const BatchFieldsFragmentDoc = gql`
  fragment BatchFields on Batch {
    id
    name
    presetId
    posted
    status
    errorMessage
  }
`;
export const BatchPrototypeFieldsFragmentDoc = gql`
  fragment BatchPrototypeFields on JEPrototype {
    id
    omit
    fields {
      id
      groupId
      label
      type
      order
      editable
      value
      formula
      secondary
    }
  }
`;
export const GlAccountFieldsFragmentDoc = gql`
  fragment GlAccountFields on GLAccount {
    id
    name
    key
    category
    accountType
    balanceType
  }
`;
export const GlMappingFieldsFragmentDoc = gql`
  fragment GlMappingFields on GLMapping {
    id
    parentId
    name
    description
    default
    filter
    glId
  }
`;
export const PresetFieldsFragmentDoc = gql`
  fragment PresetFields on Preset {
    id
    type
    version
    category
    name
    visibleExpression
    description
    lastPosted
    roles
    group {
      name
      order
    }
    glMapping {
      ...GlMappingFields
    }
    inputFields {
      id
      label
      type
      required
      defaultExpression
      options {
        label
        value
      }
    }
    recurrence {
      enabled
      locked
      rrule
      postedOffset
      parameters
    }
    icon {
      name
      color
    }
  }
  ${GlMappingFieldsFragmentDoc}
`;
export const BooksFieldsFragmentDoc = gql`
  fragment BooksFields on Books {
    accountId
    chequePlacement
    nextCheque
    nextInvoice
    nextJournal
    yearEndMonth
    receiverGeneralId
    gstNumber
    gstFiling
    printedZ
    includeDisabledProperties
    trackManagementFeeRevenue
    managementFeeBasis
    billableRevenueFeeIds
    billingRates {
      enabled
      labourRate
      materialsMarkup
      requireApproval
      minBillableMinutes
      trackRevenue
    }
    customBillingRates {
      category
      glId
      enabled
      labourRate
      materialsMarkup
      requireApproval
      minBillableMinutes
      trackRevenue
    }
    lastPrintedGlId
    printedCheques {
      glId
      printedZ
      nextCheque
      chequePlacement
    }
    incomePayoutOptions {
      excludePayables
      excludePrepayments
      includeOutstandingPayables
    }
    glAccounts {
      ...GlAccountFields
    }
    glAccountMapping {
      ...GlMappingFields
    }
    presets {
      ...PresetFields
    }
    feeMapping {
      feeId
      glId
    }
    chargeMapping {
      chargeId
      glId
    }
  }
  ${GlAccountFieldsFragmentDoc}
  ${GlMappingFieldsFragmentDoc}
  ${PresetFieldsFragmentDoc}
`;
export const BooksReportFieldsFragmentDoc = gql`
  fragment BooksReportFields on BooksReport {
    id
    title
    subtitle1
    subtitle2
    options {
      showZeroAmounts
      hideAmountColumn
    }
    propertyId
    ownerId
    range
    grandTotals {
      order
      amount
      balanceType
      header {
        label
      }
      footer {
        label
      }
      extraColumns {
        columnHeader
        value
        flex
        columnType
        keepNonZero
      }
      sections {
        order
        date
        columnHeader
        amount
        balanceType
        header {
          label
        }
        footer {
          label
        }
        extraColumns {
          columnHeader
          value
          flex
          columnType
          keepNonZero
        }
        lines {
          label
          order
          date
          columnHeader
          amount
          maxWidth
          extraColumns {
            columnHeader
            value
            flex
            columnType
            keepNonZero
            maxWidth
          }
          action {
            glId
            ownerId
            propertyId
          }
          balanceType
          header {
            label
          }
          footer {
            label
          }
          lines {
            label
            order
            date
            amount
            maxWidth
            extraColumns {
              columnHeader
              value
              flex
              columnType
              keepNonZero
              maxWidth
            }
            action {
              glId
              ownerId
              propertyId
            }
            balanceType
            header {
              label
            }
            footer {
              label
            }
          }
        }
      }
    }
  }
`;
export const ListedUnitFieldsFragmentDoc = gql`
  fragment ListedUnitFields on ListedUnit {
    id
    rent
    bedrooms
    bathrooms
    sqft
    exteriorSqFt
  }
`;
export const WorkHistoryFieldsFragmentDoc = gql`
  fragment WorkHistoryFields on WorkHistory {
    id
    name
    location
    phone
    startDate
    endDate
    frequency
    amount
  }
`;
export const OtherIncomeFieldsFragmentDoc = gql`
  fragment OtherIncomeFields on Income {
    id
    source
    amount
  }
`;
export const AddressHistoryFieldsFragmentDoc = gql`
  fragment AddressHistoryFields on AddressHistory {
    id
    name
    address {
      street
      city
      province
      postal
    }
    phone
    email
    startDate
    endDate
    rentalType
  }
`;
export const AdditionalTenantFieldsFragmentDoc = gql`
  fragment AdditionalTenantFields on AdditionalTenant {
    id
    name
    email
    phone
    leaseholder
    payeeFrac {
      numerator
      denominator
    }
  }
`;
export const PetFieldsFragmentDoc = gql`
  fragment PetFields on Pet {
    type
    count
    details {
      type
      breed
      weight
      name
      color
      license
      isService
    }
  }
`;
export const VehicleFieldsFragmentDoc = gql`
  fragment VehicleFields on Vehicle {
    id
    plate
    make
    model
    color
  }
`;
export const HouseholdFieldsFragmentDoc = gql`
  fragment HouseholdFields on Household {
    payeeFrac {
      numerator
      denominator
    }
    additionalTenants {
      ...AdditionalTenantFields
    }
    pets {
      ...PetFields
    }
    vehicles {
      ...VehicleFields
    }
  }
  ${AdditionalTenantFieldsFragmentDoc}
  ${PetFieldsFragmentDoc}
  ${VehicleFieldsFragmentDoc}
`;
export const AgreementLeaseFieldsFragmentDoc = gql`
  fragment AgreementLeaseFields on AgreementLease {
    start
    end
    rrule
    rent
    securityDeposit
    gst
    preferredPaymentMethod
    occupancy
    propertyId
    autoRenewSetup {
      enabled
      termMonths
    }
    tenants {
      name
      dob
      emails
      phone
      phones
      leaseHolder
      isPayee
      payeeFrac {
        numerator
        denominator
      }
      emergencyContacts {
        name
        relationship
        phone
      }
      pets {
        type
        count
      }
      vehicles {
        id
        plate
        make
        model
        color
      }
      preferredPaymentMethod
      padAmount
      padInstitution
      padTransit
      padAccount
    }
    incentives {
      start
      end
      discountAmount
      type
      description
    }
    rateSchedule {
      start
      rent
    }
    rentRollFees {
      feeId
      amount
      name
    }
    refundables {
      id
      name
      amount
      templateId
    }
    furnishings
  }
`;
export const SignatureFieldsFragmentDoc = gql`
  fragment SignatureFields on Signature {
    id
    signature
    signerName
    signerEntity
    isAgent
    agentRelationship
  }
`;
export const LeaseAgreementFieldsFragmentDoc = gql`
  fragment LeaseAgreementFields on LeaseAgreement {
    id
    unitId
    history {
      status
      createdZ
    }
    lease {
      ...AgreementLeaseFields
    }
    additionalProvisions {
      pets
      parking
      storage
      smoking
    }
    utilities
    additionalClause
    signatures {
      ...SignatureFields
    }
    updatedZ
    status
    houseRules
  }
  ${AgreementLeaseFieldsFragmentDoc}
  ${SignatureFieldsFragmentDoc}
`;
export const DocumentFieldsFragmentDoc = gql`
  fragment DocumentFields on Document {
    key
    name
    size
    createdZ
    typename
  }
`;
export const CoApplicationsFieldsFragmentDoc = gql`
  fragment CoApplicationsFields on CoApplication {
    id
    profileId
    name
    emails
    phone
    dob
    submittedZ
    listedUnitId
    campaignId
    screeningAgreement
    additionalInformation
    leaseAgreement {
      status
    }
    workHistory {
      ...WorkHistoryFields
    }
    otherIncome {
      ...OtherIncomeFields
    }
    addressHistory {
      ...AddressHistoryFields
    }
    household {
      ...HouseholdFields
    }
    documents {
      ...DocumentFields
    }
    emergencyContacts {
      name
      phone
      relationship
    }
  }
  ${WorkHistoryFieldsFragmentDoc}
  ${OtherIncomeFieldsFragmentDoc}
  ${AddressHistoryFieldsFragmentDoc}
  ${HouseholdFieldsFragmentDoc}
  ${DocumentFieldsFragmentDoc}
`;
export const ApplicationFieldsFragmentDoc = gql`
  fragment ApplicationFields on Application {
    id
    campaignId
    leaseId
    residencyId
    profileId
    name
    emails
    phone
    dob
    flag
    needsAgreement
    moveInDate
    notes {
      id
      text
      source
      target
      createdZ
      createdSub
      createdName
    }
    additionalInformation
    workHistory {
      ...WorkHistoryFields
    }
    otherIncome {
      ...OtherIncomeFields
    }
    addressHistory {
      ...AddressHistoryFields
    }
    household {
      ...HouseholdFields
    }
    listedUnitId
    listedUnit {
      id
      rent
      bedrooms
      bathrooms
      sqft
      exteriorSqFt
    }
    submittedZ
    status
    approvedZ
    declinedZ
    screeningAgreement
    leaseAgreement {
      ...LeaseAgreementFields
    }
    documents {
      ...DocumentFields
    }
    emergencyContacts {
      name
      phone
      relationship
    }
    coApplications {
      ...CoApplicationsFields
    }
  }
  ${WorkHistoryFieldsFragmentDoc}
  ${OtherIncomeFieldsFragmentDoc}
  ${AddressHistoryFieldsFragmentDoc}
  ${HouseholdFieldsFragmentDoc}
  ${LeaseAgreementFieldsFragmentDoc}
  ${DocumentFieldsFragmentDoc}
  ${CoApplicationsFieldsFragmentDoc}
`;
export const CampaignFieldsFragmentDoc = gql`
  fragment CampaignFields on Campaign {
    id
    accountId
    propertyId
    link
    property {
      id
      address {
        street
        city
        province
        postal
      }
      name
      key
      imageKey
      units {
        id
        floorplanId
      }
    }
    name
    status
    listedUnits {
      ...ListedUnitFields
    }
    history {
      status
      createdZ
    }
    applications {
      ...ApplicationFields
    }
    systemCreated
  }
  ${ListedUnitFieldsFragmentDoc}
  ${ApplicationFieldsFragmentDoc}
`;
export const ChequeFieldsFragmentDoc = gql`
  fragment ChequeFields on JournalEntry {
    id
    glAccount {
      ...GlAccountFields
    }
    payee
    payeeId
    payeeName
    amount
    posted
    printedZ
    ref
    description
    journalEntries(filter: { excludeSelf: true }) {
      glAccount {
        ...GlAccountFields
      }
      ref
      description
      propertyId
      amount
      clearable {
        id
        sourceJournalEntry {
          id
          description
          ref
          posted
          propertyId
          unitId
        }
      }
    }
  }
  ${GlAccountFieldsFragmentDoc}
`;
export const JournalEntryFieldsFragmentDoc = gql`
  fragment JournalEntryFields on JournalEntry {
    id
    ownerId
    glId
    jeId
    unitId
    amount
    description
    posted
    propertyId
    paymentMethod
    ref
    gstInfo
    printedZ
    voidedZ
    requestId
    payee
    payeeId
    payeeName
    clearableId
    clearableLinked
    clearable {
      id
      payee
      payeeId
      payeeName
      balanceType
      description
      sourceJournalEntry {
        jeId
      }
      sourceJournalId
    }
    notes {
      id
      target
      source
      createdName
      createdSub
      createdZ
      text
    }
    lines {
      id
      glId
      ownerId
      propertyId
      unitId
      amount
      description
      clearableId
    }
    reconciliationId
    approverName
    transactionReferenceId
  }
`;
export const ClearableFieldsFragmentDoc = gql`
  fragment ClearableFields on Clearable {
    id
    description
    journalEntries {
      ...JournalEntryFields
    }
    due
    posted
    glId
    glAccount {
      ...GlAccountFields
    }
    ownerId
    sourceJournalId
    sourceJournalEntry {
      ...JournalEntryFields
    }
    payeeId
    payeeName
    unitId
    propertyId
    payee
    amount
    outstanding
    cleared
    ref
    balanceType
    documents {
      ...DocumentFields
    }
    notes {
      id
      target
      source
      createdName
      createdSub
      createdZ
      text
    }
    batchId
    refund
    enrollmentId
    fixedEnrollmentId
    autopayCollectionsEnabled
    payeeAutopayCollectionsEnabled
    supplierAccount
    paymentsStatus
    autopayCollectionsEnabled
    autopaySinglePosted
  }
  ${JournalEntryFieldsFragmentDoc}
  ${GlAccountFieldsFragmentDoc}
  ${DocumentFieldsFragmentDoc}
`;
export const ClearableListFieldsFragmentDoc = gql`
  fragment ClearableListFields on Clearable {
    id
    due
    posted
    glId
    ownerId
    sourceJournalId
    payeeId
    unitId
    propertyId
    payee
    amount
    outstanding
    cleared
    ref
    description
    balanceType
    batchId
    refund
    enrollmentId
    fixedEnrollmentId
    paymentsStatus
    autopayCollectionsEnabled
  }
`;
export const DraftBatchFieldsFragmentDoc = gql`
  fragment DraftBatchFields on JEDraftBatch {
    id
    name
    presetId
    posted
    prototypes {
      id
      fields {
        id
        groupId
        label
        type
        order
        editable
        value
        formula
        secondary
      }
    }
  }
`;
export const FeatureFieldsFragmentDoc = gql`
  fragment FeatureFields on Feature {
    id
    name
  }
`;
export const InspectionItemFieldsFragmentDoc = gql`
  fragment InspectionItemFields on InspectionItem {
    id
    referenceId
    label
    description
    category
    wasInspected
    fields {
      id
      name
      label
      description
      type
      value
      noteText
      needsRequest
      inspectedZ
      images {
        key
      }
    }
  }
`;
export const InspectionFieldsFragmentDoc = gql`
  fragment InspectionFields on LeaseInspection {
    id
    status
    completedZ
    templateId
    category
    inspectionAgreements {
      noteText
      agreementType
    }
    costAgreement {
      cost
      noteText
      agreementType
    }
    signatures {
      ...SignatureFields
    }
    items {
      ...InspectionItemFields
    }
    generatedSummary {
      summary
      score
    }
  }
  ${SignatureFieldsFragmentDoc}
  ${InspectionItemFieldsFragmentDoc}
`;
export const SupplierFieldsFragmentDoc = gql`
  fragment SupplierFields on Supplier {
    id
    accountId
    name
    contactName
    type
    phone
    fax
    email
    enabled
    location
    address {
      suite
      street
      city
      province
      postal
    }
    deletedZ
    fingerprints {
      createdZ
    }
    verificationRequestedZ
    verifiedZ
    website
    coi
    coiExpiry
    billingEmail
    businessNumber
    supplierAccounts {
      id
      accountNumber
    }
    autopayInstitution
    autopayTransit
    autopayAccount
    autopayStatus
    autopayStatusUpdatedZ
    autopayClientId
    expenseGlId
    supplierAccounts {
      id
      accountNumber
    }
  }
`;
export const ClaimFieldsFragmentDoc = gql`
  fragment ClaimFields on Claim {
    id
    date
    amount
    status
    type
    description
  }
`;
export const InsurancePolicyFieldsFragmentDoc = gql`
  fragment InsurancePolicyFields on InsurancePolicy {
    id
    policyNumber
    policyHolder
    effective
    expiry
    supplierId
    coverage
    premium
    supplier {
      ...SupplierFields
    }
    claims {
      ...ClaimFields
    }
  }
  ${SupplierFieldsFragmentDoc}
  ${ClaimFieldsFragmentDoc}
`;
export const JournalEntryListFieldsFragmentDoc = gql`
  fragment JournalEntryListFields on JournalEntry {
    id
    ownerId
    glId
    jeId
    unitId
    amount
    description
    posted
    propertyId
    paymentMethod
    ref
    gstInfo
    printedZ
    voidedZ
    requestId
    payee
    payeeId
    payeeName
    clearableId
    clearableLinked
    lines {
      id
      glId
      ownerId
      propertyId
      unitId
      amount
      description
      clearableId
    }
    reconciliationId
    approverName
  }
`;
export const ManagerFieldsFragmentDoc = gql`
  fragment ManagerFields on Manager {
    id
    name
    email
    phone
    roles
    enabled
    accountId
    signature {
      id
      signerId
      signature
      signerName
      signerEntity
      isAgent
      agentRelationship
    }
    subscriptions {
      id
      endpoints {
        id
        address
      }
    }
  }
`;
export const ContactFieldsFragmentDoc = gql`
  fragment ContactFields on Contact {
    id
    accountId
    entityId
    type
    name
    emails
    phones
    address {
      suite
      street
      city
      province
      postal
    }
  }
`;
export const UnitListFieldsFragmentDoc = gql`
  fragment UnitListFields on Unit {
    id
    name
    ownerId
    occupancy
    floorplanId
    reserveAmount
    property {
      id
      name
      key
      imageKey
      autopayCollectionsEnabled
    }
    buildingId
  }
`;
export const OwnerFieldsFragmentDoc = gql`
  fragment OwnerFields on Owner {
    id
    name
    dob
    email
    phones
    enabled
    address {
      ...AddressFields
    }
    mailingAddress {
      ...AddressFields
    }
    correspondenceMethod
    profileId
    pets {
      ...PetFields
    }
    vehicles {
      ...VehicleFields
    }
    propertyIds
    unitIds
    assetFees {
      assetId
      feeRate
      feeAmount
      flatFee
      nrtFeeAmount
      nrtFeeRate
    }
    gstNumber
    gstFiling
    yearEndMonth
    feeRate
    feeAmount
    flatFee
    nrtFeeAmount
    nrtFeeRate
    preferredPaymentMethod
    padAmount
    padInstitution
    padTransit
    padAccount
    trackCondoFees
    booksSinceZ
    autopayInstitution
    autopayAccount
    autopayTransit
    autopayStatus
    autopayInvitedZ
    autopayStatusUpdatedZ
    autopayCollectionsEnabled
    contacts {
      ...ContactFields
    }
    properties {
      id
      address {
        street
        city
        province
        postal
      }
      name
      key
      leasedUnits
      totalUnits
      imageKey
      reserveAmount
    }
    units {
      ...UnitListFields
    }
    history {
      assetType
      status
      assetId
      createdZ
      leasedUnits
      commercialLeasedUnits
    }
    emergencyContacts {
      name
      relationship
      phone
    }
  }
  ${AddressFieldsFragmentDoc}
  ${PetFieldsFragmentDoc}
  ${VehicleFieldsFragmentDoc}
  ${ContactFieldsFragmentDoc}
  ${UnitListFieldsFragmentDoc}
`;
export const OwnerListFieldsFragmentDoc = gql`
  fragment OwnerListFields on Owner {
    id
    name
    address {
      city
      postal
      province
      suite
      street
    }
    email
    phones
    enabled
    booksSinceZ
    propertyHeaders {
      id
      name
      key
    }
    unitHeaders {
      id
      propertyId
      propertyName
      name
      occupancy
    }
    propertyIds
    unitIds
    preferredPaymentMethod
    padAmount
    profileId
    autopayClientId
    autopayStatus
    history {
      assetType
      assetId
      leasedUnits
      commercialLeasedUnits
    }
    units {
      id
      paymentDetails {
        preferredPaymentMethod
        padAmount
        padInstitution
        padTransit
        padAccount
      }
    }
  }
`;
export const OwnerWithNotesListFieldsFragmentDoc = gql`
  fragment OwnerWithNotesListFields on Owner {
    ...OwnerListFields
    correspondenceMethod
    address {
      suite
      street
      city
      province
      postal
    }
    notes {
      id
      createdName
      createdSub
      createdZ
      text
    }
  }
  ${OwnerListFieldsFragmentDoc}
`;
export const AmenityFieldsFragmentDoc = gql`
  fragment AmenityFields on Amenity {
    id
    name
    category
  }
`;
export const AmenitiesSettingFieldsFragmentDoc = gql`
  fragment AmenitiesSettingFields on AmenitiesSetting {
    amenities {
      ...AmenityFields
    }
  }
  ${AmenityFieldsFragmentDoc}
`;
export const PropertyEntityFieldsFragmentDoc = gql`
  fragment PropertyEntityFields on Property {
    id
    ownerId
    enabled
    address {
      ...AddressFields
    }
    name
    key
    leasedUnits
    totalUnits
    totalUnitFactor
    commonAreas
    ownerUnits
    commercialLeasedUnits
    condoUnits
    imageKey
    timezone
    commercialUnits
    rentalUnits
    phone
    parking {
      name
      unitId
    }
    storage {
      name
      unitId
    }
    keyring {
      name
      unitId
    }
    types
    supplierIds
    contractorIds
    legalDescription
    creatingUnits
    houseRules
    onsiteManager {
      id
      entityType
    }
    owner {
      id
      name
    }
    autopayCollectionsEnabled
    rentalLeaseDocumentTemplateId
    commercialLeaseDocumentTemplateId
  }
  ${AddressFieldsFragmentDoc}
`;
export const BuildingFieldsFragmentDoc = gql`
  fragment BuildingFields on Building {
    id
    address {
      street
      city
      province
      postal
      lat
      lng
    }
    name
    key
    imageKey
    timezone
    phone
    ownerId
    types
    legalDescription
  }
`;
export const UnitEntityFieldsFragmentDoc = gql`
  fragment UnitEntityFields on Unit {
    id
    name
    legalAddress
    sqft
    bedrooms
    bathrooms
    additionalInformation
    occupancy
    parking
    storage
    keyring
    unitFactor
    condoFee
    condoUnitFees {
      feeId
      amount
    }
    condoFeeGst
    preferredPaymentMethod
    notes {
      id
      createdName
      createdSub
      createdZ
      text
    }
    propertyId
    ownerId
    buildingId
    parking
    storage
  }
`;
export const UnitHistoryFieldsFragmentDoc = gql`
  fragment UnitHistoryFields on Unit {
    history {
      id
      name
      fingerprints {
        createdZ
      }
      ... on Request {
        summary
        status
        delay
        tenant {
          name
        }
      }
    }
  }
`;
export const UnitFieldsFragmentDoc = gql`
  fragment UnitFields on Unit {
    id
    ...UnitEntityFields
    ...UnitHistoryFields
    property {
      id
      enabled
      name
      phone
      imageKey
      timezone
      ownerId
      storage {
        name
        unitId
      }
      parking {
        name
        unitId
      }
      keyring {
        name
        unitId
      }
      owner {
        id
        name
        address {
          ...AddressFields
        }
        email
        phones
      }
      address {
        street
        city
        province
        postal
      }
    }
    building {
      ...BuildingFields
    }
    owner {
      id
      name
      address {
        ...AddressFields
      }
      email
      phones
    }
  }
  ${UnitEntityFieldsFragmentDoc}
  ${UnitHistoryFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${BuildingFieldsFragmentDoc}
`;
export const PropertyFieldsFragmentDoc = gql`
  fragment PropertyFields on Property {
    id
    ...PropertyEntityFields
    buildings {
      ...BuildingFields
    }
    units {
      ...UnitFields
      owner {
        id
        name
      }
      parking
      storage
      keyring
    }
  }
  ${PropertyEntityFieldsFragmentDoc}
  ${BuildingFieldsFragmentDoc}
  ${UnitFieldsFragmentDoc}
`;
export const PropertyListFieldsFragmentDoc = gql`
  fragment PropertyListFields on Property {
    id
    ...PropertyEntityFields
    buildings {
      id
      name
    }
  }
  ${PropertyEntityFieldsFragmentDoc}
`;
export const PropertySpecsFieldsFragmentDoc = gql`
  fragment PropertySpecsFields on PropertySpecs {
    key
    value
  }
`;
export const RecurrenceFieldsFragmentDoc = gql`
  fragment RecurrenceFields on Recurrence {
    id
    name
    rrule
    lastPosted
    output {
      lines {
        glId
        propertyId
        unitId
        ownerId
        description
        amount
        payee
        payeeId
      }
      clearable {
        payee
        payeeId
        postedForDue
      }
    }
  }
`;
export const RegionTaxDetailsFieldsFragmentDoc = gql`
  fragment RegionTaxDetailsFields on RegionTax {
    id
    region
    country
    taxes {
      id
      label
      rate
      gst
      postals
    }
    defaultTaxes
  }
`;
export const VisitFieldsFragmentDoc = gql`
  fragment VisitFields on Visit {
    id
    status
    delay
    dueZ
    appointmentZ
    operatorId
    operatorName
    minutes
    tenantProposedTimes
    category
    instructions
    materialCosts
    materialCostsNote
    materialMarkup
    labourCost
    completedDelta
    completedZ
    journalId
    notes {
      id
      target
      source
      createdName
      createdSub
      createdZ
      text
    }
    bill {
      date
      totalAmount
      ref
      due
      gstAmount
      key
    }
  }
`;
export const OperatorRequestFieldsFragmentDoc = gql`
  fragment OperatorRequestFields on Operator {
    id
    name
    location
    email
    phone
    availability
    operatorType
  }
`;
export const RequestFieldsFragmentDoc = gql`
  fragment RequestFields on Request {
    id
    name
    summary
    category
    ownerApprovalId
    ownerApprovalStatus
    approval {
      id
      status
      requestedZ
      approvedZ
      declinedZ
      request {
        ownerId
        amount
        description
      }
    }
    status
    cancelledReason
    delay
    originalRequest
    details
    appointmentZ
    submittedZ
    operatorName
    minutes
    instructions
    firstOccurred
    consents
    entryConsentDetails
    nonBillable
    printNotes
    autoSummary
    resolution
    finalizedName
    finalizedZ
    fields {
      label
      value
    }
    visits {
      ...VisitFields
    }
    notes {
      id
      target
      source
      createdName
      createdSub
      createdZ
      text
    }
    images {
      key
      notes {
        id
        createdName
        createdSub
        createdZ
        text
      }
    }
    history {
      status
      createdZ
    }
    appointmentPreferences {
      start
    }
    operator {
      ...OperatorRequestFields
    }
    tenant {
      id
      name
      phone
    }
    unit {
      ...UnitFields
    }
    costs {
      id
      date
      description
      amount
      markup
    }
    checklist {
      id
      name
      completedZ
      order
    }
    fingerprints {
      createdZ
      createdSub
    }
    source
    taxExempted
    journalId
  }
  ${VisitFieldsFragmentDoc}
  ${OperatorRequestFieldsFragmentDoc}
  ${UnitFieldsFragmentDoc}
`;
export const RequestListFieldsFragmentDoc = gql`
  fragment RequestListFields on Request {
    id
    name
    summary
    category
    ownerApprovalId
    ownerApprovalStatus
    visits {
      ...VisitFields
    }
    status
    delay
    occupant {
      name
    }
    tenant {
      name
    }
    unit {
      id
      name
      occupancy
      propertyId
      property {
        id
        name
        address {
          street
          city
          province
          postal
        }
      }
      ownerId
    }
    submittedZ
    finalizedZ
    fingerprints {
      createdZ
    }
  }
  ${VisitFieldsFragmentDoc}
`;
export const ServerRequestListFieldsFragmentDoc = gql`
  fragment ServerRequestListFields on Request {
    id
    name
    summary
    category
    status
    delay
    ownerId
    ownerApprovalId
    ownerApprovalStatus
    finalizedZ
    openedZ
    finalizedZ
    occupant {
      name
    }
    visits {
      ...VisitFields
    }
    tenantId
    tenantName
    unitId
    unitName
    unitOccupancy
    buildingId
    buildingKey
    buildingName
    buildingAddress {
      street
      city
      province
      postal
    }
    propertyId
    propertyKey
    propertyName
    propertyAddress {
      street
      city
      province
      postal
    }
  }
  ${VisitFieldsFragmentDoc}
`;
export const RoutineInspectionFieldsFragmentDoc = gql`
  fragment RoutineInspectionFields on Inspection {
    id
    status
    completedZ
    templateId
    category
    items {
      ...InspectionItemFields
    }
    signatures {
      ...SignatureFields
    }
    description
    generatedSummary {
      summary
      score
    }
  }
  ${InspectionItemFieldsFragmentDoc}
  ${SignatureFieldsFragmentDoc}
`;
export const TemplateFieldsFragmentDoc = gql`
  fragment TemplateFields on Template {
    id
    enabled
    default
    category
    minutes
    response
    fields {
      label
      type
      info
      imageKey
    }
  }
`;
export const TenantFieldsFragmentDoc = gql`
  fragment TenantFields on Tenant {
    id
    name
    emails
    phone
    phones
    leaseHolder
    unitId
    propertyId
    type
    isOwner
    preferredPaymentMethod
    padAmount
    padInstitution
    padTransit
    padAccount
    emergencyContacts {
      name
      phone
      relationship
    }
    correspondenceMethod
    profileId
    notes {
      id
      createdName
      createdSub
      createdZ
      text
    }
    enabled
    pets {
      ...PetFields
    }
    vehicles {
      ...VehicleFields
    }
    autopayClientId
    autopayInstitution
    autopayTransit
    autopayAccount
    autopayStatus
    autopayInvitedZ
    autopayCollectionsEnabled
  }
  ${PetFieldsFragmentDoc}
  ${VehicleFieldsFragmentDoc}
`;
export const ResidencyEntityFieldsFragmentDoc = gql`
  fragment ResidencyEntityFields on Residency {
    id
    terms
    startZ
    endZ
    renewZ
    securityDeposit
    securityDepositHistory {
      createdZ
      allocations {
        status
        amount
      }
    }
    eventHistory {
      id
      name
      category
      description
      eventDateZ
      deletedZ
      resolvedZ
      noticeZ
      noteText
    }
    gst
    type
    occupancy
    unitId
    propertyId
    moveInDate
    moveOutDate
    moveOutReason
    applicationId
    campaignId
    insurancePolicies {
      id
      policyNumber
      providerName
      expirationDate
      liability
    }
    residentInsurancePolicies {
      id
      policyNumber
      providerName
      liability
      expiry
    }
    rrule
    notes {
      id
      target
      source
      createdName
      createdSub
      createdZ
      text
    }
    additionalProvisions {
      pets
      parking
      storage
      smoking
    }
    utilities
    furnishings
    collectionDay
  }
`;
export const ResidencyFieldsFragmentDoc = gql`
  fragment ResidencyFields on Residency {
    id
    ...ResidencyEntityFields
    accountId
    effects {
      id
      __typename
      feeId
      startZ
      effect
      ... on IncentiveEffect {
        description
        incentiveId
      }
    }
    proratedClearableIds
    residents {
      id
      residentId
      leaseHolder
      title
      payeeFrac {
        numerator
        denominator
      }
      paymentDetails {
        preferredPaymentMethod
        padAmount
        padInstitution
        padTransit
        padAccount
      }
      resident {
        id
        __typename
        name
        emails
        phones
        ... on Tenant {
          phone
          dob
          vehicles {
            ...VehicleFields
          }
          isRentalAssist
          rentalAssistance {
            qualified
            amount
            source
          }
          pets {
            ...PetFields
          }
          mailingAddress {
            ...AddressFields
          }
        }
        ... on Owner {
          preferredPaymentMethod
          padAmount
          padInstitution
          padTransit
          padAccount
        }
        emergencyContacts {
          name
          relationship
          phone
        }
        correspondenceMethod
        enabled
      }
    }
    refundables {
      id
      name
      amount
      templateId
      accrualStartExpr
      collectedZ
      heldByOwner
      ledger {
        id
        category
        amount
        date
      }
    }
    moveInInspectionId
    moveOutInspectionId
  }
  ${ResidencyEntityFieldsFragmentDoc}
  ${VehicleFieldsFragmentDoc}
  ${PetFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;
export const UnitFieldsWithResidencyFragmentDoc = gql`
  fragment UnitFieldsWithResidency on Unit {
    id
    ...UnitFields
    allResidencies {
      id
      ...ResidencyFields
    }
  }
  ${UnitFieldsFragmentDoc}
  ${ResidencyFieldsFragmentDoc}
`;
export const UnitListFieldsWithResidencyFragmentDoc = gql`
  fragment UnitListFieldsWithResidency on Unit {
    id
    name
    legalAddress
    ownerId
    occupancy
    property {
      id
      name
      key
      imageKey
    }
    allResidencies {
      id
      startZ
      endZ
      residents {
        id
        leaseHolder
        payeeFrac {
          numerator
          denominator
        }
        resident {
          __typename
          id
          name
          phones
          ... on Tenant {
            emails
          }
          ... on Owner {
            email
          }
        }
      }
    }
    buildingId
  }
`;
export const FeeFieldsFragmentDoc = gql`
  fragment FeeFields on Fee {
    id
    name
    deletedZ
  }
`;
export const LeaseDocumentTemplateListFieldsFragmentDoc = gql`
  fragment LeaseDocumentTemplateListFields on LeaseDocumentTemplate {
    id
    name
    leaseType
    propertyIds
  }
`;
export const LeaseDocumentTemplateFieldsFragmentDoc = gql`
  fragment LeaseDocumentTemplateFields on LeaseDocumentTemplate {
    id
    name
    leaseType
    propertyIds
    schemas
  }
`;
export const TeamFieldsFragmentDoc = gql`
  fragment TeamFields on Team {
    id
    name
    propertyIds
    managerIds
    occupancies
    propertyUnitIds {
      propertyId
      unitId
    }
  }
`;
export const ScanApprovalFieldsFragmentDoc = gql`
  fragment ScanApprovalFields on ScanApproval {
    request {
      amount
      description
      due
      expenseGlId
      gstOrHstAllocation
      includesGstOrHst
      key
      ownerId
      payee
      payeeId
      payeeName
      posted
      propertyId
      ref
      scanType
      supplierAccount
      lines {
        glId
        amount
        description
        unitId
      }
    }
  }
`;
export const JournalPostedDetailFragmentDoc = gql`
  fragment JournalPostedDetail on JournalEntry {
    id
    jeId
    journalIds
    glId
    propertyId
    ownerId
    clearableId
    unitId
    posted
    amount
    description
    ref
    lines {
      id
      glId
      ownerId
      propertyId
      unitId
      amount
      description
      ref
      clearableId
      requestId
      payee
      payeeId
    }
  }
`;
export const AccountChargesFieldsFragmentDoc = gql`
  fragment AccountChargesFields on Account {
    id
    books {
      accountId
      chargeMapping {
        chargeId
        glId
      }
    }
    charges {
      id
      name
      default
      limit
    }
  }
`;
export const ReconciliationScopeFieldsFragmentDoc = gql`
  fragment ReconciliationScopeFields on ReconciliationScope {
    glId
    propertyOwnerIds
  }
`;
export const ReconciliationFieldsFragmentDoc = gql`
  fragment ReconciliationFields on Reconciliation {
    id
    name
    current
    configuration
    scope {
      ...ReconciliationScopeFields
    }
    glAccount {
      id
      name
    }
    statementDate
    status
    errorMessage
    statementBalance
    lastStatementBalance
    lastReconciliationId
    booksBatchId
    reindexToken
    completedZ
    documentKey
    ledgerDocumentKey
  }
  ${ReconciliationScopeFieldsFragmentDoc}
`;
export const ReconciliationConfigFieldsFragmentDoc = gql`
  fragment ReconciliationConfigFields on ReconciliationConfig {
    id
    name
    configuration
    bankId
    scope {
      ...ReconciliationScopeFields
    }
  }
  ${ReconciliationScopeFieldsFragmentDoc}
`;
export const AnnouncementsFieldsFragmentDoc = gql`
  fragment AnnouncementsFields on Announcement {
    id
    name
    author
    status
    scope {
      propertyIds
      unitIds
      omitIds
    }
    email {
      subject
      body
    }
    voice {
      text
    }
    publishZ
    fingerprints {
      createdZ
    }
    totalRecipients
    attachments {
      key
      name
      type
    }
    replies {
      id
      createdZ
      createdName
      createdSub
      attachments {
        key
        type
      }
      text
      managerReply
      email
      replyId
    }
  }
`;
export const LinkedAccountFieldsFragmentDoc = gql`
  fragment LinkedAccountFields on LinkedAccount {
    id
    institutionName
    accountDetails {
      accountNumber4Digit
      type
      currency
    }
  }
`;
export const AutopayBankFieldsFragmentDoc = gql`
  fragment AutopayBankFields on Bank {
    id
    name
    transitNumber
    accountNumber
    institutionNumber
  }
`;
export const AutopayFieldsFragmentDoc = gql`
  fragment AutopayFields on Autopay {
    accountId
    activated
    activatedZ
    activationStatus
    version
    enablePayments
    enableCollection
    deactivatedZ
    collectionTypes
    feeExpense
    propraSupplier
    undepositedFunds
    nsfFee
    feeBankAccountId
    feeInvoiceChargeMethod
    invitePayees
    linkedAccounts {
      ...LinkedAccountFields
    }
    banks {
      ...AutopayBankFields
    }
  }
  ${LinkedAccountFieldsFragmentDoc}
  ${AutopayBankFieldsFragmentDoc}
`;
export const AutopayPropertyFieldsFragmentDoc = gql`
  fragment AutopayPropertyFields on Property {
    id
    name
    autopayActivatedZ
    autopayDeactivatedZ
    autopayStatus
    autopayStatusHistory {
      status
      createdZ
    }
    autopayBankAccounts {
      id
      bankAccountId
      collectionType
      propertyId
    }
    key
    leasedUnits
    ownerUnits
    commercialLeasedUnits
  }
`;
export const AutopayEnrollmentFieldsFragmentDoc = gql`
  fragment AutopayEnrollmentFields on AutopayEnrollment {
    id
    payeeId
    payeeType
    invitedZ
    residencyId
    propertyId
    unitId
    status
    statusHistory {
      status
      createdZ
      description
    }
  }
`;
export const CampaignListFieldsFragmentDoc = gql`
  fragment CampaignListFields on Campaign {
    id
    accountId
    propertyId
    link
    property {
      id
      enabled
      address {
        street
        city
        province
        postal
      }
      name
      key
      imageKey
    }
    name
    status
    listedUnits {
      ...ListedUnitFields
    }
    applications {
      id
      campaignId
      leaseId
      residencyId
      profileId
      status
    }
    history {
      status
      createdZ
    }
    systemCreated
  }
  ${ListedUnitFieldsFragmentDoc}
`;
export const OperatorFieldsFragmentDoc = gql`
  fragment OperatorFields on Operator {
    id
    name
    location
    website
    email
    phone
    enabled
    operatorType
    skills
    availability
    exceptions {
      comment
      interval {
        start
        end
      }
    }
    totalAssignedVisits
    totalCompletedVisits
    totalCancelledVisits
    totalStartedVisits
    timezone
    hasNonFinalizedVisits
    address {
      country
      suite
      street
      city
      province
      postal
    }
    hasCOI
    expiryCOI
    businessNumber
    autopayInstitution
    autopayTransit
    autopayAccount
    autopayStatus
    autopayStatusUpdatedZ
    autopayClientId
    expenseGlId
  }
`;
export const OperatorRequestHistoryFieldsFragmentDoc = gql`
  fragment OperatorRequestHistoryFields on Request {
    id
    name
    summary
    status
    delay
    appointmentZ
    tenant {
      id
      name
    }
    fingerprints {
      createdZ
    }
  }
`;
export const OperatorListFieldsFragmentDoc = gql`
  fragment OperatorListFields on Operator {
    id
    name
    location
    email
    phone
    enabled
    operatorType
    availability
    skills
    hasCOI
    expiryCOI
    autopayClientId
    autopayStatus
  }
`;
export const ContactListOwnerFieldsFragmentDoc = gql`
  fragment ContactListOwnerFields on Owner {
    id
    name
    address {
      ...AddressFields
    }
    mailingAddress {
      ...AddressFields
    }
    correspondenceMethod
    email
    phones
    enabled
    contacts {
      accountId
      entityId
      id
      name
      emails
      phones
      address {
        ...AddressFields
      }
    }
    notes {
      id
      createdName
      createdSub
      createdZ
      text
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export const FloorplanListFieldsFragmentDoc = gql`
  fragment FloorplanListFields on Floorplan {
    id
    enabled
    default
    name
    description
    interiorSqFt
    exteriorSqFt
    marketRate
    bedrooms
    bathrooms
    areas {
      id
      category
      label
      description
      features {
        id
        description
        label
        type
        name
      }
    }
  }
`;
export const CondoFeeUnitFieldsFragmentDoc = gql`
  fragment CondoFeeUnitFields on Unit {
    id
    name
    unitFactor
    condoFee
    ownerId
    condoFeeGst
    condoUnitFees {
      feeId
      amount
    }
    paymentDetails {
      preferredPaymentMethod
      padAmount
      padInstitution
      padTransit
      padAccount
    }
    building {
      id
      name
      key
    }
  }
`;
export const FloorplanFieldsFragmentDoc = gql`
  fragment FloorplanFields on Floorplan {
    id
    enabled
    default
    name
    description
    bedrooms
    bathrooms
    interiorSqFt
    exteriorSqFt
    marketRate
    totalUnits
    areas {
      id
      category
      label
      description
      features {
        id
        description
        label
        type
        name
      }
    }
    additionalFeatures
    links
    units {
      id
      name
      occupancy
    }
  }
`;
export const ResidencySecurityDepositFieldsFragmentDoc = gql`
  fragment ResidencySecurityDepositFields on Residency {
    id
    securityDeposit
    securityDepositHistory {
      createdZ
      allocations {
        status
        amount
      }
    }
  }
`;
export const PropertyDataForRequestFragmentDoc = gql`
  fragment PropertyDataForRequest on Property {
    id
    name
    ownerId
    address {
      street
      city
      postal
      province
      lat
      lng
    }
    phone
    timezone
    key
    account {
      id
      phone
    }
  }
`;
export const UnitResidencyDataForRequestFragmentDoc = gql`
  fragment UnitResidencyDataForRequest on Unit {
    id
    name
    ownerId
    occupancy
    property {
      ...PropertyDataForRequest
    }
    currentResidencies {
      id
      residents {
        id
        payeeFrac {
          numerator
          denominator
        }
        resident {
          __typename
          id
          name
          ... on Tenant {
            emails
            phones
          }
          ... on Owner {
            email
            phones
          }
        }
      }
    }
  }
  ${PropertyDataForRequestFragmentDoc}
`;
export const LeaseFormFieldsFragmentDoc = gql`
  fragment LeaseFormFields on LeaseForm {
    id
    province
    name
    key
    description
    inputFields {
      id
      label
      subLabel
      type
      required
      defaultValue
      options {
        label
        value
      }
      hiddenExpression
      optionsExpression
      helpText
    }
    bindings
  }
`;
export const ResidencyOutstandingFieldsFragmentDoc = gql`
  fragment ResidencyOutstandingFields on ResidencyOutstanding {
    clearables {
      id
      posted
      glId
      payeeId
      amount
      outstanding
      due
    }
    totalOutstanding
  }
`;
export const RequestRecurrenceFragmentDoc = gql`
  fragment RequestRecurrence on RequestRecurrence {
    id
    accountId
    rrule
    unit {
      id
      name
      occupancy
      ownerId
      propertyId
      property {
        id
        name
        address {
          street
          city
          province
          postal
        }
      }
    }
    latestRequest {
      id
      status
      resolution
      autoSummary
      summary
      submittedZ
      details
      originalRequest
      history {
        createdZ
        status
      }
    }
    name
    category
    subcategory
    originalRequest
    details
    instructions
    minutes
    summary
    notes {
      id
      createdName
      createdSub
      createdZ
      text
    }
    nonBillable
    checklist {
      id
      name
      order
    }
    nextRecurrenceZ
  }
`;
export const InsuranceResidencyFieldsFragmentDoc = gql`
  fragment InsuranceResidencyFields on Residency {
    id
    startZ
    endZ
    renewZ
    insurancePolicies {
      id
      policyNumber
      providerName
      expirationDate
      liability
    }
    residentInsurancePolicies {
      id
      policyNumber
      providerName
      liability
      expiry
    }
    propertyId
    propertyName
    propertyKey
    propertyAddress {
      street
      suite
      city
      province
      postal
    }
    unitId
    unitName
    unitOccupancy
    residents {
      id
      residentId
      leaseHolder
      resident {
        __typename
        id
        enabled
        name
        emails
        phones
        ... on Tenant {
          phone
          enabled
        }
      }
    }
  }
`;
export const TenantResidencyFragmentDoc = gql`
  fragment TenantResidency on Residency {
    id
    startZ
    endZ
    unitId
    propertyId
    moveInDate
    moveOutDate
    moveOutReason
    gst
    unit {
      id
      name
      occupancy
      ownerId
      property {
        id
        name
        ownerId
        enabled
        timezone
        address {
          street
          city
          province
          postal
        }
        autopayCollectionsEnabled
      }
      building {
        id
        name
        key
        address {
          street
          city
          province
          postal
          lat
          lng
        }
      }
    }
    effects {
      id
      __typename
      feeId
      startZ
      effect
      ... on IncentiveEffect {
        description
        incentiveId
      }
    }
    residents {
      id
      payeeFrac {
        numerator
        denominator
      }
      leaseHolder
      residentId
      paymentDetails {
        preferredPaymentMethod
        padAmount
        padInstitution
        padTransit
        padAccount
      }
      resident {
        __typename
        id
        name
        emails
        phones
        ... on Tenant {
          phone
        }
      }
    }
  }
`;
export const TenantWithResidencyFragmentDoc = gql`
  fragment TenantWithResidency on Tenant {
    id
    name
    dob
    emails
    phone
    phones
    leaseHolder
    unitId
    propertyId
    type
    isOwner
    profileId
    isRentalAssist
    rentalAssistance {
      qualified
      amount
      source
    }
    allResidencies {
      id
      ...TenantResidency
    }
    emergencyContacts {
      name
      phone
      relationship
    }
    correspondenceMethod
    profileId
    enabled
    pets {
      ...PetFields
    }
    vehicles {
      ...VehicleFields
    }
    mailingAddress {
      suite
      street
      city
      province
      postal
    }
    autopayStatus
    autopayInvitedZ
    autopayInstitution
    autopayTransit
    autopayAccount
    autopayCollectionsEnabled
  }
  ${TenantResidencyFragmentDoc}
  ${PetFieldsFragmentDoc}
  ${VehicleFieldsFragmentDoc}
`;
export const ListPayeeClearablesDocument = gql`
  query ListPayeeClearables($nextToken: String, $payeeId: ID!) {
    account {
      id
      books {
        accountId
        payeeClearables(nextToken: $nextToken, payeeId: $payeeId) {
          items {
            id
            due
            posted
            glId
            ownerId
            sourceJournalId
            sourceJournalEntry {
              id
              ownerId
              glId
              jeId
              unitId
              amount
              description
              posted
              propertyId
              paymentMethod
              ref
              gstInfo
              printedZ
              voidedZ
              requestId
              payee
              payeeId
              payeeName
              reconciliationId
            }
            payeeId
            payeeName
            unitId
            propertyId
            payee
            amount
            outstanding
            cleared
            ref
            balanceType
            batchId
            refund
            enrollmentId
            fixedEnrollmentId
          }
          nextToken
        }
      }
    }
  }
`;

/**
 * __useListPayeeClearablesQuery__
 *
 * To run a query within a React component, call `useListPayeeClearablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPayeeClearablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPayeeClearablesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      payeeId: // value for 'payeeId'
 *   },
 * });
 */
export function useListPayeeClearablesQuery(
  baseOptions: Apollo.QueryHookOptions<ListPayeeClearablesQuery, ListPayeeClearablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPayeeClearablesQuery, ListPayeeClearablesQueryVariables>(
    ListPayeeClearablesDocument,
    options
  );
}
export function useListPayeeClearablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListPayeeClearablesQuery,
    ListPayeeClearablesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPayeeClearablesQuery, ListPayeeClearablesQueryVariables>(
    ListPayeeClearablesDocument,
    options
  );
}
export type ListPayeeClearablesQueryHookResult = ReturnType<typeof useListPayeeClearablesQuery>;
export type ListPayeeClearablesLazyQueryHookResult = ReturnType<
  typeof useListPayeeClearablesLazyQuery
>;
export type ListPayeeClearablesQueryResult = Apollo.QueryResult<
  ListPayeeClearablesQuery,
  ListPayeeClearablesQueryVariables
>;
export const CreateChargeApprovalDocument = gql`
  mutation CreateChargeApproval($input: CreateChargeApprovalInput!) {
    createChargeApproval(input: $input) {
      success
      error
      approval {
        ...ChargeApprovalFields
      }
    }
  }
  ${ChargeApprovalFieldsFragmentDoc}
`;
export type CreateChargeApprovalMutationFn = Apollo.MutationFunction<
  CreateChargeApprovalMutation,
  CreateChargeApprovalMutationVariables
>;

/**
 * __useCreateChargeApprovalMutation__
 *
 * To run a mutation, you first call `useCreateChargeApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargeApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargeApprovalMutation, { data, loading, error }] = useCreateChargeApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChargeApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChargeApprovalMutation,
    CreateChargeApprovalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChargeApprovalMutation, CreateChargeApprovalMutationVariables>(
    CreateChargeApprovalDocument,
    options
  );
}
export type CreateChargeApprovalMutationHookResult = ReturnType<
  typeof useCreateChargeApprovalMutation
>;
export type CreateChargeApprovalMutationResult =
  Apollo.MutationResult<CreateChargeApprovalMutation>;
export type CreateChargeApprovalMutationOptions = Apollo.BaseMutationOptions<
  CreateChargeApprovalMutation,
  CreateChargeApprovalMutationVariables
>;
export const GetAccountChargesDocument = gql`
  query GetAccountCharges {
    account {
      id
      name
      address {
        street
        city
        province
        postal
      }
      charges {
        id
        default
        limit
        name
      }
    }
  }
`;

/**
 * __useGetAccountChargesQuery__
 *
 * To run a query within a React component, call `useGetAccountChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountChargesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountChargesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAccountChargesQuery, GetAccountChargesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountChargesQuery, GetAccountChargesQueryVariables>(
    GetAccountChargesDocument,
    options
  );
}
export function useGetAccountChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountChargesQuery, GetAccountChargesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountChargesQuery, GetAccountChargesQueryVariables>(
    GetAccountChargesDocument,
    options
  );
}
export type GetAccountChargesQueryHookResult = ReturnType<typeof useGetAccountChargesQuery>;
export type GetAccountChargesLazyQueryHookResult = ReturnType<typeof useGetAccountChargesLazyQuery>;
export type GetAccountChargesQueryResult = Apollo.QueryResult<
  GetAccountChargesQuery,
  GetAccountChargesQueryVariables
>;
export const PayeePropertiesDocument = gql`
  query PayeeProperties($payeeId: ID!) {
    tenant(id: $payeeId) {
      id
      name
      propertyOptions {
        entityName
        ownerName
        occupancy
        unitId
        propertyId
        ownerId
        propertyAddress {
          street
          city
          province
          postal
        }
      }
    }
    owner(id: $payeeId) {
      id
      name
      propertyOptions {
        entityName
        ownerName
        occupancy
        unitId
        propertyId
        ownerId
        propertyAddress {
          street
          city
          province
          postal
        }
      }
    }
  }
`;

/**
 * __usePayeePropertiesQuery__
 *
 * To run a query within a React component, call `usePayeePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayeePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayeePropertiesQuery({
 *   variables: {
 *      payeeId: // value for 'payeeId'
 *   },
 * });
 */
export function usePayeePropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<PayeePropertiesQuery, PayeePropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PayeePropertiesQuery, PayeePropertiesQueryVariables>(
    PayeePropertiesDocument,
    options
  );
}
export function usePayeePropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PayeePropertiesQuery, PayeePropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PayeePropertiesQuery, PayeePropertiesQueryVariables>(
    PayeePropertiesDocument,
    options
  );
}
export type PayeePropertiesQueryHookResult = ReturnType<typeof usePayeePropertiesQuery>;
export type PayeePropertiesLazyQueryHookResult = ReturnType<typeof usePayeePropertiesLazyQuery>;
export type PayeePropertiesQueryResult = Apollo.QueryResult<
  PayeePropertiesQuery,
  PayeePropertiesQueryVariables
>;
export const ListUnitClearablesDocument = gql`
  query ListUnitClearables($nextToken: String, $unitId: ID!) {
    account {
      id
      books {
        accountId
        unitClearables(nextToken: $nextToken, unitId: $unitId) {
          items {
            id
            due
            posted
            glId
            ownerId
            sourceJournalId
            sourceJournalEntry {
              id
              ownerId
              glId
              jeId
              unitId
              amount
              description
              posted
              propertyId
              paymentMethod
              ref
              gstInfo
              printedZ
              voidedZ
              requestId
              payee
              payeeId
              payeeName
              reconciliationId
            }
            payeeId
            payeeName
            unitId
            propertyId
            payee
            amount
            outstanding
            cleared
            ref
            balanceType
            batchId
            refund
            autopaySinglePosted
            payeeAutopayCollectionsEnabled
            autopayCollectionsEnabled
            payeeAutopayStatus
          }
          nextToken
        }
      }
    }
  }
`;

/**
 * __useListUnitClearablesQuery__
 *
 * To run a query within a React component, call `useListUnitClearablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUnitClearablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUnitClearablesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useListUnitClearablesQuery(
  baseOptions: Apollo.QueryHookOptions<ListUnitClearablesQuery, ListUnitClearablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListUnitClearablesQuery, ListUnitClearablesQueryVariables>(
    ListUnitClearablesDocument,
    options
  );
}
export function useListUnitClearablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUnitClearablesQuery,
    ListUnitClearablesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListUnitClearablesQuery, ListUnitClearablesQueryVariables>(
    ListUnitClearablesDocument,
    options
  );
}
export type ListUnitClearablesQueryHookResult = ReturnType<typeof useListUnitClearablesQuery>;
export type ListUnitClearablesLazyQueryHookResult = ReturnType<
  typeof useListUnitClearablesLazyQuery
>;
export type ListUnitClearablesQueryResult = Apollo.QueryResult<
  ListUnitClearablesQuery,
  ListUnitClearablesQueryVariables
>;
export const TeamPickerDocument = gql`
  query TeamPicker {
    account {
      id
      teams {
        ...TeamFields
      }
    }
  }
  ${TeamFieldsFragmentDoc}
`;

/**
 * __useTeamPickerQuery__
 *
 * To run a query within a React component, call `useTeamPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamPickerQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamPickerQuery(
  baseOptions?: Apollo.QueryHookOptions<TeamPickerQuery, TeamPickerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamPickerQuery, TeamPickerQueryVariables>(TeamPickerDocument, options);
}
export function useTeamPickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamPickerQuery, TeamPickerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamPickerQuery, TeamPickerQueryVariables>(
    TeamPickerDocument,
    options
  );
}
export type TeamPickerQueryHookResult = ReturnType<typeof useTeamPickerQuery>;
export type TeamPickerLazyQueryHookResult = ReturnType<typeof useTeamPickerLazyQuery>;
export type TeamPickerQueryResult = Apollo.QueryResult<TeamPickerQuery, TeamPickerQueryVariables>;
export const GetAccountSettingsDocument = gql`
  query GetAccountSettings {
    account {
      id
      settings {
        soon_days_threshold
        later_days_threshold
      }
    }
  }
`;

/**
 * __useGetAccountSettingsQuery__
 *
 * To run a query within a React component, call `useGetAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAccountSettingsQuery, GetAccountSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountSettingsQuery, GetAccountSettingsQueryVariables>(
    GetAccountSettingsDocument,
    options
  );
}
export function useGetAccountSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountSettingsQuery,
    GetAccountSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountSettingsQuery, GetAccountSettingsQueryVariables>(
    GetAccountSettingsDocument,
    options
  );
}
export type GetAccountSettingsQueryHookResult = ReturnType<typeof useGetAccountSettingsQuery>;
export type GetAccountSettingsLazyQueryHookResult = ReturnType<
  typeof useGetAccountSettingsLazyQuery
>;
export type GetAccountSettingsQueryResult = Apollo.QueryResult<
  GetAccountSettingsQuery,
  GetAccountSettingsQueryVariables
>;
export const CreateRequestNoteDocument = gql`
  mutation CreateRequestNote($requestId: ID!, $text: String) {
    createRequestNote(input: { requestId: $requestId, text: $text }) {
      request {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type CreateRequestNoteMutationFn = Apollo.MutationFunction<
  CreateRequestNoteMutation,
  CreateRequestNoteMutationVariables
>;

/**
 * __useCreateRequestNoteMutation__
 *
 * To run a mutation, you first call `useCreateRequestNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestNoteMutation, { data, loading, error }] = useCreateRequestNoteMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateRequestNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRequestNoteMutation,
    CreateRequestNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRequestNoteMutation, CreateRequestNoteMutationVariables>(
    CreateRequestNoteDocument,
    options
  );
}
export type CreateRequestNoteMutationHookResult = ReturnType<typeof useCreateRequestNoteMutation>;
export type CreateRequestNoteMutationResult = Apollo.MutationResult<CreateRequestNoteMutation>;
export type CreateRequestNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateRequestNoteMutation,
  CreateRequestNoteMutationVariables
>;
export const DeleteRequestNoteDocument = gql`
  mutation DeleteRequestNote($requestId: ID!, $noteId: ID!) {
    deleteRequestNote(input: { requestId: $requestId, noteId: $noteId }) {
      request {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type DeleteRequestNoteMutationFn = Apollo.MutationFunction<
  DeleteRequestNoteMutation,
  DeleteRequestNoteMutationVariables
>;

/**
 * __useDeleteRequestNoteMutation__
 *
 * To run a mutation, you first call `useDeleteRequestNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestNoteMutation, { data, loading, error }] = useDeleteRequestNoteMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteRequestNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRequestNoteMutation,
    DeleteRequestNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRequestNoteMutation, DeleteRequestNoteMutationVariables>(
    DeleteRequestNoteDocument,
    options
  );
}
export type DeleteRequestNoteMutationHookResult = ReturnType<typeof useDeleteRequestNoteMutation>;
export type DeleteRequestNoteMutationResult = Apollo.MutationResult<DeleteRequestNoteMutation>;
export type DeleteRequestNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteRequestNoteMutation,
  DeleteRequestNoteMutationVariables
>;
export const DeleteImageNoteDocument = gql`
  mutation DeleteImageNote($input: DeleteImageNoteInput!) {
    deleteImageNote(input: $input) {
      id
      images {
        key
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type DeleteImageNoteMutationFn = Apollo.MutationFunction<
  DeleteImageNoteMutation,
  DeleteImageNoteMutationVariables
>;

/**
 * __useDeleteImageNoteMutation__
 *
 * To run a mutation, you first call `useDeleteImageNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteImageNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteImageNoteMutation, { data, loading, error }] = useDeleteImageNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteImageNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteImageNoteMutation,
    DeleteImageNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteImageNoteMutation, DeleteImageNoteMutationVariables>(
    DeleteImageNoteDocument,
    options
  );
}
export type DeleteImageNoteMutationHookResult = ReturnType<typeof useDeleteImageNoteMutation>;
export type DeleteImageNoteMutationResult = Apollo.MutationResult<DeleteImageNoteMutation>;
export type DeleteImageNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteImageNoteMutation,
  DeleteImageNoteMutationVariables
>;
export const CreateImageNoteDocument = gql`
  mutation CreateImageNote($input: CreateImageNoteInput!) {
    createImageNote(input: $input) {
      id
      images {
        key
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type CreateImageNoteMutationFn = Apollo.MutationFunction<
  CreateImageNoteMutation,
  CreateImageNoteMutationVariables
>;

/**
 * __useCreateImageNoteMutation__
 *
 * To run a mutation, you first call `useCreateImageNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImageNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImageNoteMutation, { data, loading, error }] = useCreateImageNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImageNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateImageNoteMutation,
    CreateImageNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateImageNoteMutation, CreateImageNoteMutationVariables>(
    CreateImageNoteDocument,
    options
  );
}
export type CreateImageNoteMutationHookResult = ReturnType<typeof useCreateImageNoteMutation>;
export type CreateImageNoteMutationResult = Apollo.MutationResult<CreateImageNoteMutation>;
export type CreateImageNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateImageNoteMutation,
  CreateImageNoteMutationVariables
>;
export const CreateUnitNoteDocument = gql`
  mutation CreateUnitNote($unitId: ID!, $text: String) {
    createUnitNote(input: { unitId: $unitId, text: $text }) {
      unit {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type CreateUnitNoteMutationFn = Apollo.MutationFunction<
  CreateUnitNoteMutation,
  CreateUnitNoteMutationVariables
>;

/**
 * __useCreateUnitNoteMutation__
 *
 * To run a mutation, you first call `useCreateUnitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnitNoteMutation, { data, loading, error }] = useCreateUnitNoteMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateUnitNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUnitNoteMutation, CreateUnitNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUnitNoteMutation, CreateUnitNoteMutationVariables>(
    CreateUnitNoteDocument,
    options
  );
}
export type CreateUnitNoteMutationHookResult = ReturnType<typeof useCreateUnitNoteMutation>;
export type CreateUnitNoteMutationResult = Apollo.MutationResult<CreateUnitNoteMutation>;
export type CreateUnitNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateUnitNoteMutation,
  CreateUnitNoteMutationVariables
>;
export const DeleteUnitNoteDocument = gql`
  mutation DeleteUnitNote($unitId: ID!, $noteId: ID!) {
    deleteUnitNote(input: { unitId: $unitId, noteId: $noteId }) {
      unit {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type DeleteUnitNoteMutationFn = Apollo.MutationFunction<
  DeleteUnitNoteMutation,
  DeleteUnitNoteMutationVariables
>;

/**
 * __useDeleteUnitNoteMutation__
 *
 * To run a mutation, you first call `useDeleteUnitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnitNoteMutation, { data, loading, error }] = useDeleteUnitNoteMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteUnitNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUnitNoteMutation, DeleteUnitNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUnitNoteMutation, DeleteUnitNoteMutationVariables>(
    DeleteUnitNoteDocument,
    options
  );
}
export type DeleteUnitNoteMutationHookResult = ReturnType<typeof useDeleteUnitNoteMutation>;
export type DeleteUnitNoteMutationResult = Apollo.MutationResult<DeleteUnitNoteMutation>;
export type DeleteUnitNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteUnitNoteMutation,
  DeleteUnitNoteMutationVariables
>;
export const CreateJournalEntryNoteDocument = gql`
  mutation CreateJournalEntryNote($input: CreateJournalEntryNoteInput!) {
    createJournalEntryNote(input: $input) {
      ...JournalEntryFields
    }
  }
  ${JournalEntryFieldsFragmentDoc}
`;
export type CreateJournalEntryNoteMutationFn = Apollo.MutationFunction<
  CreateJournalEntryNoteMutation,
  CreateJournalEntryNoteMutationVariables
>;

/**
 * __useCreateJournalEntryNoteMutation__
 *
 * To run a mutation, you first call `useCreateJournalEntryNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJournalEntryNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJournalEntryNoteMutation, { data, loading, error }] = useCreateJournalEntryNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJournalEntryNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateJournalEntryNoteMutation,
    CreateJournalEntryNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateJournalEntryNoteMutation,
    CreateJournalEntryNoteMutationVariables
  >(CreateJournalEntryNoteDocument, options);
}
export type CreateJournalEntryNoteMutationHookResult = ReturnType<
  typeof useCreateJournalEntryNoteMutation
>;
export type CreateJournalEntryNoteMutationResult =
  Apollo.MutationResult<CreateJournalEntryNoteMutation>;
export type CreateJournalEntryNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateJournalEntryNoteMutation,
  CreateJournalEntryNoteMutationVariables
>;
export const DeleteJournalEntryNoteDocument = gql`
  mutation DeleteJournalEntryNote($input: UpdateJournalEntryNoteInput!) {
    deleteJournalEntryNote(input: $input) {
      ...JournalEntryFields
    }
  }
  ${JournalEntryFieldsFragmentDoc}
`;
export type DeleteJournalEntryNoteMutationFn = Apollo.MutationFunction<
  DeleteJournalEntryNoteMutation,
  DeleteJournalEntryNoteMutationVariables
>;

/**
 * __useDeleteJournalEntryNoteMutation__
 *
 * To run a mutation, you first call `useDeleteJournalEntryNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJournalEntryNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJournalEntryNoteMutation, { data, loading, error }] = useDeleteJournalEntryNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteJournalEntryNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteJournalEntryNoteMutation,
    DeleteJournalEntryNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteJournalEntryNoteMutation,
    DeleteJournalEntryNoteMutationVariables
  >(DeleteJournalEntryNoteDocument, options);
}
export type DeleteJournalEntryNoteMutationHookResult = ReturnType<
  typeof useDeleteJournalEntryNoteMutation
>;
export type DeleteJournalEntryNoteMutationResult =
  Apollo.MutationResult<DeleteJournalEntryNoteMutation>;
export type DeleteJournalEntryNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteJournalEntryNoteMutation,
  DeleteJournalEntryNoteMutationVariables
>;
export const CreateResidencyNoteDocument = gql`
  mutation CreateResidencyNote($input: CreateResidencyNoteInput!) {
    createResidencyNote(input: $input) {
      success
      residency {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type CreateResidencyNoteMutationFn = Apollo.MutationFunction<
  CreateResidencyNoteMutation,
  CreateResidencyNoteMutationVariables
>;

/**
 * __useCreateResidencyNoteMutation__
 *
 * To run a mutation, you first call `useCreateResidencyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResidencyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResidencyNoteMutation, { data, loading, error }] = useCreateResidencyNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResidencyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateResidencyNoteMutation,
    CreateResidencyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateResidencyNoteMutation, CreateResidencyNoteMutationVariables>(
    CreateResidencyNoteDocument,
    options
  );
}
export type CreateResidencyNoteMutationHookResult = ReturnType<
  typeof useCreateResidencyNoteMutation
>;
export type CreateResidencyNoteMutationResult = Apollo.MutationResult<CreateResidencyNoteMutation>;
export type CreateResidencyNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateResidencyNoteMutation,
  CreateResidencyNoteMutationVariables
>;
export const DeleteResidencyNoteDocument = gql`
  mutation DeleteResidencyNote($input: DeleteResidencyNoteInput!) {
    deleteResidencyNote(input: $input) {
      success
      residency {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type DeleteResidencyNoteMutationFn = Apollo.MutationFunction<
  DeleteResidencyNoteMutation,
  DeleteResidencyNoteMutationVariables
>;

/**
 * __useDeleteResidencyNoteMutation__
 *
 * To run a mutation, you first call `useDeleteResidencyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResidencyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResidencyNoteMutation, { data, loading, error }] = useDeleteResidencyNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteResidencyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteResidencyNoteMutation,
    DeleteResidencyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteResidencyNoteMutation, DeleteResidencyNoteMutationVariables>(
    DeleteResidencyNoteDocument,
    options
  );
}
export type DeleteResidencyNoteMutationHookResult = ReturnType<
  typeof useDeleteResidencyNoteMutation
>;
export type DeleteResidencyNoteMutationResult = Apollo.MutationResult<DeleteResidencyNoteMutation>;
export type DeleteResidencyNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteResidencyNoteMutation,
  DeleteResidencyNoteMutationVariables
>;
export const CreateApplicationNoteDocument = gql`
  mutation CreateApplicationNote($input: CreateApplicationNoteInput!) {
    createApplicationNote(input: $input) {
      application {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type CreateApplicationNoteMutationFn = Apollo.MutationFunction<
  CreateApplicationNoteMutation,
  CreateApplicationNoteMutationVariables
>;

/**
 * __useCreateApplicationNoteMutation__
 *
 * To run a mutation, you first call `useCreateApplicationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationNoteMutation, { data, loading, error }] = useCreateApplicationNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplicationNoteMutation,
    CreateApplicationNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateApplicationNoteMutation, CreateApplicationNoteMutationVariables>(
    CreateApplicationNoteDocument,
    options
  );
}
export type CreateApplicationNoteMutationHookResult = ReturnType<
  typeof useCreateApplicationNoteMutation
>;
export type CreateApplicationNoteMutationResult =
  Apollo.MutationResult<CreateApplicationNoteMutation>;
export type CreateApplicationNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationNoteMutation,
  CreateApplicationNoteMutationVariables
>;
export const DeleteApplicationNoteDocument = gql`
  mutation DeleteApplicationNote($input: DeleteApplicationNoteInput!) {
    deleteApplicationNote(input: $input) {
      application {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type DeleteApplicationNoteMutationFn = Apollo.MutationFunction<
  DeleteApplicationNoteMutation,
  DeleteApplicationNoteMutationVariables
>;

/**
 * __useDeleteApplicationNoteMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationNoteMutation, { data, loading, error }] = useDeleteApplicationNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApplicationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationNoteMutation,
    DeleteApplicationNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteApplicationNoteMutation, DeleteApplicationNoteMutationVariables>(
    DeleteApplicationNoteDocument,
    options
  );
}
export type DeleteApplicationNoteMutationHookResult = ReturnType<
  typeof useDeleteApplicationNoteMutation
>;
export type DeleteApplicationNoteMutationResult =
  Apollo.MutationResult<DeleteApplicationNoteMutation>;
export type DeleteApplicationNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteApplicationNoteMutation,
  DeleteApplicationNoteMutationVariables
>;
export const ResidencyExpiringThresholdsDocument = gql`
  query ResidencyExpiringThresholds {
    account {
      id
      settings {
        soon_days_threshold
        later_days_threshold
      }
    }
  }
`;

/**
 * __useResidencyExpiringThresholdsQuery__
 *
 * To run a query within a React component, call `useResidencyExpiringThresholdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResidencyExpiringThresholdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResidencyExpiringThresholdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useResidencyExpiringThresholdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ResidencyExpiringThresholdsQuery,
    ResidencyExpiringThresholdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResidencyExpiringThresholdsQuery,
    ResidencyExpiringThresholdsQueryVariables
  >(ResidencyExpiringThresholdsDocument, options);
}
export function useResidencyExpiringThresholdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResidencyExpiringThresholdsQuery,
    ResidencyExpiringThresholdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResidencyExpiringThresholdsQuery,
    ResidencyExpiringThresholdsQueryVariables
  >(ResidencyExpiringThresholdsDocument, options);
}
export type ResidencyExpiringThresholdsQueryHookResult = ReturnType<
  typeof useResidencyExpiringThresholdsQuery
>;
export type ResidencyExpiringThresholdsLazyQueryHookResult = ReturnType<
  typeof useResidencyExpiringThresholdsLazyQuery
>;
export type ResidencyExpiringThresholdsQueryResult = Apollo.QueryResult<
  ResidencyExpiringThresholdsQuery,
  ResidencyExpiringThresholdsQueryVariables
>;
export const CreateContactDocument = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      success
      error
      contact {
        ...ContactFields
      }
    }
  }
  ${ContactFieldsFragmentDoc}
`;
export type CreateContactMutationFn = Apollo.MutationFunction<
  CreateContactMutation,
  CreateContactMutationVariables
>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(
    CreateContactDocument,
    options
  );
}
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const UpdateContactDocument = gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      contact {
        ...ContactFields
      }
    }
  }
  ${ContactFieldsFragmentDoc}
`;
export type UpdateContactMutationFn = Apollo.MutationFunction<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(
    UpdateContactDocument,
    options
  );
}
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export const DeleteContactDocument = gql`
  mutation DeleteContact($id: ID!) {
    deleteContact(id: $id) {
      success
      error
    }
  }
`;
export type DeleteContactMutationFn = Apollo.MutationFunction<
  DeleteContactMutation,
  DeleteContactMutationVariables
>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(
    DeleteContactDocument,
    options
  );
}
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<
  DeleteContactMutation,
  DeleteContactMutationVariables
>;
export const GetContactDocument = gql`
  query GetContact($id: ID!) {
    contact(id: $id) {
      ...ContactFields
    }
  }
  ${ContactFieldsFragmentDoc}
`;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactQuery(
  baseOptions: Apollo.QueryHookOptions<GetContactQuery, GetContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
}
export function useGetContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContactQuery, GetContactQueryVariables>(
    GetContactDocument,
    options
  );
}
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
export type GetContactQueryResult = Apollo.QueryResult<GetContactQuery, GetContactQueryVariables>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    manager {
      id
      name
      email
      phone
      roles
      enabled
      accountId
      account {
        id
        name
      }
      signature {
        id
        signerId
        signature
        signerName
        signerEntity
        isAgent
        agentRelationship
      }
      subscriptions {
        id
        endpoints {
          id
          address
        }
      }
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetAccountFeesDocument = gql`
  query GetAccountFees {
    account {
      id
      fees {
        ...FeeFields
      }
    }
  }
  ${FeeFieldsFragmentDoc}
`;

/**
 * __useGetAccountFeesQuery__
 *
 * To run a query within a React component, call `useGetAccountFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountFeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountFeesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAccountFeesQuery, GetAccountFeesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountFeesQuery, GetAccountFeesQueryVariables>(
    GetAccountFeesDocument,
    options
  );
}
export function useGetAccountFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountFeesQuery, GetAccountFeesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountFeesQuery, GetAccountFeesQueryVariables>(
    GetAccountFeesDocument,
    options
  );
}
export type GetAccountFeesQueryHookResult = ReturnType<typeof useGetAccountFeesQuery>;
export type GetAccountFeesLazyQueryHookResult = ReturnType<typeof useGetAccountFeesLazyQuery>;
export type GetAccountFeesQueryResult = Apollo.QueryResult<
  GetAccountFeesQuery,
  GetAccountFeesQueryVariables
>;
export const DeleteAccountFeeDocument = gql`
  mutation DeleteAccountFee($id: ID!) {
    deleteFee(id: $id) {
      success
      error
      account {
        id
        fees {
          ...FeeFields
        }
      }
    }
  }
  ${FeeFieldsFragmentDoc}
`;
export type DeleteAccountFeeMutationFn = Apollo.MutationFunction<
  DeleteAccountFeeMutation,
  DeleteAccountFeeMutationVariables
>;

/**
 * __useDeleteAccountFeeMutation__
 *
 * To run a mutation, you first call `useDeleteAccountFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountFeeMutation, { data, loading, error }] = useDeleteAccountFeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAccountFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAccountFeeMutation,
    DeleteAccountFeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAccountFeeMutation, DeleteAccountFeeMutationVariables>(
    DeleteAccountFeeDocument,
    options
  );
}
export type DeleteAccountFeeMutationHookResult = ReturnType<typeof useDeleteAccountFeeMutation>;
export type DeleteAccountFeeMutationResult = Apollo.MutationResult<DeleteAccountFeeMutation>;
export type DeleteAccountFeeMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountFeeMutation,
  DeleteAccountFeeMutationVariables
>;
export const UpdateAccountFeeDocument = gql`
  mutation UpdateAccountFee($input: UpdateFeeInput!) {
    updateFee(input: $input) {
      success
      error
      account {
        id
        fees {
          ...FeeFields
        }
      }
    }
  }
  ${FeeFieldsFragmentDoc}
`;
export type UpdateAccountFeeMutationFn = Apollo.MutationFunction<
  UpdateAccountFeeMutation,
  UpdateAccountFeeMutationVariables
>;

/**
 * __useUpdateAccountFeeMutation__
 *
 * To run a mutation, you first call `useUpdateAccountFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountFeeMutation, { data, loading, error }] = useUpdateAccountFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountFeeMutation,
    UpdateAccountFeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountFeeMutation, UpdateAccountFeeMutationVariables>(
    UpdateAccountFeeDocument,
    options
  );
}
export type UpdateAccountFeeMutationHookResult = ReturnType<typeof useUpdateAccountFeeMutation>;
export type UpdateAccountFeeMutationResult = Apollo.MutationResult<UpdateAccountFeeMutation>;
export type UpdateAccountFeeMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountFeeMutation,
  UpdateAccountFeeMutationVariables
>;
export const CreateAccountFeeDocument = gql`
  mutation CreateAccountFee($input: CreateFeeInput!) {
    createFee(input: $input) {
      success
      error
      account {
        id
        fees {
          ...FeeFields
        }
      }
    }
  }
  ${FeeFieldsFragmentDoc}
`;
export type CreateAccountFeeMutationFn = Apollo.MutationFunction<
  CreateAccountFeeMutation,
  CreateAccountFeeMutationVariables
>;

/**
 * __useCreateAccountFeeMutation__
 *
 * To run a mutation, you first call `useCreateAccountFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountFeeMutation, { data, loading, error }] = useCreateAccountFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountFeeMutation,
    CreateAccountFeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAccountFeeMutation, CreateAccountFeeMutationVariables>(
    CreateAccountFeeDocument,
    options
  );
}
export type CreateAccountFeeMutationHookResult = ReturnType<typeof useCreateAccountFeeMutation>;
export type CreateAccountFeeMutationResult = Apollo.MutationResult<CreateAccountFeeMutation>;
export type CreateAccountFeeMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountFeeMutation,
  CreateAccountFeeMutationVariables
>;
export const GetProvinceTaxDetailsDocument = gql`
  query GetProvinceTaxDetails($input: GetProvinceTaxDetailsInput!) {
    getProvinceTaxDetails(input: $input) {
      success
      error
      taxDetails {
        label
        rate
        gst
      }
    }
  }
`;

/**
 * __useGetProvinceTaxDetailsQuery__
 *
 * To run a query within a React component, call `useGetProvinceTaxDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvinceTaxDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvinceTaxDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProvinceTaxDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProvinceTaxDetailsQuery,
    GetProvinceTaxDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProvinceTaxDetailsQuery, GetProvinceTaxDetailsQueryVariables>(
    GetProvinceTaxDetailsDocument,
    options
  );
}
export function useGetProvinceTaxDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProvinceTaxDetailsQuery,
    GetProvinceTaxDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProvinceTaxDetailsQuery, GetProvinceTaxDetailsQueryVariables>(
    GetProvinceTaxDetailsDocument,
    options
  );
}
export type GetProvinceTaxDetailsQueryHookResult = ReturnType<typeof useGetProvinceTaxDetailsQuery>;
export type GetProvinceTaxDetailsLazyQueryHookResult = ReturnType<
  typeof useGetProvinceTaxDetailsLazyQuery
>;
export type GetProvinceTaxDetailsQueryResult = Apollo.QueryResult<
  GetProvinceTaxDetailsQuery,
  GetProvinceTaxDetailsQueryVariables
>;
export const GetOwnerNameDocument = gql`
  query GetOwnerName($id: ID!) {
    owner(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useGetOwnerNameQuery__
 *
 * To run a query within a React component, call `useGetOwnerNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOwnerNameQuery(
  baseOptions: Apollo.QueryHookOptions<GetOwnerNameQuery, GetOwnerNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnerNameQuery, GetOwnerNameQueryVariables>(
    GetOwnerNameDocument,
    options
  );
}
export function useGetOwnerNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerNameQuery, GetOwnerNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnerNameQuery, GetOwnerNameQueryVariables>(
    GetOwnerNameDocument,
    options
  );
}
export type GetOwnerNameQueryHookResult = ReturnType<typeof useGetOwnerNameQuery>;
export type GetOwnerNameLazyQueryHookResult = ReturnType<typeof useGetOwnerNameLazyQuery>;
export type GetOwnerNameQueryResult = Apollo.QueryResult<
  GetOwnerNameQuery,
  GetOwnerNameQueryVariables
>;
export const GetOwnerNamesDocument = gql`
  query GetOwnerNames($nextToken: String) {
    account {
      id
      listOwners(nextToken: $nextToken) {
        items {
          id
          name
        }
        nextToken
      }
    }
  }
`;

/**
 * __useGetOwnerNamesQuery__
 *
 * To run a query within a React component, call `useGetOwnerNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerNamesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetOwnerNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOwnerNamesQuery, GetOwnerNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnerNamesQuery, GetOwnerNamesQueryVariables>(
    GetOwnerNamesDocument,
    options
  );
}
export function useGetOwnerNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerNamesQuery, GetOwnerNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnerNamesQuery, GetOwnerNamesQueryVariables>(
    GetOwnerNamesDocument,
    options
  );
}
export type GetOwnerNamesQueryHookResult = ReturnType<typeof useGetOwnerNamesQuery>;
export type GetOwnerNamesLazyQueryHookResult = ReturnType<typeof useGetOwnerNamesLazyQuery>;
export type GetOwnerNamesQueryResult = Apollo.QueryResult<
  GetOwnerNamesQuery,
  GetOwnerNamesQueryVariables
>;
export const ListAllPropertiesDocument = gql`
  query ListAllProperties($nextToken: String, $filter: PropertiesFilter) {
    account {
      id
      listProperties(nextToken: $nextToken, filter: $filter) {
        items @connection(key: "id") {
          ...PropertyListFields
        }
        nextToken
      }
    }
  }
  ${PropertyListFieldsFragmentDoc}
`;

/**
 * __useListAllPropertiesQuery__
 *
 * To run a query within a React component, call `useListAllPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllPropertiesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListAllPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListAllPropertiesQuery, ListAllPropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAllPropertiesQuery, ListAllPropertiesQueryVariables>(
    ListAllPropertiesDocument,
    options
  );
}
export function useListAllPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListAllPropertiesQuery, ListAllPropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListAllPropertiesQuery, ListAllPropertiesQueryVariables>(
    ListAllPropertiesDocument,
    options
  );
}
export type ListAllPropertiesQueryHookResult = ReturnType<typeof useListAllPropertiesQuery>;
export type ListAllPropertiesLazyQueryHookResult = ReturnType<typeof useListAllPropertiesLazyQuery>;
export type ListAllPropertiesQueryResult = Apollo.QueryResult<
  ListAllPropertiesQuery,
  ListAllPropertiesQueryVariables
>;
export const GetRegionTaxDetailsDocument = gql`
  query GetRegionTaxDetails($input: GetRegionTaxDetailsInput!) {
    getRegionTaxDetails(input: $input) {
      success
      taxDetails {
        label
        rate
        gst
      }
    }
  }
`;

/**
 * __useGetRegionTaxDetailsQuery__
 *
 * To run a query within a React component, call `useGetRegionTaxDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionTaxDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionTaxDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRegionTaxDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetRegionTaxDetailsQuery, GetRegionTaxDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegionTaxDetailsQuery, GetRegionTaxDetailsQueryVariables>(
    GetRegionTaxDetailsDocument,
    options
  );
}
export function useGetRegionTaxDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegionTaxDetailsQuery,
    GetRegionTaxDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegionTaxDetailsQuery, GetRegionTaxDetailsQueryVariables>(
    GetRegionTaxDetailsDocument,
    options
  );
}
export type GetRegionTaxDetailsQueryHookResult = ReturnType<typeof useGetRegionTaxDetailsQuery>;
export type GetRegionTaxDetailsLazyQueryHookResult = ReturnType<
  typeof useGetRegionTaxDetailsLazyQuery
>;
export type GetRegionTaxDetailsQueryResult = Apollo.QueryResult<
  GetRegionTaxDetailsQuery,
  GetRegionTaxDetailsQueryVariables
>;
export const GetRequestsDocument = gql`
  query GetRequests($nextToken: String) {
    account {
      id
      listRequests(nextToken: $nextToken) {
        items {
          ...RequestListFields
        }
        nextToken
      }
    }
  }
  ${RequestListFieldsFragmentDoc}
`;

/**
 * __useGetRequestsQuery__
 *
 * To run a query within a React component, call `useGetRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRequestsQuery, GetRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestsQuery, GetRequestsQueryVariables>(GetRequestsDocument, options);
}
export function useGetRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequestsQuery, GetRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestsQuery, GetRequestsQueryVariables>(
    GetRequestsDocument,
    options
  );
}
export type GetRequestsQueryHookResult = ReturnType<typeof useGetRequestsQuery>;
export type GetRequestsLazyQueryHookResult = ReturnType<typeof useGetRequestsLazyQuery>;
export type GetRequestsQueryResult = Apollo.QueryResult<
  GetRequestsQuery,
  GetRequestsQueryVariables
>;
export const GetRequestHeaderDocument = gql`
  query GetRequestHeader($id: ID!) {
    request(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useGetRequestHeaderQuery__
 *
 * To run a query within a React component, call `useGetRequestHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestHeaderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequestHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequestHeaderQuery, GetRequestHeaderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestHeaderQuery, GetRequestHeaderQueryVariables>(
    GetRequestHeaderDocument,
    options
  );
}
export function useGetRequestHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequestHeaderQuery, GetRequestHeaderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestHeaderQuery, GetRequestHeaderQueryVariables>(
    GetRequestHeaderDocument,
    options
  );
}
export type GetRequestHeaderQueryHookResult = ReturnType<typeof useGetRequestHeaderQuery>;
export type GetRequestHeaderLazyQueryHookResult = ReturnType<typeof useGetRequestHeaderLazyQuery>;
export type GetRequestHeaderQueryResult = Apollo.QueryResult<
  GetRequestHeaderQuery,
  GetRequestHeaderQueryVariables
>;
export const CreateSupplierDocument = gql`
  mutation CreateSupplier($input: SupplierInput!) {
    createSupplier(input: $input) {
      success
      error
      supplier {
        ...SupplierFields
      }
    }
  }
  ${SupplierFieldsFragmentDoc}
`;
export type CreateSupplierMutationFn = Apollo.MutationFunction<
  CreateSupplierMutation,
  CreateSupplierMutationVariables
>;

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSupplierMutation, CreateSupplierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSupplierMutation, CreateSupplierMutationVariables>(
    CreateSupplierDocument,
    options
  );
}
export type CreateSupplierMutationHookResult = ReturnType<typeof useCreateSupplierMutation>;
export type CreateSupplierMutationResult = Apollo.MutationResult<CreateSupplierMutation>;
export type CreateSupplierMutationOptions = Apollo.BaseMutationOptions<
  CreateSupplierMutation,
  CreateSupplierMutationVariables
>;
export const LinkSupplierDocument = gql`
  mutation LinkSupplier($id: ID!, $input: LinkSupplierInput) {
    linkSupplier(id: $id, input: $input) {
      success
      error
      supplier {
        ...SupplierFields
      }
    }
  }
  ${SupplierFieldsFragmentDoc}
`;
export type LinkSupplierMutationFn = Apollo.MutationFunction<
  LinkSupplierMutation,
  LinkSupplierMutationVariables
>;

/**
 * __useLinkSupplierMutation__
 *
 * To run a mutation, you first call `useLinkSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkSupplierMutation, { data, loading, error }] = useLinkSupplierMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkSupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<LinkSupplierMutation, LinkSupplierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LinkSupplierMutation, LinkSupplierMutationVariables>(
    LinkSupplierDocument,
    options
  );
}
export type LinkSupplierMutationHookResult = ReturnType<typeof useLinkSupplierMutation>;
export type LinkSupplierMutationResult = Apollo.MutationResult<LinkSupplierMutation>;
export type LinkSupplierMutationOptions = Apollo.BaseMutationOptions<
  LinkSupplierMutation,
  LinkSupplierMutationVariables
>;
export const UpdateSupplierDocument = gql`
  mutation UpdateSupplier($input: SupplierInput!) {
    updateSupplier(input: $input) {
      success
      error
      supplier {
        ...SupplierFields
      }
    }
  }
  ${SupplierFieldsFragmentDoc}
`;
export type UpdateSupplierMutationFn = Apollo.MutationFunction<
  UpdateSupplierMutation,
  UpdateSupplierMutationVariables
>;

/**
 * __useUpdateSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierMutation, { data, loading, error }] = useUpdateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>(
    UpdateSupplierDocument,
    options
  );
}
export type UpdateSupplierMutationHookResult = ReturnType<typeof useUpdateSupplierMutation>;
export type UpdateSupplierMutationResult = Apollo.MutationResult<UpdateSupplierMutation>;
export type UpdateSupplierMutationOptions = Apollo.BaseMutationOptions<
  UpdateSupplierMutation,
  UpdateSupplierMutationVariables
>;
export const GetSuppliersDocument = gql`
  query GetSuppliers {
    account {
      id
      suppliers {
        ...SupplierFields
      }
    }
  }
  ${SupplierFieldsFragmentDoc}
`;

/**
 * __useGetSuppliersQuery__
 *
 * To run a query within a React component, call `useGetSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSuppliersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSuppliersQuery, GetSuppliersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSuppliersQuery, GetSuppliersQueryVariables>(
    GetSuppliersDocument,
    options
  );
}
export function useGetSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSuppliersQuery, GetSuppliersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSuppliersQuery, GetSuppliersQueryVariables>(
    GetSuppliersDocument,
    options
  );
}
export type GetSuppliersQueryHookResult = ReturnType<typeof useGetSuppliersQuery>;
export type GetSuppliersLazyQueryHookResult = ReturnType<typeof useGetSuppliersLazyQuery>;
export type GetSuppliersQueryResult = Apollo.QueryResult<
  GetSuppliersQuery,
  GetSuppliersQueryVariables
>;
export const SupplierDocument = gql`
  query Supplier($id: ID!) {
    supplier(id: $id) {
      ...SupplierFields
    }
  }
  ${SupplierFieldsFragmentDoc}
`;

/**
 * __useSupplierQuery__
 *
 * To run a query within a React component, call `useSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupplierQuery(
  baseOptions: Apollo.QueryHookOptions<SupplierQuery, SupplierQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SupplierQuery, SupplierQueryVariables>(SupplierDocument, options);
}
export function useSupplierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SupplierQuery, SupplierQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SupplierQuery, SupplierQueryVariables>(SupplierDocument, options);
}
export type SupplierQueryHookResult = ReturnType<typeof useSupplierQuery>;
export type SupplierLazyQueryHookResult = ReturnType<typeof useSupplierLazyQuery>;
export type SupplierQueryResult = Apollo.QueryResult<SupplierQuery, SupplierQueryVariables>;
export const ListSuppliersDocument = gql`
  query ListSuppliers($nextToken: String, $filter: SuppliersFilter) {
    account {
      id
      listSuppliers(nextToken: $nextToken, filter: $filter) {
        items @connection(key: "id") {
          ...SupplierFields
        }
        nextToken
      }
    }
  }
  ${SupplierFieldsFragmentDoc}
`;

/**
 * __useListSuppliersQuery__
 *
 * To run a query within a React component, call `useListSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSuppliersQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListSuppliersQuery(
  baseOptions?: Apollo.QueryHookOptions<ListSuppliersQuery, ListSuppliersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSuppliersQuery, ListSuppliersQueryVariables>(
    ListSuppliersDocument,
    options
  );
}
export function useListSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSuppliersQuery, ListSuppliersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSuppliersQuery, ListSuppliersQueryVariables>(
    ListSuppliersDocument,
    options
  );
}
export type ListSuppliersQueryHookResult = ReturnType<typeof useListSuppliersQuery>;
export type ListSuppliersLazyQueryHookResult = ReturnType<typeof useListSuppliersLazyQuery>;
export type ListSuppliersQueryResult = Apollo.QueryResult<
  ListSuppliersQuery,
  ListSuppliersQueryVariables
>;
export const DeleteSupplierDocument = gql`
  mutation DeleteSupplier($id: ID!) {
    deleteSupplier(id: $id) {
      success
      error
      supplier {
        id
        deletedZ
      }
    }
  }
`;
export type DeleteSupplierMutationFn = Apollo.MutationFunction<
  DeleteSupplierMutation,
  DeleteSupplierMutationVariables
>;

/**
 * __useDeleteSupplierMutation__
 *
 * To run a mutation, you first call `useDeleteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupplierMutation, { data, loading, error }] = useDeleteSupplierMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSupplierMutation, DeleteSupplierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSupplierMutation, DeleteSupplierMutationVariables>(
    DeleteSupplierDocument,
    options
  );
}
export type DeleteSupplierMutationHookResult = ReturnType<typeof useDeleteSupplierMutation>;
export type DeleteSupplierMutationResult = Apollo.MutationResult<DeleteSupplierMutation>;
export type DeleteSupplierMutationOptions = Apollo.BaseMutationOptions<
  DeleteSupplierMutation,
  DeleteSupplierMutationVariables
>;
export const PageSuppliersDocument = gql`
  query PageSuppliers(
    $first: Int
    $after: ID
    $filter: SuppliersFilterInput
    $sort: [SuppliersSortInput!]
  ) {
    account {
      id
      pageSuppliers(first: $first, after: $after, filter: $filter, sort: $sort) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ...SupplierFields
          }
        }
      }
    }
  }
  ${SupplierFieldsFragmentDoc}
`;

/**
 * __usePageSuppliersQuery__
 *
 * To run a query within a React component, call `usePageSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageSuppliersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePageSuppliersQuery(
  baseOptions?: Apollo.QueryHookOptions<PageSuppliersQuery, PageSuppliersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PageSuppliersQuery, PageSuppliersQueryVariables>(
    PageSuppliersDocument,
    options
  );
}
export function usePageSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PageSuppliersQuery, PageSuppliersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PageSuppliersQuery, PageSuppliersQueryVariables>(
    PageSuppliersDocument,
    options
  );
}
export type PageSuppliersQueryHookResult = ReturnType<typeof usePageSuppliersQuery>;
export type PageSuppliersLazyQueryHookResult = ReturnType<typeof usePageSuppliersLazyQuery>;
export type PageSuppliersQueryResult = Apollo.QueryResult<
  PageSuppliersQuery,
  PageSuppliersQueryVariables
>;
export const GetUnitsDocument = gql`
  query GetUnits($nextToken: String, $filter: UnitFilter) {
    account {
      id
      listUnits(nextToken: $nextToken, filter: $filter) {
        items {
          ...UnitListFields
        }
        nextToken
      }
    }
  }
  ${UnitListFieldsFragmentDoc}
`;

/**
 * __useGetUnitsQuery__
 *
 * To run a query within a React component, call `useGetUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUnitsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
}
export function useGetUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
}
export type GetUnitsQueryHookResult = ReturnType<typeof useGetUnitsQuery>;
export type GetUnitsLazyQueryHookResult = ReturnType<typeof useGetUnitsLazyQuery>;
export type GetUnitsQueryResult = Apollo.QueryResult<GetUnitsQuery, GetUnitsQueryVariables>;
export const ListAllUnitsDocument = gql`
  query ListAllUnits($nextToken: String) {
    account {
      id
      listUnits(nextToken: $nextToken) {
        items @connection(key: "id") {
          id
          name
          ownerId
          propertyId
          occupancy
          buildingId
          sqft
          allResidencies {
            id
            startZ
            endZ
          }
        }
        nextToken
      }
    }
  }
`;

/**
 * __useListAllUnitsQuery__
 *
 * To run a query within a React component, call `useListAllUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllUnitsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListAllUnitsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListAllUnitsQuery, ListAllUnitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAllUnitsQuery, ListAllUnitsQueryVariables>(
    ListAllUnitsDocument,
    options
  );
}
export function useListAllUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListAllUnitsQuery, ListAllUnitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListAllUnitsQuery, ListAllUnitsQueryVariables>(
    ListAllUnitsDocument,
    options
  );
}
export type ListAllUnitsQueryHookResult = ReturnType<typeof useListAllUnitsQuery>;
export type ListAllUnitsLazyQueryHookResult = ReturnType<typeof useListAllUnitsLazyQuery>;
export type ListAllUnitsQueryResult = Apollo.QueryResult<
  ListAllUnitsQuery,
  ListAllUnitsQueryVariables
>;
export const ListUnitsWithResidenciesDocument = gql`
  query ListUnitsWithResidencies($nextToken: String, $filter: UnitFilter) {
    account {
      id
      listUnits(nextToken: $nextToken, filter: $filter) {
        items @connection(key: "id") {
          id
          ...UnitEntityFields
          owner {
            id
            name
          }
          allResidencies {
            id
            startZ
            endZ
            renewZ
            rrule
            terms
            moveOutReason
            residents {
              id
              leaseHolder
              resident {
                id
                name
              }
            }
          }
        }
        nextToken
      }
    }
  }
  ${UnitEntityFieldsFragmentDoc}
`;

/**
 * __useListUnitsWithResidenciesQuery__
 *
 * To run a query within a React component, call `useListUnitsWithResidenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUnitsWithResidenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUnitsWithResidenciesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListUnitsWithResidenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUnitsWithResidenciesQuery,
    ListUnitsWithResidenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListUnitsWithResidenciesQuery, ListUnitsWithResidenciesQueryVariables>(
    ListUnitsWithResidenciesDocument,
    options
  );
}
export function useListUnitsWithResidenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUnitsWithResidenciesQuery,
    ListUnitsWithResidenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListUnitsWithResidenciesQuery, ListUnitsWithResidenciesQueryVariables>(
    ListUnitsWithResidenciesDocument,
    options
  );
}
export type ListUnitsWithResidenciesQueryHookResult = ReturnType<
  typeof useListUnitsWithResidenciesQuery
>;
export type ListUnitsWithResidenciesLazyQueryHookResult = ReturnType<
  typeof useListUnitsWithResidenciesLazyQuery
>;
export type ListUnitsWithResidenciesQueryResult = Apollo.QueryResult<
  ListUnitsWithResidenciesQuery,
  ListUnitsWithResidenciesQueryVariables
>;
export const AccountNameDocument = gql`
  query AccountName {
    account {
      id
      name
    }
  }
`;

/**
 * __useAccountNameQuery__
 *
 * To run a query within a React component, call `useAccountNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountNameQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountNameQuery, AccountNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountNameQuery, AccountNameQueryVariables>(AccountNameDocument, options);
}
export function useAccountNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountNameQuery, AccountNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountNameQuery, AccountNameQueryVariables>(
    AccountNameDocument,
    options
  );
}
export type AccountNameQueryHookResult = ReturnType<typeof useAccountNameQuery>;
export type AccountNameLazyQueryHookResult = ReturnType<typeof useAccountNameLazyQuery>;
export type AccountNameQueryResult = Apollo.QueryResult<
  AccountNameQuery,
  AccountNameQueryVariables
>;
export const AddAutopayBankDocument = gql`
  mutation AddAutopayBank($input: AddAutopayBankInput!) {
    addAutopayBank(input: $input) {
      success
      error
      books {
        accountId
        autopay {
          accountId
          banks {
            ...AutopayBankFields
          }
        }
      }
    }
  }
  ${AutopayBankFieldsFragmentDoc}
`;
export type AddAutopayBankMutationFn = Apollo.MutationFunction<
  AddAutopayBankMutation,
  AddAutopayBankMutationVariables
>;

/**
 * __useAddAutopayBankMutation__
 *
 * To run a mutation, you first call `useAddAutopayBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAutopayBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAutopayBankMutation, { data, loading, error }] = useAddAutopayBankMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAutopayBankMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAutopayBankMutation, AddAutopayBankMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAutopayBankMutation, AddAutopayBankMutationVariables>(
    AddAutopayBankDocument,
    options
  );
}
export type AddAutopayBankMutationHookResult = ReturnType<typeof useAddAutopayBankMutation>;
export type AddAutopayBankMutationResult = Apollo.MutationResult<AddAutopayBankMutation>;
export type AddAutopayBankMutationOptions = Apollo.BaseMutationOptions<
  AddAutopayBankMutation,
  AddAutopayBankMutationVariables
>;
export const GetAutopayBanksDocument = gql`
  query GetAutopayBanks {
    account {
      id
      books {
        accountId
        autopay {
          accountId
          banks {
            id
            name
            institutionNumber
            accountNumber
            transitNumber
          }
        }
      }
      autopayClientId
      autopayAccount
      autopayTransit
      autopayInstitution
    }
  }
`;

/**
 * __useGetAutopayBanksQuery__
 *
 * To run a query within a React component, call `useGetAutopayBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutopayBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutopayBanksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAutopayBanksQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAutopayBanksQuery, GetAutopayBanksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAutopayBanksQuery, GetAutopayBanksQueryVariables>(
    GetAutopayBanksDocument,
    options
  );
}
export function useGetAutopayBanksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAutopayBanksQuery, GetAutopayBanksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAutopayBanksQuery, GetAutopayBanksQueryVariables>(
    GetAutopayBanksDocument,
    options
  );
}
export type GetAutopayBanksQueryHookResult = ReturnType<typeof useGetAutopayBanksQuery>;
export type GetAutopayBanksLazyQueryHookResult = ReturnType<typeof useGetAutopayBanksLazyQuery>;
export type GetAutopayBanksQueryResult = Apollo.QueryResult<
  GetAutopayBanksQuery,
  GetAutopayBanksQueryVariables
>;
export const GetBooksActivationDocument = gql`
  query GetBooksActivation {
    account {
      id
      books {
        accountId
        activatedZ
        autopay {
          accountId
          activated
          activationStatus
          version
          enablePayments
          enableCollection
        }
      }
    }
  }
`;

/**
 * __useGetBooksActivationQuery__
 *
 * To run a query within a React component, call `useGetBooksActivationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBooksActivationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBooksActivationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBooksActivationQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBooksActivationQuery, GetBooksActivationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBooksActivationQuery, GetBooksActivationQueryVariables>(
    GetBooksActivationDocument,
    options
  );
}
export function useGetBooksActivationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBooksActivationQuery,
    GetBooksActivationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBooksActivationQuery, GetBooksActivationQueryVariables>(
    GetBooksActivationDocument,
    options
  );
}
export type GetBooksActivationQueryHookResult = ReturnType<typeof useGetBooksActivationQuery>;
export type GetBooksActivationLazyQueryHookResult = ReturnType<
  typeof useGetBooksActivationLazyQuery
>;
export type GetBooksActivationQueryResult = Apollo.QueryResult<
  GetBooksActivationQuery,
  GetBooksActivationQueryVariables
>;
export const GetBooksBatchTaskDocument = gql`
  query GetBooksBatchTask($id: ID!) {
    account {
      id
      books {
        accountId
        batchTask(id: $id) {
          id
          taskProgress {
            label
            total
            processed
            done
          }
        }
      }
    }
  }
`;

/**
 * __useGetBooksBatchTaskQuery__
 *
 * To run a query within a React component, call `useGetBooksBatchTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBooksBatchTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBooksBatchTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBooksBatchTaskQuery(
  baseOptions: Apollo.QueryHookOptions<GetBooksBatchTaskQuery, GetBooksBatchTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBooksBatchTaskQuery, GetBooksBatchTaskQueryVariables>(
    GetBooksBatchTaskDocument,
    options
  );
}
export function useGetBooksBatchTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBooksBatchTaskQuery, GetBooksBatchTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBooksBatchTaskQuery, GetBooksBatchTaskQueryVariables>(
    GetBooksBatchTaskDocument,
    options
  );
}
export type GetBooksBatchTaskQueryHookResult = ReturnType<typeof useGetBooksBatchTaskQuery>;
export type GetBooksBatchTaskLazyQueryHookResult = ReturnType<typeof useGetBooksBatchTaskLazyQuery>;
export type GetBooksBatchTaskQueryResult = Apollo.QueryResult<
  GetBooksBatchTaskQuery,
  GetBooksBatchTaskQueryVariables
>;
export const GetOpenSearchTaskDocument = gql`
  query GetOpenSearchTask($id: ID!) {
    opensearch {
      task(id: $id) {
        completed
        task {
          id
          status {
            total
            updated
            created
            deleted
          }
          cancellable
          cancelled
        }
        response {
          took
          total
          updated
          created
          deleted
        }
      }
    }
  }
`;

/**
 * __useGetOpenSearchTaskQuery__
 *
 * To run a query within a React component, call `useGetOpenSearchTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenSearchTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenSearchTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOpenSearchTaskQuery(
  baseOptions: Apollo.QueryHookOptions<GetOpenSearchTaskQuery, GetOpenSearchTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOpenSearchTaskQuery, GetOpenSearchTaskQueryVariables>(
    GetOpenSearchTaskDocument,
    options
  );
}
export function useGetOpenSearchTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOpenSearchTaskQuery, GetOpenSearchTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOpenSearchTaskQuery, GetOpenSearchTaskQueryVariables>(
    GetOpenSearchTaskDocument,
    options
  );
}
export type GetOpenSearchTaskQueryHookResult = ReturnType<typeof useGetOpenSearchTaskQuery>;
export type GetOpenSearchTaskLazyQueryHookResult = ReturnType<typeof useGetOpenSearchTaskLazyQuery>;
export type GetOpenSearchTaskQueryResult = Apollo.QueryResult<
  GetOpenSearchTaskQuery,
  GetOpenSearchTaskQueryVariables
>;
export const AllPropertiesDocument = gql`
  query AllProperties {
    account {
      id
      pageProperties(first: 1000) {
        edges {
          node {
            id
            ...PropertyListFields
          }
        }
      }
    }
  }
  ${PropertyListFieldsFragmentDoc}
`;

/**
 * __useAllPropertiesQuery__
 *
 * To run a query within a React component, call `useAllPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllPropertiesQuery, AllPropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllPropertiesQuery, AllPropertiesQueryVariables>(
    AllPropertiesDocument,
    options
  );
}
export function useAllPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllPropertiesQuery, AllPropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllPropertiesQuery, AllPropertiesQueryVariables>(
    AllPropertiesDocument,
    options
  );
}
export type AllPropertiesQueryHookResult = ReturnType<typeof useAllPropertiesQuery>;
export type AllPropertiesLazyQueryHookResult = ReturnType<typeof useAllPropertiesLazyQuery>;
export type AllPropertiesQueryResult = Apollo.QueryResult<
  AllPropertiesQuery,
  AllPropertiesQueryVariables
>;
export const PropertyNameDocument = gql`
  query PropertyName($id: ID!) {
    property(id: $id) {
      id
      name
    }
  }
`;

/**
 * __usePropertyNameQuery__
 *
 * To run a query within a React component, call `usePropertyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertyNameQuery(
  baseOptions: Apollo.QueryHookOptions<PropertyNameQuery, PropertyNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertyNameQuery, PropertyNameQueryVariables>(
    PropertyNameDocument,
    options
  );
}
export function usePropertyNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PropertyNameQuery, PropertyNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PropertyNameQuery, PropertyNameQueryVariables>(
    PropertyNameDocument,
    options
  );
}
export type PropertyNameQueryHookResult = ReturnType<typeof usePropertyNameQuery>;
export type PropertyNameLazyQueryHookResult = ReturnType<typeof usePropertyNameLazyQuery>;
export type PropertyNameQueryResult = Apollo.QueryResult<
  PropertyNameQuery,
  PropertyNameQueryVariables
>;
export const GetPropertyUnitsDocument = gql`
  query GetPropertyUnits($id: ID!) {
    property(id: $id) {
      id
      name
      key
      autopayCollectionsEnabled
      address {
        province
      }
      units {
        ...UnitListFields
      }
      buildings {
        id
        name
        key
      }
    }
  }
  ${UnitListFieldsFragmentDoc}
`;

/**
 * __useGetPropertyUnitsQuery__
 *
 * To run a query within a React component, call `useGetPropertyUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyUnitsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyUnitsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPropertyUnitsQuery, GetPropertyUnitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyUnitsQuery, GetPropertyUnitsQueryVariables>(
    GetPropertyUnitsDocument,
    options
  );
}
export function useGetPropertyUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyUnitsQuery, GetPropertyUnitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyUnitsQuery, GetPropertyUnitsQueryVariables>(
    GetPropertyUnitsDocument,
    options
  );
}
export type GetPropertyUnitsQueryHookResult = ReturnType<typeof useGetPropertyUnitsQuery>;
export type GetPropertyUnitsLazyQueryHookResult = ReturnType<typeof useGetPropertyUnitsLazyQuery>;
export type GetPropertyUnitsQueryResult = Apollo.QueryResult<
  GetPropertyUnitsQuery,
  GetPropertyUnitsQueryVariables
>;
export const GetPropertyUnitsWithResidencyDocument = gql`
  query GetPropertyUnitsWithResidency($id: ID!) {
    property(id: $id) {
      id
      name
      key
      address {
        province
      }
      units {
        ...UnitListFieldsWithResidency
      }
      buildings {
        id
        name
        key
      }
    }
  }
  ${UnitListFieldsWithResidencyFragmentDoc}
`;

/**
 * __useGetPropertyUnitsWithResidencyQuery__
 *
 * To run a query within a React component, call `useGetPropertyUnitsWithResidencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyUnitsWithResidencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyUnitsWithResidencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyUnitsWithResidencyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyUnitsWithResidencyQuery,
    GetPropertyUnitsWithResidencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPropertyUnitsWithResidencyQuery,
    GetPropertyUnitsWithResidencyQueryVariables
  >(GetPropertyUnitsWithResidencyDocument, options);
}
export function useGetPropertyUnitsWithResidencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyUnitsWithResidencyQuery,
    GetPropertyUnitsWithResidencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPropertyUnitsWithResidencyQuery,
    GetPropertyUnitsWithResidencyQueryVariables
  >(GetPropertyUnitsWithResidencyDocument, options);
}
export type GetPropertyUnitsWithResidencyQueryHookResult = ReturnType<
  typeof useGetPropertyUnitsWithResidencyQuery
>;
export type GetPropertyUnitsWithResidencyLazyQueryHookResult = ReturnType<
  typeof useGetPropertyUnitsWithResidencyLazyQuery
>;
export type GetPropertyUnitsWithResidencyQueryResult = Apollo.QueryResult<
  GetPropertyUnitsWithResidencyQuery,
  GetPropertyUnitsWithResidencyQueryVariables
>;
export const RefundableDepositTemplateDocument = gql`
  query RefundableDepositTemplate($province: String!) {
    depositTemplate(province: $province) {
      id
      enabled
      items {
        id
        name
        defaultAmount
        defaultAmountExpr
        accrualStartExpr
        present
      }
    }
  }
`;

/**
 * __useRefundableDepositTemplateQuery__
 *
 * To run a query within a React component, call `useRefundableDepositTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefundableDepositTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefundableDepositTemplateQuery({
 *   variables: {
 *      province: // value for 'province'
 *   },
 * });
 */
export function useRefundableDepositTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    RefundableDepositTemplateQuery,
    RefundableDepositTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RefundableDepositTemplateQuery, RefundableDepositTemplateQueryVariables>(
    RefundableDepositTemplateDocument,
    options
  );
}
export function useRefundableDepositTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RefundableDepositTemplateQuery,
    RefundableDepositTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RefundableDepositTemplateQuery,
    RefundableDepositTemplateQueryVariables
  >(RefundableDepositTemplateDocument, options);
}
export type RefundableDepositTemplateQueryHookResult = ReturnType<
  typeof useRefundableDepositTemplateQuery
>;
export type RefundableDepositTemplateLazyQueryHookResult = ReturnType<
  typeof useRefundableDepositTemplateLazyQuery
>;
export type RefundableDepositTemplateQueryResult = Apollo.QueryResult<
  RefundableDepositTemplateQuery,
  RefundableDepositTemplateQueryVariables
>;
export const TaxesPropertiesDocument = gql`
  query TaxesProperties($nextToken: String) {
    account {
      id
      address {
        province
      }
      listProperties(nextToken: $nextToken) {
        nextToken
        items {
          id
          address {
            province
          }
        }
      }
    }
  }
`;

/**
 * __useTaxesPropertiesQuery__
 *
 * To run a query within a React component, call `useTaxesPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxesPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxesPropertiesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useTaxesPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<TaxesPropertiesQuery, TaxesPropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaxesPropertiesQuery, TaxesPropertiesQueryVariables>(
    TaxesPropertiesDocument,
    options
  );
}
export function useTaxesPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaxesPropertiesQuery, TaxesPropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaxesPropertiesQuery, TaxesPropertiesQueryVariables>(
    TaxesPropertiesDocument,
    options
  );
}
export type TaxesPropertiesQueryHookResult = ReturnType<typeof useTaxesPropertiesQuery>;
export type TaxesPropertiesLazyQueryHookResult = ReturnType<typeof useTaxesPropertiesLazyQuery>;
export type TaxesPropertiesQueryResult = Apollo.QueryResult<
  TaxesPropertiesQuery,
  TaxesPropertiesQueryVariables
>;
export const TeamUnitDocument = gql`
  query TeamUnit($unitId: ID!) {
    unit(id: $unitId) {
      id
      propertyId
      occupancy
    }
  }
`;

/**
 * __useTeamUnitQuery__
 *
 * To run a query within a React component, call `useTeamUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamUnitQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useTeamUnitQuery(
  baseOptions: Apollo.QueryHookOptions<TeamUnitQuery, TeamUnitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamUnitQuery, TeamUnitQueryVariables>(TeamUnitDocument, options);
}
export function useTeamUnitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamUnitQuery, TeamUnitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamUnitQuery, TeamUnitQueryVariables>(TeamUnitDocument, options);
}
export type TeamUnitQueryHookResult = ReturnType<typeof useTeamUnitQuery>;
export type TeamUnitLazyQueryHookResult = ReturnType<typeof useTeamUnitLazyQuery>;
export type TeamUnitQueryResult = Apollo.QueryResult<TeamUnitQuery, TeamUnitQueryVariables>;
export const GetActionableCampaignApplicationsDocument = gql`
  query GetActionableCampaignApplications {
    account {
      id
      campaignApplicationsWithAction
    }
  }
`;

/**
 * __useGetActionableCampaignApplicationsQuery__
 *
 * To run a query within a React component, call `useGetActionableCampaignApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionableCampaignApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionableCampaignApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActionableCampaignApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActionableCampaignApplicationsQuery,
    GetActionableCampaignApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActionableCampaignApplicationsQuery,
    GetActionableCampaignApplicationsQueryVariables
  >(GetActionableCampaignApplicationsDocument, options);
}
export function useGetActionableCampaignApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActionableCampaignApplicationsQuery,
    GetActionableCampaignApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActionableCampaignApplicationsQuery,
    GetActionableCampaignApplicationsQueryVariables
  >(GetActionableCampaignApplicationsDocument, options);
}
export type GetActionableCampaignApplicationsQueryHookResult = ReturnType<
  typeof useGetActionableCampaignApplicationsQuery
>;
export type GetActionableCampaignApplicationsLazyQueryHookResult = ReturnType<
  typeof useGetActionableCampaignApplicationsLazyQuery
>;
export type GetActionableCampaignApplicationsQueryResult = Apollo.QueryResult<
  GetActionableCampaignApplicationsQuery,
  GetActionableCampaignApplicationsQueryVariables
>;
export const GetActionableRequestsForAccountDocument = gql`
  query GetActionableRequestsForAccount {
    account {
      id
      requestsWithAction
    }
  }
`;

/**
 * __useGetActionableRequestsForAccountQuery__
 *
 * To run a query within a React component, call `useGetActionableRequestsForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionableRequestsForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionableRequestsForAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActionableRequestsForAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActionableRequestsForAccountQuery,
    GetActionableRequestsForAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActionableRequestsForAccountQuery,
    GetActionableRequestsForAccountQueryVariables
  >(GetActionableRequestsForAccountDocument, options);
}
export function useGetActionableRequestsForAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActionableRequestsForAccountQuery,
    GetActionableRequestsForAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActionableRequestsForAccountQuery,
    GetActionableRequestsForAccountQueryVariables
  >(GetActionableRequestsForAccountDocument, options);
}
export type GetActionableRequestsForAccountQueryHookResult = ReturnType<
  typeof useGetActionableRequestsForAccountQuery
>;
export type GetActionableRequestsForAccountLazyQueryHookResult = ReturnType<
  typeof useGetActionableRequestsForAccountLazyQuery
>;
export type GetActionableRequestsForAccountQueryResult = Apollo.QueryResult<
  GetActionableRequestsForAccountQuery,
  GetActionableRequestsForAccountQueryVariables
>;
export const AddLinkedBankAccountsDocument = gql`
  mutation AddLinkedBankAccounts($input: AddLinkedBankAccountsInput!) {
    addLinkedBankAccounts(input: $input) {
      success
      error
      books {
        accountId
        autopay {
          ...AutopayFields
        }
      }
    }
  }
  ${AutopayFieldsFragmentDoc}
`;
export type AddLinkedBankAccountsMutationFn = Apollo.MutationFunction<
  AddLinkedBankAccountsMutation,
  AddLinkedBankAccountsMutationVariables
>;

/**
 * __useAddLinkedBankAccountsMutation__
 *
 * To run a mutation, you first call `useAddLinkedBankAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLinkedBankAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLinkedBankAccountsMutation, { data, loading, error }] = useAddLinkedBankAccountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLinkedBankAccountsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLinkedBankAccountsMutation,
    AddLinkedBankAccountsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddLinkedBankAccountsMutation, AddLinkedBankAccountsMutationVariables>(
    AddLinkedBankAccountsDocument,
    options
  );
}
export type AddLinkedBankAccountsMutationHookResult = ReturnType<
  typeof useAddLinkedBankAccountsMutation
>;
export type AddLinkedBankAccountsMutationResult =
  Apollo.MutationResult<AddLinkedBankAccountsMutation>;
export type AddLinkedBankAccountsMutationOptions = Apollo.BaseMutationOptions<
  AddLinkedBankAccountsMutation,
  AddLinkedBankAccountsMutationVariables
>;
export const DeleteLinkedBankAccountDocument = gql`
  mutation DeleteLinkedBankAccount($id: ID!) {
    deleteLinkedBankAccount(id: $id) {
      success
      error
      books {
        accountId
        autopay {
          ...AutopayFields
        }
      }
    }
  }
  ${AutopayFieldsFragmentDoc}
`;
export type DeleteLinkedBankAccountMutationFn = Apollo.MutationFunction<
  DeleteLinkedBankAccountMutation,
  DeleteLinkedBankAccountMutationVariables
>;

/**
 * __useDeleteLinkedBankAccountMutation__
 *
 * To run a mutation, you first call `useDeleteLinkedBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLinkedBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLinkedBankAccountMutation, { data, loading, error }] = useDeleteLinkedBankAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLinkedBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLinkedBankAccountMutation,
    DeleteLinkedBankAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLinkedBankAccountMutation,
    DeleteLinkedBankAccountMutationVariables
  >(DeleteLinkedBankAccountDocument, options);
}
export type DeleteLinkedBankAccountMutationHookResult = ReturnType<
  typeof useDeleteLinkedBankAccountMutation
>;
export type DeleteLinkedBankAccountMutationResult =
  Apollo.MutationResult<DeleteLinkedBankAccountMutation>;
export type DeleteLinkedBankAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteLinkedBankAccountMutation,
  DeleteLinkedBankAccountMutationVariables
>;
export const UpdateAutopayCollectionTypesDocument = gql`
  mutation UpdateAutopayCollectionTypes($input: UpdateAutopayCollectionTypesInput!) {
    updateAutopayCollectionTypes(input: $input) {
      success
      error
      books {
        accountId
        autopay {
          ...AutopayFields
        }
      }
    }
  }
  ${AutopayFieldsFragmentDoc}
`;
export type UpdateAutopayCollectionTypesMutationFn = Apollo.MutationFunction<
  UpdateAutopayCollectionTypesMutation,
  UpdateAutopayCollectionTypesMutationVariables
>;

/**
 * __useUpdateAutopayCollectionTypesMutation__
 *
 * To run a mutation, you first call `useUpdateAutopayCollectionTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutopayCollectionTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutopayCollectionTypesMutation, { data, loading, error }] = useUpdateAutopayCollectionTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAutopayCollectionTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAutopayCollectionTypesMutation,
    UpdateAutopayCollectionTypesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAutopayCollectionTypesMutation,
    UpdateAutopayCollectionTypesMutationVariables
  >(UpdateAutopayCollectionTypesDocument, options);
}
export type UpdateAutopayCollectionTypesMutationHookResult = ReturnType<
  typeof useUpdateAutopayCollectionTypesMutation
>;
export type UpdateAutopayCollectionTypesMutationResult =
  Apollo.MutationResult<UpdateAutopayCollectionTypesMutation>;
export type UpdateAutopayCollectionTypesMutationOptions = Apollo.BaseMutationOptions<
  UpdateAutopayCollectionTypesMutation,
  UpdateAutopayCollectionTypesMutationVariables
>;
export const UpdateAutopayNsfFeesDocument = gql`
  mutation UpdateAutopayNSFFees($input: UpdateAutopayNSFFeeInput!) {
    updateAutopayNSFFees(input: $input) {
      success
      error
      books {
        accountId
        autopay {
          ...AutopayFields
        }
      }
    }
  }
  ${AutopayFieldsFragmentDoc}
`;
export type UpdateAutopayNsfFeesMutationFn = Apollo.MutationFunction<
  UpdateAutopayNsfFeesMutation,
  UpdateAutopayNsfFeesMutationVariables
>;

/**
 * __useUpdateAutopayNsfFeesMutation__
 *
 * To run a mutation, you first call `useUpdateAutopayNsfFeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutopayNsfFeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutopayNsfFeesMutation, { data, loading, error }] = useUpdateAutopayNsfFeesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAutopayNsfFeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAutopayNsfFeesMutation,
    UpdateAutopayNsfFeesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAutopayNsfFeesMutation, UpdateAutopayNsfFeesMutationVariables>(
    UpdateAutopayNsfFeesDocument,
    options
  );
}
export type UpdateAutopayNsfFeesMutationHookResult = ReturnType<
  typeof useUpdateAutopayNsfFeesMutation
>;
export type UpdateAutopayNsfFeesMutationResult =
  Apollo.MutationResult<UpdateAutopayNsfFeesMutation>;
export type UpdateAutopayNsfFeesMutationOptions = Apollo.BaseMutationOptions<
  UpdateAutopayNsfFeesMutation,
  UpdateAutopayNsfFeesMutationVariables
>;
export const UpdateAutopayFeeInvoiceDocument = gql`
  mutation UpdateAutopayFeeInvoice($input: UpdateAutopayFeeInvoiceInput!) {
    updateAutopayFeeInvoice(input: $input) {
      success
      error
      books {
        accountId
        autopay {
          ...AutopayFields
        }
      }
    }
  }
  ${AutopayFieldsFragmentDoc}
`;
export type UpdateAutopayFeeInvoiceMutationFn = Apollo.MutationFunction<
  UpdateAutopayFeeInvoiceMutation,
  UpdateAutopayFeeInvoiceMutationVariables
>;

/**
 * __useUpdateAutopayFeeInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateAutopayFeeInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutopayFeeInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutopayFeeInvoiceMutation, { data, loading, error }] = useUpdateAutopayFeeInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAutopayFeeInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAutopayFeeInvoiceMutation,
    UpdateAutopayFeeInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAutopayFeeInvoiceMutation,
    UpdateAutopayFeeInvoiceMutationVariables
  >(UpdateAutopayFeeInvoiceDocument, options);
}
export type UpdateAutopayFeeInvoiceMutationHookResult = ReturnType<
  typeof useUpdateAutopayFeeInvoiceMutation
>;
export type UpdateAutopayFeeInvoiceMutationResult =
  Apollo.MutationResult<UpdateAutopayFeeInvoiceMutation>;
export type UpdateAutopayFeeInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateAutopayFeeInvoiceMutation,
  UpdateAutopayFeeInvoiceMutationVariables
>;
export const AccountAutopayConfigsDocument = gql`
  query AccountAutopayConfigs {
    account {
      id
      books {
        accountId
        glAccountMapping {
          id
          parentId
          glId
        }
        reconciliationConfigs {
          id
          name
          bankId
          scope {
            glId
            propertyOwnerIds
          }
        }
      }
    }
  }
`;

/**
 * __useAccountAutopayConfigsQuery__
 *
 * To run a query within a React component, call `useAccountAutopayConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAutopayConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAutopayConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountAutopayConfigsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountAutopayConfigsQuery,
    AccountAutopayConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountAutopayConfigsQuery, AccountAutopayConfigsQueryVariables>(
    AccountAutopayConfigsDocument,
    options
  );
}
export function useAccountAutopayConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountAutopayConfigsQuery,
    AccountAutopayConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountAutopayConfigsQuery, AccountAutopayConfigsQueryVariables>(
    AccountAutopayConfigsDocument,
    options
  );
}
export type AccountAutopayConfigsQueryHookResult = ReturnType<typeof useAccountAutopayConfigsQuery>;
export type AccountAutopayConfigsLazyQueryHookResult = ReturnType<
  typeof useAccountAutopayConfigsLazyQuery
>;
export type AccountAutopayConfigsQueryResult = Apollo.QueryResult<
  AccountAutopayConfigsQuery,
  AccountAutopayConfigsQueryVariables
>;
export const UpdateAccountAutopayDocument = gql`
  mutation UpdateAccountAutopay($input: UpdateAccountAutopayInput!) {
    updateAccountAutopay(input: $input) {
      success
      error
      account {
        ...AccountFields
      }
    }
  }
  ${AccountFieldsFragmentDoc}
`;
export type UpdateAccountAutopayMutationFn = Apollo.MutationFunction<
  UpdateAccountAutopayMutation,
  UpdateAccountAutopayMutationVariables
>;

/**
 * __useUpdateAccountAutopayMutation__
 *
 * To run a mutation, you first call `useUpdateAccountAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountAutopayMutation, { data, loading, error }] = useUpdateAccountAutopayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountAutopayMutation,
    UpdateAccountAutopayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountAutopayMutation, UpdateAccountAutopayMutationVariables>(
    UpdateAccountAutopayDocument,
    options
  );
}
export type UpdateAccountAutopayMutationHookResult = ReturnType<
  typeof useUpdateAccountAutopayMutation
>;
export type UpdateAccountAutopayMutationResult =
  Apollo.MutationResult<UpdateAccountAutopayMutation>;
export type UpdateAccountAutopayMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountAutopayMutation,
  UpdateAccountAutopayMutationVariables
>;
export const CreateLeaseDocumentTemplateDocument = gql`
  mutation CreateLeaseDocumentTemplate($input: CreateLeaseDocumentTemplateInput!) {
    createLeaseDocumentTemplate(input: $input) {
      success
      error
      leaseDocumentTemplate {
        ...LeaseDocumentTemplateFields
      }
    }
  }
  ${LeaseDocumentTemplateFieldsFragmentDoc}
`;
export type CreateLeaseDocumentTemplateMutationFn = Apollo.MutationFunction<
  CreateLeaseDocumentTemplateMutation,
  CreateLeaseDocumentTemplateMutationVariables
>;

/**
 * __useCreateLeaseDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useCreateLeaseDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeaseDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeaseDocumentTemplateMutation, { data, loading, error }] = useCreateLeaseDocumentTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLeaseDocumentTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLeaseDocumentTemplateMutation,
    CreateLeaseDocumentTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLeaseDocumentTemplateMutation,
    CreateLeaseDocumentTemplateMutationVariables
  >(CreateLeaseDocumentTemplateDocument, options);
}
export type CreateLeaseDocumentTemplateMutationHookResult = ReturnType<
  typeof useCreateLeaseDocumentTemplateMutation
>;
export type CreateLeaseDocumentTemplateMutationResult =
  Apollo.MutationResult<CreateLeaseDocumentTemplateMutation>;
export type CreateLeaseDocumentTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateLeaseDocumentTemplateMutation,
  CreateLeaseDocumentTemplateMutationVariables
>;
export const UpdateLeaseDocumentTemplateDocument = gql`
  mutation UpdateLeaseDocumentTemplate($input: UpdateLeaseDocumentTemplateInput!) {
    updateLeaseDocumentTemplate(input: $input) {
      success
      error
      leaseDocumentTemplate {
        ...LeaseDocumentTemplateFields
      }
    }
  }
  ${LeaseDocumentTemplateFieldsFragmentDoc}
`;
export type UpdateLeaseDocumentTemplateMutationFn = Apollo.MutationFunction<
  UpdateLeaseDocumentTemplateMutation,
  UpdateLeaseDocumentTemplateMutationVariables
>;

/**
 * __useUpdateLeaseDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateLeaseDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeaseDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeaseDocumentTemplateMutation, { data, loading, error }] = useUpdateLeaseDocumentTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLeaseDocumentTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLeaseDocumentTemplateMutation,
    UpdateLeaseDocumentTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLeaseDocumentTemplateMutation,
    UpdateLeaseDocumentTemplateMutationVariables
  >(UpdateLeaseDocumentTemplateDocument, options);
}
export type UpdateLeaseDocumentTemplateMutationHookResult = ReturnType<
  typeof useUpdateLeaseDocumentTemplateMutation
>;
export type UpdateLeaseDocumentTemplateMutationResult =
  Apollo.MutationResult<UpdateLeaseDocumentTemplateMutation>;
export type UpdateLeaseDocumentTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateLeaseDocumentTemplateMutation,
  UpdateLeaseDocumentTemplateMutationVariables
>;
export const GetLeaseDocumentTemplateDocument = gql`
  query GetLeaseDocumentTemplate($id: ID!) {
    leaseDocumentTemplate(id: $id) {
      ...LeaseDocumentTemplateFields
    }
  }
  ${LeaseDocumentTemplateFieldsFragmentDoc}
`;

/**
 * __useGetLeaseDocumentTemplateQuery__
 *
 * To run a query within a React component, call `useGetLeaseDocumentTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseDocumentTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseDocumentTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLeaseDocumentTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeaseDocumentTemplateQuery,
    GetLeaseDocumentTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLeaseDocumentTemplateQuery, GetLeaseDocumentTemplateQueryVariables>(
    GetLeaseDocumentTemplateDocument,
    options
  );
}
export function useGetLeaseDocumentTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaseDocumentTemplateQuery,
    GetLeaseDocumentTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLeaseDocumentTemplateQuery, GetLeaseDocumentTemplateQueryVariables>(
    GetLeaseDocumentTemplateDocument,
    options
  );
}
export type GetLeaseDocumentTemplateQueryHookResult = ReturnType<
  typeof useGetLeaseDocumentTemplateQuery
>;
export type GetLeaseDocumentTemplateLazyQueryHookResult = ReturnType<
  typeof useGetLeaseDocumentTemplateLazyQuery
>;
export type GetLeaseDocumentTemplateQueryResult = Apollo.QueryResult<
  GetLeaseDocumentTemplateQuery,
  GetLeaseDocumentTemplateQueryVariables
>;
export const ListLeaseDocumentTemplatesDocument = gql`
  query ListLeaseDocumentTemplates($nextToken: String) {
    account {
      id
      listLeaseDocumentTemplates(nextToken: $nextToken) {
        items @connection(key: "id") {
          ...LeaseDocumentTemplateListFields
        }
        nextToken
      }
    }
  }
  ${LeaseDocumentTemplateListFieldsFragmentDoc}
`;

/**
 * __useListLeaseDocumentTemplatesQuery__
 *
 * To run a query within a React component, call `useListLeaseDocumentTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLeaseDocumentTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLeaseDocumentTemplatesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListLeaseDocumentTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListLeaseDocumentTemplatesQuery,
    ListLeaseDocumentTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListLeaseDocumentTemplatesQuery, ListLeaseDocumentTemplatesQueryVariables>(
    ListLeaseDocumentTemplatesDocument,
    options
  );
}
export function useListLeaseDocumentTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListLeaseDocumentTemplatesQuery,
    ListLeaseDocumentTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListLeaseDocumentTemplatesQuery,
    ListLeaseDocumentTemplatesQueryVariables
  >(ListLeaseDocumentTemplatesDocument, options);
}
export type ListLeaseDocumentTemplatesQueryHookResult = ReturnType<
  typeof useListLeaseDocumentTemplatesQuery
>;
export type ListLeaseDocumentTemplatesLazyQueryHookResult = ReturnType<
  typeof useListLeaseDocumentTemplatesLazyQuery
>;
export type ListLeaseDocumentTemplatesQueryResult = Apollo.QueryResult<
  ListLeaseDocumentTemplatesQuery,
  ListLeaseDocumentTemplatesQueryVariables
>;
export const LeaseDocumentTemplatesPropertiesDocument = gql`
  query LeaseDocumentTemplatesProperties {
    account {
      id
      pageProperties(first: 1000) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useLeaseDocumentTemplatesPropertiesQuery__
 *
 * To run a query within a React component, call `useLeaseDocumentTemplatesPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaseDocumentTemplatesPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaseDocumentTemplatesPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLeaseDocumentTemplatesPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LeaseDocumentTemplatesPropertiesQuery,
    LeaseDocumentTemplatesPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LeaseDocumentTemplatesPropertiesQuery,
    LeaseDocumentTemplatesPropertiesQueryVariables
  >(LeaseDocumentTemplatesPropertiesDocument, options);
}
export function useLeaseDocumentTemplatesPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeaseDocumentTemplatesPropertiesQuery,
    LeaseDocumentTemplatesPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LeaseDocumentTemplatesPropertiesQuery,
    LeaseDocumentTemplatesPropertiesQueryVariables
  >(LeaseDocumentTemplatesPropertiesDocument, options);
}
export type LeaseDocumentTemplatesPropertiesQueryHookResult = ReturnType<
  typeof useLeaseDocumentTemplatesPropertiesQuery
>;
export type LeaseDocumentTemplatesPropertiesLazyQueryHookResult = ReturnType<
  typeof useLeaseDocumentTemplatesPropertiesLazyQuery
>;
export type LeaseDocumentTemplatesPropertiesQueryResult = Apollo.QueryResult<
  LeaseDocumentTemplatesPropertiesQuery,
  LeaseDocumentTemplatesPropertiesQueryVariables
>;
export const DeleteLeaseDocumentTemplateDocument = gql`
  mutation DeleteLeaseDocumentTemplate($id: ID!) {
    deleteLeaseDocumentTemplate(id: $id) {
      success
      error
    }
  }
`;
export type DeleteLeaseDocumentTemplateMutationFn = Apollo.MutationFunction<
  DeleteLeaseDocumentTemplateMutation,
  DeleteLeaseDocumentTemplateMutationVariables
>;

/**
 * __useDeleteLeaseDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteLeaseDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeaseDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeaseDocumentTemplateMutation, { data, loading, error }] = useDeleteLeaseDocumentTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLeaseDocumentTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLeaseDocumentTemplateMutation,
    DeleteLeaseDocumentTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLeaseDocumentTemplateMutation,
    DeleteLeaseDocumentTemplateMutationVariables
  >(DeleteLeaseDocumentTemplateDocument, options);
}
export type DeleteLeaseDocumentTemplateMutationHookResult = ReturnType<
  typeof useDeleteLeaseDocumentTemplateMutation
>;
export type DeleteLeaseDocumentTemplateMutationResult =
  Apollo.MutationResult<DeleteLeaseDocumentTemplateMutation>;
export type DeleteLeaseDocumentTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteLeaseDocumentTemplateMutation,
  DeleteLeaseDocumentTemplateMutationVariables
>;
export const ManagerSubscriptionsDocument = gql`
  query ManagerSubscriptions {
    manager {
      id
      email
      phone
      subscriptions {
        id
        endpoints {
          id
          address
        }
      }
    }
  }
`;

/**
 * __useManagerSubscriptionsQuery__
 *
 * To run a query within a React component, call `useManagerSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useManagerSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManagerSubscriptionsQuery,
    ManagerSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManagerSubscriptionsQuery, ManagerSubscriptionsQueryVariables>(
    ManagerSubscriptionsDocument,
    options
  );
}
export function useManagerSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManagerSubscriptionsQuery,
    ManagerSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManagerSubscriptionsQuery, ManagerSubscriptionsQueryVariables>(
    ManagerSubscriptionsDocument,
    options
  );
}
export type ManagerSubscriptionsQueryHookResult = ReturnType<typeof useManagerSubscriptionsQuery>;
export type ManagerSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useManagerSubscriptionsLazyQuery
>;
export type ManagerSubscriptionsQueryResult = Apollo.QueryResult<
  ManagerSubscriptionsQuery,
  ManagerSubscriptionsQueryVariables
>;
export const GetTeamDocument = gql`
  query GetTeam($id: ID!) {
    team(id: $id) {
      ...TeamFields
    }
  }
  ${TeamFieldsFragmentDoc}
`;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamQuery(
  baseOptions: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
}
export function useGetTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTeamQuery, GetTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
}
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<GetTeamQuery, GetTeamQueryVariables>;
export const TeamsWizardDocument = gql`
  query TeamsWizard {
    account {
      id
      pageProperties(first: 1000) {
        edges {
          node {
            id
            key
            name
          }
        }
      }
      managers {
        id
        name
        email
        roles
      }
    }
  }
`;

/**
 * __useTeamsWizardQuery__
 *
 * To run a query within a React component, call `useTeamsWizardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsWizardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsWizardQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamsWizardQuery(
  baseOptions?: Apollo.QueryHookOptions<TeamsWizardQuery, TeamsWizardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamsWizardQuery, TeamsWizardQueryVariables>(TeamsWizardDocument, options);
}
export function useTeamsWizardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamsWizardQuery, TeamsWizardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamsWizardQuery, TeamsWizardQueryVariables>(
    TeamsWizardDocument,
    options
  );
}
export type TeamsWizardQueryHookResult = ReturnType<typeof useTeamsWizardQuery>;
export type TeamsWizardLazyQueryHookResult = ReturnType<typeof useTeamsWizardLazyQuery>;
export type TeamsWizardQueryResult = Apollo.QueryResult<
  TeamsWizardQuery,
  TeamsWizardQueryVariables
>;
export const TeamsAdminDocument = gql`
  query TeamsAdmin {
    account {
      id
      pageProperties(first: 1000) {
        edges {
          node {
            id
            name
            enabled
          }
        }
      }
      managers {
        id
        name
      }
      teams {
        ...TeamFields
      }
    }
  }
  ${TeamFieldsFragmentDoc}
`;

/**
 * __useTeamsAdminQuery__
 *
 * To run a query within a React component, call `useTeamsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<TeamsAdminQuery, TeamsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamsAdminQuery, TeamsAdminQueryVariables>(TeamsAdminDocument, options);
}
export function useTeamsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamsAdminQuery, TeamsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamsAdminQuery, TeamsAdminQueryVariables>(
    TeamsAdminDocument,
    options
  );
}
export type TeamsAdminQueryHookResult = ReturnType<typeof useTeamsAdminQuery>;
export type TeamsAdminLazyQueryHookResult = ReturnType<typeof useTeamsAdminLazyQuery>;
export type TeamsAdminQueryResult = Apollo.QueryResult<TeamsAdminQuery, TeamsAdminQueryVariables>;
export const TeamPropertyUnitsDocument = gql`
  query TeamPropertyUnits($propertyId: ID!) {
    property(id: $propertyId) {
      id
      pageUnits(first: 1000) {
        edges {
          node {
            id
            name
            occupancy
            buildingId
            buildingName
          }
        }
      }
    }
  }
`;

/**
 * __useTeamPropertyUnitsQuery__
 *
 * To run a query within a React component, call `useTeamPropertyUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamPropertyUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamPropertyUnitsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useTeamPropertyUnitsQuery(
  baseOptions: Apollo.QueryHookOptions<TeamPropertyUnitsQuery, TeamPropertyUnitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamPropertyUnitsQuery, TeamPropertyUnitsQueryVariables>(
    TeamPropertyUnitsDocument,
    options
  );
}
export function useTeamPropertyUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamPropertyUnitsQuery, TeamPropertyUnitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamPropertyUnitsQuery, TeamPropertyUnitsQueryVariables>(
    TeamPropertyUnitsDocument,
    options
  );
}
export type TeamPropertyUnitsQueryHookResult = ReturnType<typeof useTeamPropertyUnitsQuery>;
export type TeamPropertyUnitsLazyQueryHookResult = ReturnType<typeof useTeamPropertyUnitsLazyQuery>;
export type TeamPropertyUnitsQueryResult = Apollo.QueryResult<
  TeamPropertyUnitsQuery,
  TeamPropertyUnitsQueryVariables
>;
export const CreateTeamDocument = gql`
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      success
      error
      team {
        ...TeamFields
      }
    }
  }
  ${TeamFieldsFragmentDoc}
`;
export type CreateTeamMutationFn = Apollo.MutationFunction<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(
    CreateTeamDocument,
    options
  );
}
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;
export const UpdateTeamDocument = gql`
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      success
      error
      team {
        ...TeamFields
      }
    }
  }
  ${TeamFieldsFragmentDoc}
`;
export type UpdateTeamMutationFn = Apollo.MutationFunction<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(
    UpdateTeamDocument,
    options
  );
}
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;
export const DeleteTeamDocument = gql`
  mutation DeleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      success
      error
    }
  }
`;
export type DeleteTeamMutationFn = Apollo.MutationFunction<
  DeleteTeamMutation,
  DeleteTeamMutationVariables
>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(
    DeleteTeamDocument,
    options
  );
}
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<
  DeleteTeamMutation,
  DeleteTeamMutationVariables
>;
export const GetAccountDocument = gql`
  query GetAccount {
    account {
      ...AccountFields
    }
  }
  ${AccountFieldsFragmentDoc}
`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
}
export function useGetAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(
    GetAccountDocument,
    options
  );
}
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const UpdateAccountDocument = gql`
  mutation UpdateAccount($id: ID!, $input: UpdateAccountInput!) {
    updateAccount(id: $id, input: $input) {
      ...AccountFields
    }
  }
  ${AccountFieldsFragmentDoc}
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(
    UpdateAccountDocument,
    options
  );
}
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const GetManagersDocument = gql`
  query GetManagers {
    account {
      id
      managers {
        ...ManagerFields
      }
    }
  }
  ${ManagerFieldsFragmentDoc}
`;

/**
 * __useGetManagersQuery__
 *
 * To run a query within a React component, call `useGetManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetManagersQuery, GetManagersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManagersQuery, GetManagersQueryVariables>(GetManagersDocument, options);
}
export function useGetManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManagersQuery, GetManagersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManagersQuery, GetManagersQueryVariables>(
    GetManagersDocument,
    options
  );
}
export type GetManagersQueryHookResult = ReturnType<typeof useGetManagersQuery>;
export type GetManagersLazyQueryHookResult = ReturnType<typeof useGetManagersLazyQuery>;
export type GetManagersQueryResult = Apollo.QueryResult<
  GetManagersQuery,
  GetManagersQueryVariables
>;
export const CreateManagerDocument = gql`
  mutation CreateManager($input: CreateManagerInput!) {
    createManager(input: $input) {
      ...ManagerFields
    }
  }
  ${ManagerFieldsFragmentDoc}
`;
export type CreateManagerMutationFn = Apollo.MutationFunction<
  CreateManagerMutation,
  CreateManagerMutationVariables
>;

/**
 * __useCreateManagerMutation__
 *
 * To run a mutation, you first call `useCreateManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManagerMutation, { data, loading, error }] = useCreateManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateManagerMutation, CreateManagerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateManagerMutation, CreateManagerMutationVariables>(
    CreateManagerDocument,
    options
  );
}
export type CreateManagerMutationHookResult = ReturnType<typeof useCreateManagerMutation>;
export type CreateManagerMutationResult = Apollo.MutationResult<CreateManagerMutation>;
export type CreateManagerMutationOptions = Apollo.BaseMutationOptions<
  CreateManagerMutation,
  CreateManagerMutationVariables
>;
export const UpdateManagerDocument = gql`
  mutation UpdateManager($id: ID!, $input: UpdateManagerInput!) {
    updateManager(id: $id, input: $input) {
      ...ManagerFields
    }
  }
  ${ManagerFieldsFragmentDoc}
`;
export type UpdateManagerMutationFn = Apollo.MutationFunction<
  UpdateManagerMutation,
  UpdateManagerMutationVariables
>;

/**
 * __useUpdateManagerMutation__
 *
 * To run a mutation, you first call `useUpdateManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagerMutation, { data, loading, error }] = useUpdateManagerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateManagerMutation, UpdateManagerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateManagerMutation, UpdateManagerMutationVariables>(
    UpdateManagerDocument,
    options
  );
}
export type UpdateManagerMutationHookResult = ReturnType<typeof useUpdateManagerMutation>;
export type UpdateManagerMutationResult = Apollo.MutationResult<UpdateManagerMutation>;
export type UpdateManagerMutationOptions = Apollo.BaseMutationOptions<
  UpdateManagerMutation,
  UpdateManagerMutationVariables
>;
export const ToggleEnableManagerDocument = gql`
  mutation ToggleEnableManager($id: ID!, $enabled: Boolean!) {
    enableManager(id: $id, enabled: $enabled) {
      ...ManagerFields
    }
  }
  ${ManagerFieldsFragmentDoc}
`;
export type ToggleEnableManagerMutationFn = Apollo.MutationFunction<
  ToggleEnableManagerMutation,
  ToggleEnableManagerMutationVariables
>;

/**
 * __useToggleEnableManagerMutation__
 *
 * To run a mutation, you first call `useToggleEnableManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleEnableManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleEnableManagerMutation, { data, loading, error }] = useToggleEnableManagerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useToggleEnableManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleEnableManagerMutation,
    ToggleEnableManagerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleEnableManagerMutation, ToggleEnableManagerMutationVariables>(
    ToggleEnableManagerDocument,
    options
  );
}
export type ToggleEnableManagerMutationHookResult = ReturnType<
  typeof useToggleEnableManagerMutation
>;
export type ToggleEnableManagerMutationResult = Apollo.MutationResult<ToggleEnableManagerMutation>;
export type ToggleEnableManagerMutationOptions = Apollo.BaseMutationOptions<
  ToggleEnableManagerMutation,
  ToggleEnableManagerMutationVariables
>;
export const DeleteManagerDocument = gql`
  mutation DeleteManager($id: ID!) {
    deleteManager(id: $id) {
      ...ManagerFields
    }
  }
  ${ManagerFieldsFragmentDoc}
`;
export type DeleteManagerMutationFn = Apollo.MutationFunction<
  DeleteManagerMutation,
  DeleteManagerMutationVariables
>;

/**
 * __useDeleteManagerMutation__
 *
 * To run a mutation, you first call `useDeleteManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagerMutation, { data, loading, error }] = useDeleteManagerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteManagerMutation, DeleteManagerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteManagerMutation, DeleteManagerMutationVariables>(
    DeleteManagerDocument,
    options
  );
}
export type DeleteManagerMutationHookResult = ReturnType<typeof useDeleteManagerMutation>;
export type DeleteManagerMutationResult = Apollo.MutationResult<DeleteManagerMutation>;
export type DeleteManagerMutationOptions = Apollo.BaseMutationOptions<
  DeleteManagerMutation,
  DeleteManagerMutationVariables
>;
export const AddManagerRoleDocument = gql`
  mutation AddManagerRole($input: ManagerRoleInput!) {
    addManagerRole(input: $input) {
      success
      error
      manager {
        id
        roles
      }
    }
  }
`;
export type AddManagerRoleMutationFn = Apollo.MutationFunction<
  AddManagerRoleMutation,
  AddManagerRoleMutationVariables
>;

/**
 * __useAddManagerRoleMutation__
 *
 * To run a mutation, you first call `useAddManagerRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManagerRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManagerRoleMutation, { data, loading, error }] = useAddManagerRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddManagerRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<AddManagerRoleMutation, AddManagerRoleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddManagerRoleMutation, AddManagerRoleMutationVariables>(
    AddManagerRoleDocument,
    options
  );
}
export type AddManagerRoleMutationHookResult = ReturnType<typeof useAddManagerRoleMutation>;
export type AddManagerRoleMutationResult = Apollo.MutationResult<AddManagerRoleMutation>;
export type AddManagerRoleMutationOptions = Apollo.BaseMutationOptions<
  AddManagerRoleMutation,
  AddManagerRoleMutationVariables
>;
export const RemoveManagerRoleDocument = gql`
  mutation RemoveManagerRole($input: ManagerRoleInput!) {
    removeManagerRole(input: $input) {
      success
      error
      manager {
        id
        roles
      }
    }
  }
`;
export type RemoveManagerRoleMutationFn = Apollo.MutationFunction<
  RemoveManagerRoleMutation,
  RemoveManagerRoleMutationVariables
>;

/**
 * __useRemoveManagerRoleMutation__
 *
 * To run a mutation, you first call `useRemoveManagerRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveManagerRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeManagerRoleMutation, { data, loading, error }] = useRemoveManagerRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveManagerRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveManagerRoleMutation,
    RemoveManagerRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveManagerRoleMutation, RemoveManagerRoleMutationVariables>(
    RemoveManagerRoleDocument,
    options
  );
}
export type RemoveManagerRoleMutationHookResult = ReturnType<typeof useRemoveManagerRoleMutation>;
export type RemoveManagerRoleMutationResult = Apollo.MutationResult<RemoveManagerRoleMutation>;
export type RemoveManagerRoleMutationOptions = Apollo.BaseMutationOptions<
  RemoveManagerRoleMutation,
  RemoveManagerRoleMutationVariables
>;
export const RemoveManagerSignatureDocument = gql`
  mutation RemoveManagerSignature($id: ID!) {
    removeManagerSignature(id: $id) {
      id
      signature {
        id
        signerId
        signature
        signerName
        signerEntity
      }
    }
  }
`;
export type RemoveManagerSignatureMutationFn = Apollo.MutationFunction<
  RemoveManagerSignatureMutation,
  RemoveManagerSignatureMutationVariables
>;

/**
 * __useRemoveManagerSignatureMutation__
 *
 * To run a mutation, you first call `useRemoveManagerSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveManagerSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeManagerSignatureMutation, { data, loading, error }] = useRemoveManagerSignatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveManagerSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveManagerSignatureMutation,
    RemoveManagerSignatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveManagerSignatureMutation,
    RemoveManagerSignatureMutationVariables
  >(RemoveManagerSignatureDocument, options);
}
export type RemoveManagerSignatureMutationHookResult = ReturnType<
  typeof useRemoveManagerSignatureMutation
>;
export type RemoveManagerSignatureMutationResult =
  Apollo.MutationResult<RemoveManagerSignatureMutation>;
export type RemoveManagerSignatureMutationOptions = Apollo.BaseMutationOptions<
  RemoveManagerSignatureMutation,
  RemoveManagerSignatureMutationVariables
>;
export const UpdateManagerSubscriptionsDocument = gql`
  mutation UpdateManagerSubscriptions($input: SubscriptionsInput!) {
    updateManagerSubscriptions(input: $input) {
      success
      error
      manager {
        id
        subscriptions {
          id
          endpoints {
            id
            address
          }
        }
      }
    }
  }
`;
export type UpdateManagerSubscriptionsMutationFn = Apollo.MutationFunction<
  UpdateManagerSubscriptionsMutation,
  UpdateManagerSubscriptionsMutationVariables
>;

/**
 * __useUpdateManagerSubscriptionsMutation__
 *
 * To run a mutation, you first call `useUpdateManagerSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagerSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagerSubscriptionsMutation, { data, loading, error }] = useUpdateManagerSubscriptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManagerSubscriptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManagerSubscriptionsMutation,
    UpdateManagerSubscriptionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateManagerSubscriptionsMutation,
    UpdateManagerSubscriptionsMutationVariables
  >(UpdateManagerSubscriptionsDocument, options);
}
export type UpdateManagerSubscriptionsMutationHookResult = ReturnType<
  typeof useUpdateManagerSubscriptionsMutation
>;
export type UpdateManagerSubscriptionsMutationResult =
  Apollo.MutationResult<UpdateManagerSubscriptionsMutation>;
export type UpdateManagerSubscriptionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateManagerSubscriptionsMutation,
  UpdateManagerSubscriptionsMutationVariables
>;
export const SearchRelatedUnitsDocument = gql`
  query SearchRelatedUnits($filter: SearchUnitsFilterInput!) {
    account {
      id
      books {
        accountId
        searchRelatedUnits(filter: $filter) {
          success
          error
          units {
            unitId
            unitName
            propertyName
            propertyKey
            buildingName
            buildingKey
          }
        }
      }
    }
  }
`;

/**
 * __useSearchRelatedUnitsQuery__
 *
 * To run a query within a React component, call `useSearchRelatedUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRelatedUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRelatedUnitsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchRelatedUnitsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchRelatedUnitsQuery, SearchRelatedUnitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchRelatedUnitsQuery, SearchRelatedUnitsQueryVariables>(
    SearchRelatedUnitsDocument,
    options
  );
}
export function useSearchRelatedUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchRelatedUnitsQuery,
    SearchRelatedUnitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchRelatedUnitsQuery, SearchRelatedUnitsQueryVariables>(
    SearchRelatedUnitsDocument,
    options
  );
}
export type SearchRelatedUnitsQueryHookResult = ReturnType<typeof useSearchRelatedUnitsQuery>;
export type SearchRelatedUnitsLazyQueryHookResult = ReturnType<
  typeof useSearchRelatedUnitsLazyQuery
>;
export type SearchRelatedUnitsQueryResult = Apollo.QueryResult<
  SearchRelatedUnitsQuery,
  SearchRelatedUnitsQueryVariables
>;
export const UnitNameDocument = gql`
  query UnitName($unitId: ID!) {
    unit(id: $unitId) {
      id
      name
    }
  }
`;

/**
 * __useUnitNameQuery__
 *
 * To run a query within a React component, call `useUnitNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitNameQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useUnitNameQuery(
  baseOptions: Apollo.QueryHookOptions<UnitNameQuery, UnitNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnitNameQuery, UnitNameQueryVariables>(UnitNameDocument, options);
}
export function useUnitNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UnitNameQuery, UnitNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnitNameQuery, UnitNameQueryVariables>(UnitNameDocument, options);
}
export type UnitNameQueryHookResult = ReturnType<typeof useUnitNameQuery>;
export type UnitNameLazyQueryHookResult = ReturnType<typeof useUnitNameLazyQuery>;
export type UnitNameQueryResult = Apollo.QueryResult<UnitNameQuery, UnitNameQueryVariables>;
export const GetBooksDocument = gql`
  query GetBooks {
    account {
      id
      address {
        ...AddressFields
      }
      charges {
        id
        name
        default
        limit
      }
      fees {
        id
        name
        deletedZ
      }
      books {
        ...BooksFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
  ${BooksFieldsFragmentDoc}
`;

/**
 * __useGetBooksQuery__
 *
 * To run a query within a React component, call `useGetBooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBooksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBooksQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBooksQuery, GetBooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBooksQuery, GetBooksQueryVariables>(GetBooksDocument, options);
}
export function useGetBooksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBooksQuery, GetBooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBooksQuery, GetBooksQueryVariables>(GetBooksDocument, options);
}
export type GetBooksQueryHookResult = ReturnType<typeof useGetBooksQuery>;
export type GetBooksLazyQueryHookResult = ReturnType<typeof useGetBooksLazyQuery>;
export type GetBooksQueryResult = Apollo.QueryResult<GetBooksQuery, GetBooksQueryVariables>;
export const GetBatchesDocument = gql`
  query GetBatches {
    account {
      id
      books {
        accountId
        batches {
          id
          presetId
          posted
          name
          status
        }
      }
    }
  }
`;

/**
 * __useGetBatchesQuery__
 *
 * To run a query within a React component, call `useGetBatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBatchesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBatchesQuery, GetBatchesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBatchesQuery, GetBatchesQueryVariables>(GetBatchesDocument, options);
}
export function useGetBatchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBatchesQuery, GetBatchesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBatchesQuery, GetBatchesQueryVariables>(
    GetBatchesDocument,
    options
  );
}
export type GetBatchesQueryHookResult = ReturnType<typeof useGetBatchesQuery>;
export type GetBatchesLazyQueryHookResult = ReturnType<typeof useGetBatchesLazyQuery>;
export type GetBatchesQueryResult = Apollo.QueryResult<GetBatchesQuery, GetBatchesQueryVariables>;
export const ListClearablesDocument = gql`
  query ListClearables($nextToken: String, $filter: ClearablesFilterInput) {
    account {
      id
      books {
        accountId
        listClearables(nextToken: $nextToken, filter: $filter) {
          items {
            ...ClearableListFields
          }
          nextToken
        }
      }
    }
  }
  ${ClearableListFieldsFragmentDoc}
`;

/**
 * __useListClearablesQuery__
 *
 * To run a query within a React component, call `useListClearablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClearablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClearablesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListClearablesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListClearablesQuery, ListClearablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListClearablesQuery, ListClearablesQueryVariables>(
    ListClearablesDocument,
    options
  );
}
export function useListClearablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListClearablesQuery, ListClearablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListClearablesQuery, ListClearablesQueryVariables>(
    ListClearablesDocument,
    options
  );
}
export type ListClearablesQueryHookResult = ReturnType<typeof useListClearablesQuery>;
export type ListClearablesLazyQueryHookResult = ReturnType<typeof useListClearablesLazyQuery>;
export type ListClearablesQueryResult = Apollo.QueryResult<
  ListClearablesQuery,
  ListClearablesQueryVariables
>;
export const CountClearablesDocument = gql`
  query CountClearables($filter: ClearablesFilterInput) {
    account {
      id
      books {
        accountId
        countClearables(filter: $filter) {
          rowCount
          amountTotal
          outstandingTotal
        }
      }
    }
  }
`;

/**
 * __useCountClearablesQuery__
 *
 * To run a query within a React component, call `useCountClearablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountClearablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountClearablesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountClearablesQuery(
  baseOptions?: Apollo.QueryHookOptions<CountClearablesQuery, CountClearablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountClearablesQuery, CountClearablesQueryVariables>(
    CountClearablesDocument,
    options
  );
}
export function useCountClearablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountClearablesQuery, CountClearablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountClearablesQuery, CountClearablesQueryVariables>(
    CountClearablesDocument,
    options
  );
}
export type CountClearablesQueryHookResult = ReturnType<typeof useCountClearablesQuery>;
export type CountClearablesLazyQueryHookResult = ReturnType<typeof useCountClearablesLazyQuery>;
export type CountClearablesQueryResult = Apollo.QueryResult<
  CountClearablesQuery,
  CountClearablesQueryVariables
>;
export const GetClearablesByIdDocument = gql`
  query GetClearablesById($ids: [ID!]!) {
    account {
      id
      books {
        accountId
        clearablesById(ids: $ids) {
          success
          error
          clearables {
            ...ClearableFields
          }
        }
      }
    }
  }
  ${ClearableFieldsFragmentDoc}
`;

/**
 * __useGetClearablesByIdQuery__
 *
 * To run a query within a React component, call `useGetClearablesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClearablesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClearablesByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetClearablesByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetClearablesByIdQuery, GetClearablesByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClearablesByIdQuery, GetClearablesByIdQueryVariables>(
    GetClearablesByIdDocument,
    options
  );
}
export function useGetClearablesByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetClearablesByIdQuery, GetClearablesByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClearablesByIdQuery, GetClearablesByIdQueryVariables>(
    GetClearablesByIdDocument,
    options
  );
}
export type GetClearablesByIdQueryHookResult = ReturnType<typeof useGetClearablesByIdQuery>;
export type GetClearablesByIdLazyQueryHookResult = ReturnType<typeof useGetClearablesByIdLazyQuery>;
export type GetClearablesByIdQueryResult = Apollo.QueryResult<
  GetClearablesByIdQuery,
  GetClearablesByIdQueryVariables
>;
export const UpdateClearableDocument = gql`
  mutation UpdateClearable($input: UpdateClearableInput!) {
    updateClearable(input: $input) {
      success
      error
      clearable {
        ...ClearableFields
      }
    }
  }
  ${ClearableFieldsFragmentDoc}
`;
export type UpdateClearableMutationFn = Apollo.MutationFunction<
  UpdateClearableMutation,
  UpdateClearableMutationVariables
>;

/**
 * __useUpdateClearableMutation__
 *
 * To run a mutation, you first call `useUpdateClearableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClearableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClearableMutation, { data, loading, error }] = useUpdateClearableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClearableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClearableMutation,
    UpdateClearableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClearableMutation, UpdateClearableMutationVariables>(
    UpdateClearableDocument,
    options
  );
}
export type UpdateClearableMutationHookResult = ReturnType<typeof useUpdateClearableMutation>;
export type UpdateClearableMutationResult = Apollo.MutationResult<UpdateClearableMutation>;
export type UpdateClearableMutationOptions = Apollo.BaseMutationOptions<
  UpdateClearableMutation,
  UpdateClearableMutationVariables
>;
export const GetClearableDetailsDocument = gql`
  query GetClearableDetails($id: ID!) {
    clearable(id: $id) {
      ...ClearableFields
      journalEntries {
        ...JournalEntryFields
        journalEntries(filter: { excludeSelf: true }) {
          ...JournalEntryFields
        }
      }
    }
  }
  ${ClearableFieldsFragmentDoc}
  ${JournalEntryFieldsFragmentDoc}
`;

/**
 * __useGetClearableDetailsQuery__
 *
 * To run a query within a React component, call `useGetClearableDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClearableDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClearableDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClearableDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetClearableDetailsQuery, GetClearableDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClearableDetailsQuery, GetClearableDetailsQueryVariables>(
    GetClearableDetailsDocument,
    options
  );
}
export function useGetClearableDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClearableDetailsQuery,
    GetClearableDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClearableDetailsQuery, GetClearableDetailsQueryVariables>(
    GetClearableDetailsDocument,
    options
  );
}
export type GetClearableDetailsQueryHookResult = ReturnType<typeof useGetClearableDetailsQuery>;
export type GetClearableDetailsLazyQueryHookResult = ReturnType<
  typeof useGetClearableDetailsLazyQuery
>;
export type GetClearableDetailsQueryResult = Apollo.QueryResult<
  GetClearableDetailsQuery,
  GetClearableDetailsQueryVariables
>;
export const GetRecurrencesDocument = gql`
  query GetRecurrences {
    account {
      id
      books {
        accountId
        recurrences {
          ...RecurrenceFields
        }
      }
    }
  }
  ${RecurrenceFieldsFragmentDoc}
`;

/**
 * __useGetRecurrencesQuery__
 *
 * To run a query within a React component, call `useGetRecurrencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurrencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurrencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecurrencesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRecurrencesQuery, GetRecurrencesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecurrencesQuery, GetRecurrencesQueryVariables>(
    GetRecurrencesDocument,
    options
  );
}
export function useGetRecurrencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecurrencesQuery, GetRecurrencesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecurrencesQuery, GetRecurrencesQueryVariables>(
    GetRecurrencesDocument,
    options
  );
}
export type GetRecurrencesQueryHookResult = ReturnType<typeof useGetRecurrencesQuery>;
export type GetRecurrencesLazyQueryHookResult = ReturnType<typeof useGetRecurrencesLazyQuery>;
export type GetRecurrencesQueryResult = Apollo.QueryResult<
  GetRecurrencesQuery,
  GetRecurrencesQueryVariables
>;
export const DeleteRecurrenceDocument = gql`
  mutation DeleteRecurrence($id: ID!) {
    deleteRecurrence(id: $id) {
      success
      error
    }
  }
`;
export type DeleteRecurrenceMutationFn = Apollo.MutationFunction<
  DeleteRecurrenceMutation,
  DeleteRecurrenceMutationVariables
>;

/**
 * __useDeleteRecurrenceMutation__
 *
 * To run a mutation, you first call `useDeleteRecurrenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecurrenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecurrenceMutation, { data, loading, error }] = useDeleteRecurrenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRecurrenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRecurrenceMutation,
    DeleteRecurrenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRecurrenceMutation, DeleteRecurrenceMutationVariables>(
    DeleteRecurrenceDocument,
    options
  );
}
export type DeleteRecurrenceMutationHookResult = ReturnType<typeof useDeleteRecurrenceMutation>;
export type DeleteRecurrenceMutationResult = Apollo.MutationResult<DeleteRecurrenceMutation>;
export type DeleteRecurrenceMutationOptions = Apollo.BaseMutationOptions<
  DeleteRecurrenceMutation,
  DeleteRecurrenceMutationVariables
>;
export const ActivateBooksDocument = gql`
  mutation ActivateBooks($input: ActivateBooksInput!) {
    activateBooks(input: $input) {
      books {
        accountId
        activatedZ
        glAccounts {
          ...GlAccountFields
        }
      }
    }
  }
  ${GlAccountFieldsFragmentDoc}
`;
export type ActivateBooksMutationFn = Apollo.MutationFunction<
  ActivateBooksMutation,
  ActivateBooksMutationVariables
>;

/**
 * __useActivateBooksMutation__
 *
 * To run a mutation, you first call `useActivateBooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBooksMutation, { data, loading, error }] = useActivateBooksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateBooksMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateBooksMutation, ActivateBooksMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateBooksMutation, ActivateBooksMutationVariables>(
    ActivateBooksDocument,
    options
  );
}
export type ActivateBooksMutationHookResult = ReturnType<typeof useActivateBooksMutation>;
export type ActivateBooksMutationResult = Apollo.MutationResult<ActivateBooksMutation>;
export type ActivateBooksMutationOptions = Apollo.BaseMutationOptions<
  ActivateBooksMutation,
  ActivateBooksMutationVariables
>;
export const UpdateFinancialSettingsDocument = gql`
  mutation UpdateFinancialSettings($input: UpdateFinancialSettingsInput!) {
    updateFinancialSettings(input: $input) {
      success
      error
      books {
        accountId
        receiverGeneralId
        glAccountMapping {
          ...GlMappingFields
        }
        billingRates {
          enabled
          labourRate
          materialsMarkup
          requireApproval
          minBillableMinutes
          trackRevenue
        }
        customBillingRates {
          category
          glId
          enabled
          labourRate
          materialsMarkup
          requireApproval
          minBillableMinutes
          trackRevenue
        }
        incomePayoutOptions {
          excludePayables
          excludePrepayments
          includeOutstandingPayables
        }
        feeMapping {
          feeId
          glId
        }
        chargeMapping {
          chargeId
          glId
        }
        yearEndMonth
        gstFiling
        gstNumber
        chequePlacement
        trackManagementFeeRevenue
        includeDisabledProperties
      }
    }
  }
  ${GlMappingFieldsFragmentDoc}
`;
export type UpdateFinancialSettingsMutationFn = Apollo.MutationFunction<
  UpdateFinancialSettingsMutation,
  UpdateFinancialSettingsMutationVariables
>;

/**
 * __useUpdateFinancialSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateFinancialSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFinancialSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFinancialSettingsMutation, { data, loading, error }] = useUpdateFinancialSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFinancialSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFinancialSettingsMutation,
    UpdateFinancialSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFinancialSettingsMutation,
    UpdateFinancialSettingsMutationVariables
  >(UpdateFinancialSettingsDocument, options);
}
export type UpdateFinancialSettingsMutationHookResult = ReturnType<
  typeof useUpdateFinancialSettingsMutation
>;
export type UpdateFinancialSettingsMutationResult =
  Apollo.MutationResult<UpdateFinancialSettingsMutation>;
export type UpdateFinancialSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateFinancialSettingsMutation,
  UpdateFinancialSettingsMutationVariables
>;
export const CreateGlAccountDocument = gql`
  mutation CreateGlAccount($input: CreateGLAccountInput!) {
    createGLAccount(input: $input) {
      glAccount {
        ...GlAccountFields
      }
    }
  }
  ${GlAccountFieldsFragmentDoc}
`;
export type CreateGlAccountMutationFn = Apollo.MutationFunction<
  CreateGlAccountMutation,
  CreateGlAccountMutationVariables
>;

/**
 * __useCreateGlAccountMutation__
 *
 * To run a mutation, you first call `useCreateGlAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGlAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGlAccountMutation, { data, loading, error }] = useCreateGlAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGlAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGlAccountMutation,
    CreateGlAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGlAccountMutation, CreateGlAccountMutationVariables>(
    CreateGlAccountDocument,
    options
  );
}
export type CreateGlAccountMutationHookResult = ReturnType<typeof useCreateGlAccountMutation>;
export type CreateGlAccountMutationResult = Apollo.MutationResult<CreateGlAccountMutation>;
export type CreateGlAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateGlAccountMutation,
  CreateGlAccountMutationVariables
>;
export const UpdateGlAccountDocument = gql`
  mutation UpdateGlAccount($input: UpdateGLAccountInput!) {
    updateGLAccount(input: $input) {
      glAccount {
        ...GlAccountFields
      }
    }
  }
  ${GlAccountFieldsFragmentDoc}
`;
export type UpdateGlAccountMutationFn = Apollo.MutationFunction<
  UpdateGlAccountMutation,
  UpdateGlAccountMutationVariables
>;

/**
 * __useUpdateGlAccountMutation__
 *
 * To run a mutation, you first call `useUpdateGlAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGlAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGlAccountMutation, { data, loading, error }] = useUpdateGlAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGlAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGlAccountMutation,
    UpdateGlAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGlAccountMutation, UpdateGlAccountMutationVariables>(
    UpdateGlAccountDocument,
    options
  );
}
export type UpdateGlAccountMutationHookResult = ReturnType<typeof useUpdateGlAccountMutation>;
export type UpdateGlAccountMutationResult = Apollo.MutationResult<UpdateGlAccountMutation>;
export type UpdateGlAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateGlAccountMutation,
  UpdateGlAccountMutationVariables
>;
export const AddClearableDocument = gql`
  mutation AddClearable($input: AddClearableInput!) {
    addClearable(input: $input) {
      success
      error
      clearable {
        ...ClearableFields
      }
      recurrence {
        ...RecurrenceFields
      }
      books {
        accountId
        nextInvoice
      }
    }
  }
  ${ClearableFieldsFragmentDoc}
  ${RecurrenceFieldsFragmentDoc}
`;
export type AddClearableMutationFn = Apollo.MutationFunction<
  AddClearableMutation,
  AddClearableMutationVariables
>;

/**
 * __useAddClearableMutation__
 *
 * To run a mutation, you first call `useAddClearableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClearableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClearableMutation, { data, loading, error }] = useAddClearableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddClearableMutation(
  baseOptions?: Apollo.MutationHookOptions<AddClearableMutation, AddClearableMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddClearableMutation, AddClearableMutationVariables>(
    AddClearableDocument,
    options
  );
}
export type AddClearableMutationHookResult = ReturnType<typeof useAddClearableMutation>;
export type AddClearableMutationResult = Apollo.MutationResult<AddClearableMutation>;
export type AddClearableMutationOptions = Apollo.BaseMutationOptions<
  AddClearableMutation,
  AddClearableMutationVariables
>;
export const RefundClearableDocument = gql`
  mutation RefundClearable($id: ID!) {
    refundClearable(id: $id) {
      success
      error
      clearable {
        id
        balanceType
        outstanding
        refund
      }
    }
  }
`;
export type RefundClearableMutationFn = Apollo.MutationFunction<
  RefundClearableMutation,
  RefundClearableMutationVariables
>;

/**
 * __useRefundClearableMutation__
 *
 * To run a mutation, you first call `useRefundClearableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundClearableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundClearableMutation, { data, loading, error }] = useRefundClearableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefundClearableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefundClearableMutation,
    RefundClearableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefundClearableMutation, RefundClearableMutationVariables>(
    RefundClearableDocument,
    options
  );
}
export type RefundClearableMutationHookResult = ReturnType<typeof useRefundClearableMutation>;
export type RefundClearableMutationResult = Apollo.MutationResult<RefundClearableMutation>;
export type RefundClearableMutationOptions = Apollo.BaseMutationOptions<
  RefundClearableMutation,
  RefundClearableMutationVariables
>;
export const AddJournalEntryDocument = gql`
  mutation AddJournalEntry($input: AddJournalEntryInput!) {
    addJournalEntry(input: $input) {
      journalIds
      journalEntries {
        ...JournalEntryFields
      }
      success
      error
      recurrence {
        ...RecurrenceFields
      }
      books {
        accountId
        nextJournal
      }
    }
  }
  ${JournalEntryFieldsFragmentDoc}
  ${RecurrenceFieldsFragmentDoc}
`;
export type AddJournalEntryMutationFn = Apollo.MutationFunction<
  AddJournalEntryMutation,
  AddJournalEntryMutationVariables
>;

/**
 * __useAddJournalEntryMutation__
 *
 * To run a mutation, you first call `useAddJournalEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJournalEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJournalEntryMutation, { data, loading, error }] = useAddJournalEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddJournalEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddJournalEntryMutation,
    AddJournalEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddJournalEntryMutation, AddJournalEntryMutationVariables>(
    AddJournalEntryDocument,
    options
  );
}
export type AddJournalEntryMutationHookResult = ReturnType<typeof useAddJournalEntryMutation>;
export type AddJournalEntryMutationResult = Apollo.MutationResult<AddJournalEntryMutation>;
export type AddJournalEntryMutationOptions = Apollo.BaseMutationOptions<
  AddJournalEntryMutation,
  AddJournalEntryMutationVariables
>;
export const UpdateReconciliationJournalsDocument = gql`
  mutation UpdateReconciliationJournals($input: UpdateReconciliationJournalsInput!) {
    updateReconciliationJournals(input: $input) {
      success
      error
    }
  }
`;
export type UpdateReconciliationJournalsMutationFn = Apollo.MutationFunction<
  UpdateReconciliationJournalsMutation,
  UpdateReconciliationJournalsMutationVariables
>;

/**
 * __useUpdateReconciliationJournalsMutation__
 *
 * To run a mutation, you first call `useUpdateReconciliationJournalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReconciliationJournalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReconciliationJournalsMutation, { data, loading, error }] = useUpdateReconciliationJournalsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReconciliationJournalsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReconciliationJournalsMutation,
    UpdateReconciliationJournalsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReconciliationJournalsMutation,
    UpdateReconciliationJournalsMutationVariables
  >(UpdateReconciliationJournalsDocument, options);
}
export type UpdateReconciliationJournalsMutationHookResult = ReturnType<
  typeof useUpdateReconciliationJournalsMutation
>;
export type UpdateReconciliationJournalsMutationResult =
  Apollo.MutationResult<UpdateReconciliationJournalsMutation>;
export type UpdateReconciliationJournalsMutationOptions = Apollo.BaseMutationOptions<
  UpdateReconciliationJournalsMutation,
  UpdateReconciliationJournalsMutationVariables
>;
export const UpdateJournalEntryDocument = gql`
  mutation UpdateJournalEntry($input: UpdateJournalEntryInput!) {
    updateJournalEntry(input: $input) {
      journalIds
      journalEntries {
        id
        ownerId
        glId
        jeId
        unitId
        amount
        description
        posted
        propertyId
        paymentMethod
        ref
        gstInfo
        printedZ
        voidedZ
        requestId
        payee
        payeeId
        payeeName
        clearableId
        clearableLinked
        notes {
          id
          target
          source
          createdName
          createdSub
          createdZ
          text
        }
        lines {
          id
          glId
          ownerId
          propertyId
          unitId
          amount
          description
          clearableId
        }
        reconciliationId
        approverName
      }
      success
      error
    }
  }
`;
export type UpdateJournalEntryMutationFn = Apollo.MutationFunction<
  UpdateJournalEntryMutation,
  UpdateJournalEntryMutationVariables
>;

/**
 * __useUpdateJournalEntryMutation__
 *
 * To run a mutation, you first call `useUpdateJournalEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJournalEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJournalEntryMutation, { data, loading, error }] = useUpdateJournalEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJournalEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateJournalEntryMutation,
    UpdateJournalEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateJournalEntryMutation, UpdateJournalEntryMutationVariables>(
    UpdateJournalEntryDocument,
    options
  );
}
export type UpdateJournalEntryMutationHookResult = ReturnType<typeof useUpdateJournalEntryMutation>;
export type UpdateJournalEntryMutationResult = Apollo.MutationResult<UpdateJournalEntryMutation>;
export type UpdateJournalEntryMutationOptions = Apollo.BaseMutationOptions<
  UpdateJournalEntryMutation,
  UpdateJournalEntryMutationVariables
>;
export const DeleteJournalEntryDocument = gql`
  mutation DeleteJournalEntry($id: ID!) {
    deleteJournalEntry(id: $id) {
      success
      error
    }
  }
`;
export type DeleteJournalEntryMutationFn = Apollo.MutationFunction<
  DeleteJournalEntryMutation,
  DeleteJournalEntryMutationVariables
>;

/**
 * __useDeleteJournalEntryMutation__
 *
 * To run a mutation, you first call `useDeleteJournalEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJournalEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJournalEntryMutation, { data, loading, error }] = useDeleteJournalEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteJournalEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteJournalEntryMutation,
    DeleteJournalEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteJournalEntryMutation, DeleteJournalEntryMutationVariables>(
    DeleteJournalEntryDocument,
    options
  );
}
export type DeleteJournalEntryMutationHookResult = ReturnType<typeof useDeleteJournalEntryMutation>;
export type DeleteJournalEntryMutationResult = Apollo.MutationResult<DeleteJournalEntryMutation>;
export type DeleteJournalEntryMutationOptions = Apollo.BaseMutationOptions<
  DeleteJournalEntryMutation,
  DeleteJournalEntryMutationVariables
>;
export const GetJournalEntryDocument = gql`
  query GetJournalEntry($id: ID!) {
    journalEntry(id: $id) {
      ...JournalEntryFields
    }
  }
  ${JournalEntryFieldsFragmentDoc}
`;

/**
 * __useGetJournalEntryQuery__
 *
 * To run a query within a React component, call `useGetJournalEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalEntryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJournalEntryQuery(
  baseOptions: Apollo.QueryHookOptions<GetJournalEntryQuery, GetJournalEntryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJournalEntryQuery, GetJournalEntryQueryVariables>(
    GetJournalEntryDocument,
    options
  );
}
export function useGetJournalEntryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJournalEntryQuery, GetJournalEntryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJournalEntryQuery, GetJournalEntryQueryVariables>(
    GetJournalEntryDocument,
    options
  );
}
export type GetJournalEntryQueryHookResult = ReturnType<typeof useGetJournalEntryQuery>;
export type GetJournalEntryLazyQueryHookResult = ReturnType<typeof useGetJournalEntryLazyQuery>;
export type GetJournalEntryQueryResult = Apollo.QueryResult<
  GetJournalEntryQuery,
  GetJournalEntryQueryVariables
>;
export const GetJournalEntryDetailsDocument = gql`
  query GetJournalEntryDetails($id: ID!) {
    journalEntry(id: $id) {
      ...JournalEntryFields
      relatedClearables {
        id
        due
        posted
        glId
        ownerId
        sourceJournalId
        sourceJournalEntry {
          id
          ownerId
          glId
          jeId
          unitId
          amount
          description
          posted
          propertyId
          paymentMethod
          ref
          gstInfo
          printedZ
          voidedZ
          requestId
          payee
          payeeId
          payeeName
          reconciliationId
        }
        payeeId
        payeeName
        unitName
        buildingName
        propertyName
        propertyKey
        unitId
        propertyId
        payee
        amount
        outstanding
        cleared
        ref
        balanceType
        batchId
        refund
      }
      journalEntries {
        ...JournalEntryFields
      }
    }
  }
  ${JournalEntryFieldsFragmentDoc}
`;

/**
 * __useGetJournalEntryDetailsQuery__
 *
 * To run a query within a React component, call `useGetJournalEntryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalEntryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalEntryDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJournalEntryDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJournalEntryDetailsQuery,
    GetJournalEntryDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJournalEntryDetailsQuery, GetJournalEntryDetailsQueryVariables>(
    GetJournalEntryDetailsDocument,
    options
  );
}
export function useGetJournalEntryDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJournalEntryDetailsQuery,
    GetJournalEntryDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJournalEntryDetailsQuery, GetJournalEntryDetailsQueryVariables>(
    GetJournalEntryDetailsDocument,
    options
  );
}
export type GetJournalEntryDetailsQueryHookResult = ReturnType<
  typeof useGetJournalEntryDetailsQuery
>;
export type GetJournalEntryDetailsLazyQueryHookResult = ReturnType<
  typeof useGetJournalEntryDetailsLazyQuery
>;
export type GetJournalEntryDetailsQueryResult = Apollo.QueryResult<
  GetJournalEntryDetailsQuery,
  GetJournalEntryDetailsQueryVariables
>;
export const JournalWithJournalsDocument = gql`
  query JournalWithJournals($id: ID!) {
    journalEntry(id: $id) {
      ...JournalEntryFields
      journalEntries {
        ...JournalEntryFields
      }
    }
  }
  ${JournalEntryFieldsFragmentDoc}
`;

/**
 * __useJournalWithJournalsQuery__
 *
 * To run a query within a React component, call `useJournalWithJournalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJournalWithJournalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJournalWithJournalsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJournalWithJournalsQuery(
  baseOptions: Apollo.QueryHookOptions<JournalWithJournalsQuery, JournalWithJournalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JournalWithJournalsQuery, JournalWithJournalsQueryVariables>(
    JournalWithJournalsDocument,
    options
  );
}
export function useJournalWithJournalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JournalWithJournalsQuery,
    JournalWithJournalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JournalWithJournalsQuery, JournalWithJournalsQueryVariables>(
    JournalWithJournalsDocument,
    options
  );
}
export type JournalWithJournalsQueryHookResult = ReturnType<typeof useJournalWithJournalsQuery>;
export type JournalWithJournalsLazyQueryHookResult = ReturnType<
  typeof useJournalWithJournalsLazyQuery
>;
export type JournalWithJournalsQueryResult = Apollo.QueryResult<
  JournalWithJournalsQuery,
  JournalWithJournalsQueryVariables
>;
export const GetJournalEntriesForJeDocument = gql`
  query GetJournalEntriesForJE($jeId: ID!) {
    account {
      id
      books {
        accountId
        journalEntriesForJE(jeId: $jeId) {
          ...JournalEntryFields
        }
      }
    }
  }
  ${JournalEntryFieldsFragmentDoc}
`;

/**
 * __useGetJournalEntriesForJeQuery__
 *
 * To run a query within a React component, call `useGetJournalEntriesForJeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalEntriesForJeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalEntriesForJeQuery({
 *   variables: {
 *      jeId: // value for 'jeId'
 *   },
 * });
 */
export function useGetJournalEntriesForJeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJournalEntriesForJeQuery,
    GetJournalEntriesForJeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJournalEntriesForJeQuery, GetJournalEntriesForJeQueryVariables>(
    GetJournalEntriesForJeDocument,
    options
  );
}
export function useGetJournalEntriesForJeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJournalEntriesForJeQuery,
    GetJournalEntriesForJeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJournalEntriesForJeQuery, GetJournalEntriesForJeQueryVariables>(
    GetJournalEntriesForJeDocument,
    options
  );
}
export type GetJournalEntriesForJeQueryHookResult = ReturnType<
  typeof useGetJournalEntriesForJeQuery
>;
export type GetJournalEntriesForJeLazyQueryHookResult = ReturnType<
  typeof useGetJournalEntriesForJeLazyQuery
>;
export type GetJournalEntriesForJeQueryResult = Apollo.QueryResult<
  GetJournalEntriesForJeQuery,
  GetJournalEntriesForJeQueryVariables
>;
export const CreateDraftBatchDocument = gql`
  mutation CreateDraftBatch($input: CreateDraftJEBatchInput!) {
    createDraftBatch(input: $input) {
      success
      error
      batch {
        ...DraftBatchFields
      }
    }
  }
  ${DraftBatchFieldsFragmentDoc}
`;
export type CreateDraftBatchMutationFn = Apollo.MutationFunction<
  CreateDraftBatchMutation,
  CreateDraftBatchMutationVariables
>;

/**
 * __useCreateDraftBatchMutation__
 *
 * To run a mutation, you first call `useCreateDraftBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftBatchMutation, { data, loading, error }] = useCreateDraftBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDraftBatchMutation,
    CreateDraftBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDraftBatchMutation, CreateDraftBatchMutationVariables>(
    CreateDraftBatchDocument,
    options
  );
}
export type CreateDraftBatchMutationHookResult = ReturnType<typeof useCreateDraftBatchMutation>;
export type CreateDraftBatchMutationResult = Apollo.MutationResult<CreateDraftBatchMutation>;
export type CreateDraftBatchMutationOptions = Apollo.BaseMutationOptions<
  CreateDraftBatchMutation,
  CreateDraftBatchMutationVariables
>;
export const GetBatchDocument = gql`
  query GetBatch($batchId: ID!) {
    batch(id: $batchId) {
      ...BatchFields
    }
  }
  ${BatchFieldsFragmentDoc}
`;

/**
 * __useGetBatchQuery__
 *
 * To run a query within a React component, call `useGetBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchQuery({
 *   variables: {
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useGetBatchQuery(
  baseOptions: Apollo.QueryHookOptions<GetBatchQuery, GetBatchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBatchQuery, GetBatchQueryVariables>(GetBatchDocument, options);
}
export function useGetBatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBatchQuery, GetBatchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBatchQuery, GetBatchQueryVariables>(GetBatchDocument, options);
}
export type GetBatchQueryHookResult = ReturnType<typeof useGetBatchQuery>;
export type GetBatchLazyQueryHookResult = ReturnType<typeof useGetBatchLazyQuery>;
export type GetBatchQueryResult = Apollo.QueryResult<GetBatchQuery, GetBatchQueryVariables>;
export const GetBatchPrototypesDocument = gql`
  query GetBatchPrototypes($nextToken: String, $batchId: ID!) {
    batch(id: $batchId) {
      ...BatchFields
      listPrototypes(nextToken: $nextToken) {
        nextToken
        items {
          ...BatchPrototypeFields
        }
      }
    }
  }
  ${BatchFieldsFragmentDoc}
  ${BatchPrototypeFieldsFragmentDoc}
`;

/**
 * __useGetBatchPrototypesQuery__
 *
 * To run a query within a React component, call `useGetBatchPrototypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchPrototypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchPrototypesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useGetBatchPrototypesQuery(
  baseOptions: Apollo.QueryHookOptions<GetBatchPrototypesQuery, GetBatchPrototypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBatchPrototypesQuery, GetBatchPrototypesQueryVariables>(
    GetBatchPrototypesDocument,
    options
  );
}
export function useGetBatchPrototypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBatchPrototypesQuery,
    GetBatchPrototypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBatchPrototypesQuery, GetBatchPrototypesQueryVariables>(
    GetBatchPrototypesDocument,
    options
  );
}
export type GetBatchPrototypesQueryHookResult = ReturnType<typeof useGetBatchPrototypesQuery>;
export type GetBatchPrototypesLazyQueryHookResult = ReturnType<
  typeof useGetBatchPrototypesLazyQuery
>;
export type GetBatchPrototypesQueryResult = Apollo.QueryResult<
  GetBatchPrototypesQuery,
  GetBatchPrototypesQueryVariables
>;
export const UpdateBatchPrototypeDocument = gql`
  mutation UpdateBatchPrototype($input: JEPrototypeInput!) {
    updateBatchPrototype(input: $input) {
      success
      error
      prototype {
        ...BatchPrototypeFields
      }
    }
  }
  ${BatchPrototypeFieldsFragmentDoc}
`;
export type UpdateBatchPrototypeMutationFn = Apollo.MutationFunction<
  UpdateBatchPrototypeMutation,
  UpdateBatchPrototypeMutationVariables
>;

/**
 * __useUpdateBatchPrototypeMutation__
 *
 * To run a mutation, you first call `useUpdateBatchPrototypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBatchPrototypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBatchPrototypeMutation, { data, loading, error }] = useUpdateBatchPrototypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBatchPrototypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBatchPrototypeMutation,
    UpdateBatchPrototypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBatchPrototypeMutation, UpdateBatchPrototypeMutationVariables>(
    UpdateBatchPrototypeDocument,
    options
  );
}
export type UpdateBatchPrototypeMutationHookResult = ReturnType<
  typeof useUpdateBatchPrototypeMutation
>;
export type UpdateBatchPrototypeMutationResult =
  Apollo.MutationResult<UpdateBatchPrototypeMutation>;
export type UpdateBatchPrototypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateBatchPrototypeMutation,
  UpdateBatchPrototypeMutationVariables
>;
export const ToggleBatchPrototypesDocument = gql`
  mutation ToggleBatchPrototypes($input: JEPrototypeKeysInput!) {
    toggleBatchPrototypes(input: $input) {
      success
      error
      prototypeKeys {
        id
        batchId
      }
    }
  }
`;
export type ToggleBatchPrototypesMutationFn = Apollo.MutationFunction<
  ToggleBatchPrototypesMutation,
  ToggleBatchPrototypesMutationVariables
>;

/**
 * __useToggleBatchPrototypesMutation__
 *
 * To run a mutation, you first call `useToggleBatchPrototypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBatchPrototypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBatchPrototypesMutation, { data, loading, error }] = useToggleBatchPrototypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleBatchPrototypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleBatchPrototypesMutation,
    ToggleBatchPrototypesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleBatchPrototypesMutation, ToggleBatchPrototypesMutationVariables>(
    ToggleBatchPrototypesDocument,
    options
  );
}
export type ToggleBatchPrototypesMutationHookResult = ReturnType<
  typeof useToggleBatchPrototypesMutation
>;
export type ToggleBatchPrototypesMutationResult =
  Apollo.MutationResult<ToggleBatchPrototypesMutation>;
export type ToggleBatchPrototypesMutationOptions = Apollo.BaseMutationOptions<
  ToggleBatchPrototypesMutation,
  ToggleBatchPrototypesMutationVariables
>;
export const InitiateBooksBatchDocument = gql`
  mutation InitiateBooksBatch($input: InitiateBooksBatchInput!) {
    initiateBooksBatch(input: $input) {
      success
      error
      batch {
        ...BatchFields
      }
    }
  }
  ${BatchFieldsFragmentDoc}
`;
export type InitiateBooksBatchMutationFn = Apollo.MutationFunction<
  InitiateBooksBatchMutation,
  InitiateBooksBatchMutationVariables
>;

/**
 * __useInitiateBooksBatchMutation__
 *
 * To run a mutation, you first call `useInitiateBooksBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateBooksBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateBooksBatchMutation, { data, loading, error }] = useInitiateBooksBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiateBooksBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateBooksBatchMutation,
    InitiateBooksBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InitiateBooksBatchMutation, InitiateBooksBatchMutationVariables>(
    InitiateBooksBatchDocument,
    options
  );
}
export type InitiateBooksBatchMutationHookResult = ReturnType<typeof useInitiateBooksBatchMutation>;
export type InitiateBooksBatchMutationResult = Apollo.MutationResult<InitiateBooksBatchMutation>;
export type InitiateBooksBatchMutationOptions = Apollo.BaseMutationOptions<
  InitiateBooksBatchMutation,
  InitiateBooksBatchMutationVariables
>;
export const CancelBooksBatchDocument = gql`
  mutation CancelBooksBatch($id: ID!) {
    cancelBooksBatch(id: $id) {
      success
      error
      batch {
        ...BatchFields
      }
    }
  }
  ${BatchFieldsFragmentDoc}
`;
export type CancelBooksBatchMutationFn = Apollo.MutationFunction<
  CancelBooksBatchMutation,
  CancelBooksBatchMutationVariables
>;

/**
 * __useCancelBooksBatchMutation__
 *
 * To run a mutation, you first call `useCancelBooksBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBooksBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBooksBatchMutation, { data, loading, error }] = useCancelBooksBatchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelBooksBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelBooksBatchMutation,
    CancelBooksBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelBooksBatchMutation, CancelBooksBatchMutationVariables>(
    CancelBooksBatchDocument,
    options
  );
}
export type CancelBooksBatchMutationHookResult = ReturnType<typeof useCancelBooksBatchMutation>;
export type CancelBooksBatchMutationResult = Apollo.MutationResult<CancelBooksBatchMutation>;
export type CancelBooksBatchMutationOptions = Apollo.BaseMutationOptions<
  CancelBooksBatchMutation,
  CancelBooksBatchMutationVariables
>;
export const CommitBooksBatchDocument = gql`
  mutation CommitBooksBatch($id: ID!) {
    commitBooksBatch(id: $id) {
      success
      error
      batch {
        ...BatchFields
      }
    }
  }
  ${BatchFieldsFragmentDoc}
`;
export type CommitBooksBatchMutationFn = Apollo.MutationFunction<
  CommitBooksBatchMutation,
  CommitBooksBatchMutationVariables
>;

/**
 * __useCommitBooksBatchMutation__
 *
 * To run a mutation, you first call `useCommitBooksBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommitBooksBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commitBooksBatchMutation, { data, loading, error }] = useCommitBooksBatchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommitBooksBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CommitBooksBatchMutation,
    CommitBooksBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CommitBooksBatchMutation, CommitBooksBatchMutationVariables>(
    CommitBooksBatchDocument,
    options
  );
}
export type CommitBooksBatchMutationHookResult = ReturnType<typeof useCommitBooksBatchMutation>;
export type CommitBooksBatchMutationResult = Apollo.MutationResult<CommitBooksBatchMutation>;
export type CommitBooksBatchMutationOptions = Apollo.BaseMutationOptions<
  CommitBooksBatchMutation,
  CommitBooksBatchMutationVariables
>;
export const GetBooksReportDocument = gql`
  query GetBooksReport($input: BooksReportInput!) {
    account {
      id
      books {
        accountId
        report(input: $input) {
          success
          error
          report {
            ...BooksReportFields
          }
          docDefinition
        }
      }
    }
  }
  ${BooksReportFieldsFragmentDoc}
`;

/**
 * __useGetBooksReportQuery__
 *
 * To run a query within a React component, call `useGetBooksReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBooksReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBooksReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBooksReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetBooksReportQuery, GetBooksReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBooksReportQuery, GetBooksReportQueryVariables>(
    GetBooksReportDocument,
    options
  );
}
export function useGetBooksReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBooksReportQuery, GetBooksReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBooksReportQuery, GetBooksReportQueryVariables>(
    GetBooksReportDocument,
    options
  );
}
export type GetBooksReportQueryHookResult = ReturnType<typeof useGetBooksReportQuery>;
export type GetBooksReportLazyQueryHookResult = ReturnType<typeof useGetBooksReportLazyQuery>;
export type GetBooksReportQueryResult = Apollo.QueryResult<
  GetBooksReportQuery,
  GetBooksReportQueryVariables
>;
export const GetBooksReportDefinitionDocument = gql`
  query GetBooksReportDefinition($input: BooksReportInput!) {
    account {
      id
      books {
        accountId
        renderReport(input: $input) {
          success
          error
          docDefinition
        }
      }
    }
  }
`;

/**
 * __useGetBooksReportDefinitionQuery__
 *
 * To run a query within a React component, call `useGetBooksReportDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBooksReportDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBooksReportDefinitionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBooksReportDefinitionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBooksReportDefinitionQuery,
    GetBooksReportDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBooksReportDefinitionQuery, GetBooksReportDefinitionQueryVariables>(
    GetBooksReportDefinitionDocument,
    options
  );
}
export function useGetBooksReportDefinitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBooksReportDefinitionQuery,
    GetBooksReportDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBooksReportDefinitionQuery, GetBooksReportDefinitionQueryVariables>(
    GetBooksReportDefinitionDocument,
    options
  );
}
export type GetBooksReportDefinitionQueryHookResult = ReturnType<
  typeof useGetBooksReportDefinitionQuery
>;
export type GetBooksReportDefinitionLazyQueryHookResult = ReturnType<
  typeof useGetBooksReportDefinitionLazyQuery
>;
export type GetBooksReportDefinitionQueryResult = Apollo.QueryResult<
  GetBooksReportDefinitionQuery,
  GetBooksReportDefinitionQueryVariables
>;
export const UpdatePresetGlMappingDocument = gql`
  mutation UpdatePresetGlMapping($input: UpdatePresetGLMappingInput!) {
    updatePresetGLMapping(input: $input) {
      success
      error
      preset {
        ...PresetFields
      }
    }
  }
  ${PresetFieldsFragmentDoc}
`;
export type UpdatePresetGlMappingMutationFn = Apollo.MutationFunction<
  UpdatePresetGlMappingMutation,
  UpdatePresetGlMappingMutationVariables
>;

/**
 * __useUpdatePresetGlMappingMutation__
 *
 * To run a mutation, you first call `useUpdatePresetGlMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePresetGlMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePresetGlMappingMutation, { data, loading, error }] = useUpdatePresetGlMappingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePresetGlMappingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePresetGlMappingMutation,
    UpdatePresetGlMappingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePresetGlMappingMutation, UpdatePresetGlMappingMutationVariables>(
    UpdatePresetGlMappingDocument,
    options
  );
}
export type UpdatePresetGlMappingMutationHookResult = ReturnType<
  typeof useUpdatePresetGlMappingMutation
>;
export type UpdatePresetGlMappingMutationResult =
  Apollo.MutationResult<UpdatePresetGlMappingMutation>;
export type UpdatePresetGlMappingMutationOptions = Apollo.BaseMutationOptions<
  UpdatePresetGlMappingMutation,
  UpdatePresetGlMappingMutationVariables
>;
export const UpdateGlChequePlacementDocument = gql`
  mutation UpdateGlChequePlacement($input: GLChequePlacementInput!) {
    updateGLChequePlacement(input: $input) {
      success
      error
      books {
        accountId
        printedCheques {
          glId
          chequePlacement
        }
      }
    }
  }
`;
export type UpdateGlChequePlacementMutationFn = Apollo.MutationFunction<
  UpdateGlChequePlacementMutation,
  UpdateGlChequePlacementMutationVariables
>;

/**
 * __useUpdateGlChequePlacementMutation__
 *
 * To run a mutation, you first call `useUpdateGlChequePlacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGlChequePlacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGlChequePlacementMutation, { data, loading, error }] = useUpdateGlChequePlacementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGlChequePlacementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGlChequePlacementMutation,
    UpdateGlChequePlacementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGlChequePlacementMutation,
    UpdateGlChequePlacementMutationVariables
  >(UpdateGlChequePlacementDocument, options);
}
export type UpdateGlChequePlacementMutationHookResult = ReturnType<
  typeof useUpdateGlChequePlacementMutation
>;
export type UpdateGlChequePlacementMutationResult =
  Apollo.MutationResult<UpdateGlChequePlacementMutation>;
export type UpdateGlChequePlacementMutationOptions = Apollo.BaseMutationOptions<
  UpdateGlChequePlacementMutation,
  UpdateGlChequePlacementMutationVariables
>;
export const VoidJournalEntryDocument = gql`
  mutation VoidJournalEntry($input: VoidJournalEntryInput!) {
    voidJournalEntry(input: $input) {
      success
      error
    }
  }
`;
export type VoidJournalEntryMutationFn = Apollo.MutationFunction<
  VoidJournalEntryMutation,
  VoidJournalEntryMutationVariables
>;

/**
 * __useVoidJournalEntryMutation__
 *
 * To run a mutation, you first call `useVoidJournalEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidJournalEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidJournalEntryMutation, { data, loading, error }] = useVoidJournalEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoidJournalEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VoidJournalEntryMutation,
    VoidJournalEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VoidJournalEntryMutation, VoidJournalEntryMutationVariables>(
    VoidJournalEntryDocument,
    options
  );
}
export type VoidJournalEntryMutationHookResult = ReturnType<typeof useVoidJournalEntryMutation>;
export type VoidJournalEntryMutationResult = Apollo.MutationResult<VoidJournalEntryMutation>;
export type VoidJournalEntryMutationOptions = Apollo.BaseMutationOptions<
  VoidJournalEntryMutation,
  VoidJournalEntryMutationVariables
>;
export const EditableGlAccountsDocument = gql`
  query EditableGlAccounts {
    account {
      id
      books {
        accountId
        editableGlAccounts
      }
    }
  }
`;

/**
 * __useEditableGlAccountsQuery__
 *
 * To run a query within a React component, call `useEditableGlAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditableGlAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditableGlAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditableGlAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<EditableGlAccountsQuery, EditableGlAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EditableGlAccountsQuery, EditableGlAccountsQueryVariables>(
    EditableGlAccountsDocument,
    options
  );
}
export function useEditableGlAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditableGlAccountsQuery,
    EditableGlAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EditableGlAccountsQuery, EditableGlAccountsQueryVariables>(
    EditableGlAccountsDocument,
    options
  );
}
export type EditableGlAccountsQueryHookResult = ReturnType<typeof useEditableGlAccountsQuery>;
export type EditableGlAccountsLazyQueryHookResult = ReturnType<
  typeof useEditableGlAccountsLazyQuery
>;
export type EditableGlAccountsQueryResult = Apollo.QueryResult<
  EditableGlAccountsQuery,
  EditableGlAccountsQueryVariables
>;
export const ClearablePropertyDocument = gql`
  query ClearableProperty($propertyId: ID!) {
    property(id: $propertyId) {
      id
      autopayCollectionsEnabled
      autopayCollectionsEnabledZ
    }
  }
`;

/**
 * __useClearablePropertyQuery__
 *
 * To run a query within a React component, call `useClearablePropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useClearablePropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClearablePropertyQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useClearablePropertyQuery(
  baseOptions: Apollo.QueryHookOptions<ClearablePropertyQuery, ClearablePropertyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClearablePropertyQuery, ClearablePropertyQueryVariables>(
    ClearablePropertyDocument,
    options
  );
}
export function useClearablePropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClearablePropertyQuery, ClearablePropertyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClearablePropertyQuery, ClearablePropertyQueryVariables>(
    ClearablePropertyDocument,
    options
  );
}
export type ClearablePropertyQueryHookResult = ReturnType<typeof useClearablePropertyQuery>;
export type ClearablePropertyLazyQueryHookResult = ReturnType<typeof useClearablePropertyLazyQuery>;
export type ClearablePropertyQueryResult = Apollo.QueryResult<
  ClearablePropertyQuery,
  ClearablePropertyQueryVariables
>;
export const OwnerGstDocument = gql`
  query OwnerGst($ownerId: ID!) {
    owner(id: $ownerId) {
      id
      gstNumber
    }
  }
`;

/**
 * __useOwnerGstQuery__
 *
 * To run a query within a React component, call `useOwnerGstQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerGstQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerGstQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useOwnerGstQuery(
  baseOptions: Apollo.QueryHookOptions<OwnerGstQuery, OwnerGstQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OwnerGstQuery, OwnerGstQueryVariables>(OwnerGstDocument, options);
}
export function useOwnerGstLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OwnerGstQuery, OwnerGstQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OwnerGstQuery, OwnerGstQueryVariables>(OwnerGstDocument, options);
}
export type OwnerGstQueryHookResult = ReturnType<typeof useOwnerGstQuery>;
export type OwnerGstLazyQueryHookResult = ReturnType<typeof useOwnerGstLazyQuery>;
export type OwnerGstQueryResult = Apollo.QueryResult<OwnerGstQuery, OwnerGstQueryVariables>;
export const SetClearableAutopayCollectionsEnabledDocument = gql`
  mutation setClearableAutopayCollectionsEnabled(
    $input: SetClearableAutopayCollectionsEnabledInput!
  ) {
    setClearableAutopayCollectionsEnabled(input: $input) {
      success
      error
      clearable {
        id
        autopayCollectionsEnabled
      }
    }
  }
`;
export type SetClearableAutopayCollectionsEnabledMutationFn = Apollo.MutationFunction<
  SetClearableAutopayCollectionsEnabledMutation,
  SetClearableAutopayCollectionsEnabledMutationVariables
>;

/**
 * __useSetClearableAutopayCollectionsEnabledMutation__
 *
 * To run a mutation, you first call `useSetClearableAutopayCollectionsEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClearableAutopayCollectionsEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClearableAutopayCollectionsEnabledMutation, { data, loading, error }] = useSetClearableAutopayCollectionsEnabledMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetClearableAutopayCollectionsEnabledMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetClearableAutopayCollectionsEnabledMutation,
    SetClearableAutopayCollectionsEnabledMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetClearableAutopayCollectionsEnabledMutation,
    SetClearableAutopayCollectionsEnabledMutationVariables
  >(SetClearableAutopayCollectionsEnabledDocument, options);
}
export type SetClearableAutopayCollectionsEnabledMutationHookResult = ReturnType<
  typeof useSetClearableAutopayCollectionsEnabledMutation
>;
export type SetClearableAutopayCollectionsEnabledMutationResult =
  Apollo.MutationResult<SetClearableAutopayCollectionsEnabledMutation>;
export type SetClearableAutopayCollectionsEnabledMutationOptions = Apollo.BaseMutationOptions<
  SetClearableAutopayCollectionsEnabledMutation,
  SetClearableAutopayCollectionsEnabledMutationVariables
>;
export const GetChargeApprovalsDocument = gql`
  query GetChargeApprovals($nextToken: String) {
    account {
      id
      listActiveApprovals(nextToken: $nextToken, filter: { type: CHARGE }) {
        nextToken
        items {
          __typename
          id
          accountId
          approverRoles
          status
          requestedName
          requestedZ
          ... on Approval {
            charge {
              id
              name
            }
            request {
              date
              amount
              chargeId
              payeeId
              payee
              ownerId
              propertyId
              unitId
              applyGst
              applyPst
              note
              unitName
              buildingName
              propertyKey
              propertyName
              payeeName
              ownerName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetChargeApprovalsQuery__
 *
 * To run a query within a React component, call `useGetChargeApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargeApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargeApprovalsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetChargeApprovalsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetChargeApprovalsQuery, GetChargeApprovalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChargeApprovalsQuery, GetChargeApprovalsQueryVariables>(
    GetChargeApprovalsDocument,
    options
  );
}
export function useGetChargeApprovalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChargeApprovalsQuery,
    GetChargeApprovalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChargeApprovalsQuery, GetChargeApprovalsQueryVariables>(
    GetChargeApprovalsDocument,
    options
  );
}
export type GetChargeApprovalsQueryHookResult = ReturnType<typeof useGetChargeApprovalsQuery>;
export type GetChargeApprovalsLazyQueryHookResult = ReturnType<
  typeof useGetChargeApprovalsLazyQuery
>;
export type GetChargeApprovalsQueryResult = Apollo.QueryResult<
  GetChargeApprovalsQuery,
  GetChargeApprovalsQueryVariables
>;
export const ApproveChargeApprovalDocument = gql`
  mutation ApproveChargeApproval($id: ID!) {
    approveChargeApproval(id: $id) {
      success
      error
    }
  }
`;
export type ApproveChargeApprovalMutationFn = Apollo.MutationFunction<
  ApproveChargeApprovalMutation,
  ApproveChargeApprovalMutationVariables
>;

/**
 * __useApproveChargeApprovalMutation__
 *
 * To run a mutation, you first call `useApproveChargeApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveChargeApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveChargeApprovalMutation, { data, loading, error }] = useApproveChargeApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveChargeApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveChargeApprovalMutation,
    ApproveChargeApprovalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApproveChargeApprovalMutation, ApproveChargeApprovalMutationVariables>(
    ApproveChargeApprovalDocument,
    options
  );
}
export type ApproveChargeApprovalMutationHookResult = ReturnType<
  typeof useApproveChargeApprovalMutation
>;
export type ApproveChargeApprovalMutationResult =
  Apollo.MutationResult<ApproveChargeApprovalMutation>;
export type ApproveChargeApprovalMutationOptions = Apollo.BaseMutationOptions<
  ApproveChargeApprovalMutation,
  ApproveChargeApprovalMutationVariables
>;
export const DeclineChargeApprovalDocument = gql`
  mutation DeclineChargeApproval($id: ID!, $declinedComment: String) {
    declineChargeApproval(id: $id, declinedComment: $declinedComment) {
      success
      error
    }
  }
`;
export type DeclineChargeApprovalMutationFn = Apollo.MutationFunction<
  DeclineChargeApprovalMutation,
  DeclineChargeApprovalMutationVariables
>;

/**
 * __useDeclineChargeApprovalMutation__
 *
 * To run a mutation, you first call `useDeclineChargeApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineChargeApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineChargeApprovalMutation, { data, loading, error }] = useDeclineChargeApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      declinedComment: // value for 'declinedComment'
 *   },
 * });
 */
export function useDeclineChargeApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineChargeApprovalMutation,
    DeclineChargeApprovalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeclineChargeApprovalMutation, DeclineChargeApprovalMutationVariables>(
    DeclineChargeApprovalDocument,
    options
  );
}
export type DeclineChargeApprovalMutationHookResult = ReturnType<
  typeof useDeclineChargeApprovalMutation
>;
export type DeclineChargeApprovalMutationResult =
  Apollo.MutationResult<DeclineChargeApprovalMutation>;
export type DeclineChargeApprovalMutationOptions = Apollo.BaseMutationOptions<
  DeclineChargeApprovalMutation,
  DeclineChargeApprovalMutationVariables
>;
export const PaymentsApprovalsDocument = gql`
  query PaymentsApprovals($nextToken: String) {
    account {
      id
      listActiveApprovals(nextToken: $nextToken, filter: { type: PAYMENTS }) {
        nextToken
        items {
          __typename
          id
          accountId
          approverRoles
          status
          requestedName
          requestedZ
          ... on PaymentsApproval {
            request {
              total
              batchId
              posted
              paymentMethod
              itemCount
            }
          }
        }
      }
    }
  }
`;

/**
 * __usePaymentsApprovalsQuery__
 *
 * To run a query within a React component, call `usePaymentsApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsApprovalsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function usePaymentsApprovalsQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentsApprovalsQuery, PaymentsApprovalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentsApprovalsQuery, PaymentsApprovalsQueryVariables>(
    PaymentsApprovalsDocument,
    options
  );
}
export function usePaymentsApprovalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentsApprovalsQuery, PaymentsApprovalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentsApprovalsQuery, PaymentsApprovalsQueryVariables>(
    PaymentsApprovalsDocument,
    options
  );
}
export type PaymentsApprovalsQueryHookResult = ReturnType<typeof usePaymentsApprovalsQuery>;
export type PaymentsApprovalsLazyQueryHookResult = ReturnType<typeof usePaymentsApprovalsLazyQuery>;
export type PaymentsApprovalsQueryResult = Apollo.QueryResult<
  PaymentsApprovalsQuery,
  PaymentsApprovalsQueryVariables
>;
export const ListRequestedPaymentsDocument = gql`
  query ListRequestedPayments($id: ID!, $nextToken: String) {
    approval(id: $id) {
      __typename
      id
      ... on PaymentsApproval {
        request {
          listPayments(nextToken: $nextToken) {
            items {
              id
              batchId
              posted
              amount
              paymentMethod
              bankId
              bankName
              payees {
                payeeId
                payee
                payeeName
                ref
                description
                glId
                payeeClearingAmounts {
                  clearableId
                  glId
                  amount
                  ownerId
                  propertyId
                  unitId
                  ref
                  description
                  posted
                  due
                  propertyKey
                  propertyName
                  buildingKey
                  buildingName
                  unitName
                  ownerName
                }
              }
            }
            nextToken
          }
        }
      }
    }
  }
`;

/**
 * __useListRequestedPaymentsQuery__
 *
 * To run a query within a React component, call `useListRequestedPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRequestedPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRequestedPaymentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListRequestedPaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListRequestedPaymentsQuery,
    ListRequestedPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListRequestedPaymentsQuery, ListRequestedPaymentsQueryVariables>(
    ListRequestedPaymentsDocument,
    options
  );
}
export function useListRequestedPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListRequestedPaymentsQuery,
    ListRequestedPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListRequestedPaymentsQuery, ListRequestedPaymentsQueryVariables>(
    ListRequestedPaymentsDocument,
    options
  );
}
export type ListRequestedPaymentsQueryHookResult = ReturnType<typeof useListRequestedPaymentsQuery>;
export type ListRequestedPaymentsLazyQueryHookResult = ReturnType<
  typeof useListRequestedPaymentsLazyQuery
>;
export type ListRequestedPaymentsQueryResult = Apollo.QueryResult<
  ListRequestedPaymentsQuery,
  ListRequestedPaymentsQueryVariables
>;
export const ApprovePaymentsApprovalDocument = gql`
  mutation ApprovePaymentsApproval($id: ID!) {
    approvePaymentsApproval(id: $id) {
      success
      error
    }
  }
`;
export type ApprovePaymentsApprovalMutationFn = Apollo.MutationFunction<
  ApprovePaymentsApprovalMutation,
  ApprovePaymentsApprovalMutationVariables
>;

/**
 * __useApprovePaymentsApprovalMutation__
 *
 * To run a mutation, you first call `useApprovePaymentsApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovePaymentsApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvePaymentsApprovalMutation, { data, loading, error }] = useApprovePaymentsApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApprovePaymentsApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApprovePaymentsApprovalMutation,
    ApprovePaymentsApprovalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApprovePaymentsApprovalMutation,
    ApprovePaymentsApprovalMutationVariables
  >(ApprovePaymentsApprovalDocument, options);
}
export type ApprovePaymentsApprovalMutationHookResult = ReturnType<
  typeof useApprovePaymentsApprovalMutation
>;
export type ApprovePaymentsApprovalMutationResult =
  Apollo.MutationResult<ApprovePaymentsApprovalMutation>;
export type ApprovePaymentsApprovalMutationOptions = Apollo.BaseMutationOptions<
  ApprovePaymentsApprovalMutation,
  ApprovePaymentsApprovalMutationVariables
>;
export const DeclinePaymentsApprovalDocument = gql`
  mutation DeclinePaymentsApproval($id: ID!, $declinedComment: String) {
    declinePaymentsApproval(id: $id, declinedComment: $declinedComment) {
      success
      error
    }
  }
`;
export type DeclinePaymentsApprovalMutationFn = Apollo.MutationFunction<
  DeclinePaymentsApprovalMutation,
  DeclinePaymentsApprovalMutationVariables
>;

/**
 * __useDeclinePaymentsApprovalMutation__
 *
 * To run a mutation, you first call `useDeclinePaymentsApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclinePaymentsApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declinePaymentsApprovalMutation, { data, loading, error }] = useDeclinePaymentsApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      declinedComment: // value for 'declinedComment'
 *   },
 * });
 */
export function useDeclinePaymentsApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclinePaymentsApprovalMutation,
    DeclinePaymentsApprovalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeclinePaymentsApprovalMutation,
    DeclinePaymentsApprovalMutationVariables
  >(DeclinePaymentsApprovalDocument, options);
}
export type DeclinePaymentsApprovalMutationHookResult = ReturnType<
  typeof useDeclinePaymentsApprovalMutation
>;
export type DeclinePaymentsApprovalMutationResult =
  Apollo.MutationResult<DeclinePaymentsApprovalMutation>;
export type DeclinePaymentsApprovalMutationOptions = Apollo.BaseMutationOptions<
  DeclinePaymentsApprovalMutation,
  DeclinePaymentsApprovalMutationVariables
>;
export const ScanApprovalsDocument = gql`
  query ScanApprovals($nextToken: String) {
    account {
      id
      listActiveApprovals(nextToken: $nextToken, filter: { type: SCAN }) {
        nextToken
        items {
          __typename
          id
          accountId
          approverRoles
          status
          requestedName
          requestedZ
          ... on ScanApproval {
            ...ScanApprovalFields
          }
        }
      }
    }
  }
  ${ScanApprovalFieldsFragmentDoc}
`;

/**
 * __useScanApprovalsQuery__
 *
 * To run a query within a React component, call `useScanApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScanApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScanApprovalsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useScanApprovalsQuery(
  baseOptions?: Apollo.QueryHookOptions<ScanApprovalsQuery, ScanApprovalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScanApprovalsQuery, ScanApprovalsQueryVariables>(
    ScanApprovalsDocument,
    options
  );
}
export function useScanApprovalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScanApprovalsQuery, ScanApprovalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScanApprovalsQuery, ScanApprovalsQueryVariables>(
    ScanApprovalsDocument,
    options
  );
}
export type ScanApprovalsQueryHookResult = ReturnType<typeof useScanApprovalsQuery>;
export type ScanApprovalsLazyQueryHookResult = ReturnType<typeof useScanApprovalsLazyQuery>;
export type ScanApprovalsQueryResult = Apollo.QueryResult<
  ScanApprovalsQuery,
  ScanApprovalsQueryVariables
>;
export const GetScanApprovalDocument = gql`
  query GetScanApproval($id: ID!) {
    approval(id: $id) {
      __typename
      id
      accountId
      approverRoles
      status
      requestedName
      requestedZ
      ... on ScanApproval {
        ...ScanApprovalFields
      }
    }
  }
  ${ScanApprovalFieldsFragmentDoc}
`;

/**
 * __useGetScanApprovalQuery__
 *
 * To run a query within a React component, call `useGetScanApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScanApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScanApprovalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScanApprovalQuery(
  baseOptions: Apollo.QueryHookOptions<GetScanApprovalQuery, GetScanApprovalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScanApprovalQuery, GetScanApprovalQueryVariables>(
    GetScanApprovalDocument,
    options
  );
}
export function useGetScanApprovalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetScanApprovalQuery, GetScanApprovalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScanApprovalQuery, GetScanApprovalQueryVariables>(
    GetScanApprovalDocument,
    options
  );
}
export type GetScanApprovalQueryHookResult = ReturnType<typeof useGetScanApprovalQuery>;
export type GetScanApprovalLazyQueryHookResult = ReturnType<typeof useGetScanApprovalLazyQuery>;
export type GetScanApprovalQueryResult = Apollo.QueryResult<
  GetScanApprovalQuery,
  GetScanApprovalQueryVariables
>;
export const ApproveScanApprovalDocument = gql`
  mutation ApproveScanApproval($id: ID!) {
    approveScanApproval(id: $id) {
      success
      error
    }
  }
`;
export type ApproveScanApprovalMutationFn = Apollo.MutationFunction<
  ApproveScanApprovalMutation,
  ApproveScanApprovalMutationVariables
>;

/**
 * __useApproveScanApprovalMutation__
 *
 * To run a mutation, you first call `useApproveScanApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveScanApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveScanApprovalMutation, { data, loading, error }] = useApproveScanApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveScanApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveScanApprovalMutation,
    ApproveScanApprovalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApproveScanApprovalMutation, ApproveScanApprovalMutationVariables>(
    ApproveScanApprovalDocument,
    options
  );
}
export type ApproveScanApprovalMutationHookResult = ReturnType<
  typeof useApproveScanApprovalMutation
>;
export type ApproveScanApprovalMutationResult = Apollo.MutationResult<ApproveScanApprovalMutation>;
export type ApproveScanApprovalMutationOptions = Apollo.BaseMutationOptions<
  ApproveScanApprovalMutation,
  ApproveScanApprovalMutationVariables
>;
export const DeclineScanApprovalDocument = gql`
  mutation DeclineScanApproval($id: ID!, $declinedComment: String) {
    declineScanApproval(id: $id, declinedComment: $declinedComment) {
      success
      error
    }
  }
`;
export type DeclineScanApprovalMutationFn = Apollo.MutationFunction<
  DeclineScanApprovalMutation,
  DeclineScanApprovalMutationVariables
>;

/**
 * __useDeclineScanApprovalMutation__
 *
 * To run a mutation, you first call `useDeclineScanApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineScanApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineScanApprovalMutation, { data, loading, error }] = useDeclineScanApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      declinedComment: // value for 'declinedComment'
 *   },
 * });
 */
export function useDeclineScanApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineScanApprovalMutation,
    DeclineScanApprovalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeclineScanApprovalMutation, DeclineScanApprovalMutationVariables>(
    DeclineScanApprovalDocument,
    options
  );
}
export type DeclineScanApprovalMutationHookResult = ReturnType<
  typeof useDeclineScanApprovalMutation
>;
export type DeclineScanApprovalMutationResult = Apollo.MutationResult<DeclineScanApprovalMutation>;
export type DeclineScanApprovalMutationOptions = Apollo.BaseMutationOptions<
  DeclineScanApprovalMutation,
  DeclineScanApprovalMutationVariables
>;
export const UpdateScanApprovalRequestDocument = gql`
  mutation UpdateScanApprovalRequest($id: ID!, $input: UpdateScanApprovalRequestInput!) {
    updateScanApprovalRequest(id: $id, input: $input) {
      success
      error
      approval {
        id
        ... on ScanApproval {
          ...ScanApprovalFields
        }
      }
    }
  }
  ${ScanApprovalFieldsFragmentDoc}
`;
export type UpdateScanApprovalRequestMutationFn = Apollo.MutationFunction<
  UpdateScanApprovalRequestMutation,
  UpdateScanApprovalRequestMutationVariables
>;

/**
 * __useUpdateScanApprovalRequestMutation__
 *
 * To run a mutation, you first call `useUpdateScanApprovalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScanApprovalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScanApprovalRequestMutation, { data, loading, error }] = useUpdateScanApprovalRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScanApprovalRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScanApprovalRequestMutation,
    UpdateScanApprovalRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScanApprovalRequestMutation,
    UpdateScanApprovalRequestMutationVariables
  >(UpdateScanApprovalRequestDocument, options);
}
export type UpdateScanApprovalRequestMutationHookResult = ReturnType<
  typeof useUpdateScanApprovalRequestMutation
>;
export type UpdateScanApprovalRequestMutationResult =
  Apollo.MutationResult<UpdateScanApprovalRequestMutation>;
export type UpdateScanApprovalRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateScanApprovalRequestMutation,
  UpdateScanApprovalRequestMutationVariables
>;
export const AddScanApprovalTaskDocument = gql`
  mutation AddScanApprovalTask($input: AddScanApprovalTaskInput!) {
    addScanApprovalTask(input: $input) {
      success
      error
      task {
        id
        taskProgress {
          processed
          total
          label
          done
        }
      }
    }
  }
`;
export type AddScanApprovalTaskMutationFn = Apollo.MutationFunction<
  AddScanApprovalTaskMutation,
  AddScanApprovalTaskMutationVariables
>;

/**
 * __useAddScanApprovalTaskMutation__
 *
 * To run a mutation, you first call `useAddScanApprovalTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddScanApprovalTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addScanApprovalTaskMutation, { data, loading, error }] = useAddScanApprovalTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddScanApprovalTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddScanApprovalTaskMutation,
    AddScanApprovalTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddScanApprovalTaskMutation, AddScanApprovalTaskMutationVariables>(
    AddScanApprovalTaskDocument,
    options
  );
}
export type AddScanApprovalTaskMutationHookResult = ReturnType<
  typeof useAddScanApprovalTaskMutation
>;
export type AddScanApprovalTaskMutationResult = Apollo.MutationResult<AddScanApprovalTaskMutation>;
export type AddScanApprovalTaskMutationOptions = Apollo.BaseMutationOptions<
  AddScanApprovalTaskMutation,
  AddScanApprovalTaskMutationVariables
>;
export const AddScanMatchingTaskDocument = gql`
  mutation AddScanMatchingTask($input: AddScanMatchingTaskInput!) {
    addScanMatchingTask(input: $input) {
      success
      error
      task {
        id
        taskProgress {
          processed
          total
          label
          done
        }
      }
    }
  }
`;
export type AddScanMatchingTaskMutationFn = Apollo.MutationFunction<
  AddScanMatchingTaskMutation,
  AddScanMatchingTaskMutationVariables
>;

/**
 * __useAddScanMatchingTaskMutation__
 *
 * To run a mutation, you first call `useAddScanMatchingTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddScanMatchingTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addScanMatchingTaskMutation, { data, loading, error }] = useAddScanMatchingTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddScanMatchingTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddScanMatchingTaskMutation,
    AddScanMatchingTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddScanMatchingTaskMutation, AddScanMatchingTaskMutationVariables>(
    AddScanMatchingTaskDocument,
    options
  );
}
export type AddScanMatchingTaskMutationHookResult = ReturnType<
  typeof useAddScanMatchingTaskMutation
>;
export type AddScanMatchingTaskMutationResult = Apollo.MutationResult<AddScanMatchingTaskMutation>;
export type AddScanMatchingTaskMutationOptions = Apollo.BaseMutationOptions<
  AddScanMatchingTaskMutation,
  AddScanMatchingTaskMutationVariables
>;
export const ActivatedEnrollmentIdsDocument = gql`
  query ActivatedEnrollmentIds {
    account {
      id
      activatedEnrollmentIds
    }
  }
`;

/**
 * __useActivatedEnrollmentIdsQuery__
 *
 * To run a query within a React component, call `useActivatedEnrollmentIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivatedEnrollmentIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivatedEnrollmentIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivatedEnrollmentIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActivatedEnrollmentIdsQuery,
    ActivatedEnrollmentIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivatedEnrollmentIdsQuery, ActivatedEnrollmentIdsQueryVariables>(
    ActivatedEnrollmentIdsDocument,
    options
  );
}
export function useActivatedEnrollmentIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivatedEnrollmentIdsQuery,
    ActivatedEnrollmentIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActivatedEnrollmentIdsQuery, ActivatedEnrollmentIdsQueryVariables>(
    ActivatedEnrollmentIdsDocument,
    options
  );
}
export type ActivatedEnrollmentIdsQueryHookResult = ReturnType<
  typeof useActivatedEnrollmentIdsQuery
>;
export type ActivatedEnrollmentIdsLazyQueryHookResult = ReturnType<
  typeof useActivatedEnrollmentIdsLazyQuery
>;
export type ActivatedEnrollmentIdsQueryResult = Apollo.QueryResult<
  ActivatedEnrollmentIdsQuery,
  ActivatedEnrollmentIdsQueryVariables
>;
export const PagePropertiesDocument = gql`
  query PageProperties {
    account {
      id
      pageProperties(first: 1000) {
        edges {
          node {
            id
            key
            name
            autopayCollectionsEnabled
            autopayCollectionsEnabledZ
          }
        }
      }
    }
  }
`;

/**
 * __usePagePropertiesQuery__
 *
 * To run a query within a React component, call `usePagePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagePropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePagePropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<PagePropertiesQuery, PagePropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PagePropertiesQuery, PagePropertiesQueryVariables>(
    PagePropertiesDocument,
    options
  );
}
export function usePagePropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PagePropertiesQuery, PagePropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PagePropertiesQuery, PagePropertiesQueryVariables>(
    PagePropertiesDocument,
    options
  );
}
export type PagePropertiesQueryHookResult = ReturnType<typeof usePagePropertiesQuery>;
export type PagePropertiesLazyQueryHookResult = ReturnType<typeof usePagePropertiesLazyQuery>;
export type PagePropertiesQueryResult = Apollo.QueryResult<
  PagePropertiesQuery,
  PagePropertiesQueryVariables
>;
export const ServerClearablesDocument = gql`
  query ServerClearables(
    $first: Int
    $after: ID
    $filter: ClearablesFilterInput
    $sort: [ClearablesSortInput!]
  ) {
    account {
      id
      books {
        accountId
        pageClearables(first: $first, after: $after, filter: $filter, sort: $sort) {
          totalAmount
          totalCount
          totalOutstanding
          creditOutstanding
          debitOutstanding
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            cursor
            node {
              ...ClearableListFields
              payeeName
              ownerName
              propertyKey
              propertyName
              buildingKey
              buildingName
              unitName
              payeeAutopayClientId
              payeeAutopayStatus
              payeeAutopayCollectionsEnabled
              autopaySinglePosted
            }
          }
        }
      }
    }
  }
  ${ClearableListFieldsFragmentDoc}
`;

/**
 * __useServerClearablesQuery__
 *
 * To run a query within a React component, call `useServerClearablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerClearablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerClearablesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useServerClearablesQuery(
  baseOptions?: Apollo.QueryHookOptions<ServerClearablesQuery, ServerClearablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServerClearablesQuery, ServerClearablesQueryVariables>(
    ServerClearablesDocument,
    options
  );
}
export function useServerClearablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServerClearablesQuery, ServerClearablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServerClearablesQuery, ServerClearablesQueryVariables>(
    ServerClearablesDocument,
    options
  );
}
export type ServerClearablesQueryHookResult = ReturnType<typeof useServerClearablesQuery>;
export type ServerClearablesLazyQueryHookResult = ReturnType<typeof useServerClearablesLazyQuery>;
export type ServerClearablesQueryResult = Apollo.QueryResult<
  ServerClearablesQuery,
  ServerClearablesQueryVariables
>;
export const PayeeOwnersDocument = gql`
  query PayeeOwners($nextToken: String) {
    account {
      id
      listOwners(nextToken: $nextToken) {
        items {
          id
          name
          address {
            city
            postal
            province
            suite
            street
          }
          email
          phones
          enabled
          propertyHeaders {
            id
            name
            key
          }
          unitHeaders {
            id
            propertyId
            name
            occupancy
          }
          propertyIds
          unitIds
          preferredPaymentMethod
          padAmount
          history {
            assetType
            assetId
          }
        }
        nextToken
      }
    }
  }
`;

/**
 * __usePayeeOwnersQuery__
 *
 * To run a query within a React component, call `usePayeeOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayeeOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayeeOwnersQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function usePayeeOwnersQuery(
  baseOptions?: Apollo.QueryHookOptions<PayeeOwnersQuery, PayeeOwnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PayeeOwnersQuery, PayeeOwnersQueryVariables>(PayeeOwnersDocument, options);
}
export function usePayeeOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PayeeOwnersQuery, PayeeOwnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PayeeOwnersQuery, PayeeOwnersQueryVariables>(
    PayeeOwnersDocument,
    options
  );
}
export type PayeeOwnersQueryHookResult = ReturnType<typeof usePayeeOwnersQuery>;
export type PayeeOwnersLazyQueryHookResult = ReturnType<typeof usePayeeOwnersLazyQuery>;
export type PayeeOwnersQueryResult = Apollo.QueryResult<
  PayeeOwnersQuery,
  PayeeOwnersQueryVariables
>;
export const CreateChargeDocument = gql`
  mutation CreateCharge($input: CreateChargeInput!) {
    createCharge(input: $input) {
      success
      error
      account {
        ...AccountChargesFields
      }
    }
  }
  ${AccountChargesFieldsFragmentDoc}
`;
export type CreateChargeMutationFn = Apollo.MutationFunction<
  CreateChargeMutation,
  CreateChargeMutationVariables
>;

/**
 * __useCreateChargeMutation__
 *
 * To run a mutation, you first call `useCreateChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargeMutation, { data, loading, error }] = useCreateChargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateChargeMutation, CreateChargeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChargeMutation, CreateChargeMutationVariables>(
    CreateChargeDocument,
    options
  );
}
export type CreateChargeMutationHookResult = ReturnType<typeof useCreateChargeMutation>;
export type CreateChargeMutationResult = Apollo.MutationResult<CreateChargeMutation>;
export type CreateChargeMutationOptions = Apollo.BaseMutationOptions<
  CreateChargeMutation,
  CreateChargeMutationVariables
>;
export const DeleteChargeDocument = gql`
  mutation DeleteCharge($id: ID!) {
    deleteCharge(id: $id) {
      success
      error
      account {
        ...AccountChargesFields
      }
    }
  }
  ${AccountChargesFieldsFragmentDoc}
`;
export type DeleteChargeMutationFn = Apollo.MutationFunction<
  DeleteChargeMutation,
  DeleteChargeMutationVariables
>;

/**
 * __useDeleteChargeMutation__
 *
 * To run a mutation, you first call `useDeleteChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChargeMutation, { data, loading, error }] = useDeleteChargeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteChargeMutation, DeleteChargeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteChargeMutation, DeleteChargeMutationVariables>(
    DeleteChargeDocument,
    options
  );
}
export type DeleteChargeMutationHookResult = ReturnType<typeof useDeleteChargeMutation>;
export type DeleteChargeMutationResult = Apollo.MutationResult<DeleteChargeMutation>;
export type DeleteChargeMutationOptions = Apollo.BaseMutationOptions<
  DeleteChargeMutation,
  DeleteChargeMutationVariables
>;
export const AddClearingEntryBatchTaskDocument = gql`
  mutation AddClearingEntryBatchTask($input: AddClearingEntryTaskInput!) {
    addClearingEntryTask(input: $input) {
      success
      error
      task {
        id
        taskProgress {
          processed
          total
          label
          done
        }
      }
    }
  }
`;
export type AddClearingEntryBatchTaskMutationFn = Apollo.MutationFunction<
  AddClearingEntryBatchTaskMutation,
  AddClearingEntryBatchTaskMutationVariables
>;

/**
 * __useAddClearingEntryBatchTaskMutation__
 *
 * To run a mutation, you first call `useAddClearingEntryBatchTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClearingEntryBatchTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClearingEntryBatchTaskMutation, { data, loading, error }] = useAddClearingEntryBatchTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddClearingEntryBatchTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddClearingEntryBatchTaskMutation,
    AddClearingEntryBatchTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddClearingEntryBatchTaskMutation,
    AddClearingEntryBatchTaskMutationVariables
  >(AddClearingEntryBatchTaskDocument, options);
}
export type AddClearingEntryBatchTaskMutationHookResult = ReturnType<
  typeof useAddClearingEntryBatchTaskMutation
>;
export type AddClearingEntryBatchTaskMutationResult =
  Apollo.MutationResult<AddClearingEntryBatchTaskMutation>;
export type AddClearingEntryBatchTaskMutationOptions = Apollo.BaseMutationOptions<
  AddClearingEntryBatchTaskMutation,
  AddClearingEntryBatchTaskMutationVariables
>;
export const AbortBooksBatchTaskDocument = gql`
  mutation AbortBooksBatchTask($id: ID!, $reason: String) {
    abortBooksBatchTask(id: $id, reason: $reason) {
      success
      error
      task {
        id
        taskProgress {
          processed
          total
          label
          done
        }
      }
    }
  }
`;
export type AbortBooksBatchTaskMutationFn = Apollo.MutationFunction<
  AbortBooksBatchTaskMutation,
  AbortBooksBatchTaskMutationVariables
>;

/**
 * __useAbortBooksBatchTaskMutation__
 *
 * To run a mutation, you first call `useAbortBooksBatchTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAbortBooksBatchTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [abortBooksBatchTaskMutation, { data, loading, error }] = useAbortBooksBatchTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useAbortBooksBatchTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AbortBooksBatchTaskMutation,
    AbortBooksBatchTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AbortBooksBatchTaskMutation, AbortBooksBatchTaskMutationVariables>(
    AbortBooksBatchTaskDocument,
    options
  );
}
export type AbortBooksBatchTaskMutationHookResult = ReturnType<
  typeof useAbortBooksBatchTaskMutation
>;
export type AbortBooksBatchTaskMutationResult = Apollo.MutationResult<AbortBooksBatchTaskMutation>;
export type AbortBooksBatchTaskMutationOptions = Apollo.BaseMutationOptions<
  AbortBooksBatchTaskMutation,
  AbortBooksBatchTaskMutationVariables
>;
export const UpdateClearingEntryBatchTaskDocument = gql`
  mutation UpdateClearingEntryBatchTask($id: ID!, $input: UpdateClearingEntryTaskInput!) {
    updateClearingEntryTask(id: $id, input: $input) {
      success
      error
      task {
        id
        taskProgress {
          processed
          total
          label
          done
        }
      }
    }
  }
`;
export type UpdateClearingEntryBatchTaskMutationFn = Apollo.MutationFunction<
  UpdateClearingEntryBatchTaskMutation,
  UpdateClearingEntryBatchTaskMutationVariables
>;

/**
 * __useUpdateClearingEntryBatchTaskMutation__
 *
 * To run a mutation, you first call `useUpdateClearingEntryBatchTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClearingEntryBatchTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClearingEntryBatchTaskMutation, { data, loading, error }] = useUpdateClearingEntryBatchTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClearingEntryBatchTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClearingEntryBatchTaskMutation,
    UpdateClearingEntryBatchTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClearingEntryBatchTaskMutation,
    UpdateClearingEntryBatchTaskMutationVariables
  >(UpdateClearingEntryBatchTaskDocument, options);
}
export type UpdateClearingEntryBatchTaskMutationHookResult = ReturnType<
  typeof useUpdateClearingEntryBatchTaskMutation
>;
export type UpdateClearingEntryBatchTaskMutationResult =
  Apollo.MutationResult<UpdateClearingEntryBatchTaskMutation>;
export type UpdateClearingEntryBatchTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateClearingEntryBatchTaskMutation,
  UpdateClearingEntryBatchTaskMutationVariables
>;
export const CreatePaymentsBatchDocument = gql`
  mutation CreatePaymentsBatch($input: CreatePaymentsBatchInput!) {
    createPaymentsBatch(input: $input) {
      success
      error
      paymentsBatch {
        id
        presetId
        total
        itemCount
        posted
        status
        paymentMethod
        errorMessage
      }
    }
  }
`;
export type CreatePaymentsBatchMutationFn = Apollo.MutationFunction<
  CreatePaymentsBatchMutation,
  CreatePaymentsBatchMutationVariables
>;

/**
 * __useCreatePaymentsBatchMutation__
 *
 * To run a mutation, you first call `useCreatePaymentsBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentsBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentsBatchMutation, { data, loading, error }] = useCreatePaymentsBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentsBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentsBatchMutation,
    CreatePaymentsBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePaymentsBatchMutation, CreatePaymentsBatchMutationVariables>(
    CreatePaymentsBatchDocument,
    options
  );
}
export type CreatePaymentsBatchMutationHookResult = ReturnType<
  typeof useCreatePaymentsBatchMutation
>;
export type CreatePaymentsBatchMutationResult = Apollo.MutationResult<CreatePaymentsBatchMutation>;
export type CreatePaymentsBatchMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentsBatchMutation,
  CreatePaymentsBatchMutationVariables
>;
export const AddPaymentsBatchPrototypesDocument = gql`
  mutation AddPaymentsBatchPrototypes($inputs: [PaymentPrototypeInput!]!) {
    addPaymentsBatchPrototypes(inputs: $inputs) {
      success
      error
    }
  }
`;
export type AddPaymentsBatchPrototypesMutationFn = Apollo.MutationFunction<
  AddPaymentsBatchPrototypesMutation,
  AddPaymentsBatchPrototypesMutationVariables
>;

/**
 * __useAddPaymentsBatchPrototypesMutation__
 *
 * To run a mutation, you first call `useAddPaymentsBatchPrototypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentsBatchPrototypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentsBatchPrototypesMutation, { data, loading, error }] = useAddPaymentsBatchPrototypesMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useAddPaymentsBatchPrototypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPaymentsBatchPrototypesMutation,
    AddPaymentsBatchPrototypesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPaymentsBatchPrototypesMutation,
    AddPaymentsBatchPrototypesMutationVariables
  >(AddPaymentsBatchPrototypesDocument, options);
}
export type AddPaymentsBatchPrototypesMutationHookResult = ReturnType<
  typeof useAddPaymentsBatchPrototypesMutation
>;
export type AddPaymentsBatchPrototypesMutationResult =
  Apollo.MutationResult<AddPaymentsBatchPrototypesMutation>;
export type AddPaymentsBatchPrototypesMutationOptions = Apollo.BaseMutationOptions<
  AddPaymentsBatchPrototypesMutation,
  AddPaymentsBatchPrototypesMutationVariables
>;
export const CreatePaymentsApprovalDocument = gql`
  mutation CreatePaymentsApproval($input: CreatePaymentsApprovalInput!) {
    createPaymentsApproval(input: $input) {
      success
      error
      approval {
        id
      }
    }
  }
`;
export type CreatePaymentsApprovalMutationFn = Apollo.MutationFunction<
  CreatePaymentsApprovalMutation,
  CreatePaymentsApprovalMutationVariables
>;

/**
 * __useCreatePaymentsApprovalMutation__
 *
 * To run a mutation, you first call `useCreatePaymentsApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentsApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentsApprovalMutation, { data, loading, error }] = useCreatePaymentsApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentsApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentsApprovalMutation,
    CreatePaymentsApprovalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaymentsApprovalMutation,
    CreatePaymentsApprovalMutationVariables
  >(CreatePaymentsApprovalDocument, options);
}
export type CreatePaymentsApprovalMutationHookResult = ReturnType<
  typeof useCreatePaymentsApprovalMutation
>;
export type CreatePaymentsApprovalMutationResult =
  Apollo.MutationResult<CreatePaymentsApprovalMutation>;
export type CreatePaymentsApprovalMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentsApprovalMutation,
  CreatePaymentsApprovalMutationVariables
>;
export const UpdatePaymentsBatchDocument = gql`
  mutation UpdatePaymentsBatch($input: UpdatePaymentsBatchInput!) {
    updatePaymentsBatch(input: $input) {
      success
      error
      paymentsBatch {
        id
        status
        approvalId
      }
    }
  }
`;
export type UpdatePaymentsBatchMutationFn = Apollo.MutationFunction<
  UpdatePaymentsBatchMutation,
  UpdatePaymentsBatchMutationVariables
>;

/**
 * __useUpdatePaymentsBatchMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentsBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentsBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentsBatchMutation, { data, loading, error }] = useUpdatePaymentsBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentsBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaymentsBatchMutation,
    UpdatePaymentsBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePaymentsBatchMutation, UpdatePaymentsBatchMutationVariables>(
    UpdatePaymentsBatchDocument,
    options
  );
}
export type UpdatePaymentsBatchMutationHookResult = ReturnType<
  typeof useUpdatePaymentsBatchMutation
>;
export type UpdatePaymentsBatchMutationResult = Apollo.MutationResult<UpdatePaymentsBatchMutation>;
export type UpdatePaymentsBatchMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentsBatchMutation,
  UpdatePaymentsBatchMutationVariables
>;
export const GetCombinedSubAccountBalanceDocument = gql`
  query GetCombinedSubAccountBalance(
    $glId: ID!
    $ownerIds: [ID!]!
    $propertyIds: [ID!]!
    $balanceDate: AWSDate
  ) {
    glAccount(id: $glId) {
      id
      combinedBalance(
        input: { ownerIds: $ownerIds, propertyIds: $propertyIds, balanceDate: $balanceDate }
      )
    }
  }
`;

/**
 * __useGetCombinedSubAccountBalanceQuery__
 *
 * To run a query within a React component, call `useGetCombinedSubAccountBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCombinedSubAccountBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCombinedSubAccountBalanceQuery({
 *   variables: {
 *      glId: // value for 'glId'
 *      ownerIds: // value for 'ownerIds'
 *      propertyIds: // value for 'propertyIds'
 *      balanceDate: // value for 'balanceDate'
 *   },
 * });
 */
export function useGetCombinedSubAccountBalanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCombinedSubAccountBalanceQuery,
    GetCombinedSubAccountBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCombinedSubAccountBalanceQuery,
    GetCombinedSubAccountBalanceQueryVariables
  >(GetCombinedSubAccountBalanceDocument, options);
}
export function useGetCombinedSubAccountBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCombinedSubAccountBalanceQuery,
    GetCombinedSubAccountBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCombinedSubAccountBalanceQuery,
    GetCombinedSubAccountBalanceQueryVariables
  >(GetCombinedSubAccountBalanceDocument, options);
}
export type GetCombinedSubAccountBalanceQueryHookResult = ReturnType<
  typeof useGetCombinedSubAccountBalanceQuery
>;
export type GetCombinedSubAccountBalanceLazyQueryHookResult = ReturnType<
  typeof useGetCombinedSubAccountBalanceLazyQuery
>;
export type GetCombinedSubAccountBalanceQueryResult = Apollo.QueryResult<
  GetCombinedSubAccountBalanceQuery,
  GetCombinedSubAccountBalanceQueryVariables
>;
export const CommitReconciliationDocument = gql`
  mutation CommitReconciliation($id: ID!) {
    completeReconciliation(id: $id) {
      success
      error
      reconciliation {
        id
        status
      }
    }
  }
`;
export type CommitReconciliationMutationFn = Apollo.MutationFunction<
  CommitReconciliationMutation,
  CommitReconciliationMutationVariables
>;

/**
 * __useCommitReconciliationMutation__
 *
 * To run a mutation, you first call `useCommitReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommitReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commitReconciliationMutation, { data, loading, error }] = useCommitReconciliationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommitReconciliationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CommitReconciliationMutation,
    CommitReconciliationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CommitReconciliationMutation, CommitReconciliationMutationVariables>(
    CommitReconciliationDocument,
    options
  );
}
export type CommitReconciliationMutationHookResult = ReturnType<
  typeof useCommitReconciliationMutation
>;
export type CommitReconciliationMutationResult =
  Apollo.MutationResult<CommitReconciliationMutation>;
export type CommitReconciliationMutationOptions = Apollo.BaseMutationOptions<
  CommitReconciliationMutation,
  CommitReconciliationMutationVariables
>;
export const DraftReconciliationDocument = gql`
  mutation DraftReconciliation($input: DraftReconciliationInput!) {
    draftReconciliation(input: $input) {
      success
      error
      reconciliation {
        id
        ...ReconciliationFields
      }
    }
  }
  ${ReconciliationFieldsFragmentDoc}
`;
export type DraftReconciliationMutationFn = Apollo.MutationFunction<
  DraftReconciliationMutation,
  DraftReconciliationMutationVariables
>;

/**
 * __useDraftReconciliationMutation__
 *
 * To run a mutation, you first call `useDraftReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftReconciliationMutation, { data, loading, error }] = useDraftReconciliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDraftReconciliationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DraftReconciliationMutation,
    DraftReconciliationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DraftReconciliationMutation, DraftReconciliationMutationVariables>(
    DraftReconciliationDocument,
    options
  );
}
export type DraftReconciliationMutationHookResult = ReturnType<
  typeof useDraftReconciliationMutation
>;
export type DraftReconciliationMutationResult = Apollo.MutationResult<DraftReconciliationMutation>;
export type DraftReconciliationMutationOptions = Apollo.BaseMutationOptions<
  DraftReconciliationMutation,
  DraftReconciliationMutationVariables
>;
export const OwnerEntityNamesDocument = gql`
  query OwnerEntityNames($ownerIds: [ID!]) {
    account {
      id
      pageOwners(first: 1000, filter: { ownerIds: $ownerIds }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useOwnerEntityNamesQuery__
 *
 * To run a query within a React component, call `useOwnerEntityNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerEntityNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerEntityNamesQuery({
 *   variables: {
 *      ownerIds: // value for 'ownerIds'
 *   },
 * });
 */
export function useOwnerEntityNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<OwnerEntityNamesQuery, OwnerEntityNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OwnerEntityNamesQuery, OwnerEntityNamesQueryVariables>(
    OwnerEntityNamesDocument,
    options
  );
}
export function useOwnerEntityNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OwnerEntityNamesQuery, OwnerEntityNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OwnerEntityNamesQuery, OwnerEntityNamesQueryVariables>(
    OwnerEntityNamesDocument,
    options
  );
}
export type OwnerEntityNamesQueryHookResult = ReturnType<typeof useOwnerEntityNamesQuery>;
export type OwnerEntityNamesLazyQueryHookResult = ReturnType<typeof useOwnerEntityNamesLazyQuery>;
export type OwnerEntityNamesQueryResult = Apollo.QueryResult<
  OwnerEntityNamesQuery,
  OwnerEntityNamesQueryVariables
>;
export const PropertyEntityNamesDocument = gql`
  query PropertyEntityNames($propertyIds: [ID!]) {
    account {
      id
      pageProperties(first: 1000, filter: { propertyIds: $propertyIds }) {
        edges {
          node {
            id
            name
            key
          }
        }
      }
    }
  }
`;

/**
 * __usePropertyEntityNamesQuery__
 *
 * To run a query within a React component, call `usePropertyEntityNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyEntityNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyEntityNamesQuery({
 *   variables: {
 *      propertyIds: // value for 'propertyIds'
 *   },
 * });
 */
export function usePropertyEntityNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<PropertyEntityNamesQuery, PropertyEntityNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertyEntityNamesQuery, PropertyEntityNamesQueryVariables>(
    PropertyEntityNamesDocument,
    options
  );
}
export function usePropertyEntityNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertyEntityNamesQuery,
    PropertyEntityNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PropertyEntityNamesQuery, PropertyEntityNamesQueryVariables>(
    PropertyEntityNamesDocument,
    options
  );
}
export type PropertyEntityNamesQueryHookResult = ReturnType<typeof usePropertyEntityNamesQuery>;
export type PropertyEntityNamesLazyQueryHookResult = ReturnType<
  typeof usePropertyEntityNamesLazyQuery
>;
export type PropertyEntityNamesQueryResult = Apollo.QueryResult<
  PropertyEntityNamesQuery,
  PropertyEntityNamesQueryVariables
>;
export const GetReconciliationJournalsDocument = gql`
  query GetReconciliationJournals($filter: JournalRecFilter!) {
    account {
      id
      books {
        accountId
        listJournalEntriesForReconciliation(filter: $filter) {
          error
          success
          journalEntries
        }
      }
    }
  }
`;

/**
 * __useGetReconciliationJournalsQuery__
 *
 * To run a query within a React component, call `useGetReconciliationJournalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReconciliationJournalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReconciliationJournalsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetReconciliationJournalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReconciliationJournalsQuery,
    GetReconciliationJournalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReconciliationJournalsQuery, GetReconciliationJournalsQueryVariables>(
    GetReconciliationJournalsDocument,
    options
  );
}
export function useGetReconciliationJournalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReconciliationJournalsQuery,
    GetReconciliationJournalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReconciliationJournalsQuery,
    GetReconciliationJournalsQueryVariables
  >(GetReconciliationJournalsDocument, options);
}
export type GetReconciliationJournalsQueryHookResult = ReturnType<
  typeof useGetReconciliationJournalsQuery
>;
export type GetReconciliationJournalsLazyQueryHookResult = ReturnType<
  typeof useGetReconciliationJournalsLazyQuery
>;
export type GetReconciliationJournalsQueryResult = Apollo.QueryResult<
  GetReconciliationJournalsQuery,
  GetReconciliationJournalsQueryVariables
>;
export const NotifyPayeePaymentReturnedDocument = gql`
  mutation NotifyPayeePaymentReturned($input: NotifyNsfPayeeInput!) {
    notifyNsfPayee(input: $input) {
      success
      error
    }
  }
`;
export type NotifyPayeePaymentReturnedMutationFn = Apollo.MutationFunction<
  NotifyPayeePaymentReturnedMutation,
  NotifyPayeePaymentReturnedMutationVariables
>;

/**
 * __useNotifyPayeePaymentReturnedMutation__
 *
 * To run a mutation, you first call `useNotifyPayeePaymentReturnedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyPayeePaymentReturnedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyPayeePaymentReturnedMutation, { data, loading, error }] = useNotifyPayeePaymentReturnedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotifyPayeePaymentReturnedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotifyPayeePaymentReturnedMutation,
    NotifyPayeePaymentReturnedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NotifyPayeePaymentReturnedMutation,
    NotifyPayeePaymentReturnedMutationVariables
  >(NotifyPayeePaymentReturnedDocument, options);
}
export type NotifyPayeePaymentReturnedMutationHookResult = ReturnType<
  typeof useNotifyPayeePaymentReturnedMutation
>;
export type NotifyPayeePaymentReturnedMutationResult =
  Apollo.MutationResult<NotifyPayeePaymentReturnedMutation>;
export type NotifyPayeePaymentReturnedMutationOptions = Apollo.BaseMutationOptions<
  NotifyPayeePaymentReturnedMutation,
  NotifyPayeePaymentReturnedMutationVariables
>;
export const AccountPayeeNameDocument = gql`
  query AccountPayeeName {
    account {
      id
      name
    }
  }
`;

/**
 * __useAccountPayeeNameQuery__
 *
 * To run a query within a React component, call `useAccountPayeeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPayeeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPayeeNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountPayeeNameQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountPayeeNameQuery, AccountPayeeNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountPayeeNameQuery, AccountPayeeNameQueryVariables>(
    AccountPayeeNameDocument,
    options
  );
}
export function useAccountPayeeNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountPayeeNameQuery, AccountPayeeNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountPayeeNameQuery, AccountPayeeNameQueryVariables>(
    AccountPayeeNameDocument,
    options
  );
}
export type AccountPayeeNameQueryHookResult = ReturnType<typeof useAccountPayeeNameQuery>;
export type AccountPayeeNameLazyQueryHookResult = ReturnType<typeof useAccountPayeeNameLazyQuery>;
export type AccountPayeeNameQueryResult = Apollo.QueryResult<
  AccountPayeeNameQuery,
  AccountPayeeNameQueryVariables
>;
export const OperatorPayeeNameDocument = gql`
  query OperatorPayeeName($id: ID!) {
    operator(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useOperatorPayeeNameQuery__
 *
 * To run a query within a React component, call `useOperatorPayeeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorPayeeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorPayeeNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOperatorPayeeNameQuery(
  baseOptions: Apollo.QueryHookOptions<OperatorPayeeNameQuery, OperatorPayeeNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OperatorPayeeNameQuery, OperatorPayeeNameQueryVariables>(
    OperatorPayeeNameDocument,
    options
  );
}
export function useOperatorPayeeNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OperatorPayeeNameQuery, OperatorPayeeNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OperatorPayeeNameQuery, OperatorPayeeNameQueryVariables>(
    OperatorPayeeNameDocument,
    options
  );
}
export type OperatorPayeeNameQueryHookResult = ReturnType<typeof useOperatorPayeeNameQuery>;
export type OperatorPayeeNameLazyQueryHookResult = ReturnType<typeof useOperatorPayeeNameLazyQuery>;
export type OperatorPayeeNameQueryResult = Apollo.QueryResult<
  OperatorPayeeNameQuery,
  OperatorPayeeNameQueryVariables
>;
export const OwnerPayeeNameDocument = gql`
  query OwnerPayeeName($id: ID!) {
    owner(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useOwnerPayeeNameQuery__
 *
 * To run a query within a React component, call `useOwnerPayeeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerPayeeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerPayeeNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOwnerPayeeNameQuery(
  baseOptions: Apollo.QueryHookOptions<OwnerPayeeNameQuery, OwnerPayeeNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OwnerPayeeNameQuery, OwnerPayeeNameQueryVariables>(
    OwnerPayeeNameDocument,
    options
  );
}
export function useOwnerPayeeNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OwnerPayeeNameQuery, OwnerPayeeNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OwnerPayeeNameQuery, OwnerPayeeNameQueryVariables>(
    OwnerPayeeNameDocument,
    options
  );
}
export type OwnerPayeeNameQueryHookResult = ReturnType<typeof useOwnerPayeeNameQuery>;
export type OwnerPayeeNameLazyQueryHookResult = ReturnType<typeof useOwnerPayeeNameLazyQuery>;
export type OwnerPayeeNameQueryResult = Apollo.QueryResult<
  OwnerPayeeNameQuery,
  OwnerPayeeNameQueryVariables
>;
export const SupplierPayeeNameDocument = gql`
  query SupplierPayeeName($id: ID!) {
    supplier(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useSupplierPayeeNameQuery__
 *
 * To run a query within a React component, call `useSupplierPayeeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierPayeeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierPayeeNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupplierPayeeNameQuery(
  baseOptions: Apollo.QueryHookOptions<SupplierPayeeNameQuery, SupplierPayeeNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SupplierPayeeNameQuery, SupplierPayeeNameQueryVariables>(
    SupplierPayeeNameDocument,
    options
  );
}
export function useSupplierPayeeNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SupplierPayeeNameQuery, SupplierPayeeNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SupplierPayeeNameQuery, SupplierPayeeNameQueryVariables>(
    SupplierPayeeNameDocument,
    options
  );
}
export type SupplierPayeeNameQueryHookResult = ReturnType<typeof useSupplierPayeeNameQuery>;
export type SupplierPayeeNameLazyQueryHookResult = ReturnType<typeof useSupplierPayeeNameLazyQuery>;
export type SupplierPayeeNameQueryResult = Apollo.QueryResult<
  SupplierPayeeNameQuery,
  SupplierPayeeNameQueryVariables
>;
export const TenantPayeeNameDocument = gql`
  query TenantPayeeName($id: ID!) {
    tenant(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useTenantPayeeNameQuery__
 *
 * To run a query within a React component, call `useTenantPayeeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantPayeeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantPayeeNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTenantPayeeNameQuery(
  baseOptions: Apollo.QueryHookOptions<TenantPayeeNameQuery, TenantPayeeNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantPayeeNameQuery, TenantPayeeNameQueryVariables>(
    TenantPayeeNameDocument,
    options
  );
}
export function useTenantPayeeNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TenantPayeeNameQuery, TenantPayeeNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantPayeeNameQuery, TenantPayeeNameQueryVariables>(
    TenantPayeeNameDocument,
    options
  );
}
export type TenantPayeeNameQueryHookResult = ReturnType<typeof useTenantPayeeNameQuery>;
export type TenantPayeeNameLazyQueryHookResult = ReturnType<typeof useTenantPayeeNameLazyQuery>;
export type TenantPayeeNameQueryResult = Apollo.QueryResult<
  TenantPayeeNameQuery,
  TenantPayeeNameQueryVariables
>;
export const JournalEntriesForPayeeRefDocument = gql`
  query JournalEntriesForPayeeRef($filter: PayeeRefFilter!) {
    account {
      id
      books {
        accountId
        journalEntriesForPayeeRef(filter: $filter) {
          id
          glId
          posted
          amount
        }
      }
    }
  }
`;

/**
 * __useJournalEntriesForPayeeRefQuery__
 *
 * To run a query within a React component, call `useJournalEntriesForPayeeRefQuery` and pass it any options that fit your needs.
 * When your component renders, `useJournalEntriesForPayeeRefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJournalEntriesForPayeeRefQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useJournalEntriesForPayeeRefQuery(
  baseOptions: Apollo.QueryHookOptions<
    JournalEntriesForPayeeRefQuery,
    JournalEntriesForPayeeRefQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JournalEntriesForPayeeRefQuery, JournalEntriesForPayeeRefQueryVariables>(
    JournalEntriesForPayeeRefDocument,
    options
  );
}
export function useJournalEntriesForPayeeRefLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JournalEntriesForPayeeRefQuery,
    JournalEntriesForPayeeRefQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    JournalEntriesForPayeeRefQuery,
    JournalEntriesForPayeeRefQueryVariables
  >(JournalEntriesForPayeeRefDocument, options);
}
export type JournalEntriesForPayeeRefQueryHookResult = ReturnType<
  typeof useJournalEntriesForPayeeRefQuery
>;
export type JournalEntriesForPayeeRefLazyQueryHookResult = ReturnType<
  typeof useJournalEntriesForPayeeRefLazyQuery
>;
export type JournalEntriesForPayeeRefQueryResult = Apollo.QueryResult<
  JournalEntriesForPayeeRefQuery,
  JournalEntriesForPayeeRefQueryVariables
>;
export const UpdatePresetRecurrenceDocument = gql`
  mutation UpdatePresetRecurrence($input: UpdatePresetRecurrenceInput!) {
    updatePresetRecurrence(input: $input) {
      success
      error
      preset {
        ...PresetFields
      }
    }
  }
  ${PresetFieldsFragmentDoc}
`;
export type UpdatePresetRecurrenceMutationFn = Apollo.MutationFunction<
  UpdatePresetRecurrenceMutation,
  UpdatePresetRecurrenceMutationVariables
>;

/**
 * __useUpdatePresetRecurrenceMutation__
 *
 * To run a mutation, you first call `useUpdatePresetRecurrenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePresetRecurrenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePresetRecurrenceMutation, { data, loading, error }] = useUpdatePresetRecurrenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePresetRecurrenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePresetRecurrenceMutation,
    UpdatePresetRecurrenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePresetRecurrenceMutation,
    UpdatePresetRecurrenceMutationVariables
  >(UpdatePresetRecurrenceDocument, options);
}
export type UpdatePresetRecurrenceMutationHookResult = ReturnType<
  typeof useUpdatePresetRecurrenceMutation
>;
export type UpdatePresetRecurrenceMutationResult =
  Apollo.MutationResult<UpdatePresetRecurrenceMutation>;
export type UpdatePresetRecurrenceMutationOptions = Apollo.BaseMutationOptions<
  UpdatePresetRecurrenceMutation,
  UpdatePresetRecurrenceMutationVariables
>;
export const BooksOwnersDocument = gql`
  query BooksOwners($nextToken: String) {
    account {
      id
      books {
        accountId
        listOwners(nextToken: $nextToken) {
          items {
            ...OwnerListFields
          }
          nextToken
        }
      }
    }
  }
  ${OwnerListFieldsFragmentDoc}
`;

/**
 * __useBooksOwnersQuery__
 *
 * To run a query within a React component, call `useBooksOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBooksOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBooksOwnersQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useBooksOwnersQuery(
  baseOptions?: Apollo.QueryHookOptions<BooksOwnersQuery, BooksOwnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BooksOwnersQuery, BooksOwnersQueryVariables>(BooksOwnersDocument, options);
}
export function useBooksOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BooksOwnersQuery, BooksOwnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BooksOwnersQuery, BooksOwnersQueryVariables>(
    BooksOwnersDocument,
    options
  );
}
export type BooksOwnersQueryHookResult = ReturnType<typeof useBooksOwnersQuery>;
export type BooksOwnersLazyQueryHookResult = ReturnType<typeof useBooksOwnersLazyQuery>;
export type BooksOwnersQueryResult = Apollo.QueryResult<
  BooksOwnersQuery,
  BooksOwnersQueryVariables
>;
export const GetReconciliationDocument = gql`
  query GetReconciliation($id: ID!) {
    reconciliation(id: $id) {
      ...ReconciliationFields
    }
  }
  ${ReconciliationFieldsFragmentDoc}
`;

/**
 * __useGetReconciliationQuery__
 *
 * To run a query within a React component, call `useGetReconciliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReconciliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReconciliationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReconciliationQuery(
  baseOptions: Apollo.QueryHookOptions<GetReconciliationQuery, GetReconciliationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReconciliationQuery, GetReconciliationQueryVariables>(
    GetReconciliationDocument,
    options
  );
}
export function useGetReconciliationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReconciliationQuery, GetReconciliationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReconciliationQuery, GetReconciliationQueryVariables>(
    GetReconciliationDocument,
    options
  );
}
export type GetReconciliationQueryHookResult = ReturnType<typeof useGetReconciliationQuery>;
export type GetReconciliationLazyQueryHookResult = ReturnType<typeof useGetReconciliationLazyQuery>;
export type GetReconciliationQueryResult = Apollo.QueryResult<
  GetReconciliationQuery,
  GetReconciliationQueryVariables
>;
export const GetReconciliationDocumentDocument = gql`
  query GetReconciliationDocument($id: ID!) {
    reconciliation(id: $id) {
      id
      documentKey
      documentError
      documentGeneratedZ
      ledgerDocumentKey
      ledgerDocumentError
      ledgerDocumentGeneratedZ
    }
  }
`;

/**
 * __useGetReconciliationDocumentQuery__
 *
 * To run a query within a React component, call `useGetReconciliationDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReconciliationDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReconciliationDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReconciliationDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReconciliationDocumentQuery,
    GetReconciliationDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReconciliationDocumentQuery, GetReconciliationDocumentQueryVariables>(
    GetReconciliationDocumentDocument,
    options
  );
}
export function useGetReconciliationDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReconciliationDocumentQuery,
    GetReconciliationDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReconciliationDocumentQuery,
    GetReconciliationDocumentQueryVariables
  >(GetReconciliationDocumentDocument, options);
}
export type GetReconciliationDocumentQueryHookResult = ReturnType<
  typeof useGetReconciliationDocumentQuery
>;
export type GetReconciliationDocumentLazyQueryHookResult = ReturnType<
  typeof useGetReconciliationDocumentLazyQuery
>;
export type GetReconciliationDocumentQueryResult = Apollo.QueryResult<
  GetReconciliationDocumentQuery,
  GetReconciliationDocumentQueryVariables
>;
export const ServerDraftedJournalsDocument = gql`
  query ServerDraftedJournals($first: Int!, $after: ID, $filter: DraftedJournalFilter!) {
    account {
      id
      books {
        accountId
        pageDraftedJournalEntries(first: $first, after: $after, filter: $filter) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            cursor
            node {
              id
              posted
              amount
              checked
            }
          }
          totalCount
          clearedCount
          totalCleared
          clearedDepositsCount
          totalDepositsCleared
          clearedWithdrawalsCount
          totalWithdrawalsCleared
          totalUncleared
          unclearedDepositsCount
          totalDepositsUncleared
          unclearedWithdrawalsCount
          totalWithdrawalsUncleared
        }
      }
    }
  }
`;

/**
 * __useServerDraftedJournalsQuery__
 *
 * To run a query within a React component, call `useServerDraftedJournalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerDraftedJournalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerDraftedJournalsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useServerDraftedJournalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServerDraftedJournalsQuery,
    ServerDraftedJournalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServerDraftedJournalsQuery, ServerDraftedJournalsQueryVariables>(
    ServerDraftedJournalsDocument,
    options
  );
}
export function useServerDraftedJournalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServerDraftedJournalsQuery,
    ServerDraftedJournalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServerDraftedJournalsQuery, ServerDraftedJournalsQueryVariables>(
    ServerDraftedJournalsDocument,
    options
  );
}
export type ServerDraftedJournalsQueryHookResult = ReturnType<typeof useServerDraftedJournalsQuery>;
export type ServerDraftedJournalsLazyQueryHookResult = ReturnType<
  typeof useServerDraftedJournalsLazyQuery
>;
export type ServerDraftedJournalsQueryResult = Apollo.QueryResult<
  ServerDraftedJournalsQuery,
  ServerDraftedJournalsQueryVariables
>;
export const ServerSubAccountDocument = gql`
  query ServerSubAccount(
    $glId: ID!
    $first: Int
    $after: ID
    $ownerId: ID
    $ownerIds: [ID!]
    $propertyId: ID
    $propertyIds: [ID!]
    $range: PostedRangeInput
    $search: String
  ) {
    glAccount(id: $glId) {
      id
      pageJournalEntries(
        first: $first
        after: $after
        filter: {
          ownerId: $ownerId
          propertyId: $propertyId
          ownerIds: $ownerIds
          propertyIds: $propertyIds
          range: $range
          search: $search
        }
      ) {
        totalCount
        endingBalance
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ...JournalEntryListFields
            payeeName
            ownerName
            propertyKey
            propertyName
            buildingKey
            buildingName
            unitName
          }
        }
      }
    }
  }
  ${JournalEntryListFieldsFragmentDoc}
`;

/**
 * __useServerSubAccountQuery__
 *
 * To run a query within a React component, call `useServerSubAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerSubAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerSubAccountQuery({
 *   variables: {
 *      glId: // value for 'glId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      ownerId: // value for 'ownerId'
 *      ownerIds: // value for 'ownerIds'
 *      propertyId: // value for 'propertyId'
 *      propertyIds: // value for 'propertyIds'
 *      range: // value for 'range'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useServerSubAccountQuery(
  baseOptions: Apollo.QueryHookOptions<ServerSubAccountQuery, ServerSubAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServerSubAccountQuery, ServerSubAccountQueryVariables>(
    ServerSubAccountDocument,
    options
  );
}
export function useServerSubAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServerSubAccountQuery, ServerSubAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServerSubAccountQuery, ServerSubAccountQueryVariables>(
    ServerSubAccountDocument,
    options
  );
}
export type ServerSubAccountQueryHookResult = ReturnType<typeof useServerSubAccountQuery>;
export type ServerSubAccountLazyQueryHookResult = ReturnType<typeof useServerSubAccountLazyQuery>;
export type ServerSubAccountQueryResult = Apollo.QueryResult<
  ServerSubAccountQuery,
  ServerSubAccountQueryVariables
>;
export const CombinedSubAccountDocument = gql`
  query CombinedSubAccount(
    $glId: ID!
    $ownerId: ID
    $ownerIds: [ID]
    $propertyId: ID
    $propertyIds: [ID]
    $from: AWSDate
    $to: AWSDate
    $nextToken: String
  ) {
    glAccount(id: $glId) {
      id
      combinedSubAccount(
        input: {
          ownerId: $ownerId
          propertyId: $propertyId
          ownerIds: $ownerIds
          propertyIds: $propertyIds
          range: { from: $from, to: $to }
        }
        nextToken: $nextToken
      ) {
        balance
        journalEntries {
          nextToken
          items {
            ...JournalEntryListFields
          }
        }
      }
    }
  }
  ${JournalEntryListFieldsFragmentDoc}
`;

/**
 * __useCombinedSubAccountQuery__
 *
 * To run a query within a React component, call `useCombinedSubAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCombinedSubAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCombinedSubAccountQuery({
 *   variables: {
 *      glId: // value for 'glId'
 *      ownerId: // value for 'ownerId'
 *      ownerIds: // value for 'ownerIds'
 *      propertyId: // value for 'propertyId'
 *      propertyIds: // value for 'propertyIds'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useCombinedSubAccountQuery(
  baseOptions: Apollo.QueryHookOptions<CombinedSubAccountQuery, CombinedSubAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CombinedSubAccountQuery, CombinedSubAccountQueryVariables>(
    CombinedSubAccountDocument,
    options
  );
}
export function useCombinedSubAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CombinedSubAccountQuery,
    CombinedSubAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CombinedSubAccountQuery, CombinedSubAccountQueryVariables>(
    CombinedSubAccountDocument,
    options
  );
}
export type CombinedSubAccountQueryHookResult = ReturnType<typeof useCombinedSubAccountQuery>;
export type CombinedSubAccountLazyQueryHookResult = ReturnType<
  typeof useCombinedSubAccountLazyQuery
>;
export type CombinedSubAccountQueryResult = Apollo.QueryResult<
  CombinedSubAccountQuery,
  CombinedSubAccountQueryVariables
>;
export const TransferJournalEntriesDocument = gql`
  mutation TransferJournalEntries($input: TransferJournalEntriesInput!) {
    transferJournalEntries(input: $input) {
      success
      error
      sourceJournalIds
      destinationJournalIds
    }
  }
`;
export type TransferJournalEntriesMutationFn = Apollo.MutationFunction<
  TransferJournalEntriesMutation,
  TransferJournalEntriesMutationVariables
>;

/**
 * __useTransferJournalEntriesMutation__
 *
 * To run a mutation, you first call `useTransferJournalEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferJournalEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferJournalEntriesMutation, { data, loading, error }] = useTransferJournalEntriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferJournalEntriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransferJournalEntriesMutation,
    TransferJournalEntriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TransferJournalEntriesMutation,
    TransferJournalEntriesMutationVariables
  >(TransferJournalEntriesDocument, options);
}
export type TransferJournalEntriesMutationHookResult = ReturnType<
  typeof useTransferJournalEntriesMutation
>;
export type TransferJournalEntriesMutationResult =
  Apollo.MutationResult<TransferJournalEntriesMutation>;
export type TransferJournalEntriesMutationOptions = Apollo.BaseMutationOptions<
  TransferJournalEntriesMutation,
  TransferJournalEntriesMutationVariables
>;
export const BundledReportsDocument = gql`
  query BundledReports {
    account {
      id
      books {
        accountId
        bundledReports {
          id
          name
          key
          startedZ
          status
          inputBindings
          completedZ
          ttl
          bundleId
          flag
          isShared
          xlsx
        }
        presets {
          id
          type
          category
          name
        }
      }
    }
  }
`;

/**
 * __useBundledReportsQuery__
 *
 * To run a query within a React component, call `useBundledReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundledReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundledReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBundledReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<BundledReportsQuery, BundledReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BundledReportsQuery, BundledReportsQueryVariables>(
    BundledReportsDocument,
    options
  );
}
export function useBundledReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BundledReportsQuery, BundledReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BundledReportsQuery, BundledReportsQueryVariables>(
    BundledReportsDocument,
    options
  );
}
export type BundledReportsQueryHookResult = ReturnType<typeof useBundledReportsQuery>;
export type BundledReportsLazyQueryHookResult = ReturnType<typeof useBundledReportsLazyQuery>;
export type BundledReportsQueryResult = Apollo.QueryResult<
  BundledReportsQuery,
  BundledReportsQueryVariables
>;
export const RunReportSetDocument = gql`
  mutation RunReportSet($input: RunReportSetInput!) {
    runReportSet(input: $input) {
      success
      error
      reportSet {
        setId
      }
    }
  }
`;
export type RunReportSetMutationFn = Apollo.MutationFunction<
  RunReportSetMutation,
  RunReportSetMutationVariables
>;

/**
 * __useRunReportSetMutation__
 *
 * To run a mutation, you first call `useRunReportSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunReportSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runReportSetMutation, { data, loading, error }] = useRunReportSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunReportSetMutation(
  baseOptions?: Apollo.MutationHookOptions<RunReportSetMutation, RunReportSetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RunReportSetMutation, RunReportSetMutationVariables>(
    RunReportSetDocument,
    options
  );
}
export type RunReportSetMutationHookResult = ReturnType<typeof useRunReportSetMutation>;
export type RunReportSetMutationResult = Apollo.MutationResult<RunReportSetMutation>;
export type RunReportSetMutationOptions = Apollo.BaseMutationOptions<
  RunReportSetMutation,
  RunReportSetMutationVariables
>;
export const DeleteBundledReportDocument = gql`
  mutation DeleteBundledReport($id: ID!) {
    deleteBundledReport(id: $id) {
      success
      error
    }
  }
`;
export type DeleteBundledReportMutationFn = Apollo.MutationFunction<
  DeleteBundledReportMutation,
  DeleteBundledReportMutationVariables
>;

/**
 * __useDeleteBundledReportMutation__
 *
 * To run a mutation, you first call `useDeleteBundledReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBundledReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBundledReportMutation, { data, loading, error }] = useDeleteBundledReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBundledReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBundledReportMutation,
    DeleteBundledReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBundledReportMutation, DeleteBundledReportMutationVariables>(
    DeleteBundledReportDocument,
    options
  );
}
export type DeleteBundledReportMutationHookResult = ReturnType<
  typeof useDeleteBundledReportMutation
>;
export type DeleteBundledReportMutationResult = Apollo.MutationResult<DeleteBundledReportMutation>;
export type DeleteBundledReportMutationOptions = Apollo.BaseMutationOptions<
  DeleteBundledReportMutation,
  DeleteBundledReportMutationVariables
>;
export const SetBundledReportSharedFlagDocument = gql`
  mutation SetBundledReportSharedFlag($id: ID!) {
    setBundledReportSharedFlag(id: $id) {
      success
      error
      bundledReport {
        id
        isShared
      }
    }
  }
`;
export type SetBundledReportSharedFlagMutationFn = Apollo.MutationFunction<
  SetBundledReportSharedFlagMutation,
  SetBundledReportSharedFlagMutationVariables
>;

/**
 * __useSetBundledReportSharedFlagMutation__
 *
 * To run a mutation, you first call `useSetBundledReportSharedFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBundledReportSharedFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBundledReportSharedFlagMutation, { data, loading, error }] = useSetBundledReportSharedFlagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetBundledReportSharedFlagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetBundledReportSharedFlagMutation,
    SetBundledReportSharedFlagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetBundledReportSharedFlagMutation,
    SetBundledReportSharedFlagMutationVariables
  >(SetBundledReportSharedFlagDocument, options);
}
export type SetBundledReportSharedFlagMutationHookResult = ReturnType<
  typeof useSetBundledReportSharedFlagMutation
>;
export type SetBundledReportSharedFlagMutationResult =
  Apollo.MutationResult<SetBundledReportSharedFlagMutation>;
export type SetBundledReportSharedFlagMutationOptions = Apollo.BaseMutationOptions<
  SetBundledReportSharedFlagMutation,
  SetBundledReportSharedFlagMutationVariables
>;
export const UpdateBundledReportFlagDocument = gql`
  mutation UpdateBundledReportFlag($input: UpdateBundledReportFlagInput!) {
    updateBundledReportFlag(input: $input) {
      success
      error
      bundledReport {
        id
        flag
      }
    }
  }
`;
export type UpdateBundledReportFlagMutationFn = Apollo.MutationFunction<
  UpdateBundledReportFlagMutation,
  UpdateBundledReportFlagMutationVariables
>;

/**
 * __useUpdateBundledReportFlagMutation__
 *
 * To run a mutation, you first call `useUpdateBundledReportFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBundledReportFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBundledReportFlagMutation, { data, loading, error }] = useUpdateBundledReportFlagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBundledReportFlagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBundledReportFlagMutation,
    UpdateBundledReportFlagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBundledReportFlagMutation,
    UpdateBundledReportFlagMutationVariables
  >(UpdateBundledReportFlagDocument, options);
}
export type UpdateBundledReportFlagMutationHookResult = ReturnType<
  typeof useUpdateBundledReportFlagMutation
>;
export type UpdateBundledReportFlagMutationResult =
  Apollo.MutationResult<UpdateBundledReportFlagMutation>;
export type UpdateBundledReportFlagMutationOptions = Apollo.BaseMutationOptions<
  UpdateBundledReportFlagMutation,
  UpdateBundledReportFlagMutationVariables
>;
export const ReportBundlesDocument = gql`
  query ReportBundles {
    account {
      id
      books {
        accountId
        reportBundles {
          id
          name
          presetIds
        }
        presets {
          id
          version
          type
          category
          name
        }
      }
    }
  }
`;

/**
 * __useReportBundlesQuery__
 *
 * To run a query within a React component, call `useReportBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportBundlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportBundlesQuery(
  baseOptions?: Apollo.QueryHookOptions<ReportBundlesQuery, ReportBundlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportBundlesQuery, ReportBundlesQueryVariables>(
    ReportBundlesDocument,
    options
  );
}
export function useReportBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportBundlesQuery, ReportBundlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportBundlesQuery, ReportBundlesQueryVariables>(
    ReportBundlesDocument,
    options
  );
}
export type ReportBundlesQueryHookResult = ReturnType<typeof useReportBundlesQuery>;
export type ReportBundlesLazyQueryHookResult = ReturnType<typeof useReportBundlesLazyQuery>;
export type ReportBundlesQueryResult = Apollo.QueryResult<
  ReportBundlesQuery,
  ReportBundlesQueryVariables
>;
export const CreateReportBundleDocument = gql`
  mutation CreateReportBundle($input: CreateReportBundleInput!) {
    createReportBundle(input: $input) {
      success
      error
      reportBundle {
        id
        name
        presetIds
      }
    }
  }
`;
export type CreateReportBundleMutationFn = Apollo.MutationFunction<
  CreateReportBundleMutation,
  CreateReportBundleMutationVariables
>;

/**
 * __useCreateReportBundleMutation__
 *
 * To run a mutation, you first call `useCreateReportBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportBundleMutation, { data, loading, error }] = useCreateReportBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReportBundleMutation,
    CreateReportBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateReportBundleMutation, CreateReportBundleMutationVariables>(
    CreateReportBundleDocument,
    options
  );
}
export type CreateReportBundleMutationHookResult = ReturnType<typeof useCreateReportBundleMutation>;
export type CreateReportBundleMutationResult = Apollo.MutationResult<CreateReportBundleMutation>;
export type CreateReportBundleMutationOptions = Apollo.BaseMutationOptions<
  CreateReportBundleMutation,
  CreateReportBundleMutationVariables
>;
export const UpdateReportBundleDocument = gql`
  mutation UpdateReportBundle($input: UpdateReportBundleInput!) {
    updateReportBundle(input: $input) {
      success
      error
      reportBundle {
        id
        name
        presetIds
      }
    }
  }
`;
export type UpdateReportBundleMutationFn = Apollo.MutationFunction<
  UpdateReportBundleMutation,
  UpdateReportBundleMutationVariables
>;

/**
 * __useUpdateReportBundleMutation__
 *
 * To run a mutation, you first call `useUpdateReportBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportBundleMutation, { data, loading, error }] = useUpdateReportBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReportBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReportBundleMutation,
    UpdateReportBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateReportBundleMutation, UpdateReportBundleMutationVariables>(
    UpdateReportBundleDocument,
    options
  );
}
export type UpdateReportBundleMutationHookResult = ReturnType<typeof useUpdateReportBundleMutation>;
export type UpdateReportBundleMutationResult = Apollo.MutationResult<UpdateReportBundleMutation>;
export type UpdateReportBundleMutationOptions = Apollo.BaseMutationOptions<
  UpdateReportBundleMutation,
  UpdateReportBundleMutationVariables
>;
export const DeleteReportBundleDocument = gql`
  mutation DeleteReportBundle($id: ID!) {
    deleteReportBundle(id: $id) {
      success
      error
    }
  }
`;
export type DeleteReportBundleMutationFn = Apollo.MutationFunction<
  DeleteReportBundleMutation,
  DeleteReportBundleMutationVariables
>;

/**
 * __useDeleteReportBundleMutation__
 *
 * To run a mutation, you first call `useDeleteReportBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportBundleMutation, { data, loading, error }] = useDeleteReportBundleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReportBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReportBundleMutation,
    DeleteReportBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteReportBundleMutation, DeleteReportBundleMutationVariables>(
    DeleteReportBundleDocument,
    options
  );
}
export type DeleteReportBundleMutationHookResult = ReturnType<typeof useDeleteReportBundleMutation>;
export type DeleteReportBundleMutationResult = Apollo.MutationResult<DeleteReportBundleMutation>;
export type DeleteReportBundleMutationOptions = Apollo.BaseMutationOptions<
  DeleteReportBundleMutation,
  DeleteReportBundleMutationVariables
>;
export const GetOwnerFiscalDocument = gql`
  query GetOwnerFiscal($ownerId: ID!) {
    owner(id: $ownerId) {
      id
      yearEndMonth
    }
  }
`;

/**
 * __useGetOwnerFiscalQuery__
 *
 * To run a query within a React component, call `useGetOwnerFiscalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerFiscalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerFiscalQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useGetOwnerFiscalQuery(
  baseOptions: Apollo.QueryHookOptions<GetOwnerFiscalQuery, GetOwnerFiscalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnerFiscalQuery, GetOwnerFiscalQueryVariables>(
    GetOwnerFiscalDocument,
    options
  );
}
export function useGetOwnerFiscalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerFiscalQuery, GetOwnerFiscalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnerFiscalQuery, GetOwnerFiscalQueryVariables>(
    GetOwnerFiscalDocument,
    options
  );
}
export type GetOwnerFiscalQueryHookResult = ReturnType<typeof useGetOwnerFiscalQuery>;
export type GetOwnerFiscalLazyQueryHookResult = ReturnType<typeof useGetOwnerFiscalLazyQuery>;
export type GetOwnerFiscalQueryResult = Apollo.QueryResult<
  GetOwnerFiscalQuery,
  GetOwnerFiscalQueryVariables
>;
export const GetBudgetsDocument = gql`
  query GetBudgets($input: GetBudgetsInput!) {
    account {
      id
      books {
        accountId
        getBudgets(input: $input) {
          ownerId
          propertyId
          glId
          period
          amount
        }
      }
    }
  }
`;

/**
 * __useGetBudgetsQuery__
 *
 * To run a query within a React component, call `useGetBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBudgetsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBudgetsQuery, GetBudgetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBudgetsQuery, GetBudgetsQueryVariables>(GetBudgetsDocument, options);
}
export function useGetBudgetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBudgetsQuery, GetBudgetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBudgetsQuery, GetBudgetsQueryVariables>(
    GetBudgetsDocument,
    options
  );
}
export type GetBudgetsQueryHookResult = ReturnType<typeof useGetBudgetsQuery>;
export type GetBudgetsLazyQueryHookResult = ReturnType<typeof useGetBudgetsLazyQuery>;
export type GetBudgetsQueryResult = Apollo.QueryResult<GetBudgetsQuery, GetBudgetsQueryVariables>;
export const PutBudgetsDocument = gql`
  mutation PutBudgets($input: PutBudgetsInput!) {
    putBudgets(input: $input) {
      success
      error
    }
  }
`;
export type PutBudgetsMutationFn = Apollo.MutationFunction<
  PutBudgetsMutation,
  PutBudgetsMutationVariables
>;

/**
 * __usePutBudgetsMutation__
 *
 * To run a mutation, you first call `usePutBudgetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutBudgetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putBudgetsMutation, { data, loading, error }] = usePutBudgetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePutBudgetsMutation(
  baseOptions?: Apollo.MutationHookOptions<PutBudgetsMutation, PutBudgetsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PutBudgetsMutation, PutBudgetsMutationVariables>(
    PutBudgetsDocument,
    options
  );
}
export type PutBudgetsMutationHookResult = ReturnType<typeof usePutBudgetsMutation>;
export type PutBudgetsMutationResult = Apollo.MutationResult<PutBudgetsMutation>;
export type PutBudgetsMutationOptions = Apollo.BaseMutationOptions<
  PutBudgetsMutation,
  PutBudgetsMutationVariables
>;
export const PrintJournalEntriesDocument = gql`
  mutation PrintJournalEntries($input: PrintJournalEntriesInput!) {
    printJournalEntries(input: $input) {
      success
      error
      glId
      books {
        ...BooksFields
      }
      journalEntries {
        ...ChequeFields
      }
    }
  }
  ${BooksFieldsFragmentDoc}
  ${ChequeFieldsFragmentDoc}
`;
export type PrintJournalEntriesMutationFn = Apollo.MutationFunction<
  PrintJournalEntriesMutation,
  PrintJournalEntriesMutationVariables
>;

/**
 * __usePrintJournalEntriesMutation__
 *
 * To run a mutation, you first call `usePrintJournalEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintJournalEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printJournalEntriesMutation, { data, loading, error }] = usePrintJournalEntriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintJournalEntriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PrintJournalEntriesMutation,
    PrintJournalEntriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PrintJournalEntriesMutation, PrintJournalEntriesMutationVariables>(
    PrintJournalEntriesDocument,
    options
  );
}
export type PrintJournalEntriesMutationHookResult = ReturnType<
  typeof usePrintJournalEntriesMutation
>;
export type PrintJournalEntriesMutationResult = Apollo.MutationResult<PrintJournalEntriesMutation>;
export type PrintJournalEntriesMutationOptions = Apollo.BaseMutationOptions<
  PrintJournalEntriesMutation,
  PrintJournalEntriesMutationVariables
>;
export const ListChequesDocument = gql`
  query ListCheques($nextToken: String, $glId: ID!, $printedZ: String!) {
    account {
      id
      books {
        accountId
        printedZ
        nextCheque
        listCheques(nextToken: $nextToken, glId: $glId, printedZ: $printedZ) {
          nextToken
          items {
            ...ChequeFields
          }
        }
      }
    }
  }
  ${ChequeFieldsFragmentDoc}
`;

/**
 * __useListChequesQuery__
 *
 * To run a query within a React component, call `useListChequesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChequesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChequesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      glId: // value for 'glId'
 *      printedZ: // value for 'printedZ'
 *   },
 * });
 */
export function useListChequesQuery(
  baseOptions: Apollo.QueryHookOptions<ListChequesQuery, ListChequesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListChequesQuery, ListChequesQueryVariables>(ListChequesDocument, options);
}
export function useListChequesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListChequesQuery, ListChequesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListChequesQuery, ListChequesQueryVariables>(
    ListChequesDocument,
    options
  );
}
export type ListChequesQueryHookResult = ReturnType<typeof useListChequesQuery>;
export type ListChequesLazyQueryHookResult = ReturnType<typeof useListChequesLazyQuery>;
export type ListChequesQueryResult = Apollo.QueryResult<
  ListChequesQuery,
  ListChequesQueryVariables
>;
export const PreviewChequesDocument = gql`
  query PreviewCheques($glId: ID!) {
    account {
      id
      books {
        accountId
        previewCheques(glId: $glId) {
          glId
          journalEntries {
            ...ChequeFields
          }
        }
      }
    }
  }
  ${ChequeFieldsFragmentDoc}
`;

/**
 * __usePreviewChequesQuery__
 *
 * To run a query within a React component, call `usePreviewChequesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewChequesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewChequesQuery({
 *   variables: {
 *      glId: // value for 'glId'
 *   },
 * });
 */
export function usePreviewChequesQuery(
  baseOptions: Apollo.QueryHookOptions<PreviewChequesQuery, PreviewChequesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PreviewChequesQuery, PreviewChequesQueryVariables>(
    PreviewChequesDocument,
    options
  );
}
export function usePreviewChequesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PreviewChequesQuery, PreviewChequesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PreviewChequesQuery, PreviewChequesQueryVariables>(
    PreviewChequesDocument,
    options
  );
}
export type PreviewChequesQueryHookResult = ReturnType<typeof usePreviewChequesQuery>;
export type PreviewChequesLazyQueryHookResult = ReturnType<typeof usePreviewChequesLazyQuery>;
export type PreviewChequesQueryResult = Apollo.QueryResult<
  PreviewChequesQuery,
  PreviewChequesQueryVariables
>;
export const TenantActivatedEnrollmentsDocument = gql`
  query TenantActivatedEnrollments($payeeId: ID!) {
    tenant(id: $payeeId) {
      id
      enrollments(filter: { anyStatus: [ACTIVATED], type: VARIABLE }) {
        id
        unitId
        propertyId
      }
    }
  }
`;

/**
 * __useTenantActivatedEnrollmentsQuery__
 *
 * To run a query within a React component, call `useTenantActivatedEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantActivatedEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantActivatedEnrollmentsQuery({
 *   variables: {
 *      payeeId: // value for 'payeeId'
 *   },
 * });
 */
export function useTenantActivatedEnrollmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TenantActivatedEnrollmentsQuery,
    TenantActivatedEnrollmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantActivatedEnrollmentsQuery, TenantActivatedEnrollmentsQueryVariables>(
    TenantActivatedEnrollmentsDocument,
    options
  );
}
export function useTenantActivatedEnrollmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TenantActivatedEnrollmentsQuery,
    TenantActivatedEnrollmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TenantActivatedEnrollmentsQuery,
    TenantActivatedEnrollmentsQueryVariables
  >(TenantActivatedEnrollmentsDocument, options);
}
export type TenantActivatedEnrollmentsQueryHookResult = ReturnType<
  typeof useTenantActivatedEnrollmentsQuery
>;
export type TenantActivatedEnrollmentsLazyQueryHookResult = ReturnType<
  typeof useTenantActivatedEnrollmentsLazyQuery
>;
export type TenantActivatedEnrollmentsQueryResult = Apollo.QueryResult<
  TenantActivatedEnrollmentsQuery,
  TenantActivatedEnrollmentsQueryVariables
>;
export const OwnerActivatedEnrollmentsDocument = gql`
  query OwnerActivatedEnrollments($payeeId: ID!) {
    owner(id: $payeeId) {
      id
      enrollments(filter: { anyStatus: [ACTIVATED], type: VARIABLE }) {
        id
        unitId
        propertyId
      }
    }
  }
`;

/**
 * __useOwnerActivatedEnrollmentsQuery__
 *
 * To run a query within a React component, call `useOwnerActivatedEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerActivatedEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerActivatedEnrollmentsQuery({
 *   variables: {
 *      payeeId: // value for 'payeeId'
 *   },
 * });
 */
export function useOwnerActivatedEnrollmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OwnerActivatedEnrollmentsQuery,
    OwnerActivatedEnrollmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OwnerActivatedEnrollmentsQuery, OwnerActivatedEnrollmentsQueryVariables>(
    OwnerActivatedEnrollmentsDocument,
    options
  );
}
export function useOwnerActivatedEnrollmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OwnerActivatedEnrollmentsQuery,
    OwnerActivatedEnrollmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OwnerActivatedEnrollmentsQuery,
    OwnerActivatedEnrollmentsQueryVariables
  >(OwnerActivatedEnrollmentsDocument, options);
}
export type OwnerActivatedEnrollmentsQueryHookResult = ReturnType<
  typeof useOwnerActivatedEnrollmentsQuery
>;
export type OwnerActivatedEnrollmentsLazyQueryHookResult = ReturnType<
  typeof useOwnerActivatedEnrollmentsLazyQuery
>;
export type OwnerActivatedEnrollmentsQueryResult = Apollo.QueryResult<
  OwnerActivatedEnrollmentsQuery,
  OwnerActivatedEnrollmentsQueryVariables
>;
export const ServerReconciliationJournalsDocument = gql`
  query ServerReconciliationJournals(
    $first: Int
    $after: ID
    $filter: ReconciliationJournalFilter!
    $sort: [JournalEntriesSortInput!]
  ) {
    account {
      id
      books {
        accountId
        pageJournalEntriesForReconciliation(
          first: $first
          after: $after
          filter: $filter
          sort: $sort
        ) {
          totalCount
          totalDeposits
          totalWithdrawals
          clearedCount
          totalCleared
          unclearedCount
          totalUncleared
          clearedDepositsCount
          totalDepositsCleared
          clearedWithdrawalsCount
          totalWithdrawalsCleared
          unclearedDepositsCount
          totalDepositsUncleared
          unclearedWithdrawalsCount
          totalWithdrawalsUncleared
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              posted
              ref
              amount
              batchId
              description
              reconciliationId
              paymentMethod
              payeeName
              ownerName
              propertyKey
              propertyName
              buildingKey
              buildingName
              unitId
              unitName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useServerReconciliationJournalsQuery__
 *
 * To run a query within a React component, call `useServerReconciliationJournalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerReconciliationJournalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerReconciliationJournalsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useServerReconciliationJournalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServerReconciliationJournalsQuery,
    ServerReconciliationJournalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ServerReconciliationJournalsQuery,
    ServerReconciliationJournalsQueryVariables
  >(ServerReconciliationJournalsDocument, options);
}
export function useServerReconciliationJournalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServerReconciliationJournalsQuery,
    ServerReconciliationJournalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ServerReconciliationJournalsQuery,
    ServerReconciliationJournalsQueryVariables
  >(ServerReconciliationJournalsDocument, options);
}
export type ServerReconciliationJournalsQueryHookResult = ReturnType<
  typeof useServerReconciliationJournalsQuery
>;
export type ServerReconciliationJournalsLazyQueryHookResult = ReturnType<
  typeof useServerReconciliationJournalsLazyQuery
>;
export type ServerReconciliationJournalsQueryResult = Apollo.QueryResult<
  ServerReconciliationJournalsQuery,
  ServerReconciliationJournalsQueryVariables
>;
export const ReconciliationConfigScopeStepPathDocument = gql`
  query ReconciliationConfigScopeStepPath($filter: SubAccountFilterInput, $nextToken: String) {
    account {
      id
      books {
        accountId
        listSubAccounts(filter: $filter, nextToken: $nextToken) {
          nextToken
          items {
            id
            ownerId
            propertyId
          }
        }
      }
    }
  }
`;

/**
 * __useReconciliationConfigScopeStepPathQuery__
 *
 * To run a query within a React component, call `useReconciliationConfigScopeStepPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useReconciliationConfigScopeStepPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReconciliationConfigScopeStepPathQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useReconciliationConfigScopeStepPathQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReconciliationConfigScopeStepPathQuery,
    ReconciliationConfigScopeStepPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReconciliationConfigScopeStepPathQuery,
    ReconciliationConfigScopeStepPathQueryVariables
  >(ReconciliationConfigScopeStepPathDocument, options);
}
export function useReconciliationConfigScopeStepPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReconciliationConfigScopeStepPathQuery,
    ReconciliationConfigScopeStepPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReconciliationConfigScopeStepPathQuery,
    ReconciliationConfigScopeStepPathQueryVariables
  >(ReconciliationConfigScopeStepPathDocument, options);
}
export type ReconciliationConfigScopeStepPathQueryHookResult = ReturnType<
  typeof useReconciliationConfigScopeStepPathQuery
>;
export type ReconciliationConfigScopeStepPathLazyQueryHookResult = ReturnType<
  typeof useReconciliationConfigScopeStepPathLazyQuery
>;
export type ReconciliationConfigScopeStepPathQueryResult = Apollo.QueryResult<
  ReconciliationConfigScopeStepPathQuery,
  ReconciliationConfigScopeStepPathQueryVariables
>;
export const GetReconciliationsDocument = gql`
  query GetReconciliations {
    manager {
      id
      account {
        id
        books {
          accountId
          reconciliations {
            ...ReconciliationFields
          }
          reconciliationConfigs {
            ...ReconciliationConfigFields
          }
        }
      }
    }
  }
  ${ReconciliationFieldsFragmentDoc}
  ${ReconciliationConfigFieldsFragmentDoc}
`;

/**
 * __useGetReconciliationsQuery__
 *
 * To run a query within a React component, call `useGetReconciliationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReconciliationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReconciliationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReconciliationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReconciliationsQuery, GetReconciliationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReconciliationsQuery, GetReconciliationsQueryVariables>(
    GetReconciliationsDocument,
    options
  );
}
export function useGetReconciliationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReconciliationsQuery,
    GetReconciliationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReconciliationsQuery, GetReconciliationsQueryVariables>(
    GetReconciliationsDocument,
    options
  );
}
export type GetReconciliationsQueryHookResult = ReturnType<typeof useGetReconciliationsQuery>;
export type GetReconciliationsLazyQueryHookResult = ReturnType<
  typeof useGetReconciliationsLazyQuery
>;
export type GetReconciliationsQueryResult = Apollo.QueryResult<
  GetReconciliationsQuery,
  GetReconciliationsQueryVariables
>;
export const ListReconciliationsDocument = gql`
  query ListReconciliations($nextToken: String) {
    account {
      id
      books {
        accountId
        listReconciliations(nextToken: $nextToken) {
          items {
            ...ReconciliationFields
          }
          nextToken
        }
      }
    }
  }
  ${ReconciliationFieldsFragmentDoc}
`;

/**
 * __useListReconciliationsQuery__
 *
 * To run a query within a React component, call `useListReconciliationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReconciliationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReconciliationsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListReconciliationsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListReconciliationsQuery, ListReconciliationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListReconciliationsQuery, ListReconciliationsQueryVariables>(
    ListReconciliationsDocument,
    options
  );
}
export function useListReconciliationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListReconciliationsQuery,
    ListReconciliationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListReconciliationsQuery, ListReconciliationsQueryVariables>(
    ListReconciliationsDocument,
    options
  );
}
export type ListReconciliationsQueryHookResult = ReturnType<typeof useListReconciliationsQuery>;
export type ListReconciliationsLazyQueryHookResult = ReturnType<
  typeof useListReconciliationsLazyQuery
>;
export type ListReconciliationsQueryResult = Apollo.QueryResult<
  ListReconciliationsQuery,
  ListReconciliationsQueryVariables
>;
export const ListReconciliationConfigsDocument = gql`
  query ListReconciliationConfigs($nextToken: String) {
    account {
      id
      books {
        accountId
        listReconciliationConfigs(nextToken: $nextToken) {
          items {
            ...ReconciliationConfigFields
          }
          nextToken
        }
      }
    }
  }
  ${ReconciliationConfigFieldsFragmentDoc}
`;

/**
 * __useListReconciliationConfigsQuery__
 *
 * To run a query within a React component, call `useListReconciliationConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReconciliationConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReconciliationConfigsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListReconciliationConfigsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListReconciliationConfigsQuery,
    ListReconciliationConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListReconciliationConfigsQuery, ListReconciliationConfigsQueryVariables>(
    ListReconciliationConfigsDocument,
    options
  );
}
export function useListReconciliationConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListReconciliationConfigsQuery,
    ListReconciliationConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListReconciliationConfigsQuery,
    ListReconciliationConfigsQueryVariables
  >(ListReconciliationConfigsDocument, options);
}
export type ListReconciliationConfigsQueryHookResult = ReturnType<
  typeof useListReconciliationConfigsQuery
>;
export type ListReconciliationConfigsLazyQueryHookResult = ReturnType<
  typeof useListReconciliationConfigsLazyQuery
>;
export type ListReconciliationConfigsQueryResult = Apollo.QueryResult<
  ListReconciliationConfigsQuery,
  ListReconciliationConfigsQueryVariables
>;
export const UpdateReconciliationDocument = gql`
  mutation UpdateReconciliation($input: UpdateReconciliationInput!) {
    updateReconciliation(input: $input) {
      success
      error
      reconciliation {
        ...ReconciliationFields
      }
    }
  }
  ${ReconciliationFieldsFragmentDoc}
`;
export type UpdateReconciliationMutationFn = Apollo.MutationFunction<
  UpdateReconciliationMutation,
  UpdateReconciliationMutationVariables
>;

/**
 * __useUpdateReconciliationMutation__
 *
 * To run a mutation, you first call `useUpdateReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReconciliationMutation, { data, loading, error }] = useUpdateReconciliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReconciliationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReconciliationMutation,
    UpdateReconciliationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateReconciliationMutation, UpdateReconciliationMutationVariables>(
    UpdateReconciliationDocument,
    options
  );
}
export type UpdateReconciliationMutationHookResult = ReturnType<
  typeof useUpdateReconciliationMutation
>;
export type UpdateReconciliationMutationResult =
  Apollo.MutationResult<UpdateReconciliationMutation>;
export type UpdateReconciliationMutationOptions = Apollo.BaseMutationOptions<
  UpdateReconciliationMutation,
  UpdateReconciliationMutationVariables
>;
export const DeleteReconciliationDocument = gql`
  mutation DeleteReconciliation($id: ID!) {
    deleteReconciliation(id: $id) {
      success
      error
      lastReconciliation {
        id
        current
      }
    }
  }
`;
export type DeleteReconciliationMutationFn = Apollo.MutationFunction<
  DeleteReconciliationMutation,
  DeleteReconciliationMutationVariables
>;

/**
 * __useDeleteReconciliationMutation__
 *
 * To run a mutation, you first call `useDeleteReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReconciliationMutation, { data, loading, error }] = useDeleteReconciliationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReconciliationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReconciliationMutation,
    DeleteReconciliationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteReconciliationMutation, DeleteReconciliationMutationVariables>(
    DeleteReconciliationDocument,
    options
  );
}
export type DeleteReconciliationMutationHookResult = ReturnType<
  typeof useDeleteReconciliationMutation
>;
export type DeleteReconciliationMutationResult =
  Apollo.MutationResult<DeleteReconciliationMutation>;
export type DeleteReconciliationMutationOptions = Apollo.BaseMutationOptions<
  DeleteReconciliationMutation,
  DeleteReconciliationMutationVariables
>;
export const UpdateJournalDraftsDocument = gql`
  mutation UpdateJournalDrafts($input: UpdateJournalDraftsInput!) {
    updateJournalDrafts(input: $input) {
      success
      error
    }
  }
`;
export type UpdateJournalDraftsMutationFn = Apollo.MutationFunction<
  UpdateJournalDraftsMutation,
  UpdateJournalDraftsMutationVariables
>;

/**
 * __useUpdateJournalDraftsMutation__
 *
 * To run a mutation, you first call `useUpdateJournalDraftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJournalDraftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJournalDraftsMutation, { data, loading, error }] = useUpdateJournalDraftsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJournalDraftsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateJournalDraftsMutation,
    UpdateJournalDraftsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateJournalDraftsMutation, UpdateJournalDraftsMutationVariables>(
    UpdateJournalDraftsDocument,
    options
  );
}
export type UpdateJournalDraftsMutationHookResult = ReturnType<
  typeof useUpdateJournalDraftsMutation
>;
export type UpdateJournalDraftsMutationResult = Apollo.MutationResult<UpdateJournalDraftsMutation>;
export type UpdateJournalDraftsMutationOptions = Apollo.BaseMutationOptions<
  UpdateJournalDraftsMutation,
  UpdateJournalDraftsMutationVariables
>;
export const AddReconciliationConfigDocument = gql`
  mutation AddReconciliationConfig($input: AddReconciliationConfigInput!) {
    addReconciliationConfig(input: $input) {
      success
      error
      reconciliationConfig {
        ...ReconciliationConfigFields
      }
    }
  }
  ${ReconciliationConfigFieldsFragmentDoc}
`;
export type AddReconciliationConfigMutationFn = Apollo.MutationFunction<
  AddReconciliationConfigMutation,
  AddReconciliationConfigMutationVariables
>;

/**
 * __useAddReconciliationConfigMutation__
 *
 * To run a mutation, you first call `useAddReconciliationConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReconciliationConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReconciliationConfigMutation, { data, loading, error }] = useAddReconciliationConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddReconciliationConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddReconciliationConfigMutation,
    AddReconciliationConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddReconciliationConfigMutation,
    AddReconciliationConfigMutationVariables
  >(AddReconciliationConfigDocument, options);
}
export type AddReconciliationConfigMutationHookResult = ReturnType<
  typeof useAddReconciliationConfigMutation
>;
export type AddReconciliationConfigMutationResult =
  Apollo.MutationResult<AddReconciliationConfigMutation>;
export type AddReconciliationConfigMutationOptions = Apollo.BaseMutationOptions<
  AddReconciliationConfigMutation,
  AddReconciliationConfigMutationVariables
>;
export const UpdateReconciliationConfigDocument = gql`
  mutation UpdateReconciliationConfig($input: UpdateReconciliationConfigInput!) {
    updateReconciliationConfig(input: $input) {
      success
      error
      reconciliationConfig {
        ...ReconciliationConfigFields
      }
    }
  }
  ${ReconciliationConfigFieldsFragmentDoc}
`;
export type UpdateReconciliationConfigMutationFn = Apollo.MutationFunction<
  UpdateReconciliationConfigMutation,
  UpdateReconciliationConfigMutationVariables
>;

/**
 * __useUpdateReconciliationConfigMutation__
 *
 * To run a mutation, you first call `useUpdateReconciliationConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReconciliationConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReconciliationConfigMutation, { data, loading, error }] = useUpdateReconciliationConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReconciliationConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReconciliationConfigMutation,
    UpdateReconciliationConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReconciliationConfigMutation,
    UpdateReconciliationConfigMutationVariables
  >(UpdateReconciliationConfigDocument, options);
}
export type UpdateReconciliationConfigMutationHookResult = ReturnType<
  typeof useUpdateReconciliationConfigMutation
>;
export type UpdateReconciliationConfigMutationResult =
  Apollo.MutationResult<UpdateReconciliationConfigMutation>;
export type UpdateReconciliationConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateReconciliationConfigMutation,
  UpdateReconciliationConfigMutationVariables
>;
export const DeleteReconciliationConfigDocument = gql`
  mutation DeleteReconciliationConfig($input: DeleteReconciliationConfigInput!) {
    deleteReconciliationConfig(input: $input) {
      success
      error
    }
  }
`;
export type DeleteReconciliationConfigMutationFn = Apollo.MutationFunction<
  DeleteReconciliationConfigMutation,
  DeleteReconciliationConfigMutationVariables
>;

/**
 * __useDeleteReconciliationConfigMutation__
 *
 * To run a mutation, you first call `useDeleteReconciliationConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReconciliationConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReconciliationConfigMutation, { data, loading, error }] = useDeleteReconciliationConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReconciliationConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReconciliationConfigMutation,
    DeleteReconciliationConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteReconciliationConfigMutation,
    DeleteReconciliationConfigMutationVariables
  >(DeleteReconciliationConfigDocument, options);
}
export type DeleteReconciliationConfigMutationHookResult = ReturnType<
  typeof useDeleteReconciliationConfigMutation
>;
export type DeleteReconciliationConfigMutationResult =
  Apollo.MutationResult<DeleteReconciliationConfigMutation>;
export type DeleteReconciliationConfigMutationOptions = Apollo.BaseMutationOptions<
  DeleteReconciliationConfigMutation,
  DeleteReconciliationConfigMutationVariables
>;
export const PropertiesWithCurrentResidenciesDocument = gql`
  query PropertiesWithCurrentResidencies(
    $first: Int
    $after: ID
    $filter: ResidenciesFilterInput
    $sort: [ResidenciesSortInput!]
  ) {
    account {
      id
      pageResidencies(first: $first, after: $after, filter: $filter, sort: $sort) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            propertyId
            propertyName
            unitId
            unitName
            residents {
              id
              residentId
              leaseHolder
              resident {
                __typename
                id
                enabled
                name
                emails
                phones
                ... on Tenant {
                  phone
                  enabled
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __usePropertiesWithCurrentResidenciesQuery__
 *
 * To run a query within a React component, call `usePropertiesWithCurrentResidenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesWithCurrentResidenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesWithCurrentResidenciesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePropertiesWithCurrentResidenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PropertiesWithCurrentResidenciesQuery,
    PropertiesWithCurrentResidenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PropertiesWithCurrentResidenciesQuery,
    PropertiesWithCurrentResidenciesQueryVariables
  >(PropertiesWithCurrentResidenciesDocument, options);
}
export function usePropertiesWithCurrentResidenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertiesWithCurrentResidenciesQuery,
    PropertiesWithCurrentResidenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PropertiesWithCurrentResidenciesQuery,
    PropertiesWithCurrentResidenciesQueryVariables
  >(PropertiesWithCurrentResidenciesDocument, options);
}
export type PropertiesWithCurrentResidenciesQueryHookResult = ReturnType<
  typeof usePropertiesWithCurrentResidenciesQuery
>;
export type PropertiesWithCurrentResidenciesLazyQueryHookResult = ReturnType<
  typeof usePropertiesWithCurrentResidenciesLazyQuery
>;
export type PropertiesWithCurrentResidenciesQueryResult = Apollo.QueryResult<
  PropertiesWithCurrentResidenciesQuery,
  PropertiesWithCurrentResidenciesQueryVariables
>;
export const GetOwnersWithContactsDocument = gql`
  query GetOwnersWithContacts(
    $first: Int
    $after: ID
    $filter: OwnersFilterInput
    $sort: [OwnersSortInput!]
  ) {
    account {
      id
      pageOwners(first: $first, after: $after, filter: $filter, sort: $sort) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            name
            email
            phones
            enabled
            unitHeaders {
              id
              propertyId
              propertyName
              name
            }
            propertyHeaders {
              id
              name
              key
            }
            propertyIds
            unitIds
            contacts {
              ...ContactFields
            }
          }
        }
      }
    }
  }
  ${ContactFieldsFragmentDoc}
`;

/**
 * __useGetOwnersWithContactsQuery__
 *
 * To run a query within a React component, call `useGetOwnersWithContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnersWithContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnersWithContactsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetOwnersWithContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOwnersWithContactsQuery,
    GetOwnersWithContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnersWithContactsQuery, GetOwnersWithContactsQueryVariables>(
    GetOwnersWithContactsDocument,
    options
  );
}
export function useGetOwnersWithContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOwnersWithContactsQuery,
    GetOwnersWithContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnersWithContactsQuery, GetOwnersWithContactsQueryVariables>(
    GetOwnersWithContactsDocument,
    options
  );
}
export type GetOwnersWithContactsQueryHookResult = ReturnType<typeof useGetOwnersWithContactsQuery>;
export type GetOwnersWithContactsLazyQueryHookResult = ReturnType<
  typeof useGetOwnersWithContactsLazyQuery
>;
export type GetOwnersWithContactsQueryResult = Apollo.QueryResult<
  GetOwnersWithContactsQuery,
  GetOwnersWithContactsQueryVariables
>;
export const GetAnnouncementsDocument = gql`
  query GetAnnouncements($nextToken: String) {
    manager {
      id
      account {
        id
        listAnnouncements(nextToken: $nextToken) {
          nextToken
          items {
            ...AnnouncementsFields
          }
        }
      }
    }
  }
  ${AnnouncementsFieldsFragmentDoc}
`;

/**
 * __useGetAnnouncementsQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetAnnouncementsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(
    GetAnnouncementsDocument,
    options
  );
}
export function useGetAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(
    GetAnnouncementsDocument,
    options
  );
}
export type GetAnnouncementsQueryHookResult = ReturnType<typeof useGetAnnouncementsQuery>;
export type GetAnnouncementsLazyQueryHookResult = ReturnType<typeof useGetAnnouncementsLazyQuery>;
export type GetAnnouncementsQueryResult = Apollo.QueryResult<
  GetAnnouncementsQuery,
  GetAnnouncementsQueryVariables
>;
export const CreateAnnouncementDocument = gql`
  mutation CreateAnnouncement($input: CreateAnnouncementInput!) {
    createAnnouncement(input: $input) {
      success
      error
      announcement {
        ...AnnouncementsFields
      }
    }
  }
  ${AnnouncementsFieldsFragmentDoc}
`;
export type CreateAnnouncementMutationFn = Apollo.MutationFunction<
  CreateAnnouncementMutation,
  CreateAnnouncementMutationVariables
>;

/**
 * __useCreateAnnouncementMutation__
 *
 * To run a mutation, you first call `useCreateAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnnouncementMutation, { data, loading, error }] = useCreateAnnouncementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnnouncementMutation,
    CreateAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAnnouncementMutation, CreateAnnouncementMutationVariables>(
    CreateAnnouncementDocument,
    options
  );
}
export type CreateAnnouncementMutationHookResult = ReturnType<typeof useCreateAnnouncementMutation>;
export type CreateAnnouncementMutationResult = Apollo.MutationResult<CreateAnnouncementMutation>;
export type CreateAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  CreateAnnouncementMutation,
  CreateAnnouncementMutationVariables
>;
export const UpdateAnnouncementDocument = gql`
  mutation UpdateAnnouncement($input: UpdateAnnouncementInput!) {
    updateAnnouncement(input: $input) {
      success
      error
      announcement {
        ...AnnouncementsFields
      }
    }
  }
  ${AnnouncementsFieldsFragmentDoc}
`;
export type UpdateAnnouncementMutationFn = Apollo.MutationFunction<
  UpdateAnnouncementMutation,
  UpdateAnnouncementMutationVariables
>;

/**
 * __useUpdateAnnouncementMutation__
 *
 * To run a mutation, you first call `useUpdateAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnouncementMutation, { data, loading, error }] = useUpdateAnnouncementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnnouncementMutation,
    UpdateAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAnnouncementMutation, UpdateAnnouncementMutationVariables>(
    UpdateAnnouncementDocument,
    options
  );
}
export type UpdateAnnouncementMutationHookResult = ReturnType<typeof useUpdateAnnouncementMutation>;
export type UpdateAnnouncementMutationResult = Apollo.MutationResult<UpdateAnnouncementMutation>;
export type UpdateAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnnouncementMutation,
  UpdateAnnouncementMutationVariables
>;
export const DeleteAnnouncementDocument = gql`
  mutation DeleteAnnouncement($id: ID!) {
    deleteAnnouncement(id: $id) {
      success
      error
    }
  }
`;
export type DeleteAnnouncementMutationFn = Apollo.MutationFunction<
  DeleteAnnouncementMutation,
  DeleteAnnouncementMutationVariables
>;

/**
 * __useDeleteAnnouncementMutation__
 *
 * To run a mutation, you first call `useDeleteAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnouncementMutation, { data, loading, error }] = useDeleteAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAnnouncementMutation,
    DeleteAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAnnouncementMutation, DeleteAnnouncementMutationVariables>(
    DeleteAnnouncementDocument,
    options
  );
}
export type DeleteAnnouncementMutationHookResult = ReturnType<typeof useDeleteAnnouncementMutation>;
export type DeleteAnnouncementMutationResult = Apollo.MutationResult<DeleteAnnouncementMutation>;
export type DeleteAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  DeleteAnnouncementMutation,
  DeleteAnnouncementMutationVariables
>;
export const PublishFutureAnnouncementDocument = gql`
  mutation PublishFutureAnnouncement($id: ID!, $publishZ: AWSDateTime) {
    publishAnnouncement(id: $id, publishZ: $publishZ) {
      success
      error
      announcement {
        ...AnnouncementsFields
      }
    }
  }
  ${AnnouncementsFieldsFragmentDoc}
`;
export type PublishFutureAnnouncementMutationFn = Apollo.MutationFunction<
  PublishFutureAnnouncementMutation,
  PublishFutureAnnouncementMutationVariables
>;

/**
 * __usePublishFutureAnnouncementMutation__
 *
 * To run a mutation, you first call `usePublishFutureAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishFutureAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishFutureAnnouncementMutation, { data, loading, error }] = usePublishFutureAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      publishZ: // value for 'publishZ'
 *   },
 * });
 */
export function usePublishFutureAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishFutureAnnouncementMutation,
    PublishFutureAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishFutureAnnouncementMutation,
    PublishFutureAnnouncementMutationVariables
  >(PublishFutureAnnouncementDocument, options);
}
export type PublishFutureAnnouncementMutationHookResult = ReturnType<
  typeof usePublishFutureAnnouncementMutation
>;
export type PublishFutureAnnouncementMutationResult =
  Apollo.MutationResult<PublishFutureAnnouncementMutation>;
export type PublishFutureAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  PublishFutureAnnouncementMutation,
  PublishFutureAnnouncementMutationVariables
>;
export const PublishAnnouncementDocument = gql`
  mutation PublishAnnouncement($id: ID!) {
    publishAnnouncement(id: $id) {
      success
      error
      announcement {
        ...AnnouncementsFields
      }
    }
  }
  ${AnnouncementsFieldsFragmentDoc}
`;
export type PublishAnnouncementMutationFn = Apollo.MutationFunction<
  PublishAnnouncementMutation,
  PublishAnnouncementMutationVariables
>;

/**
 * __usePublishAnnouncementMutation__
 *
 * To run a mutation, you first call `usePublishAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishAnnouncementMutation, { data, loading, error }] = usePublishAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishAnnouncementMutation,
    PublishAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PublishAnnouncementMutation, PublishAnnouncementMutationVariables>(
    PublishAnnouncementDocument,
    options
  );
}
export type PublishAnnouncementMutationHookResult = ReturnType<
  typeof usePublishAnnouncementMutation
>;
export type PublishAnnouncementMutationResult = Apollo.MutationResult<PublishAnnouncementMutation>;
export type PublishAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  PublishAnnouncementMutation,
  PublishAnnouncementMutationVariables
>;
export const CancelPublishAnnouncementDocument = gql`
  mutation CancelPublishAnnouncement($id: ID!) {
    cancelPublishAnnouncement(id: $id) {
      success
      error
      announcement {
        ...AnnouncementsFields
      }
    }
  }
  ${AnnouncementsFieldsFragmentDoc}
`;
export type CancelPublishAnnouncementMutationFn = Apollo.MutationFunction<
  CancelPublishAnnouncementMutation,
  CancelPublishAnnouncementMutationVariables
>;

/**
 * __useCancelPublishAnnouncementMutation__
 *
 * To run a mutation, you first call `useCancelPublishAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPublishAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPublishAnnouncementMutation, { data, loading, error }] = useCancelPublishAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelPublishAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelPublishAnnouncementMutation,
    CancelPublishAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelPublishAnnouncementMutation,
    CancelPublishAnnouncementMutationVariables
  >(CancelPublishAnnouncementDocument, options);
}
export type CancelPublishAnnouncementMutationHookResult = ReturnType<
  typeof useCancelPublishAnnouncementMutation
>;
export type CancelPublishAnnouncementMutationResult =
  Apollo.MutationResult<CancelPublishAnnouncementMutation>;
export type CancelPublishAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  CancelPublishAnnouncementMutation,
  CancelPublishAnnouncementMutationVariables
>;
export const TestVoiceAnnouncementDocument = gql`
  mutation TestVoiceAnnouncement($input: TestVoiceAnnouncementInput!) {
    testVoiceAnnouncement(input: $input) {
      success
      error
    }
  }
`;
export type TestVoiceAnnouncementMutationFn = Apollo.MutationFunction<
  TestVoiceAnnouncementMutation,
  TestVoiceAnnouncementMutationVariables
>;

/**
 * __useTestVoiceAnnouncementMutation__
 *
 * To run a mutation, you first call `useTestVoiceAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestVoiceAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testVoiceAnnouncementMutation, { data, loading, error }] = useTestVoiceAnnouncementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestVoiceAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TestVoiceAnnouncementMutation,
    TestVoiceAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TestVoiceAnnouncementMutation, TestVoiceAnnouncementMutationVariables>(
    TestVoiceAnnouncementDocument,
    options
  );
}
export type TestVoiceAnnouncementMutationHookResult = ReturnType<
  typeof useTestVoiceAnnouncementMutation
>;
export type TestVoiceAnnouncementMutationResult =
  Apollo.MutationResult<TestVoiceAnnouncementMutation>;
export type TestVoiceAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  TestVoiceAnnouncementMutation,
  TestVoiceAnnouncementMutationVariables
>;
export const AddAnnouncementReplyDocument = gql`
  mutation AddAnnouncementReply($input: AddAnnouncementReplyInput!) {
    addAnnouncementReply(input: $input) {
      success
      error
      announcement {
        ...AnnouncementsFields
      }
    }
  }
  ${AnnouncementsFieldsFragmentDoc}
`;
export type AddAnnouncementReplyMutationFn = Apollo.MutationFunction<
  AddAnnouncementReplyMutation,
  AddAnnouncementReplyMutationVariables
>;

/**
 * __useAddAnnouncementReplyMutation__
 *
 * To run a mutation, you first call `useAddAnnouncementReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnnouncementReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnnouncementReplyMutation, { data, loading, error }] = useAddAnnouncementReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAnnouncementReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAnnouncementReplyMutation,
    AddAnnouncementReplyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAnnouncementReplyMutation, AddAnnouncementReplyMutationVariables>(
    AddAnnouncementReplyDocument,
    options
  );
}
export type AddAnnouncementReplyMutationHookResult = ReturnType<
  typeof useAddAnnouncementReplyMutation
>;
export type AddAnnouncementReplyMutationResult =
  Apollo.MutationResult<AddAnnouncementReplyMutation>;
export type AddAnnouncementReplyMutationOptions = Apollo.BaseMutationOptions<
  AddAnnouncementReplyMutation,
  AddAnnouncementReplyMutationVariables
>;
export const AccountAutopayOnboardingUrlDocument = gql`
  query AccountAutopayOnboardingUrl {
    account {
      id
      books {
        accountId
        autopay {
          accountId
          activationUrl
          activationStatus
        }
      }
    }
  }
`;

/**
 * __useAccountAutopayOnboardingUrlQuery__
 *
 * To run a query within a React component, call `useAccountAutopayOnboardingUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAutopayOnboardingUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAutopayOnboardingUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountAutopayOnboardingUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountAutopayOnboardingUrlQuery,
    AccountAutopayOnboardingUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountAutopayOnboardingUrlQuery,
    AccountAutopayOnboardingUrlQueryVariables
  >(AccountAutopayOnboardingUrlDocument, options);
}
export function useAccountAutopayOnboardingUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountAutopayOnboardingUrlQuery,
    AccountAutopayOnboardingUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountAutopayOnboardingUrlQuery,
    AccountAutopayOnboardingUrlQueryVariables
  >(AccountAutopayOnboardingUrlDocument, options);
}
export type AccountAutopayOnboardingUrlQueryHookResult = ReturnType<
  typeof useAccountAutopayOnboardingUrlQuery
>;
export type AccountAutopayOnboardingUrlLazyQueryHookResult = ReturnType<
  typeof useAccountAutopayOnboardingUrlLazyQuery
>;
export type AccountAutopayOnboardingUrlQueryResult = Apollo.QueryResult<
  AccountAutopayOnboardingUrlQuery,
  AccountAutopayOnboardingUrlQueryVariables
>;
export const StartAccountAutopayDocument = gql`
  mutation StartAccountAutopay($input: StartAccountAutopayInput!) {
    startAccountAutopay(input: $input) {
      success
      error
      account {
        id
        books {
          accountId
          autopay {
            accountId
            activated
            activationStatus
            enableCollection
            enablePayments
            invitePayees
            nsfFee
            feeExpense
          }
        }
      }
    }
  }
`;
export type StartAccountAutopayMutationFn = Apollo.MutationFunction<
  StartAccountAutopayMutation,
  StartAccountAutopayMutationVariables
>;

/**
 * __useStartAccountAutopayMutation__
 *
 * To run a mutation, you first call `useStartAccountAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAccountAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAccountAutopayMutation, { data, loading, error }] = useStartAccountAutopayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartAccountAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartAccountAutopayMutation,
    StartAccountAutopayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartAccountAutopayMutation, StartAccountAutopayMutationVariables>(
    StartAccountAutopayDocument,
    options
  );
}
export type StartAccountAutopayMutationHookResult = ReturnType<
  typeof useStartAccountAutopayMutation
>;
export type StartAccountAutopayMutationResult = Apollo.MutationResult<StartAccountAutopayMutation>;
export type StartAccountAutopayMutationOptions = Apollo.BaseMutationOptions<
  StartAccountAutopayMutation,
  StartAccountAutopayMutationVariables
>;
export const GetServerTenantAutopayPayeesDocument = gql`
  query GetServerTenantAutopayPayees(
    $first: Int
    $after: ID
    $filter: AutopayPayeeFilter
    $sort: [AutopayPayeeSortInput!]
  ) {
    account {
      id
      books {
        accountId
        autopay {
          accountId
          pageTenantAutopayPayees(first: $first, after: $after, filter: $filter, sort: $sort) {
            totalCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                name
                email
                payeeType
                autopayStatus
                autopayStatusZ
                autopayCollectionsEnabled
                autopayCollectionsEnabledZ
                propertyUnits {
                  unitId
                  unitName
                  buildingName
                  propertyId
                  propertyName
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetServerTenantAutopayPayeesQuery__
 *
 * To run a query within a React component, call `useGetServerTenantAutopayPayeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServerTenantAutopayPayeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServerTenantAutopayPayeesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetServerTenantAutopayPayeesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetServerTenantAutopayPayeesQuery,
    GetServerTenantAutopayPayeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServerTenantAutopayPayeesQuery,
    GetServerTenantAutopayPayeesQueryVariables
  >(GetServerTenantAutopayPayeesDocument, options);
}
export function useGetServerTenantAutopayPayeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServerTenantAutopayPayeesQuery,
    GetServerTenantAutopayPayeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServerTenantAutopayPayeesQuery,
    GetServerTenantAutopayPayeesQueryVariables
  >(GetServerTenantAutopayPayeesDocument, options);
}
export type GetServerTenantAutopayPayeesQueryHookResult = ReturnType<
  typeof useGetServerTenantAutopayPayeesQuery
>;
export type GetServerTenantAutopayPayeesLazyQueryHookResult = ReturnType<
  typeof useGetServerTenantAutopayPayeesLazyQuery
>;
export type GetServerTenantAutopayPayeesQueryResult = Apollo.QueryResult<
  GetServerTenantAutopayPayeesQuery,
  GetServerTenantAutopayPayeesQueryVariables
>;
export const GetServerOwnerAutopayPayeesDocument = gql`
  query GetServerOwnerAutopayPayees(
    $first: Int
    $after: ID
    $filter: AutopayPayeeFilter
    $sort: [AutopayPayeeSortInput!]
  ) {
    account {
      id
      books {
        accountId
        autopay {
          accountId
          pageOwnerAutopayPayees(first: $first, after: $after, filter: $filter, sort: $sort) {
            totalCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                name
                email
                payeeType
                autopayStatus
                autopayStatusZ
                autopayCollectionsEnabled
                autopayCollectionsEnabledZ
                propertyUnits {
                  unitId
                  unitName
                  buildingName
                  propertyId
                  propertyName
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetServerOwnerAutopayPayeesQuery__
 *
 * To run a query within a React component, call `useGetServerOwnerAutopayPayeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServerOwnerAutopayPayeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServerOwnerAutopayPayeesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetServerOwnerAutopayPayeesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetServerOwnerAutopayPayeesQuery,
    GetServerOwnerAutopayPayeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServerOwnerAutopayPayeesQuery,
    GetServerOwnerAutopayPayeesQueryVariables
  >(GetServerOwnerAutopayPayeesDocument, options);
}
export function useGetServerOwnerAutopayPayeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServerOwnerAutopayPayeesQuery,
    GetServerOwnerAutopayPayeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServerOwnerAutopayPayeesQuery,
    GetServerOwnerAutopayPayeesQueryVariables
  >(GetServerOwnerAutopayPayeesDocument, options);
}
export type GetServerOwnerAutopayPayeesQueryHookResult = ReturnType<
  typeof useGetServerOwnerAutopayPayeesQuery
>;
export type GetServerOwnerAutopayPayeesLazyQueryHookResult = ReturnType<
  typeof useGetServerOwnerAutopayPayeesLazyQuery
>;
export type GetServerOwnerAutopayPayeesQueryResult = Apollo.QueryResult<
  GetServerOwnerAutopayPayeesQuery,
  GetServerOwnerAutopayPayeesQueryVariables
>;
export const AutopayPropertiesDocument = gql`
  query AutopayProperties {
    account {
      id
      pageProperties(first: 1000) {
        edges {
          node {
            id
            key
            name
            autopayCollectionsEnabled
            leasedUnits
            ownerUnits
            commercialLeasedUnits
            autopayActivatedZ
            autopayCollectionsEnabledZ
          }
        }
      }
    }
  }
`;

/**
 * __useAutopayPropertiesQuery__
 *
 * To run a query within a React component, call `useAutopayPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutopayPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutopayPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutopayPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<AutopayPropertiesQuery, AutopayPropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AutopayPropertiesQuery, AutopayPropertiesQueryVariables>(
    AutopayPropertiesDocument,
    options
  );
}
export function useAutopayPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AutopayPropertiesQuery, AutopayPropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AutopayPropertiesQuery, AutopayPropertiesQueryVariables>(
    AutopayPropertiesDocument,
    options
  );
}
export type AutopayPropertiesQueryHookResult = ReturnType<typeof useAutopayPropertiesQuery>;
export type AutopayPropertiesLazyQueryHookResult = ReturnType<typeof useAutopayPropertiesLazyQuery>;
export type AutopayPropertiesQueryResult = Apollo.QueryResult<
  AutopayPropertiesQuery,
  AutopayPropertiesQueryVariables
>;
export const AutopayPropertyConfigDocument = gql`
  query AutopayPropertyConfig {
    account {
      id
      autopayClientId
      books {
        accountId
        glAccountMapping {
          id
          parentId
          glId
        }
        reconciliationConfigs {
          id
          name
          bankId
          scope {
            glId
            propertyOwnerIds
          }
        }
        autopay {
          accountId
          activatedZ
          banks {
            id
            accountNumber
            name
          }
        }
      }
    }
  }
`;

/**
 * __useAutopayPropertyConfigQuery__
 *
 * To run a query within a React component, call `useAutopayPropertyConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutopayPropertyConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutopayPropertyConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutopayPropertyConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AutopayPropertyConfigQuery,
    AutopayPropertyConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AutopayPropertyConfigQuery, AutopayPropertyConfigQueryVariables>(
    AutopayPropertyConfigDocument,
    options
  );
}
export function useAutopayPropertyConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutopayPropertyConfigQuery,
    AutopayPropertyConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AutopayPropertyConfigQuery, AutopayPropertyConfigQueryVariables>(
    AutopayPropertyConfigDocument,
    options
  );
}
export type AutopayPropertyConfigQueryHookResult = ReturnType<typeof useAutopayPropertyConfigQuery>;
export type AutopayPropertyConfigLazyQueryHookResult = ReturnType<
  typeof useAutopayPropertyConfigLazyQuery
>;
export type AutopayPropertyConfigQueryResult = Apollo.QueryResult<
  AutopayPropertyConfigQuery,
  AutopayPropertyConfigQueryVariables
>;
export const SetPropertyAutopayCollectionsEnabledDocument = gql`
  mutation SetPropertyAutopayCollectionsEnabled(
    $input: SetPropertyAutopayCollectionsEnabledInput!
  ) {
    setPropertyAutopayCollectionsEnabled(input: $input) {
      success
      error
      property {
        id
        autopayCollectionsEnabled
        autopayCollectionsEnabledZ
      }
    }
  }
`;
export type SetPropertyAutopayCollectionsEnabledMutationFn = Apollo.MutationFunction<
  SetPropertyAutopayCollectionsEnabledMutation,
  SetPropertyAutopayCollectionsEnabledMutationVariables
>;

/**
 * __useSetPropertyAutopayCollectionsEnabledMutation__
 *
 * To run a mutation, you first call `useSetPropertyAutopayCollectionsEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPropertyAutopayCollectionsEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPropertyAutopayCollectionsEnabledMutation, { data, loading, error }] = useSetPropertyAutopayCollectionsEnabledMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPropertyAutopayCollectionsEnabledMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPropertyAutopayCollectionsEnabledMutation,
    SetPropertyAutopayCollectionsEnabledMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetPropertyAutopayCollectionsEnabledMutation,
    SetPropertyAutopayCollectionsEnabledMutationVariables
  >(SetPropertyAutopayCollectionsEnabledDocument, options);
}
export type SetPropertyAutopayCollectionsEnabledMutationHookResult = ReturnType<
  typeof useSetPropertyAutopayCollectionsEnabledMutation
>;
export type SetPropertyAutopayCollectionsEnabledMutationResult =
  Apollo.MutationResult<SetPropertyAutopayCollectionsEnabledMutation>;
export type SetPropertyAutopayCollectionsEnabledMutationOptions = Apollo.BaseMutationOptions<
  SetPropertyAutopayCollectionsEnabledMutation,
  SetPropertyAutopayCollectionsEnabledMutationVariables
>;
export const ListAutopayStatementsDocument = gql`
  query ListAutopayStatements($nextToken: String) {
    account {
      id
      listAutopaySummaries(nextToken: $nextToken) {
        items {
          id
          accountId
          autopayId
          bankId
          collectionPosted
          fileNumber
          paymentAmount
          paymentCount
          settledZ
          status
          key
          bankId
          billingName
          items
          total
          returnedItems
          returnedTotal
        }
        nextToken
      }
    }
  }
`;

/**
 * __useListAutopayStatementsQuery__
 *
 * To run a query within a React component, call `useListAutopayStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAutopayStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAutopayStatementsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListAutopayStatementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAutopayStatementsQuery,
    ListAutopayStatementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAutopayStatementsQuery, ListAutopayStatementsQueryVariables>(
    ListAutopayStatementsDocument,
    options
  );
}
export function useListAutopayStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAutopayStatementsQuery,
    ListAutopayStatementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListAutopayStatementsQuery, ListAutopayStatementsQueryVariables>(
    ListAutopayStatementsDocument,
    options
  );
}
export type ListAutopayStatementsQueryHookResult = ReturnType<typeof useListAutopayStatementsQuery>;
export type ListAutopayStatementsLazyQueryHookResult = ReturnType<
  typeof useListAutopayStatementsLazyQuery
>;
export type ListAutopayStatementsQueryResult = Apollo.QueryResult<
  ListAutopayStatementsQuery,
  ListAutopayStatementsQueryVariables
>;
export const ActivateAutopayDocument = gql`
  mutation ActivateAutopay($input: ActivateAutopayInput!) {
    activateAutopay(input: $input) {
      success
      error
      books {
        accountId
        autopay {
          ...AutopayFields
        }
      }
    }
  }
  ${AutopayFieldsFragmentDoc}
`;
export type ActivateAutopayMutationFn = Apollo.MutationFunction<
  ActivateAutopayMutation,
  ActivateAutopayMutationVariables
>;

/**
 * __useActivateAutopayMutation__
 *
 * To run a mutation, you first call `useActivateAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAutopayMutation, { data, loading, error }] = useActivateAutopayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateAutopayMutation,
    ActivateAutopayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateAutopayMutation, ActivateAutopayMutationVariables>(
    ActivateAutopayDocument,
    options
  );
}
export type ActivateAutopayMutationHookResult = ReturnType<typeof useActivateAutopayMutation>;
export type ActivateAutopayMutationResult = Apollo.MutationResult<ActivateAutopayMutation>;
export type ActivateAutopayMutationOptions = Apollo.BaseMutationOptions<
  ActivateAutopayMutation,
  ActivateAutopayMutationVariables
>;
export const DeactivateAutopayDocument = gql`
  mutation DeactivateAutopay {
    deactivateAutopay {
      success
      error
      books {
        accountId
        autopay {
          ...AutopayFields
        }
      }
    }
  }
  ${AutopayFieldsFragmentDoc}
`;
export type DeactivateAutopayMutationFn = Apollo.MutationFunction<
  DeactivateAutopayMutation,
  DeactivateAutopayMutationVariables
>;

/**
 * __useDeactivateAutopayMutation__
 *
 * To run a mutation, you first call `useDeactivateAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateAutopayMutation, { data, loading, error }] = useDeactivateAutopayMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeactivateAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateAutopayMutation,
    DeactivateAutopayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeactivateAutopayMutation, DeactivateAutopayMutationVariables>(
    DeactivateAutopayDocument,
    options
  );
}
export type DeactivateAutopayMutationHookResult = ReturnType<typeof useDeactivateAutopayMutation>;
export type DeactivateAutopayMutationResult = Apollo.MutationResult<DeactivateAutopayMutation>;
export type DeactivateAutopayMutationOptions = Apollo.BaseMutationOptions<
  DeactivateAutopayMutation,
  DeactivateAutopayMutationVariables
>;
export const ActivatePropertiesAutopayDocument = gql`
  mutation ActivatePropertiesAutopay($input: AutopayPropertiesInput!) {
    activatePropertiesAutopay(input: $input) {
      success
      error
      books {
        accountId
        autopay {
          ...AutopayFields
        }
      }
    }
  }
  ${AutopayFieldsFragmentDoc}
`;
export type ActivatePropertiesAutopayMutationFn = Apollo.MutationFunction<
  ActivatePropertiesAutopayMutation,
  ActivatePropertiesAutopayMutationVariables
>;

/**
 * __useActivatePropertiesAutopayMutation__
 *
 * To run a mutation, you first call `useActivatePropertiesAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivatePropertiesAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activatePropertiesAutopayMutation, { data, loading, error }] = useActivatePropertiesAutopayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivatePropertiesAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivatePropertiesAutopayMutation,
    ActivatePropertiesAutopayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivatePropertiesAutopayMutation,
    ActivatePropertiesAutopayMutationVariables
  >(ActivatePropertiesAutopayDocument, options);
}
export type ActivatePropertiesAutopayMutationHookResult = ReturnType<
  typeof useActivatePropertiesAutopayMutation
>;
export type ActivatePropertiesAutopayMutationResult =
  Apollo.MutationResult<ActivatePropertiesAutopayMutation>;
export type ActivatePropertiesAutopayMutationOptions = Apollo.BaseMutationOptions<
  ActivatePropertiesAutopayMutation,
  ActivatePropertiesAutopayMutationVariables
>;
export const DeactivatePropertiesAutopayDocument = gql`
  mutation DeactivatePropertiesAutopay($input: AutopayPropertiesInput!) {
    deactivatePropertiesAutopay(input: $input) {
      success
      error
      books {
        accountId
        autopay {
          ...AutopayFields
        }
      }
    }
  }
  ${AutopayFieldsFragmentDoc}
`;
export type DeactivatePropertiesAutopayMutationFn = Apollo.MutationFunction<
  DeactivatePropertiesAutopayMutation,
  DeactivatePropertiesAutopayMutationVariables
>;

/**
 * __useDeactivatePropertiesAutopayMutation__
 *
 * To run a mutation, you first call `useDeactivatePropertiesAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivatePropertiesAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivatePropertiesAutopayMutation, { data, loading, error }] = useDeactivatePropertiesAutopayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivatePropertiesAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivatePropertiesAutopayMutation,
    DeactivatePropertiesAutopayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivatePropertiesAutopayMutation,
    DeactivatePropertiesAutopayMutationVariables
  >(DeactivatePropertiesAutopayDocument, options);
}
export type DeactivatePropertiesAutopayMutationHookResult = ReturnType<
  typeof useDeactivatePropertiesAutopayMutation
>;
export type DeactivatePropertiesAutopayMutationResult =
  Apollo.MutationResult<DeactivatePropertiesAutopayMutation>;
export type DeactivatePropertiesAutopayMutationOptions = Apollo.BaseMutationOptions<
  DeactivatePropertiesAutopayMutation,
  DeactivatePropertiesAutopayMutationVariables
>;
export const ListAutopayPropertiesDocument = gql`
  query ListAutopayProperties($nextToken: String) {
    account {
      id
      listAutopayProperties(nextToken: $nextToken) {
        items @connection(key: "id") {
          ...AutopayPropertyFields
        }
        nextToken
      }
    }
  }
  ${AutopayPropertyFieldsFragmentDoc}
`;

/**
 * __useListAutopayPropertiesQuery__
 *
 * To run a query within a React component, call `useListAutopayPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAutopayPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAutopayPropertiesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListAutopayPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAutopayPropertiesQuery,
    ListAutopayPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAutopayPropertiesQuery, ListAutopayPropertiesQueryVariables>(
    ListAutopayPropertiesDocument,
    options
  );
}
export function useListAutopayPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAutopayPropertiesQuery,
    ListAutopayPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListAutopayPropertiesQuery, ListAutopayPropertiesQueryVariables>(
    ListAutopayPropertiesDocument,
    options
  );
}
export type ListAutopayPropertiesQueryHookResult = ReturnType<typeof useListAutopayPropertiesQuery>;
export type ListAutopayPropertiesLazyQueryHookResult = ReturnType<
  typeof useListAutopayPropertiesLazyQuery
>;
export type ListAutopayPropertiesQueryResult = Apollo.QueryResult<
  ListAutopayPropertiesQuery,
  ListAutopayPropertiesQueryVariables
>;
export const GetAutopayEnrollmentsDocument = gql`
  query GetAutopayEnrollments($nextToken: String, $filter: AutopayEnrollmentFilterInput) {
    account {
      id
      books {
        accountId
        autopay {
          ...AutopayFields
        }
      }
      listAutopayEnrollments(nextToken: $nextToken, filter: $filter) {
        nextToken
        items {
          ...AutopayEnrollmentFields
        }
      }
    }
  }
  ${AutopayFieldsFragmentDoc}
  ${AutopayEnrollmentFieldsFragmentDoc}
`;

/**
 * __useGetAutopayEnrollmentsQuery__
 *
 * To run a query within a React component, call `useGetAutopayEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutopayEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutopayEnrollmentsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAutopayEnrollmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAutopayEnrollmentsQuery,
    GetAutopayEnrollmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAutopayEnrollmentsQuery, GetAutopayEnrollmentsQueryVariables>(
    GetAutopayEnrollmentsDocument,
    options
  );
}
export function useGetAutopayEnrollmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAutopayEnrollmentsQuery,
    GetAutopayEnrollmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAutopayEnrollmentsQuery, GetAutopayEnrollmentsQueryVariables>(
    GetAutopayEnrollmentsDocument,
    options
  );
}
export type GetAutopayEnrollmentsQueryHookResult = ReturnType<typeof useGetAutopayEnrollmentsQuery>;
export type GetAutopayEnrollmentsLazyQueryHookResult = ReturnType<
  typeof useGetAutopayEnrollmentsLazyQuery
>;
export type GetAutopayEnrollmentsQueryResult = Apollo.QueryResult<
  GetAutopayEnrollmentsQuery,
  GetAutopayEnrollmentsQueryVariables
>;
export const InvitePayeeDocument = gql`
  mutation InvitePayee($input: InvitePayeeToEnrollInput!) {
    invitePayeeToEnroll(input: $input) {
      success
      error
      enrollment {
        ...AutopayEnrollmentFields
      }
    }
  }
  ${AutopayEnrollmentFieldsFragmentDoc}
`;
export type InvitePayeeMutationFn = Apollo.MutationFunction<
  InvitePayeeMutation,
  InvitePayeeMutationVariables
>;

/**
 * __useInvitePayeeMutation__
 *
 * To run a mutation, you first call `useInvitePayeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvitePayeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invitePayeeMutation, { data, loading, error }] = useInvitePayeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvitePayeeMutation(
  baseOptions?: Apollo.MutationHookOptions<InvitePayeeMutation, InvitePayeeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvitePayeeMutation, InvitePayeeMutationVariables>(
    InvitePayeeDocument,
    options
  );
}
export type InvitePayeeMutationHookResult = ReturnType<typeof useInvitePayeeMutation>;
export type InvitePayeeMutationResult = Apollo.MutationResult<InvitePayeeMutation>;
export type InvitePayeeMutationOptions = Apollo.BaseMutationOptions<
  InvitePayeeMutation,
  InvitePayeeMutationVariables
>;
export const ResendInviteToTenantDocument = gql`
  mutation ResendInviteToTenant($input: ResendAutopayInviteInput!) {
    resendAutopayInviteToTenant(input: $input) {
      success
      error
    }
  }
`;
export type ResendInviteToTenantMutationFn = Apollo.MutationFunction<
  ResendInviteToTenantMutation,
  ResendInviteToTenantMutationVariables
>;

/**
 * __useResendInviteToTenantMutation__
 *
 * To run a mutation, you first call `useResendInviteToTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteToTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteToTenantMutation, { data, loading, error }] = useResendInviteToTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendInviteToTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendInviteToTenantMutation,
    ResendInviteToTenantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendInviteToTenantMutation, ResendInviteToTenantMutationVariables>(
    ResendInviteToTenantDocument,
    options
  );
}
export type ResendInviteToTenantMutationHookResult = ReturnType<
  typeof useResendInviteToTenantMutation
>;
export type ResendInviteToTenantMutationResult =
  Apollo.MutationResult<ResendInviteToTenantMutation>;
export type ResendInviteToTenantMutationOptions = Apollo.BaseMutationOptions<
  ResendInviteToTenantMutation,
  ResendInviteToTenantMutationVariables
>;
export const ResendInviteToOwnerDocument = gql`
  mutation ResendInviteToOwner($input: ResendAutopayInviteInput!) {
    resendAutopayInviteToOwner(input: $input) {
      success
      error
    }
  }
`;
export type ResendInviteToOwnerMutationFn = Apollo.MutationFunction<
  ResendInviteToOwnerMutation,
  ResendInviteToOwnerMutationVariables
>;

/**
 * __useResendInviteToOwnerMutation__
 *
 * To run a mutation, you first call `useResendInviteToOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteToOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteToOwnerMutation, { data, loading, error }] = useResendInviteToOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendInviteToOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendInviteToOwnerMutation,
    ResendInviteToOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendInviteToOwnerMutation, ResendInviteToOwnerMutationVariables>(
    ResendInviteToOwnerDocument,
    options
  );
}
export type ResendInviteToOwnerMutationHookResult = ReturnType<
  typeof useResendInviteToOwnerMutation
>;
export type ResendInviteToOwnerMutationResult = Apollo.MutationResult<ResendInviteToOwnerMutation>;
export type ResendInviteToOwnerMutationOptions = Apollo.BaseMutationOptions<
  ResendInviteToOwnerMutation,
  ResendInviteToOwnerMutationVariables
>;
export const UpdateEnrollmentToInvitedDocument = gql`
  mutation UpdateEnrollmentToInvited($input: UpdateAutopayEnrollmentInput!) {
    updateAutopayEnrollmentToInvited(input: $input) {
      success
      error
      enrollment {
        ...AutopayEnrollmentFields
      }
    }
  }
  ${AutopayEnrollmentFieldsFragmentDoc}
`;
export type UpdateEnrollmentToInvitedMutationFn = Apollo.MutationFunction<
  UpdateEnrollmentToInvitedMutation,
  UpdateEnrollmentToInvitedMutationVariables
>;

/**
 * __useUpdateEnrollmentToInvitedMutation__
 *
 * To run a mutation, you first call `useUpdateEnrollmentToInvitedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnrollmentToInvitedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnrollmentToInvitedMutation, { data, loading, error }] = useUpdateEnrollmentToInvitedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnrollmentToInvitedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEnrollmentToInvitedMutation,
    UpdateEnrollmentToInvitedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEnrollmentToInvitedMutation,
    UpdateEnrollmentToInvitedMutationVariables
  >(UpdateEnrollmentToInvitedDocument, options);
}
export type UpdateEnrollmentToInvitedMutationHookResult = ReturnType<
  typeof useUpdateEnrollmentToInvitedMutation
>;
export type UpdateEnrollmentToInvitedMutationResult =
  Apollo.MutationResult<UpdateEnrollmentToInvitedMutation>;
export type UpdateEnrollmentToInvitedMutationOptions = Apollo.BaseMutationOptions<
  UpdateEnrollmentToInvitedMutation,
  UpdateEnrollmentToInvitedMutationVariables
>;
export const UpdateEnrollmentToDeclinedDocument = gql`
  mutation UpdateEnrollmentToDeclined($input: UpdateAutopayEnrollmentInput!) {
    updateAutopayEnrollmentToDeclined(input: $input) {
      success
      error
      enrollment {
        ...AutopayEnrollmentFields
      }
    }
  }
  ${AutopayEnrollmentFieldsFragmentDoc}
`;
export type UpdateEnrollmentToDeclinedMutationFn = Apollo.MutationFunction<
  UpdateEnrollmentToDeclinedMutation,
  UpdateEnrollmentToDeclinedMutationVariables
>;

/**
 * __useUpdateEnrollmentToDeclinedMutation__
 *
 * To run a mutation, you first call `useUpdateEnrollmentToDeclinedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnrollmentToDeclinedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnrollmentToDeclinedMutation, { data, loading, error }] = useUpdateEnrollmentToDeclinedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnrollmentToDeclinedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEnrollmentToDeclinedMutation,
    UpdateEnrollmentToDeclinedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEnrollmentToDeclinedMutation,
    UpdateEnrollmentToDeclinedMutationVariables
  >(UpdateEnrollmentToDeclinedDocument, options);
}
export type UpdateEnrollmentToDeclinedMutationHookResult = ReturnType<
  typeof useUpdateEnrollmentToDeclinedMutation
>;
export type UpdateEnrollmentToDeclinedMutationResult =
  Apollo.MutationResult<UpdateEnrollmentToDeclinedMutation>;
export type UpdateEnrollmentToDeclinedMutationOptions = Apollo.BaseMutationOptions<
  UpdateEnrollmentToDeclinedMutation,
  UpdateEnrollmentToDeclinedMutationVariables
>;
export const GetAutopayDocument = gql`
  query GetAutopay {
    account {
      id
      name
      books {
        accountId
        autopay {
          ...AutopayFields
        }
      }
    }
  }
  ${AutopayFieldsFragmentDoc}
`;

/**
 * __useGetAutopayQuery__
 *
 * To run a query within a React component, call `useGetAutopayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutopayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutopayQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAutopayQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAutopayQuery, GetAutopayQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAutopayQuery, GetAutopayQueryVariables>(GetAutopayDocument, options);
}
export function useGetAutopayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAutopayQuery, GetAutopayQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAutopayQuery, GetAutopayQueryVariables>(
    GetAutopayDocument,
    options
  );
}
export type GetAutopayQueryHookResult = ReturnType<typeof useGetAutopayQuery>;
export type GetAutopayLazyQueryHookResult = ReturnType<typeof useGetAutopayLazyQuery>;
export type GetAutopayQueryResult = Apollo.QueryResult<GetAutopayQuery, GetAutopayQueryVariables>;
export const UpdateApplicationDocument = gql`
  mutation UpdateApplication($input: UpdateApplicationInput!) {
    updateApplication(input: $input) {
      success
      error
      application {
        ...ApplicationFields
      }
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;
export type UpdateApplicationMutationFn = Apollo.MutationFunction<
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables
>;

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMutation, { data, loading, error }] = useUpdateApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateApplicationMutation, UpdateApplicationMutationVariables>(
    UpdateApplicationDocument,
    options
  );
}
export type UpdateApplicationMutationHookResult = ReturnType<typeof useUpdateApplicationMutation>;
export type UpdateApplicationMutationResult = Apollo.MutationResult<UpdateApplicationMutation>;
export type UpdateApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables
>;
export const GetTenantByProfileIdDocument = gql`
  query GetTenantByProfileId($profileId: ID!) {
    tenantByProfileId(profileId: $profileId) {
      id
      isOwner
    }
  }
`;

/**
 * __useGetTenantByProfileIdQuery__
 *
 * To run a query within a React component, call `useGetTenantByProfileIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantByProfileIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantByProfileIdQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetTenantByProfileIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenantByProfileIdQuery,
    GetTenantByProfileIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantByProfileIdQuery, GetTenantByProfileIdQueryVariables>(
    GetTenantByProfileIdDocument,
    options
  );
}
export function useGetTenantByProfileIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenantByProfileIdQuery,
    GetTenantByProfileIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantByProfileIdQuery, GetTenantByProfileIdQueryVariables>(
    GetTenantByProfileIdDocument,
    options
  );
}
export type GetTenantByProfileIdQueryHookResult = ReturnType<typeof useGetTenantByProfileIdQuery>;
export type GetTenantByProfileIdLazyQueryHookResult = ReturnType<
  typeof useGetTenantByProfileIdLazyQuery
>;
export type GetTenantByProfileIdQueryResult = Apollo.QueryResult<
  GetTenantByProfileIdQuery,
  GetTenantByProfileIdQueryVariables
>;
export const SaveLeaseAgreementDocument = gql`
  mutation SaveLeaseAgreement($input: CreateLeaseAgreementInput!) {
    saveLeaseAgreement(input: $input) {
      success
      error
      application {
        ...ApplicationFields
      }
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;
export type SaveLeaseAgreementMutationFn = Apollo.MutationFunction<
  SaveLeaseAgreementMutation,
  SaveLeaseAgreementMutationVariables
>;

/**
 * __useSaveLeaseAgreementMutation__
 *
 * To run a mutation, you first call `useSaveLeaseAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLeaseAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLeaseAgreementMutation, { data, loading, error }] = useSaveLeaseAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveLeaseAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveLeaseAgreementMutation,
    SaveLeaseAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveLeaseAgreementMutation, SaveLeaseAgreementMutationVariables>(
    SaveLeaseAgreementDocument,
    options
  );
}
export type SaveLeaseAgreementMutationHookResult = ReturnType<typeof useSaveLeaseAgreementMutation>;
export type SaveLeaseAgreementMutationResult = Apollo.MutationResult<SaveLeaseAgreementMutation>;
export type SaveLeaseAgreementMutationOptions = Apollo.BaseMutationOptions<
  SaveLeaseAgreementMutation,
  SaveLeaseAgreementMutationVariables
>;
export const CreateLeaseAgreementUrlDocument = gql`
  mutation CreateLeaseAgreementUrl($input: LeaseAgreementUrlInput!) {
    createLeaseAgreementUrl(input: $input) {
      error
      success
      agreementUrls {
        agreementUrl {
          accountFileUrl
          accountFileName
          profileFileUrl
          profileFileName
          message
        }
        applicationId
      }
    }
  }
`;
export type CreateLeaseAgreementUrlMutationFn = Apollo.MutationFunction<
  CreateLeaseAgreementUrlMutation,
  CreateLeaseAgreementUrlMutationVariables
>;

/**
 * __useCreateLeaseAgreementUrlMutation__
 *
 * To run a mutation, you first call `useCreateLeaseAgreementUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeaseAgreementUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeaseAgreementUrlMutation, { data, loading, error }] = useCreateLeaseAgreementUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLeaseAgreementUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLeaseAgreementUrlMutation,
    CreateLeaseAgreementUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLeaseAgreementUrlMutation,
    CreateLeaseAgreementUrlMutationVariables
  >(CreateLeaseAgreementUrlDocument, options);
}
export type CreateLeaseAgreementUrlMutationHookResult = ReturnType<
  typeof useCreateLeaseAgreementUrlMutation
>;
export type CreateLeaseAgreementUrlMutationResult =
  Apollo.MutationResult<CreateLeaseAgreementUrlMutation>;
export type CreateLeaseAgreementUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateLeaseAgreementUrlMutation,
  CreateLeaseAgreementUrlMutationVariables
>;
export const SignLeaseAgreementDocument = gql`
  mutation SignLeaseAgreement($input: SignLeaseAgreementInput!) {
    signLeaseAgreement(input: $input) {
      success
      error
      application {
        id
        leaseAgreement {
          signatures {
            ...SignatureFields
          }
        }
      }
    }
  }
  ${SignatureFieldsFragmentDoc}
`;
export type SignLeaseAgreementMutationFn = Apollo.MutationFunction<
  SignLeaseAgreementMutation,
  SignLeaseAgreementMutationVariables
>;

/**
 * __useSignLeaseAgreementMutation__
 *
 * To run a mutation, you first call `useSignLeaseAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignLeaseAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signLeaseAgreementMutation, { data, loading, error }] = useSignLeaseAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignLeaseAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignLeaseAgreementMutation,
    SignLeaseAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignLeaseAgreementMutation, SignLeaseAgreementMutationVariables>(
    SignLeaseAgreementDocument,
    options
  );
}
export type SignLeaseAgreementMutationHookResult = ReturnType<typeof useSignLeaseAgreementMutation>;
export type SignLeaseAgreementMutationResult = Apollo.MutationResult<SignLeaseAgreementMutation>;
export type SignLeaseAgreementMutationOptions = Apollo.BaseMutationOptions<
  SignLeaseAgreementMutation,
  SignLeaseAgreementMutationVariables
>;
export const SendLeaseOfferDocument = gql`
  mutation SendLeaseOffer($input: LeaseOfferInput!) {
    sendLeaseOffer(input: $input) {
      success
      error
      application {
        id
        ...ApplicationFields
      }
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;
export type SendLeaseOfferMutationFn = Apollo.MutationFunction<
  SendLeaseOfferMutation,
  SendLeaseOfferMutationVariables
>;

/**
 * __useSendLeaseOfferMutation__
 *
 * To run a mutation, you first call `useSendLeaseOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLeaseOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLeaseOfferMutation, { data, loading, error }] = useSendLeaseOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendLeaseOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<SendLeaseOfferMutation, SendLeaseOfferMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendLeaseOfferMutation, SendLeaseOfferMutationVariables>(
    SendLeaseOfferDocument,
    options
  );
}
export type SendLeaseOfferMutationHookResult = ReturnType<typeof useSendLeaseOfferMutation>;
export type SendLeaseOfferMutationResult = Apollo.MutationResult<SendLeaseOfferMutation>;
export type SendLeaseOfferMutationOptions = Apollo.BaseMutationOptions<
  SendLeaseOfferMutation,
  SendLeaseOfferMutationVariables
>;
export const UnitStepPropertyWithResidencyDocument = gql`
  query UnitStepPropertyWithResidency($propertyId: ID!) {
    property(id: $propertyId) {
      id
      ...PropertyFields
      units {
        id
        ...UnitFieldsWithResidency
      }
    }
  }
  ${PropertyFieldsFragmentDoc}
  ${UnitFieldsWithResidencyFragmentDoc}
`;

/**
 * __useUnitStepPropertyWithResidencyQuery__
 *
 * To run a query within a React component, call `useUnitStepPropertyWithResidencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitStepPropertyWithResidencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitStepPropertyWithResidencyQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useUnitStepPropertyWithResidencyQuery(
  baseOptions: Apollo.QueryHookOptions<
    UnitStepPropertyWithResidencyQuery,
    UnitStepPropertyWithResidencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UnitStepPropertyWithResidencyQuery,
    UnitStepPropertyWithResidencyQueryVariables
  >(UnitStepPropertyWithResidencyDocument, options);
}
export function useUnitStepPropertyWithResidencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnitStepPropertyWithResidencyQuery,
    UnitStepPropertyWithResidencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UnitStepPropertyWithResidencyQuery,
    UnitStepPropertyWithResidencyQueryVariables
  >(UnitStepPropertyWithResidencyDocument, options);
}
export type UnitStepPropertyWithResidencyQueryHookResult = ReturnType<
  typeof useUnitStepPropertyWithResidencyQuery
>;
export type UnitStepPropertyWithResidencyLazyQueryHookResult = ReturnType<
  typeof useUnitStepPropertyWithResidencyLazyQuery
>;
export type UnitStepPropertyWithResidencyQueryResult = Apollo.QueryResult<
  UnitStepPropertyWithResidencyQuery,
  UnitStepPropertyWithResidencyQueryVariables
>;
export const UnitStepAllPropertiesDocument = gql`
  query UnitStepAllProperties($first: Int, $after: ID) {
    account {
      id
      pageProperties(first: $first, after: $after) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useUnitStepAllPropertiesQuery__
 *
 * To run a query within a React component, call `useUnitStepAllPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitStepAllPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitStepAllPropertiesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUnitStepAllPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UnitStepAllPropertiesQuery,
    UnitStepAllPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnitStepAllPropertiesQuery, UnitStepAllPropertiesQueryVariables>(
    UnitStepAllPropertiesDocument,
    options
  );
}
export function useUnitStepAllPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnitStepAllPropertiesQuery,
    UnitStepAllPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnitStepAllPropertiesQuery, UnitStepAllPropertiesQueryVariables>(
    UnitStepAllPropertiesDocument,
    options
  );
}
export type UnitStepAllPropertiesQueryHookResult = ReturnType<typeof useUnitStepAllPropertiesQuery>;
export type UnitStepAllPropertiesLazyQueryHookResult = ReturnType<
  typeof useUnitStepAllPropertiesLazyQuery
>;
export type UnitStepAllPropertiesQueryResult = Apollo.QueryResult<
  UnitStepAllPropertiesQuery,
  UnitStepAllPropertiesQueryVariables
>;
export const GetAccountLeaseExpiryThresholdDocument = gql`
  query GetAccountLeaseExpiryThreshold {
    account {
      settings {
        soon_days_threshold
      }
    }
  }
`;

/**
 * __useGetAccountLeaseExpiryThresholdQuery__
 *
 * To run a query within a React component, call `useGetAccountLeaseExpiryThresholdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountLeaseExpiryThresholdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountLeaseExpiryThresholdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountLeaseExpiryThresholdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountLeaseExpiryThresholdQuery,
    GetAccountLeaseExpiryThresholdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountLeaseExpiryThresholdQuery,
    GetAccountLeaseExpiryThresholdQueryVariables
  >(GetAccountLeaseExpiryThresholdDocument, options);
}
export function useGetAccountLeaseExpiryThresholdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountLeaseExpiryThresholdQuery,
    GetAccountLeaseExpiryThresholdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountLeaseExpiryThresholdQuery,
    GetAccountLeaseExpiryThresholdQueryVariables
  >(GetAccountLeaseExpiryThresholdDocument, options);
}
export type GetAccountLeaseExpiryThresholdQueryHookResult = ReturnType<
  typeof useGetAccountLeaseExpiryThresholdQuery
>;
export type GetAccountLeaseExpiryThresholdLazyQueryHookResult = ReturnType<
  typeof useGetAccountLeaseExpiryThresholdLazyQuery
>;
export type GetAccountLeaseExpiryThresholdQueryResult = Apollo.QueryResult<
  GetAccountLeaseExpiryThresholdQuery,
  GetAccountLeaseExpiryThresholdQueryVariables
>;
export const ApplicationLeaseUnitWithResidencyDocument = gql`
  query ApplicationLeaseUnitWithResidency($unitId: ID!) {
    unit(id: $unitId) {
      id
      ...UnitFieldsWithResidency
    }
  }
  ${UnitFieldsWithResidencyFragmentDoc}
`;

/**
 * __useApplicationLeaseUnitWithResidencyQuery__
 *
 * To run a query within a React component, call `useApplicationLeaseUnitWithResidencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationLeaseUnitWithResidencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationLeaseUnitWithResidencyQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useApplicationLeaseUnitWithResidencyQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationLeaseUnitWithResidencyQuery,
    ApplicationLeaseUnitWithResidencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApplicationLeaseUnitWithResidencyQuery,
    ApplicationLeaseUnitWithResidencyQueryVariables
  >(ApplicationLeaseUnitWithResidencyDocument, options);
}
export function useApplicationLeaseUnitWithResidencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationLeaseUnitWithResidencyQuery,
    ApplicationLeaseUnitWithResidencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationLeaseUnitWithResidencyQuery,
    ApplicationLeaseUnitWithResidencyQueryVariables
  >(ApplicationLeaseUnitWithResidencyDocument, options);
}
export type ApplicationLeaseUnitWithResidencyQueryHookResult = ReturnType<
  typeof useApplicationLeaseUnitWithResidencyQuery
>;
export type ApplicationLeaseUnitWithResidencyLazyQueryHookResult = ReturnType<
  typeof useApplicationLeaseUnitWithResidencyLazyQuery
>;
export type ApplicationLeaseUnitWithResidencyQueryResult = Apollo.QueryResult<
  ApplicationLeaseUnitWithResidencyQuery,
  ApplicationLeaseUnitWithResidencyQueryVariables
>;
export const UpdateApplicationFlagDocument = gql`
  mutation UpdateApplicationFlag($input: UpdateApplicationInput!) {
    updateApplication(input: $input) {
      success
      error
      application {
        id
        flag
      }
    }
  }
`;
export type UpdateApplicationFlagMutationFn = Apollo.MutationFunction<
  UpdateApplicationFlagMutation,
  UpdateApplicationFlagMutationVariables
>;

/**
 * __useUpdateApplicationFlagMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationFlagMutation, { data, loading, error }] = useUpdateApplicationFlagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationFlagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationFlagMutation,
    UpdateApplicationFlagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateApplicationFlagMutation, UpdateApplicationFlagMutationVariables>(
    UpdateApplicationFlagDocument,
    options
  );
}
export type UpdateApplicationFlagMutationHookResult = ReturnType<
  typeof useUpdateApplicationFlagMutation
>;
export type UpdateApplicationFlagMutationResult =
  Apollo.MutationResult<UpdateApplicationFlagMutation>;
export type UpdateApplicationFlagMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationFlagMutation,
  UpdateApplicationFlagMutationVariables
>;
export const GetCampaignDocument = gql`
  query GetCampaign($id: ID!) {
    campaign(id: $id) {
      ...CampaignFields
    }
  }
  ${CampaignFieldsFragmentDoc}
`;

/**
 * __useGetCampaignQuery__
 *
 * To run a query within a React component, call `useGetCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<GetCampaignQuery, GetCampaignQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCampaignQuery, GetCampaignQueryVariables>(GetCampaignDocument, options);
}
export function useGetCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignQuery, GetCampaignQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCampaignQuery, GetCampaignQueryVariables>(
    GetCampaignDocument,
    options
  );
}
export type GetCampaignQueryHookResult = ReturnType<typeof useGetCampaignQuery>;
export type GetCampaignLazyQueryHookResult = ReturnType<typeof useGetCampaignLazyQuery>;
export type GetCampaignQueryResult = Apollo.QueryResult<
  GetCampaignQuery,
  GetCampaignQueryVariables
>;
export const GetCampaignsListedUnitsDocument = gql`
  query GetCampaignsListedUnits($nextToken: String) {
    campaigns(nextToken: $nextToken) {
      items {
        id
        accountId
        propertyId
        property {
          id
          name
          enabled
        }
        status
        listedUnits {
          ...ListedUnitFields
        }
      }
      nextToken
    }
  }
  ${ListedUnitFieldsFragmentDoc}
`;

/**
 * __useGetCampaignsListedUnitsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsListedUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsListedUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsListedUnitsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetCampaignsListedUnitsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCampaignsListedUnitsQuery,
    GetCampaignsListedUnitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCampaignsListedUnitsQuery, GetCampaignsListedUnitsQueryVariables>(
    GetCampaignsListedUnitsDocument,
    options
  );
}
export function useGetCampaignsListedUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCampaignsListedUnitsQuery,
    GetCampaignsListedUnitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCampaignsListedUnitsQuery, GetCampaignsListedUnitsQueryVariables>(
    GetCampaignsListedUnitsDocument,
    options
  );
}
export type GetCampaignsListedUnitsQueryHookResult = ReturnType<
  typeof useGetCampaignsListedUnitsQuery
>;
export type GetCampaignsListedUnitsLazyQueryHookResult = ReturnType<
  typeof useGetCampaignsListedUnitsLazyQuery
>;
export type GetCampaignsListedUnitsQueryResult = Apollo.QueryResult<
  GetCampaignsListedUnitsQuery,
  GetCampaignsListedUnitsQueryVariables
>;
export const GetFloorplanByIdsDocument = gql`
  query GetFloorplanByIds($ids: [ID!]!) {
    floorplanByIds(ids: $ids) {
      id
      name
      marketRate
      exteriorSqFt
      interiorSqFt
      bedrooms
      bathrooms
      units {
        id
        name
      }
    }
  }
`;

/**
 * __useGetFloorplanByIdsQuery__
 *
 * To run a query within a React component, call `useGetFloorplanByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFloorplanByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFloorplanByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetFloorplanByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<GetFloorplanByIdsQuery, GetFloorplanByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFloorplanByIdsQuery, GetFloorplanByIdsQueryVariables>(
    GetFloorplanByIdsDocument,
    options
  );
}
export function useGetFloorplanByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFloorplanByIdsQuery, GetFloorplanByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFloorplanByIdsQuery, GetFloorplanByIdsQueryVariables>(
    GetFloorplanByIdsDocument,
    options
  );
}
export type GetFloorplanByIdsQueryHookResult = ReturnType<typeof useGetFloorplanByIdsQuery>;
export type GetFloorplanByIdsLazyQueryHookResult = ReturnType<typeof useGetFloorplanByIdsLazyQuery>;
export type GetFloorplanByIdsQueryResult = Apollo.QueryResult<
  GetFloorplanByIdsQuery,
  GetFloorplanByIdsQueryVariables
>;
export const GetCampaignsDocument = gql`
  query GetCampaigns($nextToken: String) {
    campaigns(nextToken: $nextToken) {
      items {
        ...CampaignListFields
      }
      nextToken
    }
  }
  ${CampaignListFieldsFragmentDoc}
`;

/**
 * __useGetCampaignsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(
    GetCampaignsDocument,
    options
  );
}
export function useGetCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(
    GetCampaignsDocument,
    options
  );
}
export type GetCampaignsQueryHookResult = ReturnType<typeof useGetCampaignsQuery>;
export type GetCampaignsLazyQueryHookResult = ReturnType<typeof useGetCampaignsLazyQuery>;
export type GetCampaignsQueryResult = Apollo.QueryResult<
  GetCampaignsQuery,
  GetCampaignsQueryVariables
>;
export const ShareCampaignDocument = gql`
  mutation shareCampaign($input: ShareCampaignInput!) {
    shareCampaign(input: $input) {
      success
      error
    }
  }
`;
export type ShareCampaignMutationFn = Apollo.MutationFunction<
  ShareCampaignMutation,
  ShareCampaignMutationVariables
>;

/**
 * __useShareCampaignMutation__
 *
 * To run a mutation, you first call `useShareCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareCampaignMutation, { data, loading, error }] = useShareCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<ShareCampaignMutation, ShareCampaignMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ShareCampaignMutation, ShareCampaignMutationVariables>(
    ShareCampaignDocument,
    options
  );
}
export type ShareCampaignMutationHookResult = ReturnType<typeof useShareCampaignMutation>;
export type ShareCampaignMutationResult = Apollo.MutationResult<ShareCampaignMutation>;
export type ShareCampaignMutationOptions = Apollo.BaseMutationOptions<
  ShareCampaignMutation,
  ShareCampaignMutationVariables
>;
export const CreateCampaignLinkDocument = gql`
  mutation CreateCampaignLink($input: CreateCampaignLinkInput!) {
    createCampaignLink(input: $input) {
      success
      error
      campaign {
        ...CampaignFields
      }
    }
  }
  ${CampaignFieldsFragmentDoc}
`;
export type CreateCampaignLinkMutationFn = Apollo.MutationFunction<
  CreateCampaignLinkMutation,
  CreateCampaignLinkMutationVariables
>;

/**
 * __useCreateCampaignLinkMutation__
 *
 * To run a mutation, you first call `useCreateCampaignLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignLinkMutation, { data, loading, error }] = useCreateCampaignLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCampaignLinkMutation,
    CreateCampaignLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCampaignLinkMutation, CreateCampaignLinkMutationVariables>(
    CreateCampaignLinkDocument,
    options
  );
}
export type CreateCampaignLinkMutationHookResult = ReturnType<typeof useCreateCampaignLinkMutation>;
export type CreateCampaignLinkMutationResult = Apollo.MutationResult<CreateCampaignLinkMutation>;
export type CreateCampaignLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateCampaignLinkMutation,
  CreateCampaignLinkMutationVariables
>;
export const UpdateCampaignStatusDocument = gql`
  mutation updateCampaignStatus($input: UpdateCampaignStatusInput!) {
    updateCampaignStatus(input: $input) {
      success
      error
      campaign {
        ...CampaignFields
      }
    }
  }
  ${CampaignFieldsFragmentDoc}
`;
export type UpdateCampaignStatusMutationFn = Apollo.MutationFunction<
  UpdateCampaignStatusMutation,
  UpdateCampaignStatusMutationVariables
>;

/**
 * __useUpdateCampaignStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignStatusMutation, { data, loading, error }] = useUpdateCampaignStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCampaignStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCampaignStatusMutation,
    UpdateCampaignStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCampaignStatusMutation, UpdateCampaignStatusMutationVariables>(
    UpdateCampaignStatusDocument,
    options
  );
}
export type UpdateCampaignStatusMutationHookResult = ReturnType<
  typeof useUpdateCampaignStatusMutation
>;
export type UpdateCampaignStatusMutationResult =
  Apollo.MutationResult<UpdateCampaignStatusMutation>;
export type UpdateCampaignStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateCampaignStatusMutation,
  UpdateCampaignStatusMutationVariables
>;
export const CreateCampaignDocument = gql`
  mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      success
      error
      campaign {
        ...CampaignFields
      }
    }
  }
  ${CampaignFieldsFragmentDoc}
`;
export type CreateCampaignMutationFn = Apollo.MutationFunction<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;

/**
 * __useCreateCampaignMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMutation, { data, loading, error }] = useCreateCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCampaignMutation, CreateCampaignMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCampaignMutation, CreateCampaignMutationVariables>(
    CreateCampaignDocument,
    options
  );
}
export type CreateCampaignMutationHookResult = ReturnType<typeof useCreateCampaignMutation>;
export type CreateCampaignMutationResult = Apollo.MutationResult<CreateCampaignMutation>;
export type CreateCampaignMutationOptions = Apollo.BaseMutationOptions<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;
export const UpdateCampaignDocument = gql`
  mutation updateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      success
      error
      campaign {
        ...CampaignFields
      }
    }
  }
  ${CampaignFieldsFragmentDoc}
`;
export type UpdateCampaignMutationFn = Apollo.MutationFunction<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
>;

/**
 * __useUpdateCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignMutation, { data, loading, error }] = useUpdateCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCampaignMutation, UpdateCampaignMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCampaignMutation, UpdateCampaignMutationVariables>(
    UpdateCampaignDocument,
    options
  );
}
export type UpdateCampaignMutationHookResult = ReturnType<typeof useUpdateCampaignMutation>;
export type UpdateCampaignMutationResult = Apollo.MutationResult<UpdateCampaignMutation>;
export type UpdateCampaignMutationOptions = Apollo.BaseMutationOptions<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
>;
export const CollectionsDocument = gql`
  query Collections($propertyIds: [String!]) {
    account {
      id
      books {
        accountId
        collections(propertyIds: $propertyIds) {
          total
          current
          due
          overdue
          late
          delinquent
        }
      }
    }
  }
`;

/**
 * __useCollectionsQuery__
 *
 * To run a query within a React component, call `useCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsQuery({
 *   variables: {
 *      propertyIds: // value for 'propertyIds'
 *   },
 * });
 */
export function useCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<CollectionsQuery, CollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, options);
}
export function useCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CollectionsQuery, CollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CollectionsQuery, CollectionsQueryVariables>(
    CollectionsDocument,
    options
  );
}
export type CollectionsQueryHookResult = ReturnType<typeof useCollectionsQuery>;
export type CollectionsLazyQueryHookResult = ReturnType<typeof useCollectionsLazyQuery>;
export type CollectionsQueryResult = Apollo.QueryResult<
  CollectionsQuery,
  CollectionsQueryVariables
>;
export const GetPropertyFactsDocument = gql`
  query GetPropertyFacts($nextToken: String, $filter: PropertyFactFilter) {
    account {
      id
      listPropertyFacts(nextToken: $nextToken, filter: $filter) {
        nextToken
        items {
          propertyId
          dateKey
          totalUnits
          leasedUnits
          commonAreas
          ownerUnits
          expiring15Days
          expiringSoon
          expiringLater
          totalCommercialRent
          commercialLeasesExpired
          commercialExpiring15Days
          totalCommercialSqft
          commercialUnits
          commercialLeasedUnits
          commercialExpiringSoon
          commercialExpiringLater
          totalResidentialUnits
          totalResidentialRent
          totalResidentialSqft
        }
      }
    }
  }
`;

/**
 * __useGetPropertyFactsQuery__
 *
 * To run a query within a React component, call `useGetPropertyFactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyFactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyFactsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPropertyFactsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPropertyFactsQuery, GetPropertyFactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyFactsQuery, GetPropertyFactsQueryVariables>(
    GetPropertyFactsDocument,
    options
  );
}
export function useGetPropertyFactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyFactsQuery, GetPropertyFactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyFactsQuery, GetPropertyFactsQueryVariables>(
    GetPropertyFactsDocument,
    options
  );
}
export type GetPropertyFactsQueryHookResult = ReturnType<typeof useGetPropertyFactsQuery>;
export type GetPropertyFactsLazyQueryHookResult = ReturnType<typeof useGetPropertyFactsLazyQuery>;
export type GetPropertyFactsQueryResult = Apollo.QueryResult<
  GetPropertyFactsQuery,
  GetPropertyFactsQueryVariables
>;
export const ActiveRequestsDocument = gql`
  query ActiveRequests($nextToken: String) {
    account {
      id
      listActiveRequests(nextToken: $nextToken) {
        items {
          ...RequestListFields
        }
        nextToken
      }
    }
  }
  ${RequestListFieldsFragmentDoc}
`;

/**
 * __useActiveRequestsQuery__
 *
 * To run a query within a React component, call `useActiveRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveRequestsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useActiveRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<ActiveRequestsQuery, ActiveRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveRequestsQuery, ActiveRequestsQueryVariables>(
    ActiveRequestsDocument,
    options
  );
}
export function useActiveRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveRequestsQuery, ActiveRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveRequestsQuery, ActiveRequestsQueryVariables>(
    ActiveRequestsDocument,
    options
  );
}
export type ActiveRequestsQueryHookResult = ReturnType<typeof useActiveRequestsQuery>;
export type ActiveRequestsLazyQueryHookResult = ReturnType<typeof useActiveRequestsLazyQuery>;
export type ActiveRequestsQueryResult = Apollo.QueryResult<
  ActiveRequestsQuery,
  ActiveRequestsQueryVariables
>;
export const UpdateOperatorDocument = gql`
  mutation UpdateOperator($id: ID!, $input: UpdateOperatorInput!) {
    updateOperator(id: $id, input: $input) {
      id
      name
      email
      phone
      location
      website
      operatorType
      skills
      enabled
      availability
      exceptions {
        comment
        interval {
          start
          end
        }
      }
      timezone
      address {
        country
        suite
        street
        city
        province
        postal
      }
      hasCOI
      expiryCOI
    }
  }
`;
export type UpdateOperatorMutationFn = Apollo.MutationFunction<
  UpdateOperatorMutation,
  UpdateOperatorMutationVariables
>;

/**
 * __useUpdateOperatorMutation__
 *
 * To run a mutation, you first call `useUpdateOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperatorMutation, { data, loading, error }] = useUpdateOperatorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOperatorMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOperatorMutation, UpdateOperatorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOperatorMutation, UpdateOperatorMutationVariables>(
    UpdateOperatorDocument,
    options
  );
}
export type UpdateOperatorMutationHookResult = ReturnType<typeof useUpdateOperatorMutation>;
export type UpdateOperatorMutationResult = Apollo.MutationResult<UpdateOperatorMutation>;
export type UpdateOperatorMutationOptions = Apollo.BaseMutationOptions<
  UpdateOperatorMutation,
  UpdateOperatorMutationVariables
>;
export const DeleteOperatorDocument = gql`
  mutation DeleteOperator($id: ID!) {
    deleteOperator(id: $id) {
      id
    }
  }
`;
export type DeleteOperatorMutationFn = Apollo.MutationFunction<
  DeleteOperatorMutation,
  DeleteOperatorMutationVariables
>;

/**
 * __useDeleteOperatorMutation__
 *
 * To run a mutation, you first call `useDeleteOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOperatorMutation, { data, loading, error }] = useDeleteOperatorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOperatorMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOperatorMutation, DeleteOperatorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOperatorMutation, DeleteOperatorMutationVariables>(
    DeleteOperatorDocument,
    options
  );
}
export type DeleteOperatorMutationHookResult = ReturnType<typeof useDeleteOperatorMutation>;
export type DeleteOperatorMutationResult = Apollo.MutationResult<DeleteOperatorMutation>;
export type DeleteOperatorMutationOptions = Apollo.BaseMutationOptions<
  DeleteOperatorMutation,
  DeleteOperatorMutationVariables
>;
export const UpdateOperatorAutoApDocument = gql`
  mutation UpdateOperatorAutoAP($input: UpdateOperatorAutoAPInput!) {
    updateOperatorAutoAP(input: $input) {
      success
      error
      operator {
        ...OperatorFields
      }
    }
  }
  ${OperatorFieldsFragmentDoc}
`;
export type UpdateOperatorAutoApMutationFn = Apollo.MutationFunction<
  UpdateOperatorAutoApMutation,
  UpdateOperatorAutoApMutationVariables
>;

/**
 * __useUpdateOperatorAutoApMutation__
 *
 * To run a mutation, you first call `useUpdateOperatorAutoApMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperatorAutoApMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperatorAutoApMutation, { data, loading, error }] = useUpdateOperatorAutoApMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOperatorAutoApMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOperatorAutoApMutation,
    UpdateOperatorAutoApMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOperatorAutoApMutation, UpdateOperatorAutoApMutationVariables>(
    UpdateOperatorAutoApDocument,
    options
  );
}
export type UpdateOperatorAutoApMutationHookResult = ReturnType<
  typeof useUpdateOperatorAutoApMutation
>;
export type UpdateOperatorAutoApMutationResult =
  Apollo.MutationResult<UpdateOperatorAutoApMutation>;
export type UpdateOperatorAutoApMutationOptions = Apollo.BaseMutationOptions<
  UpdateOperatorAutoApMutation,
  UpdateOperatorAutoApMutationVariables
>;
export const GetOperatorDocument = gql`
  query GetOperator($id: ID!) {
    operator(id: $id) {
      ...OperatorFields
    }
  }
  ${OperatorFieldsFragmentDoc}
`;

/**
 * __useGetOperatorQuery__
 *
 * To run a query within a React component, call `useGetOperatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOperatorQuery(
  baseOptions: Apollo.QueryHookOptions<GetOperatorQuery, GetOperatorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOperatorQuery, GetOperatorQueryVariables>(GetOperatorDocument, options);
}
export function useGetOperatorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOperatorQuery, GetOperatorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOperatorQuery, GetOperatorQueryVariables>(
    GetOperatorDocument,
    options
  );
}
export type GetOperatorQueryHookResult = ReturnType<typeof useGetOperatorQuery>;
export type GetOperatorLazyQueryHookResult = ReturnType<typeof useGetOperatorLazyQuery>;
export type GetOperatorQueryResult = Apollo.QueryResult<
  GetOperatorQuery,
  GetOperatorQueryVariables
>;
export const GetOperatorRequestHistoryDocument = gql`
  query GetOperatorRequestHistory($id: ID!) {
    operator(id: $id) {
      id
      requests: requestsCompleted {
        ...OperatorRequestHistoryFields
      }
    }
  }
  ${OperatorRequestHistoryFieldsFragmentDoc}
`;

/**
 * __useGetOperatorRequestHistoryQuery__
 *
 * To run a query within a React component, call `useGetOperatorRequestHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatorRequestHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatorRequestHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOperatorRequestHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOperatorRequestHistoryQuery,
    GetOperatorRequestHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOperatorRequestHistoryQuery, GetOperatorRequestHistoryQueryVariables>(
    GetOperatorRequestHistoryDocument,
    options
  );
}
export function useGetOperatorRequestHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOperatorRequestHistoryQuery,
    GetOperatorRequestHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOperatorRequestHistoryQuery,
    GetOperatorRequestHistoryQueryVariables
  >(GetOperatorRequestHistoryDocument, options);
}
export type GetOperatorRequestHistoryQueryHookResult = ReturnType<
  typeof useGetOperatorRequestHistoryQuery
>;
export type GetOperatorRequestHistoryLazyQueryHookResult = ReturnType<
  typeof useGetOperatorRequestHistoryLazyQuery
>;
export type GetOperatorRequestHistoryQueryResult = Apollo.QueryResult<
  GetOperatorRequestHistoryQuery,
  GetOperatorRequestHistoryQueryVariables
>;
export const AddOperatorDocument = gql`
  mutation AddOperator($input: CreateOperatorInput!) {
    createOperator(input: $input) {
      ...OperatorListFields
    }
  }
  ${OperatorListFieldsFragmentDoc}
`;
export type AddOperatorMutationFn = Apollo.MutationFunction<
  AddOperatorMutation,
  AddOperatorMutationVariables
>;

/**
 * __useAddOperatorMutation__
 *
 * To run a mutation, you first call `useAddOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOperatorMutation, { data, loading, error }] = useAddOperatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOperatorMutation(
  baseOptions?: Apollo.MutationHookOptions<AddOperatorMutation, AddOperatorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOperatorMutation, AddOperatorMutationVariables>(
    AddOperatorDocument,
    options
  );
}
export type AddOperatorMutationHookResult = ReturnType<typeof useAddOperatorMutation>;
export type AddOperatorMutationResult = Apollo.MutationResult<AddOperatorMutation>;
export type AddOperatorMutationOptions = Apollo.BaseMutationOptions<
  AddOperatorMutation,
  AddOperatorMutationVariables
>;
export const GetOperatorsDocument = gql`
  query GetOperators {
    account {
      id
      operators {
        ...OperatorListFields
      }
    }
  }
  ${OperatorListFieldsFragmentDoc}
`;

/**
 * __useGetOperatorsQuery__
 *
 * To run a query within a React component, call `useGetOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOperatorsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOperatorsQuery, GetOperatorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOperatorsQuery, GetOperatorsQueryVariables>(
    GetOperatorsDocument,
    options
  );
}
export function useGetOperatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOperatorsQuery, GetOperatorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOperatorsQuery, GetOperatorsQueryVariables>(
    GetOperatorsDocument,
    options
  );
}
export type GetOperatorsQueryHookResult = ReturnType<typeof useGetOperatorsQuery>;
export type GetOperatorsLazyQueryHookResult = ReturnType<typeof useGetOperatorsLazyQuery>;
export type GetOperatorsQueryResult = Apollo.QueryResult<
  GetOperatorsQuery,
  GetOperatorsQueryVariables
>;
export const SetOwnerAutopayCollectionsEnabledDocument = gql`
  mutation SetOwnerAutopayCollectionsEnabled($input: SetAutopayCollectionsEnabledInput!) {
    setOwnerAutopayCollectionsEnabled(input: $input) {
      success
      error
      owner {
        id
        autopayCollectionsEnabled
        autopayCollectionsEnabledZ
      }
    }
  }
`;
export type SetOwnerAutopayCollectionsEnabledMutationFn = Apollo.MutationFunction<
  SetOwnerAutopayCollectionsEnabledMutation,
  SetOwnerAutopayCollectionsEnabledMutationVariables
>;

/**
 * __useSetOwnerAutopayCollectionsEnabledMutation__
 *
 * To run a mutation, you first call `useSetOwnerAutopayCollectionsEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOwnerAutopayCollectionsEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOwnerAutopayCollectionsEnabledMutation, { data, loading, error }] = useSetOwnerAutopayCollectionsEnabledMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOwnerAutopayCollectionsEnabledMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetOwnerAutopayCollectionsEnabledMutation,
    SetOwnerAutopayCollectionsEnabledMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetOwnerAutopayCollectionsEnabledMutation,
    SetOwnerAutopayCollectionsEnabledMutationVariables
  >(SetOwnerAutopayCollectionsEnabledDocument, options);
}
export type SetOwnerAutopayCollectionsEnabledMutationHookResult = ReturnType<
  typeof useSetOwnerAutopayCollectionsEnabledMutation
>;
export type SetOwnerAutopayCollectionsEnabledMutationResult =
  Apollo.MutationResult<SetOwnerAutopayCollectionsEnabledMutation>;
export type SetOwnerAutopayCollectionsEnabledMutationOptions = Apollo.BaseMutationOptions<
  SetOwnerAutopayCollectionsEnabledMutation,
  SetOwnerAutopayCollectionsEnabledMutationVariables
>;
export const GetPropertyForHeaderDocument = gql`
  query GetPropertyForHeader($propertyId: ID!) {
    property(id: $propertyId) {
      id
      address {
        street
        city
        province
        postal
      }
      accountId
      name
      imageKey
      ownerId
      enabled
    }
  }
`;

/**
 * __useGetPropertyForHeaderQuery__
 *
 * To run a query within a React component, call `useGetPropertyForHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyForHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyForHeaderQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetPropertyForHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyForHeaderQuery,
    GetPropertyForHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyForHeaderQuery, GetPropertyForHeaderQueryVariables>(
    GetPropertyForHeaderDocument,
    options
  );
}
export function useGetPropertyForHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyForHeaderQuery,
    GetPropertyForHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyForHeaderQuery, GetPropertyForHeaderQueryVariables>(
    GetPropertyForHeaderDocument,
    options
  );
}
export type GetPropertyForHeaderQueryHookResult = ReturnType<typeof useGetPropertyForHeaderQuery>;
export type GetPropertyForHeaderLazyQueryHookResult = ReturnType<
  typeof useGetPropertyForHeaderLazyQuery
>;
export type GetPropertyForHeaderQueryResult = Apollo.QueryResult<
  GetPropertyForHeaderQuery,
  GetPropertyForHeaderQueryVariables
>;
export const GetOwnerProfileDocument = gql`
  query getOwnerProfile($id: ID!) {
    owner(id: $id) {
      id
      profile {
        id
        name
        dob
        phone
        email
        logins(status: CONFIRMED) {
          id
          email
        }
        assetsShares {
          id
          profile {
            id
            name
            emails
          }
          assets {
            id
            role
            assetType
            permissions
          }
        }
      }
    }
  }
`;

/**
 * __useGetOwnerProfileQuery__
 *
 * To run a query within a React component, call `useGetOwnerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOwnerProfileQuery(
  baseOptions: Apollo.QueryHookOptions<GetOwnerProfileQuery, GetOwnerProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnerProfileQuery, GetOwnerProfileQueryVariables>(
    GetOwnerProfileDocument,
    options
  );
}
export function useGetOwnerProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerProfileQuery, GetOwnerProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnerProfileQuery, GetOwnerProfileQueryVariables>(
    GetOwnerProfileDocument,
    options
  );
}
export type GetOwnerProfileQueryHookResult = ReturnType<typeof useGetOwnerProfileQuery>;
export type GetOwnerProfileLazyQueryHookResult = ReturnType<typeof useGetOwnerProfileLazyQuery>;
export type GetOwnerProfileQueryResult = Apollo.QueryResult<
  GetOwnerProfileQuery,
  GetOwnerProfileQueryVariables
>;
export const OwnerUnitFinancialsPathDocument = gql`
  query OwnerUnitFinancialsPath($ownerId: ID!, $unitId: ID!, $nextToken: String) {
    owner(id: $ownerId) {
      id
      listUnits(filter: { unitIds: [$unitId] }, nextToken: $nextToken) {
        nextToken
        items {
          id
          name
          ownerId
          condoFee
          condoFeeGst
          condoUnitFees {
            feeId
            amount
          }
          paymentDetails {
            preferredPaymentMethod
            padAmount
            padInstitution
            padTransit
            padAccount
          }
          building {
            id
            name
          }
          property {
            id
            name
            address {
              ...AddressFields
            }
          }
        }
      }
    }
  }
  ${AddressFieldsFragmentDoc}
`;

/**
 * __useOwnerUnitFinancialsPathQuery__
 *
 * To run a query within a React component, call `useOwnerUnitFinancialsPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerUnitFinancialsPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerUnitFinancialsPathQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      unitId: // value for 'unitId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useOwnerUnitFinancialsPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    OwnerUnitFinancialsPathQuery,
    OwnerUnitFinancialsPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OwnerUnitFinancialsPathQuery, OwnerUnitFinancialsPathQueryVariables>(
    OwnerUnitFinancialsPathDocument,
    options
  );
}
export function useOwnerUnitFinancialsPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OwnerUnitFinancialsPathQuery,
    OwnerUnitFinancialsPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OwnerUnitFinancialsPathQuery, OwnerUnitFinancialsPathQueryVariables>(
    OwnerUnitFinancialsPathDocument,
    options
  );
}
export type OwnerUnitFinancialsPathQueryHookResult = ReturnType<
  typeof useOwnerUnitFinancialsPathQuery
>;
export type OwnerUnitFinancialsPathLazyQueryHookResult = ReturnType<
  typeof useOwnerUnitFinancialsPathLazyQuery
>;
export type OwnerUnitFinancialsPathQueryResult = Apollo.QueryResult<
  OwnerUnitFinancialsPathQuery,
  OwnerUnitFinancialsPathQueryVariables
>;
export const UpdateOwnerUnitFinancialsDocument = gql`
  mutation UpdateOwnerUnitFinancials($unitId: ID!, $input: PaymentDetailsInput!) {
    updateUnit(input: { id: $unitId, paymentDetails: $input }) {
      success
      error
      unit {
        id
        name
        paymentDetails {
          preferredPaymentMethod
          padAmount
          padInstitution
          padTransit
          padAccount
        }
      }
    }
  }
`;
export type UpdateOwnerUnitFinancialsMutationFn = Apollo.MutationFunction<
  UpdateOwnerUnitFinancialsMutation,
  UpdateOwnerUnitFinancialsMutationVariables
>;

/**
 * __useUpdateOwnerUnitFinancialsMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerUnitFinancialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerUnitFinancialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerUnitFinancialsMutation, { data, loading, error }] = useUpdateOwnerUnitFinancialsMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnerUnitFinancialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnerUnitFinancialsMutation,
    UpdateOwnerUnitFinancialsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOwnerUnitFinancialsMutation,
    UpdateOwnerUnitFinancialsMutationVariables
  >(UpdateOwnerUnitFinancialsDocument, options);
}
export type UpdateOwnerUnitFinancialsMutationHookResult = ReturnType<
  typeof useUpdateOwnerUnitFinancialsMutation
>;
export type UpdateOwnerUnitFinancialsMutationResult =
  Apollo.MutationResult<UpdateOwnerUnitFinancialsMutation>;
export type UpdateOwnerUnitFinancialsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnerUnitFinancialsMutation,
  UpdateOwnerUnitFinancialsMutationVariables
>;
export const GetOwnerDocumentsDocument = gql`
  query GetOwnerDocuments($id: ID!) {
    owner(id: $id) {
      id
      documents {
        name
        key
        createdZ
        size
      }
    }
  }
`;

/**
 * __useGetOwnerDocumentsQuery__
 *
 * To run a query within a React component, call `useGetOwnerDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOwnerDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<GetOwnerDocumentsQuery, GetOwnerDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnerDocumentsQuery, GetOwnerDocumentsQueryVariables>(
    GetOwnerDocumentsDocument,
    options
  );
}
export function useGetOwnerDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerDocumentsQuery, GetOwnerDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnerDocumentsQuery, GetOwnerDocumentsQueryVariables>(
    GetOwnerDocumentsDocument,
    options
  );
}
export type GetOwnerDocumentsQueryHookResult = ReturnType<typeof useGetOwnerDocumentsQuery>;
export type GetOwnerDocumentsLazyQueryHookResult = ReturnType<typeof useGetOwnerDocumentsLazyQuery>;
export type GetOwnerDocumentsQueryResult = Apollo.QueryResult<
  GetOwnerDocumentsQuery,
  GetOwnerDocumentsQueryVariables
>;
export const RenameOwnerDocumentDocument = gql`
  mutation RenameOwnerDocument($input: RenameOwnerDocumentInput!) {
    renameOwnerDocument(input: $input) {
      success
      error
      owner {
        id
        documents {
          key
          name
        }
      }
    }
  }
`;
export type RenameOwnerDocumentMutationFn = Apollo.MutationFunction<
  RenameOwnerDocumentMutation,
  RenameOwnerDocumentMutationVariables
>;

/**
 * __useRenameOwnerDocumentMutation__
 *
 * To run a mutation, you first call `useRenameOwnerDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameOwnerDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameOwnerDocumentMutation, { data, loading, error }] = useRenameOwnerDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameOwnerDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameOwnerDocumentMutation,
    RenameOwnerDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RenameOwnerDocumentMutation, RenameOwnerDocumentMutationVariables>(
    RenameOwnerDocumentDocument,
    options
  );
}
export type RenameOwnerDocumentMutationHookResult = ReturnType<
  typeof useRenameOwnerDocumentMutation
>;
export type RenameOwnerDocumentMutationResult = Apollo.MutationResult<RenameOwnerDocumentMutation>;
export type RenameOwnerDocumentMutationOptions = Apollo.BaseMutationOptions<
  RenameOwnerDocumentMutation,
  RenameOwnerDocumentMutationVariables
>;
export const OwnerWithResidenciesDetailDocument = gql`
  query OwnerWithResidenciesDetail($ownerId: ID!) {
    owner(id: $ownerId) {
      id
      ...OwnerFields
      units {
        id
        ...UnitListFields
        currentResidencies {
          id
          startZ
          endZ
          moveOutReason
          terms
          rrule
        }
      }
    }
  }
  ${OwnerFieldsFragmentDoc}
  ${UnitListFieldsFragmentDoc}
`;

/**
 * __useOwnerWithResidenciesDetailQuery__
 *
 * To run a query within a React component, call `useOwnerWithResidenciesDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerWithResidenciesDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerWithResidenciesDetailQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useOwnerWithResidenciesDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    OwnerWithResidenciesDetailQuery,
    OwnerWithResidenciesDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OwnerWithResidenciesDetailQuery, OwnerWithResidenciesDetailQueryVariables>(
    OwnerWithResidenciesDetailDocument,
    options
  );
}
export function useOwnerWithResidenciesDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OwnerWithResidenciesDetailQuery,
    OwnerWithResidenciesDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OwnerWithResidenciesDetailQuery,
    OwnerWithResidenciesDetailQueryVariables
  >(OwnerWithResidenciesDetailDocument, options);
}
export type OwnerWithResidenciesDetailQueryHookResult = ReturnType<
  typeof useOwnerWithResidenciesDetailQuery
>;
export type OwnerWithResidenciesDetailLazyQueryHookResult = ReturnType<
  typeof useOwnerWithResidenciesDetailLazyQuery
>;
export type OwnerWithResidenciesDetailQueryResult = Apollo.QueryResult<
  OwnerWithResidenciesDetailQuery,
  OwnerWithResidenciesDetailQueryVariables
>;
export const GetOwnerEnrollmentsDocument = gql`
  query GetOwnerEnrollments($id: ID!, $filter: AutopayEnrollmentFilterInput) {
    owner(id: $id) {
      id
      enrollments(filter: $filter) {
        id
        residencyId
        unitId
        payeeType
        status
      }
    }
  }
`;

/**
 * __useGetOwnerEnrollmentsQuery__
 *
 * To run a query within a React component, call `useGetOwnerEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerEnrollmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOwnerEnrollmentsQuery(
  baseOptions: Apollo.QueryHookOptions<GetOwnerEnrollmentsQuery, GetOwnerEnrollmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnerEnrollmentsQuery, GetOwnerEnrollmentsQueryVariables>(
    GetOwnerEnrollmentsDocument,
    options
  );
}
export function useGetOwnerEnrollmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOwnerEnrollmentsQuery,
    GetOwnerEnrollmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnerEnrollmentsQuery, GetOwnerEnrollmentsQueryVariables>(
    GetOwnerEnrollmentsDocument,
    options
  );
}
export type GetOwnerEnrollmentsQueryHookResult = ReturnType<typeof useGetOwnerEnrollmentsQuery>;
export type GetOwnerEnrollmentsLazyQueryHookResult = ReturnType<
  typeof useGetOwnerEnrollmentsLazyQuery
>;
export type GetOwnerEnrollmentsQueryResult = Apollo.QueryResult<
  GetOwnerEnrollmentsQuery,
  GetOwnerEnrollmentsQueryVariables
>;
export const SearchOwnersByEmailDocument = gql`
  query searchOwnersByEmail($filter: OwnerSearchFilter!) {
    searchOwnersByEmail(filter: $filter) {
      id
      name
      email
      phones
      enabled
    }
  }
`;

/**
 * __useSearchOwnersByEmailQuery__
 *
 * To run a query within a React component, call `useSearchOwnersByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOwnersByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOwnersByEmailQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchOwnersByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<SearchOwnersByEmailQuery, SearchOwnersByEmailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchOwnersByEmailQuery, SearchOwnersByEmailQueryVariables>(
    SearchOwnersByEmailDocument,
    options
  );
}
export function useSearchOwnersByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchOwnersByEmailQuery,
    SearchOwnersByEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchOwnersByEmailQuery, SearchOwnersByEmailQueryVariables>(
    SearchOwnersByEmailDocument,
    options
  );
}
export type SearchOwnersByEmailQueryHookResult = ReturnType<typeof useSearchOwnersByEmailQuery>;
export type SearchOwnersByEmailLazyQueryHookResult = ReturnType<
  typeof useSearchOwnersByEmailLazyQuery
>;
export type SearchOwnersByEmailQueryResult = Apollo.QueryResult<
  SearchOwnersByEmailQuery,
  SearchOwnersByEmailQueryVariables
>;
export const GetOwnerDocument = gql`
  query GetOwner($id: ID!) {
    owner(id: $id) {
      ...OwnerFields
      units {
        ...UnitListFields
        reserveAmount
        currentResidencies {
          id
          occupancy
        }
        property {
          id
          name
          ownerId
          enabled
          address {
            ...AddressFields
          }
        }
        reserveAmount
        condoFee
        condoFeeGst
        condoUnitFees {
          feeId
          amount
        }
        paymentDetails {
          preferredPaymentMethod
          padAmount
          padInstitution
          padTransit
          padAccount
        }
      }
    }
  }
  ${OwnerFieldsFragmentDoc}
  ${UnitListFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;

/**
 * __useGetOwnerQuery__
 *
 * To run a query within a React component, call `useGetOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<GetOwnerQuery, GetOwnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnerQuery, GetOwnerQueryVariables>(GetOwnerDocument, options);
}
export function useGetOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerQuery, GetOwnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnerQuery, GetOwnerQueryVariables>(GetOwnerDocument, options);
}
export type GetOwnerQueryHookResult = ReturnType<typeof useGetOwnerQuery>;
export type GetOwnerLazyQueryHookResult = ReturnType<typeof useGetOwnerLazyQuery>;
export type GetOwnerQueryResult = Apollo.QueryResult<GetOwnerQuery, GetOwnerQueryVariables>;
export const AddOwnerDocument = gql`
  mutation AddOwner($input: CreateOwnerInput!) {
    createOwner(input: $input) {
      success
      error
      owner {
        ...OwnerFields
        propertyHeaders {
          id
        }
        unitHeaders {
          id
        }
      }
    }
  }
  ${OwnerFieldsFragmentDoc}
`;
export type AddOwnerMutationFn = Apollo.MutationFunction<
  AddOwnerMutation,
  AddOwnerMutationVariables
>;

/**
 * __useAddOwnerMutation__
 *
 * To run a mutation, you first call `useAddOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOwnerMutation, { data, loading, error }] = useAddOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<AddOwnerMutation, AddOwnerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOwnerMutation, AddOwnerMutationVariables>(AddOwnerDocument, options);
}
export type AddOwnerMutationHookResult = ReturnType<typeof useAddOwnerMutation>;
export type AddOwnerMutationResult = Apollo.MutationResult<AddOwnerMutation>;
export type AddOwnerMutationOptions = Apollo.BaseMutationOptions<
  AddOwnerMutation,
  AddOwnerMutationVariables
>;
export const UpdateOwnerDocument = gql`
  mutation UpdateOwner($input: UpdateOwnerInput!) {
    updateOwner(input: $input) {
      success
      error
      owner {
        ...OwnerFields
      }
    }
  }
  ${OwnerFieldsFragmentDoc}
`;
export type UpdateOwnerMutationFn = Apollo.MutationFunction<
  UpdateOwnerMutation,
  UpdateOwnerMutationVariables
>;

/**
 * __useUpdateOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerMutation, { data, loading, error }] = useUpdateOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOwnerMutation, UpdateOwnerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOwnerMutation, UpdateOwnerMutationVariables>(
    UpdateOwnerDocument,
    options
  );
}
export type UpdateOwnerMutationHookResult = ReturnType<typeof useUpdateOwnerMutation>;
export type UpdateOwnerMutationResult = Apollo.MutationResult<UpdateOwnerMutation>;
export type UpdateOwnerMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnerMutation,
  UpdateOwnerMutationVariables
>;
export const UpdateOwnerFinancialsDocument = gql`
  mutation UpdateOwnerFinancials($input: UpdateOwnerFinancialsInput!) {
    updateOwnerFinancials(input: $input) {
      success
      error
      owner {
        ...OwnerFields
      }
    }
  }
  ${OwnerFieldsFragmentDoc}
`;
export type UpdateOwnerFinancialsMutationFn = Apollo.MutationFunction<
  UpdateOwnerFinancialsMutation,
  UpdateOwnerFinancialsMutationVariables
>;

/**
 * __useUpdateOwnerFinancialsMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerFinancialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerFinancialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerFinancialsMutation, { data, loading, error }] = useUpdateOwnerFinancialsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnerFinancialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnerFinancialsMutation,
    UpdateOwnerFinancialsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOwnerFinancialsMutation, UpdateOwnerFinancialsMutationVariables>(
    UpdateOwnerFinancialsDocument,
    options
  );
}
export type UpdateOwnerFinancialsMutationHookResult = ReturnType<
  typeof useUpdateOwnerFinancialsMutation
>;
export type UpdateOwnerFinancialsMutationResult =
  Apollo.MutationResult<UpdateOwnerFinancialsMutation>;
export type UpdateOwnerFinancialsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnerFinancialsMutation,
  UpdateOwnerFinancialsMutationVariables
>;
export const UpdateOwnerAutopayDocument = gql`
  mutation UpdateOwnerAutopay($input: UpdateOwnerAutopayInput!) {
    updateOwnerAutopay(input: $input) {
      success
      error
      owner {
        ...OwnerFields
      }
    }
  }
  ${OwnerFieldsFragmentDoc}
`;
export type UpdateOwnerAutopayMutationFn = Apollo.MutationFunction<
  UpdateOwnerAutopayMutation,
  UpdateOwnerAutopayMutationVariables
>;

/**
 * __useUpdateOwnerAutopayMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerAutopayMutation, { data, loading, error }] = useUpdateOwnerAutopayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnerAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnerAutopayMutation,
    UpdateOwnerAutopayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOwnerAutopayMutation, UpdateOwnerAutopayMutationVariables>(
    UpdateOwnerAutopayDocument,
    options
  );
}
export type UpdateOwnerAutopayMutationHookResult = ReturnType<typeof useUpdateOwnerAutopayMutation>;
export type UpdateOwnerAutopayMutationResult = Apollo.MutationResult<UpdateOwnerAutopayMutation>;
export type UpdateOwnerAutopayMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnerAutopayMutation,
  UpdateOwnerAutopayMutationVariables
>;
export const InviteOwnerAutopayDocument = gql`
  mutation InviteOwnerAutopay($input: InviteOwnerAutopayInput!) {
    inviteOwnerAutopay(input: $input) {
      success
      error
      owner {
        ...OwnerFields
      }
    }
  }
  ${OwnerFieldsFragmentDoc}
`;
export type InviteOwnerAutopayMutationFn = Apollo.MutationFunction<
  InviteOwnerAutopayMutation,
  InviteOwnerAutopayMutationVariables
>;

/**
 * __useInviteOwnerAutopayMutation__
 *
 * To run a mutation, you first call `useInviteOwnerAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteOwnerAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteOwnerAutopayMutation, { data, loading, error }] = useInviteOwnerAutopayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteOwnerAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteOwnerAutopayMutation,
    InviteOwnerAutopayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteOwnerAutopayMutation, InviteOwnerAutopayMutationVariables>(
    InviteOwnerAutopayDocument,
    options
  );
}
export type InviteOwnerAutopayMutationHookResult = ReturnType<typeof useInviteOwnerAutopayMutation>;
export type InviteOwnerAutopayMutationResult = Apollo.MutationResult<InviteOwnerAutopayMutation>;
export type InviteOwnerAutopayMutationOptions = Apollo.BaseMutationOptions<
  InviteOwnerAutopayMutation,
  InviteOwnerAutopayMutationVariables
>;
export const DeleteOwnerDocument = gql`
  mutation DeleteOwner($input: DeleteOwnerInput!) {
    deleteOwner(input: $input) {
      success
      error
    }
  }
`;
export type DeleteOwnerMutationFn = Apollo.MutationFunction<
  DeleteOwnerMutation,
  DeleteOwnerMutationVariables
>;

/**
 * __useDeleteOwnerMutation__
 *
 * To run a mutation, you first call `useDeleteOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOwnerMutation, { data, loading, error }] = useDeleteOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOwnerMutation, DeleteOwnerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOwnerMutation, DeleteOwnerMutationVariables>(
    DeleteOwnerDocument,
    options
  );
}
export type DeleteOwnerMutationHookResult = ReturnType<typeof useDeleteOwnerMutation>;
export type DeleteOwnerMutationResult = Apollo.MutationResult<DeleteOwnerMutation>;
export type DeleteOwnerMutationOptions = Apollo.BaseMutationOptions<
  DeleteOwnerMutation,
  DeleteOwnerMutationVariables
>;
export const RemoveOwnerPropertyDocument = gql`
  mutation RemoveOwnerProperty($input: RemoveOwnerPropertyInput!) {
    removeOwnerProperty(input: $input) {
      success
      error
      owner {
        id
        propertyIds
        properties {
          id
          ownerId
        }
      }
    }
  }
`;
export type RemoveOwnerPropertyMutationFn = Apollo.MutationFunction<
  RemoveOwnerPropertyMutation,
  RemoveOwnerPropertyMutationVariables
>;

/**
 * __useRemoveOwnerPropertyMutation__
 *
 * To run a mutation, you first call `useRemoveOwnerPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOwnerPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOwnerPropertyMutation, { data, loading, error }] = useRemoveOwnerPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOwnerPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOwnerPropertyMutation,
    RemoveOwnerPropertyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveOwnerPropertyMutation, RemoveOwnerPropertyMutationVariables>(
    RemoveOwnerPropertyDocument,
    options
  );
}
export type RemoveOwnerPropertyMutationHookResult = ReturnType<
  typeof useRemoveOwnerPropertyMutation
>;
export type RemoveOwnerPropertyMutationResult = Apollo.MutationResult<RemoveOwnerPropertyMutation>;
export type RemoveOwnerPropertyMutationOptions = Apollo.BaseMutationOptions<
  RemoveOwnerPropertyMutation,
  RemoveOwnerPropertyMutationVariables
>;
export const RemoveOwnerUnitDocument = gql`
  mutation RemoveOwnerUnit($input: RemoveOwnerUnitInput!) {
    removeOwnerUnit(input: $input) {
      success
      error
      owner {
        id
        unitIds
        units {
          id
          ownerId
        }
      }
    }
  }
`;
export type RemoveOwnerUnitMutationFn = Apollo.MutationFunction<
  RemoveOwnerUnitMutation,
  RemoveOwnerUnitMutationVariables
>;

/**
 * __useRemoveOwnerUnitMutation__
 *
 * To run a mutation, you first call `useRemoveOwnerUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOwnerUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOwnerUnitMutation, { data, loading, error }] = useRemoveOwnerUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOwnerUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOwnerUnitMutation,
    RemoveOwnerUnitMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveOwnerUnitMutation, RemoveOwnerUnitMutationVariables>(
    RemoveOwnerUnitDocument,
    options
  );
}
export type RemoveOwnerUnitMutationHookResult = ReturnType<typeof useRemoveOwnerUnitMutation>;
export type RemoveOwnerUnitMutationResult = Apollo.MutationResult<RemoveOwnerUnitMutation>;
export type RemoveOwnerUnitMutationOptions = Apollo.BaseMutationOptions<
  RemoveOwnerUnitMutation,
  RemoveOwnerUnitMutationVariables
>;
export const AddOwnerPropertyDocument = gql`
  mutation AddOwnerProperty($input: AddOwnerPropertyInput!) {
    addOwnerProperty(input: $input) {
      success
      error
      owner {
        id
        propertyIds
        properties {
          id
          ownerId
          reserveAmount
        }
      }
    }
  }
`;
export type AddOwnerPropertyMutationFn = Apollo.MutationFunction<
  AddOwnerPropertyMutation,
  AddOwnerPropertyMutationVariables
>;

/**
 * __useAddOwnerPropertyMutation__
 *
 * To run a mutation, you first call `useAddOwnerPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOwnerPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOwnerPropertyMutation, { data, loading, error }] = useAddOwnerPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOwnerPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOwnerPropertyMutation,
    AddOwnerPropertyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOwnerPropertyMutation, AddOwnerPropertyMutationVariables>(
    AddOwnerPropertyDocument,
    options
  );
}
export type AddOwnerPropertyMutationHookResult = ReturnType<typeof useAddOwnerPropertyMutation>;
export type AddOwnerPropertyMutationResult = Apollo.MutationResult<AddOwnerPropertyMutation>;
export type AddOwnerPropertyMutationOptions = Apollo.BaseMutationOptions<
  AddOwnerPropertyMutation,
  AddOwnerPropertyMutationVariables
>;
export const AddOwnerUnitDocument = gql`
  mutation AddOwnerUnit($input: AddOwnerUnitInput!) {
    addOwnerUnit(input: $input) {
      success
      error
      owner {
        id
        unitIds
        units {
          id
          ownerId
          reserveAmount
        }
      }
    }
  }
`;
export type AddOwnerUnitMutationFn = Apollo.MutationFunction<
  AddOwnerUnitMutation,
  AddOwnerUnitMutationVariables
>;

/**
 * __useAddOwnerUnitMutation__
 *
 * To run a mutation, you first call `useAddOwnerUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOwnerUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOwnerUnitMutation, { data, loading, error }] = useAddOwnerUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOwnerUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<AddOwnerUnitMutation, AddOwnerUnitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOwnerUnitMutation, AddOwnerUnitMutationVariables>(
    AddOwnerUnitDocument,
    options
  );
}
export type AddOwnerUnitMutationHookResult = ReturnType<typeof useAddOwnerUnitMutation>;
export type AddOwnerUnitMutationResult = Apollo.MutationResult<AddOwnerUnitMutation>;
export type AddOwnerUnitMutationOptions = Apollo.BaseMutationOptions<
  AddOwnerUnitMutation,
  AddOwnerUnitMutationVariables
>;
export const UpdateOwnerUnitDocument = gql`
  mutation UpdateOwnerUnit($input: UpdateUnitInput!) {
    updateUnit(input: $input) {
      unit {
        id
        ownerId
        reserveAmount
      }
    }
  }
`;
export type UpdateOwnerUnitMutationFn = Apollo.MutationFunction<
  UpdateOwnerUnitMutation,
  UpdateOwnerUnitMutationVariables
>;

/**
 * __useUpdateOwnerUnitMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerUnitMutation, { data, loading, error }] = useUpdateOwnerUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnerUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnerUnitMutation,
    UpdateOwnerUnitMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOwnerUnitMutation, UpdateOwnerUnitMutationVariables>(
    UpdateOwnerUnitDocument,
    options
  );
}
export type UpdateOwnerUnitMutationHookResult = ReturnType<typeof useUpdateOwnerUnitMutation>;
export type UpdateOwnerUnitMutationResult = Apollo.MutationResult<UpdateOwnerUnitMutation>;
export type UpdateOwnerUnitMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnerUnitMutation,
  UpdateOwnerUnitMutationVariables
>;
export const UpdateOwnerPropertyDocument = gql`
  mutation UpdateOwnerProperty($id: ID!, $input: UpdatePropertyInput!) {
    updateProperty(id: $id, input: $input) {
      id
      ownerId
      reserveAmount
    }
  }
`;
export type UpdateOwnerPropertyMutationFn = Apollo.MutationFunction<
  UpdateOwnerPropertyMutation,
  UpdateOwnerPropertyMutationVariables
>;

/**
 * __useUpdateOwnerPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerPropertyMutation, { data, loading, error }] = useUpdateOwnerPropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnerPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnerPropertyMutation,
    UpdateOwnerPropertyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOwnerPropertyMutation, UpdateOwnerPropertyMutationVariables>(
    UpdateOwnerPropertyDocument,
    options
  );
}
export type UpdateOwnerPropertyMutationHookResult = ReturnType<
  typeof useUpdateOwnerPropertyMutation
>;
export type UpdateOwnerPropertyMutationResult = Apollo.MutationResult<UpdateOwnerPropertyMutation>;
export type UpdateOwnerPropertyMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnerPropertyMutation,
  UpdateOwnerPropertyMutationVariables
>;
export const CreateOwnerNoteDocument = gql`
  mutation CreateOwnerNote($input: CreateOwnerNoteInput!) {
    createOwnerNote(input: $input) {
      owner {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type CreateOwnerNoteMutationFn = Apollo.MutationFunction<
  CreateOwnerNoteMutation,
  CreateOwnerNoteMutationVariables
>;

/**
 * __useCreateOwnerNoteMutation__
 *
 * To run a mutation, you first call `useCreateOwnerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOwnerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOwnerNoteMutation, { data, loading, error }] = useCreateOwnerNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOwnerNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOwnerNoteMutation,
    CreateOwnerNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOwnerNoteMutation, CreateOwnerNoteMutationVariables>(
    CreateOwnerNoteDocument,
    options
  );
}
export type CreateOwnerNoteMutationHookResult = ReturnType<typeof useCreateOwnerNoteMutation>;
export type CreateOwnerNoteMutationResult = Apollo.MutationResult<CreateOwnerNoteMutation>;
export type CreateOwnerNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateOwnerNoteMutation,
  CreateOwnerNoteMutationVariables
>;
export const DeleteOwnerNoteDocument = gql`
  mutation DeleteOwnerNote($input: DeleteOwnerNoteInput!) {
    deleteOwnerNote(input: $input) {
      owner {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type DeleteOwnerNoteMutationFn = Apollo.MutationFunction<
  DeleteOwnerNoteMutation,
  DeleteOwnerNoteMutationVariables
>;

/**
 * __useDeleteOwnerNoteMutation__
 *
 * To run a mutation, you first call `useDeleteOwnerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOwnerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOwnerNoteMutation, { data, loading, error }] = useDeleteOwnerNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOwnerNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOwnerNoteMutation,
    DeleteOwnerNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOwnerNoteMutation, DeleteOwnerNoteMutationVariables>(
    DeleteOwnerNoteDocument,
    options
  );
}
export type DeleteOwnerNoteMutationHookResult = ReturnType<typeof useDeleteOwnerNoteMutation>;
export type DeleteOwnerNoteMutationResult = Apollo.MutationResult<DeleteOwnerNoteMutation>;
export type DeleteOwnerNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteOwnerNoteMutation,
  DeleteOwnerNoteMutationVariables
>;
export const OwnerNotesDocument = gql`
  query OwnerNotes($id: ID!) {
    owner(id: $id) {
      id
      notes {
        id
        createdName
        createdSub
        createdZ
        text
      }
    }
  }
`;

/**
 * __useOwnerNotesQuery__
 *
 * To run a query within a React component, call `useOwnerNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOwnerNotesQuery(
  baseOptions: Apollo.QueryHookOptions<OwnerNotesQuery, OwnerNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OwnerNotesQuery, OwnerNotesQueryVariables>(OwnerNotesDocument, options);
}
export function useOwnerNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OwnerNotesQuery, OwnerNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OwnerNotesQuery, OwnerNotesQueryVariables>(
    OwnerNotesDocument,
    options
  );
}
export type OwnerNotesQueryHookResult = ReturnType<typeof useOwnerNotesQuery>;
export type OwnerNotesLazyQueryHookResult = ReturnType<typeof useOwnerNotesLazyQuery>;
export type OwnerNotesQueryResult = Apollo.QueryResult<OwnerNotesQuery, OwnerNotesQueryVariables>;
export const UpdateOwnerManagementFeeDocument = gql`
  mutation UpdateOwnerManagementFee($input: UpdateOwnerManagementFeeInput!) {
    updateOwnerManagementFee(input: $input) {
      success
      error
      owner {
        id
        assetFees {
          assetId
          feeRate
          feeAmount
          flatFee
          nrtFeeAmount
          nrtFeeRate
        }
      }
    }
  }
`;
export type UpdateOwnerManagementFeeMutationFn = Apollo.MutationFunction<
  UpdateOwnerManagementFeeMutation,
  UpdateOwnerManagementFeeMutationVariables
>;

/**
 * __useUpdateOwnerManagementFeeMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerManagementFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerManagementFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerManagementFeeMutation, { data, loading, error }] = useUpdateOwnerManagementFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnerManagementFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnerManagementFeeMutation,
    UpdateOwnerManagementFeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOwnerManagementFeeMutation,
    UpdateOwnerManagementFeeMutationVariables
  >(UpdateOwnerManagementFeeDocument, options);
}
export type UpdateOwnerManagementFeeMutationHookResult = ReturnType<
  typeof useUpdateOwnerManagementFeeMutation
>;
export type UpdateOwnerManagementFeeMutationResult =
  Apollo.MutationResult<UpdateOwnerManagementFeeMutation>;
export type UpdateOwnerManagementFeeMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnerManagementFeeMutation,
  UpdateOwnerManagementFeeMutationVariables
>;
export const GetOwnersDocument = gql`
  query GetOwners($nextToken: String, $filter: OwnersFilter) {
    account {
      id
      listOwners(nextToken: $nextToken, filter: $filter) {
        items {
          ...OwnerListFields
        }
        nextToken
      }
    }
  }
  ${OwnerListFieldsFragmentDoc}
`;

/**
 * __useGetOwnersQuery__
 *
 * To run a query within a React component, call `useGetOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnersQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOwnersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOwnersQuery, GetOwnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnersQuery, GetOwnersQueryVariables>(GetOwnersDocument, options);
}
export function useGetOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOwnersQuery, GetOwnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnersQuery, GetOwnersQueryVariables>(GetOwnersDocument, options);
}
export type GetOwnersQueryHookResult = ReturnType<typeof useGetOwnersQuery>;
export type GetOwnersLazyQueryHookResult = ReturnType<typeof useGetOwnersLazyQuery>;
export type GetOwnersQueryResult = Apollo.QueryResult<GetOwnersQuery, GetOwnersQueryVariables>;
export const CreateUnitsDocument = gql`
  mutation CreateUnits($input: CreateUnitsInput!) {
    createUnits(input: $input) {
      success
      error
    }
  }
`;
export type CreateUnitsMutationFn = Apollo.MutationFunction<
  CreateUnitsMutation,
  CreateUnitsMutationVariables
>;

/**
 * __useCreateUnitsMutation__
 *
 * To run a mutation, you first call `useCreateUnitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnitsMutation, { data, loading, error }] = useCreateUnitsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUnitsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUnitsMutation, CreateUnitsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUnitsMutation, CreateUnitsMutationVariables>(
    CreateUnitsDocument,
    options
  );
}
export type CreateUnitsMutationHookResult = ReturnType<typeof useCreateUnitsMutation>;
export type CreateUnitsMutationResult = Apollo.MutationResult<CreateUnitsMutation>;
export type CreateUnitsMutationOptions = Apollo.BaseMutationOptions<
  CreateUnitsMutation,
  CreateUnitsMutationVariables
>;
export const ResidentContactListForPropertyDocument = gql`
  query ResidentContactListForProperty($id: ID!) {
    property(id: $id) {
      id
      name
      enabled
      owner {
        ...ContactListOwnerFields
      }
      address {
        city
        postal
        province
        street
      }
      buildings {
        id
        name
        address {
          city
          postal
          province
          street
        }
      }
      units {
        id
        name
        owner {
          ...ContactListOwnerFields
        }
        buildingId
        currentResidencies {
          id
          residents {
            id
            leaseHolder
            title
            resident {
              id
              __typename
              name
              emails
              phones
              ... on Owner {
                ...ContactListOwnerFields
              }
              ... on Tenant {
                leaseHolder
                correspondenceMethod
                mailingAddress {
                  suite
                  street
                  city
                  province
                  postal
                }
                notes {
                  id
                  createdName
                  createdSub
                  createdZ
                  text
                }
              }
            }
          }
        }
      }
    }
  }
  ${ContactListOwnerFieldsFragmentDoc}
`;

/**
 * __useResidentContactListForPropertyQuery__
 *
 * To run a query within a React component, call `useResidentContactListForPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useResidentContactListForPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResidentContactListForPropertyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResidentContactListForPropertyQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResidentContactListForPropertyQuery,
    ResidentContactListForPropertyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResidentContactListForPropertyQuery,
    ResidentContactListForPropertyQueryVariables
  >(ResidentContactListForPropertyDocument, options);
}
export function useResidentContactListForPropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResidentContactListForPropertyQuery,
    ResidentContactListForPropertyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResidentContactListForPropertyQuery,
    ResidentContactListForPropertyQueryVariables
  >(ResidentContactListForPropertyDocument, options);
}
export type ResidentContactListForPropertyQueryHookResult = ReturnType<
  typeof useResidentContactListForPropertyQuery
>;
export type ResidentContactListForPropertyLazyQueryHookResult = ReturnType<
  typeof useResidentContactListForPropertyLazyQuery
>;
export type ResidentContactListForPropertyQueryResult = Apollo.QueryResult<
  ResidentContactListForPropertyQuery,
  ResidentContactListForPropertyQueryVariables
>;
export const GetPropertyForFloorplanDocument = gql`
  query GetPropertyForFloorplan($id: ID!) {
    property(id: $id) {
      id
      name
      enabled
      address {
        city
        postal
        province
        street
      }
      units {
        id
        name
        occupancy
        buildingId
        floorplanId
        bedrooms
        bathrooms
        sqft
      }
      buildings {
        id
        name
        key
      }
    }
  }
`;

/**
 * __useGetPropertyForFloorplanQuery__
 *
 * To run a query within a React component, call `useGetPropertyForFloorplanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyForFloorplanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyForFloorplanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyForFloorplanQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyForFloorplanQuery,
    GetPropertyForFloorplanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyForFloorplanQuery, GetPropertyForFloorplanQueryVariables>(
    GetPropertyForFloorplanDocument,
    options
  );
}
export function useGetPropertyForFloorplanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyForFloorplanQuery,
    GetPropertyForFloorplanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyForFloorplanQuery, GetPropertyForFloorplanQueryVariables>(
    GetPropertyForFloorplanDocument,
    options
  );
}
export type GetPropertyForFloorplanQueryHookResult = ReturnType<
  typeof useGetPropertyForFloorplanQuery
>;
export type GetPropertyForFloorplanLazyQueryHookResult = ReturnType<
  typeof useGetPropertyForFloorplanLazyQuery
>;
export type GetPropertyForFloorplanQueryResult = Apollo.QueryResult<
  GetPropertyForFloorplanQuery,
  GetPropertyForFloorplanQueryVariables
>;
export const CreatePropertyFloorplanDocument = gql`
  mutation CreatePropertyFloorplan($input: CreatePropertyFloorplanInput!) {
    createPropertyFloorplan(input: $input) {
      success
      floorplan {
        id
        name
      }
      property {
        id
        accountId
        floorplans {
          ...FloorplanListFields
        }
        units {
          id
          name
          occupancy
          buildingId
          floorplanId
          bedrooms
          bathrooms
          sqft
        }
      }
    }
  }
  ${FloorplanListFieldsFragmentDoc}
`;
export type CreatePropertyFloorplanMutationFn = Apollo.MutationFunction<
  CreatePropertyFloorplanMutation,
  CreatePropertyFloorplanMutationVariables
>;

/**
 * __useCreatePropertyFloorplanMutation__
 *
 * To run a mutation, you first call `useCreatePropertyFloorplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePropertyFloorplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyFloorplanMutation, { data, loading, error }] = useCreatePropertyFloorplanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePropertyFloorplanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePropertyFloorplanMutation,
    CreatePropertyFloorplanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePropertyFloorplanMutation,
    CreatePropertyFloorplanMutationVariables
  >(CreatePropertyFloorplanDocument, options);
}
export type CreatePropertyFloorplanMutationHookResult = ReturnType<
  typeof useCreatePropertyFloorplanMutation
>;
export type CreatePropertyFloorplanMutationResult =
  Apollo.MutationResult<CreatePropertyFloorplanMutation>;
export type CreatePropertyFloorplanMutationOptions = Apollo.BaseMutationOptions<
  CreatePropertyFloorplanMutation,
  CreatePropertyFloorplanMutationVariables
>;
export const CreateBuildingFloorplanDocument = gql`
  mutation CreateBuildingFloorplan($input: CreateBuildingFloorplanInput!) {
    createBuildingFloorplan(input: $input) {
      success
      floorplan {
        id
        name
      }
      property {
        id
        accountId
        buildings {
          id
          floorplans {
            ...FloorplanListFields
          }
        }
        units {
          id
          name
          occupancy
          buildingId
          floorplanId
          bedrooms
          bathrooms
          sqft
        }
      }
    }
  }
  ${FloorplanListFieldsFragmentDoc}
`;
export type CreateBuildingFloorplanMutationFn = Apollo.MutationFunction<
  CreateBuildingFloorplanMutation,
  CreateBuildingFloorplanMutationVariables
>;

/**
 * __useCreateBuildingFloorplanMutation__
 *
 * To run a mutation, you first call `useCreateBuildingFloorplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBuildingFloorplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBuildingFloorplanMutation, { data, loading, error }] = useCreateBuildingFloorplanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBuildingFloorplanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBuildingFloorplanMutation,
    CreateBuildingFloorplanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBuildingFloorplanMutation,
    CreateBuildingFloorplanMutationVariables
  >(CreateBuildingFloorplanDocument, options);
}
export type CreateBuildingFloorplanMutationHookResult = ReturnType<
  typeof useCreateBuildingFloorplanMutation
>;
export type CreateBuildingFloorplanMutationResult =
  Apollo.MutationResult<CreateBuildingFloorplanMutation>;
export type CreateBuildingFloorplanMutationOptions = Apollo.BaseMutationOptions<
  CreateBuildingFloorplanMutation,
  CreateBuildingFloorplanMutationVariables
>;
export const GetDefaultFloorplansDocument = gql`
  query GetDefaultFloorplans {
    defaultFloorplanTemplates {
      ...FloorplanListFields
    }
  }
  ${FloorplanListFieldsFragmentDoc}
`;

/**
 * __useGetDefaultFloorplansQuery__
 *
 * To run a query within a React component, call `useGetDefaultFloorplansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultFloorplansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultFloorplansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultFloorplansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDefaultFloorplansQuery,
    GetDefaultFloorplansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDefaultFloorplansQuery, GetDefaultFloorplansQueryVariables>(
    GetDefaultFloorplansDocument,
    options
  );
}
export function useGetDefaultFloorplansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDefaultFloorplansQuery,
    GetDefaultFloorplansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDefaultFloorplansQuery, GetDefaultFloorplansQueryVariables>(
    GetDefaultFloorplansDocument,
    options
  );
}
export type GetDefaultFloorplansQueryHookResult = ReturnType<typeof useGetDefaultFloorplansQuery>;
export type GetDefaultFloorplansLazyQueryHookResult = ReturnType<
  typeof useGetDefaultFloorplansLazyQuery
>;
export type GetDefaultFloorplansQueryResult = Apollo.QueryResult<
  GetDefaultFloorplansQuery,
  GetDefaultFloorplansQueryVariables
>;
export const ListFloorplansDocument = gql`
  query ListFloorplans($nextToken: String) {
    account {
      id
      listFloorplans(nextToken: $nextToken) {
        items @connection(key: "id") {
          ...FloorplanListFields
        }
        nextToken
      }
    }
  }
  ${FloorplanListFieldsFragmentDoc}
`;

/**
 * __useListFloorplansQuery__
 *
 * To run a query within a React component, call `useListFloorplansQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFloorplansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFloorplansQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListFloorplansQuery(
  baseOptions?: Apollo.QueryHookOptions<ListFloorplansQuery, ListFloorplansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListFloorplansQuery, ListFloorplansQueryVariables>(
    ListFloorplansDocument,
    options
  );
}
export function useListFloorplansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListFloorplansQuery, ListFloorplansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListFloorplansQuery, ListFloorplansQueryVariables>(
    ListFloorplansDocument,
    options
  );
}
export type ListFloorplansQueryHookResult = ReturnType<typeof useListFloorplansQuery>;
export type ListFloorplansLazyQueryHookResult = ReturnType<typeof useListFloorplansLazyQuery>;
export type ListFloorplansQueryResult = Apollo.QueryResult<
  ListFloorplansQuery,
  ListFloorplansQueryVariables
>;
export const GetFloorplanDocument = gql`
  query GetFloorplan($id: ID!) {
    floorplan(id: $id) {
      ...FloorplanFields
    }
  }
  ${FloorplanFieldsFragmentDoc}
`;

/**
 * __useGetFloorplanQuery__
 *
 * To run a query within a React component, call `useGetFloorplanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFloorplanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFloorplanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFloorplanQuery(
  baseOptions: Apollo.QueryHookOptions<GetFloorplanQuery, GetFloorplanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFloorplanQuery, GetFloorplanQueryVariables>(
    GetFloorplanDocument,
    options
  );
}
export function useGetFloorplanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFloorplanQuery, GetFloorplanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFloorplanQuery, GetFloorplanQueryVariables>(
    GetFloorplanDocument,
    options
  );
}
export type GetFloorplanQueryHookResult = ReturnType<typeof useGetFloorplanQuery>;
export type GetFloorplanLazyQueryHookResult = ReturnType<typeof useGetFloorplanLazyQuery>;
export type GetFloorplanQueryResult = Apollo.QueryResult<
  GetFloorplanQuery,
  GetFloorplanQueryVariables
>;
export const GetDefaultFeaturesDocument = gql`
  query GetDefaultFeatures {
    floorplanAreaDefaultFeatures {
      id
      name
      label
      description
    }
  }
`;

/**
 * __useGetDefaultFeaturesQuery__
 *
 * To run a query within a React component, call `useGetDefaultFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultFeaturesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDefaultFeaturesQuery, GetDefaultFeaturesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDefaultFeaturesQuery, GetDefaultFeaturesQueryVariables>(
    GetDefaultFeaturesDocument,
    options
  );
}
export function useGetDefaultFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDefaultFeaturesQuery,
    GetDefaultFeaturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDefaultFeaturesQuery, GetDefaultFeaturesQueryVariables>(
    GetDefaultFeaturesDocument,
    options
  );
}
export type GetDefaultFeaturesQueryHookResult = ReturnType<typeof useGetDefaultFeaturesQuery>;
export type GetDefaultFeaturesLazyQueryHookResult = ReturnType<
  typeof useGetDefaultFeaturesLazyQuery
>;
export type GetDefaultFeaturesQueryResult = Apollo.QueryResult<
  GetDefaultFeaturesQuery,
  GetDefaultFeaturesQueryVariables
>;
export const GetDefaultAdditionalFeaturesDocument = gql`
  query GetDefaultAdditionalFeatures {
    floorplanDefaultAdditionalFeatures
  }
`;

/**
 * __useGetDefaultAdditionalFeaturesQuery__
 *
 * To run a query within a React component, call `useGetDefaultAdditionalFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultAdditionalFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultAdditionalFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultAdditionalFeaturesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDefaultAdditionalFeaturesQuery,
    GetDefaultAdditionalFeaturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDefaultAdditionalFeaturesQuery,
    GetDefaultAdditionalFeaturesQueryVariables
  >(GetDefaultAdditionalFeaturesDocument, options);
}
export function useGetDefaultAdditionalFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDefaultAdditionalFeaturesQuery,
    GetDefaultAdditionalFeaturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDefaultAdditionalFeaturesQuery,
    GetDefaultAdditionalFeaturesQueryVariables
  >(GetDefaultAdditionalFeaturesDocument, options);
}
export type GetDefaultAdditionalFeaturesQueryHookResult = ReturnType<
  typeof useGetDefaultAdditionalFeaturesQuery
>;
export type GetDefaultAdditionalFeaturesLazyQueryHookResult = ReturnType<
  typeof useGetDefaultAdditionalFeaturesLazyQuery
>;
export type GetDefaultAdditionalFeaturesQueryResult = Apollo.QueryResult<
  GetDefaultAdditionalFeaturesQuery,
  GetDefaultAdditionalFeaturesQueryVariables
>;
export const GetCondoFeeUnitsForPropertyDocument = gql`
  query GetCondoFeeUnitsForProperty($nextToken: String, $filter: UnitFilter) {
    account {
      id
      listUnits(nextToken: $nextToken, filter: $filter) {
        nextToken
        items {
          id
          ...CondoFeeUnitFields
        }
      }
    }
  }
  ${CondoFeeUnitFieldsFragmentDoc}
`;

/**
 * __useGetCondoFeeUnitsForPropertyQuery__
 *
 * To run a query within a React component, call `useGetCondoFeeUnitsForPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCondoFeeUnitsForPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCondoFeeUnitsForPropertyQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCondoFeeUnitsForPropertyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCondoFeeUnitsForPropertyQuery,
    GetCondoFeeUnitsForPropertyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCondoFeeUnitsForPropertyQuery,
    GetCondoFeeUnitsForPropertyQueryVariables
  >(GetCondoFeeUnitsForPropertyDocument, options);
}
export function useGetCondoFeeUnitsForPropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCondoFeeUnitsForPropertyQuery,
    GetCondoFeeUnitsForPropertyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCondoFeeUnitsForPropertyQuery,
    GetCondoFeeUnitsForPropertyQueryVariables
  >(GetCondoFeeUnitsForPropertyDocument, options);
}
export type GetCondoFeeUnitsForPropertyQueryHookResult = ReturnType<
  typeof useGetCondoFeeUnitsForPropertyQuery
>;
export type GetCondoFeeUnitsForPropertyLazyQueryHookResult = ReturnType<
  typeof useGetCondoFeeUnitsForPropertyLazyQuery
>;
export type GetCondoFeeUnitsForPropertyQueryResult = Apollo.QueryResult<
  GetCondoFeeUnitsForPropertyQuery,
  GetCondoFeeUnitsForPropertyQueryVariables
>;
export const GetPropertyForCondoFeesDocument = gql`
  query GetPropertyForCondoFees($id: ID!) {
    property(id: $id) {
      id
      name
      ownerId
      address {
        province
      }
    }
  }
`;

/**
 * __useGetPropertyForCondoFeesQuery__
 *
 * To run a query within a React component, call `useGetPropertyForCondoFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyForCondoFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyForCondoFeesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyForCondoFeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyForCondoFeesQuery,
    GetPropertyForCondoFeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyForCondoFeesQuery, GetPropertyForCondoFeesQueryVariables>(
    GetPropertyForCondoFeesDocument,
    options
  );
}
export function useGetPropertyForCondoFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyForCondoFeesQuery,
    GetPropertyForCondoFeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyForCondoFeesQuery, GetPropertyForCondoFeesQueryVariables>(
    GetPropertyForCondoFeesDocument,
    options
  );
}
export type GetPropertyForCondoFeesQueryHookResult = ReturnType<
  typeof useGetPropertyForCondoFeesQuery
>;
export type GetPropertyForCondoFeesLazyQueryHookResult = ReturnType<
  typeof useGetPropertyForCondoFeesLazyQuery
>;
export type GetPropertyForCondoFeesQueryResult = Apollo.QueryResult<
  GetPropertyForCondoFeesQuery,
  GetPropertyForCondoFeesQueryVariables
>;
export const UpdateFloorplanDocument = gql`
  mutation UpdateFloorplan($id: ID!, $input: FloorplanInput!) {
    updateFloorplan(id: $id, input: $input) {
      success
      floorplan {
        ...FloorplanFields
      }
    }
  }
  ${FloorplanFieldsFragmentDoc}
`;
export type UpdateFloorplanMutationFn = Apollo.MutationFunction<
  UpdateFloorplanMutation,
  UpdateFloorplanMutationVariables
>;

/**
 * __useUpdateFloorplanMutation__
 *
 * To run a mutation, you first call `useUpdateFloorplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFloorplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFloorplanMutation, { data, loading, error }] = useUpdateFloorplanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFloorplanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFloorplanMutation,
    UpdateFloorplanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFloorplanMutation, UpdateFloorplanMutationVariables>(
    UpdateFloorplanDocument,
    options
  );
}
export type UpdateFloorplanMutationHookResult = ReturnType<typeof useUpdateFloorplanMutation>;
export type UpdateFloorplanMutationResult = Apollo.MutationResult<UpdateFloorplanMutation>;
export type UpdateFloorplanMutationOptions = Apollo.BaseMutationOptions<
  UpdateFloorplanMutation,
  UpdateFloorplanMutationVariables
>;
export const UnitEffectsForPropertyDocument = gql`
  query UnitEffectsForProperty($id: ID!) {
    property(id: $id) {
      id
      name
      units {
        id
        name
        occupancy
        property {
          id
          timezone
        }
        currentResidencies {
          id
          startZ
          endZ
          effects {
            id
            feeId
            effect
            startZ
          }
        }
      }
    }
  }
`;

/**
 * __useUnitEffectsForPropertyQuery__
 *
 * To run a query within a React component, call `useUnitEffectsForPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitEffectsForPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitEffectsForPropertyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnitEffectsForPropertyQuery(
  baseOptions: Apollo.QueryHookOptions<
    UnitEffectsForPropertyQuery,
    UnitEffectsForPropertyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnitEffectsForPropertyQuery, UnitEffectsForPropertyQueryVariables>(
    UnitEffectsForPropertyDocument,
    options
  );
}
export function useUnitEffectsForPropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnitEffectsForPropertyQuery,
    UnitEffectsForPropertyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnitEffectsForPropertyQuery, UnitEffectsForPropertyQueryVariables>(
    UnitEffectsForPropertyDocument,
    options
  );
}
export type UnitEffectsForPropertyQueryHookResult = ReturnType<
  typeof useUnitEffectsForPropertyQuery
>;
export type UnitEffectsForPropertyLazyQueryHookResult = ReturnType<
  typeof useUnitEffectsForPropertyLazyQuery
>;
export type UnitEffectsForPropertyQueryResult = Apollo.QueryResult<
  UnitEffectsForPropertyQuery,
  UnitEffectsForPropertyQueryVariables
>;
export const AddBuildingDocument = gql`
  mutation AddBuilding($input: CreateBuildingInput!) {
    createBuilding(input: $input) {
      success
      error
      property {
        id
        name
        buildings {
          ...BuildingFields
        }
      }
      building {
        ...BuildingFields
      }
      unitNames {
        namesFound
        totalUnits
        complete
        names
      }
    }
  }
  ${BuildingFieldsFragmentDoc}
`;
export type AddBuildingMutationFn = Apollo.MutationFunction<
  AddBuildingMutation,
  AddBuildingMutationVariables
>;

/**
 * __useAddBuildingMutation__
 *
 * To run a mutation, you first call `useAddBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBuildingMutation, { data, loading, error }] = useAddBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBuildingMutation(
  baseOptions?: Apollo.MutationHookOptions<AddBuildingMutation, AddBuildingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddBuildingMutation, AddBuildingMutationVariables>(
    AddBuildingDocument,
    options
  );
}
export type AddBuildingMutationHookResult = ReturnType<typeof useAddBuildingMutation>;
export type AddBuildingMutationResult = Apollo.MutationResult<AddBuildingMutation>;
export type AddBuildingMutationOptions = Apollo.BaseMutationOptions<
  AddBuildingMutation,
  AddBuildingMutationVariables
>;
export const UpdateBuildingDocument = gql`
  mutation UpdateBuilding($input: UpdateBuildingInput!) {
    updateBuilding(input: $input) {
      success
      error
      property {
        id
        name
        buildings {
          ...BuildingFields
        }
      }
    }
  }
  ${BuildingFieldsFragmentDoc}
`;
export type UpdateBuildingMutationFn = Apollo.MutationFunction<
  UpdateBuildingMutation,
  UpdateBuildingMutationVariables
>;

/**
 * __useUpdateBuildingMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingMutation, { data, loading, error }] = useUpdateBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuildingMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBuildingMutation, UpdateBuildingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBuildingMutation, UpdateBuildingMutationVariables>(
    UpdateBuildingDocument,
    options
  );
}
export type UpdateBuildingMutationHookResult = ReturnType<typeof useUpdateBuildingMutation>;
export type UpdateBuildingMutationResult = Apollo.MutationResult<UpdateBuildingMutation>;
export type UpdateBuildingMutationOptions = Apollo.BaseMutationOptions<
  UpdateBuildingMutation,
  UpdateBuildingMutationVariables
>;
export const CreateBuildingNoteDocument = gql`
  mutation CreateBuildingNote($input: CreateBuildingNoteInput!) {
    createBuildingNote(input: $input) {
      property {
        id
        name
        buildings {
          id
          name
          notes {
            id
            createdName
            createdSub
            createdZ
            text
          }
        }
      }
    }
  }
`;
export type CreateBuildingNoteMutationFn = Apollo.MutationFunction<
  CreateBuildingNoteMutation,
  CreateBuildingNoteMutationVariables
>;

/**
 * __useCreateBuildingNoteMutation__
 *
 * To run a mutation, you first call `useCreateBuildingNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBuildingNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBuildingNoteMutation, { data, loading, error }] = useCreateBuildingNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBuildingNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBuildingNoteMutation,
    CreateBuildingNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBuildingNoteMutation, CreateBuildingNoteMutationVariables>(
    CreateBuildingNoteDocument,
    options
  );
}
export type CreateBuildingNoteMutationHookResult = ReturnType<typeof useCreateBuildingNoteMutation>;
export type CreateBuildingNoteMutationResult = Apollo.MutationResult<CreateBuildingNoteMutation>;
export type CreateBuildingNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateBuildingNoteMutation,
  CreateBuildingNoteMutationVariables
>;
export const DeleteBuildingNoteDocument = gql`
  mutation DeleteBuildingNote($input: DeleteBuildingNoteInput!) {
    deleteBuildingNote(input: $input) {
      property {
        id
        name
        buildings {
          id
          name
          notes {
            id
            createdName
            createdSub
            createdZ
            text
          }
        }
      }
    }
  }
`;
export type DeleteBuildingNoteMutationFn = Apollo.MutationFunction<
  DeleteBuildingNoteMutation,
  DeleteBuildingNoteMutationVariables
>;

/**
 * __useDeleteBuildingNoteMutation__
 *
 * To run a mutation, you first call `useDeleteBuildingNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBuildingNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBuildingNoteMutation, { data, loading, error }] = useDeleteBuildingNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBuildingNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBuildingNoteMutation,
    DeleteBuildingNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBuildingNoteMutation, DeleteBuildingNoteMutationVariables>(
    DeleteBuildingNoteDocument,
    options
  );
}
export type DeleteBuildingNoteMutationHookResult = ReturnType<typeof useDeleteBuildingNoteMutation>;
export type DeleteBuildingNoteMutationResult = Apollo.MutationResult<DeleteBuildingNoteMutation>;
export type DeleteBuildingNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteBuildingNoteMutation,
  DeleteBuildingNoteMutationVariables
>;
export const BuildingNotesDocument = gql`
  query BuildingNotes($input: BuildingFilter!) {
    building(filter: $input) {
      id
      notes {
        id
        createdName
        createdSub
        createdZ
        text
      }
    }
  }
`;

/**
 * __useBuildingNotesQuery__
 *
 * To run a query within a React component, call `useBuildingNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingNotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildingNotesQuery(
  baseOptions: Apollo.QueryHookOptions<BuildingNotesQuery, BuildingNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuildingNotesQuery, BuildingNotesQueryVariables>(
    BuildingNotesDocument,
    options
  );
}
export function useBuildingNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuildingNotesQuery, BuildingNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuildingNotesQuery, BuildingNotesQueryVariables>(
    BuildingNotesDocument,
    options
  );
}
export type BuildingNotesQueryHookResult = ReturnType<typeof useBuildingNotesQuery>;
export type BuildingNotesLazyQueryHookResult = ReturnType<typeof useBuildingNotesLazyQuery>;
export type BuildingNotesQueryResult = Apollo.QueryResult<
  BuildingNotesQuery,
  BuildingNotesQueryVariables
>;
export const GetBuildingSpecsDocument = gql`
  query GetBuildingSpecs($input: BuildingFilter!) {
    building(filter: $input) {
      id
      specs {
        ...PropertySpecsFields
      }
    }
  }
  ${PropertySpecsFieldsFragmentDoc}
`;

/**
 * __useGetBuildingSpecsQuery__
 *
 * To run a query within a React component, call `useGetBuildingSpecsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingSpecsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingSpecsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBuildingSpecsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBuildingSpecsQuery, GetBuildingSpecsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBuildingSpecsQuery, GetBuildingSpecsQueryVariables>(
    GetBuildingSpecsDocument,
    options
  );
}
export function useGetBuildingSpecsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingSpecsQuery, GetBuildingSpecsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBuildingSpecsQuery, GetBuildingSpecsQueryVariables>(
    GetBuildingSpecsDocument,
    options
  );
}
export type GetBuildingSpecsQueryHookResult = ReturnType<typeof useGetBuildingSpecsQuery>;
export type GetBuildingSpecsLazyQueryHookResult = ReturnType<typeof useGetBuildingSpecsLazyQuery>;
export type GetBuildingSpecsQueryResult = Apollo.QueryResult<
  GetBuildingSpecsQuery,
  GetBuildingSpecsQueryVariables
>;
export const UpdateBuildingSpecsDocument = gql`
  mutation UpdateBuildingSpecs($input: UpdateBuildingSpecsInput!) {
    updateBuildingSpecs(input: $input) {
      property {
        id
        name
        buildings {
          id
          name
          specs {
            ...PropertySpecsFields
          }
        }
      }
    }
  }
  ${PropertySpecsFieldsFragmentDoc}
`;
export type UpdateBuildingSpecsMutationFn = Apollo.MutationFunction<
  UpdateBuildingSpecsMutation,
  UpdateBuildingSpecsMutationVariables
>;

/**
 * __useUpdateBuildingSpecsMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingSpecsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingSpecsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingSpecsMutation, { data, loading, error }] = useUpdateBuildingSpecsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuildingSpecsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBuildingSpecsMutation,
    UpdateBuildingSpecsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBuildingSpecsMutation, UpdateBuildingSpecsMutationVariables>(
    UpdateBuildingSpecsDocument,
    options
  );
}
export type UpdateBuildingSpecsMutationHookResult = ReturnType<
  typeof useUpdateBuildingSpecsMutation
>;
export type UpdateBuildingSpecsMutationResult = Apollo.MutationResult<UpdateBuildingSpecsMutation>;
export type UpdateBuildingSpecsMutationOptions = Apollo.BaseMutationOptions<
  UpdateBuildingSpecsMutation,
  UpdateBuildingSpecsMutationVariables
>;
export const BuildingDocumentsDocument = gql`
  query BuildingDocuments($input: BuildingFilter!) {
    building(filter: $input) {
      id
      documents {
        ...DocumentFields
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`;

/**
 * __useBuildingDocumentsQuery__
 *
 * To run a query within a React component, call `useBuildingDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingDocumentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildingDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<BuildingDocumentsQuery, BuildingDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuildingDocumentsQuery, BuildingDocumentsQueryVariables>(
    BuildingDocumentsDocument,
    options
  );
}
export function useBuildingDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuildingDocumentsQuery, BuildingDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuildingDocumentsQuery, BuildingDocumentsQueryVariables>(
    BuildingDocumentsDocument,
    options
  );
}
export type BuildingDocumentsQueryHookResult = ReturnType<typeof useBuildingDocumentsQuery>;
export type BuildingDocumentsLazyQueryHookResult = ReturnType<typeof useBuildingDocumentsLazyQuery>;
export type BuildingDocumentsQueryResult = Apollo.QueryResult<
  BuildingDocumentsQuery,
  BuildingDocumentsQueryVariables
>;
export const RenameBuildingDocumentDocument = gql`
  mutation RenameBuildingDocument($input: RenameBuildingDocumentInput!) {
    renameBuildingDocument(input: $input) {
      property {
        id
        buildings {
          id
          documents {
            ...DocumentFields
          }
        }
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`;
export type RenameBuildingDocumentMutationFn = Apollo.MutationFunction<
  RenameBuildingDocumentMutation,
  RenameBuildingDocumentMutationVariables
>;

/**
 * __useRenameBuildingDocumentMutation__
 *
 * To run a mutation, you first call `useRenameBuildingDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameBuildingDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameBuildingDocumentMutation, { data, loading, error }] = useRenameBuildingDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameBuildingDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameBuildingDocumentMutation,
    RenameBuildingDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RenameBuildingDocumentMutation,
    RenameBuildingDocumentMutationVariables
  >(RenameBuildingDocumentDocument, options);
}
export type RenameBuildingDocumentMutationHookResult = ReturnType<
  typeof useRenameBuildingDocumentMutation
>;
export type RenameBuildingDocumentMutationResult =
  Apollo.MutationResult<RenameBuildingDocumentMutation>;
export type RenameBuildingDocumentMutationOptions = Apollo.BaseMutationOptions<
  RenameBuildingDocumentMutation,
  RenameBuildingDocumentMutationVariables
>;
export const DeleteBuildingDocument = gql`
  mutation DeleteBuilding($input: DeleteBuildingInput!) {
    deleteBuilding(input: $input) {
      property {
        id
        name
        buildings {
          ...BuildingFields
        }
      }
    }
  }
  ${BuildingFieldsFragmentDoc}
`;
export type DeleteBuildingMutationFn = Apollo.MutationFunction<
  DeleteBuildingMutation,
  DeleteBuildingMutationVariables
>;

/**
 * __useDeleteBuildingMutation__
 *
 * To run a mutation, you first call `useDeleteBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBuildingMutation, { data, loading, error }] = useDeleteBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBuildingMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBuildingMutation, DeleteBuildingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBuildingMutation, DeleteBuildingMutationVariables>(
    DeleteBuildingDocument,
    options
  );
}
export type DeleteBuildingMutationHookResult = ReturnType<typeof useDeleteBuildingMutation>;
export type DeleteBuildingMutationResult = Apollo.MutationResult<DeleteBuildingMutation>;
export type DeleteBuildingMutationOptions = Apollo.BaseMutationOptions<
  DeleteBuildingMutation,
  DeleteBuildingMutationVariables
>;
export const GetBuildingAmenitiesDocument = gql`
  query GetBuildingAmenities($input: BuildingFilter!) {
    building(filter: $input) {
      id
      amenitiesSetting {
        ...AmenitiesSettingFields
      }
    }
  }
  ${AmenitiesSettingFieldsFragmentDoc}
`;

/**
 * __useGetBuildingAmenitiesQuery__
 *
 * To run a query within a React component, call `useGetBuildingAmenitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingAmenitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingAmenitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBuildingAmenitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBuildingAmenitiesQuery,
    GetBuildingAmenitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBuildingAmenitiesQuery, GetBuildingAmenitiesQueryVariables>(
    GetBuildingAmenitiesDocument,
    options
  );
}
export function useGetBuildingAmenitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBuildingAmenitiesQuery,
    GetBuildingAmenitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBuildingAmenitiesQuery, GetBuildingAmenitiesQueryVariables>(
    GetBuildingAmenitiesDocument,
    options
  );
}
export type GetBuildingAmenitiesQueryHookResult = ReturnType<typeof useGetBuildingAmenitiesQuery>;
export type GetBuildingAmenitiesLazyQueryHookResult = ReturnType<
  typeof useGetBuildingAmenitiesLazyQuery
>;
export type GetBuildingAmenitiesQueryResult = Apollo.QueryResult<
  GetBuildingAmenitiesQuery,
  GetBuildingAmenitiesQueryVariables
>;
export const UpdateBuildingAmenitiesDocument = gql`
  mutation UpdateBuildingAmenities($input: UpdateBuildingAmenitiesInput!) {
    updateBuildingAmenities(input: $input) {
      property {
        id
        name
        buildings {
          id
          name
          amenitiesSetting {
            ...AmenitiesSettingFields
          }
        }
      }
    }
  }
  ${AmenitiesSettingFieldsFragmentDoc}
`;
export type UpdateBuildingAmenitiesMutationFn = Apollo.MutationFunction<
  UpdateBuildingAmenitiesMutation,
  UpdateBuildingAmenitiesMutationVariables
>;

/**
 * __useUpdateBuildingAmenitiesMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingAmenitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingAmenitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingAmenitiesMutation, { data, loading, error }] = useUpdateBuildingAmenitiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuildingAmenitiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBuildingAmenitiesMutation,
    UpdateBuildingAmenitiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBuildingAmenitiesMutation,
    UpdateBuildingAmenitiesMutationVariables
  >(UpdateBuildingAmenitiesDocument, options);
}
export type UpdateBuildingAmenitiesMutationHookResult = ReturnType<
  typeof useUpdateBuildingAmenitiesMutation
>;
export type UpdateBuildingAmenitiesMutationResult =
  Apollo.MutationResult<UpdateBuildingAmenitiesMutation>;
export type UpdateBuildingAmenitiesMutationOptions = Apollo.BaseMutationOptions<
  UpdateBuildingAmenitiesMutation,
  UpdateBuildingAmenitiesMutationVariables
>;
export const GetBuildingFeaturesDocument = gql`
  query GetBuildingFeatures($input: BuildingFilter!) {
    building(filter: $input) {
      id
      features {
        ...FeatureFields
      }
    }
  }
  ${FeatureFieldsFragmentDoc}
`;

/**
 * __useGetBuildingFeaturesQuery__
 *
 * To run a query within a React component, call `useGetBuildingFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingFeaturesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBuildingFeaturesQuery(
  baseOptions: Apollo.QueryHookOptions<GetBuildingFeaturesQuery, GetBuildingFeaturesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBuildingFeaturesQuery, GetBuildingFeaturesQueryVariables>(
    GetBuildingFeaturesDocument,
    options
  );
}
export function useGetBuildingFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBuildingFeaturesQuery,
    GetBuildingFeaturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBuildingFeaturesQuery, GetBuildingFeaturesQueryVariables>(
    GetBuildingFeaturesDocument,
    options
  );
}
export type GetBuildingFeaturesQueryHookResult = ReturnType<typeof useGetBuildingFeaturesQuery>;
export type GetBuildingFeaturesLazyQueryHookResult = ReturnType<
  typeof useGetBuildingFeaturesLazyQuery
>;
export type GetBuildingFeaturesQueryResult = Apollo.QueryResult<
  GetBuildingFeaturesQuery,
  GetBuildingFeaturesQueryVariables
>;
export const UpdateBuildingFeaturesDocument = gql`
  mutation UpdateBuildingFeatures($input: UpdateBuildingFeaturesInput!) {
    updateBuildingFeatures(input: $input) {
      property {
        id
        name
        buildings {
          id
          name
          features {
            ...FeatureFields
          }
        }
      }
    }
  }
  ${FeatureFieldsFragmentDoc}
`;
export type UpdateBuildingFeaturesMutationFn = Apollo.MutationFunction<
  UpdateBuildingFeaturesMutation,
  UpdateBuildingFeaturesMutationVariables
>;

/**
 * __useUpdateBuildingFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingFeaturesMutation, { data, loading, error }] = useUpdateBuildingFeaturesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuildingFeaturesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBuildingFeaturesMutation,
    UpdateBuildingFeaturesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBuildingFeaturesMutation,
    UpdateBuildingFeaturesMutationVariables
  >(UpdateBuildingFeaturesDocument, options);
}
export type UpdateBuildingFeaturesMutationHookResult = ReturnType<
  typeof useUpdateBuildingFeaturesMutation
>;
export type UpdateBuildingFeaturesMutationResult =
  Apollo.MutationResult<UpdateBuildingFeaturesMutation>;
export type UpdateBuildingFeaturesMutationOptions = Apollo.BaseMutationOptions<
  UpdateBuildingFeaturesMutation,
  UpdateBuildingFeaturesMutationVariables
>;
export const AddBuildingInsuranceDocument = gql`
  mutation AddBuildingInsurance($input: BuildingInsuranceInput!) {
    addBuildingInsurance(input: $input) {
      property {
        id
        buildings {
          id
          insurancePolicy {
            ...InsurancePolicyFields
          }
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type AddBuildingInsuranceMutationFn = Apollo.MutationFunction<
  AddBuildingInsuranceMutation,
  AddBuildingInsuranceMutationVariables
>;

/**
 * __useAddBuildingInsuranceMutation__
 *
 * To run a mutation, you first call `useAddBuildingInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBuildingInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBuildingInsuranceMutation, { data, loading, error }] = useAddBuildingInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBuildingInsuranceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBuildingInsuranceMutation,
    AddBuildingInsuranceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddBuildingInsuranceMutation, AddBuildingInsuranceMutationVariables>(
    AddBuildingInsuranceDocument,
    options
  );
}
export type AddBuildingInsuranceMutationHookResult = ReturnType<
  typeof useAddBuildingInsuranceMutation
>;
export type AddBuildingInsuranceMutationResult =
  Apollo.MutationResult<AddBuildingInsuranceMutation>;
export type AddBuildingInsuranceMutationOptions = Apollo.BaseMutationOptions<
  AddBuildingInsuranceMutation,
  AddBuildingInsuranceMutationVariables
>;
export const UpdateBuildingInsuranceDocument = gql`
  mutation UpdateBuildingInsurance($input: BuildingInsuranceInput!) {
    updateBuildingInsurance(input: $input) {
      property {
        id
        buildings {
          id
          insurancePolicy {
            ...InsurancePolicyFields
          }
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type UpdateBuildingInsuranceMutationFn = Apollo.MutationFunction<
  UpdateBuildingInsuranceMutation,
  UpdateBuildingInsuranceMutationVariables
>;

/**
 * __useUpdateBuildingInsuranceMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingInsuranceMutation, { data, loading, error }] = useUpdateBuildingInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuildingInsuranceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBuildingInsuranceMutation,
    UpdateBuildingInsuranceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBuildingInsuranceMutation,
    UpdateBuildingInsuranceMutationVariables
  >(UpdateBuildingInsuranceDocument, options);
}
export type UpdateBuildingInsuranceMutationHookResult = ReturnType<
  typeof useUpdateBuildingInsuranceMutation
>;
export type UpdateBuildingInsuranceMutationResult =
  Apollo.MutationResult<UpdateBuildingInsuranceMutation>;
export type UpdateBuildingInsuranceMutationOptions = Apollo.BaseMutationOptions<
  UpdateBuildingInsuranceMutation,
  UpdateBuildingInsuranceMutationVariables
>;
export const AddBuildingInsuranceHistoryDocument = gql`
  mutation AddBuildingInsuranceHistory($input: CreateBuildingInsuranceHistoryInput!) {
    addBuildingInsuranceHistory(input: $input) {
      success
      property {
        id
        buildings {
          id
          insurancePolicyHistory {
            ...InsurancePolicyFields
          }
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type AddBuildingInsuranceHistoryMutationFn = Apollo.MutationFunction<
  AddBuildingInsuranceHistoryMutation,
  AddBuildingInsuranceHistoryMutationVariables
>;

/**
 * __useAddBuildingInsuranceHistoryMutation__
 *
 * To run a mutation, you first call `useAddBuildingInsuranceHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBuildingInsuranceHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBuildingInsuranceHistoryMutation, { data, loading, error }] = useAddBuildingInsuranceHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBuildingInsuranceHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBuildingInsuranceHistoryMutation,
    AddBuildingInsuranceHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddBuildingInsuranceHistoryMutation,
    AddBuildingInsuranceHistoryMutationVariables
  >(AddBuildingInsuranceHistoryDocument, options);
}
export type AddBuildingInsuranceHistoryMutationHookResult = ReturnType<
  typeof useAddBuildingInsuranceHistoryMutation
>;
export type AddBuildingInsuranceHistoryMutationResult =
  Apollo.MutationResult<AddBuildingInsuranceHistoryMutation>;
export type AddBuildingInsuranceHistoryMutationOptions = Apollo.BaseMutationOptions<
  AddBuildingInsuranceHistoryMutation,
  AddBuildingInsuranceHistoryMutationVariables
>;
export const UpdateBuildingInsuranceHistoryDocument = gql`
  mutation UpdateBuildingInsuranceHistory($input: UpdateBuildingInsuranceHistoryInput!) {
    updateBuildingInsuranceHistory(input: $input) {
      success
      property {
        id
        buildings {
          id
          insurancePolicyHistory {
            ...InsurancePolicyFields
          }
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type UpdateBuildingInsuranceHistoryMutationFn = Apollo.MutationFunction<
  UpdateBuildingInsuranceHistoryMutation,
  UpdateBuildingInsuranceHistoryMutationVariables
>;

/**
 * __useUpdateBuildingInsuranceHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingInsuranceHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingInsuranceHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingInsuranceHistoryMutation, { data, loading, error }] = useUpdateBuildingInsuranceHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuildingInsuranceHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBuildingInsuranceHistoryMutation,
    UpdateBuildingInsuranceHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBuildingInsuranceHistoryMutation,
    UpdateBuildingInsuranceHistoryMutationVariables
  >(UpdateBuildingInsuranceHistoryDocument, options);
}
export type UpdateBuildingInsuranceHistoryMutationHookResult = ReturnType<
  typeof useUpdateBuildingInsuranceHistoryMutation
>;
export type UpdateBuildingInsuranceHistoryMutationResult =
  Apollo.MutationResult<UpdateBuildingInsuranceHistoryMutation>;
export type UpdateBuildingInsuranceHistoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateBuildingInsuranceHistoryMutation,
  UpdateBuildingInsuranceHistoryMutationVariables
>;
export const DeleteBuildingInsuranceHistoryDocument = gql`
  mutation DeleteBuildingInsuranceHistory($input: DeleteBuildingInsuranceHistoryInput!) {
    deleteBuildingInsuranceHistory(input: $input) {
      success
      property {
        id
        buildings {
          id
          insurancePolicyHistory {
            ...InsurancePolicyFields
          }
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type DeleteBuildingInsuranceHistoryMutationFn = Apollo.MutationFunction<
  DeleteBuildingInsuranceHistoryMutation,
  DeleteBuildingInsuranceHistoryMutationVariables
>;

/**
 * __useDeleteBuildingInsuranceHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteBuildingInsuranceHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBuildingInsuranceHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBuildingInsuranceHistoryMutation, { data, loading, error }] = useDeleteBuildingInsuranceHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBuildingInsuranceHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBuildingInsuranceHistoryMutation,
    DeleteBuildingInsuranceHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBuildingInsuranceHistoryMutation,
    DeleteBuildingInsuranceHistoryMutationVariables
  >(DeleteBuildingInsuranceHistoryDocument, options);
}
export type DeleteBuildingInsuranceHistoryMutationHookResult = ReturnType<
  typeof useDeleteBuildingInsuranceHistoryMutation
>;
export type DeleteBuildingInsuranceHistoryMutationResult =
  Apollo.MutationResult<DeleteBuildingInsuranceHistoryMutation>;
export type DeleteBuildingInsuranceHistoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteBuildingInsuranceHistoryMutation,
  DeleteBuildingInsuranceHistoryMutationVariables
>;
export const AddBuildingInsuranceClaimDocument = gql`
  mutation AddBuildingInsuranceClaim($input: CreateBuildingInsuranceClaimInput!) {
    addBuildingInsuranceClaim(input: $input) {
      success
      property {
        id
        buildings {
          id
          insurancePolicy {
            ...InsurancePolicyFields
          }
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type AddBuildingInsuranceClaimMutationFn = Apollo.MutationFunction<
  AddBuildingInsuranceClaimMutation,
  AddBuildingInsuranceClaimMutationVariables
>;

/**
 * __useAddBuildingInsuranceClaimMutation__
 *
 * To run a mutation, you first call `useAddBuildingInsuranceClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBuildingInsuranceClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBuildingInsuranceClaimMutation, { data, loading, error }] = useAddBuildingInsuranceClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBuildingInsuranceClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBuildingInsuranceClaimMutation,
    AddBuildingInsuranceClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddBuildingInsuranceClaimMutation,
    AddBuildingInsuranceClaimMutationVariables
  >(AddBuildingInsuranceClaimDocument, options);
}
export type AddBuildingInsuranceClaimMutationHookResult = ReturnType<
  typeof useAddBuildingInsuranceClaimMutation
>;
export type AddBuildingInsuranceClaimMutationResult =
  Apollo.MutationResult<AddBuildingInsuranceClaimMutation>;
export type AddBuildingInsuranceClaimMutationOptions = Apollo.BaseMutationOptions<
  AddBuildingInsuranceClaimMutation,
  AddBuildingInsuranceClaimMutationVariables
>;
export const UpdateBuildingInsuranceClaimDocument = gql`
  mutation UpdateBuildingInsuranceClaim($input: UpdateBuildingInsuranceClaimInput!) {
    updateBuildingInsuranceClaim(input: $input) {
      success
      property {
        id
        buildings {
          id
          insurancePolicy {
            ...InsurancePolicyFields
          }
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type UpdateBuildingInsuranceClaimMutationFn = Apollo.MutationFunction<
  UpdateBuildingInsuranceClaimMutation,
  UpdateBuildingInsuranceClaimMutationVariables
>;

/**
 * __useUpdateBuildingInsuranceClaimMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingInsuranceClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingInsuranceClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingInsuranceClaimMutation, { data, loading, error }] = useUpdateBuildingInsuranceClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuildingInsuranceClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBuildingInsuranceClaimMutation,
    UpdateBuildingInsuranceClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBuildingInsuranceClaimMutation,
    UpdateBuildingInsuranceClaimMutationVariables
  >(UpdateBuildingInsuranceClaimDocument, options);
}
export type UpdateBuildingInsuranceClaimMutationHookResult = ReturnType<
  typeof useUpdateBuildingInsuranceClaimMutation
>;
export type UpdateBuildingInsuranceClaimMutationResult =
  Apollo.MutationResult<UpdateBuildingInsuranceClaimMutation>;
export type UpdateBuildingInsuranceClaimMutationOptions = Apollo.BaseMutationOptions<
  UpdateBuildingInsuranceClaimMutation,
  UpdateBuildingInsuranceClaimMutationVariables
>;
export const DeleteBuildingInsuranceClaimDocument = gql`
  mutation DeleteBuildingInsuranceClaim($input: DeleteBuildingInsuranceClaimInput!) {
    deleteBuildingInsuranceClaim(input: $input) {
      success
      property {
        id
        buildings {
          id
          insurancePolicy {
            ...InsurancePolicyFields
          }
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type DeleteBuildingInsuranceClaimMutationFn = Apollo.MutationFunction<
  DeleteBuildingInsuranceClaimMutation,
  DeleteBuildingInsuranceClaimMutationVariables
>;

/**
 * __useDeleteBuildingInsuranceClaimMutation__
 *
 * To run a mutation, you first call `useDeleteBuildingInsuranceClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBuildingInsuranceClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBuildingInsuranceClaimMutation, { data, loading, error }] = useDeleteBuildingInsuranceClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBuildingInsuranceClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBuildingInsuranceClaimMutation,
    DeleteBuildingInsuranceClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBuildingInsuranceClaimMutation,
    DeleteBuildingInsuranceClaimMutationVariables
  >(DeleteBuildingInsuranceClaimDocument, options);
}
export type DeleteBuildingInsuranceClaimMutationHookResult = ReturnType<
  typeof useDeleteBuildingInsuranceClaimMutation
>;
export type DeleteBuildingInsuranceClaimMutationResult =
  Apollo.MutationResult<DeleteBuildingInsuranceClaimMutation>;
export type DeleteBuildingInsuranceClaimMutationOptions = Apollo.BaseMutationOptions<
  DeleteBuildingInsuranceClaimMutation,
  DeleteBuildingInsuranceClaimMutationVariables
>;
export const AddBuildingInsuranceHistoryClaimDocument = gql`
  mutation AddBuildingInsuranceHistoryClaim($input: CreateBuildingInsuranceClaimInput!) {
    addBuildingInsuranceHistoryClaim(input: $input) {
      success
      property {
        id
        buildings {
          id
          insurancePolicyHistory {
            ...InsurancePolicyFields
          }
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type AddBuildingInsuranceHistoryClaimMutationFn = Apollo.MutationFunction<
  AddBuildingInsuranceHistoryClaimMutation,
  AddBuildingInsuranceHistoryClaimMutationVariables
>;

/**
 * __useAddBuildingInsuranceHistoryClaimMutation__
 *
 * To run a mutation, you first call `useAddBuildingInsuranceHistoryClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBuildingInsuranceHistoryClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBuildingInsuranceHistoryClaimMutation, { data, loading, error }] = useAddBuildingInsuranceHistoryClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBuildingInsuranceHistoryClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBuildingInsuranceHistoryClaimMutation,
    AddBuildingInsuranceHistoryClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddBuildingInsuranceHistoryClaimMutation,
    AddBuildingInsuranceHistoryClaimMutationVariables
  >(AddBuildingInsuranceHistoryClaimDocument, options);
}
export type AddBuildingInsuranceHistoryClaimMutationHookResult = ReturnType<
  typeof useAddBuildingInsuranceHistoryClaimMutation
>;
export type AddBuildingInsuranceHistoryClaimMutationResult =
  Apollo.MutationResult<AddBuildingInsuranceHistoryClaimMutation>;
export type AddBuildingInsuranceHistoryClaimMutationOptions = Apollo.BaseMutationOptions<
  AddBuildingInsuranceHistoryClaimMutation,
  AddBuildingInsuranceHistoryClaimMutationVariables
>;
export const UpdateBuildingInsuranceHistoryClaimDocument = gql`
  mutation UpdateBuildingInsuranceHistoryClaim($input: UpdateBuildingInsuranceClaimInput!) {
    updateBuildingInsuranceHistoryClaim(input: $input) {
      success
      property {
        id
        buildings {
          id
          insurancePolicyHistory {
            ...InsurancePolicyFields
          }
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type UpdateBuildingInsuranceHistoryClaimMutationFn = Apollo.MutationFunction<
  UpdateBuildingInsuranceHistoryClaimMutation,
  UpdateBuildingInsuranceHistoryClaimMutationVariables
>;

/**
 * __useUpdateBuildingInsuranceHistoryClaimMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingInsuranceHistoryClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingInsuranceHistoryClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingInsuranceHistoryClaimMutation, { data, loading, error }] = useUpdateBuildingInsuranceHistoryClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuildingInsuranceHistoryClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBuildingInsuranceHistoryClaimMutation,
    UpdateBuildingInsuranceHistoryClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBuildingInsuranceHistoryClaimMutation,
    UpdateBuildingInsuranceHistoryClaimMutationVariables
  >(UpdateBuildingInsuranceHistoryClaimDocument, options);
}
export type UpdateBuildingInsuranceHistoryClaimMutationHookResult = ReturnType<
  typeof useUpdateBuildingInsuranceHistoryClaimMutation
>;
export type UpdateBuildingInsuranceHistoryClaimMutationResult =
  Apollo.MutationResult<UpdateBuildingInsuranceHistoryClaimMutation>;
export type UpdateBuildingInsuranceHistoryClaimMutationOptions = Apollo.BaseMutationOptions<
  UpdateBuildingInsuranceHistoryClaimMutation,
  UpdateBuildingInsuranceHistoryClaimMutationVariables
>;
export const DeleteBuildingInsuranceHistoryClaimDocument = gql`
  mutation DeleteBuildingInsuranceHistoryClaim($input: DeleteBuildingInsuranceClaimInput!) {
    deleteBuildingInsuranceHistoryClaim(input: $input) {
      success
      property {
        id
        buildings {
          id
          insurancePolicyHistory {
            ...InsurancePolicyFields
          }
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type DeleteBuildingInsuranceHistoryClaimMutationFn = Apollo.MutationFunction<
  DeleteBuildingInsuranceHistoryClaimMutation,
  DeleteBuildingInsuranceHistoryClaimMutationVariables
>;

/**
 * __useDeleteBuildingInsuranceHistoryClaimMutation__
 *
 * To run a mutation, you first call `useDeleteBuildingInsuranceHistoryClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBuildingInsuranceHistoryClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBuildingInsuranceHistoryClaimMutation, { data, loading, error }] = useDeleteBuildingInsuranceHistoryClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBuildingInsuranceHistoryClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBuildingInsuranceHistoryClaimMutation,
    DeleteBuildingInsuranceHistoryClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBuildingInsuranceHistoryClaimMutation,
    DeleteBuildingInsuranceHistoryClaimMutationVariables
  >(DeleteBuildingInsuranceHistoryClaimDocument, options);
}
export type DeleteBuildingInsuranceHistoryClaimMutationHookResult = ReturnType<
  typeof useDeleteBuildingInsuranceHistoryClaimMutation
>;
export type DeleteBuildingInsuranceHistoryClaimMutationResult =
  Apollo.MutationResult<DeleteBuildingInsuranceHistoryClaimMutation>;
export type DeleteBuildingInsuranceHistoryClaimMutationOptions = Apollo.BaseMutationOptions<
  DeleteBuildingInsuranceHistoryClaimMutation,
  DeleteBuildingInsuranceHistoryClaimMutationVariables
>;
export const GetBuildingInsuranceDocument = gql`
  query GetBuildingInsurance($input: BuildingFilter!) {
    building(filter: $input) {
      id
      insurancePolicy {
        ...InsurancePolicyFields
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;

/**
 * __useGetBuildingInsuranceQuery__
 *
 * To run a query within a React component, call `useGetBuildingInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingInsuranceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBuildingInsuranceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBuildingInsuranceQuery,
    GetBuildingInsuranceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBuildingInsuranceQuery, GetBuildingInsuranceQueryVariables>(
    GetBuildingInsuranceDocument,
    options
  );
}
export function useGetBuildingInsuranceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBuildingInsuranceQuery,
    GetBuildingInsuranceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBuildingInsuranceQuery, GetBuildingInsuranceQueryVariables>(
    GetBuildingInsuranceDocument,
    options
  );
}
export type GetBuildingInsuranceQueryHookResult = ReturnType<typeof useGetBuildingInsuranceQuery>;
export type GetBuildingInsuranceLazyQueryHookResult = ReturnType<
  typeof useGetBuildingInsuranceLazyQuery
>;
export type GetBuildingInsuranceQueryResult = Apollo.QueryResult<
  GetBuildingInsuranceQuery,
  GetBuildingInsuranceQueryVariables
>;
export const GetBuildingInsuranceHistoryDocument = gql`
  query GetBuildingInsuranceHistory($input: BuildingFilter!) {
    building(filter: $input) {
      id
      insurancePolicyHistory {
        ...InsurancePolicyFields
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;

/**
 * __useGetBuildingInsuranceHistoryQuery__
 *
 * To run a query within a React component, call `useGetBuildingInsuranceHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingInsuranceHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingInsuranceHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBuildingInsuranceHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBuildingInsuranceHistoryQuery,
    GetBuildingInsuranceHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBuildingInsuranceHistoryQuery,
    GetBuildingInsuranceHistoryQueryVariables
  >(GetBuildingInsuranceHistoryDocument, options);
}
export function useGetBuildingInsuranceHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBuildingInsuranceHistoryQuery,
    GetBuildingInsuranceHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBuildingInsuranceHistoryQuery,
    GetBuildingInsuranceHistoryQueryVariables
  >(GetBuildingInsuranceHistoryDocument, options);
}
export type GetBuildingInsuranceHistoryQueryHookResult = ReturnType<
  typeof useGetBuildingInsuranceHistoryQuery
>;
export type GetBuildingInsuranceHistoryLazyQueryHookResult = ReturnType<
  typeof useGetBuildingInsuranceHistoryLazyQuery
>;
export type GetBuildingInsuranceHistoryQueryResult = Apollo.QueryResult<
  GetBuildingInsuranceHistoryQuery,
  GetBuildingInsuranceHistoryQueryVariables
>;
export const GetPropertyTimezoneDocument = gql`
  query GetPropertyTimezone($id: ID!) {
    property(id: $id) {
      id
      timezone
    }
  }
`;

/**
 * __useGetPropertyTimezoneQuery__
 *
 * To run a query within a React component, call `useGetPropertyTimezoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyTimezoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyTimezoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyTimezoneQuery(
  baseOptions: Apollo.QueryHookOptions<GetPropertyTimezoneQuery, GetPropertyTimezoneQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyTimezoneQuery, GetPropertyTimezoneQueryVariables>(
    GetPropertyTimezoneDocument,
    options
  );
}
export function useGetPropertyTimezoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyTimezoneQuery,
    GetPropertyTimezoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyTimezoneQuery, GetPropertyTimezoneQueryVariables>(
    GetPropertyTimezoneDocument,
    options
  );
}
export type GetPropertyTimezoneQueryHookResult = ReturnType<typeof useGetPropertyTimezoneQuery>;
export type GetPropertyTimezoneLazyQueryHookResult = ReturnType<
  typeof useGetPropertyTimezoneLazyQuery
>;
export type GetPropertyTimezoneQueryResult = Apollo.QueryResult<
  GetPropertyTimezoneQuery,
  GetPropertyTimezoneQueryVariables
>;
export const UnitCountsDocument = gql`
  query UnitCounts {
    account {
      id
      stats {
        unit
      }
    }
  }
`;

/**
 * __useUnitCountsQuery__
 *
 * To run a query within a React component, call `useUnitCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnitCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<UnitCountsQuery, UnitCountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnitCountsQuery, UnitCountsQueryVariables>(UnitCountsDocument, options);
}
export function useUnitCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UnitCountsQuery, UnitCountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnitCountsQuery, UnitCountsQueryVariables>(
    UnitCountsDocument,
    options
  );
}
export type UnitCountsQueryHookResult = ReturnType<typeof useUnitCountsQuery>;
export type UnitCountsLazyQueryHookResult = ReturnType<typeof useUnitCountsLazyQuery>;
export type UnitCountsQueryResult = Apollo.QueryResult<UnitCountsQuery, UnitCountsQueryVariables>;
export const UpdateResidencyEffectsDocument = gql`
  mutation UpdateResidencyEffects(
    $residencyId: ID!
    $unitId: ID!
    $propertyId: ID!
    $input: UpdateResidencyEffectsInput!
  ) {
    updateResidencyEffects(
      residencyId: $residencyId
      unitId: $unitId
      propertyId: $propertyId
      input: $input
    ) {
      success
      error
      residency {
        id
        ...ResidencyFields
      }
    }
  }
  ${ResidencyFieldsFragmentDoc}
`;
export type UpdateResidencyEffectsMutationFn = Apollo.MutationFunction<
  UpdateResidencyEffectsMutation,
  UpdateResidencyEffectsMutationVariables
>;

/**
 * __useUpdateResidencyEffectsMutation__
 *
 * To run a mutation, you first call `useUpdateResidencyEffectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResidencyEffectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResidencyEffectsMutation, { data, loading, error }] = useUpdateResidencyEffectsMutation({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *      unitId: // value for 'unitId'
 *      propertyId: // value for 'propertyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResidencyEffectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateResidencyEffectsMutation,
    UpdateResidencyEffectsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateResidencyEffectsMutation,
    UpdateResidencyEffectsMutationVariables
  >(UpdateResidencyEffectsDocument, options);
}
export type UpdateResidencyEffectsMutationHookResult = ReturnType<
  typeof useUpdateResidencyEffectsMutation
>;
export type UpdateResidencyEffectsMutationResult =
  Apollo.MutationResult<UpdateResidencyEffectsMutation>;
export type UpdateResidencyEffectsMutationOptions = Apollo.BaseMutationOptions<
  UpdateResidencyEffectsMutation,
  UpdateResidencyEffectsMutationVariables
>;
export const UpdateResidencyRateSchedulePathDocument = gql`
  query UpdateResidencyRateSchedulePath($residencyId: ID!) {
    residency(id: $residencyId) {
      id
      startZ
      propertyId
      unitId
      effects {
        id
        __typename
        feeId
        startZ
        effect
        ... on IncentiveEffect {
          description
          incentiveId
        }
      }
    }
  }
`;

/**
 * __useUpdateResidencyRateSchedulePathQuery__
 *
 * To run a query within a React component, call `useUpdateResidencyRateSchedulePathQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateResidencyRateSchedulePathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateResidencyRateSchedulePathQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useUpdateResidencyRateSchedulePathQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateResidencyRateSchedulePathQuery,
    UpdateResidencyRateSchedulePathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UpdateResidencyRateSchedulePathQuery,
    UpdateResidencyRateSchedulePathQueryVariables
  >(UpdateResidencyRateSchedulePathDocument, options);
}
export function useUpdateResidencyRateSchedulePathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateResidencyRateSchedulePathQuery,
    UpdateResidencyRateSchedulePathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UpdateResidencyRateSchedulePathQuery,
    UpdateResidencyRateSchedulePathQueryVariables
  >(UpdateResidencyRateSchedulePathDocument, options);
}
export type UpdateResidencyRateSchedulePathQueryHookResult = ReturnType<
  typeof useUpdateResidencyRateSchedulePathQuery
>;
export type UpdateResidencyRateSchedulePathLazyQueryHookResult = ReturnType<
  typeof useUpdateResidencyRateSchedulePathLazyQuery
>;
export type UpdateResidencyRateSchedulePathQueryResult = Apollo.QueryResult<
  UpdateResidencyRateSchedulePathQuery,
  UpdateResidencyRateSchedulePathQueryVariables
>;
export const AddPropertyInsuranceDocument = gql`
  mutation AddPropertyInsurance($input: PropertyInsuranceInput!) {
    addPropertyInsurance(input: $input) {
      property {
        id
        insurancePolicy {
          ...InsurancePolicyFields
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type AddPropertyInsuranceMutationFn = Apollo.MutationFunction<
  AddPropertyInsuranceMutation,
  AddPropertyInsuranceMutationVariables
>;

/**
 * __useAddPropertyInsuranceMutation__
 *
 * To run a mutation, you first call `useAddPropertyInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPropertyInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPropertyInsuranceMutation, { data, loading, error }] = useAddPropertyInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPropertyInsuranceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPropertyInsuranceMutation,
    AddPropertyInsuranceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPropertyInsuranceMutation, AddPropertyInsuranceMutationVariables>(
    AddPropertyInsuranceDocument,
    options
  );
}
export type AddPropertyInsuranceMutationHookResult = ReturnType<
  typeof useAddPropertyInsuranceMutation
>;
export type AddPropertyInsuranceMutationResult =
  Apollo.MutationResult<AddPropertyInsuranceMutation>;
export type AddPropertyInsuranceMutationOptions = Apollo.BaseMutationOptions<
  AddPropertyInsuranceMutation,
  AddPropertyInsuranceMutationVariables
>;
export const UpdatePropertyInsuranceDocument = gql`
  mutation updatePropertyInsurance($input: PropertyInsuranceInput!) {
    updatePropertyInsurance(input: $input) {
      property {
        id
        insurancePolicy {
          ...InsurancePolicyFields
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type UpdatePropertyInsuranceMutationFn = Apollo.MutationFunction<
  UpdatePropertyInsuranceMutation,
  UpdatePropertyInsuranceMutationVariables
>;

/**
 * __useUpdatePropertyInsuranceMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyInsuranceMutation, { data, loading, error }] = useUpdatePropertyInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertyInsuranceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePropertyInsuranceMutation,
    UpdatePropertyInsuranceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePropertyInsuranceMutation,
    UpdatePropertyInsuranceMutationVariables
  >(UpdatePropertyInsuranceDocument, options);
}
export type UpdatePropertyInsuranceMutationHookResult = ReturnType<
  typeof useUpdatePropertyInsuranceMutation
>;
export type UpdatePropertyInsuranceMutationResult =
  Apollo.MutationResult<UpdatePropertyInsuranceMutation>;
export type UpdatePropertyInsuranceMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyInsuranceMutation,
  UpdatePropertyInsuranceMutationVariables
>;
export const AddPropertyInsuranceHistoryDocument = gql`
  mutation AddPropertyInsuranceHistory($input: CreatePropertyInsuranceHistoryInput!) {
    addPropertyInsuranceHistory(input: $input) {
      success
      property {
        id
        insurancePolicyHistory {
          ...InsurancePolicyFields
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type AddPropertyInsuranceHistoryMutationFn = Apollo.MutationFunction<
  AddPropertyInsuranceHistoryMutation,
  AddPropertyInsuranceHistoryMutationVariables
>;

/**
 * __useAddPropertyInsuranceHistoryMutation__
 *
 * To run a mutation, you first call `useAddPropertyInsuranceHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPropertyInsuranceHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPropertyInsuranceHistoryMutation, { data, loading, error }] = useAddPropertyInsuranceHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPropertyInsuranceHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPropertyInsuranceHistoryMutation,
    AddPropertyInsuranceHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPropertyInsuranceHistoryMutation,
    AddPropertyInsuranceHistoryMutationVariables
  >(AddPropertyInsuranceHistoryDocument, options);
}
export type AddPropertyInsuranceHistoryMutationHookResult = ReturnType<
  typeof useAddPropertyInsuranceHistoryMutation
>;
export type AddPropertyInsuranceHistoryMutationResult =
  Apollo.MutationResult<AddPropertyInsuranceHistoryMutation>;
export type AddPropertyInsuranceHistoryMutationOptions = Apollo.BaseMutationOptions<
  AddPropertyInsuranceHistoryMutation,
  AddPropertyInsuranceHistoryMutationVariables
>;
export const UpdatePropertyInsuranceHistoryDocument = gql`
  mutation UpdatePropertyInsuranceHistory($input: UpdatePropertyInsuranceHistoryInput!) {
    updatePropertyInsuranceHistory(input: $input) {
      success
      property {
        id
        insurancePolicyHistory {
          ...InsurancePolicyFields
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type UpdatePropertyInsuranceHistoryMutationFn = Apollo.MutationFunction<
  UpdatePropertyInsuranceHistoryMutation,
  UpdatePropertyInsuranceHistoryMutationVariables
>;

/**
 * __useUpdatePropertyInsuranceHistoryMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyInsuranceHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyInsuranceHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyInsuranceHistoryMutation, { data, loading, error }] = useUpdatePropertyInsuranceHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertyInsuranceHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePropertyInsuranceHistoryMutation,
    UpdatePropertyInsuranceHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePropertyInsuranceHistoryMutation,
    UpdatePropertyInsuranceHistoryMutationVariables
  >(UpdatePropertyInsuranceHistoryDocument, options);
}
export type UpdatePropertyInsuranceHistoryMutationHookResult = ReturnType<
  typeof useUpdatePropertyInsuranceHistoryMutation
>;
export type UpdatePropertyInsuranceHistoryMutationResult =
  Apollo.MutationResult<UpdatePropertyInsuranceHistoryMutation>;
export type UpdatePropertyInsuranceHistoryMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyInsuranceHistoryMutation,
  UpdatePropertyInsuranceHistoryMutationVariables
>;
export const DeletePropertyInsuranceHistoryDocument = gql`
  mutation DeletePropertyInsuranceHistory($input: DeletePropertyInsuranceHistoryInput!) {
    deletePropertyInsuranceHistory(input: $input) {
      success
      property {
        id
        insurancePolicyHistory {
          ...InsurancePolicyFields
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type DeletePropertyInsuranceHistoryMutationFn = Apollo.MutationFunction<
  DeletePropertyInsuranceHistoryMutation,
  DeletePropertyInsuranceHistoryMutationVariables
>;

/**
 * __useDeletePropertyInsuranceHistoryMutation__
 *
 * To run a mutation, you first call `useDeletePropertyInsuranceHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyInsuranceHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyInsuranceHistoryMutation, { data, loading, error }] = useDeletePropertyInsuranceHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePropertyInsuranceHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePropertyInsuranceHistoryMutation,
    DeletePropertyInsuranceHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePropertyInsuranceHistoryMutation,
    DeletePropertyInsuranceHistoryMutationVariables
  >(DeletePropertyInsuranceHistoryDocument, options);
}
export type DeletePropertyInsuranceHistoryMutationHookResult = ReturnType<
  typeof useDeletePropertyInsuranceHistoryMutation
>;
export type DeletePropertyInsuranceHistoryMutationResult =
  Apollo.MutationResult<DeletePropertyInsuranceHistoryMutation>;
export type DeletePropertyInsuranceHistoryMutationOptions = Apollo.BaseMutationOptions<
  DeletePropertyInsuranceHistoryMutation,
  DeletePropertyInsuranceHistoryMutationVariables
>;
export const AddPropertyInsuranceClaimDocument = gql`
  mutation AddPropertyInsuranceClaim($input: CreatePropertyInsuranceClaimInput!) {
    addPropertyInsuranceClaim(input: $input) {
      success
      property {
        id
        insurancePolicy {
          ...InsurancePolicyFields
        }
        insurancePolicyHistory {
          ...InsurancePolicyFields
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type AddPropertyInsuranceClaimMutationFn = Apollo.MutationFunction<
  AddPropertyInsuranceClaimMutation,
  AddPropertyInsuranceClaimMutationVariables
>;

/**
 * __useAddPropertyInsuranceClaimMutation__
 *
 * To run a mutation, you first call `useAddPropertyInsuranceClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPropertyInsuranceClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPropertyInsuranceClaimMutation, { data, loading, error }] = useAddPropertyInsuranceClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPropertyInsuranceClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPropertyInsuranceClaimMutation,
    AddPropertyInsuranceClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPropertyInsuranceClaimMutation,
    AddPropertyInsuranceClaimMutationVariables
  >(AddPropertyInsuranceClaimDocument, options);
}
export type AddPropertyInsuranceClaimMutationHookResult = ReturnType<
  typeof useAddPropertyInsuranceClaimMutation
>;
export type AddPropertyInsuranceClaimMutationResult =
  Apollo.MutationResult<AddPropertyInsuranceClaimMutation>;
export type AddPropertyInsuranceClaimMutationOptions = Apollo.BaseMutationOptions<
  AddPropertyInsuranceClaimMutation,
  AddPropertyInsuranceClaimMutationVariables
>;
export const UpdatePropertyInsuranceClaimDocument = gql`
  mutation UpdatePropertyInsuranceClaim($input: UpdatePropertyInsuranceClaimInput!) {
    updatePropertyInsuranceClaim(input: $input) {
      success
      property {
        id
        insurancePolicy {
          ...InsurancePolicyFields
        }
        insurancePolicyHistory {
          ...InsurancePolicyFields
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type UpdatePropertyInsuranceClaimMutationFn = Apollo.MutationFunction<
  UpdatePropertyInsuranceClaimMutation,
  UpdatePropertyInsuranceClaimMutationVariables
>;

/**
 * __useUpdatePropertyInsuranceClaimMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyInsuranceClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyInsuranceClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyInsuranceClaimMutation, { data, loading, error }] = useUpdatePropertyInsuranceClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertyInsuranceClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePropertyInsuranceClaimMutation,
    UpdatePropertyInsuranceClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePropertyInsuranceClaimMutation,
    UpdatePropertyInsuranceClaimMutationVariables
  >(UpdatePropertyInsuranceClaimDocument, options);
}
export type UpdatePropertyInsuranceClaimMutationHookResult = ReturnType<
  typeof useUpdatePropertyInsuranceClaimMutation
>;
export type UpdatePropertyInsuranceClaimMutationResult =
  Apollo.MutationResult<UpdatePropertyInsuranceClaimMutation>;
export type UpdatePropertyInsuranceClaimMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyInsuranceClaimMutation,
  UpdatePropertyInsuranceClaimMutationVariables
>;
export const DeletePropertyInsuranceClaimDocument = gql`
  mutation DeletePropertyInsuranceClaim($input: DeletePropertyInsuranceClaimInput!) {
    deletePropertyInsuranceClaim(input: $input) {
      success
      property {
        id
        insurancePolicy {
          ...InsurancePolicyFields
        }
        insurancePolicyHistory {
          ...InsurancePolicyFields
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type DeletePropertyInsuranceClaimMutationFn = Apollo.MutationFunction<
  DeletePropertyInsuranceClaimMutation,
  DeletePropertyInsuranceClaimMutationVariables
>;

/**
 * __useDeletePropertyInsuranceClaimMutation__
 *
 * To run a mutation, you first call `useDeletePropertyInsuranceClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyInsuranceClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyInsuranceClaimMutation, { data, loading, error }] = useDeletePropertyInsuranceClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePropertyInsuranceClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePropertyInsuranceClaimMutation,
    DeletePropertyInsuranceClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePropertyInsuranceClaimMutation,
    DeletePropertyInsuranceClaimMutationVariables
  >(DeletePropertyInsuranceClaimDocument, options);
}
export type DeletePropertyInsuranceClaimMutationHookResult = ReturnType<
  typeof useDeletePropertyInsuranceClaimMutation
>;
export type DeletePropertyInsuranceClaimMutationResult =
  Apollo.MutationResult<DeletePropertyInsuranceClaimMutation>;
export type DeletePropertyInsuranceClaimMutationOptions = Apollo.BaseMutationOptions<
  DeletePropertyInsuranceClaimMutation,
  DeletePropertyInsuranceClaimMutationVariables
>;
export const AddPropertyInsuranceHistoryClaimDocument = gql`
  mutation AddPropertyInsuranceHistoryClaim($input: CreatePropertyInsuranceClaimInput!) {
    addPropertyInsuranceHistoryClaim(input: $input) {
      success
      property {
        id
        insurancePolicy {
          ...InsurancePolicyFields
        }
        insurancePolicyHistory {
          ...InsurancePolicyFields
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type AddPropertyInsuranceHistoryClaimMutationFn = Apollo.MutationFunction<
  AddPropertyInsuranceHistoryClaimMutation,
  AddPropertyInsuranceHistoryClaimMutationVariables
>;

/**
 * __useAddPropertyInsuranceHistoryClaimMutation__
 *
 * To run a mutation, you first call `useAddPropertyInsuranceHistoryClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPropertyInsuranceHistoryClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPropertyInsuranceHistoryClaimMutation, { data, loading, error }] = useAddPropertyInsuranceHistoryClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPropertyInsuranceHistoryClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPropertyInsuranceHistoryClaimMutation,
    AddPropertyInsuranceHistoryClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPropertyInsuranceHistoryClaimMutation,
    AddPropertyInsuranceHistoryClaimMutationVariables
  >(AddPropertyInsuranceHistoryClaimDocument, options);
}
export type AddPropertyInsuranceHistoryClaimMutationHookResult = ReturnType<
  typeof useAddPropertyInsuranceHistoryClaimMutation
>;
export type AddPropertyInsuranceHistoryClaimMutationResult =
  Apollo.MutationResult<AddPropertyInsuranceHistoryClaimMutation>;
export type AddPropertyInsuranceHistoryClaimMutationOptions = Apollo.BaseMutationOptions<
  AddPropertyInsuranceHistoryClaimMutation,
  AddPropertyInsuranceHistoryClaimMutationVariables
>;
export const UpdatePropertyInsuranceHistoryClaimDocument = gql`
  mutation UpdatePropertyInsuranceHistoryClaim($input: UpdatePropertyInsuranceClaimInput!) {
    updatePropertyInsuranceHistoryClaim(input: $input) {
      success
      property {
        id
        insurancePolicy {
          ...InsurancePolicyFields
        }
        insurancePolicyHistory {
          ...InsurancePolicyFields
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type UpdatePropertyInsuranceHistoryClaimMutationFn = Apollo.MutationFunction<
  UpdatePropertyInsuranceHistoryClaimMutation,
  UpdatePropertyInsuranceHistoryClaimMutationVariables
>;

/**
 * __useUpdatePropertyInsuranceHistoryClaimMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyInsuranceHistoryClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyInsuranceHistoryClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyInsuranceHistoryClaimMutation, { data, loading, error }] = useUpdatePropertyInsuranceHistoryClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertyInsuranceHistoryClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePropertyInsuranceHistoryClaimMutation,
    UpdatePropertyInsuranceHistoryClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePropertyInsuranceHistoryClaimMutation,
    UpdatePropertyInsuranceHistoryClaimMutationVariables
  >(UpdatePropertyInsuranceHistoryClaimDocument, options);
}
export type UpdatePropertyInsuranceHistoryClaimMutationHookResult = ReturnType<
  typeof useUpdatePropertyInsuranceHistoryClaimMutation
>;
export type UpdatePropertyInsuranceHistoryClaimMutationResult =
  Apollo.MutationResult<UpdatePropertyInsuranceHistoryClaimMutation>;
export type UpdatePropertyInsuranceHistoryClaimMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyInsuranceHistoryClaimMutation,
  UpdatePropertyInsuranceHistoryClaimMutationVariables
>;
export const DeletePropertyInsuranceHistoryClaimDocument = gql`
  mutation DeletePropertyInsuranceHistoryClaim($input: DeletePropertyInsuranceClaimInput!) {
    deletePropertyInsuranceHistoryClaim(input: $input) {
      success
      property {
        id
        insurancePolicy {
          ...InsurancePolicyFields
        }
        insurancePolicyHistory {
          ...InsurancePolicyFields
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type DeletePropertyInsuranceHistoryClaimMutationFn = Apollo.MutationFunction<
  DeletePropertyInsuranceHistoryClaimMutation,
  DeletePropertyInsuranceHistoryClaimMutationVariables
>;

/**
 * __useDeletePropertyInsuranceHistoryClaimMutation__
 *
 * To run a mutation, you first call `useDeletePropertyInsuranceHistoryClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyInsuranceHistoryClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyInsuranceHistoryClaimMutation, { data, loading, error }] = useDeletePropertyInsuranceHistoryClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePropertyInsuranceHistoryClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePropertyInsuranceHistoryClaimMutation,
    DeletePropertyInsuranceHistoryClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePropertyInsuranceHistoryClaimMutation,
    DeletePropertyInsuranceHistoryClaimMutationVariables
  >(DeletePropertyInsuranceHistoryClaimDocument, options);
}
export type DeletePropertyInsuranceHistoryClaimMutationHookResult = ReturnType<
  typeof useDeletePropertyInsuranceHistoryClaimMutation
>;
export type DeletePropertyInsuranceHistoryClaimMutationResult =
  Apollo.MutationResult<DeletePropertyInsuranceHistoryClaimMutation>;
export type DeletePropertyInsuranceHistoryClaimMutationOptions = Apollo.BaseMutationOptions<
  DeletePropertyInsuranceHistoryClaimMutation,
  DeletePropertyInsuranceHistoryClaimMutationVariables
>;
export const GetPropertyInsuranceDocument = gql`
  query GetPropertyInsurance($id: ID!) {
    property(id: $id) {
      id
      insurancePolicy {
        ...InsurancePolicyFields
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;

/**
 * __useGetPropertyInsuranceQuery__
 *
 * To run a query within a React component, call `useGetPropertyInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyInsuranceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyInsuranceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyInsuranceQuery,
    GetPropertyInsuranceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyInsuranceQuery, GetPropertyInsuranceQueryVariables>(
    GetPropertyInsuranceDocument,
    options
  );
}
export function useGetPropertyInsuranceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyInsuranceQuery,
    GetPropertyInsuranceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyInsuranceQuery, GetPropertyInsuranceQueryVariables>(
    GetPropertyInsuranceDocument,
    options
  );
}
export type GetPropertyInsuranceQueryHookResult = ReturnType<typeof useGetPropertyInsuranceQuery>;
export type GetPropertyInsuranceLazyQueryHookResult = ReturnType<
  typeof useGetPropertyInsuranceLazyQuery
>;
export type GetPropertyInsuranceQueryResult = Apollo.QueryResult<
  GetPropertyInsuranceQuery,
  GetPropertyInsuranceQueryVariables
>;
export const GetPropertyInsuranceHistoryDocument = gql`
  query GetPropertyInsuranceHistory($id: ID!) {
    property(id: $id) {
      id
      insurancePolicyHistory {
        ...InsurancePolicyFields
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;

/**
 * __useGetPropertyInsuranceHistoryQuery__
 *
 * To run a query within a React component, call `useGetPropertyInsuranceHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyInsuranceHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyInsuranceHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyInsuranceHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyInsuranceHistoryQuery,
    GetPropertyInsuranceHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPropertyInsuranceHistoryQuery,
    GetPropertyInsuranceHistoryQueryVariables
  >(GetPropertyInsuranceHistoryDocument, options);
}
export function useGetPropertyInsuranceHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyInsuranceHistoryQuery,
    GetPropertyInsuranceHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPropertyInsuranceHistoryQuery,
    GetPropertyInsuranceHistoryQueryVariables
  >(GetPropertyInsuranceHistoryDocument, options);
}
export type GetPropertyInsuranceHistoryQueryHookResult = ReturnType<
  typeof useGetPropertyInsuranceHistoryQuery
>;
export type GetPropertyInsuranceHistoryLazyQueryHookResult = ReturnType<
  typeof useGetPropertyInsuranceHistoryLazyQuery
>;
export type GetPropertyInsuranceHistoryQueryResult = Apollo.QueryResult<
  GetPropertyInsuranceHistoryQuery,
  GetPropertyInsuranceHistoryQueryVariables
>;
export const MoveUnitsToBuildingDocument = gql`
  mutation MoveUnitsToBuilding($input: MoveUnitsToBuildingInput!) {
    moveUnitsToBuilding(input: $input) {
      success
      error
    }
  }
`;
export type MoveUnitsToBuildingMutationFn = Apollo.MutationFunction<
  MoveUnitsToBuildingMutation,
  MoveUnitsToBuildingMutationVariables
>;

/**
 * __useMoveUnitsToBuildingMutation__
 *
 * To run a mutation, you first call `useMoveUnitsToBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveUnitsToBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveUnitsToBuildingMutation, { data, loading, error }] = useMoveUnitsToBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveUnitsToBuildingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveUnitsToBuildingMutation,
    MoveUnitsToBuildingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveUnitsToBuildingMutation, MoveUnitsToBuildingMutationVariables>(
    MoveUnitsToBuildingDocument,
    options
  );
}
export type MoveUnitsToBuildingMutationHookResult = ReturnType<
  typeof useMoveUnitsToBuildingMutation
>;
export type MoveUnitsToBuildingMutationResult = Apollo.MutationResult<MoveUnitsToBuildingMutation>;
export type MoveUnitsToBuildingMutationOptions = Apollo.BaseMutationOptions<
  MoveUnitsToBuildingMutation,
  MoveUnitsToBuildingMutationVariables
>;
export const AddPropertyDocument = gql`
  mutation AddProperty($input: CreatePropertyInput!) {
    createProperty(input: $input) {
      success
      error
      property {
        ...PropertyFields
      }
      unitNames {
        namesFound
        totalUnits
        complete
        names
      }
    }
  }
  ${PropertyFieldsFragmentDoc}
`;
export type AddPropertyMutationFn = Apollo.MutationFunction<
  AddPropertyMutation,
  AddPropertyMutationVariables
>;

/**
 * __useAddPropertyMutation__
 *
 * To run a mutation, you first call `useAddPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPropertyMutation, { data, loading, error }] = useAddPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<AddPropertyMutation, AddPropertyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPropertyMutation, AddPropertyMutationVariables>(
    AddPropertyDocument,
    options
  );
}
export type AddPropertyMutationHookResult = ReturnType<typeof useAddPropertyMutation>;
export type AddPropertyMutationResult = Apollo.MutationResult<AddPropertyMutation>;
export type AddPropertyMutationOptions = Apollo.BaseMutationOptions<
  AddPropertyMutation,
  AddPropertyMutationVariables
>;
export const GetPropertyEntityDocument = gql`
  query GetPropertyEntity($id: ID!) {
    property(id: $id) {
      id
      ...PropertyEntityFields
      buildings {
        id
        ...BuildingFields
      }
    }
  }
  ${PropertyEntityFieldsFragmentDoc}
  ${BuildingFieldsFragmentDoc}
`;

/**
 * __useGetPropertyEntityQuery__
 *
 * To run a query within a React component, call `useGetPropertyEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyEntityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyEntityQuery(
  baseOptions: Apollo.QueryHookOptions<GetPropertyEntityQuery, GetPropertyEntityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyEntityQuery, GetPropertyEntityQueryVariables>(
    GetPropertyEntityDocument,
    options
  );
}
export function useGetPropertyEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyEntityQuery, GetPropertyEntityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyEntityQuery, GetPropertyEntityQueryVariables>(
    GetPropertyEntityDocument,
    options
  );
}
export type GetPropertyEntityQueryHookResult = ReturnType<typeof useGetPropertyEntityQuery>;
export type GetPropertyEntityLazyQueryHookResult = ReturnType<typeof useGetPropertyEntityLazyQuery>;
export type GetPropertyEntityQueryResult = Apollo.QueryResult<
  GetPropertyEntityQuery,
  GetPropertyEntityQueryVariables
>;
export const GetPropertyDocument = gql`
  query GetProperty($id: ID!) {
    property(id: $id) {
      id
      ...PropertyFields
    }
  }
  ${PropertyFieldsFragmentDoc}
`;

/**
 * __useGetPropertyQuery__
 *
 * To run a query within a React component, call `useGetPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyQuery(
  baseOptions: Apollo.QueryHookOptions<GetPropertyQuery, GetPropertyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyQuery, GetPropertyQueryVariables>(GetPropertyDocument, options);
}
export function useGetPropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyQuery, GetPropertyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyQuery, GetPropertyQueryVariables>(
    GetPropertyDocument,
    options
  );
}
export type GetPropertyQueryHookResult = ReturnType<typeof useGetPropertyQuery>;
export type GetPropertyLazyQueryHookResult = ReturnType<typeof useGetPropertyLazyQuery>;
export type GetPropertyQueryResult = Apollo.QueryResult<
  GetPropertyQuery,
  GetPropertyQueryVariables
>;
export const PropertyWithResidenciesDocument = gql`
  query PropertyWithResidencies($id: ID!) {
    property(id: $id) {
      id
      journalCount
      ...PropertyFields
      units {
        ...UnitEntityFields
        floorplan {
          id
          marketRate
          exteriorSqFt
          interiorSqFt
          bedrooms
          bathrooms
        }
        owner {
          id
          name
        }
        allResidencies {
          id
          startZ
          endZ
          renewZ
          rrule
          terms
          moveOutReason
          residents {
            id
            leaseHolder
            resident {
              id
              name
            }
          }
        }
      }
    }
  }
  ${PropertyFieldsFragmentDoc}
  ${UnitEntityFieldsFragmentDoc}
`;

/**
 * __usePropertyWithResidenciesQuery__
 *
 * To run a query within a React component, call `usePropertyWithResidenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyWithResidenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyWithResidenciesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertyWithResidenciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertyWithResidenciesQuery,
    PropertyWithResidenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertyWithResidenciesQuery, PropertyWithResidenciesQueryVariables>(
    PropertyWithResidenciesDocument,
    options
  );
}
export function usePropertyWithResidenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertyWithResidenciesQuery,
    PropertyWithResidenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PropertyWithResidenciesQuery, PropertyWithResidenciesQueryVariables>(
    PropertyWithResidenciesDocument,
    options
  );
}
export type PropertyWithResidenciesQueryHookResult = ReturnType<
  typeof usePropertyWithResidenciesQuery
>;
export type PropertyWithResidenciesLazyQueryHookResult = ReturnType<
  typeof usePropertyWithResidenciesLazyQuery
>;
export type PropertyWithResidenciesQueryResult = Apollo.QueryResult<
  PropertyWithResidenciesQuery,
  PropertyWithResidenciesQueryVariables
>;
export const UpdatePropertyAmenitiesDocument = gql`
  mutation UpdatePropertyAmenities($input: UpdatePropertyAmenitiesInput!) {
    updatePropertyAmenities(input: $input) {
      property {
        id
        amenitiesSetting {
          ...AmenitiesSettingFields
        }
      }
    }
  }
  ${AmenitiesSettingFieldsFragmentDoc}
`;
export type UpdatePropertyAmenitiesMutationFn = Apollo.MutationFunction<
  UpdatePropertyAmenitiesMutation,
  UpdatePropertyAmenitiesMutationVariables
>;

/**
 * __useUpdatePropertyAmenitiesMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyAmenitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyAmenitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyAmenitiesMutation, { data, loading, error }] = useUpdatePropertyAmenitiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertyAmenitiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePropertyAmenitiesMutation,
    UpdatePropertyAmenitiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePropertyAmenitiesMutation,
    UpdatePropertyAmenitiesMutationVariables
  >(UpdatePropertyAmenitiesDocument, options);
}
export type UpdatePropertyAmenitiesMutationHookResult = ReturnType<
  typeof useUpdatePropertyAmenitiesMutation
>;
export type UpdatePropertyAmenitiesMutationResult =
  Apollo.MutationResult<UpdatePropertyAmenitiesMutation>;
export type UpdatePropertyAmenitiesMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyAmenitiesMutation,
  UpdatePropertyAmenitiesMutationVariables
>;
export const CreatePropertyNoteDocument = gql`
  mutation CreatePropertyNote($input: CreatePropertyNoteInput!) {
    createPropertyNote(input: $input) {
      property {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type CreatePropertyNoteMutationFn = Apollo.MutationFunction<
  CreatePropertyNoteMutation,
  CreatePropertyNoteMutationVariables
>;

/**
 * __useCreatePropertyNoteMutation__
 *
 * To run a mutation, you first call `useCreatePropertyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePropertyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyNoteMutation, { data, loading, error }] = useCreatePropertyNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePropertyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePropertyNoteMutation,
    CreatePropertyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePropertyNoteMutation, CreatePropertyNoteMutationVariables>(
    CreatePropertyNoteDocument,
    options
  );
}
export type CreatePropertyNoteMutationHookResult = ReturnType<typeof useCreatePropertyNoteMutation>;
export type CreatePropertyNoteMutationResult = Apollo.MutationResult<CreatePropertyNoteMutation>;
export type CreatePropertyNoteMutationOptions = Apollo.BaseMutationOptions<
  CreatePropertyNoteMutation,
  CreatePropertyNoteMutationVariables
>;
export const DeletePropertyNoteDocument = gql`
  mutation DeletePropertyNote($input: DeletePropertyNoteInput!) {
    deletePropertyNote(input: $input) {
      property {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type DeletePropertyNoteMutationFn = Apollo.MutationFunction<
  DeletePropertyNoteMutation,
  DeletePropertyNoteMutationVariables
>;

/**
 * __useDeletePropertyNoteMutation__
 *
 * To run a mutation, you first call `useDeletePropertyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyNoteMutation, { data, loading, error }] = useDeletePropertyNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePropertyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePropertyNoteMutation,
    DeletePropertyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePropertyNoteMutation, DeletePropertyNoteMutationVariables>(
    DeletePropertyNoteDocument,
    options
  );
}
export type DeletePropertyNoteMutationHookResult = ReturnType<typeof useDeletePropertyNoteMutation>;
export type DeletePropertyNoteMutationResult = Apollo.MutationResult<DeletePropertyNoteMutation>;
export type DeletePropertyNoteMutationOptions = Apollo.BaseMutationOptions<
  DeletePropertyNoteMutation,
  DeletePropertyNoteMutationVariables
>;
export const UpdatePropertyDocument = gql`
  mutation UpdateProperty($id: ID!, $input: UpdatePropertyInput!) {
    updateProperty(id: $id, input: $input) {
      ...PropertyFields
    }
  }
  ${PropertyFieldsFragmentDoc}
`;
export type UpdatePropertyMutationFn = Apollo.MutationFunction<
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables
>;

/**
 * __useUpdatePropertyMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyMutation, { data, loading, error }] = useUpdatePropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePropertyMutation, UpdatePropertyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePropertyMutation, UpdatePropertyMutationVariables>(
    UpdatePropertyDocument,
    options
  );
}
export type UpdatePropertyMutationHookResult = ReturnType<typeof useUpdatePropertyMutation>;
export type UpdatePropertyMutationResult = Apollo.MutationResult<UpdatePropertyMutation>;
export type UpdatePropertyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables
>;
export const PropertyNotesDocument = gql`
  query PropertyNotes($id: ID!) {
    property(id: $id) {
      id
      notes {
        id
        createdName
        createdSub
        createdZ
        text
      }
    }
  }
`;

/**
 * __usePropertyNotesQuery__
 *
 * To run a query within a React component, call `usePropertyNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertyNotesQuery(
  baseOptions: Apollo.QueryHookOptions<PropertyNotesQuery, PropertyNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertyNotesQuery, PropertyNotesQueryVariables>(
    PropertyNotesDocument,
    options
  );
}
export function usePropertyNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PropertyNotesQuery, PropertyNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PropertyNotesQuery, PropertyNotesQueryVariables>(
    PropertyNotesDocument,
    options
  );
}
export type PropertyNotesQueryHookResult = ReturnType<typeof usePropertyNotesQuery>;
export type PropertyNotesLazyQueryHookResult = ReturnType<typeof usePropertyNotesLazyQuery>;
export type PropertyNotesQueryResult = Apollo.QueryResult<
  PropertyNotesQuery,
  PropertyNotesQueryVariables
>;
export const UpdatePropertySpecsDocument = gql`
  mutation UpdatePropertySpecs($input: UpdatePropertySpecsInput!) {
    updatePropertySpecs(input: $input) {
      property {
        id
        specs {
          ...PropertySpecsFields
        }
      }
    }
  }
  ${PropertySpecsFieldsFragmentDoc}
`;
export type UpdatePropertySpecsMutationFn = Apollo.MutationFunction<
  UpdatePropertySpecsMutation,
  UpdatePropertySpecsMutationVariables
>;

/**
 * __useUpdatePropertySpecsMutation__
 *
 * To run a mutation, you first call `useUpdatePropertySpecsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertySpecsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertySpecsMutation, { data, loading, error }] = useUpdatePropertySpecsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertySpecsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePropertySpecsMutation,
    UpdatePropertySpecsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePropertySpecsMutation, UpdatePropertySpecsMutationVariables>(
    UpdatePropertySpecsDocument,
    options
  );
}
export type UpdatePropertySpecsMutationHookResult = ReturnType<
  typeof useUpdatePropertySpecsMutation
>;
export type UpdatePropertySpecsMutationResult = Apollo.MutationResult<UpdatePropertySpecsMutation>;
export type UpdatePropertySpecsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertySpecsMutation,
  UpdatePropertySpecsMutationVariables
>;
export const GetPropertySpecsDocument = gql`
  query GetPropertySpecs($id: ID!) {
    property(id: $id) {
      id
      specs {
        ...PropertySpecsFields
      }
    }
  }
  ${PropertySpecsFieldsFragmentDoc}
`;

/**
 * __useGetPropertySpecsQuery__
 *
 * To run a query within a React component, call `useGetPropertySpecsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertySpecsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertySpecsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertySpecsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPropertySpecsQuery, GetPropertySpecsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertySpecsQuery, GetPropertySpecsQueryVariables>(
    GetPropertySpecsDocument,
    options
  );
}
export function useGetPropertySpecsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPropertySpecsQuery, GetPropertySpecsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertySpecsQuery, GetPropertySpecsQueryVariables>(
    GetPropertySpecsDocument,
    options
  );
}
export type GetPropertySpecsQueryHookResult = ReturnType<typeof useGetPropertySpecsQuery>;
export type GetPropertySpecsLazyQueryHookResult = ReturnType<typeof useGetPropertySpecsLazyQuery>;
export type GetPropertySpecsQueryResult = Apollo.QueryResult<
  GetPropertySpecsQuery,
  GetPropertySpecsQueryVariables
>;
export const PropertyAmenitiesDocument = gql`
  query PropertyAmenities($id: ID!) {
    property(id: $id) {
      id
      amenitiesSetting {
        ...AmenitiesSettingFields
      }
    }
  }
  ${AmenitiesSettingFieldsFragmentDoc}
`;

/**
 * __usePropertyAmenitiesQuery__
 *
 * To run a query within a React component, call `usePropertyAmenitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyAmenitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyAmenitiesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertyAmenitiesQuery(
  baseOptions: Apollo.QueryHookOptions<PropertyAmenitiesQuery, PropertyAmenitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertyAmenitiesQuery, PropertyAmenitiesQueryVariables>(
    PropertyAmenitiesDocument,
    options
  );
}
export function usePropertyAmenitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PropertyAmenitiesQuery, PropertyAmenitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PropertyAmenitiesQuery, PropertyAmenitiesQueryVariables>(
    PropertyAmenitiesDocument,
    options
  );
}
export type PropertyAmenitiesQueryHookResult = ReturnType<typeof usePropertyAmenitiesQuery>;
export type PropertyAmenitiesLazyQueryHookResult = ReturnType<typeof usePropertyAmenitiesLazyQuery>;
export type PropertyAmenitiesQueryResult = Apollo.QueryResult<
  PropertyAmenitiesQuery,
  PropertyAmenitiesQueryVariables
>;
export const SuppliersDocument = gql`
  query Suppliers($id: ID!) {
    property(id: $id) {
      id
      suppliers {
        ...SupplierFields
      }
    }
  }
  ${SupplierFieldsFragmentDoc}
`;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSuppliersQuery(
  baseOptions: Apollo.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
}
export function useSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
}
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>;
export type SuppliersQueryResult = Apollo.QueryResult<SuppliersQuery, SuppliersQueryVariables>;
export const GetPropertyFeaturesDocument = gql`
  query GetPropertyFeatures($id: ID!) {
    property(id: $id) {
      id
      features {
        ...FeatureFields
      }
    }
  }
  ${FeatureFieldsFragmentDoc}
`;

/**
 * __useGetPropertyFeaturesQuery__
 *
 * To run a query within a React component, call `useGetPropertyFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyFeaturesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyFeaturesQuery(
  baseOptions: Apollo.QueryHookOptions<GetPropertyFeaturesQuery, GetPropertyFeaturesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyFeaturesQuery, GetPropertyFeaturesQueryVariables>(
    GetPropertyFeaturesDocument,
    options
  );
}
export function useGetPropertyFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyFeaturesQuery,
    GetPropertyFeaturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyFeaturesQuery, GetPropertyFeaturesQueryVariables>(
    GetPropertyFeaturesDocument,
    options
  );
}
export type GetPropertyFeaturesQueryHookResult = ReturnType<typeof useGetPropertyFeaturesQuery>;
export type GetPropertyFeaturesLazyQueryHookResult = ReturnType<
  typeof useGetPropertyFeaturesLazyQuery
>;
export type GetPropertyFeaturesQueryResult = Apollo.QueryResult<
  GetPropertyFeaturesQuery,
  GetPropertyFeaturesQueryVariables
>;
export const UpdatePropertyFeaturesDocument = gql`
  mutation UpdatePropertyFeatures($input: UpdatePropertyFeaturesInput!) {
    updatePropertyFeatures(input: $input) {
      property {
        id
        features {
          ...FeatureFields
        }
      }
    }
  }
  ${FeatureFieldsFragmentDoc}
`;
export type UpdatePropertyFeaturesMutationFn = Apollo.MutationFunction<
  UpdatePropertyFeaturesMutation,
  UpdatePropertyFeaturesMutationVariables
>;

/**
 * __useUpdatePropertyFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyFeaturesMutation, { data, loading, error }] = useUpdatePropertyFeaturesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertyFeaturesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePropertyFeaturesMutation,
    UpdatePropertyFeaturesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePropertyFeaturesMutation,
    UpdatePropertyFeaturesMutationVariables
  >(UpdatePropertyFeaturesDocument, options);
}
export type UpdatePropertyFeaturesMutationHookResult = ReturnType<
  typeof useUpdatePropertyFeaturesMutation
>;
export type UpdatePropertyFeaturesMutationResult =
  Apollo.MutationResult<UpdatePropertyFeaturesMutation>;
export type UpdatePropertyFeaturesMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyFeaturesMutation,
  UpdatePropertyFeaturesMutationVariables
>;
export const PropertyDocumentsDocument = gql`
  query PropertyDocuments($id: ID!) {
    property(id: $id) {
      id
      documents {
        ...DocumentFields
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`;

/**
 * __usePropertyDocumentsQuery__
 *
 * To run a query within a React component, call `usePropertyDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertyDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<PropertyDocumentsQuery, PropertyDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertyDocumentsQuery, PropertyDocumentsQueryVariables>(
    PropertyDocumentsDocument,
    options
  );
}
export function usePropertyDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PropertyDocumentsQuery, PropertyDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PropertyDocumentsQuery, PropertyDocumentsQueryVariables>(
    PropertyDocumentsDocument,
    options
  );
}
export type PropertyDocumentsQueryHookResult = ReturnType<typeof usePropertyDocumentsQuery>;
export type PropertyDocumentsLazyQueryHookResult = ReturnType<typeof usePropertyDocumentsLazyQuery>;
export type PropertyDocumentsQueryResult = Apollo.QueryResult<
  PropertyDocumentsQuery,
  PropertyDocumentsQueryVariables
>;
export const RenamePropertyDocumentDocument = gql`
  mutation RenamePropertyDocument($input: RenamePropertyDocumentInput!) {
    renamePropertyDocument(input: $input) {
      property {
        id
        documents {
          ...DocumentFields
        }
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`;
export type RenamePropertyDocumentMutationFn = Apollo.MutationFunction<
  RenamePropertyDocumentMutation,
  RenamePropertyDocumentMutationVariables
>;

/**
 * __useRenamePropertyDocumentMutation__
 *
 * To run a mutation, you first call `useRenamePropertyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenamePropertyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renamePropertyDocumentMutation, { data, loading, error }] = useRenamePropertyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenamePropertyDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenamePropertyDocumentMutation,
    RenamePropertyDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RenamePropertyDocumentMutation,
    RenamePropertyDocumentMutationVariables
  >(RenamePropertyDocumentDocument, options);
}
export type RenamePropertyDocumentMutationHookResult = ReturnType<
  typeof useRenamePropertyDocumentMutation
>;
export type RenamePropertyDocumentMutationResult =
  Apollo.MutationResult<RenamePropertyDocumentMutation>;
export type RenamePropertyDocumentMutationOptions = Apollo.BaseMutationOptions<
  RenamePropertyDocumentMutation,
  RenamePropertyDocumentMutationVariables
>;
export const UpdatePropertySuppliersDocument = gql`
  mutation UpdatePropertySuppliers($input: UpdatePropertySuppliersInput!) {
    updatePropertySuppliers(input: $input) {
      property {
        id
        supplierIds
      }
    }
  }
`;
export type UpdatePropertySuppliersMutationFn = Apollo.MutationFunction<
  UpdatePropertySuppliersMutation,
  UpdatePropertySuppliersMutationVariables
>;

/**
 * __useUpdatePropertySuppliersMutation__
 *
 * To run a mutation, you first call `useUpdatePropertySuppliersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertySuppliersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertySuppliersMutation, { data, loading, error }] = useUpdatePropertySuppliersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertySuppliersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePropertySuppliersMutation,
    UpdatePropertySuppliersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePropertySuppliersMutation,
    UpdatePropertySuppliersMutationVariables
  >(UpdatePropertySuppliersDocument, options);
}
export type UpdatePropertySuppliersMutationHookResult = ReturnType<
  typeof useUpdatePropertySuppliersMutation
>;
export type UpdatePropertySuppliersMutationResult =
  Apollo.MutationResult<UpdatePropertySuppliersMutation>;
export type UpdatePropertySuppliersMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertySuppliersMutation,
  UpdatePropertySuppliersMutationVariables
>;
export const UpdatePropertyContractorsDocument = gql`
  mutation UpdatePropertyContractors($input: UpdatePropertyContractorsInput!) {
    updatePropertyContractors(input: $input) {
      property {
        id
        contractorIds
      }
    }
  }
`;
export type UpdatePropertyContractorsMutationFn = Apollo.MutationFunction<
  UpdatePropertyContractorsMutation,
  UpdatePropertyContractorsMutationVariables
>;

/**
 * __useUpdatePropertyContractorsMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyContractorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyContractorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyContractorsMutation, { data, loading, error }] = useUpdatePropertyContractorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertyContractorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePropertyContractorsMutation,
    UpdatePropertyContractorsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePropertyContractorsMutation,
    UpdatePropertyContractorsMutationVariables
  >(UpdatePropertyContractorsDocument, options);
}
export type UpdatePropertyContractorsMutationHookResult = ReturnType<
  typeof useUpdatePropertyContractorsMutation
>;
export type UpdatePropertyContractorsMutationResult =
  Apollo.MutationResult<UpdatePropertyContractorsMutation>;
export type UpdatePropertyContractorsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyContractorsMutation,
  UpdatePropertyContractorsMutationVariables
>;
export const DeletePropertyDocument = gql`
  mutation DeleteProperty($id: ID!) {
    deleteProperty(id: $id) {
      success
    }
  }
`;
export type DeletePropertyMutationFn = Apollo.MutationFunction<
  DeletePropertyMutation,
  DeletePropertyMutationVariables
>;

/**
 * __useDeletePropertyMutation__
 *
 * To run a mutation, you first call `useDeletePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyMutation, { data, loading, error }] = useDeletePropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePropertyMutation, DeletePropertyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePropertyMutation, DeletePropertyMutationVariables>(
    DeletePropertyDocument,
    options
  );
}
export type DeletePropertyMutationHookResult = ReturnType<typeof useDeletePropertyMutation>;
export type DeletePropertyMutationResult = Apollo.MutationResult<DeletePropertyMutation>;
export type DeletePropertyMutationOptions = Apollo.BaseMutationOptions<
  DeletePropertyMutation,
  DeletePropertyMutationVariables
>;
export const RemoveParkingStallDocument = gql`
  mutation removeParkingStall($input: RemovePropertyParkingInput!) {
    removePropertyParking(input: $input) {
      success
      error
      property {
        id
        parking {
          name
          unitId
        }
      }
    }
  }
`;
export type RemoveParkingStallMutationFn = Apollo.MutationFunction<
  RemoveParkingStallMutation,
  RemoveParkingStallMutationVariables
>;

/**
 * __useRemoveParkingStallMutation__
 *
 * To run a mutation, you first call `useRemoveParkingStallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveParkingStallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeParkingStallMutation, { data, loading, error }] = useRemoveParkingStallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveParkingStallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveParkingStallMutation,
    RemoveParkingStallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveParkingStallMutation, RemoveParkingStallMutationVariables>(
    RemoveParkingStallDocument,
    options
  );
}
export type RemoveParkingStallMutationHookResult = ReturnType<typeof useRemoveParkingStallMutation>;
export type RemoveParkingStallMutationResult = Apollo.MutationResult<RemoveParkingStallMutation>;
export type RemoveParkingStallMutationOptions = Apollo.BaseMutationOptions<
  RemoveParkingStallMutation,
  RemoveParkingStallMutationVariables
>;
export const RemoveStorageLockerDocument = gql`
  mutation removeStorageLocker($input: RemovePropertyStorageInput!) {
    removePropertyStorage(input: $input) {
      success
      error
      property {
        id
        storage {
          name
          unitId
        }
      }
    }
  }
`;
export type RemoveStorageLockerMutationFn = Apollo.MutationFunction<
  RemoveStorageLockerMutation,
  RemoveStorageLockerMutationVariables
>;

/**
 * __useRemoveStorageLockerMutation__
 *
 * To run a mutation, you first call `useRemoveStorageLockerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStorageLockerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStorageLockerMutation, { data, loading, error }] = useRemoveStorageLockerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStorageLockerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveStorageLockerMutation,
    RemoveStorageLockerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveStorageLockerMutation, RemoveStorageLockerMutationVariables>(
    RemoveStorageLockerDocument,
    options
  );
}
export type RemoveStorageLockerMutationHookResult = ReturnType<
  typeof useRemoveStorageLockerMutation
>;
export type RemoveStorageLockerMutationResult = Apollo.MutationResult<RemoveStorageLockerMutation>;
export type RemoveStorageLockerMutationOptions = Apollo.BaseMutationOptions<
  RemoveStorageLockerMutation,
  RemoveStorageLockerMutationVariables
>;
export const RemoveKeyringDocument = gql`
  mutation removeKeyring($input: RemovePropertyKeyInput!) {
    removePropertyKeyring(input: $input) {
      success
      error
      property {
        id
        keyring {
          name
          unitId
        }
      }
    }
  }
`;
export type RemoveKeyringMutationFn = Apollo.MutationFunction<
  RemoveKeyringMutation,
  RemoveKeyringMutationVariables
>;

/**
 * __useRemoveKeyringMutation__
 *
 * To run a mutation, you first call `useRemoveKeyringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKeyringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKeyringMutation, { data, loading, error }] = useRemoveKeyringMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveKeyringMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveKeyringMutation, RemoveKeyringMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveKeyringMutation, RemoveKeyringMutationVariables>(
    RemoveKeyringDocument,
    options
  );
}
export type RemoveKeyringMutationHookResult = ReturnType<typeof useRemoveKeyringMutation>;
export type RemoveKeyringMutationResult = Apollo.MutationResult<RemoveKeyringMutation>;
export type RemoveKeyringMutationOptions = Apollo.BaseMutationOptions<
  RemoveKeyringMutation,
  RemoveKeyringMutationVariables
>;
export const AddParkingStallDocument = gql`
  mutation addParkingStall($input: AddPropertyParkingInput!) {
    addPropertyParking(input: $input) {
      success
      error
      property {
        id
        parking {
          name
          unitId
        }
      }
    }
  }
`;
export type AddParkingStallMutationFn = Apollo.MutationFunction<
  AddParkingStallMutation,
  AddParkingStallMutationVariables
>;

/**
 * __useAddParkingStallMutation__
 *
 * To run a mutation, you first call `useAddParkingStallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParkingStallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParkingStallMutation, { data, loading, error }] = useAddParkingStallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddParkingStallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddParkingStallMutation,
    AddParkingStallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddParkingStallMutation, AddParkingStallMutationVariables>(
    AddParkingStallDocument,
    options
  );
}
export type AddParkingStallMutationHookResult = ReturnType<typeof useAddParkingStallMutation>;
export type AddParkingStallMutationResult = Apollo.MutationResult<AddParkingStallMutation>;
export type AddParkingStallMutationOptions = Apollo.BaseMutationOptions<
  AddParkingStallMutation,
  AddParkingStallMutationVariables
>;
export const AddStorageLockerDocument = gql`
  mutation addStorageLocker($input: AddPropertyStorageInput!) {
    addPropertyStorage(input: $input) {
      success
      error
      property {
        id
        storage {
          name
          unitId
        }
      }
    }
  }
`;
export type AddStorageLockerMutationFn = Apollo.MutationFunction<
  AddStorageLockerMutation,
  AddStorageLockerMutationVariables
>;

/**
 * __useAddStorageLockerMutation__
 *
 * To run a mutation, you first call `useAddStorageLockerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStorageLockerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStorageLockerMutation, { data, loading, error }] = useAddStorageLockerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStorageLockerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddStorageLockerMutation,
    AddStorageLockerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddStorageLockerMutation, AddStorageLockerMutationVariables>(
    AddStorageLockerDocument,
    options
  );
}
export type AddStorageLockerMutationHookResult = ReturnType<typeof useAddStorageLockerMutation>;
export type AddStorageLockerMutationResult = Apollo.MutationResult<AddStorageLockerMutation>;
export type AddStorageLockerMutationOptions = Apollo.BaseMutationOptions<
  AddStorageLockerMutation,
  AddStorageLockerMutationVariables
>;
export const AddKeyringDocument = gql`
  mutation addKeyring($input: AddPropertyKeyringInput!) {
    addPropertyKeyring(input: $input) {
      success
      error
      property {
        id
        keyring {
          name
          unitId
        }
      }
    }
  }
`;
export type AddKeyringMutationFn = Apollo.MutationFunction<
  AddKeyringMutation,
  AddKeyringMutationVariables
>;

/**
 * __useAddKeyringMutation__
 *
 * To run a mutation, you first call `useAddKeyringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKeyringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKeyringMutation, { data, loading, error }] = useAddKeyringMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddKeyringMutation(
  baseOptions?: Apollo.MutationHookOptions<AddKeyringMutation, AddKeyringMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddKeyringMutation, AddKeyringMutationVariables>(
    AddKeyringDocument,
    options
  );
}
export type AddKeyringMutationHookResult = ReturnType<typeof useAddKeyringMutation>;
export type AddKeyringMutationResult = Apollo.MutationResult<AddKeyringMutation>;
export type AddKeyringMutationOptions = Apollo.BaseMutationOptions<
  AddKeyringMutation,
  AddKeyringMutationVariables
>;
export const GetPropertyFloorplansDocument = gql`
  query GetPropertyFloorplans($id: ID!) {
    property(id: $id) {
      id
      floorplans {
        ...FloorplanFields
      }
    }
  }
  ${FloorplanFieldsFragmentDoc}
`;

/**
 * __useGetPropertyFloorplansQuery__
 *
 * To run a query within a React component, call `useGetPropertyFloorplansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyFloorplansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyFloorplansQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyFloorplansQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyFloorplansQuery,
    GetPropertyFloorplansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyFloorplansQuery, GetPropertyFloorplansQueryVariables>(
    GetPropertyFloorplansDocument,
    options
  );
}
export function useGetPropertyFloorplansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyFloorplansQuery,
    GetPropertyFloorplansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyFloorplansQuery, GetPropertyFloorplansQueryVariables>(
    GetPropertyFloorplansDocument,
    options
  );
}
export type GetPropertyFloorplansQueryHookResult = ReturnType<typeof useGetPropertyFloorplansQuery>;
export type GetPropertyFloorplansLazyQueryHookResult = ReturnType<
  typeof useGetPropertyFloorplansLazyQuery
>;
export type GetPropertyFloorplansQueryResult = Apollo.QueryResult<
  GetPropertyFloorplansQuery,
  GetPropertyFloorplansQueryVariables
>;
export const GetBuildingFloorplansDocument = gql`
  query GetBuildingFloorplans($filter: BuildingFilter!) {
    building(filter: $filter) {
      id
      floorplans {
        ...FloorplanFields
      }
    }
  }
  ${FloorplanFieldsFragmentDoc}
`;

/**
 * __useGetBuildingFloorplansQuery__
 *
 * To run a query within a React component, call `useGetBuildingFloorplansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingFloorplansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingFloorplansQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetBuildingFloorplansQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBuildingFloorplansQuery,
    GetBuildingFloorplansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBuildingFloorplansQuery, GetBuildingFloorplansQueryVariables>(
    GetBuildingFloorplansDocument,
    options
  );
}
export function useGetBuildingFloorplansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBuildingFloorplansQuery,
    GetBuildingFloorplansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBuildingFloorplansQuery, GetBuildingFloorplansQueryVariables>(
    GetBuildingFloorplansDocument,
    options
  );
}
export type GetBuildingFloorplansQueryHookResult = ReturnType<typeof useGetBuildingFloorplansQuery>;
export type GetBuildingFloorplansLazyQueryHookResult = ReturnType<
  typeof useGetBuildingFloorplansLazyQuery
>;
export type GetBuildingFloorplansQueryResult = Apollo.QueryResult<
  GetBuildingFloorplansQuery,
  GetBuildingFloorplansQueryVariables
>;
export const GetPropertyPhotosDocument = gql`
  query GetPropertyPhotos($id: ID!) {
    property(id: $id) {
      id
      images {
        key
        name
        tags
      }
    }
  }
`;

/**
 * __useGetPropertyPhotosQuery__
 *
 * To run a query within a React component, call `useGetPropertyPhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyPhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyPhotosQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyPhotosQuery(
  baseOptions: Apollo.QueryHookOptions<GetPropertyPhotosQuery, GetPropertyPhotosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyPhotosQuery, GetPropertyPhotosQueryVariables>(
    GetPropertyPhotosDocument,
    options
  );
}
export function useGetPropertyPhotosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyPhotosQuery, GetPropertyPhotosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyPhotosQuery, GetPropertyPhotosQueryVariables>(
    GetPropertyPhotosDocument,
    options
  );
}
export type GetPropertyPhotosQueryHookResult = ReturnType<typeof useGetPropertyPhotosQuery>;
export type GetPropertyPhotosLazyQueryHookResult = ReturnType<typeof useGetPropertyPhotosLazyQuery>;
export type GetPropertyPhotosQueryResult = Apollo.QueryResult<
  GetPropertyPhotosQuery,
  GetPropertyPhotosQueryVariables
>;
export const AddPropertyImagesDocument = gql`
  mutation AddPropertyImages($input: PropertyImagesInput!) {
    addPropertyImages(input: $input) {
      success
      error
      property {
        id
        images {
          key
          name
          tags
        }
      }
    }
  }
`;
export type AddPropertyImagesMutationFn = Apollo.MutationFunction<
  AddPropertyImagesMutation,
  AddPropertyImagesMutationVariables
>;

/**
 * __useAddPropertyImagesMutation__
 *
 * To run a mutation, you first call `useAddPropertyImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPropertyImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPropertyImagesMutation, { data, loading, error }] = useAddPropertyImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPropertyImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPropertyImagesMutation,
    AddPropertyImagesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPropertyImagesMutation, AddPropertyImagesMutationVariables>(
    AddPropertyImagesDocument,
    options
  );
}
export type AddPropertyImagesMutationHookResult = ReturnType<typeof useAddPropertyImagesMutation>;
export type AddPropertyImagesMutationResult = Apollo.MutationResult<AddPropertyImagesMutation>;
export type AddPropertyImagesMutationOptions = Apollo.BaseMutationOptions<
  AddPropertyImagesMutation,
  AddPropertyImagesMutationVariables
>;
export const DeletePropertyImageDocument = gql`
  mutation DeletePropertyImage($input: DeletePropertyImageInput!) {
    deletePropertyImage(input: $input) {
      success
      error
      property {
        id
        images {
          key
          name
          tags
        }
      }
    }
  }
`;
export type DeletePropertyImageMutationFn = Apollo.MutationFunction<
  DeletePropertyImageMutation,
  DeletePropertyImageMutationVariables
>;

/**
 * __useDeletePropertyImageMutation__
 *
 * To run a mutation, you first call `useDeletePropertyImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyImageMutation, { data, loading, error }] = useDeletePropertyImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePropertyImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePropertyImageMutation,
    DeletePropertyImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePropertyImageMutation, DeletePropertyImageMutationVariables>(
    DeletePropertyImageDocument,
    options
  );
}
export type DeletePropertyImageMutationHookResult = ReturnType<
  typeof useDeletePropertyImageMutation
>;
export type DeletePropertyImageMutationResult = Apollo.MutationResult<DeletePropertyImageMutation>;
export type DeletePropertyImageMutationOptions = Apollo.BaseMutationOptions<
  DeletePropertyImageMutation,
  DeletePropertyImageMutationVariables
>;
export const InspectionPhotosUnitDataDocument = gql`
  query InspectionPhotosUnitData($unitId: ID!) {
    unit(id: $unitId) {
      id
      ...UnitFields
      routineInspections {
        ...RoutineInspectionFields
      }
    }
  }
  ${UnitFieldsFragmentDoc}
  ${RoutineInspectionFieldsFragmentDoc}
`;

/**
 * __useInspectionPhotosUnitDataQuery__
 *
 * To run a query within a React component, call `useInspectionPhotosUnitDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspectionPhotosUnitDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspectionPhotosUnitDataQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useInspectionPhotosUnitDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    InspectionPhotosUnitDataQuery,
    InspectionPhotosUnitDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InspectionPhotosUnitDataQuery, InspectionPhotosUnitDataQueryVariables>(
    InspectionPhotosUnitDataDocument,
    options
  );
}
export function useInspectionPhotosUnitDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InspectionPhotosUnitDataQuery,
    InspectionPhotosUnitDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InspectionPhotosUnitDataQuery, InspectionPhotosUnitDataQueryVariables>(
    InspectionPhotosUnitDataDocument,
    options
  );
}
export type InspectionPhotosUnitDataQueryHookResult = ReturnType<
  typeof useInspectionPhotosUnitDataQuery
>;
export type InspectionPhotosUnitDataLazyQueryHookResult = ReturnType<
  typeof useInspectionPhotosUnitDataLazyQuery
>;
export type InspectionPhotosUnitDataQueryResult = Apollo.QueryResult<
  InspectionPhotosUnitDataQuery,
  InspectionPhotosUnitDataQueryVariables
>;
export const InspectionPhotosResidencyDataDocument = gql`
  query InspectionPhotosResidencyData($residencyId: ID!) {
    residency(id: $residencyId) {
      id
      moveInInspection {
        id
        ...InspectionFields
      }
      moveOutInspection {
        id
        ...InspectionFields
      }
    }
  }
  ${InspectionFieldsFragmentDoc}
`;

/**
 * __useInspectionPhotosResidencyDataQuery__
 *
 * To run a query within a React component, call `useInspectionPhotosResidencyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspectionPhotosResidencyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspectionPhotosResidencyDataQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useInspectionPhotosResidencyDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    InspectionPhotosResidencyDataQuery,
    InspectionPhotosResidencyDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InspectionPhotosResidencyDataQuery,
    InspectionPhotosResidencyDataQueryVariables
  >(InspectionPhotosResidencyDataDocument, options);
}
export function useInspectionPhotosResidencyDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InspectionPhotosResidencyDataQuery,
    InspectionPhotosResidencyDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InspectionPhotosResidencyDataQuery,
    InspectionPhotosResidencyDataQueryVariables
  >(InspectionPhotosResidencyDataDocument, options);
}
export type InspectionPhotosResidencyDataQueryHookResult = ReturnType<
  typeof useInspectionPhotosResidencyDataQuery
>;
export type InspectionPhotosResidencyDataLazyQueryHookResult = ReturnType<
  typeof useInspectionPhotosResidencyDataLazyQuery
>;
export type InspectionPhotosResidencyDataQueryResult = Apollo.QueryResult<
  InspectionPhotosResidencyDataQuery,
  InspectionPhotosResidencyDataQueryVariables
>;
export const UnassignStorageDocument = gql`
  mutation unassignStorage($input: AssignStorageInput!) {
    unassignStorage(input: $input) {
      success
      property {
        id
        storage {
          name
          unitId
        }
      }
      unit {
        id
        storage
      }
    }
  }
`;
export type UnassignStorageMutationFn = Apollo.MutationFunction<
  UnassignStorageMutation,
  UnassignStorageMutationVariables
>;

/**
 * __useUnassignStorageMutation__
 *
 * To run a mutation, you first call `useUnassignStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignStorageMutation, { data, loading, error }] = useUnassignStorageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignStorageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnassignStorageMutation,
    UnassignStorageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnassignStorageMutation, UnassignStorageMutationVariables>(
    UnassignStorageDocument,
    options
  );
}
export type UnassignStorageMutationHookResult = ReturnType<typeof useUnassignStorageMutation>;
export type UnassignStorageMutationResult = Apollo.MutationResult<UnassignStorageMutation>;
export type UnassignStorageMutationOptions = Apollo.BaseMutationOptions<
  UnassignStorageMutation,
  UnassignStorageMutationVariables
>;
export const AssignStorageDocument = gql`
  mutation assignStorage($input: AssignStorageInput!) {
    assignStorage(input: $input) {
      success
      property {
        id
        storage {
          name
          unitId
        }
      }
      unit {
        id
        storage
      }
    }
  }
`;
export type AssignStorageMutationFn = Apollo.MutationFunction<
  AssignStorageMutation,
  AssignStorageMutationVariables
>;

/**
 * __useAssignStorageMutation__
 *
 * To run a mutation, you first call `useAssignStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignStorageMutation, { data, loading, error }] = useAssignStorageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignStorageMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignStorageMutation, AssignStorageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignStorageMutation, AssignStorageMutationVariables>(
    AssignStorageDocument,
    options
  );
}
export type AssignStorageMutationHookResult = ReturnType<typeof useAssignStorageMutation>;
export type AssignStorageMutationResult = Apollo.MutationResult<AssignStorageMutation>;
export type AssignStorageMutationOptions = Apollo.BaseMutationOptions<
  AssignStorageMutation,
  AssignStorageMutationVariables
>;
export const UnassignParkingDocument = gql`
  mutation unassignParking($input: AssignParkingInput!) {
    unassignParking(input: $input) {
      success
      property {
        id
        parking {
          name
          unitId
        }
      }
      unit {
        id
        parking
      }
    }
  }
`;
export type UnassignParkingMutationFn = Apollo.MutationFunction<
  UnassignParkingMutation,
  UnassignParkingMutationVariables
>;

/**
 * __useUnassignParkingMutation__
 *
 * To run a mutation, you first call `useUnassignParkingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignParkingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignParkingMutation, { data, loading, error }] = useUnassignParkingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignParkingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnassignParkingMutation,
    UnassignParkingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnassignParkingMutation, UnassignParkingMutationVariables>(
    UnassignParkingDocument,
    options
  );
}
export type UnassignParkingMutationHookResult = ReturnType<typeof useUnassignParkingMutation>;
export type UnassignParkingMutationResult = Apollo.MutationResult<UnassignParkingMutation>;
export type UnassignParkingMutationOptions = Apollo.BaseMutationOptions<
  UnassignParkingMutation,
  UnassignParkingMutationVariables
>;
export const AssignParkingDocument = gql`
  mutation assignParking($input: AssignParkingInput!) {
    assignParking(input: $input) {
      success
      property {
        id
        parking {
          name
          unitId
        }
      }
      unit {
        id
        parking
      }
    }
  }
`;
export type AssignParkingMutationFn = Apollo.MutationFunction<
  AssignParkingMutation,
  AssignParkingMutationVariables
>;

/**
 * __useAssignParkingMutation__
 *
 * To run a mutation, you first call `useAssignParkingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignParkingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignParkingMutation, { data, loading, error }] = useAssignParkingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignParkingMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignParkingMutation, AssignParkingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignParkingMutation, AssignParkingMutationVariables>(
    AssignParkingDocument,
    options
  );
}
export type AssignParkingMutationHookResult = ReturnType<typeof useAssignParkingMutation>;
export type AssignParkingMutationResult = Apollo.MutationResult<AssignParkingMutation>;
export type AssignParkingMutationOptions = Apollo.BaseMutationOptions<
  AssignParkingMutation,
  AssignParkingMutationVariables
>;
export const UnassignKeyringDocument = gql`
  mutation unassignKeyring($input: AssignKeyringInput!) {
    unassignKeyring(input: $input) {
      success
      property {
        id
        keyring {
          name
          unitId
        }
      }
      unit {
        id
        keyring
      }
    }
  }
`;
export type UnassignKeyringMutationFn = Apollo.MutationFunction<
  UnassignKeyringMutation,
  UnassignKeyringMutationVariables
>;

/**
 * __useUnassignKeyringMutation__
 *
 * To run a mutation, you first call `useUnassignKeyringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignKeyringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignKeyringMutation, { data, loading, error }] = useUnassignKeyringMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignKeyringMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnassignKeyringMutation,
    UnassignKeyringMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnassignKeyringMutation, UnassignKeyringMutationVariables>(
    UnassignKeyringDocument,
    options
  );
}
export type UnassignKeyringMutationHookResult = ReturnType<typeof useUnassignKeyringMutation>;
export type UnassignKeyringMutationResult = Apollo.MutationResult<UnassignKeyringMutation>;
export type UnassignKeyringMutationOptions = Apollo.BaseMutationOptions<
  UnassignKeyringMutation,
  UnassignKeyringMutationVariables
>;
export const AssignKeyringDocument = gql`
  mutation assignKeyring($input: AssignKeyringInput!) {
    assignKeyring(input: $input) {
      success
      property {
        id
        keyring {
          name
          unitId
        }
      }
      unit {
        id
        keyring
      }
    }
  }
`;
export type AssignKeyringMutationFn = Apollo.MutationFunction<
  AssignKeyringMutation,
  AssignKeyringMutationVariables
>;

/**
 * __useAssignKeyringMutation__
 *
 * To run a mutation, you first call `useAssignKeyringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignKeyringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignKeyringMutation, { data, loading, error }] = useAssignKeyringMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignKeyringMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignKeyringMutation, AssignKeyringMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignKeyringMutation, AssignKeyringMutationVariables>(
    AssignKeyringDocument,
    options
  );
}
export type AssignKeyringMutationHookResult = ReturnType<typeof useAssignKeyringMutation>;
export type AssignKeyringMutationResult = Apollo.MutationResult<AssignKeyringMutation>;
export type AssignKeyringMutationOptions = Apollo.BaseMutationOptions<
  AssignKeyringMutation,
  AssignKeyringMutationVariables
>;
export const GetUnitFloorplanDocument = gql`
  query GetUnitFloorplan($id: ID!) {
    unit(id: $id) {
      id
      floorplan {
        id
        name
      }
    }
  }
`;

/**
 * __useGetUnitFloorplanQuery__
 *
 * To run a query within a React component, call `useGetUnitFloorplanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitFloorplanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitFloorplanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnitFloorplanQuery(
  baseOptions: Apollo.QueryHookOptions<GetUnitFloorplanQuery, GetUnitFloorplanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnitFloorplanQuery, GetUnitFloorplanQueryVariables>(
    GetUnitFloorplanDocument,
    options
  );
}
export function useGetUnitFloorplanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnitFloorplanQuery, GetUnitFloorplanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnitFloorplanQuery, GetUnitFloorplanQueryVariables>(
    GetUnitFloorplanDocument,
    options
  );
}
export type GetUnitFloorplanQueryHookResult = ReturnType<typeof useGetUnitFloorplanQuery>;
export type GetUnitFloorplanLazyQueryHookResult = ReturnType<typeof useGetUnitFloorplanLazyQuery>;
export type GetUnitFloorplanQueryResult = Apollo.QueryResult<
  GetUnitFloorplanQuery,
  GetUnitFloorplanQueryVariables
>;
export const UpdateUnitFloorplanDocument = gql`
  mutation UpdateUnitFloorplan($input: UpdateUnitFloorplanInput!) {
    updateUnitFloorplan(input: $input) {
      success
      unit {
        id
        bedrooms
        bathrooms
        sqft
        floorplan {
          id
          name
        }
      }
    }
  }
`;
export type UpdateUnitFloorplanMutationFn = Apollo.MutationFunction<
  UpdateUnitFloorplanMutation,
  UpdateUnitFloorplanMutationVariables
>;

/**
 * __useUpdateUnitFloorplanMutation__
 *
 * To run a mutation, you first call `useUpdateUnitFloorplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitFloorplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitFloorplanMutation, { data, loading, error }] = useUpdateUnitFloorplanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUnitFloorplanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUnitFloorplanMutation,
    UpdateUnitFloorplanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUnitFloorplanMutation, UpdateUnitFloorplanMutationVariables>(
    UpdateUnitFloorplanDocument,
    options
  );
}
export type UpdateUnitFloorplanMutationHookResult = ReturnType<
  typeof useUpdateUnitFloorplanMutation
>;
export type UpdateUnitFloorplanMutationResult = Apollo.MutationResult<UpdateUnitFloorplanMutation>;
export type UpdateUnitFloorplanMutationOptions = Apollo.BaseMutationOptions<
  UpdateUnitFloorplanMutation,
  UpdateUnitFloorplanMutationVariables
>;
export const RemoveUnitFloorplanDocument = gql`
  mutation RemoveUnitFloorplan($id: ID!) {
    removeUnitFloorplan(id: $id) {
      success
      unit {
        id
        floorplan {
          id
          name
        }
      }
    }
  }
`;
export type RemoveUnitFloorplanMutationFn = Apollo.MutationFunction<
  RemoveUnitFloorplanMutation,
  RemoveUnitFloorplanMutationVariables
>;

/**
 * __useRemoveUnitFloorplanMutation__
 *
 * To run a mutation, you first call `useRemoveUnitFloorplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUnitFloorplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUnitFloorplanMutation, { data, loading, error }] = useRemoveUnitFloorplanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUnitFloorplanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUnitFloorplanMutation,
    RemoveUnitFloorplanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveUnitFloorplanMutation, RemoveUnitFloorplanMutationVariables>(
    RemoveUnitFloorplanDocument,
    options
  );
}
export type RemoveUnitFloorplanMutationHookResult = ReturnType<
  typeof useRemoveUnitFloorplanMutation
>;
export type RemoveUnitFloorplanMutationResult = Apollo.MutationResult<RemoveUnitFloorplanMutation>;
export type RemoveUnitFloorplanMutationOptions = Apollo.BaseMutationOptions<
  RemoveUnitFloorplanMutation,
  RemoveUnitFloorplanMutationVariables
>;
export const UpdateResidencyDocument = gql`
  mutation UpdateResidency($input: UpdateResidencyInput!) {
    updateResidency(input: $input) {
      success
      error
      residency {
        id
        ...ResidencyFields
      }
    }
  }
  ${ResidencyFieldsFragmentDoc}
`;
export type UpdateResidencyMutationFn = Apollo.MutationFunction<
  UpdateResidencyMutation,
  UpdateResidencyMutationVariables
>;

/**
 * __useUpdateResidencyMutation__
 *
 * To run a mutation, you first call `useUpdateResidencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResidencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResidencyMutation, { data, loading, error }] = useUpdateResidencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResidencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateResidencyMutation,
    UpdateResidencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateResidencyMutation, UpdateResidencyMutationVariables>(
    UpdateResidencyDocument,
    options
  );
}
export type UpdateResidencyMutationHookResult = ReturnType<typeof useUpdateResidencyMutation>;
export type UpdateResidencyMutationResult = Apollo.MutationResult<UpdateResidencyMutation>;
export type UpdateResidencyMutationOptions = Apollo.BaseMutationOptions<
  UpdateResidencyMutation,
  UpdateResidencyMutationVariables
>;
export const CreateRequestRecurrenceDocument = gql`
  mutation createRequestRecurrence($input: CreateRequestRecurrenceInput!) {
    createRequestRecurrence(input: $input) {
      success
      error
      requestRecurrence {
        id
        accountId
        rrule
        notes {
          id
          text
        }
        nonBillable
        summary
        minutes
        instructions
        checklist {
          id
        }
        details
        originalRequest
        subcategory
        category
        name
        unit {
          id
          name
          occupancy
          ownerId
          property {
            id
            address {
              country
              street
              city
              province
              postal
              suite
              lat
              lng
            }
          }
        }
        latestRequest {
          id
          status
        }
      }
    }
  }
`;
export type CreateRequestRecurrenceMutationFn = Apollo.MutationFunction<
  CreateRequestRecurrenceMutation,
  CreateRequestRecurrenceMutationVariables
>;

/**
 * __useCreateRequestRecurrenceMutation__
 *
 * To run a mutation, you first call `useCreateRequestRecurrenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestRecurrenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestRecurrenceMutation, { data, loading, error }] = useCreateRequestRecurrenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestRecurrenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRequestRecurrenceMutation,
    CreateRequestRecurrenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRequestRecurrenceMutation,
    CreateRequestRecurrenceMutationVariables
  >(CreateRequestRecurrenceDocument, options);
}
export type CreateRequestRecurrenceMutationHookResult = ReturnType<
  typeof useCreateRequestRecurrenceMutation
>;
export type CreateRequestRecurrenceMutationResult =
  Apollo.MutationResult<CreateRequestRecurrenceMutation>;
export type CreateRequestRecurrenceMutationOptions = Apollo.BaseMutationOptions<
  CreateRequestRecurrenceMutation,
  CreateRequestRecurrenceMutationVariables
>;
export const CreateResidencyPathDocument = gql`
  query CreateResidencyPath($unitId: ID!) {
    unit(id: $unitId) {
      id
      ...UnitFieldsWithResidency
    }
  }
  ${UnitFieldsWithResidencyFragmentDoc}
`;

/**
 * __useCreateResidencyPathQuery__
 *
 * To run a query within a React component, call `useCreateResidencyPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateResidencyPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateResidencyPathQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useCreateResidencyPathQuery(
  baseOptions: Apollo.QueryHookOptions<CreateResidencyPathQuery, CreateResidencyPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreateResidencyPathQuery, CreateResidencyPathQueryVariables>(
    CreateResidencyPathDocument,
    options
  );
}
export function useCreateResidencyPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreateResidencyPathQuery,
    CreateResidencyPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreateResidencyPathQuery, CreateResidencyPathQueryVariables>(
    CreateResidencyPathDocument,
    options
  );
}
export type CreateResidencyPathQueryHookResult = ReturnType<typeof useCreateResidencyPathQuery>;
export type CreateResidencyPathLazyQueryHookResult = ReturnType<
  typeof useCreateResidencyPathLazyQuery
>;
export type CreateResidencyPathQueryResult = Apollo.QueryResult<
  CreateResidencyPathQuery,
  CreateResidencyPathQueryVariables
>;
export const CreateResidencyDocument = gql`
  mutation CreateResidency($input: CreateResidencyInput!) {
    createResidency(input: $input) {
      success
      error
      residency {
        id
        ...ResidencyFields
      }
    }
  }
  ${ResidencyFieldsFragmentDoc}
`;
export type CreateResidencyMutationFn = Apollo.MutationFunction<
  CreateResidencyMutation,
  CreateResidencyMutationVariables
>;

/**
 * __useCreateResidencyMutation__
 *
 * To run a mutation, you first call `useCreateResidencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResidencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResidencyMutation, { data, loading, error }] = useCreateResidencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResidencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateResidencyMutation,
    CreateResidencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateResidencyMutation, CreateResidencyMutationVariables>(
    CreateResidencyDocument,
    options
  );
}
export type CreateResidencyMutationHookResult = ReturnType<typeof useCreateResidencyMutation>;
export type CreateResidencyMutationResult = Apollo.MutationResult<CreateResidencyMutation>;
export type CreateResidencyMutationOptions = Apollo.BaseMutationOptions<
  CreateResidencyMutation,
  CreateResidencyMutationVariables
>;
export const DeleteResidencyPathDocument = gql`
  query DeleteResidencyPath($residencyId: ID!) {
    residency(id: $residencyId) {
      id
      endZ
      startZ
      unitId
    }
  }
`;

/**
 * __useDeleteResidencyPathQuery__
 *
 * To run a query within a React component, call `useDeleteResidencyPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteResidencyPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteResidencyPathQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useDeleteResidencyPathQuery(
  baseOptions: Apollo.QueryHookOptions<DeleteResidencyPathQuery, DeleteResidencyPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DeleteResidencyPathQuery, DeleteResidencyPathQueryVariables>(
    DeleteResidencyPathDocument,
    options
  );
}
export function useDeleteResidencyPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeleteResidencyPathQuery,
    DeleteResidencyPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DeleteResidencyPathQuery, DeleteResidencyPathQueryVariables>(
    DeleteResidencyPathDocument,
    options
  );
}
export type DeleteResidencyPathQueryHookResult = ReturnType<typeof useDeleteResidencyPathQuery>;
export type DeleteResidencyPathLazyQueryHookResult = ReturnType<
  typeof useDeleteResidencyPathLazyQuery
>;
export type DeleteResidencyPathQueryResult = Apollo.QueryResult<
  DeleteResidencyPathQuery,
  DeleteResidencyPathQueryVariables
>;
export const DeleteResidencyDocument = gql`
  mutation DeleteResidency($input: DeleteResidencyInput!) {
    deleteResidency(input: $input) {
      success
      error
      unit {
        id
        allResidencies {
          id
        }
      }
    }
  }
`;
export type DeleteResidencyMutationFn = Apollo.MutationFunction<
  DeleteResidencyMutation,
  DeleteResidencyMutationVariables
>;

/**
 * __useDeleteResidencyMutation__
 *
 * To run a mutation, you first call `useDeleteResidencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResidencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResidencyMutation, { data, loading, error }] = useDeleteResidencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteResidencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteResidencyMutation,
    DeleteResidencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteResidencyMutation, DeleteResidencyMutationVariables>(
    DeleteResidencyDocument,
    options
  );
}
export type DeleteResidencyMutationHookResult = ReturnType<typeof useDeleteResidencyMutation>;
export type DeleteResidencyMutationResult = Apollo.MutationResult<DeleteResidencyMutation>;
export type DeleteResidencyMutationOptions = Apollo.BaseMutationOptions<
  DeleteResidencyMutation,
  DeleteResidencyMutationVariables
>;
export const GetFurnishingsTemplateDocument = gql`
  query GetFurnishingsTemplate {
    furnishingsTemplate {
      id
      name
      items {
        id
        name
      }
    }
  }
`;

/**
 * __useGetFurnishingsTemplateQuery__
 *
 * To run a query within a React component, call `useGetFurnishingsTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFurnishingsTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFurnishingsTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFurnishingsTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFurnishingsTemplateQuery,
    GetFurnishingsTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFurnishingsTemplateQuery, GetFurnishingsTemplateQueryVariables>(
    GetFurnishingsTemplateDocument,
    options
  );
}
export function useGetFurnishingsTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFurnishingsTemplateQuery,
    GetFurnishingsTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFurnishingsTemplateQuery, GetFurnishingsTemplateQueryVariables>(
    GetFurnishingsTemplateDocument,
    options
  );
}
export type GetFurnishingsTemplateQueryHookResult = ReturnType<
  typeof useGetFurnishingsTemplateQuery
>;
export type GetFurnishingsTemplateLazyQueryHookResult = ReturnType<
  typeof useGetFurnishingsTemplateLazyQuery
>;
export type GetFurnishingsTemplateQueryResult = Apollo.QueryResult<
  GetFurnishingsTemplateQuery,
  GetFurnishingsTemplateQueryVariables
>;
export const ResidencyWithInspectionsDocument = gql`
  query ResidencyWithInspections($residencyId: ID!) {
    residency(id: $residencyId) {
      id
      moveInInspection {
        id
        ...InspectionFields
      }
      moveOutInspection {
        id
        ...InspectionFields
      }
    }
  }
  ${InspectionFieldsFragmentDoc}
`;

/**
 * __useResidencyWithInspectionsQuery__
 *
 * To run a query within a React component, call `useResidencyWithInspectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResidencyWithInspectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResidencyWithInspectionsQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useResidencyWithInspectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResidencyWithInspectionsQuery,
    ResidencyWithInspectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResidencyWithInspectionsQuery, ResidencyWithInspectionsQueryVariables>(
    ResidencyWithInspectionsDocument,
    options
  );
}
export function useResidencyWithInspectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResidencyWithInspectionsQuery,
    ResidencyWithInspectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResidencyWithInspectionsQuery, ResidencyWithInspectionsQueryVariables>(
    ResidencyWithInspectionsDocument,
    options
  );
}
export type ResidencyWithInspectionsQueryHookResult = ReturnType<
  typeof useResidencyWithInspectionsQuery
>;
export type ResidencyWithInspectionsLazyQueryHookResult = ReturnType<
  typeof useResidencyWithInspectionsLazyQuery
>;
export type ResidencyWithInspectionsQueryResult = Apollo.QueryResult<
  ResidencyWithInspectionsQuery,
  ResidencyWithInspectionsQueryVariables
>;
export const RemoveResidencyResidentPathDocument = gql`
  query RemoveResidencyResidentPath($residencyId: ID!) {
    residency(id: $residencyId) {
      id
      unitId
      propertyId
      residents {
        id
        leaseHolder
        payeeFrac {
          numerator
          denominator
        }
        resident {
          id
          __typename
          name
        }
      }
    }
  }
`;

/**
 * __useRemoveResidencyResidentPathQuery__
 *
 * To run a query within a React component, call `useRemoveResidencyResidentPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemoveResidencyResidentPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoveResidencyResidentPathQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useRemoveResidencyResidentPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    RemoveResidencyResidentPathQuery,
    RemoveResidencyResidentPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RemoveResidencyResidentPathQuery,
    RemoveResidencyResidentPathQueryVariables
  >(RemoveResidencyResidentPathDocument, options);
}
export function useRemoveResidencyResidentPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RemoveResidencyResidentPathQuery,
    RemoveResidencyResidentPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RemoveResidencyResidentPathQuery,
    RemoveResidencyResidentPathQueryVariables
  >(RemoveResidencyResidentPathDocument, options);
}
export type RemoveResidencyResidentPathQueryHookResult = ReturnType<
  typeof useRemoveResidencyResidentPathQuery
>;
export type RemoveResidencyResidentPathLazyQueryHookResult = ReturnType<
  typeof useRemoveResidencyResidentPathLazyQuery
>;
export type RemoveResidencyResidentPathQueryResult = Apollo.QueryResult<
  RemoveResidencyResidentPathQuery,
  RemoveResidencyResidentPathQueryVariables
>;
export const RemoveResidencyResidentDocument = gql`
  mutation RemoveResidencyResident($input: RemoveResidencyResidentInput!) {
    removeResidencyResident(input: $input) {
      error
      success
      residency {
        id
        residents {
          id
          leaseHolder
          payeeFrac {
            numerator
            denominator
          }
          resident {
            id
            __typename
            name
          }
        }
      }
    }
  }
`;
export type RemoveResidencyResidentMutationFn = Apollo.MutationFunction<
  RemoveResidencyResidentMutation,
  RemoveResidencyResidentMutationVariables
>;

/**
 * __useRemoveResidencyResidentMutation__
 *
 * To run a mutation, you first call `useRemoveResidencyResidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveResidencyResidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeResidencyResidentMutation, { data, loading, error }] = useRemoveResidencyResidentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveResidencyResidentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveResidencyResidentMutation,
    RemoveResidencyResidentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveResidencyResidentMutation,
    RemoveResidencyResidentMutationVariables
  >(RemoveResidencyResidentDocument, options);
}
export type RemoveResidencyResidentMutationHookResult = ReturnType<
  typeof useRemoveResidencyResidentMutation
>;
export type RemoveResidencyResidentMutationResult =
  Apollo.MutationResult<RemoveResidencyResidentMutation>;
export type RemoveResidencyResidentMutationOptions = Apollo.BaseMutationOptions<
  RemoveResidencyResidentMutation,
  RemoveResidencyResidentMutationVariables
>;
export const UpdateResidencyInsuranceDocument = gql`
  mutation UpdateResidencyInsurance(
    $id: ID!
    $unitId: ID!
    $propertyId: ID!
    $input: ResidencyInsuranceInput!
  ) {
    updateResidencyInsurance(id: $id, unitId: $unitId, propertyId: $propertyId, input: $input) {
      success
      error
      residency {
        id
        insurancePolicies {
          id
          policyNumber
          providerName
          expirationDate
          liability
        }
      }
    }
  }
`;
export type UpdateResidencyInsuranceMutationFn = Apollo.MutationFunction<
  UpdateResidencyInsuranceMutation,
  UpdateResidencyInsuranceMutationVariables
>;

/**
 * __useUpdateResidencyInsuranceMutation__
 *
 * To run a mutation, you first call `useUpdateResidencyInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResidencyInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResidencyInsuranceMutation, { data, loading, error }] = useUpdateResidencyInsuranceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unitId: // value for 'unitId'
 *      propertyId: // value for 'propertyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResidencyInsuranceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateResidencyInsuranceMutation,
    UpdateResidencyInsuranceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateResidencyInsuranceMutation,
    UpdateResidencyInsuranceMutationVariables
  >(UpdateResidencyInsuranceDocument, options);
}
export type UpdateResidencyInsuranceMutationHookResult = ReturnType<
  typeof useUpdateResidencyInsuranceMutation
>;
export type UpdateResidencyInsuranceMutationResult =
  Apollo.MutationResult<UpdateResidencyInsuranceMutation>;
export type UpdateResidencyInsuranceMutationOptions = Apollo.BaseMutationOptions<
  UpdateResidencyInsuranceMutation,
  UpdateResidencyInsuranceMutationVariables
>;
export const RemoveResidencyInsuranceDocument = gql`
  mutation RemoveResidencyInsurance($id: ID!, $unitId: ID!, $propertyId: ID!) {
    removeResidencyInsurance(id: $id, unitId: $unitId, propertyId: $propertyId) {
      success
      error
      residency {
        id
        insurancePolicies {
          id
          policyNumber
          providerName
          expirationDate
          liability
        }
      }
    }
  }
`;
export type RemoveResidencyInsuranceMutationFn = Apollo.MutationFunction<
  RemoveResidencyInsuranceMutation,
  RemoveResidencyInsuranceMutationVariables
>;

/**
 * __useRemoveResidencyInsuranceMutation__
 *
 * To run a mutation, you first call `useRemoveResidencyInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveResidencyInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeResidencyInsuranceMutation, { data, loading, error }] = useRemoveResidencyInsuranceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unitId: // value for 'unitId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useRemoveResidencyInsuranceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveResidencyInsuranceMutation,
    RemoveResidencyInsuranceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveResidencyInsuranceMutation,
    RemoveResidencyInsuranceMutationVariables
  >(RemoveResidencyInsuranceDocument, options);
}
export type RemoveResidencyInsuranceMutationHookResult = ReturnType<
  typeof useRemoveResidencyInsuranceMutation
>;
export type RemoveResidencyInsuranceMutationResult =
  Apollo.MutationResult<RemoveResidencyInsuranceMutation>;
export type RemoveResidencyInsuranceMutationOptions = Apollo.BaseMutationOptions<
  RemoveResidencyInsuranceMutation,
  RemoveResidencyInsuranceMutationVariables
>;
export const UpdateResidencyInsurancePolicyPathDocument = gql`
  query UpdateResidencyInsurancePolicyPath($residencyId: ID!) {
    residency(id: $residencyId) {
      id
      insurancePolicies {
        id
        policyNumber
        providerName
        expirationDate
        liability
      }
    }
  }
`;

/**
 * __useUpdateResidencyInsurancePolicyPathQuery__
 *
 * To run a query within a React component, call `useUpdateResidencyInsurancePolicyPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateResidencyInsurancePolicyPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateResidencyInsurancePolicyPathQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useUpdateResidencyInsurancePolicyPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateResidencyInsurancePolicyPathQuery,
    UpdateResidencyInsurancePolicyPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UpdateResidencyInsurancePolicyPathQuery,
    UpdateResidencyInsurancePolicyPathQueryVariables
  >(UpdateResidencyInsurancePolicyPathDocument, options);
}
export function useUpdateResidencyInsurancePolicyPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateResidencyInsurancePolicyPathQuery,
    UpdateResidencyInsurancePolicyPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UpdateResidencyInsurancePolicyPathQuery,
    UpdateResidencyInsurancePolicyPathQueryVariables
  >(UpdateResidencyInsurancePolicyPathDocument, options);
}
export type UpdateResidencyInsurancePolicyPathQueryHookResult = ReturnType<
  typeof useUpdateResidencyInsurancePolicyPathQuery
>;
export type UpdateResidencyInsurancePolicyPathLazyQueryHookResult = ReturnType<
  typeof useUpdateResidencyInsurancePolicyPathLazyQuery
>;
export type UpdateResidencyInsurancePolicyPathQueryResult = Apollo.QueryResult<
  UpdateResidencyInsurancePolicyPathQuery,
  UpdateResidencyInsurancePolicyPathQueryVariables
>;
export const ResidencyKeysPathDocument = gql`
  query ResidencyKeysPath($residencyId: ID!) {
    residency(id: $residencyId) {
      id
      unitId
      propertyId
      campaignId
      applicationId
    }
  }
`;

/**
 * __useResidencyKeysPathQuery__
 *
 * To run a query within a React component, call `useResidencyKeysPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useResidencyKeysPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResidencyKeysPathQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useResidencyKeysPathQuery(
  baseOptions: Apollo.QueryHookOptions<ResidencyKeysPathQuery, ResidencyKeysPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResidencyKeysPathQuery, ResidencyKeysPathQueryVariables>(
    ResidencyKeysPathDocument,
    options
  );
}
export function useResidencyKeysPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ResidencyKeysPathQuery, ResidencyKeysPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResidencyKeysPathQuery, ResidencyKeysPathQueryVariables>(
    ResidencyKeysPathDocument,
    options
  );
}
export type ResidencyKeysPathQueryHookResult = ReturnType<typeof useResidencyKeysPathQuery>;
export type ResidencyKeysPathLazyQueryHookResult = ReturnType<typeof useResidencyKeysPathLazyQuery>;
export type ResidencyKeysPathQueryResult = Apollo.QueryResult<
  ResidencyKeysPathQuery,
  ResidencyKeysPathQueryVariables
>;
export const ResidencySecurityDepositHistoryPathDocument = gql`
  query ResidencySecurityDepositHistoryPath($residencyId: ID!) {
    residency(id: $residencyId) {
      id
      ...ResidencySecurityDepositFields
    }
  }
  ${ResidencySecurityDepositFieldsFragmentDoc}
`;

/**
 * __useResidencySecurityDepositHistoryPathQuery__
 *
 * To run a query within a React component, call `useResidencySecurityDepositHistoryPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useResidencySecurityDepositHistoryPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResidencySecurityDepositHistoryPathQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useResidencySecurityDepositHistoryPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResidencySecurityDepositHistoryPathQuery,
    ResidencySecurityDepositHistoryPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResidencySecurityDepositHistoryPathQuery,
    ResidencySecurityDepositHistoryPathQueryVariables
  >(ResidencySecurityDepositHistoryPathDocument, options);
}
export function useResidencySecurityDepositHistoryPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResidencySecurityDepositHistoryPathQuery,
    ResidencySecurityDepositHistoryPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResidencySecurityDepositHistoryPathQuery,
    ResidencySecurityDepositHistoryPathQueryVariables
  >(ResidencySecurityDepositHistoryPathDocument, options);
}
export type ResidencySecurityDepositHistoryPathQueryHookResult = ReturnType<
  typeof useResidencySecurityDepositHistoryPathQuery
>;
export type ResidencySecurityDepositHistoryPathLazyQueryHookResult = ReturnType<
  typeof useResidencySecurityDepositHistoryPathLazyQuery
>;
export type ResidencySecurityDepositHistoryPathQueryResult = Apollo.QueryResult<
  ResidencySecurityDepositHistoryPathQuery,
  ResidencySecurityDepositHistoryPathQueryVariables
>;
export const AddResidencySecurityDepositStatusDocument = gql`
  mutation AddResidencySecurityDepositStatus($input: AddResidencySecurityDepositStatusInput!) {
    addResidencySecurityDepositStatus(input: $input) {
      success
      error
      residency {
        id
        ...ResidencySecurityDepositFields
      }
    }
  }
  ${ResidencySecurityDepositFieldsFragmentDoc}
`;
export type AddResidencySecurityDepositStatusMutationFn = Apollo.MutationFunction<
  AddResidencySecurityDepositStatusMutation,
  AddResidencySecurityDepositStatusMutationVariables
>;

/**
 * __useAddResidencySecurityDepositStatusMutation__
 *
 * To run a mutation, you first call `useAddResidencySecurityDepositStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddResidencySecurityDepositStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addResidencySecurityDepositStatusMutation, { data, loading, error }] = useAddResidencySecurityDepositStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddResidencySecurityDepositStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddResidencySecurityDepositStatusMutation,
    AddResidencySecurityDepositStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddResidencySecurityDepositStatusMutation,
    AddResidencySecurityDepositStatusMutationVariables
  >(AddResidencySecurityDepositStatusDocument, options);
}
export type AddResidencySecurityDepositStatusMutationHookResult = ReturnType<
  typeof useAddResidencySecurityDepositStatusMutation
>;
export type AddResidencySecurityDepositStatusMutationResult =
  Apollo.MutationResult<AddResidencySecurityDepositStatusMutation>;
export type AddResidencySecurityDepositStatusMutationOptions = Apollo.BaseMutationOptions<
  AddResidencySecurityDepositStatusMutation,
  AddResidencySecurityDepositStatusMutationVariables
>;
export const TerminateResidencyPathDocument = gql`
  query TerminateResidencyPath($residencyId: ID!) {
    residency(id: $residencyId) {
      id
      endZ
      moveOutReason
      unitId
      propertyId
    }
  }
`;

/**
 * __useTerminateResidencyPathQuery__
 *
 * To run a query within a React component, call `useTerminateResidencyPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerminateResidencyPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerminateResidencyPathQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useTerminateResidencyPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    TerminateResidencyPathQuery,
    TerminateResidencyPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TerminateResidencyPathQuery, TerminateResidencyPathQueryVariables>(
    TerminateResidencyPathDocument,
    options
  );
}
export function useTerminateResidencyPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TerminateResidencyPathQuery,
    TerminateResidencyPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TerminateResidencyPathQuery, TerminateResidencyPathQueryVariables>(
    TerminateResidencyPathDocument,
    options
  );
}
export type TerminateResidencyPathQueryHookResult = ReturnType<
  typeof useTerminateResidencyPathQuery
>;
export type TerminateResidencyPathLazyQueryHookResult = ReturnType<
  typeof useTerminateResidencyPathLazyQuery
>;
export type TerminateResidencyPathQueryResult = Apollo.QueryResult<
  TerminateResidencyPathQuery,
  TerminateResidencyPathQueryVariables
>;
export const TerminateResidencyDocument = gql`
  mutation TerminateResidency($input: TerminateResidencyInput!) {
    terminateResidency(input: $input) {
      success
      error
      residency {
        id
        endZ
        terms
        moveOutReason
        unitId
        propertyId
      }
    }
  }
`;
export type TerminateResidencyMutationFn = Apollo.MutationFunction<
  TerminateResidencyMutation,
  TerminateResidencyMutationVariables
>;

/**
 * __useTerminateResidencyMutation__
 *
 * To run a mutation, you first call `useTerminateResidencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateResidencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateResidencyMutation, { data, loading, error }] = useTerminateResidencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateResidencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TerminateResidencyMutation,
    TerminateResidencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TerminateResidencyMutation, TerminateResidencyMutationVariables>(
    TerminateResidencyDocument,
    options
  );
}
export type TerminateResidencyMutationHookResult = ReturnType<typeof useTerminateResidencyMutation>;
export type TerminateResidencyMutationResult = Apollo.MutationResult<TerminateResidencyMutation>;
export type TerminateResidencyMutationOptions = Apollo.BaseMutationOptions<
  TerminateResidencyMutation,
  TerminateResidencyMutationVariables
>;
export const GetUtilitiesTemplateDocument = gql`
  query GetUtilitiesTemplate($propertyId: ID) {
    utilitiesTemplate(propertyId: $propertyId) {
      id
      name
      items {
        id
        name
      }
    }
  }
`;

/**
 * __useGetUtilitiesTemplateQuery__
 *
 * To run a query within a React component, call `useGetUtilitiesTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUtilitiesTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUtilitiesTemplateQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetUtilitiesTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUtilitiesTemplateQuery,
    GetUtilitiesTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUtilitiesTemplateQuery, GetUtilitiesTemplateQueryVariables>(
    GetUtilitiesTemplateDocument,
    options
  );
}
export function useGetUtilitiesTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUtilitiesTemplateQuery,
    GetUtilitiesTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUtilitiesTemplateQuery, GetUtilitiesTemplateQueryVariables>(
    GetUtilitiesTemplateDocument,
    options
  );
}
export type GetUtilitiesTemplateQueryHookResult = ReturnType<typeof useGetUtilitiesTemplateQuery>;
export type GetUtilitiesTemplateLazyQueryHookResult = ReturnType<
  typeof useGetUtilitiesTemplateLazyQuery
>;
export type GetUtilitiesTemplateQueryResult = Apollo.QueryResult<
  GetUtilitiesTemplateQuery,
  GetUtilitiesTemplateQueryVariables
>;
export const CreateInspectionUrlDocument = gql`
  mutation CreateInspectionUrl($input: InspectionUrlInput!) {
    createInspectionUrl(input: $input) {
      error
      success
      inspectionUrlObject {
        fileUrl
        filename
        message
      }
    }
  }
`;
export type CreateInspectionUrlMutationFn = Apollo.MutationFunction<
  CreateInspectionUrlMutation,
  CreateInspectionUrlMutationVariables
>;

/**
 * __useCreateInspectionUrlMutation__
 *
 * To run a mutation, you first call `useCreateInspectionUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInspectionUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInspectionUrlMutation, { data, loading, error }] = useCreateInspectionUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInspectionUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInspectionUrlMutation,
    CreateInspectionUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInspectionUrlMutation, CreateInspectionUrlMutationVariables>(
    CreateInspectionUrlDocument,
    options
  );
}
export type CreateInspectionUrlMutationHookResult = ReturnType<
  typeof useCreateInspectionUrlMutation
>;
export type CreateInspectionUrlMutationResult = Apollo.MutationResult<CreateInspectionUrlMutation>;
export type CreateInspectionUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateInspectionUrlMutation,
  CreateInspectionUrlMutationVariables
>;
export const GetUnitRoutineInspectionsDocument = gql`
  query GetUnitRoutineInspections($id: ID!) {
    unit(id: $id) {
      id
      routineInspections {
        ...RoutineInspectionFields
      }
    }
  }
  ${RoutineInspectionFieldsFragmentDoc}
`;

/**
 * __useGetUnitRoutineInspectionsQuery__
 *
 * To run a query within a React component, call `useGetUnitRoutineInspectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitRoutineInspectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitRoutineInspectionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnitRoutineInspectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUnitRoutineInspectionsQuery,
    GetUnitRoutineInspectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnitRoutineInspectionsQuery, GetUnitRoutineInspectionsQueryVariables>(
    GetUnitRoutineInspectionsDocument,
    options
  );
}
export function useGetUnitRoutineInspectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnitRoutineInspectionsQuery,
    GetUnitRoutineInspectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUnitRoutineInspectionsQuery,
    GetUnitRoutineInspectionsQueryVariables
  >(GetUnitRoutineInspectionsDocument, options);
}
export type GetUnitRoutineInspectionsQueryHookResult = ReturnType<
  typeof useGetUnitRoutineInspectionsQuery
>;
export type GetUnitRoutineInspectionsLazyQueryHookResult = ReturnType<
  typeof useGetUnitRoutineInspectionsLazyQuery
>;
export type GetUnitRoutineInspectionsQueryResult = Apollo.QueryResult<
  GetUnitRoutineInspectionsQuery,
  GetUnitRoutineInspectionsQueryVariables
>;
export const CreateRequestDocument = gql`
  mutation CreateRequest($input: CreateRequestInput!) {
    createRequest(input: $input) {
      success
      error
      request {
        ...RequestFields
      }
    }
  }
  ${RequestFieldsFragmentDoc}
`;
export type CreateRequestMutationFn = Apollo.MutationFunction<
  CreateRequestMutation,
  CreateRequestMutationVariables
>;

/**
 * __useCreateRequestMutation__
 *
 * To run a mutation, you first call `useCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestMutation, { data, loading, error }] = useCreateRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRequestMutation, CreateRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRequestMutation, CreateRequestMutationVariables>(
    CreateRequestDocument,
    options
  );
}
export type CreateRequestMutationHookResult = ReturnType<typeof useCreateRequestMutation>;
export type CreateRequestMutationResult = Apollo.MutationResult<CreateRequestMutation>;
export type CreateRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateRequestMutation,
  CreateRequestMutationVariables
>;
export const GetUnitWithResidencyForRequestDocument = gql`
  query GetUnitWithResidencyForRequest($id: ID!) {
    unit(id: $id) {
      ...UnitResidencyDataForRequest
    }
  }
  ${UnitResidencyDataForRequestFragmentDoc}
`;

/**
 * __useGetUnitWithResidencyForRequestQuery__
 *
 * To run a query within a React component, call `useGetUnitWithResidencyForRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitWithResidencyForRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitWithResidencyForRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnitWithResidencyForRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUnitWithResidencyForRequestQuery,
    GetUnitWithResidencyForRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUnitWithResidencyForRequestQuery,
    GetUnitWithResidencyForRequestQueryVariables
  >(GetUnitWithResidencyForRequestDocument, options);
}
export function useGetUnitWithResidencyForRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnitWithResidencyForRequestQuery,
    GetUnitWithResidencyForRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUnitWithResidencyForRequestQuery,
    GetUnitWithResidencyForRequestQueryVariables
  >(GetUnitWithResidencyForRequestDocument, options);
}
export type GetUnitWithResidencyForRequestQueryHookResult = ReturnType<
  typeof useGetUnitWithResidencyForRequestQuery
>;
export type GetUnitWithResidencyForRequestLazyQueryHookResult = ReturnType<
  typeof useGetUnitWithResidencyForRequestLazyQuery
>;
export type GetUnitWithResidencyForRequestQueryResult = Apollo.QueryResult<
  GetUnitWithResidencyForRequestQuery,
  GetUnitWithResidencyForRequestQueryVariables
>;
export const RequestDocumentsDocument = gql`
  query RequestDocuments($id: ID!) {
    request(id: $id) {
      id
      documents {
        ...DocumentFields
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`;

/**
 * __useRequestDocumentsQuery__
 *
 * To run a query within a React component, call `useRequestDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequestDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<RequestDocumentsQuery, RequestDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RequestDocumentsQuery, RequestDocumentsQueryVariables>(
    RequestDocumentsDocument,
    options
  );
}
export function useRequestDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RequestDocumentsQuery, RequestDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RequestDocumentsQuery, RequestDocumentsQueryVariables>(
    RequestDocumentsDocument,
    options
  );
}
export type RequestDocumentsQueryHookResult = ReturnType<typeof useRequestDocumentsQuery>;
export type RequestDocumentsLazyQueryHookResult = ReturnType<typeof useRequestDocumentsLazyQuery>;
export type RequestDocumentsQueryResult = Apollo.QueryResult<
  RequestDocumentsQuery,
  RequestDocumentsQueryVariables
>;
export const RenameRequestDocumentDocument = gql`
  mutation RenameRequestDocument($input: RenameRequestDocumentInput!) {
    renameRequestDocument(input: $input) {
      request {
        id
        documents {
          ...DocumentFields
        }
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`;
export type RenameRequestDocumentMutationFn = Apollo.MutationFunction<
  RenameRequestDocumentMutation,
  RenameRequestDocumentMutationVariables
>;

/**
 * __useRenameRequestDocumentMutation__
 *
 * To run a mutation, you first call `useRenameRequestDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameRequestDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameRequestDocumentMutation, { data, loading, error }] = useRenameRequestDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameRequestDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameRequestDocumentMutation,
    RenameRequestDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RenameRequestDocumentMutation, RenameRequestDocumentMutationVariables>(
    RenameRequestDocumentDocument,
    options
  );
}
export type RenameRequestDocumentMutationHookResult = ReturnType<
  typeof useRenameRequestDocumentMutation
>;
export type RenameRequestDocumentMutationResult =
  Apollo.MutationResult<RenameRequestDocumentMutation>;
export type RenameRequestDocumentMutationOptions = Apollo.BaseMutationOptions<
  RenameRequestDocumentMutation,
  RenameRequestDocumentMutationVariables
>;
export const CreateResidencyWizardPathDocument = gql`
  query CreateResidencyWizardPath($unitId: ID!, $propertyId: ID!) {
    unit(id: $unitId) {
      id
      name
      ...UnitEntityFields
      allResidencies {
        id
        ...ResidencyFields
      }
    }
    property(id: $propertyId) {
      id
      name
      enabled
      ownerId
      autopayStatus
      autopayBankAccounts {
        id
        collectionType
      }
      address {
        ...AddressFields
      }
    }
  }
  ${UnitEntityFieldsFragmentDoc}
  ${ResidencyFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;

/**
 * __useCreateResidencyWizardPathQuery__
 *
 * To run a query within a React component, call `useCreateResidencyWizardPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateResidencyWizardPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateResidencyWizardPathQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useCreateResidencyWizardPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreateResidencyWizardPathQuery,
    CreateResidencyWizardPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreateResidencyWizardPathQuery, CreateResidencyWizardPathQueryVariables>(
    CreateResidencyWizardPathDocument,
    options
  );
}
export function useCreateResidencyWizardPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreateResidencyWizardPathQuery,
    CreateResidencyWizardPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CreateResidencyWizardPathQuery,
    CreateResidencyWizardPathQueryVariables
  >(CreateResidencyWizardPathDocument, options);
}
export type CreateResidencyWizardPathQueryHookResult = ReturnType<
  typeof useCreateResidencyWizardPathQuery
>;
export type CreateResidencyWizardPathLazyQueryHookResult = ReturnType<
  typeof useCreateResidencyWizardPathLazyQuery
>;
export type CreateResidencyWizardPathQueryResult = Apollo.QueryResult<
  CreateResidencyWizardPathQuery,
  CreateResidencyWizardPathQueryVariables
>;
export const AddResidencyEventDocument = gql`
  mutation AddResidencyEvent($input: AddResidencyEventInput!) {
    addResidencyEvent(input: $input) {
      success
      error
      residency {
        id
        eventHistory {
          id
          name
          category
          description
          eventDateZ
          deletedZ
          resolvedZ
          noticeZ
          noteText
        }
      }
    }
  }
`;
export type AddResidencyEventMutationFn = Apollo.MutationFunction<
  AddResidencyEventMutation,
  AddResidencyEventMutationVariables
>;

/**
 * __useAddResidencyEventMutation__
 *
 * To run a mutation, you first call `useAddResidencyEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddResidencyEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addResidencyEventMutation, { data, loading, error }] = useAddResidencyEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddResidencyEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddResidencyEventMutation,
    AddResidencyEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddResidencyEventMutation, AddResidencyEventMutationVariables>(
    AddResidencyEventDocument,
    options
  );
}
export type AddResidencyEventMutationHookResult = ReturnType<typeof useAddResidencyEventMutation>;
export type AddResidencyEventMutationResult = Apollo.MutationResult<AddResidencyEventMutation>;
export type AddResidencyEventMutationOptions = Apollo.BaseMutationOptions<
  AddResidencyEventMutation,
  AddResidencyEventMutationVariables
>;
export const UpdateResidencyEventDocument = gql`
  mutation UpdateResidencyEvent($input: UpdateResidencyEventInput!) {
    updateResidencyEvent(input: $input) {
      success
      error
      residency {
        id
        eventHistory {
          id
          name
          category
          description
          eventDateZ
          deletedZ
          resolvedZ
          noticeZ
          noteText
        }
      }
    }
  }
`;
export type UpdateResidencyEventMutationFn = Apollo.MutationFunction<
  UpdateResidencyEventMutation,
  UpdateResidencyEventMutationVariables
>;

/**
 * __useUpdateResidencyEventMutation__
 *
 * To run a mutation, you first call `useUpdateResidencyEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResidencyEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResidencyEventMutation, { data, loading, error }] = useUpdateResidencyEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResidencyEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateResidencyEventMutation,
    UpdateResidencyEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateResidencyEventMutation, UpdateResidencyEventMutationVariables>(
    UpdateResidencyEventDocument,
    options
  );
}
export type UpdateResidencyEventMutationHookResult = ReturnType<
  typeof useUpdateResidencyEventMutation
>;
export type UpdateResidencyEventMutationResult =
  Apollo.MutationResult<UpdateResidencyEventMutation>;
export type UpdateResidencyEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateResidencyEventMutation,
  UpdateResidencyEventMutationVariables
>;
export const DeleteResidencyEventDocument = gql`
  mutation DeleteResidencyEvent($input: DeleteResidencyEventInput!) {
    deleteResidencyEvent(input: $input) {
      success
      error
      residency {
        id
        eventHistory {
          id
          name
          category
          description
          eventDateZ
          deletedZ
          resolvedZ
          noticeZ
          noteText
        }
      }
    }
  }
`;
export type DeleteResidencyEventMutationFn = Apollo.MutationFunction<
  DeleteResidencyEventMutation,
  DeleteResidencyEventMutationVariables
>;

/**
 * __useDeleteResidencyEventMutation__
 *
 * To run a mutation, you first call `useDeleteResidencyEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResidencyEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResidencyEventMutation, { data, loading, error }] = useDeleteResidencyEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteResidencyEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteResidencyEventMutation,
    DeleteResidencyEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteResidencyEventMutation, DeleteResidencyEventMutationVariables>(
    DeleteResidencyEventDocument,
    options
  );
}
export type DeleteResidencyEventMutationHookResult = ReturnType<
  typeof useDeleteResidencyEventMutation
>;
export type DeleteResidencyEventMutationResult =
  Apollo.MutationResult<DeleteResidencyEventMutation>;
export type DeleteResidencyEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteResidencyEventMutation,
  DeleteResidencyEventMutationVariables
>;
export const ResidencyPathDocument = gql`
  query ResidencyPath($residencyId: ID!, $unitId: ID!, $propertyId: ID!) {
    unit(id: $unitId) {
      id
      name
      ...UnitEntityFields
      allResidencies {
        id
        ...ResidencyFields
      }
      residency(id: $residencyId) {
        id
        ...ResidencyFields
        refundables {
          id
          name
          settledZ
          collectedZ
          templateId
          accrualStartExpr
          ledger {
            id
            date
            amount
            category
          }
        }
      }
    }
    property(id: $propertyId) {
      id
      name
      enabled
      ownerId
      address {
        province
      }
    }
  }
  ${UnitEntityFieldsFragmentDoc}
  ${ResidencyFieldsFragmentDoc}
`;

/**
 * __useResidencyPathQuery__
 *
 * To run a query within a React component, call `useResidencyPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useResidencyPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResidencyPathQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *      unitId: // value for 'unitId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useResidencyPathQuery(
  baseOptions: Apollo.QueryHookOptions<ResidencyPathQuery, ResidencyPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResidencyPathQuery, ResidencyPathQueryVariables>(
    ResidencyPathDocument,
    options
  );
}
export function useResidencyPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ResidencyPathQuery, ResidencyPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResidencyPathQuery, ResidencyPathQueryVariables>(
    ResidencyPathDocument,
    options
  );
}
export type ResidencyPathQueryHookResult = ReturnType<typeof useResidencyPathQuery>;
export type ResidencyPathLazyQueryHookResult = ReturnType<typeof useResidencyPathLazyQuery>;
export type ResidencyPathQueryResult = Apollo.QueryResult<
  ResidencyPathQuery,
  ResidencyPathQueryVariables
>;
export const CurrentResidenciesPathDocument = gql`
  query CurrentResidenciesPath($unitId: ID!, $propertyId: ID!) {
    unit(id: $unitId) {
      id
      name
      ...UnitEntityFields
      allResidencies {
        id
        ...ResidencyFields
      }
      currentResidencies {
        id
        ...ResidencyFields
      }
    }
    property(id: $propertyId) {
      id
      name
      enabled
      ownerId
      address {
        province
      }
    }
  }
  ${UnitEntityFieldsFragmentDoc}
  ${ResidencyFieldsFragmentDoc}
`;

/**
 * __useCurrentResidenciesPathQuery__
 *
 * To run a query within a React component, call `useCurrentResidenciesPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentResidenciesPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentResidenciesPathQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useCurrentResidenciesPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentResidenciesPathQuery,
    CurrentResidenciesPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentResidenciesPathQuery, CurrentResidenciesPathQueryVariables>(
    CurrentResidenciesPathDocument,
    options
  );
}
export function useCurrentResidenciesPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentResidenciesPathQuery,
    CurrentResidenciesPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentResidenciesPathQuery, CurrentResidenciesPathQueryVariables>(
    CurrentResidenciesPathDocument,
    options
  );
}
export type CurrentResidenciesPathQueryHookResult = ReturnType<
  typeof useCurrentResidenciesPathQuery
>;
export type CurrentResidenciesPathLazyQueryHookResult = ReturnType<
  typeof useCurrentResidenciesPathLazyQuery
>;
export type CurrentResidenciesPathQueryResult = Apollo.QueryResult<
  CurrentResidenciesPathQuery,
  CurrentResidenciesPathQueryVariables
>;
export const ResidencyProRatedEffectsDocument = gql`
  query ResidencyProRatedEffects($input: ResidencyProRatedEffectsInput!) {
    residencyProRatedEffects(input: $input) {
      residents {
        name
        residentId
        amount
        effects {
          id
          feeId
          effect
          startZ
          description
          glId
        }
      }
    }
  }
`;

/**
 * __useResidencyProRatedEffectsQuery__
 *
 * To run a query within a React component, call `useResidencyProRatedEffectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResidencyProRatedEffectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResidencyProRatedEffectsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResidencyProRatedEffectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResidencyProRatedEffectsQuery,
    ResidencyProRatedEffectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResidencyProRatedEffectsQuery, ResidencyProRatedEffectsQueryVariables>(
    ResidencyProRatedEffectsDocument,
    options
  );
}
export function useResidencyProRatedEffectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResidencyProRatedEffectsQuery,
    ResidencyProRatedEffectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResidencyProRatedEffectsQuery, ResidencyProRatedEffectsQueryVariables>(
    ResidencyProRatedEffectsDocument,
    options
  );
}
export type ResidencyProRatedEffectsQueryHookResult = ReturnType<
  typeof useResidencyProRatedEffectsQuery
>;
export type ResidencyProRatedEffectsLazyQueryHookResult = ReturnType<
  typeof useResidencyProRatedEffectsLazyQuery
>;
export type ResidencyProRatedEffectsQueryResult = Apollo.QueryResult<
  ResidencyProRatedEffectsQuery,
  ResidencyProRatedEffectsQueryVariables
>;
export const RenameResidencyDocumentDocument = gql`
  mutation RenameResidencyDocument($input: RenameResidencyDocumentInput!) {
    renameResidencyDocument(input: $input) {
      residency {
        id
        documents {
          ...DocumentFields
        }
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`;
export type RenameResidencyDocumentMutationFn = Apollo.MutationFunction<
  RenameResidencyDocumentMutation,
  RenameResidencyDocumentMutationVariables
>;

/**
 * __useRenameResidencyDocumentMutation__
 *
 * To run a mutation, you first call `useRenameResidencyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameResidencyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameResidencyDocumentMutation, { data, loading, error }] = useRenameResidencyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameResidencyDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameResidencyDocumentMutation,
    RenameResidencyDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RenameResidencyDocumentMutation,
    RenameResidencyDocumentMutationVariables
  >(RenameResidencyDocumentDocument, options);
}
export type RenameResidencyDocumentMutationHookResult = ReturnType<
  typeof useRenameResidencyDocumentMutation
>;
export type RenameResidencyDocumentMutationResult =
  Apollo.MutationResult<RenameResidencyDocumentMutation>;
export type RenameResidencyDocumentMutationOptions = Apollo.BaseMutationOptions<
  RenameResidencyDocumentMutation,
  RenameResidencyDocumentMutationVariables
>;
export const ResidencyDocumentsDocument = gql`
  query ResidencyDocuments($id: ID!) {
    residency(id: $id) {
      id
      moveInInspectionId
      moveOutInspectionId
      documents {
        ...DocumentFields
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`;

/**
 * __useResidencyDocumentsQuery__
 *
 * To run a query within a React component, call `useResidencyDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResidencyDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResidencyDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResidencyDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<ResidencyDocumentsQuery, ResidencyDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResidencyDocumentsQuery, ResidencyDocumentsQueryVariables>(
    ResidencyDocumentsDocument,
    options
  );
}
export function useResidencyDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResidencyDocumentsQuery,
    ResidencyDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResidencyDocumentsQuery, ResidencyDocumentsQueryVariables>(
    ResidencyDocumentsDocument,
    options
  );
}
export type ResidencyDocumentsQueryHookResult = ReturnType<typeof useResidencyDocumentsQuery>;
export type ResidencyDocumentsLazyQueryHookResult = ReturnType<
  typeof useResidencyDocumentsLazyQuery
>;
export type ResidencyDocumentsQueryResult = Apollo.QueryResult<
  ResidencyDocumentsQuery,
  ResidencyDocumentsQueryVariables
>;
export const ResidencyResidentDocumentsDocument = gql`
  query ResidencyResidentDocuments($id: ID!) {
    residency(id: $id) {
      id
      residentDocuments {
        residentId
        sentZ
        entityId
        entityType
        document {
          ...DocumentFields
        }
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`;

/**
 * __useResidencyResidentDocumentsQuery__
 *
 * To run a query within a React component, call `useResidencyResidentDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResidencyResidentDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResidencyResidentDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResidencyResidentDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResidencyResidentDocumentsQuery,
    ResidencyResidentDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResidencyResidentDocumentsQuery, ResidencyResidentDocumentsQueryVariables>(
    ResidencyResidentDocumentsDocument,
    options
  );
}
export function useResidencyResidentDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResidencyResidentDocumentsQuery,
    ResidencyResidentDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResidencyResidentDocumentsQuery,
    ResidencyResidentDocumentsQueryVariables
  >(ResidencyResidentDocumentsDocument, options);
}
export type ResidencyResidentDocumentsQueryHookResult = ReturnType<
  typeof useResidencyResidentDocumentsQuery
>;
export type ResidencyResidentDocumentsLazyQueryHookResult = ReturnType<
  typeof useResidencyResidentDocumentsLazyQuery
>;
export type ResidencyResidentDocumentsQueryResult = Apollo.QueryResult<
  ResidencyResidentDocumentsQuery,
  ResidencyResidentDocumentsQueryVariables
>;
export const ListAllResidentsDocument = gql`
  query ListAllResidents($nextToken: String) {
    account {
      id
      listResidents(nextToken: $nextToken) {
        items @connection(key: "id") {
          id
          name
          phones
          emails
          leaseHolder
          isOwner
          enabled
          preferredPaymentMethod
          padAmount
          padAccount
          padTransit
          padInstitution
          vehicles {
            ...VehicleFields
          }
          pets {
            ...PetFields
          }
          emergencyContacts {
            name
            relationship
            phone
          }
          correspondenceMethod
        }
        nextToken
      }
    }
  }
  ${VehicleFieldsFragmentDoc}
  ${PetFieldsFragmentDoc}
`;

/**
 * __useListAllResidentsQuery__
 *
 * To run a query within a React component, call `useListAllResidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllResidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllResidentsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListAllResidentsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListAllResidentsQuery, ListAllResidentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAllResidentsQuery, ListAllResidentsQueryVariables>(
    ListAllResidentsDocument,
    options
  );
}
export function useListAllResidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListAllResidentsQuery, ListAllResidentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListAllResidentsQuery, ListAllResidentsQueryVariables>(
    ListAllResidentsDocument,
    options
  );
}
export type ListAllResidentsQueryHookResult = ReturnType<typeof useListAllResidentsQuery>;
export type ListAllResidentsLazyQueryHookResult = ReturnType<typeof useListAllResidentsLazyQuery>;
export type ListAllResidentsQueryResult = Apollo.QueryResult<
  ListAllResidentsQuery,
  ListAllResidentsQueryVariables
>;
export const UpdateResidencyWizardPathDocument = gql`
  query UpdateResidencyWizardPath($unitId: ID!, $propertyId: ID!, $residencyId: ID!) {
    unit(id: $unitId) {
      id
      name
      ...UnitEntityFields
      residency(id: $residencyId) {
        id
        ...ResidencyFields
      }
      allResidencies {
        id
        ...ResidencyFields
      }
    }
    property(id: $propertyId) {
      id
      name
      enabled
      ownerId
      autopayStatus
      autopayBankAccounts {
        id
        collectionType
      }
      address {
        ...AddressFields
      }
    }
  }
  ${UnitEntityFieldsFragmentDoc}
  ${ResidencyFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;

/**
 * __useUpdateResidencyWizardPathQuery__
 *
 * To run a query within a React component, call `useUpdateResidencyWizardPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateResidencyWizardPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateResidencyWizardPathQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      propertyId: // value for 'propertyId'
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useUpdateResidencyWizardPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateResidencyWizardPathQuery,
    UpdateResidencyWizardPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UpdateResidencyWizardPathQuery, UpdateResidencyWizardPathQueryVariables>(
    UpdateResidencyWizardPathDocument,
    options
  );
}
export function useUpdateResidencyWizardPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateResidencyWizardPathQuery,
    UpdateResidencyWizardPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UpdateResidencyWizardPathQuery,
    UpdateResidencyWizardPathQueryVariables
  >(UpdateResidencyWizardPathDocument, options);
}
export type UpdateResidencyWizardPathQueryHookResult = ReturnType<
  typeof useUpdateResidencyWizardPathQuery
>;
export type UpdateResidencyWizardPathLazyQueryHookResult = ReturnType<
  typeof useUpdateResidencyWizardPathLazyQuery
>;
export type UpdateResidencyWizardPathQueryResult = Apollo.QueryResult<
  UpdateResidencyWizardPathQuery,
  UpdateResidencyWizardPathQueryVariables
>;
export const LeaseFormsByProvinceDocument = gql`
  query LeaseFormsByProvince($province: String) {
    leaseForms(filter: { province: $province }) {
      ...LeaseFormFields
    }
  }
  ${LeaseFormFieldsFragmentDoc}
`;

/**
 * __useLeaseFormsByProvinceQuery__
 *
 * To run a query within a React component, call `useLeaseFormsByProvinceQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaseFormsByProvinceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaseFormsByProvinceQuery({
 *   variables: {
 *      province: // value for 'province'
 *   },
 * });
 */
export function useLeaseFormsByProvinceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LeaseFormsByProvinceQuery,
    LeaseFormsByProvinceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeaseFormsByProvinceQuery, LeaseFormsByProvinceQueryVariables>(
    LeaseFormsByProvinceDocument,
    options
  );
}
export function useLeaseFormsByProvinceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeaseFormsByProvinceQuery,
    LeaseFormsByProvinceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeaseFormsByProvinceQuery, LeaseFormsByProvinceQueryVariables>(
    LeaseFormsByProvinceDocument,
    options
  );
}
export type LeaseFormsByProvinceQueryHookResult = ReturnType<typeof useLeaseFormsByProvinceQuery>;
export type LeaseFormsByProvinceLazyQueryHookResult = ReturnType<
  typeof useLeaseFormsByProvinceLazyQuery
>;
export type LeaseFormsByProvinceQueryResult = Apollo.QueryResult<
  LeaseFormsByProvinceQuery,
  LeaseFormsByProvinceQueryVariables
>;
export const LeaseFormDocument = gql`
  query LeaseForm($id: ID!, $province: String!) {
    leaseForm(input: { id: $id, province: $province }) {
      ...LeaseFormFields
    }
  }
  ${LeaseFormFieldsFragmentDoc}
`;

/**
 * __useLeaseFormQuery__
 *
 * To run a query within a React component, call `useLeaseFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaseFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaseFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *      province: // value for 'province'
 *   },
 * });
 */
export function useLeaseFormQuery(
  baseOptions: Apollo.QueryHookOptions<LeaseFormQuery, LeaseFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeaseFormQuery, LeaseFormQueryVariables>(LeaseFormDocument, options);
}
export function useLeaseFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LeaseFormQuery, LeaseFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeaseFormQuery, LeaseFormQueryVariables>(LeaseFormDocument, options);
}
export type LeaseFormQueryHookResult = ReturnType<typeof useLeaseFormQuery>;
export type LeaseFormLazyQueryHookResult = ReturnType<typeof useLeaseFormLazyQuery>;
export type LeaseFormQueryResult = Apollo.QueryResult<LeaseFormQuery, LeaseFormQueryVariables>;
export const LeaseFormResidencyDataDocument = gql`
  query LeaseFormResidencyData($id: ID!, $province: String!, $residencyId: ID!, $unitId: ID!) {
    leaseForm(input: { id: $id, province: $province }) {
      ...LeaseFormFields
    }
    residency(id: $residencyId) {
      ...ResidencyFields
      outstanding {
        ...ResidencyOutstandingFields
      }
    }
    unit(id: $unitId) {
      ...UnitFields
    }
    account {
      ...AccountFields
    }
    manager {
      ...ManagerFields
    }
  }
  ${LeaseFormFieldsFragmentDoc}
  ${ResidencyFieldsFragmentDoc}
  ${ResidencyOutstandingFieldsFragmentDoc}
  ${UnitFieldsFragmentDoc}
  ${AccountFieldsFragmentDoc}
  ${ManagerFieldsFragmentDoc}
`;

/**
 * __useLeaseFormResidencyDataQuery__
 *
 * To run a query within a React component, call `useLeaseFormResidencyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaseFormResidencyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaseFormResidencyDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      province: // value for 'province'
 *      residencyId: // value for 'residencyId'
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useLeaseFormResidencyDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LeaseFormResidencyDataQuery,
    LeaseFormResidencyDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeaseFormResidencyDataQuery, LeaseFormResidencyDataQueryVariables>(
    LeaseFormResidencyDataDocument,
    options
  );
}
export function useLeaseFormResidencyDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeaseFormResidencyDataQuery,
    LeaseFormResidencyDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeaseFormResidencyDataQuery, LeaseFormResidencyDataQueryVariables>(
    LeaseFormResidencyDataDocument,
    options
  );
}
export type LeaseFormResidencyDataQueryHookResult = ReturnType<
  typeof useLeaseFormResidencyDataQuery
>;
export type LeaseFormResidencyDataLazyQueryHookResult = ReturnType<
  typeof useLeaseFormResidencyDataLazyQuery
>;
export type LeaseFormResidencyDataQueryResult = Apollo.QueryResult<
  LeaseFormResidencyDataQuery,
  LeaseFormResidencyDataQueryVariables
>;
export const LeaseFormResidencyPathDocument = gql`
  query LeaseFormResidencyPath($residencyId: ID!, $unitId: ID!, $propertyId: ID!) {
    unit(id: $unitId) {
      id
      name
      occupancy
      residency(id: $residencyId) {
        id
        startZ
        endZ
      }
    }
    property(id: $propertyId) {
      id
      name
      enabled
      address {
        ...AddressFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
`;

/**
 * __useLeaseFormResidencyPathQuery__
 *
 * To run a query within a React component, call `useLeaseFormResidencyPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaseFormResidencyPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaseFormResidencyPathQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *      unitId: // value for 'unitId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useLeaseFormResidencyPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    LeaseFormResidencyPathQuery,
    LeaseFormResidencyPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeaseFormResidencyPathQuery, LeaseFormResidencyPathQueryVariables>(
    LeaseFormResidencyPathDocument,
    options
  );
}
export function useLeaseFormResidencyPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeaseFormResidencyPathQuery,
    LeaseFormResidencyPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeaseFormResidencyPathQuery, LeaseFormResidencyPathQueryVariables>(
    LeaseFormResidencyPathDocument,
    options
  );
}
export type LeaseFormResidencyPathQueryHookResult = ReturnType<
  typeof useLeaseFormResidencyPathQuery
>;
export type LeaseFormResidencyPathLazyQueryHookResult = ReturnType<
  typeof useLeaseFormResidencyPathLazyQuery
>;
export type LeaseFormResidencyPathQueryResult = Apollo.QueryResult<
  LeaseFormResidencyPathQuery,
  LeaseFormResidencyPathQueryVariables
>;
export const LeaseFormCurrentResidenciesPathDocument = gql`
  query LeaseFormCurrentResidenciesPath($unitId: ID!, $propertyId: ID!) {
    unit(id: $unitId) {
      id
      name
      occupancy
      currentResidencies {
        id
        startZ
        endZ
      }
    }
    property(id: $propertyId) {
      id
      name
      enabled
      address {
        ...AddressFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
`;

/**
 * __useLeaseFormCurrentResidenciesPathQuery__
 *
 * To run a query within a React component, call `useLeaseFormCurrentResidenciesPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaseFormCurrentResidenciesPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaseFormCurrentResidenciesPathQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useLeaseFormCurrentResidenciesPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    LeaseFormCurrentResidenciesPathQuery,
    LeaseFormCurrentResidenciesPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LeaseFormCurrentResidenciesPathQuery,
    LeaseFormCurrentResidenciesPathQueryVariables
  >(LeaseFormCurrentResidenciesPathDocument, options);
}
export function useLeaseFormCurrentResidenciesPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeaseFormCurrentResidenciesPathQuery,
    LeaseFormCurrentResidenciesPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LeaseFormCurrentResidenciesPathQuery,
    LeaseFormCurrentResidenciesPathQueryVariables
  >(LeaseFormCurrentResidenciesPathDocument, options);
}
export type LeaseFormCurrentResidenciesPathQueryHookResult = ReturnType<
  typeof useLeaseFormCurrentResidenciesPathQuery
>;
export type LeaseFormCurrentResidenciesPathLazyQueryHookResult = ReturnType<
  typeof useLeaseFormCurrentResidenciesPathLazyQuery
>;
export type LeaseFormCurrentResidenciesPathQueryResult = Apollo.QueryResult<
  LeaseFormCurrentResidenciesPathQuery,
  LeaseFormCurrentResidenciesPathQueryVariables
>;
export const IssueDepositWizardPathDocument = gql`
  query IssueDepositWizardPath($residencyId: ID!, $unitId: ID!, $propertyId: ID!) {
    residency(id: $residencyId) {
      id
      startZ
      endZ
      occupancy
      refundables {
        id
        name
        amount
        simple
        templateId
        accrualStartExpr
        ledger {
          id
          date
          amount
          category
        }
      }
    }
    unit(id: $unitId) {
      id
      name
    }
    property(id: $propertyId) {
      id
      name
      timezone
      address {
        province
      }
    }
  }
`;

/**
 * __useIssueDepositWizardPathQuery__
 *
 * To run a query within a React component, call `useIssueDepositWizardPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssueDepositWizardPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssueDepositWizardPathQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *      unitId: // value for 'unitId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useIssueDepositWizardPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    IssueDepositWizardPathQuery,
    IssueDepositWizardPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IssueDepositWizardPathQuery, IssueDepositWizardPathQueryVariables>(
    IssueDepositWizardPathDocument,
    options
  );
}
export function useIssueDepositWizardPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IssueDepositWizardPathQuery,
    IssueDepositWizardPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IssueDepositWizardPathQuery, IssueDepositWizardPathQueryVariables>(
    IssueDepositWizardPathDocument,
    options
  );
}
export type IssueDepositWizardPathQueryHookResult = ReturnType<
  typeof useIssueDepositWizardPathQuery
>;
export type IssueDepositWizardPathLazyQueryHookResult = ReturnType<
  typeof useIssueDepositWizardPathLazyQuery
>;
export type IssueDepositWizardPathQueryResult = Apollo.QueryResult<
  IssueDepositWizardPathQuery,
  IssueDepositWizardPathQueryVariables
>;
export const ResidencyAccrualsQueryDocument = gql`
  query ResidencyAccrualsQuery($residencyId: ID!, $accrualsInput: AccrualsInput!) {
    residency(id: $residencyId) {
      id
      accruals(input: $accrualsInput) {
        id
        date
        amount
        category
      }
    }
  }
`;

/**
 * __useResidencyAccrualsQueryQuery__
 *
 * To run a query within a React component, call `useResidencyAccrualsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useResidencyAccrualsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResidencyAccrualsQueryQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *      accrualsInput: // value for 'accrualsInput'
 *   },
 * });
 */
export function useResidencyAccrualsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResidencyAccrualsQueryQuery,
    ResidencyAccrualsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResidencyAccrualsQueryQuery, ResidencyAccrualsQueryQueryVariables>(
    ResidencyAccrualsQueryDocument,
    options
  );
}
export function useResidencyAccrualsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResidencyAccrualsQueryQuery,
    ResidencyAccrualsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResidencyAccrualsQueryQuery, ResidencyAccrualsQueryQueryVariables>(
    ResidencyAccrualsQueryDocument,
    options
  );
}
export type ResidencyAccrualsQueryQueryHookResult = ReturnType<
  typeof useResidencyAccrualsQueryQuery
>;
export type ResidencyAccrualsQueryLazyQueryHookResult = ReturnType<
  typeof useResidencyAccrualsQueryLazyQuery
>;
export type ResidencyAccrualsQueryQueryResult = Apollo.QueryResult<
  ResidencyAccrualsQueryQuery,
  ResidencyAccrualsQueryQueryVariables
>;
export const GetAccountInfoDocument = gql`
  query GetAccountInfo {
    account {
      id
      name
      logoKey
      address {
        suite
        street
        city
        province
        postal
      }
    }
  }
`;

/**
 * __useGetAccountInfoQuery__
 *
 * To run a query within a React component, call `useGetAccountInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAccountInfoQuery, GetAccountInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountInfoQuery, GetAccountInfoQueryVariables>(
    GetAccountInfoDocument,
    options
  );
}
export function useGetAccountInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountInfoQuery, GetAccountInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountInfoQuery, GetAccountInfoQueryVariables>(
    GetAccountInfoDocument,
    options
  );
}
export type GetAccountInfoQueryHookResult = ReturnType<typeof useGetAccountInfoQuery>;
export type GetAccountInfoLazyQueryHookResult = ReturnType<typeof useGetAccountInfoLazyQuery>;
export type GetAccountInfoQueryResult = Apollo.QueryResult<
  GetAccountInfoQuery,
  GetAccountInfoQueryVariables
>;
export const GetResidencyDocument = gql`
  query GetResidency($residencyId: ID!) {
    residency(id: $residencyId) {
      id
      startZ
      endZ
      residents {
        id
        leaseHolder
        resident {
          __typename
          id
          name
          ... on Tenant {
            mailingAddress {
              suite
              street
              city
              province
              postal
            }
          }
        }
        payeeFrac {
          numerator
          denominator
        }
      }
      unit {
        id
        name
      }
      property {
        id
        name
      }
    }
  }
`;

/**
 * __useGetResidencyQuery__
 *
 * To run a query within a React component, call `useGetResidencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResidencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResidencyQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useGetResidencyQuery(
  baseOptions: Apollo.QueryHookOptions<GetResidencyQuery, GetResidencyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetResidencyQuery, GetResidencyQueryVariables>(
    GetResidencyDocument,
    options
  );
}
export function useGetResidencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetResidencyQuery, GetResidencyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetResidencyQuery, GetResidencyQueryVariables>(
    GetResidencyDocument,
    options
  );
}
export type GetResidencyQueryHookResult = ReturnType<typeof useGetResidencyQuery>;
export type GetResidencyLazyQueryHookResult = ReturnType<typeof useGetResidencyLazyQuery>;
export type GetResidencyQueryResult = Apollo.QueryResult<
  GetResidencyQuery,
  GetResidencyQueryVariables
>;
export const ResidencyRefundablesPathDocument = gql`
  query ResidencyRefundablesPath($residencyId: ID!, $unitId: ID!, $propertyId: ID!) {
    residency(id: $residencyId) {
      id
      startZ
      endZ
      occupancy
      refundables {
        id
        name
        amount
        templateId
        accrualStartExpr
        collectedZ
        settledZ
        heldByOwner
        ledger {
          id
          date
          amount
          category
        }
      }
      effects {
        id
        feeId
        effect
        startZ
      }
    }
    unit(id: $unitId) {
      id
      name
    }
    property(id: $propertyId) {
      id
      name
      address {
        province
      }
    }
  }
`;

/**
 * __useResidencyRefundablesPathQuery__
 *
 * To run a query within a React component, call `useResidencyRefundablesPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useResidencyRefundablesPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResidencyRefundablesPathQuery({
 *   variables: {
 *      residencyId: // value for 'residencyId'
 *      unitId: // value for 'unitId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useResidencyRefundablesPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResidencyRefundablesPathQuery,
    ResidencyRefundablesPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResidencyRefundablesPathQuery, ResidencyRefundablesPathQueryVariables>(
    ResidencyRefundablesPathDocument,
    options
  );
}
export function useResidencyRefundablesPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResidencyRefundablesPathQuery,
    ResidencyRefundablesPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResidencyRefundablesPathQuery, ResidencyRefundablesPathQueryVariables>(
    ResidencyRefundablesPathDocument,
    options
  );
}
export type ResidencyRefundablesPathQueryHookResult = ReturnType<
  typeof useResidencyRefundablesPathQuery
>;
export type ResidencyRefundablesPathLazyQueryHookResult = ReturnType<
  typeof useResidencyRefundablesPathLazyQuery
>;
export type ResidencyRefundablesPathQueryResult = Apollo.QueryResult<
  ResidencyRefundablesPathQuery,
  ResidencyRefundablesPathQueryVariables
>;
export const UpdateResidencyRefundablesDocument = gql`
  mutation UpdateResidencyRefundables($input: UpdateResidencyRefundableDepositsInput!) {
    updateResidencyRefundableDeposits(input: $input) {
      residency {
        id
        refundables {
          id
          name
          amount
          templateId
          accrualStartExpr
          collectedZ
          heldByOwner
          ledger {
            id
            date
            amount
            category
          }
        }
      }
    }
  }
`;
export type UpdateResidencyRefundablesMutationFn = Apollo.MutationFunction<
  UpdateResidencyRefundablesMutation,
  UpdateResidencyRefundablesMutationVariables
>;

/**
 * __useUpdateResidencyRefundablesMutation__
 *
 * To run a mutation, you first call `useUpdateResidencyRefundablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResidencyRefundablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResidencyRefundablesMutation, { data, loading, error }] = useUpdateResidencyRefundablesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResidencyRefundablesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateResidencyRefundablesMutation,
    UpdateResidencyRefundablesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateResidencyRefundablesMutation,
    UpdateResidencyRefundablesMutationVariables
  >(UpdateResidencyRefundablesDocument, options);
}
export type UpdateResidencyRefundablesMutationHookResult = ReturnType<
  typeof useUpdateResidencyRefundablesMutation
>;
export type UpdateResidencyRefundablesMutationResult =
  Apollo.MutationResult<UpdateResidencyRefundablesMutation>;
export type UpdateResidencyRefundablesMutationOptions = Apollo.BaseMutationOptions<
  UpdateResidencyRefundablesMutation,
  UpdateResidencyRefundablesMutationVariables
>;
export const GetTenantsByEmailDocument = gql`
  query GetTenantsByEmail($email: String!) {
    tenantsByEmail(email: $email) {
      ...TenantFields
    }
  }
  ${TenantFieldsFragmentDoc}
`;

/**
 * __useGetTenantsByEmailQuery__
 *
 * To run a query within a React component, call `useGetTenantsByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetTenantsByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<GetTenantsByEmailQuery, GetTenantsByEmailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantsByEmailQuery, GetTenantsByEmailQueryVariables>(
    GetTenantsByEmailDocument,
    options
  );
}
export function useGetTenantsByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTenantsByEmailQuery, GetTenantsByEmailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantsByEmailQuery, GetTenantsByEmailQueryVariables>(
    GetTenantsByEmailDocument,
    options
  );
}
export type GetTenantsByEmailQueryHookResult = ReturnType<typeof useGetTenantsByEmailQuery>;
export type GetTenantsByEmailLazyQueryHookResult = ReturnType<typeof useGetTenantsByEmailLazyQuery>;
export type GetTenantsByEmailQueryResult = Apollo.QueryResult<
  GetTenantsByEmailQuery,
  GetTenantsByEmailQueryVariables
>;
export const GetTenantsDocument = gql`
  query GetTenants {
    tenants {
      ...TenantFields
    }
  }
  ${TenantFieldsFragmentDoc}
`;

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
}
export function useGetTenantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(
    GetTenantsDocument,
    options
  );
}
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<typeof useGetTenantsLazyQuery>;
export type GetTenantsQueryResult = Apollo.QueryResult<GetTenantsQuery, GetTenantsQueryVariables>;
export const GetUnitDocument = gql`
  query GetUnit($id: ID!) {
    unit(id: $id) {
      ...UnitFieldsWithResidency
    }
  }
  ${UnitFieldsWithResidencyFragmentDoc}
`;

/**
 * __useGetUnitQuery__
 *
 * To run a query within a React component, call `useGetUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnitQuery(
  baseOptions: Apollo.QueryHookOptions<GetUnitQuery, GetUnitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnitQuery, GetUnitQueryVariables>(GetUnitDocument, options);
}
export function useGetUnitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnitQuery, GetUnitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnitQuery, GetUnitQueryVariables>(GetUnitDocument, options);
}
export type GetUnitQueryHookResult = ReturnType<typeof useGetUnitQuery>;
export type GetUnitLazyQueryHookResult = ReturnType<typeof useGetUnitLazyQuery>;
export type GetUnitQueryResult = Apollo.QueryResult<GetUnitQuery, GetUnitQueryVariables>;
export const UnitPathDocument = gql`
  query UnitPath($unitId: ID!, $propertyId: ID!) {
    unit(id: $unitId) {
      id
      ...UnitFieldsWithResidency
    }
    property(id: $propertyId) {
      id
      enabled
      name
      phone
      imageKey
      timezone
      ownerId
      storage {
        name
        unitId
      }
      parking {
        name
        unitId
      }
      owner {
        id
        name
      }
      address {
        street
        city
        province
        postal
      }
    }
  }
  ${UnitFieldsWithResidencyFragmentDoc}
`;

/**
 * __useUnitPathQuery__
 *
 * To run a query within a React component, call `useUnitPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitPathQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useUnitPathQuery(
  baseOptions: Apollo.QueryHookOptions<UnitPathQuery, UnitPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnitPathQuery, UnitPathQueryVariables>(UnitPathDocument, options);
}
export function useUnitPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UnitPathQuery, UnitPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnitPathQuery, UnitPathQueryVariables>(UnitPathDocument, options);
}
export type UnitPathQueryHookResult = ReturnType<typeof useUnitPathQuery>;
export type UnitPathLazyQueryHookResult = ReturnType<typeof useUnitPathLazyQuery>;
export type UnitPathQueryResult = Apollo.QueryResult<UnitPathQuery, UnitPathQueryVariables>;
export const UnitDocumentsDocument = gql`
  query UnitDocuments($id: ID!) {
    unit(id: $id) {
      id
      documents {
        ...DocumentFields
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`;

/**
 * __useUnitDocumentsQuery__
 *
 * To run a query within a React component, call `useUnitDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnitDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<UnitDocumentsQuery, UnitDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnitDocumentsQuery, UnitDocumentsQueryVariables>(
    UnitDocumentsDocument,
    options
  );
}
export function useUnitDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UnitDocumentsQuery, UnitDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnitDocumentsQuery, UnitDocumentsQueryVariables>(
    UnitDocumentsDocument,
    options
  );
}
export type UnitDocumentsQueryHookResult = ReturnType<typeof useUnitDocumentsQuery>;
export type UnitDocumentsLazyQueryHookResult = ReturnType<typeof useUnitDocumentsLazyQuery>;
export type UnitDocumentsQueryResult = Apollo.QueryResult<
  UnitDocumentsQuery,
  UnitDocumentsQueryVariables
>;
export const UpdateUnitDocument = gql`
  mutation UpdateUnit($input: UpdateUnitInput!) {
    updateUnit(input: $input) {
      unit {
        ...UnitFields
      }
    }
  }
  ${UnitFieldsFragmentDoc}
`;
export type UpdateUnitMutationFn = Apollo.MutationFunction<
  UpdateUnitMutation,
  UpdateUnitMutationVariables
>;

/**
 * __useUpdateUnitMutation__
 *
 * To run a mutation, you first call `useUpdateUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitMutation, { data, loading, error }] = useUpdateUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUnitMutation, UpdateUnitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUnitMutation, UpdateUnitMutationVariables>(
    UpdateUnitDocument,
    options
  );
}
export type UpdateUnitMutationHookResult = ReturnType<typeof useUpdateUnitMutation>;
export type UpdateUnitMutationResult = Apollo.MutationResult<UpdateUnitMutation>;
export type UpdateUnitMutationOptions = Apollo.BaseMutationOptions<
  UpdateUnitMutation,
  UpdateUnitMutationVariables
>;
export const RenameUnitDocumentDocument = gql`
  mutation RenameUnitDocument($input: RenameUnitDocumentInput!) {
    renameUnitDocument(input: $input) {
      unit {
        id
        documents {
          ...DocumentFields
        }
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`;
export type RenameUnitDocumentMutationFn = Apollo.MutationFunction<
  RenameUnitDocumentMutation,
  RenameUnitDocumentMutationVariables
>;

/**
 * __useRenameUnitDocumentMutation__
 *
 * To run a mutation, you first call `useRenameUnitDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameUnitDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameUnitDocumentMutation, { data, loading, error }] = useRenameUnitDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameUnitDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameUnitDocumentMutation,
    RenameUnitDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RenameUnitDocumentMutation, RenameUnitDocumentMutationVariables>(
    RenameUnitDocumentDocument,
    options
  );
}
export type RenameUnitDocumentMutationHookResult = ReturnType<typeof useRenameUnitDocumentMutation>;
export type RenameUnitDocumentMutationResult = Apollo.MutationResult<RenameUnitDocumentMutation>;
export type RenameUnitDocumentMutationOptions = Apollo.BaseMutationOptions<
  RenameUnitDocumentMutation,
  RenameUnitDocumentMutationVariables
>;
export const AddUnitDocument = gql`
  mutation AddUnit($input: CreateUnitOnPropertyInput!) {
    createUnitOnProperty(input: $input) {
      unit {
        ...UnitFields
      }
    }
  }
  ${UnitFieldsFragmentDoc}
`;
export type AddUnitMutationFn = Apollo.MutationFunction<AddUnitMutation, AddUnitMutationVariables>;

/**
 * __useAddUnitMutation__
 *
 * To run a mutation, you first call `useAddUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUnitMutation, { data, loading, error }] = useAddUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<AddUnitMutation, AddUnitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUnitMutation, AddUnitMutationVariables>(AddUnitDocument, options);
}
export type AddUnitMutationHookResult = ReturnType<typeof useAddUnitMutation>;
export type AddUnitMutationResult = Apollo.MutationResult<AddUnitMutation>;
export type AddUnitMutationOptions = Apollo.BaseMutationOptions<
  AddUnitMutation,
  AddUnitMutationVariables
>;
export const AddRequestImagesDocument = gql`
  mutation AddRequestImages($input: RequestImagesInput!) {
    addRequestImages(input: $input) {
      success
      request {
        id
        images {
          key
          notes {
            id
            createdName
            createdSub
            createdZ
            text
          }
        }
      }
    }
  }
`;
export type AddRequestImagesMutationFn = Apollo.MutationFunction<
  AddRequestImagesMutation,
  AddRequestImagesMutationVariables
>;

/**
 * __useAddRequestImagesMutation__
 *
 * To run a mutation, you first call `useAddRequestImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRequestImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRequestImagesMutation, { data, loading, error }] = useAddRequestImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRequestImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRequestImagesMutation,
    AddRequestImagesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddRequestImagesMutation, AddRequestImagesMutationVariables>(
    AddRequestImagesDocument,
    options
  );
}
export type AddRequestImagesMutationHookResult = ReturnType<typeof useAddRequestImagesMutation>;
export type AddRequestImagesMutationResult = Apollo.MutationResult<AddRequestImagesMutation>;
export type AddRequestImagesMutationOptions = Apollo.BaseMutationOptions<
  AddRequestImagesMutation,
  AddRequestImagesMutationVariables
>;
export const DeleteRequestImageDocument = gql`
  mutation DeleteRequestImage($input: DeleteRequestImageInput!) {
    deleteRequestImage(input: $input) {
      success
      request {
        id
        images {
          key
          notes {
            id
            createdName
            createdSub
            createdZ
            text
          }
        }
      }
    }
  }
`;
export type DeleteRequestImageMutationFn = Apollo.MutationFunction<
  DeleteRequestImageMutation,
  DeleteRequestImageMutationVariables
>;

/**
 * __useDeleteRequestImageMutation__
 *
 * To run a mutation, you first call `useDeleteRequestImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestImageMutation, { data, loading, error }] = useDeleteRequestImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRequestImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRequestImageMutation,
    DeleteRequestImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRequestImageMutation, DeleteRequestImageMutationVariables>(
    DeleteRequestImageDocument,
    options
  );
}
export type DeleteRequestImageMutationHookResult = ReturnType<typeof useDeleteRequestImageMutation>;
export type DeleteRequestImageMutationResult = Apollo.MutationResult<DeleteRequestImageMutation>;
export type DeleteRequestImageMutationOptions = Apollo.BaseMutationOptions<
  DeleteRequestImageMutation,
  DeleteRequestImageMutationVariables
>;
export const UpdateRequestChecklistItemDocument = gql`
  mutation UpdateRequestChecklistItem($input: UpdateRequestChecklistItemInput!) {
    updateRequestChecklistItem(input: $input) {
      success
      error
      request {
        id
        checklist {
          id
          name
          completedZ
          order
          lastUpdatedZ
        }
      }
    }
  }
`;
export type UpdateRequestChecklistItemMutationFn = Apollo.MutationFunction<
  UpdateRequestChecklistItemMutation,
  UpdateRequestChecklistItemMutationVariables
>;

/**
 * __useUpdateRequestChecklistItemMutation__
 *
 * To run a mutation, you first call `useUpdateRequestChecklistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequestChecklistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequestChecklistItemMutation, { data, loading, error }] = useUpdateRequestChecklistItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRequestChecklistItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRequestChecklistItemMutation,
    UpdateRequestChecklistItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRequestChecklistItemMutation,
    UpdateRequestChecklistItemMutationVariables
  >(UpdateRequestChecklistItemDocument, options);
}
export type UpdateRequestChecklistItemMutationHookResult = ReturnType<
  typeof useUpdateRequestChecklistItemMutation
>;
export type UpdateRequestChecklistItemMutationResult =
  Apollo.MutationResult<UpdateRequestChecklistItemMutation>;
export type UpdateRequestChecklistItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateRequestChecklistItemMutation,
  UpdateRequestChecklistItemMutationVariables
>;
export const CreateRequestCostDocument = gql`
  mutation CreateRequestCost($input: CreateRequestCostInput!) {
    createRequestCost(input: $input) {
      success
      error
      newCostId
      request {
        id
        costs {
          id
          markup
        }
        costLines {
          id
          date
          amount
          markup
          description
          receiptKey
          receiptImages {
            key
          }
          total
        }
      }
    }
  }
`;
export type CreateRequestCostMutationFn = Apollo.MutationFunction<
  CreateRequestCostMutation,
  CreateRequestCostMutationVariables
>;

/**
 * __useCreateRequestCostMutation__
 *
 * To run a mutation, you first call `useCreateRequestCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestCostMutation, { data, loading, error }] = useCreateRequestCostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestCostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRequestCostMutation,
    CreateRequestCostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRequestCostMutation, CreateRequestCostMutationVariables>(
    CreateRequestCostDocument,
    options
  );
}
export type CreateRequestCostMutationHookResult = ReturnType<typeof useCreateRequestCostMutation>;
export type CreateRequestCostMutationResult = Apollo.MutationResult<CreateRequestCostMutation>;
export type CreateRequestCostMutationOptions = Apollo.BaseMutationOptions<
  CreateRequestCostMutation,
  CreateRequestCostMutationVariables
>;
export const UpdateRequestCostDocument = gql`
  mutation UpdateRequestCost($input: UpdateRequestCostInput!) {
    updateRequestCost(input: $input) {
      success
      error
      request {
        id
        costs {
          id
          markup
        }
        costLines {
          id
          date
          amount
          markup
          description
          receiptKey
          receiptImages {
            key
          }
          total
        }
      }
    }
  }
`;
export type UpdateRequestCostMutationFn = Apollo.MutationFunction<
  UpdateRequestCostMutation,
  UpdateRequestCostMutationVariables
>;

/**
 * __useUpdateRequestCostMutation__
 *
 * To run a mutation, you first call `useUpdateRequestCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequestCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequestCostMutation, { data, loading, error }] = useUpdateRequestCostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRequestCostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRequestCostMutation,
    UpdateRequestCostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRequestCostMutation, UpdateRequestCostMutationVariables>(
    UpdateRequestCostDocument,
    options
  );
}
export type UpdateRequestCostMutationHookResult = ReturnType<typeof useUpdateRequestCostMutation>;
export type UpdateRequestCostMutationResult = Apollo.MutationResult<UpdateRequestCostMutation>;
export type UpdateRequestCostMutationOptions = Apollo.BaseMutationOptions<
  UpdateRequestCostMutation,
  UpdateRequestCostMutationVariables
>;
export const DeleteRequestCostDocument = gql`
  mutation DeleteRequestCost($input: DeleteRequestCostInput!) {
    deleteRequestCost(input: $input) {
      success
      error
      request {
        id
        costs {
          id
          markup
        }
        costLines {
          id
          date
          amount
          markup
          description
          receiptKey
          receiptImages {
            key
          }
          total
        }
      }
    }
  }
`;
export type DeleteRequestCostMutationFn = Apollo.MutationFunction<
  DeleteRequestCostMutation,
  DeleteRequestCostMutationVariables
>;

/**
 * __useDeleteRequestCostMutation__
 *
 * To run a mutation, you first call `useDeleteRequestCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestCostMutation, { data, loading, error }] = useDeleteRequestCostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRequestCostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRequestCostMutation,
    DeleteRequestCostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRequestCostMutation, DeleteRequestCostMutationVariables>(
    DeleteRequestCostDocument,
    options
  );
}
export type DeleteRequestCostMutationHookResult = ReturnType<typeof useDeleteRequestCostMutation>;
export type DeleteRequestCostMutationResult = Apollo.MutationResult<DeleteRequestCostMutation>;
export type DeleteRequestCostMutationOptions = Apollo.BaseMutationOptions<
  DeleteRequestCostMutation,
  DeleteRequestCostMutationVariables
>;
export const RefreshRequestImagesDocument = gql`
  mutation RefreshRequestImages($id: ID!) {
    refreshRequestImages(id: $id) {
      success
      error
      request {
        id
        images {
          key
        }
      }
    }
  }
`;
export type RefreshRequestImagesMutationFn = Apollo.MutationFunction<
  RefreshRequestImagesMutation,
  RefreshRequestImagesMutationVariables
>;

/**
 * __useRefreshRequestImagesMutation__
 *
 * To run a mutation, you first call `useRefreshRequestImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshRequestImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshRequestImagesMutation, { data, loading, error }] = useRefreshRequestImagesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefreshRequestImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshRequestImagesMutation,
    RefreshRequestImagesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshRequestImagesMutation, RefreshRequestImagesMutationVariables>(
    RefreshRequestImagesDocument,
    options
  );
}
export type RefreshRequestImagesMutationHookResult = ReturnType<
  typeof useRefreshRequestImagesMutation
>;
export type RefreshRequestImagesMutationResult =
  Apollo.MutationResult<RefreshRequestImagesMutation>;
export type RefreshRequestImagesMutationOptions = Apollo.BaseMutationOptions<
  RefreshRequestImagesMutation,
  RefreshRequestImagesMutationVariables
>;
export const RequestBillingRatesDocument = gql`
  query RequestBillingRates {
    account {
      id
      books {
        accountId
        billingRates {
          materialsMarkup
        }
        customBillingRates {
          category
          materialsMarkup
        }
      }
    }
  }
`;

/**
 * __useRequestBillingRatesQuery__
 *
 * To run a query within a React component, call `useRequestBillingRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestBillingRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestBillingRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequestBillingRatesQuery(
  baseOptions?: Apollo.QueryHookOptions<RequestBillingRatesQuery, RequestBillingRatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RequestBillingRatesQuery, RequestBillingRatesQueryVariables>(
    RequestBillingRatesDocument,
    options
  );
}
export function useRequestBillingRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestBillingRatesQuery,
    RequestBillingRatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RequestBillingRatesQuery, RequestBillingRatesQueryVariables>(
    RequestBillingRatesDocument,
    options
  );
}
export type RequestBillingRatesQueryHookResult = ReturnType<typeof useRequestBillingRatesQuery>;
export type RequestBillingRatesLazyQueryHookResult = ReturnType<
  typeof useRequestBillingRatesLazyQuery
>;
export type RequestBillingRatesQueryResult = Apollo.QueryResult<
  RequestBillingRatesQuery,
  RequestBillingRatesQueryVariables
>;
export const DeleteVisitBillDocument = gql`
  mutation DeleteVisitBill($input: DeleteVisitBillInput!) {
    deleteVisitBill(input: $input) {
      success
      error
      visit {
        id
        bill {
          date
          totalAmount
          ref
          due
          gstAmount
          key
        }
      }
    }
  }
`;
export type DeleteVisitBillMutationFn = Apollo.MutationFunction<
  DeleteVisitBillMutation,
  DeleteVisitBillMutationVariables
>;

/**
 * __useDeleteVisitBillMutation__
 *
 * To run a mutation, you first call `useDeleteVisitBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVisitBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVisitBillMutation, { data, loading, error }] = useDeleteVisitBillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVisitBillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVisitBillMutation,
    DeleteVisitBillMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVisitBillMutation, DeleteVisitBillMutationVariables>(
    DeleteVisitBillDocument,
    options
  );
}
export type DeleteVisitBillMutationHookResult = ReturnType<typeof useDeleteVisitBillMutation>;
export type DeleteVisitBillMutationResult = Apollo.MutationResult<DeleteVisitBillMutation>;
export type DeleteVisitBillMutationOptions = Apollo.BaseMutationOptions<
  DeleteVisitBillMutation,
  DeleteVisitBillMutationVariables
>;
export const CreateMaintenanceApprovalDocument = gql`
  mutation CreateMaintenanceApproval($input: CreateMaintenanceApprovalInput!) {
    createMaintenanceApproval(input: $input) {
      success
      error
      request {
        id
        ownerApprovalId
        ownerApprovalStatus
        unit {
          owner {
            id
            name
          }
          property {
            owner {
              id
              name
            }
          }
        }
        approval {
          id
          status
          approvedZ
          approvedSub
          declinedZ
          declinedSub
        }
      }
    }
  }
`;
export type CreateMaintenanceApprovalMutationFn = Apollo.MutationFunction<
  CreateMaintenanceApprovalMutation,
  CreateMaintenanceApprovalMutationVariables
>;

/**
 * __useCreateMaintenanceApprovalMutation__
 *
 * To run a mutation, you first call `useCreateMaintenanceApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaintenanceApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaintenanceApprovalMutation, { data, loading, error }] = useCreateMaintenanceApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMaintenanceApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMaintenanceApprovalMutation,
    CreateMaintenanceApprovalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMaintenanceApprovalMutation,
    CreateMaintenanceApprovalMutationVariables
  >(CreateMaintenanceApprovalDocument, options);
}
export type CreateMaintenanceApprovalMutationHookResult = ReturnType<
  typeof useCreateMaintenanceApprovalMutation
>;
export type CreateMaintenanceApprovalMutationResult =
  Apollo.MutationResult<CreateMaintenanceApprovalMutation>;
export type CreateMaintenanceApprovalMutationOptions = Apollo.BaseMutationOptions<
  CreateMaintenanceApprovalMutation,
  CreateMaintenanceApprovalMutationVariables
>;
export const GetManagerDocument = gql`
  query GetManager($id: ID!) {
    manager(id: $id) {
      ...ManagerFields
    }
  }
  ${ManagerFieldsFragmentDoc}
`;

/**
 * __useGetManagerQuery__
 *
 * To run a query within a React component, call `useGetManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetManagerQuery(
  baseOptions: Apollo.QueryHookOptions<GetManagerQuery, GetManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManagerQuery, GetManagerQueryVariables>(GetManagerDocument, options);
}
export function useGetManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManagerQuery, GetManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManagerQuery, GetManagerQueryVariables>(
    GetManagerDocument,
    options
  );
}
export type GetManagerQueryHookResult = ReturnType<typeof useGetManagerQuery>;
export type GetManagerLazyQueryHookResult = ReturnType<typeof useGetManagerLazyQuery>;
export type GetManagerQueryResult = Apollo.QueryResult<GetManagerQuery, GetManagerQueryVariables>;
export const UpdateRequestDocument = gql`
  mutation UpdateRequest($id: ID!, $input: UpdateRequestInput!) {
    updateRequest(id: $id, input: $input) {
      id
      operatorName
      appointmentZ
      status
      instructions
      summary
      originalRequest
      nonBillable
      printNotes
      resolution
      taxExempted
    }
  }
`;
export type UpdateRequestMutationFn = Apollo.MutationFunction<
  UpdateRequestMutation,
  UpdateRequestMutationVariables
>;

/**
 * __useUpdateRequestMutation__
 *
 * To run a mutation, you first call `useUpdateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequestMutation, { data, loading, error }] = useUpdateRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRequestMutation, UpdateRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRequestMutation, UpdateRequestMutationVariables>(
    UpdateRequestDocument,
    options
  );
}
export type UpdateRequestMutationHookResult = ReturnType<typeof useUpdateRequestMutation>;
export type UpdateRequestMutationResult = Apollo.MutationResult<UpdateRequestMutation>;
export type UpdateRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateRequestMutation,
  UpdateRequestMutationVariables
>;
export const FinalizeRequestDocument = gql`
  mutation FinalizeRequest($input: FinalizeRequestInput!) {
    finalizeRequest(input: $input) {
      success
      error
      request {
        id
        finalizedName
        status
        visits {
          id
          status
          labourCost
          materialCosts
          materialCostsNote
          materialMarkup
        }
        taxExempted
        cancelledReason
      }
    }
  }
`;
export type FinalizeRequestMutationFn = Apollo.MutationFunction<
  FinalizeRequestMutation,
  FinalizeRequestMutationVariables
>;

/**
 * __useFinalizeRequestMutation__
 *
 * To run a mutation, you first call `useFinalizeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeRequestMutation, { data, loading, error }] = useFinalizeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinalizeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalizeRequestMutation,
    FinalizeRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinalizeRequestMutation, FinalizeRequestMutationVariables>(
    FinalizeRequestDocument,
    options
  );
}
export type FinalizeRequestMutationHookResult = ReturnType<typeof useFinalizeRequestMutation>;
export type FinalizeRequestMutationResult = Apollo.MutationResult<FinalizeRequestMutation>;
export type FinalizeRequestMutationOptions = Apollo.BaseMutationOptions<
  FinalizeRequestMutation,
  FinalizeRequestMutationVariables
>;
export const GetOperatorWindowsDocument = gql`
  query GetOperatorWindows($propertyId: ID!, $duration: Int!) {
    property(id: $propertyId) {
      id
      operatorWindows(duration: $duration) {
        operator {
          id
          name
          skills
          operatorType
        }
        window
      }
    }
  }
`;

/**
 * __useGetOperatorWindowsQuery__
 *
 * To run a query within a React component, call `useGetOperatorWindowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatorWindowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatorWindowsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      duration: // value for 'duration'
 *   },
 * });
 */
export function useGetOperatorWindowsQuery(
  baseOptions: Apollo.QueryHookOptions<GetOperatorWindowsQuery, GetOperatorWindowsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOperatorWindowsQuery, GetOperatorWindowsQueryVariables>(
    GetOperatorWindowsDocument,
    options
  );
}
export function useGetOperatorWindowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOperatorWindowsQuery,
    GetOperatorWindowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOperatorWindowsQuery, GetOperatorWindowsQueryVariables>(
    GetOperatorWindowsDocument,
    options
  );
}
export type GetOperatorWindowsQueryHookResult = ReturnType<typeof useGetOperatorWindowsQuery>;
export type GetOperatorWindowsLazyQueryHookResult = ReturnType<
  typeof useGetOperatorWindowsLazyQuery
>;
export type GetOperatorWindowsQueryResult = Apollo.QueryResult<
  GetOperatorWindowsQuery,
  GetOperatorWindowsQueryVariables
>;
export const ConfirmAppointmentDocument = gql`
  mutation ConfirmAppointment($requestId: ID!, $input: UpdateRequestInput!) {
    updateRequest(id: $requestId, input: $input) {
      id
      appointmentZ
      status
      delay
      operator {
        id
      }
    }
  }
`;
export type ConfirmAppointmentMutationFn = Apollo.MutationFunction<
  ConfirmAppointmentMutation,
  ConfirmAppointmentMutationVariables
>;

/**
 * __useConfirmAppointmentMutation__
 *
 * To run a mutation, you first call `useConfirmAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAppointmentMutation, { data, loading, error }] = useConfirmAppointmentMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmAppointmentMutation,
    ConfirmAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmAppointmentMutation, ConfirmAppointmentMutationVariables>(
    ConfirmAppointmentDocument,
    options
  );
}
export type ConfirmAppointmentMutationHookResult = ReturnType<typeof useConfirmAppointmentMutation>;
export type ConfirmAppointmentMutationResult = Apollo.MutationResult<ConfirmAppointmentMutation>;
export type ConfirmAppointmentMutationOptions = Apollo.BaseMutationOptions<
  ConfirmAppointmentMutation,
  ConfirmAppointmentMutationVariables
>;
export const UpdateVisitDocument = gql`
  mutation UpdateVisit($input: UpdateVisitInput!) {
    updateRequestVisit(input: $input) {
      visit {
        ...VisitFields
      }
      error
      success
    }
  }
  ${VisitFieldsFragmentDoc}
`;
export type UpdateVisitMutationFn = Apollo.MutationFunction<
  UpdateVisitMutation,
  UpdateVisitMutationVariables
>;

/**
 * __useUpdateVisitMutation__
 *
 * To run a mutation, you first call `useUpdateVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisitMutation, { data, loading, error }] = useUpdateVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVisitMutation, UpdateVisitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVisitMutation, UpdateVisitMutationVariables>(
    UpdateVisitDocument,
    options
  );
}
export type UpdateVisitMutationHookResult = ReturnType<typeof useUpdateVisitMutation>;
export type UpdateVisitMutationResult = Apollo.MutationResult<UpdateVisitMutation>;
export type UpdateVisitMutationOptions = Apollo.BaseMutationOptions<
  UpdateVisitMutation,
  UpdateVisitMutationVariables
>;
export const ScheduleVisitDocument = gql`
  mutation ScheduleVisit($input: UpdateVisitInput!) {
    scheduleRequestVisit(input: $input) {
      visit {
        ...VisitFields
      }
      error
      success
    }
  }
  ${VisitFieldsFragmentDoc}
`;
export type ScheduleVisitMutationFn = Apollo.MutationFunction<
  ScheduleVisitMutation,
  ScheduleVisitMutationVariables
>;

/**
 * __useScheduleVisitMutation__
 *
 * To run a mutation, you first call `useScheduleVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleVisitMutation, { data, loading, error }] = useScheduleVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<ScheduleVisitMutation, ScheduleVisitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ScheduleVisitMutation, ScheduleVisitMutationVariables>(
    ScheduleVisitDocument,
    options
  );
}
export type ScheduleVisitMutationHookResult = ReturnType<typeof useScheduleVisitMutation>;
export type ScheduleVisitMutationResult = Apollo.MutationResult<ScheduleVisitMutation>;
export type ScheduleVisitMutationOptions = Apollo.BaseMutationOptions<
  ScheduleVisitMutation,
  ScheduleVisitMutationVariables
>;
export const AssignVisitDocument = gql`
  mutation AssignVisit($input: UpdateVisitInput!) {
    assignRequestVisit(input: $input) {
      visit {
        ...VisitFields
      }
      error
      success
    }
  }
  ${VisitFieldsFragmentDoc}
`;
export type AssignVisitMutationFn = Apollo.MutationFunction<
  AssignVisitMutation,
  AssignVisitMutationVariables
>;

/**
 * __useAssignVisitMutation__
 *
 * To run a mutation, you first call `useAssignVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignVisitMutation, { data, loading, error }] = useAssignVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignVisitMutation, AssignVisitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignVisitMutation, AssignVisitMutationVariables>(
    AssignVisitDocument,
    options
  );
}
export type AssignVisitMutationHookResult = ReturnType<typeof useAssignVisitMutation>;
export type AssignVisitMutationResult = Apollo.MutationResult<AssignVisitMutation>;
export type AssignVisitMutationOptions = Apollo.BaseMutationOptions<
  AssignVisitMutation,
  AssignVisitMutationVariables
>;
export const FinalizeVisitDocument = gql`
  mutation FinalizeVisit($input: UpdateVisitInput!) {
    finalizeRequestVisit(input: $input) {
      visit {
        ...VisitFields
      }
      error
      success
    }
  }
  ${VisitFieldsFragmentDoc}
`;
export type FinalizeVisitMutationFn = Apollo.MutationFunction<
  FinalizeVisitMutation,
  FinalizeVisitMutationVariables
>;

/**
 * __useFinalizeVisitMutation__
 *
 * To run a mutation, you first call `useFinalizeVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeVisitMutation, { data, loading, error }] = useFinalizeVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinalizeVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<FinalizeVisitMutation, FinalizeVisitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinalizeVisitMutation, FinalizeVisitMutationVariables>(
    FinalizeVisitDocument,
    options
  );
}
export type FinalizeVisitMutationHookResult = ReturnType<typeof useFinalizeVisitMutation>;
export type FinalizeVisitMutationResult = Apollo.MutationResult<FinalizeVisitMutation>;
export type FinalizeVisitMutationOptions = Apollo.BaseMutationOptions<
  FinalizeVisitMutation,
  FinalizeVisitMutationVariables
>;
export const CreateVisitDocument = gql`
  mutation CreateVisit($input: CreateVisitInput!) {
    createRequestVisit(input: $input) {
      request {
        id
        visits {
          ...VisitFields
        }
      }
    }
  }
  ${VisitFieldsFragmentDoc}
`;
export type CreateVisitMutationFn = Apollo.MutationFunction<
  CreateVisitMutation,
  CreateVisitMutationVariables
>;

/**
 * __useCreateVisitMutation__
 *
 * To run a mutation, you first call `useCreateVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVisitMutation, { data, loading, error }] = useCreateVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVisitMutation, CreateVisitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVisitMutation, CreateVisitMutationVariables>(
    CreateVisitDocument,
    options
  );
}
export type CreateVisitMutationHookResult = ReturnType<typeof useCreateVisitMutation>;
export type CreateVisitMutationResult = Apollo.MutationResult<CreateVisitMutation>;
export type CreateVisitMutationOptions = Apollo.BaseMutationOptions<
  CreateVisitMutation,
  CreateVisitMutationVariables
>;
export const GetRequestDocument = gql`
  query GetRequest($id: ID!) {
    request(id: $id) {
      ...RequestFields
    }
  }
  ${RequestFieldsFragmentDoc}
`;

/**
 * __useGetRequestQuery__
 *
 * To run a query within a React component, call `useGetRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequestQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequestQuery, GetRequestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestQuery, GetRequestQueryVariables>(GetRequestDocument, options);
}
export function useGetRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequestQuery, GetRequestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestQuery, GetRequestQueryVariables>(
    GetRequestDocument,
    options
  );
}
export type GetRequestQueryHookResult = ReturnType<typeof useGetRequestQuery>;
export type GetRequestLazyQueryHookResult = ReturnType<typeof useGetRequestLazyQuery>;
export type GetRequestQueryResult = Apollo.QueryResult<GetRequestQuery, GetRequestQueryVariables>;
export const GetRequestCostsDocument = gql`
  query GetRequestCosts($id: ID!) {
    request(id: $id) {
      id
      costLines {
        id
        date
        visitNumber
        amount
        markup
        description
        receiptKey
        receiptImages {
          key
        }
        total
      }
    }
  }
`;

/**
 * __useGetRequestCostsQuery__
 *
 * To run a query within a React component, call `useGetRequestCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestCostsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequestCostsQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequestCostsQuery, GetRequestCostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestCostsQuery, GetRequestCostsQueryVariables>(
    GetRequestCostsDocument,
    options
  );
}
export function useGetRequestCostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequestCostsQuery, GetRequestCostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestCostsQuery, GetRequestCostsQueryVariables>(
    GetRequestCostsDocument,
    options
  );
}
export type GetRequestCostsQueryHookResult = ReturnType<typeof useGetRequestCostsQuery>;
export type GetRequestCostsLazyQueryHookResult = ReturnType<typeof useGetRequestCostsLazyQuery>;
export type GetRequestCostsQueryResult = Apollo.QueryResult<
  GetRequestCostsQuery,
  GetRequestCostsQueryVariables
>;
export const UpdateRequestRecurrenceDocument = gql`
  mutation UpdateRequestRecurrence($id: ID!, $input: UpdateRequestRecurrenceInput!) {
    updateRequestRecurrence(id: $id, input: $input) {
      error
      success
      requestRecurrence {
        id
        rrule
        summary
        name
        category
        originalRequest
        details
        instructions
        minutes
        nextRecurrenceZ
      }
    }
  }
`;
export type UpdateRequestRecurrenceMutationFn = Apollo.MutationFunction<
  UpdateRequestRecurrenceMutation,
  UpdateRequestRecurrenceMutationVariables
>;

/**
 * __useUpdateRequestRecurrenceMutation__
 *
 * To run a mutation, you first call `useUpdateRequestRecurrenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequestRecurrenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequestRecurrenceMutation, { data, loading, error }] = useUpdateRequestRecurrenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRequestRecurrenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRequestRecurrenceMutation,
    UpdateRequestRecurrenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRequestRecurrenceMutation,
    UpdateRequestRecurrenceMutationVariables
  >(UpdateRequestRecurrenceDocument, options);
}
export type UpdateRequestRecurrenceMutationHookResult = ReturnType<
  typeof useUpdateRequestRecurrenceMutation
>;
export type UpdateRequestRecurrenceMutationResult =
  Apollo.MutationResult<UpdateRequestRecurrenceMutation>;
export type UpdateRequestRecurrenceMutationOptions = Apollo.BaseMutationOptions<
  UpdateRequestRecurrenceMutation,
  UpdateRequestRecurrenceMutationVariables
>;
export const DeleteRequestRecurrenceDocument = gql`
  mutation DeleteRequestRecurrence($id: ID!) {
    deleteRequestRecurrence(id: $id) {
      success
      error
    }
  }
`;
export type DeleteRequestRecurrenceMutationFn = Apollo.MutationFunction<
  DeleteRequestRecurrenceMutation,
  DeleteRequestRecurrenceMutationVariables
>;

/**
 * __useDeleteRequestRecurrenceMutation__
 *
 * To run a mutation, you first call `useDeleteRequestRecurrenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestRecurrenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestRecurrenceMutation, { data, loading, error }] = useDeleteRequestRecurrenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRequestRecurrenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRequestRecurrenceMutation,
    DeleteRequestRecurrenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRequestRecurrenceMutation,
    DeleteRequestRecurrenceMutationVariables
  >(DeleteRequestRecurrenceDocument, options);
}
export type DeleteRequestRecurrenceMutationHookResult = ReturnType<
  typeof useDeleteRequestRecurrenceMutation
>;
export type DeleteRequestRecurrenceMutationResult =
  Apollo.MutationResult<DeleteRequestRecurrenceMutation>;
export type DeleteRequestRecurrenceMutationOptions = Apollo.BaseMutationOptions<
  DeleteRequestRecurrenceMutation,
  DeleteRequestRecurrenceMutationVariables
>;
export const GetRequestRecurrenceDocument = gql`
  query GetRequestRecurrence($id: ID!) {
    requestRecurrence(id: $id) {
      ...RequestRecurrence
    }
  }
  ${RequestRecurrenceFragmentDoc}
`;

/**
 * __useGetRequestRecurrenceQuery__
 *
 * To run a query within a React component, call `useGetRequestRecurrenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestRecurrenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestRecurrenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequestRecurrenceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRequestRecurrenceQuery,
    GetRequestRecurrenceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestRecurrenceQuery, GetRequestRecurrenceQueryVariables>(
    GetRequestRecurrenceDocument,
    options
  );
}
export function useGetRequestRecurrenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRequestRecurrenceQuery,
    GetRequestRecurrenceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestRecurrenceQuery, GetRequestRecurrenceQueryVariables>(
    GetRequestRecurrenceDocument,
    options
  );
}
export type GetRequestRecurrenceQueryHookResult = ReturnType<typeof useGetRequestRecurrenceQuery>;
export type GetRequestRecurrenceLazyQueryHookResult = ReturnType<
  typeof useGetRequestRecurrenceLazyQuery
>;
export type GetRequestRecurrenceQueryResult = Apollo.QueryResult<
  GetRequestRecurrenceQuery,
  GetRequestRecurrenceQueryVariables
>;
export const GetRequestRecurrencesDocument = gql`
  query GetRequestRecurrences($nextToken: String) {
    account {
      id
      listRequestRecurrences(nextToken: $nextToken) {
        items @connection(key: "id") {
          ...RequestRecurrence
        }
        nextToken
      }
    }
  }
  ${RequestRecurrenceFragmentDoc}
`;

/**
 * __useGetRequestRecurrencesQuery__
 *
 * To run a query within a React component, call `useGetRequestRecurrencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestRecurrencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestRecurrencesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetRequestRecurrencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRequestRecurrencesQuery,
    GetRequestRecurrencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestRecurrencesQuery, GetRequestRecurrencesQueryVariables>(
    GetRequestRecurrencesDocument,
    options
  );
}
export function useGetRequestRecurrencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRequestRecurrencesQuery,
    GetRequestRecurrencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestRecurrencesQuery, GetRequestRecurrencesQueryVariables>(
    GetRequestRecurrencesDocument,
    options
  );
}
export type GetRequestRecurrencesQueryHookResult = ReturnType<typeof useGetRequestRecurrencesQuery>;
export type GetRequestRecurrencesLazyQueryHookResult = ReturnType<
  typeof useGetRequestRecurrencesLazyQuery
>;
export type GetRequestRecurrencesQueryResult = Apollo.QueryResult<
  GetRequestRecurrencesQuery,
  GetRequestRecurrencesQueryVariables
>;
export const ServerRequestsDocument = gql`
  query ServerRequests(
    $first: Int
    $after: ID
    $filter: RequestsFilterInput
    $sort: [RequestsSortInput!]
  ) {
    account {
      id
      pageRequests(first: $first, after: $after, filter: $filter, sort: $sort) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ...ServerRequestListFields
          }
        }
      }
    }
  }
  ${ServerRequestListFieldsFragmentDoc}
`;

/**
 * __useServerRequestsQuery__
 *
 * To run a query within a React component, call `useServerRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerRequestsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useServerRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<ServerRequestsQuery, ServerRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServerRequestsQuery, ServerRequestsQueryVariables>(
    ServerRequestsDocument,
    options
  );
}
export function useServerRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServerRequestsQuery, ServerRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServerRequestsQuery, ServerRequestsQueryVariables>(
    ServerRequestsDocument,
    options
  );
}
export type ServerRequestsQueryHookResult = ReturnType<typeof useServerRequestsQuery>;
export type ServerRequestsLazyQueryHookResult = ReturnType<typeof useServerRequestsLazyQuery>;
export type ServerRequestsQueryResult = Apollo.QueryResult<
  ServerRequestsQuery,
  ServerRequestsQueryVariables
>;
export const GetResidenciesEligibleForRentIncreaseDocument = gql`
  query GetResidenciesEligibleForRentIncrease(
    $first: Int
    $after: ID
    $filter: ResidenciesFilterInput
    $sort: [ResidenciesSortInput!]
  ) {
    account {
      id
      pageResidencies(first: $first, after: $after, filter: $filter, sort: $sort) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            accountId
            unitId
            unitName
            unitOwnerId
            ownerId
            ownerName
            propertyId
            propertyName
            startZ
            terms
            renewZ
            endZ
            latestRentEffectZ
            propertyAddress {
              ...AddressFields
            }
            residents {
              leaseHolder
              residentName
            }
            unitOccupancy
            effects {
              id
              feeId
              startZ
              effect
            }
          }
        }
      }
    }
  }
  ${AddressFieldsFragmentDoc}
`;

/**
 * __useGetResidenciesEligibleForRentIncreaseQuery__
 *
 * To run a query within a React component, call `useGetResidenciesEligibleForRentIncreaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResidenciesEligibleForRentIncreaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResidenciesEligibleForRentIncreaseQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetResidenciesEligibleForRentIncreaseQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetResidenciesEligibleForRentIncreaseQuery,
    GetResidenciesEligibleForRentIncreaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetResidenciesEligibleForRentIncreaseQuery,
    GetResidenciesEligibleForRentIncreaseQueryVariables
  >(GetResidenciesEligibleForRentIncreaseDocument, options);
}
export function useGetResidenciesEligibleForRentIncreaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetResidenciesEligibleForRentIncreaseQuery,
    GetResidenciesEligibleForRentIncreaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetResidenciesEligibleForRentIncreaseQuery,
    GetResidenciesEligibleForRentIncreaseQueryVariables
  >(GetResidenciesEligibleForRentIncreaseDocument, options);
}
export type GetResidenciesEligibleForRentIncreaseQueryHookResult = ReturnType<
  typeof useGetResidenciesEligibleForRentIncreaseQuery
>;
export type GetResidenciesEligibleForRentIncreaseLazyQueryHookResult = ReturnType<
  typeof useGetResidenciesEligibleForRentIncreaseLazyQuery
>;
export type GetResidenciesEligibleForRentIncreaseQueryResult = Apollo.QueryResult<
  GetResidenciesEligibleForRentIncreaseQuery,
  GetResidenciesEligibleForRentIncreaseQueryVariables
>;
export const GetInsuranceResidenciesDocument = gql`
  query GetInsuranceResidencies(
    $first: Int
    $after: ID
    $filter: ResidenciesFilterInput
    $sort: [ResidenciesSortInput!]
  ) {
    account {
      id
      pageResidencies(first: $first, after: $after, filter: $filter, sort: $sort) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ...InsuranceResidencyFields
          }
        }
      }
    }
  }
  ${InsuranceResidencyFieldsFragmentDoc}
`;

/**
 * __useGetInsuranceResidenciesQuery__
 *
 * To run a query within a React component, call `useGetInsuranceResidenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsuranceResidenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsuranceResidenciesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetInsuranceResidenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInsuranceResidenciesQuery,
    GetInsuranceResidenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInsuranceResidenciesQuery, GetInsuranceResidenciesQueryVariables>(
    GetInsuranceResidenciesDocument,
    options
  );
}
export function useGetInsuranceResidenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInsuranceResidenciesQuery,
    GetInsuranceResidenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInsuranceResidenciesQuery, GetInsuranceResidenciesQueryVariables>(
    GetInsuranceResidenciesDocument,
    options
  );
}
export type GetInsuranceResidenciesQueryHookResult = ReturnType<
  typeof useGetInsuranceResidenciesQuery
>;
export type GetInsuranceResidenciesLazyQueryHookResult = ReturnType<
  typeof useGetInsuranceResidenciesLazyQuery
>;
export type GetInsuranceResidenciesQueryResult = Apollo.QueryResult<
  GetInsuranceResidenciesQuery,
  GetInsuranceResidenciesQueryVariables
>;
export const ListAllResidenciesDocument = gql`
  query ListAllResidencies($nextToken: String, $filter: ResidencyConnectionFilter) {
    account {
      id
      listResidencies(nextToken: $nextToken, filter: $filter) {
        items @connection(key: "id") {
          ...ResidencyFields
        }
        nextToken
      }
    }
  }
  ${ResidencyFieldsFragmentDoc}
`;

/**
 * __useListAllResidenciesQuery__
 *
 * To run a query within a React component, call `useListAllResidenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllResidenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllResidenciesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListAllResidenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListAllResidenciesQuery, ListAllResidenciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAllResidenciesQuery, ListAllResidenciesQueryVariables>(
    ListAllResidenciesDocument,
    options
  );
}
export function useListAllResidenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAllResidenciesQuery,
    ListAllResidenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListAllResidenciesQuery, ListAllResidenciesQueryVariables>(
    ListAllResidenciesDocument,
    options
  );
}
export type ListAllResidenciesQueryHookResult = ReturnType<typeof useListAllResidenciesQuery>;
export type ListAllResidenciesLazyQueryHookResult = ReturnType<
  typeof useListAllResidenciesLazyQuery
>;
export type ListAllResidenciesQueryResult = Apollo.QueryResult<
  ListAllResidenciesQuery,
  ListAllResidenciesQueryVariables
>;
export const InspectionsByIdsDocument = gql`
  query InspectionsByIds($ids: [ID!]!) {
    inspectionByIds(ids: $ids) {
      id
      completedZ
      unitId
    }
  }
`;

/**
 * __useInspectionsByIdsQuery__
 *
 * To run a query within a React component, call `useInspectionsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspectionsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspectionsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useInspectionsByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<InspectionsByIdsQuery, InspectionsByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InspectionsByIdsQuery, InspectionsByIdsQueryVariables>(
    InspectionsByIdsDocument,
    options
  );
}
export function useInspectionsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InspectionsByIdsQuery, InspectionsByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InspectionsByIdsQuery, InspectionsByIdsQueryVariables>(
    InspectionsByIdsDocument,
    options
  );
}
export type InspectionsByIdsQueryHookResult = ReturnType<typeof useInspectionsByIdsQuery>;
export type InspectionsByIdsLazyQueryHookResult = ReturnType<typeof useInspectionsByIdsLazyQuery>;
export type InspectionsByIdsQueryResult = Apollo.QueryResult<
  InspectionsByIdsQuery,
  InspectionsByIdsQueryVariables
>;
export const UnitsWithResidenciesDocument = gql`
  query UnitsWithResidencies($filter: UnitsWithResidenciesFilter!) {
    unitsWithResidencies(filter: $filter) {
      id
      name
      ownerId
      propertyId
      occupancy
      buildingId
      sqft
      owner {
        id
        name
      }
      allResidencies {
        id
        startZ
        endZ
        rrule
        terms
        renewZ
        moveOutInspectionId
        moveInInspectionId
        residents {
          id
          leaseHolder
          resident {
            name
          }
        }
        gst
        occupancy
        effects {
          id
          feeId
          effect
          startZ
        }
      }
    }
  }
`;

/**
 * __useUnitsWithResidenciesQuery__
 *
 * To run a query within a React component, call `useUnitsWithResidenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsWithResidenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsWithResidenciesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUnitsWithResidenciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UnitsWithResidenciesQuery,
    UnitsWithResidenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnitsWithResidenciesQuery, UnitsWithResidenciesQueryVariables>(
    UnitsWithResidenciesDocument,
    options
  );
}
export function useUnitsWithResidenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnitsWithResidenciesQuery,
    UnitsWithResidenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnitsWithResidenciesQuery, UnitsWithResidenciesQueryVariables>(
    UnitsWithResidenciesDocument,
    options
  );
}
export type UnitsWithResidenciesQueryHookResult = ReturnType<typeof useUnitsWithResidenciesQuery>;
export type UnitsWithResidenciesLazyQueryHookResult = ReturnType<
  typeof useUnitsWithResidenciesLazyQuery
>;
export type UnitsWithResidenciesQueryResult = Apollo.QueryResult<
  UnitsWithResidenciesQuery,
  UnitsWithResidenciesQueryVariables
>;
export const UpdateSupplierAutoApDocument = gql`
  mutation UpdateSupplierAutoAP($input: UpdateSupplierAutoAPInput!) {
    updateSupplierAutoAP(input: $input) {
      success
      error
      supplier {
        ...SupplierFields
      }
    }
  }
  ${SupplierFieldsFragmentDoc}
`;
export type UpdateSupplierAutoApMutationFn = Apollo.MutationFunction<
  UpdateSupplierAutoApMutation,
  UpdateSupplierAutoApMutationVariables
>;

/**
 * __useUpdateSupplierAutoApMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierAutoApMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierAutoApMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierAutoApMutation, { data, loading, error }] = useUpdateSupplierAutoApMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupplierAutoApMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSupplierAutoApMutation,
    UpdateSupplierAutoApMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSupplierAutoApMutation, UpdateSupplierAutoApMutationVariables>(
    UpdateSupplierAutoApDocument,
    options
  );
}
export type UpdateSupplierAutoApMutationHookResult = ReturnType<
  typeof useUpdateSupplierAutoApMutation
>;
export type UpdateSupplierAutoApMutationResult =
  Apollo.MutationResult<UpdateSupplierAutoApMutation>;
export type UpdateSupplierAutoApMutationOptions = Apollo.BaseMutationOptions<
  UpdateSupplierAutoApMutation,
  UpdateSupplierAutoApMutationVariables
>;
export const CreateHouseRulesTemplateDocument = gql`
  mutation CreateHouseRulesTemplate($input: HouseRulesTemplateInput!) {
    createHouseRulesTemplate(input: $input) {
      ...TemplateFields
    }
  }
  ${TemplateFieldsFragmentDoc}
`;
export type CreateHouseRulesTemplateMutationFn = Apollo.MutationFunction<
  CreateHouseRulesTemplateMutation,
  CreateHouseRulesTemplateMutationVariables
>;

/**
 * __useCreateHouseRulesTemplateMutation__
 *
 * To run a mutation, you first call `useCreateHouseRulesTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHouseRulesTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHouseRulesTemplateMutation, { data, loading, error }] = useCreateHouseRulesTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHouseRulesTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateHouseRulesTemplateMutation,
    CreateHouseRulesTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateHouseRulesTemplateMutation,
    CreateHouseRulesTemplateMutationVariables
  >(CreateHouseRulesTemplateDocument, options);
}
export type CreateHouseRulesTemplateMutationHookResult = ReturnType<
  typeof useCreateHouseRulesTemplateMutation
>;
export type CreateHouseRulesTemplateMutationResult =
  Apollo.MutationResult<CreateHouseRulesTemplateMutation>;
export type CreateHouseRulesTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateHouseRulesTemplateMutation,
  CreateHouseRulesTemplateMutationVariables
>;
export const UpdateHouseRulesTemplateDocument = gql`
  mutation UpdateHouseRulesTemplate($id: ID!, $input: HouseRulesTemplateInput!) {
    updateHouseRulesTemplate(id: $id, input: $input) {
      ...TemplateFields
    }
  }
  ${TemplateFieldsFragmentDoc}
`;
export type UpdateHouseRulesTemplateMutationFn = Apollo.MutationFunction<
  UpdateHouseRulesTemplateMutation,
  UpdateHouseRulesTemplateMutationVariables
>;

/**
 * __useUpdateHouseRulesTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateHouseRulesTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHouseRulesTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHouseRulesTemplateMutation, { data, loading, error }] = useUpdateHouseRulesTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHouseRulesTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHouseRulesTemplateMutation,
    UpdateHouseRulesTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateHouseRulesTemplateMutation,
    UpdateHouseRulesTemplateMutationVariables
  >(UpdateHouseRulesTemplateDocument, options);
}
export type UpdateHouseRulesTemplateMutationHookResult = ReturnType<
  typeof useUpdateHouseRulesTemplateMutation
>;
export type UpdateHouseRulesTemplateMutationResult =
  Apollo.MutationResult<UpdateHouseRulesTemplateMutation>;
export type UpdateHouseRulesTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateHouseRulesTemplateMutation,
  UpdateHouseRulesTemplateMutationVariables
>;
export const DeleteHouseRulesTemplateDocument = gql`
  mutation DeleteHouseRulesTemplate($id: ID!) {
    deleteHouseRulesTemplate(id: $id) {
      ...TemplateFields
    }
  }
  ${TemplateFieldsFragmentDoc}
`;
export type DeleteHouseRulesTemplateMutationFn = Apollo.MutationFunction<
  DeleteHouseRulesTemplateMutation,
  DeleteHouseRulesTemplateMutationVariables
>;

/**
 * __useDeleteHouseRulesTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteHouseRulesTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHouseRulesTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHouseRulesTemplateMutation, { data, loading, error }] = useDeleteHouseRulesTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHouseRulesTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteHouseRulesTemplateMutation,
    DeleteHouseRulesTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteHouseRulesTemplateMutation,
    DeleteHouseRulesTemplateMutationVariables
  >(DeleteHouseRulesTemplateDocument, options);
}
export type DeleteHouseRulesTemplateMutationHookResult = ReturnType<
  typeof useDeleteHouseRulesTemplateMutation
>;
export type DeleteHouseRulesTemplateMutationResult =
  Apollo.MutationResult<DeleteHouseRulesTemplateMutation>;
export type DeleteHouseRulesTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteHouseRulesTemplateMutation,
  DeleteHouseRulesTemplateMutationVariables
>;
export const GetHouseRulesTemplateDocument = gql`
  query GetHouseRulesTemplate {
    houseRulesTemplate {
      ...TemplateFields
    }
  }
  ${TemplateFieldsFragmentDoc}
`;

/**
 * __useGetHouseRulesTemplateQuery__
 *
 * To run a query within a React component, call `useGetHouseRulesTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseRulesTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseRulesTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHouseRulesTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHouseRulesTemplateQuery,
    GetHouseRulesTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHouseRulesTemplateQuery, GetHouseRulesTemplateQueryVariables>(
    GetHouseRulesTemplateDocument,
    options
  );
}
export function useGetHouseRulesTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHouseRulesTemplateQuery,
    GetHouseRulesTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHouseRulesTemplateQuery, GetHouseRulesTemplateQueryVariables>(
    GetHouseRulesTemplateDocument,
    options
  );
}
export type GetHouseRulesTemplateQueryHookResult = ReturnType<typeof useGetHouseRulesTemplateQuery>;
export type GetHouseRulesTemplateLazyQueryHookResult = ReturnType<
  typeof useGetHouseRulesTemplateLazyQuery
>;
export type GetHouseRulesTemplateQueryResult = Apollo.QueryResult<
  GetHouseRulesTemplateQuery,
  GetHouseRulesTemplateQueryVariables
>;
export const CreateTemplateDocument = gql`
  mutation CreateTemplate($id: ID!, $input: CreateTemplateInput!) {
    createTemplate(id: $id, input: $input) {
      ...TemplateFields
    }
  }
  ${TemplateFieldsFragmentDoc}
`;
export type CreateTemplateMutationFn = Apollo.MutationFunction<
  CreateTemplateMutation,
  CreateTemplateMutationVariables
>;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(
    CreateTemplateDocument,
    options
  );
}
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = Apollo.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateTemplateMutation,
  CreateTemplateMutationVariables
>;
export const UpdateTemplateDocument = gql`
  mutation UpdateTemplate($id: ID!, $input: UpdateTemplateInput!) {
    updateTemplate(id: $id, input: $input) {
      ...TemplateFields
    }
  }
  ${TemplateFieldsFragmentDoc}
`;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<
  UpdateTemplateMutation,
  UpdateTemplateMutationVariables
>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(
    UpdateTemplateDocument,
    options
  );
}
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = Apollo.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateTemplateMutation,
  UpdateTemplateMutationVariables
>;
export const DeleteTemplateDocument = gql`
  mutation DeleteTemplate($id: ID!) {
    deleteTemplate(id: $id) {
      ...TemplateFields
    }
  }
  ${TemplateFieldsFragmentDoc}
`;
export type DeleteTemplateMutationFn = Apollo.MutationFunction<
  DeleteTemplateMutation,
  DeleteTemplateMutationVariables
>;

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(
    DeleteTemplateDocument,
    options
  );
}
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = Apollo.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteTemplateMutation,
  DeleteTemplateMutationVariables
>;
export const GetDefaultTemplateDocument = gql`
  query GetDefaultTemplate($id: ID!) {
    defaultTemplate(id: $id) {
      ...TemplateFields
    }
  }
  ${TemplateFieldsFragmentDoc}
`;

/**
 * __useGetDefaultTemplateQuery__
 *
 * To run a query within a React component, call `useGetDefaultTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDefaultTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<GetDefaultTemplateQuery, GetDefaultTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDefaultTemplateQuery, GetDefaultTemplateQueryVariables>(
    GetDefaultTemplateDocument,
    options
  );
}
export function useGetDefaultTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDefaultTemplateQuery,
    GetDefaultTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDefaultTemplateQuery, GetDefaultTemplateQueryVariables>(
    GetDefaultTemplateDocument,
    options
  );
}
export type GetDefaultTemplateQueryHookResult = ReturnType<typeof useGetDefaultTemplateQuery>;
export type GetDefaultTemplateLazyQueryHookResult = ReturnType<
  typeof useGetDefaultTemplateLazyQuery
>;
export type GetDefaultTemplateQueryResult = Apollo.QueryResult<
  GetDefaultTemplateQuery,
  GetDefaultTemplateQueryVariables
>;
export const GetCustomTemplateDocument = gql`
  query GetCustomTemplate($id: ID!) {
    customTemplate(id: $id) {
      ...TemplateFields
    }
  }
  ${TemplateFieldsFragmentDoc}
`;

/**
 * __useGetCustomTemplateQuery__
 *
 * To run a query within a React component, call `useGetCustomTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomTemplateQuery, GetCustomTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomTemplateQuery, GetCustomTemplateQueryVariables>(
    GetCustomTemplateDocument,
    options
  );
}
export function useGetCustomTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomTemplateQuery, GetCustomTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomTemplateQuery, GetCustomTemplateQueryVariables>(
    GetCustomTemplateDocument,
    options
  );
}
export type GetCustomTemplateQueryHookResult = ReturnType<typeof useGetCustomTemplateQuery>;
export type GetCustomTemplateLazyQueryHookResult = ReturnType<typeof useGetCustomTemplateLazyQuery>;
export type GetCustomTemplateQueryResult = Apollo.QueryResult<
  GetCustomTemplateQuery,
  GetCustomTemplateQueryVariables
>;
export const GetTemplatesDocument = gql`
  query getTemplates {
    templates {
      ...TemplateFields
    }
  }
  ${TemplateFieldsFragmentDoc}
`;

/**
 * __useGetTemplatesQuery__
 *
 * To run a query within a React component, call `useGetTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(
    GetTemplatesDocument,
    options
  );
}
export function useGetTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(
    GetTemplatesDocument,
    options
  );
}
export type GetTemplatesQueryHookResult = ReturnType<typeof useGetTemplatesQuery>;
export type GetTemplatesLazyQueryHookResult = ReturnType<typeof useGetTemplatesLazyQuery>;
export type GetTemplatesQueryResult = Apollo.QueryResult<
  GetTemplatesQuery,
  GetTemplatesQueryVariables
>;
export const SetTenantAutopayCollectionsEnabledDocument = gql`
  mutation SetTenantAutopayCollectionsEnabled($input: SetAutopayCollectionsEnabledInput!) {
    setTenantAutopayCollectionsEnabled(input: $input) {
      success
      error
      tenant {
        id
        autopayCollectionsEnabled
        autopayCollectionsEnabledZ
      }
    }
  }
`;
export type SetTenantAutopayCollectionsEnabledMutationFn = Apollo.MutationFunction<
  SetTenantAutopayCollectionsEnabledMutation,
  SetTenantAutopayCollectionsEnabledMutationVariables
>;

/**
 * __useSetTenantAutopayCollectionsEnabledMutation__
 *
 * To run a mutation, you first call `useSetTenantAutopayCollectionsEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTenantAutopayCollectionsEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTenantAutopayCollectionsEnabledMutation, { data, loading, error }] = useSetTenantAutopayCollectionsEnabledMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTenantAutopayCollectionsEnabledMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetTenantAutopayCollectionsEnabledMutation,
    SetTenantAutopayCollectionsEnabledMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetTenantAutopayCollectionsEnabledMutation,
    SetTenantAutopayCollectionsEnabledMutationVariables
  >(SetTenantAutopayCollectionsEnabledDocument, options);
}
export type SetTenantAutopayCollectionsEnabledMutationHookResult = ReturnType<
  typeof useSetTenantAutopayCollectionsEnabledMutation
>;
export type SetTenantAutopayCollectionsEnabledMutationResult =
  Apollo.MutationResult<SetTenantAutopayCollectionsEnabledMutation>;
export type SetTenantAutopayCollectionsEnabledMutationOptions = Apollo.BaseMutationOptions<
  SetTenantAutopayCollectionsEnabledMutation,
  SetTenantAutopayCollectionsEnabledMutationVariables
>;
export const TenantResidencyFinancialsPathDocument = gql`
  query TenantResidencyFinancialsPath($tenantId: ID!, $residencyId: ID!) {
    residency(id: $residencyId) {
      id
      startZ
      endZ
      gst
      effects {
        __typename
        id
        feeId
        startZ
        effect
      }
      unit {
        id
        name
        building {
          id
          name
        }
      }
      property {
        id
        name
      }
      residents(filter: { residentId: $tenantId }) {
        id
        residentId
        leaseHolder
        payeeFrac {
          numerator
          denominator
        }
        paymentDetails {
          preferredPaymentMethod
          padAmount
          padInstitution
          padTransit
          padAccount
        }
      }
    }
  }
`;

/**
 * __useTenantResidencyFinancialsPathQuery__
 *
 * To run a query within a React component, call `useTenantResidencyFinancialsPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantResidencyFinancialsPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantResidencyFinancialsPathQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useTenantResidencyFinancialsPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    TenantResidencyFinancialsPathQuery,
    TenantResidencyFinancialsPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TenantResidencyFinancialsPathQuery,
    TenantResidencyFinancialsPathQueryVariables
  >(TenantResidencyFinancialsPathDocument, options);
}
export function useTenantResidencyFinancialsPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TenantResidencyFinancialsPathQuery,
    TenantResidencyFinancialsPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TenantResidencyFinancialsPathQuery,
    TenantResidencyFinancialsPathQueryVariables
  >(TenantResidencyFinancialsPathDocument, options);
}
export type TenantResidencyFinancialsPathQueryHookResult = ReturnType<
  typeof useTenantResidencyFinancialsPathQuery
>;
export type TenantResidencyFinancialsPathLazyQueryHookResult = ReturnType<
  typeof useTenantResidencyFinancialsPathLazyQuery
>;
export type TenantResidencyFinancialsPathQueryResult = Apollo.QueryResult<
  TenantResidencyFinancialsPathQuery,
  TenantResidencyFinancialsPathQueryVariables
>;
export const ResidencyResidentFinancialsPathDocument = gql`
  query ResidencyResidentFinancialsPath($residentId: ID!, $residencyId: ID!) {
    residency(id: $residencyId) {
      id
      startZ
      endZ
      gst
      effects {
        __typename
        id
        feeId
        startZ
        effect
      }
      residents(filter: { residentId: $residentId }) {
        id
        residentId
        leaseHolder
        payeeFrac {
          numerator
          denominator
        }
        paymentDetails {
          preferredPaymentMethod
          padAmount
          padInstitution
          padTransit
          padAccount
        }
      }
    }
  }
`;

/**
 * __useResidencyResidentFinancialsPathQuery__
 *
 * To run a query within a React component, call `useResidencyResidentFinancialsPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useResidencyResidentFinancialsPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResidencyResidentFinancialsPathQuery({
 *   variables: {
 *      residentId: // value for 'residentId'
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useResidencyResidentFinancialsPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResidencyResidentFinancialsPathQuery,
    ResidencyResidentFinancialsPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResidencyResidentFinancialsPathQuery,
    ResidencyResidentFinancialsPathQueryVariables
  >(ResidencyResidentFinancialsPathDocument, options);
}
export function useResidencyResidentFinancialsPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResidencyResidentFinancialsPathQuery,
    ResidencyResidentFinancialsPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResidencyResidentFinancialsPathQuery,
    ResidencyResidentFinancialsPathQueryVariables
  >(ResidencyResidentFinancialsPathDocument, options);
}
export type ResidencyResidentFinancialsPathQueryHookResult = ReturnType<
  typeof useResidencyResidentFinancialsPathQuery
>;
export type ResidencyResidentFinancialsPathLazyQueryHookResult = ReturnType<
  typeof useResidencyResidentFinancialsPathLazyQuery
>;
export type ResidencyResidentFinancialsPathQueryResult = Apollo.QueryResult<
  ResidencyResidentFinancialsPathQuery,
  ResidencyResidentFinancialsPathQueryVariables
>;
export const UpdateResidencyResidentFinancialsDocument = gql`
  mutation UpdateResidencyResidentFinancials(
    $residentId: ID!
    $input: UpdateResidencyResidentFinancialsInput!
  ) {
    updateResidencyResidentFinancials(input: $input) {
      residency {
        id
        residents(filter: { residentId: $residentId }) {
          id
          residentId
          leaseHolder
          paymentDetails {
            preferredPaymentMethod
            padAmount
            padInstitution
            padTransit
            padAccount
          }
        }
      }
    }
  }
`;
export type UpdateResidencyResidentFinancialsMutationFn = Apollo.MutationFunction<
  UpdateResidencyResidentFinancialsMutation,
  UpdateResidencyResidentFinancialsMutationVariables
>;

/**
 * __useUpdateResidencyResidentFinancialsMutation__
 *
 * To run a mutation, you first call `useUpdateResidencyResidentFinancialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResidencyResidentFinancialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResidencyResidentFinancialsMutation, { data, loading, error }] = useUpdateResidencyResidentFinancialsMutation({
 *   variables: {
 *      residentId: // value for 'residentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResidencyResidentFinancialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateResidencyResidentFinancialsMutation,
    UpdateResidencyResidentFinancialsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateResidencyResidentFinancialsMutation,
    UpdateResidencyResidentFinancialsMutationVariables
  >(UpdateResidencyResidentFinancialsDocument, options);
}
export type UpdateResidencyResidentFinancialsMutationHookResult = ReturnType<
  typeof useUpdateResidencyResidentFinancialsMutation
>;
export type UpdateResidencyResidentFinancialsMutationResult =
  Apollo.MutationResult<UpdateResidencyResidentFinancialsMutation>;
export type UpdateResidencyResidentFinancialsMutationOptions = Apollo.BaseMutationOptions<
  UpdateResidencyResidentFinancialsMutation,
  UpdateResidencyResidentFinancialsMutationVariables
>;
export const CreateTenantNoteDocument = gql`
  mutation CreateTenantNote($input: CreateTenantNoteInput!) {
    createTenantNote(input: $input) {
      tenant {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type CreateTenantNoteMutationFn = Apollo.MutationFunction<
  CreateTenantNoteMutation,
  CreateTenantNoteMutationVariables
>;

/**
 * __useCreateTenantNoteMutation__
 *
 * To run a mutation, you first call `useCreateTenantNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantNoteMutation, { data, loading, error }] = useCreateTenantNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTenantNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTenantNoteMutation,
    CreateTenantNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTenantNoteMutation, CreateTenantNoteMutationVariables>(
    CreateTenantNoteDocument,
    options
  );
}
export type CreateTenantNoteMutationHookResult = ReturnType<typeof useCreateTenantNoteMutation>;
export type CreateTenantNoteMutationResult = Apollo.MutationResult<CreateTenantNoteMutation>;
export type CreateTenantNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateTenantNoteMutation,
  CreateTenantNoteMutationVariables
>;
export const DeleteTenantNoteDocument = gql`
  mutation DeleteTenantNote($input: DeleteTenantNoteInput!) {
    deleteTenantNote(input: $input) {
      tenant {
        id
        notes {
          id
          createdName
          createdSub
          createdZ
          text
        }
      }
    }
  }
`;
export type DeleteTenantNoteMutationFn = Apollo.MutationFunction<
  DeleteTenantNoteMutation,
  DeleteTenantNoteMutationVariables
>;

/**
 * __useDeleteTenantNoteMutation__
 *
 * To run a mutation, you first call `useDeleteTenantNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTenantNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTenantNoteMutation, { data, loading, error }] = useDeleteTenantNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTenantNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTenantNoteMutation,
    DeleteTenantNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTenantNoteMutation, DeleteTenantNoteMutationVariables>(
    DeleteTenantNoteDocument,
    options
  );
}
export type DeleteTenantNoteMutationHookResult = ReturnType<typeof useDeleteTenantNoteMutation>;
export type DeleteTenantNoteMutationResult = Apollo.MutationResult<DeleteTenantNoteMutation>;
export type DeleteTenantNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteTenantNoteMutation,
  DeleteTenantNoteMutationVariables
>;
export const TenantNotesDocument = gql`
  query TenantNotes($id: ID!) {
    tenant(id: $id) {
      id
      notes {
        id
        createdName
        createdSub
        createdZ
        text
      }
    }
  }
`;

/**
 * __useTenantNotesQuery__
 *
 * To run a query within a React component, call `useTenantNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTenantNotesQuery(
  baseOptions: Apollo.QueryHookOptions<TenantNotesQuery, TenantNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantNotesQuery, TenantNotesQueryVariables>(TenantNotesDocument, options);
}
export function useTenantNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TenantNotesQuery, TenantNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantNotesQuery, TenantNotesQueryVariables>(
    TenantNotesDocument,
    options
  );
}
export type TenantNotesQueryHookResult = ReturnType<typeof useTenantNotesQuery>;
export type TenantNotesLazyQueryHookResult = ReturnType<typeof useTenantNotesLazyQuery>;
export type TenantNotesQueryResult = Apollo.QueryResult<
  TenantNotesQuery,
  TenantNotesQueryVariables
>;
export const UpdateTenantAutopayDocument = gql`
  mutation UpdateTenantAutopay($input: UpdateTenantAutopayInput!) {
    updateTenantAutopay(input: $input) {
      success
      error
      tenant {
        ...TenantFields
      }
    }
  }
  ${TenantFieldsFragmentDoc}
`;
export type UpdateTenantAutopayMutationFn = Apollo.MutationFunction<
  UpdateTenantAutopayMutation,
  UpdateTenantAutopayMutationVariables
>;

/**
 * __useUpdateTenantAutopayMutation__
 *
 * To run a mutation, you first call `useUpdateTenantAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantAutopayMutation, { data, loading, error }] = useUpdateTenantAutopayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTenantAutopayMutation,
    UpdateTenantAutopayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTenantAutopayMutation, UpdateTenantAutopayMutationVariables>(
    UpdateTenantAutopayDocument,
    options
  );
}
export type UpdateTenantAutopayMutationHookResult = ReturnType<
  typeof useUpdateTenantAutopayMutation
>;
export type UpdateTenantAutopayMutationResult = Apollo.MutationResult<UpdateTenantAutopayMutation>;
export type UpdateTenantAutopayMutationOptions = Apollo.BaseMutationOptions<
  UpdateTenantAutopayMutation,
  UpdateTenantAutopayMutationVariables
>;
export const InviteTenantAutopayDocument = gql`
  mutation InviteTenantAutopay($input: InviteTenantAutopayInput!) {
    inviteTenantAutopay(input: $input) {
      success
      error
      tenant {
        ...TenantFields
      }
    }
  }
  ${TenantFieldsFragmentDoc}
`;
export type InviteTenantAutopayMutationFn = Apollo.MutationFunction<
  InviteTenantAutopayMutation,
  InviteTenantAutopayMutationVariables
>;

/**
 * __useInviteTenantAutopayMutation__
 *
 * To run a mutation, you first call `useInviteTenantAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteTenantAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteTenantAutopayMutation, { data, loading, error }] = useInviteTenantAutopayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteTenantAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteTenantAutopayMutation,
    InviteTenantAutopayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteTenantAutopayMutation, InviteTenantAutopayMutationVariables>(
    InviteTenantAutopayDocument,
    options
  );
}
export type InviteTenantAutopayMutationHookResult = ReturnType<
  typeof useInviteTenantAutopayMutation
>;
export type InviteTenantAutopayMutationResult = Apollo.MutationResult<InviteTenantAutopayMutation>;
export type InviteTenantAutopayMutationOptions = Apollo.BaseMutationOptions<
  InviteTenantAutopayMutation,
  InviteTenantAutopayMutationVariables
>;
export const GetTenantWithResidencyDocument = gql`
  query GetTenantWithResidency($id: ID!) {
    tenant(id: $id) {
      ...TenantWithResidency
    }
  }
  ${TenantWithResidencyFragmentDoc}
`;

/**
 * __useGetTenantWithResidencyQuery__
 *
 * To run a query within a React component, call `useGetTenantWithResidencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantWithResidencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantWithResidencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantWithResidencyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenantWithResidencyQuery,
    GetTenantWithResidencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantWithResidencyQuery, GetTenantWithResidencyQueryVariables>(
    GetTenantWithResidencyDocument,
    options
  );
}
export function useGetTenantWithResidencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenantWithResidencyQuery,
    GetTenantWithResidencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantWithResidencyQuery, GetTenantWithResidencyQueryVariables>(
    GetTenantWithResidencyDocument,
    options
  );
}
export type GetTenantWithResidencyQueryHookResult = ReturnType<
  typeof useGetTenantWithResidencyQuery
>;
export type GetTenantWithResidencyLazyQueryHookResult = ReturnType<
  typeof useGetTenantWithResidencyLazyQuery
>;
export type GetTenantWithResidencyQueryResult = Apollo.QueryResult<
  GetTenantWithResidencyQuery,
  GetTenantWithResidencyQueryVariables
>;
export const GetTenantDocument = gql`
  query GetTenant($id: ID!) {
    tenant(id: $id) {
      id
      ...TenantFields
    }
  }
  ${TenantFieldsFragmentDoc}
`;

/**
 * __useGetTenantQuery__
 *
 * To run a query within a React component, call `useGetTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantQuery(
  baseOptions: Apollo.QueryHookOptions<GetTenantQuery, GetTenantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
}
export function useGetTenantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTenantQuery, GetTenantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
}
export type GetTenantQueryHookResult = ReturnType<typeof useGetTenantQuery>;
export type GetTenantLazyQueryHookResult = ReturnType<typeof useGetTenantLazyQuery>;
export type GetTenantQueryResult = Apollo.QueryResult<GetTenantQuery, GetTenantQueryVariables>;
export const GetTenantNameDocument = gql`
  query GetTenantName($id: ID!) {
    tenant(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useGetTenantNameQuery__
 *
 * To run a query within a React component, call `useGetTenantNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantNameQuery(
  baseOptions: Apollo.QueryHookOptions<GetTenantNameQuery, GetTenantNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantNameQuery, GetTenantNameQueryVariables>(
    GetTenantNameDocument,
    options
  );
}
export function useGetTenantNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTenantNameQuery, GetTenantNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantNameQuery, GetTenantNameQueryVariables>(
    GetTenantNameDocument,
    options
  );
}
export type GetTenantNameQueryHookResult = ReturnType<typeof useGetTenantNameQuery>;
export type GetTenantNameLazyQueryHookResult = ReturnType<typeof useGetTenantNameLazyQuery>;
export type GetTenantNameQueryResult = Apollo.QueryResult<
  GetTenantNameQuery,
  GetTenantNameQueryVariables
>;
export const GetTenantEnrollmentsDocument = gql`
  query GetTenantEnrollments($id: ID!, $filter: AutopayEnrollmentFilterInput) {
    tenant(id: $id) {
      id
      enrollments(filter: $filter) {
        id
        payeeType
        unitId
        residencyId
        status
      }
    }
  }
`;

/**
 * __useGetTenantEnrollmentsQuery__
 *
 * To run a query within a React component, call `useGetTenantEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantEnrollmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTenantEnrollmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenantEnrollmentsQuery,
    GetTenantEnrollmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantEnrollmentsQuery, GetTenantEnrollmentsQueryVariables>(
    GetTenantEnrollmentsDocument,
    options
  );
}
export function useGetTenantEnrollmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenantEnrollmentsQuery,
    GetTenantEnrollmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantEnrollmentsQuery, GetTenantEnrollmentsQueryVariables>(
    GetTenantEnrollmentsDocument,
    options
  );
}
export type GetTenantEnrollmentsQueryHookResult = ReturnType<typeof useGetTenantEnrollmentsQuery>;
export type GetTenantEnrollmentsLazyQueryHookResult = ReturnType<
  typeof useGetTenantEnrollmentsLazyQuery
>;
export type GetTenantEnrollmentsQueryResult = Apollo.QueryResult<
  GetTenantEnrollmentsQuery,
  GetTenantEnrollmentsQueryVariables
>;
export const GetTenantContactDocument = gql`
  query GetTenantContact($id: ID!) {
    tenant(id: $id) {
      id
      name
      dob
      emails
      phone
      phones
      profileId
      isRentalAssist
      rentalAssistance {
        qualified
        amount
        source
      }
      correspondenceMethod
      emergencyContacts {
        name
        phone
        relationship
      }
      pets {
        type
        count
        details {
          type
          breed
          weight
          name
          color
          license
          isService
        }
      }
      vehicles {
        id
        plate
        make
        model
        color
      }
      profile {
        id
        name
        email
        emails
        phone
        dob
        logins(status: CONFIRMED) {
          id
          email
        }
        household {
          pets {
            type
            count
            details {
              type
              breed
              weight
              name
              color
              license
              isService
            }
          }
          vehicles {
            id
            plate
            make
            model
            color
          }
        }
      }
    }
  }
`;

/**
 * __useGetTenantContactQuery__
 *
 * To run a query within a React component, call `useGetTenantContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantContactQuery(
  baseOptions: Apollo.QueryHookOptions<GetTenantContactQuery, GetTenantContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantContactQuery, GetTenantContactQueryVariables>(
    GetTenantContactDocument,
    options
  );
}
export function useGetTenantContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTenantContactQuery, GetTenantContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantContactQuery, GetTenantContactQueryVariables>(
    GetTenantContactDocument,
    options
  );
}
export type GetTenantContactQueryHookResult = ReturnType<typeof useGetTenantContactQuery>;
export type GetTenantContactLazyQueryHookResult = ReturnType<typeof useGetTenantContactLazyQuery>;
export type GetTenantContactQueryResult = Apollo.QueryResult<
  GetTenantContactQuery,
  GetTenantContactQueryVariables
>;
export const ListAllTenantsWithResidencyDocument = gql`
  query ListAllTenantsWithResidency($nextToken: String) {
    account {
      id
      listTenants(nextToken: $nextToken) {
        items @connection(key: "id") {
          ...TenantFields
          allResidencies {
            id
            unitId
            propertyId
            startZ
            endZ
            moveOutReason
            unit {
              id
              propertyId
              occupancy
            }
            residents {
              id
              payeeFrac {
                numerator
                denominator
              }
              leaseHolder
              residentId
              paymentDetails {
                preferredPaymentMethod
                padAmount
                padInstitution
                padTransit
                padAccount
              }
            }
          }
        }
        nextToken
      }
    }
  }
  ${TenantFieldsFragmentDoc}
`;

/**
 * __useListAllTenantsWithResidencyQuery__
 *
 * To run a query within a React component, call `useListAllTenantsWithResidencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllTenantsWithResidencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllTenantsWithResidencyQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListAllTenantsWithResidencyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAllTenantsWithResidencyQuery,
    ListAllTenantsWithResidencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListAllTenantsWithResidencyQuery,
    ListAllTenantsWithResidencyQueryVariables
  >(ListAllTenantsWithResidencyDocument, options);
}
export function useListAllTenantsWithResidencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAllTenantsWithResidencyQuery,
    ListAllTenantsWithResidencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListAllTenantsWithResidencyQuery,
    ListAllTenantsWithResidencyQueryVariables
  >(ListAllTenantsWithResidencyDocument, options);
}
export type ListAllTenantsWithResidencyQueryHookResult = ReturnType<
  typeof useListAllTenantsWithResidencyQuery
>;
export type ListAllTenantsWithResidencyLazyQueryHookResult = ReturnType<
  typeof useListAllTenantsWithResidencyLazyQuery
>;
export type ListAllTenantsWithResidencyQueryResult = Apollo.QueryResult<
  ListAllTenantsWithResidencyQuery,
  ListAllTenantsWithResidencyQueryVariables
>;
export const TenantDocumentsDocument = gql`
  query TenantDocuments($id: ID!) {
    tenant(id: $id) {
      id
      documents {
        ...DocumentFields
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`;

/**
 * __useTenantDocumentsQuery__
 *
 * To run a query within a React component, call `useTenantDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTenantDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<TenantDocumentsQuery, TenantDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantDocumentsQuery, TenantDocumentsQueryVariables>(
    TenantDocumentsDocument,
    options
  );
}
export function useTenantDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TenantDocumentsQuery, TenantDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantDocumentsQuery, TenantDocumentsQueryVariables>(
    TenantDocumentsDocument,
    options
  );
}
export type TenantDocumentsQueryHookResult = ReturnType<typeof useTenantDocumentsQuery>;
export type TenantDocumentsLazyQueryHookResult = ReturnType<typeof useTenantDocumentsLazyQuery>;
export type TenantDocumentsQueryResult = Apollo.QueryResult<
  TenantDocumentsQuery,
  TenantDocumentsQueryVariables
>;
export const RenameTenantDocumentDocument = gql`
  mutation RenameTenantDocument($input: RenameTenantDocumentInput!) {
    renameTenantDocument(input: $input) {
      tenant {
        id
        documents {
          ...DocumentFields
        }
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`;
export type RenameTenantDocumentMutationFn = Apollo.MutationFunction<
  RenameTenantDocumentMutation,
  RenameTenantDocumentMutationVariables
>;

/**
 * __useRenameTenantDocumentMutation__
 *
 * To run a mutation, you first call `useRenameTenantDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameTenantDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameTenantDocumentMutation, { data, loading, error }] = useRenameTenantDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameTenantDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameTenantDocumentMutation,
    RenameTenantDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RenameTenantDocumentMutation, RenameTenantDocumentMutationVariables>(
    RenameTenantDocumentDocument,
    options
  );
}
export type RenameTenantDocumentMutationHookResult = ReturnType<
  typeof useRenameTenantDocumentMutation
>;
export type RenameTenantDocumentMutationResult =
  Apollo.MutationResult<RenameTenantDocumentMutation>;
export type RenameTenantDocumentMutationOptions = Apollo.BaseMutationOptions<
  RenameTenantDocumentMutation,
  RenameTenantDocumentMutationVariables
>;
export const UpdateTenantFinancialsDocument = gql`
  mutation UpdateTenantFinancials($input: UpdateTenantFinancialsInput!) {
    updateTenantFinancials(input: $input) {
      success
      error
      tenant {
        id
        preferredPaymentMethod
        padAmount
        padInstitution
        padTransit
        padAccount
      }
    }
  }
`;
export type UpdateTenantFinancialsMutationFn = Apollo.MutationFunction<
  UpdateTenantFinancialsMutation,
  UpdateTenantFinancialsMutationVariables
>;

/**
 * __useUpdateTenantFinancialsMutation__
 *
 * To run a mutation, you first call `useUpdateTenantFinancialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantFinancialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantFinancialsMutation, { data, loading, error }] = useUpdateTenantFinancialsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantFinancialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTenantFinancialsMutation,
    UpdateTenantFinancialsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTenantFinancialsMutation,
    UpdateTenantFinancialsMutationVariables
  >(UpdateTenantFinancialsDocument, options);
}
export type UpdateTenantFinancialsMutationHookResult = ReturnType<
  typeof useUpdateTenantFinancialsMutation
>;
export type UpdateTenantFinancialsMutationResult =
  Apollo.MutationResult<UpdateTenantFinancialsMutation>;
export type UpdateTenantFinancialsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTenantFinancialsMutation,
  UpdateTenantFinancialsMutationVariables
>;
export const WelcomeTenantDocument = gql`
  mutation WelcomeTenant($input: WelcomeTenantInput!) {
    welcomeTenant(input: $input) {
      success
      error
    }
  }
`;
export type WelcomeTenantMutationFn = Apollo.MutationFunction<
  WelcomeTenantMutation,
  WelcomeTenantMutationVariables
>;

/**
 * __useWelcomeTenantMutation__
 *
 * To run a mutation, you first call `useWelcomeTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWelcomeTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [welcomeTenantMutation, { data, loading, error }] = useWelcomeTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWelcomeTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<WelcomeTenantMutation, WelcomeTenantMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WelcomeTenantMutation, WelcomeTenantMutationVariables>(
    WelcomeTenantDocument,
    options
  );
}
export type WelcomeTenantMutationHookResult = ReturnType<typeof useWelcomeTenantMutation>;
export type WelcomeTenantMutationResult = Apollo.MutationResult<WelcomeTenantMutation>;
export type WelcomeTenantMutationOptions = Apollo.BaseMutationOptions<
  WelcomeTenantMutation,
  WelcomeTenantMutationVariables
>;
export const UpdateTenantRecordDocument = gql`
  mutation UpdateTenantRecord($input: UpdateTenantInput!) {
    updateTenantRecord(input: $input) {
      success
      error
      tenant {
        ...TenantWithResidency
      }
    }
  }
  ${TenantWithResidencyFragmentDoc}
`;
export type UpdateTenantRecordMutationFn = Apollo.MutationFunction<
  UpdateTenantRecordMutation,
  UpdateTenantRecordMutationVariables
>;

/**
 * __useUpdateTenantRecordMutation__
 *
 * To run a mutation, you first call `useUpdateTenantRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantRecordMutation, { data, loading, error }] = useUpdateTenantRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantRecordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTenantRecordMutation,
    UpdateTenantRecordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTenantRecordMutation, UpdateTenantRecordMutationVariables>(
    UpdateTenantRecordDocument,
    options
  );
}
export type UpdateTenantRecordMutationHookResult = ReturnType<typeof useUpdateTenantRecordMutation>;
export type UpdateTenantRecordMutationResult = Apollo.MutationResult<UpdateTenantRecordMutation>;
export type UpdateTenantRecordMutationOptions = Apollo.BaseMutationOptions<
  UpdateTenantRecordMutation,
  UpdateTenantRecordMutationVariables
>;
export const AccountEventsDocument = gql`
  subscription AccountEvents($accountId: ID!) {
    onAccountEvent(accountId: $accountId) {
      accountId
      source
      detail
      detailType
      id
      time
    }
  }
`;

/**
 * __useAccountEventsSubscription__
 *
 * To run a query within a React component, call `useAccountEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountEventsSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountEventsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    AccountEventsSubscription,
    AccountEventsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<AccountEventsSubscription, AccountEventsSubscriptionVariables>(
    AccountEventsDocument,
    options
  );
}
export type AccountEventsSubscriptionHookResult = ReturnType<typeof useAccountEventsSubscription>;
export type AccountEventsSubscriptionResult = Apollo.SubscriptionResult<AccountEventsSubscription>;
export type AccountKeySpecifier = (
  | 'activatedEnrollmentIds'
  | 'address'
  | 'announcements'
  | 'autopayAccount'
  | 'autopayClientId'
  | 'autopayInstitution'
  | 'autopayStatus'
  | 'autopayStatusUpdatedZ'
  | 'autopayTransit'
  | 'billingEmail'
  | 'books'
  | 'businessNumber'
  | 'campaignApplicationsWithAction'
  | 'charges'
  | 'contactName'
  | 'enabled'
  | 'fees'
  | 'fingerprints'
  | 'id'
  | 'kyc'
  | 'kycHistory'
  | 'listActiveApprovals'
  | 'listActiveRequests'
  | 'listAllFinalizedRequests'
  | 'listAnnouncements'
  | 'listApprovals'
  | 'listAutopayEnrollments'
  | 'listAutopayProperties'
  | 'listAutopaySummaries'
  | 'listFloorplans'
  | 'listLeaseDocumentTemplates'
  | 'listOwners'
  | 'listProperties'
  | 'listPropertyFacts'
  | 'listRecentFinalizedRequests'
  | 'listRequestRecurrences'
  | 'listRequests'
  | 'listResidencies'
  | 'listResidents'
  | 'listSuppliers'
  | 'listTenants'
  | 'listUnits'
  | 'logoKey'
  | 'managers'
  | 'name'
  | 'operators'
  | 'owners'
  | 'pageOwners'
  | 'pageProperties'
  | 'pageRequests'
  | 'pageResidencies'
  | 'pageSuppliers'
  | 'pageUnits'
  | 'phone'
  | 'properties'
  | 'propertyFacts'
  | 'requests'
  | 'requestsWithAction'
  | 'settings'
  | 'stats'
  | 'suppliers'
  | 'teams'
  | 'templates'
  | 'test'
  | 'testUnits'
  | 'tier'
  | 'totalUnits'
  | AccountKeySpecifier
)[];
export type AccountFieldPolicy = {
  activatedEnrollmentIds?: FieldPolicy<any> | FieldReadFunction<any>;
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  announcements?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayClientId?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayInstitution?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatusUpdatedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayTransit?: FieldPolicy<any> | FieldReadFunction<any>;
  billingEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  books?: FieldPolicy<any> | FieldReadFunction<any>;
  businessNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  campaignApplicationsWithAction?: FieldPolicy<any> | FieldReadFunction<any>;
  charges?: FieldPolicy<any> | FieldReadFunction<any>;
  contactName?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  fees?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kyc?: FieldPolicy<any> | FieldReadFunction<any>;
  kycHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  listActiveApprovals?: FieldPolicy<any> | FieldReadFunction<any>;
  listActiveRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  listAllFinalizedRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  listAnnouncements?: FieldPolicy<any> | FieldReadFunction<any>;
  listApprovals?: FieldPolicy<any> | FieldReadFunction<any>;
  listAutopayEnrollments?: FieldPolicy<any> | FieldReadFunction<any>;
  listAutopayProperties?: FieldPolicy<any> | FieldReadFunction<any>;
  listAutopaySummaries?: FieldPolicy<any> | FieldReadFunction<any>;
  listFloorplans?: FieldPolicy<any> | FieldReadFunction<any>;
  listLeaseDocumentTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  listOwners?: FieldPolicy<any> | FieldReadFunction<any>;
  listProperties?: FieldPolicy<any> | FieldReadFunction<any>;
  listPropertyFacts?: FieldPolicy<any> | FieldReadFunction<any>;
  listRecentFinalizedRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  listRequestRecurrences?: FieldPolicy<any> | FieldReadFunction<any>;
  listRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  listResidencies?: FieldPolicy<any> | FieldReadFunction<any>;
  listResidents?: FieldPolicy<any> | FieldReadFunction<any>;
  listSuppliers?: FieldPolicy<any> | FieldReadFunction<any>;
  listTenants?: FieldPolicy<any> | FieldReadFunction<any>;
  listUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  logoKey?: FieldPolicy<any> | FieldReadFunction<any>;
  managers?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operators?: FieldPolicy<any> | FieldReadFunction<any>;
  owners?: FieldPolicy<any> | FieldReadFunction<any>;
  pageOwners?: FieldPolicy<any> | FieldReadFunction<any>;
  pageProperties?: FieldPolicy<any> | FieldReadFunction<any>;
  pageRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  pageResidencies?: FieldPolicy<any> | FieldReadFunction<any>;
  pageSuppliers?: FieldPolicy<any> | FieldReadFunction<any>;
  pageUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  properties?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyFacts?: FieldPolicy<any> | FieldReadFunction<any>;
  requests?: FieldPolicy<any> | FieldReadFunction<any>;
  requestsWithAction?: FieldPolicy<any> | FieldReadFunction<any>;
  settings?: FieldPolicy<any> | FieldReadFunction<any>;
  stats?: FieldPolicy<any> | FieldReadFunction<any>;
  suppliers?: FieldPolicy<any> | FieldReadFunction<any>;
  teams?: FieldPolicy<any> | FieldReadFunction<any>;
  templates?: FieldPolicy<any> | FieldReadFunction<any>;
  test?: FieldPolicy<any> | FieldReadFunction<any>;
  testUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  tier?: FieldPolicy<any> | FieldReadFunction<any>;
  totalUnits?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AccountDetailKeySpecifier = (
  | 'accountDetails'
  | 'accountNumber'
  | 'accountNumber4Digit'
  | 'category'
  | 'currency'
  | 'institutionNumber'
  | 'transitNumber'
  | 'type'
  | AccountDetailKeySpecifier
)[];
export type AccountDetailFieldPolicy = {
  accountDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  accountNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  accountNumber4Digit?: FieldPolicy<any> | FieldReadFunction<any>;
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  institutionNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  transitNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AccountEventKeySpecifier = (
  | 'accountId'
  | 'detail'
  | 'detailType'
  | 'id'
  | 'source'
  | 'time'
  | AccountEventKeySpecifier
)[];
export type AccountEventFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  detail?: FieldPolicy<any> | FieldReadFunction<any>;
  detailType?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
  time?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AccountFactKeySpecifier = (
  | 'accountEnabled'
  | 'accountId'
  | 'accountName'
  | 'commercialExpiring15Days'
  | 'commercialLeasedUnits'
  | 'commercialLeasesExpired'
  | 'commercialUnits'
  | 'commonAreas'
  | 'dateKey'
  | 'expired'
  | 'expiring15Days'
  | 'leasedUnits'
  | 'ownedTestUnits'
  | 'ownedUnits'
  | 'ownerUnits'
  | 'testAccount'
  | 'testUnits'
  | 'totalCommercialRent'
  | 'totalCommercialSqft'
  | 'totalRent'
  | 'totalResidentialRent'
  | 'totalResidentialSqft'
  | 'totalResidentialUnits'
  | 'totalSqft'
  | 'totalUnits'
  | AccountFactKeySpecifier
)[];
export type AccountFactFieldPolicy = {
  accountEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  accountName?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialExpiring15Days?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialLeasedUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialLeasesExpired?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  commonAreas?: FieldPolicy<any> | FieldReadFunction<any>;
  dateKey?: FieldPolicy<any> | FieldReadFunction<any>;
  expired?: FieldPolicy<any> | FieldReadFunction<any>;
  expiring15Days?: FieldPolicy<any> | FieldReadFunction<any>;
  leasedUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  ownedTestUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  ownedUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  testAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  testUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCommercialRent?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCommercialSqft?: FieldPolicy<any> | FieldReadFunction<any>;
  totalRent?: FieldPolicy<any> | FieldReadFunction<any>;
  totalResidentialRent?: FieldPolicy<any> | FieldReadFunction<any>;
  totalResidentialSqft?: FieldPolicy<any> | FieldReadFunction<any>;
  totalResidentialUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  totalSqft?: FieldPolicy<any> | FieldReadFunction<any>;
  totalUnits?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AccountFactConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | AccountFactConnectionKeySpecifier
)[];
export type AccountFactConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AccountPayloadKeySpecifier = (
  | 'account'
  | 'error'
  | 'success'
  | AccountPayloadKeySpecifier
)[];
export type AccountPayloadFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AccountStatKeySpecifier = (
  | 'accountId'
  | 'testUnits'
  | 'totalUnits'
  | AccountStatKeySpecifier
)[];
export type AccountStatFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  testUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  totalUnits?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AccountStatsKeySpecifier = (
  | 'books_gl'
  | 'operator'
  | 'owner'
  | 'property'
  | 'residency'
  | 'tenant'
  | 'unit'
  | AccountStatsKeySpecifier
)[];
export type AccountStatsFieldPolicy = {
  books_gl?: FieldPolicy<any> | FieldReadFunction<any>;
  operator?: FieldPolicy<any> | FieldReadFunction<any>;
  owner?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  residency?: FieldPolicy<any> | FieldReadFunction<any>;
  tenant?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ActivateBooksPayloadKeySpecifier = (
  | 'books'
  | 'error'
  | 'success'
  | ActivateBooksPayloadKeySpecifier
)[];
export type ActivateBooksPayloadFieldPolicy = {
  books?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdditionalProvisionsKeySpecifier = (
  | 'parking'
  | 'pets'
  | 'smoking'
  | 'storage'
  | AdditionalProvisionsKeySpecifier
)[];
export type AdditionalProvisionsFieldPolicy = {
  parking?: FieldPolicy<any> | FieldReadFunction<any>;
  pets?: FieldPolicy<any> | FieldReadFunction<any>;
  smoking?: FieldPolicy<any> | FieldReadFunction<any>;
  storage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdditionalTenantKeySpecifier = (
  | 'email'
  | 'id'
  | 'leaseholder'
  | 'name'
  | 'payeeFrac'
  | 'phone'
  | AdditionalTenantKeySpecifier
)[];
export type AdditionalTenantFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseholder?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeFrac?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AddressKeySpecifier = (
  | 'city'
  | 'country'
  | 'lat'
  | 'lng'
  | 'postal'
  | 'province'
  | 'street'
  | 'suite'
  | AddressKeySpecifier
)[];
export type AddressFieldPolicy = {
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  lat?: FieldPolicy<any> | FieldReadFunction<any>;
  lng?: FieldPolicy<any> | FieldReadFunction<any>;
  postal?: FieldPolicy<any> | FieldReadFunction<any>;
  province?: FieldPolicy<any> | FieldReadFunction<any>;
  street?: FieldPolicy<any> | FieldReadFunction<any>;
  suite?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AddressHistoryKeySpecifier = (
  | 'address'
  | 'email'
  | 'endDate'
  | 'id'
  | 'name'
  | 'phone'
  | 'rentalType'
  | 'startDate'
  | AddressHistoryKeySpecifier
)[];
export type AddressHistoryFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  endDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  rentalType?: FieldPolicy<any> | FieldReadFunction<any>;
  startDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AgreementKeySpecifier = (
  | 'agreementType'
  | 'cost'
  | 'entityId'
  | 'noteText'
  | AgreementKeySpecifier
)[];
export type AgreementFieldPolicy = {
  agreementType?: FieldPolicy<any> | FieldReadFunction<any>;
  cost?: FieldPolicy<any> | FieldReadFunction<any>;
  entityId?: FieldPolicy<any> | FieldReadFunction<any>;
  noteText?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AgreementLeaseKeySpecifier = (
  | 'autoRenewSetup'
  | 'end'
  | 'furnishings'
  | 'gst'
  | 'incentives'
  | 'moveInDate'
  | 'moveOutDate'
  | 'occupancy'
  | 'preferredPaymentMethod'
  | 'propertyId'
  | 'rateSchedule'
  | 'refundables'
  | 'rent'
  | 'rentRollFees'
  | 'rrule'
  | 'securityDeposit'
  | 'start'
  | 'tenants'
  | AgreementLeaseKeySpecifier
)[];
export type AgreementLeaseFieldPolicy = {
  autoRenewSetup?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  furnishings?: FieldPolicy<any> | FieldReadFunction<any>;
  gst?: FieldPolicy<any> | FieldReadFunction<any>;
  incentives?: FieldPolicy<any> | FieldReadFunction<any>;
  moveInDate?: FieldPolicy<any> | FieldReadFunction<any>;
  moveOutDate?: FieldPolicy<any> | FieldReadFunction<any>;
  occupancy?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredPaymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  rateSchedule?: FieldPolicy<any> | FieldReadFunction<any>;
  refundables?: FieldPolicy<any> | FieldReadFunction<any>;
  rent?: FieldPolicy<any> | FieldReadFunction<any>;
  rentRollFees?: FieldPolicy<any> | FieldReadFunction<any>;
  rrule?: FieldPolicy<any> | FieldReadFunction<any>;
  securityDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  tenants?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AgreementLeaseFeesKeySpecifier = (
  | 'amount'
  | 'feeId'
  | 'name'
  | AgreementLeaseFeesKeySpecifier
)[];
export type AgreementLeaseFeesFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  feeId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AgreementUrlKeySpecifier = (
  | 'agreementUrl'
  | 'applicationId'
  | 'profileId'
  | AgreementUrlKeySpecifier
)[];
export type AgreementUrlFieldPolicy = {
  agreementUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  applicationId?: FieldPolicy<any> | FieldReadFunction<any>;
  profileId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AmenitiesSettingKeySpecifier = ('amenities' | AmenitiesSettingKeySpecifier)[];
export type AmenitiesSettingFieldPolicy = {
  amenities?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AmenityKeySpecifier = ('category' | 'id' | 'name' | AmenityKeySpecifier)[];
export type AmenityFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AmenityCategoryKeySpecifier = ('id' | 'name' | AmenityCategoryKeySpecifier)[];
export type AmenityCategoryFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AnnouncementKeySpecifier = (
  | 'attachments'
  | 'author'
  | 'email'
  | 'fingerprints'
  | 'id'
  | 'name'
  | 'publishZ'
  | 'replies'
  | 'scope'
  | 'status'
  | 'totalRecipients'
  | 'voice'
  | AnnouncementKeySpecifier
)[];
export type AnnouncementFieldPolicy = {
  attachments?: FieldPolicy<any> | FieldReadFunction<any>;
  author?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  publishZ?: FieldPolicy<any> | FieldReadFunction<any>;
  replies?: FieldPolicy<any> | FieldReadFunction<any>;
  scope?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  totalRecipients?: FieldPolicy<any> | FieldReadFunction<any>;
  voice?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AnnouncementConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | AnnouncementConnectionKeySpecifier
)[];
export type AnnouncementConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AnnouncementEmailKeySpecifier = ('body' | 'subject' | AnnouncementEmailKeySpecifier)[];
export type AnnouncementEmailFieldPolicy = {
  body?: FieldPolicy<any> | FieldReadFunction<any>;
  subject?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AnnouncementPayloadKeySpecifier = (
  | 'announcement'
  | 'error'
  | 'success'
  | AnnouncementPayloadKeySpecifier
)[];
export type AnnouncementPayloadFieldPolicy = {
  announcement?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AnnouncementScopeKeySpecifier = (
  | 'omitIds'
  | 'propertyIds'
  | 'unitIds'
  | AnnouncementScopeKeySpecifier
)[];
export type AnnouncementScopeFieldPolicy = {
  omitIds?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyIds?: FieldPolicy<any> | FieldReadFunction<any>;
  unitIds?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AnnouncementVoiceKeySpecifier = ('text' | AnnouncementVoiceKeySpecifier)[];
export type AnnouncementVoiceFieldPolicy = {
  text?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ApplicationKeySpecifier = (
  | 'accountId'
  | 'additionalInformation'
  | 'addressHistory'
  | 'applicationId'
  | 'approvedZ'
  | 'campaignId'
  | 'coApplications'
  | 'declinedZ'
  | 'dob'
  | 'documents'
  | 'emails'
  | 'emergencyContacts'
  | 'flag'
  | 'household'
  | 'id'
  | 'leaseAgreement'
  | 'leaseId'
  | 'listedUnit'
  | 'listedUnitId'
  | 'message'
  | 'moveInDate'
  | 'name'
  | 'needsAgreement'
  | 'notes'
  | 'otherIncome'
  | 'phone'
  | 'profileId'
  | 'residencyId'
  | 'screeningAgreement'
  | 'status'
  | 'submittedZ'
  | 'workHistory'
  | ApplicationKeySpecifier
)[];
export type ApplicationFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  additionalInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  addressHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  applicationId?: FieldPolicy<any> | FieldReadFunction<any>;
  approvedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  campaignId?: FieldPolicy<any> | FieldReadFunction<any>;
  coApplications?: FieldPolicy<any> | FieldReadFunction<any>;
  declinedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  dob?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  emails?: FieldPolicy<any> | FieldReadFunction<any>;
  emergencyContacts?: FieldPolicy<any> | FieldReadFunction<any>;
  flag?: FieldPolicy<any> | FieldReadFunction<any>;
  household?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseId?: FieldPolicy<any> | FieldReadFunction<any>;
  listedUnit?: FieldPolicy<any> | FieldReadFunction<any>;
  listedUnitId?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  moveInDate?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  needsAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  otherIncome?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  profileId?: FieldPolicy<any> | FieldReadFunction<any>;
  residencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  screeningAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  workHistory?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ApplicationPayloadKeySpecifier = (
  | 'application'
  | 'error'
  | 'success'
  | ApplicationPayloadKeySpecifier
)[];
export type ApplicationPayloadFieldPolicy = {
  application?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AppointmentChangeKeySpecifier = (
  | 'appointmentPreferences'
  | 'fingerprints'
  | 'id'
  | 'isRead'
  | 'reason'
  | 'type'
  | AppointmentChangeKeySpecifier
)[];
export type AppointmentChangeFieldPolicy = {
  appointmentPreferences?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isRead?: FieldPolicy<any> | FieldReadFunction<any>;
  reason?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ApprovalKeySpecifier = (
  | 'accountId'
  | 'approvedSub'
  | 'approvedZ'
  | 'approverRoles'
  | 'charge'
  | 'declinedSub'
  | 'declinedZ'
  | 'id'
  | 'request'
  | 'requestedName'
  | 'requestedSub'
  | 'requestedZ'
  | 'status'
  | ApprovalKeySpecifier
)[];
export type ApprovalFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  approvedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  approvedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  approverRoles?: FieldPolicy<any> | FieldReadFunction<any>;
  charge?: FieldPolicy<any> | FieldReadFunction<any>;
  declinedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  declinedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  request?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedName?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ApprovalConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | ApprovalConnectionKeySpecifier
)[];
export type ApprovalConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ApprovalPayloadKeySpecifier = (
  | 'approval'
  | 'error'
  | 'success'
  | ApprovalPayloadKeySpecifier
)[];
export type ApprovalPayloadFieldPolicy = {
  approval?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ApprovalRecordKeySpecifier = (
  | 'accountId'
  | 'approvedSub'
  | 'approvedZ'
  | 'approverRoles'
  | 'declinedSub'
  | 'declinedZ'
  | 'id'
  | 'requestedName'
  | 'requestedSub'
  | 'requestedZ'
  | 'status'
  | ApprovalRecordKeySpecifier
)[];
export type ApprovalRecordFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  approvedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  approvedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  approverRoles?: FieldPolicy<any> | FieldReadFunction<any>;
  declinedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  declinedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedName?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AssetKeySpecifier = (
  | 'accountId'
  | 'assetType'
  | 'id'
  | 'permissions'
  | 'role'
  | 'shared'
  | AssetKeySpecifier
)[];
export type AssetFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  assetType?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  permissions?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
  shared?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AssetFeeKeySpecifier = (
  | 'assetId'
  | 'feeAmount'
  | 'feeRate'
  | 'flatFee'
  | 'nrtFeeAmount'
  | 'nrtFeeRate'
  | 'type'
  | AssetFeeKeySpecifier
)[];
export type AssetFeeFieldPolicy = {
  assetId?: FieldPolicy<any> | FieldReadFunction<any>;
  feeAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  feeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  flatFee?: FieldPolicy<any> | FieldReadFunction<any>;
  nrtFeeAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  nrtFeeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AssetShareKeySpecifier = (
  | 'assets'
  | 'id'
  | 'profile'
  | 'profileId'
  | AssetShareKeySpecifier
)[];
export type AssetShareFieldPolicy = {
  assets?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  profile?: FieldPolicy<any> | FieldReadFunction<any>;
  profileId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AssignKeyringPayloadKeySpecifier = (
  | 'property'
  | 'success'
  | 'unit'
  | AssignKeyringPayloadKeySpecifier
)[];
export type AssignKeyringPayloadFieldPolicy = {
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AssignParkingPayloadKeySpecifier = (
  | 'property'
  | 'success'
  | 'unit'
  | AssignParkingPayloadKeySpecifier
)[];
export type AssignParkingPayloadFieldPolicy = {
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AssignStoragePayloadKeySpecifier = (
  | 'property'
  | 'success'
  | 'unit'
  | AssignStoragePayloadKeySpecifier
)[];
export type AssignStoragePayloadFieldPolicy = {
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AttachmentKeySpecifier = ('key' | 'name' | 'type' | AttachmentKeySpecifier)[];
export type AttachmentFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutoRenewSetupKeySpecifier = ('enabled' | 'termMonths' | AutoRenewSetupKeySpecifier)[];
export type AutoRenewSetupFieldPolicy = {
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  termMonths?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutopayKeySpecifier = (
  | 'accountId'
  | 'activated'
  | 'activatedZ'
  | 'activationStatus'
  | 'activationUrl'
  | 'activationUrlExpireZ'
  | 'agreeToTermsSub'
  | 'agreeToTermsZ'
  | 'banks'
  | 'collectionTypes'
  | 'deactivatedZ'
  | 'enableCollection'
  | 'enablePayments'
  | 'feeBankAccountId'
  | 'feeExpense'
  | 'feeInvoiceChargeMethod'
  | 'invitePayees'
  | 'linkedAccounts'
  | 'nsfFee'
  | 'pageOwnerAutopayPayees'
  | 'pageTenantAutopayPayees'
  | 'propraSupplier'
  | 'undepositedFunds'
  | 'version'
  | 'vopay'
  | AutopayKeySpecifier
)[];
export type AutopayFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  activated?: FieldPolicy<any> | FieldReadFunction<any>;
  activatedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  activationStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  activationUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  activationUrlExpireZ?: FieldPolicy<any> | FieldReadFunction<any>;
  agreeToTermsSub?: FieldPolicy<any> | FieldReadFunction<any>;
  agreeToTermsZ?: FieldPolicy<any> | FieldReadFunction<any>;
  banks?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivatedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  enableCollection?: FieldPolicy<any> | FieldReadFunction<any>;
  enablePayments?: FieldPolicy<any> | FieldReadFunction<any>;
  feeBankAccountId?: FieldPolicy<any> | FieldReadFunction<any>;
  feeExpense?: FieldPolicy<any> | FieldReadFunction<any>;
  feeInvoiceChargeMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  invitePayees?: FieldPolicy<any> | FieldReadFunction<any>;
  linkedAccounts?: FieldPolicy<any> | FieldReadFunction<any>;
  nsfFee?: FieldPolicy<any> | FieldReadFunction<any>;
  pageOwnerAutopayPayees?: FieldPolicy<any> | FieldReadFunction<any>;
  pageTenantAutopayPayees?: FieldPolicy<any> | FieldReadFunction<any>;
  propraSupplier?: FieldPolicy<any> | FieldReadFunction<any>;
  undepositedFunds?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
  vopay?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutopayEnrollmentKeySpecifier = (
  | 'archivedZ'
  | 'collectionType'
  | 'id'
  | 'invitedZ'
  | 'leaseId'
  | 'payeeId'
  | 'payeeType'
  | 'profileId'
  | 'propertyId'
  | 'residencyId'
  | 'status'
  | 'statusHistory'
  | 'unitId'
  | AutopayEnrollmentKeySpecifier
)[];
export type AutopayEnrollmentFieldPolicy = {
  archivedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionType?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  invitedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseId?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeId?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeType?: FieldPolicy<any> | FieldReadFunction<any>;
  profileId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  residencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutopayEnrollmentConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | AutopayEnrollmentConnectionKeySpecifier
)[];
export type AutopayEnrollmentConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutopayEnrollmentPayloadKeySpecifier = (
  | 'enrollment'
  | 'error'
  | 'success'
  | AutopayEnrollmentPayloadKeySpecifier
)[];
export type AutopayEnrollmentPayloadFieldPolicy = {
  enrollment?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutopayEnrollmentStatusHistoryKeySpecifier = (
  | 'createdZ'
  | 'description'
  | 'status'
  | AutopayEnrollmentStatusHistoryKeySpecifier
)[];
export type AutopayEnrollmentStatusHistoryFieldPolicy = {
  createdZ?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutopayOnboardingUrlPayloadKeySpecifier = (
  | 'error'
  | 'success'
  | 'url'
  | AutopayOnboardingUrlPayloadKeySpecifier
)[];
export type AutopayOnboardingUrlPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutopayPayeeKeySpecifier = (
  | 'autopayCollectionsEnabled'
  | 'autopayCollectionsEnabledZ'
  | 'autopayStatus'
  | 'autopayStatusZ'
  | 'email'
  | 'id'
  | 'name'
  | 'payeeType'
  | 'propertyUnits'
  | AutopayPayeeKeySpecifier
)[];
export type AutopayPayeeFieldPolicy = {
  autopayCollectionsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayCollectionsEnabledZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatusZ?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeType?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyUnits?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutopayPayeeEdgeKeySpecifier = ('cursor' | 'node' | AutopayPayeeEdgeKeySpecifier)[];
export type AutopayPayeeEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutopayPayeePageKeySpecifier = (
  | 'edges'
  | 'pageInfo'
  | 'totalCount'
  | AutopayPayeePageKeySpecifier
)[];
export type AutopayPayeePageFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutopayPayeePropertyUnitKeySpecifier = (
  | 'buildingId'
  | 'buildingName'
  | 'propertyId'
  | 'propertyName'
  | 'residencyId'
  | 'unitId'
  | 'unitName'
  | AutopayPayeePropertyUnitKeySpecifier
)[];
export type AutopayPayeePropertyUnitFieldPolicy = {
  buildingId?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingName?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyName?: FieldPolicy<any> | FieldReadFunction<any>;
  residencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutopayStatusHistoryKeySpecifier = (
  | 'status'
  | 'z'
  | AutopayStatusHistoryKeySpecifier
)[];
export type AutopayStatusHistoryFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  z?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AutopaySummaryConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | AutopaySummaryConnectionKeySpecifier
)[];
export type AutopaySummaryConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BankKeySpecifier = (
  | 'accountNumber'
  | 'id'
  | 'institutionNumber'
  | 'name'
  | 'transitNumber'
  | BankKeySpecifier
)[];
export type BankFieldPolicy = {
  accountNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  institutionNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  transitNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BatchKeySpecifier = (
  | 'clearables'
  | 'errorMessage'
  | 'id'
  | 'itemCount'
  | 'journalEntries'
  | 'listPrototypes'
  | 'name'
  | 'posted'
  | 'presetId'
  | 'status'
  | BatchKeySpecifier
)[];
export type BatchFieldPolicy = {
  clearables?: FieldPolicy<any> | FieldReadFunction<any>;
  errorMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  itemCount?: FieldPolicy<any> | FieldReadFunction<any>;
  journalEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  listPrototypes?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
  presetId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BatchPrototypePayloadKeySpecifier = (
  | 'error'
  | 'prototype'
  | 'success'
  | BatchPrototypePayloadKeySpecifier
)[];
export type BatchPrototypePayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  prototype?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BatchTaskKeySpecifier = ('id' | 'taskProgress' | BatchTaskKeySpecifier)[];
export type BatchTaskFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  taskProgress?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BatchTaskPayloadKeySpecifier = (
  | 'error'
  | 'success'
  | 'task'
  | BatchTaskPayloadKeySpecifier
)[];
export type BatchTaskPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  task?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BatchTaskProgressKeySpecifier = (
  | 'done'
  | 'label'
  | 'processed'
  | 'total'
  | BatchTaskProgressKeySpecifier
)[];
export type BatchTaskProgressFieldPolicy = {
  done?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  processed?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BillingAccountKeySpecifier = (
  | 'address'
  | 'domainId'
  | 'email'
  | 'id'
  | 'name'
  | BillingAccountKeySpecifier
)[];
export type BillingAccountFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  domainId?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BillingRateKeySpecifier = (
  | 'category'
  | 'enabled'
  | 'glId'
  | 'labourRate'
  | 'materialsMarkup'
  | 'minBillableMinutes'
  | 'requireApproval'
  | 'trackRevenue'
  | BillingRateKeySpecifier
)[];
export type BillingRateFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  labourRate?: FieldPolicy<any> | FieldReadFunction<any>;
  materialsMarkup?: FieldPolicy<any> | FieldReadFunction<any>;
  minBillableMinutes?: FieldPolicy<any> | FieldReadFunction<any>;
  requireApproval?: FieldPolicy<any> | FieldReadFunction<any>;
  trackRevenue?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BooksKeySpecifier = (
  | 'accountId'
  | 'activatedZ'
  | 'autopay'
  | 'batchTask'
  | 'batches'
  | 'billableRevenueFeeIds'
  | 'billingRates'
  | 'bundledReports'
  | 'chargeMapping'
  | 'chequePlacement'
  | 'cheques'
  | 'clearablesById'
  | 'collections'
  | 'countClearables'
  | 'customBillingRates'
  | 'editableGlAccounts'
  | 'feeMapping'
  | 'getBudgets'
  | 'glAccountMapping'
  | 'glAccounts'
  | 'gstFiling'
  | 'gstNumber'
  | 'includeDisabledProperties'
  | 'incomePayoutOptions'
  | 'journalEntriesForJE'
  | 'journalEntriesForPayeeRef'
  | 'lastPrintedGlId'
  | 'listCheques'
  | 'listClearables'
  | 'listJournalEntriesForReconciliation'
  | 'listOwners'
  | 'listReconciliationConfigs'
  | 'listReconciliations'
  | 'listSubAccounts'
  | 'managementFeeBasis'
  | 'nextCheque'
  | 'nextInvoice'
  | 'nextJournal'
  | 'pageClearables'
  | 'pageDraftedJournalEntries'
  | 'pageJournalEntriesForReconciliation'
  | 'payeeClearables'
  | 'presets'
  | 'previewCheques'
  | 'printedCheques'
  | 'printedZ'
  | 'receiverGeneralId'
  | 'reconciliationConfigs'
  | 'reconciliations'
  | 'recurrences'
  | 'renderReport'
  | 'report'
  | 'reportBundles'
  | 'searchRelatedUnits'
  | 'trackManagementFeeRevenue'
  | 'unitClearables'
  | 'yearEndMonth'
  | BooksKeySpecifier
)[];
export type BooksFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  activatedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopay?: FieldPolicy<any> | FieldReadFunction<any>;
  batchTask?: FieldPolicy<any> | FieldReadFunction<any>;
  batches?: FieldPolicy<any> | FieldReadFunction<any>;
  billableRevenueFeeIds?: FieldPolicy<any> | FieldReadFunction<any>;
  billingRates?: FieldPolicy<any> | FieldReadFunction<any>;
  bundledReports?: FieldPolicy<any> | FieldReadFunction<any>;
  chargeMapping?: FieldPolicy<any> | FieldReadFunction<any>;
  chequePlacement?: FieldPolicy<any> | FieldReadFunction<any>;
  cheques?: FieldPolicy<any> | FieldReadFunction<any>;
  clearablesById?: FieldPolicy<any> | FieldReadFunction<any>;
  collections?: FieldPolicy<any> | FieldReadFunction<any>;
  countClearables?: FieldPolicy<any> | FieldReadFunction<any>;
  customBillingRates?: FieldPolicy<any> | FieldReadFunction<any>;
  editableGlAccounts?: FieldPolicy<any> | FieldReadFunction<any>;
  feeMapping?: FieldPolicy<any> | FieldReadFunction<any>;
  getBudgets?: FieldPolicy<any> | FieldReadFunction<any>;
  glAccountMapping?: FieldPolicy<any> | FieldReadFunction<any>;
  glAccounts?: FieldPolicy<any> | FieldReadFunction<any>;
  gstFiling?: FieldPolicy<any> | FieldReadFunction<any>;
  gstNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  includeDisabledProperties?: FieldPolicy<any> | FieldReadFunction<any>;
  incomePayoutOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  journalEntriesForJE?: FieldPolicy<any> | FieldReadFunction<any>;
  journalEntriesForPayeeRef?: FieldPolicy<any> | FieldReadFunction<any>;
  lastPrintedGlId?: FieldPolicy<any> | FieldReadFunction<any>;
  listCheques?: FieldPolicy<any> | FieldReadFunction<any>;
  listClearables?: FieldPolicy<any> | FieldReadFunction<any>;
  listJournalEntriesForReconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  listOwners?: FieldPolicy<any> | FieldReadFunction<any>;
  listReconciliationConfigs?: FieldPolicy<any> | FieldReadFunction<any>;
  listReconciliations?: FieldPolicy<any> | FieldReadFunction<any>;
  listSubAccounts?: FieldPolicy<any> | FieldReadFunction<any>;
  managementFeeBasis?: FieldPolicy<any> | FieldReadFunction<any>;
  nextCheque?: FieldPolicy<any> | FieldReadFunction<any>;
  nextInvoice?: FieldPolicy<any> | FieldReadFunction<any>;
  nextJournal?: FieldPolicy<any> | FieldReadFunction<any>;
  pageClearables?: FieldPolicy<any> | FieldReadFunction<any>;
  pageDraftedJournalEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  pageJournalEntriesForReconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeClearables?: FieldPolicy<any> | FieldReadFunction<any>;
  presets?: FieldPolicy<any> | FieldReadFunction<any>;
  previewCheques?: FieldPolicy<any> | FieldReadFunction<any>;
  printedCheques?: FieldPolicy<any> | FieldReadFunction<any>;
  printedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  receiverGeneralId?: FieldPolicy<any> | FieldReadFunction<any>;
  reconciliationConfigs?: FieldPolicy<any> | FieldReadFunction<any>;
  reconciliations?: FieldPolicy<any> | FieldReadFunction<any>;
  recurrences?: FieldPolicy<any> | FieldReadFunction<any>;
  renderReport?: FieldPolicy<any> | FieldReadFunction<any>;
  report?: FieldPolicy<any> | FieldReadFunction<any>;
  reportBundles?: FieldPolicy<any> | FieldReadFunction<any>;
  searchRelatedUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  trackManagementFeeRevenue?: FieldPolicy<any> | FieldReadFunction<any>;
  unitClearables?: FieldPolicy<any> | FieldReadFunction<any>;
  yearEndMonth?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BooksActivatedPayloadKeySpecifier = (
  | 'anyActivated'
  | BooksActivatedPayloadKeySpecifier
)[];
export type BooksActivatedPayloadFieldPolicy = {
  anyActivated?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BooksBatchPayloadKeySpecifier = (
  | 'batch'
  | 'error'
  | 'success'
  | BooksBatchPayloadKeySpecifier
)[];
export type BooksBatchPayloadFieldPolicy = {
  batch?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BooksPayloadKeySpecifier = ('books' | 'error' | 'success' | BooksPayloadKeySpecifier)[];
export type BooksPayloadFieldPolicy = {
  books?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BooksPrintedChequeKeySpecifier = (
  | 'chequePlacement'
  | 'glId'
  | 'nextCheque'
  | 'printedZ'
  | BooksPrintedChequeKeySpecifier
)[];
export type BooksPrintedChequeFieldPolicy = {
  chequePlacement?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  nextCheque?: FieldPolicy<any> | FieldReadFunction<any>;
  printedZ?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BooksReportKeySpecifier = (
  | 'grandTotals'
  | 'id'
  | 'options'
  | 'ownerId'
  | 'propertyId'
  | 'range'
  | 'subtitle1'
  | 'subtitle2'
  | 'title'
  | BooksReportKeySpecifier
)[];
export type BooksReportFieldPolicy = {
  grandTotals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  options?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  range?: FieldPolicy<any> | FieldReadFunction<any>;
  subtitle1?: FieldPolicy<any> | FieldReadFunction<any>;
  subtitle2?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BooksReportExtraColumnKeySpecifier = (
  | 'columnHeader'
  | 'columnType'
  | 'flex'
  | 'keepNonZero'
  | 'maxWidth'
  | 'value'
  | BooksReportExtraColumnKeySpecifier
)[];
export type BooksReportExtraColumnFieldPolicy = {
  columnHeader?: FieldPolicy<any> | FieldReadFunction<any>;
  columnType?: FieldPolicy<any> | FieldReadFunction<any>;
  flex?: FieldPolicy<any> | FieldReadFunction<any>;
  keepNonZero?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWidth?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BooksReportFooterKeySpecifier = ('label' | BooksReportFooterKeySpecifier)[];
export type BooksReportFooterFieldPolicy = {
  label?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BooksReportHeaderKeySpecifier = ('label' | BooksReportHeaderKeySpecifier)[];
export type BooksReportHeaderFieldPolicy = {
  label?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BooksReportOptionsKeySpecifier = (
  | 'hideAmountColumn'
  | 'showZeroAmounts'
  | BooksReportOptionsKeySpecifier
)[];
export type BooksReportOptionsFieldPolicy = {
  hideAmountColumn?: FieldPolicy<any> | FieldReadFunction<any>;
  showZeroAmounts?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BooksReportPayloadKeySpecifier = (
  | 'docDefinition'
  | 'error'
  | 'report'
  | 'success'
  | BooksReportPayloadKeySpecifier
)[];
export type BooksReportPayloadFieldPolicy = {
  docDefinition?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  report?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BooksReportRenderedKeySpecifier = (
  | 'action'
  | 'amount'
  | 'balanceType'
  | 'columnHeader'
  | 'date'
  | 'extraColumns'
  | 'footer'
  | 'header'
  | 'label'
  | 'lines'
  | 'maxWidth'
  | 'order'
  | BooksReportRenderedKeySpecifier
)[];
export type BooksReportRenderedFieldPolicy = {
  action?: FieldPolicy<any> | FieldReadFunction<any>;
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  balanceType?: FieldPolicy<any> | FieldReadFunction<any>;
  columnHeader?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  extraColumns?: FieldPolicy<any> | FieldReadFunction<any>;
  footer?: FieldPolicy<any> | FieldReadFunction<any>;
  header?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  lines?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWidth?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BudgetKeySpecifier = (
  | 'amount'
  | 'glId'
  | 'ownerId'
  | 'period'
  | 'propertyId'
  | BudgetKeySpecifier
)[];
export type BudgetFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  period?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BudgetsPayloadKeySpecifier = (
  | 'budgets'
  | 'error'
  | 'success'
  | BudgetsPayloadKeySpecifier
)[];
export type BudgetsPayloadFieldPolicy = {
  budgets?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BuildingKeySpecifier = (
  | 'accountId'
  | 'address'
  | 'amenitiesSetting'
  | 'documents'
  | 'features'
  | 'fingerprints'
  | 'floorplanIds'
  | 'floorplans'
  | 'id'
  | 'imageKey'
  | 'imageUrl'
  | 'insurancePolicy'
  | 'insurancePolicyHistory'
  | 'key'
  | 'legalDescription'
  | 'name'
  | 'notes'
  | 'ownerId'
  | 'phone'
  | 'specs'
  | 'timezone'
  | 'types'
  | BuildingKeySpecifier
)[];
export type BuildingFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  amenitiesSetting?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  features?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  floorplanIds?: FieldPolicy<any> | FieldReadFunction<any>;
  floorplans?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageKey?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  insurancePolicy?: FieldPolicy<any> | FieldReadFunction<any>;
  insurancePolicyHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  legalDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  specs?: FieldPolicy<any> | FieldReadFunction<any>;
  timezone?: FieldPolicy<any> | FieldReadFunction<any>;
  types?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BundledReportKeySpecifier = (
  | 'bundleId'
  | 'completedZ'
  | 'flag'
  | 'id'
  | 'inputBindings'
  | 'isShared'
  | 'key'
  | 'managerId'
  | 'name'
  | 'startedZ'
  | 'status'
  | 'ttl'
  | 'xlsx'
  | BundledReportKeySpecifier
)[];
export type BundledReportFieldPolicy = {
  bundleId?: FieldPolicy<any> | FieldReadFunction<any>;
  completedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  flag?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inputBindings?: FieldPolicy<any> | FieldReadFunction<any>;
  isShared?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  managerId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  startedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  ttl?: FieldPolicy<any> | FieldReadFunction<any>;
  xlsx?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BundledReportPayloadKeySpecifier = (
  | 'bundledReport'
  | 'error'
  | 'success'
  | BundledReportPayloadKeySpecifier
)[];
export type BundledReportPayloadFieldPolicy = {
  bundledReport?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CampaignKeySpecifier = (
  | 'accountId'
  | 'applications'
  | 'fingerprints'
  | 'history'
  | 'id'
  | 'link'
  | 'listedUnits'
  | 'name'
  | 'property'
  | 'propertyId'
  | 'status'
  | 'systemCreated'
  | CampaignKeySpecifier
)[];
export type CampaignFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  applications?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  history?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  link?: FieldPolicy<any> | FieldReadFunction<any>;
  listedUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  systemCreated?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CampaignApplicationDocRuleKeySpecifier = (
  | 'docTypes'
  | 'quantity'
  | CampaignApplicationDocRuleKeySpecifier
)[];
export type CampaignApplicationDocRuleFieldPolicy = {
  docTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  quantity?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CampaignApplicationRuleKeySpecifier = (
  | 'requiredDocs'
  | CampaignApplicationRuleKeySpecifier
)[];
export type CampaignApplicationRuleFieldPolicy = {
  requiredDocs?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CampaignConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | CampaignConnectionKeySpecifier
)[];
export type CampaignConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CampaignPayloadKeySpecifier = (
  | 'campaign'
  | 'error'
  | 'success'
  | CampaignPayloadKeySpecifier
)[];
export type CampaignPayloadFieldPolicy = {
  campaign?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChargeKeySpecifier = ('default' | 'id' | 'limit' | 'name' | ChargeKeySpecifier)[];
export type ChargeFieldPolicy = {
  default?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  limit?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChargeApprovalRequestKeySpecifier = (
  | 'amount'
  | 'applyGst'
  | 'applyPst'
  | 'buildingName'
  | 'chargeId'
  | 'date'
  | 'note'
  | 'notifyPayee'
  | 'ownerId'
  | 'ownerName'
  | 'payee'
  | 'payeeId'
  | 'payeeName'
  | 'propertyId'
  | 'propertyKey'
  | 'propertyName'
  | 'unitId'
  | 'unitName'
  | ChargeApprovalRequestKeySpecifier
)[];
export type ChargeApprovalRequestFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  applyGst?: FieldPolicy<any> | FieldReadFunction<any>;
  applyPst?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingName?: FieldPolicy<any> | FieldReadFunction<any>;
  chargeId?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  note?: FieldPolicy<any> | FieldReadFunction<any>;
  notifyPayee?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerName?: FieldPolicy<any> | FieldReadFunction<any>;
  payee?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeId?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeName?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyKey?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyName?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChargeMappingKeySpecifier = ('chargeId' | 'glId' | ChargeMappingKeySpecifier)[];
export type ChargeMappingFieldPolicy = {
  chargeId?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChecklistItemKeySpecifier = (
  | 'completedZ'
  | 'id'
  | 'lastUpdatedZ'
  | 'name'
  | 'order'
  | ChecklistItemKeySpecifier
)[];
export type ChecklistItemFieldPolicy = {
  completedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastUpdatedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChequePayloadKeySpecifier = (
  | 'books'
  | 'error'
  | 'glId'
  | 'journalEntries'
  | 'success'
  | ChequePayloadKeySpecifier
)[];
export type ChequePayloadFieldPolicy = {
  books?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  journalEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClaimKeySpecifier = (
  | 'amount'
  | 'date'
  | 'description'
  | 'fingerprints'
  | 'id'
  | 'status'
  | 'type'
  | ClaimKeySpecifier
)[];
export type ClaimFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClaimPayloadKeySpecifier = (
  | 'error'
  | 'property'
  | 'success'
  | ClaimPayloadKeySpecifier
)[];
export type ClaimPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClearableKeySpecifier = (
  | 'amount'
  | 'approverName'
  | 'autopayCollectionsEnabled'
  | 'autopaySinglePosted'
  | 'autopaySingleProfileId'
  | 'balanceType'
  | 'batchId'
  | 'buildingKey'
  | 'buildingName'
  | 'chargeback'
  | 'cleared'
  | 'description'
  | 'documents'
  | 'due'
  | 'enrollment'
  | 'enrollmentId'
  | 'fixedEnrollmentId'
  | 'glAccount'
  | 'glId'
  | 'id'
  | 'journalEntries'
  | 'notes'
  | 'outstanding'
  | 'ownerId'
  | 'ownerName'
  | 'payee'
  | 'payeeAutopayClientId'
  | 'payeeAutopayCollectionsEnabled'
  | 'payeeAutopayStatus'
  | 'payeeId'
  | 'payeeName'
  | 'paymentsStatus'
  | 'posted'
  | 'propertyId'
  | 'propertyKey'
  | 'propertyName'
  | 'ref'
  | 'refund'
  | 'sourceJournalEntry'
  | 'sourceJournalId'
  | 'supplierAccount'
  | 'unitId'
  | 'unitName'
  | ClearableKeySpecifier
)[];
export type ClearableFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  approverName?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayCollectionsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  autopaySinglePosted?: FieldPolicy<any> | FieldReadFunction<any>;
  autopaySingleProfileId?: FieldPolicy<any> | FieldReadFunction<any>;
  balanceType?: FieldPolicy<any> | FieldReadFunction<any>;
  batchId?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingKey?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingName?: FieldPolicy<any> | FieldReadFunction<any>;
  chargeback?: FieldPolicy<any> | FieldReadFunction<any>;
  cleared?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  due?: FieldPolicy<any> | FieldReadFunction<any>;
  enrollment?: FieldPolicy<any> | FieldReadFunction<any>;
  enrollmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  fixedEnrollmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  glAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  journalEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  outstanding?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerName?: FieldPolicy<any> | FieldReadFunction<any>;
  payee?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeAutopayClientId?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeAutopayCollectionsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeAutopayStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeId?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeName?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentsStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyKey?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyName?: FieldPolicy<any> | FieldReadFunction<any>;
  ref?: FieldPolicy<any> | FieldReadFunction<any>;
  refund?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceJournalEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceJournalId?: FieldPolicy<any> | FieldReadFunction<any>;
  supplierAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClearableConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | ClearableConnectionKeySpecifier
)[];
export type ClearableConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClearableCountKeySpecifier = (
  | 'amountTotal'
  | 'outstandingTotal'
  | 'rowCount'
  | ClearableCountKeySpecifier
)[];
export type ClearableCountFieldPolicy = {
  amountTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  outstandingTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  rowCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClearableEdgeKeySpecifier = ('cursor' | 'node' | ClearableEdgeKeySpecifier)[];
export type ClearableEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClearablePayloadKeySpecifier = (
  | 'books'
  | 'clearable'
  | 'error'
  | 'recurrence'
  | 'success'
  | ClearablePayloadKeySpecifier
)[];
export type ClearablePayloadFieldPolicy = {
  books?: FieldPolicy<any> | FieldReadFunction<any>;
  clearable?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  recurrence?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClearablesPageKeySpecifier = (
  | 'creditAmount'
  | 'creditOutstanding'
  | 'debitAmount'
  | 'debitOutstanding'
  | 'edges'
  | 'pageInfo'
  | 'totalAmount'
  | 'totalCount'
  | 'totalOutstanding'
  | ClearablesPageKeySpecifier
)[];
export type ClearablesPageFieldPolicy = {
  creditAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  creditOutstanding?: FieldPolicy<any> | FieldReadFunction<any>;
  debitAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  debitOutstanding?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalOutstanding?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClearablesPayloadKeySpecifier = (
  | 'clearables'
  | 'error'
  | 'success'
  | ClearablesPayloadKeySpecifier
)[];
export type ClearablesPayloadFieldPolicy = {
  clearables?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CoApplicationKeySpecifier = (
  | 'accountId'
  | 'additionalInformation'
  | 'addressHistory'
  | 'applicationId'
  | 'campaignId'
  | 'dob'
  | 'documents'
  | 'emails'
  | 'emergencyContacts'
  | 'household'
  | 'id'
  | 'leaseAgreement'
  | 'listedUnitId'
  | 'message'
  | 'name'
  | 'otherIncome'
  | 'phone'
  | 'profileId'
  | 'screeningAgreement'
  | 'submittedZ'
  | 'workHistory'
  | CoApplicationKeySpecifier
)[];
export type CoApplicationFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  additionalInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  addressHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  applicationId?: FieldPolicy<any> | FieldReadFunction<any>;
  campaignId?: FieldPolicy<any> | FieldReadFunction<any>;
  dob?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  emails?: FieldPolicy<any> | FieldReadFunction<any>;
  emergencyContacts?: FieldPolicy<any> | FieldReadFunction<any>;
  household?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  listedUnitId?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  otherIncome?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  profileId?: FieldPolicy<any> | FieldReadFunction<any>;
  screeningAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  workHistory?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CoApplicationLeaseAgreementKeySpecifier = (
  | 'id'
  | 'status'
  | CoApplicationLeaseAgreementKeySpecifier
)[];
export type CoApplicationLeaseAgreementFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CollectionsKeySpecifier = (
  | 'current'
  | 'delinquent'
  | 'due'
  | 'late'
  | 'overdue'
  | 'total'
  | CollectionsKeySpecifier
)[];
export type CollectionsFieldPolicy = {
  current?: FieldPolicy<any> | FieldReadFunction<any>;
  delinquent?: FieldPolicy<any> | FieldReadFunction<any>;
  due?: FieldPolicy<any> | FieldReadFunction<any>;
  late?: FieldPolicy<any> | FieldReadFunction<any>;
  overdue?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CombinedSubAccountKeySpecifier = (
  | 'balance'
  | 'journalEntries'
  | CombinedSubAccountKeySpecifier
)[];
export type CombinedSubAccountFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  journalEntries?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CondoUnitFeeKeySpecifier = ('amount' | 'feeId' | CondoUnitFeeKeySpecifier)[];
export type CondoUnitFeeFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  feeId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContactKeySpecifier = (
  | 'accountId'
  | 'address'
  | 'emails'
  | 'entityId'
  | 'fingerprints'
  | 'id'
  | 'name'
  | 'phones'
  | 'type'
  | ContactKeySpecifier
)[];
export type ContactFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  emails?: FieldPolicy<any> | FieldReadFunction<any>;
  entityId?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phones?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContactPayloadKeySpecifier = (
  | 'contact'
  | 'error'
  | 'success'
  | ContactPayloadKeySpecifier
)[];
export type ContactPayloadFieldPolicy = {
  contact?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContractorBillKeySpecifier = (
  | 'date'
  | 'due'
  | 'gstAmount'
  | 'key'
  | 'ref'
  | 'totalAmount'
  | ContractorBillKeySpecifier
)[];
export type ContractorBillFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  due?: FieldPolicy<any> | FieldReadFunction<any>;
  gstAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  ref?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CostLineKeySpecifier = (
  | 'amount'
  | 'date'
  | 'description'
  | 'id'
  | 'markup'
  | 'receiptImages'
  | 'receiptKey'
  | 'total'
  | 'visitNumber'
  | CostLineKeySpecifier
)[];
export type CostLineFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markup?: FieldPolicy<any> | FieldReadFunction<any>;
  receiptImages?: FieldPolicy<any> | FieldReadFunction<any>;
  receiptKey?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
  visitNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateApplicationNotePayloadKeySpecifier = (
  | 'application'
  | 'error'
  | 'success'
  | CreateApplicationNotePayloadKeySpecifier
)[];
export type CreateApplicationNotePayloadFieldPolicy = {
  application?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateAutopayEmbedUrlPayloadKeySpecifier = (
  | 'error'
  | 'iframeKey'
  | 'success'
  | 'url'
  | CreateAutopayEmbedUrlPayloadKeySpecifier
)[];
export type CreateAutopayEmbedUrlPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  iframeKey?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateBuildingPayloadKeySpecifier = (
  | 'building'
  | 'error'
  | 'property'
  | 'success'
  | 'unitNames'
  | CreateBuildingPayloadKeySpecifier
)[];
export type CreateBuildingPayloadFieldPolicy = {
  building?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  unitNames?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateCampaignPayloadKeySpecifier = (
  | 'campaign'
  | 'error'
  | 'success'
  | CreateCampaignPayloadKeySpecifier
)[];
export type CreateCampaignPayloadFieldPolicy = {
  campaign?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateDraftJEBatchPayloadKeySpecifier = (
  | 'batch'
  | 'error'
  | 'success'
  | CreateDraftJEBatchPayloadKeySpecifier
)[];
export type CreateDraftJEBatchPayloadFieldPolicy = {
  batch?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateFloorplanPayloadKeySpecifier = (
  | 'error'
  | 'floorplan'
  | 'property'
  | 'success'
  | CreateFloorplanPayloadKeySpecifier
)[];
export type CreateFloorplanPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  floorplan?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateGLAccountPayloadKeySpecifier = (
  | 'error'
  | 'glAccount'
  | 'success'
  | CreateGLAccountPayloadKeySpecifier
)[];
export type CreateGLAccountPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  glAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateRequestCostPayloadKeySpecifier = (
  | 'error'
  | 'newCostId'
  | 'request'
  | 'success'
  | CreateRequestCostPayloadKeySpecifier
)[];
export type CreateRequestCostPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  newCostId?: FieldPolicy<any> | FieldReadFunction<any>;
  request?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateRequestNotePayloadKeySpecifier = (
  | 'request'
  | CreateRequestNotePayloadKeySpecifier
)[];
export type CreateRequestNotePayloadFieldPolicy = {
  request?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateRequestPayloadKeySpecifier = (
  | 'error'
  | 'request'
  | 'success'
  | CreateRequestPayloadKeySpecifier
)[];
export type CreateRequestPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  request?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateVisitPayloadKeySpecifier = ('request' | CreateVisitPayloadKeySpecifier)[];
export type CreateVisitPayloadFieldPolicy = {
  request?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DeleteApplicationNotePayloadKeySpecifier = (
  | 'application'
  | DeleteApplicationNotePayloadKeySpecifier
)[];
export type DeleteApplicationNotePayloadFieldPolicy = {
  application?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DeleteBuildingPayloadKeySpecifier = (
  | 'error'
  | 'property'
  | 'success'
  | DeleteBuildingPayloadKeySpecifier
)[];
export type DeleteBuildingPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DeleteClaimPayloadKeySpecifier = (
  | 'error'
  | 'property'
  | 'success'
  | DeleteClaimPayloadKeySpecifier
)[];
export type DeleteClaimPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DeleteClearablePayloadKeySpecifier = (
  | 'error'
  | 'success'
  | DeleteClearablePayloadKeySpecifier
)[];
export type DeleteClearablePayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DeleteRequestNotePayloadKeySpecifier = (
  | 'request'
  | DeleteRequestNotePayloadKeySpecifier
)[];
export type DeleteRequestNotePayloadFieldPolicy = {
  request?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DepositTemplateKeySpecifier = (
  | 'default'
  | 'enabled'
  | 'id'
  | 'items'
  | 'province'
  | DepositTemplateKeySpecifier
)[];
export type DepositTemplateFieldPolicy = {
  default?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  province?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DepositTemplateItemKeySpecifier = (
  | 'accrualStartExpr'
  | 'defaultAmount'
  | 'defaultAmountExpr'
  | 'id'
  | 'name'
  | 'present'
  | 'simple'
  | DepositTemplateItemKeySpecifier
)[];
export type DepositTemplateItemFieldPolicy = {
  accrualStartExpr?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultAmountExpr?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  present?: FieldPolicy<any> | FieldReadFunction<any>;
  simple?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentKeySpecifier = (
  | 'createdZ'
  | 'expiryZ'
  | 'key'
  | 'name'
  | 'size'
  | 'typename'
  | DocumentKeySpecifier
)[];
export type DocumentFieldPolicy = {
  createdZ?: FieldPolicy<any> | FieldReadFunction<any>;
  expiryZ?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  size?: FieldPolicy<any> | FieldReadFunction<any>;
  typename?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentLinkPayloadKeySpecifier = (
  | 'error'
  | 'link'
  | DocumentLinkPayloadKeySpecifier
)[];
export type DocumentLinkPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  link?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DraftReconciliationPayloadKeySpecifier = (
  | 'error'
  | 'reconciliation'
  | 'success'
  | DraftReconciliationPayloadKeySpecifier
)[];
export type DraftReconciliationPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  reconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DraftedJournalEntriesPageKeySpecifier = (
  | 'clearedCount'
  | 'clearedDepositsCount'
  | 'clearedWithdrawalsCount'
  | 'edges'
  | 'pageInfo'
  | 'totalCleared'
  | 'totalCount'
  | 'totalDepositsCleared'
  | 'totalDepositsUncleared'
  | 'totalUncleared'
  | 'totalWithdrawalsCleared'
  | 'totalWithdrawalsUncleared'
  | 'unclearedCount'
  | 'unclearedDepositsCount'
  | 'unclearedWithdrawalsCount'
  | DraftedJournalEntriesPageKeySpecifier
)[];
export type DraftedJournalEntriesPageFieldPolicy = {
  clearedCount?: FieldPolicy<any> | FieldReadFunction<any>;
  clearedDepositsCount?: FieldPolicy<any> | FieldReadFunction<any>;
  clearedWithdrawalsCount?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCleared?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalDepositsCleared?: FieldPolicy<any> | FieldReadFunction<any>;
  totalDepositsUncleared?: FieldPolicy<any> | FieldReadFunction<any>;
  totalUncleared?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWithdrawalsCleared?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWithdrawalsUncleared?: FieldPolicy<any> | FieldReadFunction<any>;
  unclearedCount?: FieldPolicy<any> | FieldReadFunction<any>;
  unclearedDepositsCount?: FieldPolicy<any> | FieldReadFunction<any>;
  unclearedWithdrawalsCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DraftedJournalEntryKeySpecifier = (
  | 'amount'
  | 'checked'
  | 'id'
  | 'posted'
  | DraftedJournalEntryKeySpecifier
)[];
export type DraftedJournalEntryFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  checked?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DraftedJournalEntryEdgeKeySpecifier = (
  | 'cursor'
  | 'node'
  | DraftedJournalEntryEdgeKeySpecifier
)[];
export type DraftedJournalEntryEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EmergencyContactKeySpecifier = (
  | 'name'
  | 'phone'
  | 'relationship'
  | EmergencyContactKeySpecifier
)[];
export type EmergencyContactFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  relationship?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExceptionKeySpecifier = ('comment' | 'interval' | ExceptionKeySpecifier)[];
export type ExceptionFieldPolicy = {
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  interval?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FeatureKeySpecifier = ('id' | 'name' | FeatureKeySpecifier)[];
export type FeatureFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FeeKeySpecifier = ('deletedZ' | 'id' | 'name' | FeeKeySpecifier)[];
export type FeeFieldPolicy = {
  deletedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FeeMappingKeySpecifier = ('feeId' | 'glId' | FeeMappingKeySpecifier)[];
export type FeeMappingFieldPolicy = {
  feeId?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FieldKeySpecifier = (
  | 'id'
  | 'imageKey'
  | 'info'
  | 'label'
  | 'type'
  | 'value'
  | FieldKeySpecifier
)[];
export type FieldFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageKey?: FieldPolicy<any> | FieldReadFunction<any>;
  info?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FinalizeRequestPayloadKeySpecifier = (
  | 'error'
  | 'request'
  | 'success'
  | FinalizeRequestPayloadKeySpecifier
)[];
export type FinalizeRequestPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  request?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FingerprintsKeySpecifier = (
  | 'createdSub'
  | 'createdZ'
  | 'updatedSub'
  | 'updatedZ'
  | FingerprintsKeySpecifier
)[];
export type FingerprintsFieldPolicy = {
  createdSub?: FieldPolicy<any> | FieldReadFunction<any>;
  createdZ?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedZ?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FloorplanKeySpecifier = (
  | 'additionalFeatures'
  | 'areas'
  | 'bathrooms'
  | 'bedrooms'
  | 'default'
  | 'description'
  | 'enabled'
  | 'exteriorSqFt'
  | 'generatedSummary'
  | 'id'
  | 'interiorSqFt'
  | 'links'
  | 'marketRate'
  | 'name'
  | 'photos'
  | 'publicPhotos'
  | 'totalUnits'
  | 'units'
  | FloorplanKeySpecifier
)[];
export type FloorplanFieldPolicy = {
  additionalFeatures?: FieldPolicy<any> | FieldReadFunction<any>;
  areas?: FieldPolicy<any> | FieldReadFunction<any>;
  bathrooms?: FieldPolicy<any> | FieldReadFunction<any>;
  bedrooms?: FieldPolicy<any> | FieldReadFunction<any>;
  default?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  exteriorSqFt?: FieldPolicy<any> | FieldReadFunction<any>;
  generatedSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  interiorSqFt?: FieldPolicy<any> | FieldReadFunction<any>;
  links?: FieldPolicy<any> | FieldReadFunction<any>;
  marketRate?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  photos?: FieldPolicy<any> | FieldReadFunction<any>;
  publicPhotos?: FieldPolicy<any> | FieldReadFunction<any>;
  totalUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  units?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FloorplanAreaKeySpecifier = (
  | 'category'
  | 'description'
  | 'features'
  | 'id'
  | 'label'
  | FloorplanAreaKeySpecifier
)[];
export type FloorplanAreaFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  features?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FloorplanAreaFeatureKeySpecifier = (
  | 'description'
  | 'id'
  | 'label'
  | 'name'
  | 'type'
  | FloorplanAreaFeatureKeySpecifier
)[];
export type FloorplanAreaFeatureFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FloorplanConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | FloorplanConnectionKeySpecifier
)[];
export type FloorplanConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FloorplanPayloadKeySpecifier = (
  | 'error'
  | 'floorplan'
  | 'success'
  | FloorplanPayloadKeySpecifier
)[];
export type FloorplanPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  floorplan?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FractionKeySpecifier = ('denominator' | 'numerator' | FractionKeySpecifier)[];
export type FractionFieldPolicy = {
  denominator?: FieldPolicy<any> | FieldReadFunction<any>;
  numerator?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FurnishingsTemplateKeySpecifier = (
  | 'id'
  | 'items'
  | 'name'
  | FurnishingsTemplateKeySpecifier
)[];
export type FurnishingsTemplateFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GLAccountKeySpecifier = (
  | 'accountType'
  | 'balanceType'
  | 'category'
  | 'combinedBalance'
  | 'combinedSubAccount'
  | 'enabled'
  | 'id'
  | 'key'
  | 'name'
  | 'pageJournalEntries'
  | GLAccountKeySpecifier
)[];
export type GLAccountFieldPolicy = {
  accountType?: FieldPolicy<any> | FieldReadFunction<any>;
  balanceType?: FieldPolicy<any> | FieldReadFunction<any>;
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  combinedBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  combinedSubAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  pageJournalEntries?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GLMappingKeySpecifier = (
  | 'default'
  | 'description'
  | 'filter'
  | 'glId'
  | 'id'
  | 'name'
  | 'parentId'
  | GLMappingKeySpecifier
)[];
export type GLMappingFieldPolicy = {
  default?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  filter?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  parentId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GeneratedFloorplanFeatureKeySpecifier = (
  | 'feature'
  | 'label'
  | GeneratedFloorplanFeatureKeySpecifier
)[];
export type GeneratedFloorplanFeatureFieldPolicy = {
  feature?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GeneratedFloorplanSummaryKeySpecifier = (
  | 'features'
  | 'summary'
  | GeneratedFloorplanSummaryKeySpecifier
)[];
export type GeneratedFloorplanSummaryFieldPolicy = {
  features?: FieldPolicy<any> | FieldReadFunction<any>;
  summary?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GrandTotalKeySpecifier = (
  | 'amount'
  | 'balanceType'
  | 'extraColumns'
  | 'footer'
  | 'header'
  | 'order'
  | 'sections'
  | GrandTotalKeySpecifier
)[];
export type GrandTotalFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  balanceType?: FieldPolicy<any> | FieldReadFunction<any>;
  extraColumns?: FieldPolicy<any> | FieldReadFunction<any>;
  footer?: FieldPolicy<any> | FieldReadFunction<any>;
  header?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
  sections?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type HouseholdKeySpecifier = (
  | 'additionalTenants'
  | 'payeeFrac'
  | 'pets'
  | 'vehicles'
  | HouseholdKeySpecifier
)[];
export type HouseholdFieldPolicy = {
  additionalTenants?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeFrac?: FieldPolicy<any> | FieldReadFunction<any>;
  pets?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicles?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IamMutationKeySpecifier = (
  | 'addAccountKycNote'
  | 'addManagerRole'
  | 'addPropertyAssignedKeyrings'
  | 'addPropertyAssignedParkings'
  | 'addPropertyAssignedStorages'
  | 'createAccount'
  | 'createAutopayEmbedUrl'
  | 'createInterestRate'
  | 'createManager'
  | 'createResidency'
  | 'deleteAccountKycNote'
  | 'deleteInterestRate'
  | 'deleteManager'
  | 'deleteProperty'
  | 'deleteReconciliationIndex'
  | 'deleteUnit'
  | 'enableAccount'
  | 'enableManager'
  | 'enableProperty'
  | 'reactivateAutopay'
  | 'removeManagerRole'
  | 'suspendAutopay'
  | 'updateAccountBillingInfo'
  | 'updateAccountKyc'
  | 'updateInterestRate'
  | 'updateManager'
  | 'updateUnit'
  | 'updateUnitTest'
  | 'welcomeTenant'
  | IamMutationKeySpecifier
)[];
export type IamMutationFieldPolicy = {
  addAccountKycNote?: FieldPolicy<any> | FieldReadFunction<any>;
  addManagerRole?: FieldPolicy<any> | FieldReadFunction<any>;
  addPropertyAssignedKeyrings?: FieldPolicy<any> | FieldReadFunction<any>;
  addPropertyAssignedParkings?: FieldPolicy<any> | FieldReadFunction<any>;
  addPropertyAssignedStorages?: FieldPolicy<any> | FieldReadFunction<any>;
  createAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  createAutopayEmbedUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  createInterestRate?: FieldPolicy<any> | FieldReadFunction<any>;
  createManager?: FieldPolicy<any> | FieldReadFunction<any>;
  createResidency?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteAccountKycNote?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteInterestRate?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteManager?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteProperty?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteReconciliationIndex?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUnit?: FieldPolicy<any> | FieldReadFunction<any>;
  enableAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  enableManager?: FieldPolicy<any> | FieldReadFunction<any>;
  enableProperty?: FieldPolicy<any> | FieldReadFunction<any>;
  reactivateAutopay?: FieldPolicy<any> | FieldReadFunction<any>;
  removeManagerRole?: FieldPolicy<any> | FieldReadFunction<any>;
  suspendAutopay?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAccountBillingInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAccountKyc?: FieldPolicy<any> | FieldReadFunction<any>;
  updateInterestRate?: FieldPolicy<any> | FieldReadFunction<any>;
  updateManager?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUnit?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUnitTest?: FieldPolicy<any> | FieldReadFunction<any>;
  welcomeTenant?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IamQueryKeySpecifier = (
  | 'account'
  | 'accountStats'
  | 'accounts'
  | 'autopayAccount'
  | 'autopayResident'
  | 'bank'
  | 'billingAccount'
  | 'campaign'
  | 'campaigns'
  | 'clearable'
  | 'entity'
  | 'entry'
  | 'interestRates'
  | 'listAccountFacts'
  | 'listPaymentBatchPrototypes'
  | 'listProperties'
  | 'listPropertyFacts'
  | 'propertyFacts'
  | 'unit'
  | 'unitJournalEntries'
  | IamQueryKeySpecifier
)[];
export type IamQueryFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  accountStats?: FieldPolicy<any> | FieldReadFunction<any>;
  accounts?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayResident?: FieldPolicy<any> | FieldReadFunction<any>;
  bank?: FieldPolicy<any> | FieldReadFunction<any>;
  billingAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  campaign?: FieldPolicy<any> | FieldReadFunction<any>;
  campaigns?: FieldPolicy<any> | FieldReadFunction<any>;
  clearable?: FieldPolicy<any> | FieldReadFunction<any>;
  entity?: FieldPolicy<any> | FieldReadFunction<any>;
  entry?: FieldPolicy<any> | FieldReadFunction<any>;
  interestRates?: FieldPolicy<any> | FieldReadFunction<any>;
  listAccountFacts?: FieldPolicy<any> | FieldReadFunction<any>;
  listPaymentBatchPrototypes?: FieldPolicy<any> | FieldReadFunction<any>;
  listProperties?: FieldPolicy<any> | FieldReadFunction<any>;
  listPropertyFacts?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyFacts?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
  unitJournalEntries?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IamTenantKeySpecifier = (
  | 'account'
  | 'booksActivation'
  | 'campaign'
  | 'documentLink'
  | 'getInspectionUrl'
  | 'getProvinceTaxDetails'
  | 'getRegionTaxDetails'
  | 'manager'
  | 'owner'
  | 'payeeClearable'
  | 'payeeClearables'
  | 'property'
  | 'propertyExpenses'
  | 'request'
  | 'searchOwnerRequests'
  | 'searchResidents'
  | 'tenant'
  | 'unit'
  | IamTenantKeySpecifier
)[];
export type IamTenantFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  booksActivation?: FieldPolicy<any> | FieldReadFunction<any>;
  campaign?: FieldPolicy<any> | FieldReadFunction<any>;
  documentLink?: FieldPolicy<any> | FieldReadFunction<any>;
  getInspectionUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  getProvinceTaxDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  getRegionTaxDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  manager?: FieldPolicy<any> | FieldReadFunction<any>;
  owner?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeClearable?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeClearables?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyExpenses?: FieldPolicy<any> | FieldReadFunction<any>;
  request?: FieldPolicy<any> | FieldReadFunction<any>;
  searchOwnerRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  searchResidents?: FieldPolicy<any> | FieldReadFunction<any>;
  tenant?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IamTenantClearableKeySpecifier = (
  | 'accountAddress'
  | 'accountId'
  | 'accountName'
  | 'amount'
  | 'autopaySinglePosted'
  | 'autopaySingleProfileId'
  | 'balanceType'
  | 'cleared'
  | 'description'
  | 'due'
  | 'enrollmentId'
  | 'fixedEnrollmentId'
  | 'glId'
  | 'id'
  | 'outstanding'
  | 'ownerId'
  | 'payee'
  | 'payeeAutopayClientId'
  | 'payeeAutopayStatus'
  | 'payeeId'
  | 'payeeMailingAddress'
  | 'payeeName'
  | 'payments'
  | 'posted'
  | 'propertyAddress'
  | 'propertyId'
  | 'propertyName'
  | 'ref'
  | 'refund'
  | 'sourceJournalId'
  | 'unitId'
  | 'unitName'
  | IamTenantClearableKeySpecifier
)[];
export type IamTenantClearableFieldPolicy = {
  accountAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  accountName?: FieldPolicy<any> | FieldReadFunction<any>;
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  autopaySinglePosted?: FieldPolicy<any> | FieldReadFunction<any>;
  autopaySingleProfileId?: FieldPolicy<any> | FieldReadFunction<any>;
  balanceType?: FieldPolicy<any> | FieldReadFunction<any>;
  cleared?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  due?: FieldPolicy<any> | FieldReadFunction<any>;
  enrollmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  fixedEnrollmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  outstanding?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  payee?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeAutopayClientId?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeAutopayStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeId?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeMailingAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeName?: FieldPolicy<any> | FieldReadFunction<any>;
  payments?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyName?: FieldPolicy<any> | FieldReadFunction<any>;
  ref?: FieldPolicy<any> | FieldReadFunction<any>;
  refund?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceJournalId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IamTenantClearableEdgeKeySpecifier = (
  | 'cursor'
  | 'node'
  | IamTenantClearableEdgeKeySpecifier
)[];
export type IamTenantClearableEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IamTenantClearablePaymentEffectKeySpecifier = (
  | 'effect'
  | 'id'
  | 'paymentMethod'
  | 'posted'
  | IamTenantClearablePaymentEffectKeySpecifier
)[];
export type IamTenantClearablePaymentEffectFieldPolicy = {
  effect?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IamTenantClearablesPageKeySpecifier = (
  | 'edges'
  | 'pageInfo'
  | 'totalByAccount'
  | 'totalByProperty'
  | 'totalByUnit'
  | 'totalCount'
  | IamTenantClearablesPageKeySpecifier
)[];
export type IamTenantClearablesPageFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalByAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalByProperty?: FieldPolicy<any> | FieldReadFunction<any>;
  totalByUnit?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IamTenantInsurancePolicyKeySpecifier = (
  | 'effective'
  | 'expiry'
  | 'id'
  | 'liability'
  | 'policyDocumentKey'
  | 'policyNumber'
  | 'propertyId'
  | 'providerName'
  | 'residencyId'
  | 'unitId'
  | IamTenantInsurancePolicyKeySpecifier
)[];
export type IamTenantInsurancePolicyFieldPolicy = {
  effective?: FieldPolicy<any> | FieldReadFunction<any>;
  expiry?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  liability?: FieldPolicy<any> | FieldReadFunction<any>;
  policyDocumentKey?: FieldPolicy<any> | FieldReadFunction<any>;
  policyNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  providerName?: FieldPolicy<any> | FieldReadFunction<any>;
  residencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IamTenantJournalEntryKeySpecifier = (
  | 'amount'
  | 'clearableId'
  | 'description'
  | 'documents'
  | 'glAccountType'
  | 'glBalanceType'
  | 'glId'
  | 'glName'
  | 'gstAmount'
  | 'id'
  | 'outstanding'
  | 'ownerId'
  | 'paidDate'
  | 'posted'
  | 'propertyId'
  | 'ref'
  | 'requestId'
  | 'unitId'
  | IamTenantJournalEntryKeySpecifier
)[];
export type IamTenantJournalEntryFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  clearableId?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  glAccountType?: FieldPolicy<any> | FieldReadFunction<any>;
  glBalanceType?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  glName?: FieldPolicy<any> | FieldReadFunction<any>;
  gstAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  outstanding?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  paidDate?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  ref?: FieldPolicy<any> | FieldReadFunction<any>;
  requestId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ImageKeySpecifier = ('key' | 'name' | 'notes' | 'tags' | ImageKeySpecifier)[];
export type ImageFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  tags?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IncentiveEffectKeySpecifier = (
  | 'description'
  | 'effect'
  | 'feeId'
  | 'id'
  | 'incentiveId'
  | 'startZ'
  | IncentiveEffectKeySpecifier
)[];
export type IncentiveEffectFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  effect?: FieldPolicy<any> | FieldReadFunction<any>;
  feeId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  incentiveId?: FieldPolicy<any> | FieldReadFunction<any>;
  startZ?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IncomeKeySpecifier = ('amount' | 'id' | 'source' | IncomeKeySpecifier)[];
export type IncomeFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IncomePayoutOptionsKeySpecifier = (
  | 'excludePayables'
  | 'excludePrepayments'
  | 'includeOutstandingPayables'
  | IncomePayoutOptionsKeySpecifier
)[];
export type IncomePayoutOptionsFieldPolicy = {
  excludePayables?: FieldPolicy<any> | FieldReadFunction<any>;
  excludePrepayments?: FieldPolicy<any> | FieldReadFunction<any>;
  includeOutstandingPayables?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionKeySpecifier = (
  | 'accountId'
  | 'category'
  | 'comments'
  | 'completedZ'
  | 'description'
  | 'generatedSummary'
  | 'id'
  | 'includeTenantInfo'
  | 'items'
  | 'lastSyncedZ'
  | 'propertyId'
  | 'reportKeys'
  | 'signatures'
  | 'status'
  | 'templateId'
  | 'unitId'
  | InspectionKeySpecifier
)[];
export type InspectionFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  completedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  generatedSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  includeTenantInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSyncedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  reportKeys?: FieldPolicy<any> | FieldReadFunction<any>;
  signatures?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  templateId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionAttemptKeySpecifier = (
  | 'attemptedZ'
  | 'noteText'
  | 'reason'
  | InspectionAttemptKeySpecifier
)[];
export type InspectionAttemptFieldPolicy = {
  attemptedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  noteText?: FieldPolicy<any> | FieldReadFunction<any>;
  reason?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionFieldKeySpecifier = (
  | 'category'
  | 'description'
  | 'hasChanged'
  | 'id'
  | 'images'
  | 'inspectedZ'
  | 'label'
  | 'name'
  | 'needsRequest'
  | 'noteText'
  | 'type'
  | 'value'
  | InspectionFieldKeySpecifier
)[];
export type InspectionFieldFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  hasChanged?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  needsRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  noteText?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionItemKeySpecifier = (
  | 'category'
  | 'description'
  | 'fields'
  | 'hasChanged'
  | 'id'
  | 'label'
  | 'referenceId'
  | 'wasInspected'
  | InspectionItemKeySpecifier
)[];
export type InspectionItemFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  fields?: FieldPolicy<any> | FieldReadFunction<any>;
  hasChanged?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  referenceId?: FieldPolicy<any> | FieldReadFunction<any>;
  wasInspected?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionSummaryKeySpecifier = ('score' | 'summary' | InspectionSummaryKeySpecifier)[];
export type InspectionSummaryFieldPolicy = {
  score?: FieldPolicy<any> | FieldReadFunction<any>;
  summary?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionTemplateKeySpecifier = (
  | 'category'
  | 'default'
  | 'enabled'
  | 'fingerprints'
  | 'id'
  | 'items'
  | InspectionTemplateKeySpecifier
)[];
export type InspectionTemplateFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  default?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionUrlObjectKeySpecifier = (
  | 'fileUrl'
  | 'filename'
  | 'message'
  | InspectionUrlObjectKeySpecifier
)[];
export type InspectionUrlObjectFieldPolicy = {
  fileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  filename?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionUrlPayloadKeySpecifier = (
  | 'error'
  | 'inspectionUrlObject'
  | 'success'
  | InspectionUrlPayloadKeySpecifier
)[];
export type InspectionUrlPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionUrlObject?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InsurancePolicyKeySpecifier = (
  | 'claims'
  | 'coverage'
  | 'effective'
  | 'expiry'
  | 'fingerprints'
  | 'id'
  | 'liability'
  | 'policyHolder'
  | 'policyNumber'
  | 'premium'
  | 'supplier'
  | 'supplierId'
  | InsurancePolicyKeySpecifier
)[];
export type InsurancePolicyFieldPolicy = {
  claims?: FieldPolicy<any> | FieldReadFunction<any>;
  coverage?: FieldPolicy<any> | FieldReadFunction<any>;
  effective?: FieldPolicy<any> | FieldReadFunction<any>;
  expiry?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  liability?: FieldPolicy<any> | FieldReadFunction<any>;
  policyHolder?: FieldPolicy<any> | FieldReadFunction<any>;
  policyNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  premium?: FieldPolicy<any> | FieldReadFunction<any>;
  supplier?: FieldPolicy<any> | FieldReadFunction<any>;
  supplierId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InsurancePolicyPayloadKeySpecifier = (
  | 'error'
  | 'property'
  | 'success'
  | InsurancePolicyPayloadKeySpecifier
)[];
export type InsurancePolicyPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InterestRateKeySpecifier = (
  | 'id'
  | 'province'
  | 'rate'
  | 'startDate'
  | InterestRateKeySpecifier
)[];
export type InterestRateFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  province?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  startDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InterestRateConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | InterestRateConnectionKeySpecifier
)[];
export type InterestRateConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InterestRatePayloadKeySpecifier = (
  | 'error'
  | 'interestRate'
  | 'success'
  | InterestRatePayloadKeySpecifier
)[];
export type InterestRatePayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  interestRate?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IntervalKeySpecifier = ('end' | 'start' | IntervalKeySpecifier)[];
export type IntervalFieldPolicy = {
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JEDraftBatchKeySpecifier = (
  | 'id'
  | 'listPrototypes'
  | 'name'
  | 'posted'
  | 'presetId'
  | 'prototypes'
  | JEDraftBatchKeySpecifier
)[];
export type JEDraftBatchFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  listPrototypes?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
  presetId?: FieldPolicy<any> | FieldReadFunction<any>;
  prototypes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JEPrototypeKeySpecifier = ('fields' | 'id' | 'omit' | JEPrototypeKeySpecifier)[];
export type JEPrototypeFieldPolicy = {
  fields?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  omit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JEPrototypeConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | JEPrototypeConnectionKeySpecifier
)[];
export type JEPrototypeConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JEPrototypeFieldKeySpecifier = (
  | 'editable'
  | 'formula'
  | 'groupId'
  | 'id'
  | 'label'
  | 'order'
  | 'secondary'
  | 'type'
  | 'value'
  | JEPrototypeFieldKeySpecifier
)[];
export type JEPrototypeFieldFieldPolicy = {
  editable?: FieldPolicy<any> | FieldReadFunction<any>;
  formula?: FieldPolicy<any> | FieldReadFunction<any>;
  groupId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
  secondary?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JEPrototypeKeyKeySpecifier = ('batchId' | 'id' | JEPrototypeKeyKeySpecifier)[];
export type JEPrototypeKeyFieldPolicy = {
  batchId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JEPrototypeKeysPayloadKeySpecifier = (
  | 'error'
  | 'prototypeKeys'
  | 'success'
  | JEPrototypeKeysPayloadKeySpecifier
)[];
export type JEPrototypeKeysPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  prototypeKeys?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JournalEntriesForRecPageKeySpecifier = (
  | 'clearedCount'
  | 'clearedDepositsCount'
  | 'clearedWithdrawalsCount'
  | 'edges'
  | 'pageInfo'
  | 'totalCleared'
  | 'totalCount'
  | 'totalDeposits'
  | 'totalDepositsCleared'
  | 'totalDepositsUncleared'
  | 'totalUncleared'
  | 'totalWithdrawals'
  | 'totalWithdrawalsCleared'
  | 'totalWithdrawalsUncleared'
  | 'unclearedCount'
  | 'unclearedDepositsCount'
  | 'unclearedWithdrawalsCount'
  | JournalEntriesForRecPageKeySpecifier
)[];
export type JournalEntriesForRecPageFieldPolicy = {
  clearedCount?: FieldPolicy<any> | FieldReadFunction<any>;
  clearedDepositsCount?: FieldPolicy<any> | FieldReadFunction<any>;
  clearedWithdrawalsCount?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCleared?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalDeposits?: FieldPolicy<any> | FieldReadFunction<any>;
  totalDepositsCleared?: FieldPolicy<any> | FieldReadFunction<any>;
  totalDepositsUncleared?: FieldPolicy<any> | FieldReadFunction<any>;
  totalUncleared?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWithdrawals?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWithdrawalsCleared?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWithdrawalsUncleared?: FieldPolicy<any> | FieldReadFunction<any>;
  unclearedCount?: FieldPolicy<any> | FieldReadFunction<any>;
  unclearedDepositsCount?: FieldPolicy<any> | FieldReadFunction<any>;
  unclearedWithdrawalsCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JournalEntriesPageKeySpecifier = (
  | 'edges'
  | 'endingBalance'
  | 'pageInfo'
  | 'totalCount'
  | JournalEntriesPageKeySpecifier
)[];
export type JournalEntriesPageFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  endingBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JournalEntryKeySpecifier = (
  | 'amount'
  | 'approvalId'
  | 'approverName'
  | 'batchId'
  | 'buildingKey'
  | 'buildingName'
  | 'clearable'
  | 'clearableId'
  | 'clearableLinked'
  | 'description'
  | 'documents'
  | 'draftReconciliationId'
  | 'glAccount'
  | 'glId'
  | 'gstAmount'
  | 'gstInfo'
  | 'id'
  | 'jeId'
  | 'journalEntries'
  | 'journalIds'
  | 'lines'
  | 'notes'
  | 'ownerId'
  | 'ownerName'
  | 'payee'
  | 'payeeId'
  | 'payeeName'
  | 'paymentMethod'
  | 'posted'
  | 'printedZ'
  | 'property'
  | 'propertyId'
  | 'propertyKey'
  | 'propertyName'
  | 'reconciliation'
  | 'reconciliationId'
  | 'ref'
  | 'relatedClearables'
  | 'requestId'
  | 'transactionReferenceId'
  | 'unit'
  | 'unitId'
  | 'unitName'
  | 'voidedZ'
  | JournalEntryKeySpecifier
)[];
export type JournalEntryFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  approvalId?: FieldPolicy<any> | FieldReadFunction<any>;
  approverName?: FieldPolicy<any> | FieldReadFunction<any>;
  batchId?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingKey?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingName?: FieldPolicy<any> | FieldReadFunction<any>;
  clearable?: FieldPolicy<any> | FieldReadFunction<any>;
  clearableId?: FieldPolicy<any> | FieldReadFunction<any>;
  clearableLinked?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  draftReconciliationId?: FieldPolicy<any> | FieldReadFunction<any>;
  glAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  gstAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  gstInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  jeId?: FieldPolicy<any> | FieldReadFunction<any>;
  journalEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  journalIds?: FieldPolicy<any> | FieldReadFunction<any>;
  lines?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerName?: FieldPolicy<any> | FieldReadFunction<any>;
  payee?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeId?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeName?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
  printedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyKey?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyName?: FieldPolicy<any> | FieldReadFunction<any>;
  reconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  reconciliationId?: FieldPolicy<any> | FieldReadFunction<any>;
  ref?: FieldPolicy<any> | FieldReadFunction<any>;
  relatedClearables?: FieldPolicy<any> | FieldReadFunction<any>;
  requestId?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionReferenceId?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitName?: FieldPolicy<any> | FieldReadFunction<any>;
  voidedZ?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JournalEntryConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | JournalEntryConnectionKeySpecifier
)[];
export type JournalEntryConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JournalEntryEdgeKeySpecifier = ('cursor' | 'node' | JournalEntryEdgeKeySpecifier)[];
export type JournalEntryEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JournalEntryLineKeySpecifier = (
  | 'amount'
  | 'clearableId'
  | 'description'
  | 'glId'
  | 'id'
  | 'ownerId'
  | 'payee'
  | 'payeeId'
  | 'propertyId'
  | 'ref'
  | 'requestId'
  | 'unitId'
  | JournalEntryLineKeySpecifier
)[];
export type JournalEntryLineFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  clearableId?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  payee?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  ref?: FieldPolicy<any> | FieldReadFunction<any>;
  requestId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JournalEntryPayloadKeySpecifier = (
  | 'books'
  | 'error'
  | 'journalEntries'
  | 'journalIds'
  | 'recurrence'
  | 'success'
  | JournalEntryPayloadKeySpecifier
)[];
export type JournalEntryPayloadFieldPolicy = {
  books?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  journalEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  journalIds?: FieldPolicy<any> | FieldReadFunction<any>;
  recurrence?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JournalForReconciliationPayloadKeySpecifier = (
  | 'error'
  | 'journalEntries'
  | 'success'
  | JournalForReconciliationPayloadKeySpecifier
)[];
export type JournalForReconciliationPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  journalEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JournalKeyKeySpecifier = ('id' | 'posted' | JournalKeyKeySpecifier)[];
export type JournalKeyFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JournalKeysPayloadKeySpecifier = (
  | 'error'
  | 'journals'
  | 'success'
  | JournalKeysPayloadKeySpecifier
)[];
export type JournalKeysPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  journals?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type KeyringKeySpecifier = ('name' | 'unitId' | KeyringKeySpecifier)[];
export type KeyringFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type KycKeySpecifier = (
  | 'bankInfoMatched'
  | 'businessNumberVerified'
  | 'completed'
  | 'customerType'
  | 'noOfEmployees'
  | 'notes'
  | 'riskProfile'
  | 'signupMethod'
  | 'trainingForStaff'
  | 'website'
  | KycKeySpecifier
)[];
export type KycFieldPolicy = {
  bankInfoMatched?: FieldPolicy<any> | FieldReadFunction<any>;
  businessNumberVerified?: FieldPolicy<any> | FieldReadFunction<any>;
  completed?: FieldPolicy<any> | FieldReadFunction<any>;
  customerType?: FieldPolicy<any> | FieldReadFunction<any>;
  noOfEmployees?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  riskProfile?: FieldPolicy<any> | FieldReadFunction<any>;
  signupMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  trainingForStaff?: FieldPolicy<any> | FieldReadFunction<any>;
  website?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseKeySpecifier = (
  | 'additionalProvisions'
  | 'applicationId'
  | 'autoRenewSetup'
  | 'campaignId'
  | 'chainedLease'
  | 'documents'
  | 'end'
  | 'fingerprints'
  | 'fullTenants'
  | 'gst'
  | 'id'
  | 'incentives'
  | 'insurancePolicy'
  | 'leaseAgreement'
  | 'moveInDate'
  | 'moveInInspection'
  | 'moveOutDate'
  | 'moveOutInspection'
  | 'moveOutReason'
  | 'notes'
  | 'occupancy'
  | 'originalLeaseId'
  | 'preferredPaymentMethod'
  | 'property'
  | 'propertyId'
  | 'rateSchedule'
  | 'renewedLeaseId'
  | 'rent'
  | 'rentRollFees'
  | 'securityDeposit'
  | 'securityDepositHistory'
  | 'start'
  | 'status'
  | 'tenants'
  | 'type'
  | 'unit'
  | 'unitId'
  | 'utilities'
  | LeaseKeySpecifier
)[];
export type LeaseFieldPolicy = {
  additionalProvisions?: FieldPolicy<any> | FieldReadFunction<any>;
  applicationId?: FieldPolicy<any> | FieldReadFunction<any>;
  autoRenewSetup?: FieldPolicy<any> | FieldReadFunction<any>;
  campaignId?: FieldPolicy<any> | FieldReadFunction<any>;
  chainedLease?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  fullTenants?: FieldPolicy<any> | FieldReadFunction<any>;
  gst?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  incentives?: FieldPolicy<any> | FieldReadFunction<any>;
  insurancePolicy?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  moveInDate?: FieldPolicy<any> | FieldReadFunction<any>;
  moveInInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  moveOutDate?: FieldPolicy<any> | FieldReadFunction<any>;
  moveOutInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  moveOutReason?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  occupancy?: FieldPolicy<any> | FieldReadFunction<any>;
  originalLeaseId?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredPaymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  rateSchedule?: FieldPolicy<any> | FieldReadFunction<any>;
  renewedLeaseId?: FieldPolicy<any> | FieldReadFunction<any>;
  rent?: FieldPolicy<any> | FieldReadFunction<any>;
  rentRollFees?: FieldPolicy<any> | FieldReadFunction<any>;
  securityDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  securityDepositHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tenants?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
  utilities?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementKeySpecifier = (
  | 'additionalClause'
  | 'additionalProvisions'
  | 'agreementFileKey'
  | 'history'
  | 'houseRules'
  | 'id'
  | 'lease'
  | 'signatures'
  | 'status'
  | 'unitId'
  | 'updatedZ'
  | 'utilities'
  | LeaseAgreementKeySpecifier
)[];
export type LeaseAgreementFieldPolicy = {
  additionalClause?: FieldPolicy<any> | FieldReadFunction<any>;
  additionalProvisions?: FieldPolicy<any> | FieldReadFunction<any>;
  agreementFileKey?: FieldPolicy<any> | FieldReadFunction<any>;
  history?: FieldPolicy<any> | FieldReadFunction<any>;
  houseRules?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lease?: FieldPolicy<any> | FieldReadFunction<any>;
  signatures?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  utilities?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementUrlKeySpecifier = (
  | 'accountFileName'
  | 'accountFileUrl'
  | 'message'
  | 'profileFileName'
  | 'profileFileUrl'
  | LeaseAgreementUrlKeySpecifier
)[];
export type LeaseAgreementUrlFieldPolicy = {
  accountFileName?: FieldPolicy<any> | FieldReadFunction<any>;
  accountFileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  profileFileName?: FieldPolicy<any> | FieldReadFunction<any>;
  profileFileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseAgreementUrlPayloadKeySpecifier = (
  | 'agreementUrls'
  | 'error'
  | 'success'
  | LeaseAgreementUrlPayloadKeySpecifier
)[];
export type LeaseAgreementUrlPayloadFieldPolicy = {
  agreementUrls?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseConnectionKeySpecifier = ('items' | 'nextToken' | LeaseConnectionKeySpecifier)[];
export type LeaseConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseDocumentTemplateKeySpecifier = (
  | 'baseDocument'
  | 'id'
  | 'leaseType'
  | 'name'
  | 'propertyIds'
  | 'schemas'
  | LeaseDocumentTemplateKeySpecifier
)[];
export type LeaseDocumentTemplateFieldPolicy = {
  baseDocument?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseType?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyIds?: FieldPolicy<any> | FieldReadFunction<any>;
  schemas?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseDocumentTemplateConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | LeaseDocumentTemplateConnectionKeySpecifier
)[];
export type LeaseDocumentTemplateConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseDocumentTemplatePayloadKeySpecifier = (
  | 'error'
  | 'leaseDocumentTemplate'
  | 'success'
  | LeaseDocumentTemplatePayloadKeySpecifier
)[];
export type LeaseDocumentTemplatePayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseDocumentTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseFormKeySpecifier = (
  | 'bindings'
  | 'description'
  | 'id'
  | 'inputFields'
  | 'key'
  | 'name'
  | 'province'
  | LeaseFormKeySpecifier
)[];
export type LeaseFormFieldPolicy = {
  bindings?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inputFields?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  province?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseFormInputFieldKeySpecifier = (
  | 'defaultValue'
  | 'helpText'
  | 'hiddenExpression'
  | 'id'
  | 'label'
  | 'options'
  | 'optionsExpression'
  | 'required'
  | 'subLabel'
  | 'type'
  | LeaseFormInputFieldKeySpecifier
)[];
export type LeaseFormInputFieldFieldPolicy = {
  defaultValue?: FieldPolicy<any> | FieldReadFunction<any>;
  helpText?: FieldPolicy<any> | FieldReadFunction<any>;
  hiddenExpression?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  options?: FieldPolicy<any> | FieldReadFunction<any>;
  optionsExpression?: FieldPolicy<any> | FieldReadFunction<any>;
  required?: FieldPolicy<any> | FieldReadFunction<any>;
  subLabel?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseFormInputFieldOptionKeySpecifier = (
  | 'label'
  | 'value'
  | LeaseFormInputFieldOptionKeySpecifier
)[];
export type LeaseFormInputFieldOptionFieldPolicy = {
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseIncentiveKeySpecifier = (
  | 'description'
  | 'discountAmount'
  | 'end'
  | 'id'
  | 'start'
  | 'type'
  | LeaseIncentiveKeySpecifier
)[];
export type LeaseIncentiveFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  discountAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseInspectionKeySpecifier = (
  | 'accountId'
  | 'attempts'
  | 'category'
  | 'completedZ'
  | 'costAgreement'
  | 'generatedSummary'
  | 'id'
  | 'inspectionAgreement'
  | 'inspectionAgreements'
  | 'items'
  | 'lastSyncedZ'
  | 'propertyId'
  | 'reportKeys'
  | 'requests'
  | 'residencyId'
  | 'signatures'
  | 'status'
  | 'templateId'
  | 'tenantMailingAddress'
  | 'unitId'
  | LeaseInspectionKeySpecifier
)[];
export type LeaseInspectionFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  attempts?: FieldPolicy<any> | FieldReadFunction<any>;
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  completedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  costAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  generatedSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionAgreements?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSyncedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  reportKeys?: FieldPolicy<any> | FieldReadFunction<any>;
  requests?: FieldPolicy<any> | FieldReadFunction<any>;
  residencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  signatures?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  templateId?: FieldPolicy<any> | FieldReadFunction<any>;
  tenantMailingAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseInspectionPayloadKeySpecifier = (
  | 'error'
  | 'residency'
  | 'success'
  | LeaseInspectionPayloadKeySpecifier
)[];
export type LeaseInspectionPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  residency?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseInspectionRequestKeySpecifier = (
  | 'fieldId'
  | 'itemId'
  | 'requestId'
  | LeaseInspectionRequestKeySpecifier
)[];
export type LeaseInspectionRequestFieldPolicy = {
  fieldId?: FieldPolicy<any> | FieldReadFunction<any>;
  itemId?: FieldPolicy<any> | FieldReadFunction<any>;
  requestId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseInspectionUrlPayloadKeySpecifier = (
  | 'error'
  | 'inspectionUrlObject'
  | 'success'
  | LeaseInspectionUrlPayloadKeySpecifier
)[];
export type LeaseInspectionUrlPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionUrlObject?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseInsurancePolicyKeySpecifier = (
  | 'expirationDate'
  | 'id'
  | 'liability'
  | 'policyNumber'
  | 'providerName'
  | LeaseInsurancePolicyKeySpecifier
)[];
export type LeaseInsurancePolicyFieldPolicy = {
  expirationDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  liability?: FieldPolicy<any> | FieldReadFunction<any>;
  policyNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  providerName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeasePayloadKeySpecifier = ('error' | 'lease' | 'success' | LeasePayloadKeySpecifier)[];
export type LeasePayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  lease?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseTenantKeySpecifier = (
  | 'correspondenceMethod'
  | 'dob'
  | 'emails'
  | 'emergencyContacts'
  | 'enabled'
  | 'id'
  | 'isOwner'
  | 'isPayee'
  | 'leaseHolder'
  | 'name'
  | 'padAccount'
  | 'padAmount'
  | 'padInstitution'
  | 'padTransit'
  | 'payeeFrac'
  | 'pets'
  | 'phone'
  | 'phones'
  | 'preferredPaymentMethod'
  | 'sendWelcomeEmail'
  | 'type'
  | 'vehicles'
  | LeaseTenantKeySpecifier
)[];
export type LeaseTenantFieldPolicy = {
  correspondenceMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  dob?: FieldPolicy<any> | FieldReadFunction<any>;
  emails?: FieldPolicy<any> | FieldReadFunction<any>;
  emergencyContacts?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  isPayee?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseHolder?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  padAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  padAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  padInstitution?: FieldPolicy<any> | FieldReadFunction<any>;
  padTransit?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeFrac?: FieldPolicy<any> | FieldReadFunction<any>;
  pets?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  phones?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredPaymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  sendWelcomeEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicles?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LeaseUnitPayloadKeySpecifier = (
  | 'error'
  | 'renewedLeaseId'
  | 'success'
  | 'unit'
  | LeaseUnitPayloadKeySpecifier
)[];
export type LeaseUnitPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  renewedLeaseId?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LinkedAccountKeySpecifier = (
  | 'accountDetails'
  | 'flinksBankAccountId'
  | 'id'
  | 'institutionName'
  | 'loginId'
  | 'requestId'
  | LinkedAccountKeySpecifier
)[];
export type LinkedAccountFieldPolicy = {
  accountDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  flinksBankAccountId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  institutionName?: FieldPolicy<any> | FieldReadFunction<any>;
  loginId?: FieldPolicy<any> | FieldReadFunction<any>;
  requestId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListedUnitKeySpecifier = (
  | 'bathrooms'
  | 'bedrooms'
  | 'exteriorSqFt'
  | 'id'
  | 'rent'
  | 'sqft'
  | ListedUnitKeySpecifier
)[];
export type ListedUnitFieldPolicy = {
  bathrooms?: FieldPolicy<any> | FieldReadFunction<any>;
  bedrooms?: FieldPolicy<any> | FieldReadFunction<any>;
  exteriorSqFt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rent?: FieldPolicy<any> | FieldReadFunction<any>;
  sqft?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MaintenanceApprovalKeySpecifier = (
  | 'accountId'
  | 'approvedSub'
  | 'approvedZ'
  | 'approverRoles'
  | 'declinedSub'
  | 'declinedZ'
  | 'id'
  | 'request'
  | 'requestedName'
  | 'requestedSub'
  | 'requestedZ'
  | 'status'
  | MaintenanceApprovalKeySpecifier
)[];
export type MaintenanceApprovalFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  approvedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  approvedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  approverRoles?: FieldPolicy<any> | FieldReadFunction<any>;
  declinedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  declinedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  request?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedName?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MaintenanceApprovalPayloadKeySpecifier = (
  | 'error'
  | 'request'
  | 'success'
  | MaintenanceApprovalPayloadKeySpecifier
)[];
export type MaintenanceApprovalPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  request?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MaintenanceApprovalRequestKeySpecifier = (
  | 'amount'
  | 'description'
  | 'ownerId'
  | 'propertyId'
  | 'requestId'
  | 'unitId'
  | MaintenanceApprovalRequestKeySpecifier
)[];
export type MaintenanceApprovalRequestFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  requestId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ManagerKeySpecifier = (
  | 'account'
  | 'accountId'
  | 'email'
  | 'enabled'
  | 'fingerprints'
  | 'id'
  | 'locale'
  | 'name'
  | 'phone'
  | 'pushTokens'
  | 'role'
  | 'roles'
  | 'signature'
  | 'subscriptions'
  | 'unreadPushNotifications'
  | ManagerKeySpecifier
)[];
export type ManagerFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  locale?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  pushTokens?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
  roles?: FieldPolicy<any> | FieldReadFunction<any>;
  signature?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptions?: FieldPolicy<any> | FieldReadFunction<any>;
  unreadPushNotifications?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ManagerContactInformationKeySpecifier = (
  | 'email'
  | 'id'
  | 'name'
  | 'phone'
  | ManagerContactInformationKeySpecifier
)[];
export type ManagerContactInformationFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ManagerPayloadKeySpecifier = (
  | 'error'
  | 'manager'
  | 'success'
  | ManagerPayloadKeySpecifier
)[];
export type ManagerPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  manager?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MarkAppointmentChangesReadPayloadKeySpecifier = (
  | 'error'
  | 'request'
  | 'success'
  | MarkAppointmentChangesReadPayloadKeySpecifier
)[];
export type MarkAppointmentChangesReadPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  request?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageSubscriptionKeySpecifier = (
  | 'endpoints'
  | 'id'
  | MessageSubscriptionKeySpecifier
)[];
export type MessageSubscriptionFieldPolicy = {
  endpoints?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | 'abortBooksBatchTask'
  | 'abortDraftBatch'
  | 'accountEvent'
  | 'activateAutopay'
  | 'activateBooks'
  | 'activatePropertiesAutopay'
  | 'addAnnouncementReply'
  | 'addAutopayBank'
  | 'addBuildingInsurance'
  | 'addBuildingInsuranceClaim'
  | 'addBuildingInsuranceHistory'
  | 'addBuildingInsuranceHistoryClaim'
  | 'addClearable'
  | 'addClearingEntryTask'
  | 'addJournalEntry'
  | 'addLinkedBankAccounts'
  | 'addManagerRole'
  | 'addOwnerProperty'
  | 'addOwnerUnit'
  | 'addPaymentsBatchPrototypes'
  | 'addPropertyAutopayBankAccount'
  | 'addPropertyImages'
  | 'addPropertyInsurance'
  | 'addPropertyInsuranceClaim'
  | 'addPropertyInsuranceHistory'
  | 'addPropertyInsuranceHistoryClaim'
  | 'addPropertyKeyring'
  | 'addPropertyParking'
  | 'addPropertyStorage'
  | 'addPushToken'
  | 'addReconciliationConfig'
  | 'addRequestImages'
  | 'addResidencyEvent'
  | 'addResidencySecurityDepositStatus'
  | 'addScanApprovalTask'
  | 'addScanMatchingTask'
  | 'approveChargeApproval'
  | 'approvePaymentsApproval'
  | 'approveScanApproval'
  | 'assignKeyring'
  | 'assignParking'
  | 'assignRequestVisit'
  | 'assignStorage'
  | 'cancelBooksBatch'
  | 'cancelPublishAnnouncement'
  | 'commitBooksBatch'
  | 'completeReconciliation'
  | 'createAccountAutopayOnboardingUrl'
  | 'createAnnouncement'
  | 'createApplicationNote'
  | 'createBuilding'
  | 'createBuildingFloorplan'
  | 'createBuildingNote'
  | 'createCampaign'
  | 'createCampaignLink'
  | 'createCharge'
  | 'createChargeApproval'
  | 'createContact'
  | 'createDraftBatch'
  | 'createFee'
  | 'createGLAccount'
  | 'createHouseRulesTemplate'
  | 'createImageNote'
  | 'createInspectionUrl'
  | 'createJournalEntryNote'
  | 'createLeaseAgreementUrl'
  | 'createLeaseDocumentTemplate'
  | 'createLeaseInspection'
  | 'createMaintenanceApproval'
  | 'createManager'
  | 'createOperator'
  | 'createOwner'
  | 'createOwnerNote'
  | 'createPaymentsApproval'
  | 'createPaymentsBatch'
  | 'createProperty'
  | 'createPropertyFloorplan'
  | 'createPropertyNote'
  | 'createReportBundle'
  | 'createRequest'
  | 'createRequestCost'
  | 'createRequestNote'
  | 'createRequestRecurrence'
  | 'createRequestVisit'
  | 'createResidency'
  | 'createResidencyNote'
  | 'createRoutineInspection'
  | 'createSupplier'
  | 'createTeam'
  | 'createTemplate'
  | 'createTenantNote'
  | 'createUnitNote'
  | 'createUnitOnProperty'
  | 'createUnits'
  | 'deactivateAutopay'
  | 'deactivatePropertiesAutopay'
  | 'declineChargeApproval'
  | 'declinePaymentsApproval'
  | 'declineScanApproval'
  | 'deleteAnnouncement'
  | 'deleteApplicationNote'
  | 'deleteAutopayBank'
  | 'deleteBuilding'
  | 'deleteBuildingInsuranceClaim'
  | 'deleteBuildingInsuranceHistory'
  | 'deleteBuildingInsuranceHistoryClaim'
  | 'deleteBuildingNote'
  | 'deleteBundledReport'
  | 'deleteCharge'
  | 'deleteClearable'
  | 'deleteContact'
  | 'deleteFee'
  | 'deleteHouseRulesTemplate'
  | 'deleteImageNote'
  | 'deleteJournalEntry'
  | 'deleteJournalEntryNote'
  | 'deleteLeaseDocumentTemplate'
  | 'deleteLeaseInspection'
  | 'deleteLinkedBankAccount'
  | 'deleteManager'
  | 'deleteOperator'
  | 'deleteOwner'
  | 'deleteOwnerNote'
  | 'deleteProperty'
  | 'deletePropertyAutopayBankAccount'
  | 'deletePropertyImage'
  | 'deletePropertyInsuranceClaim'
  | 'deletePropertyInsuranceHistory'
  | 'deletePropertyInsuranceHistoryClaim'
  | 'deletePropertyNote'
  | 'deletePushTokens'
  | 'deleteReconciliation'
  | 'deleteReconciliationConfig'
  | 'deleteRecurrence'
  | 'deleteReportBundle'
  | 'deleteRequestCost'
  | 'deleteRequestImage'
  | 'deleteRequestNote'
  | 'deleteRequestRecurrence'
  | 'deleteResidency'
  | 'deleteResidencyEvent'
  | 'deleteResidencyNote'
  | 'deleteRoutineInspection'
  | 'deleteSupplier'
  | 'deleteTeam'
  | 'deleteTemplate'
  | 'deleteTenantNote'
  | 'deleteUnitNote'
  | 'deleteVisitBill'
  | 'draftReconciliation'
  | 'enableManager'
  | 'finalizeRequest'
  | 'finalizeRequestVisit'
  | 'iamMutation'
  | 'initiateBooksBatch'
  | 'inviteOwnerAutopay'
  | 'invitePayeeToEnroll'
  | 'inviteTenantAutopay'
  | 'linkSupplier'
  | 'markRequestAppointmentChangesRead'
  | 'moveUnitsToBuilding'
  | 'notifyNsfPayee'
  | 'postDraftBatch'
  | 'printJournalEntries'
  | 'publishAnnouncement'
  | 'putBudgets'
  | 'refreshRequestImages'
  | 'refundClearable'
  | 'removeManagerRole'
  | 'removeManagerSignature'
  | 'removeOwnerProperty'
  | 'removeOwnerUnit'
  | 'removePropertyKeyring'
  | 'removePropertyParking'
  | 'removePropertyStorage'
  | 'removeResidencyInsurance'
  | 'removeResidencyResident'
  | 'removeUnitFloorplan'
  | 'renameBuildingDocument'
  | 'renameOwnerDocument'
  | 'renamePropertyDocument'
  | 'renameRequestDocument'
  | 'renameResidencyDocument'
  | 'renameTenantDocument'
  | 'renameUnitDocument'
  | 'resendAutopayInviteToOwner'
  | 'resendAutopayInviteToTenant'
  | 'runReportSet'
  | 'saveLeaseAgreement'
  | 'scheduleRequestVisit'
  | 'sendLeaseOffer'
  | 'setBundledReportSharedFlag'
  | 'setClearableAutopayCollectionsEnabled'
  | 'setOwnerAutopayCollectionsEnabled'
  | 'setPropertyAutopayCollectionsEnabled'
  | 'setTenantAutopayCollectionsEnabled'
  | 'shareCampaign'
  | 'signLeaseAgreement'
  | 'startAccountAutopay'
  | 'startReconciliation'
  | 'terminateResidency'
  | 'testVoiceAnnouncement'
  | 'toggleBatchPrototypes'
  | 'transferJournalEntries'
  | 'unassignKeyring'
  | 'unassignParking'
  | 'unassignStorage'
  | 'updateAccount'
  | 'updateAccountAutopay'
  | 'updateAnnouncement'
  | 'updateApplication'
  | 'updateAutopayCollectionTypes'
  | 'updateAutopayEnrollmentToDeclined'
  | 'updateAutopayEnrollmentToInvited'
  | 'updateAutopayFeeInvoice'
  | 'updateAutopayNSFFees'
  | 'updateBatchPrototype'
  | 'updateBuilding'
  | 'updateBuildingAmenities'
  | 'updateBuildingFeatures'
  | 'updateBuildingInsurance'
  | 'updateBuildingInsuranceClaim'
  | 'updateBuildingInsuranceHistory'
  | 'updateBuildingInsuranceHistoryClaim'
  | 'updateBuildingSpecs'
  | 'updateBundledReportFlag'
  | 'updateCampaign'
  | 'updateCampaignStatus'
  | 'updateCharge'
  | 'updateClearable'
  | 'updateClearingEntryTask'
  | 'updateContact'
  | 'updateFee'
  | 'updateFinancialSettings'
  | 'updateFloorplan'
  | 'updateGLAccount'
  | 'updateGLChequePlacement'
  | 'updateHouseRulesTemplate'
  | 'updateJournalDrafts'
  | 'updateJournalEntry'
  | 'updateLeaseDocumentTemplate'
  | 'updateLeaseInspection'
  | 'updateManager'
  | 'updateManagerLocale'
  | 'updateManagerSubscriptions'
  | 'updateNextCheque'
  | 'updateOperator'
  | 'updateOperatorAutoAP'
  | 'updateOwner'
  | 'updateOwnerAutopay'
  | 'updateOwnerFinancials'
  | 'updateOwnerManagementFee'
  | 'updatePaymentsBatch'
  | 'updatePresetGLMapping'
  | 'updatePresetRecurrence'
  | 'updateProperty'
  | 'updatePropertyAmenities'
  | 'updatePropertyContractors'
  | 'updatePropertyFeatures'
  | 'updatePropertyInsurance'
  | 'updatePropertyInsuranceClaim'
  | 'updatePropertyInsuranceHistory'
  | 'updatePropertyInsuranceHistoryClaim'
  | 'updatePropertySpecs'
  | 'updatePropertySuppliers'
  | 'updateReconciliation'
  | 'updateReconciliationConfig'
  | 'updateReconciliationJournals'
  | 'updateReportBundle'
  | 'updateRequest'
  | 'updateRequestChecklistItem'
  | 'updateRequestCost'
  | 'updateRequestRecurrence'
  | 'updateRequestVisit'
  | 'updateResidency'
  | 'updateResidencyEffects'
  | 'updateResidencyEvent'
  | 'updateResidencyInsurance'
  | 'updateResidencyRefundableDeposits'
  | 'updateResidencyResidentFinancials'
  | 'updateRoutineInspection'
  | 'updateScanApprovalRequest'
  | 'updateSupplier'
  | 'updateSupplierAutoAP'
  | 'updateTeam'
  | 'updateTemplate'
  | 'updateTenantAutopay'
  | 'updateTenantFinancials'
  | 'updateTenantRecord'
  | 'updateUnit'
  | 'updateUnitFloorplan'
  | 'updateUnreadPushNotifications'
  | 'updateVisitBillMarkup'
  | 'upsertLeaseInspection'
  | 'upsertRoutineInspection'
  | 'voidJournalEntry'
  | 'welcomeTenant'
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  abortBooksBatchTask?: FieldPolicy<any> | FieldReadFunction<any>;
  abortDraftBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  accountEvent?: FieldPolicy<any> | FieldReadFunction<any>;
  activateAutopay?: FieldPolicy<any> | FieldReadFunction<any>;
  activateBooks?: FieldPolicy<any> | FieldReadFunction<any>;
  activatePropertiesAutopay?: FieldPolicy<any> | FieldReadFunction<any>;
  addAnnouncementReply?: FieldPolicy<any> | FieldReadFunction<any>;
  addAutopayBank?: FieldPolicy<any> | FieldReadFunction<any>;
  addBuildingInsurance?: FieldPolicy<any> | FieldReadFunction<any>;
  addBuildingInsuranceClaim?: FieldPolicy<any> | FieldReadFunction<any>;
  addBuildingInsuranceHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  addBuildingInsuranceHistoryClaim?: FieldPolicy<any> | FieldReadFunction<any>;
  addClearable?: FieldPolicy<any> | FieldReadFunction<any>;
  addClearingEntryTask?: FieldPolicy<any> | FieldReadFunction<any>;
  addJournalEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  addLinkedBankAccounts?: FieldPolicy<any> | FieldReadFunction<any>;
  addManagerRole?: FieldPolicy<any> | FieldReadFunction<any>;
  addOwnerProperty?: FieldPolicy<any> | FieldReadFunction<any>;
  addOwnerUnit?: FieldPolicy<any> | FieldReadFunction<any>;
  addPaymentsBatchPrototypes?: FieldPolicy<any> | FieldReadFunction<any>;
  addPropertyAutopayBankAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  addPropertyImages?: FieldPolicy<any> | FieldReadFunction<any>;
  addPropertyInsurance?: FieldPolicy<any> | FieldReadFunction<any>;
  addPropertyInsuranceClaim?: FieldPolicy<any> | FieldReadFunction<any>;
  addPropertyInsuranceHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  addPropertyInsuranceHistoryClaim?: FieldPolicy<any> | FieldReadFunction<any>;
  addPropertyKeyring?: FieldPolicy<any> | FieldReadFunction<any>;
  addPropertyParking?: FieldPolicy<any> | FieldReadFunction<any>;
  addPropertyStorage?: FieldPolicy<any> | FieldReadFunction<any>;
  addPushToken?: FieldPolicy<any> | FieldReadFunction<any>;
  addReconciliationConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  addRequestImages?: FieldPolicy<any> | FieldReadFunction<any>;
  addResidencyEvent?: FieldPolicy<any> | FieldReadFunction<any>;
  addResidencySecurityDepositStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  addScanApprovalTask?: FieldPolicy<any> | FieldReadFunction<any>;
  addScanMatchingTask?: FieldPolicy<any> | FieldReadFunction<any>;
  approveChargeApproval?: FieldPolicy<any> | FieldReadFunction<any>;
  approvePaymentsApproval?: FieldPolicy<any> | FieldReadFunction<any>;
  approveScanApproval?: FieldPolicy<any> | FieldReadFunction<any>;
  assignKeyring?: FieldPolicy<any> | FieldReadFunction<any>;
  assignParking?: FieldPolicy<any> | FieldReadFunction<any>;
  assignRequestVisit?: FieldPolicy<any> | FieldReadFunction<any>;
  assignStorage?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelBooksBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelPublishAnnouncement?: FieldPolicy<any> | FieldReadFunction<any>;
  commitBooksBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  completeReconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  createAccountAutopayOnboardingUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  createAnnouncement?: FieldPolicy<any> | FieldReadFunction<any>;
  createApplicationNote?: FieldPolicy<any> | FieldReadFunction<any>;
  createBuilding?: FieldPolicy<any> | FieldReadFunction<any>;
  createBuildingFloorplan?: FieldPolicy<any> | FieldReadFunction<any>;
  createBuildingNote?: FieldPolicy<any> | FieldReadFunction<any>;
  createCampaign?: FieldPolicy<any> | FieldReadFunction<any>;
  createCampaignLink?: FieldPolicy<any> | FieldReadFunction<any>;
  createCharge?: FieldPolicy<any> | FieldReadFunction<any>;
  createChargeApproval?: FieldPolicy<any> | FieldReadFunction<any>;
  createContact?: FieldPolicy<any> | FieldReadFunction<any>;
  createDraftBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  createFee?: FieldPolicy<any> | FieldReadFunction<any>;
  createGLAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  createHouseRulesTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  createImageNote?: FieldPolicy<any> | FieldReadFunction<any>;
  createInspectionUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  createJournalEntryNote?: FieldPolicy<any> | FieldReadFunction<any>;
  createLeaseAgreementUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  createLeaseDocumentTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  createLeaseInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  createMaintenanceApproval?: FieldPolicy<any> | FieldReadFunction<any>;
  createManager?: FieldPolicy<any> | FieldReadFunction<any>;
  createOperator?: FieldPolicy<any> | FieldReadFunction<any>;
  createOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  createOwnerNote?: FieldPolicy<any> | FieldReadFunction<any>;
  createPaymentsApproval?: FieldPolicy<any> | FieldReadFunction<any>;
  createPaymentsBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  createProperty?: FieldPolicy<any> | FieldReadFunction<any>;
  createPropertyFloorplan?: FieldPolicy<any> | FieldReadFunction<any>;
  createPropertyNote?: FieldPolicy<any> | FieldReadFunction<any>;
  createReportBundle?: FieldPolicy<any> | FieldReadFunction<any>;
  createRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  createRequestCost?: FieldPolicy<any> | FieldReadFunction<any>;
  createRequestNote?: FieldPolicy<any> | FieldReadFunction<any>;
  createRequestRecurrence?: FieldPolicy<any> | FieldReadFunction<any>;
  createRequestVisit?: FieldPolicy<any> | FieldReadFunction<any>;
  createResidency?: FieldPolicy<any> | FieldReadFunction<any>;
  createResidencyNote?: FieldPolicy<any> | FieldReadFunction<any>;
  createRoutineInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  createSupplier?: FieldPolicy<any> | FieldReadFunction<any>;
  createTeam?: FieldPolicy<any> | FieldReadFunction<any>;
  createTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  createTenantNote?: FieldPolicy<any> | FieldReadFunction<any>;
  createUnitNote?: FieldPolicy<any> | FieldReadFunction<any>;
  createUnitOnProperty?: FieldPolicy<any> | FieldReadFunction<any>;
  createUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivateAutopay?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivatePropertiesAutopay?: FieldPolicy<any> | FieldReadFunction<any>;
  declineChargeApproval?: FieldPolicy<any> | FieldReadFunction<any>;
  declinePaymentsApproval?: FieldPolicy<any> | FieldReadFunction<any>;
  declineScanApproval?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteAnnouncement?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteApplicationNote?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteAutopayBank?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteBuilding?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteBuildingInsuranceClaim?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteBuildingInsuranceHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteBuildingInsuranceHistoryClaim?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteBuildingNote?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteBundledReport?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteCharge?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteClearable?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteContact?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteFee?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteHouseRulesTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteImageNote?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteJournalEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteJournalEntryNote?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteLeaseDocumentTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteLeaseInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteLinkedBankAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteManager?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteOperator?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteOwnerNote?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteProperty?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePropertyAutopayBankAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePropertyImage?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePropertyInsuranceClaim?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePropertyInsuranceHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePropertyInsuranceHistoryClaim?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePropertyNote?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePushTokens?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteReconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteReconciliationConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRecurrence?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteReportBundle?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRequestCost?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRequestImage?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRequestNote?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRequestRecurrence?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteResidency?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteResidencyEvent?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteResidencyNote?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRoutineInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteSupplier?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTeam?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTenantNote?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUnitNote?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteVisitBill?: FieldPolicy<any> | FieldReadFunction<any>;
  draftReconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  enableManager?: FieldPolicy<any> | FieldReadFunction<any>;
  finalizeRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  finalizeRequestVisit?: FieldPolicy<any> | FieldReadFunction<any>;
  iamMutation?: FieldPolicy<any> | FieldReadFunction<any>;
  initiateBooksBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  inviteOwnerAutopay?: FieldPolicy<any> | FieldReadFunction<any>;
  invitePayeeToEnroll?: FieldPolicy<any> | FieldReadFunction<any>;
  inviteTenantAutopay?: FieldPolicy<any> | FieldReadFunction<any>;
  linkSupplier?: FieldPolicy<any> | FieldReadFunction<any>;
  markRequestAppointmentChangesRead?: FieldPolicy<any> | FieldReadFunction<any>;
  moveUnitsToBuilding?: FieldPolicy<any> | FieldReadFunction<any>;
  notifyNsfPayee?: FieldPolicy<any> | FieldReadFunction<any>;
  postDraftBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  printJournalEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  publishAnnouncement?: FieldPolicy<any> | FieldReadFunction<any>;
  putBudgets?: FieldPolicy<any> | FieldReadFunction<any>;
  refreshRequestImages?: FieldPolicy<any> | FieldReadFunction<any>;
  refundClearable?: FieldPolicy<any> | FieldReadFunction<any>;
  removeManagerRole?: FieldPolicy<any> | FieldReadFunction<any>;
  removeManagerSignature?: FieldPolicy<any> | FieldReadFunction<any>;
  removeOwnerProperty?: FieldPolicy<any> | FieldReadFunction<any>;
  removeOwnerUnit?: FieldPolicy<any> | FieldReadFunction<any>;
  removePropertyKeyring?: FieldPolicy<any> | FieldReadFunction<any>;
  removePropertyParking?: FieldPolicy<any> | FieldReadFunction<any>;
  removePropertyStorage?: FieldPolicy<any> | FieldReadFunction<any>;
  removeResidencyInsurance?: FieldPolicy<any> | FieldReadFunction<any>;
  removeResidencyResident?: FieldPolicy<any> | FieldReadFunction<any>;
  removeUnitFloorplan?: FieldPolicy<any> | FieldReadFunction<any>;
  renameBuildingDocument?: FieldPolicy<any> | FieldReadFunction<any>;
  renameOwnerDocument?: FieldPolicy<any> | FieldReadFunction<any>;
  renamePropertyDocument?: FieldPolicy<any> | FieldReadFunction<any>;
  renameRequestDocument?: FieldPolicy<any> | FieldReadFunction<any>;
  renameResidencyDocument?: FieldPolicy<any> | FieldReadFunction<any>;
  renameTenantDocument?: FieldPolicy<any> | FieldReadFunction<any>;
  renameUnitDocument?: FieldPolicy<any> | FieldReadFunction<any>;
  resendAutopayInviteToOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  resendAutopayInviteToTenant?: FieldPolicy<any> | FieldReadFunction<any>;
  runReportSet?: FieldPolicy<any> | FieldReadFunction<any>;
  saveLeaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  scheduleRequestVisit?: FieldPolicy<any> | FieldReadFunction<any>;
  sendLeaseOffer?: FieldPolicy<any> | FieldReadFunction<any>;
  setBundledReportSharedFlag?: FieldPolicy<any> | FieldReadFunction<any>;
  setClearableAutopayCollectionsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  setOwnerAutopayCollectionsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  setPropertyAutopayCollectionsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  setTenantAutopayCollectionsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  shareCampaign?: FieldPolicy<any> | FieldReadFunction<any>;
  signLeaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  startAccountAutopay?: FieldPolicy<any> | FieldReadFunction<any>;
  startReconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  terminateResidency?: FieldPolicy<any> | FieldReadFunction<any>;
  testVoiceAnnouncement?: FieldPolicy<any> | FieldReadFunction<any>;
  toggleBatchPrototypes?: FieldPolicy<any> | FieldReadFunction<any>;
  transferJournalEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  unassignKeyring?: FieldPolicy<any> | FieldReadFunction<any>;
  unassignParking?: FieldPolicy<any> | FieldReadFunction<any>;
  unassignStorage?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAccountAutopay?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAnnouncement?: FieldPolicy<any> | FieldReadFunction<any>;
  updateApplication?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAutopayCollectionTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAutopayEnrollmentToDeclined?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAutopayEnrollmentToInvited?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAutopayFeeInvoice?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAutopayNSFFees?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBatchPrototype?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBuilding?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBuildingAmenities?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBuildingFeatures?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBuildingInsurance?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBuildingInsuranceClaim?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBuildingInsuranceHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBuildingInsuranceHistoryClaim?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBuildingSpecs?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBundledReportFlag?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCampaign?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCampaignStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCharge?: FieldPolicy<any> | FieldReadFunction<any>;
  updateClearable?: FieldPolicy<any> | FieldReadFunction<any>;
  updateClearingEntryTask?: FieldPolicy<any> | FieldReadFunction<any>;
  updateContact?: FieldPolicy<any> | FieldReadFunction<any>;
  updateFee?: FieldPolicy<any> | FieldReadFunction<any>;
  updateFinancialSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  updateFloorplan?: FieldPolicy<any> | FieldReadFunction<any>;
  updateGLAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  updateGLChequePlacement?: FieldPolicy<any> | FieldReadFunction<any>;
  updateHouseRulesTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  updateJournalDrafts?: FieldPolicy<any> | FieldReadFunction<any>;
  updateJournalEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  updateLeaseDocumentTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  updateLeaseInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  updateManager?: FieldPolicy<any> | FieldReadFunction<any>;
  updateManagerLocale?: FieldPolicy<any> | FieldReadFunction<any>;
  updateManagerSubscriptions?: FieldPolicy<any> | FieldReadFunction<any>;
  updateNextCheque?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOperator?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOperatorAutoAP?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOwnerAutopay?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOwnerFinancials?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOwnerManagementFee?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePaymentsBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePresetGLMapping?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePresetRecurrence?: FieldPolicy<any> | FieldReadFunction<any>;
  updateProperty?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePropertyAmenities?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePropertyContractors?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePropertyFeatures?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePropertyInsurance?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePropertyInsuranceClaim?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePropertyInsuranceHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePropertyInsuranceHistoryClaim?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePropertySpecs?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePropertySuppliers?: FieldPolicy<any> | FieldReadFunction<any>;
  updateReconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  updateReconciliationConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  updateReconciliationJournals?: FieldPolicy<any> | FieldReadFunction<any>;
  updateReportBundle?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRequestChecklistItem?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRequestCost?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRequestRecurrence?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRequestVisit?: FieldPolicy<any> | FieldReadFunction<any>;
  updateResidency?: FieldPolicy<any> | FieldReadFunction<any>;
  updateResidencyEffects?: FieldPolicy<any> | FieldReadFunction<any>;
  updateResidencyEvent?: FieldPolicy<any> | FieldReadFunction<any>;
  updateResidencyInsurance?: FieldPolicy<any> | FieldReadFunction<any>;
  updateResidencyRefundableDeposits?: FieldPolicy<any> | FieldReadFunction<any>;
  updateResidencyResidentFinancials?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRoutineInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  updateScanApprovalRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSupplier?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSupplierAutoAP?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTeam?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTenantAutopay?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTenantFinancials?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTenantRecord?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUnit?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUnitFloorplan?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUnreadPushNotifications?: FieldPolicy<any> | FieldReadFunction<any>;
  updateVisitBillMarkup?: FieldPolicy<any> | FieldReadFunction<any>;
  upsertLeaseInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  upsertRoutineInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  voidJournalEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  welcomeTenant?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NoteKeySpecifier = (
  | 'createdName'
  | 'createdSub'
  | 'createdZ'
  | 'id'
  | 'source'
  | 'target'
  | 'text'
  | NoteKeySpecifier
)[];
export type NoteFieldPolicy = {
  createdName?: FieldPolicy<any> | FieldReadFunction<any>;
  createdSub?: FieldPolicy<any> | FieldReadFunction<any>;
  createdZ?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
  target?: FieldPolicy<any> | FieldReadFunction<any>;
  text?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OnsiteManagerKeySpecifier = (
  | 'address'
  | 'email'
  | 'entityType'
  | 'id'
  | 'name'
  | 'phone'
  | OnsiteManagerKeySpecifier
)[];
export type OnsiteManagerFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  entityType?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OpenSearchKeySpecifier = ('task' | OpenSearchKeySpecifier)[];
export type OpenSearchFieldPolicy = {
  task?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OpenSearchTaskKeySpecifier = (
  | 'cancellable'
  | 'cancelled'
  | 'id'
  | 'status'
  | OpenSearchTaskKeySpecifier
)[];
export type OpenSearchTaskFieldPolicy = {
  cancellable?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelled?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OpenSearchTaskResponseKeySpecifier = (
  | 'created'
  | 'deleted'
  | 'took'
  | 'total'
  | 'updated'
  | OpenSearchTaskResponseKeySpecifier
)[];
export type OpenSearchTaskResponseFieldPolicy = {
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  deleted?: FieldPolicy<any> | FieldReadFunction<any>;
  took?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
  updated?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OpenSearchTaskResultKeySpecifier = (
  | 'completed'
  | 'response'
  | 'task'
  | OpenSearchTaskResultKeySpecifier
)[];
export type OpenSearchTaskResultFieldPolicy = {
  completed?: FieldPolicy<any> | FieldReadFunction<any>;
  response?: FieldPolicy<any> | FieldReadFunction<any>;
  task?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OpenSearchTaskStatusKeySpecifier = (
  | 'created'
  | 'deleted'
  | 'total'
  | 'updated'
  | OpenSearchTaskStatusKeySpecifier
)[];
export type OpenSearchTaskStatusFieldPolicy = {
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  deleted?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
  updated?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OperatorKeySpecifier = (
  | 'account'
  | 'accountId'
  | 'address'
  | 'autopayAccount'
  | 'autopayClientId'
  | 'autopayInstitution'
  | 'autopayStatus'
  | 'autopayStatusUpdatedZ'
  | 'autopayTransit'
  | 'availability'
  | 'businessNumber'
  | 'email'
  | 'enabled'
  | 'exceptions'
  | 'expenseGlId'
  | 'expiryCOI'
  | 'fingerprints'
  | 'gcalBusy'
  | 'hasCOI'
  | 'hasNonFinalizedVisits'
  | 'id'
  | 'location'
  | 'name'
  | 'operatorType'
  | 'phone'
  | 'requests'
  | 'requestsCompleted'
  | 'skills'
  | 'timezone'
  | 'totalAssignedVisits'
  | 'totalCancelledVisits'
  | 'totalCompletedVisits'
  | 'totalStartedVisits'
  | 'website'
  | OperatorKeySpecifier
)[];
export type OperatorFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayClientId?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayInstitution?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatusUpdatedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayTransit?: FieldPolicy<any> | FieldReadFunction<any>;
  availability?: FieldPolicy<any> | FieldReadFunction<any>;
  businessNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  exceptions?: FieldPolicy<any> | FieldReadFunction<any>;
  expenseGlId?: FieldPolicy<any> | FieldReadFunction<any>;
  expiryCOI?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  gcalBusy?: FieldPolicy<any> | FieldReadFunction<any>;
  hasCOI?: FieldPolicy<any> | FieldReadFunction<any>;
  hasNonFinalizedVisits?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operatorType?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  requests?: FieldPolicy<any> | FieldReadFunction<any>;
  requestsCompleted?: FieldPolicy<any> | FieldReadFunction<any>;
  skills?: FieldPolicy<any> | FieldReadFunction<any>;
  timezone?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAssignedVisits?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCancelledVisits?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCompletedVisits?: FieldPolicy<any> | FieldReadFunction<any>;
  totalStartedVisits?: FieldPolicy<any> | FieldReadFunction<any>;
  website?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OperatorPayloadKeySpecifier = (
  | 'error'
  | 'operator'
  | 'success'
  | OperatorPayloadKeySpecifier
)[];
export type OperatorPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  operator?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OperatorWindowKeySpecifier = ('operator' | 'window' | OperatorWindowKeySpecifier)[];
export type OperatorWindowFieldPolicy = {
  operator?: FieldPolicy<any> | FieldReadFunction<any>;
  window?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OwnerKeySpecifier = (
  | 'accountId'
  | 'address'
  | 'assetFees'
  | 'autopayAccount'
  | 'autopayClientId'
  | 'autopayCollectionsEnabled'
  | 'autopayCollectionsEnabledZ'
  | 'autopayInstitution'
  | 'autopayInvitedZ'
  | 'autopayStatus'
  | 'autopayStatusUpdatedZ'
  | 'autopayTransit'
  | 'booksSinceZ'
  | 'contacts'
  | 'correspondenceMethod'
  | 'dob'
  | 'documents'
  | 'email'
  | 'emails'
  | 'emergencyContacts'
  | 'enabled'
  | 'enrollments'
  | 'feeAmount'
  | 'feeRate'
  | 'flatFee'
  | 'gstFiling'
  | 'gstNumber'
  | 'history'
  | 'id'
  | 'listProperties'
  | 'listUnits'
  | 'mailingAddress'
  | 'name'
  | 'notes'
  | 'nrtFeeAmount'
  | 'nrtFeeRate'
  | 'padAccount'
  | 'padAmount'
  | 'padInstitution'
  | 'padTransit'
  | 'pets'
  | 'phone'
  | 'phones'
  | 'preferredPaymentMethod'
  | 'profile'
  | 'profileId'
  | 'properties'
  | 'propertyCount'
  | 'propertyHeaders'
  | 'propertyIds'
  | 'propertyOptions'
  | 'trackCondoFees'
  | 'unitCount'
  | 'unitHeaders'
  | 'unitIds'
  | 'units'
  | 'vehicles'
  | 'yearEndMonth'
  | OwnerKeySpecifier
)[];
export type OwnerFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  assetFees?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayClientId?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayCollectionsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayCollectionsEnabledZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayInstitution?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayInvitedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatusUpdatedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayTransit?: FieldPolicy<any> | FieldReadFunction<any>;
  booksSinceZ?: FieldPolicy<any> | FieldReadFunction<any>;
  contacts?: FieldPolicy<any> | FieldReadFunction<any>;
  correspondenceMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  dob?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  emails?: FieldPolicy<any> | FieldReadFunction<any>;
  emergencyContacts?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  enrollments?: FieldPolicy<any> | FieldReadFunction<any>;
  feeAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  feeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  flatFee?: FieldPolicy<any> | FieldReadFunction<any>;
  gstFiling?: FieldPolicy<any> | FieldReadFunction<any>;
  gstNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  history?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  listProperties?: FieldPolicy<any> | FieldReadFunction<any>;
  listUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  mailingAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  nrtFeeAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  nrtFeeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  padAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  padAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  padInstitution?: FieldPolicy<any> | FieldReadFunction<any>;
  padTransit?: FieldPolicy<any> | FieldReadFunction<any>;
  pets?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  phones?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredPaymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  profile?: FieldPolicy<any> | FieldReadFunction<any>;
  profileId?: FieldPolicy<any> | FieldReadFunction<any>;
  properties?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyCount?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyHeaders?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyIds?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  trackCondoFees?: FieldPolicy<any> | FieldReadFunction<any>;
  unitCount?: FieldPolicy<any> | FieldReadFunction<any>;
  unitHeaders?: FieldPolicy<any> | FieldReadFunction<any>;
  unitIds?: FieldPolicy<any> | FieldReadFunction<any>;
  units?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicles?: FieldPolicy<any> | FieldReadFunction<any>;
  yearEndMonth?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OwnerConnectionKeySpecifier = ('items' | 'nextToken' | OwnerConnectionKeySpecifier)[];
export type OwnerConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OwnerEdgeKeySpecifier = ('cursor' | 'node' | OwnerEdgeKeySpecifier)[];
export type OwnerEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OwnerHistoryEntryKeySpecifier = (
  | 'assetId'
  | 'assetType'
  | 'commercialLeasedUnits'
  | 'createdZ'
  | 'lease'
  | 'leasedUnits'
  | 'status'
  | OwnerHistoryEntryKeySpecifier
)[];
export type OwnerHistoryEntryFieldPolicy = {
  assetId?: FieldPolicy<any> | FieldReadFunction<any>;
  assetType?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialLeasedUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  createdZ?: FieldPolicy<any> | FieldReadFunction<any>;
  lease?: FieldPolicy<any> | FieldReadFunction<any>;
  leasedUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OwnerPayloadKeySpecifier = ('error' | 'owner' | 'success' | OwnerPayloadKeySpecifier)[];
export type OwnerPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  owner?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OwnersPageKeySpecifier = (
  | 'edges'
  | 'pageInfo'
  | 'totalCount'
  | OwnersPageKeySpecifier
)[];
export type OwnersPageFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ParkingStallKeySpecifier = ('name' | 'unitId' | ParkingStallKeySpecifier)[];
export type ParkingStallFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PayeeClearingAmountKeySpecifier = (
  | 'amount'
  | 'buildingKey'
  | 'buildingName'
  | 'clearable'
  | 'clearableId'
  | 'description'
  | 'due'
  | 'glId'
  | 'ownerId'
  | 'ownerName'
  | 'posted'
  | 'propertyId'
  | 'propertyKey'
  | 'propertyName'
  | 'ref'
  | 'unitId'
  | 'unitName'
  | PayeeClearingAmountKeySpecifier
)[];
export type PayeeClearingAmountFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingKey?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingName?: FieldPolicy<any> | FieldReadFunction<any>;
  clearable?: FieldPolicy<any> | FieldReadFunction<any>;
  clearableId?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  due?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerName?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyKey?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyName?: FieldPolicy<any> | FieldReadFunction<any>;
  ref?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentDetailsKeySpecifier = (
  | 'padAccount'
  | 'padAmount'
  | 'padInstitution'
  | 'padTransit'
  | 'preferredPaymentMethod'
  | PaymentDetailsKeySpecifier
)[];
export type PaymentDetailsFieldPolicy = {
  padAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  padAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  padInstitution?: FieldPolicy<any> | FieldReadFunction<any>;
  padTransit?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredPaymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentPayeeKeySpecifier = (
  | 'amount'
  | 'description'
  | 'glId'
  | 'payee'
  | 'payeeClearingAmounts'
  | 'payeeId'
  | 'payeeName'
  | 'ref'
  | PaymentPayeeKeySpecifier
)[];
export type PaymentPayeeFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  payee?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeClearingAmounts?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeId?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeName?: FieldPolicy<any> | FieldReadFunction<any>;
  ref?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentPrototypeKeySpecifier = (
  | 'amount'
  | 'bankId'
  | 'bankName'
  | 'batchId'
  | 'billingName'
  | 'id'
  | 'payees'
  | 'paymentMethod'
  | 'posted'
  | PaymentPrototypeKeySpecifier
)[];
export type PaymentPrototypeFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bankId?: FieldPolicy<any> | FieldReadFunction<any>;
  bankName?: FieldPolicy<any> | FieldReadFunction<any>;
  batchId?: FieldPolicy<any> | FieldReadFunction<any>;
  billingName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  payees?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsApprovalKeySpecifier = (
  | 'accountId'
  | 'approvedSub'
  | 'approvedZ'
  | 'approverRoles'
  | 'declinedSub'
  | 'declinedZ'
  | 'id'
  | 'request'
  | 'requestedName'
  | 'requestedSub'
  | 'requestedZ'
  | 'status'
  | PaymentsApprovalKeySpecifier
)[];
export type PaymentsApprovalFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  approvedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  approvedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  approverRoles?: FieldPolicy<any> | FieldReadFunction<any>;
  declinedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  declinedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  request?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedName?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsApprovalPayloadKeySpecifier = (
  | 'approval'
  | 'error'
  | 'success'
  | PaymentsApprovalPayloadKeySpecifier
)[];
export type PaymentsApprovalPayloadFieldPolicy = {
  approval?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsApprovalRequestKeySpecifier = (
  | 'batchId'
  | 'itemCount'
  | 'listPayments'
  | 'paymentMethod'
  | 'posted'
  | 'total'
  | PaymentsApprovalRequestKeySpecifier
)[];
export type PaymentsApprovalRequestFieldPolicy = {
  batchId?: FieldPolicy<any> | FieldReadFunction<any>;
  itemCount?: FieldPolicy<any> | FieldReadFunction<any>;
  listPayments?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsBatchKeySpecifier = (
  | 'approvalId'
  | 'errorMessage'
  | 'id'
  | 'itemCount'
  | 'listPrototypes'
  | 'paymentMethod'
  | 'posted'
  | 'presetId'
  | 'status'
  | 'total'
  | PaymentsBatchKeySpecifier
)[];
export type PaymentsBatchFieldPolicy = {
  approvalId?: FieldPolicy<any> | FieldReadFunction<any>;
  errorMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  itemCount?: FieldPolicy<any> | FieldReadFunction<any>;
  listPrototypes?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
  presetId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsBatchPayloadKeySpecifier = (
  | 'error'
  | 'paymentsBatch'
  | 'success'
  | PaymentsBatchPayloadKeySpecifier
)[];
export type PaymentsBatchPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentsBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsPrototypeConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | PaymentsPrototypeConnectionKeySpecifier
)[];
export type PaymentsPrototypeConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PetKeySpecifier = ('count' | 'details' | 'type' | PetKeySpecifier)[];
export type PetFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  details?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PetDetailsKeySpecifier = (
  | 'breed'
  | 'color'
  | 'isService'
  | 'license'
  | 'name'
  | 'type'
  | 'weight'
  | PetDetailsKeySpecifier
)[];
export type PetDetailsFieldPolicy = {
  breed?: FieldPolicy<any> | FieldReadFunction<any>;
  color?: FieldPolicy<any> | FieldReadFunction<any>;
  isService?: FieldPolicy<any> | FieldReadFunction<any>;
  license?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  weight?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PostDraftJEBatchPayloadKeySpecifier = (
  | 'batch'
  | 'error'
  | 'success'
  | PostDraftJEBatchPayloadKeySpecifier
)[];
export type PostDraftJEBatchPayloadFieldPolicy = {
  batch?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PresetKeySpecifier = (
  | 'category'
  | 'description'
  | 'glMapping'
  | 'group'
  | 'icon'
  | 'id'
  | 'inputFields'
  | 'lastPosted'
  | 'name'
  | 'recurrence'
  | 'roles'
  | 'type'
  | 'version'
  | 'visibleExpression'
  | PresetKeySpecifier
)[];
export type PresetFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  glMapping?: FieldPolicy<any> | FieldReadFunction<any>;
  group?: FieldPolicy<any> | FieldReadFunction<any>;
  icon?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inputFields?: FieldPolicy<any> | FieldReadFunction<any>;
  lastPosted?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  recurrence?: FieldPolicy<any> | FieldReadFunction<any>;
  roles?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
  visibleExpression?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PresetGroupKeySpecifier = ('name' | 'order' | PresetGroupKeySpecifier)[];
export type PresetGroupFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PresetIconKeySpecifier = ('color' | 'name' | PresetIconKeySpecifier)[];
export type PresetIconFieldPolicy = {
  color?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PresetInputFieldKeySpecifier = (
  | 'defaultExpression'
  | 'id'
  | 'label'
  | 'options'
  | 'required'
  | 'type'
  | PresetInputFieldKeySpecifier
)[];
export type PresetInputFieldFieldPolicy = {
  defaultExpression?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  options?: FieldPolicy<any> | FieldReadFunction<any>;
  required?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PresetOptionKeySpecifier = ('label' | 'value' | PresetOptionKeySpecifier)[];
export type PresetOptionFieldPolicy = {
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PresetPayloadKeySpecifier = (
  | 'error'
  | 'preset'
  | 'success'
  | PresetPayloadKeySpecifier
)[];
export type PresetPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  preset?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PresetRecurrenceKeySpecifier = (
  | 'enabled'
  | 'locked'
  | 'parameters'
  | 'postedOffset'
  | 'rrule'
  | PresetRecurrenceKeySpecifier
)[];
export type PresetRecurrenceFieldPolicy = {
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  locked?: FieldPolicy<any> | FieldReadFunction<any>;
  parameters?: FieldPolicy<any> | FieldReadFunction<any>;
  postedOffset?: FieldPolicy<any> | FieldReadFunction<any>;
  rrule?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProRatedEffectKeySpecifier = (
  | 'description'
  | 'effect'
  | 'feeId'
  | 'glId'
  | 'id'
  | 'incentiveId'
  | 'startZ'
  | ProRatedEffectKeySpecifier
)[];
export type ProRatedEffectFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  effect?: FieldPolicy<any> | FieldReadFunction<any>;
  feeId?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  incentiveId?: FieldPolicy<any> | FieldReadFunction<any>;
  startZ?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProfileKeySpecifier = (
  | 'addressHistory'
  | 'assetsShares'
  | 'dob'
  | 'email'
  | 'emails'
  | 'household'
  | 'id'
  | 'logins'
  | 'name'
  | 'otherIncome'
  | 'phone'
  | 'workHistory'
  | ProfileKeySpecifier
)[];
export type ProfileFieldPolicy = {
  addressHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  assetsShares?: FieldPolicy<any> | FieldReadFunction<any>;
  dob?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  emails?: FieldPolicy<any> | FieldReadFunction<any>;
  household?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  logins?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  otherIncome?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  workHistory?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProfileLoginKeySpecifier = ('email' | 'id' | 'status' | ProfileLoginKeySpecifier)[];
export type ProfileLoginFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertiesPageKeySpecifier = (
  | 'edges'
  | 'pageInfo'
  | 'totalCount'
  | PropertiesPageKeySpecifier
)[];
export type PropertiesPageFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertyKeySpecifier = (
  | 'account'
  | 'accountId'
  | 'address'
  | 'amenitiesSetting'
  | 'autopayActivatedZ'
  | 'autopayBankAccounts'
  | 'autopayCollectionsEnabled'
  | 'autopayCollectionsEnabledZ'
  | 'autopayDeactivatedZ'
  | 'autopayStatus'
  | 'autopayStatusHistory'
  | 'availabilityWindows'
  | 'buildings'
  | 'campaigns'
  | 'commercialLeaseDocumentTemplateId'
  | 'commercialLeasedUnits'
  | 'commercialUnits'
  | 'commonAreas'
  | 'condoUnits'
  | 'contractorIds'
  | 'contractors'
  | 'creatingUnits'
  | 'currentResidencies'
  | 'currentResidents'
  | 'documents'
  | 'enabled'
  | 'features'
  | 'fingerprints'
  | 'floorplanIds'
  | 'floorplans'
  | 'houseRules'
  | 'id'
  | 'imageKey'
  | 'imageUrl'
  | 'images'
  | 'insurancePolicy'
  | 'insurancePolicyHistory'
  | 'journalCount'
  | 'key'
  | 'keyring'
  | 'leasedUnits'
  | 'legalDescription'
  | 'name'
  | 'notes'
  | 'onsiteManager'
  | 'operatorWindows'
  | 'owner'
  | 'ownerId'
  | 'ownerUnits'
  | 'pageCurrentResidencies'
  | 'pageUnits'
  | 'parking'
  | 'phone'
  | 'rentalLeaseDocumentTemplateId'
  | 'rentalUnits'
  | 'reserveAmount'
  | 'specs'
  | 'storage'
  | 'supplierIds'
  | 'suppliers'
  | 'timezone'
  | 'totalUnitFactor'
  | 'totalUnits'
  | 'types'
  | 'units'
  | 'window'
  | PropertyKeySpecifier
)[];
export type PropertyFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  amenitiesSetting?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayActivatedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayBankAccounts?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayCollectionsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayCollectionsEnabledZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayDeactivatedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatusHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  availabilityWindows?: FieldPolicy<any> | FieldReadFunction<any>;
  buildings?: FieldPolicy<any> | FieldReadFunction<any>;
  campaigns?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialLeaseDocumentTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialLeasedUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  commonAreas?: FieldPolicy<any> | FieldReadFunction<any>;
  condoUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  contractorIds?: FieldPolicy<any> | FieldReadFunction<any>;
  contractors?: FieldPolicy<any> | FieldReadFunction<any>;
  creatingUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  currentResidencies?: FieldPolicy<any> | FieldReadFunction<any>;
  currentResidents?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  features?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  floorplanIds?: FieldPolicy<any> | FieldReadFunction<any>;
  floorplans?: FieldPolicy<any> | FieldReadFunction<any>;
  houseRules?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageKey?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  insurancePolicy?: FieldPolicy<any> | FieldReadFunction<any>;
  insurancePolicyHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  journalCount?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  keyring?: FieldPolicy<any> | FieldReadFunction<any>;
  leasedUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  legalDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  onsiteManager?: FieldPolicy<any> | FieldReadFunction<any>;
  operatorWindows?: FieldPolicy<any> | FieldReadFunction<any>;
  owner?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  pageCurrentResidencies?: FieldPolicy<any> | FieldReadFunction<any>;
  pageUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  parking?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  rentalLeaseDocumentTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  rentalUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  reserveAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  specs?: FieldPolicy<any> | FieldReadFunction<any>;
  storage?: FieldPolicy<any> | FieldReadFunction<any>;
  supplierIds?: FieldPolicy<any> | FieldReadFunction<any>;
  suppliers?: FieldPolicy<any> | FieldReadFunction<any>;
  timezone?: FieldPolicy<any> | FieldReadFunction<any>;
  totalUnitFactor?: FieldPolicy<any> | FieldReadFunction<any>;
  totalUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  types?: FieldPolicy<any> | FieldReadFunction<any>;
  units?: FieldPolicy<any> | FieldReadFunction<any>;
  window?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertyBankAccountKeySpecifier = (
  | 'bankAccountId'
  | 'collectionType'
  | 'id'
  | 'propertyId'
  | PropertyBankAccountKeySpecifier
)[];
export type PropertyBankAccountFieldPolicy = {
  bankAccountId?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionType?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertyConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | PropertyConnectionKeySpecifier
)[];
export type PropertyConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertyEdgeKeySpecifier = ('cursor' | 'node' | PropertyEdgeKeySpecifier)[];
export type PropertyEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertyFactKeySpecifier = (
  | 'accountEnabled'
  | 'accountId'
  | 'accountName'
  | 'commercialExpiring15Days'
  | 'commercialExpiringLater'
  | 'commercialExpiringSoon'
  | 'commercialLeasedUnits'
  | 'commercialLeasesExpired'
  | 'commercialUnits'
  | 'commonAreas'
  | 'dateKey'
  | 'expired'
  | 'expiring1Month'
  | 'expiring15Days'
  | 'expiringLater'
  | 'expiringSoon'
  | 'leasedUnits'
  | 'ownedTestUnits'
  | 'ownedUnits'
  | 'ownerUnits'
  | 'propertyEnabled'
  | 'propertyId'
  | 'testAccount'
  | 'testUnits'
  | 'totalCommercialRent'
  | 'totalCommercialSqft'
  | 'totalRent'
  | 'totalResidentialRent'
  | 'totalResidentialSqft'
  | 'totalResidentialUnits'
  | 'totalSqft'
  | 'totalUnits'
  | PropertyFactKeySpecifier
)[];
export type PropertyFactFieldPolicy = {
  accountEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  accountName?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialExpiring15Days?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialExpiringLater?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialExpiringSoon?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialLeasedUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialLeasesExpired?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  commonAreas?: FieldPolicy<any> | FieldReadFunction<any>;
  dateKey?: FieldPolicy<any> | FieldReadFunction<any>;
  expired?: FieldPolicy<any> | FieldReadFunction<any>;
  expiring1Month?: FieldPolicy<any> | FieldReadFunction<any>;
  expiring15Days?: FieldPolicy<any> | FieldReadFunction<any>;
  expiringLater?: FieldPolicy<any> | FieldReadFunction<any>;
  expiringSoon?: FieldPolicy<any> | FieldReadFunction<any>;
  leasedUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  ownedTestUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  ownedUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  testAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  testUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCommercialRent?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCommercialSqft?: FieldPolicy<any> | FieldReadFunction<any>;
  totalRent?: FieldPolicy<any> | FieldReadFunction<any>;
  totalResidentialRent?: FieldPolicy<any> | FieldReadFunction<any>;
  totalResidentialSqft?: FieldPolicy<any> | FieldReadFunction<any>;
  totalResidentialUnits?: FieldPolicy<any> | FieldReadFunction<any>;
  totalSqft?: FieldPolicy<any> | FieldReadFunction<any>;
  totalUnits?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertyFactConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | PropertyFactConnectionKeySpecifier
)[];
export type PropertyFactConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertyHeaderKeySpecifier = (
  | 'address'
  | 'id'
  | 'imageKey'
  | 'key'
  | 'name'
  | PropertyHeaderKeySpecifier
)[];
export type PropertyHeaderFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageKey?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertyOptionKeySpecifier = (
  | 'entityName'
  | 'occupancy'
  | 'ownerId'
  | 'ownerName'
  | 'propertyAddress'
  | 'propertyId'
  | 'unitId'
  | PropertyOptionKeySpecifier
)[];
export type PropertyOptionFieldPolicy = {
  entityName?: FieldPolicy<any> | FieldReadFunction<any>;
  occupancy?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerName?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertyPayloadKeySpecifier = (
  | 'error'
  | 'property'
  | 'success'
  | 'unitNames'
  | PropertyPayloadKeySpecifier
)[];
export type PropertyPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  unitNames?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertySpecsKeySpecifier = ('key' | 'value' | PropertySpecsKeySpecifier)[];
export type PropertySpecsFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertyUnitKeySpecifier = ('propertyId' | 'unitId' | PropertyUnitKeySpecifier)[];
export type PropertyUnitFieldPolicy = {
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProvinceTaxDetailsPayloadKeySpecifier = (
  | 'error'
  | 'success'
  | 'taxDetails'
  | ProvinceTaxDetailsPayloadKeySpecifier
)[];
export type ProvinceTaxDetailsPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  taxDetails?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PushTokenKeySpecifier = ('id' | 'platform' | 'token' | PushTokenKeySpecifier)[];
export type PushTokenFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  token?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | 'account'
  | 'announcement'
  | 'approval'
  | 'batch'
  | 'building'
  | 'campaign'
  | 'campaigns'
  | 'clearable'
  | 'contact'
  | 'customTemplate'
  | 'defaultFloorplanTemplates'
  | 'defaultTemplate'
  | 'depositTemplate'
  | 'depositTemplates'
  | 'draftBatch'
  | 'floorplan'
  | 'floorplanAreaDefaultFeatures'
  | 'floorplanByIds'
  | 'floorplanDefaultAdditionalFeatures'
  | 'furnishingsTemplate'
  | 'getProvinceTaxDetails'
  | 'getRegionTaxDetails'
  | 'glAccount'
  | 'houseRulesTemplate'
  | 'iamQuery'
  | 'iamTenant'
  | 'inspection'
  | 'inspectionByIds'
  | 'inspectionTemplates'
  | 'je'
  | 'journalEntry'
  | 'leaseDocumentTemplate'
  | 'leaseForm'
  | 'leaseForms'
  | 'leaseInspection'
  | 'manager'
  | 'managers'
  | 'opensearch'
  | 'operator'
  | 'operatorByEmail'
  | 'owner'
  | 'ownerByIds'
  | 'properties'
  | 'property'
  | 'reconciliation'
  | 'recurrence'
  | 'request'
  | 'requestRecurrence'
  | 'residency'
  | 'residencyProRatedEffects'
  | 'searchOwnersByEmail'
  | 'supplier'
  | 'suppliers'
  | 'team'
  | 'templates'
  | 'tenant'
  | 'tenantByIds'
  | 'tenantByProfileId'
  | 'tenants'
  | 'tenantsByEmail'
  | 'unit'
  | 'units'
  | 'unitsWithResidencies'
  | 'utilitiesTemplate'
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  announcement?: FieldPolicy<any> | FieldReadFunction<any>;
  approval?: FieldPolicy<any> | FieldReadFunction<any>;
  batch?: FieldPolicy<any> | FieldReadFunction<any>;
  building?: FieldPolicy<any> | FieldReadFunction<any>;
  campaign?: FieldPolicy<any> | FieldReadFunction<any>;
  campaigns?: FieldPolicy<any> | FieldReadFunction<any>;
  clearable?: FieldPolicy<any> | FieldReadFunction<any>;
  contact?: FieldPolicy<any> | FieldReadFunction<any>;
  customTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultFloorplanTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  depositTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  depositTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  draftBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  floorplan?: FieldPolicy<any> | FieldReadFunction<any>;
  floorplanAreaDefaultFeatures?: FieldPolicy<any> | FieldReadFunction<any>;
  floorplanByIds?: FieldPolicy<any> | FieldReadFunction<any>;
  floorplanDefaultAdditionalFeatures?: FieldPolicy<any> | FieldReadFunction<any>;
  furnishingsTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  getProvinceTaxDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  getRegionTaxDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  glAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  houseRulesTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  iamQuery?: FieldPolicy<any> | FieldReadFunction<any>;
  iamTenant?: FieldPolicy<any> | FieldReadFunction<any>;
  inspection?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionByIds?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  je?: FieldPolicy<any> | FieldReadFunction<any>;
  journalEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseDocumentTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseForm?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseForms?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  manager?: FieldPolicy<any> | FieldReadFunction<any>;
  managers?: FieldPolicy<any> | FieldReadFunction<any>;
  opensearch?: FieldPolicy<any> | FieldReadFunction<any>;
  operator?: FieldPolicy<any> | FieldReadFunction<any>;
  operatorByEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  owner?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerByIds?: FieldPolicy<any> | FieldReadFunction<any>;
  properties?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  reconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  recurrence?: FieldPolicy<any> | FieldReadFunction<any>;
  request?: FieldPolicy<any> | FieldReadFunction<any>;
  requestRecurrence?: FieldPolicy<any> | FieldReadFunction<any>;
  residency?: FieldPolicy<any> | FieldReadFunction<any>;
  residencyProRatedEffects?: FieldPolicy<any> | FieldReadFunction<any>;
  searchOwnersByEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  supplier?: FieldPolicy<any> | FieldReadFunction<any>;
  suppliers?: FieldPolicy<any> | FieldReadFunction<any>;
  team?: FieldPolicy<any> | FieldReadFunction<any>;
  templates?: FieldPolicy<any> | FieldReadFunction<any>;
  tenant?: FieldPolicy<any> | FieldReadFunction<any>;
  tenantByIds?: FieldPolicy<any> | FieldReadFunction<any>;
  tenantByProfileId?: FieldPolicy<any> | FieldReadFunction<any>;
  tenants?: FieldPolicy<any> | FieldReadFunction<any>;
  tenantsByEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
  units?: FieldPolicy<any> | FieldReadFunction<any>;
  unitsWithResidencies?: FieldPolicy<any> | FieldReadFunction<any>;
  utilitiesTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RateScheduleKeySpecifier = ('rent' | 'start' | RateScheduleKeySpecifier)[];
export type RateScheduleFieldPolicy = {
  rent?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReconciliationKeySpecifier = (
  | 'booksBatchId'
  | 'completedZ'
  | 'configuration'
  | 'current'
  | 'documentError'
  | 'documentGeneratedZ'
  | 'documentKey'
  | 'errorMessage'
  | 'glAccount'
  | 'id'
  | 'lastReconciliationId'
  | 'lastStatementBalance'
  | 'ledgerDocumentError'
  | 'ledgerDocumentGeneratedZ'
  | 'ledgerDocumentKey'
  | 'name'
  | 'reindexToken'
  | 'scope'
  | 'statementBalance'
  | 'statementDate'
  | 'status'
  | 'taskProgress'
  | ReconciliationKeySpecifier
)[];
export type ReconciliationFieldPolicy = {
  booksBatchId?: FieldPolicy<any> | FieldReadFunction<any>;
  completedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  configuration?: FieldPolicy<any> | FieldReadFunction<any>;
  current?: FieldPolicy<any> | FieldReadFunction<any>;
  documentError?: FieldPolicy<any> | FieldReadFunction<any>;
  documentGeneratedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  documentKey?: FieldPolicy<any> | FieldReadFunction<any>;
  errorMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  glAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastReconciliationId?: FieldPolicy<any> | FieldReadFunction<any>;
  lastStatementBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  ledgerDocumentError?: FieldPolicy<any> | FieldReadFunction<any>;
  ledgerDocumentGeneratedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  ledgerDocumentKey?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  reindexToken?: FieldPolicy<any> | FieldReadFunction<any>;
  scope?: FieldPolicy<any> | FieldReadFunction<any>;
  statementBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  statementDate?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  taskProgress?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReconciliationConfigKeySpecifier = (
  | 'bankId'
  | 'configuration'
  | 'id'
  | 'name'
  | 'scope'
  | ReconciliationConfigKeySpecifier
)[];
export type ReconciliationConfigFieldPolicy = {
  bankId?: FieldPolicy<any> | FieldReadFunction<any>;
  configuration?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  scope?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReconciliationConfigConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | ReconciliationConfigConnectionKeySpecifier
)[];
export type ReconciliationConfigConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReconciliationConfigPayloadKeySpecifier = (
  | 'error'
  | 'reconciliationConfig'
  | 'success'
  | ReconciliationConfigPayloadKeySpecifier
)[];
export type ReconciliationConfigPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  reconciliationConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReconciliationConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | ReconciliationConnectionKeySpecifier
)[];
export type ReconciliationConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReconciliationPayloadKeySpecifier = (
  | 'error'
  | 'reconciliation'
  | 'success'
  | ReconciliationPayloadKeySpecifier
)[];
export type ReconciliationPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  reconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReconciliationScopeKeySpecifier = (
  | 'glId'
  | 'propertyOwnerIds'
  | ReconciliationScopeKeySpecifier
)[];
export type ReconciliationScopeFieldPolicy = {
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyOwnerIds?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReconciliationTaskProgressKeySpecifier = (
  | 'clearedAmount'
  | 'done'
  | 'progress'
  | ReconciliationTaskProgressKeySpecifier
)[];
export type ReconciliationTaskProgressFieldPolicy = {
  clearedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  done?: FieldPolicy<any> | FieldReadFunction<any>;
  progress?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecurrenceKeySpecifier = (
  | 'dueOffset'
  | 'id'
  | 'lastPosted'
  | 'name'
  | 'output'
  | 'rrule'
  | RecurrenceKeySpecifier
)[];
export type RecurrenceFieldPolicy = {
  dueOffset?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastPosted?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  output?: FieldPolicy<any> | FieldReadFunction<any>;
  rrule?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecurrenceClearableConfigKeySpecifier = (
  | 'payee'
  | 'payeeId'
  | 'postedForDue'
  | RecurrenceClearableConfigKeySpecifier
)[];
export type RecurrenceClearableConfigFieldPolicy = {
  payee?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeId?: FieldPolicy<any> | FieldReadFunction<any>;
  postedForDue?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecurrenceOutputKeySpecifier = ('clearable' | 'lines' | RecurrenceOutputKeySpecifier)[];
export type RecurrenceOutputFieldPolicy = {
  clearable?: FieldPolicy<any> | FieldReadFunction<any>;
  lines?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RefundableKeySpecifier = (
  | 'accrualStartExpr'
  | 'amount'
  | 'collectedZ'
  | 'extraCollection'
  | 'heldByOwner'
  | 'id'
  | 'interest'
  | 'ledger'
  | 'ledgerHash'
  | 'name'
  | 'retained'
  | 'returned'
  | 'settledZ'
  | 'simple'
  | 'templateId'
  | RefundableKeySpecifier
)[];
export type RefundableFieldPolicy = {
  accrualStartExpr?: FieldPolicy<any> | FieldReadFunction<any>;
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  collectedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  extraCollection?: FieldPolicy<any> | FieldReadFunction<any>;
  heldByOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  interest?: FieldPolicy<any> | FieldReadFunction<any>;
  ledger?: FieldPolicy<any> | FieldReadFunction<any>;
  ledgerHash?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  retained?: FieldPolicy<any> | FieldReadFunction<any>;
  returned?: FieldPolicy<any> | FieldReadFunction<any>;
  settledZ?: FieldPolicy<any> | FieldReadFunction<any>;
  simple?: FieldPolicy<any> | FieldReadFunction<any>;
  templateId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RefundableLedgerKeySpecifier = (
  | 'amount'
  | 'category'
  | 'date'
  | 'id'
  | RefundableLedgerKeySpecifier
)[];
export type RefundableLedgerFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RegionTaxKeySpecifier = (
  | 'accountId'
  | 'categories'
  | 'country'
  | 'defaultTaxes'
  | 'id'
  | 'region'
  | 'taxes'
  | RegionTaxKeySpecifier
)[];
export type RegionTaxFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  categories?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultTaxes?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  region?: FieldPolicy<any> | FieldReadFunction<any>;
  taxes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RegionTaxDetailsPayloadKeySpecifier = (
  | 'error'
  | 'success'
  | 'taxDetails'
  | RegionTaxDetailsPayloadKeySpecifier
)[];
export type RegionTaxDetailsPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  taxDetails?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RelayEdgeKeySpecifier = ('cursor' | RelayEdgeKeySpecifier)[];
export type RelayEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RelayPageKeySpecifier = ('edges' | 'pageInfo' | RelayPageKeySpecifier)[];
export type RelayPageFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RelayPageInfoKeySpecifier = ('endCursor' | 'hasNextPage' | RelayPageInfoKeySpecifier)[];
export type RelayPageInfoFieldPolicy = {
  endCursor?: FieldPolicy<any> | FieldReadFunction<any>;
  hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RemoveReconciliationPayloadKeySpecifier = (
  | 'error'
  | 'lastReconciliation'
  | 'reconciliation'
  | 'success'
  | RemoveReconciliationPayloadKeySpecifier
)[];
export type RemoveReconciliationPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  lastReconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  reconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RenderedActionKeySpecifier = (
  | 'glId'
  | 'ownerId'
  | 'propertyId'
  | RenderedActionKeySpecifier
)[];
export type RenderedActionFieldPolicy = {
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RentRollFeeKeySpecifier = ('amount' | 'feeId' | RentRollFeeKeySpecifier)[];
export type RentRollFeeFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  feeId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RentalAssistanceKeySpecifier = (
  | 'amount'
  | 'qualified'
  | 'source'
  | RentalAssistanceKeySpecifier
)[];
export type RentalAssistanceFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  qualified?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReplyKeySpecifier = (
  | 'attachments'
  | 'createdName'
  | 'createdSub'
  | 'createdZ'
  | 'email'
  | 'id'
  | 'managerReply'
  | 'messageId'
  | 'replyId'
  | 'text'
  | ReplyKeySpecifier
)[];
export type ReplyFieldPolicy = {
  attachments?: FieldPolicy<any> | FieldReadFunction<any>;
  createdName?: FieldPolicy<any> | FieldReadFunction<any>;
  createdSub?: FieldPolicy<any> | FieldReadFunction<any>;
  createdZ?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  managerReply?: FieldPolicy<any> | FieldReadFunction<any>;
  messageId?: FieldPolicy<any> | FieldReadFunction<any>;
  replyId?: FieldPolicy<any> | FieldReadFunction<any>;
  text?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReportBundleKeySpecifier = ('id' | 'name' | 'presetIds' | ReportBundleKeySpecifier)[];
export type ReportBundleFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  presetIds?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReportBundlePayloadKeySpecifier = (
  | 'error'
  | 'reportBundle'
  | 'success'
  | ReportBundlePayloadKeySpecifier
)[];
export type ReportBundlePayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  reportBundle?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReportSetKeySpecifier = ('setId' | ReportSetKeySpecifier)[];
export type ReportSetFieldPolicy = {
  setId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReportSetPayloadKeySpecifier = (
  | 'error'
  | 'reportSet'
  | 'success'
  | ReportSetPayloadKeySpecifier
)[];
export type ReportSetPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  reportSet?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequestKeySpecifier = (
  | 'accountId'
  | 'additionalTenants'
  | 'analysis'
  | 'appointmentChanges'
  | 'appointmentPreferences'
  | 'appointmentZ'
  | 'approval'
  | 'autoSummary'
  | 'buildingAddress'
  | 'buildingId'
  | 'buildingKey'
  | 'buildingName'
  | 'cancelledReason'
  | 'category'
  | 'checklist'
  | 'consents'
  | 'costLines'
  | 'costs'
  | 'delay'
  | 'details'
  | 'documents'
  | 'entryConsentDetails'
  | 'fields'
  | 'finalizedName'
  | 'finalizedZ'
  | 'fingerprints'
  | 'firstOccurred'
  | 'history'
  | 'id'
  | 'images'
  | 'instructions'
  | 'journalId'
  | 'minutes'
  | 'name'
  | 'nonBillable'
  | 'notes'
  | 'occupant'
  | 'openedZ'
  | 'operator'
  | 'operatorName'
  | 'originalRequest'
  | 'ownerApprovalId'
  | 'ownerApprovalStatus'
  | 'ownerId'
  | 'ownerName'
  | 'printNotes'
  | 'propertyAddress'
  | 'propertyId'
  | 'propertyKey'
  | 'propertyName'
  | 'resolution'
  | 'source'
  | 'sourceInspection'
  | 'status'
  | 'subcategory'
  | 'submittedZ'
  | 'summary'
  | 'taxExempted'
  | 'tenant'
  | 'tenantId'
  | 'tenantName'
  | 'unit'
  | 'unitId'
  | 'unitName'
  | 'unitOccupancy'
  | 'visits'
  | RequestKeySpecifier
)[];
export type RequestFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  additionalTenants?: FieldPolicy<any> | FieldReadFunction<any>;
  analysis?: FieldPolicy<any> | FieldReadFunction<any>;
  appointmentChanges?: FieldPolicy<any> | FieldReadFunction<any>;
  appointmentPreferences?: FieldPolicy<any> | FieldReadFunction<any>;
  appointmentZ?: FieldPolicy<any> | FieldReadFunction<any>;
  approval?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingId?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingKey?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingName?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelledReason?: FieldPolicy<any> | FieldReadFunction<any>;
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  checklist?: FieldPolicy<any> | FieldReadFunction<any>;
  consents?: FieldPolicy<any> | FieldReadFunction<any>;
  costLines?: FieldPolicy<any> | FieldReadFunction<any>;
  costs?: FieldPolicy<any> | FieldReadFunction<any>;
  delay?: FieldPolicy<any> | FieldReadFunction<any>;
  details?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  entryConsentDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  fields?: FieldPolicy<any> | FieldReadFunction<any>;
  finalizedName?: FieldPolicy<any> | FieldReadFunction<any>;
  finalizedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  firstOccurred?: FieldPolicy<any> | FieldReadFunction<any>;
  history?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  instructions?: FieldPolicy<any> | FieldReadFunction<any>;
  journalId?: FieldPolicy<any> | FieldReadFunction<any>;
  minutes?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nonBillable?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  occupant?: FieldPolicy<any> | FieldReadFunction<any>;
  openedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  operator?: FieldPolicy<any> | FieldReadFunction<any>;
  operatorName?: FieldPolicy<any> | FieldReadFunction<any>;
  originalRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerApprovalId?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerApprovalStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerName?: FieldPolicy<any> | FieldReadFunction<any>;
  printNotes?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyKey?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyName?: FieldPolicy<any> | FieldReadFunction<any>;
  resolution?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  subcategory?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  summary?: FieldPolicy<any> | FieldReadFunction<any>;
  taxExempted?: FieldPolicy<any> | FieldReadFunction<any>;
  tenant?: FieldPolicy<any> | FieldReadFunction<any>;
  tenantId?: FieldPolicy<any> | FieldReadFunction<any>;
  tenantName?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitName?: FieldPolicy<any> | FieldReadFunction<any>;
  unitOccupancy?: FieldPolicy<any> | FieldReadFunction<any>;
  visits?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequestConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | RequestConnectionKeySpecifier
)[];
export type RequestConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequestCostKeySpecifier = (
  | 'amount'
  | 'date'
  | 'description'
  | 'id'
  | 'markup'
  | RequestCostKeySpecifier
)[];
export type RequestCostFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markup?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequestEdgeKeySpecifier = ('cursor' | 'node' | RequestEdgeKeySpecifier)[];
export type RequestEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequestEventKeySpecifier = (
  | 'fingerprints'
  | 'id'
  | 'name'
  | RequestEventKeySpecifier
)[];
export type RequestEventFieldPolicy = {
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequestPayloadKeySpecifier = (
  | 'error'
  | 'request'
  | 'success'
  | RequestPayloadKeySpecifier
)[];
export type RequestPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  request?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequestRecurrenceKeySpecifier = (
  | 'accountId'
  | 'category'
  | 'checklist'
  | 'details'
  | 'id'
  | 'instructions'
  | 'latestRequest'
  | 'minutes'
  | 'name'
  | 'nextRecurrenceZ'
  | 'nonBillable'
  | 'notes'
  | 'originalRequest'
  | 'printNotes'
  | 'property'
  | 'rrule'
  | 'subcategory'
  | 'summary'
  | 'unit'
  | RequestRecurrenceKeySpecifier
)[];
export type RequestRecurrenceFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  checklist?: FieldPolicy<any> | FieldReadFunction<any>;
  details?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  instructions?: FieldPolicy<any> | FieldReadFunction<any>;
  latestRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  minutes?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nextRecurrenceZ?: FieldPolicy<any> | FieldReadFunction<any>;
  nonBillable?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  originalRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  printNotes?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  rrule?: FieldPolicy<any> | FieldReadFunction<any>;
  subcategory?: FieldPolicy<any> | FieldReadFunction<any>;
  summary?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequestRecurrenceConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | RequestRecurrenceConnectionKeySpecifier
)[];
export type RequestRecurrenceConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequestRecurrencePayloadKeySpecifier = (
  | 'error'
  | 'requestRecurrence'
  | 'success'
  | RequestRecurrencePayloadKeySpecifier
)[];
export type RequestRecurrencePayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  requestRecurrence?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequestTenantKeySpecifier = (
  | 'email'
  | 'id'
  | 'name'
  | 'phone'
  | RequestTenantKeySpecifier
)[];
export type RequestTenantFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequestsPageKeySpecifier = (
  | 'edges'
  | 'pageInfo'
  | 'totalCount'
  | RequestsPageKeySpecifier
)[];
export type RequestsPageFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidenciesPageKeySpecifier = (
  | 'edges'
  | 'pageInfo'
  | 'totalCount'
  | ResidenciesPageKeySpecifier
)[];
export type ResidenciesPageFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidencyKeySpecifier = (
  | 'accountId'
  | 'accruals'
  | 'additionalProvisions'
  | 'applicationId'
  | 'buildingKey'
  | 'buildingName'
  | 'campaignId'
  | 'collectionDay'
  | 'documents'
  | 'effects'
  | 'endZ'
  | 'eventHistory'
  | 'furnishings'
  | 'gst'
  | 'id'
  | 'insurancePolicies'
  | 'insurancePolicy'
  | 'latestRentEffectZ'
  | 'leaseAgreement'
  | 'moveInDate'
  | 'moveInInspection'
  | 'moveInInspectionId'
  | 'moveOutDate'
  | 'moveOutInspection'
  | 'moveOutInspectionId'
  | 'moveOutReason'
  | 'notes'
  | 'occupancy'
  | 'outstanding'
  | 'ownerId'
  | 'ownerName'
  | 'property'
  | 'propertyAddress'
  | 'propertyId'
  | 'propertyKey'
  | 'propertyName'
  | 'proratedClearableIds'
  | 'refundables'
  | 'renewZ'
  | 'residentDocuments'
  | 'residentInsurancePolicies'
  | 'residents'
  | 'rrule'
  | 'securityDeposit'
  | 'securityDepositHistory'
  | 'startZ'
  | 'terms'
  | 'type'
  | 'unit'
  | 'unitId'
  | 'unitName'
  | 'unitOccupancy'
  | 'unitOwnerId'
  | 'utilities'
  | ResidencyKeySpecifier
)[];
export type ResidencyFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  accruals?: FieldPolicy<any> | FieldReadFunction<any>;
  additionalProvisions?: FieldPolicy<any> | FieldReadFunction<any>;
  applicationId?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingKey?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingName?: FieldPolicy<any> | FieldReadFunction<any>;
  campaignId?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionDay?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  effects?: FieldPolicy<any> | FieldReadFunction<any>;
  endZ?: FieldPolicy<any> | FieldReadFunction<any>;
  eventHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  furnishings?: FieldPolicy<any> | FieldReadFunction<any>;
  gst?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  insurancePolicies?: FieldPolicy<any> | FieldReadFunction<any>;
  insurancePolicy?: FieldPolicy<any> | FieldReadFunction<any>;
  latestRentEffectZ?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseAgreement?: FieldPolicy<any> | FieldReadFunction<any>;
  moveInDate?: FieldPolicy<any> | FieldReadFunction<any>;
  moveInInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  moveInInspectionId?: FieldPolicy<any> | FieldReadFunction<any>;
  moveOutDate?: FieldPolicy<any> | FieldReadFunction<any>;
  moveOutInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  moveOutInspectionId?: FieldPolicy<any> | FieldReadFunction<any>;
  moveOutReason?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  occupancy?: FieldPolicy<any> | FieldReadFunction<any>;
  outstanding?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerName?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyKey?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyName?: FieldPolicy<any> | FieldReadFunction<any>;
  proratedClearableIds?: FieldPolicy<any> | FieldReadFunction<any>;
  refundables?: FieldPolicy<any> | FieldReadFunction<any>;
  renewZ?: FieldPolicy<any> | FieldReadFunction<any>;
  residentDocuments?: FieldPolicy<any> | FieldReadFunction<any>;
  residentInsurancePolicies?: FieldPolicy<any> | FieldReadFunction<any>;
  residents?: FieldPolicy<any> | FieldReadFunction<any>;
  rrule?: FieldPolicy<any> | FieldReadFunction<any>;
  securityDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  securityDepositHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  startZ?: FieldPolicy<any> | FieldReadFunction<any>;
  terms?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitName?: FieldPolicy<any> | FieldReadFunction<any>;
  unitOccupancy?: FieldPolicy<any> | FieldReadFunction<any>;
  unitOwnerId?: FieldPolicy<any> | FieldReadFunction<any>;
  utilities?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidencyConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | ResidencyConnectionKeySpecifier
)[];
export type ResidencyConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidencyEdgeKeySpecifier = ('cursor' | 'node' | ResidencyEdgeKeySpecifier)[];
export type ResidencyEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidencyEffectKeySpecifier = (
  | 'effect'
  | 'feeId'
  | 'id'
  | 'startZ'
  | ResidencyEffectKeySpecifier
)[];
export type ResidencyEffectFieldPolicy = {
  effect?: FieldPolicy<any> | FieldReadFunction<any>;
  feeId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  startZ?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidencyEffectRecordKeySpecifier = (
  | 'effect'
  | 'feeId'
  | 'id'
  | 'startZ'
  | ResidencyEffectRecordKeySpecifier
)[];
export type ResidencyEffectRecordFieldPolicy = {
  effect?: FieldPolicy<any> | FieldReadFunction<any>;
  feeId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  startZ?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidencyEventKeySpecifier = (
  | 'category'
  | 'deletedZ'
  | 'description'
  | 'eventDateZ'
  | 'id'
  | 'name'
  | 'noteText'
  | 'noticeZ'
  | 'resolvedZ'
  | ResidencyEventKeySpecifier
)[];
export type ResidencyEventFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  eventDateZ?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  noteText?: FieldPolicy<any> | FieldReadFunction<any>;
  noticeZ?: FieldPolicy<any> | FieldReadFunction<any>;
  resolvedZ?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidencyOutstandingKeySpecifier = (
  | 'clearables'
  | 'totalOutstanding'
  | ResidencyOutstandingKeySpecifier
)[];
export type ResidencyOutstandingFieldPolicy = {
  clearables?: FieldPolicy<any> | FieldReadFunction<any>;
  totalOutstanding?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidencyPayloadKeySpecifier = (
  | 'error'
  | 'residency'
  | 'success'
  | ResidencyPayloadKeySpecifier
)[];
export type ResidencyPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  residency?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidencyProRatedEffectKeySpecifier = (
  | 'amount'
  | 'autopayActivated'
  | 'effects'
  | 'enrollmentId'
  | 'name'
  | 'residentId'
  | ResidencyProRatedEffectKeySpecifier
)[];
export type ResidencyProRatedEffectFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayActivated?: FieldPolicy<any> | FieldReadFunction<any>;
  effects?: FieldPolicy<any> | FieldReadFunction<any>;
  enrollmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  residentId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidencyProRatedEffectsPayloadKeySpecifier = (
  | 'residents'
  | 'success'
  | ResidencyProRatedEffectsPayloadKeySpecifier
)[];
export type ResidencyProRatedEffectsPayloadFieldPolicy = {
  residents?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidentKeySpecifier = (
  | 'accountId'
  | 'email'
  | 'emails'
  | 'id'
  | 'name'
  | 'phone'
  | 'phones'
  | 'profileId'
  | ResidentKeySpecifier
)[];
export type ResidentFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  emails?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  phones?: FieldPolicy<any> | FieldReadFunction<any>;
  profileId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidentAssociationKeySpecifier = (
  | 'id'
  | 'leaseHolder'
  | 'padAccount'
  | 'padAmount'
  | 'padInstitution'
  | 'padTransit'
  | 'payeeFrac'
  | 'paymentDetails'
  | 'preferredPaymentMethod'
  | 'residencyId'
  | 'resident'
  | 'residentId'
  | 'residentName'
  | 'title'
  | ResidentAssociationKeySpecifier
)[];
export type ResidentAssociationFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseHolder?: FieldPolicy<any> | FieldReadFunction<any>;
  padAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  padAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  padInstitution?: FieldPolicy<any> | FieldReadFunction<any>;
  padTransit?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeFrac?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredPaymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  residencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  resident?: FieldPolicy<any> | FieldReadFunction<any>;
  residentId?: FieldPolicy<any> | FieldReadFunction<any>;
  residentName?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidentDocumentKeySpecifier = (
  | 'document'
  | 'entityId'
  | 'entityType'
  | 'residentId'
  | 'sentZ'
  | ResidentDocumentKeySpecifier
)[];
export type ResidentDocumentFieldPolicy = {
  document?: FieldPolicy<any> | FieldReadFunction<any>;
  entityId?: FieldPolicy<any> | FieldReadFunction<any>;
  entityType?: FieldPolicy<any> | FieldReadFunction<any>;
  residentId?: FieldPolicy<any> | FieldReadFunction<any>;
  sentZ?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidentEdgeKeySpecifier = ('cursor' | 'node' | ResidentEdgeKeySpecifier)[];
export type ResidentEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidentPageKeySpecifier = (
  | 'edges'
  | 'pageInfo'
  | 'totalCount'
  | ResidentPageKeySpecifier
)[];
export type ResidentPageFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResidentRecordKeySpecifier = (
  | 'accountId'
  | 'autopayClientId'
  | 'correspondenceMethod'
  | 'documents'
  | 'emails'
  | 'emergencyContacts'
  | 'enabled'
  | 'enrollments'
  | 'id'
  | 'name'
  | 'notes'
  | 'phones'
  | 'profileId'
  | 'propertyOptions'
  | 'vehicles'
  | ResidentRecordKeySpecifier
)[];
export type ResidentRecordFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayClientId?: FieldPolicy<any> | FieldReadFunction<any>;
  correspondenceMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  emails?: FieldPolicy<any> | FieldReadFunction<any>;
  emergencyContacts?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  enrollments?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  phones?: FieldPolicy<any> | FieldReadFunction<any>;
  profileId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicles?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RoutineInspectionPayloadKeySpecifier = (
  | 'error'
  | 'inspection'
  | 'success'
  | 'unit'
  | RoutineInspectionPayloadKeySpecifier
)[];
export type RoutineInspectionPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  inspection?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ScanApprovalKeySpecifier = (
  | 'accountId'
  | 'approvedSub'
  | 'approvedZ'
  | 'approverRoles'
  | 'declinedSub'
  | 'declinedZ'
  | 'id'
  | 'request'
  | 'requestedName'
  | 'requestedSub'
  | 'requestedZ'
  | 'status'
  | ScanApprovalKeySpecifier
)[];
export type ScanApprovalFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  approvedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  approvedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  approverRoles?: FieldPolicy<any> | FieldReadFunction<any>;
  declinedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  declinedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  request?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedName?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedSub?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ScanApprovalLineKeySpecifier = (
  | 'amount'
  | 'description'
  | 'glId'
  | 'unitId'
  | ScanApprovalLineKeySpecifier
)[];
export type ScanApprovalLineFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ScanApprovalPayloadKeySpecifier = (
  | 'approval'
  | 'error'
  | 'success'
  | ScanApprovalPayloadKeySpecifier
)[];
export type ScanApprovalPayloadFieldPolicy = {
  approval?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ScanApprovalRequestKeySpecifier = (
  | 'amount'
  | 'description'
  | 'due'
  | 'expenseGlId'
  | 'gstOrHstAllocation'
  | 'includesGstOrHst'
  | 'key'
  | 'lines'
  | 'ownerId'
  | 'payee'
  | 'payeeId'
  | 'payeeName'
  | 'posted'
  | 'propertyId'
  | 'ref'
  | 'scanType'
  | 'supplierAccount'
  | ScanApprovalRequestKeySpecifier
)[];
export type ScanApprovalRequestFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  due?: FieldPolicy<any> | FieldReadFunction<any>;
  expenseGlId?: FieldPolicy<any> | FieldReadFunction<any>;
  gstOrHstAllocation?: FieldPolicy<any> | FieldReadFunction<any>;
  includesGstOrHst?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  lines?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  payee?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeId?: FieldPolicy<any> | FieldReadFunction<any>;
  payeeName?: FieldPolicy<any> | FieldReadFunction<any>;
  posted?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  ref?: FieldPolicy<any> | FieldReadFunction<any>;
  scanType?: FieldPolicy<any> | FieldReadFunction<any>;
  supplierAccount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchUnitKeySpecifier = (
  | 'buildingKey'
  | 'buildingName'
  | 'propertyKey'
  | 'propertyName'
  | 'unitId'
  | 'unitName'
  | SearchUnitKeySpecifier
)[];
export type SearchUnitFieldPolicy = {
  buildingKey?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingName?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyKey?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyName?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SearchUnitPayloadKeySpecifier = (
  | 'error'
  | 'success'
  | 'units'
  | SearchUnitPayloadKeySpecifier
)[];
export type SearchUnitPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  units?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SecurityDepositAllocationKeySpecifier = (
  | 'amount'
  | 'status'
  | SecurityDepositAllocationKeySpecifier
)[];
export type SecurityDepositAllocationFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SecurityDepositHistoryKeySpecifier = (
  | 'allocations'
  | 'createdZ'
  | SecurityDepositHistoryKeySpecifier
)[];
export type SecurityDepositHistoryFieldPolicy = {
  allocations?: FieldPolicy<any> | FieldReadFunction<any>;
  createdZ?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SettingsKeySpecifier = (
  | 'campaignApplicationRules'
  | 'later_days_threshold'
  | 'resident_request_scheduling'
  | 'soon_days_threshold'
  | SettingsKeySpecifier
)[];
export type SettingsFieldPolicy = {
  campaignApplicationRules?: FieldPolicy<any> | FieldReadFunction<any>;
  later_days_threshold?: FieldPolicy<any> | FieldReadFunction<any>;
  resident_request_scheduling?: FieldPolicy<any> | FieldReadFunction<any>;
  soon_days_threshold?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SettlementSummaryKeySpecifier = (
  | 'accountId'
  | 'accountName'
  | 'autopayId'
  | 'bankId'
  | 'billingName'
  | 'collectionPosted'
  | 'feesTotal'
  | 'fileNumber'
  | 'gstAmount'
  | 'id'
  | 'items'
  | 'journalsTotal'
  | 'key'
  | 'paymentAmount'
  | 'paymentCount'
  | 'returnedItems'
  | 'returnedTotal'
  | 'settledZ'
  | 'status'
  | 'total'
  | 'version'
  | SettlementSummaryKeySpecifier
)[];
export type SettlementSummaryFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  accountName?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayId?: FieldPolicy<any> | FieldReadFunction<any>;
  bankId?: FieldPolicy<any> | FieldReadFunction<any>;
  billingName?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionPosted?: FieldPolicy<any> | FieldReadFunction<any>;
  feesTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  fileNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  gstAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  journalsTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentCount?: FieldPolicy<any> | FieldReadFunction<any>;
  returnedItems?: FieldPolicy<any> | FieldReadFunction<any>;
  returnedTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  settledZ?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SignatureKeySpecifier = (
  | 'agentRelationship'
  | 'id'
  | 'isAgent'
  | 'signature'
  | 'signerEntity'
  | 'signerId'
  | 'signerName'
  | SignatureKeySpecifier
)[];
export type SignatureFieldPolicy = {
  agentRelationship?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isAgent?: FieldPolicy<any> | FieldReadFunction<any>;
  signature?: FieldPolicy<any> | FieldReadFunction<any>;
  signerEntity?: FieldPolicy<any> | FieldReadFunction<any>;
  signerId?: FieldPolicy<any> | FieldReadFunction<any>;
  signerName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SourceInspectionKeySpecifier = (
  | 'fieldId'
  | 'inspectionId'
  | 'itemId'
  | 'leaseId'
  | 'unitId'
  | SourceInspectionKeySpecifier
)[];
export type SourceInspectionFieldPolicy = {
  fieldId?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionId?: FieldPolicy<any> | FieldReadFunction<any>;
  itemId?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseId?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StartReconciliationPayloadKeySpecifier = (
  | 'error'
  | 'journalEntries'
  | 'reconciliation'
  | 'success'
  | StartReconciliationPayloadKeySpecifier
)[];
export type StartReconciliationPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  journalEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  reconciliation?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusHistoryEventKeySpecifier = (
  | 'createdZ'
  | 'status'
  | StatusHistoryEventKeySpecifier
)[];
export type StatusHistoryEventFieldPolicy = {
  createdZ?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StorageLockerKeySpecifier = ('name' | 'unitId' | StorageLockerKeySpecifier)[];
export type StorageLockerFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubAccountKeySpecifier = (
  | 'glId'
  | 'id'
  | 'owner'
  | 'ownerId'
  | 'property'
  | 'propertyId'
  | SubAccountKeySpecifier
)[];
export type SubAccountFieldPolicy = {
  glId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  owner?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubAccountConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | SubAccountConnectionKeySpecifier
)[];
export type SubAccountConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionKeySpecifier = ('onAccountEvent' | SubscriptionKeySpecifier)[];
export type SubscriptionFieldPolicy = {
  onAccountEvent?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionEndpointKeySpecifier = (
  | 'address'
  | 'id'
  | SubscriptionEndpointKeySpecifier
)[];
export type SubscriptionEndpointFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SuccessPayloadKeySpecifier = ('error' | 'success' | SuccessPayloadKeySpecifier)[];
export type SuccessPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SupplierKeySpecifier = (
  | 'accountId'
  | 'address'
  | 'autopayAccount'
  | 'autopayClientId'
  | 'autopayInstitution'
  | 'autopayStatus'
  | 'autopayStatusUpdatedZ'
  | 'autopayTransit'
  | 'billingEmail'
  | 'businessNumber'
  | 'coi'
  | 'coiExpiry'
  | 'contactName'
  | 'deletedZ'
  | 'email'
  | 'enabled'
  | 'expenseGlId'
  | 'fax'
  | 'fingerprints'
  | 'id'
  | 'location'
  | 'name'
  | 'phone'
  | 'supplierAccounts'
  | 'type'
  | 'verificationRequestedZ'
  | 'verifiedZ'
  | 'website'
  | SupplierKeySpecifier
)[];
export type SupplierFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayClientId?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayInstitution?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatusUpdatedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayTransit?: FieldPolicy<any> | FieldReadFunction<any>;
  billingEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  businessNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  coi?: FieldPolicy<any> | FieldReadFunction<any>;
  coiExpiry?: FieldPolicy<any> | FieldReadFunction<any>;
  contactName?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  expenseGlId?: FieldPolicy<any> | FieldReadFunction<any>;
  fax?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  supplierAccounts?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  verificationRequestedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  verifiedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  website?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SupplierAccountKeySpecifier = ('accountNumber' | 'id' | SupplierAccountKeySpecifier)[];
export type SupplierAccountFieldPolicy = {
  accountNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SupplierEdgeKeySpecifier = ('cursor' | 'node' | SupplierEdgeKeySpecifier)[];
export type SupplierEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SupplierPayloadKeySpecifier = (
  | 'error'
  | 'success'
  | 'supplier'
  | SupplierPayloadKeySpecifier
)[];
export type SupplierPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  supplier?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SuppliersConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | SuppliersConnectionKeySpecifier
)[];
export type SuppliersConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SuppliersPageKeySpecifier = (
  | 'edges'
  | 'pageInfo'
  | 'totalCount'
  | SuppliersPageKeySpecifier
)[];
export type SuppliersPageFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TaxKeySpecifier = ('gst' | 'id' | 'label' | 'postals' | 'rate' | TaxKeySpecifier)[];
export type TaxFieldPolicy = {
  gst?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  postals?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TaxDetailKeySpecifier = ('gst' | 'label' | 'rate' | TaxDetailKeySpecifier)[];
export type TaxDetailFieldPolicy = {
  gst?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TeamKeySpecifier = (
  | 'id'
  | 'managerIds'
  | 'name'
  | 'occupancies'
  | 'propertyIds'
  | 'propertyUnitIds'
  | TeamKeySpecifier
)[];
export type TeamFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  managerIds?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  occupancies?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyIds?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyUnitIds?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TeamPayloadKeySpecifier = ('error' | 'success' | 'team' | TeamPayloadKeySpecifier)[];
export type TeamPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  team?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TemplateKeySpecifier = (
  | 'category'
  | 'default'
  | 'enabled'
  | 'fields'
  | 'fingerprints'
  | 'id'
  | 'minutes'
  | 'response'
  | TemplateKeySpecifier
)[];
export type TemplateFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  default?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  fields?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  minutes?: FieldPolicy<any> | FieldReadFunction<any>;
  response?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TemplateItemKeySpecifier = ('id' | 'name' | TemplateItemKeySpecifier)[];
export type TemplateItemFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TenantKeySpecifier = (
  | 'accountId'
  | 'allResidencies'
  | 'autopayAccount'
  | 'autopayClientId'
  | 'autopayCollectionsEnabled'
  | 'autopayCollectionsEnabledZ'
  | 'autopayInstitution'
  | 'autopayInvitedZ'
  | 'autopayStatus'
  | 'autopayStatusUpdatedZ'
  | 'autopayTransit'
  | 'correspondenceMethod'
  | 'currentResidencies'
  | 'dob'
  | 'documents'
  | 'emails'
  | 'emergencyContacts'
  | 'enabled'
  | 'enrollments'
  | 'fingerprints'
  | 'futureResidencies'
  | 'id'
  | 'isOwner'
  | 'isRentalAssist'
  | 'leaseFutureIds'
  | 'leaseHistoryIds'
  | 'leaseHolder'
  | 'leaseId'
  | 'mailingAddress'
  | 'name'
  | 'notes'
  | 'padAccount'
  | 'padAmount'
  | 'padInstitution'
  | 'padTransit'
  | 'pets'
  | 'phone'
  | 'phones'
  | 'preferredPaymentMethod'
  | 'profile'
  | 'profileId'
  | 'propertyId'
  | 'propertyOptions'
  | 'rentalAssistance'
  | 'sendWelcomeEmail'
  | 'type'
  | 'unitId'
  | 'vehicles'
  | TenantKeySpecifier
)[];
export type TenantFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  allResidencies?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayClientId?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayCollectionsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayCollectionsEnabledZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayInstitution?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayInvitedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayStatusUpdatedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  autopayTransit?: FieldPolicy<any> | FieldReadFunction<any>;
  correspondenceMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  currentResidencies?: FieldPolicy<any> | FieldReadFunction<any>;
  dob?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  emails?: FieldPolicy<any> | FieldReadFunction<any>;
  emergencyContacts?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  enrollments?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  futureResidencies?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  isRentalAssist?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseFutureIds?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseHistoryIds?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseHolder?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseId?: FieldPolicy<any> | FieldReadFunction<any>;
  mailingAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  padAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  padAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  padInstitution?: FieldPolicy<any> | FieldReadFunction<any>;
  padTransit?: FieldPolicy<any> | FieldReadFunction<any>;
  pets?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  phones?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredPaymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  profile?: FieldPolicy<any> | FieldReadFunction<any>;
  profileId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  rentalAssistance?: FieldPolicy<any> | FieldReadFunction<any>;
  sendWelcomeEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicles?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TenantConnectionKeySpecifier = ('items' | 'nextToken' | TenantConnectionKeySpecifier)[];
export type TenantConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TenantLeaseKeySpecifier = (
  | 'isPayee'
  | 'leaseHolder'
  | 'leaseId'
  | 'propertyId'
  | 'status'
  | 'unitId'
  | TenantLeaseKeySpecifier
)[];
export type TenantLeaseFieldPolicy = {
  isPayee?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseHolder?: FieldPolicy<any> | FieldReadFunction<any>;
  leaseId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TenantPayloadKeySpecifier = (
  | 'error'
  | 'success'
  | 'tenant'
  | TenantPayloadKeySpecifier
)[];
export type TenantPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  tenant?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TotalByAccountKeySpecifier = (
  | 'accountId'
  | 'totalOutstanding'
  | TotalByAccountKeySpecifier
)[];
export type TotalByAccountFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalOutstanding?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TotalByPropertyKeySpecifier = (
  | 'propertyId'
  | 'totalOutstanding'
  | TotalByPropertyKeySpecifier
)[];
export type TotalByPropertyFieldPolicy = {
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalOutstanding?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TotalByUnitKeySpecifier = ('totalOutstanding' | 'unitId' | TotalByUnitKeySpecifier)[];
export type TotalByUnitFieldPolicy = {
  totalOutstanding?: FieldPolicy<any> | FieldReadFunction<any>;
  unitId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransferJournalEntriesPayloadKeySpecifier = (
  | 'destinationJournalIds'
  | 'error'
  | 'sourceJournalIds'
  | 'success'
  | TransferJournalEntriesPayloadKeySpecifier
)[];
export type TransferJournalEntriesPayloadFieldPolicy = {
  destinationJournalIds?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceJournalIds?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UnitKeySpecifier = (
  | 'additionalInformation'
  | 'allResidencies'
  | 'bathrooms'
  | 'bedrooms'
  | 'building'
  | 'buildingId'
  | 'buildingName'
  | 'condition'
  | 'condoFee'
  | 'condoFeeGst'
  | 'condoUnitFees'
  | 'currentResidencies'
  | 'documents'
  | 'fingerprints'
  | 'floorplan'
  | 'floorplanId'
  | 'futureResidencies'
  | 'history'
  | 'id'
  | 'keyring'
  | 'legalAddress'
  | 'name'
  | 'notes'
  | 'occupancy'
  | 'owner'
  | 'ownerId'
  | 'parking'
  | 'paymentDetails'
  | 'preferredPaymentMethod'
  | 'property'
  | 'propertyId'
  | 'propertyName'
  | 'requests'
  | 'reserveAmount'
  | 'residency'
  | 'routineInspections'
  | 'sqft'
  | 'storage'
  | 'test'
  | 'unitFactor'
  | UnitKeySpecifier
)[];
export type UnitFieldPolicy = {
  additionalInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  allResidencies?: FieldPolicy<any> | FieldReadFunction<any>;
  bathrooms?: FieldPolicy<any> | FieldReadFunction<any>;
  bedrooms?: FieldPolicy<any> | FieldReadFunction<any>;
  building?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingId?: FieldPolicy<any> | FieldReadFunction<any>;
  buildingName?: FieldPolicy<any> | FieldReadFunction<any>;
  condition?: FieldPolicy<any> | FieldReadFunction<any>;
  condoFee?: FieldPolicy<any> | FieldReadFunction<any>;
  condoFeeGst?: FieldPolicy<any> | FieldReadFunction<any>;
  condoUnitFees?: FieldPolicy<any> | FieldReadFunction<any>;
  currentResidencies?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  floorplan?: FieldPolicy<any> | FieldReadFunction<any>;
  floorplanId?: FieldPolicy<any> | FieldReadFunction<any>;
  futureResidencies?: FieldPolicy<any> | FieldReadFunction<any>;
  history?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  keyring?: FieldPolicy<any> | FieldReadFunction<any>;
  legalAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  occupancy?: FieldPolicy<any> | FieldReadFunction<any>;
  owner?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
  parking?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredPaymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyName?: FieldPolicy<any> | FieldReadFunction<any>;
  requests?: FieldPolicy<any> | FieldReadFunction<any>;
  reserveAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  residency?: FieldPolicy<any> | FieldReadFunction<any>;
  routineInspections?: FieldPolicy<any> | FieldReadFunction<any>;
  sqft?: FieldPolicy<any> | FieldReadFunction<any>;
  storage?: FieldPolicy<any> | FieldReadFunction<any>;
  test?: FieldPolicy<any> | FieldReadFunction<any>;
  unitFactor?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UnitConditionKeySpecifier = (
  | 'category'
  | 'id'
  | 'items'
  | 'templateId'
  | UnitConditionKeySpecifier
)[];
export type UnitConditionFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  templateId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UnitConnectionKeySpecifier = ('items' | 'nextToken' | UnitConnectionKeySpecifier)[];
export type UnitConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UnitEdgeKeySpecifier = ('cursor' | 'node' | UnitEdgeKeySpecifier)[];
export type UnitEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UnitHeaderKeySpecifier = (
  | 'address'
  | 'id'
  | 'name'
  | 'occupancy'
  | 'propertyId'
  | 'propertyImageKey'
  | 'propertyName'
  | UnitHeaderKeySpecifier
)[];
export type UnitHeaderFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  occupancy?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyImageKey?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UnitHistoryKeySpecifier = ('fingerprints' | 'id' | 'name' | UnitHistoryKeySpecifier)[];
export type UnitHistoryFieldPolicy = {
  fingerprints?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UnitNamesKeySpecifier = (
  | 'complete'
  | 'names'
  | 'namesFound'
  | 'totalUnits'
  | UnitNamesKeySpecifier
)[];
export type UnitNamesFieldPolicy = {
  complete?: FieldPolicy<any> | FieldReadFunction<any>;
  names?: FieldPolicy<any> | FieldReadFunction<any>;
  namesFound?: FieldPolicy<any> | FieldReadFunction<any>;
  totalUnits?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UnitPayloadKeySpecifier = ('error' | 'success' | 'unit' | UnitPayloadKeySpecifier)[];
export type UnitPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UnitsPageKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | UnitsPageKeySpecifier)[];
export type UnitsPageFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UpdateBuildingPayloadKeySpecifier = (
  | 'building'
  | 'error'
  | 'property'
  | 'success'
  | UpdateBuildingPayloadKeySpecifier
)[];
export type UpdateBuildingPayloadFieldPolicy = {
  building?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  property?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UpdateGLAccountPayloadKeySpecifier = (
  | 'error'
  | 'glAccount'
  | 'success'
  | UpdateGLAccountPayloadKeySpecifier
)[];
export type UpdateGLAccountPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  glAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UpdateJournalPayloadKeySpecifier = (
  | 'error'
  | 'journalEntry'
  | 'success'
  | UpdateJournalPayloadKeySpecifier
)[];
export type UpdateJournalPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  journalEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UpdateScanApprovalPayloadKeySpecifier = (
  | 'approval'
  | 'error'
  | 'success'
  | UpdateScanApprovalPayloadKeySpecifier
)[];
export type UpdateScanApprovalPayloadFieldPolicy = {
  approval?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UpdateVisitPayloadKeySpecifier = (
  | 'error'
  | 'success'
  | 'visit'
  | UpdateVisitPayloadKeySpecifier
)[];
export type UpdateVisitPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  visit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UpsertRoutineInspectionPayloadKeySpecifier = (
  | 'error'
  | 'inspection'
  | 'success'
  | 'unit'
  | UpsertRoutineInspectionPayloadKeySpecifier
)[];
export type UpsertRoutineInspectionPayloadFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  inspection?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UtilitiesTemplateKeySpecifier = (
  | 'id'
  | 'items'
  | 'name'
  | 'propertyId'
  | UtilitiesTemplateKeySpecifier
)[];
export type UtilitiesTemplateFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VehicleKeySpecifier = (
  | 'color'
  | 'id'
  | 'make'
  | 'model'
  | 'plate'
  | VehicleKeySpecifier
)[];
export type VehicleFieldPolicy = {
  color?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  make?: FieldPolicy<any> | FieldReadFunction<any>;
  model?: FieldPolicy<any> | FieldReadFunction<any>;
  plate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VisitKeySpecifier = (
  | 'appointmentZ'
  | 'bill'
  | 'category'
  | 'completedDelta'
  | 'completedZ'
  | 'delay'
  | 'dueZ'
  | 'id'
  | 'images'
  | 'instructions'
  | 'journalId'
  | 'labourCost'
  | 'managerContactInformation'
  | 'materialCosts'
  | 'materialCostsNote'
  | 'materialMarkup'
  | 'minutes'
  | 'notes'
  | 'operatorId'
  | 'operatorName'
  | 'status'
  | 'tenantProposedTimes'
  | 'timezone'
  | VisitKeySpecifier
)[];
export type VisitFieldPolicy = {
  appointmentZ?: FieldPolicy<any> | FieldReadFunction<any>;
  bill?: FieldPolicy<any> | FieldReadFunction<any>;
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  completedDelta?: FieldPolicy<any> | FieldReadFunction<any>;
  completedZ?: FieldPolicy<any> | FieldReadFunction<any>;
  delay?: FieldPolicy<any> | FieldReadFunction<any>;
  dueZ?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  instructions?: FieldPolicy<any> | FieldReadFunction<any>;
  journalId?: FieldPolicy<any> | FieldReadFunction<any>;
  labourCost?: FieldPolicy<any> | FieldReadFunction<any>;
  managerContactInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  materialCosts?: FieldPolicy<any> | FieldReadFunction<any>;
  materialCostsNote?: FieldPolicy<any> | FieldReadFunction<any>;
  materialMarkup?: FieldPolicy<any> | FieldReadFunction<any>;
  minutes?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  operatorId?: FieldPolicy<any> | FieldReadFunction<any>;
  operatorName?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tenantProposedTimes?: FieldPolicy<any> | FieldReadFunction<any>;
  timezone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VopayKeySpecifier = ('accountId' | 'key' | 'secretCipher' | VopayKeySpecifier)[];
export type VopayFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  secretCipher?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WorkHistoryKeySpecifier = (
  | 'amount'
  | 'endDate'
  | 'frequency'
  | 'id'
  | 'location'
  | 'name'
  | 'phone'
  | 'startDate'
  | WorkHistoryKeySpecifier
)[];
export type WorkHistoryFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  endDate?: FieldPolicy<any> | FieldReadFunction<any>;
  frequency?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  startDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
  Account?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AccountKeySpecifier | (() => undefined | AccountKeySpecifier);
    fields?: AccountFieldPolicy;
  };
  AccountDetail?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AccountDetailKeySpecifier | (() => undefined | AccountDetailKeySpecifier);
    fields?: AccountDetailFieldPolicy;
  };
  AccountEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AccountEventKeySpecifier | (() => undefined | AccountEventKeySpecifier);
    fields?: AccountEventFieldPolicy;
  };
  AccountFact?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AccountFactKeySpecifier | (() => undefined | AccountFactKeySpecifier);
    fields?: AccountFactFieldPolicy;
  };
  AccountFactConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AccountFactConnectionKeySpecifier
      | (() => undefined | AccountFactConnectionKeySpecifier);
    fields?: AccountFactConnectionFieldPolicy;
  };
  AccountPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AccountPayloadKeySpecifier | (() => undefined | AccountPayloadKeySpecifier);
    fields?: AccountPayloadFieldPolicy;
  };
  AccountStat?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AccountStatKeySpecifier | (() => undefined | AccountStatKeySpecifier);
    fields?: AccountStatFieldPolicy;
  };
  AccountStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AccountStatsKeySpecifier | (() => undefined | AccountStatsKeySpecifier);
    fields?: AccountStatsFieldPolicy;
  };
  ActivateBooksPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ActivateBooksPayloadKeySpecifier
      | (() => undefined | ActivateBooksPayloadKeySpecifier);
    fields?: ActivateBooksPayloadFieldPolicy;
  };
  AdditionalProvisions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdditionalProvisionsKeySpecifier
      | (() => undefined | AdditionalProvisionsKeySpecifier);
    fields?: AdditionalProvisionsFieldPolicy;
  };
  AdditionalTenant?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdditionalTenantKeySpecifier
      | (() => undefined | AdditionalTenantKeySpecifier);
    fields?: AdditionalTenantFieldPolicy;
  };
  Address?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AddressKeySpecifier | (() => undefined | AddressKeySpecifier);
    fields?: AddressFieldPolicy;
  };
  AddressHistory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AddressHistoryKeySpecifier | (() => undefined | AddressHistoryKeySpecifier);
    fields?: AddressHistoryFieldPolicy;
  };
  Agreement?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AgreementKeySpecifier | (() => undefined | AgreementKeySpecifier);
    fields?: AgreementFieldPolicy;
  };
  AgreementLease?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AgreementLeaseKeySpecifier | (() => undefined | AgreementLeaseKeySpecifier);
    fields?: AgreementLeaseFieldPolicy;
  };
  AgreementLeaseFees?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AgreementLeaseFeesKeySpecifier
      | (() => undefined | AgreementLeaseFeesKeySpecifier);
    fields?: AgreementLeaseFeesFieldPolicy;
  };
  AgreementUrl?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AgreementUrlKeySpecifier | (() => undefined | AgreementUrlKeySpecifier);
    fields?: AgreementUrlFieldPolicy;
  };
  AmenitiesSetting?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AmenitiesSettingKeySpecifier
      | (() => undefined | AmenitiesSettingKeySpecifier);
    fields?: AmenitiesSettingFieldPolicy;
  };
  Amenity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AmenityKeySpecifier | (() => undefined | AmenityKeySpecifier);
    fields?: AmenityFieldPolicy;
  };
  AmenityCategory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AmenityCategoryKeySpecifier
      | (() => undefined | AmenityCategoryKeySpecifier);
    fields?: AmenityCategoryFieldPolicy;
  };
  Announcement?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AnnouncementKeySpecifier | (() => undefined | AnnouncementKeySpecifier);
    fields?: AnnouncementFieldPolicy;
  };
  AnnouncementConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AnnouncementConnectionKeySpecifier
      | (() => undefined | AnnouncementConnectionKeySpecifier);
    fields?: AnnouncementConnectionFieldPolicy;
  };
  AnnouncementEmail?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AnnouncementEmailKeySpecifier
      | (() => undefined | AnnouncementEmailKeySpecifier);
    fields?: AnnouncementEmailFieldPolicy;
  };
  AnnouncementPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AnnouncementPayloadKeySpecifier
      | (() => undefined | AnnouncementPayloadKeySpecifier);
    fields?: AnnouncementPayloadFieldPolicy;
  };
  AnnouncementScope?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AnnouncementScopeKeySpecifier
      | (() => undefined | AnnouncementScopeKeySpecifier);
    fields?: AnnouncementScopeFieldPolicy;
  };
  AnnouncementVoice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AnnouncementVoiceKeySpecifier
      | (() => undefined | AnnouncementVoiceKeySpecifier);
    fields?: AnnouncementVoiceFieldPolicy;
  };
  Application?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ApplicationKeySpecifier | (() => undefined | ApplicationKeySpecifier);
    fields?: ApplicationFieldPolicy;
  };
  ApplicationPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ApplicationPayloadKeySpecifier
      | (() => undefined | ApplicationPayloadKeySpecifier);
    fields?: ApplicationPayloadFieldPolicy;
  };
  AppointmentChange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AppointmentChangeKeySpecifier
      | (() => undefined | AppointmentChangeKeySpecifier);
    fields?: AppointmentChangeFieldPolicy;
  };
  Approval?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ApprovalKeySpecifier | (() => undefined | ApprovalKeySpecifier);
    fields?: ApprovalFieldPolicy;
  };
  ApprovalConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ApprovalConnectionKeySpecifier
      | (() => undefined | ApprovalConnectionKeySpecifier);
    fields?: ApprovalConnectionFieldPolicy;
  };
  ApprovalPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ApprovalPayloadKeySpecifier
      | (() => undefined | ApprovalPayloadKeySpecifier);
    fields?: ApprovalPayloadFieldPolicy;
  };
  ApprovalRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ApprovalRecordKeySpecifier | (() => undefined | ApprovalRecordKeySpecifier);
    fields?: ApprovalRecordFieldPolicy;
  };
  Asset?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AssetKeySpecifier | (() => undefined | AssetKeySpecifier);
    fields?: AssetFieldPolicy;
  };
  AssetFee?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AssetFeeKeySpecifier | (() => undefined | AssetFeeKeySpecifier);
    fields?: AssetFeeFieldPolicy;
  };
  AssetShare?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AssetShareKeySpecifier | (() => undefined | AssetShareKeySpecifier);
    fields?: AssetShareFieldPolicy;
  };
  AssignKeyringPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AssignKeyringPayloadKeySpecifier
      | (() => undefined | AssignKeyringPayloadKeySpecifier);
    fields?: AssignKeyringPayloadFieldPolicy;
  };
  AssignParkingPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AssignParkingPayloadKeySpecifier
      | (() => undefined | AssignParkingPayloadKeySpecifier);
    fields?: AssignParkingPayloadFieldPolicy;
  };
  AssignStoragePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AssignStoragePayloadKeySpecifier
      | (() => undefined | AssignStoragePayloadKeySpecifier);
    fields?: AssignStoragePayloadFieldPolicy;
  };
  Attachment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AttachmentKeySpecifier | (() => undefined | AttachmentKeySpecifier);
    fields?: AttachmentFieldPolicy;
  };
  AutoRenewSetup?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AutoRenewSetupKeySpecifier | (() => undefined | AutoRenewSetupKeySpecifier);
    fields?: AutoRenewSetupFieldPolicy;
  };
  Autopay?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AutopayKeySpecifier | (() => undefined | AutopayKeySpecifier);
    fields?: AutopayFieldPolicy;
  };
  AutopayEnrollment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutopayEnrollmentKeySpecifier
      | (() => undefined | AutopayEnrollmentKeySpecifier);
    fields?: AutopayEnrollmentFieldPolicy;
  };
  AutopayEnrollmentConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutopayEnrollmentConnectionKeySpecifier
      | (() => undefined | AutopayEnrollmentConnectionKeySpecifier);
    fields?: AutopayEnrollmentConnectionFieldPolicy;
  };
  AutopayEnrollmentPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutopayEnrollmentPayloadKeySpecifier
      | (() => undefined | AutopayEnrollmentPayloadKeySpecifier);
    fields?: AutopayEnrollmentPayloadFieldPolicy;
  };
  AutopayEnrollmentStatusHistory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutopayEnrollmentStatusHistoryKeySpecifier
      | (() => undefined | AutopayEnrollmentStatusHistoryKeySpecifier);
    fields?: AutopayEnrollmentStatusHistoryFieldPolicy;
  };
  AutopayOnboardingUrlPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutopayOnboardingUrlPayloadKeySpecifier
      | (() => undefined | AutopayOnboardingUrlPayloadKeySpecifier);
    fields?: AutopayOnboardingUrlPayloadFieldPolicy;
  };
  AutopayPayee?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AutopayPayeeKeySpecifier | (() => undefined | AutopayPayeeKeySpecifier);
    fields?: AutopayPayeeFieldPolicy;
  };
  AutopayPayeeEdge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutopayPayeeEdgeKeySpecifier
      | (() => undefined | AutopayPayeeEdgeKeySpecifier);
    fields?: AutopayPayeeEdgeFieldPolicy;
  };
  AutopayPayeePage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutopayPayeePageKeySpecifier
      | (() => undefined | AutopayPayeePageKeySpecifier);
    fields?: AutopayPayeePageFieldPolicy;
  };
  AutopayPayeePropertyUnit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutopayPayeePropertyUnitKeySpecifier
      | (() => undefined | AutopayPayeePropertyUnitKeySpecifier);
    fields?: AutopayPayeePropertyUnitFieldPolicy;
  };
  AutopayStatusHistory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutopayStatusHistoryKeySpecifier
      | (() => undefined | AutopayStatusHistoryKeySpecifier);
    fields?: AutopayStatusHistoryFieldPolicy;
  };
  AutopaySummaryConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutopaySummaryConnectionKeySpecifier
      | (() => undefined | AutopaySummaryConnectionKeySpecifier);
    fields?: AutopaySummaryConnectionFieldPolicy;
  };
  Bank?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BankKeySpecifier | (() => undefined | BankKeySpecifier);
    fields?: BankFieldPolicy;
  };
  Batch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BatchKeySpecifier | (() => undefined | BatchKeySpecifier);
    fields?: BatchFieldPolicy;
  };
  BatchPrototypePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BatchPrototypePayloadKeySpecifier
      | (() => undefined | BatchPrototypePayloadKeySpecifier);
    fields?: BatchPrototypePayloadFieldPolicy;
  };
  BatchTask?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BatchTaskKeySpecifier | (() => undefined | BatchTaskKeySpecifier);
    fields?: BatchTaskFieldPolicy;
  };
  BatchTaskPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BatchTaskPayloadKeySpecifier
      | (() => undefined | BatchTaskPayloadKeySpecifier);
    fields?: BatchTaskPayloadFieldPolicy;
  };
  BatchTaskProgress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BatchTaskProgressKeySpecifier
      | (() => undefined | BatchTaskProgressKeySpecifier);
    fields?: BatchTaskProgressFieldPolicy;
  };
  BillingAccount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BillingAccountKeySpecifier | (() => undefined | BillingAccountKeySpecifier);
    fields?: BillingAccountFieldPolicy;
  };
  BillingRate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BillingRateKeySpecifier | (() => undefined | BillingRateKeySpecifier);
    fields?: BillingRateFieldPolicy;
  };
  Books?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BooksKeySpecifier | (() => undefined | BooksKeySpecifier);
    fields?: BooksFieldPolicy;
  };
  BooksActivatedPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BooksActivatedPayloadKeySpecifier
      | (() => undefined | BooksActivatedPayloadKeySpecifier);
    fields?: BooksActivatedPayloadFieldPolicy;
  };
  BooksBatchPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BooksBatchPayloadKeySpecifier
      | (() => undefined | BooksBatchPayloadKeySpecifier);
    fields?: BooksBatchPayloadFieldPolicy;
  };
  BooksPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BooksPayloadKeySpecifier | (() => undefined | BooksPayloadKeySpecifier);
    fields?: BooksPayloadFieldPolicy;
  };
  BooksPrintedCheque?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BooksPrintedChequeKeySpecifier
      | (() => undefined | BooksPrintedChequeKeySpecifier);
    fields?: BooksPrintedChequeFieldPolicy;
  };
  BooksReport?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BooksReportKeySpecifier | (() => undefined | BooksReportKeySpecifier);
    fields?: BooksReportFieldPolicy;
  };
  BooksReportExtraColumn?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BooksReportExtraColumnKeySpecifier
      | (() => undefined | BooksReportExtraColumnKeySpecifier);
    fields?: BooksReportExtraColumnFieldPolicy;
  };
  BooksReportFooter?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BooksReportFooterKeySpecifier
      | (() => undefined | BooksReportFooterKeySpecifier);
    fields?: BooksReportFooterFieldPolicy;
  };
  BooksReportHeader?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BooksReportHeaderKeySpecifier
      | (() => undefined | BooksReportHeaderKeySpecifier);
    fields?: BooksReportHeaderFieldPolicy;
  };
  BooksReportOptions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BooksReportOptionsKeySpecifier
      | (() => undefined | BooksReportOptionsKeySpecifier);
    fields?: BooksReportOptionsFieldPolicy;
  };
  BooksReportPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BooksReportPayloadKeySpecifier
      | (() => undefined | BooksReportPayloadKeySpecifier);
    fields?: BooksReportPayloadFieldPolicy;
  };
  BooksReportRendered?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BooksReportRenderedKeySpecifier
      | (() => undefined | BooksReportRenderedKeySpecifier);
    fields?: BooksReportRenderedFieldPolicy;
  };
  Budget?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BudgetKeySpecifier | (() => undefined | BudgetKeySpecifier);
    fields?: BudgetFieldPolicy;
  };
  BudgetsPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BudgetsPayloadKeySpecifier | (() => undefined | BudgetsPayloadKeySpecifier);
    fields?: BudgetsPayloadFieldPolicy;
  };
  Building?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BuildingKeySpecifier | (() => undefined | BuildingKeySpecifier);
    fields?: BuildingFieldPolicy;
  };
  BundledReport?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BundledReportKeySpecifier | (() => undefined | BundledReportKeySpecifier);
    fields?: BundledReportFieldPolicy;
  };
  BundledReportPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BundledReportPayloadKeySpecifier
      | (() => undefined | BundledReportPayloadKeySpecifier);
    fields?: BundledReportPayloadFieldPolicy;
  };
  Campaign?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CampaignKeySpecifier | (() => undefined | CampaignKeySpecifier);
    fields?: CampaignFieldPolicy;
  };
  CampaignApplicationDocRule?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CampaignApplicationDocRuleKeySpecifier
      | (() => undefined | CampaignApplicationDocRuleKeySpecifier);
    fields?: CampaignApplicationDocRuleFieldPolicy;
  };
  CampaignApplicationRule?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CampaignApplicationRuleKeySpecifier
      | (() => undefined | CampaignApplicationRuleKeySpecifier);
    fields?: CampaignApplicationRuleFieldPolicy;
  };
  CampaignConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CampaignConnectionKeySpecifier
      | (() => undefined | CampaignConnectionKeySpecifier);
    fields?: CampaignConnectionFieldPolicy;
  };
  CampaignPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CampaignPayloadKeySpecifier
      | (() => undefined | CampaignPayloadKeySpecifier);
    fields?: CampaignPayloadFieldPolicy;
  };
  Charge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ChargeKeySpecifier | (() => undefined | ChargeKeySpecifier);
    fields?: ChargeFieldPolicy;
  };
  ChargeApprovalRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ChargeApprovalRequestKeySpecifier
      | (() => undefined | ChargeApprovalRequestKeySpecifier);
    fields?: ChargeApprovalRequestFieldPolicy;
  };
  ChargeMapping?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ChargeMappingKeySpecifier | (() => undefined | ChargeMappingKeySpecifier);
    fields?: ChargeMappingFieldPolicy;
  };
  ChecklistItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ChecklistItemKeySpecifier | (() => undefined | ChecklistItemKeySpecifier);
    fields?: ChecklistItemFieldPolicy;
  };
  ChequePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ChequePayloadKeySpecifier | (() => undefined | ChequePayloadKeySpecifier);
    fields?: ChequePayloadFieldPolicy;
  };
  Claim?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ClaimKeySpecifier | (() => undefined | ClaimKeySpecifier);
    fields?: ClaimFieldPolicy;
  };
  ClaimPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ClaimPayloadKeySpecifier | (() => undefined | ClaimPayloadKeySpecifier);
    fields?: ClaimPayloadFieldPolicy;
  };
  Clearable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ClearableKeySpecifier | (() => undefined | ClearableKeySpecifier);
    fields?: ClearableFieldPolicy;
  };
  ClearableConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ClearableConnectionKeySpecifier
      | (() => undefined | ClearableConnectionKeySpecifier);
    fields?: ClearableConnectionFieldPolicy;
  };
  ClearableCount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ClearableCountKeySpecifier | (() => undefined | ClearableCountKeySpecifier);
    fields?: ClearableCountFieldPolicy;
  };
  ClearableEdge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ClearableEdgeKeySpecifier | (() => undefined | ClearableEdgeKeySpecifier);
    fields?: ClearableEdgeFieldPolicy;
  };
  ClearablePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ClearablePayloadKeySpecifier
      | (() => undefined | ClearablePayloadKeySpecifier);
    fields?: ClearablePayloadFieldPolicy;
  };
  ClearablesPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ClearablesPageKeySpecifier | (() => undefined | ClearablesPageKeySpecifier);
    fields?: ClearablesPageFieldPolicy;
  };
  ClearablesPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ClearablesPayloadKeySpecifier
      | (() => undefined | ClearablesPayloadKeySpecifier);
    fields?: ClearablesPayloadFieldPolicy;
  };
  CoApplication?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CoApplicationKeySpecifier | (() => undefined | CoApplicationKeySpecifier);
    fields?: CoApplicationFieldPolicy;
  };
  CoApplicationLeaseAgreement?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CoApplicationLeaseAgreementKeySpecifier
      | (() => undefined | CoApplicationLeaseAgreementKeySpecifier);
    fields?: CoApplicationLeaseAgreementFieldPolicy;
  };
  Collections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CollectionsKeySpecifier | (() => undefined | CollectionsKeySpecifier);
    fields?: CollectionsFieldPolicy;
  };
  CombinedSubAccount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CombinedSubAccountKeySpecifier
      | (() => undefined | CombinedSubAccountKeySpecifier);
    fields?: CombinedSubAccountFieldPolicy;
  };
  CondoUnitFee?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CondoUnitFeeKeySpecifier | (() => undefined | CondoUnitFeeKeySpecifier);
    fields?: CondoUnitFeeFieldPolicy;
  };
  Contact?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ContactKeySpecifier | (() => undefined | ContactKeySpecifier);
    fields?: ContactFieldPolicy;
  };
  ContactPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ContactPayloadKeySpecifier | (() => undefined | ContactPayloadKeySpecifier);
    fields?: ContactPayloadFieldPolicy;
  };
  ContractorBill?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ContractorBillKeySpecifier | (() => undefined | ContractorBillKeySpecifier);
    fields?: ContractorBillFieldPolicy;
  };
  CostLine?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CostLineKeySpecifier | (() => undefined | CostLineKeySpecifier);
    fields?: CostLineFieldPolicy;
  };
  CreateApplicationNotePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateApplicationNotePayloadKeySpecifier
      | (() => undefined | CreateApplicationNotePayloadKeySpecifier);
    fields?: CreateApplicationNotePayloadFieldPolicy;
  };
  CreateAutopayEmbedUrlPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateAutopayEmbedUrlPayloadKeySpecifier
      | (() => undefined | CreateAutopayEmbedUrlPayloadKeySpecifier);
    fields?: CreateAutopayEmbedUrlPayloadFieldPolicy;
  };
  CreateBuildingPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateBuildingPayloadKeySpecifier
      | (() => undefined | CreateBuildingPayloadKeySpecifier);
    fields?: CreateBuildingPayloadFieldPolicy;
  };
  CreateCampaignPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateCampaignPayloadKeySpecifier
      | (() => undefined | CreateCampaignPayloadKeySpecifier);
    fields?: CreateCampaignPayloadFieldPolicy;
  };
  CreateDraftJEBatchPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateDraftJEBatchPayloadKeySpecifier
      | (() => undefined | CreateDraftJEBatchPayloadKeySpecifier);
    fields?: CreateDraftJEBatchPayloadFieldPolicy;
  };
  CreateFloorplanPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateFloorplanPayloadKeySpecifier
      | (() => undefined | CreateFloorplanPayloadKeySpecifier);
    fields?: CreateFloorplanPayloadFieldPolicy;
  };
  CreateGLAccountPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateGLAccountPayloadKeySpecifier
      | (() => undefined | CreateGLAccountPayloadKeySpecifier);
    fields?: CreateGLAccountPayloadFieldPolicy;
  };
  CreateRequestCostPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateRequestCostPayloadKeySpecifier
      | (() => undefined | CreateRequestCostPayloadKeySpecifier);
    fields?: CreateRequestCostPayloadFieldPolicy;
  };
  CreateRequestNotePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateRequestNotePayloadKeySpecifier
      | (() => undefined | CreateRequestNotePayloadKeySpecifier);
    fields?: CreateRequestNotePayloadFieldPolicy;
  };
  CreateRequestPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateRequestPayloadKeySpecifier
      | (() => undefined | CreateRequestPayloadKeySpecifier);
    fields?: CreateRequestPayloadFieldPolicy;
  };
  CreateVisitPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateVisitPayloadKeySpecifier
      | (() => undefined | CreateVisitPayloadKeySpecifier);
    fields?: CreateVisitPayloadFieldPolicy;
  };
  DeleteApplicationNotePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DeleteApplicationNotePayloadKeySpecifier
      | (() => undefined | DeleteApplicationNotePayloadKeySpecifier);
    fields?: DeleteApplicationNotePayloadFieldPolicy;
  };
  DeleteBuildingPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DeleteBuildingPayloadKeySpecifier
      | (() => undefined | DeleteBuildingPayloadKeySpecifier);
    fields?: DeleteBuildingPayloadFieldPolicy;
  };
  DeleteClaimPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DeleteClaimPayloadKeySpecifier
      | (() => undefined | DeleteClaimPayloadKeySpecifier);
    fields?: DeleteClaimPayloadFieldPolicy;
  };
  DeleteClearablePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DeleteClearablePayloadKeySpecifier
      | (() => undefined | DeleteClearablePayloadKeySpecifier);
    fields?: DeleteClearablePayloadFieldPolicy;
  };
  DeleteRequestNotePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DeleteRequestNotePayloadKeySpecifier
      | (() => undefined | DeleteRequestNotePayloadKeySpecifier);
    fields?: DeleteRequestNotePayloadFieldPolicy;
  };
  DepositTemplate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DepositTemplateKeySpecifier
      | (() => undefined | DepositTemplateKeySpecifier);
    fields?: DepositTemplateFieldPolicy;
  };
  DepositTemplateItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DepositTemplateItemKeySpecifier
      | (() => undefined | DepositTemplateItemKeySpecifier);
    fields?: DepositTemplateItemFieldPolicy;
  };
  Document?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DocumentKeySpecifier | (() => undefined | DocumentKeySpecifier);
    fields?: DocumentFieldPolicy;
  };
  DocumentLinkPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentLinkPayloadKeySpecifier
      | (() => undefined | DocumentLinkPayloadKeySpecifier);
    fields?: DocumentLinkPayloadFieldPolicy;
  };
  DraftReconciliationPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DraftReconciliationPayloadKeySpecifier
      | (() => undefined | DraftReconciliationPayloadKeySpecifier);
    fields?: DraftReconciliationPayloadFieldPolicy;
  };
  DraftedJournalEntriesPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DraftedJournalEntriesPageKeySpecifier
      | (() => undefined | DraftedJournalEntriesPageKeySpecifier);
    fields?: DraftedJournalEntriesPageFieldPolicy;
  };
  DraftedJournalEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DraftedJournalEntryKeySpecifier
      | (() => undefined | DraftedJournalEntryKeySpecifier);
    fields?: DraftedJournalEntryFieldPolicy;
  };
  DraftedJournalEntryEdge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DraftedJournalEntryEdgeKeySpecifier
      | (() => undefined | DraftedJournalEntryEdgeKeySpecifier);
    fields?: DraftedJournalEntryEdgeFieldPolicy;
  };
  EmergencyContact?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EmergencyContactKeySpecifier
      | (() => undefined | EmergencyContactKeySpecifier);
    fields?: EmergencyContactFieldPolicy;
  };
  Exception?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ExceptionKeySpecifier | (() => undefined | ExceptionKeySpecifier);
    fields?: ExceptionFieldPolicy;
  };
  Feature?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FeatureKeySpecifier | (() => undefined | FeatureKeySpecifier);
    fields?: FeatureFieldPolicy;
  };
  Fee?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FeeKeySpecifier | (() => undefined | FeeKeySpecifier);
    fields?: FeeFieldPolicy;
  };
  FeeMapping?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FeeMappingKeySpecifier | (() => undefined | FeeMappingKeySpecifier);
    fields?: FeeMappingFieldPolicy;
  };
  Field?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FieldKeySpecifier | (() => undefined | FieldKeySpecifier);
    fields?: FieldFieldPolicy;
  };
  FinalizeRequestPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FinalizeRequestPayloadKeySpecifier
      | (() => undefined | FinalizeRequestPayloadKeySpecifier);
    fields?: FinalizeRequestPayloadFieldPolicy;
  };
  Fingerprints?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FingerprintsKeySpecifier | (() => undefined | FingerprintsKeySpecifier);
    fields?: FingerprintsFieldPolicy;
  };
  Floorplan?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FloorplanKeySpecifier | (() => undefined | FloorplanKeySpecifier);
    fields?: FloorplanFieldPolicy;
  };
  FloorplanArea?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FloorplanAreaKeySpecifier | (() => undefined | FloorplanAreaKeySpecifier);
    fields?: FloorplanAreaFieldPolicy;
  };
  FloorplanAreaFeature?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FloorplanAreaFeatureKeySpecifier
      | (() => undefined | FloorplanAreaFeatureKeySpecifier);
    fields?: FloorplanAreaFeatureFieldPolicy;
  };
  FloorplanConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FloorplanConnectionKeySpecifier
      | (() => undefined | FloorplanConnectionKeySpecifier);
    fields?: FloorplanConnectionFieldPolicy;
  };
  FloorplanPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FloorplanPayloadKeySpecifier
      | (() => undefined | FloorplanPayloadKeySpecifier);
    fields?: FloorplanPayloadFieldPolicy;
  };
  Fraction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FractionKeySpecifier | (() => undefined | FractionKeySpecifier);
    fields?: FractionFieldPolicy;
  };
  FurnishingsTemplate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FurnishingsTemplateKeySpecifier
      | (() => undefined | FurnishingsTemplateKeySpecifier);
    fields?: FurnishingsTemplateFieldPolicy;
  };
  GLAccount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | GLAccountKeySpecifier | (() => undefined | GLAccountKeySpecifier);
    fields?: GLAccountFieldPolicy;
  };
  GLMapping?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | GLMappingKeySpecifier | (() => undefined | GLMappingKeySpecifier);
    fields?: GLMappingFieldPolicy;
  };
  GeneratedFloorplanFeature?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GeneratedFloorplanFeatureKeySpecifier
      | (() => undefined | GeneratedFloorplanFeatureKeySpecifier);
    fields?: GeneratedFloorplanFeatureFieldPolicy;
  };
  GeneratedFloorplanSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GeneratedFloorplanSummaryKeySpecifier
      | (() => undefined | GeneratedFloorplanSummaryKeySpecifier);
    fields?: GeneratedFloorplanSummaryFieldPolicy;
  };
  GrandTotal?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | GrandTotalKeySpecifier | (() => undefined | GrandTotalKeySpecifier);
    fields?: GrandTotalFieldPolicy;
  };
  Household?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | HouseholdKeySpecifier | (() => undefined | HouseholdKeySpecifier);
    fields?: HouseholdFieldPolicy;
  };
  IamMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | IamMutationKeySpecifier | (() => undefined | IamMutationKeySpecifier);
    fields?: IamMutationFieldPolicy;
  };
  IamQuery?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | IamQueryKeySpecifier | (() => undefined | IamQueryKeySpecifier);
    fields?: IamQueryFieldPolicy;
  };
  IamTenant?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | IamTenantKeySpecifier | (() => undefined | IamTenantKeySpecifier);
    fields?: IamTenantFieldPolicy;
  };
  IamTenantClearable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IamTenantClearableKeySpecifier
      | (() => undefined | IamTenantClearableKeySpecifier);
    fields?: IamTenantClearableFieldPolicy;
  };
  IamTenantClearableEdge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IamTenantClearableEdgeKeySpecifier
      | (() => undefined | IamTenantClearableEdgeKeySpecifier);
    fields?: IamTenantClearableEdgeFieldPolicy;
  };
  IamTenantClearablePaymentEffect?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IamTenantClearablePaymentEffectKeySpecifier
      | (() => undefined | IamTenantClearablePaymentEffectKeySpecifier);
    fields?: IamTenantClearablePaymentEffectFieldPolicy;
  };
  IamTenantClearablesPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IamTenantClearablesPageKeySpecifier
      | (() => undefined | IamTenantClearablesPageKeySpecifier);
    fields?: IamTenantClearablesPageFieldPolicy;
  };
  IamTenantInsurancePolicy?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IamTenantInsurancePolicyKeySpecifier
      | (() => undefined | IamTenantInsurancePolicyKeySpecifier);
    fields?: IamTenantInsurancePolicyFieldPolicy;
  };
  IamTenantJournalEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IamTenantJournalEntryKeySpecifier
      | (() => undefined | IamTenantJournalEntryKeySpecifier);
    fields?: IamTenantJournalEntryFieldPolicy;
  };
  Image?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ImageKeySpecifier | (() => undefined | ImageKeySpecifier);
    fields?: ImageFieldPolicy;
  };
  IncentiveEffect?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IncentiveEffectKeySpecifier
      | (() => undefined | IncentiveEffectKeySpecifier);
    fields?: IncentiveEffectFieldPolicy;
  };
  Income?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | IncomeKeySpecifier | (() => undefined | IncomeKeySpecifier);
    fields?: IncomeFieldPolicy;
  };
  IncomePayoutOptions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IncomePayoutOptionsKeySpecifier
      | (() => undefined | IncomePayoutOptionsKeySpecifier);
    fields?: IncomePayoutOptionsFieldPolicy;
  };
  Inspection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | InspectionKeySpecifier | (() => undefined | InspectionKeySpecifier);
    fields?: InspectionFieldPolicy;
  };
  InspectionAttempt?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InspectionAttemptKeySpecifier
      | (() => undefined | InspectionAttemptKeySpecifier);
    fields?: InspectionAttemptFieldPolicy;
  };
  InspectionField?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InspectionFieldKeySpecifier
      | (() => undefined | InspectionFieldKeySpecifier);
    fields?: InspectionFieldFieldPolicy;
  };
  InspectionItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | InspectionItemKeySpecifier | (() => undefined | InspectionItemKeySpecifier);
    fields?: InspectionItemFieldPolicy;
  };
  InspectionSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InspectionSummaryKeySpecifier
      | (() => undefined | InspectionSummaryKeySpecifier);
    fields?: InspectionSummaryFieldPolicy;
  };
  InspectionTemplate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InspectionTemplateKeySpecifier
      | (() => undefined | InspectionTemplateKeySpecifier);
    fields?: InspectionTemplateFieldPolicy;
  };
  InspectionUrlObject?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InspectionUrlObjectKeySpecifier
      | (() => undefined | InspectionUrlObjectKeySpecifier);
    fields?: InspectionUrlObjectFieldPolicy;
  };
  InspectionUrlPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InspectionUrlPayloadKeySpecifier
      | (() => undefined | InspectionUrlPayloadKeySpecifier);
    fields?: InspectionUrlPayloadFieldPolicy;
  };
  InsurancePolicy?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InsurancePolicyKeySpecifier
      | (() => undefined | InsurancePolicyKeySpecifier);
    fields?: InsurancePolicyFieldPolicy;
  };
  InsurancePolicyPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InsurancePolicyPayloadKeySpecifier
      | (() => undefined | InsurancePolicyPayloadKeySpecifier);
    fields?: InsurancePolicyPayloadFieldPolicy;
  };
  InterestRate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | InterestRateKeySpecifier | (() => undefined | InterestRateKeySpecifier);
    fields?: InterestRateFieldPolicy;
  };
  InterestRateConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InterestRateConnectionKeySpecifier
      | (() => undefined | InterestRateConnectionKeySpecifier);
    fields?: InterestRateConnectionFieldPolicy;
  };
  InterestRatePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InterestRatePayloadKeySpecifier
      | (() => undefined | InterestRatePayloadKeySpecifier);
    fields?: InterestRatePayloadFieldPolicy;
  };
  Interval?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | IntervalKeySpecifier | (() => undefined | IntervalKeySpecifier);
    fields?: IntervalFieldPolicy;
  };
  JEDraftBatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | JEDraftBatchKeySpecifier | (() => undefined | JEDraftBatchKeySpecifier);
    fields?: JEDraftBatchFieldPolicy;
  };
  JEPrototype?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | JEPrototypeKeySpecifier | (() => undefined | JEPrototypeKeySpecifier);
    fields?: JEPrototypeFieldPolicy;
  };
  JEPrototypeConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JEPrototypeConnectionKeySpecifier
      | (() => undefined | JEPrototypeConnectionKeySpecifier);
    fields?: JEPrototypeConnectionFieldPolicy;
  };
  JEPrototypeField?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JEPrototypeFieldKeySpecifier
      | (() => undefined | JEPrototypeFieldKeySpecifier);
    fields?: JEPrototypeFieldFieldPolicy;
  };
  JEPrototypeKey?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | JEPrototypeKeyKeySpecifier | (() => undefined | JEPrototypeKeyKeySpecifier);
    fields?: JEPrototypeKeyFieldPolicy;
  };
  JEPrototypeKeysPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JEPrototypeKeysPayloadKeySpecifier
      | (() => undefined | JEPrototypeKeysPayloadKeySpecifier);
    fields?: JEPrototypeKeysPayloadFieldPolicy;
  };
  JournalEntriesForRecPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JournalEntriesForRecPageKeySpecifier
      | (() => undefined | JournalEntriesForRecPageKeySpecifier);
    fields?: JournalEntriesForRecPageFieldPolicy;
  };
  JournalEntriesPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JournalEntriesPageKeySpecifier
      | (() => undefined | JournalEntriesPageKeySpecifier);
    fields?: JournalEntriesPageFieldPolicy;
  };
  JournalEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | JournalEntryKeySpecifier | (() => undefined | JournalEntryKeySpecifier);
    fields?: JournalEntryFieldPolicy;
  };
  JournalEntryConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JournalEntryConnectionKeySpecifier
      | (() => undefined | JournalEntryConnectionKeySpecifier);
    fields?: JournalEntryConnectionFieldPolicy;
  };
  JournalEntryEdge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JournalEntryEdgeKeySpecifier
      | (() => undefined | JournalEntryEdgeKeySpecifier);
    fields?: JournalEntryEdgeFieldPolicy;
  };
  JournalEntryLine?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JournalEntryLineKeySpecifier
      | (() => undefined | JournalEntryLineKeySpecifier);
    fields?: JournalEntryLineFieldPolicy;
  };
  JournalEntryPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JournalEntryPayloadKeySpecifier
      | (() => undefined | JournalEntryPayloadKeySpecifier);
    fields?: JournalEntryPayloadFieldPolicy;
  };
  JournalForReconciliationPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JournalForReconciliationPayloadKeySpecifier
      | (() => undefined | JournalForReconciliationPayloadKeySpecifier);
    fields?: JournalForReconciliationPayloadFieldPolicy;
  };
  JournalKey?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | JournalKeyKeySpecifier | (() => undefined | JournalKeyKeySpecifier);
    fields?: JournalKeyFieldPolicy;
  };
  JournalKeysPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JournalKeysPayloadKeySpecifier
      | (() => undefined | JournalKeysPayloadKeySpecifier);
    fields?: JournalKeysPayloadFieldPolicy;
  };
  Keyring?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | KeyringKeySpecifier | (() => undefined | KeyringKeySpecifier);
    fields?: KeyringFieldPolicy;
  };
  Kyc?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | KycKeySpecifier | (() => undefined | KycKeySpecifier);
    fields?: KycFieldPolicy;
  };
  Lease?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LeaseKeySpecifier | (() => undefined | LeaseKeySpecifier);
    fields?: LeaseFieldPolicy;
  };
  LeaseAgreement?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LeaseAgreementKeySpecifier | (() => undefined | LeaseAgreementKeySpecifier);
    fields?: LeaseAgreementFieldPolicy;
  };
  LeaseAgreementUrl?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementUrlKeySpecifier
      | (() => undefined | LeaseAgreementUrlKeySpecifier);
    fields?: LeaseAgreementUrlFieldPolicy;
  };
  LeaseAgreementUrlPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseAgreementUrlPayloadKeySpecifier
      | (() => undefined | LeaseAgreementUrlPayloadKeySpecifier);
    fields?: LeaseAgreementUrlPayloadFieldPolicy;
  };
  LeaseConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseConnectionKeySpecifier
      | (() => undefined | LeaseConnectionKeySpecifier);
    fields?: LeaseConnectionFieldPolicy;
  };
  LeaseDocumentTemplate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseDocumentTemplateKeySpecifier
      | (() => undefined | LeaseDocumentTemplateKeySpecifier);
    fields?: LeaseDocumentTemplateFieldPolicy;
  };
  LeaseDocumentTemplateConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseDocumentTemplateConnectionKeySpecifier
      | (() => undefined | LeaseDocumentTemplateConnectionKeySpecifier);
    fields?: LeaseDocumentTemplateConnectionFieldPolicy;
  };
  LeaseDocumentTemplatePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseDocumentTemplatePayloadKeySpecifier
      | (() => undefined | LeaseDocumentTemplatePayloadKeySpecifier);
    fields?: LeaseDocumentTemplatePayloadFieldPolicy;
  };
  LeaseForm?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LeaseFormKeySpecifier | (() => undefined | LeaseFormKeySpecifier);
    fields?: LeaseFormFieldPolicy;
  };
  LeaseFormInputField?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseFormInputFieldKeySpecifier
      | (() => undefined | LeaseFormInputFieldKeySpecifier);
    fields?: LeaseFormInputFieldFieldPolicy;
  };
  LeaseFormInputFieldOption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseFormInputFieldOptionKeySpecifier
      | (() => undefined | LeaseFormInputFieldOptionKeySpecifier);
    fields?: LeaseFormInputFieldOptionFieldPolicy;
  };
  LeaseIncentive?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LeaseIncentiveKeySpecifier | (() => undefined | LeaseIncentiveKeySpecifier);
    fields?: LeaseIncentiveFieldPolicy;
  };
  LeaseInspection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseInspectionKeySpecifier
      | (() => undefined | LeaseInspectionKeySpecifier);
    fields?: LeaseInspectionFieldPolicy;
  };
  LeaseInspectionPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseInspectionPayloadKeySpecifier
      | (() => undefined | LeaseInspectionPayloadKeySpecifier);
    fields?: LeaseInspectionPayloadFieldPolicy;
  };
  LeaseInspectionRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseInspectionRequestKeySpecifier
      | (() => undefined | LeaseInspectionRequestKeySpecifier);
    fields?: LeaseInspectionRequestFieldPolicy;
  };
  LeaseInspectionUrlPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseInspectionUrlPayloadKeySpecifier
      | (() => undefined | LeaseInspectionUrlPayloadKeySpecifier);
    fields?: LeaseInspectionUrlPayloadFieldPolicy;
  };
  LeaseInsurancePolicy?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseInsurancePolicyKeySpecifier
      | (() => undefined | LeaseInsurancePolicyKeySpecifier);
    fields?: LeaseInsurancePolicyFieldPolicy;
  };
  LeasePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LeasePayloadKeySpecifier | (() => undefined | LeasePayloadKeySpecifier);
    fields?: LeasePayloadFieldPolicy;
  };
  LeaseTenant?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LeaseTenantKeySpecifier | (() => undefined | LeaseTenantKeySpecifier);
    fields?: LeaseTenantFieldPolicy;
  };
  LeaseUnitPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LeaseUnitPayloadKeySpecifier
      | (() => undefined | LeaseUnitPayloadKeySpecifier);
    fields?: LeaseUnitPayloadFieldPolicy;
  };
  LinkedAccount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LinkedAccountKeySpecifier | (() => undefined | LinkedAccountKeySpecifier);
    fields?: LinkedAccountFieldPolicy;
  };
  ListedUnit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ListedUnitKeySpecifier | (() => undefined | ListedUnitKeySpecifier);
    fields?: ListedUnitFieldPolicy;
  };
  MaintenanceApproval?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MaintenanceApprovalKeySpecifier
      | (() => undefined | MaintenanceApprovalKeySpecifier);
    fields?: MaintenanceApprovalFieldPolicy;
  };
  MaintenanceApprovalPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MaintenanceApprovalPayloadKeySpecifier
      | (() => undefined | MaintenanceApprovalPayloadKeySpecifier);
    fields?: MaintenanceApprovalPayloadFieldPolicy;
  };
  MaintenanceApprovalRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MaintenanceApprovalRequestKeySpecifier
      | (() => undefined | MaintenanceApprovalRequestKeySpecifier);
    fields?: MaintenanceApprovalRequestFieldPolicy;
  };
  Manager?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ManagerKeySpecifier | (() => undefined | ManagerKeySpecifier);
    fields?: ManagerFieldPolicy;
  };
  ManagerContactInformation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ManagerContactInformationKeySpecifier
      | (() => undefined | ManagerContactInformationKeySpecifier);
    fields?: ManagerContactInformationFieldPolicy;
  };
  ManagerPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ManagerPayloadKeySpecifier | (() => undefined | ManagerPayloadKeySpecifier);
    fields?: ManagerPayloadFieldPolicy;
  };
  MarkAppointmentChangesReadPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MarkAppointmentChangesReadPayloadKeySpecifier
      | (() => undefined | MarkAppointmentChangesReadPayloadKeySpecifier);
    fields?: MarkAppointmentChangesReadPayloadFieldPolicy;
  };
  MessageSubscription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageSubscriptionKeySpecifier
      | (() => undefined | MessageSubscriptionKeySpecifier);
    fields?: MessageSubscriptionFieldPolicy;
  };
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier);
    fields?: MutationFieldPolicy;
  };
  Note?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | NoteKeySpecifier | (() => undefined | NoteKeySpecifier);
    fields?: NoteFieldPolicy;
  };
  OnsiteManager?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OnsiteManagerKeySpecifier | (() => undefined | OnsiteManagerKeySpecifier);
    fields?: OnsiteManagerFieldPolicy;
  };
  OpenSearch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OpenSearchKeySpecifier | (() => undefined | OpenSearchKeySpecifier);
    fields?: OpenSearchFieldPolicy;
  };
  OpenSearchTask?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OpenSearchTaskKeySpecifier | (() => undefined | OpenSearchTaskKeySpecifier);
    fields?: OpenSearchTaskFieldPolicy;
  };
  OpenSearchTaskResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OpenSearchTaskResponseKeySpecifier
      | (() => undefined | OpenSearchTaskResponseKeySpecifier);
    fields?: OpenSearchTaskResponseFieldPolicy;
  };
  OpenSearchTaskResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OpenSearchTaskResultKeySpecifier
      | (() => undefined | OpenSearchTaskResultKeySpecifier);
    fields?: OpenSearchTaskResultFieldPolicy;
  };
  OpenSearchTaskStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OpenSearchTaskStatusKeySpecifier
      | (() => undefined | OpenSearchTaskStatusKeySpecifier);
    fields?: OpenSearchTaskStatusFieldPolicy;
  };
  Operator?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OperatorKeySpecifier | (() => undefined | OperatorKeySpecifier);
    fields?: OperatorFieldPolicy;
  };
  OperatorPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OperatorPayloadKeySpecifier
      | (() => undefined | OperatorPayloadKeySpecifier);
    fields?: OperatorPayloadFieldPolicy;
  };
  OperatorWindow?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OperatorWindowKeySpecifier | (() => undefined | OperatorWindowKeySpecifier);
    fields?: OperatorWindowFieldPolicy;
  };
  Owner?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OwnerKeySpecifier | (() => undefined | OwnerKeySpecifier);
    fields?: OwnerFieldPolicy;
  };
  OwnerConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OwnerConnectionKeySpecifier
      | (() => undefined | OwnerConnectionKeySpecifier);
    fields?: OwnerConnectionFieldPolicy;
  };
  OwnerEdge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OwnerEdgeKeySpecifier | (() => undefined | OwnerEdgeKeySpecifier);
    fields?: OwnerEdgeFieldPolicy;
  };
  OwnerHistoryEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OwnerHistoryEntryKeySpecifier
      | (() => undefined | OwnerHistoryEntryKeySpecifier);
    fields?: OwnerHistoryEntryFieldPolicy;
  };
  OwnerPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OwnerPayloadKeySpecifier | (() => undefined | OwnerPayloadKeySpecifier);
    fields?: OwnerPayloadFieldPolicy;
  };
  OwnersPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OwnersPageKeySpecifier | (() => undefined | OwnersPageKeySpecifier);
    fields?: OwnersPageFieldPolicy;
  };
  ParkingStall?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ParkingStallKeySpecifier | (() => undefined | ParkingStallKeySpecifier);
    fields?: ParkingStallFieldPolicy;
  };
  PayeeClearingAmount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PayeeClearingAmountKeySpecifier
      | (() => undefined | PayeeClearingAmountKeySpecifier);
    fields?: PayeeClearingAmountFieldPolicy;
  };
  PaymentDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PaymentDetailsKeySpecifier | (() => undefined | PaymentDetailsKeySpecifier);
    fields?: PaymentDetailsFieldPolicy;
  };
  PaymentPayee?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PaymentPayeeKeySpecifier | (() => undefined | PaymentPayeeKeySpecifier);
    fields?: PaymentPayeeFieldPolicy;
  };
  PaymentPrototype?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentPrototypeKeySpecifier
      | (() => undefined | PaymentPrototypeKeySpecifier);
    fields?: PaymentPrototypeFieldPolicy;
  };
  PaymentsApproval?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsApprovalKeySpecifier
      | (() => undefined | PaymentsApprovalKeySpecifier);
    fields?: PaymentsApprovalFieldPolicy;
  };
  PaymentsApprovalPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsApprovalPayloadKeySpecifier
      | (() => undefined | PaymentsApprovalPayloadKeySpecifier);
    fields?: PaymentsApprovalPayloadFieldPolicy;
  };
  PaymentsApprovalRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsApprovalRequestKeySpecifier
      | (() => undefined | PaymentsApprovalRequestKeySpecifier);
    fields?: PaymentsApprovalRequestFieldPolicy;
  };
  PaymentsBatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PaymentsBatchKeySpecifier | (() => undefined | PaymentsBatchKeySpecifier);
    fields?: PaymentsBatchFieldPolicy;
  };
  PaymentsBatchPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsBatchPayloadKeySpecifier
      | (() => undefined | PaymentsBatchPayloadKeySpecifier);
    fields?: PaymentsBatchPayloadFieldPolicy;
  };
  PaymentsPrototypeConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsPrototypeConnectionKeySpecifier
      | (() => undefined | PaymentsPrototypeConnectionKeySpecifier);
    fields?: PaymentsPrototypeConnectionFieldPolicy;
  };
  Pet?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PetKeySpecifier | (() => undefined | PetKeySpecifier);
    fields?: PetFieldPolicy;
  };
  PetDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PetDetailsKeySpecifier | (() => undefined | PetDetailsKeySpecifier);
    fields?: PetDetailsFieldPolicy;
  };
  PostDraftJEBatchPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PostDraftJEBatchPayloadKeySpecifier
      | (() => undefined | PostDraftJEBatchPayloadKeySpecifier);
    fields?: PostDraftJEBatchPayloadFieldPolicy;
  };
  Preset?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PresetKeySpecifier | (() => undefined | PresetKeySpecifier);
    fields?: PresetFieldPolicy;
  };
  PresetGroup?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PresetGroupKeySpecifier | (() => undefined | PresetGroupKeySpecifier);
    fields?: PresetGroupFieldPolicy;
  };
  PresetIcon?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PresetIconKeySpecifier | (() => undefined | PresetIconKeySpecifier);
    fields?: PresetIconFieldPolicy;
  };
  PresetInputField?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PresetInputFieldKeySpecifier
      | (() => undefined | PresetInputFieldKeySpecifier);
    fields?: PresetInputFieldFieldPolicy;
  };
  PresetOption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PresetOptionKeySpecifier | (() => undefined | PresetOptionKeySpecifier);
    fields?: PresetOptionFieldPolicy;
  };
  PresetPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PresetPayloadKeySpecifier | (() => undefined | PresetPayloadKeySpecifier);
    fields?: PresetPayloadFieldPolicy;
  };
  PresetRecurrence?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PresetRecurrenceKeySpecifier
      | (() => undefined | PresetRecurrenceKeySpecifier);
    fields?: PresetRecurrenceFieldPolicy;
  };
  ProRatedEffect?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProRatedEffectKeySpecifier | (() => undefined | ProRatedEffectKeySpecifier);
    fields?: ProRatedEffectFieldPolicy;
  };
  Profile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProfileKeySpecifier | (() => undefined | ProfileKeySpecifier);
    fields?: ProfileFieldPolicy;
  };
  ProfileLogin?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProfileLoginKeySpecifier | (() => undefined | ProfileLoginKeySpecifier);
    fields?: ProfileLoginFieldPolicy;
  };
  PropertiesPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PropertiesPageKeySpecifier | (() => undefined | PropertiesPageKeySpecifier);
    fields?: PropertiesPageFieldPolicy;
  };
  Property?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PropertyKeySpecifier | (() => undefined | PropertyKeySpecifier);
    fields?: PropertyFieldPolicy;
  };
  PropertyBankAccount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PropertyBankAccountKeySpecifier
      | (() => undefined | PropertyBankAccountKeySpecifier);
    fields?: PropertyBankAccountFieldPolicy;
  };
  PropertyConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PropertyConnectionKeySpecifier
      | (() => undefined | PropertyConnectionKeySpecifier);
    fields?: PropertyConnectionFieldPolicy;
  };
  PropertyEdge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PropertyEdgeKeySpecifier | (() => undefined | PropertyEdgeKeySpecifier);
    fields?: PropertyEdgeFieldPolicy;
  };
  PropertyFact?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PropertyFactKeySpecifier | (() => undefined | PropertyFactKeySpecifier);
    fields?: PropertyFactFieldPolicy;
  };
  PropertyFactConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PropertyFactConnectionKeySpecifier
      | (() => undefined | PropertyFactConnectionKeySpecifier);
    fields?: PropertyFactConnectionFieldPolicy;
  };
  PropertyHeader?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PropertyHeaderKeySpecifier | (() => undefined | PropertyHeaderKeySpecifier);
    fields?: PropertyHeaderFieldPolicy;
  };
  PropertyOption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PropertyOptionKeySpecifier | (() => undefined | PropertyOptionKeySpecifier);
    fields?: PropertyOptionFieldPolicy;
  };
  PropertyPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PropertyPayloadKeySpecifier
      | (() => undefined | PropertyPayloadKeySpecifier);
    fields?: PropertyPayloadFieldPolicy;
  };
  PropertySpecs?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PropertySpecsKeySpecifier | (() => undefined | PropertySpecsKeySpecifier);
    fields?: PropertySpecsFieldPolicy;
  };
  PropertyUnit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PropertyUnitKeySpecifier | (() => undefined | PropertyUnitKeySpecifier);
    fields?: PropertyUnitFieldPolicy;
  };
  ProvinceTaxDetailsPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProvinceTaxDetailsPayloadKeySpecifier
      | (() => undefined | ProvinceTaxDetailsPayloadKeySpecifier);
    fields?: ProvinceTaxDetailsPayloadFieldPolicy;
  };
  PushToken?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PushTokenKeySpecifier | (() => undefined | PushTokenKeySpecifier);
    fields?: PushTokenFieldPolicy;
  };
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier);
    fields?: QueryFieldPolicy;
  };
  RateSchedule?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RateScheduleKeySpecifier | (() => undefined | RateScheduleKeySpecifier);
    fields?: RateScheduleFieldPolicy;
  };
  Reconciliation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReconciliationKeySpecifier | (() => undefined | ReconciliationKeySpecifier);
    fields?: ReconciliationFieldPolicy;
  };
  ReconciliationConfig?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReconciliationConfigKeySpecifier
      | (() => undefined | ReconciliationConfigKeySpecifier);
    fields?: ReconciliationConfigFieldPolicy;
  };
  ReconciliationConfigConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReconciliationConfigConnectionKeySpecifier
      | (() => undefined | ReconciliationConfigConnectionKeySpecifier);
    fields?: ReconciliationConfigConnectionFieldPolicy;
  };
  ReconciliationConfigPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReconciliationConfigPayloadKeySpecifier
      | (() => undefined | ReconciliationConfigPayloadKeySpecifier);
    fields?: ReconciliationConfigPayloadFieldPolicy;
  };
  ReconciliationConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReconciliationConnectionKeySpecifier
      | (() => undefined | ReconciliationConnectionKeySpecifier);
    fields?: ReconciliationConnectionFieldPolicy;
  };
  ReconciliationPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReconciliationPayloadKeySpecifier
      | (() => undefined | ReconciliationPayloadKeySpecifier);
    fields?: ReconciliationPayloadFieldPolicy;
  };
  ReconciliationScope?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReconciliationScopeKeySpecifier
      | (() => undefined | ReconciliationScopeKeySpecifier);
    fields?: ReconciliationScopeFieldPolicy;
  };
  ReconciliationTaskProgress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReconciliationTaskProgressKeySpecifier
      | (() => undefined | ReconciliationTaskProgressKeySpecifier);
    fields?: ReconciliationTaskProgressFieldPolicy;
  };
  Recurrence?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RecurrenceKeySpecifier | (() => undefined | RecurrenceKeySpecifier);
    fields?: RecurrenceFieldPolicy;
  };
  RecurrenceClearableConfig?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RecurrenceClearableConfigKeySpecifier
      | (() => undefined | RecurrenceClearableConfigKeySpecifier);
    fields?: RecurrenceClearableConfigFieldPolicy;
  };
  RecurrenceOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RecurrenceOutputKeySpecifier
      | (() => undefined | RecurrenceOutputKeySpecifier);
    fields?: RecurrenceOutputFieldPolicy;
  };
  Refundable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RefundableKeySpecifier | (() => undefined | RefundableKeySpecifier);
    fields?: RefundableFieldPolicy;
  };
  RefundableLedger?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RefundableLedgerKeySpecifier
      | (() => undefined | RefundableLedgerKeySpecifier);
    fields?: RefundableLedgerFieldPolicy;
  };
  RegionTax?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RegionTaxKeySpecifier | (() => undefined | RegionTaxKeySpecifier);
    fields?: RegionTaxFieldPolicy;
  };
  RegionTaxDetailsPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RegionTaxDetailsPayloadKeySpecifier
      | (() => undefined | RegionTaxDetailsPayloadKeySpecifier);
    fields?: RegionTaxDetailsPayloadFieldPolicy;
  };
  RelayEdge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RelayEdgeKeySpecifier | (() => undefined | RelayEdgeKeySpecifier);
    fields?: RelayEdgeFieldPolicy;
  };
  RelayPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RelayPageKeySpecifier | (() => undefined | RelayPageKeySpecifier);
    fields?: RelayPageFieldPolicy;
  };
  RelayPageInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RelayPageInfoKeySpecifier | (() => undefined | RelayPageInfoKeySpecifier);
    fields?: RelayPageInfoFieldPolicy;
  };
  RemoveReconciliationPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RemoveReconciliationPayloadKeySpecifier
      | (() => undefined | RemoveReconciliationPayloadKeySpecifier);
    fields?: RemoveReconciliationPayloadFieldPolicy;
  };
  RenderedAction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RenderedActionKeySpecifier | (() => undefined | RenderedActionKeySpecifier);
    fields?: RenderedActionFieldPolicy;
  };
  RentRollFee?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RentRollFeeKeySpecifier | (() => undefined | RentRollFeeKeySpecifier);
    fields?: RentRollFeeFieldPolicy;
  };
  RentalAssistance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RentalAssistanceKeySpecifier
      | (() => undefined | RentalAssistanceKeySpecifier);
    fields?: RentalAssistanceFieldPolicy;
  };
  Reply?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReplyKeySpecifier | (() => undefined | ReplyKeySpecifier);
    fields?: ReplyFieldPolicy;
  };
  ReportBundle?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReportBundleKeySpecifier | (() => undefined | ReportBundleKeySpecifier);
    fields?: ReportBundleFieldPolicy;
  };
  ReportBundlePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReportBundlePayloadKeySpecifier
      | (() => undefined | ReportBundlePayloadKeySpecifier);
    fields?: ReportBundlePayloadFieldPolicy;
  };
  ReportSet?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReportSetKeySpecifier | (() => undefined | ReportSetKeySpecifier);
    fields?: ReportSetFieldPolicy;
  };
  ReportSetPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReportSetPayloadKeySpecifier
      | (() => undefined | ReportSetPayloadKeySpecifier);
    fields?: ReportSetPayloadFieldPolicy;
  };
  Request?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RequestKeySpecifier | (() => undefined | RequestKeySpecifier);
    fields?: RequestFieldPolicy;
  };
  RequestConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RequestConnectionKeySpecifier
      | (() => undefined | RequestConnectionKeySpecifier);
    fields?: RequestConnectionFieldPolicy;
  };
  RequestCost?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RequestCostKeySpecifier | (() => undefined | RequestCostKeySpecifier);
    fields?: RequestCostFieldPolicy;
  };
  RequestEdge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RequestEdgeKeySpecifier | (() => undefined | RequestEdgeKeySpecifier);
    fields?: RequestEdgeFieldPolicy;
  };
  RequestEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RequestEventKeySpecifier | (() => undefined | RequestEventKeySpecifier);
    fields?: RequestEventFieldPolicy;
  };
  RequestPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RequestPayloadKeySpecifier | (() => undefined | RequestPayloadKeySpecifier);
    fields?: RequestPayloadFieldPolicy;
  };
  RequestRecurrence?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RequestRecurrenceKeySpecifier
      | (() => undefined | RequestRecurrenceKeySpecifier);
    fields?: RequestRecurrenceFieldPolicy;
  };
  RequestRecurrenceConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RequestRecurrenceConnectionKeySpecifier
      | (() => undefined | RequestRecurrenceConnectionKeySpecifier);
    fields?: RequestRecurrenceConnectionFieldPolicy;
  };
  RequestRecurrencePayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RequestRecurrencePayloadKeySpecifier
      | (() => undefined | RequestRecurrencePayloadKeySpecifier);
    fields?: RequestRecurrencePayloadFieldPolicy;
  };
  RequestTenant?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RequestTenantKeySpecifier | (() => undefined | RequestTenantKeySpecifier);
    fields?: RequestTenantFieldPolicy;
  };
  RequestsPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RequestsPageKeySpecifier | (() => undefined | RequestsPageKeySpecifier);
    fields?: RequestsPageFieldPolicy;
  };
  ResidenciesPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResidenciesPageKeySpecifier
      | (() => undefined | ResidenciesPageKeySpecifier);
    fields?: ResidenciesPageFieldPolicy;
  };
  Residency?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ResidencyKeySpecifier | (() => undefined | ResidencyKeySpecifier);
    fields?: ResidencyFieldPolicy;
  };
  ResidencyConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResidencyConnectionKeySpecifier
      | (() => undefined | ResidencyConnectionKeySpecifier);
    fields?: ResidencyConnectionFieldPolicy;
  };
  ResidencyEdge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ResidencyEdgeKeySpecifier | (() => undefined | ResidencyEdgeKeySpecifier);
    fields?: ResidencyEdgeFieldPolicy;
  };
  ResidencyEffect?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResidencyEffectKeySpecifier
      | (() => undefined | ResidencyEffectKeySpecifier);
    fields?: ResidencyEffectFieldPolicy;
  };
  ResidencyEffectRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResidencyEffectRecordKeySpecifier
      | (() => undefined | ResidencyEffectRecordKeySpecifier);
    fields?: ResidencyEffectRecordFieldPolicy;
  };
  ResidencyEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ResidencyEventKeySpecifier | (() => undefined | ResidencyEventKeySpecifier);
    fields?: ResidencyEventFieldPolicy;
  };
  ResidencyOutstanding?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResidencyOutstandingKeySpecifier
      | (() => undefined | ResidencyOutstandingKeySpecifier);
    fields?: ResidencyOutstandingFieldPolicy;
  };
  ResidencyPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResidencyPayloadKeySpecifier
      | (() => undefined | ResidencyPayloadKeySpecifier);
    fields?: ResidencyPayloadFieldPolicy;
  };
  ResidencyProRatedEffect?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResidencyProRatedEffectKeySpecifier
      | (() => undefined | ResidencyProRatedEffectKeySpecifier);
    fields?: ResidencyProRatedEffectFieldPolicy;
  };
  ResidencyProRatedEffectsPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResidencyProRatedEffectsPayloadKeySpecifier
      | (() => undefined | ResidencyProRatedEffectsPayloadKeySpecifier);
    fields?: ResidencyProRatedEffectsPayloadFieldPolicy;
  };
  Resident?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ResidentKeySpecifier | (() => undefined | ResidentKeySpecifier);
    fields?: ResidentFieldPolicy;
  };
  ResidentAssociation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResidentAssociationKeySpecifier
      | (() => undefined | ResidentAssociationKeySpecifier);
    fields?: ResidentAssociationFieldPolicy;
  };
  ResidentDocument?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResidentDocumentKeySpecifier
      | (() => undefined | ResidentDocumentKeySpecifier);
    fields?: ResidentDocumentFieldPolicy;
  };
  ResidentEdge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ResidentEdgeKeySpecifier | (() => undefined | ResidentEdgeKeySpecifier);
    fields?: ResidentEdgeFieldPolicy;
  };
  ResidentPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ResidentPageKeySpecifier | (() => undefined | ResidentPageKeySpecifier);
    fields?: ResidentPageFieldPolicy;
  };
  ResidentRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ResidentRecordKeySpecifier | (() => undefined | ResidentRecordKeySpecifier);
    fields?: ResidentRecordFieldPolicy;
  };
  RoutineInspectionPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RoutineInspectionPayloadKeySpecifier
      | (() => undefined | RoutineInspectionPayloadKeySpecifier);
    fields?: RoutineInspectionPayloadFieldPolicy;
  };
  ScanApproval?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ScanApprovalKeySpecifier | (() => undefined | ScanApprovalKeySpecifier);
    fields?: ScanApprovalFieldPolicy;
  };
  ScanApprovalLine?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ScanApprovalLineKeySpecifier
      | (() => undefined | ScanApprovalLineKeySpecifier);
    fields?: ScanApprovalLineFieldPolicy;
  };
  ScanApprovalPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ScanApprovalPayloadKeySpecifier
      | (() => undefined | ScanApprovalPayloadKeySpecifier);
    fields?: ScanApprovalPayloadFieldPolicy;
  };
  ScanApprovalRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ScanApprovalRequestKeySpecifier
      | (() => undefined | ScanApprovalRequestKeySpecifier);
    fields?: ScanApprovalRequestFieldPolicy;
  };
  SearchUnit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SearchUnitKeySpecifier | (() => undefined | SearchUnitKeySpecifier);
    fields?: SearchUnitFieldPolicy;
  };
  SearchUnitPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SearchUnitPayloadKeySpecifier
      | (() => undefined | SearchUnitPayloadKeySpecifier);
    fields?: SearchUnitPayloadFieldPolicy;
  };
  SecurityDepositAllocation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SecurityDepositAllocationKeySpecifier
      | (() => undefined | SecurityDepositAllocationKeySpecifier);
    fields?: SecurityDepositAllocationFieldPolicy;
  };
  SecurityDepositHistory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SecurityDepositHistoryKeySpecifier
      | (() => undefined | SecurityDepositHistoryKeySpecifier);
    fields?: SecurityDepositHistoryFieldPolicy;
  };
  Settings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SettingsKeySpecifier | (() => undefined | SettingsKeySpecifier);
    fields?: SettingsFieldPolicy;
  };
  SettlementSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SettlementSummaryKeySpecifier
      | (() => undefined | SettlementSummaryKeySpecifier);
    fields?: SettlementSummaryFieldPolicy;
  };
  Signature?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SignatureKeySpecifier | (() => undefined | SignatureKeySpecifier);
    fields?: SignatureFieldPolicy;
  };
  SourceInspection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SourceInspectionKeySpecifier
      | (() => undefined | SourceInspectionKeySpecifier);
    fields?: SourceInspectionFieldPolicy;
  };
  StartReconciliationPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StartReconciliationPayloadKeySpecifier
      | (() => undefined | StartReconciliationPayloadKeySpecifier);
    fields?: StartReconciliationPayloadFieldPolicy;
  };
  StatusHistoryEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusHistoryEventKeySpecifier
      | (() => undefined | StatusHistoryEventKeySpecifier);
    fields?: StatusHistoryEventFieldPolicy;
  };
  StorageLocker?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | StorageLockerKeySpecifier | (() => undefined | StorageLockerKeySpecifier);
    fields?: StorageLockerFieldPolicy;
  };
  SubAccount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SubAccountKeySpecifier | (() => undefined | SubAccountKeySpecifier);
    fields?: SubAccountFieldPolicy;
  };
  SubAccountConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubAccountConnectionKeySpecifier
      | (() => undefined | SubAccountConnectionKeySpecifier);
    fields?: SubAccountConnectionFieldPolicy;
  };
  Subscription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SubscriptionKeySpecifier | (() => undefined | SubscriptionKeySpecifier);
    fields?: SubscriptionFieldPolicy;
  };
  SubscriptionEndpoint?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionEndpointKeySpecifier
      | (() => undefined | SubscriptionEndpointKeySpecifier);
    fields?: SubscriptionEndpointFieldPolicy;
  };
  SuccessPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SuccessPayloadKeySpecifier | (() => undefined | SuccessPayloadKeySpecifier);
    fields?: SuccessPayloadFieldPolicy;
  };
  Supplier?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SupplierKeySpecifier | (() => undefined | SupplierKeySpecifier);
    fields?: SupplierFieldPolicy;
  };
  SupplierAccount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SupplierAccountKeySpecifier
      | (() => undefined | SupplierAccountKeySpecifier);
    fields?: SupplierAccountFieldPolicy;
  };
  SupplierEdge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SupplierEdgeKeySpecifier | (() => undefined | SupplierEdgeKeySpecifier);
    fields?: SupplierEdgeFieldPolicy;
  };
  SupplierPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SupplierPayloadKeySpecifier
      | (() => undefined | SupplierPayloadKeySpecifier);
    fields?: SupplierPayloadFieldPolicy;
  };
  SuppliersConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SuppliersConnectionKeySpecifier
      | (() => undefined | SuppliersConnectionKeySpecifier);
    fields?: SuppliersConnectionFieldPolicy;
  };
  SuppliersPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SuppliersPageKeySpecifier | (() => undefined | SuppliersPageKeySpecifier);
    fields?: SuppliersPageFieldPolicy;
  };
  Tax?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TaxKeySpecifier | (() => undefined | TaxKeySpecifier);
    fields?: TaxFieldPolicy;
  };
  TaxDetail?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TaxDetailKeySpecifier | (() => undefined | TaxDetailKeySpecifier);
    fields?: TaxDetailFieldPolicy;
  };
  Team?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TeamKeySpecifier | (() => undefined | TeamKeySpecifier);
    fields?: TeamFieldPolicy;
  };
  TeamPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TeamPayloadKeySpecifier | (() => undefined | TeamPayloadKeySpecifier);
    fields?: TeamPayloadFieldPolicy;
  };
  Template?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TemplateKeySpecifier | (() => undefined | TemplateKeySpecifier);
    fields?: TemplateFieldPolicy;
  };
  TemplateItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TemplateItemKeySpecifier | (() => undefined | TemplateItemKeySpecifier);
    fields?: TemplateItemFieldPolicy;
  };
  Tenant?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TenantKeySpecifier | (() => undefined | TenantKeySpecifier);
    fields?: TenantFieldPolicy;
  };
  TenantConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TenantConnectionKeySpecifier
      | (() => undefined | TenantConnectionKeySpecifier);
    fields?: TenantConnectionFieldPolicy;
  };
  TenantLease?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TenantLeaseKeySpecifier | (() => undefined | TenantLeaseKeySpecifier);
    fields?: TenantLeaseFieldPolicy;
  };
  TenantPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TenantPayloadKeySpecifier | (() => undefined | TenantPayloadKeySpecifier);
    fields?: TenantPayloadFieldPolicy;
  };
  TotalByAccount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TotalByAccountKeySpecifier | (() => undefined | TotalByAccountKeySpecifier);
    fields?: TotalByAccountFieldPolicy;
  };
  TotalByProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TotalByPropertyKeySpecifier
      | (() => undefined | TotalByPropertyKeySpecifier);
    fields?: TotalByPropertyFieldPolicy;
  };
  TotalByUnit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TotalByUnitKeySpecifier | (() => undefined | TotalByUnitKeySpecifier);
    fields?: TotalByUnitFieldPolicy;
  };
  TransferJournalEntriesPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransferJournalEntriesPayloadKeySpecifier
      | (() => undefined | TransferJournalEntriesPayloadKeySpecifier);
    fields?: TransferJournalEntriesPayloadFieldPolicy;
  };
  Unit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UnitKeySpecifier | (() => undefined | UnitKeySpecifier);
    fields?: UnitFieldPolicy;
  };
  UnitCondition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UnitConditionKeySpecifier | (() => undefined | UnitConditionKeySpecifier);
    fields?: UnitConditionFieldPolicy;
  };
  UnitConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UnitConnectionKeySpecifier | (() => undefined | UnitConnectionKeySpecifier);
    fields?: UnitConnectionFieldPolicy;
  };
  UnitEdge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UnitEdgeKeySpecifier | (() => undefined | UnitEdgeKeySpecifier);
    fields?: UnitEdgeFieldPolicy;
  };
  UnitHeader?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UnitHeaderKeySpecifier | (() => undefined | UnitHeaderKeySpecifier);
    fields?: UnitHeaderFieldPolicy;
  };
  UnitHistory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UnitHistoryKeySpecifier | (() => undefined | UnitHistoryKeySpecifier);
    fields?: UnitHistoryFieldPolicy;
  };
  UnitNames?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UnitNamesKeySpecifier | (() => undefined | UnitNamesKeySpecifier);
    fields?: UnitNamesFieldPolicy;
  };
  UnitPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UnitPayloadKeySpecifier | (() => undefined | UnitPayloadKeySpecifier);
    fields?: UnitPayloadFieldPolicy;
  };
  UnitsPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UnitsPageKeySpecifier | (() => undefined | UnitsPageKeySpecifier);
    fields?: UnitsPageFieldPolicy;
  };
  UpdateBuildingPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UpdateBuildingPayloadKeySpecifier
      | (() => undefined | UpdateBuildingPayloadKeySpecifier);
    fields?: UpdateBuildingPayloadFieldPolicy;
  };
  UpdateGLAccountPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UpdateGLAccountPayloadKeySpecifier
      | (() => undefined | UpdateGLAccountPayloadKeySpecifier);
    fields?: UpdateGLAccountPayloadFieldPolicy;
  };
  UpdateJournalPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UpdateJournalPayloadKeySpecifier
      | (() => undefined | UpdateJournalPayloadKeySpecifier);
    fields?: UpdateJournalPayloadFieldPolicy;
  };
  UpdateScanApprovalPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UpdateScanApprovalPayloadKeySpecifier
      | (() => undefined | UpdateScanApprovalPayloadKeySpecifier);
    fields?: UpdateScanApprovalPayloadFieldPolicy;
  };
  UpdateVisitPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UpdateVisitPayloadKeySpecifier
      | (() => undefined | UpdateVisitPayloadKeySpecifier);
    fields?: UpdateVisitPayloadFieldPolicy;
  };
  UpsertRoutineInspectionPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UpsertRoutineInspectionPayloadKeySpecifier
      | (() => undefined | UpsertRoutineInspectionPayloadKeySpecifier);
    fields?: UpsertRoutineInspectionPayloadFieldPolicy;
  };
  UtilitiesTemplate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UtilitiesTemplateKeySpecifier
      | (() => undefined | UtilitiesTemplateKeySpecifier);
    fields?: UtilitiesTemplateFieldPolicy;
  };
  Vehicle?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | VehicleKeySpecifier | (() => undefined | VehicleKeySpecifier);
    fields?: VehicleFieldPolicy;
  };
  Visit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | VisitKeySpecifier | (() => undefined | VisitKeySpecifier);
    fields?: VisitFieldPolicy;
  };
  Vopay?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | VopayKeySpecifier | (() => undefined | VopayKeySpecifier);
    fields?: VopayFieldPolicy;
  };
  WorkHistory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | WorkHistoryKeySpecifier | (() => undefined | WorkHistoryKeySpecifier);
    fields?: WorkHistoryFieldPolicy;
  };
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
export const API = {
  Query: {
    ListPayeeClearables: 'ListPayeeClearables',
    GetAccountCharges: 'GetAccountCharges',
    PayeeProperties: 'PayeeProperties',
    ListUnitClearables: 'ListUnitClearables',
    TeamPicker: 'TeamPicker',
    GetAccountSettings: 'GetAccountSettings',
    ResidencyExpiringThresholds: 'ResidencyExpiringThresholds',
    GetContact: 'GetContact',
    GetCurrentUser: 'GetCurrentUser',
    GetAccountFees: 'GetAccountFees',
    GetProvinceTaxDetails: 'GetProvinceTaxDetails',
    GetOwnerName: 'GetOwnerName',
    GetOwnerNames: 'GetOwnerNames',
    ListAllProperties: 'ListAllProperties',
    GetRegionTaxDetails: 'GetRegionTaxDetails',
    GetRequests: 'GetRequests',
    GetRequestHeader: 'GetRequestHeader',
    GetSuppliers: 'GetSuppliers',
    Supplier: 'Supplier',
    ListSuppliers: 'ListSuppliers',
    PageSuppliers: 'PageSuppliers',
    GetUnits: 'GetUnits',
    ListAllUnits: 'ListAllUnits',
    ListUnitsWithResidencies: 'ListUnitsWithResidencies',
    AccountName: 'AccountName',
    GetAutopayBanks: 'GetAutopayBanks',
    GetBooksActivation: 'GetBooksActivation',
    GetBooksBatchTask: 'GetBooksBatchTask',
    GetOpenSearchTask: 'GetOpenSearchTask',
    AllProperties: 'AllProperties',
    PropertyName: 'PropertyName',
    GetPropertyUnits: 'GetPropertyUnits',
    GetPropertyUnitsWithResidency: 'GetPropertyUnitsWithResidency',
    RefundableDepositTemplate: 'RefundableDepositTemplate',
    TaxesProperties: 'TaxesProperties',
    TeamUnit: 'TeamUnit',
    GetActionableCampaignApplications: 'GetActionableCampaignApplications',
    GetActionableRequestsForAccount: 'GetActionableRequestsForAccount',
    AccountAutopayConfigs: 'AccountAutopayConfigs',
    GetLeaseDocumentTemplate: 'GetLeaseDocumentTemplate',
    ListLeaseDocumentTemplates: 'ListLeaseDocumentTemplates',
    LeaseDocumentTemplatesProperties: 'LeaseDocumentTemplatesProperties',
    ManagerSubscriptions: 'ManagerSubscriptions',
    GetTeam: 'GetTeam',
    TeamsWizard: 'TeamsWizard',
    TeamsAdmin: 'TeamsAdmin',
    TeamPropertyUnits: 'TeamPropertyUnits',
    GetAccount: 'GetAccount',
    GetManagers: 'GetManagers',
    SearchRelatedUnits: 'SearchRelatedUnits',
    UnitName: 'UnitName',
    GetBooks: 'GetBooks',
    GetBatches: 'GetBatches',
    ListClearables: 'ListClearables',
    CountClearables: 'CountClearables',
    GetClearablesById: 'GetClearablesById',
    GetClearableDetails: 'GetClearableDetails',
    GetRecurrences: 'GetRecurrences',
    GetJournalEntry: 'GetJournalEntry',
    GetJournalEntryDetails: 'GetJournalEntryDetails',
    JournalWithJournals: 'JournalWithJournals',
    GetJournalEntriesForJE: 'GetJournalEntriesForJE',
    GetBatch: 'GetBatch',
    GetBatchPrototypes: 'GetBatchPrototypes',
    GetBooksReport: 'GetBooksReport',
    GetBooksReportDefinition: 'GetBooksReportDefinition',
    EditableGlAccounts: 'EditableGlAccounts',
    ClearableProperty: 'ClearableProperty',
    OwnerGst: 'OwnerGst',
    GetChargeApprovals: 'GetChargeApprovals',
    PaymentsApprovals: 'PaymentsApprovals',
    ListRequestedPayments: 'ListRequestedPayments',
    ScanApprovals: 'ScanApprovals',
    GetScanApproval: 'GetScanApproval',
    ActivatedEnrollmentIds: 'ActivatedEnrollmentIds',
    PageProperties: 'PageProperties',
    ServerClearables: 'ServerClearables',
    PayeeOwners: 'PayeeOwners',
    GetCombinedSubAccountBalance: 'GetCombinedSubAccountBalance',
    OwnerEntityNames: 'OwnerEntityNames',
    PropertyEntityNames: 'PropertyEntityNames',
    GetReconciliationJournals: 'GetReconciliationJournals',
    AccountPayeeName: 'AccountPayeeName',
    OperatorPayeeName: 'OperatorPayeeName',
    OwnerPayeeName: 'OwnerPayeeName',
    SupplierPayeeName: 'SupplierPayeeName',
    TenantPayeeName: 'TenantPayeeName',
    JournalEntriesForPayeeRef: 'JournalEntriesForPayeeRef',
    BooksOwners: 'BooksOwners',
    GetReconciliation: 'GetReconciliation',
    GetReconciliationDocument: 'GetReconciliationDocument',
    ServerDraftedJournals: 'ServerDraftedJournals',
    ServerSubAccount: 'ServerSubAccount',
    CombinedSubAccount: 'CombinedSubAccount',
    BundledReports: 'BundledReports',
    ReportBundles: 'ReportBundles',
    GetOwnerFiscal: 'GetOwnerFiscal',
    GetBudgets: 'GetBudgets',
    ListCheques: 'ListCheques',
    PreviewCheques: 'PreviewCheques',
    TenantActivatedEnrollments: 'TenantActivatedEnrollments',
    OwnerActivatedEnrollments: 'OwnerActivatedEnrollments',
    ServerReconciliationJournals: 'ServerReconciliationJournals',
    ReconciliationConfigScopeStepPath: 'ReconciliationConfigScopeStepPath',
    GetReconciliations: 'GetReconciliations',
    ListReconciliations: 'ListReconciliations',
    ListReconciliationConfigs: 'ListReconciliationConfigs',
    PropertiesWithCurrentResidencies: 'PropertiesWithCurrentResidencies',
    GetOwnersWithContacts: 'GetOwnersWithContacts',
    GetAnnouncements: 'GetAnnouncements',
    AccountAutopayOnboardingUrl: 'AccountAutopayOnboardingUrl',
    GetServerTenantAutopayPayees: 'GetServerTenantAutopayPayees',
    GetServerOwnerAutopayPayees: 'GetServerOwnerAutopayPayees',
    AutopayProperties: 'AutopayProperties',
    AutopayPropertyConfig: 'AutopayPropertyConfig',
    ListAutopayStatements: 'ListAutopayStatements',
    ListAutopayProperties: 'ListAutopayProperties',
    GetAutopayEnrollments: 'GetAutopayEnrollments',
    GetAutopay: 'GetAutopay',
    GetTenantByProfileId: 'GetTenantByProfileId',
    UnitStepPropertyWithResidency: 'UnitStepPropertyWithResidency',
    UnitStepAllProperties: 'UnitStepAllProperties',
    GetAccountLeaseExpiryThreshold: 'GetAccountLeaseExpiryThreshold',
    ApplicationLeaseUnitWithResidency: 'ApplicationLeaseUnitWithResidency',
    GetCampaign: 'GetCampaign',
    GetCampaignsListedUnits: 'GetCampaignsListedUnits',
    GetFloorplanByIds: 'GetFloorplanByIds',
    GetCampaigns: 'GetCampaigns',
    Collections: 'Collections',
    GetPropertyFacts: 'GetPropertyFacts',
    ActiveRequests: 'ActiveRequests',
    GetOperator: 'GetOperator',
    GetOperatorRequestHistory: 'GetOperatorRequestHistory',
    GetOperators: 'GetOperators',
    GetPropertyForHeader: 'GetPropertyForHeader',
    getOwnerProfile: 'getOwnerProfile',
    OwnerUnitFinancialsPath: 'OwnerUnitFinancialsPath',
    GetOwnerDocuments: 'GetOwnerDocuments',
    OwnerWithResidenciesDetail: 'OwnerWithResidenciesDetail',
    GetOwnerEnrollments: 'GetOwnerEnrollments',
    searchOwnersByEmail: 'searchOwnersByEmail',
    GetOwner: 'GetOwner',
    OwnerNotes: 'OwnerNotes',
    GetOwners: 'GetOwners',
    ResidentContactListForProperty: 'ResidentContactListForProperty',
    GetPropertyForFloorplan: 'GetPropertyForFloorplan',
    GetDefaultFloorplans: 'GetDefaultFloorplans',
    ListFloorplans: 'ListFloorplans',
    GetFloorplan: 'GetFloorplan',
    GetDefaultFeatures: 'GetDefaultFeatures',
    GetDefaultAdditionalFeatures: 'GetDefaultAdditionalFeatures',
    GetCondoFeeUnitsForProperty: 'GetCondoFeeUnitsForProperty',
    GetPropertyForCondoFees: 'GetPropertyForCondoFees',
    UnitEffectsForProperty: 'UnitEffectsForProperty',
    BuildingNotes: 'BuildingNotes',
    GetBuildingSpecs: 'GetBuildingSpecs',
    BuildingDocuments: 'BuildingDocuments',
    GetBuildingAmenities: 'GetBuildingAmenities',
    GetBuildingFeatures: 'GetBuildingFeatures',
    GetBuildingInsurance: 'GetBuildingInsurance',
    GetBuildingInsuranceHistory: 'GetBuildingInsuranceHistory',
    GetPropertyTimezone: 'GetPropertyTimezone',
    UnitCounts: 'UnitCounts',
    UpdateResidencyRateSchedulePath: 'UpdateResidencyRateSchedulePath',
    GetPropertyInsurance: 'GetPropertyInsurance',
    GetPropertyInsuranceHistory: 'GetPropertyInsuranceHistory',
    GetPropertyEntity: 'GetPropertyEntity',
    GetProperty: 'GetProperty',
    PropertyWithResidencies: 'PropertyWithResidencies',
    PropertyNotes: 'PropertyNotes',
    GetPropertySpecs: 'GetPropertySpecs',
    PropertyAmenities: 'PropertyAmenities',
    Suppliers: 'Suppliers',
    GetPropertyFeatures: 'GetPropertyFeatures',
    PropertyDocuments: 'PropertyDocuments',
    GetPropertyFloorplans: 'GetPropertyFloorplans',
    GetBuildingFloorplans: 'GetBuildingFloorplans',
    GetPropertyPhotos: 'GetPropertyPhotos',
    InspectionPhotosUnitData: 'InspectionPhotosUnitData',
    InspectionPhotosResidencyData: 'InspectionPhotosResidencyData',
    GetUnitFloorplan: 'GetUnitFloorplan',
    CreateResidencyPath: 'CreateResidencyPath',
    DeleteResidencyPath: 'DeleteResidencyPath',
    GetFurnishingsTemplate: 'GetFurnishingsTemplate',
    ResidencyWithInspections: 'ResidencyWithInspections',
    RemoveResidencyResidentPath: 'RemoveResidencyResidentPath',
    UpdateResidencyInsurancePolicyPath: 'UpdateResidencyInsurancePolicyPath',
    ResidencyKeysPath: 'ResidencyKeysPath',
    ResidencySecurityDepositHistoryPath: 'ResidencySecurityDepositHistoryPath',
    TerminateResidencyPath: 'TerminateResidencyPath',
    GetUtilitiesTemplate: 'GetUtilitiesTemplate',
    GetUnitRoutineInspections: 'GetUnitRoutineInspections',
    GetUnitWithResidencyForRequest: 'GetUnitWithResidencyForRequest',
    RequestDocuments: 'RequestDocuments',
    CreateResidencyWizardPath: 'CreateResidencyWizardPath',
    ResidencyPath: 'ResidencyPath',
    CurrentResidenciesPath: 'CurrentResidenciesPath',
    ResidencyProRatedEffects: 'ResidencyProRatedEffects',
    ResidencyDocuments: 'ResidencyDocuments',
    ResidencyResidentDocuments: 'ResidencyResidentDocuments',
    ListAllResidents: 'ListAllResidents',
    UpdateResidencyWizardPath: 'UpdateResidencyWizardPath',
    LeaseFormsByProvince: 'LeaseFormsByProvince',
    LeaseForm: 'LeaseForm',
    LeaseFormResidencyData: 'LeaseFormResidencyData',
    LeaseFormResidencyPath: 'LeaseFormResidencyPath',
    LeaseFormCurrentResidenciesPath: 'LeaseFormCurrentResidenciesPath',
    IssueDepositWizardPath: 'IssueDepositWizardPath',
    ResidencyAccrualsQuery: 'ResidencyAccrualsQuery',
    GetAccountInfo: 'GetAccountInfo',
    GetResidency: 'GetResidency',
    ResidencyRefundablesPath: 'ResidencyRefundablesPath',
    GetTenantsByEmail: 'GetTenantsByEmail',
    GetTenants: 'GetTenants',
    GetUnit: 'GetUnit',
    UnitPath: 'UnitPath',
    UnitDocuments: 'UnitDocuments',
    RequestBillingRates: 'RequestBillingRates',
    GetManager: 'GetManager',
    GetOperatorWindows: 'GetOperatorWindows',
    GetRequest: 'GetRequest',
    GetRequestCosts: 'GetRequestCosts',
    GetRequestRecurrence: 'GetRequestRecurrence',
    GetRequestRecurrences: 'GetRequestRecurrences',
    ServerRequests: 'ServerRequests',
    GetResidenciesEligibleForRentIncrease: 'GetResidenciesEligibleForRentIncrease',
    GetInsuranceResidencies: 'GetInsuranceResidencies',
    ListAllResidencies: 'ListAllResidencies',
    InspectionsByIds: 'InspectionsByIds',
    UnitsWithResidencies: 'UnitsWithResidencies',
    GetHouseRulesTemplate: 'GetHouseRulesTemplate',
    GetDefaultTemplate: 'GetDefaultTemplate',
    GetCustomTemplate: 'GetCustomTemplate',
    getTemplates: 'getTemplates',
    TenantResidencyFinancialsPath: 'TenantResidencyFinancialsPath',
    ResidencyResidentFinancialsPath: 'ResidencyResidentFinancialsPath',
    TenantNotes: 'TenantNotes',
    GetTenantWithResidency: 'GetTenantWithResidency',
    GetTenant: 'GetTenant',
    GetTenantName: 'GetTenantName',
    GetTenantEnrollments: 'GetTenantEnrollments',
    GetTenantContact: 'GetTenantContact',
    ListAllTenantsWithResidency: 'ListAllTenantsWithResidency',
    TenantDocuments: 'TenantDocuments',
  },
  Mutation: {
    CreateChargeApproval: 'CreateChargeApproval',
    CreateRequestNote: 'CreateRequestNote',
    DeleteRequestNote: 'DeleteRequestNote',
    DeleteImageNote: 'DeleteImageNote',
    CreateImageNote: 'CreateImageNote',
    CreateUnitNote: 'CreateUnitNote',
    DeleteUnitNote: 'DeleteUnitNote',
    CreateJournalEntryNote: 'CreateJournalEntryNote',
    DeleteJournalEntryNote: 'DeleteJournalEntryNote',
    CreateResidencyNote: 'CreateResidencyNote',
    DeleteResidencyNote: 'DeleteResidencyNote',
    CreateApplicationNote: 'CreateApplicationNote',
    DeleteApplicationNote: 'DeleteApplicationNote',
    CreateContact: 'CreateContact',
    UpdateContact: 'UpdateContact',
    DeleteContact: 'DeleteContact',
    DeleteAccountFee: 'DeleteAccountFee',
    UpdateAccountFee: 'UpdateAccountFee',
    CreateAccountFee: 'CreateAccountFee',
    CreateSupplier: 'CreateSupplier',
    LinkSupplier: 'LinkSupplier',
    UpdateSupplier: 'UpdateSupplier',
    DeleteSupplier: 'DeleteSupplier',
    AddAutopayBank: 'AddAutopayBank',
    AddLinkedBankAccounts: 'AddLinkedBankAccounts',
    DeleteLinkedBankAccount: 'DeleteLinkedBankAccount',
    UpdateAutopayCollectionTypes: 'UpdateAutopayCollectionTypes',
    UpdateAutopayNSFFees: 'UpdateAutopayNSFFees',
    UpdateAutopayFeeInvoice: 'UpdateAutopayFeeInvoice',
    UpdateAccountAutopay: 'UpdateAccountAutopay',
    CreateLeaseDocumentTemplate: 'CreateLeaseDocumentTemplate',
    UpdateLeaseDocumentTemplate: 'UpdateLeaseDocumentTemplate',
    DeleteLeaseDocumentTemplate: 'DeleteLeaseDocumentTemplate',
    CreateTeam: 'CreateTeam',
    UpdateTeam: 'UpdateTeam',
    DeleteTeam: 'DeleteTeam',
    UpdateAccount: 'UpdateAccount',
    CreateManager: 'CreateManager',
    UpdateManager: 'UpdateManager',
    ToggleEnableManager: 'ToggleEnableManager',
    DeleteManager: 'DeleteManager',
    AddManagerRole: 'AddManagerRole',
    RemoveManagerRole: 'RemoveManagerRole',
    RemoveManagerSignature: 'RemoveManagerSignature',
    UpdateManagerSubscriptions: 'UpdateManagerSubscriptions',
    UpdateClearable: 'UpdateClearable',
    DeleteRecurrence: 'DeleteRecurrence',
    ActivateBooks: 'ActivateBooks',
    UpdateFinancialSettings: 'UpdateFinancialSettings',
    CreateGlAccount: 'CreateGlAccount',
    UpdateGlAccount: 'UpdateGlAccount',
    AddClearable: 'AddClearable',
    RefundClearable: 'RefundClearable',
    AddJournalEntry: 'AddJournalEntry',
    UpdateReconciliationJournals: 'UpdateReconciliationJournals',
    UpdateJournalEntry: 'UpdateJournalEntry',
    DeleteJournalEntry: 'DeleteJournalEntry',
    CreateDraftBatch: 'CreateDraftBatch',
    UpdateBatchPrototype: 'UpdateBatchPrototype',
    ToggleBatchPrototypes: 'ToggleBatchPrototypes',
    InitiateBooksBatch: 'InitiateBooksBatch',
    CancelBooksBatch: 'CancelBooksBatch',
    CommitBooksBatch: 'CommitBooksBatch',
    UpdatePresetGlMapping: 'UpdatePresetGlMapping',
    UpdateGlChequePlacement: 'UpdateGlChequePlacement',
    VoidJournalEntry: 'VoidJournalEntry',
    setClearableAutopayCollectionsEnabled: 'setClearableAutopayCollectionsEnabled',
    ApproveChargeApproval: 'ApproveChargeApproval',
    DeclineChargeApproval: 'DeclineChargeApproval',
    ApprovePaymentsApproval: 'ApprovePaymentsApproval',
    DeclinePaymentsApproval: 'DeclinePaymentsApproval',
    ApproveScanApproval: 'ApproveScanApproval',
    DeclineScanApproval: 'DeclineScanApproval',
    UpdateScanApprovalRequest: 'UpdateScanApprovalRequest',
    AddScanApprovalTask: 'AddScanApprovalTask',
    AddScanMatchingTask: 'AddScanMatchingTask',
    CreateCharge: 'CreateCharge',
    DeleteCharge: 'DeleteCharge',
    AddClearingEntryBatchTask: 'AddClearingEntryBatchTask',
    AbortBooksBatchTask: 'AbortBooksBatchTask',
    UpdateClearingEntryBatchTask: 'UpdateClearingEntryBatchTask',
    CreatePaymentsBatch: 'CreatePaymentsBatch',
    AddPaymentsBatchPrototypes: 'AddPaymentsBatchPrototypes',
    CreatePaymentsApproval: 'CreatePaymentsApproval',
    UpdatePaymentsBatch: 'UpdatePaymentsBatch',
    CommitReconciliation: 'CommitReconciliation',
    DraftReconciliation: 'DraftReconciliation',
    NotifyPayeePaymentReturned: 'NotifyPayeePaymentReturned',
    UpdatePresetRecurrence: 'UpdatePresetRecurrence',
    TransferJournalEntries: 'TransferJournalEntries',
    RunReportSet: 'RunReportSet',
    DeleteBundledReport: 'DeleteBundledReport',
    SetBundledReportSharedFlag: 'SetBundledReportSharedFlag',
    UpdateBundledReportFlag: 'UpdateBundledReportFlag',
    CreateReportBundle: 'CreateReportBundle',
    UpdateReportBundle: 'UpdateReportBundle',
    DeleteReportBundle: 'DeleteReportBundle',
    PutBudgets: 'PutBudgets',
    PrintJournalEntries: 'PrintJournalEntries',
    UpdateReconciliation: 'UpdateReconciliation',
    DeleteReconciliation: 'DeleteReconciliation',
    UpdateJournalDrafts: 'UpdateJournalDrafts',
    AddReconciliationConfig: 'AddReconciliationConfig',
    UpdateReconciliationConfig: 'UpdateReconciliationConfig',
    DeleteReconciliationConfig: 'DeleteReconciliationConfig',
    CreateAnnouncement: 'CreateAnnouncement',
    UpdateAnnouncement: 'UpdateAnnouncement',
    DeleteAnnouncement: 'DeleteAnnouncement',
    PublishFutureAnnouncement: 'PublishFutureAnnouncement',
    PublishAnnouncement: 'PublishAnnouncement',
    CancelPublishAnnouncement: 'CancelPublishAnnouncement',
    TestVoiceAnnouncement: 'TestVoiceAnnouncement',
    AddAnnouncementReply: 'AddAnnouncementReply',
    StartAccountAutopay: 'StartAccountAutopay',
    SetPropertyAutopayCollectionsEnabled: 'SetPropertyAutopayCollectionsEnabled',
    ActivateAutopay: 'ActivateAutopay',
    DeactivateAutopay: 'DeactivateAutopay',
    ActivatePropertiesAutopay: 'ActivatePropertiesAutopay',
    DeactivatePropertiesAutopay: 'DeactivatePropertiesAutopay',
    InvitePayee: 'InvitePayee',
    ResendInviteToTenant: 'ResendInviteToTenant',
    ResendInviteToOwner: 'ResendInviteToOwner',
    UpdateEnrollmentToInvited: 'UpdateEnrollmentToInvited',
    UpdateEnrollmentToDeclined: 'UpdateEnrollmentToDeclined',
    UpdateApplication: 'UpdateApplication',
    SaveLeaseAgreement: 'SaveLeaseAgreement',
    CreateLeaseAgreementUrl: 'CreateLeaseAgreementUrl',
    SignLeaseAgreement: 'SignLeaseAgreement',
    SendLeaseOffer: 'SendLeaseOffer',
    UpdateApplicationFlag: 'UpdateApplicationFlag',
    shareCampaign: 'shareCampaign',
    CreateCampaignLink: 'CreateCampaignLink',
    updateCampaignStatus: 'updateCampaignStatus',
    CreateCampaign: 'CreateCampaign',
    updateCampaign: 'updateCampaign',
    UpdateOperator: 'UpdateOperator',
    DeleteOperator: 'DeleteOperator',
    UpdateOperatorAutoAP: 'UpdateOperatorAutoAP',
    AddOperator: 'AddOperator',
    SetOwnerAutopayCollectionsEnabled: 'SetOwnerAutopayCollectionsEnabled',
    UpdateOwnerUnitFinancials: 'UpdateOwnerUnitFinancials',
    RenameOwnerDocument: 'RenameOwnerDocument',
    AddOwner: 'AddOwner',
    UpdateOwner: 'UpdateOwner',
    UpdateOwnerFinancials: 'UpdateOwnerFinancials',
    UpdateOwnerAutopay: 'UpdateOwnerAutopay',
    InviteOwnerAutopay: 'InviteOwnerAutopay',
    DeleteOwner: 'DeleteOwner',
    RemoveOwnerProperty: 'RemoveOwnerProperty',
    RemoveOwnerUnit: 'RemoveOwnerUnit',
    AddOwnerProperty: 'AddOwnerProperty',
    AddOwnerUnit: 'AddOwnerUnit',
    UpdateOwnerUnit: 'UpdateOwnerUnit',
    UpdateOwnerProperty: 'UpdateOwnerProperty',
    CreateOwnerNote: 'CreateOwnerNote',
    DeleteOwnerNote: 'DeleteOwnerNote',
    UpdateOwnerManagementFee: 'UpdateOwnerManagementFee',
    CreateUnits: 'CreateUnits',
    CreatePropertyFloorplan: 'CreatePropertyFloorplan',
    CreateBuildingFloorplan: 'CreateBuildingFloorplan',
    UpdateFloorplan: 'UpdateFloorplan',
    AddBuilding: 'AddBuilding',
    UpdateBuilding: 'UpdateBuilding',
    CreateBuildingNote: 'CreateBuildingNote',
    DeleteBuildingNote: 'DeleteBuildingNote',
    UpdateBuildingSpecs: 'UpdateBuildingSpecs',
    RenameBuildingDocument: 'RenameBuildingDocument',
    DeleteBuilding: 'DeleteBuilding',
    UpdateBuildingAmenities: 'UpdateBuildingAmenities',
    UpdateBuildingFeatures: 'UpdateBuildingFeatures',
    AddBuildingInsurance: 'AddBuildingInsurance',
    UpdateBuildingInsurance: 'UpdateBuildingInsurance',
    AddBuildingInsuranceHistory: 'AddBuildingInsuranceHistory',
    UpdateBuildingInsuranceHistory: 'UpdateBuildingInsuranceHistory',
    DeleteBuildingInsuranceHistory: 'DeleteBuildingInsuranceHistory',
    AddBuildingInsuranceClaim: 'AddBuildingInsuranceClaim',
    UpdateBuildingInsuranceClaim: 'UpdateBuildingInsuranceClaim',
    DeleteBuildingInsuranceClaim: 'DeleteBuildingInsuranceClaim',
    AddBuildingInsuranceHistoryClaim: 'AddBuildingInsuranceHistoryClaim',
    UpdateBuildingInsuranceHistoryClaim: 'UpdateBuildingInsuranceHistoryClaim',
    DeleteBuildingInsuranceHistoryClaim: 'DeleteBuildingInsuranceHistoryClaim',
    UpdateResidencyEffects: 'UpdateResidencyEffects',
    AddPropertyInsurance: 'AddPropertyInsurance',
    updatePropertyInsurance: 'updatePropertyInsurance',
    AddPropertyInsuranceHistory: 'AddPropertyInsuranceHistory',
    UpdatePropertyInsuranceHistory: 'UpdatePropertyInsuranceHistory',
    DeletePropertyInsuranceHistory: 'DeletePropertyInsuranceHistory',
    AddPropertyInsuranceClaim: 'AddPropertyInsuranceClaim',
    UpdatePropertyInsuranceClaim: 'UpdatePropertyInsuranceClaim',
    DeletePropertyInsuranceClaim: 'DeletePropertyInsuranceClaim',
    AddPropertyInsuranceHistoryClaim: 'AddPropertyInsuranceHistoryClaim',
    UpdatePropertyInsuranceHistoryClaim: 'UpdatePropertyInsuranceHistoryClaim',
    DeletePropertyInsuranceHistoryClaim: 'DeletePropertyInsuranceHistoryClaim',
    MoveUnitsToBuilding: 'MoveUnitsToBuilding',
    AddProperty: 'AddProperty',
    UpdatePropertyAmenities: 'UpdatePropertyAmenities',
    CreatePropertyNote: 'CreatePropertyNote',
    DeletePropertyNote: 'DeletePropertyNote',
    UpdateProperty: 'UpdateProperty',
    UpdatePropertySpecs: 'UpdatePropertySpecs',
    UpdatePropertyFeatures: 'UpdatePropertyFeatures',
    RenamePropertyDocument: 'RenamePropertyDocument',
    UpdatePropertySuppliers: 'UpdatePropertySuppliers',
    UpdatePropertyContractors: 'UpdatePropertyContractors',
    DeleteProperty: 'DeleteProperty',
    removeParkingStall: 'removeParkingStall',
    removeStorageLocker: 'removeStorageLocker',
    removeKeyring: 'removeKeyring',
    addParkingStall: 'addParkingStall',
    addStorageLocker: 'addStorageLocker',
    addKeyring: 'addKeyring',
    AddPropertyImages: 'AddPropertyImages',
    DeletePropertyImage: 'DeletePropertyImage',
    unassignStorage: 'unassignStorage',
    assignStorage: 'assignStorage',
    unassignParking: 'unassignParking',
    assignParking: 'assignParking',
    unassignKeyring: 'unassignKeyring',
    assignKeyring: 'assignKeyring',
    UpdateUnitFloorplan: 'UpdateUnitFloorplan',
    RemoveUnitFloorplan: 'RemoveUnitFloorplan',
    UpdateResidency: 'UpdateResidency',
    createRequestRecurrence: 'createRequestRecurrence',
    CreateResidency: 'CreateResidency',
    DeleteResidency: 'DeleteResidency',
    RemoveResidencyResident: 'RemoveResidencyResident',
    UpdateResidencyInsurance: 'UpdateResidencyInsurance',
    RemoveResidencyInsurance: 'RemoveResidencyInsurance',
    AddResidencySecurityDepositStatus: 'AddResidencySecurityDepositStatus',
    TerminateResidency: 'TerminateResidency',
    CreateInspectionUrl: 'CreateInspectionUrl',
    CreateRequest: 'CreateRequest',
    RenameRequestDocument: 'RenameRequestDocument',
    AddResidencyEvent: 'AddResidencyEvent',
    UpdateResidencyEvent: 'UpdateResidencyEvent',
    DeleteResidencyEvent: 'DeleteResidencyEvent',
    RenameResidencyDocument: 'RenameResidencyDocument',
    UpdateResidencyRefundables: 'UpdateResidencyRefundables',
    UpdateUnit: 'UpdateUnit',
    RenameUnitDocument: 'RenameUnitDocument',
    AddUnit: 'AddUnit',
    AddRequestImages: 'AddRequestImages',
    DeleteRequestImage: 'DeleteRequestImage',
    UpdateRequestChecklistItem: 'UpdateRequestChecklistItem',
    CreateRequestCost: 'CreateRequestCost',
    UpdateRequestCost: 'UpdateRequestCost',
    DeleteRequestCost: 'DeleteRequestCost',
    RefreshRequestImages: 'RefreshRequestImages',
    DeleteVisitBill: 'DeleteVisitBill',
    CreateMaintenanceApproval: 'CreateMaintenanceApproval',
    UpdateRequest: 'UpdateRequest',
    FinalizeRequest: 'FinalizeRequest',
    ConfirmAppointment: 'ConfirmAppointment',
    UpdateVisit: 'UpdateVisit',
    ScheduleVisit: 'ScheduleVisit',
    AssignVisit: 'AssignVisit',
    FinalizeVisit: 'FinalizeVisit',
    CreateVisit: 'CreateVisit',
    UpdateRequestRecurrence: 'UpdateRequestRecurrence',
    DeleteRequestRecurrence: 'DeleteRequestRecurrence',
    UpdateSupplierAutoAP: 'UpdateSupplierAutoAP',
    CreateHouseRulesTemplate: 'CreateHouseRulesTemplate',
    UpdateHouseRulesTemplate: 'UpdateHouseRulesTemplate',
    DeleteHouseRulesTemplate: 'DeleteHouseRulesTemplate',
    CreateTemplate: 'CreateTemplate',
    UpdateTemplate: 'UpdateTemplate',
    DeleteTemplate: 'DeleteTemplate',
    SetTenantAutopayCollectionsEnabled: 'SetTenantAutopayCollectionsEnabled',
    UpdateResidencyResidentFinancials: 'UpdateResidencyResidentFinancials',
    CreateTenantNote: 'CreateTenantNote',
    DeleteTenantNote: 'DeleteTenantNote',
    UpdateTenantAutopay: 'UpdateTenantAutopay',
    InviteTenantAutopay: 'InviteTenantAutopay',
    RenameTenantDocument: 'RenameTenantDocument',
    UpdateTenantFinancials: 'UpdateTenantFinancials',
    WelcomeTenant: 'WelcomeTenant',
    UpdateTenantRecord: 'UpdateTenantRecord',
  },
  Subscription: {
    AccountEvents: 'AccountEvents',
  },
  Fragment: {
    ChargeApprovalFields: 'ChargeApprovalFields',
    AccountFields: 'AccountFields',
    AddressFields: 'AddressFields',
    AddressHistoryFields: 'AddressHistoryFields',
    AdditionalTenantFields: 'AdditionalTenantFields',
    PetFields: 'PetFields',
    VehicleFields: 'VehicleFields',
    HouseholdFields: 'HouseholdFields',
    WorkHistoryFields: 'WorkHistoryFields',
    OtherIncomeFields: 'OtherIncomeFields',
    CoApplicationsFields: 'CoApplicationsFields',
    ApplicationFields: 'ApplicationFields',
    BatchFields: 'BatchFields',
    BatchPrototypeFields: 'BatchPrototypeFields',
    BooksFields: 'BooksFields',
    BooksReportFields: 'BooksReportFields',
    BuildingFields: 'BuildingFields',
    ListedUnitFields: 'ListedUnitFields',
    CampaignFields: 'CampaignFields',
    ChequeFields: 'ChequeFields',
    ClearableFields: 'ClearableFields',
    ClearableListFields: 'ClearableListFields',
    ContactFields: 'ContactFields',
    DocumentFields: 'DocumentFields',
    DraftBatchFields: 'DraftBatchFields',
    FeatureFields: 'FeatureFields',
    GlAccountFields: 'GlAccountFields',
    GlMappingFields: 'GlMappingFields',
    InspectionFields: 'InspectionFields',
    InspectionItemFields: 'InspectionItemFields',
    ClaimFields: 'ClaimFields',
    InsurancePolicyFields: 'InsurancePolicyFields',
    JournalEntryFields: 'JournalEntryFields',
    JournalEntryListFields: 'JournalEntryListFields',
    AgreementLeaseFields: 'AgreementLeaseFields',
    LeaseAgreementFields: 'LeaseAgreementFields',
    ManagerFields: 'ManagerFields',
    OperatorRequestFields: 'OperatorRequestFields',
    OwnerFields: 'OwnerFields',
    OwnerListFields: 'OwnerListFields',
    OwnerWithNotesListFields: 'OwnerWithNotesListFields',
    PresetFields: 'PresetFields',
    AmenityFields: 'AmenityFields',
    AmenitiesSettingFields: 'AmenitiesSettingFields',
    PropertyEntityFields: 'PropertyEntityFields',
    PropertyFields: 'PropertyFields',
    PropertyListFields: 'PropertyListFields',
    PropertySpecsFields: 'PropertySpecsFields',
    RecurrenceFields: 'RecurrenceFields',
    RegionTaxDetailsFields: 'RegionTaxDetailsFields',
    RequestFields: 'RequestFields',
    RequestListFields: 'RequestListFields',
    ServerRequestListFields: 'ServerRequestListFields',
    ResidencyEntityFields: 'ResidencyEntityFields',
    ResidencyFields: 'ResidencyFields',
    RoutineInspectionFields: 'RoutineInspectionFields',
    SignatureFields: 'SignatureFields',
    SupplierFields: 'SupplierFields',
    TemplateFields: 'TemplateFields',
    TenantFields: 'TenantFields',
    UnitEntityFields: 'UnitEntityFields',
    UnitFields: 'UnitFields',
    UnitFieldsWithResidency: 'UnitFieldsWithResidency',
    UnitHistoryFields: 'UnitHistoryFields',
    UnitListFields: 'UnitListFields',
    UnitListFieldsWithResidency: 'UnitListFieldsWithResidency',
    VisitFields: 'VisitFields',
    FeeFields: 'FeeFields',
    LeaseDocumentTemplateListFields: 'LeaseDocumentTemplateListFields',
    LeaseDocumentTemplateFields: 'LeaseDocumentTemplateFields',
    TeamFields: 'TeamFields',
    ScanApprovalFields: 'ScanApprovalFields',
    JournalPostedDetail: 'JournalPostedDetail',
    AccountChargesFields: 'AccountChargesFields',
    ReconciliationScopeFields: 'ReconciliationScopeFields',
    ReconciliationFields: 'ReconciliationFields',
    ReconciliationConfigFields: 'ReconciliationConfigFields',
    AnnouncementsFields: 'AnnouncementsFields',
    AutopayBankFields: 'AutopayBankFields',
    AutopayFields: 'AutopayFields',
    LinkedAccountFields: 'LinkedAccountFields',
    AutopayPropertyFields: 'AutopayPropertyFields',
    AutopayEnrollmentFields: 'AutopayEnrollmentFields',
    CampaignListFields: 'CampaignListFields',
    OperatorFields: 'OperatorFields',
    OperatorRequestHistoryFields: 'OperatorRequestHistoryFields',
    OperatorListFields: 'OperatorListFields',
    ContactListOwnerFields: 'ContactListOwnerFields',
    FloorplanListFields: 'FloorplanListFields',
    CondoFeeUnitFields: 'CondoFeeUnitFields',
    FloorplanFields: 'FloorplanFields',
    ResidencySecurityDepositFields: 'ResidencySecurityDepositFields',
    PropertyDataForRequest: 'PropertyDataForRequest',
    UnitResidencyDataForRequest: 'UnitResidencyDataForRequest',
    LeaseFormFields: 'LeaseFormFields',
    ResidencyOutstandingFields: 'ResidencyOutstandingFields',
    RequestRecurrence: 'RequestRecurrence',
    InsuranceResidencyFields: 'InsuranceResidencyFields',
    TenantResidency: 'TenantResidency',
    TenantWithResidency: 'TenantWithResidency',
  },
};
