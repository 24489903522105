import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CodeToggle,
  CreateLink,
  ListsToggle,
  MDXEditor,
  UndoRedo,
  codeBlockPlugin,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  quotePlugin,
  toolbarPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { FormControl, FormHelperText, InputLabel, styled } from '@mui/material';
import { useTheme } from 'context';
import { Controller, useFormContext } from 'react-hook-form';

export const plugins = [
  toolbarPlugin({
    toolbarContents: () => (
      <>
        <UndoRedo />
        <BoldItalicUnderlineToggles />
        <BlockTypeSelect />
        <CodeToggle />
        <ListsToggle />
        <CreateLink />
      </>
    ),
  }),
  listsPlugin(),
  quotePlugin(),
  headingsPlugin({ allowedHeadingLevels: [1, 2, 3, 4, 5, 6] }),
  codeBlockPlugin({ defaultCodeBlockLanguage: 'txt' }),
  linkPlugin(),
  linkDialogPlugin(),
];

const Editor = styled(MDXEditor)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginTop: theme.spacing(6),
  border: `1px solid ${theme.palette.divider}`,
  '& .mdxeditor-root-contenteditable ': {
    '& *': {
      color: theme.palette.text.primary,
    },
  },
})) as unknown as typeof MDXEditor;

export default function EditorFieldController({
  name,
  label,
  disabled,
}: {
  name: string;
  label: string;
  disabled?: boolean;
}) {
  const { control } = useFormContext();
  const { darkMode } = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl sx={{ width: '100%' }}>
          <InputLabel sx={{ ml: -2 }}>{label}</InputLabel>
          <Editor
            className={darkMode ? 'dark-theme dark-editor' : ''}
            disabled={disabled}
            {...field}
            {...fieldState}
            markdown={field.value ?? ''}
            plugins={plugins}
          />
          {fieldState.error?.message && (
            <FormHelperText error>{fieldState.error?.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
