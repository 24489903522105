import { makeVar } from '@apollo/client';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { AutopayEnrollmentStatus } from 'api';
import { StatusOptions as AutopayPayeeStatusOptions } from 'pages/autopay/Payees/utils';
import { CLEARABLES_PAGE_SIZE, JOURNALS_PAGE_SIZE, SUPPLIERS_PAGE_SIZE } from 'system';

export const gridInitialState = (options?: Partial<GridInitialStatePro>): GridInitialStatePro => ({
  columns: { columnVisibilityModel: {} },
  ...options,
});

const sorted = (field: string, sort: 'asc' | 'desc' = 'asc') =>
  gridInitialState({ sorting: { sortModel: [{ field, sort }] } });

const paginatedClearables = (state: GridInitialStatePro) => ({
  ...state,
  pagination: {
    paginationModel: { pageSize: CLEARABLES_PAGE_SIZE, page: 0 },
  },
});

const paginatedJournals = (state: GridInitialStatePro) => ({
  ...state,
  sorting: { sortModel: [{ field: 'posted', sort: 'desc' as const }] },
  pagination: {
    paginationModel: { pageSize: JOURNALS_PAGE_SIZE, page: 0 },
  },
});
export const autopayStatementsGridState = makeVar<GridInitialStatePro>(
  gridInitialState({
    sorting: { sortModel: [{ field: 'settledZ', sort: 'desc' }] },
  })
);
export const propertyOwnerPickerGridState = makeVar<GridInitialStatePro>(
  sorted('propertyName', 'asc')
);
export const residencyTermsGridState = makeVar<GridInitialStatePro>(sorted('start', 'asc'));
export const pastResidenciesGridState = makeVar<GridInitialStatePro>(sorted('start', 'desc'));
export const futureResidenciesGridState = makeVar<GridInitialStatePro>(sorted('start', 'asc'));
export const residenciesGridState = makeVar<GridInitialStatePro>(sorted('start', 'desc'));
export const payablesGridState = makeVar<GridInitialStatePro>(
  paginatedClearables(sorted('posted', 'desc'))
);
export const receivablesGridState = makeVar<GridInitialStatePro>(
  paginatedClearables(sorted('posted', 'desc'))
);
export const suppliersGridState = makeVar<GridInitialStatePro>({
  pagination: {
    paginationModel: { pageSize: SUPPLIERS_PAGE_SIZE, page: 0 },
  },
});
export const collectionsGridState = makeVar<GridInitialStatePro>(
  paginatedClearables(sorted('due', 'asc'))
);
export const payeeGridState = makeVar<GridInitialStatePro>(
  paginatedClearables(sorted('posted', 'desc'))
);

export const tenantObligationsGridState = makeVar<GridInitialStatePro>(sorted('start', 'desc'));
export const ownerObligationGridState = makeVar<GridInitialStatePro>(
  gridInitialState({
    filter: {
      filterModel: {
        items: [
          {
            id: 1,
            field: 'obligation',
            operator: '>',
            value: 0,
          },
        ],
      },
    },
  })
);

export const tenantsGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const tenantsFilterGridState = makeVar<{ filteredRowIds?: string[] }>({});
export const ownersGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const ownersFilterGridState = makeVar<{ filteredRowIds?: string[] }>({});
export const unitsGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const unitsFilterGridState = makeVar<{ filteredRowIds?: string[] }>({});
export const commercialUnitsGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const commonUnitsGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const expiringLeasesGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const glAccountsGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const journalEntriesGridState = makeVar<GridInitialStatePro>(
  paginatedJournals({
    columns: { columnVisibilityModel: { payeeName: false } },
  })
);
export const requestsGridState = makeVar<GridInitialStatePro>(
  gridInitialState({
    columns: { columnVisibilityModel: { finalizedZ: false } },
  })
);
export const applicationsGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const propertyContactsGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const approvalsGridState = makeVar<GridInitialStatePro>(sorted('date'));
export const paymentsApprovalsGridState = makeVar<GridInitialStatePro>(sorted('date'));
export const scanApprovalsGridState = makeVar<GridInitialStatePro>(sorted('date'));
export const draftBatchGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const campaignsGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const campaignApplicationUnitGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const leaseTenantsGridState = makeVar<GridInitialStatePro>(
  gridInitialState({
    sorting: { sortModel: [{ field: 'badges', sort: 'desc' }] },
  })
);
export const reconciliationsGridState = makeVar<GridInitialStatePro>(
  gridInitialState({
    sorting: { sortModel: [{ field: 'posted', sort: 'desc' }] },
  })
);

export const recurrenceGridState = makeVar<GridInitialStatePro>(
  gridInitialState({
    sorting: { sortModel: [{ field: 'posted', sort: 'desc' }] },
  })
);
export const bundledReportsGridState = makeVar<GridInitialStatePro>(
  gridInitialState({
    sorting: { sortModel: [{ field: 'started', sort: 'desc' }] },
  })
);

export const sentAnnouncementsGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const autopayPropertiesGridState = makeVar<GridInitialStatePro>(
  gridInitialState({
    sorting: { sortModel: [{ field: 'selected', sort: 'desc' }] },
  })
);
export const autopayAddPropertiesGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const autopayPayeesGridState = makeVar<GridInitialStatePro>(
  gridInitialState({
    filter: {
      filterModel: {
        items: [
          {
            id: 2,
            field: 'status',
            operator: 'is any of',
            value: AutopayPayeeStatusOptions.filter(
              (option) =>
                ![
                  AutopayEnrollmentStatus.Activated,
                  AutopayEnrollmentStatus.Declined,
                  AutopayEnrollmentStatus.Cancelled,
                ].includes(option.id as AutopayEnrollmentStatus)
            ),
          },
        ],
      },
    },
  })
);
export const autopayBankAccountsGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const autopayPropertyBankAccountGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const unitsAvailabilityGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const residencyInsuranceGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const residencyRentalIncreaseGridState = makeVar<GridInitialStatePro>(
  gridInitialState({
    sorting: {
      sortModel: [{ field: 'lastIncrease', sort: 'desc' }],
    },
  })
);
export const residencyViolationsGridState = makeVar<GridInitialStatePro>(
  gridInitialState({
    sorting: {
      sortModel: [
        { field: 'latestViolationDate', sort: 'desc' },
        { field: 'unitName', sort: 'asc' },
      ],
    },
  })
);
export const condoFeeUpdateUnitsGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const rentUpdateUnitsGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const residencyInsurancePoliciesGridState = makeVar<GridInitialStatePro>(gridInitialState());

export const journalTableMainState = makeVar<GridInitialStatePro>(
  gridInitialState({
    pagination: { paginationModel: { pageSize: 1000, page: 1 } },
  })
);

export const journalTableLinkedState = makeVar<GridInitialStatePro>(
  gridInitialState({
    pagination: { paginationModel: { pageSize: 10, page: 1 } },
  })
);

export const propertyTableGridState = makeVar<GridInitialStatePro>(
  gridInitialState({
    pagination: { paginationModel: { pageSize: 10, page: 0 } },
  })
);
export const propertyTableFilterGridState = makeVar<{ filteredRowIds?: string[] }>({});

export const propertySuppliersGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const propertyContractorsGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const addReconciliationJournalTableState = makeVar<GridInitialStatePro>(
  gridInitialState({
    pagination: { paginationModel: { pageSize: 100, page: 1 } },
  })
);
export const floorplansGridState = makeVar<GridInitialStatePro>(gridInitialState());

export const repliesGridState = makeVar<GridInitialStatePro>(gridInitialState());
export const leaseDocumentTemplatesGridState = makeVar<GridInitialStatePro>(gridInitialState());
