import { CloudUpload as CU } from '@mui/icons-material';
import { Box, Button, Chip, Grid, Stack, styled, Typography } from '@mui/material';
import { LoadingButton } from 'components';
import { castArray, compact } from 'lodash';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { hasProperty } from 'system';

const DropzoneContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 20,
  borderWidth: 2,
  borderRadius: 16,
  borderColor: theme.palette.secondary.main,
  borderStyle: 'dashed',
  backgroundColor: theme.palette.background.paper,
  outline: 'none',
  transition: 'border .24s ease-in-out',
  textAlign: 'center',
  cursor: 'pointer',
}));

const CloudUpload = styled(CU)({
  color: 'secondary.main',
  width: 48,
  height: 48,
});

type DropzoneProps = {
  uploading?: boolean;
  filesToUpload: File[];
  maxFileSizeMB?: number;
  onDrop: (f: File[]) => void;
  onRemove: (f: File) => void;
  onSave?: VoidFunction;
  onCancel?: VoidFunction;
  cancelAfterSave?: boolean;
  dropzoneOptions?: Pick<DropzoneOptions, 'accept'>;
};

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const mimeExt = {
  'text/csv': '.csv',
  'application/pdf': '.pdf',
} as const;

export default function Dropzone({
  onCancel,
  onDrop,
  onRemove,
  onSave,
  cancelAfterSave = false,
  uploading,
  filesToUpload,
  maxFileSizeMB = 40,
  dropzoneOptions,
}: DropzoneProps) {
  const { getRootProps, getInputProps } = useDropzone({
    ...dropzoneOptions,
    onDrop,
    multiple: true,
    maxSize: maxFileSizeMB * 1024 * 1024,
  });

  const handleSave = async () => {
    if (onSave) {
      await onSave();
    }

    if (cancelAfterSave) {
      onCancel?.();
    }
  };

  return (
    <Stack>
      <DropzoneContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <CloudUpload />
        <Typography variant="h5">Upload Documents</Typography>
        <Typography variant="subtitle1">
          Drag and drop your documents or click here to get started.
        </Typography>
        <Typography variant="caption">Maximum file upload size: {maxFileSizeMB} MB</Typography>
        {dropzoneOptions?.accept && (
          <Typography variant="caption">
            Allowed files:{' '}
            {compact(castArray(dropzoneOptions?.accept))
              .map((mime) => (hasProperty(mimeExt, mime) ? mimeExt[mime] : mime))
              .join(', ')}
          </Typography>
        )}

        <Grid container direction="row" alignItems="center" justifyContent="center">
          {filesToUpload.length > 0 &&
            filesToUpload.map((file) => (
              <Chip
                key={file.name}
                label={file.name}
                onDelete={() => onRemove(file)}
                sx={{ m: 1 }}
              />
            ))}
        </Grid>
      </DropzoneContainer>

      <Grid item container justifyContent="flex-end">
        {onCancel && (
          <Button
            aria-roledescription="Cancel uploading"
            onClick={onCancel}
            variant="cancel"
            disabled={uploading}
          >
            Cancel
          </Button>
        )}
        {onSave && (
          <LoadingButton
            color="primary"
            aria-roledescription="Save files"
            onClick={handleSave}
            loading={uploading}
            disabled={filesToUpload.length === 0}
          >
            Save
          </LoadingButton>
        )}
      </Grid>
    </Stack>
  );
}
