import { ensureArray } from './ensureArray';

/**
 * Groups an array of objects based on the value of a specified key.
 *
 * @template T extends Record<string, unknown>
 * @param {T[]} arr - The array of objects to be grouped.
 * @param {keyof T} key - The key to group the objects by.
 * @returns An object where the keys are the unique values of the specified key, and the values are arrays containing the objects that have that value for the key.
 *
 * @example // grouping by a string key
 * const items = [
 *   { id: 1, name: 'Alice', age: 25 },
 *   { id: 2, name: 'Bob', age: 30 },
 *   { id: 3, name: 'Alice', age: 25 }
 * ];
 * const grouped = groupBy(items, 'name');
 * // grouped.Alice type is Array<{id: number, name: string, age: number}>
 * // grouped.Bob type is Array<{id: number, name: string, age: number}>
 *
 * @example // grouping by a discriminated union member
 * const items = [
 *   { type: 'success', data: 'ok' },
 *   { type: 'error', message: 'failed' }
 * ] as const;
 * const grouped = groupBy(items, 'type');
 * // grouped.success type is Array<{type: 'success', data: string}>
 * // grouped.error type is Array<{type: 'error', message: string}>
 */
export const groupBy = <
  T extends Record<string, unknown>,
  K extends keyof T,
  V extends T[K] extends PropertyKey
    ? T[K]
    : T[K] extends boolean
      ? 'true' | 'false'
      : T[K] & PropertyKey,
>(
  arr: readonly T[],
  key: K
) =>
  arr.reduce(
    (acc, item) => {
      const keyValue = String(item[key]) as V;
      return {
        ...acc,
        [keyValue]: [...ensureArray(acc[keyValue]), item],
      };
    },
    {} as {
      [P in V]?: Extract<T, Record<K, P extends 'true' | 'false' ? boolean : P>>[];
    }
  );
