import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

const numberFormatSx: TextFieldProps['sx'] = {
  '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
    {
      display: 'none',
    },
};

export type CurrencyFieldProps = Omit<TextFieldProps, 'ref' | 'defaultValue'> & {
  onValueChange?: (vals: NumberFormatValues) => void;
  showClear?: boolean;
  value?: number;
  defaultValue?: number;
};

export const CurrencyField = ({
  variant = 'filled',
  disabled = false,
  onChange,
  className,
  onValueChange,
  autoComplete = 'off',
  type: _type,
  InputProps,
  sx,
  ...props
}: CurrencyFieldProps) => {
  return (
    <NumberFormat<TextFieldProps>
      customInput={TextField}
      variant={variant}
      thousandSeparator
      isNumericString
      decimalScale={2}
      fixedDecimalScale={true}
      disabled={disabled}
      onValueChange={(vals, { source }) => {
        source === 'event' && onValueChange?.(vals);
      }}
      onChange={onChange}
      fullWidth
      sx={{ ...numberFormatSx, ...sx }}
      className={className}
      InputProps={{
        autoComplete,
        ...InputProps,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      {...props}
    />
  );
};
